import { Box, MarginBoxProps, marginStyleGenerator } from 'aos-ui/src/components/base/Box'
import { textStyleBuilder } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { CSSObject } from 'styled-components'

export enum HeaderIconVariant {
    Yellow,
    Orange,
}

interface HeaderIconProps extends MarginBoxProps {
    variant: HeaderIconVariant
}

const variantStyleMap: Record<HeaderIconVariant, CSSObject> = {
    [HeaderIconVariant.Orange]: {
        background: Color.Orange,
        ...textStyleBuilder({ size: 12, color: Color.Black }),
    },
    [HeaderIconVariant.Yellow]: {
        background: Color.Yellow,
        ...textStyleBuilder({ size: 12, color: Color.Black }),
    },
}

export const HeaderLabel = styled(Box)<HeaderIconProps>(({ variant, ...props }) => {
    const styles: CSSObject = {
        display: 'inline-block',
        height: '20px',
        borderRadius: '3px',
        padding: '0 8px',
        lineHeight: '20px',
        fontWeight: 'bolder',
    }

    const margins = marginStyleGenerator(props)

    return { ...margins, ...variantStyleMap[variant], ...styles }
})
