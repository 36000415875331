import { CdmMetricPointDto } from '../../../../dataaccess/cdm/types/CdmMetricPointDto'
import { CdmMetricPoint } from './CdmMetricPoint'

export class CdmMetricPointImpl implements CdmMetricPoint {
    public get value(): number {
        return Math.round(this.pointValue.value)
    }
    public get flightNumbers(): string[] {
        return this.pointValue.flightNumbers
    }

    constructor(private pointValue: CdmMetricPointDto) {}
}
