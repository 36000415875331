import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Towing } from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { IataCodeName } from 'aos-services/src/services/iata/types/IataCodeName'

import { FlightFilters } from './flight/flightFiltersState'

export interface FlightInformationState {
    isLoading: boolean
    list: Flight[]
    isTowingLoading: boolean
    towingList: Towing[]
    columnValues: FlightInformationColumnValues
    showCustomizeModal: boolean
    showShareModal: boolean
    sendingInProgress: boolean
    nowLocked: boolean
    airlines: IataCodeName[]
    lastFilters: Record<FlightListType, Partial<FlightFilters>>
    sorting: Record<FlightSort, SortingRule>
    quickSearch: string
}

export enum FlightSort {
    ARRIVALS = 'arrivals',
    DEPARTURES = 'departures',
    FLIGHTS = 'flights',
    TOWING = 'towing',
    DELAYS = 'delays',
    PUNCTUALITY = 'punctuality',
    REGULARITY = 'regularity',
    BAGGAGE = 'baggage',
}

export interface FlightInformationStateAware {
    flights: FlightInformationState
}

export interface SortingRule {
    field: string
    desc: boolean
}

export interface FlightInformationColumnValues {
    // Common
    hapt: string[]
    naflt: string[]
    routes: string[]
    actype: string[]
    prm: string[]

    // Arrivals
    bltarea: string[]
    bag: string[]
    door: string[]
    rwy: string[]

    // Towings
    handl: string[]
}

export interface TableFilter<V = string> {
    id: string
    value: V
}

export const initialColumnValues: FlightInformationColumnValues = {
    hapt: [],
    naflt: [],
    routes: [],
    actype: [],
    bltarea: [],
    bag: [],
    prm: [],
    door: [],
    rwy: [],
    handl: [],
}

export const defaultSorting: SortingRule = { field: 'sdt', desc: true }
export const defaultTowingSorting: SortingRule = { field: 'after', desc: true }

export const initialFlightInformationState: FlightInformationState = {
    isLoading: true,
    list: [],
    isTowingLoading: true,
    towingList: [],
    columnValues: initialColumnValues,
    showCustomizeModal: false,
    showShareModal: false,
    sendingInProgress: false,
    nowLocked: true,
    airlines: [],
    lastFilters: getInitialValuesForEnumRecord(FlightListType, {}),
    sorting: Object.fromEntries(
        Object.values(FlightSort).map(key => [key, defaultSorting]),
    ) as Record<FlightSort, SortingRule>,
    quickSearch: '',
}
