import { GaugeOptions } from '../types/GaugeOptions'
import { getMinMax } from './getMinMax'

export const getCurrentValueInRange = ({ currentValue, ranges }: GaugeOptions) => {
    if (currentValue === null) {
        return null
    }

    const [min, max] = getMinMax(ranges)

    if (currentValue < min) {
        return min
    }

    if (currentValue > max) {
        return max
    }

    return currentValue
}
