import { AosUserNotificationsPreferences } from '../../services/users/types/AosUserNotificationsPreferences'
import { BaseRestService } from '../base/BaseRestService'
import { AosUserGroupDto } from './types/AosUserGroupDto'

class GroupRestService extends BaseRestService {
    public getAllGroups(): Promise<AosUserGroupDto[]> {
        return this.getAndTransform<AosUserGroupDto[]>('/groups', BaseRestService.jsonTransformer)
    }

    public createNewGroup(name: string): Promise<AosUserGroupDto> {
        return this.postJsonAndTransform<AosUserGroupDto>(
            '/groups',
            {
                name,
            },
            BaseRestService.jsonTransformer,
        )
    }

    public removeUserFromGroup(groupId: number, userId: number): Promise<Response> {
        return this.deleteReq(`/groups/${groupId}/members/${userId}`)
    }

    public addUserToGroup(groupId: number, userId: number): Promise<Response> {
        return this.postJson(`/groups/${groupId}/members/${userId}`, {})
    }

    public removeGroup(groupId: number): Promise<Response> {
        return this.deleteReq(`/groups/${groupId}`)
    }

    public renameGroup(groupId: number, newName: string): Promise<Response> {
        return this.patchJson(`/groups/${groupId}`, {
            name: newName,
        })
    }

    public exportGroups() {
        return this.getAndTransform('/groups/export', BaseRestService.fileDownloadTransformer)
    }

    public updateNotifications(
        groupId: number,
        notificationPreferences: AosUserNotificationsPreferences,
    ): Promise<Response> {
        return this.postJson(`/groups/${groupId}/notifications`, notificationPreferences)
    }
}

export const groupRestService = new GroupRestService()
