import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { assigneesLabel } from 'aos-services/src/services/tasks/types/task/BaseTask'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Box, MarginBoxProps } from '../base/Box'
import { Text } from '../base/Text'
import { Icon, IconVariant } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'

interface UserAssignmentProps extends MarginBoxProps {
    users: { name: string }[]
    onWhite?: boolean
    textColor?: Color
    iconVariant?: IconVariant
}

export const UserAssignment: FCWithChildren<UserAssignmentProps> = ({
    users,
    textColor,
    iconVariant,
    onWhite,
    ...margins
}) => {
    if (!users.length) {
        return null
    }
    return (
        <Box row {...margins}>
            <Icon
                svg={SvgIcon.Users}
                iconVariant={
                    onWhite ? IconVariant.Black : iconVariant ? iconVariant : IconVariant.White
                }
                iconSize={BlockSize.Tiny}
            />
            <Text
                paddingLeft={4}
                size={12}
                color={onWhite ? Color.Black : textColor ? textColor : Color.Grey}
                shrink={0}
            >
                {assigneesLabel(users)}
            </Text>
        </Box>
    )
}
