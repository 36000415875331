import { isNil, isNumber } from 'lodash'

import { translate } from './translations/Translations'

export const emptyText = () => translate('text.empty')

export const optionalText = (i?: string | number): string =>
    !isNil(i) ? (isNumber(i) ? i.toString() : i) : emptyText()

export const joinPipe = (items: string[]): string => items.join(' | ')

export const thinSpace = '\u2009'
