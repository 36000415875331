export enum LayerId {
    Gates = 'gates',
    BusGates = 'busGates',
    BaggageClaims = 'baggageClaims',
    Events = 'eventLayer',
    Tasks = 'taskLayer',
    Stands = 'stands',
    Trains = 'trains',
    BusDoors = 'busDoors',
    Parkings = 'parkings',
    ArrivalServices = 'arrivalServices',
    CheckInDesks = 'checkInDesks',
    CheckInKiosk = 'checkInKiosk',
    Customs = 'customs',
    PassportControl = 'passportControl',
    SecurityCheck = 'securityCheck',
    WalkingRoutes = 'walkingRoutes',
    WalkingRoutesIcon = 'walkingRoutesIcon',
    Fods = 'fods',
}
