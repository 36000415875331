export enum TaskOrDescription {
    Task = 'TASK',
    Description = 'DESCRIPTION',
}

export enum AosChecklistItemType {
    Task = 'TASK',
    Description = 'DESCRIPTION',
    MessageTemplate = 'MESSAGE_TEMPLATE',
}
