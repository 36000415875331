import { firebaseApp } from 'firebaseApp'

import { loadFirebaseValue } from '../../../../aos-helpers/src/helpers/firebase/Firebase'

class FirebaseMapLayersVisibilityRepository {
    public getRef = (userId: number) => firebaseApp.database().ref(`userMapLayers/${userId}`)

    public load = (userId: number): Promise<string[]> =>
        loadFirebaseValue<string[]>(this.getRef(userId), snapshot => snapshot.val() ?? [])

    public update = (userId: number, maplayers: string[]) => this.getRef(userId).set(maplayers)
}
export const firebaseMapLayersVisibilityRepository = new FirebaseMapLayersVisibilityRepository()
