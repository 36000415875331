import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { translateResourceNumberLabel } from 'aos-services/src/services/restrictions/types/RestrictionResourceType'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    selectedItemArrivalsSelector,
    selectedItemDeparturesSelector,
    selectedRestrictionSelector,
} from '../../../../core/standsGatesTimeline/selectors'
import { AirportResourceSidebar } from '../../../sidebar/AirportResourceSidebar'
import { DefaultSidebar } from './DefaultSidebar'

const SidebarStackClass: FC<SidebarStackStateProps> = props => {
    const { restriction, arrivals, departures } = props

    if (restriction) {
        return (
            <AirportResourceSidebar
                title={translateResourceNumberLabel(
                    restriction.resourceType,
                    restriction.resourceNumber,
                )}
                restriction={restriction}
                arrivals={arrivals}
                departures={departures}
            />
        )
    }

    return <DefaultSidebar />
}

interface SidebarStackStateProps {
    restriction?: Restriction
    arrivals?: Flight[]
    departures?: Flight[]
}

export const SidebarStack = connect<SidebarStackStateProps>(
    createStructuredSelector({
        restriction: selectedRestrictionSelector,
        arrivals: selectedItemArrivalsSelector,
        departures: selectedItemDeparturesSelector,
    }),
)(SidebarStackClass)
