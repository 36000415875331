import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { DateTime } from 'aos-helpers/src/helpers/Time'

export const SET_CURRENT_TIME = 'COMMON/SET_CURRENT_TIME'
export type SetCurrentTimeAction = PayloadAction<typeof SET_CURRENT_TIME, DateTime>
export const setCurrentTimeAction = payloadActionCreator<SetCurrentTimeAction, DateTime>(
    SET_CURRENT_TIME,
)

export type CommonAction = SetCurrentTimeAction
