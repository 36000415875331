import { DateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { duration } from 'moment'

import { FlightDto } from '../../../dataaccess/flightInformation/types/FlightDto'
import { baggageDeliveryRangesValues } from '../../airportStatus/baggage/baggageDeliveryConstants'
import { BaggageStatSection } from '../../airportStatus/baggage/types/BaggageStatSection'
import { getStatusRangeType } from '../../common/types/StatusRange'
import { AosFlightBeltStatus, BaggageIndicatorProgress } from './AosFlightBeltStatus'

export class AosFlightBeltStatusImpl
    extends WrapperObject<FlightDto>
    implements AosFlightBeltStatus {
    private ablk_d = dateTimeFromOptional(this.value.ablk)

    public indicator = this.value.bltInd || ''
    public firstBag = dateTimeFromOptional(this.value.fibag)
    public lastBag = dateTimeFromOptional(this.value.labag)

    public hasBag = !!this.ablk_d && !!this.firstBag
    public deliveryTime = (now: DateTime) =>
        this.hasBag ? duration((this.lastBag || now).diff(this.ablk_d)) : duration(0)
    public deliveryTimeInMinutes = (now: DateTime) => this.deliveryTime(now).asMinutes()
    public isDeliveryTimeValid = (now: DateTime) => this.deliveryTimeInMinutes(now) <= 120 // TODO: treshold from Firebase

    public get progress() {
        if (!this.ablk_d) {
            return BaggageIndicatorProgress.NotLanded
        } else if (!this.firstBag) {
            return BaggageIndicatorProgress.Landed
        } else {
            return !this.lastBag
                ? BaggageIndicatorProgress.DeliveryStarted
                : BaggageIndicatorProgress.DeliveryCompleted
        }
    }

    public deliveryStatus = (now: DateTime) => {
        this.deliveryTimeInMinutes(now)
        return getStatusRangeType(
            baggageDeliveryRangesValues,
            this.deliveryTimeInMinutes(now),
            BaggageStatSection.Normal,
        )
    }
}
