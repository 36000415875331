import zod from 'zod'

import { LocationDto } from '../../common/types/LocationDto'
export interface AosCommonEventDto {
    id?: number
    category: string
    severity: string
    process: string
    description?: string
    title: string
    startTime?: string
    endTime?: string
    attachments?: Attachment[]
    location?: LocationDto
    atcAlert?: boolean
    atcFields?: AtcFieldsDto
    aosUserGroupId?: number
    exercise?: boolean
    hardAlert?: boolean
}

export interface Attachment {
    id?: number
    link: string
}

export const AtcFieldsDtoSchema = zod.object({
    aircraftModel: zod.string().nullable().optional(),
    flightNumber: zod.string().nullable().optional(),
    eta: zod.string().nullable().optional(),
    numberOfPax: zod.string().nullable().optional(),
    incidentType: zod.string().nullable().optional(),
    numberOfCrew: zod.string().nullable().optional(),
    dgr: zod.string().nullable().optional(),
    moreInfo: zod.string().nullable().optional(),
    fuel: zod.string().nullable().optional(),
    callSign: zod.string().nullable().optional(),
    locationName: zod.string().nullable().optional(),
    route: zod.string().nullable().optional(),
})

export type AtcFieldsDto = zod.infer<typeof AtcFieldsDtoSchema>
