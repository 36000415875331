import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { compact } from 'lodash'

import { AosFeedOutDto } from '../../../dataaccess/events/types/AosEventDto'
import { AosUserGroupImpl } from '../../users/types/AosUserGroupImpl'
import { AosFeedOut } from './AosFeedOut'

export class AosFeedOutImpl extends WrapperObject<AosFeedOutDto> implements AosFeedOut {
    constructor(value: AosFeedOutDto) {
        super(value)
    }

    get automated() {
        return this.value.automated
    }

    public get id() {
        return this.value.id
    }

    public get text() {
        return this.value.text
    }

    public get channels() {
        return compact([
            this.value.dashboard && AosMessageChannel.Notification,
            this.value.push && AosMessageChannel.Push,
            this.value.sms && AosMessageChannel.Sms,
            this.value.email && AosMessageChannel.Email,
        ])
    }

    public get createdAt() {
        return dateTime(this.value.createdAt)
    }

    public get group() {
        return new AosUserGroupImpl(this.value.group)
    }
}
