import { compact } from 'lodash'

import { TaskType } from '../TaskType'
import { BaseTask } from './BaseTask'
import { InspectionItem } from './InspectionItem'
import { translateInspectionType } from './InspectionType'

export interface InspectionTask extends BaseTask {
    type: TaskType.Inspection
    items: InspectionItem[]
}

export const inspectionItemLabel = (ch: InspectionItem) =>
    compact([translateInspectionType(ch.inspectionType), ch.comment]).join(' - ')
