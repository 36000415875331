import {
    getColorForRingRailTrainScheduleStatus,
    RingRailTrainScheduleStatus,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import React, { PureComponent } from 'react'

import { TrainNoDirectionBgIcon } from './TrainNoDirectionBgIcon'
import { TrainWithDirectionBgIcon } from './TrainWithDirectionBgIcon'

export class TrainBackgroundIconRenderer extends PureComponent<TrainBgIconRendererProps> {
    public render() {
        const { isSelected, scheduleStatus, withDirection } = this.props

        const color = isSelected
            ? 'rgba(255,255,255,.2)'
            : getColorForRingRailTrainScheduleStatus(scheduleStatus)

        return withDirection ? (
            <TrainWithDirectionBgIcon color={color} withBorder={isSelected} />
        ) : (
            <TrainNoDirectionBgIcon color={color} withBorder={isSelected} />
        )
    }
}

export interface TrainBgIconRendererProps {
    withDirection: boolean
    scheduleStatus?: RingRailTrainScheduleStatus
    isSelected?: boolean
}
