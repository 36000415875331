import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

export class UserSearch extends PureComponent<UserSearchProps> {
    public render() {
        return (
            <CleanableInput
                type='text'
                variant={ThemeVariant.Black}
                placeholder={translate('group-manager.user-list.search.placeholder')}
                value={this.props.searchUserText}
                leftSvg={SvgIcon.SearchWhite}
                onChangeText={this.props.onSearch}
                small
            />
        )
    }
}

interface UserSearchProps extends ClassNameProps {
    searchUserText?: string
    onSearch(v: string): void
}
