import { TimeOfDayAxis } from 'aos-components/src/components/chart/axes/TimeOfDayAxis'
import { Chart } from 'aos-components/src/components/chart/Chart'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import {
    calculateDomain,
    calculateTimeDomainForSeries,
} from 'aos-helpers/src/helpers/domain/Domain'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { startOfTimeOfDays } from 'aos-helpers/src/helpers/TimeOfDay'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastHoursChartPoint } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { PaxForecastHoursChartLegend } from './PaxForecastHoursChartLegend'
import { PaxForecastHoursDoubleBarSeries } from './PaxForecastHoursDoubleBarSeries'

interface PaxForecastHoursChartProps {
    data: PaxForecastHoursChartPoint[]
    flex?: boolean
}

export class PaxForecastHoursChart extends PureComponent<PaxForecastHoursChartProps> {
    public render() {
        const { data } = this.props
        const yDomain = calculateDomain(data, this.yTotalAccessor, 7)

        return (
            <Chart
                flex
                xBandConfig={{
                    domain: calculateTimeDomainForSeries(data),
                    tickFormat: formatHour,
                    bandPadding: { x0: { inner: 0.25, outer: 0 } },
                    barCount: 2,
                }}
                y1Config={{
                    domain: yDomain,
                    tickFormat: formatNumber,
                    showAxisLine: false,
                }}
                topAxisSize={40}
                bottomLegend={{ component: <PaxForecastHoursChartLegend />, height: 30 }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { xBandScale, y1Scale, xBandConfig } = props
        if (!xBandScale || !y1Scale || !xBandConfig) {
            return null
        }
        const { data } = this.props
        const lineSeriesScales = {
            xScale: xBandScale.x0,
            yScale: y1Scale,
        }
        const barSeriesScales = {
            x0Scale: xBandScale.x0,
            x1Scale: xBandScale.x1,
            yScale: y1Scale,
        }

        return (
            <>
                <TimeOfDayAxis
                    axisConfig={{ tickValues: startOfTimeOfDays(xBandConfig?.domain.ticks) }}
                    scale={barSeriesScales.x0Scale}
                />
                <PaxForecastHoursDoubleBarSeries data={data} scales={barSeriesScales} />
                <LineSeries
                    data={data}
                    seriesConfig={{ color: Color.White, withDots: true }}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.yTotalAccessor }}
                    scales={lineSeriesScales}
                />
            </>
        )
    }

    private xAccessor = (d: PaxForecastHoursChartPoint) => d.time
    private yTotalAccessor = (d: PaxForecastHoursChartPoint) =>
        d[PaxForecastArrivalOrDeparture.Departures] + d[PaxForecastArrivalOrDeparture.Arrivals]
}
