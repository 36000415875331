export enum InvitationRole {
    Command = 'COMMAND',
    Participate = 'PARTICIPATE',
    Observe = 'OBSERVE',
    Pending = 'PENDING',
    Left = 'LEFT',
}

// Supported fallback statuses
export enum FallbackInvitationStatus {
    Command = 'COMMANDER',
    Follows = 'FOLLOWS',
}

export enum InvitationStatus {
    AtAirport = 'AT_AIRPORT',
    Coming = 'COMING',
    Remote = 'REMOTE',
    Skip = 'SKIP',
    NotSelected = 'NOT_SELECTED',
}

export interface InvitationOrder {
    [key: string]: number
}

export const invitationRoleOrder: InvitationOrder = {
    [InvitationRole.Command]: 1,
    [InvitationRole.Participate]: 2,
    [InvitationRole.Observe]: 3,
    [InvitationRole.Pending]: 4,
    [InvitationRole.Left]: 5,
}
