import { Box } from 'aos-ui/src/components/base/Box'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { VerticalTabs } from 'aos-ui/src/components/tabs/VerticalTabs'
import { VerticalTabText } from 'aos-ui/src/components/tabs/VerticalTabText'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { WizardNavigator } from 'aos-ui/src/components/wizard/WizardNavigator'
import React, { LazyExoticComponent, PureComponent, Suspense } from 'react'

export class HelpModal extends PureComponent<HelpModalProps> {
    public state: HelpModalState = {
        currentIndex: 0,
    }

    public render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                title={this.props.title}
                modalKind={ModalKind.Standard}
                closeAction={this.props.closeAction}
            >
                <Box className='help-modal-content' flex={1} row>
                    {Array.isArray(this.props.items) && this.renderTabs(this.props.items)}
                    {this.renderContent()}
                </Box>
            </Modal>
        )
    }

    private renderContent = () => {
        const hasMoreElements = Array.isArray(this.props.items)
        const ItemComponent = Array.isArray(this.props.items)
            ? this.props.items[this.state.currentIndex].renderer
            : this.props.items
        return (
            <Box column fullHeight flex={1}>
                <Box relative flex={1}>
                    <Suspense fallback={<Spinner />}>
                        <ItemComponent />
                    </Suspense>
                </Box>
                {hasMoreElements && this.renderFooter()}
            </Box>
        )
    }

    private renderTabs = (items: HelpModalItem[]) => (
        <Box className='help-modal-content__tabs' fullHeight paddingTop={20}>
            <VerticalTabs
                items={items}
                activeIndex={this.state.currentIndex}
                itemRenderer={this.renderTab}
            />
        </Box>
    )

    private renderTab = (item: HelpModalItem, isActive: boolean, index: number) => (
        <VerticalTabText
            title={item.title}
            note={item.note}
            isActive={isActive}
            onClick={() => this.switchTab(index)}
        />
    )

    private renderFooter() {
        return (
            <Box className='border-top--black' padding={30} shrink={0}>
                <WizardNavigator
                    current={this.state.currentIndex}
                    itemCount={this.props.items.length}
                    onNext={this.next}
                    onPrev={this.prev}
                />
            </Box>
        )
    }

    private next = () => {
        this.switchTab(this.state.currentIndex + 1)
    }

    private switchTab = (currentIndex: number) => {
        this.setState({ currentIndex })
    }

    private prev = () => {
        this.switchTab(this.state.currentIndex - 1)
    }
}

interface HelpModalState {
    currentIndex: number
}

type Renderer = React.ComponentType | LazyExoticComponent<any>

export interface HelpModalItem {
    title: string
    note?: string
    renderer: Renderer
}

export interface HelpModalConfig {
    title: string
    items: HelpModalItem[] | Renderer
}

interface HelpModalProps extends HelpModalConfig {
    isOpen: boolean
    closeAction(): void
}
