import { formatDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    BaggageDeliverySelectorState,
    baggageDeliveryTileSelector,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/baggageDelivery/baggageDelivery'
import { TrendMeasure } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { BaggageDeliveryItemState } from '../../../../services/statusDashboard/types/itemStates/BaggageDeliveryItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { BaggageDeliveryChartLegend } from './BaggageDeliveryChartLabel'
import { BaggageDeliveryItem } from './partials/BaggageDeliveryItem'

interface BaggageDeliveryTileProps
    extends StatusDashboardItemCommonProps<BaggageDeliveryItemState>,
        BaggageDeliveryTileStateProps {}

export const BaggageDeliveryTileComponent: FC<BaggageDeliveryTileProps> = props => {
    const { times, meanTrend, flightCounts } = props.stats

    return (
        <BaggageDeliveryItem
            itemProps={pickDashboardItemProps(props)}
            noDataWarning={props.noDataWarning}
            link={link}
        >
            <TileContainer>
                <TrendMeasure
                    value={formatDuration(times.mean)}
                    trend={meanTrend}
                    subunit={'\u00A0' + translate('dashboard.baggage-delivery.unit.flights')}
                    label={
                        <BaggageDeliveryChartLegend
                            counts={flightCounts}
                            maxTime={times.max}
                            minTime={times.min}
                            marginTop={4}
                        />
                    }
                />
            </TileContainer>
        </BaggageDeliveryItem>
    )
}

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Baggage })

export interface BaggageDeliveryTileStateProps extends BaggageDeliverySelectorState {}

export const BaggageDeliveryTile = connect<BaggageDeliveryTileStateProps>(
    baggageDeliveryTileSelector,
)(BaggageDeliveryTileComponent)
