import { NewGlobalMessage } from 'aos-services/src/services/messages/input/NewGlobalMessage'
import { AosGlobalMessage } from 'aos-services/src/services/messages/types/AosGlobalMessage'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'

export interface GlobalMessagingUIState {
    isConfirmationVisible: boolean
    isSendingMessage: boolean
}

export interface GlobalMessagingState {
    isOpen: boolean
    messages: AosGlobalMessage[]
    newMessage: NewGlobalMessage
    ui: GlobalMessagingUIState
}

export interface GlobalMessagingStateAware {
    globalMessaging: GlobalMessagingState
}

export const initialNewGlobalMessageState: NewGlobalMessage = {
    text: '',
    channels: [AosMessageChannel.Notification],
}

export const initialGlobalMessagingState: GlobalMessagingState = {
    isOpen: false,
    messages: [],
    newMessage: initialNewGlobalMessageState,
    ui: {
        isConfirmationVisible: false,
        isSendingMessage: false,
    },
}
