import { isSectionActive, RunwayMode } from 'aos-services/src/services/runways/types/RunwayMode'
import React, { PureComponent } from 'react'

import { RunwayArrow } from './RunwayArrow'

export class RunwayBottomSection extends PureComponent<RunwaySectionProps> {
    public render() {
        return (
            <g transform={`translate(${this.props.offset}, 220)`} width={24} height={60}>
                {this.props.modes.map((m, index) => (
                    <RunwayArrow key={index} inversed mode={m} top={28 + index * 20} />
                ))}
                <text x='12' y='13' className={this.partClass} textAnchor='middle'>
                    {this.props.name}
                </text>
            </g>
        )
    }

    private get partClass() {
        return isSectionActive(this.props.modes) ? 'active' : 'inactive'
    }
}

interface RunwaySectionProps {
    offset: number
    modes: RunwayMode[]
    name: string
}
