import { isNotNull } from 'aos-helpers/src/helpers/Function'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { chain } from 'lodash'

import { ChecklistManagerStateAware } from './state'

export const getExistingTags = (templates: AosSimpleTemplate[]) =>
    chain(templates)
        .orderBy([t => t.modifiedAt?.valueOf?.() ?? 0], ['desc'])
        .map(t => t.tag)
        .filter(isNotNull)
        .uniq()
        .value()

export const getExistingTagsSortedSelector = <T extends { tag: string | null }>(checklists: T[]) =>
    chain(checklists)
        .map(t => t.tag)
        .sort()
        .filter(isNotNull)
        .uniq()
        .value()

export const currentEditTaskOrDescriptionItemType = (state: ChecklistManagerStateAware) => {
    const payload = state.checklistManager.editTaskOrDescriptionModal.currentValue?.payload
    if (payload && payload.item) {
        return payload.item.type
    }

    return undefined
}

export const currentCreateTaskOrDescriptionItemType = (state: ChecklistManagerStateAware) => {
    const value = state.checklistManager.createTaskOrDescriptionModal.currentValue?.value
    if (value) {
        return value.type
    }

    return undefined
}

export const isCreateMessageTemplateDialog = (state: ChecklistManagerStateAware) => {
    const payload = state.checklistManager.editMessageTemplateModal?.currentValue?.payload
    return !(payload && payload.item && !!payload.item.id)
}
