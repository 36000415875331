import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canViewTasksSelector } from 'aos-services/src/core/auth/state'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { openTaskAction } from '../../../../../core/tasks/actions'
import { loadRecentTasksAction } from '../../../../../core/tasks/recent/actions'
import {
    recentTasksIsErrorSelector,
    recentTasksIsLoadingSelector,
    recentTasksSelector,
} from '../../../../../core/tasks/recent/selectors'
import { RecentTasksItemState } from '../../../../../services/statusDashboard/types/itemStates/RecentTasksItemState'
import { pickDashboardItemProps, StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { TaskCardList } from '../partial/TaskCardList'

interface RecentTasksProps
    extends RecentTasksStateProps,
        RecentTasksDispatchProps,
        StatusDashboardItemCommonProps<RecentTasksItemState> {}

const RecentTasksComponent: FC<RecentTasksProps> = ({
    tasks,
    isLoading,
    isError,
    loadRecentTasks,
    openTask,
    canView,
    ...props
}) => {
    useEffect(() => {
        loadRecentTasks()
    }, [])

    return (
        <StatusDashboardItem
            title={translate('dashboard.tasks.recent-tasks.title')}
            description={translate('dashboard.tasks.recent-tasks.description')}
            {...pickDashboardItemProps(props)}
        >
            {canView ? (
                <TaskCardList
                    tasks={tasks}
                    isLoading={isLoading}
                    isError={isError}
                    onOpen={task => openTask([TaskFilter.All, task])}
                    empty={
                        <PanelPlaceholder
                            svg={SvgIcon.ChecklistPlaceholder}
                            label={translate('dashboard.tasks.recent-tasks.no-recent-tasks')}
                        />
                    }
                />
            ) : (
                <PanelPlaceholder svg={SvgIcon.NoData} label={translate('tasks.no-permissions')} />
            )}
        </StatusDashboardItem>
    )
}

interface RecentTasksStateProps {
    tasks: TaskSnapshot[]
    isLoading: boolean
    isError: boolean
    canView: boolean
}

interface RecentTasksDispatchProps {
    loadRecentTasks: typeof loadRecentTasksAction
    openTask: typeof openTaskAction
}

export const RecentTasks = connect<RecentTasksStateProps, RecentTasksDispatchProps>(
    createStructuredSelector({
        tasks: recentTasksSelector,
        isLoading: recentTasksIsLoadingSelector,
        isError: recentTasksIsErrorSelector,
        canView: canViewTasksSelector,
    }),
    {
        loadRecentTasks: loadRecentTasksAction,
        openTask: openTaskAction,
    },
)(RecentTasksComponent)
