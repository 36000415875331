import { dateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, timeRange } from 'aos-helpers/src/helpers/TimeRange'

import { eventsRestService } from '../../dataaccess/events/eventsRestService'
import { AosMapEventDto } from '../../dataaccess/events/types/AosMapEventDto'
import { restrictionsRestService } from '../../dataaccess/restrictions/restrictionsRestService'
import { tasksRestService } from '../../dataaccess/tasks/tasksRestService'
import { MapTaskDto } from '../../dataaccess/tasks/types/MapTaskDto'
import { AosMapEvent } from './types/AosMapEvent'
import { AosMapEventImpl } from './types/AosMapEventImpl'
import { BBox, extendBBox, normalizeBBox } from './types/BBox'
import { MapRestriction } from './types/MapRestriction'
import { MapRestrictionImpl } from './types/MapRestrictionImpl'
import { MapTask } from './types/MapTask'
import { MapTaskImpl } from './types/MapTaskImpl'

export class MapService {
    public loadMapEvents(bbox: BBox): Promise<AosMapEvent[]> {
        return eventsRestService
            .loadMapEvents(normalizeBBox(extendBBox(bbox, 1.2)).join(','))
            .then((t: AosMapEventDto[]) => t.map(e => new AosMapEventImpl(e)))
    }

    public loadMapTasks(bbox: BBox): Promise<MapTask[]> {
        return tasksRestService
            .loadMapTasks(normalizeBBox(extendBBox(bbox, 1.2)).join(','))
            .then((t: MapTaskDto[]) => t.map(e => new MapTaskImpl(e)))
    }

    public loadMapRestrictions(): Promise<MapRestriction[]> {
        return restrictionsRestService
            .getAllRestrictions()
            .then(restrictions =>
                restrictions
                    .filter(restriction =>
                        isTimeWithin(
                            dateTime(),
                            timeRange(
                                dateTime(restriction.startDate),
                                dateTime(restriction.endDate),
                            ),
                        ),
                    )
                    .map(restriction => new MapRestrictionImpl(restriction)),
            )
    }
}

export const mapService = new MapService()
