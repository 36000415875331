import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'

import { CheckboxItem } from './Checkbox'
import { DateTime } from './Time'
import { TimeRange, timeRange } from './TimeRange'

export interface IExportForm {
    timeStart: DateTime
    timeEnd: DateTime
    taskTypes: CheckboxItem<TaskVariant>[]
    airports: CheckboxItem<AosAirport>[]
    categories: TaskCategory[]
}

export interface TaskExport {
    range: TimeRange
    siteLocations: AosAirport[]
    tasks: boolean
    fods: boolean
    shiftNotes: boolean
    fireFighterShifts: boolean
    taskCategories: string[]
}

const isTaskVariantPresentInForm = (form: IExportForm, taskVariant: TaskVariant) =>
    form.taskTypes.some(q => q[1] && q[0] === taskVariant)

export const mapFormToExportTaskPayload = (form: IExportForm): TaskExport => {
    const taskCategories = form.categories.map(q => q.toUpperCase())
    const airports = form.airports.filter(q => q[1]).map(q => q[0])
    return {
        range: timeRange(form.timeStart, form.timeEnd),
        siteLocations: airports,
        tasks: isTaskVariantPresentInForm(form, TaskVariant.TASKS),
        fods: isTaskVariantPresentInForm(form, TaskVariant.FOD),
        shiftNotes: isTaskVariantPresentInForm(form, TaskVariant.SHIFT_NOTES),
        fireFighterShifts: isTaskVariantPresentInForm(form, TaskVariant.FIREFIGHTER_RESCUE_UNIT),
        taskCategories,
    }
}
