import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { isHelSelector } from 'aos-services/src/core/auth/state'
import {
    flightsSyncAction,
    flightsSyncStopAction,
} from 'aos-services/src/core/flightInformation/actions'
import {
    notamSyncAction,
    notamSyncStopAction,
    snowtamSyncAction,
    snowtamSyncStopAction,
} from 'aos-services/src/core/notam/actions'
import { towingSyncAction, towingSyncStopAction } from 'aos-services/src/core/towings/actions'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { NavLink } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { restoreLastFiltersAction } from '../../core/flightInformation/actions'
import { FlightInformationTab } from '../../core/flightInformation/types/FlightInformationTab'
import { Link } from '../../core/Links'
import { FlightInfoCustomizeModal } from './FlightInfoCustomizeModal'
import { FlightInfoShareModal } from './FlightInfoShareModal'
import { FlightTableArrivals } from './FlightTableArrivals'
import { FlightTableDepartures } from './FlightTableDepartures'
import { FlightListHeaderFilters } from './header/FlightListHeaderFilters'
import { NotamTableHeader } from './notam/NotamTableHeader'
import { NotamTimeline } from './notam/NotamTimeline'
import { StandsGatesTimeline } from './standsGates/StandsGatesTimeline'
import { StandsGatesTimelineHeader } from './standsGates/StandsGatesTimelineHeader'
import { TowingTable } from './TowingTable'

const linksList: Record<FlightInformationTab, string> = {
    [FlightInformationTab.Arrivals]: Link.FlightsArrivals,
    [FlightInformationTab.Departures]: Link.FlightsDepartures,
    [FlightInformationTab.Towing]: Link.FlightsTowingList,
    [FlightInformationTab.StandsGates]: Link.FlightsStandsGates,
    [FlightInformationTab.NOTAM]: Link.FlightsNotam,
}

class FlightInformationClass extends PureComponent<FlightInformationStateProps> {
    public render() {
        return (
            <>
                <Route path={`${Link.Flights}`} exact>
                    <Redirect to={Link.FlightsArrivals} />
                </Route>
                <Route
                    exact
                    path={Link.FlightsArrivals}
                    render={() => this.renderContent(FlightInformationTab.Arrivals)}
                />
                <Route
                    exact
                    path={Link.FlightsDepartures}
                    render={() => this.renderContent(FlightInformationTab.Departures)}
                />
                <Route
                    exact
                    path={Link.FlightsTowingList}
                    render={() => this.renderContent(FlightInformationTab.Towing)}
                />
                <Route
                    exact
                    path={Link.FlightsStandsGates}
                    render={() => this.renderContent(FlightInformationTab.StandsGates)}
                />
                <Route
                    exact
                    path={Link.FlightsNotam}
                    render={() => this.renderContent(FlightInformationTab.NOTAM)}
                />
            </>
        )
    }
    public renderContent(tab: FlightInformationTab) {
        return (
            <Box fullSize column>
                <PanelWithHeader header={this.renderHeader(tab)} disableScroll>
                    {tab === FlightInformationTab.Arrivals && this.renderArrivals()}
                    {tab === FlightInformationTab.Departures && this.renderDepartures()}
                    {tab === FlightInformationTab.Towing && this.renderTowings()}
                    {tab === FlightInformationTab.StandsGates && <StandsGatesTimeline />}
                    {tab === FlightInformationTab.NOTAM && this.renderNotam()}
                </PanelWithHeader>
                <FlightInfoShareModal />
            </Box>
        )
    }

    private renderArrivals() {
        return (
            <SyncWrapper
                onEnter={[
                    flightsSyncAction(FlightListType.Arrivals),
                    restoreLastFiltersAction(FlightListType.Arrivals),
                ]}
                onLeave={flightsSyncStopAction(FlightListType.Arrivals)}
            >
                <FlightTableArrivals />
                <FlightInfoCustomizeModal type={FlightListType.Arrivals} />
            </SyncWrapper>
        )
    }

    private renderDepartures() {
        return (
            <SyncWrapper
                onEnter={[
                    flightsSyncAction(FlightListType.Departures),
                    restoreLastFiltersAction(FlightListType.Departures),
                ]}
                onLeave={flightsSyncStopAction(FlightListType.Departures)}
            >
                <FlightTableDepartures />
                <FlightInfoCustomizeModal type={FlightListType.Departures} />
            </SyncWrapper>
        )
    }

    private renderNotam() {
        return (
            <SyncWrapper
                onEnter={[
                    notamSyncAction(FlightListType.NOTAM),
                    restoreLastFiltersAction(FlightListType.NOTAM),
                    snowtamSyncAction(FlightListType.SNOWTAM),
                ]}
                onLeave={[
                    notamSyncStopAction(FlightListType.NOTAM),
                    snowtamSyncStopAction(FlightListType.SNOWTAM),
                ]}
            >
                <NotamTimeline />
            </SyncWrapper>
        )
    }

    private renderTowings() {
        return (
            <SyncWrapper
                onEnter={[
                    towingSyncAction(FlightListType.Towing),
                    restoreLastFiltersAction(FlightListType.Towing),
                ]}
                onLeave={towingSyncStopAction(FlightListType.Towing)}
            >
                <TowingTable />
            </SyncWrapper>
        )
    }

    private renderHeader(type: FlightInformationTab) {
        return (
            <HeaderContainer title={this.renderTitleSection(type)}>
                {type === FlightInformationTab.Arrivals && (
                    <FlightListHeaderFilters type={FlightListType.Arrivals} />
                )}
                {type === FlightInformationTab.Departures && (
                    <FlightListHeaderFilters type={FlightListType.Departures} />
                )}
                {type === FlightInformationTab.Towing && (
                    <FlightListHeaderFilters type={FlightListType.Towing} />
                )}
                {type === FlightInformationTab.StandsGates && <StandsGatesTimelineHeader />}
                {type === FlightInformationTab.NOTAM && <NotamTableHeader />}
            </HeaderContainer>
        )
    }

    private renderTitleSection(type: FlightInformationTab) {
        const translateFlightType = translateEnum<FlightInformationTab>(
            FlightInformationTab,
            'flight-information.type',
        )
        const { isHel } = this.props
        const items = isHel
            ? [
                  FlightInformationTab.Arrivals,
                  FlightInformationTab.Departures,
                  FlightInformationTab.Towing,
                  FlightInformationTab.StandsGates,
                  FlightInformationTab.NOTAM,
              ]
            : [
                  FlightInformationTab.Arrivals,
                  FlightInformationTab.Departures,
                  FlightInformationTab.NOTAM,
              ]

        return (
            <HeaderFilter
                items={items}
                value={type}
                valueFormatter={v => (
                    <NavLink key={linksList[v]} to={linksList[v]}>
                        {translateFlightType(v)}
                    </NavLink>
                )}
            />
        )
    }
}

interface FlightInformationStateProps {
    isHel: boolean
}

export const FlightInformation = connect<FlightInformationStateProps>(
    createStructuredSelector({
        isHel: isHelSelector,
    }),
)(FlightInformationClass)
