import { DateTime, dateTimeToDate, dateTimeToUTC } from 'aos-helpers/src/helpers/Time'
import { ByWeekday, Frequency, Options, RRule, Weekday } from 'rrule'

import { RecurrenceCustomOn, RecurrenceCustomOnValue } from './types/payload/RecurrencePayloadEnums'
import { TaskRecurrencePayload } from './types/payload/TaskRecurrenceFormPayload'
import { TaskRecurrenceMonthlyPayload } from './types/payload/TaskRecurrenceMonthlyFormPayload'
import { TaskRecurrenceWeeklyPayload } from './types/payload/TaskRecurrenceWeeklyFormPayload'
import { TaskRecurrenceYearlyPayload } from './types/payload/TaskRecurrenceYearlyFormPayload'
import { TaskRecurrenceType } from './types/task/TaskRecurrenceType'
import { TaskRecurrenceWeekDay } from './types/task/TaskRecurrenceWeekly'

const recurrenceFrequency: Record<TaskRecurrenceType, Frequency> = {
    [TaskRecurrenceType.Hourly]: RRule.HOURLY,
    [TaskRecurrenceType.Weekly]: RRule.WEEKLY,
    [TaskRecurrenceType.Monthly]: RRule.MONTHLY,
    [TaskRecurrenceType.Yearly]: RRule.YEARLY,
}

const recurrenceWeekDays: Record<TaskRecurrenceWeekDay, Weekday> = {
    [TaskRecurrenceWeekDay.Monday]: RRule.MO,
    [TaskRecurrenceWeekDay.Tuesday]: RRule.TU,
    [TaskRecurrenceWeekDay.Wednesday]: RRule.WE,
    [TaskRecurrenceWeekDay.Thursday]: RRule.TH,
    [TaskRecurrenceWeekDay.Friday]: RRule.FR,
    [TaskRecurrenceWeekDay.Saturday]: RRule.SA,
    [TaskRecurrenceWeekDay.Sunday]: RRule.SU,
}

const customValues: Record<RecurrenceCustomOn, number> = {
    [RecurrenceCustomOn.FIRST]: 1,
    [RecurrenceCustomOn.SECOND]: 2,
    [RecurrenceCustomOn.THIRD]: 3,
    [RecurrenceCustomOn.FOURTH]: 4,
    [RecurrenceCustomOn.LAST]: -1,
    [RecurrenceCustomOn.EVERY]: 0,
}

export const validateHasAnyOccurrence = (
    r: TaskRecurrencePayload,
    startTime?: DateTime,
    endTime?: DateTime,
): boolean => {
    if (isInfiniteSchedule(startTime, endTime)) {
        return true
    }

    const options: Partial<Options> = prepareOptions(r, startTime!, endTime!)

    return new RRule(options).all().length > 0
}

const isInfiniteSchedule = (startTime?: DateTime, endTime?: DateTime) => !startTime || !endTime

const prepareOptions = (
    r: TaskRecurrencePayload,
    startTime: DateTime,
    endTime: DateTime,
): Partial<Options> => {
    switch (r.type) {
        case TaskRecurrenceType.Hourly:
            return baseOptions(r, startTime, endTime)
        case TaskRecurrenceType.Weekly:
            return weeklyOptions(r, startTime, endTime)
        case TaskRecurrenceType.Monthly:
            return monthlyOptions(r, startTime, endTime)
        case TaskRecurrenceType.Yearly:
            return yearlyOptions(r, startTime, endTime)
    }
}

const baseOptions = (
    r: TaskRecurrencePayload,
    startTime: DateTime,
    endTime: DateTime,
): Partial<Options> => ({
    freq: mapFrequencyType(r.type),
    dtstart: dateTimeToDate(dateTimeToUTC(startTime)),
    until: dateTimeToDate(dateTimeToUTC(endTime)),
    interval: Math.max(r.every, 0),
    count: 1,
})

const weeklyOptions = (
    r: TaskRecurrenceWeeklyPayload,
    startTime: DateTime,
    endTime: DateTime,
): Partial<Options> => ({
    ...baseOptions(r, startTime, endTime),
    byweekday: r.weekDays.length ? r.weekDays.map(mapWeekDay) : 0,
})

const monthlyOptions = (
    r: TaskRecurrenceMonthlyPayload,
    startTime: DateTime,
    endTime: DateTime,
): Partial<Options> => ({
    ...baseOptions(r, startTime, endTime),
    bymonthday: r.customOn ? undefined : r.on,
    count: r.customOn === RecurrenceCustomOn.EVERY ? undefined : 1,
    freq: r.customOn === RecurrenceCustomOn.EVERY ? RRule.WEEKLY : RRule.MONTHLY,
    byweekday: r.customOn
        ? r.customOn === RecurrenceCustomOn.EVERY
            ? (r.customOnValue as ByWeekday[])
            : (r.customOnValue as ByWeekday[]).map(weekday => (
                r.customOnValue.includes(RecurrenceCustomOnValue.DAY) ? 0 :
                    (RRule[weekday as keyof typeof RRule] as Weekday).nth(customValues[r.customOn!])
            ),
              )
        : undefined,
})

const yearlyOptions = (
    r: TaskRecurrenceYearlyPayload,
    startTime: DateTime,
    endTime: DateTime,
): Partial<Options> => ({
    ...baseOptions(r, startTime, endTime),
    bymonth: r.month,
})

const mapFrequencyType = (t: TaskRecurrenceType): Frequency => recurrenceFrequency[t]

const mapWeekDay = (w: TaskRecurrenceWeekDay): Weekday => recurrenceWeekDays[w]
