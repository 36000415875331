import { safeMax, safeMean, safeMin } from 'aos-helpers/src/helpers/Math'
import { Duration, duration } from 'moment'

export interface TimeStats {
    mean: Duration
    min: Duration
    max: Duration
}

export const meanDuration = (durations: Duration[]): Duration => {
    const milliseconds = durations.map(d => d.asMilliseconds())
    return duration(safeMean(milliseconds), 'ms')
}

export const getTimeStats = (durations: Duration[]): TimeStats => {
    const milliseconds = durations.map(d => d.asMilliseconds())
    return {
        mean: duration(safeMean(milliseconds), 'ms'),
        max: duration(safeMax(milliseconds), 'ms'),
        min: duration(safeMin(milliseconds), 'ms'),
    }
}

export interface CountStats {
    all: number
    applicable: number
}

export const formatCountStats = (stats: CountStats) => `${stats.applicable}/${stats.all}`
