import zod from 'zod'

import { translate } from '../../../../../aos-helpers/src/helpers/translations/Translations'
import { AosAirport } from '../../flightInformation/types/AosAirport'
import { FilterOption } from '../../flightInformation/types/FlightInfoFilters'

export interface ContactItem {
    id: number
    contactVisibility: ContactVisibility
    siteLocations: AosAirport[]
    name: string
    description: string
    phoneNumber: string
    email: string
    link: string
    linkLabel: string
    unitAirportContact: UnitAirportContact
}

export interface ContactList {
    [key: string]: {
        unit: UnitAirportContact
        contacts: ContactItem[]
    }
}

export enum ContactVisibility {
    AllUsers = 'ALL_USERS',
    APOCOnly = 'APOC_ONLY',
}

export interface UnitAirportContact {
    id: number
    name: string
    description: string
}

export interface UnitAirportContactDTO {
    id?: number
    name: string
    description: string
}

export type ContactListRow = UnitRow | ContactRow

export type UnitRow = {
    type: 'unit'
    unit: UnitAirportContact
}

export type ContactRow = {
    type: 'contact'
    contact: ContactItem
}

export interface ContactDTO {
    id: number
    contactVisibility: ContactVisibility
    siteLocations: (AosAirport | FilterOption)[]
    name: string
    description: string
    phoneNumber: string
    email: string
    link: string
    linkLabel: string
    unitAirportContact: Partial<UnitAirportContact>
}

const emailSchema = zod.union([
    zod.literal(''),
    zod.string().email().max(64, translate('contact-list.form.email.error-too-long')),
])
const urlSchema = zod.union([
    zod.literal(''),
    zod
        .string()
        .url()
        .max(64, translate('contact-list.form.link.error-too-long'))
        .optional()
        .nullable(),
])

export const ContactItemSchema = zod.object({
    id: zod.number().nullable().optional(),
    contactVisibility: zod.enum([ContactVisibility.AllUsers, ContactVisibility.APOCOnly]),
    siteLocations: zod.array(zod.union([zod.nativeEnum(AosAirport), zod.nativeEnum(FilterOption)])),
    unitAirportContact: zod.object({
        id: zod.number(),
        name: zod.string(),
        description: zod.string().optional().nullable(),
    }),
    name: zod
        .string({ required_error: translate('contact-list.form.unit-name.error') })
        .min(1, { message: translate('contact-list.form.unit-name.error-too-short') })
        .max(64, { message: translate('contact-list.form.unit-name.error-too-long') }),
    description: zod
        .string()
        .max(64, { message: translate('contact-list.form.unit-description.error-too-long') })
        .nullable()
        .optional(),
    phoneNumber: zod
        .string({ required_error: translate('contact-list.form.phone-number.error') })
        .min(3, translate('contact-list.form.phone-number.error-too-short'))
        .max(20, translate('contact-list.form.phone-number.error-too-long')),
    email: emailSchema,
    link: urlSchema,
    linkLabel: zod
        .string()
        .max(64, translate('contact-list.form.link-label.error-too-long'))
        .optional()
        .nullable(),
})

export const UnitAirportContactSchema = (existingNames: string[]) =>
    zod.object({
        id: zod.number().nullable().optional(),
        name: zod
            .string({ required_error: translate('contact-list.form.unit-name.error') })
            .min(1, { message: translate('contact-list.form.unit-name.error-too-short') })
            .max(64, { message: translate('contact-list.form.unit-name.error-too-long') })
            .refine(name => !existingNames.includes(name), {
                message: translate('contact-list.form.unit-name.error-duplicate'),
            }),
        description: zod
            .string()
            .max(64, {
                message: translate('contact-list.form.unit-description.error-too-long'),
            })
            .optional()
            .nullable(),
    })
