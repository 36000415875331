import { TaskDuration } from 'aos-services/src/services/tasks/types/TaskStatus'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { translate } from '../../../../../aos-helpers/src/helpers/translations/Translations'
import { Box } from '../../base/Box'
import { TimeInput } from './TimeInput'

export const TimePicker = () => {
    const form = useFormContext<TaskDuration>()

    return (
        <Box justify='space-between' row>
            <Controller
                name='hour'
                control={form.control}
                render={({ field, fieldState }) => (
                    <TimeInput
                        {...field}
                        {...fieldState}
                        max={999}
                        label={translate('time-picker.hours')}
                    />
                )}
            />
            <Controller
                name='minute'
                control={form.control}
                render={({ field, fieldState }) => (
                    <TimeInput
                        {...field}
                        {...fieldState}
                        max={55}
                        step={5}
                        label={translate('time-picker.minutes')}
                    />
                )}
            />
        </Box>
    )
}
