export const chartIconOffset = 16
export const chartIconSize = 32
export const chartIconBlockSize = 50

export const chartUnitOffset = 40

export const mainChartConditionsConfig = {
    margins: { top: 30, bottom: 50, left: 100, right: 120 },
}

export const mainChartWindCloudsConfig = {
    margins: { top: 30, bottom: 50, left: 100, right: 120 },
}

export const blockChartConfig = {
    margins: { top: 0, bottom: 0, left: 100, right: 120 },
}

export const iconChartConfig = {
    margins: { top: 10, bottom: 0, left: 100, right: 120 },
}
