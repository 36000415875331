import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { useInterval } from 'aos-ui-common/src/components/hooks/useInterval'
import { noop } from 'lodash'
import React, { FCWithChildren, useEffect, useState } from 'react'

import { FormButton, FormButtonProps } from './FormButton'

interface AsyncButtonProps extends FormButtonProps {
    isLoading?: boolean
    minimumLoadingTime?: boolean
    disableCheck?: boolean
    checkTime?: number
    onDone?(): void
}

const CHECK_TIME = 3000
const MINIMUM_LOADING_TIME = 750

export const OkButton: FCWithChildren<AsyncButtonProps> = props => {
    // tslint:disable:trailing-comma
    const {
        overlayIcon,
        disableCheck,
        onClick = noop,
        onDone = noop,
        isLoading = false,
        minimumLoadingTime = false,
        checkTime = CHECK_TIME,
        ...restProps
    } = props

    const [showCheck, setShowCheck] = useState(false)
    const [delayedLoading, setDelayedLoading] = useState(false)
    const startCheckInterval = showCheck && !delayedLoading && !isLoading

    useEffect(() => {
        if (minimumLoadingTime && isLoading === true) {
            setDelayedLoading(true)
        }
    }, [isLoading])

    useInterval(
        () => {
            setDelayedLoading(false)
        },
        minimumLoadingTime && delayedLoading ? MINIMUM_LOADING_TIME : null,
    )

    useInterval(
        () => {
            setShowCheck(false)
            onDone()
        },
        startCheckInterval ? checkTime : null,
    )

    const onClickWrapper = (ev: React.MouseEvent<any>) => {
        if (showCheck || isLoading) {
            return
        }

        if (!disableCheck) {
            setShowCheck(true)
        }

        onClick(ev)
    }

    return (
        <FormButton
            {...restProps}
            onClick={onClickWrapper}
            overlayIcon={showCheck ? SvgIcon.CheckWhite : overlayIcon}
            isLoading={isLoading || delayedLoading}
        />
    )
}
