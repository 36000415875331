import { isDefined } from 'aos-helpers/src/helpers/Function'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { getBarPositionAndSize } from 'aos-ui-common/src/components/chart/series/barSeries'
import {
    LinearStackBarSeriesAccessors,
    transformToStackData,
} from 'aos-ui-common/src/components/chart/series/linearStackBarSeries'
import { ChartScales } from 'aos-ui-common/src/components/chart/types/Chart'
import { LinearStackBarSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { SeriesPoint } from 'd3-shape'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

const stackBarSeriesWidth = 15

class LinearStackBarSeriesComponent<
    T,
    TX extends number | { valueOf(): number },
    Key extends string | number
> extends BaseChartComponent<ChartLinearStackBarSeriesProps<T, TX, Key>> {
    public render() {
        const { data, accessors } = this.props

        const { stackedSeriesData } = transformToStackData(data, accessors)

        return (
            <g className={cxp(this.props, 'series series--linear-stack-bar')}>
                {stackedSeriesData.map(this.renderBars)}
            </g>
        )
    }

    private renderBars = (data: SeriesPoint<T>[], seriesIndex: number) => {
        return data.map((d, i) => this.renderBar(d, i, seriesIndex))
    }

    private renderBar = (datum: SeriesPoint<T>, index: number, seriesIndex: number) => {
        const { size, margins, minYValue, seriesConfig, accessors, scales } = this.props

        const yVal = scales.yScale(minYValue + (datum[1] - Math.max(datum[0], minYValue))) || 0
        const { x, width } = getBarPositionAndSize(
            scales,
            accessors.xAccessor(datum.data),
            stackBarSeriesWidth,
        )
        const height = Math.ceil(size.height + margins.top - yVal)

        if (!isDefined(datum[1]) || !isDefined(datum[0]) || isNaN(datum[0]) || isNaN(datum[1])) {
            return null
        }
        const rect = (
            <rect
                key={index}
                x={x}
                y={scales.yScale(datum[1]) || 0}
                height={Math.max(height, 0)}
                width={width}
                fill={this.getAttributeValue(seriesConfig.colors[seriesIndex])}
            />
        )

        if (seriesConfig.tooltip) {
            return (
                <Tooltip
                    key={index}
                    body={seriesConfig.tooltip(datum.data)}
                    placement='top'
                    withArrow
                >
                    {rect}
                </Tooltip>
            )
        }

        return rect
    }
    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartLinearStackBarSeriesProps<T, TX, Key extends string | number>
    extends ClassNameProps {
    data: T[]
    seriesConfig: LinearStackBarSeriesConfig<T>
    accessors: LinearStackBarSeriesAccessors<T, TX, Key>
    scales: ChartScales<TX, number>
    minYValue: number
}

export const LinearStackBarSeries = withChartContext(LinearStackBarSeriesComponent)
