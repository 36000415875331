export enum FirebaseHealthItemType {
    FLIGHT_STATS = 'flightStats',
    FLIGHT_INFO = 'flightInfo',
    RUNWAYS = 'runways',
    CDM = 'cdm',
    PAX = 'pax',
    PAX_FORECAST = 'paxForecast',
    METAR = 'metar',
    NOTAM = 'notam',
}
