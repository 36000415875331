import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import {
    AosUnitTaskItem,
    AosUnitTaskItemDTO,
    AosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'

export const CREATE_UNIT_TASK_ITEM_SUCCESS = 'CHECKLIST_MANAGER/CREATE_UNIT_TASK_ITEM_SUCCESS'
export type CreateUnitTaskItemSuccessAction = PayloadAction<
    typeof CREATE_UNIT_TASK_ITEM_SUCCESS,
    AosUnitTaskItem
>
export const createUnitTaskItemSuccessAction = payloadActionCreator<
    CreateUnitTaskItemSuccessAction,
    AosUnitTaskItem
>(CREATE_UNIT_TASK_ITEM_SUCCESS)

export const SELECT_UNIT_TASK_TEMPLATE = 'CHECKLIST_MANAGER/SELECT_UNIT_TASK_TEMPLATE'
export type SelectUnitTaskTemplateAction = PayloadAction<
    typeof SELECT_UNIT_TASK_TEMPLATE,
    AosUnitTaskTemplate
>
export const selectUnitTaskTemplateAction = payloadActionCreator<
    SelectUnitTaskTemplateAction,
    AosSimpleTemplate
>(SELECT_UNIT_TASK_TEMPLATE)

export const LOAD_UNIT_TASK_TEMPLATES = 'CHECKLIST_MANAGER/LOAD_UNIT_TASK_TEMPLATES'
export type LoadUnitTaskTemplatesAction = Action<typeof LOAD_UNIT_TASK_TEMPLATES>
export const loadUnitTaskTemplatesAction =
    emptyActionCreator<LoadUnitTaskTemplatesAction>(LOAD_UNIT_TASK_TEMPLATES)

export const LOAD_UNIT_TASK_TEMPLATES_SUCCESS = 'CHECKLIST_MANAGER/LOAD_UNIT_TASK_TEMPLATES_SUCCESS'
export type LoadUnitTaskTemplatesSuccessAction = PayloadAction<
    typeof LOAD_UNIT_TASK_TEMPLATES_SUCCESS,
    AosUnitTaskTemplate[]
>
export const loadUnitTaskTemplatesSuccessAction = payloadActionCreator<
    LoadUnitTaskTemplatesSuccessAction,
    AosUnitTaskTemplate[]
>(LOAD_UNIT_TASK_TEMPLATES_SUCCESS)

export const LOAD_UNIT_TASK_TEMPLATE = 'CHECKLIST_MANAGER/LOAD_UNIT_TASK_TEMPLATE'
export type LoadUnitTaskTemplateAction = PayloadAction<typeof LOAD_UNIT_TASK_TEMPLATE, number>
export const loadUnitTaskTemplateAction = payloadActionCreator<LoadUnitTaskTemplateAction, number>(
    LOAD_UNIT_TASK_TEMPLATE,
)

export const LOAD_UNIT_TASK_TEMPLATE_SUCCESS = 'CHECKLIST_MANAGER/LOAD_UNIT_TASK_TEMPLATE_SUCCESS'
export type LoadUnitTaskTemplateSuccessAction = PayloadAction<
    typeof LOAD_UNIT_TASK_TEMPLATE_SUCCESS,
    AosUnitTaskTemplate
>
export const loadUnitTaskTemplateSuccessAction = payloadActionCreator<
    LoadUnitTaskTemplateSuccessAction,
    AosUnitTaskTemplate
>(LOAD_UNIT_TASK_TEMPLATE_SUCCESS)

export const SHOW_CREATE_UNIT_TASK_ITEM_MODAL = 'CHECKLIST_MANAGER/SHOW_CREATE_UNIT_TASK_ITEM_MODAL'
export type ShowCreateUnitTaskItemModalAction = PayloadAction<
    typeof SHOW_CREATE_UNIT_TASK_ITEM_MODAL,
    AosSeverity | undefined
>
export const showCreateUnitTaskItemModalAction = payloadActionCreator<
    ShowCreateUnitTaskItemModalAction,
    AosSeverity | undefined
>(SHOW_CREATE_UNIT_TASK_ITEM_MODAL)

export const SHOW_EDIT_UNIT_TASK_ITEM_MODAL = 'CHECKLIST_MANAGER/SHOW_EDIT_UNIT_TASK_ITEM_MODAL'
export type ShowEditUnitTaskItemModalAction = PayloadAction<
    typeof SHOW_EDIT_UNIT_TASK_ITEM_MODAL,
    AosUnitTaskItem
>
export const showEditUnitTaskTemplateModalAction = payloadActionCreator<
    ShowEditUnitTaskItemModalAction,
    AosUnitTaskItem
>(SHOW_EDIT_UNIT_TASK_ITEM_MODAL)

export const EXPORT_ALL_UNIT_TASK_TEMPLATES = 'CHECKLIST_MANAGER/EXPORT_ALL_UNIT_TASK_TEMPLATES'
export type ExportAllUnitTaskTemplatesAction = Action<typeof EXPORT_ALL_UNIT_TASK_TEMPLATES>
export const exportAllUnitTaskTemplatesAction =
    emptyActionCreator<ExportAllUnitTaskTemplatesAction>(EXPORT_ALL_UNIT_TASK_TEMPLATES)

export const EXPORT_SINGLE_UNIT_TASK_TEMPLATE = 'CHECKLIST_MANAGER/EXPORT_SINGLE_UNIT_TASK_TEMPLATE'
export type ExportSingleUnitTaskTemplateAction = PayloadAction<
    typeof EXPORT_SINGLE_UNIT_TASK_TEMPLATE,
    number
>
export const exportSingleUnitTaskTemplateAction = payloadActionCreator<
    ExportSingleUnitTaskTemplateAction,
    number
>(EXPORT_SINGLE_UNIT_TASK_TEMPLATE)

export const CREATE_UNIT_TASK_ITEM = 'CHECKLIST_MANAGER/CREATE_UNIT_TASK_ITEM'
export type CreateUnitTaskItemAction = PayloadAction<
    typeof CREATE_UNIT_TASK_ITEM,
    AosUnitTaskItemDTO
>
export const createUnitTaskItemAction = payloadActionCreator<
    CreateUnitTaskItemAction,
    AosUnitTaskItemDTO
>(CREATE_UNIT_TASK_ITEM)

export const UPDATE_UNIT_TASK_ITEM = 'CHECKLIST_MANAGER/UPDATE_UNIT_TASK_ITEM'
export type UpdateUnitTaskItemAction = PayloadAction<
    typeof UPDATE_UNIT_TASK_ITEM,
    AosUnitTaskItemDTO
>
export const updateUnitTaskItemAction = payloadActionCreator<
    UpdateUnitTaskItemAction,
    AosUnitTaskItemDTO
>(UPDATE_UNIT_TASK_ITEM)

export const HIDE_CREATE_UNIT_TASK_ITEM_MODAL = 'CHECKLIST_MANAGER/HIDE_CREATE_UNIT_TASK_ITEM_MODAL'
export type HideCreateUnitTaskItemModalAction = Action<typeof HIDE_CREATE_UNIT_TASK_ITEM_MODAL>
export const hideCreateUnitTaskItemModalAction =
    emptyActionCreator<HideCreateUnitTaskItemModalAction>(HIDE_CREATE_UNIT_TASK_ITEM_MODAL)

export const HIDE_EDIT_UNIT_TASK_ITEM_MODAL = 'CHECKLIST_MANAGER/HIDE_EDIT_UNIT_TASK_ITEM_MODAL'
export type HideEditUnitTaskItemModalAction = Action<typeof HIDE_EDIT_UNIT_TASK_ITEM_MODAL>
export const hideEditUnitTaskItemModalAction = emptyActionCreator<HideEditUnitTaskItemModalAction>(
    HIDE_EDIT_UNIT_TASK_ITEM_MODAL,
)

export type UnitTaskManagerActions =
    | LoadUnitTaskTemplatesSuccessAction
    | LoadUnitTaskTemplateSuccessAction
    | SelectUnitTaskTemplateAction
    | ShowCreateUnitTaskItemModalAction
    | HideCreateUnitTaskItemModalAction
    | ShowEditUnitTaskItemModalAction
    | HideEditUnitTaskItemModalAction
    | CreateUnitTaskItemSuccessAction
