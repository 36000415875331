import { SimpleHorizontalLegend } from 'aos-components/src/components/chart/SimpleHorizontalLegend'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    paxForecastArrivalDepartureColors,
    PaxForecastArrivalDepartureFilter,
    paxForecastArrivalDepartureTransparentColors,
    translatePaxArrivalDepartureFilterForChart,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface PaxForecastTimeOfDayChartLegendProps {
    arrivalDepartureFilter: PaxForecastArrivalDepartureFilter
}

export const PaxForecastTimeOfDayChartLegend: FC<PaxForecastTimeOfDayChartLegendProps> = props => {
    const mainColor = paxForecastArrivalDepartureColors[props.arrivalDepartureFilter]
    const transparentColor =
        paxForecastArrivalDepartureTransparentColors[props.arrivalDepartureFilter]
    return (
        <SimpleHorizontalLegend
            rightItems={[
                [
                    { mainColor },
                    translatePaxArrivalDepartureFilterForChart(props.arrivalDepartureFilter),
                ],
                [
                    {
                        mainColor,
                        secondaryColor: transparentColor,
                        variant: LegendDotVariant.FillAndStroke,
                    },
                    translate('dashboard.pax-forecast.chart.time-of-day.local'),
                ],
                [
                    {
                        mainColor,
                        secondaryColor: Color.Transparent,
                        variant: LegendDotVariant.FillAndStroke,
                    },
                    translate('dashboard.pax-forecast.chart.time-of-day.transfer'),
                ],
            ]}
            size={BlockSize.Tiny}
        />
    )
}
