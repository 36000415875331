export interface ErrorPayload {
    message: string
    status: string
    timestamp: string
    httpStatus: number
}

export class ResponseError extends Error {
    public handled: boolean = false
    constructor(public originalResponse: Response, public errorPayload?: ErrorPayload) {
        super(originalResponse.statusText || `${originalResponse.status}`)
        this.name = 'ResponseError'
    }
}
export function isResponseError(arg: any): arg is ResponseError {
    return arg.name === 'ResponseError'
}
