import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { LayerName } from 'aos-services/src/services/map/types/LayersVisibility'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { CheckboxList } from 'aos-ui/src/components/form/checkbox/CheckboxList'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { BimLayerCategory } from 'aos-ui-map/src/components/map/bim/BimLayerCategory'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

export interface LayersVisibilityCategoryProps {
    category?: BimLayerCategory
    bimMapLayerNames: BimLayerName[]
    bimMapLayersVisibility: string[]
    setBimLayersVisibility(v: string[]): void
}
export const LayersVisibilityCategory = ({
    category,
    bimMapLayersVisibility,
    setBimLayersVisibility,
    bimMapLayerNames,
}: LayersVisibilityCategoryProps) => {
    const [areaItems, setAreaItems] = useState(
        bimMapLayerNames.map(
            layerName =>
                [layerName, bimMapLayersVisibility.includes(layerName)] as CheckboxItem<string>,
        ),
    )

    const isAnyLayerVisible = () => areaItems.filter(i => i[1]).length > 0

    const [categoryChecked, setCategoryChecked] = useState<boolean>(isAnyLayerVisible())

    const getLayerNameLabel = useCallback((l: LayerName) => translate('bim.layer.name.' + l), [])

    const onChangeLayerVisibility = (layer: CheckboxItem<string>[]) => {
        const isAnyTrue = layer.some(i => i[1])
        if (isAnyTrue) {
            setCategoryChecked(true)
        }
        if (layer.every(i => !i[1])) {
            setCategoryChecked(false)
        }
        setAreaItems(layer)
    }

    const onChangeSingleLayerVisibility = (layer: CheckboxItem<React.ReactText>) => {
        if (bimMapLayersVisibility && layer[0] && layer[1]) {
            setBimLayersVisibility([...bimMapLayersVisibility, layer[0] as string])
        } else if (bimMapLayersVisibility && !layer[1]) {
            setBimLayersVisibility(bimMapLayersVisibility.filter(item => item !== layer[0]))
        }
    }

    const toggleAll = useCallback(
        (value: boolean) =>
            setAreaItems(prevAreaItems => prevAreaItems.map(item => [item[0], value])),
        [],
    )

    const onCategoryChange = (v: boolean) => {
        setCategoryChecked(v)
        if (v) {
            const layersNotInvolved = areaItems
                .filter(areaItem => !areaItem[1])
                .map(item => item[0])
            setBimLayersVisibility([...bimMapLayersVisibility, ...layersNotInvolved])
            toggleAll(true)
        } else {
            const withoutRemovedLayers = bimMapLayersVisibility.filter(
                (item: string) => !bimMapLayerNames.includes(item as BimLayerName),
            )
            setBimLayersVisibility(withoutRemovedLayers)
            toggleAll(false)
        }
    }

    const [openListToggle, setOpenListToggle] = useState<boolean>(false)

    const toggleCategorySubmenu = useCallback(() => {
        setOpenListToggle(prevState => !prevState)
    }, [setOpenListToggle])

    return (
        <>
            <Box paddingVertical={8} flex={1} row>
                <Checkbox
                    position='left'
                    checked={categoryChecked}
                    checkableLabel
                    variant={ThemeVariant.White}
                    fontSize={12}
                    onChange={v => onCategoryChange(v)}
                >
                    {translate('bim.layer.category.' + category)}
                </Checkbox>
                <IconBox>
                    {openListToggle && (
                        <IconButton
                            iconSize={BlockSize.Tiny}
                            svg={SvgIcon.Collapse}
                            iconVariant={IconVariant.GreyTxt}
                            onClick={toggleCategorySubmenu}
                        />
                    )}
                    {!openListToggle && (
                        <IconButton
                            iconSize={BlockSize.Small}
                            svg={SvgIcon.Expand}
                            iconVariant={IconVariant.GreyTxt}
                            onClick={toggleCategorySubmenu}
                        />
                    )}
                </IconBox>
            </Box>
            <Box bg={Color.Grey1} marginLeft={12}>
                {openListToggle && (
                    <CheckboxList
                        items={areaItems}
                        itemToLabel={getLayerNameLabel}
                        onChange={(layer: CheckboxItem<string>[]) => onChangeLayerVisibility(layer)}
                        onChangeItem={(v: CheckboxItem<string>) => onChangeSingleLayerVisibility(v)}
                    />
                )}
            </Box>
        </>
    )
}

const IconBox = styled(Box)`
    margin-left: auto;
`
