import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    MaintenanceContractorPayload,
    MaintenanceJobAssignmentPayload,
    MaintenanceTaskFormPayload,
    MaintenanceTaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/MaintenanceTaskFormPayload'
import { MaintenanceTaskType } from 'aos-services/src/services/tasks/types/task/MaintenanceTask'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { CleanableNumberInput } from 'aos-ui/src/components/form/input/CleanableNumberInput'
import { FormLabel } from 'aos-ui/src/components/form/labeled/FormLabel'
import { LabeledEditableList } from 'aos-ui/src/components/form/labeled/LabeledEditableList'
import { LabeledToggle } from 'aos-ui/src/components/form/labeled/LabeledToggle'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { MaintenanceJobAssignmentRenderer } from './partials/MaintenanceJobAssignmentRenderer'

interface MaintenanceTaskFormProps {
    form: FormValidation<MaintenanceTaskPayloadValidation>
    payload: MaintenanceTaskFormPayload
    metadata: TaskMetadata
    changeForm(t: Partial<MaintenanceTaskFormPayload>): void
}

export const MaintenanceTaskForm: FC<MaintenanceTaskFormProps> = ({
    form,
    payload,
    changeForm,
    metadata,
}) => {
    const [prevPayload, setPrevPayload] = useState(payload)

    useEffect(() => {
        setPrevPayload(payload)
    }, [payload])

    const changeTitle = useCallback(
        (assignments: MaintenanceJobAssignmentPayload[]) => {
            if (
                prevPayload?.assignments[0]?.brushesVehicle?.label !==
                    assignments[0]?.brushesVehicle?.label &&
                assignments[0]?.job !== undefined &&
                assignments[0]?.job?.jobType === MaintenanceTaskType.Brushes
            ) {
                return changeForm({
                    assignments,
                    title: assignments[0]?.brushesVehicle?.label,
                })
            } else if (
                prevPayload?.assignments[0]?.job === undefined ||
                prevPayload?.assignments[0]?.job?.label !== assignments[0]?.job?.label
            ) {
                return changeForm({
                    assignments,
                    title: assignments[0]?.job?.label,
                })
            } else if (assignments[0]?.brushesVehicle === undefined) {
                return changeForm({
                    assignments,
                    title: undefined,
                })
            }

            changeForm({
                assignments,
            })
        },
        [prevPayload],
    )

    const missionsError = form.error.assignments
        .map(
            i =>
                (i.brushesVehicle ||
                    i.brushesBrand ||
                    i.engineLastShiftHours ||
                    i.brushesLastShiftHours ||
                    i.totalHours ||
                    i.job) &&
                !form.pristine,
        )
        .some(Boolean)

    return (
        <>
            <LabeledEditableList
                keyPrefix='tasks.form.maintenance.assignments'
                value={payload.assignments}
                marginBottom={20}
                spacing={16}
                isError={missionsError}
                isRequired
                separated
                emptyItem={{ uuid: crypto.randomUUID() }}
                onChange={changeTitle}
                itemRenderer={({ item, onChangeItem }) => (
                    <MaintenanceJobAssignmentRenderer
                        maintenanceJobs={metadata.maintenanceJobs}
                        assignment={item}
                        metadata={metadata}
                        onChange={onChangeItem}
                    />
                )}
            />
            <LabeledToggle
                keyPrefix='tasks.form.maintenance.billable'
                marginBottom={12}
                value={payload.billable}
                onChange={billable => changeForm({ billable })}
            />
            <LabeledEditableList
                keyPrefix='tasks.form.maintenance.contractors'
                value={payload.contractors}
                emptyItem={{ uuid: crypto.randomUUID() }}
                spacing={16}
                separated
                marginBottom={12}
                showFirstValue={false}
                onChange={contractors => changeForm({ contractors })}
                validation={form.error.contractors.map(i => ({
                    isError:
                        i.name || i.contractTime || i.jobStart || i.jobEnd || i.numberOfWorkers,
                    errorMessage: translate('tasks.reporting.maintenance.contractors.error'),
                }))}
                itemRenderer={({ item, index, onChangeItem }) => (
                    <MaintenanceContractorRenderer
                        contractor={item}
                        key={index}
                        onChange={onChangeItem}
                    />
                )}
            />
        </>
    )
}

interface MaintenanceContractorRendererProps {
    contractor: MaintenanceContractorPayload
    onChange(v: MaintenanceContractorPayload): void
}

export const MaintenanceContractorRenderer: FC<MaintenanceContractorRendererProps> = ({
    contractor,
    onChange,
}) => (
    <Box row alignItems='flex-start' marginRight={16}>
        <Box flex={1}>
            <Box row paddingBottom={12}>
                <Box flex={1}>
                    <CleanableInput
                        placeholder={translate('tasks.form.contractor.name.placeholder')}
                        value={contractor.name}
                        onChangeText={name => onChange({ ...contractor, name })}
                    />
                </Box>
                <Box flex={1} marginLeft={20}>
                    <CleanableNumberInput
                        placeholder={translate(
                            'tasks.form.contractor.number-of-workers.placeholder',
                        )}
                        value={contractor.numberOfWorkers}
                        onChange={numberOfWorkers => onChange({ ...contractor, numberOfWorkers })}
                    />
                </Box>
            </Box>
            <Box row paddingBottom={12}>
                <Box flex={1}>
                    <FormLabel>{translate('tasks.form.contractor.contact-time.label')}</FormLabel>
                </Box>
                <Box flex={1} marginLeft={20}>
                    <DatetimeInput
                        placeholder={translate('tasks.form.contractor.contact-time.placeholder')}
                        value={contractor.contractTime}
                        onChange={contractTime => onChange({ ...contractor, contractTime })}
                    />
                </Box>
            </Box>
            <Box row paddingBottom={12}>
                <Box flex={1}>
                    <FormLabel>{translate('tasks.form.contractor.job-start.label')}</FormLabel>
                </Box>
                <Box flex={1} marginLeft={20}>
                    <DatetimeInput
                        placeholder={translate('tasks.form.contractor.job-start.placeholder')}
                        value={contractor.jobStart}
                        onChange={jobStart => onChange({ ...contractor, jobStart })}
                    />
                </Box>
            </Box>
            <Box row>
                <Box flex={1}>
                    <FormLabel>{translate('tasks.form.contractor.job-end.label')}</FormLabel>
                </Box>
                <Box flex={1} marginLeft={20}>
                    <DatetimeInput
                        placeholder={translate('tasks.form.contractor.job-end.placeholder')}
                        value={contractor.jobEnd}
                        onChange={jobEnd => onChange({ ...contractor, jobEnd })}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
)
