import { translate } from 'aos-helpers/src/helpers/translations/Translations'

export enum RestrictionResourceType {
    Stand = 'STAND',
    Gate = 'GATE',
    BusGate = 'BUS_GATE',
}

export const isAnyGateRestrictionType = (type: RestrictionResourceType) =>
    type === RestrictionResourceType.Gate || type === RestrictionResourceType.BusGate

const resourceNumberLabel: Record<RestrictionResourceType, string> = {
    [RestrictionResourceType.Stand]: 'restrictions.stand.number',
    [RestrictionResourceType.Gate]: 'restrictions.gate.number',
    [RestrictionResourceType.BusGate]: 'restrictions.bus-gate.number',
}

export const translateResourceNumberLabel = (
    type: RestrictionResourceType,
    number: string,
): string => translate(resourceNumberLabel[type], { number })
