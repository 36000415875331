import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { NumberValues } from '../../base/Box'
import { Text, TextSize } from '../../base/Text'
import { ThemeVariant } from '../../base/ThemeVariant'

interface FormLabelProps {
    variant?: ThemeVariant
    isError?: boolean
    isRequired?: boolean
    size?: TextSize
    paddingRight?: NumberValues
}

/* eslint-disable react/jsx-no-literals */
export const FormLabel: FCWithChildren<PropsWithChildren<FormLabelProps>> = ({
    isError,
    variant = ThemeVariant.White,
    isRequired,
    children,
    size = 14,
    paddingRight = 8,
}) => {
    const color = isError
        ? Color.Red
        : variant === ThemeVariant.Black
        ? Color.White
        : Color.UiBlack1
    return (
        <FormLabelText size={size} color={color} paddingRight={paddingRight}>
            {children}
            {isRequired && <sup>*</sup>}
        </FormLabelText>
    )
}

const FormLabelText = styled(Text)`
    min-height: 36px;
    display: flex;
    align-items: center;
    padding-right: ${p => p.paddingRight}px;
`
