import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { EventsSyncWrapper } from './EventsSyncWrapper'

interface EventsItemProps {
    noDataWarning: boolean
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const EventsItem: FC<PropsWithChildren<EventsItemProps>> = props => (
    <EventsSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.events.title')}
            description={translate('dashboard.events.description')}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </EventsSyncWrapper>
)
