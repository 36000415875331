import { downloadFileFromResponse } from 'aos-helpers/src/helpers/Download'
import { isUndefined, omit, omitBy } from 'lodash'

import { dateTimeFromOptional } from '../../../../aos-helpers/src/helpers/Time'
import { checklistTemplatesRestService } from '../../dataaccess/checklists/checklistTemplatesRestService'
import { AosChecklistItem } from './types/AosChecklistItem'
import { AosChecklistTemplate, AosSimpleTemplate } from './types/AosChecklistTemplate'
import { AosChecklistTemplateImpl } from './types/AosChecklistTemplateImpl'
import { AosChecklistTemplateStatus } from './types/AosChecklistTemplateStatus'
import { AosChecklistUnits } from './types/AosChecklistUnits'
import { ChecklistTemplateItemEditPayload } from './types/input/ChecklistTemplateItemEditPayload'
import {
    ChecklistTemplateEditPayload,
    ChecklistTemplatePayload,
} from './types/input/CheclistTemplatePayload'

class ChecklistTemplatesService {
    public getAll = (): Promise<AosSimpleTemplate[]> =>
        checklistTemplatesRestService.getAll().then(checklistTemplates =>
            checklistTemplates.map(checklistTemplate => ({
                ...checklistTemplate,
                createdAt: dateTimeFromOptional(checklistTemplate.createdAt),
                modifiedAt: dateTimeFromOptional(checklistTemplate.modifiedAt),
                status: checklistTemplate.status as AosChecklistTemplateStatus,
                unit: checklistTemplate.unit as AosChecklistUnits,
                reviewedAt: dateTimeFromOptional(checklistTemplate.reviewedAt),
                reviewedBy: checklistTemplate.reviewedBy,
            })),
        )

    public create = (checklistTemplate: ChecklistTemplatePayload): Promise<{}> =>
        checklistTemplatesRestService.create(checklistTemplate)

    public delete = (templateId: number): Promise<Response> =>
        checklistTemplatesRestService.delete(templateId)

    public update = (checklistTemplate: ChecklistTemplateEditPayload): Promise<Response> =>
        checklistTemplatesRestService.update(checklistTemplate)

    public getOne = (checklistTemplateId: number): Promise<AosChecklistTemplate> =>
        checklistTemplatesRestService
            .getOne(checklistTemplateId)
            .then(template => new AosChecklistTemplateImpl(template))

    public getForEventManager = async (): Promise<AosSimpleTemplate[]> => {
        const templates = await this.getAll()
        return templates.filter(
            t => (t as AosChecklistTemplate).status === AosChecklistTemplateStatus.REVIEWED,
        )
    }

    public reorder = (checklistTemplates: AosSimpleTemplate[]): Promise<{}> =>
        checklistTemplatesRestService.reorder(
            checklistTemplates.map((ct, index) => ({
                id: ct.id,
                sequenceNumber: index,
            })),
        )

    public reorderItems = (checklistTemplateId: number, tasks: AosChecklistItem[]): Promise<{}> =>
        checklistTemplatesRestService.reorderItems(
            checklistTemplateId,
            tasks.map((task, index) => ({
                id: task.id,
                sequenceNumber: index,
            })),
        )

    public editTaskOrDescription = (payload: ChecklistTemplateItemEditPayload): Promise<{}> =>
        checklistTemplatesRestService.updateItem(
            payload.checklistTemplateId,
            payload.item.id,
            this.clearPayload(payload.item),
        )

    public editMessageTemplate = (payload: ChecklistTemplateItemEditPayload): Promise<{}> => {
        if (payload.item.id) {
            return checklistTemplatesRestService.updateItem(
                payload.checklistTemplateId,
                payload.item.id,
                this.clearPayload(payload.item),
            )
        } else {
            return this.createItem(payload)
        }
    }

    public deleteItem = (checklistTemplateId: number, itemId: number): Promise<{}> =>
        checklistTemplatesRestService.deleteItem(checklistTemplateId, itemId)

    public createItem = (payload: ChecklistTemplateItemEditPayload): Promise<{}> =>
        checklistTemplatesRestService.createItem(
            payload.checklistTemplateId,
            this.clearPayload(payload.item),
        )

    public exportAll = (): Promise<void> =>
        checklistTemplatesRestService.exportAll().then(downloadFileFromResponse)

    public exportSingle = (id: number): Promise<void> =>
        checklistTemplatesRestService.exportSingle(id).then(downloadFileFromResponse)

    public markReviewed = (id: number): Promise<{}> =>
        checklistTemplatesRestService.markReviewed(id)

    private clearPayload = (payload: AosChecklistItem): any =>
        omit(omitBy(payload, isUndefined), 'id')
}

export const checklistTemplatesService = new ChecklistTemplatesService()
