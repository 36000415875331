export enum FeedChannelsFilter {
    Finavia = 'Finavia',
    Airport_FRA = 'Airport_FRA',
    Ansfinland = 'ANSfinland',
    Arlanda = 'Arlanda',
    Aypassenger = 'AYPassenger',
    Brusselsairport = 'BrusselsAirport',
    Cphairports = 'CPHAirports',
    Easyjet = 'easyJet',
    Eurocontrol = 'Eurocontrol',
    Finnair = 'Finnair',
    Flightradar24 = 'Flightradar24',
    Fly_norwegian = 'Fly_Norwegian',
    GBT_Finland = 'amexgbtfinland',
    Heathrowairport = 'HeathrowAirport',
    HelsinkiAirport = 'HelsinkiAirport',
    Hiaqatar = 'HIAQatar',
    Icelandair = 'Icelandair',
    Iupoliisi = 'IUPoliisi',
    Jfkairport = 'JFKairport',
    Kefairport = 'Kefairport',
    Klm = 'KLM',
    Lentopostifi = 'Lentopostifi',
    Lufthansa = 'Lufthansa',
    Meteorologist = 'Meteorologist',
    MUC_Airport = 'MUC_Airport',
    Parisaeroport = 'ParisAeroport',
    Qatarairways = 'QatarAirways',
    Riga_airport = 'Riga_airport',
    Sas = 'SAS',
    Schiphol = 'Schiphol',
    Trafi_finland = 'Trafi_Finland',
    Turkishairlines = 'TurkishAirlines',
}
