import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import {
    jumpToDateAction,
    jumpToNowAction,
    zoomInAction,
    zoomOutAction,
} from 'aos-services/src/core/timeline/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconBoxButton } from 'aos-ui/src/components/buttons/IconBoxButton'
import { CurrentTime } from 'aos-ui/src/components/CurrentTime'
import { JumpToDate } from 'aos-ui/src/components/form/datetime/JumpToDate'
import { HeaderButton } from 'aos-ui/src/components/header/HeaderButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    openFiltersModalAction,
    standsGatesTimelineTimelineParentAction,
} from '../../../core/standsGatesTimeline/actions'

interface StandsGatesTimelineHeaderProps
    extends StandsGatesTimelineHeaderStateProps,
        StandsGatesTimelineHeaderDispatchProps {}

export const StandsGatesTimelineHeaderClass: FC<StandsGatesTimelineHeaderProps> = props => {
    const { jumpToNow, onSelectDate, zoomOut, zoomIn, openFiltersModal } = props

    const selectJumpDate = (date: DateTime | undefined) => date && onSelectDate(date)

    return (
        <Box shrink={0} row>
            <JumpToDate marginRight={30} onChange={selectJumpDate} />
            <CurrentTime marginRight={30} onClick={jumpToNow} date={props.currentTime} />
            <IconBoxButton id='zoom-in' svg={SvgIcon.ZoomIn} marginRight={8} onClick={zoomIn} />
            <IconBoxButton id='zoom-out' svg={SvgIcon.ZoomOut} onClick={zoomOut} marginRight={16} />
            <HeaderButton
                onClick={openFiltersModal}
                text={translate('flight-information.toggle-filters')}
                icon={SvgIcon.SearchFilters}
            />
        </Box>
    )
}

interface StandsGatesTimelineHeaderStateProps {
    currentTime: DateTime
}

interface StandsGatesTimelineHeaderDispatchProps {
    onSelectDate(date: DateTime): void
    jumpToNow(): void
    zoomIn(): void
    zoomOut(): void
    openFiltersModal: typeof openFiltersModalAction
}

export const StandsGatesTimelineHeader = connect<
    StandsGatesTimelineHeaderStateProps,
    StandsGatesTimelineHeaderDispatchProps
>(
    createStructuredSelector({
        currentTime: currentTimeSelector,
    }),
    {
        onSelectDate: (date: DateTime) =>
            standsGatesTimelineTimelineParentAction(jumpToDateAction(date)),
        jumpToNow: () => standsGatesTimelineTimelineParentAction(jumpToNowAction()),
        zoomIn: () => standsGatesTimelineTimelineParentAction(zoomInAction()),
        zoomOut: () => standsGatesTimelineTimelineParentAction(zoomOutAction()),
        openFiltersModal: openFiltersModalAction,
    },
)(StandsGatesTimelineHeaderClass)
