import { EnumValues } from 'enum-values'
import { createSelector } from 'reselect'

import { CdmMetric } from '../../../services/airportStatus/cdm/types/CdmMetric'
import { CdmMetricSubType } from '../../../services/airportStatus/cdm/types/CdmMetricSubType'
import { CdmMetricType } from '../../../services/airportStatus/cdm/types/CdmMetricType'
import { CdmRange } from '../../../services/airportStatus/cdm/types/CdmRange'
import { CdmFilters } from '../../../services/statusDashboard/types/filters/CdmFilters'
import { ItemStateAware, StatusDashboardStateAware } from '../flights/selectors/common'
import { StatusDashboardDataStateAware } from '../state'

interface OwnProps extends ItemStateAware<CdmFilters> {
    metricType: CdmMetricType
}

export const cdmMetricCurrentSubTypeSelector = (
    _: CdmMetric,
    ownProps: OwnProps,
): CdmMetricSubType => {
    const { cdmMetricSubType } = ownProps.itemState
    return cdmMetricSubType[ownProps.metricType]
}

export const cdmMetricsSelector = (
    state: StatusDashboardDataStateAware,
    { metricType }: OwnProps,
): CdmMetric => state.statusDashboardData.cdm[metricType]

export const cdmMetricTypeOwnPropsSelector = (_state: never, { metricType }: OwnProps) => metricType

export const cdmMetricRangesSelector = (
    state: StatusDashboardStateAware,
    { metricType }: OwnProps,
): CdmRange[] => state.statusDashboard.ranges.cdm[metricType]

export const makeCdmMetricSubTypesSelector = () =>
    createSelector(
        cdmMetricsSelector,
        (metric): CdmMetricSubType[] =>
            EnumValues.getValues<string>(CdmMetricSubType).filter(
                type => type in metric,
            ) as CdmMetricSubType[],
    )

export const cdmValueMerger = (
    metric: CdmMetric,
    subType: CdmMetricSubType,
): number | undefined => {
    const metricData = metric[subType]

    if (!metricData) {
        return
    }

    return metricData.value
}

export const cdmValueSelector = createSelector(
    cdmMetricsSelector,
    cdmMetricCurrentSubTypeSelector,
    cdmValueMerger,
)

export const tileMetricType = CdmMetricType.TOBT_vs_TSAT

export const cdmTileMetricsSelector = (state: StatusDashboardDataStateAware): CdmMetric =>
    state.statusDashboardData.cdm[tileMetricType]

export const cdmTileMetricRangesSelector = (state: StatusDashboardStateAware): CdmRange[] =>
    state.statusDashboard.ranges.cdm[tileMetricType]

export const cdmTileValueSelector = createSelector(
    cdmTileMetricsSelector,
    () => CdmMetricSubType.Last30m,
    cdmValueMerger,
)

export const tobtDataSelector = (
    state: StatusDashboardDataStateAware & StatusDashboardStateAware,
) => ({
    hourTobt: state.statusDashboardData.tobt.hourTobt,
    airlineTobt: state.statusDashboardData.tobt.airlineTobt,
    handlerTobs: state.statusDashboardData.tobt.handlerTobs,
    tobtRanges: state.statusDashboard.ranges.tobt,
})

export const tobtWebDataSelector = () =>
    createSelector(tobtDataSelector, tobtData => ({
        hourTobt: tobtData.hourTobt,
        airlineTobt: tobtData.airlineTobt,
        handlerTobs: tobtData.handlerTobs,
        tobtRanges: tobtData.tobtRanges,
    }))
