import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { HeaderIconVariant, HeaderLabel } from 'aos-ui/src/components/ui/HeaderLabel'
import React, { FC } from 'react'

export const TestingLabel: FC<MarginBoxProps> = props => (
    <HeaderLabel variant={HeaderIconVariant.Yellow} {...props}>
        {translate('dashboard.label.testing')}
    </HeaderLabel>
)
