export enum TravelServicesSubTheme {
    ArrivalServices = 40,
    SecurityCheck = 28,
    CheckInKiosk = 8,
    CheckInDesks = 7,
    BaggageClaim = 2,
    Customs = 11,
    PassportControl = 23,
}

export interface TravelServicesProperties {
    Pictogram_category: string
    Main_theme: number
    Sub_theme: TravelServicesSubTheme
}
