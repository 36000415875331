import { calculateDomainForPositiveMax, Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { sum } from 'aos-helpers/src/helpers/Number'
import { getYear } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { allAirports } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { AnimalSpecie } from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import { AnimalsOccurrence } from 'aos-services/src/services/tasksReporting/types/AnimalsOccurrence'
import { emptyAnimalsReportData } from 'aos-services/src/services/tasksReporting/types/AnimalsReportData'
import {
    Dictionary,
    groupBy,
    keyBy,
    mapValues,
    max,
    range,
    round,
    sortBy,
    toPairs,
    uniq,
    values,
    xor,
} from 'lodash'
import { createSelector } from 'reselect'

import { TaskManagerStateAware } from '../../state'
import { ReportsFilters } from '../state'
import { reportFiltersSelector } from './filters'
import {
    ChartMultiPoint,
    ChartMultiSelectorState,
    ReportSummarySelectorState,
} from './types/ChartSelectorState'

export interface AnimalsSelectorState {
    data: AnimalsOccurrence[]
    filters: ReportsFilters
    animals: AnimalSpecie[]
    chartState: ChartMultiSelectorState
    maxYearRange: number[]
    totalSummary: ReportSummarySelectorState
    yearsSummary: ReportSummarySelectorState[]
}

const animalsReportDataSelector = (state: TaskManagerStateAware) =>
    state.taskManager.animalsReport || emptyAnimalsReportData

export const animalsReportSelector = createSelector(
    animalsReportDataSelector,
    reportFiltersSelector,
    (report, filters): AnimalsSelectorState => {
        const maxYearRange = yearRangeForData(report.data)
        const filteredData = filterAnimals(report.data, filters)
        const animals = animalsForData(report.data, report.types)
        const totalSummary = getTotalSummary(filteredData, filters.yearFrom, filters.yearTo)
        const yearsSummary = range(filters.yearFrom, filters.yearTo).map(year =>
            getSummaryForYear(filteredData, year),
        )

        const chartState =
            filters.airport === FilterOptionAll.All
                ? chartStateForAllAirport(filteredData, filters)
                : chartStateForAirport(filteredData, animals, filters)

        return {
            data: filteredData,
            animals: report.types,
            filters,
            maxYearRange,
            chartState,
            totalSummary,
            yearsSummary,
        }
    },
)

const chartStateForAllAirport = (
    data: AnimalsOccurrence[],
    filters: ReportsFilters,
): ChartMultiSelectorState => {
    const dataKeys = allAirports
    const dataSubKeys = filtersYearsRangeKeys(filters)
    const dataByAirport = groupBy(data, item => item.airport)

    const groupedData = toPairs(dataByAirport).map(
        ([airport, items]): ChartMultiPoint => ({
            key: airport,
            data: groupByYear(items),
        }),
    )

    return {
        dataKeys,
        dataSubKeys,
        data: groupedData,
        yDomain: domainForPoints(groupedData),
    }
}

interface ChartMultiPointWithSum extends ChartMultiPoint {
    sum: number
}

const MAX_DATA_KEYS = 20

const chartStateForAirport = (
    data: AnimalsOccurrence[],
    animals: AnimalSpecie[],
    filters: ReportsFilters,
): ChartMultiSelectorState => {
    const animalsMap = keyBy(animals, 'id')
    const yearsRange = filtersYearsRangeKeys(filters)
    const dataByAnimals = groupBy(data, item => item.animalId)

    let groupedData = toPairs(dataByAnimals).map(
        ([id, items]): ChartMultiPointWithSum => ({
            key: animalsMap[id].label,
            data: groupByYear(items),
            sum: sumAmount(items),
        }),
    )

    if (groupedData.length > MAX_DATA_KEYS) {
        const sortedData = sortBy(groupedData, point => point.sum).reverse()
        const min = sortedData[MAX_DATA_KEYS].sum
        const currentData = groupedData.filter(point => point.sum > min)
        const omittedData = xor(groupedData, currentData)

        groupedData = currentData
        groupedData.push({
            key: translate('reports.omitted-chart-items', { count: omittedData.length }),
            data: sumPointsByYear(omittedData),
            sum: NaN,
        })
    }

    const animalLabels = groupedData.map(a => a.key)

    return {
        dataKeys: animalLabels,
        dataSubKeys: yearsRange,
        data: groupedData,
        yDomain: domainForPoints(groupedData),
    }
}

const filterAnimals = (data: AnimalsOccurrence[], filters: ReportsFilters): AnimalsOccurrence[] => {
    return data
        .filter(({ airport }) =>
            filters.airport !== FilterOptionAll.All ? airport === filters.airport : true,
        )
        .filter(({ animalId }) => {
            if (filters.animal !== FilterOptionAll.All) {
                return filters.animal.id === animalId
            }
            return true
        })
        .filter(({ monthTime }) => {
            const year = getYear(monthTime)
            return year >= filters.yearFrom && year <= filters.yearTo
        })
}

const sumAmount = (data: AnimalsOccurrence[]): number => {
    return round(sum(data.map(item => item.amount)))
}

export const filtersYearsRangeKeys = (filters: ReportsFilters) => {
    return range(filters.yearFrom, filters.yearTo + 1).map(year => year.toString())
}

const domainForPoints = (items: ChartMultiPoint[]): Domain<number> => {
    const allValues = items.flatMap(v => values(v.data))
    const maxValue = max(allValues) || 0
    return calculateDomainForPositiveMax(maxValue)
}

const groupByYear = (data: AnimalsOccurrence[]): Record<string, number> =>
    mapValues(
        groupBy(data, item => getYear(item.monthTime)),
        data => sumAmount(data),
    )

const yearRangeForData = (data: AnimalsOccurrence[]): number[] => {
    return uniq(data.map(animal => getYear(animal.monthTime)))
}

const getTotalSummary = (
    data: AnimalsOccurrence[],
    yearFrom: number,
    yearTo: number,
): ReportSummarySelectorState => ({
    value: sumAmount(data),
    yearRange: [yearFrom, yearTo],
})

const getSummaryForYear = (
    data: AnimalsOccurrence[],
    year: number,
): ReportSummarySelectorState => ({
    value: sumAmount(data.filter(({ monthTime }) => getYear(monthTime) === year)),
    yearRange: [year],
})

const animalsForData = (data: AnimalsOccurrence[], animals: AnimalSpecie[]): AnimalSpecie[] => {
    const uniqIds = uniq(data.map(animal => animal.animalId))
    return animals.filter(animal => uniqIds.includes(animal.id))
}

const sumPointsByYear = (data: ChartMultiPointWithSum[]): Record<string, number> =>
    data
        .flatMap(point => toPairs(point.data))
        .reduce((yearsObj, [year, sum]) => {
            if (year in yearsObj) {
                yearsObj[year] += sum
            } else {
                yearsObj[year] = sum
            }
            return yearsObj
        }, {} as Dictionary<number>)
