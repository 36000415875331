import {
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { takeEveryChildAction, takeLatestChildAction } from 'aos-helpers/src/helpers/Saga'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isApocSelector } from 'aos-services/src/core/auth/state'
import {
    SyncChecklistAction,
    syncChecklistAction,
    TOGGLE_EVENT_CHECKLIST_TASK,
    ToggleEventChecklistTask,
    UPDATE_EVENT_ITEM,
    UpdateEventItemAction,
} from 'aos-services/src/core/events/actions'
import {
    baseEventSagas,
    syncChecklistSaga,
    syncEventLayersSaga,
} from 'aos-services/src/core/events/saga'
import { firebaseEventsRepository } from 'aos-services/src/dataaccess/firebaseEvents/firebaseEventsRepository'
import { AosFirebaseEventDto } from 'aos-services/src/dataaccess/firebaseEvents/types/AosFirebaseEventDto'
import { taskUnitService } from 'aos-services/src/services/checklists/taskUnitService'
import { UnitTaskStatus } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { firebaseEventsService } from 'aos-services/src/services/firebaseEvents/firebaseEventsService'
import { infoToast } from 'aos-ui/src/components/toast/ToastMessages'
import { push } from 'redux-first-history'
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { SUBMIT_CHECKLISTS } from '../checklistSelectionModal/actions'
import { assureCommonUserGroup } from '../commonFrontend/sagas'
import { previewEventAction } from '../eventPreview/actions'
import { Link } from '../Links'
import {
    ADD_FEED_OUT,
    AddFeedOutAction,
    addFeedOutRequestAction,
    addFeedOutSuccessAction,
    CLOSE_EVENT_PARENT,
    DELETE_EVENT,
    DeleteEventAction,
    LOAD_EVENT_IN_EVENT_EDITOR,
    LOAD_FIREBASE_EVENT_SUCCESS,
    LoadEventInEventEditorAction,
    loadEventSuccessAction,
    LoadFirebaseEventSuccessAction,
    loadFirebaseEventSuccessAction,
    OPEN_EVENT,
    OpenEventAction,
    refreshEventAction,
    REOPEN_EVENT,
    ReopenEventAction,
    SET_ADDITIONAL_NOTE_PARENT,
    setAdditionalNoteActions,
    setFirebaseEventSuccessAction,
    UNDELETE_EVENT,
    UPDATE_INVITATION,
    UpdateInvitationAction,
} from './actions'
import { iWillArriveSagas } from './iWillArrive/sagas'
import { selectUserGroupSagas } from './selectUserGroup/sagas'
import { compareEventStsValues, currentEventEditorId } from './state'

export const openEventInEventEditor = (id: number) => push(`${Link.EventManager}/${id}`)

export function* reloadEvent(id: number, refresh?: boolean) {
    try {
        const event: AosEvent = yield call(eventsService.loadEvent, id)
        yield put(refresh ? refreshEventAction(event) : loadEventSuccessAction(event))
    } catch (e) {
        yield put(loadEventSuccessAction(undefined))
        throw e
    }
}

export function* loadEventInEventEditor(id: number) {
    const stateId: number = yield select(currentEventEditorId)
    const isApoc: boolean = yield select(isApocSelector)
    if (isApoc) {
        yield assureCommonUserGroup()
    }
    if (id !== stateId) {
        yield put(loadEventSuccessAction(undefined))
    }
    yield fork(syncFirebaseEvent, id)
    yield reloadEvent(id)
}

function* loadEventInEventEditorSaga() {
    yield takeEvery<LoadEventInEventEditorAction>(
        LOAD_EVENT_IN_EVENT_EDITOR,
        function* (action: LoadEventInEventEditorAction) {
            yield loadEventInEventEditor(action.payload)
        },
    )
}

function* openEventSaga() {
    yield takeEvery<OpenEventAction>(OPEN_EVENT, function* (action: OpenEventAction) {
        const isApoc: boolean = yield select(isApocSelector)
        const event: AosEvent = yield call(eventsService.loadEvent, action.payload)
        if (isApoc) {
            yield assureCommonUserGroup()
        }
        if (isApoc || event.hasPrivateChannel) {
            yield put(loadEventSuccessAction(event))
            yield fork(syncFirebaseEvent, event.id)
            yield fork(syncChecklistSaga, event.id, reloadEvent)
            yield fork(syncEventLayersSaga, event.id, reloadEvent)
            yield put(openEventInEventEditor(event.id))
        } else {
            yield put(previewEventAction({ eventId: event.id, openOnMap: false }))
        }
    })
}

function* submitCloseEventSaga() {
    yield takeEveryChildAction<SubmitAction>(
        CLOSE_EVENT_PARENT,
        SUBMIT,
        function* (action: SubmitAction) {
            const { eventId } = action.payload.payload
            const endTime = action.payload.value
            yield call(eventsService.closeEvent, eventId, endTime)
        },
    )
}

function* addFeedOutSaga() {
    yield takeLatest<AddFeedOutAction>(ADD_FEED_OUT, function* (action: AddFeedOutAction) {
        yield put(addFeedOutRequestAction())
        yield call(eventsService.addFeedOut, action.payload[0], action.payload[1])
        yield put(addFeedOutSuccessAction())
    })
}

function* reloadEventOnFirebaseEventUpdateSaga() {
    yield takeEvery<LoadFirebaseEventSuccessAction>(
        LOAD_FIREBASE_EVENT_SUCCESS,
        function* (action: LoadFirebaseEventSuccessAction) {
            const event = action.payload
            if (event) {
                const shouldEventUpdate: boolean = yield select(
                    compareEventStsValues,
                    event.sendToSync,
                )
                yield put(setFirebaseEventSuccessAction(event))
                if (shouldEventUpdate) {
                    yield reloadEvent(event.id, true)
                }
            }
        },
    )
}

function* toggleEventChecklistTaskSaga() {
    function* toggleEventChecklistTask(action: ToggleEventChecklistTask) {
        yield call(eventsService.updateEventChecklistTask, action.payload)
        yield put(
            syncChecklistAction({
                eventId: action.payload.eventId,
                checklistId: action.payload.checklistId,
            }),
        )
    }

    yield takeEvery<ToggleEventChecklistTask>(TOGGLE_EVENT_CHECKLIST_TASK, toggleEventChecklistTask)
}

function* reopenEventSaga() {
    yield takeEvery(REOPEN_EVENT, function* (action: ReopenEventAction) {
        const id = action.payload
        yield call(eventsService.reopenEvent, id)
    })
}

function* deleteEventSaga() {
    yield takeEvery(DELETE_EVENT, function* (action: DeleteEventAction) {
        const id = action.payload
        yield call(eventsService.deleteEvent, id)
    })
}

function* undeleteEventSaga() {
    yield takeEvery(UNDELETE_EVENT, function* (action: DeleteEventAction) {
        const id = action.payload
        yield call(eventsService.undeleteEvent, id)
    })
}

function* syncFirebaseEvent(eventId: number) {
    yield firebaseService.restartListener('eventEditorEvent', function* () {
        yield syncValue(
            firebaseEventsRepository.eventRef(eventId),
            (t: [string, AosFirebaseEventDto]) =>
                loadFirebaseEventSuccessAction(
                    firebaseEventsService.firebaseEventDtoToFirebaseEvent(t),
                ),
            false,
        )
    })
}

function* updateInvitationSaga() {
    yield takeEvery<UpdateInvitationAction>(
        UPDATE_INVITATION,
        function* (action: UpdateInvitationAction) {
            yield call(firebaseEventsService.updateInvitation, action.payload)
        },
    )
}

function* submitChecklistSaga() {
    yield takeEvery<SyncChecklistAction>(SUBMIT_CHECKLISTS, function* (action) {
        yield call(
            firebaseEventsService.updateChecklist,
            action.payload.eventId,
            action.payload.checklistId,
        )
        infoToast(translate('event.messages.checklistUpdated'))
    })
}

function* updateEventUnitTask() {
    yield takeEvery<UpdateEventItemAction>(UPDATE_EVENT_ITEM, function* (action) {
        if (action.payload.status === UnitTaskStatus.DONE && action.payload.additionalNote) {
            yield put(
                setAdditionalNoteActions.showModalWithValueAction({
                    payload: action.payload.additionalNote,
                    value: action.payload.additionalNote,
                }),
            )
            yield takeLatestChildAction<SubmitAction>(
                SET_ADDITIONAL_NOTE_PARENT,
                SUBMIT,
                function* (result) {
                    yield taskUnitService.updateTaskItem({
                        ...action.payload,
                        additionalNote: result.payload.value,
                    })
                },
                true,
            )
        } else {
            yield taskUnitService.updateTaskItem(action.payload)
        }
    })
}

export const eventEditorSagas = [
    loadEventInEventEditorSaga,
    openEventSaga,
    submitCloseEventSaga,
    addFeedOutSaga,
    toggleEventChecklistTaskSaga,
    updateInvitationSaga,
    reloadEventOnFirebaseEventUpdateSaga,
    reopenEventSaga,
    deleteEventSaga,
    undeleteEventSaga,
    submitChecklistSaga,
    updateEventUnitTask,
    ...baseEventSagas,
    ...selectUserGroupSagas,
    ...iWillArriveSagas,
]
