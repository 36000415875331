import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { Rotated } from 'aos-ui/src/components/base/Rotated'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'

import { IconVariant } from '../svg/Icon'
import { IconButton } from './IconButton'

export enum CollapsibleButtonVariant {
    RightBottom,
    TopBottom,
}

export interface CollapsibleButtonProps extends ButtonProps {
    isCollapsed: boolean
    variant: CollapsibleButtonVariant
    iconVariant?: IconVariant
    iconSize?: BlockSize
    svg?: Svg
}

export const CollapsibleButton: FCWithChildren<CollapsibleButtonProps> = ({
    onClick,
    iconSize = BlockSize.Std,
    iconVariant = IconVariant.BlackGrey,
    svg = SvgIcon.Chevron,
    isCollapsed,
    variant,
}) => {
    const fromTo: Record<CollapsibleButtonVariant, [number, number]> = {
        [CollapsibleButtonVariant.TopBottom]: [0, 180],
        [CollapsibleButtonVariant.RightBottom]: [-90, 0],
    }
    const index = isCollapsed ? 0 : 1
    return (
        <Rotated angle={fromTo[variant][index]}>
            <IconButton
                onClick={onClick}
                iconSize={iconSize}
                iconVariant={iconVariant}
                svg={svg}
                block
            />
        </Rotated>
    )
}
