import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FlightPoint } from 'aos-services/src/services/airportStatus/flights/types/FlightsInfo'
import React, { FC } from 'react'

interface PunctualityTooltipProps {
    point: FlightPoint
}

export const PunctualityTooltip: FC<PunctualityTooltipProps> = props => {
    const { point } = props
    const getLabel = () => {
        if (point.flightsCount > 0) {
            return `${translate('dashboard.punctuality.label.flights-on-time')} (${
                point.flightsCount
            }): ${point.flightNumbers}`
        } else if (point.value === 100) {
            return translate('dashboard.punctuality.label.no-flights')
        } else {
            return translate('dashboard.punctuality.label.no-flights-on-time')
        }
    }

    return <p>{getLabel()}</p>
}
