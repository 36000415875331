import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosEventChecklistDto } from '../../../dataaccess/events/types/AosEventChecklistDto'
import { AosEventChecklist } from './AosEventChecklist'
import { AosEventChecklistItemImpl } from './AosEventChecklistItemImpl'

export class AosEventChecklistImpl
    extends WrapperObject<AosEventChecklistDto>
    implements AosEventChecklist {
    constructor(value: AosEventChecklistDto) {
        super(value)
    }

    public get id() {
        return this.value.id
    }

    public get sourceId() {
        return this.value.templateId
    }

    public get items() {
        return this.value.items ?
            this.value.items.map(checklistItem => new AosEventChecklistItemImpl(checklistItem))
            : []
    }

    public get name() {
        return this.value.name
    }

    public get tag() {
        return this.value.tag
    }
}
