import { userProfileService } from 'aos-services/src/services/users/userProfileService'
import { put, takeEvery } from 'redux-saga/effects'

import {
    REMOVE_ACCOUNT,
    RemoveAccountAction,
    removeAccountError,
    removeAccountSuccess,
} from './actions'

function* removeAccountSaga() {
    yield takeEvery<RemoveAccountAction>(REMOVE_ACCOUNT, function* (action) {
        try {
            yield userProfileService.deleteAccount(action.payload)
            yield put(removeAccountSuccess())
        } catch {
            yield put(removeAccountError())
        }
    })
}

export const removeAccountSagas = [removeAccountSaga]
