import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import {
    getLvpProbabilityTypeForWeatherProbabilityValue,
    getWeatherProbabilityTypeForWeatherProbabilityValue,
    WeatherProbabilityType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import {
    TooltipColorList,
    TooltipColorListItem,
} from 'aos-ui/src/components/tooltip/TooltipColorsOverlay'

interface ProbabilityLabel {
    color: string
    text: string
}

export enum ProbabilityColors {
    Small = '#4DC8C9',
    Normal = '#A6D46D',
    Big = '#F8BA00',
    Extreme = '#DA58AB',
}

const probabilityLabels: Record<WeatherProbabilityType, ProbabilityLabel | null> = {
    [WeatherProbabilityType.None]: null,
    [WeatherProbabilityType.Small]: {
        color: ProbabilityColors.Small,
        text: translate('dashboard.weather.probability.unlikely'),
    },
    [WeatherProbabilityType.Normal]: {
        color: ProbabilityColors.Normal,
        text: translate('dashboard.weather.probability.possible'),
    },
    [WeatherProbabilityType.Big]: {
        color: ProbabilityColors.Big,
        text: translate('dashboard.weather.probability.likely'),
    },
    [WeatherProbabilityType.Extreme]: {
        color: ProbabilityColors.Extreme,
        text: translate('dashboard.weather.probability.highly-probable'),
    },
}

const getColor = (type: WeatherProbabilityType): TooltipColorListItem => {
    const label = probabilityLabels[type]!
    return [label.color, label.text]
}

export const probabilityColorList: TooltipColorList = [
    getColor(WeatherProbabilityType.Small),
    getColor(WeatherProbabilityType.Normal),
    getColor(WeatherProbabilityType.Big),
    getColor(WeatherProbabilityType.Extreme),
]

export const lvpProbabilityColorList: TooltipColorList = [
    [ProbabilityColors.Big, translate('dashboard.weather.probability.likely-lvp')],
]

export const getWeatherProbabilityLabel = (
    weatherPoint: TimeValuePoint,
): ProbabilityLabel | null => {
    const type = getWeatherProbabilityTypeForWeatherProbabilityValue(weatherPoint)
    return probabilityLabels[type]
}

export const getLvpProbabilityLabel = (weatherPoint: TimeValuePoint): ProbabilityLabel | null => {
    const type = getLvpProbabilityTypeForWeatherProbabilityValue(weatherPoint)
    if (type === WeatherProbabilityType.Big) {
        return {
            color: ProbabilityColors.Big,
            text: translate('dashboard.weather.probability.likely-lvp'),
        }
    }
    return null
}
