import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { ForwardedRef, ReactNode, useEffect } from 'react'

import { CheckboxListItem } from './CheckboxListItem'

export interface CheckboxListProps<T> {
    items: CheckboxItem<T>[]
    itemToLabel(v: T): ReactNode
    onChange?(v: CheckboxItem<T>[]): void
    onChangeItem?(v: CheckboxItem<T>): void
    enableSelectAll?: boolean
    selectAllLabel?: string
}

type CheckAllState = 'checked' | 'unchecked' | 'indeterminate'

const List = <T extends unknown>(props: CheckboxListProps<T>, _ref: ForwardedRef<any>) => {
    const [checkAllState, setCheckAllState] = React.useState<CheckAllState>('unchecked')

    const onChange = (item: CheckboxItem<T>) => {
        const newItems = props.items.map(v => (v[0] === item[0] ? item : v))
        updateCheckAllState(newItems)
        if (props.onChange) {
            props.onChange(newItems)
        }
        if (props.onChangeItem) {
            props.onChangeItem(item)
        }
    }
    const updateCheckAllState = (items: CheckboxItem<T>[]) => {
        const allChecked = items.every(item => item[1])
        const someChecked = items.some(item => item[1])
        setCheckAllState(allChecked ? 'checked' : someChecked ? 'indeterminate' : 'unchecked')
    }

    const onCheckAllChange = () => {
        const newState: CheckAllState = checkAllState === 'checked' ? 'unchecked' : 'checked'
        const newItems = props.items.map(
            item => [item[0], newState === 'checked'] as CheckboxItem<T>,
        )
        setCheckAllState(newState)
        if (props.onChange) {
            props.onChange(newItems)
        }
    }

    useEffect(() => {
        updateCheckAllState(props.items)
    }, [props.items])

    return (
        <Box>
            {props.enableSelectAll && (
                <CheckboxListItem
                    itemToLabel={item => item}
                    indeterminate={checkAllState === 'indeterminate'}
                    item={[props.selectAllLabel, checkAllState === 'checked']}
                    onChange={onCheckAllChange}
                />
            )}
            {props.items.map((item: CheckboxItem<T>, index: number) => (
                <CheckboxListItem
                    key={index}
                    item={item}
                    itemToLabel={props.itemToLabel}
                    onChange={onChange}
                />
            ))}
        </Box>
    )
}

export const CheckboxList = React.forwardRef(List) as <T>(
    props: CheckboxListProps<T> & { ref?: React.ForwardedRef<any> },
) => ReturnType<typeof List>
