import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import {
    firefightersLoadShiftAction,
    firefighterStopSyncAction,
    firefighterSyncAction,
} from 'aos-services/src/core/firefighters/actions'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { Box } from 'aos-ui/src/components/base/Box'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { SizeMe } from 'react-sizeme'
import { createStructuredSelector } from 'reselect'

import { firefightersReloadAction } from '../../../core/tasks/firefighters/actions'
import { firefighterListSelector } from '../../../core/tasks/firefighters/selectors'
import { useSyncHook } from '../../../hooks/useSync'
import { FirefightersTile } from './list/FirefighterTile'

interface FireFightersProps extends FirefightersListStateProps, FirefightersListDispatchProps {
    onEdit(shift: FirefighterShift): void
}

export const FirefightersListComponent: FC<FireFightersProps> = ({
    firefightersLoad,
    firefightersReload,
    list,
    canEdit,
    onEdit,
}) => {
    useEffect(() => {
        firefightersLoad()
    }, [])

    useSyncHook([firefighterSyncAction('list')], [firefighterStopSyncAction('list')])

    return (
        <SizeMe>
            {({ size }) => (
                <PaginationList page={list} onChange={firefightersReload} paddingBottom={20}>
                    <Box bg={Color.UiBlack1} padding={30}>
                        {list.content?.map(item => (
                            <Box key={item.id} paddingBottom={16}>
                                <FirefightersTile
                                    item={item}
                                    onEdit={canEdit ? onEdit : undefined}
                                    parentWidth={size.width || 0}
                                />
                            </Box>
                        ))}
                    </Box>
                </PaginationList>
            )}
        </SizeMe>
    )
}

interface FirefightersListStateProps {
    list: Pageable<FirefighterShift>
    canEdit: boolean
}

interface FirefightersListDispatchProps {
    firefightersLoad: typeof firefightersLoadShiftAction
    firefightersReload: typeof firefightersReloadAction
}

export const FirefightersList = connect<FirefightersListStateProps, FirefightersListDispatchProps>(
    createStructuredSelector({
        list: firefighterListSelector,
        canEdit: canEditTasksSelector,
    }),
    {
        firefightersLoad: firefightersLoadShiftAction,
        firefightersReload: firefightersReloadAction,
    },
)(FirefightersListComponent)
