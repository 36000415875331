import { ChartContextProps } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { isFunction } from 'lodash'
import { PureComponent } from 'react'

export abstract class BaseChartComponent<T> extends PureComponent<T & ChartContextProps> {
    public abstract render(): JSX.Element

    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}
