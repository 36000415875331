export interface ChecklistTemplatePayload {
    name: string
    tag: string
}

export interface ChecklistTemplateEditPayload extends ChecklistTemplatePayload {
    id: number
}

export const emptyChecklistTemplatePayload = {
    name: '',
    tag: '',
}

export const emptyChecklistTemplateEditPayload = {
    ...emptyChecklistTemplatePayload,
    id: -1,
}
