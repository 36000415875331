import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/performance'
import 'firebase/remote-config'

import { FirebaseAppClass } from 'aos-services/src/dataaccess/firebase/FirebaseAppClass'
import firebase from 'firebase/app'

import { appConfig } from './appConfig'

export const firebaseApp = new FirebaseAppClass(
    firebase.initializeApp(appConfig.firebaseConfig) as any,
)
