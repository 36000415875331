import { fodSyncAction, fodSyncStopAction } from 'aos-services/src/core/fod/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { FC, PropsWithChildren } from 'react'

import { useSyncHook } from '../../../hooks/useSync'

interface FodSyncWrapperProps {
    id: SyncDataKey
}

export const FodSyncWrapper: FC<PropsWithChildren<FodSyncWrapperProps>> = ({ children, id }) => {
    useSyncHook([fodSyncAction(id)], [fodSyncStopAction(id)])
    return children as JSX.Element
}
