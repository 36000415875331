import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosAirport,
    locationName,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AosUserLocalisation } from 'aos-services/src/services/users/types/AosUserLocalisation'
import { EnumValues } from 'enum-values'

export const localisationOptionMapper = (u: AosUserLocalisation) => ({
    label: translate(`languages.${u}`),
    value: u,
})

export const siteLocationOptionMapper = (u: AosAirport) => ({
    label: locationName(u),
    value: u,
})

export const allAirports = EnumValues.getValues<AosAirport>(AosAirport)
export const languageOptions = EnumValues.getValues<AosUserLocalisation>(AosUserLocalisation)
