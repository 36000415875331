import { DateTime } from 'aos-helpers/src/helpers/Time'
import { defaultDateFormat, defaultTimeFormat } from 'aos-helpers/src/helpers/TimeFormat'
import { dateTimeByMode, formatDateTimeByMode, TimeMode } from 'aos-helpers/src/helpers/TimeMode'
import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { ClickOutside } from 'aos-ui/src/components/base/ClickOutside'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Moment } from 'moment/moment'
import React, { forwardRef, PropsWithChildren, useEffect, useRef, useState } from 'react'
import ReactDatetime from 'react-datetime'

import { DropdownPortal, DropdownPortalType } from '../dropdown/base/DropdownPortal'
import { CleanableInput } from '../input/CleanableInput'
import { ChangeValue, GenericDatetime, ViewMode } from './GenericDatetime'
type DateTimeChangeType = 'date' | 'time' | 'none'
export interface DatetimeInputProps extends MarginBoxProps {
    icon?: Svg
    hideClear?: boolean
    placeholder?: string
    value?: DateTime
    onChange(v: DateTime | undefined, changeType: DateTimeChangeType): void
    onBlur?(): void
    mode?: TimeMode
    timeRangeStart?: DateTime
    isValidDate?: (v: DateTime) => boolean
    viewMode?: ViewMode
    roundMinutesTo?: number
    timeConstraints?: ReactDatetime.TimeConstraints
}

export const DatetimeInput = forwardRef<HTMLDivElement, PropsWithChildren<DatetimeInputProps>>(
    ({
        onChange,
        onBlur,
        mode = 'datetime',
        hideClear,
        icon,
        value,
        placeholder,
        timeRangeStart,
        children,
        isValidDate,
        viewMode,
        roundMinutesTo,
        timeConstraints,
        ...margins
    }) => {
        const format = (date?: DateTime) => (date ? formatDateTimeByMode(date, mode) : '')
        const [previousValue, setPreviousValue] = useState<DateTime | undefined>(value)

        const [isOpen, setOpen] = useState(false)
        const [textValue, setTextValue] = useState(format(value))
        const ref = useRef<HTMLDivElement | null>(null)

        useEffect(() => {
            if (value) {
                setTextValue(format(value))
            } else if (!isOpen) {
                setTextValue('')
            }
        }, [value])

        const inputIcon = icon ?? mode === 'time' ? SvgIcon.CurrentTime : SvgIcon.Timeline

        const changeDateTime = (value?: ChangeValue) => {
            if (value === undefined) {
                return
            }

            if (typeof value === 'string') {
                const date = dateTimeByMode(value, mode)
                if (date.isValid()) {
                    onChange(date, compareDates(date, previousValue))
                    setPreviousValue(date)
                } else {
                    onChange(undefined, 'none')
                    setTextValue(value)
                }
            } else {
                onChange(
                    roundDateMinutes(value, roundMinutesTo),
                    compareDates(value, previousValue),
                )
                setPreviousValue(value)
            }
        }

        return (
            <Box relative ref={ref} {...margins}>
                {children ? (
                    <Box onClick={() => setOpen(true)}>{children}</Box>
                ) : (
                    <CleanableInput
                        hideClean={hideClear}
                        svg={inputIcon}
                        value={textValue}
                        onFocus={() => setOpen(true)}
                        onBlur={event => {
                            if (!isOpen) {
                                changeDateTime(event.target.value)
                            }
                            onBlur?.()
                        }}
                        onClear={() => {
                            changeDateTime('')
                        }}
                        onChangeText={setTextValue}
                        placeholder={placeholder}
                    />
                )}
                <DropdownPortal element={ref} type={DropdownPortalType.BottomCentered}>
                    <ClickOutside onClickOutside={() => setOpen(false)}>
                        <GenericDatetime
                            timeFormat={mode !== 'date' && defaultTimeFormat}
                            dateFormat={mode !== 'time' && defaultDateFormat}
                            input={false}
                            timeConstraints={timeConstraints}
                            value={value}
                            position='bottom'
                            onChange={changeDateTime}
                            open={isOpen}
                            timeRangeStart={timeRangeStart}
                            isValidDate={isValidDate}
                            viewMode={viewMode}
                        />
                    </ClickOutside>
                </DropdownPortal>
            </Box>
        )
    },
)

const roundDateMinutes = (date: DateTime, r = 0) => {
    if (!r) {
        return date
    }

    const minutes = date.minute()
    const modulo = minutes % r
    let min: number

    // no changes
    if (modulo === 0) {
        min = minutes
    }
    // single -1 click
    else if (modulo === r - 1) {
        min = minutes - modulo
    }
    // single +1 click
    else if (modulo === 1) {
        min = minutes + (r - modulo)
    }
    // just round to nearest
    else {
        min = ((((minutes + r / 2) / r) | 0) * r) % 60
    }

    // don't change hours
    if (min === 60) {
        min = 0
    }

    return date.clone().minutes(min)
}

function compareDates(date1: Moment, date2?: Moment): DateTimeChangeType {
    if (!date2) {
        return 'date'
    }
    const isDateDifferent = date1.format('YYYY-MM-DD') !== date2.format('YYYY-MM-DD')
    const isTimeDifferent = date1.format('HH:mm:ss') !== date2.format('HH:mm:ss')

    return isDateDifferent ? 'date' : isTimeDifferent ? 'time' : 'none'
}
