import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { FlightsSyncWrapper } from '../../base/FlightsSyncWrapper'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'

interface BaggageDeliveryItemProps {
    itemProps: StatusDashboardItemCommonProps
    noDataWarning?: boolean
    link?: string
}

export const BaggageDeliveryItem: FC<PropsWithChildren<BaggageDeliveryItemProps>> = props => (
    <FlightsSyncWrapper id={props.itemProps.id}>
        <StatusDashboardItem
            title={translate('dashboard.baggage-delivery.title')}
            description={translate('dashboard.baggage-delivery.description')}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </FlightsSyncWrapper>
)
