import { useContext } from 'react'

import { keycloakContext } from './KeycloakContext'

export function useKeycloak() {
    const context = useContext(keycloakContext)

    if (!context.client) {
        throw new Error('keycloak client has not been assigned to KeycloakProvider')
    }

    const { client } = context
    return {
        keycloak: client,
    }
}
