import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AtcFieldsDto, AtcFieldsDtoSchema } from '../../../dataaccess/feeds/types/AosCommonEventDto'

export class AtcFieldsImpl extends WrapperObject<AtcFieldsDto> implements AtcFieldsDto {
    constructor(value: AtcFieldsDto) {
        const parsedValue = AtcFieldsDtoSchema.parse(value)
        super(parsedValue)
    }

    get aircraftModel() {
        return this.value.aircraftModel
    }

    get flightNumber() {
        return this.value.flightNumber
    }

    get eta() {
        return this.value.eta
    }

    get numberOfPax() {
        return this.value.numberOfPax
    }

    get incidentType() {
        return this.value.incidentType
    }

    get numberOfCrew() {
        return this.value.numberOfCrew
    }

    get dgr() {
        return this.value.dgr
    }

    get moreInfo() {
        return this.value.moreInfo
    }

    get fuel() {
        return this.value.fuel
    }

    get callSign() {
        return this.value.callSign
    }

    get locationName() {
        return this.value.locationName
    }

    get route() {
        return this.value.route
    }
}
