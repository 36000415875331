import { AxisTicks } from 'aos-components/src/components/chart/axes/AxisTicks'
import { AxisUnit } from 'aos-components/src/components/chart/axes/AxisUnit'
import { LegacyChart } from 'aos-components/src/components/chart/LegacyChart'
import { seriesBarPadding } from 'aos-components/src/components/chart/series/GradientSeries'
import {
    LinearIconSeries,
    seriesIconSize,
    seriesIconsPadding,
} from 'aos-components/src/components/chart/series/LinearIconSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    NullableTimeValuePoint,
    TimeValuePoint,
} from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { Box } from 'aos-ui/src/components/base/Box'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import {
    ChartConfig,
    ChartScaleType,
    getRanges,
} from 'aos-ui-common/src/components/chart/types/Chart'
import { AxisScale } from 'd3-axis'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import { chartIconOffset, chartUnitOffset } from '../WeatherConsts'
import { getWeatherColorForWeatherSeries } from '../WeatherSettings'
import { WeatherIcons } from './WeatherIcons'

const windDirectionIconSeriesConfig = {
    color: getWeatherColorForWeatherSeries(WeatherValueSeriesType.WindDirection),
    rotate: (weatherPoint: TimeValuePoint) => `${(weatherPoint.value || 0) - 180}`,
    svgIcon: SvgIcon.WeatherWindDirection,
}

class WeatherWindDirectionChartClass extends PureComponent<
    WeatherWindDirectionChartProps & SizeMeProps,
    {}
> {
    public xAccessor = (d: TimeValuePoint) => d.time
    public yAccessor = (d: TimeValuePoint) => d.value

    public render() {
        const { chartConfig, size, seriesData, unit } = this.props
        const config = this.scalesConfig

        return (
            <Box className='icons-chart'>
                <LegacyChart chartConfig={chartConfig} size={size}>
                    <AxisTicks
                        axisConfig={{ tickValues: config[ChartScaleType.X].tickValues }}
                        scale={config[ChartScaleType.X].scale}
                    />
                    {unit && (
                        <AxisUnit
                            unit={unit}
                            left={(this.props.size.width || 0) - chartUnitOffset}
                            top={
                                this.props.chartConfig.margins.top +
                                seriesBarPadding +
                                seriesIconSize / 2
                            }
                            textAnchor='middle'
                        />
                    )}
                    <LinearIconSeries
                        data={seriesData}
                        seriesConfig={windDirectionIconSeriesConfig}
                        accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                        scales={{ xScale: config[ChartScaleType.X].scale }}
                    />
                    <WeatherIcons
                        components={[WeatherValueSeriesType.WindDirection]}
                        left={chartIconOffset}
                        top={seriesIconsPadding}
                    />
                </LegacyChart>
            </Box>
        )
    }

    private get scalesConfig(): ScalesConfig {
        const { chartConfig, xDomain } = this.props

        const { xRange } = getRanges(chartConfig.margins, this.size)

        const xScale = Scales.scaleDateTime(xDomain.domain, xRange)

        return {
            [ChartScaleType.X]: {
                scale: xScale,
                tickValues: xDomain.ticks,
            },
        }
    }

    private get size() {
        return { width: this.props.size.width || 0, height: this.props.size.height || 0 }
    }
}

interface DateConfig {
    scale: AxisScale<DateTime>
    tickValues: DateTime[]
}

interface ScalesConfig {
    [ChartScaleType.X]: DateConfig
}

export const WeatherWindDirectionChart = sizeMe({ monitorHeight: true })(
    WeatherWindDirectionChartClass,
)

export interface WeatherWindDirectionChartProps {
    chartConfig: ChartConfig
    seriesData: NullableTimeValuePoint[]
    xDomain: Domain<DateTime>
    unit: string
}
