import {
    SimpleFormModalState,
    ValueWrapper,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/state'
import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { TaskOrDescription } from 'aos-services/src/services/checklists/types/AosCheckListItemType'

export type TaskOrDescriptionModalState = SimpleFormModalState<
    TaskOrDescriptionState,
    TaskOrDescriptionValidation,
    undefined
>

export interface TaskOrDescriptionState {
    content: string
    type: TaskOrDescription
}

export interface TaskOrDescriptionValidation {
    content: boolean
    type: boolean
}

export type TaskOrDescriptionValueWrapper = ValueWrapper<TaskOrDescriptionState, undefined>

export const initialTaskOrDescriptionValueWrapper: TaskOrDescriptionValueWrapper = {
    value: {
        content: '',
        type: TaskOrDescription.Task,
    },
}

export const validateTaskOrDescription = (
    e: TaskOrDescriptionValueWrapper,
): TaskOrDescriptionValidation => ({
    content: isNotEmpty(e.value && e.value.content),
    type: isNotEmpty(e.value && e.value.type),
})

export const createInitialTaskOrDescriptionModalState = (): TaskOrDescriptionModalState => ({
    isOpen: false,
    currentValue: initialTaskOrDescriptionValueWrapper,
    form: FormValidation.fromFields(
        validateTaskOrDescription(initialTaskOrDescriptionValueWrapper),
    ),
})
