import {
    LegendItemConfig,
    SimpleHorizontalLegend,
} from 'aos-components/src/components/chart/SimpleHorizontalLegend'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ControlPointConfig } from 'aos-services/src/services/queueingTime/types/ControlPointConfig'
import React, { FC } from 'react'

interface QueueingTimeBorderLegendProps {
    config: Record<string, ControlPointConfig>
}

export const QueueingTimeLegend: FC<QueueingTimeBorderLegendProps> = ({ config }) => {
    const configForPoint = (p: string): LegendItemConfig => [
        { mainColor: config[p].color },
        config[p].fullName,
    ]
    return (
        <SimpleHorizontalLegend
            leftItems={Object.keys(config).map(configForPoint)}
            size={BlockSize.Tiny}
        />
    )
}
