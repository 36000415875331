import { CurrentUser } from 'aos-services/src/services/auth/types/CurrentUser'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { ClassicComponent, FunctionComponent } from 'react'
import { DndComponentClass } from 'react-dnd'

export type TemplateItemProps<T extends AosSimpleTemplate> = {
    seq: number
    isSelected: boolean
    canEdit: boolean
    exportingChecklistTemplates: boolean
    checklistTemplate: T
    onDelete?: (v: T) => void
    onEdit?: (v: T) => void
    onSelect(v: T): void
    onExport(v: number): void
    onChangeSeq?(v: ChangeSeqVector): void
    onApplySeqChanges?(): void
}

export const TemplatesList = <T extends AosSimpleTemplate>({
    checklistTemplates,
    selectedChecklistTemplate,
    canEdit = true,
    Component,
    ...rest
}: TemplatesListProps<T>) => {
    const Item = Component as FunctionComponent<TemplateItemProps<T>>
    return (
        <Box fullHeight bg={Color.UiBlack2}>
            {checklistTemplates.map(checklistTemplate => (
                <Item
                    {...rest}
                    canEdit={canEdit}
                    key={checklistTemplate.id}
                    isSelected={selectedChecklistTemplate?.id === checklistTemplate.id}
                    checklistTemplate={checklistTemplate as T}
                    seq={checklistTemplate.sequenceNumber}
                />
            ))}
        </Box>
    )
}

interface TemplatesListProps<T extends AosSimpleTemplate> {
    Component:
        | FunctionComponent<TemplateItemProps<T>>
        | ClassicComponent<TemplateItemProps<T>>
        | DndComponentClass<DndComponentClass<any, any>, TemplateItemProps<T>>
    checklistTemplates: AosSimpleTemplate[]
    selectedChecklistTemplate: AosSimpleTemplate | undefined
    currentUser: CurrentUser
    exportingChecklistTemplates: boolean
    onSelect(v: AosSimpleTemplate): void
    onExport(v: number): void
    onDelete?(v: AosSimpleTemplate): void
    onEdit?(v: AosSimpleTemplate): void
    onChangeSeq?(v: ChangeSeqVector): void
    onApplySeqChanges?(): void
    canEdit: boolean
}
