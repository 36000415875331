import { BoxProps } from 'aos-ui/src/components/base/Box'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import React, { FCWithChildren, PropsWithChildren } from 'react'

export const FormFieldContainer: FCWithChildren<PropsWithChildren<BoxProps>> = ({
    children,
    ...boxProps
}) => (
    <SeparatedContainer paddingBottom={12} spacing={12} {...boxProps}>
        {children}
    </SeparatedContainer>
)
