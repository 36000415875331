import { ActionModalMode } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import {
    WhiteModalContent,
    WhiteModalContentVariant,
} from 'aos-components/src/components/modal/WhiteModal/WhiteModalContent'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

export class UserExists extends PureComponent<UserExistsProps> {
    public render() {
        return (
            <WhiteModalContent
                title={this.props.email}
                variant={WhiteModalContentVariant.Blue}
                svg={SvgIcon.Warning}
                buttons={this.getButtons()}
                description={this.props.description}
            />
        )
    }

    private getButtons = () => {
        const backButton = this.props.back
            ? [
                  <GreyTextButton
                      key='1'
                      label={translate('manage-user.back')}
                      onClick={this.props.back}
                  />,
              ]
            : []

        const actionButton = this.props.userAction
            ? [
                  <FormButton
                      key='1'
                      label={this.props.userActionLabel!}
                      onClick={this.props.userAction}
                      variant={this.buttonVariantForMode()}
                  />,
              ]
            : []

        return [...actionButton, ...backButton]
    }

    private buttonVariantForMode = () => {
        switch (this.props.mode) {
            case ActionModalMode.Normal:
                return FormButtonVariant.Blue

            case ActionModalMode.Important:
                return FormButtonVariant.Red

            default:
                return FormButtonVariant.Blue
        }
    }
}

interface UserExistsProps {
    email: string
    description: string
    userActionLabel?: string
    mode?: ActionModalMode
    back?(): void
    userAction?(): void
}
