import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { Box } from '../base/Box'
import { Text } from '../base/Text'

interface SomethingWentWrongToastProps {
    error: ResponseError
}

export const SomethingWentWrongToast = ({ error }: SomethingWentWrongToastProps) => {
    const status = error.errorPayload?.status ?? error.originalResponse.statusText
    const errorMessage = error.errorPayload?.message

    return (
        <Box>
            <Text color={Color.Black} size={14} marginBottom={4}>
                {translate('error.something-went-wrong')}
            </Text>
            <Text color={Color.Black} size={12} wordBreak>
                {status} {error.originalResponse.url}
            </Text>
            <Text color={Color.Black} size={12}>
                {errorMessage}
            </Text>
        </Box>
    )
}
