import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const title = (suffix: string) => translate(`dashboard.help.flights.${suffix}`)
const note = (suffix: string) => translate(`dashboard.help.flights.${suffix}.note`)

export const flightsHelpConfig: HelpModalConfig = {
    title: translate('dashboard.help.flights.title'),
    items: [
        {
            title: title('volumes'),
            renderer: lazy(() => import('./renderers/FlightsVolumesHelp')),
        },
        {
            title: title('chart-scale'),
            note: note('chart-scale'),
            renderer: lazy(() => import('./renderers/FlightsChartScaleHelp')),
        },
        {
            title: title('chart-data'),
            note: note('chart-data'),
            renderer: lazy(() => import('./renderers/FlightsChartDataHelp')),
        },
    ],
}
