import { TaskChecklist, TaskChecklistItem } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import React, { FC, useState } from 'react'

import { ChecklistItem } from '../../eventEditor/section/items/ChecklistItem'

interface TaskDetailsChecklistProps {
    checklists: TaskChecklist[]
    onChecklistItemChange(checklistId: number, itemId: number, value: boolean): void
}

export const TaskDetailsChecklist: FC<TaskDetailsChecklistProps> = ({
    checklists,
    onChecklistItemChange,
}) => {
    const [expandedChecklist, setExpandedChecklist] = useState<Record<number, boolean>>(
        checklists.reduce((acc, checklist) => {
            acc[checklist.id] = false
            return acc
        }, {} as Record<number, boolean>),
    )

    return (
        <>
            {checklists.map(checklist => (
                <TaskDetailsExpandable
                    onItemChange={onChecklistItemChange}
                    isCollapsed={expandedChecklist[checklist.id]}
                    onToggle={() => {
                        setExpandedChecklist({
                            ...expandedChecklist,
                            [checklist.id]: !expandedChecklist[checklist.id],
                        })
                    }}
                    key={checklist.id}
                    checklist={checklist}
                />
            ))}
        </>
    )
}

interface TaskDetailsExpandableProps {
    checklist: TaskChecklist
    onToggle(): void
    isCollapsed: boolean
    onItemChange(checklistId: number, itemId: number, value: boolean): void
}

export const TaskDetailsExpandable: FC<TaskDetailsExpandableProps> = ({
    checklist,
    onToggle,
    isCollapsed,
    onItemChange,
}) => {
    const renderHeader = () => (
        <Box row fullWidth flex={1}>
            <Box justify='space-between' flex={1} row>
                <Text fontVariant='all-small-caps'>{checklist.name}</Text>
                <BorderedChip width={80} weight='medium'>
                    {checklist.tag}
                </BorderedChip>
            </Box>
        </Box>
    )

    const renderItem = (item: TaskChecklistItem, index: number) => (
        <Box key={index} marginBottom={16}>
            <ChecklistItem
                toggleTask={() =>
                    onItemChange(checklist.id ?? checklist.templateId, item.id, !item.done)
                }
                isEditable
                isEven={index % 2 === 0}
                item={item}
            />
        </Box>
    )

    return (
        <CollapsibleContainer
            label={renderHeader()}
            toggle={onToggle}
            isCollapsed={isCollapsed}
            variant={CollapsibleButtonVariant.RightBottom}
            iconVariant={IconVariant.White}
            paddingVertical={16}
        >
            {checklist.items.map((task, index) => renderItem(task, index))}
        </CollapsibleContainer>
    )
}
