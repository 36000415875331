import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { capitalize } from 'lodash'

import { MapSiteLocationCustomization } from '../../common/types/MapSiteLocationCustomization'

export enum AosAirport {
    HEL = 'HEL',
    IVL = 'IVL',
    JOE = 'JOE',
    JYV = 'JYV',
    KAJ = 'KAJ',
    KEM = 'KEM',
    KEV = 'KEV',
    KTT = 'KTT',
    KOK = 'KOK',
    KUO = 'KUO',
    KAO = 'KAO',
    MHQ = 'MHQ',
    OUL = 'OUL',
    POR = 'POR',
    RVN = 'RVN',
    SVL = 'SVL',
    TMP = 'TMP',
    TKU = 'TKU',
    UTI = 'UTI',
    VAA = 'VAA',
}

export const allAirports = EnumValues.getValues<AosAirport>(AosAirport)

export const locationName = translateEnum<AosAirport>(AosAirport, 'airports')

export const airportIcon = (e: AosAirport) => capitalize((e as string).toLowerCase())

export const toSiteLocationCustomization = (aosAirport: AosAirport): MapSiteLocationCustomization =>
    aosAirport === AosAirport.HEL
        ? MapSiteLocationCustomization.Helsinki
        : MapSiteLocationCustomization.Other
