import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { WaitingTimeSeries } from 'aos-services/src/core/statusDashboardData/pax/paxCalculations'
import { PointId } from 'aos-services/src/services/pax/types/PointId'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { SecurityPointBox } from './SecurityPointBox'

interface SecurityPointBoxesProps {
    data: WaitingTimeSeries
    pointId: PointId
}

export const SecurityPointBoxes: FC<SecurityPointBoxesProps> = ({ data, pointId }) => {
    const orangeRows = data.orangeContinuous.map(
        (array, index) =>
            array.length > 0 && <SecurityPointBox key={index} pax={array} color={Color.Yellow} />,
    )
    const redRows = data.redContinuous.map(
        (array, index) =>
            array.length > 0 && <SecurityPointBox key={index} pax={array} color={Color.Red} />,
    )
    const renderWithTimeOrder = (data: WaitingTimeSeries) => {
        if (data.redContinuous.length && data.orangeContinuous.length) {
            const rows = [data.redContinuous[0], data.orangeContinuous[0]]
            return rows[0][0].time > rows[1][0].time
                ? [orangeRows[0], redRows[0]]
                : [redRows[0], orangeRows[0]]
        } else {
            return (
                <>
                    {data.orangeContinuous.length > 0 && orangeRows.slice(0, 2)}
                    {data.redContinuous.length > 0 && redRows.slice(0, 2)}
                </>
            )
        }
    }

    return (
        <Box fullWidth rowGap={20} column marginLeft={30} paddingVertical={2}>
            {renderWithTimeOrder(data)}
            {data.redContinuous.length === 0 && data.orangeContinuous.length === 0 && (
                <NoDataText className=''>{translate('dashboard.pax.no-data')}</NoDataText>
            )}
            <span className='text__l-grey-txt'>
                {`${translateEnum<PointId>(PointId, 'dashboard.pax.point')(pointId)} ${translate(
                    'dashboard.pax.waiting-time',
                )}`}
            </span>
        </Box>
    )
}

const NoDataText = styled.span`
    width: 80%;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
`
