import { dateTime } from 'aos-helpers/src/helpers/Time'
import { PREFERENCES_LOCATION_CHANGED } from 'aos-services/src/core/auth/actions'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    SYNC_STATUS_DASHBOARD,
    SYNC_STATUS_DASHBOARD_STOP,
} from 'aos-services/src/core/statusDashboard/actions'
import { baseStatusDashboardSagas } from 'aos-services/src/core/statusDashboard/sagas'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { widgetStatesService } from 'aos-services/src/services/widgetStates/widgetStatesService'
import { push } from 'redux-first-history'
import { call, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects'

import {
    DashboardPresets,
    MyDashboardTab,
} from '../../services/statusDashboard/types/DashboardPreset'
import {
    DISMISS_ALERT,
    DismissAlertAction,
    OPEN_STATUS_DASHBOARD_TAB,
    OpenStatusDashboardTabAction,
    openStatusDashboardTabAction,
    setPresetsAction,
    TOGGLE_SIDEBAR,
    ToggleSidebarAction,
    toggleSidebarAction,
    triggerCarouselChangeAction,
} from './actions'
import { defaultPresetsSelector } from './selectors'

function* openDashboardTabSaga() {
    yield takeEvery<OpenStatusDashboardTabAction>(OPEN_STATUS_DASHBOARD_TAB, function* (action) {
        yield put(push(`/status-dashboard/${action.payload}`))
    })
}

function* dismissAlertSaga() {
    yield takeEvery<DismissAlertAction>(DISMISS_ALERT, function* (action: DismissAlertAction) {
        const location: AosAirport = yield select(currentUserSiteLocation)
        yield call(widgetStatesService.dismissAlert, location, action.payload)
    })
}

function* syncStatusDashboardSaga() {
    yield takeEvery(SYNC_STATUS_DASHBOARD, function* () {
        yield fork(triggerCarouselChangeSaga)
    })
}

function* openSidebarSaga() {
    yield takeEvery<ToggleSidebarAction>(TOGGLE_SIDEBAR, function* (action: ToggleSidebarAction) {
        if (action.payload) {
            yield put(openStatusDashboardTabAction(MyDashboardTab.MyDashboard))
        }
    })
}

function* closeSidebarSaga() {
    yield takeEvery<OpenStatusDashboardTabAction>(
        OPEN_STATUS_DASHBOARD_TAB,
        function* (action: OpenStatusDashboardTabAction) {
            if (action.payload !== MyDashboardTab.MyDashboard) {
                yield put(toggleSidebarAction(false))
            }
        },
    )
}

function* triggerCarouselChangeSaga() {
    yield race({
        task: call(function* () {
            while (true) {
                yield delay(10 * 1000)
                yield put(triggerCarouselChangeAction(dateTime()))
            }
        }),
        cancel: take([SYNC_STATUS_DASHBOARD_STOP, SYNC_STATUS_DASHBOARD]),
    })
}

function* preferencesLocationChangedSaga() {
    yield takeEvery(PREFERENCES_LOCATION_CHANGED, function* () {
        const defaultPresets: DashboardPresets = yield select(defaultPresetsSelector)
        yield put(setPresetsAction(defaultPresets))
    })
}

export const statusDashboardSagas = [
    ...baseStatusDashboardSagas,
    preferencesLocationChangedSaga,
    syncStatusDashboardSaga,
    openDashboardTabSaga,
    dismissAlertSaga,
    openSidebarSaga,
    closeSidebarSaga,
]
