import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'

import { SimpleFormModalState, ValueWrapper } from '../simpleForm/state'

export interface SingleSelectModalState<T>
    extends SimpleFormModalState<T, SingleSelectFormValidation, any> {
    options: T[]
}

export interface SingleSelectFormValidation {
    value: boolean
}

export const validateForm = (e: ValueWrapper<any, any>): SingleSelectFormValidation => ({
    value: isNotEmpty(e.value),
})

export function createInitialSingleSelectModalState<T>(): SingleSelectModalState<T> {
    return {
        isOpen: false,
        options: [],
        currentValue: {} as ValueWrapper<T, any>,
        form: FormValidation.fromFields(validateForm({})),
    }
}
