import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { InvitationRole } from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { PrivateChannelParticipant } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useMemo } from 'react'

import { ChannelParticipant } from '../privateChannel/participants/ChannelParticipant'
interface CommanderList {
    participants: PrivateChannelParticipant[]
}
export const CommanderList: FC<CommanderList> = ({ participants }) => {
    const emptyList = () => (
        <Box marginBottom={12}>
            <PanelPlaceholder
                inline
                label={translate('private-channel.no-commander')}
                svg={SvgIcon.UserPlaceholder}
            />
        </Box>
    )

    const commanderList = useMemo(
        () =>
            participants.filter(
                participant => participant?.invitationRole === InvitationRole.Command,
            ),
        [participants],
    )

    return (
        <Box column wrap justify='space-around'>
            {commanderList.length === 0
                ? emptyList()
                : commanderList.map(participant => (
                      <ChannelParticipant
                          key={participant?.firebaseUid}
                          participant={participant as PrivateChannelParticipant}
                      />
                  ))}
        </Box>
    )
}
