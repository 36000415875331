import { optionalToArray } from 'aos-helpers/src/helpers/Array'
import { isDefined } from 'aos-helpers/src/helpers/Function'
import { PredicateMatcher } from 'aos-helpers/src/helpers/PredicateMatcher'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { chain, Dictionary, groupBy, isUndefined, keys, max } from 'lodash'

import {
    Station,
    TimeTableRow,
    TimeTableRowTypeEnum,
    Train,
    TrainLocation,
} from '../../dataaccess/layerData/types/RingRail'
import {
    RingRailTimeTableRow,
    RingRailTrain,
    RingRailTrainProps,
    RingRailTrainRoute,
    RingRailTrainScheduleStatus,
    RingRailTrainStatus,
    TRAIN_SCHEDULE_THRESHOLD_AT_RISK,
    TRAIN_SCHEDULE_THRESHOLD_DELAYED,
} from '../layerData/types/RingRailTrain'
import { RingRailTrainPosition } from '../layerData/types/RingRailTrainPosition'

class RingRailTrainMapper {
    public ringRailTrainPositionForTrainLocation = (
        trainLocation: TrainLocation,
    ): Partial<RingRailTrainPosition> => ({
        position: {
            lon: trainLocation.location!.coordinates![0],
            lat: trainLocation.location!.coordinates![1],
        },
        speed: trainLocation.speed!,
    })

    public ringRailTrainPositionForTrainByStation = (
        train: Partial<RingRailTrain>,
        stations: Dictionary<Station>,
    ): Partial<RingRailTrainPosition> => {
        if (!train || !train.trainNumber || !train.previousStations || !train.nextStations) {
            return {}
        }

        const isBeforeFirstStation =
            train.previousStations!.length === 0 &&
            train.nextStations!.length > 0 &&
            !train.position
        const currentStation = train.currentStation
            ? stations[train.currentStation!.stationShortCode]
            : undefined
        const firstStation = isBeforeFirstStation
            ? stations[train.nextStations![0].stationShortCode]
            : undefined

        return (
            this.ringRailTrainPositionByStation(currentStation) ||
            this.ringRailTrainPositionByStation(firstStation) ||
            {}
        )
    }

    public ringRailTrainForTrain = (train: Train): RingRailTrainProps => {
        const { trainNumber, commuterLineID, trainType, cancelled, timeTableRows } = train
        const timeTableRowsStops = timeTableRows ? timeTableRows.filter(r => !!r.trainStopping) : []

        const timeTableRowsDict = groupBy(
            timeTableRowsStops,
            r => `${r.stationShortCode}-${r.commercialTrack}`,
        )

        const ringRailTimeTableRows = keys(timeTableRowsDict).map((k: string) =>
            this.getRingRailTimeTableRowForDict(timeTableRowsDict, k),
        )

        const currentStation = ringRailTimeTableRows.find(t => t.isCurrentStation)

        const previousStations = chain(ringRailTimeTableRows)
            .filter(t => isDefined(t.departureActualTime))
            .sortBy('departureScheduledTime')
            .value()

        const nextStations = chain(ringRailTimeTableRows)
            .filter(t => isUndefined(t.departureActualTime))
            .sortBy('arrivalScheduledTime')
            .value()

        return {
            trainNumber,
            commuterLine: commuterLineID || '',
            trainType: trainType || '',

            trainStatus: this.getTrainStatus(!!cancelled, nextStations, currentStation),
            scheduleStatus: this.getTrainScheduleStatusRoute([
                ...optionalToArray(currentStation),
                ...nextStations,
            ]),

            route: this.getTrainRoute(timeTableRowsStops),
            currentRoute: this.getTrainCurrentRoute(previousStations, nextStations),

            currentStation,
            previousStations,
            nextStations,
        }
    }

    private ringRailTrainPositionByStation = (
        station: Station | undefined,
    ): Partial<RingRailTrainPosition> | undefined =>
        isDefined(station) && isDefined(station!.longitude) && isDefined(station!.latitude)
            ? {
                  position: {
                      lon: station!.longitude!,
                      lat: station!.latitude!,
                  },
              }
            : undefined

    private getRingRailTimeTableRowForDict = (
        dict: Dictionary<TimeTableRow[]>,
        key: string,
    ): RingRailTimeTableRow => {
        const stationShortCode = key.split('-')[0]
        return this.ringRailTimeTableRowForTimeTableRows(dict[key], stationShortCode)
    }

    private ringRailTimeTableRowForTimeTableRows = (
        timetableRows: TimeTableRow[],
        stationShortCode: string,
    ): RingRailTimeTableRow => {
        const arrivalData = timetableRows.find(t => t.type === TimeTableRowTypeEnum.ARRIVAL)
        const departureData = timetableRows.find(t => t.type === TimeTableRowTypeEnum.DEPARTURE)

        const differenceInMinutes = this.getStationDelayInMinutes(arrivalData, departureData)

        return {
            stationShortCode,
            commercialTrack: this.getStationCommercialTrack(arrivalData, departureData),
            isCurrentStation: this.getIsCurrentStation(arrivalData, departureData),

            ...this.getStationArrivalTimes(arrivalData),
            ...this.getStationDepartureTimes(departureData),

            differenceInMinutes,
            scheduleStatus: this.getScheduleStatus(differenceInMinutes),
        }
    }

    private getTrainRoute = (timetable?: TimeTableRow[]): RingRailTrainRoute => ({
        from: timetable && timetable.length > 0 ? timetable[0].stationShortCode : '',
        to:
            timetable && timetable.length > 0
                ? timetable[timetable.length - 1].stationShortCode
                : '',
    })

    private getTrainCurrentRoute = (
        previousStations: RingRailTimeTableRow[],
        nextStations: RingRailTimeTableRow[],
    ): RingRailTrainRoute => ({
        from:
            previousStations.length > 0
                ? previousStations[previousStations.length - 1].stationShortCode
                : '',
        to: nextStations.length > 0 ? nextStations[0].stationShortCode : '',
    })

    private getTrainScheduleStatusRoute = (
        ringRailTimetable: RingRailTimeTableRow[],
    ): RingRailTrainScheduleStatus =>
        max(ringRailTimetable.map(t => t.scheduleStatus)) || RingRailTrainScheduleStatus.Unknown

    private getTrainStatus = (
        cancelled: boolean,
        next: RingRailTimeTableRow[],
        current?: RingRailTimeTableRow,
    ): RingRailTrainStatus => {
        const matcher = PredicateMatcher.of<
            { cancelled: boolean; next: RingRailTimeTableRow[]; current?: RingRailTimeTableRow },
            RingRailTrainStatus
        >()
            .caseWhen(d => d.cancelled === true, RingRailTrainStatus.Canceled)
            .caseWhen(d => isDefined(d.current), RingRailTrainStatus.AtTheStation)
            .caseWhen(d => isUndefined(d.current) && d.next.length > 0, RingRailTrainStatus.Running)
            .else(RingRailTrainStatus.Unknown)

        return matcher.match({ cancelled, next, current }) || RingRailTrainStatus.Unknown
    }

    private getIsCurrentStation = (
        arrivalData?: TimeTableRow,
        departureData?: TimeTableRow,
    ): boolean =>
        isDefined(arrivalData) &&
        isDefined(arrivalData.actualTime) &&
        (isUndefined(departureData) || isUndefined(departureData.actualTime))

    private getScheduleStatus = (differenceInMinutes?: number): RingRailTrainScheduleStatus => {
        const matcher = PredicateMatcher.of<number | undefined, RingRailTrainScheduleStatus>()
            .caseWhen(d => isUndefined(d), RingRailTrainScheduleStatus.Unknown)
            .caseWhen(d => d! <= 0, RingRailTrainScheduleStatus.OnTime)
            .caseWhen(
                d => d! > TRAIN_SCHEDULE_THRESHOLD_DELAYED,
                RingRailTrainScheduleStatus.Delayed,
            )
            .caseWhen(
                d => d! > TRAIN_SCHEDULE_THRESHOLD_AT_RISK,
                RingRailTrainScheduleStatus.AtRisk,
            )
            .else(RingRailTrainScheduleStatus.Unknown)

        return matcher.match(differenceInMinutes) || RingRailTrainScheduleStatus.Unknown
    }

    private getStationArrivalTimes = (
        timeScheduleData?: TimeTableRow,
    ): Partial<RingRailTimeTableRow> =>
        timeScheduleData
            ? {
                  arrivalScheduledTime: timeScheduleData.scheduledTime
                      ? dateTime(timeScheduleData.scheduledTime)
                      : undefined,
                  arrivalEstimateTime: timeScheduleData.liveEstimateTime
                      ? dateTime(timeScheduleData.liveEstimateTime)
                      : undefined,
                  arrivalActualTime: timeScheduleData.actualTime
                      ? dateTime(timeScheduleData.actualTime)
                      : undefined,
              }
            : {}

    private getStationDepartureTimes = (
        timeScheduleData?: TimeTableRow,
    ): Partial<RingRailTimeTableRow> =>
        timeScheduleData
            ? {
                  departureScheduledTime: timeScheduleData.scheduledTime
                      ? dateTime(timeScheduleData.scheduledTime)
                      : undefined,
                  departureEstimateTime: timeScheduleData.liveEstimateTime
                      ? dateTime(timeScheduleData.liveEstimateTime)
                      : undefined,
                  departureActualTime: timeScheduleData.actualTime
                      ? dateTime(timeScheduleData.actualTime)
                      : undefined,
              }
            : {}

    private getStationCommercialTrack = (
        arrivalData?: TimeTableRow,
        departureData?: TimeTableRow,
    ): string => {
        const arrivalTrack = arrivalData ? arrivalData.commercialTrack : ''
        const departureTrack = departureData ? departureData.commercialTrack : ''

        return arrivalTrack && departureTrack && arrivalTrack !== departureTrack
            ? `${arrivalTrack} - ${departureTrack}`
            : arrivalTrack || departureTrack || ''
    }

    private getStationDelayInMinutes = (
        arrivalData?: TimeTableRow,
        departureData?: TimeTableRow,
    ): number | undefined => {
        const arrivalDiff = arrivalData ? arrivalData.differenceInMinutes : undefined
        const departureDiff = departureData ? departureData.differenceInMinutes : undefined

        return isUndefined(arrivalDiff) && isUndefined(departureDiff)
            ? undefined
            : Math.max(arrivalDiff || 0, departureDiff || 0)
    }
}

export const ringRailTrainMapper: RingRailTrainMapper = new RingRailTrainMapper()
