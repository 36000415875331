import { compact } from 'lodash'
import React, { PureComponent } from 'react'

import { PictogramTextIcon } from './PictogramTextIcon'

interface GateIconProps {
    text: string
}

// tslint:disable:max-line-length
export class GateIcon extends PureComponent<GateIconProps> {
    public render() {
        const [t1, t2] = this.text
        return (
            <PictogramTextIcon t1={t1} t2={t2}>
                <path
                    d='M19.712 40.664L13 33.328s2.281-1.155 2.623-1.302c.484-.203.946-.204 1.588.2.445.282 6.227 3.727 6.227 3.727s7.97-4.214 16.185-8.53L25.26 18.317s1.795-.799 2.385-1.042c.647-.268 1.256-.236 1.865-.043.27.082 20.972 4.798 20.972 4.798 10.528-5.533 14.02-7.385 17.545-7.844 4.058-.527 6.244.02 5.783 2.239-.563 2.713-5.797 5.415-17.57 12.606 0 0-9.56 25.168-10.037 26.31-.262.623-.62 1.101-1.26 1.374-.642.27-3.891 1.281-3.891 1.281l1.75-23.25c-6.48 2.946-8.324 3.203-11.545 4.116-5.341 1.514-11.547 1.802-11.547 1.802h.001z'
                    fill='#FFFFFF'
                    fillRule='nonzero'
                />
            </PictogramTextIcon>
        )
    }

    private get text(): [string, string | undefined] {
        return compact(this.props.text.split(/([0-9]+)/)) as [string, string | undefined]
    }
}
