import { Color } from 'aos-ui-common/src/styles/Color'
import { xor } from 'lodash'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { IdAware } from '../../base/IdAware'
import { CleanValueButton } from '../../buttons/CleanValueButton'
import { IconVariant } from '../../svg/Icon'
import { DropdownVariant } from './base/DropdownVariant'
import { DropdownWidth } from './base/DropdownWidth'
import { CheckboxDropdown } from './CheckboxDropdown'

export interface CheckboxColorDropdownProps<T extends keyof any> extends IdAware {
    allContent: ReactNode
    colors: Record<T, Color>
    items: T[]
    value: T[]
    variant?: DropdownVariant
    width?: DropdownWidth | number
    small?: boolean
    preventEmpty?: boolean

    onChange(v: T[]): void
    valueRenderer(v: T): ReactNode
    clearAction?(): void
}

export const CheckboxColorDropdown = <T extends keyof any>(
    props: CheckboxColorDropdownProps<T>,
) => {
    const {
        id,
        items,
        variant = DropdownVariant.White,
        valueRenderer,
        width,
        onChange,
        clearAction,
        small,
        value,
        colors,
        allContent,
        preventEmpty = false,
    } = props

    const customChange = (v: T[]) => {
        if (v.length === 0) {
            onChange(items)
        } else {
            onChange(v)
        }
    }

    const cleanItem = (i: T) => {
        customChange(xor(value, [i]))
    }

    const item = (i: T, index: number) => (
        <BoxItem
            color={colors[i]}
            key={index}
            paddingHorizontal={4}
            paddingVertical={2}
            marginRight={4}
        >
            <CleanValueButton onClick={() => cleanItem(i)} iconVariant={IconVariant.White} />
        </BoxItem>
    )

    const partialContent = (values: T[]) => <Box row>{values.map(item)}</Box>

    return (
        <CheckboxDropdown
            value={value}
            items={items}
            variant={variant}
            id={id}
            width={width}
            small={small}
            clearAction={clearAction}
            onChange={customChange}
            partialContent={partialContent}
            allContent={allContent}
            valueRenderer={valueRenderer}
            preventEmpty={preventEmpty}
        />
    )
}

const BoxItem = styled(Box)<{ color: Color }>`
    width: 32px;
    height: 20px;
    border-radius: 3px;
    background-color: ${p => p.color};
`
