import { PieArcDatum } from 'd3-shape'

import { getArcInnerOuterRadius } from '../arc/getArcInnerOuterRadius'
import { getColor } from '../helpers/getColor'
import { GaugeLabel } from '../types/GaugeLabel'
import { GaugeOptions } from '../types/GaugeOptions'
import { GaugeRange } from '../types/GaugeRange'
import { getCorner } from './getCorner'
import { getLabelsToShow } from './getLabelsToShow'
import { getTickPosition } from './getTickPosition'

export const createLabels = (
    datum: PieArcDatum<GaugeRange>,
    options: GaugeOptions,
): GaugeLabel[] => {
    const labels = getLabelsToShow(datum.data, options)

    if (!labels) {
        return []
    }

    const { showLeft, showRight } = labels
    const {
        startAngle,
        endAngle,
        data: { from, to, status },
    } = datum
    const { outerRadius } = getArcInnerOuterRadius(options)

    const centerAngle = (startAngle + endAngle) / 2
    const tickRadius = outerRadius + 2
    const labelsRadius = outerRadius + options.size / 10

    const textSize = options.size / 16
    const fill = getColor(status)

    const center: GaugeLabel = {
        position: getCorner(0, centerAngle, labelsRadius),
        tickPosition: getTickPosition(0, centerAngle, tickRadius, options),
        value: (to + from) / 2,
        textSize,
        fill,
    }

    if (from === to && (showLeft || showRight)) {
        return [center]
    }

    const left: GaugeLabel = {
        position: getCorner(+1, startAngle, labelsRadius),
        tickPosition: getTickPosition(+1, startAngle, tickRadius, options),
        value: from,
        textSize,
        fill,
    }

    if (showLeft && !showRight) {
        return [left]
    }

    const right: GaugeLabel = {
        position: getCorner(-1, endAngle, labelsRadius),
        tickPosition: getTickPosition(-1, endAngle, tickRadius, options),
        value: to,
        textSize,
        fill,
    }

    if (!showLeft && showRight) {
        return [right]
    }

    return [left, right]
}
