import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'

export const LOAD_UPCOMING_TASKS = 'TASKS/LOAD_UPCOMING_TASKS'
export type LoadUpcomingTasksAction = Action<typeof LOAD_UPCOMING_TASKS>
export const loadUpcomingTasksAction = emptyActionCreator<LoadUpcomingTasksAction>(
    LOAD_UPCOMING_TASKS,
)

export const LOAD_UPCOMING_TASKS_SUCCESS = 'TASKS/LOAD_UPCOMING_TASKS_SUCCESS'
export type LoadUpcomingTasksSuccessAction = PayloadAction<
    typeof LOAD_UPCOMING_TASKS_SUCCESS,
    TaskSnapshot[]
>
export const loadUpcomingTasksSuccessAction = payloadActionCreator<
    LoadUpcomingTasksSuccessAction,
    TaskSnapshot[]
>(LOAD_UPCOMING_TASKS_SUCCESS)

export const LOAD_UPCOMING_TASKS_FAILURE = 'TASKS/LOAD_UPCOMING_TASKS_FAILURE'
export type LoadUpcomingTasksFailureAction = Action<typeof LOAD_UPCOMING_TASKS_FAILURE>
export const loadUpcomingTasksFailureAction = emptyActionCreator<LoadUpcomingTasksFailureAction>(
    LOAD_UPCOMING_TASKS_FAILURE,
)

export type UpcomingTasksAction =
    | LoadUpcomingTasksAction
    | LoadUpcomingTasksSuccessAction
    | LoadUpcomingTasksFailureAction
