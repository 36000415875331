import { initialTaskChecklistManagerState } from '../state'
import {
    HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL,
    HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL,
    LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS,
    OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL,
    SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL,
    TaskChecklistManagerActions,
    UPDATE_TASK_CHECKLIST_TEMPLATE,
} from './actions'

export const taskChecklistReducer = (
    state = initialTaskChecklistManagerState,
    action: TaskChecklistManagerActions,
) => {
    switch (action.type) {
        case LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS:
            return {
                ...initialTaskChecklistManagerState,
                taskChecklistTemplates: action.payload,
            }
        case HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isCreateModalOpen: false,
            }
        case SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isCreateModalOpen: true,
            }
        case OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isEditModalOpen: true,
                editedTaskChecklistTemplate: action.payload,
            }
        case UPDATE_TASK_CHECKLIST_TEMPLATE:
            return {
                ...state,
                isEditModalOpen: false,
            }
        case HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isEditModalOpen: false,
                editedTaskChecklistTemplate: undefined,
            }
        default:
            return state
    }
}
