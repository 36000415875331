import { omit, pickBy } from 'lodash'
import { Query } from 'url'

import { FilterableAndPageable, Pageable, sortEntriesForPageable, SortEntry } from './Pageable'

export interface PageRequest {
    page: number
    size: number
}

export interface PageAndSortRequest extends PageRequest {
    sort: SortEntry[]
}

export interface FilterablePageAndSortRequest<T> extends PageAndSortRequest {
    filter: T
}

export interface FilterablePageRequest<T> extends PageRequest {
    filter: T
}

export const shrinkPageRequest = <T extends PageRequest>(p: T, total: number): T =>
    Object.assign(p, { page: Math.min(Math.floor(total / p.size), p.page) })

export const pageRequestFromPageable = (pg: Pageable<any>): PageRequest =>
    shrinkPageRequest(
        {
            page: pg.number,
            size: pg.size,
        },
        pg.totalElements,
    )
// TODO remove
export const pageAndSortRequestFromPageable = (pg: Pageable<any>): PageAndSortRequest =>
    shrinkPageRequest(
        {
            page: pg.number,
            size: pg.size,
            sort: sortEntriesForPageable(pg),
        },
        pg.totalElements,
    )

export const filterablePageRequestBuilder = <T, U>(
    f: FilterableAndPageable<T, U>,
): FilterablePageRequest<U> => ({
    ...pageRequestFromPageable(f.data),
    filter: f.filter,
})

export const filterablePageAndSortRequestBuilder = <T, U>(
    f: FilterableAndPageable<T, U>,
    req?: Partial<FilterablePageAndSortRequest<U>>,
): FilterablePageAndSortRequest<U> =>
    shrinkPageRequest(
        {
            ...pageAndSortRequestFromPageable(f.data),
            filter: f.filter,
            ...pickBy(req, a => a !== undefined),
        },
        f.data.totalElements,
    )

export const pageAndSortRequestBuilder = (
    f: Pageable<any>,
    req?: Partial<PageAndSortRequest>,
): PageAndSortRequest =>
    shrinkPageRequest(
        {
            ...pageAndSortRequestFromPageable(f),
            ...req,
        },
        f.totalElements,
    )

export const pageRequestToQuery = (
    pr: FilterablePageAndSortRequest<any> | PageAndSortRequest,
): Query => ({
    ...omit(pr, 'sort', 'filter'),
    sortBy: pr.sort.map(t => t[0]),
    sortOrder: pr.sort.map(t => t[1]),
})

export const sortEntryToQuery = (sortEntry: SortEntry[]): Query => ({
    sortBy: sortEntry.map(t => t[0]),
    sortOrder: sortEntry.map(t => t[1]),
})
