import {
    DndDropSpec,
    DndItemType,
    draggableWrapper,
    droppableWrapper,
} from 'aos-components/src/helpers/DnD'
import { DnDWrapper } from 'aos-components/src/helpers/DnDWrapper'
import { BaseDragSourceProps, BaseDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistItemType } from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import { mapChecklistUnitToUserRole } from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import { AosUnitTaskItem } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import { DarkListItemTextBlock } from 'aos-ui/src/components/darkListItem/DarkListItemTextBlock'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { DragSourceSpec } from 'react-dnd'
import styled from 'styled-components'

import { ChecklistItemIcon } from '../../../common/ChecklistItemIcon'

const UnitTaskItemComponent: FC<UnitTaskItemProps & BaseDragSourceProps & BaseDropTargetProps> =
    React.memo(
        ({
            isEditable,
            connectDropTarget,
            connectDragSource,
            isDragging,
            isEven,
            onDelete,
            onEdit,
            item,
        }) => {
            const content = () => (
                <Box rowGap={8} column>
                    <DarkListItemTextBlock title={item.content} noTextOverflow />
                    {item.additionalNote && (
                        <Box>
                            <Text lineHeightPx={20} size={12} weight='light'>
                                {translate('tasklist-manager.item.additional-notes')}
                            </Text>
                            <NoteTemplate
                                lineHeightPx={20}
                                paddingLeft={4}
                                size={12}
                                weight='light'
                                color={Color.GreyLight}
                            >
                                {item.additionalNote}
                            </NoteTemplate>
                        </Box>
                    )}
                </Box>
            )

            return (
                <DnDWrapper
                    draggable={isEditable}
                    droppable={isEditable}
                    connectDragSource={connectDragSource}
                    connectDropTarget={connectDropTarget}
                >
                    <DarkListItem
                        row
                        paddingVertical={12}
                        justify='space-between'
                        marginBottom={12}
                        paddingHorizontal={12}
                        isDragging={isDragging}
                        cursor={isEditable ? 'pointer ' : 'default'}
                        lightBg={!isEven}
                    >
                        <MoreHover row flex={1} alignContent='flex-start'>
                            <Box rowGap={12} flex={1} column alignItems='flex-start'>
                                <BorderedChip size={12} weight='regular'>
                                    {translate(`unit.${mapChecklistUnitToUserRole[item.unit]}`)}
                                </BorderedChip>
                                <Box alignItems='flex-start' flex={1} row>
                                    <ChecklistItemIcon type={AosChecklistItemType.Task} />
                                    {content()}
                                </Box>
                            </Box>
                            <Box>
                                {isEditable && (
                                    <More>
                                        <MoreItem
                                            onClick={() => {
                                                onDelete(item)
                                            }}
                                        >
                                            {translate('checklist-manager.item-more.delete')}
                                        </MoreItem>
                                        <MoreItem onClick={onEdit}>
                                            {translate('checklist-manager.item-more.edit')}
                                        </MoreItem>
                                    </More>
                                )}
                            </Box>
                        </MoreHover>
                    </DarkListItem>
                </DnDWrapper>
            )
        },
    )

const NoteTemplate = styled(Text)`
    border-left: 1px solid ${Color.GreyLight};
`

const unitTaskItemSpec: DragSourceSpec<UnitTaskItemProps, UnitTaskItemProps> = {
    beginDrag(props) {
        return {
            ...props,
        }
    },
    endDrag(props) {
        props.onApplyMove()
    },
}

const unitTaskItemTarget: DndDropSpec<UnitTaskItemProps> = {
    hover(dropElementProps, monitor) {
        const dragElementProps = monitor.getItem() as UnitTaskItemProps
        if (dragElementProps && dragElementProps.seq !== dropElementProps.seq) {
            dragElementProps.onMove(dragElementProps.seq, dropElementProps.seq)
        }
    },
}

const dndItem = (type: DndItemType) =>
    droppableWrapper<UnitTaskItemProps>(
        type,
        unitTaskItemTarget,
    )(
        draggableWrapper<UnitTaskItemProps, UnitTaskItemProps>(
            type,
            unitTaskItemSpec,
        )(UnitTaskItemComponent),
    )

export const UnitTaskItem = dndItem(DndItemType.UnitTaskItem)

interface UnitTaskItemProps {
    item: AosUnitTaskItem
    isEditable?: boolean
    isEven: boolean
    seq: number
    onMove(v: number, v2: number): void
    onApplyMove(): void
    onEdit(): void
    onDelete(v: AosUnitTaskItem): void
}
