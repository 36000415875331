import { isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import {
    AosHandlingAgent,
    mapHandlingAgentToCompany,
} from '../../../flightInformation/types/AosHandlingAgent'
import { FilterOptionAll, filterOptionsWithAll } from './common'

export type HandlingAgentFilter = FilterOptionAll | AosHandlingAgent

export const handlingAgentFilterValues: HandlingAgentFilter[] = filterOptionsWithAll(
    AosHandlingAgent,
)

export const translateHandlingAgent = (value: HandlingAgentFilter): string =>
    isValidEnumEntry(AosHandlingAgent)<AosHandlingAgent>(value)
        ? mapHandlingAgentToCompany(value)
        : translateEnum(FilterOptionAll, 'dashboard.baggage-delivery.handling-agent-tab')(value)
