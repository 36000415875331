import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { uuid } from 'aos-helpers/src/helpers/Uuid'

import { AosAirport } from '../../../flightInformation/types/AosAirport'
import { UserResult } from '../../../users/types/UserResult'

export interface FirefighterFormFields<T = FirefighterFiretruck> {
    id?: number
    validUntil?: DateTime
    reporter?: UserResult
    fireFighterSquads: T[]
    siteLocation?: AosAirport
}

export interface FiretruckMemberViewModel {
    id?: number
    key?: string
    user?: UserResult
    assignment: {
        lp3: boolean
        lp5: boolean
        kat12: boolean
    }
}

export interface FiretruckMember {
    id?: number
    key?: string
    user?: UserResult
    lp3: boolean
    lp5: boolean
    kat12: boolean
}

export interface FirefighterFiretruckViewModel {
    id?: number
    fireTruckId: number | undefined
    fireFighters: FiretruckMemberViewModel[]
    fireTruck?: FireTruck
}

export interface FirefighterFiretruck {
    id?: number
    fireTruckId: number | undefined
    fireFighters: FiretruckMember[]
    fireTruck?: FireTruck
}

export interface FireTruck {
    id: number
    name: string
    evidenceNumber: string
    callSign: string
    siteLocation: string
}

export const getEmptyFiretruckMember = (): FiretruckMember => ({
    key: uuid(),
    lp3: false,
    lp5: false,
    kat12: false,
})

export const firefighterFormInitialState: FirefighterFormFields = {
    validUntil: dateTime().add(2, 'day'),
    fireFighterSquads: [],
}
