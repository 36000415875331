import { LoginAction, RESET_LOGIN, SET_LOGIN_STATUS } from './actions'
import { initialLoginState, LoginState } from './state'

export function loginReducer(
    state: LoginState = initialLoginState,
    action: LoginAction,
): LoginState {
    switch (action.type) {
        case SET_LOGIN_STATUS:
            return { ...state, loginStatus: action.payload }

        case RESET_LOGIN:
            return initialLoginState

        default:
            return state
    }
}
