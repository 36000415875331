import { TaskFormPayload } from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setPreviewLocationModalOpenAction } from '../../../core/tasks/actions'
import { TaskManagerStateAware } from '../../../core/tasks/state'
import { TasksMap } from './TasksMap'

interface TaskPreviewLocationModalProps {
    task: TaskFormPayload
    isOpen: boolean
}

interface TaskPreviewLocationModalDispatchProps {
    closeAction(): void
}

const TaskPreviewLocationModalComponent: FC<
    TaskPreviewLocationModalProps & TaskPreviewLocationModalDispatchProps
> = ({ task, closeAction, isOpen }) => (
    <Modal closeAction={closeAction} isOpen={isOpen} modalKind={ModalKind.Big} title={task.title}>
        <TasksMap />
    </Modal>
)

export const TaskPreviewLocationModal = connect<
    TaskPreviewLocationModalProps,
    TaskPreviewLocationModalDispatchProps
>(
    createStructuredSelector({
        isOpen: (state: TaskManagerStateAware) => state.taskManager.isPreviewModalOpen,
        task: (state: TaskManagerStateAware) => state.taskManager.payload,
    }),
    {
        closeAction: () => setPreviewLocationModalOpenAction(false),
    },
)(TaskPreviewLocationModalComponent)
