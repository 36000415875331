import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface BaggageIndicatorIconProps {
    color: Color
    variant: BaggageIndicatorIconVariant
}

export enum BaggageIndicatorIconVariant {
    Empty,
    HalfFull,
    Full,
}

// tslint:disable:max-line-length
export const BaggageIndicatorIcon: FC<BaggageIndicatorIconProps> = props => (
    <svg className='flex-shrink--0' width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fillRule='evenodd'>
            <path
                fill={props.color}
                d='M7.038 6.35V2.765c0-.303.245-.55.548-.55h8.783c.302 0 .548.247.548.55V6.35h5.148c.492 0 .89.398.89.889v13.747a.89.89 0 0 1-.89.889H1.888a.89.89 0 0 1-.888-.89V7.24c0-.491.398-.89.89-.89h5.148zm1.097 0h7.684V3.616a.487.487 0 0 0-.487-.487h-6.71a.487.487 0 0 0-.486.487V6.35zM2.557 8.143v12.151h18.84V8.144H2.557z'
            />
            {props.variant === BaggageIndicatorIconVariant.Full && (
                <path fill={props.color} d='M3.667 9.33h16.571v9.778H3.668z' />
            )}

            {props.variant === BaggageIndicatorIconVariant.HalfFull && (
                <path fill={props.color} d='M12.962 9.328l-4.639 9.738H3.686V9.328z' />
            )}
        </g>
    </svg>
)
