import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { FlightsSyncWrapper } from '../../base/FlightsSyncWrapper'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'

interface RegularityItemItemProps {
    itemProps: StatusDashboardItemCommonProps
    noDataWarning?: boolean
    link?: string
    syncId: SyncDataKey
}

export const RegularityItem: FC<PropsWithChildren<RegularityItemItemProps>> = props => (
    <FlightsSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.regularity.title')}
            description={translate('dashboard.regularity.description')}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </FlightsSyncWrapper>
)
