import { isUndefined, omitBy } from 'lodash'

import { firebaseEventsRepository } from '../../dataaccess/firebaseEvents/firebaseEventsRepository'
import { AosFirebaseEventDto } from '../../dataaccess/firebaseEvents/types/AosFirebaseEventDto'
import { PrivateChannelParticipantInput } from './input/PrivateChannelParticipantInput'
import { AosFirebaseEventImpl } from './types/AosFirebaseEventImpl'

class FirebaseEventsService {
    firebaseEventDtoToFirebaseEvent([key, dto]: [string, AosFirebaseEventDto]) {
        if (dto) {
            return new AosFirebaseEventImpl(dto, key)
        }
        return undefined
    }

    updateInvitation(input: PrivateChannelParticipantInput) {
        const payload = {
            ...input,
            arrivalTime: input.arrivalTime ? input.arrivalTime.valueOf() : undefined,
        }

        return firebaseEventsRepository
            .invitationRef(input.eventId, input.firebaseUid)
            .update(omitBy(payload, isUndefined))
    }

    updateChecklist(eventId: number, checklistId: number) {
        return firebaseEventsRepository
            .checklistRef(eventId)
            .update({ [checklistId]: { lastSync: Date.now() } })
    }

    updateVisibilityLayers(eventId: number, visibilityLayers: string[]) {
        return firebaseEventsRepository.eventRef(eventId).update({ visibilityLayers })
    }
}

export const firebaseEventsService = new FirebaseEventsService()
