import {
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { filterablePageAndSortRequestBuilder } from 'aos-helpers/src/helpers/PageRequest'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { groupService } from 'aos-services/src/services/users/groupService'
import { UserPageRequest } from 'aos-services/src/services/users/transport/UsersPageRequest'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { userManagementService } from 'aos-services/src/services/users/userManagementService'
import { call, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { State } from '../state'
import {
    applyUsersFilterAction,
    DOWNLOAD_GROUPS,
    LOAD_GROUPS,
    LOAD_USERS,
    LoadGroupsAction,
    loadGroupsAction,
    loadGroupsSuccessAction,
    LoadUsersAction,
    loadUsersAction,
    loadUsersSuccessAction,
    REMOVE_USER,
    REMOVE_USER_FROM_GROUP,
    REMOVE_USER_GROUP,
    REMOVE_USER_PERMANENTLY,
    RemoveUserAction,
    RemoveUserFromGroupAction,
    RemoveUserGroupAction,
    RemoveUserPermanentlyAction,
    RENAME_USER_GROUP,
    RESTORE_USER,
    RestoreUserAction,
    SAVE_NOTIFICATIONS_SETTINGS,
    SaveNotificationSettingsAction,
    saveNotificationSettingsSuccessAction,
    SELECT_GROUP,
    SelectGroupAction,
    SET_USERS_FILTER,
    setUserGroupExportingAction,
    SetUsersFilterAction,
} from './actions'
import { isFilterApplicable, reloadCommonUserGroups, reloadUserList } from './common/sagas'
import { createGroupSagas } from './createGroup/sagas'
import { addAdUserSagas } from './manageUser/adUser/sagas'
import { addContactSagas } from './manageUser/contact/sagas'
import { firstUserPageRequest, GroupManagerStateAware } from './state'

function* loadGroupsSaga() {
    function* loadGroups() {
        try {
            const groups: AosUserGroup[] = yield call(groupService.getAllGroups)
            yield put(loadGroupsSuccessAction(groups))
        } catch (e) {
            yield put(loadGroupsSuccessAction([]))
            throw e
        }
    }

    yield takeEvery<LoadGroupsAction>(LOAD_GROUPS, loadGroups)
}

function* selectGroupSaga() {
    function* selectGroup(action: SelectGroupAction) {
        const userPageRequest: UserPageRequest = yield select((t: State) =>
            firstUserPageRequest(t.groupManager),
        )
        yield put(loadUsersAction([action.payload.id, userPageRequest]))
    }

    yield takeEvery<SelectGroupAction>(SELECT_GROUP, selectGroup)
}

function* shouldLoadUsers(filter: string, selectedGroup: AosUserGroup) {
    const oldFilter: string = yield select((t: State) => t.groupManager.users.filter.searchUserText)
    const shouldApplyFilter: boolean = isFilterApplicable(filter) || isFilterApplicable(oldFilter)

    return selectedGroup && shouldApplyFilter
}

function* setUsersFilterSaga() {
    function* setUsersFilter(action: SetUsersFilterAction) {
        const selectedGroup: AosUserGroup = yield select((t: State) => t.groupManager.selectedGroup)
        const shouldRefreshUsers: boolean = yield shouldLoadUsers(action.payload, selectedGroup)

        yield put(applyUsersFilterAction(action.payload))

        if (shouldRefreshUsers) {
            yield delay(100)
            yield loadGroupUsers(selectedGroup)
        }
    }

    yield takeLatest<SetUsersFilterAction>(SET_USERS_FILTER, setUsersFilter)
}

function* loadGroupUsers(group: AosUserGroup) {
    const userPageRequest: UserPageRequest = yield select((t: State) =>
        filterablePageAndSortRequestBuilder(t.groupManager.users),
    )
    yield put(loadUsersAction([group.id, userPageRequest]))
}

function* loadUsersSaga() {
    function* loadUsers(action: LoadUsersAction) {
        const shouldApplyFilter = isFilterApplicable(action.payload[1].filter.searchUserText)
        const users = shouldApplyFilter
            ? ((yield call(
                  userManagementService.getFilteredGroupUsers,
                  action.payload[0],
                  action.payload[1],
              )) as Pageable<AosUser>)
            : ((yield call(
                  userManagementService.getGroupUsers,
                  action.payload[0],
                  action.payload[1],
              )) as Pageable<AosUser>)

        yield put(loadUsersSuccessAction(users))
    }

    yield takeEvery<LoadUsersAction>(LOAD_USERS, loadUsers)
}

function* removeUserSaga() {
    function* removeUser(action: RemoveUserAction) {
        yield call(userManagementService.removeUser, action.payload)
        yield reloadUserList()
    }

    yield takeEvery<RemoveUserAction>(REMOVE_USER, removeUser)
}

function* removeUserFromGroupSaga() {
    function* removeUserFromGroup(action: RemoveUserFromGroupAction) {
        yield call(groupService.removeUserFromGroup, action.payload[0].id, action.payload[1].id)
        yield reloadUserList()
    }

    yield takeEvery<RemoveUserFromGroupAction>(REMOVE_USER_FROM_GROUP, removeUserFromGroup)
}

function* restoreUserSaga() {
    function* restoreUser(action: RestoreUserAction) {
        yield call(userManagementService.restoreUser, action.payload.id)
        yield reloadUserList()
    }

    yield takeEvery<RestoreUserAction>(RESTORE_USER, restoreUser)
}

function* removeUserPermanentlySaga() {
    function* removeUserPermanently(action: RemoveUserPermanentlyAction) {
        yield call(userManagementService.removeUserPermanently, action.payload)
        yield reloadUserList()
    }

    yield takeEvery<RemoveUserPermanentlyAction>(REMOVE_USER_PERMANENTLY, removeUserPermanently)
}

function* removeUserGroupSaga() {
    function* removeUserGroup(action: RemoveUserGroupAction) {
        yield call(groupService.removeGroup, action.payload.id)
        yield put(loadGroupsAction())
        yield fork(reloadCommonUserGroups)
    }

    yield takeEvery<RemoveUserGroupAction>(REMOVE_USER_GROUP, removeUserGroup)
}

function* renameUserGroupSaga() {
    function* removeUserGroup(action: SubmitAction) {
        yield call(groupService.renameGroup, action.payload.payload, action.payload.value)
        yield put(loadGroupsAction())
        yield fork(reloadCommonUserGroups)
    }

    yield takeEveryChildAction<SubmitAction>(RENAME_USER_GROUP, SUBMIT, removeUserGroup)
}

function* downloadGroupsSaga() {
    yield takeEvery(DOWNLOAD_GROUPS, function* () {
        yield put(setUserGroupExportingAction(true))
        try {
            yield call(groupService.exportGroups)
            yield put(setUserGroupExportingAction(false))
        } catch (e) {
            yield put(setUserGroupExportingAction(false))
            throw e
        }
    })
}

function* saveGroupNotificationSettings() {
    yield takeEvery<SaveNotificationSettingsAction>(
        SAVE_NOTIFICATIONS_SETTINGS,
        function* (action) {
            const group: AosUserGroup = yield select(
                (state: GroupManagerStateAware) => state.groupManager.notificationSettingsGroup,
            )
            yield call(groupService.updateNotifications, group.id, action.payload)
            yield reloadUserList()
            yield put(saveNotificationSettingsSuccessAction())
        },
    )
}

export const groupManagerSagas = [
    loadGroupsSaga,
    loadUsersSaga,
    setUsersFilterSaga,
    selectGroupSaga,
    downloadGroupsSaga,
    removeUserSaga,
    removeUserFromGroupSaga,
    restoreUserSaga,
    removeUserPermanentlySaga,
    removeUserGroupSaga,
    renameUserGroupSaga,
    saveGroupNotificationSettings,
    ...createGroupSagas,
    ...addAdUserSagas,
    ...addContactSagas,
]
