import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { Input } from '../input/Input'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledReadOnlyInputProps extends MarginBoxProps, SeleniumProps {
    keyPrefix: string
    value?: string
    type?: string
    variant?: ThemeVariant
}

export const LabeledReadOnlyInput: FCWithChildren<LabeledReadOnlyInputProps> = ({
    variant,
    seleniumLocation,
    value,
    keyPrefix,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <Input type='text' value={value || ''} disabled />
        </LabeledFormElement>
    )
}
