import { TaskDuration } from 'aos-services/src/services/tasks/types/TaskStatus'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { translate } from '../../../../../aos-helpers/src/helpers/translations/Translations'
import { Box } from '../../base/Box'
import { DateTimeInput } from './DateTimeInput'

export const StartAndEndTimePicker = () => {
    const form = useFormContext<TaskDuration>()

    return (
        <Box>
            <Controller
                name='startTime'
                control={form.control}
                render={({ field, fieldState }) => (
                    <DateTimeInput
                        {...field}
                        {...fieldState}
                        label={translate('task-scheduling-accuracy.starttime')}
                    />
                )}
            />
            <Controller
                name='endTime'
                control={form.control}
                render={({ field, fieldState }) => (
                    <DateTimeInput
                        {...field}
                        {...fieldState}
                        label={translate('task-scheduling-accuracy.endtime')}
                    />
                )}
            />
        </Box>
    )
}
