import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

export const VerticalTabText: FCWithChildren<VerticalTabTextProps> = props => {
    const { isActive, title, note, onClick } = props

    const textColor = isActive ? Color.White : Color.Grey
    return (
        <Text
            paddingVertical={8}
            paddingLeft={20}
            cursor='pointer'
            size={14}
            onClick={onClick}
            color={textColor}
        >
            {title}
            {note && <Text as='span' size={10} color={textColor} paddingLeft={8}>{`${note}`}</Text>}
        </Text>
    )
}

interface VerticalTabTextProps {
    title: string
    isActive: boolean
    note?: string
    onClick?(): void
}
