import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'

export enum TimeRangeFilter {
    Until6 = 1,
    From6To10,
    From10To14,
    From14To18,
    After18,
}

export const isWithinRange = (timeRange: TimeRangeFilter, t: DateTime): boolean => {
    switch (timeRange) {
        case TimeRangeFilter.Until6:
            return t.isBefore(dateTime(6, 'HH'))

        case TimeRangeFilter.From6To10:
            return t.isBetween(dateTime(6, 'HH'), dateTime(10, 'HH'), undefined, '[)')

        case TimeRangeFilter.From10To14:
            return t.isBetween(dateTime(10, 'HH'), dateTime(14, 'HH'), undefined, '[)')

        case TimeRangeFilter.From14To18:
            return t.isBetween(dateTime(14, 'HH'), dateTime(18, 'HH'), undefined, '[)')

        case TimeRangeFilter.After18:
            return t.isSameOrAfter(dateTime(18, 'HH'))
    }
}
