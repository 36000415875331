import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { TaskRecurrenceHourly } from './TaskRecurrenceHourly'
import { TaskRecurrenceMonthly } from './TaskRecurrenceMonthly'
import { TaskRecurrenceType } from './TaskRecurrenceType'
import { TaskRecurrenceWeekly } from './TaskRecurrenceWeekly'

export type TaskRecurrence = TaskRecurrenceHourly | TaskRecurrenceWeekly | TaskRecurrenceMonthly

export const allTaskRecurrenceTypes: TaskRecurrenceType[] = EnumValues.getValues(TaskRecurrenceType)
export const translateTaskRecurrenceType = translateEnum<TaskRecurrenceType>(
    TaskRecurrenceType,
    'tasks.recurrence-type',
)
