import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { Text } from '../../base/Text'
import { LegendItem } from '../../ui/LegendItem'
import { TrendMeasure, TrendProps } from './TrendMeasure'

interface TrendInversedLegendItemProps extends TrendProps {
    labelColor: Color
    labelNote?: string
    compact?: boolean
}

export const TrendInversedLegendItem: FCWithChildren<TrendInversedLegendItemProps> = ({
    trend,
    label,
    labelColor,
    trendVariant,
    value,
    unit,
    subvalue,
    subunit,
    labelNote,
    subunitInversed,
    sizeVariant,
    compact,
    ...marginProps
}) => (
    <Box {...marginProps}>
        <LabelContainer compact={compact}>
            <LegendItem
                dotConfig={{ size: BlockSize.Tiny, mainColor: labelColor }}
                textColor={Color.White}
                textSize={22}
                fontWeight='light'
            >
                {label}
            </LegendItem>
            {labelNote && (
                <Text color={Color.Grey} size={10}>
                    {labelNote}
                </Text>
            )}
        </LabelContainer>
        <TrendMeasure
            value={value}
            unit={unit}
            subvalue={subvalue}
            subunit={subunit}
            subunitInversed={subunitInversed}
            trendVariant={trendVariant}
            trend={trend}
            sizeVariant={sizeVariant}
        />
    </Box>
)

const LabelContainer = styled(Box)<{ compact?: boolean }>`
    min-height: ${p => (p.compact ? 0 : 48)}px;
`
