import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { attachmentReducer } from 'aos-services/src/core/attachments/reducer'
import {
    CREATE_FOD_SUCCESS,
    EDIT_FOD_SUCCESS,
    FOD_CHANGE_FORM,
    FOD_CLEAR_FORM,
    FOD_PRISTINE_CLEAR_FORM,
    LOAD_FOD_SUCCESS,
} from 'aos-services/src/core/fod/actions'
import {
    LOAD_USER_LAYER_VISIBILITY_SUCCESS,
    SET_USER_LAYERS_VISIBILITY,
} from 'aos-services/src/core/userLayersVisibility/actions'
import { userLayerVisibilityReducer } from 'aos-services/src/core/userLayersVisibility/reducer'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import {
    BaseFodFormPayload,
    baseValidateFod,
} from 'aos-services/src/services/fod/types/FodNotesFormData'
import {
    getAirportPosition,
    helsinkiAirportPosition,
} from 'aos-services/src/services/map/mapPositions'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { MapAction, PICK_LOCATION } from 'aos-ui-map/src/core/actions'
import { mapReducer } from 'aos-ui-map/src/core/reducer'
import { setPickedLocationFromFod } from 'aos-ui-map/src/core/state'

import {
    FOD_ATTACHMENTS_PARENT,
    FOD_EDIT_MAP_PARENT,
    FOD_RELOAD_SUCCESS,
    FodWebActions,
    PREVIEW_FOD_LOCATION,
    SAVE_LOCATION,
    SET_AIRPORT,
    SET_FOD_CREATE_MODAL_OPEN,
    SET_FOD_EDIT_MODAL_OPEN,
    SET_PREVIEW_FOD_LOCATION_MODAL_OPEN,
} from './actions'
import { FodState, initialFodModalState, initialFodState } from './state'

export const fodReducer = (state = initialFodState, action: FodWebActions): FodState => {
    switch (action.type) {
        case SET_FOD_CREATE_MODAL_OPEN:
            return action.payload
                ? { ...state, isCreateModalOpen: action.payload }
                : { ...state, ...initialFodModalState, isCreateModalOpen: action.payload }
        case SET_FOD_EDIT_MODAL_OPEN:
            if (action.payload) {
                const payloadFromDto = {
                    ...action.payload,
                    discoveryDate: dateTime(action.payload.discoveryDate),
                } as BaseFodFormPayload

                return {
                    ...state,
                    isEditModalOpen: true,
                    payload: payloadFromDto,
                    form: state.form.modify(baseValidateFod(payloadFromDto)),
                    fodMap: setPickedLocationFromFod(
                        { ...state.fodMap, mode: MapMode.EditSingleLocation },
                        action.payload as FodDto,
                    ),
                    attachmentsState: {
                        ...state.attachmentsState,
                        attachments: action.payload.attachments,
                    },
                }
            }
            return { ...state, ...initialFodModalState, isEditModalOpen: false }
        case SAVE_LOCATION:
            return {
                ...state,
                form: FormValidation.fromFields(
                    baseValidateFod({
                        ...state.payload,
                        location: state.fodMap.pickedFod?.location,
                    }),
                    state.form.pristine,
                ),
                payload: { ...state.payload, location: state.fodMap.pickedFod?.location },
            }

        case PREVIEW_FOD_LOCATION:
            return {
                ...state,
                fodMap: setPickedLocationFromFod(
                    { ...state.fodMap, mode: MapMode.ViewSingleLocation },
                    action.payload as FodDto,
                ),
            }

        case FOD_EDIT_MAP_PARENT:
            switch (action.payload.type) {
                case PICK_LOCATION:
                    return {
                        ...state,
                        fodMap: {
                            ...state.fodMap,
                            pickedFod: { location: action.payload.payload } as FodDto,
                        },
                    }
                case SET_USER_LAYERS_VISIBILITY:
                case LOAD_USER_LAYER_VISIBILITY_SUCCESS:
                    return {
                        ...state,
                        userLayerVisibility: userLayerVisibilityReducer(
                            state.userLayerVisibility,
                            action.payload,
                        ),
                    }
                default:
                    return {
                        ...state,
                        fodMap: mapReducer(state.fodMap, action.payload as MapAction),
                    }
            }

        case FOD_CHANGE_FORM:
            const pickedFod = state.fodMap.pickedFod
            const payload = { ...state.payload, ...action.payload }
            return {
                ...state,
                payload,
                form: FormValidation.fromFields(baseValidateFod(payload), state.form.pristine),
                fodMap: {
                    ...state.fodMap,
                    pickedFod,
                },
            }

        case FOD_ATTACHMENTS_PARENT:
            return {
                ...state,
                attachmentsState: attachmentReducer(state.attachmentsState, action.payload),
            }

        case CREATE_FOD_SUCCESS:
            return {
                ...state,
                isCreateModalOpen: false,
            }
        case EDIT_FOD_SUCCESS:
            return {
                ...state,
                isEditModalOpen: false,
            }
        case LOAD_FOD_SUCCESS:
            return { ...state, list: action.payload }

        case FOD_RELOAD_SUCCESS:
            return { ...state, list: action.payload }

        case FOD_CLEAR_FORM:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
                attachmentsState: initialFodState.attachmentsState,
                fodMap: {
                    ...state.fodMap,
                    pickedFod: null,
                },
            }

        case FOD_PRISTINE_CLEAR_FORM:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
            }

        case SET_AIRPORT:
            return {
                ...state,
                fodMap: {
                    ...state.fodMap,
                    terminalMapState: {
                        ...state.fodMap.terminalMapState,
                        position: helsinkiAirportPosition,
                    },
                    worldMapState: {
                        ...state.fodMap.worldMapState,
                        position: getAirportPosition(action.payload as AosAirport),
                    },
                    variant:
                        action.payload === AosAirport.HEL ? MapVariant.Terminal : MapVariant.World,
                    siteLocationCustomization:
                        action.payload === AosAirport.HEL
                            ? MapSiteLocationCustomization.Helsinki
                            : MapSiteLocationCustomization.Other,
                },
            }
        case SET_PREVIEW_FOD_LOCATION_MODAL_OPEN:
            return action.payload
                ? { ...state, isPreviewModalOpen: action.payload }
                : { ...state, ...initialFodModalState, isPreviewModalOpen: action.payload }

        default:
            return state
    }
}
