import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'

import { Box, BoxProps, MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { Text } from '../../base/Text'
import { ThemeVariant } from '../../base/ThemeVariant'
import { SvgIcon } from '../../svg/SvgIcon'
import { SvgImage } from '../../svg/SvgImage'
import { Tooltip } from '../../tooltip/Tooltip'
import { FormLabel } from './FormLabel'

interface LabeledFormElementProps extends SeleniumProps, MarginBoxProps, BoxProps {
    label: ReactNode
    labelComment?: ReactNode
    isRequired?: boolean
    isError?: boolean
    errorMessage?: ReactNode
    variant?: ThemeVariant
    tooltip?: ReactNode | string
    horizontal?: boolean
    hideLabel?: boolean
}

export const LabeledFormElement: FCWithChildren<PropsWithChildren<LabeledFormElementProps>> = ({
    children,
    label,
    labelComment,
    isError,
    isRequired,
    seleniumLocation,
    errorMessage,
    variant = ThemeVariant.White,
    tooltip,
    horizontal,
    hideLabel,
    ...marginProps
}) => (
    <Box data-test-id={`labeled-${seleniumLocation}`} {...marginProps} row={horizontal}>
        <Box row justify='space-between'>
            {!hideLabel && (
                <FormLabel variant={variant} isError={isError} isRequired={isRequired}>
                    {label}
                </FormLabel>
            )}
            {tooltip && (
                <Tooltip white placement='top' body={tooltip} withArrow>
                    <div>
                        <SvgImage svg={SvgIcon.Info} />
                    </div>
                </Tooltip>
            )}
        </Box>
        {labelComment && (
            <Text color={Color.Grey3} size={14} lineHeight='large' marginBottom={8}>
                {labelComment}
            </Text>
        )}
        {children}
        {isError && errorMessage && (
            <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                {errorMessage}
            </Text>
        )}
    </Box>
)
