import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { AxisScale } from 'd3-axis'
import { scaleBand, scaleLinear, scaleTime } from 'd3-scale'
import { range } from 'lodash'

import { AxisBandConfig } from './ChartProps'
import { Range } from './types/Range'

export class Scales {
    public static xScale = (xRange: Range, domain: Domain<DateTime>) =>
        Scales.scaleDateTime(domain.domain, xRange)

    public static timeScale = (xRange: Range, domain: Domain<DateTime>) =>
        scaleTime().domain(domain.domain).rangeRound(xRange)

    public static xScaleBand = (xRange: Range, config: AxisBandConfig<any>) => {
        const x0 = scaleBand<DateTime>()
            .domain(config.domain.ticks)
            .rangeRound(xRange)
            .paddingInner(config.bandPadding.x0?.inner || 0)
            .paddingOuter(config.bandPadding.x0?.outer || 0)

        const x1 = scaleBand<number>()
            .rangeRound([0, x0.bandwidth()])
            .paddingInner(config.bandPadding.x1?.inner || 0.33)
            .paddingOuter(config.bandPadding.x1?.outer || 0)
            .domain(range(0, config.barCount))

        return {
            x0,
            x1,
        }
    }

    public static yScale = (yRange: Range, domain: Domain<number>) =>
        scaleLinear().domain(domain.domain).rangeRound(yRange)

    public static scaleDateTime = (domain: [DateTime, DateTime] | DateTime[], range: Range) => {
        const start = domain[0].valueOf()
        const end = domain[1].valueOf()
        const rangeStart = range[0]
        const rangeEnd = range[1]
        const scale = (x: DateTime) => {
            const xValue = x.valueOf()
            if (xValue < start || xValue > end) {
                return undefined
            }
            return ((xValue - start) / (end - start)) * (rangeEnd - rangeStart) + rangeStart
        }
        scale.domain = () => domain
        scale.copy = () => scale
        scale.range = () => range
        return scale as AxisScale<DateTime>
    }
}
