import { firebaseApp } from 'firebaseApp'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { AlertsStateDto } from './types/AlertsStateDto'

class FirebaseAlertsRepository {
    public ref = (location: AosAirport) => firebaseApp.database().ref(`alerts/${location}`)

    public update = (location: AosAirport, payload: AlertsStateDto) =>
        this.ref(location).update(payload)
}

export const firebaseAlertsRepository = new FirebaseAlertsRepository()
