import { UserResult } from 'aos-services/src/services/users/types/UserResult'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { truncate } from 'lodash'
import React, { FC } from 'react'

import { findUsers } from './findUsers'

export interface UserNameAutocompleteProps {
    value?: UserResult
    onChange(value?: UserResult): void
    placeholder?: string
    trimLongValues?: boolean
}

export const UserNameAutocomplete: FC<UserNameAutocompleteProps> = ({
    value,
    onChange,
    placeholder,
    trimLongValues,
}) => {
    const trimmedValue = (value: UserResult) =>
        trimLongValues ? truncate(value?.name, { length: 20, omission: '...' }) : value?.name
    return (
        <DropdownAutocomplete
            value={value}
            items={findUsers}
            variant={DropdownVariant.White}
            valueRenderer={trimmedValue}
            placeholder={placeholder}
            onChange={onChange}
            labelRenderer={trimmedValue}
            outlined
        />
    )
}
