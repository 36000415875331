import { FormValidation, isEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AosUserLocalisation } from 'aos-services/src/services/users/types/AosUserLocalisation'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

export enum TabId {
    UserSettings = 1,
    NotificationSettings,
}

export interface UserProfileModalStateAware {
    userProfileModal: UserProfileModalState
}

export interface UserProfileFormState {
    notificationPreferences?: AosUserNotificationsPreferences
    siteLocation?: AosAirport
    localisation?: AosUserLocalisation
}

export interface UserProfileModalState {
    activeTab: TabId
    userProfileModal: boolean
    form: FormValidation<UserProfileSettingsValidationState>
    formState: UserProfileFormState
    profileUpdateInProgress: boolean
}

export interface UserProfileSettingsValidationState {
    siteLocation: boolean
    localisation: boolean
}

export const validateUserProfile = (
    s: UserProfileFormState,
): UserProfileSettingsValidationState => ({
    siteLocation: !isEmpty(s.siteLocation),
    localisation: !isEmpty(s.localisation),
})

export const initialUserProfileState: UserProfileModalState = {
    userProfileModal: false,
    profileUpdateInProgress: false,
    activeTab: TabId.UserSettings,
    formState: {},
    form: FormValidation.fromFields(validateUserProfile({})),
}
