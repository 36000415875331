import { restrictionsRestService } from '../../dataaccess/restrictions/restrictionsRestService'
import { Restriction } from './types/Restriction'
import { RestrictionImpl } from './types/RestrictionImpl'

class RestrictionsService {
    public async loadRestrictions(): Promise<Restriction[]> {
        const restrictions = await restrictionsRestService.getAllRestrictions()
        return restrictions.map(restriction => new RestrictionImpl(restriction))
    }
}

export const restrictionsService = new RestrictionsService()
