import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box } from '../base/Box'
import { AttachmentEditToolbar } from './AttachmentEditToolbar'
import { PreviewItem } from './PreviewItem'

export interface PreviewViewBoxProps {
    attachment: UploadedAttachment
    next?(): void
    previous?(): void
    close(): void
}

export const PreviewViewBox: FCWithChildren<PreviewViewBoxProps> = props => (
    <Wrapper centered>
        <PreviewItem attachment={props.attachment} />
        <AttachmentEditToolbar
            onClose={props.close}
            editable={false}
            next={props.next}
            previous={props.previous}
        />
    </Wrapper>
)

const Wrapper = styled(Box)`
    width: 100vw;
    height: 100vh;
    padding: 10vh 10vw;
`
