import { conditionalFunction } from 'aos-helpers/src/helpers/Function'
import { AttachmentUpdatePayload } from 'aos-services/src/services/attachments/types/AttachmentUpdatePayload'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Box } from '../base/Box'
import { AttachmentEditToolbar } from './AttachmentEditToolbar'
import { PreviewViewBoxProps } from './PreviewViewBox'

interface PreviewEditBoxState {
    angle: number
    isSaving: boolean
}

interface PreviewEditBoxProps extends PreviewViewBoxProps {
    updateAttachment?(v: AttachmentUpdatePayload): void
}

export class PreviewEditBox extends PureComponent<PreviewEditBoxProps, PreviewEditBoxState> {
    private canvas: HTMLCanvasElement | null = null
    private canvasContext: CanvasRenderingContext2D | null = null
    private image: HTMLImageElement | null = null
    private initialState: PreviewEditBoxState = {
        angle: 0,
        isSaving: false,
    }
    public state: PreviewEditBoxState = this.initialState

    public render() {
        const reset = conditionalFunction(this.state.angle !== 0, this.reset)
        const save = conditionalFunction(this.state.angle !== 0 && !this.state.isSaving, this.save)
        return (
            <Wrapper centered>
                <Canvas ref={this.setRef} />
                <AttachmentEditToolbar
                    onClose={this.props.close}
                    rotateLeft={this.rotateLeft}
                    rotateRight={this.rotateRight}
                    reset={reset}
                    save={save}
                    next={this.props.next}
                    previous={this.props.previous}
                    editable
                />
            </Wrapper>
        )
    }

    public componentDidMount() {
        this.loadImage(this.props)
    }

    public componentDidUpdate(_prevProps: PreviewEditBoxProps, prevState: PreviewEditBoxState) {
        if (this.state.angle !== prevState.angle) {
            this.redrawImage()
        }
    }

    public componentWillReceiveProps(next: PreviewEditBoxProps) {
        if (next.attachment.link !== this.props.attachment.link) {
            this.loadImage(next)
        }
    }

    private setRef = (canvas: HTMLCanvasElement | null) => {
        this.canvas = canvas
        if (this.canvas) {
            this.canvasContext = this.canvas.getContext('2d')!
        } else {
            this.canvasContext = null
        }
    }

    private loadImage = (props: PreviewEditBoxProps) => {
        if (!this.image) {
            this.image = document.createElement('img')
            this.image.crossOrigin = 'Anonymous'
            this.image.onload = this.imageLoaded
        }
        this.image.src = props.attachment.link
    }

    private imageLoaded = () => {
        if (this.state.angle !== 0) {
            this.setState(this.initialState)
        } else {
            this.redrawImage()
        }
    }

    private redrawImage = () => {
        if (this.canvasContext && this.canvas && this.image) {
            this.prepareCanvas()
            this.canvasContext.save()
            this.canvasContext.translate(this.canvas.width / 2, this.canvas.height / 2)
            this.canvasContext.rotate((this.state.angle * Math.PI) / 180)
            this.canvasContext.drawImage(this.image, -this.image.width / 2, -this.image.height / 2)
            this.canvasContext.restore()
        }
    }

    private prepareCanvas = () => {
        if (this.canvasContext && this.canvas && this.image) {
            this.canvas.width = this.isOrientationChanged ? this.image.height : this.image.width
            this.canvas.height = this.isOrientationChanged ? this.image.width : this.image.height
        }
    }

    private get isOrientationChanged() {
        return this.state.angle % 180 !== 0
    }

    private rotateRight = () => {
        this.rotate(90)
    }

    private rotateLeft = () => {
        this.rotate(-90)
    }

    private rotate = (angle: number) => {
        let newAngle = this.state.angle + angle
        if (newAngle < 0) {
            newAngle += 360
        }
        if (newAngle >= 360) {
            newAngle -= 360
        }
        this.setAngle(newAngle)
    }

    private setAngle = (angle: number) => {
        this.setState({ ...this.state, angle })
    }

    private setIsSaving = () => {
        this.setState({ ...this.state, isSaving: true })
    }

    private reset = () => {
        this.setAngle(0)
    }

    private save = () => {
        const currentUrl = this.props.attachment.link
        if (this.props.updateAttachment && currentUrl && this.canvas) {
            this.setIsSaving()
            this.canvas.toBlob(data => {
                this.props.updateAttachment!({
                    url: currentUrl,
                    data: data as Blob,
                })
            })
        }
    }
}

const Canvas = styled.canvas`
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
`

const Wrapper = styled(Box)`
    width: 100vw;
    height: 100vh;
    padding: 10vh 10vw;
`
