import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { DialogColumn, EventEditorDialogColumn } from './state'

export const TOGGLE_MENU = 'LAYOUT/TOGGLE_MENU'
export type ToggleMenuAction = Action<typeof TOGGLE_MENU>
export const toggleMenuAction = emptyActionCreator<ToggleMenuAction>(TOGGLE_MENU)

export const TOGGLE_WHATS_NEW = 'LAYOUT/TOGGLE_WHATS_NEW'
export type ToggleWhatsNewAction = PayloadAction<typeof TOGGLE_WHATS_NEW, boolean>
export const toggleWhatsNewAction = payloadActionCreator<ToggleWhatsNewAction, boolean>(
    TOGGLE_WHATS_NEW,
)

export const SYNC_EVENT_MANAGER_DOT = 'LAYOUT/SYNC_EVENT_MANAGER_DOT'
export type SyncEventManagerDotAction = Action<typeof SYNC_EVENT_MANAGER_DOT>
export const syncEventManagerDotAction =
    emptyActionCreator<SyncEventManagerDotAction>(SYNC_EVENT_MANAGER_DOT)

export const RELOAD_EVENT_MANAGER_DOT = 'LAYOUT/RELOAD_EVENT_MANAGER_DOT'
export type ReloadEventManagerDotAction = Action<typeof RELOAD_EVENT_MANAGER_DOT>
export const reloadEventManagerDotAction =
    emptyActionCreator<ReloadEventManagerDotAction>(RELOAD_EVENT_MANAGER_DOT)

export const SET_EVENT_MANAGER_DOT = 'LAYOUT/SET_EVENT_MANAGER_DOT'
export type SetEventManagerDotAction = PayloadAction<typeof SET_EVENT_MANAGER_DOT, boolean>
export const setEventManagerDotAction = payloadActionCreator<SetEventManagerDotAction, boolean>(
    SET_EVENT_MANAGER_DOT,
)

export const RESIZE_COLUMN = 'LAYOUT/RESIZE_COLUMN'
export type ResizeColumnAction = PayloadAction<typeof RESIZE_COLUMN, [DialogColumn, number[]]>
export const resizeColumnAction = payloadActionCreator<
    ResizeColumnAction,
    [DialogColumn, number[]]
>(RESIZE_COLUMN)
export const resizeColumnActionFactory =
    (dialogColumn: DialogColumn) =>
    (value: number[]): ResizeColumnAction =>
        resizeColumnAction([dialogColumn, value])

export const RESIZE_EVENT_EDITOR_COLUMN = 'LAYOUT/RESIZE_EVENT_EDITOR_COLUMN'
export type ResizeEventEditorColumnAction = PayloadAction<
    typeof RESIZE_EVENT_EDITOR_COLUMN,
    [EventEditorDialogColumn, number[]]
>
export const resizeEventEditorColumnAction = payloadActionCreator<
    ResizeEventEditorColumnAction,
    [EventEditorDialogColumn, number[]]
>(RESIZE_EVENT_EDITOR_COLUMN)

export type LayoutAction =
    | ToggleMenuAction
    | ToggleWhatsNewAction
    | ReloadEventManagerDotAction
    | SetEventManagerDotAction
    | ResizeColumnAction
    | ResizeEventEditorColumnAction
