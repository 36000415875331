import { randomString } from 'aos-helpers/src/helpers/Random'
import { MqttRef } from 'aos-helpers/src/helpers/websocket/MqttSaga'
import { appConfig } from 'appConfig'

import { BaseRestService } from '../base/BaseRestService'
import { Station, Train, TrainLocation } from './types/RingRail'

class RingRailDataAccessService extends BaseRestService {
    public getTrainsRef = (): MqttRef => ({
        url: `${appConfig.wsOrigin}/rata-mqtt`,
        topic: 'trains-by-station/LEN',
        clientId: `finavia-aos${randomString(10)}`,
    })

    public getTrainLocationsRef = (): MqttRef => ({
        url: `${appConfig.wsOrigin}/rata-mqtt`,
        topic: 'train-locations/#',
        clientId: `finavia-aos${randomString(10)}`,
    })

    public getCurrentTrains = (): Promise<Train[]> =>
        this.getAndTransform<Train[]>(
            'https://rata.digitraffic.fi/api/v1/live-trains/station/LEN',
            BaseRestService.jsonTransformer,
        )

    public getCurrentTrainLocations = (): Promise<TrainLocation[]> =>
        this.getAndTransform<Train[]>(
            'https://rata.digitraffic.fi/api/v1/train-locations/latest',
            BaseRestService.jsonTransformer,
        )

    public getTrainStations = (): Promise<Station[]> =>
        this.getAndTransform<Station[]>(
            'https://rata.digitraffic.fi/api/v1/metadata/stations',
            BaseRestService.jsonTransformer,
        )
}

export const ringRailDataAccessService: RingRailDataAccessService = new RingRailDataAccessService()
