import React, { FCWithChildren } from 'react'

import { NumberValues } from '../../../base/Box'
import { DropdownItemWrapper, DropdownLabel, dropdownSizes } from './DropdownContent'
import { DropdownHeight } from './DropdownHeight'
import { DropdownVariant } from './DropdownVariant'

interface DropdownItemProps {
    variant: DropdownVariant
    height: DropdownHeight
    padding: NumberValues
    isSelected: boolean
    onClick(e: React.MouseEvent<any>): void
    separator?: boolean
}

export const DropdownItem: FCWithChildren<DropdownItemProps> = ({
    isSelected,
    children,
    variant,
    height,
    padding,
    separator,
    onClick,
}) => (
    <DropdownItemWrapper
        row
        onClick={onClick}
        variant={variant}
        isSelected={isSelected}
        paddingHorizontal={padding}
        itemHeight={dropdownSizes[height].itemHeight}
        separator={separator}
    >
        <DropdownLabel size={12} as='span' variant={variant} isSelected={isSelected}>
            {children}
        </DropdownLabel>
    </DropdownItemWrapper>
)
