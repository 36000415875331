import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

export const SidebarPlaceholder: FC = () => (
    <PanelPlaceholder
        svg={SvgIcon.InspectorPlaceholder}
        label={translate('map.inspector-description')}
    />
)
