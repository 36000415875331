import { Moment } from 'moment/moment'

export enum TobtKeyTypes {
    airline = 'airline',
    handler = 'handler',
}

export enum TobtTimeRange {
    before = 'before',
    late = 'late',
    later = 'later',
    suspended = 'suspended',
    min0_5 = 'min0_5',
    min6_10 = 'min6_10',
    min11_20 = 'min11_20',
    min21_30 = 'min21_30',
    min31_40 = 'min31_40',
    min41_50 = 'min41_50',
    min51_60 = 'min51_60',
    min60 = 'min60',
}

export type TobtTimeRangeType = typeof TobtTimeRange[keyof typeof TobtTimeRange]

export type TobtTableData = {
    [key in TobtTimeRangeType]: string
} & {
    airline?: string
    handler?: string
}

export const TobtTimeColor = {
    [TobtTimeRange.min0_5]: '#F8BA00',
    [TobtTimeRange.later]: '#FF6D00',
    [TobtTimeRange.suspended]: '#E42626',
}

export enum TobtListKeys {
    airlineTobt = 'airlineTobt',
    hourTobt = 'hourTobt',
    handlerTobt = 'handlerTobt',
}
export interface AirlineTobtItem {
    airline: string
    tobtUpdate: string
    id: string
    percentage: number
    date: string
}

export interface HandlerTobsItem {
    handler: string
    tobtUpdate: string
    id: string
    percentage: number
    date: string
}

export interface HourTobtItem {
    dateTime: string
    tobtUpdate: string
    id: string
    percentage: number
    total: boolean
}

export type HourTobtItemUpdated = {
    [key in TobtTimeRangeType]: number
} & {
    date: Moment
    invertedValue: number
} & HourTobtItem

export type AirlineTobtList = AirlineTobtItem[]

export type HandlerTobsList = HandlerTobsItem[]

export type HourTobtList = HourTobtItem[]

export type HourTobtListUpdated = HourTobtItemUpdated[]

export type TobtList = {
    [key in TobtListKeys]: AirlineTobtList | HandlerTobsList | HourTobtList
}
