import { compose } from 'redux'
import persistState from 'redux-localstorage'
import adapter from 'redux-localstorage/lib/adapters/localStorage'
import filter from 'redux-localstorage-filter'

import { persistentPaths } from './state'

const storage = compose(filter(persistentPaths))(adapter(window.localStorage))

export const persistenceEnhancer = persistState(storage)
