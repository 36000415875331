import { DateTime } from 'aos-helpers/src/helpers/Time'
import { newsFeedTileSelector } from 'aos-services/src/core/statusDashboardData/newsFeed/selectors'
import { Feed } from 'aos-services/src/services/newsFeed/types/Feed'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { NewsFeedItemState } from '../../../../services/statusDashboard/types/itemStates/NewsFeedItemState'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { FeedItem } from './partials/FeedItem'
import { NewsFeedItem } from './partials/NewsFeedItem'

interface NewsFeedTileProps
    extends NewsFeedStateProps,
        StatusDashboardItemCommonProps<NewsFeedItemState> {}

export const NewsTileFeedComponent: FC<NewsFeedTileProps> = props => {
    const { newsFeed, carouselTrigger, itemState } = props
    return (
        <NewsFeedItem
            newsFeed={newsFeed}
            itemProps={props}
            link={link}
            filters={itemState.filters}
            syncId='overview'
        >
            <ItemContainer horizontal>
                <Carousel changeTrigger={carouselTrigger}>
                    {newsFeed && newsFeed.map(feed => <FeedItem key={feed.id} feed={feed} />)}
                </Carousel>
            </ItemContainer>
        </NewsFeedItem>
    )
}

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Events })

interface NewsFeedStateProps {
    newsFeed: Feed[]
    carouselTrigger: DateTime
}

export const NewsFeedTile = connect<NewsFeedStateProps>(
    createStructuredSelector({
        newsFeed: newsFeedTileSelector,
        carouselTrigger: carouselTriggerSelector,
    }),
)(NewsTileFeedComponent)
