import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { AnimalsTaskPayloadValidation } from 'aos-services/src/services/tasks/types/payload/AnimalsTaskFormPayload'
import { DeIcingTaskPayloadValidation } from 'aos-services/src/services/tasks/types/payload/DeIcingTaskFormPayload'
import { InspectionTaskPayloadValidation } from 'aos-services/src/services/tasks/types/payload/InspectionTaskFormPayload'
import { MaintenanceTaskPayloadValidation } from 'aos-services/src/services/tasks/types/payload/MaintenanceTaskFormPayload'
import { RescueServiceTaskPayloadValidation } from 'aos-services/src/services/tasks/types/payload/RescueServiceTaskFormPayload'
import {
    TaskFormPayload,
    TaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { TaskType } from 'aos-services/src/services/tasks/types/TaskType'
import React, { FC } from 'react'

import { AnimalsTaskForm } from './taskForms/AnimalsTaskForm'
import { DeIcingTaskForm } from './taskForms/DeIcingTaskForm'
import { InspectionTaskForm } from './taskForms/InspectionTaskForm'
import { MaintenanceTaskForm } from './taskForms/MaintenanceTaskForm'
import { RescueServiceTaskForm } from './taskForms/RescueServiceTaskForm'

interface DetailedTaskFormProps {
    form: FormValidation<TaskPayloadValidation>
    payload: TaskFormPayload
    metadata: TaskMetadata
    changeForm(t: Partial<TaskFormPayload>): void
}

export const DetailedTaskForm: FC<DetailedTaskFormProps> = ({
    form,
    payload,
    metadata,
    changeForm,
}) => {
    switch (payload.type) {
        case TaskType.AnimalPrevention:
            return (
                <AnimalsTaskForm
                    form={form as FormValidation<AnimalsTaskPayloadValidation>}
                    payload={payload}
                    metadata={metadata}
                    changeForm={changeForm}
                />
            )

        case TaskType.DeIcing:
            return (
                <DeIcingTaskForm
                    form={form as FormValidation<DeIcingTaskPayloadValidation>}
                    payload={payload}
                    metadata={metadata}
                    changeForm={changeForm}
                />
            )

        case TaskType.Maintenance:
            return (
                <MaintenanceTaskForm
                    form={form as FormValidation<MaintenanceTaskPayloadValidation>}
                    payload={payload}
                    metadata={metadata}
                    changeForm={changeForm}
                />
            )

        case TaskType.RescueService:
            return (
                <RescueServiceTaskForm
                    form={form as FormValidation<RescueServiceTaskPayloadValidation>}
                    metadata={metadata}
                    payload={payload}
                    changeForm={changeForm}
                />
            )

        case TaskType.Inspection:
            return (
                <InspectionTaskForm
                    form={form as FormValidation<InspectionTaskPayloadValidation>}
                    metadata={metadata}
                    payload={payload}
                    changeForm={changeForm}
                />
            )

        default:
            return null
    }
}
