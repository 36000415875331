import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC } from 'react'

interface CalendarTimeCellProps {
    value: DateTime
}

export const RelativeTimeCell: FC<CalendarTimeCellProps> = props => {
    const { value } = props
    return (
        <Tooltip body={formatDateTime(value)} placement='top' withArrow>
            <span>{value.fromNow()}</span>
        </Tooltip>
    )
}
