import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    isRunwayActive,
    isRunwayMode,
    Runway,
    runway1,
    runway2,
    runway3,
    RunwayCombination,
    RunwayMode,
} from 'aos-services/src/services/runways/types/RunwayMode'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent } from 'react'

import { RunwayBottomSection } from './RunwayBottomSection'
import { RunwayTopSection } from './RunwayTopSection'

export class RunwaysGraph extends PureComponent<RunwayContentProps> {
    public render() {
        const { combination } = this.props
        const [r1Active, r3Active, r2Active] = this.getActiveRunways(runway1, runway3, runway2)
        const [r1Label, r3Label, r2Label] = this.getRunwayTooltipLabels(runway1, runway3, runway2)

        const activeClass = 'runway-active'
        const inactiveClass = 'runway-inactive'

        return (
            // viewbox width = 160 + 60*2
            <svg
                className='runway-graph'
                viewBox='0 0 313 280'
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='xMaxYMax meet'
            >
                <style>
                    {
                        // tslint:disable-next-line: jsx-use-translation-function
                        `.active {font: normal 10px montserrat; fill:#FFFFFF }
                        .inactive {font: normal 10px montserrat; fill:#6780AA }
                        .runway-active { fill:#6780AA }
                        .runway-inactive { fill:#3C4B69 }
                    `
                    }
                </style>
                <g
                    strokeWidth='2'
                    stroke='#232D43'
                    fill='none'
                    fillRule='evenodd'
                    transform='translate(0, 60)'
                >
                    <Tooltip body={translate(r1Label, { nr: 'R3' })} placement='topRight' withArrow>
                        <path
                            id='R1'
                            d='M130.47 1L2.126 156H34.53L162.874 1H130.47z'
                            className={r1Active ? activeClass : inactiveClass}
                        />
                    </Tooltip>
                    <Tooltip
                        body={translate(r3Label, { nr: 'R2' })}
                        placement='bottomRight'
                        withArrow
                    >
                        <path
                            id='R3'
                            d='M170.922 1L279.52 156h31.558L202.48 1h-31.558z'
                            className={r3Active ? activeClass : inactiveClass}
                        />
                    </Tooltip>
                    <Tooltip body={translate(r2Label, { nr: 'R1' })} placement='topRight' withArrow>
                        <path
                            id='R2'
                            d='M236.47 1L108.126 156h32.404L268.874 1H236.47z'
                            className={r2Active ? activeClass : inactiveClass}
                        />
                    </Tooltip>
                </g>
                <RunwayTopSection modes={combination['22R']} name='22R' offset={135} />
                <RunwayTopSection modes={combination['15']} name='15' offset={176} />
                <RunwayTopSection modes={combination['22L']} name='22L' offset={240} />
                <RunwayBottomSection modes={combination['04L']} name='04L' offset={5} />
                <RunwayBottomSection modes={combination['04R']} name='04R' offset={112} />
                <RunwayBottomSection modes={combination['33']} name='33' offset={282} />
            </svg>
        )
    }

    private getActiveRunways(...runways: Runway[][]) {
        const { combination } = this.props
        return runways.map((runway: [Runway, Runway]) => isRunwayActive(combination, runway))
    }

    private getRunwayTooltipLabels(...runways: Runway[][]) {
        const { combination } = this.props
        const base = 'dashboard.runway.tooltip.'

        return runways.map((runway: [Runway, Runway]) => {
            const isArrival = isRunwayMode(combination, runway, RunwayMode.Arrival)
            const isDeparture = isRunwayMode(combination, runway, RunwayMode.Departure)
            const isConditional = isRunwayMode(combination, runway, RunwayMode.Conditional)

            if (isConditional) {
                return base + 'conditionally-open'
            }

            if (isArrival || isDeparture) {
                const label = base + 'active.'

                return (
                    label +
                    (isArrival && isDeparture
                        ? 'arrival-and-departure'
                        : isArrival
                        ? 'arrival'
                        : 'departure')
                )
            }

            return base + 'inactive'
        })
    }
}

interface RunwayContentProps {
    combination: RunwayCombination
}
