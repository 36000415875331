import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { connect } from 'react-redux'

import { addAdUserActions, removeUserAction } from '../../../core/groupManager/actions'
import { State } from '../../../core/state'
import {
    AddAdUserModalsClass,
    AdUserModalsDispatchProps,
    AdUserModalsProps,
} from './AddAdUserModalsClass'

export const AddAdUserModal = connect<AdUserModalsProps, AdUserModalsDispatchProps, {}>(
    (state: State) => ({
        ...state.groupManager.addAdUserState,
        groups: state.groupManager.groups,
        headerTitle: translate('manage-user.ad-user.title'),
    }),
    {
        ...addAdUserActions,
        removeUserAction,
        saveUserAction: addAdUserActions.createAdUserAction,
    },
)(AddAdUserModalsClass)
