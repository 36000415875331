import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { FormValidation, isEmpty, isValidEmail } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { OkButton } from 'aos-ui/src/components/buttons/OkButton'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { ModalFooter } from 'aos-ui/src/components/modal/Modal/ModalFooter'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import copyToClipboard from 'copy-to-clipboard'
import { EnumValues } from 'enum-values'
import React, { FormEvent, PureComponent } from 'react'
import { connect } from 'react-redux'

import { sendShareEmailAction, showShareModalAction } from '../../core/flightInformation/actions'
import { currentAbsoluteUrlSelector } from '../../core/router/state'
import { State } from '../../core/state'

enum ShareTabs {
    Link = 'link',
    Email = 'email',
}

const shareTabsIcons: Record<ShareTabs, Svg> = {
    [ShareTabs.Link]: SvgIcon.Window,
    [ShareTabs.Email]: SvgIcon.Mail,
}

const formInitialState = {
    email: '',
    note: '',
}

interface ValidationState {
    email: boolean
}

class FlightInfoShareModalClass extends PureComponent<
    FlightInfoShareModalProps,
    FlightInfoShareModalState
> {
    private shareTabs = EnumValues.getValues(ShareTabs)

    public state: FlightInfoShareModalState = {
        activeTab: ShareTabs.Link,
        data: formInitialState,
        form: FormValidation.fromFields(validate(formInitialState)),
    }

    public render() {
        return (
            <WhiteModal
                title={translate('flight-information.share.title')}
                closeAction={this.handleClose}
                shouldCloseOnOverlayClick
                isOpen={this.props.show}
            >
                <Box paddingVertical={30}>
                    <UnderlineTabs
                        items={this.shareTabs}
                        value={this.state.activeTab}
                        onChange={(activeTab: ShareTabs) => this.setState({ activeTab })}
                        iconFormatter={this.iconFormatter}
                        nameFormatter={this.nameFormatter}
                        paddingHorizontal={30}
                    />
                </Box>
                {this.renderContent()}
            </WhiteModal>
        )
    }

    private renderContent() {
        const { activeTab } = this.state

        switch (activeTab) {
            case ShareTabs.Link:
                return this.renderLinkContent()

            case ShareTabs.Email:
                return this.renderEmailContent()

            default:
                return null
        }
    }

    private renderLinkContent() {
        return (
            <Box key='url'>
                <Box flex={1} paddingHorizontal={30}>
                    <LabeledInput
                        keyPrefix='flight-information.share.url'
                        value={this.props.url}
                        readOnly
                    />
                </Box>
                <ModalFooter>
                    <OkButton
                        label={translate('flight-information.share.copy-button')}
                        onClick={this.handleCopyToClipboard}
                    />
                </ModalFooter>
            </Box>
        )
    }

    private handleCopyToClipboard = () => copyToClipboard(this.props.url)

    private renderEmailContent() {
        const { form, data } = this.state
        const { sendingInProgress } = this.props

        return (
            <form onSubmit={this.handleSubmitEmail} key='mail' noValidate>
                <Box flex={1} paddingHorizontal={30}>
                    <LabeledInput
                        keyPrefix='flight-information.share.email'
                        type='email'
                        value={data.email}
                        onChangeText={this.handleEmailChange}
                        isError={form.error.email}
                    />
                    <LabeledTextArea
                        keyPrefix='flight-information.share.email-content'
                        value={data.note}
                        onChangeText={this.handleNoteChange}
                    />
                </Box>
                <ModalFooter>
                    <OkButton
                        type='submit'
                        label={translate('flight-information.share.send-button')}
                        disableCheck={!form.valid}
                        isLoading={sendingInProgress}
                        minimumLoadingTime
                    />
                </ModalFooter>
            </form>
        )
    }

    private handleEmailChange = (email: string) => {
        this.setState(state => {
            const data = { ...state.data, email }
            return {
                data,
                form: state.form.modify(validate(data)),
            }
        })
    }

    private handleNoteChange = (note: string) => {
        this.setState(({ data }) => ({ data: { ...data, note } }))
    }

    private handleSubmitEmail = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this.setState(state => ({
            form: state.form.setPristine(false),
        }))

        const { data, form } = this.state
        const { email, note } = data

        if (form.valid) {
            this.props.sendShareEmailAction({ email, note, link: this.props.url })
        }
    }

    private iconFormatter = (item: keyof typeof shareTabsIcons) => shareTabsIcons[item]
    private nameFormatter = (item: keyof typeof shareTabsIcons) =>
        translate(`flight-information.share.tabs.${item}`)

    private handleClose = () => {
        this.props.showShareModalAction(false)
    }
}

const validate = (data: typeof formInitialState): ValidationState => ({
    email: !isEmpty(data.email) && isValidEmail(data.email),
})

interface FlightInfoShareModalState {
    activeTab: ShareTabs
    data: {
        email: string
        note: string
    }
    form: FormValidation<ValidationState>
}

interface FlightInfoShareModalStateProps {
    show: boolean
    url: string
    sendingInProgress: boolean
}

interface FlightInfoShareModalDispatchProps {
    showShareModalAction: typeof showShareModalAction
    sendShareEmailAction: typeof sendShareEmailAction
}

type FlightInfoShareModalProps = FlightInfoShareModalStateProps & FlightInfoShareModalDispatchProps

export const FlightInfoShareModal = connect<
    FlightInfoShareModalStateProps,
    FlightInfoShareModalDispatchProps
>(
    (state: State) => ({
        show: state.flights.showShareModal,
        sendingInProgress: state.flights.sendingInProgress,
        url: currentAbsoluteUrlSelector(state),
    }),
    {
        showShareModalAction,
        sendShareEmailAction,
    },
)(FlightInfoShareModalClass)
