import { initialTimelineState, TimelineState } from 'aos-services/src/core/timeline/state'
import { RestrictionArea } from 'aos-services/src/services/restrictions/types/RestrictionArea'

import { StandsGatesTimelineItem } from './types/StandsGatesTimelineItem'

export interface StandsGatesTimelineState {
    timeline: TimelineState
    selectedItem?: StandsGatesTimelineItem
    collapsedGroups: string[]
    filters: StandsGatesTimelineFilters
    isFiltersModalOpen: boolean
}

export interface StandsGatesTimelineStateAware {
    standsGatesTimeline: StandsGatesTimelineState
}

export interface StandsGatesTimelineFilters {
    hiddenAreas: RestrictionArea[]
}

export const initialStandsGatesTimelineState: StandsGatesTimelineState = {
    timeline: initialTimelineState,
    collapsedGroups: [],
    filters: {
        hiddenAreas: [],
    },
    isFiltersModalOpen: false,
}
