import { ActionType } from '@redux-saga/types'
import { Action } from 'aos-helpers/src/helpers/ActionCreator'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { attachmentsService } from 'aos-services/src/services/attachments/attachmentsService'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import { call, put } from 'redux-saga/effects'

import {
    AttachmentsAction,
    DELETE_ATTACHMENT,
    DeleteAttachmentAction,
    REPLACE_ATTACHMENT,
    ReplaceAttachmentAction,
    replaceAttachmentSuccessAction,
    UPLOAD_ATTACHMENT,
    UploadAttachmentAction,
    uploadAttachmentAddAction,
    uploadAttachmentSuccessAction,
} from './actions'

export const attachmentsSagaFactory = (
    parentActionType: ActionType,
    creator: (v: AttachmentsAction) => Action<any>,
) => [
    function* uploadAttachmentSaga() {
        yield takeEveryChildAction<UploadAttachmentAction>(
            parentActionType,
            UPLOAD_ATTACHMENT,
            function* (action: UploadAttachmentAction) {
                for (const file of Array.from(action.payload)) {
                    const url: UploadedAttachment = yield call(
                        attachmentsService.uploadAttachment,
                        file,
                    )
                    yield put(creator(uploadAttachmentAddAction(url)))
                }
                yield put(creator(uploadAttachmentSuccessAction()))
            },
        )
    },
    function* replaceAttachmentSaga() {
        yield takeEveryChildAction<ReplaceAttachmentAction>(
            parentActionType,
            REPLACE_ATTACHMENT,
            function* (action: ReplaceAttachmentAction) {
                const newUrl: string = yield call(
                    attachmentsService.replaceAttachment,
                    action.payload,
                )
                yield put(
                    creator(
                        replaceAttachmentSuccessAction({
                            oldUrl: action.payload.url,
                            newUrl,
                        }),
                    ),
                )
            },
        )
    },
    function* deleteAttachmentSaga() {
        yield takeEveryChildAction<DeleteAttachmentAction>(
            parentActionType,
            DELETE_ATTACHMENT,
            function* (action: DeleteAttachmentAction) {
                if (action.payload.id === undefined) {
                    yield call(attachmentsService.deleteAttachment, action.payload.link)
                }
            },
        )
    },
]
