import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { userLayerVisibilityService } from '../../../../aos-ui-map/src/components/map/layersVisibility/UserLayerVisibilityService'
import { currentUserIdSelector } from '../auth/state'
import {
    LOAD_EVENT_MAP_USER_LAYER_VISIBILITY,
    LoadEventMapUserLayerVisibilityAction,
    loadEventMapUserLayerVisibilitySuccessAction,
} from './actions'

function* loadEventMapUserLayerVisibilitySaga() {
    yield takeEvery<LoadEventMapUserLayerVisibilityAction>(
        LOAD_EVENT_MAP_USER_LAYER_VISIBILITY,
        function* () {
            const id: number = yield select(currentUserIdSelector)
            const userLayerData: string[] = yield call(
                userLayerVisibilityService.loadUserLayers,
                id,
            )
            if (userLayerData.length > 0) {
                const getFloorNumber = () => {
                    const floorMapLayer = userLayerData.find((mapLayer: string) =>
                        mapLayer.includes('floor'),
                    )
                    const floorNumber = floorMapLayer?.slice(floorMapLayer.length - 1) as string
                    return +floorNumber
                }
                const userLayerPayload = {
                    list: userLayerData,
                    floor: getFloorNumber(),
                }
                yield put(loadEventMapUserLayerVisibilitySuccessAction(userLayerPayload))
            } else {
                const initialLayers = {
                    list: [BimLayerName.Tasks, BimLayerName.Events, BimLayerName.Floor0],
                    floor: 0,
                }
                yield put(loadEventMapUserLayerVisibilitySuccessAction(initialLayers))
            }
        },
    )
}

export const eventMapUserLayerVisibilitySagas = [loadEventMapUserLayerVisibilitySaga]
