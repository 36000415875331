import { firebaseApp } from 'firebaseApp'

class FirebaseEventsRepository {
    eventRef = (eventId: number) => firebaseApp.database().ref(`events/${eventId}`)

    sendToSyncRef = (eventId: number) => this.eventRef(eventId).child('sendToSync')

    invitationRef(eventId: number, firebaseUid: string) {
        return this.eventRef(eventId).child(`invitations/${firebaseUid}`)
    }

    checklistRef(eventId: number) {
        return this.eventRef(eventId).child(`checklists`)
    }

    visibilityLayersRef(eventId: number) {
        return this.eventRef(eventId).child(`visibilityLayers`)
    }
}
export const firebaseEventsRepository = new FirebaseEventsRepository()
