import { Values } from 'aos-helpers/src/helpers/Object'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import {
    AirlineFilter,
    airlinesFilterValues,
    translateAirlineFilter,
} from 'aos-services/src/services/statusDashboard/types/filters/AirlineFilter'
import {
    BaseCommonFlightFilters,
    CommonFlightView,
    CommonTimeRange,
    FlightFilterConfigOption,
    FlightFooterConfiguration,
    FlightsHistoryTimeRange,
    FlightsPredictionTimeRange,
    flightTypeFilterValues,
    translateFlightType,
    translateFullView,
    translateTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import {
    HandlingAgentFilter,
    handlingAgentFilterValues,
    translateHandlingAgent,
} from 'aos-services/src/services/statusDashboard/types/filters/HandlingAgentFilter'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'

import { Footer } from './Footer'

interface FlightFooterFiltersProps {
    timeRange: CommonTimeRange
    fullViewTab: CommonFlightView
    airline: AirlineFilter
    flightType: FlightsType
    handlingAgent: HandlingAgentFilter
    isSmall: boolean
    tabConfiguration: FlightFooterConfiguration
    setFilterAction(v: Partial<BaseCommonFlightFilters>): void
}

export class FlightFooterFilters extends PureComponent<FlightFooterFiltersProps> {
    public render() {
        return (
            <Footer>
                <MultiRoundTabs items={this.tabConfig} />
            </Footer>
        )
    }
    private get tabConfig() {
        const { small, flights, history } = this.props.tabConfiguration
        if (this.props.isSmall) {
            return [...this.getConfigs(small)]
        } else if (this.props.fullViewTab === CommonFlightView.Flights) {
            return [this.switchTabConfig, ...this.getConfigs(flights)]
        } else {
            return [this.switchTabConfig, ...this.getConfigs(history)]
        }
    }

    private get switchTabConfig() {
        return {
            items: EnumValues.getValues(CommonFlightView),
            value: this.props.fullViewTab,
            name: 'baggage-delivery-full-view-tab',
            formatter: translateFullView,
            onChange: this.changeMainTab,
            tooltip: translate('dashboard.flight-filters.full-view-tab.tooltip'),
            isAlwaysExpanded: true,
        }
    }

    private getConfigs = (
        configList: FlightFilterConfigOption[],
    ): RoundTabsProps<Values<BaseCommonFlightFilters>>[] =>
        configList.map(config => this.getConfig(config))

    private getConfig = (config: FlightFilterConfigOption) => {
        switch (config) {
            case FlightFilterConfigOption.HistoryTimeRange:
                return {
                    items: EnumValues.getValues(FlightsHistoryTimeRange),
                    value: this.props.timeRange,
                    name: 'punctuality-time-range-tab',
                    formatter: translateTimeRange,
                    onChange: this.changeTimeRange,
                    tooltip: translate('dashboard.flight-filters.time-range.tooltip'),
                    width: DropdownWidth.Std,
                }
            case FlightFilterConfigOption.PredictionTimeRange:
                return {
                    items: EnumValues.getValues(FlightsPredictionTimeRange),
                    value: this.props.timeRange,
                    name: 'punctuality-time-range-tab',
                    formatter: translateTimeRange,
                    onChange: this.changeTimeRange,
                    tooltip: translate('dashboard.flight-filters.time-range.tooltip'),
                    width: DropdownWidth.Std,
                }
            case FlightFilterConfigOption.FlightType:
                return {
                    items: flightTypeFilterValues,
                    value: this.props.flightType,
                    name: 'baggage-delivery-flight-type-tab',
                    formatter: translateFlightType,
                    onChange: this.changeFlightType,
                    tooltip: translate('dashboard.flight-filters.flight-type-tab.tooltip'),
                }
            case FlightFilterConfigOption.Airline:
                return {
                    items: airlinesFilterValues,
                    value: this.props.airline,
                    name: 'regularity-airline-tab',
                    formatter: translateAirlineFilter,
                    onChange: this.changeAirline,
                    tooltip: translate('dashboard.flight-filters.airline.tooltip'),
                }
            case FlightFilterConfigOption.HandlingAgent:
                return {
                    items: handlingAgentFilterValues,
                    value: this.props.handlingAgent,
                    name: 'baggage-delivery-handling-agent-tab',
                    formatter: translateHandlingAgent,
                    onChange: this.changeHandlingAgent,
                    tooltip: translate('dashboard.flight-filters.handling-agent-tab.tooltip'),
                }
        }
    }

    private changeTimeRange = (timeRange: CommonTimeRange) =>
        this.props.setFilterAction({ timeRange })

    private changeMainTab = (fullViewTab: CommonFlightView) =>
        this.props.setFilterAction({ fullViewTab })

    private changeFlightType = (flightType: FlightsType) =>
        this.props.setFilterAction({ flightType })

    private changeAirline = (airline: AirlineFilter) => this.props.setFilterAction({ airline })

    private changeHandlingAgent = (handlingAgent: HandlingAgentFilter) =>
        this.props.setFilterAction({ handlingAgent })
}
