import { AlertsStateAware, initialAlertsState } from 'aos-services/src/core/alerts/state'
import {
    BimLayersStateAware,
    initialBimLayersState,
} from 'aos-services/src/core/bimLayersData/state'
import { CommonStateAware } from 'aos-services/src/core/common/state'
import {
    EventMapUserLayerVisibilityStateAware,
    initialWorldEventMapUserLayersVisibilityState,
} from 'aos-services/src/core/eventUserLayersVisibility/state'
import {
    FeatureFlagStateAware,
    initialFeatureFlagState,
} from 'aos-services/src/core/featureFlags/state'
import {
    initialRestrictionsState,
    RestrictionsStateAware,
} from 'aos-services/src/core/restrictions/state'
import {
    initialStatusDashboardDataState,
    StatusDashboardDataStateAware,
} from 'aos-services/src/core/statusDashboardData/state'
import {
    initialUserLayersVisibilityState,
    UserLayerVisibilityStateAware,
} from 'aos-services/src/core/userLayersVisibility/state'
import {
    LayersDataState,
    LayersDataStateAware,
} from 'aos-services/src/services/layerData/types/LayersDataState'
import { initialMapState } from 'aos-ui-map/src/core/state'
import { merge, pick } from 'lodash'

import { ActionModalStateAware } from './actionModal/state'
import { AirportMapStateAware } from './airportMap/state'
import { AuthStateAware, initialAuthState } from './auth/state'
import { ChecklistManagerStateAware } from './checklistManager/state'
import { ChecklistSelectionModalStateAware } from './checklistSelectionModal/state'
import { CommonFrontendStateAware } from './commonFrontend/state'
import { ContactListStateAware, initialContactListState } from './contactList/state'
import { EventEditorStateAware } from './eventEditor/state'
import { EventManagerStateAware } from './eventManager/state'
import { EventModalsStateAware } from './eventModals/state'
import { EventPreviewStateAware } from './eventPreview/state'
import { EventTimelineStateAware } from './eventTimeline/state'
import {
    FlightInformationStateAware,
    initialFlightInformationState,
} from './flightInformation/state'
import { FodStateAware } from './fod/state'
import { GlobalMessagingStateAware } from './globalMessaging/state'
import { GroupManagerStateAware } from './groupManager/state'
import { LayoutStateAware } from './layout/state'
import { LoginStateAware } from './login/state'
import { initialNotamState, NotamStateAware } from './notamTimeline/state'
import { initialNotificationsState, NotificationsStateAware } from './notifications/state'
import { OneTimeStateAware } from './oneTime/state'
import { initialRemoveAccountState, RemoveAccountStateAware } from './removeAccount/state'
import { initialReportingState, ReportingStateAware } from './reporting/state'
import { CustomRouterStateAware } from './router/state'
import { StandsGatesTimelineStateAware } from './standsGatesTimeline/state'
import { initialStatusDashboardState, StatusDashboardStateAware } from './statusDashboard/state'
import { TasksStateAware } from './tasks'
import { initialFirefightersState } from './tasks/firefighters/state'
import { initialShiftNotesState } from './tasks/shiftNotes/state'
import { initialUserProfileState, UserProfileModalStateAware } from './userProfileModal/state'

export type State = CommonFrontendStateAware &
    CommonStateAware &
    AlertsStateAware &
    EventTimelineStateAware &
    LoginStateAware &
    LayersDataStateAware &
    LayoutStateAware &
    CustomRouterStateAware &
    AuthStateAware &
    EventPreviewStateAware &
    EventModalsStateAware &
    EventManagerStateAware &
    EventEditorStateAware &
    ActionModalStateAware &
    GroupManagerStateAware &
    ChecklistManagerStateAware &
    NotificationsStateAware &
    ChecklistSelectionModalStateAware &
    AirportMapStateAware &
    GlobalMessagingStateAware &
    FlightInformationStateAware &
    StatusDashboardStateAware &
    StatusDashboardDataStateAware &
    OneTimeStateAware &
    UserProfileModalStateAware &
    RestrictionsStateAware &
    StandsGatesTimelineStateAware &
    LayersDataState &
    BimLayersStateAware &
    TasksStateAware &
    UserLayerVisibilityStateAware &
    EventMapUserLayerVisibilityStateAware &
    FodStateAware &
    ReportingStateAware &
    FeatureFlagStateAware &
    RemoveAccountStateAware &
    NotamStateAware &
    ContactListStateAware

export const initialState = (): Partial<State> => ({
    auth: initialAuthState,
    airportMap: initialMapState,
    notifications: initialNotificationsState,
    flights: initialFlightInformationState,
    statusDashboard: initialStatusDashboardState,
    userProfileModal: initialUserProfileState,
    statusDashboardData: initialStatusDashboardDataState,
    alerts: initialAlertsState,
    shiftNotes: initialShiftNotesState,
    firefighters: initialFirefightersState,
    restrictions: initialRestrictionsState,
    bimLayers: initialBimLayersState,
    userLayerVisibility: initialUserLayersVisibilityState,
    eventMapUserLayerVisibility: initialWorldEventMapUserLayersVisibilityState,
    reporting: initialReportingState,
    featureFlags: initialFeatureFlagState,
    removeAccount: initialRemoveAccountState,
    notam: initialNotamState,
    contactList: initialContactListState,
})

export const myDashboardPath = 'statusDashboard.presets.my-dashboard'
export const persistentPaths = [
    'layout.columnSizes',
    'notifications.ui',
    'flightInformation.lastFlightFilters',
    'flightInformation.lastTowingFilters',
    'oneTime.alreadyShown',
    myDashboardPath,
]

export const resetState = (state: State): State => {
    const initial = initialState()
    const persistentState = pick(state, persistentPaths)
    return merge(initial, persistentState, { router: state.router }) as State
}
