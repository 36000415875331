import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { HeaderIconVariant } from 'aos-ui/src/components/ui/HeaderLabel'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { WeatherAlertLabel } from './labels/WeatherAlertLabel'
import { WeatherSyncWrapper } from './WeatherSyncWrapper'

interface WeatherItemProps {
    syncId: SyncDataKey
    weatherAlert: boolean
    lvpAlert: boolean
    forecastAvailable?: boolean
    itemProps: StatusDashboardItemCommonProps
    link?: string
    noDataWarning: boolean
}

export const WeatherItem: FC<PropsWithChildren<WeatherItemProps>> = props => {
    const weatherAlert = props.weatherAlert && (
        <WeatherAlertLabel
            tooltip={translate('dashboard.weather.wa.tooltip')}
            variant={HeaderIconVariant.Orange}
            title={translate('dashboard.weather.wa')}
            marginHorizontal={4}
        />
    )
    const lvpAlert = props.lvpAlert && (
        <WeatherAlertLabel
            tooltip={translate('dashboard.weather.lvp.tooltip')}
            variant={HeaderIconVariant.Yellow}
            title={translate('dashboard.weather.lvp')}
            marginHorizontal={4}
        />
    )
    const forecastAvailable = props.forecastAvailable && <Icon svg={SvgIcon.Forecast} />
    const labels = (
        <Box flex={1} row justify='flex-end'>
            {forecastAvailable}
            {lvpAlert}
            {weatherAlert}
        </Box>
    )
    return (
        <WeatherSyncWrapper id={props.syncId}>
            <StatusDashboardItem
                title={translate('dashboard.weather.title')}
                labels={labels}
                description={translate('dashboard.weather.description')}
                noDataWarning={props.noDataWarning}
                link={props.link}
                {...props.itemProps}
            >
                {props.children}
            </StatusDashboardItem>
        </WeatherSyncWrapper>
    )
}
