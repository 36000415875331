import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { getBarPositionAndSize } from 'aos-ui-common/src/components/chart/series/barSeries'
import { ChartScales, ChartXYAccessors } from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

const seriesBarWidth = 9

class LinearBarSeriesComponent<T, TX, TY> extends BaseChartComponent<
    ChartBarSeriesProps<T, TX, TY>
> {
    public render() {
        const { data } = this.props

        return (
            <g className={cxp(this.props, 'series series--linear-bar')}>
                {data.map(this.renderBar)}
            </g>
        )
    }

    private renderBar = (datum: T, index: number) => {
        const { size, margins, seriesConfig, scales, accessors } = this.props

        const value = scales.yScale(accessors.yAccessor(datum)) || 0
        const { x, width } = getBarPositionAndSize(
            scales,
            accessors.xAccessor(datum),
            seriesBarWidth,
        )
        const color = this.getAttributeValue(seriesConfig.color, datum)

        const react = (
            <rect
                key={index}
                x={x}
                y={value}
                height={Math.max(size.height + margins.top - value, 0)}
                width={width}
                fill={color}
            />
        )

        if (!seriesConfig.tooltip) {
            return react
        }

        const body = <span style={{ color }}>{seriesConfig.tooltip(datum)}</span>

        return (
            <Tooltip key={index} body={body} offset={[0, -10]} placement='top' withArrow>
                {react}
            </Tooltip>
        )
    }

    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartBarSeriesProps<T, TX, TY> extends ClassNameProps {
    data: T[]
    seriesConfig: ChartSeriesConfig<T>
    accessors: ChartXYAccessors<T, TX, TY>
    scales: ChartScales<TX, TY>
}

export const LinearBarSeries = withChartContext(LinearBarSeriesComponent)
