import { bySdtComparator, Flight } from '../../../flightInformation/types/Flight'
import { FlightsType } from './FlightsType'

export interface FlightCollection {
    arrivals: Flight[]
    departures: Flight[]
}

export const filterCollection = (
    collection: FlightCollection,
    predicate: f.Func1<Flight, boolean>,
): FlightCollection => ({
    arrivals: collection.arrivals.filter(predicate),
    departures: collection.departures.filter(predicate),
})

export const flightsForType = (
    collection: FlightCollection,
    type: FlightsType,
    predicate: f.Func1<Flight, boolean>,
): Flight[] => {
    const filteredArrivals = [FlightsType.Arrivals, FlightsType.All].includes(type)
        ? collection.arrivals.filter(predicate)
        : []
    const filteredDepartures = [FlightsType.Departures, FlightsType.All].includes(type)
        ? collection.departures.filter(predicate)
        : []

    return [...filteredArrivals, ...filteredDepartures].sort(bySdtComparator)
}
