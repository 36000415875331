import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { enumToName } from 'aos-helpers/src/helpers/Enum'
import {
    FallbackInvitationStatus,
    InvitationRole,
    InvitationStatus,
} from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import React from 'react'

interface ParticipantStatusPictogramProps extends ClassNameProps {
    role: InvitationRole
    status: InvitationStatus
    timePassed: boolean
}

const mapStatusToIcon = (
    role: InvitationRole,
    status: InvitationStatus | FallbackInvitationStatus,
    timePassed: boolean,
) => {
    switch (role) {
        case InvitationRole.Participate:
            switch (status) {
                case InvitationStatus.AtAirport:
                    return SvgIcon.ParticipantAtAirport
                case InvitationStatus.Coming:
                    return timePassed ? SvgIcon.ParticipantAtAirport : SvgIcon.ParticipantComing
                case InvitationStatus.Remote:
                    return SvgIcon.ParticipantRemote
            }
            return SvgIcon.ParticipantPending
        case InvitationRole.Command:
            return SvgIcon.ParticipantCommand

        case InvitationRole.Observe:
            return SvgIcon.ParticipantObserving
        case InvitationRole.Pending:
            return SvgIcon.ParticipantPending
        case InvitationRole.Left:
            return SvgIcon.ParticipantLeft

        default:
            switch (status) {
                case FallbackInvitationStatus.Command:
                    return SvgIcon.ParticipantCommand
                case FallbackInvitationStatus.Follows:
                    return SvgIcon.ParticipantObserving
            }
            return SvgIcon.ParticipantPending
    }
}

export function ParticipantStatusPictogram(props: ParticipantStatusPictogramProps) {
    const { role, status, timePassed } = props
    const pictogramClass = cxp(
        props,
        'private-channel-item__pictogram',
        `private-channel-item__pictogram--${enumToName(InvitationRole)(status)}`,
    )

    return (
        <Box className={pictogramClass}>
            <SvgImage svg={mapStatusToIcon(role, status, timePassed)} />
        </Box>
    )
}
