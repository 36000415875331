import { mapValues } from 'lodash'

import {
    BaseStatusDashboardAction,
    SET_DASHBOARD_HEALTHS,
    SET_DASHBOARD_HEALTHS_FOR_AIRPORT,
    SET_DASHBOARD_RANGES,
    SET_DASHBOARD_STATUSES,
} from './actions'
import { BaseStatusDashboardState } from './state'

export function statusDashboardBaseReducer<T extends BaseStatusDashboardState>(
    state: T,
    action: BaseStatusDashboardAction,
): T {
    switch (action.type) {
        case SET_DASHBOARD_STATUSES:
            const payload = mapValues(action.payload, item => ({ reasonCodes: [], ...item }))
            return {
                ...state,
                statuses: payload,
            }

        case SET_DASHBOARD_HEALTHS:
            return {
                ...state,
                healths: action.payload,
            }

        case SET_DASHBOARD_HEALTHS_FOR_AIRPORT:
            return {
                ...state,
                healthsForAirport: action.payload,
            }

        case SET_DASHBOARD_RANGES:
            return {
                ...state,
                ranges: action.payload,
            }

        default:
            return state
    }
}
