import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum AosUserLocalisation {
    EN_US = 'en_US',
    FI_FI = 'fi_FI',
}

export const languageOptions = EnumValues.getValues<AosUserLocalisation>(AosUserLocalisation)

export const languageName = (u: AosUserLocalisation) => translate(`languages.${u}`)
