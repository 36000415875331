import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { PaxFlightTypeStatsSection } from './PaxFlightTypeStatsSection'
import { PaxForecastTotalTrend } from './PaxForecastTotalTrend'

interface PaxForecastStatsBigProps {
    stats: PaxForecastStats
}

export const PaxForecastStatsBig: FC<PaxForecastStatsBigProps> = props => (
    <>
        <Box marginRight={30}>
            <PaxForecastTotalTrend stats={props.stats} />
        </Box>
        <PaxFlightTypeStatsSection
            type={PaxForecastArrivalOrDeparture.Arrivals}
            stats={props.stats}
            className='margin-right--xx-large flex--auto'
        />
        <PaxFlightTypeStatsSection
            type={PaxForecastArrivalOrDeparture.Departures}
            stats={props.stats}
            className='flex--auto'
        />
    </>
)
