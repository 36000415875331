import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { TaskMetadataDto } from './types/TaskMetadataDto'

class MetadataRestServiceClass extends BaseRestService {
    public getTaskMetadata(): Promise<TaskMetadataDto> {
        return this.getAndTransform<TaskMetadataDto>(
            format({
                pathname: '/task-manager/metadata',
            }),
            BaseRestService.jsonTransformer,
        )
    }
}

export const metadataRestService = new MetadataRestServiceClass()
