import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AnimalsTaskFormPayload,
    AnimalsTaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/AnimalsTaskFormPayload'
import {
    allAnimalAges,
    allAnimalGenders,
    allAnimalsTaskTypes,
    allAnimalTypes,
    AnimalSpecie,
    AnimalsTaskType,
    AnimalType,
    translateAnimalAge,
    translateAnimalGender,
    translateAnimalsTaskType,
    translateAnimalType,
} from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import {
    animalSpeciesForType,
    TaskMetadata,
} from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledNumberInput } from 'aos-ui/src/components/form/labeled/LabeledNumberInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { DefaultRadioRenderer } from 'aos-ui/src/components/form/radio/DefaultRadioRenderer'
import { IconRadioRendererLarge } from 'aos-ui/src/components/form/radio/IconRadioRenderer'
import { Color } from 'aos-ui-common/src/styles/Color'
import { animalTaskTypeIcons } from 'aos-ui-task/src/components/task/AnimalTaskTypeIcon'
import React, { FC, useMemo, useState } from 'react'

interface AnimalsTaskFormProps {
    form: FormValidation<AnimalsTaskPayloadValidation>
    payload: AnimalsTaskFormPayload
    metadata: TaskMetadata
    changeForm(t: Partial<AnimalsTaskFormPayload>): void
}

export const AnimalsTaskForm: FC<AnimalsTaskFormProps> = ({
    form,
    payload,
    metadata,
    ...props
}) => {
    const [animalType, setAnimalType] = useState<AnimalType | undefined>(
        payload.animalSpecies?.animalType,
    )
    const species = useMemo(
        () => animalSpeciesForType(metadata, animalType),
        [metadata, animalType],
    )
    const changeForm = (v: Partial<AnimalsTaskFormPayload>) =>
        props.changeForm({ ...payload, ...v })

    const areCartridgesRequired = [AnimalsTaskType.Banishment, AnimalsTaskType.Kill].some(
        t => t === payload.animalTaskType,
    )

    const areExpulsionsRequired = payload.animalTaskType === AnimalsTaskType.Banishment

    const isMammalToKill =
        animalType === AnimalType.Mammal &&
        payload.animalTaskType === AnimalsTaskType.Kill &&
        payload.animalSpecies?.horns

    const killMammalForm = () => (
        <>
            <LabeledFormElement
                label={translate('tasks.form.animal-gender.label')}
                isRequired
                isError={form.error.gender}
                errorMessage={translate('tasks.form.animal-gender.error')}
                seleniumLocation='category'
            >
                <BlockRadioGroup
                    items={allAnimalGenders}
                    value={payload.gender}
                    marginBottom={8}
                    gridSize={4}
                    Renderer={({ isSelected, item, onSelect }) => (
                        <DefaultRadioRenderer
                            isSelected={isSelected}
                            label={translateAnimalGender(item)}
                            onSelect={onSelect}
                            color={Color.UiBlack3}
                        />
                    )}
                    onChange={gender => changeForm({ gender })}
                />
            </LabeledFormElement>
            <LabeledFormElement
                label={translate('tasks.form.animal-age.label')}
                isRequired
                isError={form.error.lifeStage}
                errorMessage={translate('tasks.form.animal-age.error')}
                seleniumLocation='category'
            >
                <BlockRadioGroup
                    items={allAnimalAges}
                    value={payload.lifeStage}
                    marginBottom={8}
                    gridSize={4}
                    Renderer={({ isSelected, item, onSelect }) => (
                        <DefaultRadioRenderer
                            isSelected={isSelected}
                            label={translateAnimalAge(item)}
                            onSelect={onSelect}
                            color={Color.UiBlack3}
                        />
                    )}
                    onChange={lifeStage => changeForm({ lifeStage })}
                />
            </LabeledFormElement>
            <LabeledFormElement label={translate('tasks.form.animal-horns.label')}>
                <Toggle value={!!payload.horns} onChange={horns => changeForm({ horns })} />
            </LabeledFormElement>
            {payload.horns && (
                <LabeledNumberInput
                    min={0}
                    max={50}
                    isError={form.error.amountOfSpikes}
                    isRequired={payload.horns}
                    onChange={amountOfSpikes => changeForm({ amountOfSpikes })}
                    value={payload.amountOfSpikes}
                    keyPrefix={translate('tasks.form.animal-spikes')}
                />
            )}
        </>
    )

    return (
        <>
            <LabeledFormElement
                label={translate('tasks.form.animal-task-type.label')}
                isRequired
                isError={form.error.taskType}
                errorMessage={translate('tasks.form.animal-task-type.error')}
                seleniumLocation='category'
            >
                <BlockRadioGroup
                    items={allAnimalsTaskTypes}
                    value={payload.animalTaskType}
                    marginBottom={8}
                    gridSize={4}
                    Renderer={({ isSelected, item, onSelect }) => (
                        <IconRadioRendererLarge
                            isSelected={isSelected}
                            label={translateAnimalsTaskType(item)}
                            onSelect={onSelect}
                            icon={animalTaskTypeIcons[item]}
                            color={Color.UiBlack3}
                        />
                    )}
                    onChange={animalTaskType => changeForm({ animalTaskType })}
                />
            </LabeledFormElement>
            <Box row alignItems='flex-start'>
                {areCartridgesRequired && (
                    <Box flex={1}>
                        <LabeledNumberInput
                            keyPrefix='tasks.form.animal-cartridges'
                            marginBottom={12}
                            isRequired
                            isError={form.error.cartridges}
                            seleniumLocation='animal-cartridges'
                            value={payload.cartridges}
                            onChange={cartridges => changeForm({ cartridges })}
                        />
                    </Box>
                )}
                {areExpulsionsRequired && (
                    <Box flex={1} marginLeft={20}>
                        <LabeledNumberInput
                            keyPrefix='tasks.form.animal-expulsions'
                            marginBottom={12}
                            isRequired
                            isError={form.error.expulsions}
                            seleniumLocation='animal-expulsions'
                            value={payload.expulsions}
                            onChange={expulsions => changeForm({ expulsions })}
                        />
                    </Box>
                )}
            </Box>

            <LabeledSelect
                keyPrefix='tasks.form.animal-type'
                marginBottom={12}
                seleniumLocation='animal-type'
                items={allAnimalTypes}
                labelRenderer={translateAnimalType}
                value={animalType}
                onChange={t => setAnimalType(t as AnimalType)}
            />

            <Box row alignItems='flex-start'>
                <Box flex={2}>
                    <LabeledSelect
                        keyPrefix='tasks.form.animal-species'
                        marginBottom={12}
                        isRequired
                        isError={form.error.animalSpecies}
                        seleniumLocation='animal-specie'
                        items={species}
                        valueRenderer={(v: AnimalSpecie) => v.label}
                        labelRenderer={(v: AnimalSpecie) => v.label}
                        value={payload.animalSpecies}
                        onChange={(animalSpecies: AnimalSpecie) =>
                            changeForm({ animalSpecies, title: animalSpecies?.label })
                        }
                    />
                </Box>
                <Box flex={1} marginLeft={20}>
                    <LabeledNumberInput
                        keyPrefix='tasks.form.animal-amount'
                        marginBottom={12}
                        isRequired
                        isError={form.error.amount}
                        seleniumLocation='animal-amount'
                        value={payload.amount}
                        onChange={amount => changeForm({ amount })}
                    />
                </Box>
            </Box>

            {isMammalToKill && killMammalForm()}
        </>
    )
}
