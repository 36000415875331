import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'

import { TimeValuePoint } from '../../airportStatus/base/types/TimePoint'
import { QueueingTimePoint } from './QueueingTimePoint'
import { SecurityControlPoint } from './SecurityControlPoint'

export type QueueingData = Record<QueueingTimePoint, QueueDataItem>

export interface QueueDataItem {
    length: number
    waitTime: number
    waitTimeForecast: number
    waitTimeHistory: TimeValuePoint[]
    lengthHistory: TimeValuePoint[]
}

const emptyQueueingPointRecord = <T>(v: T): Record<QueueingTimePoint, T> => ({
    ...getInitialValuesForEnumRecord(SecurityControlPoint, v),
})

export const emptyQueueingData: QueueingData = emptyQueueingPointRecord({
    length: 0,
    waitTime: 0,
    waitTimeForecast: 0,
    waitTimeHistory: [],
    lengthHistory: [],
})
