import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { FeedDto } from './types/FeedDto'

class NewsFeedRestService extends BaseRestService {
    public getNewsFeed(): Promise<FeedDto[]> {
        const url = format({
            pathname: '/airport-status/news-feed'
        })

        return this.getAndTransform<FeedDto[]>(url, BaseRestService.jsonTransformer)
    }
}

export const newsFeedRestService = new NewsFeedRestService()
