import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { MultilineText } from 'aos-ui/src/components/text/MultilineText'
import { Dictionary } from 'lodash'
import _ from 'lodash'
import React, { PureComponent } from 'react'

import { cx } from '../../base/ClassNames'

export class WhiteModalContent extends PureComponent<WhiteModalContentProps> {
    public render() {
        const [iconVariant, textClass] = colorMapping[this.props.variant]
        return (
            <div className='container container--vertical container--align-center flex--1 padding-top--x-large padding-horizontal--x-large'>
                <Icon svg={this.props.svg} iconSize={BlockSize.Status} iconVariant={iconVariant} />
                <div className='text__modal-title text--lh-std text--center padding-top--large'>
                    {this.props.title}
                </div>
                {this.props.description && (
                    <MultilineText
                        className={cx('padding-top--std text--lh-large text--center', textClass)}
                    >
                        {this.props.description}
                    </MultilineText>
                )}
                <SeparatedContainer
                    column
                    alignItems='center'
                    paddingTop={30}
                    paddingBottom={20}
                    spacing={8}
                >
                    {this.props.buttons}
                </SeparatedContainer>
            </div>
        )
    }
}

interface WhiteModalContentProps extends SvgProps {
    variant: WhiteModalContentVariant
    title: string
    description?: string
    buttons?: JSX.Element | JSX.Element[]
}

export enum WhiteModalContentVariant {
    Blue,
    Red,
}

const colorMapping: Dictionary<[IconVariant, string]> = _.fromPairs([
    [
        WhiteModalContentVariant.Blue,
        [IconVariant.Blue, 'text__l-grey-txt'] as [IconVariant, string],
    ],
    [
        WhiteModalContentVariant.Red,
        [IconVariant.Red, 'text__l-grey-txt text--error'] as [IconVariant, string],
    ],
])
