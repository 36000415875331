import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum CdmMetricType {
    TOBT_vs_TSAT = 'TOBT_vs_TSAT',
    TTOT_vs_ATOT = 'TTOT_vs_ATOT',
    CTOT_vs_ATOT = 'CTOT_vs_ATOT',
    AOBT_vs_ATOT = 'AOBT_vs_ATOT',
    ALDT_vs_AIBT = 'ALDT_vs_AIBT',
    EDIT_vs_ADIT = 'EDIT_vs_ADIT',
}

export const translateCmdMetricTypeTitle = translateEnum<CdmMetricType>(
    CdmMetricType,
    'dashboard.cdm.title',
)
