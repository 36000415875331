import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Text, TextProps } from '../base/Text'
import { CollapsibleButton, CollapsibleButtonVariant } from '../buttons/CollapsibleButton'
import { IconVariant } from '../svg/Icon'

interface CollapsibleContainerProps extends TextProps {
    isCollapsed: boolean
    variant: CollapsibleButtonVariant
    iconVariant?: IconVariant
    iconSize?: BlockSize
    label: ReactNode
    borderedTop?: boolean
    borderedBottom?: boolean

    toggle(): void
}

export const CollapsibleContainer: FCWithChildren<PropsWithChildren<CollapsibleContainerProps>> = ({
    label,
    toggle,
    variant,
    iconVariant,
    iconSize,
    children,
    isCollapsed,
    borderedTop,
    borderedBottom,
    ...marginProps
}) => (
    <>
        <Wrapper
            row
            shrink={0}
            onClick={toggle}
            borderedTop={borderedTop}
            borderedBottom={borderedBottom}
            pointer
            data-test-id='collapsible-container'
            {...marginProps}
        >
            <CollapsibleButton
                isCollapsed={isCollapsed}
                variant={variant}
                iconVariant={iconVariant}
                iconSize={iconSize}
            />
            {label}
        </Wrapper>
        {!isCollapsed && children}
    </>
)

const Wrapper = styled(Text)<{ borderedBottom?: boolean; borderedTop?: boolean }>`
    ${p =>
        p.borderedTop &&
        css`
            border-top: 1px solid ${Color.UiBlack3};
        `}
    ${p =>
        p.borderedBottom &&
        css`
            border-bottom: 1px solid ${Color.UiBlack3};
        `}
`
