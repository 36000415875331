import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { ErrorInfo, PropsWithChildren, PureComponent } from 'react'

export class ErrorBoundary extends PureComponent<PropsWithChildren> {
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.handleErrorWithErrorInfo(error, errorInfo as ErrorInfo & { componentStack: string })
    }

    public render() {
        return this.props.children as any
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }
}
