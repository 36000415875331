import { IExportForm } from 'aos-helpers/src/helpers/TaskExport'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { locationName } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CheckboxList } from 'aos-ui/src/components/form/checkbox/CheckboxList'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { unionWith } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const AirportSelection = () => {
    const { control, watch } = useFormContext<IExportForm>()
    const [query, setQuery] = useState('')

    const filteredAirports = useMemo(
        () =>
            watch().airports.filter(
                airport =>
                    airport[0].toLowerCase().includes(query.toLowerCase()) ||
                    locationName(airport[0]).toLowerCase().includes(query.toLowerCase()),
            ),
        [query, watch().airports],
    )

    return (
        <Box overflow='hidden' height={350} rowGap={12} column>
            <Text color={Color.UiBlack1} size={18}>
                {translate('export-tasks.airport.title')}
            </Text>
            <CleanableInput
                value={query}
                onChangeText={setQuery}
                placeholder={translate('export-tasks.airport.search')}
                svg={SvgIcon.SearchWhite}
            />
            <Box column flex={1}>
                <Text color={Color.Grey8} size={12}>
                    {translate('export-tasks.airport.selected', {
                        selected: watch()
                            .airports.filter(q => q[1])
                            .map(q => q[0])
                            .join(', '),
                    })}
                </Text>
                <Box flex={1}>
                    <DarkScrollbar>
                        <Controller
                            name='airports'
                            control={control}
                            render={({ field, fieldState }) => (
                                <Box>
                                    <CheckboxList
                                        {...field}
                                        enableSelectAll
                                        selectAllLabel={translate(
                                            'export-tasks.airport.select-all',
                                            {
                                                count: filteredAirports.length,
                                            },
                                        )}
                                        onChange={items =>
                                            field.onChange(
                                                unionWith(
                                                    items,
                                                    control._defaultValues.airports,
                                                    (q, p) => q?.[0] === p?.[0],
                                                ),
                                            )
                                        }
                                        itemToLabel={item => `${locationName(item)} (${item})`}
                                        items={filteredAirports}
                                    />
                                    {fieldState.error && (
                                        <Text
                                            size={12}
                                            color={Color.Red}
                                            paddingTop={4}
                                            lineHeight='standard'
                                        >
                                            {fieldState.error.message}
                                        </Text>
                                    )}
                                </Box>
                            )}
                        />
                    </DarkScrollbar>
                </Box>
            </Box>
        </Box>
    )
}
