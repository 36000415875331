import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import React, { FC, PropsWithChildren } from 'react'

import { WhiteModal } from '../WhiteModal/WhiteModal'

interface FormModalProps {
    isOpen: boolean
    pristine: boolean
    valid: boolean
    keyPrefix: string
    customFooter?: (props: FooterModalProps) => JSX.Element

    submit: f.Action
    hide: f.Action
}

export interface FooterModalProps {
    submit: f.Action
    pristine: boolean
    valid: boolean
    translateSuffix: (v: string) => string
}

export const FormModal: FC<PropsWithChildren<FormModalProps>> = props => {
    const translateSuffix = (v: string) => translate(`${props.keyPrefix}.${v}`)
    return (
        <WhiteModal
            isOpen={props.isOpen}
            closeAction={props.hide}
            title={translateSuffix('title')}
            footerSeparator
            footer={
                props.customFooter ? (
                    props.customFooter({ ...props, translateSuffix })
                ) : (
                    <div className='container container--horizontal full-width'>
                        <ValidationInfo pristine={props.pristine} valid={props.valid} />
                        <FormButton label={translateSuffix('ok')} onClick={props.submit} />
                    </div>
                )
            }
        >
            <div className='flex--1 padding-horizontal--x-large padding-top--std'>
                {props.children}
            </div>
        </WhiteModal>
    )
}
