export interface ReportingState {
    isOpen: boolean
}

export type ReportingStateAware = {
    reporting: ReportingState
}

export const initialReportingState: ReportingState = {
    isOpen: false,
}
