import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { pick } from 'lodash'

import { RingRailTrain, RingRailTrainScheduleStatus } from '../types/RingRailTrain'

export interface RingRailTrainMapProperties {
    trainNumber: number
    commuterLine: string
    scheduleStatus: RingRailTrainScheduleStatus
    position?: LonLat
    angle?: number
}

export const ringRailTrainToRingRailTrainMapProperties = (train: RingRailTrain) => ({
    ...pick(train, ['trainNumber', 'commuterLine', 'scheduleStatus', 'position', 'angle']),
})
export const hasTrainDirection = (train: RingRailTrain | RingRailTrainMapProperties): boolean =>
    !!train.angle
