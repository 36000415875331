import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { PropsWithChildren, PureComponent } from 'react'

import { SimpleFormModalActionCreators } from './core/simpleForm/actions'
import { SimpleFormModalState } from './core/simpleForm/state'
import { FormModal } from './FormModal'
import { SimpleFormFeedback } from './SimpleFormFeedback'

export class SimpleFormModal extends PureComponent<
    PropsWithChildren<
        SimpleFormModalProps &
            SimpleFormModalBaseProps &
            SimpleFormModalState<any, any, any> &
            SimpleFormModalActionCreators
    >
> {
    public render() {
        return this.props.feedback ? this.renderFeedback() : this.renderContent()
    }

    private renderContent() {
        return (
            <FormModal
                isOpen={this.props.isOpen}
                pristine={this.props.form.pristine}
                valid={this.props.form.valid}
                keyPrefix={this.props.keyPrefix}
                hide={this.props.hideModelAction}
                submit={this.trySubmit}
            >
                {this.props.children}
            </FormModal>
        )
    }

    private trySubmit = () => {
        if (this.props.form.valid) {
            this.props.submitAction(this.props.currentValue)
            this.props.hideModelAction()
        } else {
            this.props.setFormPristineAction(false)
        }
    }

    private translateSuffix = (v: string) => translate(`${this.props.keyPrefix}.${v}`)

    private renderFeedback = () => {
        const { isOpen, hideModelAction, clearFeedback, feedback } = this.props
        return (
            <SimpleFormFeedback
                isOpen={isOpen}
                closeAction={hideModelAction}
                backAction={clearFeedback}
                feedback={feedback!}
                title={this.translateSuffix('title')}
            />
        )
    }
}

export interface SimpleFormModalProps {
    keyPrefix: string
}

export interface SimpleFormModalBaseProps {
    hideModelAction(): void
    clearFeedback(): void
    setFormPristineAction(v: boolean): void
    submitAction(v: any): void
}
