import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Icon, IconVariant } from '../svg/Icon'

export interface HeaderButtonProps extends ButtonProps {
    isExpanded: boolean
}

export const BurgerButton: FCWithChildren<HeaderButtonProps> = ({ isExpanded, ...props }) => (
    <BurgerButtonWrapper onClick={props.onClick}>
        <Icon
            iconSize={BlockSize.Large}
            iconVariant={IconVariant.BlackGrey}
            svg={isExpanded ? SvgIcon.Close : SvgIcon.Burger}
        />
    </BurgerButtonWrapper>
)

const BurgerButtonWrapper = styled(Button)`
    display: flex;
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
`
