import { dateTime } from 'aos-helpers/src/helpers/Time'
import { delay, put } from 'redux-saga/effects'

import { setCurrentTimeAction } from './actions'

function* currentTimeSaga() {
    while (true) {
        yield delay(60 * 1000)
        yield put(setCurrentTimeAction(dateTime().startOf('minute')))
    }
}

export const commonSagas = [currentTimeSaga]
