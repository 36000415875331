import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { PaxForecastArrivalDepartureTrend } from './PaxForecastArrivalDepartureTrend'
import { PaxForecastTotalTrend } from './PaxForecastTotalTrend'

interface PaxForecastStatsSmallProps {
    stats: PaxForecastStats
}

export const PaxForecastStatsSmall: FC<PaxForecastStatsSmallProps> = props => (
    <>
        <Box marginRight={30}>
            <PaxForecastTotalTrend stats={props.stats} />
        </Box>
        <Box marginRight={30}>
            <PaxForecastArrivalDepartureTrend
                stats={props.stats}
                type={PaxForecastArrivalOrDeparture.Arrivals}
            />
        </Box>
        <PaxForecastArrivalDepartureTrend
            stats={props.stats}
            type={PaxForecastArrivalOrDeparture.Departures}
        />
    </>
)
