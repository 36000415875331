import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

import { JustifyContent } from '../../base/Box'
import { CheckableBlock } from '../base/CheckableBlock'

interface DefaultRadioRendererProps {
    isSelected: boolean
    label: ReactNode
    color?: Color
    onSelect(): void
    justifyContent?: JustifyContent
}

export const DefaultRadioRenderer: FCWithChildren<DefaultRadioRendererProps> = ({
    isSelected,
    label,
    onSelect,
    color = Color.Primary,
    justifyContent = 'flex-start',
}) => {
    return (
        <CheckableBlock
            row
            isSelected={isSelected}
            color={color}
            cursor='pointer'
            onClick={onSelect}
            paddingHorizontal={8}
            justify={justifyContent}
        >
            {label}
        </CheckableBlock>
    )
}
