import { dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { saveOperationalForecastAction } from 'aos-services/src/core/statusDashboardData/operationalForecast/actions'
import {
    operationalForecastSelector,
    OperationalForecastSelectorState,
} from 'aos-services/src/core/statusDashboardData/operationalForecast/selectors'
import { OperationalItem } from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import {
    operationalForecastFiltersValues,
    OperationalForecastGroupFilter,
    translateOperationalForecastFiltersValues,
} from 'aos-services/src/services/statusDashboard/types/filters/OperationalForecastFilters'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setOperationalForecastTabStateAction } from '../../../../core/statusDashboard/actions'
import { OperationalForecastItemState } from '../../../../services/statusDashboard/types/itemStates/OperationalForecastItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { Footer } from '../base/Footer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { OperationalForecastEditModal } from './OperationalForecastEditModal'
import { OperationalForecastGroupBlocks } from './OperationalForecastGroupBlocks'
import { OperationalForecastList } from './OperationalForecastList'
import { OperationalForecastItem } from './partials/OperationalForecastItem'

interface OperationalForecastState {
    isEditOpen: boolean
}

class OperationalForecastClass extends PureComponent<
    OperationalForecastSelectorState &
        OperationalForecastDispatchProps &
        OperationalForecastOwnProps,
    OperationalForecastState
> {
    public state: OperationalForecastState = {
        isEditOpen: false,
    }

    public render() {
        const { allItems, lastUpdated, id } = this.props
        const { isEditOpen } = this.state
        const handleSave = (items: OperationalItem[]) => {
            this.setState({ isEditOpen: false })
            this.props.saveOperationalForecastAction({
                items,
                lastUpdated: dateTime(),
            })
        }
        return (
            <OperationalForecastItem
                itemProps={pickDashboardItemProps(this.props)}
                noDataWarning={false}
                lastUpdated={lastUpdated}
                syncId={id}
            >
                {this.renderContent()}
                <OperationalForecastEditModal
                    items={allItems}
                    isOpen={isEditOpen}
                    onClose={() => this.setState({ isEditOpen: false })}
                    onSave={handleSave}
                />
            </OperationalForecastItem>
        )
    }

    private renderContent() {
        const { itemState } = this.props
        return itemState.size === DashboardItemSizeVariant.Small
            ? this.renderSmallVariant()
            : this.renderBigVariant()
    }

    private renderSmallVariant = () => {
        const { blocks } = this.props
        return <OperationalForecastGroupBlocks items={blocks} />
    }

    private renderBigVariant = () => (
        <>
            <OperationalForecastList items={this.props.items} />
            <Footer row>
                <Box flex='auto'>
                    <MultiRoundTabs items={this.tabsConfig} />
                </Box>
                <Box marginLeft={20}>
                    {this.props.isApoc && (
                        <FormButton
                            size={FormComponentSize.Small}
                            label='Edit'
                            onClick={() => this.setState({ isEditOpen: true })}
                        />
                    )}
                </Box>
            </Footer>
        </>
    )

    private get tabsConfig(): RoundTabsProps<number | string>[] {
        return [
            {
                items: operationalForecastFiltersValues,
                value: this.props.itemState.group,
                name: 'operational-forecast-group',
                formatter: translateOperationalForecastFiltersValues,
                onChange: this.changeGroup,
                tooltip: translate('dashboard.operational-forecast.group.time-range.tooltip'),
                width: DropdownWidth.Medium,
            },
        ]
    }

    private changeGroup = (groupFilter: OperationalForecastGroupFilter) =>
        this.props.setOperationalForecastTabStateAction([this.props.id, { group: groupFilter }])
}

interface OperationalForecastDispatchProps {
    setOperationalForecastTabStateAction: typeof setOperationalForecastTabStateAction
    saveOperationalForecastAction: typeof saveOperationalForecastAction
}

type OperationalForecastOwnProps = StatusDashboardItemCommonProps<OperationalForecastItemState>

export const OperationalForecast = connect<
    OperationalForecastSelectorState,
    OperationalForecastDispatchProps,
    OperationalForecastOwnProps
>(operationalForecastSelector, {
    setOperationalForecastTabStateAction,
    saveOperationalForecastAction,
})(OperationalForecastClass)
