import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { uuid } from 'aos-helpers/src/helpers/Uuid'

import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import { FirefighterFormFields } from '../../../services/tasks/firefighters/types/FirefighterFormFields'
import { FirefighterShift } from '../../../services/tasks/firefighters/types/FirefighterShift'
import {
    FirefighterShiftDto,
    FirefighterShiftReqBodyDto,
} from '../../../services/tasks/firefighters/types/FirefighterShiftDto'
import { firefightersRestService } from './firefightersRestService'

class FireFightersService {
    public reloadFireFighters = (
        location: AosAirport,
        data: Pageable<FirefighterShiftDto>,
    ): Promise<Pageable<FirefighterShiftDto>> =>
        firefightersRestService.getShifts(location, data.number, data.size)

    private mapShiftDtoToShift = (
        fightersShiftDto: Pageable<FirefighterShiftDto>,
    ): Pageable<FirefighterShift> => ({
        ...fightersShiftDto,
        content: fightersShiftDto.content.map(fighterShift => ({
            ...fighterShift,
            validUntil: dateTime(fighterShift.validUntil),
            validFrom: dateTime(fighterShift.validFrom),
        })),
    })

    public getShifts = async (siteLocation: AosAirport) => {
        const fireFightersData = await firefightersRestService.getShifts(siteLocation)
        return this.mapShiftDtoToShift(fireFightersData)
    }

    public mapShiftToFormFields = (shiftToUpdate: FirefighterShift): FirefighterFormFields => {
        return {
            id: shiftToUpdate.id,
            siteLocation: shiftToUpdate.siteLocation,
            validUntil: shiftToUpdate.validUntil,
            reporter: { name: shiftToUpdate.reporterName, id: shiftToUpdate.reporterId },
            fireFighterSquads: shiftToUpdate.fireFighterSquads.map(squad => ({
                id: squad.id,
                fireTruckId: squad.fireTruckId,
                fireTruck: { ...squad.fireTruck! },

                fireFighters: squad.fireFighters.map(fireFighter => ({
                    key: uuid(),
                    id: fireFighter.id,
                    kat12: fireFighter.kat12,
                    lp3: fireFighter.lp3,
                    lp5: fireFighter.lp5,
                    user: { id: fireFighter.truckUserId, name: fireFighter.truckUserName },
                })),
            })),
        }
    }

    public formDataToUpdateBody = (
        data: FirefighterFormFields,
        siteLocation: AosAirport,
    ): FirefighterShiftReqBodyDto => {
        return {
            reporterId: data.reporter!.id,
            siteLocation,
            validUntil: data.validUntil!.toISOString(),
            id: data.id,
            fireFighterSquads: data.fireFighterSquads.map(({ fireTruckId, id, fireFighters }) => ({
                fireTruckId: fireTruckId!,
                id,
                fireFighters: fireFighters.map(({ id, kat12, lp3, lp5, user }) => ({
                    id,
                    kat12,
                    lp3,
                    lp5,
                    truckUserId: user!.id,
                })),
            })),
        }
    }
    public add = async (siteLocation: AosAirport, data: FirefighterFormFields) => {
        const body = this.formDataToUpdateBody(data, siteLocation)
        await firefightersRestService.add(body)
    }

    public update = async (siteLocation: AosAirport, data: FirefighterFormFields) => {
        const body = this.formDataToUpdateBody(data, siteLocation)
        await firefightersRestService.update(body)
    }

    public getReloadedFightersList = async (
        siteLocation: AosAirport,
        data: Pageable<FirefighterShiftDto>,
    ) => {
        const fireFightersData = await this.reloadFireFighters(siteLocation, data)

        return this.mapShiftDtoToShift(fireFightersData)
    }
}

export const firefightersService = new FireFightersService()
