import React, { FCWithChildren, useState } from 'react'

import { Input, InputStyleWrapperProps } from './Input'

interface NumberInputProps extends InputStyleWrapperProps {
    value?: number
    width?: number
    onChange(value: number | undefined): void
    fallback?: number
    disabled?: boolean
}

export const NumberInput: FCWithChildren<NumberInputProps> = ({
    value,
    width,
    fallback,
    onChange,
    disabled,
    ...rest
}) => {
    const [localValue, setLocalValue] = useState(value ?? fallback ?? '')

    const handleChange = (value: string) => {
        setLocalValue(value)

        const number = parseInt(value, 10)

        if (Number.isNaN(number)) {
            onChange(fallback)
        } else {
            onChange(number)
        }
    }

    const handleBlur = () => {
        setLocalValue(value ?? '')
    }

    return (
        <Input
            value={localValue}
            onChange={({ target }) => handleChange(target.value)}
            onBlur={handleBlur}
            style={{ width }}
            disabled={disabled}
            {...rest}
        />
    )
}
