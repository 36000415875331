import ReactGa from 'react-ga'

class AnalyticsService {
    private isInitialized: boolean = false

    public initialize = (tag: string) => {
        ReactGa.initialize(tag)
        this.isInitialized = true
    }

    public pageView = (pathname: string, search?: string) => {
        if (this.isInitialized) {
            ReactGa.pageview(pathname + search)
        }
    }

    public buttonClick = (buttonId: string) => {
        if (this.isInitialized) {
            ReactGa.event({
                category: 'buttonClick',
                action: buttonId,
            })
        }
    }
}

export const analyticsService = new AnalyticsService()
