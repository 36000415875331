import { dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosChecklistTemplateDto } from '../../../dataaccess/checklists/types/AosChecklistTemplateDto'
import { AosChecklistItemImpl } from './AosChecklistItemImpl'
import { AosChecklistItemType } from './AosCheckListItemType'
import { AosChecklistTemplate } from './AosChecklistTemplate'
import { AosChecklistTemplateStatus } from './AosChecklistTemplateStatus'
import { AosChecklistUnits } from './AosChecklistUnits'

export class AosChecklistTemplateImpl
    extends WrapperObject<AosChecklistTemplateDto>
    implements AosChecklistTemplate {
    constructor(p: AosChecklistTemplateDto) {
        super(p)
    }

    public get status() {
        return this.value.status as AosChecklistTemplateStatus
    }

    public get id() {
        return this.value.id
    }

    public get tag() {
        return this.value.tag
    }

    public get name() {
        return this.value.name
    }

    public get createdAt() {
        return dateTimeFromOptional(this.value.createdAt)
    }

    public get createdBy() {
        return this.value.createdBy
    }

    public get items() {
        return this.value.items.map(item => new AosChecklistItemImpl(item))
    }

    public set items(items: AosChecklistItemImpl[]) {
        this.value.items = items
    }

    public get tasksAndDescriptions() {
        return this.items.filter(t => t.type !== AosChecklistItemType.MessageTemplate)
    }

    public get messageTemplates() {
        return this.items.filter(t => t.type === AosChecklistItemType.MessageTemplate)
    }

    public get unit() {
        return this.value.unit as AosChecklistUnits
    }

    public get lastUsed() {
        return dateTimeFromOptional(this.value.lastUsed)
    }

    public get modifiedAt() {
        return dateTimeFromOptional(this.value.modifiedAt)
    }

    public get modifiedBy() {
        return this.value.modifiedBy
    }

    public get sequenceNumber() {
        return this.value.sequenceNumber
    }

    public get reviewedBy() {
        return this.value.reviewedBy
    }

    public get reviewedAt() {
        return dateTimeFromOptional(this.value.reviewedAt)
    }
}
