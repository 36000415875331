import { AbstractEntity } from '../../base/types/AbstractEntity'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { TaskCommonProps } from '../../tasks/types/task/BaseTask'
import { TaskCategory } from '../../tasks/types/TaskCategory'
import { TaskProcessType } from '../../tasks/types/TaskProcessType'

export interface MapTask extends BaseMapTask, Omit<TaskCommonProps, 'location'> {}

export interface BaseMapTask extends AbstractEntity {
    location: AosLocation
    severity: AosSeverity
    category: TaskCategory
    processType: TaskProcessType
}

export interface MapTaskProperties {
    task: MapTask
}

export const newBaseAosMapTask = (
    location: AosLocation,
    task: { severity?: AosSeverity; category?: TaskCategory; processType?: TaskProcessType },
) => ({
    location,
    severity: task.severity || AosSeverity.Info,
    category: task.category || TaskCategory.Maintenance,
    processType: task.processType || TaskProcessType.Daily,
    id: 0,
})
