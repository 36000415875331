import {
    AnimalsTask,
    translateAnimalAge,
    translateAnimalGender,
    translateAnimalsTaskType,
} from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import { translateTrueFalse } from 'aos-services/src/services/tasks/types/task/BaseTask'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'

export const animalsTaskPropertyList = (t: AnimalsTask): PropsListEntry[] => [
    ['tasks.task-preview.animal-task-type', translateAnimalsTaskType(t.animalTaskType)],
    ['tasks.task-preview.animal-species', t.animalSpecies.label],
    ['tasks.task-preview.amount', t.amount],
    ['tasks.task-preview.cartridges', t.cartridges],
    ['tasks.task-preview.gender', translateAnimalGender(t.gender)],
    ['tasks.task-preview.age', translateAnimalAge(t.lifeStage)],
    ['tasks.task-preview.horns', translateTrueFalse(t.horns)],
    ['tasks.task-preview.spike-amount', t.amountOfSpikes],
    ['tasks.task-preview.expulsions', t.expulsions],
]
