import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { HorizontalLegend } from 'aos-ui/src/components/chart/HorizontalLegend'
import { LegendItem } from 'aos-ui/src/components/ui/LegendItem'
import { LegendDotConfig } from 'aos-ui/src/components/ui/LegendItemDot'
import React, { FC } from 'react'

export type LegendItemConfig = [Omit<LegendDotConfig, 'size'>, string]

interface SimpleHorizontalLegendProps {
    size: BlockSize
    leftItems?: LegendItemConfig[]
    rightItems?: LegendItemConfig[]
}

/* eslint-disable react/jsx-key */
export const SimpleHorizontalLegend: FC<SimpleHorizontalLegendProps> = ({
    leftItems = [],
    rightItems = [],
    size,
}) => (
    <HorizontalLegend
        leftItems={leftItems.map(([config, label]) => (
            <LegendItem dotConfig={{ ...config, size }}>{label}</LegendItem>
        ))}
        rightItems={rightItems.map(([config, label]) => (
            <LegendItem dotConfig={{ ...config, size }}>{label}</LegendItem>
        ))}
    />
)
