import { SingleInputActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import { SingleInputModalState } from 'aos-components/src/components/modal/InputModal/core/singleInput/state'
import { SimpleFormModalProps } from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { SingleTextAreaModalClass } from 'aos-components/src/components/modal/InputModal/SingleTextAreaModal'
import { connect } from 'react-redux'

import { editTaskOrDescriptionActions } from '../../../../core/checklistManager/actions'
import { State } from '../../../../core/state'

export const EditTaskOrDescriptionModal = connect<
    SingleInputModalState,
    SingleInputActionCreators,
    SimpleFormModalProps
>(
    (state: State) => state.checklistManager.editTaskOrDescriptionModal,
    editTaskOrDescriptionActions,
)(SingleTextAreaModalClass)
