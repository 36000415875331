import { Box } from 'aos-ui/src/components/base/Box'
import { BaseChartProps } from 'aos-ui-common/src/components/chart/BaseChart'
import { ChartProvider } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { getRanges, getRealChartSize } from 'aos-ui-common/src/components/chart/types/Chart'
import React, { FC } from 'react'
import { SizeMe, SizeMeProps } from 'react-sizeme'

export const BaseChart: FC<BaseChartProps> = ({ margins, legends, flex = false, children }) => {
    const renderContent = (sizeProps: SizeMeProps) => {
        const outerSize = { width: sizeProps.size.width || 0, height: sizeProps.size.height || 0 }
        const size = getRealChartSize(outerSize, margins)
        const { xRange, yRange } = getRanges(margins, outerSize)
        const childProps = {
            outerSize,
            size,
            margins,
            xRange,
            yRange,
        }
        return (
            <ChartProvider value={childProps}>
                <Box relative flex={flex ? 'auto' : undefined} fullSize={!flex}>
                    <svg width={outerSize.width} height={outerSize.height}>
                        <g>{children(childProps)}</g>
                    </svg>
                    {typeof legends === 'function' ? legends(childProps) : legends}
                </Box>
            </ChartProvider>
        )
    }
    return <SizeMe monitorHeight>{renderContent}</SizeMe>
}
