import OlBaseLayer from 'ol/layer/Base'

import { layerIdKey } from '../OpenlayersMapContext'
import { BaseComponent } from './BaseComponent'

export class BaseLayer<T extends BaseLayerProps = BaseLayerProps> extends BaseComponent<T, {}> {
    protected layer: OlBaseLayer
    constructor(props: T, layer: OlBaseLayer) {
        super(props)

        this.layer = layer
        // allow to get reference to this layer in selection components
        this.layer.set(layerIdKey, this.props.layerId, true)
    }

    public componentDidMount() {
        this.context.map.addLayer(this.layer)
        this.setProps(this.props)
    }

    public componentWillUnmount() {
        this.context.map.removeLayer(this.layer)
    }

    public componentWillReceiveProps(nextProps: T) {
        this.setProps(nextProps)
    }

    private setProps({ minZoom, maxZoom, isVisible, zIndex }: T) {
        this.layer.setVisible(isVisible !== false)
        this.layer.setZIndex(zIndex)
        if (minZoom !== undefined) {
            this.layer.setMaxResolution(this.getResolutionForZoom(minZoom!))
        }
        if (maxZoom !== undefined) {
            this.layer.setMinResolution(this.getResolutionForZoom(maxZoom!))
        }
    }

    private getResolutionForZoom = (zoom: number): number =>
        (this.context.map.getView() as any).getResolutionForZoom(zoom)
}

export interface BaseLayerProps {
    isVisible?: boolean
    zIndex: number
    layerId?: string
    minZoom?: number
    maxZoom?: number
}
