import {
    SHOW_MODAL_WITH_PAYLOAD,
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { call, put, select } from 'redux-saga/effects'

import { userGroupsSelector } from '../../commonFrontend/state'
import {
    loadEventInEventEditorAction,
    SELECT_USER_GROUP_PARENT,
    selectUserGroupActions,
} from '../actions'

function* createPrivateChannelSaga() {
    function* createPrivateChannel(action: SubmitAction) {
        const value = action.payload.value as {
            group: AosUserGroup
            isHardAlert: boolean
            eventId: number
        }
        yield call(
            eventsService.createPrivateChannel,
            value.eventId,
            value.group.id,
            value.isHardAlert,
        )
        yield put(loadEventInEventEditorAction(value.eventId))
    }

    yield takeEveryChildAction<SubmitAction>(SELECT_USER_GROUP_PARENT, SUBMIT, createPrivateChannel)
}

function* loadGroupRefsSaga() {
    function* loadGroupRefs() {
        const refs: AosUserGroup[] = yield select(userGroupsSelector)
        yield put(selectUserGroupActions.setOptionsAction(refs))
    }

    yield takeEveryChildAction<SubmitAction>(
        SELECT_USER_GROUP_PARENT,
        SHOW_MODAL_WITH_PAYLOAD,
        loadGroupRefs,
    )
}

export const selectUserGroupSagas = [createPrivateChannelSaga, loadGroupRefsSaga]
