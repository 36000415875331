import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { FeedDto } from '../../../dataaccess/newsFeed/types/FeedDto'
import { Feed } from './Feed'

export class FeedImpl extends WrapperObject<FeedDto> implements Feed {
    constructor(entity: FeedDto) {
        super(entity)
    }

    public get title() {
        return this.value.title
    }

    public get link() {
        return this.value.link
    }

    public get description() {
        return this.value.description
    }

    public get comments() {
        return this.value.comments
    }

    public get id() {
        return this.link
    }

    public get pubDate() {
        return dateTime(this.value.pubDate)
    }

}
