import { Box } from 'aos-ui/src/components/base/Box'
import React, { PropsWithChildren, PureComponent, ReactNode } from 'react'
import {
    arrayMove,
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortEnd,
} from 'react-sortable-hoc'

export { SortableHandle as sortableHandle } from 'react-sortable-hoc'

interface SortableProps<I> extends SortableContainerProps {
    items: I[]
    renderItem: (item: I, index: number) => ReactNode
    onChange: (sortedItems: I[]) => void
    getKey: (item: I) => string
    isDisabled?: (item: I) => boolean
}

export class Sortable<I> extends PureComponent<SortableProps<I>> {
    public render() {
        const { items, ...options } = this.props

        return (
            <Container onSortEnd={this.handleSortEnd} {...options}>
                {items.map(this.renderItem)}
            </Container>
        )
    }

    private handleSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        const { onChange, items } = this.props
        onChange(arrayMove(items, oldIndex, newIndex))
    }

    private renderItem = (item: I, index: number) => {
        const { renderItem, isDisabled, getKey } = this.props
        return (
            <SortableItem
                key={getKey(item)}
                index={index}
                children={renderItem(item, index)}
                disabled={isDisabled ? isDisabled(item) : false}
            />
        )
    }
}

const Container = SortableContainer<PropsWithChildren>((props: PropsWithChildren) => (
    <Box>{props.children}</Box>
))

export const SortableItem = SortableElement<PropsWithChildren>((props: PropsWithChildren) => (
    <>{props.children}</>
))
