import { BaggageDeliveryStat } from 'aos-services/src/services/airportStatus/baggage/types/BaggageDeliveryStat'
import React, { FC } from 'react'

import { ItemContainer } from '../../base/ItemContainer'
import { BaggageDeliveryChart } from '../BaggageDeliveryChart'

interface BaggageDeliveryCompactViewProps {
    stats: BaggageDeliveryStat
}

export const BaggageDeliveryCompactView: FC<BaggageDeliveryCompactViewProps> = props => (
    <ItemContainer horizontal horizontalSpacing autoHeight>
        <BaggageDeliveryChart {...props.stats} />
    </ItemContainer>
)
