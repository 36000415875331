import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { FireFightersWidget } from './FireFighters'

export const FireFightersConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 12, h: 12 },
        },
    },
    renderer: FireFightersWidget,
}
