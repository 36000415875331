import { Box } from 'aos-ui/src/components/base/Box'
import { CheckableBlock } from 'aos-ui/src/components/form/base/CheckableBlock'
import { Color } from 'aos-ui-common/src/styles/Color'
import { xor } from 'lodash'
import React, { ReactNode } from 'react'

interface BlockCheckboxGroupProps<T> {
    values: T[]
    items: T[]
    renderer: (item: T, selected: boolean) => ReactNode
    onChange(v: T[]): void
    condensed?: boolean
    stretch?: boolean
    disabled?: boolean
}

export const BlockCheckboxGroup = <T extends any>({
    items,
    values,
    condensed,
    stretch,
    renderer,
    onChange,
    disabled,
}: BlockCheckboxGroupProps<T>) => {
    const margin = condensed ? 2 : 4
    const padding = condensed ? 6 : 12
    const onChangeItems = (item: T) => {
        return disabled ? () => {} : onChange(xor(values, [item]))
    }

    return (
        <Box row wrap alignItems='flex-start'>
            {items.map((item: T, index: number) => {
                const selected = values.includes(item)
                return (
                    <CheckableBlock
                        onClick={() => onChangeItems(item)}
                        key={index}
                        color={Color.Primary}
                        isSelected={selected}
                        marginRight={margin}
                        marginBottom={margin}
                        paddingHorizontal={padding}
                        flex={stretch ? 1 : 0}
                        height={condensed ? 30 : undefined}
                        justify='center'
                        alignContent='center'
                        disabled={disabled}
                    >
                        {renderer(item, selected)}
                    </CheckableBlock>
                )
            })}
        </Box>
    )
}
