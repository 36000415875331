import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateTrend } from 'aos-helpers/src/helpers/trend/Trend'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import { FlightVolumesStats } from 'aos-services/src/services/airportStatus/flights/types/FlightVolumesStats'
import { FlightsGrouping } from 'aos-services/src/services/statusDashboard/types/filters/FlightsVolumesFilters'
import { LabeledDispersionBar } from 'aos-ui/src/components/bar/LabeledDispersionBar'
import { Box } from 'aos-ui/src/components/base/Box'
import { TrendMeasure } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC } from 'react'

import { flightsDispersionBarConfig } from '../FlightsConsts'

interface FlightStatsSectionProps {
    stats: FlightVolumesStats
    tab: FlightsGrouping
}

export const FlightStatsSection: FC<FlightStatsSectionProps> = props => {
    const { stats, tab } = props
    const trendKey =
        tab === FlightsGrouping.Total
            ? 'dashboard.flights.label.total'
            : 'dashboard.flights.label.hourly'
    const finalStats = stats[tab]

    return (
        <Box row fullWidth>
            <Tooltip
                body={translateTrendTooltip(finalStats[FlightsType.All].trend)}
                placement='top'
                withArrow
            >
                <div>
                    <Box alignItems='flex-start' marginRight={20}>
                        <TrendMeasure
                            value={finalStats[FlightsType.All].value}
                            trend={finalStats[FlightsType.All].trend}
                            label={translate(trendKey)}
                        />
                    </Box>
                </div>
            </Tooltip>
            <LabeledDispersionBar
                marginTop={2}
                barConfig={flightsDispersionBarConfig}
                barData={[
                    {
                        value: finalStats[FlightsType.Arrivals].value,
                        label: translate('dashboard.flights.label.arrivals'),
                    },
                    {
                        value: finalStats[FlightsType.Departures].value,
                        label: translate('dashboard.flights.label.departures'),
                    },
                ]}
            />
        </Box>
    )
}

const translateTrendTooltip = translateTrend('dashboard.flights.trend.tooltip')
