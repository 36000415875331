import { FormModal } from 'aos-components/src/components/modal/InputModal/FormModal'
import { required, useBindForm, useForm } from 'aos-form'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ChecklistTemplatePayload } from 'aos-services/src/services/checklists/types/input/CheclistTemplatePayload'
import { Box } from 'aos-ui/src/components/base/Box'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { FormLabel } from 'aos-ui/src/components/form/labeled/FormLabel'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import React from 'react'
import { Dispatch } from 'redux'

import { ChecklistManagerAction } from '../../../../../core/checklistManager/actions'
import { LabeledDropdownAutocompleteCreateNew } from './components/LabeledDropdownAutocompleteCreateNew'

export interface ChecklistTemplateModalStateProps<T extends ChecklistTemplatePayload> {
    initialFormState: T
    isOpen: boolean
    keyPrefix: string
    existingTags: string[]

    submitAction: f.Func1<T, ChecklistManagerAction>
    hideAction: f.Func0<ChecklistManagerAction>
}

export interface ChecklistTemplateModalDispatchProps {
    dispatch: Dispatch<ChecklistManagerAction>
}

export const ChecklistTemplateModal = <T extends ChecklistTemplatePayload>(
    props: ChecklistTemplateModalStateProps<T> & ChecklistTemplateModalDispatchProps,
) => {
    const formApi = useForm({
        initialState: props.initialFormState,
        validators: { name: required() },
    })
    const { bindLabeledTextInput, bindDropdown } = useBindForm(formApi)

    const hide = () => props.dispatch(props.hideAction())
    const submit = formApi.submitHandler(formData => {
        props.dispatch(props.submitAction(formData))
        hide()
        formApi.reset()
    })

    return (
        <FormModal
            pristine={!formApi.allErrorsShown}
            valid={formApi.isValid()}
            isOpen={props.isOpen}
            hide={hide}
            keyPrefix={props.keyPrefix}
            submit={submit}
        >
            <LabeledInput
                {...bindLabeledTextInput('name')}
                isRequired
                keyPrefix='checklist-template.name'
                seleniumLocation='first-input'
            />
            <LabeledDropdownAutocompleteCreateNew
                {...bindDropdown('tag')}
                items={props.existingTags}
                keyPrefix='checklist-template.tag'
                seleniumLocation='second-input'
                labelRenderer={item => item}
            />
            {props.existingTags.length > 0 && (
                <Box>
                    <FormLabel>{translate('checklist-template.latest-tags.label')}</FormLabel>
                    <BorderedChipList
                        tags={props.existingTags}
                        onClick={tag => formApi.updateFieldValue('tag', tag)}
                    />
                </Box>
            )}
        </FormModal>
    )
}

const BorderedChipList = (props: { tags: string[]; onClick: f.Action1<string> }) => (
    <Box row wrap overflow='auto' height='50vh'>
        {props.tags.map(tag => (
            <Box key={tag} marginRight={12} marginBottom={8}>
                <BorderedChipButton onClick={() => props.onClick(tag)} tag={tag} />
            </Box>
        ))}
    </Box>
)

const BorderedChipButton = (props: { tag: string; onClick: f.Action }) => (
    <Button onClick={props.onClick}>
        <BorderedChip>{props.tag}</BorderedChip>
    </Button>
)
