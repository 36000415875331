import {
    isEndDateAfterStartDate,
    isNotEmpty,
    isNumber,
} from 'aos-helpers/src/helpers/FormValidation'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import moment, { Moment } from 'moment/moment'

import { AosLocation } from '../../../common/types/AosLocation'
import { AosSeverity } from '../../../common/types/AosSeverity'
import { validateHasAnyOccurrence } from '../../taskRecurrenceScheduleValidator'
import { BaseTask } from '../task/BaseTask'
import { TaskAssignee } from '../task/TaskAssignee'
import { TaskCategory } from '../TaskCategory'
import { TaskProcessType } from '../TaskProcessType'
import { TaskStatus } from '../TaskStatus'
import { MaintenanceJobAssignmentPayload } from './MaintenanceTaskFormPayload'
import {
    TaskRecurrencePayload,
    TaskRecurrenceValidation,
    validateTaskRecurrence,
} from './TaskRecurrenceFormPayload'

export interface BaseTaskFormPayload {
    id?: number
    parentTaskId?: number
    instanceId?: number

    severity?: AosSeverity
    category?: TaskCategory
    recurrence?: TaskRecurrencePayload
    startTime?: DateTime
    endTime?: DateTime
    location?: AosLocation
    title?: string
    description?: string
    status?: TaskStatus
    spendTimeInMinutes?: number
    assignees: TaskAssignee[]
    assignments?: MaintenanceJobAssignmentPayload[]
    checklistsTemplateIds?: number[]

    updateMode?: TaskUpdateMode
    processType?: TaskProcessType
}

export const initialBaseTaskPayload: BaseTaskFormPayload = {
    assignees: [],
    startTime: moment(),
    endTime: moment().add(1, 'hours'),
    checklistsTemplateIds: [],
}

export interface BaseTaskPayloadValidation {
    title: boolean
    category: boolean
    severity: boolean
    recurrenceSchedule?: boolean
    recurrence?: TaskRecurrenceValidation
    // cumulative
    base: boolean
    processType: boolean
    endTimeEmpty: boolean
    endTime: boolean
    status: boolean
    spendTimeInMinutes?: boolean
    location: boolean
}

export const baseValidateTask = (e: BaseTaskFormPayload): BaseTaskPayloadValidation => ({
    title: isNotEmpty(e.title),
    category: isNotEmpty(e.category),
    severity: isNotEmpty(e.severity),
    status: isNotEmpty(e.status),
    base:
        isNotEmpty(e.category) &&
        isNotEmpty(e.severity) &&
        isNotEmpty(e.status) &&
        timeSpentValidation(e) &&
        (e.category === TaskCategory.Animals ? isNotEmpty(e.location) : true),
    ...(e.recurrence && {
        recurrenceSchedule: validateHasAnyOccurrence(e.recurrence, e.startTime, e.endTime),
        recurrence: validateTaskRecurrence(e.recurrence),
    }),
    processType: isNotEmpty(e.processType),
    endTimeEmpty: isNotEmpty(e.endTime),
    endTime:
        isEndDateAfterStartDate(e.startTime, e.endTime) &&
        recurrenceTaskValidation(e.endTime, e.recurrence),
    spendTimeInMinutes: timeSpentValidation(e),
    location: e.category === TaskCategory.Animals ? isNotEmpty(e.location) : true,
})

const timeSpentValidation = (e: BaseTaskFormPayload) =>
    e.status === TaskStatus.Done && e.severity !== AosSeverity.Maintenance
        ? isNumber(e.spendTimeInMinutes) && (e.spendTimeInMinutes as number) > 0
        : true

export const recurrenceTaskValidation = (
    endTime: Moment | undefined,
    recurrence: TaskRecurrencePayload | undefined,
) => (recurrence === undefined || recurrence === null ? true : endTime !== undefined)

export const baseTaskFormPayloadFromTask = (
    t: BaseTask | BaseTaskFormPayload,
): BaseTaskFormPayload => ({
    id: t.id,
    parentTaskId: t.parentTaskId,
    instanceId: t.instanceId,
    severity: t.severity,
    category: t.category,
    startTime: t.startTime,
    endTime: t.endTime,
    location: t.location,
    title: t.title,
    description: t.description,
    assignees: t.assignees,
    recurrence: t.recurrence,
    processType: t.processType,
    status: t.status,
    spendTimeInMinutes: t.spendTimeInMinutes,
    checklistsTemplateIds: (t as BaseTask).checklists?.map(c => c.templateId),
})

export enum TaskUpdateMode {
    Instance = 'INSTANCE',
    Parent = 'PARENT',
    Clone = 'CLONE',
}

export const updateModes: TaskUpdateMode[] = EnumValues.getValues(TaskUpdateMode).filter(
    mode => mode !== TaskUpdateMode.Clone,
) as TaskUpdateMode[]
export const translateUpdateMode = translateEnum<TaskUpdateMode>(
    TaskUpdateMode,
    'tasks.update-modal.mode',
)

export const isPersistentRecurrenceTaskOrInstance = (v: BaseTaskFormPayload) => {
    return v.parentTaskId !== undefined || (v.id !== undefined && v.recurrence)
}

export const isPersistentTaskOrInstance = (v: BaseTaskFormPayload) => {
    return v.parentTaskId !== undefined || v.id !== undefined
}
