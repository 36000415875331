export abstract class WrapperObject<T> {
    protected value: T

    constructor(entity: T) {
        this.value = entity
    }

    public toObject<T>(): Record<keyof T, unknown> {
        const obj: Record<string, unknown> = {}
        const prototype = Object.getPrototypeOf(this)

        for (const key of Object.getOwnPropertyNames(prototype)) {
            const descriptor = Object.getOwnPropertyDescriptor(prototype, key)

            if (descriptor && descriptor.get) {
                obj[key] = this[key as keyof WrapperObject<T>]
            }
        }

        return obj as Record<keyof T, unknown>
    }
}
