import { zodResolver } from '@hookform/resolvers/zod'
import { Booleanish } from 'aos-helpers/src/helpers/Boolean'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { getGroupMembersCountTranslation } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Zod from 'zod'

import { hideActionModalAction } from '../../../core/actionModal/actions'
import { State } from '../../../core/state'

export const AtcHardAlertContainer = () => {
    const currentEvent = useSelector((state: State) => state.eventModals.currentEvent)
    const aosUserGroup = currentEvent.aosUserGroup
    const dispatch = useDispatch()

    return (
        <AtcHardAlert
            onSubmit={value => dispatch(hideActionModalAction(value))}
            aosUserGroup={aosUserGroup!}
        />
    )
}

export const AtcHardAlert: FC<{
    aosUserGroup: AosUserGroup
    onSubmit: (value: Booleanish) => void
}> = ({ aosUserGroup, onSubmit }) => {
    const [isInputVisible, setIsInputVisible] = useState(false)
    const email = useSelector((state: State) => state.auth.userProfile?.email)
    const form = useForm({
        mode: 'onSubmit',
        defaultValues: {
            email: '',
        },
        resolver: zodResolver(
            Zod.object({
                email: Zod.string()
                    .email()
                    .includes(email as string),
            }),
        ),
    })

    const onOkClick = () => {
        if (isInputVisible) {
            form.handleSubmit(() => {
                onSubmit(true)
            })()
        }
        setIsInputVisible(true)
    }

    const description = useMemo(
        () =>
            isInputVisible
                ? translate('send-hard-alert.explanation')
                : translate('send-hard-alert.description', {
                      people: `${aosUserGroup?.userCount} ${getGroupMembersCountTranslation(
                          aosUserGroup?.userCount as number,
                      )}`,
                      group: aosUserGroup?.name,
                  }),
        [aosUserGroup, isInputVisible],
    )

    return (
        <FormProvider {...form}>
            <Box rowGap={24} justify='center' column fullWidth paddingTop={24}>
                <Text textAlign='center' size={14} color={Color.Grey8}>
                    {description}
                </Text>
                {isInputVisible && (
                    <Controller
                        render={({ field, fieldState }) => (
                            <LabeledInput
                                {...field}
                                {...fieldState}
                                isRequired
                                isError={!!fieldState.error}
                                type='email'
                                hideLabel
                                fullWidth
                                keyPrefix='send-hard-alert.input'
                                value={field.value}
                                onChangeText={field.onChange}
                            />
                        )}
                        name='email'
                    />
                )}
                <Box rowGap={12} justify='center' column>
                    <FormButton
                        variant={FormButtonVariant.Red}
                        onClick={onOkClick}
                        label={translate('send-hard-alert.ok')}
                    />
                    <FormButton
                        onClick={() => onSubmit('unknown')}
                        variant={FormButtonVariant.Text}
                        label={translate('send-hard-alert.skip')}
                    />
                </Box>
            </Box>
        </FormProvider>
    )
}
