import { Box } from 'aos-ui/src/components/base/Box'
import {
    BottomLegendConfig,
    SiteLegendConfig,
} from 'aos-ui-common/src/components/chart/LegendConfig'
import { Margins } from 'aos-ui-common/src/components/chart/types/Margins'
import React, { CSSProperties, FC } from 'react'

interface ChartLegendsProps {
    margins: Margins
    leftLegend?: SiteLegendConfig
    rightLegend?: SiteLegendConfig
    bottomLegend?: BottomLegendConfig
}

export const ChartLegends: FC<ChartLegendsProps> = ({
    margins,
    bottomLegend,
    leftLegend,
    rightLegend,
}) => {
    const baseStyle: CSSProperties = { top: margins.top, position: 'absolute' }
    return (
        <>
            {leftLegend && (
                <Box style={{ ...baseStyle, left: 0, width: leftLegend?.width }}>
                    {leftLegend?.component}
                </Box>
            )}
            {rightLegend && (
                <Box style={{ ...baseStyle, right: 0, width: rightLegend?.width }}>
                    {rightLegend?.component}
                </Box>
            )}
            {bottomLegend && (
                <Box style={{ position: 'absolute', left: margins.left, bottom: 0, right: 0 }}>
                    {bottomLegend?.component}
                </Box>
            )}
        </>
    )
}
