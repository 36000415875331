import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { RestrictionResourceType } from 'aos-services/src/services/restrictions/types/RestrictionResourceType'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface RestrictionIconProps {
    type: RestrictionResourceType
    iconVariant: IconVariant
}

export const RestrictionIcon: FC<RestrictionIconProps> = props => {
    const { iconVariant, type } = props
    return (
        <Icon
            iconSize={BlockSize.Std}
            svg={restrictionTypeIcons[type]}
            iconVariant={iconVariant!}
        />
    )
}

const restrictionTypeIcons: Record<RestrictionResourceType, Svg> = {
    [RestrictionResourceType.Gate]: SvgIcon.Gate,
    [RestrictionResourceType.BusGate]: SvgIcon.Gate,
    [RestrictionResourceType.Stand]: SvgIcon.AircraftStands,
}
