import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import { AosAdUser, AosContact, AosUser } from 'aos-services/src/services/users/types/AosUser'
import { userManagementService } from 'aos-services/src/services/users/userManagementService'
import { userProfileService } from 'aos-services/src/services/users/userProfileService'
import {
    errorToUserResponseStatus,
    UserResponseStatus,
} from 'aos-services/src/services/users/userResponseConverter'
import { call, put, select } from 'redux-saga/effects'

import { State } from '../../../state'
import {
    ADD_CONTACT_PARENT,
    addAdUserActions,
    addContactActions,
    EDIT_CONTACT_PARENT,
    editAdUserActions,
    editContactActions,
} from '../../actions'
import { reloadUserList, searchUserByEmail } from '../../common/sagas'
import {
    SWITCH_TO_EDIT_AD_USER,
    SWITCH_TO_EDIT_CONTACT,
    SwitchToEditAdUserAction,
    SwitchToEditContactAction,
} from '../actions'
import {
    CREATE_CONTACT,
    CreateContactAction,
    SWITCH_TO_AD_USER,
    SwitchToAdUserAction,
    UPDATE_CONTACT,
    UpdateContactAction,
} from './actions'

function* handleCreateContactResponseStatus(userStatus: UserResponseStatus) {
    if (userStatus) {
        if (userStatus[0] !== undefined) {
            yield put(addContactActions.setConflictUserIdAction(userStatus[0]!))
        }
        yield put(addContactActions.setUserStatusAction(userStatus[1]))
    }
}

function* handleUpdateContactResponseStatus(userStatus: UserResponseStatus) {
    if (userStatus) {
        if (userStatus[0] !== undefined) {
            yield put(editContactActions.setConflictUserIdAction(userStatus[0]!))
        }
        yield put(editContactActions.setUserStatusAction(userStatus[1]))
    }
}

function* createContactSaga() {
    function* createContact(action: CreateContactAction) {
        try {
            const { formData, notificationSettings } = action.payload

            const user: AosUser = yield call(userManagementService.createContact, formData)

            if (notificationSettings) {
                yield call(
                    userProfileService.updateUserNotifications,
                    user.id,
                    notificationSettings,
                )
            }

            yield put(addContactActions.hideUserModalAction())
            yield reloadUserList()
        } catch (e) {
            const userResponseStatus = errorToUserResponseStatus(e as ResponseError)
            if (userResponseStatus) {
                yield handleCreateContactResponseStatus(userResponseStatus)
            } else {
                throw e
            }
        }
    }

    yield takeEveryChildAction<CreateContactAction>(
        ADD_CONTACT_PARENT,
        CREATE_CONTACT,
        createContact,
    )
}

function* updateContactSaga() {
    function* updateContact(action: UpdateContactAction) {
        try {
            const { formData, notificationSettings } = action.payload

            yield call(userManagementService.updateContact, formData)

            if (notificationSettings) {
                yield call(
                    userProfileService.updateUserNotifications,
                    formData.id!,
                    notificationSettings,
                )
            }

            yield put(editContactActions.hideUserModalAction())
            yield reloadUserList()
        } catch (e) {
            const userResponseStatus = errorToUserResponseStatus(e as ResponseError)
            if (userResponseStatus) {
                yield handleUpdateContactResponseStatus(userResponseStatus)
            } else {
                throw e
            }
        }
    }

    yield takeEveryChildAction<UpdateContactAction>(
        EDIT_CONTACT_PARENT,
        UPDATE_CONTACT,
        updateContact,
    )
}

function* openAddAdUser() {
    const user: ContactFormData = yield select(
        (state: State) => state.groupManager.addContactState.user,
    )
    const userAsAdUser = {
        email: user.email,
        alternativePhone: user.phone,
        selectedGroupsIds: [...user.selectedGroupsIds],
    }
    yield put(addAdUserActions.setUserDataAction(userAsAdUser))

    yield searchUserByEmail(user.email as string)

    yield put(addAdUserActions.showUserModalAction())
}

function* openEditAdUser(action: SwitchToEditAdUserAction) {
    const userId = action.payload
    const user: AosAdUser = yield call(userManagementService.getUserById, userId)

    yield put(editAdUserActions.showUserModalWithAdUserAction(user))
}

function* openEditContact(action: SwitchToEditContactAction) {
    const userId = action.payload
    const user: AosContact = yield call(userManagementService.getUserById, userId)

    yield put(editContactActions.showUserModalWithContactAction(user))
}

function* switchAddContactToAddAdUserSaga() {
    function* switchToAddAdUser() {
        yield openAddAdUser()
        yield put(addContactActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToAdUserAction>(
        ADD_CONTACT_PARENT,
        SWITCH_TO_AD_USER,
        switchToAddAdUser,
    )
}

function* switchAddContactToEditAdUserSaga() {
    function* switchToEditAdUser(action: SwitchToEditAdUserAction) {
        yield openEditAdUser(action)
        yield put(addContactActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditAdUserAction>(
        ADD_CONTACT_PARENT,
        SWITCH_TO_EDIT_AD_USER,
        switchToEditAdUser,
    )
}

function* switchAddContactToEditContactSaga() {
    function* switchToEditContact(action: SwitchToEditContactAction) {
        yield openEditContact(action)
        yield put(addContactActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditContactAction>(
        ADD_CONTACT_PARENT,
        SWITCH_TO_EDIT_CONTACT,
        switchToEditContact,
    )
}

function* switchEditContactToEditAdUserSaga() {
    function* switchToEditAdUser(action: SwitchToEditAdUserAction) {
        yield openEditAdUser(action)
        yield put(editContactActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditAdUserAction>(
        EDIT_CONTACT_PARENT,
        SWITCH_TO_EDIT_AD_USER,
        switchToEditAdUser,
    )
}

function* switchEditContactToEditContactSaga() {
    function* switchToEditContact(action: SwitchToEditContactAction) {
        yield openEditContact(action)
    }

    yield takeEveryChildAction<SwitchToEditContactAction>(
        EDIT_CONTACT_PARENT,
        SWITCH_TO_EDIT_CONTACT,
        switchToEditContact,
    )
}

export const addContactSagas = [
    createContactSaga,
    updateContactSaga,
    switchAddContactToAddAdUserSaga,
    switchAddContactToEditAdUserSaga,
    switchEditContactToEditAdUserSaga,
    switchAddContactToEditContactSaga,
    switchEditContactToEditContactSaga,
]
