import { firebaseApp } from 'firebaseApp'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'

export class FirebaseWidgetHealthsRepository {
    public widgetHealthsRef = () => firebaseApp.database().ref('health/')
    public widgetHealthsForAirportRef = (airport: AosAirport) =>
        firebaseApp.database().ref(`healthForAirports/${airport}`)
}

export const firebaseWidgetHealthsRepository = new FirebaseWidgetHealthsRepository()
