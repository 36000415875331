import { InspectionGroup } from 'aos-services/src/services/tasks/types/task/InspectionGroup'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'

export const inspectionGroupIcons: Record<InspectionGroup, Svg> = {
    [InspectionGroup.Terminal]: SvgIcon.TaskInspectionTerminal,
    [InspectionGroup.Runway]: SvgIcon.TaskInspectionRunway,
    [InspectionGroup.Taxiway]: SvgIcon.TaskInspectionTaxiway,
    [InspectionGroup.FireTrucks]: SvgIcon.TaskInspectionFireTruck,
    [InspectionGroup.Rope]: SvgIcon.TaskInspectionRope,
    [InspectionGroup.Contractor]: SvgIcon.TaskInspectionContractor,
    [InspectionGroup.Other]: SvgIcon.TaskInspectionOther,
    [InspectionGroup.Fod]: SvgIcon.TaskInspectionFod,
    [InspectionGroup.OtherActors]: SvgIcon.TaskInspectionOtherActors,
    [InspectionGroup.OtherAreas]: SvgIcon.TaskInspectionOtherAreas,
    [InspectionGroup.AreaInspection]: SvgIcon.TaskInspectionAreaInspection,
}
