import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { flatMap } from 'lodash'

import { paxForecastRestService } from '../../../dataaccess/paxForecast/paxForecastRestService'
import {
    PaxForecastDto,
    PaxForecastPointDto,
} from '../../../dataaccess/paxForecast/types/PaxForecastDto'
import { PaxForecast } from './types/PaxForecast'
import { PaxForecastArrivalOrDeparture } from './types/PaxForecastArrivalOrDeparture'
import { PaxForecastDataSource } from './types/PaxForecastDataSource'
import { PaxLocalOrTransfer } from './types/PaxLocalOrTransfer'

export class PaxForecastService {
    public getPaxForecast = (): Promise<PaxForecast> =>
        paxForecastRestService.getPaxForecast().then(this.dtoToForecast)

    private dtoToForecast = (dto: PaxForecastDto): PaxForecast =>
        flatMap(Object.entries(dto), ([date, historicalData]) => {
            const parsedDate = dateTime(date)
            return [
                ...flatMap(
                    historicalData.current,
                    this.pointDtoToPoint(PaxForecastDataSource.Forecast, parsedDate),
                ),
                ...flatMap(
                    historicalData.lastYear,
                    this.pointDtoToPoint(PaxForecastDataSource.LastYear, parsedDate),
                ),
            ]
        })

    private pointDtoToPoint(dataSource: PaxForecastDataSource, time: DateTime) {
        return (pointDto: PaxForecastPointDto) => [
            {
                dataSource,
                localOrTransfer: PaxLocalOrTransfer.Local,
                pax: pointDto.localPax,
                terminal: pointDto.terminal,
                arrivalOrDeparture: pointDto.type as PaxForecastArrivalOrDeparture,
                time,
                flightsCount: pointDto.flightsCount,
            },
            {
                dataSource,
                localOrTransfer: PaxLocalOrTransfer.Transfer,
                pax: pointDto.transferPax,
                terminal: pointDto.terminal,
                arrivalOrDeparture: pointDto.type as PaxForecastArrivalOrDeparture,
                time,
                flightsCount: pointDto.flightsCount,
            },
        ]
    }
}

export const paxForecastService = new PaxForecastService()
