import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Runway } from 'aos-services/src/services/runways/types/RunwayMode'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { RunwayConditionalMode } from './RunwayConditionalMode'

interface RunwaysModesProps extends ClassNameProps {
    type: 'arrivals' | 'departures'
    runways: Runway[]
    conditionalRunways?: Runway[]
}

export const RunwayModes: FC<RunwaysModesProps> = props => {
    const conditionalRunways = props.conditionalRunways || []
    const conditionalRunwayComma =
        props.runways.length > 0 && conditionalRunways.length > 0 ? ',\u00A0' : ''
    return (
        <Box className={cxp(props, 'container container--horizontal')}>
            <Icon
                marginRight={8}
                iconSize={BlockSize.Large}
                svg={props.type === 'arrivals' ? SvgIcon.Arrivals : SvgIcon.Departures}
            />
            <span className='text__white-title'>
                {props.runways.join(', ') + conditionalRunwayComma}
            </span>
            {conditionalRunways.map(r => (
                <RunwayConditionalMode key={r} runway={r} />
            ))}
        </Box>
    )
}
