import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { TrainTimeTableRow } from './TrainTimeTableRow'

export class TrainTimeTableHeader extends PureComponent {
    public render() {
        return (
            <Box paddingBottom={8}>
                <TrainTimeTableRow>
                    <Text color={Color.White} size={12}>
                        {translate('train-details.timetable.station')}
                    </Text>
                    <Text color={Color.White} size={12}>
                        {translate('train-details.timetable.adtime')}
                    </Text>
                    <Text color={Color.White} size={12}>
                        {translate('train-details.timetable.track')}
                    </Text>
                </TrainTimeTableRow>
            </Box>
        )
    }
}
