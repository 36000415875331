import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import React, { FC } from 'react'

import { SidebarPlaceholder } from './SidebarPlaceholder'

export const DefaultSidebar: FC = () => (
    <Sidebar title={translate('flight-information.timeline.default-sidebar.title')}>
        <SidebarPlaceholder />
    </Sidebar>
)
