import { UploadedAttachment } from '../../services/attachments/types/UploadedAttachment'

export interface AttachmentsState {
    isUploading: boolean
    attachments: UploadedAttachment[]
}

export const replaceAttachment = (state: AttachmentsState, oldUrl: string, newUrl: string) => {
    const attachments = state.attachments.map(attachment =>
        attachment.link === oldUrl ? { link: newUrl } : attachment,
    )

    return {
        ...state,
        attachments,
    }
}

export const initialAttachmentsState: AttachmentsState = {
    isUploading: false,
    attachments: [],
}
