import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import { BimLayer } from 'aos-ui-map/src/components/map/bim/BimLayer'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../core/state'
import { FloorButtons } from './FloorButtons'
import { TerminalLayersVisibilityCategory } from './TerminalLayersVisibilityCategory'
import { WorldLayersVisibilityCategory } from './WorldLayersVisibilityCategory'

export const LayersVisibilitySidebarClass: FC<
    LayersVisibilitySidebarProps & LayersVisibilitySidebarDispatchProps
> = ({
    bimMapLayers,
    isTooltip,
    userLayersVisibility,
    setBimLayersVisibility,
    setFloorNumber,
    mapVariant,
}) => {
    return (
        <SidebarBox column bg={Color.White}>
            {mapVariant === MapVariant.Terminal && (
                <FloorButtons
                    selectedFloor={userLayersVisibility.floor}
                    setFloorNumber={setFloorNumber}
                />
            )}
            <Box flex={1}>
                {isTooltip ? (
                    <DarkScrollbar autoHeight autoHeightMax={500}>
                        <Box paddingHorizontal={24} fullHeight>
                            {mapVariant === MapVariant.Terminal ? (
                                <TerminalLayersVisibilityCategory
                                    bimMapLayers={bimMapLayers}
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            ) : (
                                <WorldLayersVisibilityCategory
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            )}
                        </Box>
                    </DarkScrollbar>
                ) : (
                    <DarkScrollbar>
                        <Box paddingHorizontal={24} fullHeight>
                            {mapVariant === MapVariant.Terminal ? (
                                <TerminalLayersVisibilityCategory
                                    bimMapLayers={bimMapLayers}
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            ) : (
                                <WorldLayersVisibilityCategory
                                    bimMapLayersVisibility={userLayersVisibility.list}
                                    setBimLayersVisibility={setBimLayersVisibility}
                                />
                            )}
                        </Box>
                    </DarkScrollbar>
                )}
            </Box>
        </SidebarBox>
    )
}

const SidebarBox = styled(Box)`
    width: 360px;
    flex-shrink: 0;
`

interface LayersVisibilitySidebarProps {
    mapVariant: MapVariant
    bimMapLayers: BimLayer[]
    isTooltip?: boolean
    userLayersVisibility: UserLayerVisibilityState
}

interface LayersVisibilitySidebarDispatchProps {
    setBimLayersVisibility(v: string[]): void
    setFloorNumber(v: number): void
}

export const LayersVisibilitySidebar = connect<
    LayersVisibilitySidebarProps,
    LayersVisibilitySidebarDispatchProps,
    {
        isTooltip?: boolean
        bimMapLayers: BimLayer[]
        userLayersVisibility: UserLayerVisibilityState
    }
>((state: State, ownProps) => ({
    mapVariant: state.airportMap.variant,
    userLayersVisibility: ownProps.userLayersVisibility,
    isTooltip: ownProps.isTooltip,
    bimMapLayers: ownProps.bimMapLayers,
}))(LayersVisibilitySidebarClass)
