import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'

import { TaskRecurrenceType } from '../task/TaskRecurrenceType'

export interface TaskRecurrenceHourlyPayload {
    type: TaskRecurrenceType.Hourly
    duration: number
    every: number
}

export const defaultTaskRecurrenceHourly: TaskRecurrenceHourlyPayload = {
    type: TaskRecurrenceType.Hourly,
    duration: 60,
    every: 4,
}

export interface TaskRecurrenceHourlyValidation {
    duration: boolean
    every: boolean
}

export const validateTaskRecurrenceHourly = (
    r: TaskRecurrenceHourlyPayload,
): TaskRecurrenceHourlyValidation => ({
    duration: isNotEmpty(r.duration) && r.duration > 0,
    every: isNotEmpty(r.every) && r.every > 0,
})
