import { SimpleFormModalActions } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import {
    SingleInputActionCreators,
    singleInputActionCreators,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { AosChecklistTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import {
    AosUnitTaskItem,
    AosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { ChangeSeqVectorPayload } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'

import { EventChecklistManagerActions } from './event/actions'
import { TaskChecklistManagerActions } from './task/actions'
import { UnitTaskManagerActions } from './unit/actions'

export const APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES =
    'CHECKLIST_MANAGER/APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES'
export type ApplyChecklistTemplateSeqChangesAction = Action<
    typeof APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES
>
export const applyChecklistTemplateSeqChangesAction =
    emptyActionCreator<ApplyChecklistTemplateSeqChangesAction>(APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES)

export const CHANGE_ITEM_SEQ = 'CHECKLIST_MANAGER/CHANGE_ITEM_SEQ'
export type ChangeItemSeqAction = PayloadAction<typeof CHANGE_ITEM_SEQ, ChangeSeqVectorPayload>
export const changeItemSeqAction = payloadActionCreator<
    ChangeItemSeqAction,
    ChangeSeqVectorPayload
>(CHANGE_ITEM_SEQ)

export const APPLY_ITEM_SEQ_CHANGES = 'CHECKLIST_MANAGER/APPLY_ITEM_SEQ_CHANGES'
export type ApplyItemSeqChangesAction = PayloadAction<typeof APPLY_ITEM_SEQ_CHANGES, number>
export const applyItemSeqChangesAction = payloadActionCreator<ApplyItemSeqChangesAction, number>(
    APPLY_ITEM_SEQ_CHANGES,
)

export const EDIT_TASK_OR_DESCRIPTION_PARENT = 'CHECKLIST_MANAGER/EDIT_TASK_OR_DESCRIPTION_PARENT'
export type EditTaskOrDescriptionAction = PayloadAction<
    typeof EDIT_TASK_OR_DESCRIPTION_PARENT,
    SimpleFormModalActions
>
export const editTaskOrDescriptionParentAction = payloadActionCreator<
    EditTaskOrDescriptionAction,
    SimpleFormModalActions
>(EDIT_TASK_OR_DESCRIPTION_PARENT)
export const editTaskOrDescriptionActions: SingleInputActionCreators = parentActionWrapper(
    editTaskOrDescriptionParentAction,
    singleInputActionCreators,
)

export const EDIT_MESSAGE_TEMPLATE_PARENT = 'CHECKLIST_MANAGER/EDIT_MESSAGE_TEMPLATE_PARENT'
export type EditMessageTemplateParentAction = PayloadAction<
    typeof EDIT_MESSAGE_TEMPLATE_PARENT,
    SimpleFormModalActions
>
export const editMessageTemplateParentAction = payloadActionCreator<
    EditMessageTemplateParentAction,
    SimpleFormModalActions
>(EDIT_MESSAGE_TEMPLATE_PARENT)
export const editMessageTemplateActions: SingleInputActionCreators = parentActionWrapper(
    editMessageTemplateParentAction,
    singleInputActionCreators,
)

export const DELETE_ITEM = 'CHECKLIST_MANAGER/DELETE_ITEM'
export type DeleteItemAction = PayloadAction<
    typeof DELETE_ITEM,
    [AosChecklistTemplate, AosChecklistItem]
>
export const deleteItemAction = payloadActionCreator<
    DeleteItemAction,
    [
        AosChecklistTemplate | AosUnitTaskTemplate | AosTaskChecklistTemplate,
        AosChecklistItem | AosUnitTaskItem,
    ]
>(DELETE_ITEM)

export const CREATE_TASK_OR_DESCRIPTION = 'CHECKLIST_MANAGER/CREATE_TASK_OR_DESCRIPTION'
export type CreateTaskOrDescriptionParentAction = PayloadAction<
    typeof CREATE_TASK_OR_DESCRIPTION,
    SimpleFormModalActions
>
export const createTaskOrDescriptionParentAction = payloadActionCreator<
    CreateTaskOrDescriptionParentAction,
    SimpleFormModalActions
>(CREATE_TASK_OR_DESCRIPTION)
export const createTaskOrDescriptionActions: SingleInputActionCreators = parentActionWrapper(
    createTaskOrDescriptionParentAction,
    singleInputActionCreators,
)

export const EXPORT_ALL_CHECKLIST_TEMPLATES = 'CHECKLIST_MANAGER/EXPORT_ALL_CHECKLIST_TEMPLATES'
export type ExportAllChecklistTemplatesAction = Action<typeof EXPORT_ALL_CHECKLIST_TEMPLATES>
export const exportAllChecklistTemplatesAction =
    emptyActionCreator<ExportAllChecklistTemplatesAction>(EXPORT_ALL_CHECKLIST_TEMPLATES)

export const EXPORT_SINGLE_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/EXPORT_SINGLE_CHECKLIST_TEMPLATE'
export type ExportSingleChecklistTemplateAction = PayloadAction<
    typeof EXPORT_SINGLE_CHECKLIST_TEMPLATE,
    number
>
export const exportSingleChecklistTemplateAction = payloadActionCreator<
    ExportSingleChecklistTemplateAction,
    number
>(EXPORT_SINGLE_CHECKLIST_TEMPLATE)

export const REVIEWED_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/REVIEWED_CHECKLIST_TEMPLATE'
export type ReviewedChecklistTemplateAction = PayloadAction<
    typeof REVIEWED_CHECKLIST_TEMPLATE,
    number
>
export const reviewedChecklistTemplateAction = payloadActionCreator<
    ReviewedChecklistTemplateAction,
    number
>(REVIEWED_CHECKLIST_TEMPLATE)

export const SET_CHECKLIST_FILTERING = 'CHECKLIST_MANAGER/SET_CHECKLIST_FILTERING'
export type SetChecklistFilteringAction = PayloadAction<
    typeof SET_CHECKLIST_FILTERING,
    AosChecklistFilter
>
export const setChecklistFilteringAction = payloadActionCreator<
    SetChecklistFilteringAction,
    AosChecklistFilter
>(SET_CHECKLIST_FILTERING)

export type ChecklistManagerAction =
    | ChangeItemSeqAction
    | EditTaskOrDescriptionAction
    | CreateTaskOrDescriptionParentAction
    | EditMessageTemplateParentAction
    | UnitTaskManagerActions
    | TaskChecklistManagerActions
    | EventChecklistManagerActions
    | DeleteItemAction
    | ApplyItemSeqChangesAction
    | ApplyChecklistTemplateSeqChangesAction
    | SetChecklistFilteringAction
