import { DateTime } from 'aos-helpers/src/helpers/Time'

import { InvitationRole, InvitationStatus } from './InvitationStatus'

export interface PrivateChannelParticipant {
    firebaseUid: string
    displayName: string
    invitationStatus: InvitationStatus
    invitationRole: InvitationRole
    arrivalTime?: DateTime
}

export type SplittedParticipants = [
    PrivateChannelParticipant | undefined,
    PrivateChannelParticipant[],
]

const countingAsAccepted = [
    InvitationRole.Command,
    InvitationRole.Observe,
    InvitationRole.Participate,
]

const countingAsResponded = [
    InvitationRole.Command,
    InvitationRole.Observe,
    InvitationRole.Participate,
    InvitationRole.Left,
]

const atAirportComingRemoteSkip = [
    InvitationStatus.AtAirport,
    InvitationStatus.Coming,
    InvitationStatus.Remote,
    InvitationStatus.Skip,
]

export function isInvitationResponded(t: PrivateChannelParticipant) {
    return countingAsResponded.includes(t.invitationRole)
}

export function countRespondedInvitations(items: SplittedParticipants): [number, number] {
    const allItems: PrivateChannelParticipant[] = [...items[1], items[0]].filter(
        t => t,
    ) as PrivateChannelParticipant[]
    return [allItems.filter(isInvitationResponded).length, allItems.length]
}

export function isInvitationAccepted(t: PrivateChannelParticipant) {
    return countingAsAccepted.includes(t.invitationRole)
}

export function isStatusSelected(t: PrivateChannelParticipant) {
    return (
        atAirportComingRemoteSkip.includes(t.invitationStatus) ||
        t.invitationRole === InvitationRole.Observe
    )
}
