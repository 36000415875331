import {
    calculateDomain,
    calculateDomainForValues,
    calculateTimeDomainFromTo,
    Domain,
} from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime, startOfHour } from 'aos-helpers/src/helpers/Time'
import { createSelector } from 'reselect'

import { baggageDeliveryRanges } from '../../../../../services/airportStatus/baggage/baggageDeliveryConstants'
import {
    BaggageDeliveryHourlyStat,
    BaggageDeliveryStat,
} from '../../../../../services/airportStatus/baggage/types/BaggageDeliveryStat'
import { BaggageStatSection } from '../../../../../services/airportStatus/baggage/types/BaggageStatSection'
import { filterToTimeRange } from '../../../../../services/airportStatus/base/types/TimeRangeFilter'
import { flightFilterService } from '../../../../../services/flightInformation/FlightFilterService'
import { Flight } from '../../../../../services/flightInformation/types/Flight'
import {
    BaggageDeliveryTimeRange,
    BaseBaggageDeliveryFilters,
    BeltAreaFilter,
    beltAreaFilterValues,
} from '../../../../../services/statusDashboard/types/filters/BaggageDeliveryFilters'
import { FilterOptionAll } from '../../../../../services/statusDashboard/types/filters/common'
import { currentTimeSelector } from '../../../../common/selectors'
import { flightsLoadedSelector } from '../common'
import {
    allFlightsSelector,
    baggageDeliveryFiltersOwnPropsSelector,
    filterFlights,
    uniqueBeltAreaValues,
} from './common'
import { hourlyStats } from './fullChart'
import { statsForPeriod } from './smallChart'

export interface BaggageDeliverySelectorState {
    stats: BaggageDeliveryStat
    beltAreaValues: BeltAreaFilter[]
    noDataWarning: boolean
    flights: Flight[]
    chartData: BaggageDeliveryHourlyStat[]
    now: DateTime
    filters: BaseBaggageDeliveryFilters
    xDomain: Domain<DateTime>
    lineDomain: Domain<number>
    barDomain: Domain<number>
}

export const statCalculator = (
    allFlights: Flight[],
    filters: BaseBaggageDeliveryFilters,
    now: DateTime,
) => {
    const { handlingAgent, beltArea, timeRange } = filters
    const period = filterToTimeRange(timeRange, now)
    const prefilteredFlights = filterFlights(allFlights, handlingAgent, beltArea, now)
    const stats = statsForPeriod(prefilteredFlights, period, now)
    return {
        period,
        prefilteredFlights,
        stats,
    }
}

export const baggageDeliveryMerger = (
    allFlights: Flight[],
    filters: BaseBaggageDeliveryFilters,
    now: DateTime,
    dataLoaded: boolean,
): BaggageDeliverySelectorState => {
    const { stats, prefilteredFlights, period } = statCalculator(allFlights, filters, now)
    const xDomain = calculateTimeDomainFromTo(
        startOfHour(now).subtract(16, 'hours'),
        startOfHour(now),
    )
    const chartDate = hourlyStats(prefilteredFlights, now)
    const lineDomain = calculateDomain(chartDate, v => v.meanDeliveryTime.asMinutes(), {
        count: 5,
        fixed: true,
    })
    const barDomain = calculateDomainForValues(0, 100, { count: 5, fixed: true })

    return {
        now,
        stats,
        beltAreaValues: beltAreaFilterValues(uniqueBeltAreaValues(allFlights, now)),
        noDataWarning: !dataLoaded,
        chartData: hourlyStats(prefilteredFlights, now),
        flights: flightFilterService
            .getFlightsFromPeriod(prefilteredFlights, period)
            .filter(
                f =>
                    f.bag.deliveryTimeInMinutes(now) >
                        baggageDeliveryRanges[BaggageStatSection.Normal].to && f.bag.hasBag,
            ),
        filters,
        xDomain,
        lineDomain,
        barDomain,
    }
}

export const baggageDeliverySelector = createSelector(
    allFlightsSelector,
    baggageDeliveryFiltersOwnPropsSelector,
    currentTimeSelector,
    flightsLoadedSelector,
    baggageDeliveryMerger,
)

export const baggageDeliveryTileSelector = createSelector(
    allFlightsSelector,
    () => ({
        handlingAgent: FilterOptionAll.All,
        beltArea: FilterOptionAll.All,
        timeRange: BaggageDeliveryTimeRange.Today,
    }),
    currentTimeSelector,
    flightsLoadedSelector,
    baggageDeliveryMerger,
)
