import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FlightPoint } from 'aos-services/src/services/airportStatus/flights/types/FlightsInfo'
import React, { FC } from 'react'

interface RegularityTooltipProps {
    point: FlightPoint
}

export const RegularityTooltip: FC<RegularityTooltipProps> = props => {
    const { point } = props
    const getLabel = () => {
        if (point.flightsCount > 0) {
            return `${translate('dashboard.regularity.tooltip.cancelled-flights')} (${
                point.flightsCount
            }): ${point.flightNumbers}`
        }
        return translate('dashboard.regularity.tooltip.no-cancelled-flights')
    }

    return <p>{getLabel()}</p>
}
