import { ManyBarsSeries } from 'aos-components/src/components/chart/series/ManyBarsSeries'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastHoursChartPoint } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { ManyBarSeriesScales } from 'aos-ui-common/src/components/chart/series/manyBarScales'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface PaxForecastHoursDoubleBarSeriesProps {
    data: PaxForecastHoursChartPoint[]
    scales: ManyBarSeriesScales<DateTime, number>
}

export const PaxForecastHoursDoubleBarSeries: FC<PaxForecastHoursDoubleBarSeriesProps> = props => (
    <ManyBarsSeries
        data={props.data}
        series={[
            {
                fill: Color.PaleOrange,
                yAccessor: (data: PaxForecastHoursChartPoint) =>
                    data[PaxForecastArrivalOrDeparture.Arrivals],
                tooltip: translate('dashboard.pax-forecast.chart.hours.arrivals'),
            },
            {
                fill: Color.Violet,
                yAccessor: (data: PaxForecastHoursChartPoint) =>
                    data[PaxForecastArrivalOrDeparture.Departures],
                tooltip: translate('dashboard.pax-forecast.chart.hours.departures'),
            },
        ]}
        xAccessor={(d: PaxForecastHoursChartPoint) => d.time}
        scales={props.scales}
    />
)
