import { LayersDataState } from '../../services/layerData/types/LayersDataState'
import { ringRailService } from '../../services/ringRail/ringRailService'
import {
    LayersDataAction,
    LOAD_LAYERS_DATA_SUCCESS,
    UPDATE_TRAIN_LOCATIONS,
    UPDATE_TRAINS,
} from './actions'
import { initialLayersDataState } from './state'

export function layersDataReducer(
    state = initialLayersDataState,
    action: LayersDataAction,
): LayersDataState {
    const stations = state.ringRailTrainsInfo ? state.ringRailTrainsInfo.stations : {}
    const trains = state.ringRailTrainsInfo ? state.ringRailTrainsInfo.trains : []

    switch (action.type) {
        case LOAD_LAYERS_DATA_SUCCESS:
            return { ...state, ...action.payload }

        case UPDATE_TRAINS:
            return {
                ...state,
                ringRailTrainsInfo: {
                    ...state.ringRailTrainsInfo!,
                    trains: ringRailService.updateTrains(trains, action.payload, stations),
                },
            }

        case UPDATE_TRAIN_LOCATIONS:
            return {
                ...state,
                ringRailTrainsInfo: {
                    ...state.ringRailTrainsInfo!,
                    trains: ringRailService.updateTrainLocations(trains, action.payload),
                },
            }

        default:
            return state
    }
}
