import {
    AosUnitTaskItem,
    AosUnitTaskTemplate,
} from '../../services/checklists/types/AosUnitTaskTemplate'
import { UnitTaskChangeObject } from '../../services/events/types/AosEventChecklist'
import { BaseRestService } from '../base/BaseRestService'
import { AosTaskInSeq } from './types/AosTaskInSeq'

class TaskUnitRestService extends BaseRestService {
    private apiUrl = (suffix?: string) => `/unit-tasks/templates${suffix ? suffix : ''}`
    getAll() {
        return this.getAndTransform<AosUnitTaskTemplate[]>(
            this.apiUrl(),
            BaseRestService.jsonTransformer,
        )
    }

    getOne(id: number) {
        return this.getAndTransform<AosUnitTaskTemplate>(
            this.apiUrl(`/${id}`),
            BaseRestService.jsonTransformer,
        )
    }

    reorderItems(checklistTemplateId: number, tasksOrder: AosTaskInSeq[]): Promise<void> {
        return this.patchJsonAndTransform<void>(
            this.apiUrl(`/${checklistTemplateId}/items`),
            tasksOrder,
            BaseRestService.jsonTransformer,
        )
    }

    create(checklistTemplate: Omit<AosUnitTaskItem, 'id' | 'sequenceNumber'>): Promise<void> {
        return this.postJsonAndTransform<void>(
            this.apiUrl('/items'),
            checklistTemplate,
            BaseRestService.jsonTransformer,
        )
    }

    update(
        templateId: number,
        { id, ...checklistTemplateData }: AosUnitTaskItem,
    ): Promise<Response> {
        return this.patchJson(this.apiUrl(`/${templateId}/items/${id}`), checklistTemplateData)
    }

    delete(templateId: number, itemId: number): Promise<Response> {
        return this.deleteReq(this.apiUrl(`/${templateId}/items/${itemId}`))
    }

    exportSingle(id: number): Promise<[Blob, string]> {
        return this.getAndTransform(
            `/unit-task/templates/export/template/${id}`, //TODO: fix this path
            BaseRestService.fileDownloadTransformer,
        )
    }

    exportAll(): Promise<[Blob, string]> {
        return this.getAndTransform(
            '/unit-task/templates/export',
            BaseRestService.fileDownloadTransformer,
        )
    }

    updateUnitTask(payload: UnitTaskChangeObject) {
        return this.patchJson(
            `/events/${payload.eventId}/unittasks/${payload.unitTaskId}/items/${payload.itemId}`,
            { status: payload.status, additionalNote: payload.additionalNote },
        )
    }
}

export const taskUnitRestService = new TaskUnitRestService()
