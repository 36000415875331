import { Box, BoxProps, NumberValues } from 'aos-ui/src/components/base/Box'
import React, { Children, FCWithChildren, PropsWithChildren } from 'react'

interface SeparatedContainerProps extends BoxProps {
    withFirstElement?: boolean
    spacing: NumberValues
    orientation?: 'horizontal' | 'vertical'
}

export const SeparatedContainer: FCWithChildren<PropsWithChildren<SeparatedContainerProps>> = ({
    children,
    withFirstElement,
    spacing,
    orientation = 'vertical',
    ...boxProps
}) => {
    const value = (index: number) => (index > 0 || withFirstElement === true ? spacing : 0)
    return (
        <Box row={orientation === 'horizontal'} {...boxProps}>
            {Children.toArray(children).map((item, index) => (
                <Box
                    key={index}
                    paddingTop={orientation === 'vertical' ? value(index) : 0}
                    paddingLeft={orientation === 'horizontal' ? value(index) : 0}
                >
                    {item}
                </Box>
            ))}
        </Box>
    )
}
