export const debounceAndAggregateCalls = <T>(
    aggregateInterval: number,
): f.Action2<f.Action1<T[]>, T> => {
    let aggregatedItems: T[] = []
    let currentHandler: number | undefined
    const commit = (emitter: f.Action1<T[]>) => () => {
        emitter(aggregatedItems)
        aggregatedItems = []
        currentHandler = undefined
    }

    return (emit: f.Action1<T[]>, item: T) => {
        aggregatedItems.push(item)
        if (currentHandler) {
            clearTimeout(currentHandler)
        }
        currentHandler = setTimeout(commit(emit), aggregateInterval) as any
    }
}

export const aggregateCalls = <T>(aggregateInterval: number): f.Action2<f.Action1<T[]>, T> => {
    let aggregatedItems: T[] = []
    const commit = (emitter: f.Action1<T[]>) => () => {
        emitter(aggregatedItems)
        aggregatedItems = []
    }

    return (emit: f.Action1<T[]>, item: T) => {
        if (aggregatedItems.length === 0) {
            setTimeout(commit(emit), aggregateInterval)
        }
        aggregatedItems.push(item)
    }
}

export const throttleCalls = <T>(aggregateInterval: number): f.Action2<f.Action1<T>, T> => {
    let aggregatedItem: T | null = null
    let currentHandler: number | undefined
    const commit = (emitter: f.Action1<T>) => () => {
        if (aggregatedItem) {
            emitter(aggregatedItem)
        }
        aggregatedItem = null
        currentHandler = undefined
    }

    return (emit: f.Action1<T>, item: T) => {
        aggregatedItem = item
        if (!currentHandler) {
            currentHandler = setTimeout(commit(emit), aggregateInterval) as any
        }
    }
}
