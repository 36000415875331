import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    UnitAirportContactDTO,
    UnitAirportContactSchema,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalFooter } from 'aos-ui/src/components/modal/Modal/ModalFooter'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import {
    closeUnitAirportContactModal,
    createUnitAirportContact,
    editUnitAirportContact,
} from '../../core/contactList/actions'
import { State } from '../../core/state'

export const ContactUnitModal = () => {
    const { contactModalOpen, selectedAirportUnit, units } = useSelector(
        (state: State) => state.contactList,
    )
    const formSchema = useMemo(
        () =>
            UnitAirportContactSchema(
                units.filter(q => q.id !== selectedAirportUnit?.id).map(unit => unit.name),
            ),
        [units, selectedAirportUnit],
    )

    const dispatch = useDispatch()
    const form = useForm<UnitAirportContactDTO>({
        resolver: zodResolver(formSchema),
        defaultValues: selectedAirportUnit || {},
        mode: 'onSubmit',
    })
    const onSubmit = (data: UnitAirportContactDTO) => {
        if (selectedAirportUnit) {
            dispatch(editUnitAirportContact(data))
        } else {
            dispatch(createUnitAirportContact(data))
        }
    }

    const title = selectedAirportUnit
        ? translate('contact-list.edit-contact-unit')
        : translate('contact-list.create-contact-unit')

    useEffect(() => {
        form.reset(selectedAirportUnit || {})
    }, [selectedAirportUnit, contactModalOpen])

    return (
        <Modal
            title={title}
            modalKind={ModalKind.Small}
            isOpen={contactModalOpen}
            closeAction={() => dispatch(closeUnitAirportContactModal())}
        >
            <Box paddingVertical={20} paddingHorizontal={30}>
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            isRequired
                            errorMessage={fieldState.error?.message}
                            keyPrefix='contact-list.form.unit-name'
                            isError={fieldState.invalid}
                            onChangeText={field.onChange}
                            {...field}
                        />
                    )}
                    name='name'
                    control={form.control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <LabeledInput
                            keyPrefix='contact-list.form.unit-description'
                            isError={fieldState.invalid}
                            errorMessage={fieldState.error?.message}
                            onChangeText={field.onChange}
                            {...field}
                        />
                    )}
                    name='description'
                    control={form.control}
                />
            </Box>
            <ModalFooter topSeparator>
                <Box flex={1} row columnGap={8}>
                    <ValidationInfo
                        pristine={!form.formState.isDirty || !form.formState.isSubmitted}
                        valid={form.formState.isValid}
                    />
                    <FormButton
                        label={translate('contact-list.form.save')}
                        onClick={form.handleSubmit(onSubmit)}
                    />
                </Box>
            </ModalFooter>
        </Modal>
    )
}
