import { Box } from 'aos-ui/src/components/base/Box'
import React, { FCWithChildren } from 'react'

import { Spinner } from '../../ui/Spinner'
import { AttachmentContainerItem } from './AttachmentContainerItem'
import { PreviewableProps } from './PreviewableProps'

export const AttachmentPlaceholder: FCWithChildren<PreviewableProps> = props => (
    <AttachmentContainerItem {...props}>
        <Box coverAll>
            <Spinner onLight />
        </Box>
    </AttachmentContainerItem>
)
