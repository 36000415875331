import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { restrictionsTileDataSelector } from 'aos-services/src/core/statusDashboardData/restrictions/selectors'
import { RestrictionsStats } from 'aos-services/src/services/restrictions/types/RestrictionsStats'
import { Box } from 'aos-ui/src/components/base/Box'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { RestrictionsItemState } from '../../../../services/statusDashboard/types/itemStates/RestrictionsItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { RestrictionsItem } from './partials/RestrictionsItem'
import { RestrictionsHeader } from './RestrictionsHeader'
import { RestrictionsStatsItem } from './RestrictionsStatsItem'

interface RestrictionsTileStateProps {
    stats: RestrictionsStats[]
    carouselTrigger: DateTime
}

interface RestrictionsTileProps
    extends StatusDashboardItemCommonProps<RestrictionsItemState>,
        RestrictionsTileStateProps {}

export const RestrictionsTileComponent: FC<RestrictionsTileProps> = props => (
    <RestrictionsItem
        itemProps={pickDashboardItemProps(props)}
        link={Link.FlightsStandsGates}
        syncId='overview'
    >
        <ItemContainer horizontalSpacing horizontal>
            <Box flex={1}>
                <RestrictionsHeader airport={translate('dashboard.restrictions.helsinki')} />
                <Carousel changeTrigger={props.carouselTrigger}>
                    {props.stats.map(areaStats => (
                        <RestrictionsStatsItem key={areaStats.areaType} stats={areaStats} />
                    ))}
                </Carousel>
            </Box>
        </ItemContainer>
    </RestrictionsItem>
)

export const RestrictionsTile = connect<RestrictionsTileStateProps>(
    createStructuredSelector({
        stats: restrictionsTileDataSelector,
        carouselTrigger: carouselTriggerSelector,
    }),
)(RestrictionsTileComponent)
