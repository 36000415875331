import { newsFeedRestService } from '../../../dataaccess/newsFeed/newsFeedRestService'
import { byPubDateComparator, Feed } from '../../newsFeed/types/Feed'
import { FeedImpl } from '../../newsFeed/types/FeedImpl'

export class NewsFeedService {
    public getNewsFeed = (): Promise<Feed[]> =>
        newsFeedRestService
            .getNewsFeed()
            .then(v => v.map(e => new FeedImpl(e)).sort(byPubDateComparator))
}

export const newsFeedService = new NewsFeedService()
