import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import React, { PureComponent } from 'react'

import { SidebarPlaceholder } from '../SidebarPlaceholder'

export class DefaultSidebar extends PureComponent<DefaultSidebarProps> {
    public render() {
        return (
            <Sidebar
                title={translateEnum<MapVariant>(
                    MapVariant,
                    'map.default-sidebar',
                )(this.props.variant)}
            >
                <SidebarPlaceholder />
            </Sidebar>
        )
    }
}

interface DefaultSidebarProps {
    variant: MapVariant
}
