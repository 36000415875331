import { ActionModalMode } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import {
    AOS_USER_EXISTS_NOT_FOUND_IN_AD,
    USER_OR_GROUP_NOT_FOUND,
    USER_REMOVED,
} from 'aos-services/src/services/users/types/AosUserFindStatus'
import React, { PureComponent } from 'react'

import { UserError } from './UserError'
import { UserExists } from './UserExists'

export class ExistingAdUserStatus extends PureComponent<ExistingAdUserStatusProps> {
    public render() {
        const { user, hide } = this.props
        const { userStatus } = user

        switch (userStatus) {
            case AOS_USER_EXISTS_NOT_FOUND_IN_AD:
                return (
                    <UserExists
                        email={user.email!}
                        description={translate(
                            'manage-user.existing-ad-user.user-exists-gone-in-ldap',
                        )}
                        userActionLabel={translate(
                            'manage-user.ad-user.user-exists-gone-in-ldap.remove',
                        )}
                        userAction={this.props.removeAdUser}
                        mode={ActionModalMode.Important}
                    />
                )
            case USER_REMOVED:
                return (
                    <UserError
                        email={user.email!}
                        description={translate('manage-user.user-removed')}
                        hide={hide}
                    />
                )
            case USER_OR_GROUP_NOT_FOUND:
                return (
                    <UserError
                        email={user.email!}
                        description={translate('manage-user.group-not-found')}
                        hide={hide}
                    />
                )
            default:
                return <span>{translate(`manage-user.status.${userStatus!.toLowerCase()}`)}</span>
        }
    }
}

export interface ExistingAdUserStatusProps {
    user: AdUserFormData
    hide(): void
    removeAdUser?(): void
}
