import { FreezingPhenomenonType } from 'aos-services/src/services/airportStatus/weather/types/FreezingPhenomenon'

export enum FzraColors {
    Heavy = '#2DE6F2',
    Moderate = '#2596A5',
    Light = '#1F596B',
}

export enum FzdzColors {
    Heavy = '#F22D83',
    Moderate = '#9B2762',
    Light = '#5A224A',
}

export enum FzfgColors {
    Moderate = '#F29C2D',
}

export type FreezingPhenomenonColors = FzraColors | FzdzColors | FzfgColors

export type FreezingPhenomenonStatusType = 'Heavy' | 'Moderate' | 'Light'

export type FreezingPhenomenonColorListType = Array<
    [FreezingPhenomenonType, Array<[FreezingPhenomenonColors, FreezingPhenomenonStatusType]>]
>

export const freezingPhenomenonColorList: FreezingPhenomenonColorListType = [
    [
        FreezingPhenomenonType.Fzra,
        [
            [FzraColors.Heavy, 'Heavy'],
            [FzraColors.Moderate, 'Moderate'],
            [FzraColors.Light, 'Light'],
        ],
    ],
    [
        FreezingPhenomenonType.Fzdz,
        [
            [FzdzColors.Heavy, 'Heavy'],
            [FzdzColors.Moderate, 'Moderate'],
            [FzdzColors.Light, 'Light'],
        ],
    ],
    [FreezingPhenomenonType.Fzfg, [[FzfgColors.Moderate, 'Moderate']]],
]
