import { AnyEnum, EnumValue, getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { groupBy } from 'lodash'

import { StatusRangeType } from './StatusRangeType'

export interface StatusRange<T> {
    from: number
    to: number
    status: T
}

export type WidgetStatusRange = StatusRange<StatusRangeType>

const compareRanges = <T>(r1: StatusRange<T>, r2: StatusRange<T>) => {
    if (r1.to === r2.to) {
        return r1.from - r2.from
    }
    return r1.to - r2.to
}

export const getStatusRangeType = <T>(
    ranges: StatusRange<T>[],
    value: number,
    defaultStatus: T,
): T => {
    if (ranges.length === 0) {
        return defaultStatus
    }
    const sortedRanges = [...ranges].sort(compareRanges)

    const last = sortedRanges[sortedRanges.length - 1]
    if (last && value > last.to) {
        return last.status
    }
    const first = sortedRanges[0]
    if (first && value < first.from) {
        return first.status
    }

    const block = ranges.find(r => value > r.from && value <= r.to)
    if (block) {
        return block.status
    }
    return defaultStatus
}

export const groupByStatusRange = <E extends AnyEnum, U extends EnumValue, T>(
    enumEntity: E,
    defaultStatus: U,
    ranges: StatusRange<U>[],
    valueGetter: f.Func1<T, number>,
) => (values: T[]): Record<U, T[]> => ({
    ...getInitialValuesForEnumRecord(enumEntity, []),
    ...groupBy(values, v => getStatusRangeType(ranges, valueGetter(v), defaultStatus)),
})
