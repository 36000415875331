import { AnimalSpecie } from '../../tasks/types/task/AnimalsTask'
import { AnimalsOccurrence } from './AnimalsOccurrence'

export interface AnimalsReportData {
    data: AnimalsOccurrence[]
    types: AnimalSpecie[]
}

export const emptyAnimalsReportData: AnimalsReportData = {
    data: [],
    types: [],
}
