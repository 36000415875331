import {
    flightsTileSelector,
    FlightsVolumesSelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/flights'
import { FlightsGrouping } from 'aos-services/src/services/statusDashboard/types/filters/FlightsVolumesFilters'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { FlightsItem } from './partials/FlightsItem'
import { FlightStatsSection } from './partials/FlightStatsSection'

type FlightsTileOwnProps = StatusDashboardItemCommonProps

interface FlightsTileProps extends FlightsVolumesSelectorState, FlightsTileOwnProps {}

export const FlightsTileComponent: FC<FlightsTileProps> = props => (
    <FlightsItem
        noDataWarning={props.noDataWarning}
        itemProps={pickDashboardItemProps(props)}
        link={link}
        syncId='overview'
    >
        <TileContainer>
            <FlightStatsSection stats={props.stats} tab={FlightsGrouping.Total} />
        </TileContainer>
    </FlightsItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Operations })

export const FlightsTile = connect<FlightsVolumesSelectorState>(flightsTileSelector)(
    FlightsTileComponent,
)
