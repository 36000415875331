import { streamGroupArrayByEquality } from 'aos-helpers/src/helpers/Array'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { EnumValues } from 'enum-values'
import { last, mapValues, pick } from 'lodash'

import { enumRecord, isValidEnumEntry } from '../../../../../aos-helpers/src/helpers/Enum'
import { isPresent } from '../../../../../aos-helpers/src/helpers/Function'
import { airportStatusRestService } from '../../../dataaccess/airportStatus/airportStatusRestService'
import {
    FreezingPhenomenonPointDto,
    WeatherMetricDto,
    WeatherSeriesDto,
    WeatherStringPointDto,
    WeatherValuePointDto,
} from '../../../dataaccess/airportStatus/types/WeatherSeriesDto'
import { AosAirport } from '../../flightInformation/types/AosAirport'
import { NullableTimeValuePoint, TimeValuePoint } from '../base/types/TimePoint'
import { WeatherCategory, WeatherMetric, WeatherSeries } from './types/WeatherMetrics'
import { FreezingPhenomenonPoint, WeatherCategoryPoint } from './types/WeatherPoints'
import { WeatherOtherSeriesType, WeatherValueSeriesType } from './types/WeatherSeriesType'
import { WeatherState } from './types/WeatherState'

export class WeatherService {
    public getWeatherSeries = async (airport?: AosAirport): Promise<WeatherState> => {
        const promises =
            airport === AosAirport.HEL
                ? () =>
                      Promise.all([
                          airportStatusRestService
                              .getWeatherForecast(airport)
                              .then(this.weatherDtoToSeries),
                          airportStatusRestService
                              .getWeatherCurrent(airport)
                              .then(this.weatherDtoToSeriesMetric),
                          airportStatusRestService.getWeatherLatestInfo(airport),
                      ])
                : () =>
                      Promise.all([
                          airportStatusRestService
                              .getWeatherForecast(airport)
                              .then(this.weatherDtoToSeries),
                          airportStatusRestService
                              .getWeatherCurrent(airport)
                              .then(this.weatherDtoToSeriesMetric),
                      ])
        const result = await promises()
        return {
            forecasts: result[0],
            current: result[1],
            latestInfo: result[2],
            lastUpdate: dateTime(),
        }
    }

    private weatherDtoToSeriesMetric = (dto: WeatherMetricDto): WeatherMetric => {
        const valueSeriesDto: Record<WeatherValueSeriesType, WeatherValuePointDto | null> = pick(
            dto,
            EnumValues.getValues(WeatherValueSeriesType),
        )
        const valueMetrics = mapValues<
            Record<WeatherValueSeriesType, WeatherValuePointDto | null>,
            number | undefined
        >(valueSeriesDto, (point: WeatherValuePointDto | null) =>
            point !== null ? point.value : undefined,
        )
        return {
            ...valueMetrics,
            [WeatherOtherSeriesType.WeatherCategory]: this.mapWeatherCategory(
                dto[WeatherOtherSeriesType.WeatherCategory]?.value,
            ),
        }
    }

    private weatherDtoToSeries = (dto: WeatherSeriesDto): WeatherSeries => {
        const valueSeries = enumRecord<WeatherValueSeriesType, TimeValuePoint[]>(
            WeatherValueSeriesType,
            valueSeriesType => this.mapWeatherValueDtos(dto[valueSeriesType]),
        )

        return {
            ...valueSeries,
            [WeatherValueSeriesType.CumulonimbusCloudsBase]: this.mapCumulonimbusCloudsBaseDtos(
                dto[WeatherValueSeriesType.CumulonimbusCloudsBase],
            ),
            [WeatherOtherSeriesType.WeatherCategory]: this.mapWeatherCategoryDtos(
                dto[WeatherOtherSeriesType.WeatherCategory],
            ),
            [WeatherOtherSeriesType.FreezingWeatherPhenomenon]:
                this.mergeAndMapFreezingPhenomenonDtos(
                    dto[WeatherOtherSeriesType.FreezingWeatherPhenomenon],
                ),
            [WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon]:
                this.mergeAndMapFreezingPhenomenonDtos(
                    dto[WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon],
                ),
        }
    }

    private mergeAndMapFreezingPhenomenonDtos = (dtos: FreezingPhenomenonPointDto[]) =>
        streamGroupArrayByEquality(dtos, (a, b) => a.value.value === b.value.value).map(elements =>
            this.freezingWeatherPhenomenonPoint(elements[0], last(elements)),
        )

    private freezingWeatherPhenomenonPoint = (
        a: FreezingPhenomenonPointDto,
        b?: FreezingPhenomenonPointDto,
    ): FreezingPhenomenonPoint => ({
        from: dateTime(a.time),
        to: b ? dateTime(b.time).add(1, 'hour') : dateTime(a.time).add(1, 'hour'),
        value: a.value,
    })

    private mapWeatherValueDtos = (dtos: WeatherValuePointDto[]) =>
        dtos.map(t => ({
            time: dateTime(t.time),
            value: t.value,
        }))

    private mapCumulonimbusCloudsBaseDtos = (
        dtos: WeatherValuePointDto[],
    ): NullableTimeValuePoint[] =>
        dtos.map(t => ({
            time: dateTime(t.time),
            value: isPresent(t.value) ? Math.round(t.value) : null,
        }))

    private mapWeatherCategoryDtos = (dtos: WeatherStringPointDto[]): WeatherCategoryPoint[] =>
        dtos.map(t => ({
            time: dateTime(t.time),
            value: this.mapWeatherCategory(t.value),
        }))

    private mapWeatherCategory = (value: string | undefined): WeatherCategory =>
        value && isValidEnumEntry(WeatherCategory)<WeatherCategory>(value)
            ? value
            : WeatherCategory.Normal
}

export const weatherService = new WeatherService()
