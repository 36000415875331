import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum CdmMetricSubType {
    Future = 'future',
    Last30m = 'last30m',
    Last1h = 'last1h',
    Last3h = 'last3h',
    Last24h = 'last24h',
    Top5 = 'top5',
}

export const translateCdmMetricSubType = translateEnum<CdmMetricSubType>(
    CdmMetricSubType,
    'dashboard.cdm.select',
)
