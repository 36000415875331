import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { PaxEntryDto } from '../../../dataaccess/pax/types/PaxEntryDto'
import { PaxEntry } from './PaxEntry'
import { PointId } from './PointId'

export class PaxEntryImpl extends WrapperObject<PaxEntryDto> implements PaxEntry {
    public halfHourOffset: number

    constructor(public id: string, value: PaxEntryDto, baseTime: DateTime) {
        super(value)
        this.halfHourOffset = this.getHalfHourOffset(baseTime, value.time)
        this.value.waitingTime = Math.round(this.value.waitingTime)
    }

    get capacity() {
        return this.value.capacity
    }

    get waitingTime() {
        return this.value.waitingTime
    }

    get pointId() {
        return this.value.id as PointId
    }

    get lineCount() {
        return this.value.lineCount
    }

    get pax() {
        return this.value.pax
    }
    get time() {
        return dateTime(this.value.time)
    }

    private getHalfHourOffset(baseTime: DateTime, timestamp: number) {
        const newTime = dateTime(timestamp)
        return Math.floor(newTime.diff(baseTime, 'minutes') / 30)
    }
}
