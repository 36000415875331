import { LayerDataServiceEntry } from './types/LayerDataServiceEntry'
import { LayersDataState } from './types/LayersDataState'

interface LoadBimLayerEntry {
    name?: {
        [key: string]: string
    }
    loader(): Promise<LayersDataState>
}

export class LayerDataService {
    private loadLayerDict: Map<LayerDataServiceEntry, LoadBimLayerEntry>

    constructor() {
        this.loadLayerDict = new Map<LayerDataServiceEntry, LoadBimLayerEntry>([])
    }

    public loadLayers = (layers: LayerDataServiceEntry[]): Promise<LayersDataState> => {
        return Promise.all(
            layers.map(name =>
                this.loadLayerDict.has(name)
                    ? this.loadLayerDict.get(name)!.loader()
                    : Promise.resolve({}),
            ),
        ).then((items: Partial<LayersDataState>[]) =>
            items.reduce((accumulator, s) => ({ ...accumulator, ...s }), {}),
        )
    }
}

export const layerDataService = new LayerDataService()
