import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import _ from 'lodash'
import React, { PureComponent } from 'react'

import { WhiteModal } from '../WhiteModal/WhiteModal'
import { WhiteModalContent, WhiteModalContentVariant } from '../WhiteModal/WhiteModalContent'
import { Feedback } from './core/simpleForm/state'
import { FormFeedback } from './core/simpleForm/state'

export class SimpleFormFeedback extends PureComponent<SimpleFormFeedbackProps> {
    public render() {
        const { isOpen, closeAction, title } = this.props
        return (
            <WhiteModal isOpen={isOpen} closeAction={closeAction} title={title}>
                {this.content()}
            </WhiteModal>
        )
    }

    private content = () => {
        const { message } = this.props.feedback
        return (
            <WhiteModalContent
                title={this.props.title}
                variant={
                    this.isPositive() ? WhiteModalContentVariant.Blue : WhiteModalContentVariant.Red
                }
                svg={this.isPositive() ? SvgIcon.Success : SvgIcon.Error}
                buttons={this.getButtons()}
                description={message}
            />
        )
    }

    private getButtons = () =>
        this.isPositive()
            ? [
                  <FormButton
                      key='0'
                      label={_.capitalize(translate('ok'))}
                      onClick={this.props.closeAction}
                  />,
              ]
            : [
                  <FormButton
                      key='0'
                      label={_.capitalize(translate('back'))}
                      onClick={this.props.backAction}
                  />,
              ]

    private isPositive = () => this.props.feedback.status === FormFeedback.Positive
}

interface SimpleFormFeedbackProps {
    isOpen: boolean
    feedback: Feedback
    title: string
    closeAction(): void
    backAction(): void
}
