import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

import { EventInfoContent } from '../../eventEditor/section/EventInfoContent'

export class EventDetailsSidebar extends PureComponent<EventDetailsSidebarProps> {
    public render() {
        return (
            <Sidebar title={this.renderTitle()}>
                <EventInfoContent
                    event={this.props.event}
                    withVisibilityField={false}
                    selectLocation={this.selectLocation}
                />
            </Sidebar>
        )
    }

    private renderTitle = () => {
        const openEvent = () => this.props.openEventAction(this.props.event.id)
        return (
            <Box row>
                <Box flex={1}>{translate('map.sidebar.event-details')}</Box>
                <IconButton
                    svg={SvgIcon.OpenIn}
                    onClick={openEvent}
                    iconVariant={IconVariant.White}
                    iconSize={BlockSize.Tiny}
                />
            </Box>
        )
    }

    private selectLocation = () => {
        const { mapVariant, event } = this.props

        if (!!event.location) {
            this.props.setMapPositionAction([mapVariant, event.location])
        }
    }
}

interface EventDetailsSidebarProps {
    event: AosEvent
    mapVariant: MapVariant
    openEventAction(v: number): void
    setMapPositionAction(v: [MapVariant, LonLat]): void
}
