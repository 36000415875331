import React from 'react'

import { DropdownVariant } from '../form/dropdown/base/DropdownVariant'
import { Dropdown } from '../form/dropdown/Dropdown'
import { ResponsiveWrapper } from '../ui/ResponsiveWrapper'
import { RoundTabsList } from './RoundTabsList'
import { RoundTabsProps } from './RoundTabsProps'

export const RoundTabs = <T extends string | number>(props: RoundTabsProps<T>) => {
    const renderTabsAsDropdown = () => {
        const { value, items, formatter, tooltip, onChange } = props
        return (
            <Dropdown
                variant={DropdownVariant.BlackGrey}
                value={value}
                items={items}
                valueRenderer={formatter}
                onChange={onChange}
                tooltip={tooltip}
            />
        )
    }
    return (
        <ResponsiveWrapper
            height={28}
            seleniumLocation='round-tabs'
            alternativeContent={renderTabsAsDropdown}
        >
            <RoundTabsList {...props} />
        </ResponsiveWrapper>
    )
}
