import { State as FrontendState } from 'aos-frontend/src/app/core/state'
import {
    calculateChartDomainForRange,
    calculateDomain,
    Domain,
} from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, timeRangeFromNow } from 'aos-helpers/src/helpers/TimeRange'
import { State as MobileState } from 'aos-mobile/src/app/core/state'
import {
    CommonFlightView,
    FlightsHistoryTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { createSelector } from 'reselect'

import { filterToTimeRange } from '../../../../services/airportStatus/base/types/TimeRangeFilter'
import { DelaysStats } from '../../../../services/airportStatus/flights/types/DelaysStats'
import {
    filterCollection,
    flightsForType,
} from '../../../../services/airportStatus/flights/types/FlightCollection'
import {
    filterCollectionAndPoints,
    FlightCollectionAndPoints,
} from '../../../../services/airportStatus/flights/types/FlightCollectionAndPoints'
import {
    toFlightSeries,
    toFlightStats,
} from '../../../../services/airportStatus/flights/types/FlightCollectionPoint'
import {
    FlightPoint,
    FlightsSeries,
} from '../../../../services/airportStatus/flights/types/FlightsInfo'
import { FlightsType } from '../../../../services/airportStatus/flights/types/FlightsType'
import {
    averageForFlights,
    Flight,
    getAverageDelay,
    getFlightDelay,
    isDelayed,
} from '../../../../services/flightInformation/types/Flight'
import { FilterOptionAll } from '../../../../services/statusDashboard/types/filters/common'
import { ExtendedDelayFilters } from '../../../../services/statusDashboard/types/filters/DelaysFilters'
import { currentTimeSelector } from '../../../common/selectors'
import { flightsPredicate } from '../../../statusDashboard/selectors/flightCommon/common'
import {
    completedFlightCollectionAndPointsSelector,
    flightsLoadedSelector,
    ItemStateAware,
} from './common'

type State = FrontendState | MobileState

export interface DelaysSelectorState {
    flights: Flight[]
    filters: ExtendedDelayFilters
    series: FlightsSeries
    stats: DelaysStats
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    noDataWarning: boolean
}

const delaysFiltersOwnPropsSelector = createSelector(
    (_: State, ownProps: ItemStateAware<ExtendedDelayFilters>) => ownProps.itemState.timeRange,
    (_: State, ownProps: ItemStateAware<ExtendedDelayFilters>) => ownProps.itemState.flightType,
    (_: State, ownProps: ItemStateAware<ExtendedDelayFilters>) => ownProps.itemState.airline,
    (_: State, ownProps: ItemStateAware<ExtendedDelayFilters>) => ownProps.itemState.handlingAgent,
    (_: State, ownProps: ItemStateAware<ExtendedDelayFilters>) => ownProps.itemState.fullViewTab,
    (timeRange, flightType, airline, handlingAgent, fullViewTab) => ({
        timeRange,
        flightType,
        airline,
        handlingAgent,
        fullViewTab,
    }),
)

const flightsToDelaysTimePoint = (flights: Flight[], time: DateTime): FlightPoint => {
    const value = averageForFlights(flights, getFlightDelay)
    return {
        time,
        value,
        flightsCount: flights.length,
        flightNumbers: flights.map(f => f.fltnr).join(','),
    }
}

export const delaysMerger = (
    completed: FlightCollectionAndPoints,
    filters: ExtendedDelayFilters,
    now: DateTime,
    dataLoaded: boolean,
): DelaysSelectorState => {
    const collection = filterCollectionAndPoints(completed, isDelayed)
    const range = timeRangeFromNow(now, -15, 1)
    const statTimeRange = filterToTimeRange(filters.timeRange, now)

    const applicableFlightsPredicate = (f: Flight) =>
        flightsPredicate(f, filters.handlingAgent, filters.airline)
    const delayedFlightsPredicate = (f: Flight) => isDelayed(f)
    const fullApplicableFlightsPredicate = (f: Flight) =>
        applicableFlightsPredicate(f) &&
        delayedFlightsPredicate(f) &&
        isTimeWithin(f.sdt, statTimeRange)
    const prefilteredFlights = filterCollection(collection.flights, applicableFlightsPredicate)
    const flights = flightsForType(
        prefilteredFlights,
        filters.flightType,
        fullApplicableFlightsPredicate,
    )

    const series = toFlightSeries(collection.points, range, flightsToDelaysTimePoint)
    const stats = toFlightStats(collection.flights, statTimeRange, _t => true, getAverageDelay)
    const yDomain = calculateDomain([...series.arrivals, ...series.departures], f => f.value, {
        count: 5,
        fixed: true,
    })
    const xDomain = calculateChartDomainForRange(range)
    const noDataWarning = !dataLoaded
    return {
        flights,
        series,
        yDomain,
        xDomain,
        stats,
        filters,
        noDataWarning,
    }
}

export const delaysSelector = createSelector(
    completedFlightCollectionAndPointsSelector,
    delaysFiltersOwnPropsSelector,
    currentTimeSelector,
    flightsLoadedSelector,
    delaysMerger,
)

export const delaysTileSelector = createSelector(
    completedFlightCollectionAndPointsSelector,
    () => ({
        timeRange: FlightsHistoryTimeRange.Today,
        flightType: FlightsType.All,
        airline: FilterOptionAll.All,
        handlingAgent: FilterOptionAll.All,
        fullViewTab: CommonFlightView.History,
    }),
    currentTimeSelector,
    flightsLoadedSelector,
    delaysMerger,
)
