import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { WeatherTimePoint } from '../../../airportStatus/weather/types/WeatherMetrics'
import { WeatherUnit } from './WeatherUnit'

export const translateWeatherUnit = translateEnum<WeatherUnit>(
    WeatherUnit,
    'dashboard.weather.unit-tabs',
)

export enum WeatherGroup {
    Conditions,
    WindAndClouds,
    Status,
}

export const translateWeatherGroup = translateEnum<WeatherGroup>(
    WeatherGroup,
    'dashboard.weather.group-tab',
)

export enum WeatherTimeRange {
    Range12 = 13,
    Range24 = 25,
    Range32 = 33,
}

export const translateWeatherTimeRange = translateEnum<WeatherTimeRange>(
    WeatherTimeRange,
    'dashboard.weather.time-range',
)

export interface WeatherFilters {
    timePoint: WeatherTimePoint
    unit: WeatherUnit
    timeRange: WeatherTimeRange
    group: WeatherGroup
}

const weatherTimeRangeToTicksCountMap: Map<WeatherTimeRange, number> = new Map<
    WeatherTimeRange,
    number
>([
    [WeatherTimeRange.Range12, 12],
    [WeatherTimeRange.Range24, 8],
    [WeatherTimeRange.Range32, 8],
])

export const getWeatherTicksCountForWeatherTimeRange = (weatherTimeRange: WeatherTimeRange) =>
    weatherTimeRangeToTicksCountMap.get(weatherTimeRange) || 0
