import { dateTime } from 'aos-helpers/src/helpers/Time'
import { mapValues } from 'lodash'

import { queuesRestService } from '../../dataaccess/queueingTime/queuesRestService'
import {
    HistoryPointDto,
    QueueDto,
    QueueRecordDto,
} from '../../dataaccess/queueingTime/types/QueueDto'
import { TimeValuePoint } from '../airportStatus/base/types/TimePoint'
import { QueueDataItem, QueueingData } from './types/QueueingData'

class QueueingTimeService {
    public getQueueTime = (): Promise<QueueingData> =>
        queuesRestService.getSecurityQueues().then(this.dtoToPoint)

    private dtoToPoint = (dto: QueueDto) => {
        return mapValues(dto, this.itemToQueueingDataItem) as QueueingData
    }

    private itemToQueueingDataItem = (item: QueueRecordDto): QueueDataItem => ({
        waitTime: Math.round((item.waitTime / 60 + Number.EPSILON) * 100) / 100,
        waitTimeForecast: Math.round(item.waitTimeForecast / 60),
        length: item.length,
        lengthHistory: item.historyPoints.map(hp => this.dtoPointToTimeValueLength(hp)),
        waitTimeHistory: item.historyPoints.map(hp => this.dtoPointToTimeValueWaitTime(hp)),
    })

    private dtoPointToTimeValueWaitTime = (p: HistoryPointDto): TimeValuePoint => ({
        value: Math.round((p.waitTime / 60 + Number.EPSILON) * 100) / 100,
        time: dateTime(p.time),
    })

    private dtoPointToTimeValueLength = (p: HistoryPointDto): TimeValuePoint => ({
        value: p.length,
        time: dateTime(p.time),
    })
}

export const queueingTimeService = new QueueingTimeService()
