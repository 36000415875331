import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DeIcingChemicals } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

import {
    ReportChemicals,
    translateReportChemicals,
} from '../../../../core/tasks/reports/types/ReportChemicals'

interface ReportChemicalsDropdownProps {
    chemicals: DeIcingChemicals[]
    value: ReportChemicals
    onChange(v: ReportChemicals): void
}

export const ReportChemicalsDropdown: FC<ReportChemicalsDropdownProps> = ({
    value,
    onChange,
    chemicals,
}) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            width={160}
            items={[FilterOptionAll.All, ...chemicals]}
            valueRenderer={translateReportChemicals}
            onChange={onChange}
        />
    )
}
