import { isEmpty, isNil, omitBy } from 'lodash'

import { TableFilter } from '../state'
import { FiltersObject } from './urlFilters'

export const stringFilter = <T extends {}>(filter: TableFilter, row: T): boolean => {
    const id: keyof T = filter.id as keyof T
    return row[id] !== undefined ? new RegExp(filter.value, 'i').test(String(row[id])) : false
}

export const clearFilters = (filters: FiltersObject) =>
    omitBy(filters, filter =>
        Array.isArray(filter) ? isEmpty(filter) : isNil(filter) || filter === '',
    )
