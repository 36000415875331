import { groupByEnum } from 'aos-helpers/src/helpers/Enum'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { sumBy } from 'lodash'

import { PaxForecastArrivalOrDeparture } from './PaxForecastArrivalOrDeparture'
import { PaxForecastDataSource } from './PaxForecastDataSource'
import { PaxLocalOrTransfer } from './PaxLocalOrTransfer'

export interface PaxForecastPoint {
    time: DateTime
    dataSource: PaxForecastDataSource
    localOrTransfer: PaxLocalOrTransfer
    arrivalOrDeparture: PaxForecastArrivalOrDeparture
    terminal: string
    pax: number
    flightsCount: number
}

export type PaxForecast = PaxForecastPoint[]

export const sumPax = (forecast: PaxForecastPoint[]) => sumBy(forecast, p => p.pax)

export const sumFlights = (forecast: PaxForecastPoint[]) => sumBy(forecast, p => p.flightsCount)

export const groupByArrDep = (aggregator: f.Func1<PaxForecastPoint[], number>) =>
    groupByEnum(PaxForecastArrivalOrDeparture, p => p.arrivalOrDeparture, aggregator)

export const groupByLocalTransfer = (aggregator: f.Func1<PaxForecastPoint[], number>) =>
    groupByEnum(PaxLocalOrTransfer, p => p.localOrTransfer, aggregator)

export const groupByDataSource = groupByEnum(
    PaxForecastDataSource,
    (p: PaxForecastPoint) => p.dataSource,
    p => ({ pax: sumPax(p), flightsCount: sumFlights(p) }),
)
