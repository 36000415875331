import { featureFlagSelector } from 'aos-services/src/core/featureFlags/selectors'
import { FeatureFlagType } from 'aos-services/src/dataaccess/firebase/FirebaseFeatureFlagService'
import React, { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

type FeatureFlagProps = {
    flag: FeatureFlagType
} & PropsWithChildren
export const FeatureFlag: FC<FeatureFlagProps> = ({ children, flag }) => {
    const flagValue = useSelector(featureFlagSelector(flag))
    if (flagValue) {
        return <>{children}</>
    }
    return null
}
