import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { flightsHelpConfig } from '../../help/flights/flightHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Flights } from './Flights'
import { FlightsTile } from './FlightsTile'

export const flightsConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 3, h: 3 },
            maxSize: { w: 4, h: 4 },
        },
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 4, h: 6 },
            maxSize: { w: 12, h: 12 },
        },
    },
    renderer: Flights,
    tileRenderer: FlightsTile,
    helpConfig: flightsHelpConfig,
}
