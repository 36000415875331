import { State } from 'aos-frontend/src/app/core/state'
import { calculateChartDomainForRange, Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, timeRangeFromNow } from 'aos-helpers/src/helpers/TimeRange'
import {
    CommonFlightView,
    FlightsHistoryTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { partition } from 'lodash'
import { createSelector } from 'reselect'

import { filterToTimeRange } from '../../../../services/airportStatus/base/types/TimeRangeFilter'
import {
    filterCollection,
    flightsForType,
} from '../../../../services/airportStatus/flights/types/FlightCollection'
import { FlightCollectionAndPoints } from '../../../../services/airportStatus/flights/types/FlightCollectionAndPoints'
import {
    toFlightSeries,
    toFlightStats,
} from '../../../../services/airportStatus/flights/types/FlightCollectionPoint'
import {
    calculatePercentageYDomain,
    FlightPoint,
    FlightsSeries,
} from '../../../../services/airportStatus/flights/types/FlightsInfo'
import { FlightsType } from '../../../../services/airportStatus/flights/types/FlightsType'
import { PunctualityStats } from '../../../../services/airportStatus/flights/types/PunctualityStats'
import {
    Flight,
    getFlightPercentage,
    isPunctual,
} from '../../../../services/flightInformation/types/Flight'
import { FilterOptionAll } from '../../../../services/statusDashboard/types/filters/common'
import { PunctualityFilters } from '../../../../services/statusDashboard/types/filters/PunctualityFilters'
import { currentTimeSelector } from '../../../common/selectors'
import { flightsPredicate } from '../../../statusDashboard/selectors/flightCommon/common'
import {
    completedFlightCollectionAndPointsSelector,
    flightsLoadedSelector,
    ItemStateAware,
} from './common'

export interface PunctualitySelectorState {
    flights: Flight[]
    filters: PunctualityFilters
    series: FlightsSeries
    stats: PunctualityStats
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    noDataWarning: boolean
}

const punctualityFiltersOwnPropsSelector = createSelector(
    (_: State, ownProps: ItemStateAware<PunctualityFilters>) => ownProps.itemState.timeRange,
    (_: State, ownProps: ItemStateAware<PunctualityFilters>) => ownProps.itemState.flightType,
    (_: State, ownProps: ItemStateAware<PunctualityFilters>) => ownProps.itemState.airline,
    (_: State, ownProps: ItemStateAware<PunctualityFilters>) => ownProps.itemState.handlingAgent,
    (_: State, ownProps: ItemStateAware<PunctualityFilters>) => ownProps.itemState.fullViewTab,
    (timeRange, flightType, airline, handlingAgent, fullViewTab) => ({
        timeRange,
        flightType,
        airline,
        handlingAgent,
        fullViewTab,
    }),
)

const flightsToPunctualityTimePoint = (flights: Flight[], time: DateTime) => {
    const [punctual] = partition(flights, isPunctual)

    const value = getFlightPercentage(flights, punctual)
    return {
        time,
        value,
        invertedValue: 100 - value,
        flightsCount: punctual.length,
        flightNumbers: punctual.map(f => f.fltnr).join(','),
    } as FlightPoint
}

export const punctualityMerger = (
    collection: FlightCollectionAndPoints,
    filters: PunctualityFilters,
    now: DateTime,
    dataLoaded: boolean,
): PunctualitySelectorState => {
    const range = timeRangeFromNow(now, -15, 1)
    const statTimeRange = filterToTimeRange(filters.timeRange, now)

    const applicableFlightsPredicate = (f: Flight) =>
        flightsPredicate(f, filters.handlingAgent, filters.airline)
    const nonPunctualFlightsPredicate = (f: Flight) => !isPunctual(f)
    const nonPunctualApplicableFlightsPredicate = (f: Flight) =>
        applicableFlightsPredicate(f) &&
        nonPunctualFlightsPredicate(f) &&
        isTimeWithin(f.sdt, statTimeRange)

    const prefilteredFlights = filterCollection(collection.flights, applicableFlightsPredicate)
    const flights = flightsForType(
        prefilteredFlights,
        filters.flightType,
        nonPunctualApplicableFlightsPredicate,
    )

    const series = toFlightSeries(collection.points, range, flightsToPunctualityTimePoint)
    const stats = toFlightStats(collection.flights, statTimeRange, isPunctual, getFlightPercentage)
    const yDomain = calculatePercentageYDomain(series)
    const xDomain = calculateChartDomainForRange(range)
    const noDataWarning = !dataLoaded
    return {
        flights,
        series,
        yDomain,
        xDomain,
        stats,
        filters,
        noDataWarning,
    }
}

export const punctualitySelector = createSelector(
    completedFlightCollectionAndPointsSelector,
    punctualityFiltersOwnPropsSelector,
    currentTimeSelector,
    flightsLoadedSelector,
    punctualityMerger,
)

export const punctualityTileSelector = createSelector(
    completedFlightCollectionAndPointsSelector,
    () => ({
        timeRange: FlightsHistoryTimeRange.Today,
        flightType: FlightsType.All,
        airline: FilterOptionAll.All,
        handlingAgent: FilterOptionAll.All,
        fullViewTab: CommonFlightView.History,
    }),
    currentTimeSelector,
    flightsLoadedSelector,
    punctualityMerger,
)
