import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { DatetimeInput } from '../datetime/DatetimeInput'
import { LabeledFormElement } from './LabeledFormElement'

export interface FieldRange {
    startTime?: DateTime
    endTime?: DateTime
}

interface LabeledTimeRangeProps extends SeleniumProps, MarginBoxProps {
    keyPrefix: string
    value: FieldRange
    isRequired?: boolean
    isError?: boolean
    variant?: ThemeVariant
    errorSuffix?: string
    isEndTimeRelatedToStartTime?: boolean
    onChange(value: FieldRange): void
}

export const LabeledTimeRange: FCWithChildren<LabeledTimeRangeProps> = ({
    variant,
    isRequired,
    seleniumLocation,
    value,
    onChange,
    isError,
    keyPrefix,
    errorSuffix = '',
    isEndTimeRelatedToStartTime,
    ...marginProps
}) => {
    const translateSuffix = (key: string) => translateParts(keyPrefix, key)
    const isValidEndTime = (currentDate: DateTime) => {
        if (value.startTime) {
            return currentDate.isSameOrAfter(value.startTime, 'day')
        }
        return true
    }

    const changeStartTimeAndEndTime = (startTime: DateTime | undefined) => {
        onChange({
            startTime: startTime,
            endTime: startTime?.clone().add(1, 'hour'),
        })
    }

    const changeTime = (range: FieldRange) => {
        if (!range.startTime && range.endTime) {
            onChange({
                startTime: range.endTime.clone().add(-1, 'hour'),
                endTime: range.endTime,
            })
        } else if (range.startTime && range.endTime) {
            onChange({
                startTime: range.startTime,
                endTime: range.endTime,
            })
        } else if (range) {
            onChange({ startTime: range.startTime, endTime: undefined })
        }
    }

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error' + errorSuffix)}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <DatetimeInput
                marginBottom={8}
                value={value.startTime}
                onChange={startTime => isEndTimeRelatedToStartTime ? changeStartTimeAndEndTime(startTime) : changeTime({ ...value, startTime })}
                placeholder={translateSuffix('from')}
            />
            <DatetimeInput
                value={value.endTime}
                onChange={endTime => changeTime({ ...value, endTime })}
                timeRangeStart={value.startTime}
                isValidDate={isValidEndTime}
                placeholder={translateSuffix('to')}
            />
        </LabeledFormElement>
    )
}
