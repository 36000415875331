import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { connect } from 'react-redux'

import { addContactActions, removeUserAction } from '../../../core/groupManager/actions'
import { State } from '../../../core/state'
import {
    AddContactModalsClass,
    ContactModalsDispatchProps,
    ContactModalsProps,
} from './AddContactModalsClass'

export const AddContactModal = connect<ContactModalsProps, ContactModalsDispatchProps, {}>(
    (state: State) => ({
        ...state.groupManager.addContactState,
        groups: state.groupManager.groups,
        headerTitle: translate('manage-user.contact.title'),
    }),
    {
        ...addContactActions,
        removeUserAction,
        saveUserAction: addContactActions.createContactAction,
    },
)(AddContactModalsClass)
