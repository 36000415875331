import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Box, MarginBoxProps, NumberValues } from '../base/Box'
import { Chip } from './Chip'

interface ChipListProps<T> extends MarginBoxProps {
    items: T[]
    horizontalSpacing: NumberValues
    verticalSpacing: NumberValues
    crossed?: boolean
    renderer(v: T): ReactNode
    onRemove?(v: T): void
}
interface SpacingProps {
    horizontalSpacing: NumberValues
    verticalSpacing: NumberValues
}

export const ChipList = <T extends any>({
    items,
    horizontalSpacing,
    verticalSpacing,
    renderer,
    onRemove,
    crossed,
    ...margins
}: ChipListProps<T>) => (
    <Wrapper
        row
        wrap
        horizontalSpacing={horizontalSpacing}
        verticalSpacing={verticalSpacing}
        {...margins}
    >
        {items.map((i, index) => (
            <Chip
                key={index}
                crossed={crossed}
                marginRight={horizontalSpacing}
                onRemove={onRemove ? () => onRemove(i) : undefined}
                marginBottom={verticalSpacing}
            >
                {renderer(i)}
            </Chip>
        ))}
    </Wrapper>
)

const Wrapper = styled(Box)<SpacingProps>`
    margin-right: ${p => -p.horizontalSpacing}px;
    margin-bottom: ${p => -p.verticalSpacing}px;
`
