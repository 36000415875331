import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { format, Query } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { AosFeedInDto, AosFeedInputDto } from './types/AosFeedInDto'

class FeedsRestServiceClass extends BaseRestService {
    public createFeedIn(v: Partial<AosFeedInDto>): Promise<AosFeedInDto> {
        return this.postJsonAndTransform<AosFeedInDto>('/feeds', v, BaseRestService.jsonTransformer)
    }

    public loadFeeds(filter: string, pr: Query): Promise<Pageable<AosFeedInDto>> {
        return this.getAndTransform<Pageable<AosFeedInDto>>(
            format({
                pathname: `/feeds/${filter}`,
                query: pr,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public dismissEvent(id: number): Promise<any> {
        return this.post(`/feeds/${id}/dismiss`)
    }

    public activateEvent(id: number, e: AosFeedInputDto): Promise<AosFeedInDto> {
        return this.postJsonAndTransform<AosFeedInDto>(
            `/feeds/${id}/activate`,
            e,
            BaseRestService.jsonTransformer,
        )
    }

    public addEventToGroup(parentId: number, feedId: number): Promise<Response> {
        return this.post(`/feeds/${feedId}/group/${parentId}`)
    }

    public loadFeedIn(id: number): Promise<AosFeedInDto> {
        return this.getAndTransform<AosFeedInDto>(`/feeds/${id}`, BaseRestService.jsonTransformer)
    }
}

export const feedsRestService = new FeedsRestServiceClass()
