import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { isUndefined } from 'lodash'

import { stringSorter } from '../helpers/sorters'

export const flightNumberSorter = (a: Flight, b: Flight, desc: boolean) =>
    stringSorter(a.fltnr, b.fltnr, desc)

export const natureOfFlightSorter = (a: Flight, b: Flight, desc: boolean) =>
    stringSorter(a.naflt.value, b.naflt.value, desc)

export const routeSorter = (a: Flight, b: Flight, desc: boolean) => {
    const v1 = a.routes[0] ? a.routes[0].airport : ''
    const v2 = b.routes[0] ? b.routes[0].airport : ''
    return stringSorter(v1, v2, desc)
}

export const parkSorter = (a: Flight, b: Flight, desc: boolean) =>
    stringSorter(a.park && a.park.current, b.park && b.park.current, desc)

export const gateSorter = (a: Flight, b: Flight, desc: boolean) =>
    stringSorter(a.gate && a.gate.current, b.gate && b.gate.current, desc)

export const beltAreaSorter = (a: Flight, b: Flight, desc: boolean) => {
    if (!isUndefined(a.bltarea) && a.bltarea === b.bltarea) {
        if (a.belt && b.belt) {
            return stringSorter(a.belt, b.belt, desc)
        }
    }
    return stringSorter(a.bltarea, b.bltarea, desc)
}

export const bagSorter = (a: Flight, b: Flight, desc: boolean, now: DateTime) => {
    const t1 = a.bag.deliveryTimeInMinutes(now)
    const t2 = b.bag.deliveryTimeInMinutes(now)
    return desc ? t1 - t2 : t2 - t1
}

export const checkInSorter = (a: Flight, b: Flight, desc: boolean) => {
    if (!isUndefined(a.chkarea) && a.chkarea === b.chkarea) {
        if (a.chkdsk[0] && b.chkdsk[0]) {
            return stringSorter(a.chkdsk[0], b.chkdsk[0], desc)
        }
    }
    return stringSorter(a.chkarea, b.chkarea, desc)
}

export const statusSorter = (a: Flight, b: Flight, desc: boolean) =>
    stringSorter(a.prm.text, b.prm.text, desc)
