import { chain } from 'lodash'

import { Restriction } from './Restriction'
import { RestrictionAreaType } from './RestrictionArea'

export interface RestrictionsGroupedStats {
    areaType: RestrictionAreaType
    areaNumber: string
    active: number
}

export const toRestrictionsGroupedStats = (
    restrictions: Restriction[],
): RestrictionsGroupedStats[] =>
    chain(restrictions)
        .groupBy(restriction => restriction.areaType)
        .map((items: Restriction[], areaType: string): RestrictionsGroupedStats[] => {
            return groupStatsByLocation(items, areaType as RestrictionAreaType)
        })
        .flatten()
        .value()
        .sort(sortRestrictionsGroupedStats)

const groupStatsByLocation = (
    restrictions: Restriction[],
    areaType: RestrictionAreaType,
): RestrictionsGroupedStats[] => {
    return chain(restrictions)
        .groupBy(restriction => restriction.areaNumber)
        .map(
            (groupedItems: Restriction[], areaNumber: string): RestrictionsGroupedStats => ({
                areaType: areaType as RestrictionAreaType,
                areaNumber: areaNumber,
                active: groupedItems.length,
            }),
        )
        .value()
}

export const sortRestrictionsGroupedStats = (
    a: RestrictionsGroupedStats,
    b: RestrictionsGroupedStats,
): number =>
    a.areaType === b.areaType
        ? sortRestrictionsGroupedStatsByAreaNumber(a, b)
        : a.areaType === RestrictionAreaType.Terminal
        ? -1
        : 1

const sortRestrictionsGroupedStatsByAreaNumber = (
    a: RestrictionsGroupedStats,
    b: RestrictionsGroupedStats,
): number => (a.areaNumber > b.areaNumber ? 1 : -1)
