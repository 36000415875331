import { conditionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { getCustomGroupList } from 'aos-services/src/services/users/aosUserFormatter'
import { isExistingUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import { USER_FORM_READY } from 'aos-services/src/services/users/types/AosUserFindStatus'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import React from 'react'

import {
    ContactActionCreators,
    ContactUserData,
} from '../../../core/groupManager/manageUser/contact/actions'
import { ManageContactState } from '../../../core/groupManager/manageUser/state'
import { ContactForm } from './forms/ContactForm'
import { PermissionsForm } from './forms/PermissionsForm'
import { ContactStatus } from './partial/ContactStatus'
import { ExistingContactStatus } from './partial/ExistingContactStatus'
import { UserModalsClass, UserModalsDispatchProps, UserModalsProps } from './UserModalsClass'

export interface ContactModalsProps extends ManageContactState, UserModalsProps {}

export class AddContactModalsClass extends UserModalsClass<
    ContactModalsProps & ContactModalsDispatchProps,
    ContactFormData
> {
    protected renderFooterContent() {
        const form = this.props.form
        return <ValidationInfo pristine={form.pristine} valid={form.valid} />
    }

    protected renderFormContent() {
        const { user, form, groups } = this.props
        const customGroups = getCustomGroupList(groups)

        return (
            <ContactForm
                user={user}
                form={form}
                groups={customGroups}
                changeForm={this.changeValue}
                selectAllGroups={this.selectAll(customGroups)}
                deselectAllGroups={this.props.deselectAllGroupsAction}
            />
        )
    }

    protected renderPreCheckFormContent() {
        return null
    }

    protected renderStatusContent() {
        const { user, conflictUserId } = this.props
        const goBack = () => this.changeValue({ userStatus: USER_FORM_READY })
        const editAdUser = conditionalFunctionCall1Arg(
            !!conflictUserId,
            this.props.switchToEditAdUserAction,
            conflictUserId,
        )
        const editContact = conditionalFunctionCall1Arg(
            !!conflictUserId,
            this.props.switchToEditContactAction,
            conflictUserId,
        )

        return isExistingUserFormData(user) ? (
            <ExistingContactStatus
                user={user}
                goBack={goBack}
                hide={this.props.hideUserModalAction}
                removeAdUser={this.removeUser}
                editAdUser={editAdUser}
                editContact={editContact}
            />
        ) : (
            <ContactStatus
                user={user}
                goBack={goBack}
                hide={this.props.hideUserModalAction}
                addAsAdUser={this.props.switchToAdUserAction}
                removeAdUser={this.removeUser}
                editAdUser={editAdUser}
                editContact={editContact}
            />
        )
    }

    protected renderPermissionSettings = () => {
        const { user } = this.props
        return <PermissionsForm user={user} changeForm={this.changeValue} />
    }

    protected saveUserAction() {
        this.props.saveUserAction({
            formData: this.props.user,
            notificationSettings: this.props.notificationSettings,
        })
    }

    private changeValue = (v: Partial<ContactFormData>) => {
        this.props.changeFormValueAction(Object.assign({}, this.props.user, v))
    }
}

export interface ContactModalsDispatchProps extends ContactActionCreators, UserModalsDispatchProps {
    saveUserAction(v: ContactUserData): void
}
