import { OperationalBlock } from 'aos-services/src/core/statusDashboardData/operationalForecast/selectors'
import React, { FC } from 'react'
import styled from 'styled-components'

import { TileContainer } from '../base/ItemContainer'
import { OperationalForecastTileIconItem } from './OperationalForecastTileItem'

interface OperationalForecastGroupBlocksProps {
    items: OperationalBlock[]
}

export const OperationalForecastGroupBlocks: FC<OperationalForecastGroupBlocksProps> = ({
    items,
}) => (
    <TileContainer autoHeight>
        <OperationalForecastBox>
            {items.map(([group, mode], index) => (
                <OperationalForecastTileIconItem
                    key={index}
                    group={group}
                    mode={mode}
                    size='large'
                />
            ))}
        </OperationalForecastBox>
    </TileContainer>
)

const OperationalForecastBox = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(50px, 100px));
    grid-auto-rows: 1fr;
    grid-gap: 12px;
`
