import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisScale } from 'aos-ui-common/src/components/chart/types/AxisScale'
import { ChartXYAccessors } from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartLinearIconSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { isFunction } from 'lodash'
import React, { ReactNode } from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

export const seriesIconSize = 17
export const seriesIconsPadding = 22
export const seriesTextPadding = 12

class LinearIconSeriesComponent<T, TX, TY> extends BaseChartComponent<
    ChartLinearIconSeriesProps<T, TX, TY>
> {
    public render() {
        const { data } = this.props

        return (
            <g className={cxp(this.props, 'series series--linear-icon')}>
                {data.map(this.renderIcon)}
            </g>
        )
    }

    private renderIcon = (datum: T) => {
        const { margins, seriesConfig, scales, accessors } = this.props

        const xPos = scales.xScale(accessors.xAccessor(datum)) || 0
        const xIconPos = xPos - seriesIconSize / 2

        const yPos = margins.top + seriesIconsPadding + seriesIconSize / 2
        const yIconPos = yPos - seriesIconSize / 2

        const y = accessors.yAccessor(datum)

        const rotation = `rotate(${this.getAttributeValue(seriesConfig.rotate, datum)},
                    ${xPos},
                    ${yPos})`

        return (
            <g key={xPos}>
                <g fill={this.getAttributeValue(seriesConfig.color, datum)} transform={rotation}>
                    <SvgImage
                        svg={seriesConfig.svgIcon}
                        attributes={{
                            width: seriesIconSize,
                            height: seriesIconSize,
                            x: xIconPos,
                            y: yIconPos,
                        }}
                    />
                </g>
                <text x={xPos} y={yPos + seriesTextPadding} dy='1em' textAnchor='middle'>
                    {y as ReactNode}
                </text>
            </g>
        )
    }
    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartLinearIconSeriesProps<T, TX, TY> extends ClassNameProps {
    data: T[]
    seriesConfig: ChartLinearIconSeriesConfig<T>
    accessors: ChartXYAccessors<T, TX, TY>
    scales: { xScale: AxisScale<TX> }
}

export const LinearIconSeries = withChartContext(LinearIconSeriesComponent)
