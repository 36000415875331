import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosUnitTaskItem,
    EventPhase,
    eventPhaseOrder,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { PlusButton } from 'aos-ui/src/components/buttons/PlusButton'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import { chain } from 'lodash'
import React, { FC, useMemo, useState } from 'react'

import { UnitTaskItem } from './UnitTaskItem'

export const UnitTaskItemSection: FC<UnitTaskSectionProps> = ({
    items,
    title,
    isEditable,
    emptyPlaceholder,
    onAdd,
    onChangeSeq,
    onEdit,
    onDelete,
    onApplySeqChanges,
}) => {
    const groupedItems = useMemo(
        () =>
            chain(items)
                .groupBy(q => q.eventPhase)
                .mapValues((v, k) => ({ group: k as EventPhase, items: v }))
                .values()
                .orderBy(q => eventPhaseOrder[q.group])
                .value(),
        [items],
    )
    const renderContent = () => {
        return (
            <DarkScrollbar>
                <Box paddingHorizontal={30}>{groupedItems.map(renderSection)}</Box>
            </DarkScrollbar>
        )
    }

    const onMove = (fromId: number, toId: number) =>
        onChangeSeq({
            fromId,
            toId,
        })

    const renderSection = (prop: UnitTaskGroupProps) => (
        <UnitTaskGroup {...prop} renderItem={renderItem} />
    )

    const renderItem = (item: AosUnitTaskItem) => {
        const onEditItem = () => onEdit(item)
        const onDeleteItem = () => onDelete(item)

        return (
            <UnitTaskItem
                key={item.id}
                isEditable={isEditable}
                isEven
                item={item}
                seq={item.id}
                onMove={onMove}
                onApplyMove={onApplySeqChanges}
                onEdit={onEditItem}
                onDelete={onDeleteItem}
            />
        )
    }

    const renderPlaceholder = () => (
        <Box paddingHorizontal={30} paddingBottom={30}>
            {emptyPlaceholder}
        </Box>
    )

    return (
        <>
            <Box row paddingBottom={16} paddingTop={20} paddingHorizontal={30} shrink={0}>
                <Text flex={1} color={Color.White} weight='light' size={18}>
                    {title}
                </Text>
                {isEditable && (
                    <Box paddingLeft={20}>
                        <PlusButton onClick={onAdd} seleniumLocation={`${title}-action-button`} />
                    </Box>
                )}
            </Box>
            {items.length ? renderContent() : renderPlaceholder()}
        </>
    )
}

type UnitTaskGroupProps = {
    group: EventPhase
    items: AosUnitTaskItem[]
    renderItem(item: AosUnitTaskItem): JSX.Element
}
const UnitTaskGroup: FC<UnitTaskGroupProps> = ({ group, items, renderItem }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    return (
        <CollapsibleContainer
            isCollapsed={isCollapsed}
            toggle={() => setIsCollapsed(!isCollapsed)}
            variant={CollapsibleButtonVariant.TopBottom}
            key={group}
            paddingBottom={8}
            label={
                <Text fontVariant='all-small-caps' size={16} lineHeightPx={36}>
                    {translateEnum<EventPhase>(EventPhase, 'tasklist-manager')(group)}
                </Text>
            }
        >
            {items.map(renderItem)}
        </CollapsibleContainer>
    )
}

interface UnitTaskSectionProps extends ClassNameProps {
    items: AosUnitTaskItem[]
    isEditable: boolean
    title: string
    emptyPlaceholder: JSX.Element
    onAdd(): void
    onEdit(v: AosUnitTaskItem): void
    onDelete(v: AosUnitTaskItem): void
    onChangeSeq(v: ChangeSeqVector): void
    onApplySeqChanges(): void
}
