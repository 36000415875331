import 'react-toastify/dist/ReactToastify.min.css'

import { Color } from 'aos-ui-common/src/styles/Color'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const StyledToastContainer = styled(ToastContainer).attrs({
    position: 'top-center',
    pauseOnHover: false,
})`
    width: 400px;

    .Toastify__toast {
        min-height: 0;
    }

    .Toastify__toast--error {
        background-color: ${Color.PaleRed};
    }

    .Toastify__toast-icon {
        color: ${Color.Red};
    }

    .Toastify__toast--success {
        background-color: ${Color.UltraPaleGreen};
        color: ${Color.Black};
    }

    .Toastify__progress-bar--success {
        background-color: ${Color.Green};
    }
`
