import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    isStationTheUpcomingStation,
    RingRailStation,
    RingRailTimeTableRow,
    RingRailTimeTableRowWithShowSchedule,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Dictionary, flatten } from 'lodash'
import React, { PureComponent } from 'react'

import { TrainTimeTableEmptyItem } from './TrainTimeTableEmptyItem'
import { TrainTimeTableHeader } from './TrainTimeTableHeader'
import { TrainTimeTableItem } from './TrainTimeTableItem'
import { TrainTimeTableLegend } from './TrainTimeTableLegend'

export class TrainTimeTable extends PureComponent<TrainTimeTableProps> {
    constructor(props: TrainTimeTableProps) {
        super(props)
    }

    public render() {
        return (
            <Box className={cxp(this.props, 'container container--vertical')}>
                <TrainTimeTableHeader />
                <Box flex={1}>{this.renderTimeTable()}</Box>
                <TrainTimeTableLegend />
            </Box>
        )
    }

    private renderTimeTable = () => {
        const { timeTable } = this.props
        return timeTable.length === 0 ? (
            <Text textAlign='center' color={Color.Grey} size={14} lineHeight='standard'>
                {translate('train-details.timetable.unavailable')}
            </Text>
        ) : (
            flatten(timeTable.map(this.renderTimeTableItem))
        )
    }

    private renderTimeTableItem = (
        item: RingRailTimeTableRowWithShowSchedule,
        index: number,
        items: RingRailTimeTableRowWithShowSchedule[],
    ) => {
        const { stations, upcomingStation } = this.props
        const [stationItem, shouldShow] = item
        const isItemTheUpcomingStation = isStationTheUpcomingStation(stationItem, upcomingStation)

        const isFirstItem = index === 0
        const isLastItem = index === items.length - 1
        const showAdditionalItem = !shouldShow && isItemTheUpcomingStation

        return [
            showAdditionalItem && isLastItem ? (
                <TrainTimeTableEmptyItem key='additionalItemBefore' />
            ) : null,
            <TrainTimeTableItem
                key={index}
                item={stationItem}
                station={stations[stationItem.stationShortCode]}
                highlightItem={isItemTheUpcomingStation}
            />,
            showAdditionalItem && isFirstItem ? (
                <TrainTimeTableEmptyItem key='additionalItemAfter' />
            ) : null,
        ]
    }
}

interface TrainTimeTableProps extends ClassNameProps {
    timeTable: RingRailTimeTableRowWithShowSchedule[]
    stations: Dictionary<RingRailStation>
    upcomingStation?: RingRailTimeTableRow
}
