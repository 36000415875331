import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { TimeType } from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import React, { FC } from 'react'

import ChocksSvg from './assets/chocks.svg'
import EstimatedSvg from './assets/estimated.svg'
import ScheduledSvg from './assets/scheduled.svg'
import UserSuppliedSvg from './assets/user-supplied.svg'

interface TimeTypeIconProps {
    type: TimeType
}

const icons: Record<TimeType, Svg> = {
    [TimeType.Chocks]: ChocksSvg,
    [TimeType.Scheduled]: ScheduledSvg,
    [TimeType.Estimated]: EstimatedSvg,
    [TimeType.UserSupplied]: UserSuppliedSvg,
}

export const TimeTypeIcon: FC<TimeTypeIconProps> = props => {
    const svg = icons[props.type]
    return <Icon iconSize={BlockSize.Large} svg={svg} />
}
