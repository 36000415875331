import React, { FCWithChildren, PropsWithChildren } from 'react'

import { Box, BoxProps } from '../base/Box'
import { Pagination, PaginationProps } from '../pagination/Pagination'

interface PaginationListProps extends BoxProps, PaginationProps {}

export const PaginationList: FCWithChildren<PropsWithChildren<PaginationListProps>> = ({
    page,
    maxButtons,
    onChange,
    children,
    ...boxProps
}) => {
    const paginationNeeded = page.totalElements > page.size
    return (
        <Box flex={1} column {...boxProps}>
            <Box flex={1}>{children}</Box>
            {paginationNeeded && (
                <Box marginTop={8}>
                    <Pagination page={page} maxButtons={maxButtons} onChange={onChange} />
                </Box>
            )}
        </Box>
    )
}
