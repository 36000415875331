import { Box } from 'aos-ui/src/components/base/Box'
import React, { PropsWithChildren, PureComponent } from 'react'

export class BaseFlightItem extends PureComponent<PropsWithChildren<BaseFlightItemProps>> {
    public render() {
        return (
            <Box
                className='flight-table-preview__item'
                paddingVertical={8}
                onClick={this.props.onClick}
            >
                {this.props.children}
            </Box>
        )
    }
}

export interface BaseFlightItemProps {
    onClick?(): void
}
