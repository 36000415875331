import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { translateFirst } from 'aos-helpers/src/helpers/translations/Translations'
import { kebabCase, sortBy } from 'lodash'

import { isUpToDate, WidgetHealth } from '../../widgetHealths/type/DashboardItemHealths'
import { DashboardItemMode } from './DashboardItemMode'
import { DashboardItemType } from './DashboardItemType'

export type DashboardItemStatuses = Partial<Record<DashboardItemType | 'TOBT', DashboardItemStatus>>

export interface DashboardItemStatusReasonCode {
    code: string
    status: DashboardItemMode
}

export interface DashboardItemStatus {
    status: DashboardItemMode
    warning: boolean
    reasonCodes: DashboardItemStatusReasonCode[]
    testing?: boolean
}

export const getStatusForItemTypeAndHealth = (
    statuses: DashboardItemStatuses,
    itemHealth: WidgetHealth | undefined,
    type: DashboardItemType,
): DashboardItemStatus => {
    const status = statuses[type]
    if (!isUpToDate(itemHealth) || !status) {
        return { ...status, ...noDataStatus }
    }

    return status
}

const noDataStatus: DashboardItemStatus = {
    status: DashboardItemMode.NoData,
    warning: false,
    reasonCodes: [],
}

export const defaultItemStatus: DashboardItemStatus = {
    status: DashboardItemMode.Normal,
    warning: false,
    reasonCodes: [],
}

export const defaultItemStatuses: Record<DashboardItemType | 'TOBT', DashboardItemStatus> = {
    ...getInitialValuesForEnumRecord(DashboardItemType, defaultItemStatus),
    TOBT: defaultItemStatus,
}

export const translateReasonCodes = (reasonCodes: DashboardItemStatusReasonCode[]): string[] =>
    reasonCodes.map(reason => {
        const base = 'dashboard.alert-reason'
        const label = `${base}.${kebabCase(reason.code)}`
        const labelWithStatus = `${label}.${kebabCase(reason.status)}`
        return translateFirst([labelWithStatus, label])
    })

export const statusToNumber: Record<DashboardItemMode, number> = {
    [DashboardItemMode.Alert]: 0,
    [DashboardItemMode.Warning]: 1,
    [DashboardItemMode.Normal]: 3,
    [DashboardItemMode.NoData]: 4,
}

const getStatusForType = (t: DashboardItemType, statuses: DashboardItemStatuses) =>
    statuses[t] && statuses[t]!.status !== undefined
        ? statuses[t]!.status
        : DashboardItemMode.Normal

const typeToOrder = (statuses: DashboardItemStatuses) => (t: DashboardItemType) =>
    statusToNumber[getStatusForType(t, statuses)]

export const sortItemTypesBySeverity = (
    items: DashboardItemType[],
    statuses: DashboardItemStatuses,
): DashboardItemType[] => sortBy(items, typeToOrder(statuses))
