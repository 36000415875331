import { getUniqueValues } from 'aos-helpers/src/helpers/Array'
import { DateTime, startOfHour } from 'aos-helpers/src/helpers/Time'
import { unionTimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { EnumValues } from 'enum-values'
import { createSelector } from 'reselect'

import { baggageDeliveryRangesValues } from '../../../../../services/airportStatus/baggage/baggageDeliveryConstants'
import { BaggageStatSection } from '../../../../../services/airportStatus/baggage/types/BaggageStatSection'
import { filterToTimeRange } from '../../../../../services/airportStatus/base/types/TimeRangeFilter'
import { groupByStatusRange } from '../../../../../services/common/types/StatusRange'
import { flightFilterService } from '../../../../../services/flightInformation/FlightFilterService'
import {
    Flight,
    flightInRangePredicate,
} from '../../../../../services/flightInformation/types/Flight'
import { FlightListType } from '../../../../../services/flightInformation/types/FlightListType'
import {
    BaggageDeliveryFilters,
    BaggageDeliveryTimeRange,
    BeltAreaFilter,
} from '../../../../../services/statusDashboard/types/filters/BaggageDeliveryFilters'
import { FilterOptionAll } from '../../../../../services/statusDashboard/types/filters/common'
import { HandlingAgentFilter } from '../../../../../services/statusDashboard/types/filters/HandlingAgentFilter'
import { currentTimeSelector } from '../../../../common/selectors'
import { FlightsStateAware } from '../../../../flightInformation/state'
import { ItemStateAware } from '../common'

export const allArrivalsSelector = (state: FlightsStateAware): Flight[] =>
    state.flights.list.filter(flight => flight.flightType === FlightListType.Arrivals)

export const timeRangeOwnPropsSelector = (
    _0: any,
    ownProps: ItemStateAware<BaggageDeliveryFilters>,
) => ownProps.itemState.timeRange
export const handlingAgentOwnPropsSelector = (
    _0: any,
    ownProps: ItemStateAware<BaggageDeliveryFilters>,
) => ownProps.itemState.handlingAgent
export const beltAreaOwnPropsSelector = (
    _0: any,
    ownProps: ItemStateAware<BaggageDeliveryFilters>,
) => ownProps.itemState.beltArea

export const baggageDeliveryFiltersOwnPropsSelector = createSelector(
    timeRangeOwnPropsSelector,
    handlingAgentOwnPropsSelector,
    beltAreaOwnPropsSelector,
    (timeRange, handlingAgent, beltArea) => ({ timeRange, handlingAgent, beltArea }),
)

export const uniqueBeltAreaValues = (flights: Flight[], now: DateTime) => {
    const period = EnumValues.getValues<BaggageDeliveryTimeRange>(BaggageDeliveryTimeRange)
        .map(rangeFilter => filterToTimeRange(rangeFilter, now))
        .reduce(unionTimeRange)
    flights = flightFilterService.getFlightsFromPeriod(flights, period)
    return getUniqueValues(flights, flight => flight.bltarea)
}

export const filterFlights = (
    flights: Flight[],
    handlingAgent: HandlingAgentFilter,
    beltArea: BeltAreaFilter,
    now: DateTime,
) => {
    if (handlingAgent !== FilterOptionAll.All) {
        flights = flights.filter(flight => flight.handl === handlingAgent)
    }
    if (beltArea !== FilterOptionAll.All) {
        flights = flights.filter(flight => (flight.bltarea && flight.bltarea) === beltArea)
    }
    flights = flights.filter(flight => flight.bag.isDeliveryTimeValid(now))
    return flights
}

export const groupFlightsBySection = (
    flights: Flight[],
    now: DateTime,
): Record<BaggageStatSection, Flight[]> =>
    groupByStatusRange(
        BaggageStatSection,
        BaggageStatSection.Normal,
        baggageDeliveryRangesValues,
        (f: Flight) => f.bag.deliveryTimeInMinutes(now),
    )(flights)

export const allFlightsSelector = createSelector(
    allArrivalsSelector,
    currentTimeSelector,
    (arrivals, now) => {
        const to = startOfHour(now).add(1, 'hour')
        const from = to.clone().add(-48, 'hour')
        return arrivals.filter(flightInRangePredicate(from, to))
    },
)
