import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Runway } from 'aos-services/src/services/runways/types/RunwayMode'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface RunwayConditionalModeProps {
    runway: Runway
}

export const RunwayConditionalMode: FC<RunwayConditionalModeProps> = props => (
    <Box row>
        <span className='text__white-title'> {props.runway} </span>
        <Icon raw marginLeft={2} iconSize={BlockSize.Small} svg={SvgIcon.ConditionalIndicator} />
    </Box>
)
