import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../services/firebase/firebaseService'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { fodService } from '../../services/fod'
import { FodDto } from '../../services/fod/types'
import { currentUserSiteLocation } from '../auth/state'
import {
    FOD_SYNC,
    FOD_SYNC_STOP,
    FodSyncAction,
    FodSyncStopAction,
    LOAD_FOD,
    loadFod,
    LoadFodAction,
    loadFodSuccess,
} from './actions'

function* fodSyncSaga() {
    yield takeEvery<FodSyncAction>(FOD_SYNC, function* (action: FodSyncAction) {
        yield* firebaseService.onListener(
            'fod',
            function* () {
                yield syncValue(sendToSyncRepository.stsRef(SendToSyncType.FOD), loadFod, false)
            },
            action.payload,
        ) as Generator
    })
}

function* fodSyncStopSaga() {
    yield takeEvery<FodSyncStopAction>(FOD_SYNC_STOP, function* (action: FodSyncStopAction) {
        yield firebaseService.offListener('fod', action.payload)
    })
}

function* loadFodSaga() {
    yield takeEvery<LoadFodAction>(LOAD_FOD, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<FodDto> = yield call(
            fodService.getFodList,
            siteLocation,
            action.payload.page,
            action.payload.size,
        )
        yield put(loadFodSuccess(response))
    })
}

export const fodSagas = [loadFodSaga, fodSyncSaga, fodSyncStopSaga]
