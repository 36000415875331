import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    maintenanceContractorLabel,
    MaintenanceJobAssignment,
    MaintenanceTask,
    MaintenanceTaskType,
} from 'aos-services/src/services/tasks/types/task/MaintenanceTask'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

export const maintenanceTaskPropertyList = (t: MaintenanceTask): PropsListEntry[] => [
    [
        'tasks.task-preview.maintenance.assignments',
        !!t.assignments.length && (
            <Box>
                {t.assignments.map((a, index) => (
                    <Wrapper
                        paddingTop={8}
                        key={index}
                        isFirst={index === 0}
                        isLast={index === t.assignments.length - 1}
                    >
                        <Text as='span' size={14} weight='regular' color={Color.Grey}>
                            {a.job?.label}
                        </Text>
                        {a.job?.jobType === MaintenanceTaskType.Brushes && (
                            <MaintenanceJobAssignmentDetails assignment={a} />
                        )}
                    </Wrapper>
                ))}
            </Box>
        ),
    ],
    [
        'tasks.task-preview.maintenance.billable',
        t.billable ? translate('general.yes') : translate('general.no'),
    ],
    [
        'tasks.task-preview.maintenance.contractors',
        !!t.contractors.length && (
            <Box>
                {t.contractors.map((ch, index) => (
                    <Box key={index}>{maintenanceContractorLabel(ch)}</Box>
                ))}
            </Box>
        ),
    ],
]

interface MaintenanceJobAssignmentDetailsProps {
    assignment: MaintenanceJobAssignment
}

export const MaintenanceJobAssignmentDetails: FC<MaintenanceJobAssignmentDetailsProps> = ({
    assignment,
}) => (
    <Box paddingTop={8}>
        <AssignmentDetail>
            <DetailLabel
                value={`${translate(
                    'tasks.task-preview.maintenance.assignments.brushes-vehicle',
                )}:`}
            />
            <Box paddingLeft={4}>{assignment.brushesVehicle.label}</Box>
        </AssignmentDetail>
        <AssignmentDetail>
            <DetailLabel
                value={`${translate('tasks.task-preview.maintenance.assignments.brushes-brand')}:`}
            />
            <Box paddingLeft={4}>{assignment.brushesBrand.name}</Box>
        </AssignmentDetail>
        <AssignmentDetail>
            <DetailLabel
                value={`${translate(
                    'tasks.task-preview.maintenance.assignments.engine-last-shift-hours',
                )}:`}
            />
            <Box paddingLeft={4}>{assignment.engineLastShiftHours}</Box>
        </AssignmentDetail>
        <AssignmentDetail>
            <DetailLabel
                value={`${translate(
                    'tasks.task-preview.maintenance.assignments.brushes-last-shift-hours',
                )}:`}
            />
            <Box paddingLeft={4}>{assignment.brushesLastShiftHours}</Box>
        </AssignmentDetail>
        <AssignmentDetail>
            <DetailLabel
                value={`${translate('tasks.task-preview.maintenance.assignments.total-hours')}:`}
            />
            <Box paddingLeft={4}>{assignment.totalHours}</Box>
        </AssignmentDetail>
    </Box>
)

const DetailLabel: FC<{ value: string }> = ({ value }) => (
    <Text as='span' size={12} weight='light' color={Color.Grey}>
        {value}
    </Text>
)

const AssignmentDetail = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
    justify-content: space-between;
`

const Wrapper = styled(Box)<{ isLast: boolean; isFirst: boolean }>`
    ${p =>
        !p.isLast &&
        css`
            padding-bottom: 8px;
            border-bottom: 1px solid ${Color.Grey};
        `}
    ${p =>
        !p.isFirst &&
        css`
            padding-top: 16px;
        `}
`
