import { withDragDropContext } from 'aos-components/src/helpers/DnD'
import { TwoColumn } from 'aos-helpers/src/helpers/ColumnLayout'
import { AbstractEntity } from 'aos-services/src/services/base/types/AbstractEntity'
import {
    allEventsFilters,
    EventsFilter,
    translateEventsFilter,
} from 'aos-services/src/services/events/transport/EventsFilter'
import { allEventSortings } from 'aos-services/src/services/events/transport/EventsSorting'
import {
    allFeedInFilters,
    translateFeedInFilter,
} from 'aos-services/src/services/events/transport/FeedInPageRequest'
import { Box } from 'aos-ui/src/components/base/Box'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { PageSizeAndSortDropdown } from 'aos-ui/src/components/form/dropdown/PageSizeAndSortDropdown'
import { PageSizeDropdown } from 'aos-ui/src/components/form/dropdown/PageSizeDropdown'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import { CircleIndicator, CircleIndicatorVariant } from 'aos-ui/src/components/ui/CircleIndicator'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'

import { openEventAction } from '../../core/eventEditor/actions'
import {
    addEventToGroupAction,
    dismissEventAction,
    loadEventsAction,
    loadFeedInAction,
    reloadEventsListAction,
    reloadFeedInListAction,
    setEventsFilteringAction,
    setEventsSortingAction,
    setFeedInFilteringAction,
    syncEventManagerAction,
} from '../../core/eventManager/actions'
import { EventManagerState, feedInForAbstractEntity } from '../../core/eventManager/state'
import { tryActivateFeedInAction } from '../../core/eventModals/actions'
import { previewFeedInAction } from '../../core/eventPreview/actions'
import { resizeColumnActionFactory } from '../../core/layout/actions'
import { State } from '../../core/state'
import { EventDndList, EventList } from './EventList'

export const EventManagerComponent: FC<EventManagerProps & EventManagerDispatchProps> = ({
    columnSize,
    resizeColumn,
    syncEventManager,
    reloadEventsList,
    reloadFeedInList,
    feedInFilter,
    feedInEvents,
    loadFeedIn,
    loadEvents,
    setFeedInFiltering,
    tryActivateFeedIn,
    dismissEvent,
    previewFeedIn,
    setEventsFiltering,
    setEventsSorting,
    events,
    eventsFilter,
    eventsSorting,
    openEvent,
    addEventToGroup,
    overdueEventsCount,
}) => {
    useEffect(() => {
        syncEventManager(true)
        reloadEventsList()
        reloadFeedInList()
        return () => {
            syncEventManager(false)
        }
    }, [])

    const activateEvent = (event: AbstractEntity) => {
        tryActivateFeedIn(feedInForAbstractEntity(event, feedInEvents)!)
    }

    const formatEventFilter = (filter: EventsFilter) => {
        if (filter === EventsFilter.Overdue) {
            return (
                <Box relative>
                    {translateEventsFilter(filter)}
                    {!!overdueEventsCount && (
                        <Positioned left='100%' bottom={7}>
                            <CircleIndicator variant={CircleIndicatorVariant.Yellow}>
                                {overdueEventsCount}
                            </CircleIndicator>
                        </Positioned>
                    )}
                </Box>
            )
        }
        return translateEventsFilter(filter)
    }

    const feedInHeader = () => {
        return (
            <HeaderContainer
                title={
                    <HeaderFilter
                        items={allFeedInFilters}
                        value={feedInFilter}
                        onChange={setFeedInFiltering}
                        valueFormatter={translateFeedInFilter}
                    />
                }
                rightButton={
                    <PageSizeDropdown
                        value={feedInEvents.size}
                        onChange={(size: number) => loadFeedIn({ size })}
                    />
                }
            />
        )
    }

    const eventsHeader = () => {
        return (
            <HeaderContainer
                title={
                    <HeaderFilter
                        items={allEventsFilters}
                        value={eventsFilter}
                        onChange={setEventsFiltering}
                        valueFormatter={formatEventFilter}
                    />
                }
                rightButton={
                    <PageSizeAndSortDropdown
                        onChangeSize={size => loadEvents({ size })}
                        onChangeSort={sort => setEventsSorting(sort)}
                        pageSize={events.size}
                        sort={eventsSorting}
                        sortEntries={allEventSortings}
                        keyPrefix='events-sorting'
                    />
                }
            />
        )
    }

    return (
        <ResizableSplittedPanel
            separated
            initialRightSizes={columnSize}
            onResize={resizeColumn}
            leftMinSizes={[20]}
            rightMinSizes={[20]}
        >
            <PanelWithHeader header={feedInHeader()}>
                <EventList
                    events={feedInEvents}
                    draggableItems
                    loadItems={loadFeedIn}
                    activableItems
                    dismisableItems
                    activateEvent={activateEvent}
                    dismissEvent={dismissEvent}
                    onItemClick={(e: AbstractEntity) => previewFeedIn(e.id)}
                />
            </PanelWithHeader>
            <PanelWithHeader header={eventsHeader()} splitterRightPane>
                <EventDndList
                    events={events}
                    draggableItems={false}
                    loadItems={loadEvents}
                    droppableItems
                    activateEvent={activateEvent}
                    addEventToGroup={addEventToGroup}
                    onItemClick={(e: AbstractEntity) => openEvent(e.id)}
                    onSubItemClick={(e: AbstractEntity) => previewFeedIn(e.id)}
                />
            </PanelWithHeader>
        </ResizableSplittedPanel>
    )
}

interface EventManagerProps extends EventManagerState {
    columnSize: TwoColumn
}

interface EventManagerDispatchProps {
    tryActivateFeedIn: typeof tryActivateFeedInAction
    addEventToGroup: typeof addEventToGroupAction
    loadFeedIn: typeof loadFeedInAction
    loadEvents: typeof loadEventsAction
    dismissEvent: typeof dismissEventAction
    setFeedInFiltering: typeof setFeedInFilteringAction
    setEventsFiltering: typeof setEventsFilteringAction
    openEvent: typeof openEventAction
    previewFeedIn: typeof previewFeedInAction
    syncEventManager: typeof syncEventManagerAction
    reloadEventsList: typeof reloadEventsListAction
    reloadFeedInList: typeof reloadFeedInListAction
    setEventsSorting: typeof setEventsSortingAction
    resizeColumn(v: TwoColumn): void
}

export const EventManager = connect<EventManagerProps, EventManagerDispatchProps, {}>(
    (state: State) => ({
        ...state.eventManager,
        columnSize: state.layout.columnSizes.eventManager,
    }),
    {
        tryActivateFeedIn: tryActivateFeedInAction,
        addEventToGroup: addEventToGroupAction,
        loadFeedIn: loadFeedInAction,
        loadEvents: loadEventsAction,
        dismissEvent: dismissEventAction,
        setFeedInFiltering: setFeedInFilteringAction,
        setEventsFiltering: setEventsFilteringAction,
        openEvent: openEventAction,
        previewFeedIn: previewFeedInAction,
        syncEventManager: syncEventManagerAction,
        reloadEventsList: reloadEventsListAction,
        reloadFeedInList: reloadFeedInListAction,
        setEventsSorting: setEventsSortingAction,
        resizeColumn: resizeColumnActionFactory('eventManager'),
    },
)(withDragDropContext(EventManagerComponent))
