import React, { PureComponent } from 'react'
import styled from 'styled-components'
import urlRegex from 'url-regex'

export class TextWithLinks extends PureComponent<TextWithLinksProps> {
    public render() {
        const textWithLinks = this.props.text.replace(
            urlRegex(),
            link => `<a href="${link}" target="blank">${link}</a>`,
        )
        return <LinkWrapper dangerouslySetInnerHTML={{ __html: textWithLinks }} />
    }
}

interface TextWithLinksProps {
    text: string
}

const LinkWrapper = styled.span`
    a {
        font-weight: 400;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`
