import { singleDateModalReducer } from 'aos-components/src/components/modal/InputModal/core/singleDate/reducer'
import { singleInputModalReducer } from 'aos-components/src/components/modal/InputModal/core/singleInput/reducer'
import { singleSelectModalReducer } from 'aos-components/src/components/modal/InputModal/core/singleSelect/reducer'
import { createInitialSingleSelectModalState } from 'aos-components/src/components/modal/InputModal/core/singleSelect/state'
import { TOGGLE_EVENT_CHECKLIST_TASK } from 'aos-services/src/core/events/actions'
import { isSeverityHigh } from 'aos-services/src/services/common/types/AosSeverity'
import { NewFeedOut } from 'aos-services/src/services/events/input/NewFeedOut'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import {
    EditableChecklist,
    TaskOrMessageTemplate,
} from 'aos-services/src/services/events/types/AosEventChecklist'
import {
    completeEditableChecklistTask,
    implementEditableChecklists,
    mapChecklist,
} from 'aos-services/src/services/events/util'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { union } from 'lodash'

import {
    ADD_FEED_OUT_REQUEST,
    ADD_FEED_OUT_SUCCESS,
    CHANGE_CHECKLIST_TAB,
    CHANGE_FEED_OUT_FORM,
    CLOSE_EVENT_PARENT,
    COPY_MESSAGE_TEMPLATE,
    EventEditorAction,
    I_WILL_ARRIVE_PARENT,
    LOAD_EVENT_SUCCESS,
    REFRESH_EVENT,
    SELECT_USER_GROUP_PARENT,
    SET_ADDITIONAL_NOTE_PARENT,
    SET_FIREBASE_EVENT_SUCCESS,
    TOGGLE_EVENT_CHECKLIST,
    TOGGLE_FEEDOUT_CONFIRMATION,
    TOGGLE_USER_GROUP_CONFIRMATION,
} from './actions'
import { EventEditorState, initialEventEditorState, initialNewFeedOutState } from './state'

const isDifferedEvent = (a: AosEvent | undefined, b: AosEvent | undefined) => {
    if (a && b) {
        return a.id !== b.id
    }
    return a !== b
}

const selectPushForHighSeverityEvents = (state: NewFeedOut, event?: AosEvent) => {
    if (
        event &&
        isSeverityHigh(event.severity) &&
        state.channels.includes(AosMessageChannel.Notification)
    ) {
        return {
            ...state,
            channels: union(state.channels, [AosMessageChannel.Push]),
        }
    }
    return state
}

export function eventEditorReducer(
    state = initialEventEditorState,
    action: EventEditorAction,
): EventEditorState {
    switch (action.type) {
        case LOAD_EVENT_SUCCESS:
            const newFeedOut = isDifferedEvent(action.payload, state.event)
                ? initialNewFeedOutState
                : state.newFeedOut
            return {
                ...state,
                event: action.payload,
                newFeedOut: selectPushForHighSeverityEvents(newFeedOut, action.payload),
                editableChecklists:
                    action.payload && action.payload.checklists
                        ? implementEditableChecklists(
                              action.payload.checklists,
                              state.editableChecklists,
                          )
                        : state.editableChecklists,
            }

        case REFRESH_EVENT:
            return {
                ...state,
                event: action.payload,
            }

        case CHANGE_FEED_OUT_FORM:
            return {
                ...state,
                newFeedOut: selectPushForHighSeverityEvents(action.payload, state.event),
            }

        case TOGGLE_FEEDOUT_CONFIRMATION:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isConfirmationVisible: action.payload,
                },
            }

        case ADD_FEED_OUT_REQUEST:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isSendingFeedOut: true,
                },
            }

        case SET_ADDITIONAL_NOTE_PARENT:
            return {
                ...state,
                setAdditionalNoteState: singleInputModalReducer(
                    state.setAdditionalNoteState,
                    action.payload,
                ),
            }

        case ADD_FEED_OUT_SUCCESS:
            return {
                ...state,
                newFeedOut: selectPushForHighSeverityEvents(initialNewFeedOutState, state.event),
                ui: {
                    isSendingFeedOut: false,
                    isConfirmationVisible: false,
                },
            }

        case TOGGLE_EVENT_CHECKLIST:
            return {
                ...state,
                editableChecklists: toggleEditableChecklist(
                    state.editableChecklists!,
                    action.payload,
                ),
            }

        case TOGGLE_EVENT_CHECKLIST_TASK:
            return {
                ...state,
                editableChecklists: completeEditableChecklistTask(
                    state.editableChecklists!,
                    action.payload,
                ),
            }

        case CHANGE_CHECKLIST_TAB:
            return {
                ...state,
                editableChecklists: changeChecklistTab(
                    state.editableChecklists!,
                    action.payload[0],
                    action.payload[1],
                ),
            }

        case SET_FIREBASE_EVENT_SUCCESS:
            return {
                ...state,
                firebaseEvent: action.payload,
            }

        case SELECT_USER_GROUP_PARENT:
            return {
                ...state,
                selectUserGroupModalState: {
                    ...state.selectUserGroupModalState,
                    ...singleSelectModalReducer(
                        state.selectUserGroupModalState,
                        createInitialSingleSelectModalState(),
                        action.payload,
                    ),
                },
            }

        case TOGGLE_USER_GROUP_CONFIRMATION:
            return {
                ...state,
                selectUserGroupModalState: {
                    ...state.selectUserGroupModalState,
                    isConfirmationVisible: action.payload,
                },
            }

        case I_WILL_ARRIVE_PARENT:
            return {
                ...state,
                iWillArriveModalState: singleDateModalReducer(
                    state.iWillArriveModalState,
                    action.payload,
                ),
            }

        case CLOSE_EVENT_PARENT:
            return {
                ...state,
                closeEventModalState: singleDateModalReducer(
                    state.closeEventModalState,
                    action.payload,
                ),
            }

        case COPY_MESSAGE_TEMPLATE:
            const currentText = state.newFeedOut.text
            const newText = action.payload.content
            const nextNewFeedOut = {
                ...state.newFeedOut,
                text: currentText ? `${currentText} ${newText}` : newText,
            }

            return {
                ...state,
                newFeedOut: nextNewFeedOut,
            }

        default:
            return state
    }
}

function toggleEditableChecklist(checklists: EditableChecklist[], id: number): EditableChecklist[] {
    return checklists.map(
        mapChecklist(id, (checklist: EditableChecklist) => ({
            ...checklist,
            isCollapsed: !checklist.isCollapsed,
        })),
    )
}

function changeChecklistTab(
    checklists: EditableChecklist[],
    id: number,
    tab: TaskOrMessageTemplate,
): EditableChecklist[] {
    return checklists.map(
        mapChecklist(id, (checklist: EditableChecklist) => ({ ...checklist, tab })),
    )
}
