import React, { PureComponent } from 'react'

import { MapContext } from '../OpenlayersMapContext'

export class BaseComponent<T, U = {}> extends PureComponent<T, U> {
    public static contextType = MapContext
    // @ts-ignore
    public context: React.ContextType<typeof MapContext>

    constructor(props: T) {
        super(props)
    }

    public render(): JSX.Element | null {
        return null
    }
}
