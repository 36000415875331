import { FirebaseAppClass } from '../../dataaccess/firebase/FirebaseAppClass'
import { ListenerService } from '../common/ListenerService'

export class FirebaseServiceClass extends ListenerService {
    constructor(private fa: FirebaseAppClass) {
        super(fa)
    }

    public storage = () => this.app().storage()

    public storageRef = () => this.app().storage().ref()

    public auth = () => this.app().auth()

    protected app = () => this.fa
}
