import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'

import { AirlineTobtList, HandlerTobsList, HourTobtList } from '../cdm/types/TobtTypes'

export interface TobtState {
    hourTobt: HourTobtList
    airlineTobt: AirlineTobtList
    handlerTobs: HandlerTobsList
    tobtRanges: Array<GaugeRange>
}

export const emptyTobtData: TobtState = {
    hourTobt: [],
    airlineTobt: [],
    handlerTobs: [],
    tobtRanges: [],
}
