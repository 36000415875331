import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'

import { baggageDeliveryConfig } from './renderers/baggage/BaggageDeliveryConfig'
import { cdmConfig } from './renderers/cdm/CdmConfig'
import { delaysConfig } from './renderers/delays/DelaysConfig'
import { eventsConfig } from './renderers/events/EventsConfig'
import { FireFightersConfig } from './renderers/Firefighters/FireFightersConfig'
import { flightsConfig } from './renderers/flights/FlightsConfig'
import { newsFeedConfig } from './renderers/newsFeed/NewsFeedConfig'
import { notamConfig } from './renderers/notam/NotamConfig'
import { operationalForecastConfig } from './renderers/operationalForecast/OperationalForecastConfig'
import { paxConfig } from './renderers/pax/PaxConfig'
import { paxForecastConfig } from './renderers/paxForecast/PaxForecastConfig'
import { punctualityConfig } from './renderers/punctuality/PunctualityConfig'
import { queueingTimeBorderConfig } from './renderers/queueingTime/QueueingTimeBorderConfig'
import { queueingTimeSecurityConfig } from './renderers/queueingTime/QueueingTimeSecurityConfig'
import { regularityConfig } from './renderers/regularity/RegularityConfig'
import { restrictionsConfig } from './renderers/restrictions/RestrictionsConfig'
import { runwayConfig } from './renderers/runway/RunwayConfig'
import { ShiftNotesConfig } from './renderers/ShiftNotes/ShiftNotesConfig'
import { recentTasksConfig } from './renderers/tasks/recent/RecentTasksConfig'
import { upcomingTasksConfig } from './renderers/tasks/upcoming/UpcomingTasksConfig'
import { weatherConfig, weatherForecastConfig } from './renderers/weather/WeatherConfig'
import { DashboardItemsWeb, StatusDashboardItemConfig } from './StatusDashboardItemConfig'

export type StatusDashboardConfig = Record<DashboardItemsWeb, StatusDashboardItemConfig>

export const statusDashboardConfig: StatusDashboardConfig = {
    [DashboardItemType.FireFighters]: FireFightersConfig,
    [DashboardItemType.ShiftNotes]: ShiftNotesConfig,
    [DashboardItemType.Weather]: weatherConfig,
    [DashboardItemType.WeatherForecast]: weatherForecastConfig,
    [DashboardItemType.Events]: eventsConfig,
    [DashboardItemType.Runways]: runwayConfig,
    [DashboardItemType.NewsFeed]: newsFeedConfig,
    [DashboardItemType.Pax]: paxConfig,
    [DashboardItemType.Flights]: flightsConfig,
    [DashboardItemType.Delays]: delaysConfig,
    [DashboardItemType.Punctuality]: punctualityConfig,
    [DashboardItemType.Cdm]: cdmConfig,
    [DashboardItemType.BaggageDelivery]: baggageDeliveryConfig,
    [DashboardItemType.Regularity]: regularityConfig,
    [DashboardItemType.PaxForecast]: paxForecastConfig,
    [DashboardItemType.OperationalForecast]: operationalForecastConfig,
    [DashboardItemType.QueueingTimeBorder]: queueingTimeBorderConfig,
    [DashboardItemType.QueueingTimeSecurity]: queueingTimeSecurityConfig,
    [DashboardItemType.Restrictions]: restrictionsConfig,
    [DashboardItemType.RecentTasks]: recentTasksConfig,
    [DashboardItemType.UpcomingTasks]: upcomingTasksConfig,
    [DashboardItemType.NOTAM]: notamConfig,
}
