import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import { TaskStatus } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface TaskIconProps {
    category: TaskCategory
    iconVariant?: IconVariant
    iconSize?: BlockSize
}

export const taskIcon: Record<TaskCategory, Svg> = {
    [TaskCategory.Animals]: SvgIcon.TaskAnimals,
    [TaskCategory.Maintenance]: SvgIcon.TaskMaintenance,
    [TaskCategory.DeIcing]: SvgIcon.TaskDeicing,
    [TaskCategory.RescueService]: SvgIcon.TaskRescueService,
    [TaskCategory.Inspection]: SvgIcon.TaskSafetyInspection,
    [TaskCategory.LVP]: SvgIcon.TaskLvp,
    [TaskCategory.HBA]: SvgIcon.TaskHba,
}

export const taskIconVariant: Record<TaskStatus, IconVariant> = {
    [TaskStatus.Todo]: IconVariant.White,
    [TaskStatus.InProgress]: IconVariant.White,
    [TaskStatus.OnHold]: IconVariant.White,
    [TaskStatus.Done]: IconVariant.UiBlack4,
}

export const TaskIcon: FC<TaskIconProps> = ({
    category,
    iconVariant,
    iconSize = BlockSize.Std,
}) => <Icon iconSize={iconSize} svg={taskIcon[category]} iconVariant={iconVariant} />
