import { Values } from 'aos-helpers/src/helpers/Object'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin } from 'aos-helpers/src/helpers/TimeRange'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { filterToRoundedTimeRange } from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import {
    FilterOptionAll,
    StringFilterWithAllOption,
    translateStringFilterWithAllOption,
} from 'aos-services/src/services/statusDashboard/types/filters/common'
import {
    PaxForecastArrivalDepartureFilter,
    paxForecastArrivalDepartureFilterValues,
    translatePaxArrivalDepartureFilter,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import {
    PaxForecastDayViewType,
    translatePaxForecastDayViewType,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastDayViewType'
import {
    getApplicablePaxForecastFilters,
    PaxForecastFilters,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastFilters'
import {
    PaxForecastLocalOrTransferFilter,
    paxForecastLocalOrTransferFilterValues,
    translatePaxLocalOrTransferFilter,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastLocalOrTransferFilter'
import {
    PaxForecastTimeRange,
    translatePaxForecastTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastTimeRange'
import { RoundDatePicker } from 'aos-ui/src/components/form/datetime/RoundDatePicker'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabDefinition, RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { EnumValues } from 'enum-values'
import React, { PureComponent, ReactElement } from 'react'

import { Footer } from '../../base/Footer'

interface PaxForecastFooterProps {
    filters: PaxForecastFilters
    terminals: string[]
    setFilterAction(v: Partial<PaxForecastFilters>): void
}

type TabType = Values<Omit<PaxForecastFilters, 'customDate'>>

export class PaxForecastFooter extends PureComponent<PaxForecastFooterProps> {
    public render() {
        return (
            <Footer>
                <MultiRoundTabs items={this.tabsConfig} />
            </Footer>
        )
    }

    private get tabsConfig(): RoundTabDefinition<TabType>[] {
        const visibleFilters = getApplicablePaxForecastFilters(this.props.filters)

        return [
            this.terminalTabConfig,
            this.timeRangeTabConfig,
            visibleFilters.includes('customDate') && this.customDatePicker,
            visibleFilters.includes('dayViewType') && this.dayViewTabConfig,
            visibleFilters.includes('arrivalDeparture') && this.arrDepTabConfig,
            visibleFilters.includes('localOrTransfer') && this.localTransferTabConfig,
        ]
    }

    private get terminalTabConfig(): RoundTabsProps<StringFilterWithAllOption> {
        return {
            items: [FilterOptionAll.All, ...this.props.terminals],
            value: this.props.filters.terminal,
            name: 'pax-forecast-terminal-tab',
            formatter: translateStringFilterWithAllOption(
                'dashboard.pax-forecast.filters.terminal',
            ),
            onChange: this.changeTerminal,
            tooltip: translate('dashboard.pax-forecast.filters.terminal.tooltip'),
            width: DropdownWidth.Medium,
        }
    }

    private get timeRangeTabConfig(): RoundTabsProps<PaxForecastTimeRange> {
        return {
            items: EnumValues.getValues<PaxForecastTimeRange>(PaxForecastTimeRange),
            value: this.props.filters.timeRange,
            name: 'pax-forecast-time-range-tab',
            formatter: translatePaxForecastTimeRange,
            onChange: this.changeTimeRange,
            tooltip: translate('dashboard.pax-forecast.filters.time-range.tooltip'),
            width: DropdownWidth.Medium,
        }
    }

    private get customDatePicker(): ReactElement {
        return (
            <RoundDatePicker
                key='dateTime'
                marginRight={30}
                value={dateTime(this.props.filters.customDate)}
                onChange={this.changeCustomDate}
                isValidDate={date =>
                    isTimeWithin(
                        date,
                        filterToRoundedTimeRange(
                            PaxForecastTimeRange.Next6Weeks,
                            dateTime(),
                            'days',
                        ),
                    )
                }
            />
        )
    }

    private get dayViewTabConfig(): RoundTabsProps<PaxForecastDayViewType> {
        return {
            items: EnumValues.getValues(PaxForecastDayViewType),
            value: this.props.filters.dayViewType,
            name: 'pax-forecast-day-view-type-tab',
            formatter: translatePaxForecastDayViewType,
            onChange: this.changeDayViewType,
            tooltip: translate('dashboard.pax-forecast.filters.day-view-type.tooltip'),
            width: DropdownWidth.Medium,
        }
    }

    private get arrDepTabConfig(): RoundTabsProps<PaxForecastArrivalDepartureFilter> {
        return {
            items: paxForecastArrivalDepartureFilterValues,
            value: this.props.filters.arrivalDeparture,
            name: 'pax-forecast-arrival-or-departures-tab',
            formatter: translatePaxArrivalDepartureFilter,
            onChange: this.changeArrivalDeparture,
            tooltip: translate('dashboard.pax-forecast.filters.arrival-or-departure.tooltip'),
            width: DropdownWidth.Medium,
        }
    }

    private get localTransferTabConfig(): RoundTabsProps<PaxForecastLocalOrTransferFilter> {
        return {
            items: paxForecastLocalOrTransferFilterValues,
            value: this.props.filters.localOrTransfer,
            name: 'pax-forecast-local-or-transfer-tab',
            formatter: translatePaxLocalOrTransferFilter,
            onChange: this.changeLocalOrTransfer,
            tooltip: translate('dashboard.pax-forecast.filters.local-or-transfer.tooltip'),
            width: DropdownWidth.Medium,
        }
    }

    private changeTerminal = (terminal: StringFilterWithAllOption) =>
        this.props.setFilterAction({ terminal })
    private changeTimeRange = (timeRange: PaxForecastTimeRange) =>
        this.props.setFilterAction({ timeRange })
    private changeCustomDate = (customDate: DateTime | undefined) => {
        if (customDate) {
            this.props.setFilterAction({ customDate: customDate.valueOf() })
        }
    }
    private changeDayViewType = (dayViewType: PaxForecastDayViewType) =>
        this.props.setFilterAction({ dayViewType })
    private changeLocalOrTransfer = (localOrTransfer: PaxForecastLocalOrTransferFilter) =>
        this.props.setFilterAction({ localOrTransfer })
    private changeArrivalDeparture = (arrivalDeparture: PaxForecastArrivalDepartureFilter) =>
        this.props.setFilterAction({ arrivalDeparture })
}
