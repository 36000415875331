import { BaseRestService } from '../base/BaseRestService'
import { RestrictionDto } from './types/RestrictionDto'

class RestrictionsRestService extends BaseRestService {
    public getAllRestrictions(): Promise<RestrictionDto[]> {
        return this.getAndTransform<RestrictionDto[]>(
            '/airport-status/restrictions',
            BaseRestService.jsonTransformer,
        )
    }
}

export const restrictionsRestService = new RestrictionsRestService()
