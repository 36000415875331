import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Duration } from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'

interface LateInspectionLabelProps {
    duration: Duration
}

export const LateInspectionLabel: FC<LateInspectionLabelProps> = ({ duration }) => {
    const formattedDuration = duration.format('[-]h[H] mm[MIN]', { trim: false })
    return (
        <Box row>
            <Ticker />
            <Text color={Color.Orange} size={8} weight='bold'>
                {formattedDuration}
            </Text>
        </Box>
    )
}

const Ticker = styled.div`
    margin-right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${Color.Orange};
`
