import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { Location } from 'aos-ui/src/components/form/location/Location'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import React, { PureComponent } from 'react'

import { EventModalSimpleFormValidationState } from '../../../core/eventModals/state'
import { EventFormProps } from './EventFormProps'

export class EventSimpleForm extends PureComponent<
    EventFormProps<EventModalSimpleFormValidationState>
> {
    public render() {
        return (
            <Box
                className='scrollable'
                fullHeight
                flex={1}
                paddingHorizontal={30}
                paddingVertical={20}
            >
                {this.renderDescription()}
                {this.renderLocation()}
                {this.renderAttachments()}
            </Box>
        )
    }

    private renderAttachments = () => (
        <LabeledFormElement
            label={translate('create-event-modal.attachments.label')}
            marginBottom={12}
        >
            <AttachmentContainer
                attachmentsState={this.props.attachmentsState}
                attachmentsAction={this.props.attachmentsAction}
                editable
                gridSize={4}
            />
        </LabeledFormElement>
    )

    private renderLocation = () => {
        const { location, resetLocation } = this.props
        const navigateToMap = () => this.props.setViewMode(DialogView.Map)
        return (
            <LabeledFormElement
                label={translate('create-event-modal.location.label')}
                marginBottom={12}
            >
                <Location
                    location={location?.location}
                    onEdit={navigateToMap}
                    onClear={resetLocation}
                />
            </LabeledFormElement>
        )
    }

    private renderDescription = () => (
        <LabeledTextArea
            keyPrefix='create-event-modal.description'
            marginBottom={12}
            onChangeText={description => this.props.changeForm({ description })}
            value={this.props.currentEvent.description}
            resizeable
        />
    )
}
