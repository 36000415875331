import { Page } from 'aos-helpers/src/helpers/Pageable'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskPageable } from 'aos-services/src/services/tasks/types/TaskPageable'
import { taskIsEqual, TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { Text } from 'aos-ui/src/components/base/Text'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { TasksListItem } from './TasksListItem'

interface TasksProps {
    tasks: TaskPageable
    selectedTask?: Task
    onChangePage(page: Page): void
    onOpen(t: TaskSnapshot): void
}

export const TasksList: FC<TasksProps> = ({ tasks, onChangePage, onOpen, selectedTask }) => {
    if (tasks.totalElements === 0) {
        return <PanelPlaceholder label={translate('tasks.no-tasks')} svg={SvgIcon.Assignment} />
    }

    const isLastPage = tasks.totalPages === tasks.number + 1

    return (
        <PaginationList
            page={tasks}
            onChange={onChangePage}
            paddingHorizontal={30}
            paddingTop={30}
            paddingBottom={20}
        >
            {tasks.content.map((t, index) => (
                <TasksListItem
                    task={t}
                    key={index}
                    isSelected={taskIsEqual(t, selectedTask)}
                    onOpen={() => onOpen(t)}
                />
            ))}

            {isLastPage && (
                <Text size={12} color={Color.Grey} paddingBottom={16} centered>
                    {translate('tasks.query-limit-info')}
                </Text>
            )}
        </PaginationList>
    )
}
