import {
    LayoutAction,
    RESIZE_COLUMN,
    RESIZE_EVENT_EDITOR_COLUMN,
    SET_EVENT_MANAGER_DOT,
    TOGGLE_MENU,
    TOGGLE_WHATS_NEW,
} from './actions'
import { ColumnState, initialLayoutState, LayoutState } from './state'

export function layoutReducer(state = initialLayoutState, action: LayoutAction): LayoutState {
    const updateColumnSizes = (updated: f.Func1<ColumnState, ColumnState>) => ({
        ...state,
        columnSizes: updated(state.columnSizes),
    })

    switch (action.type) {
        case TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen,
            }

        case TOGGLE_WHATS_NEW:
            return {
                ...state,
                showWhatsNewModal: action.payload,
            }

        case SET_EVENT_MANAGER_DOT:
            return {
                ...state,
                isEventManagerDotVisible: action.payload,
            }

        case RESIZE_COLUMN:
            return updateColumnSizes((columnSizes: ColumnState) => ({
                ...columnSizes,
                [action.payload[0]]: action.payload[1] as any,
            }))

        case RESIZE_EVENT_EDITOR_COLUMN:
            return updateColumnSizes((columnSizes: ColumnState) => ({
                ...columnSizes,
                eventEditor: {
                    ...columnSizes.eventEditor,
                    [action.payload[0]]: action.payload[1] as any,
                },
            }))

        default:
            return state
    }
}
