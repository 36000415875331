import { cx } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Gauge } from 'aos-ui/src/components/gauge/Gauge'
import { GaugePlaceholder } from 'aos-ui/src/components/gauge/GaugePlaceholder'
import { GaugeText } from 'aos-ui/src/components/gauge/GaugeText'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

interface Props {
    tobtValue: number | undefined
    horizontal?: boolean
    title: string
    tooltip?: string
    description: string
    ranges: GaugeRange[]
}

const CdmGaugeTobt = (props: Props) => {
    const { horizontal, title, tooltip, description, ranges } = props

    const renderGauge = () => {
        const { tobtValue } = props

        if (tobtValue === undefined || !ranges.length) {
            return <GaugePlaceholder noDataText={translate('dashboard.cdm.no-data')} />
        }

        return (
            <Gauge currentValue={tobtValue} ranges={getRanges()}>
                {renderValue(tobtValue)}
            </Gauge>
        )
    }

    const renderValue = (value: number) => (size: number) => (
        <GaugeText size={size} unit='AVG'>
            {value}
        </GaugeText>
    )

    const getRanges = () => {
        return (ranges as unknown) as GaugeRange[]
    }

    return (
        <Box
            className={cx('container container--justify-center full-height cdm-widget__item', {
                'container--vertical': !horizontal,
                'container--horizontal-reversed': horizontal,
            })}
        >
            <Box
                style={{ width: '140px' }}
                className={cx('text__m-white', {
                    'text--center flex-shrink--0': !horizontal,
                    'text--left': horizontal,
                })}
            >
                {title}
                <Tooltip placement='top' body={tooltip} withArrow>
                    <div>
                        <Text paddingTop={4} color={Color.Grey} size={12}>
                            {description}
                        </Text>
                    </div>
                </Tooltip>
            </Box>
            <Box className={cx(!horizontal && 'flex--auto')}>{renderGauge()}</Box>
        </Box>
    )
}

export default CdmGaugeTobt
