import TileLayer from 'ol/layer/Tile'
import { EventTypes } from 'ol/Observable'
import RenderEvent from 'ol/render/Event'
import OSM from 'ol/source/OSM'

import { BaseLayer, BaseLayerProps } from '../base/BaseLayer'

export class OSMLightLayer extends BaseLayer {
    constructor(props: BaseLayerProps) {
        super(
            props,
            new TileLayer({
                source: new OSM({
                    maxZoom: 19,
                }),
                preload: Infinity,
            }),
        )

        this.layer.on(['postrender' as EventTypes], evt => {
            const castedEvt = evt as RenderEvent
            const context = castedEvt.context as CanvasRenderingContext2D
            context.fillStyle = '#031122'
            context.globalCompositeOperation = 'color'
            context.fillRect(0, 0, context.canvas.width, context.canvas.height)
            context.globalCompositeOperation = 'source-over'
        })
    }
}
