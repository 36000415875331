import { dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosUserGroupDto } from '../../../dataaccess/groups/types/AosUserGroupDto'
import { AosUserGroup } from './AosUserGroup'
import { AosUserGroupType } from './AosUserGroupType'

export class AosUserGroupImpl extends WrapperObject<AosUserGroupDto> implements AosUserGroup {
    constructor(value: AosUserGroupDto) {
        super(value)
    }

    public get userCount() {
        return this.value.count
    }

    public get name() {
        return this.value.name
    }

    public get id() {
        return this.value.id
    }

    public get modifiedBy() {
        return this.value.modifiedBy
    }

    public get modifiedAt() {
        return dateTimeFromOptional(this.value.modifiedAt)
    }

    public get type() {
        return this.value.type as AosUserGroupType
    }
}
