import React, { FC, PropsWithChildren } from 'react'

export const markerSize = 66

export const MarkerMapIcon: FC<PropsWithChildren<EventMapIconProps>> = props => {
    const { color, iconColor } = props
    return (
        <svg
            width={markerSize}
            height={markerSize}
            viewBox='0 0 66 66'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <defs>
                <rect id='b' x='1' y='1' width='48' height='48' rx='7' />
                <filter
                    x='-26%'
                    y='-22%'
                    width='152%'
                    height='152%'
                    filterUnits='objectBoundingBox'
                    id='a'
                >
                    <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
                    <feGaussianBlur
                        stdDeviation='4'
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                    />
                    <feComposite
                        in='shadowBlurOuter1'
                        in2='SourceAlpha'
                        operator='out'
                        result='shadowBlurOuter1'
                    />
                    <feColorMatrix
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0'
                        in='shadowBlurOuter1'
                    />
                </filter>
            </defs>
            <style>
                {
                    // tslint:disable-next-line: jsx-use-translation-function
                    'svg svg path, svg svg rect, svg svg g:not(.raw), svg svg circle { fill: inherit;}'
                }
            </style>
            <g fill='none' fillRule='evenodd'>
                <g transform='translate(8 6)'>
                    <use fill='#000' filter='url(#a)' xlinkHref='#b' />
                    <use fill={color} xlinkHref='#b' />
                    <rect stroke='#FFF' strokeWidth='2' x='1' y='1' width='48' height='48' rx='7' />
                </g>
                <path fill='#FFF' d='M33 64l-5-10h10' />
                <g transform='translate(21 21)' fill={iconColor}>
                    {props.children}
                </g>
            </g>
        </svg>
    )
}

interface EventMapIconProps {
    color: string
    iconColor: string
}
