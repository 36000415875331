import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Box, BoxProps } from '../../base/Box'
import { ThemeVariant } from '../../base/ThemeVariant'
import { PreviewableOverlay, PreviewableOverlayBox } from './PreviewableOverlay'

const variantStyles: Record<ThemeVariant, CSSObject> = {
    [ThemeVariant.White]: {
        border: `1px solid ${Color.Grey2}`,
        background: Color.White,
        borderRadius: '5px',
    },
    [ThemeVariant.Black]: {
        background: Color.UiBlack3,
    },
}

interface AttachmentContainerItem2Props extends BoxProps {
    variant: ThemeVariant
    previewable?: boolean
    previewText?: ReactNode
    onClick?(): void
    onDelete?(): void
}

export const AttachmentContainerItem: FCWithChildren<AttachmentContainerItem2Props> = ({
    children,
    onClick,
    onDelete,
    previewText,
    previewable,
    variant,
    ...boxProps
}) => (
    <PreviewableOverlayWrapper variant={variant} {...boxProps}>
        {children}
        {previewable && (
            <PreviewableOverlay
                onClick={onClick}
                onDelete={onDelete}
                previewText={previewText}
                variant={variant}
            />
        )}
    </PreviewableOverlayWrapper>
)

const PreviewableOverlayWrapper = styled(Box)<{ variant: ThemeVariant }>`
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    ${PreviewableOverlayBox} {
        display: none;
    }

    :hover ${PreviewableOverlayBox} {
        display: block;
    }
    ${p => variantStyles[p.variant]}
`
