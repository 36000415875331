import { dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosUserDto } from '../../../dataaccess/users/types/AosUserDto'
import { AosBaseUserGroupImpl } from './AosBaseUserGroupImpl'
import { UserTaskPermissions } from './AosUser'
import { AosUserType } from './AosUserType'

export abstract class AosUserBaseImpl<T extends AosUserDto> extends WrapperObject<T> {
    protected constructor(protected value: T) {
        super(value)
    }

    public get groups() {
        return this.value.groups
            ? this.value.groups.map(group => new AosBaseUserGroupImpl(group))
            : []
    }

    public get type() {
        return this.value.origin as AosUserType
    }

    public get id() {
        return this.value.id
    }

    public get name() {
        return this.value.name
    }

    public get company() {
        return this.value.company
    }

    public get role() {
        return this.value.role
    }

    public get email() {
        return this.value.email
    }

    public get phone() {
        return this.value.phoneNumber
    }

    public get siteLocation() {
        return this.value.siteLocation
    }

    public get localisation() {
        return this.value.localisation
    }

    public get taskPermissions() {
        return this.value.taskPermissions as UserTaskPermissions
    }

    public get createdAt() {
        return dateTimeFromOptional(this.value.createdAt)
    }

    public get modifiedAt() {
        return dateTimeFromOptional(this.value.modifiedAt)
    }

    get isEventCommander() {
        return this.value.isEventCommander
    }
}
