import { appConfig } from 'appConfig'
import Keycloak from 'keycloak-js'

const webKeycloakConfig = new Keycloak({
    url: appConfig.keycloakConfig.url,
    realm: appConfig.keycloakConfig.realm,
    clientId: appConfig.keycloakConfig.clientId,
})

export default webKeycloakConfig
