import { CurrentTimeChart } from 'aos-components/src/components/chart/CurrentTimeChart'
import { calculateTimeDomain } from 'aos-helpers/src/helpers/domain/Domain'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import {
    WeatherSeries,
    WeatherValueSeries,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherSeriesDefinition } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesDefinition'
import {
    translateChartWeatherSeriesType,
    WeatherOtherSeriesType,
    WeatherValueSeriesType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import {
    getWeatherTicksCountForWeatherTimeRange,
    WeatherTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { ChartScaleType } from 'aos-ui-common/src/components/chart/types/Chart'
import React, { PureComponent } from 'react'

import { blockChartConfig, mainChartConditionsConfig } from '../WeatherConsts'
import { getWeatherColorForWeatherSeries } from '../WeatherSettings'
import {
    lvpProbabilityGradientSeriesConfig,
    probabilityGradientSeriesConfig,
    WeatherGradientChart,
} from './WeatherGradientChart'
import { WeatherMainChart } from './WeatherMainChart'
import { WeatherPhenomenonChart } from './WeatherPhenomenonChart'

const commonLineSeriesConfigByType = (type: WeatherValueSeriesType) => ({
    color: getWeatherColorForWeatherSeries(type),
    withDots: true,
    isCurved: true,
    tooltip: (point: TimeValuePoint) => translateChartWeatherSeriesType(type, point.value),
})

const getWeatherLineSeriesDefinition = (
    weatherSeries: WeatherValueSeries,
): WeatherSeriesDefinition => ({
    [WeatherValueSeriesType.Temperature]: {
        data: weatherSeries[WeatherValueSeriesType.Temperature] || [],
        config: commonLineSeriesConfigByType(WeatherValueSeriesType.Temperature),
        scale: ChartScaleType.Y1,
        valueGetter: (d: TimeValuePoint) => d.value,
    },
    [WeatherValueSeriesType.DewPoint]: {
        data: weatherSeries[WeatherValueSeriesType.DewPoint] || [],
        config: commonLineSeriesConfigByType(WeatherValueSeriesType.DewPoint),
        scale: ChartScaleType.Y1,
        valueGetter: (d: TimeValuePoint) => d.value,
    },
})

const getWeatherBarSeriesDefinition = (
    weatherSeries: WeatherValueSeries,
): WeatherSeriesDefinition => ({
    [WeatherValueSeriesType.Rain]: {
        data: weatherSeries[WeatherValueSeriesType.Rain] || [],
        config: commonLineSeriesConfigByType(WeatherValueSeriesType.Rain),
        scale: ChartScaleType.Y2,
        valueGetter: (d: TimeValuePoint) => d.value,
    },
    [WeatherValueSeriesType.Snow]: {
        data: weatherSeries[WeatherValueSeriesType.Snow] || [],
        config: commonLineSeriesConfigByType(WeatherValueSeriesType.Snow),
        scale: ChartScaleType.Y2,
        valueGetter: (d: TimeValuePoint) => d.value,
    },
    [WeatherValueSeriesType.RainAndSnow]: {
        data: weatherSeries[WeatherValueSeriesType.RainAndSnow] || [],
        config: commonLineSeriesConfigByType(WeatherValueSeriesType.RainAndSnow),
        scale: ChartScaleType.Y2,
        valueGetter: (d: TimeValuePoint) => d.value,
    },
})

export class WeatherConditionsChart extends PureComponent<WeatherConditionsChartProps> {
    public render() {
        const chartLineSeriesDefinition = getWeatherLineSeriesDefinition(this.props.weatherSeries)
        const chartBarSeriesDefinition = getWeatherBarSeriesDefinition(this.props.weatherSeries)
        const lVPGradientSeriesData =
            this.props.weatherSeries[WeatherValueSeriesType.LvpProbability]
        const thunderstormGradientSeriesData =
            this.props.weatherSeries[WeatherValueSeriesType.ThunderstormProbability]
        const phenomenonSeriesData =
            this.props.weatherSeries[WeatherOtherSeriesType.FreezingWeatherPhenomenon]
        const temporaryPhenomenonSeriesData =
            this.props.weatherSeries[WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon]

        const timeTicksCount = getWeatherTicksCountForWeatherTimeRange(this.props.timeRangeTab)
        const xDomain = calculateTimeDomain(
            chartLineSeriesDefinition[WeatherValueSeriesType.Temperature]?.data ?? [],
            timeTicksCount,
        )

        return (
            <>
                <WeatherMainChart
                    chartConfig={mainChartConditionsConfig}
                    lineSeriesDefinition={chartLineSeriesDefinition}
                    barSeriesDefinition={chartBarSeriesDefinition}
                    xDomain={xDomain}
                    units={{
                        y1: translate('dashboard.weather.unit-tabs.degC'),
                        y2: translate('dashboard.weather.unit-tabs.mmh'),
                    }}
                />
                {lVPGradientSeriesData.length > 0 && (
                    <WeatherGradientChart
                        seriesConfig={lvpProbabilityGradientSeriesConfig}
                        name='lvp-chart'
                        type={WeatherValueSeriesType.LvpProbability}
                        chartConfig={blockChartConfig}
                        seriesData={lVPGradientSeriesData}
                        xDomain={xDomain}
                        unit={translate('dashboard.weather.unit-tabs.prob-lvp')}
                    />
                )}
                {this.props.isHel && (
                    <>
                        {thunderstormGradientSeriesData.length > 0 && (
                            <WeatherGradientChart
                                seriesConfig={probabilityGradientSeriesConfig}
                                name='thunderstorm-chart'
                                chartConfig={blockChartConfig}
                                seriesData={thunderstormGradientSeriesData}
                                type={WeatherValueSeriesType.ThunderstormProbability}
                                xDomain={xDomain}
                                unit={translate('dashboard.weather.unit-tabs.prob')}
                            />
                        )}
                        {phenomenonSeriesData && (
                            <WeatherPhenomenonChart
                                name='weather-phenomenon'
                                chartConfig={blockChartConfig}
                                seriesData={phenomenonSeriesData}
                                xDomain={xDomain}
                                type={WeatherOtherSeriesType.FreezingWeatherPhenomenon}
                            />
                        )}
                        {temporaryPhenomenonSeriesData && (
                            <WeatherPhenomenonChart
                                name='temporary-weather-phenomenon'
                                chartConfig={blockChartConfig}
                                seriesData={temporaryPhenomenonSeriesData}
                                xDomain={xDomain}
                                type={WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon}
                            />
                        )}
                    </>
                )}
                <CurrentTimeChart margins={blockChartConfig.margins} domain={xDomain.domain} />
            </>
        )
    }
}

export interface WeatherConditionsChartProps {
    weatherSeries: WeatherSeries
    unitTab: WeatherUnit
    timeRangeTab: WeatherTimeRange
    isHel: boolean
}
