import { lonLatToCoordinate } from 'aos-helpers/src/helpers/coordinate/CoordinateTransformer'
import { isDefined } from 'aos-helpers/src/helpers/Function'
import {
    RingRailTrainMapProperties,
    ringRailTrainToRingRailTrainMapProperties,
} from 'aos-services/src/services/layerData/properties/RingRailTrainMapProperties'
import { RingRailTrain } from 'aos-services/src/services/layerData/types/RingRailTrain'
import {
    isRingRailTrainPositionDifferent,
    isRingRailTrainPositionEqual,
} from 'aos-services/src/services/layerData/types/RingRailTrainPosition'
import {
    SelectedMapElementType,
    selectionTargetKey,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import { differenceWith, intersectionWith } from 'lodash'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { getStyleForTrain } from '../../styleBuilder/ringRailTrainStyleBuilder'
import { BaseLayerProps } from '../base/BaseLayer'
import { BaseMarkerLayer } from '../base/BaseMarkerLayer'

export class RingRailLayer extends BaseMarkerLayer<RingRailLayerProps> {
    constructor(props: RingRailLayerProps) {
        super(props)
    }

    protected updateMarkers(nextProps: RingRailLayerProps, prevProps?: RingRailLayerProps) {
        const next = nextProps.trainLocations
        const prev = prevProps ? prevProps.trainLocations : []
        const itemsToDelete = differenceWith(prev, next, isRingRailTrainPositionEqual)
        const itemsToAdd = differenceWith(next, prev, isRingRailTrainPositionEqual)
        const itemsToUpdate = intersectionWith(next, prev, isRingRailTrainPositionDifferent)

        itemsToDelete.forEach(e => this.removeFeatureById(e.trainNumber))
        itemsToUpdate.forEach(e => this.updateMarkerPosition(e as RingRailTrain))
        Promise.all(itemsToAdd.map(this.getNewFeatureForTrain)).then(items =>
            items.filter(isDefined).map((f: Feature) => this.markerSource.addFeature(f)),
        )
    }

    private getNewFeatureForTrain = (train: RingRailTrain): Promise<Feature | undefined> => {
        if (train.position) {
            const isSelected = this.isTrainSelected(train)
            return getStyleForTrain([train, isSelected]).then(style => {
                const point = new Point(lonLatToCoordinate(train.position!))
                const feature = new Feature(point)
                feature.setStyle(style)
                feature.setId(train.trainNumber)
                feature.set(selectionTargetKey, this.props.selectionTarget)
                feature.setProperties(
                    { train: ringRailTrainToRingRailTrainMapProperties(train) },
                    true,
                )
                return feature
            })
        }
        return Promise.resolve(undefined)
    }

    private updateMarkerPosition = (train: RingRailTrain) => {
        const marker = this.markerSource.getFeatureById(train.trainNumber) as Feature<Point>
        if (marker && train.position) {
            const isSelected = this.isTrainSelected(train)
            getStyleForTrain([train, isSelected]).then(style => {
                const point = new Point(lonLatToCoordinate(train.position!))
                marker.setGeometry(point)
                marker.setStyle(style)
                marker.setProperties(
                    { train: ringRailTrainToRingRailTrainMapProperties(train) },
                    true,
                )
            })
        }
    }

    private isTrainSelected = (train: RingRailTrain) =>
        !!this.props.selectedTrain && this.props.selectedTrain.trainNumber === train.trainNumber
}

interface RingRailLayerProps extends BaseLayerProps {
    trainLocations: RingRailTrain[]
    selectionTarget?: SelectedMapElementType
    selectedTrain?: RingRailTrainMapProperties
}
