import { DateTime } from 'aos-helpers/src/helpers/Time'
import { getTrendData, TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { getTrend, Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { mapValues } from 'lodash'

import { BaggageDeliveryStat } from '../../../../../services/airportStatus/baggage/types/BaggageDeliveryStat'
import { BaggageStatSection } from '../../../../../services/airportStatus/baggage/types/BaggageStatSection'
import {
    distribution,
    DistributionValue,
    DistributionWithTrendStat,
} from '../../../../../services/common/types/DistributionValue'
import { getTimeStats, meanDuration } from '../../../../../services/common/types/Stats'
import { flightFilterService } from '../../../../../services/flightInformation/FlightFilterService'
import { Flight } from '../../../../../services/flightInformation/types/Flight'
import { groupFlightsBySection } from './common'

const deliveryTimeDistributionStats = (
    currentFlights: Flight[],
    previousFlights: Flight[],
    now: DateTime,
): Record<BaggageStatSection, DistributionWithTrendStat> => {
    const currentDistribution = distribution(groupFlightsBySection(currentFlights, now))
    const previousDistribution = distribution(groupFlightsBySection(previousFlights, now))

    return mapValues<Record<BaggageStatSection, DistributionValue>, DistributionWithTrendStat>(
        currentDistribution,
        (v: DistributionValue, k: string) => ({
            ...v,
            trend: getTrend(v.percentage, previousDistribution[k as BaggageStatSection].percentage),
        }),
    )
}

const meanDeliveryTimeTrend = (
    currentFlights: Flight[],
    previousFlights: Flight[],
    now: DateTime,
): Trend => {
    const currentMean = meanDuration(
        flightFilterService.flightsToDeliveryTimes(currentFlights, now),
    ).asMinutes()
    const previousMean = meanDuration(
        flightFilterService.flightsToDeliveryTimes(previousFlights, now),
    ).asMinutes()
    return getTrend(currentMean, previousMean)
}

export const statsForPeriod = (
    allFlights: Flight[],
    period: TimeRange,
    now: DateTime,
): BaggageDeliveryStat => {
    const allFlightsFromPeriod = flightFilterService.getFlightsFromPeriod(allFlights, period)
    const allFlightsWithBags = flightFilterService.getFlightsWithBags(allFlights)
    const flightsWithBags = getTrendData((flight: Flight) => flight.sdt)(allFlightsWithBags, period)

    return {
        distributionStats: deliveryTimeDistributionStats(
            flightsWithBags.current,
            flightsWithBags.previous,
            now,
        ),
        times: getTimeStats(flightsWithBags.current.map(f => f.bag.deliveryTime(now))),
        meanTrend: meanDeliveryTimeTrend(flightsWithBags.current, flightsWithBags.previous, now),
        flightCounts: {
            all: allFlightsFromPeriod.length,
            applicable: flightsWithBags.current.length,
        },
    }
}
