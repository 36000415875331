import { firebaseRestService } from '../../dataaccess/firebase/firebaseRestService'
import { firebaseService } from '../firebase/firebaseService'

class FirebaseAuthService {
    public assureUserLoggedIn = async () => {
        if (firebaseService.auth().currentUser) {
            return Promise.resolve()
        } else {
            const token = await firebaseRestService.getAuthToken()
            return await firebaseService.auth().signInWithCustomToken(token)
        }
    }

    public logout = () => {
        if (firebaseService.auth().currentUser) {
            return firebaseService.auth().signOut()
        }
        return Promise.resolve()
    }
}

export const firebaseAuthService = new FirebaseAuthService()
