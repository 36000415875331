import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { MapAction, MapActionCreators, mapActionCreators } from 'aos-ui-map/src/core/actions'

export interface PreviewEventSuccessPayload {
    event: AosEvent
    openOnMap: boolean
}

export interface PreviewEventPayload {
    eventId: number
    openOnMap: boolean
}

export const PREVIEW_EVENT_LOAD_SUCCESS = 'EVENT_PREVIEW/PREVIEW_EVENT_LOAD_SUCCESS'
export type PreviewEventLoadSuccessAction = PayloadAction<
    typeof PREVIEW_EVENT_LOAD_SUCCESS,
    PreviewEventSuccessPayload
>
export const previewEventLoadSuccessAction = payloadActionCreator<
    PreviewEventLoadSuccessAction,
    PreviewEventSuccessPayload
>(PREVIEW_EVENT_LOAD_SUCCESS)

export const PREVIEW_EVENT_REFRESH = 'EVENT_PREVIEW/PREVIEW_EVENT_REFRESH'
export type PreviewEventRefreshAction = PayloadAction<typeof PREVIEW_EVENT_REFRESH, number>
export const previewEventRefreshAction = payloadActionCreator<PreviewEventRefreshAction, number>(
    PREVIEW_EVENT_REFRESH,
)

export const PREVIEW_EVENT_REFRESH_SUCCESS = 'EVENT_PREVIEW/PREVIEW_EVENT_REFRESH_SUCCESS'
export type PreviewEventRefreshSuccessAction = PayloadAction<
    typeof PREVIEW_EVENT_REFRESH_SUCCESS,
    AosEvent
>
export const previewEventRefreshSuccessAction = payloadActionCreator<
    PreviewEventRefreshSuccessAction,
    AosEvent
>(PREVIEW_EVENT_REFRESH_SUCCESS)

export const PREVIEW_EVENT_HIDE_MODAL = 'EVENT_PREVIEW/PREVIEW_EVENT_HIDE_MODAL'
export type PreviewEventHideModalAction = Action<typeof PREVIEW_EVENT_HIDE_MODAL>
export const previewEventHideModalAction = emptyActionCreator<PreviewEventHideModalAction>(
    PREVIEW_EVENT_HIDE_MODAL,
)

export const PREVIEW_FEED_IN = 'EVENT_PREVIEW/PREVIEW_FEED_IN'
export type PreviewFeedInAction = PayloadAction<typeof PREVIEW_FEED_IN, number>
export const previewFeedInAction = payloadActionCreator<PreviewFeedInAction, number>(
    PREVIEW_FEED_IN,
)

export const PREVIEW_EVENT = 'EVENT_PREVIEW/PREVIEW_EVENT'
export type PreviewEventAction = PayloadAction<typeof PREVIEW_EVENT, PreviewEventPayload>
export const previewEventAction = payloadActionCreator<PreviewEventAction, PreviewEventPayload>(
    PREVIEW_EVENT,
)

export const PREVIEW_FEED_IN_SUCCESS = 'EVENT_PREVIEW/PREVIEW_FEED_IN_SUCCESS'
export type PreviewFeedInSuccessAction = PayloadAction<typeof PREVIEW_FEED_IN_SUCCESS, AosFeedIn>
export const previewFeedInSuccessAction = payloadActionCreator<
    PreviewFeedInSuccessAction,
    AosFeedIn
>(PREVIEW_FEED_IN_SUCCESS)

export const SET_DIALOG_SUB_CONTENT = 'EVENT_PREVIEW/SET_DIALOG_SUB_CONTENT'
export type SetDialogSubContentAction = PayloadAction<typeof SET_DIALOG_SUB_CONTENT, DialogView>
export const setDialogSubContentAction = payloadActionCreator<
    SetDialogSubContentAction,
    DialogView
>(SET_DIALOG_SUB_CONTENT)

export const EVENT_PREVIEW_MAP_PARENT = 'EVENT_PREVIEW/EVENT_PREVIEW_MAP_PARENT'
export type EventPreviewMapParentAction = PayloadAction<typeof EVENT_PREVIEW_MAP_PARENT, MapAction>
export const eventPreviewMapParentAction = payloadActionCreator<
    EventPreviewMapParentAction,
    MapAction
>(EVENT_PREVIEW_MAP_PARENT)
export const eventPreviewMapAction: MapActionCreators = parentActionWrapper(
    eventPreviewMapParentAction,
    mapActionCreators,
)

export type EventPreviewActions =
    | PreviewEventLoadSuccessAction
    | PreviewEventRefreshAction
    | PreviewEventRefreshSuccessAction
    | PreviewEventHideModalAction
    | PreviewFeedInAction
    | PreviewFeedInSuccessAction
    | EventPreviewMapParentAction
    | SetDialogSubContentAction
