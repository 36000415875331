import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { FlightLoadSuccessAction } from 'aos-services/src/core/flightInformation/actions'
import { TowingActions, TowingLoadSuccessAction } from 'aos-services/src/core/towings/actions'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { SendLinkPayload } from 'aos-services/src/services/mailing/mailingService'

import { FlightFilters } from './flight/flightFiltersState'
import { FlightSort, SortingRule } from './state'
import { TowingFilters } from './towing/towingFiltersState'

export const UPDATE_FILTERS = 'FLIGHT_INFORMATION/UPDATE_FILTERS'
export type UpdateFiltersAction = PayloadAction<
    typeof UPDATE_FILTERS,
    [FlightListType, Partial<FlightFilters | TowingFilters>]
>
export const updateFiltersAction = payloadActionCreator<
    UpdateFiltersAction,
    [FlightListType, Partial<FlightFilters | TowingFilters>]
>(UPDATE_FILTERS)

export const updateArrivalsFiltersAction = (filters: Partial<FlightFilters>) =>
    updateFiltersAction([FlightListType.Arrivals, filters])

export const updateDeparturesFiltersAction = (filters: Partial<FlightFilters>) =>
    updateFiltersAction([FlightListType.Departures, filters])

export const updateTowingFiltersAction = (filters: Partial<TowingFilters>) =>
    updateFiltersAction([FlightListType.Towing, filters])

export const SAVE_LAST_FILTERS = 'FLIGHT_INFORMATION/SAVE_LAST_FILTERS'
export type SaveLastFiltersAction = PayloadAction<
    typeof SAVE_LAST_FILTERS,
    [FlightListType, Partial<FlightFilters | TowingFilters>]
>
export const saveLastFiltersAction = payloadActionCreator<
    SaveLastFiltersAction,
    [FlightListType, Partial<FlightFilters | TowingFilters>]
>(SAVE_LAST_FILTERS)

export const RESTORE_LAST_FILTERS = 'FLIGHT_INFORMATION/RESTORE_LAST_FILTERS'
export type RestoreLastFiltersAction = PayloadAction<typeof RESTORE_LAST_FILTERS, FlightListType>
export const restoreLastFiltersAction = payloadActionCreator<SaveLastFiltersAction, FlightListType>(
    RESTORE_LAST_FILTERS,
)

export const SHOW_CUSTOMIZE_MODAL = 'FLIGHT_INFORMATION/SHOW_CUSTOMIZE_MODAL'
export type ShowCustomizeModalAction = PayloadAction<typeof SHOW_CUSTOMIZE_MODAL, boolean>
export const showCustomizeModalAction = payloadActionCreator<ShowCustomizeModalAction, boolean>(
    SHOW_CUSTOMIZE_MODAL,
)

export const SHOW_SHARE_MODAL = 'FLIGHT_INFORMATION/SHOW_SHARE_MODAL'
export type ShowShareModalAction = PayloadAction<typeof SHOW_SHARE_MODAL, boolean>
export const showShareModalAction = payloadActionCreator<ShowShareModalAction, boolean>(
    SHOW_SHARE_MODAL,
)

export const SEND_SHARE_EMAIL = 'FLIGHT_INFORMATION/SEND_SHARE_EMAIL'
export type SendShareEmailAction = PayloadAction<typeof SEND_SHARE_EMAIL, SendLinkPayload>
export const sendShareEmailAction = payloadActionCreator<SendShareEmailAction, SendLinkPayload>(
    SEND_SHARE_EMAIL,
)

export const SEND_SHARE_EMAIL_SUCCESS = 'FLIGHT_INFORMATION/SEND_SHARE_EMAIL_SUCCESS'
export type SendShareEmailSuccessAction = Action<typeof SEND_SHARE_EMAIL_SUCCESS>
export const sendShareEmailSuccessAction =
    emptyActionCreator<SendShareEmailSuccessAction>(SEND_SHARE_EMAIL_SUCCESS)

export const LOCK_NOW = 'FLIGHT_INFORMATION/LOCK_NOW'
export type LockNowAction = PayloadAction<typeof LOCK_NOW, boolean>
export const lockNowAction = payloadActionCreator<LockNowAction, boolean>(LOCK_NOW)

export const CHANGE_SORTING = 'FLIGHT_INFORMATION/CHANGE_SORTING'
export type ChangeSortingAction = PayloadAction<
    typeof CHANGE_SORTING,
    { type: FlightSort; value: Partial<SortingRule> }
>
export const changeSortingAction = payloadActionCreator<
    ChangeSortingAction,
    { type: FlightSort; value: Partial<SortingRule> }
>(CHANGE_SORTING)

export const SET_QUICK_SEARCH = 'FLIGHT_INFORMATION/SET_QUICK_SEARCH'
export type SetQuickSearchAction = PayloadAction<typeof SET_QUICK_SEARCH, string>
export const setQuickSearchAction = payloadActionCreator<SetQuickSearchAction, string>(
    SET_QUICK_SEARCH,
)

export type FlightInformationAction =
    | FlightLoadSuccessAction
    | TowingLoadSuccessAction
    | TowingActions
    | UpdateFiltersAction
    | SaveLastFiltersAction
    | RestoreLastFiltersAction
    | ShowCustomizeModalAction
    | ShowShareModalAction
    | SendShareEmailAction
    | SendShareEmailSuccessAction
    | LockNowAction
    | ChangeSortingAction
    | SetQuickSearchAction
