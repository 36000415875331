import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isApocSelector, isApronControlSelector } from 'aos-services/src/core/auth/state'
import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { Link } from 'aos-ui/src/components/text/Link'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { showMapLocationAction } from '../../../core/restrictions/actions'

interface RestrictionPreviewProps
    extends RestrictionPreviewStateProps,
        RestrictionPreviewDispatchProps {
    restriction: Restriction
}

export const RestrictionPreviewClass: FC<RestrictionPreviewProps> = props => {
    const { restriction, isApoc, isApronControl, showMapLocation } = props

    const navigateToRestriction = () => showMapLocation(restriction)

    const locationField = () => (
        <Link onClick={navigateToRestriction} as='span'>
            {translate('restrictions.preview.select-location')}
        </Link>
    )

    const items = [
        ['restrictions.preview.description', restriction.aosMessage],
        ...(isApoc || isApronControl ? [['restrictions.preview.apoc', restriction.message]] : []),
        [
            'restrictions.preview.date-time',
            `${formatDateTime(restriction.startDate)} - ${formatDateTime(restriction.endDate)}`,
        ],
        ['restrictions.preview.location', locationField()],
    ] as PropsListEntry[]

    return (
        <Container paddingLeft={20}>
            <Text size={14} paddingBottom={8}>
                {translate('restrictions.preview.restriction')}
            </Text>
            <PropsList
                items={items}
                variant={ThemeVariant.Black}
                size={LabeledTextElementSize.Small}
            />
        </Container>
    )
}

const Container = styled(Box)`
    box-shadow: inset 5px 0px 0px -1px ${Color.Yellow};
`

interface RestrictionPreviewDispatchProps {
    showMapLocation: typeof showMapLocationAction
}

interface RestrictionPreviewStateProps {
    isApoc: boolean
    isApronControl: boolean
}

export const RestrictionPreview = connect<
    RestrictionPreviewStateProps,
    RestrictionPreviewDispatchProps
>(
    createStructuredSelector({
        isApoc: isApocSelector,
        isApronControl: isApronControlSelector,
    }),
    {
        showMapLocation: showMapLocationAction,
    },
)(RestrictionPreviewClass)
