import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DropdownPortalType } from 'aos-ui/src/components/form/dropdown/base/DropdownPortal'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { identity } from 'lodash'
import React, { ReactNode } from 'react'

interface TableDropdownProps<T> {
    items: T[]
    value?: T
    placeholder?: string
    valueRenderer?(v: T): ReactNode
    onChange?(v?: T): void
}

export const TableDropdown = <T extends any>(props: TableDropdownProps<T>) => {
    const { value, items } = props
    const clearAction = () => {
        if (props.onChange) {
            props.onChange(undefined)
        }
    }
    return (
        <Dropdown
            items={items}
            value={value}
            variant={DropdownVariant.Black}
            width={DropdownWidth.Auto}
            valueRenderer={props.valueRenderer || identity}
            portalType={DropdownPortalType.BottomLeft}
            placeholder={props.placeholder ?? translate('filter.placeholder.all')}
            toggleVisible={false}
            onChange={props.onChange}
            clearAction={clearAction}
        />
    )
}
