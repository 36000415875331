import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { ReportsUnit } from '../../../../core/tasks/reports/state'

interface UnitToggleProps {
    value: ReportsUnit
    onChange(unit: ReportsUnit): void
}

export const UnitToggle = ({ value, onChange }: UnitToggleProps) => {
    return (
        <Box row>
            <Text size={12} color={Color.White} marginRight={6}>
                {ReportsUnit.Kg}
            </Text>
            <Toggle
                onChange={active => onChange(active ? ReportsUnit.L : ReportsUnit.Kg)}
                value={value === ReportsUnit.L}
            />
            <Text size={12} color={Color.White} marginLeft={6}>
                {ReportsUnit.L}
            </Text>
        </Box>
    )
}
