import { isResponseError } from 'aos-helpers/src/helpers/error/ResponseError'

import { BaseRestService } from '../../dataaccess/base/BaseRestService'

export const registerUnauthorizedHandler = (onUnauthorized: f.Action) => {
    BaseRestService.errorHandlers.push((error: Error) => {
        if (isResponseError(error) && !error.handled) {
            if (error.originalResponse.status === 401) {
                onUnauthorized()
                error.handled = true
            }
        }
        return Promise.reject(error)
    })
}
