import { RESTRICTIONS_LOAD_SUCCESS } from 'aos-services/src/core/restrictions/actions'
import {
    initialRestrictionsState,
    RestrictionsState,
} from 'aos-services/src/core/restrictions/state'

import { RestrictionsAction } from './actions'

export function restrictionsReducer(
    state = initialRestrictionsState,
    action: RestrictionsAction,
): RestrictionsState {
    switch (action.type) {
        case RESTRICTIONS_LOAD_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }

        default:
            return state
    }
}
