import { dateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate, formatDayLong, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ShiftNotesDto } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesListDto'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { StatusTile } from '../../common/StatusTile'

interface ShiftNotesTileProps {
    item: ShiftNotesDto
}

export const ShiftNotesTile: FC<ShiftNotesTileProps> = ({ item }) => {
    const date = dateTime(item.createdAt)

    return (
        <StatusTile
            status='neutral' // TODO
            title={
                <Box row>
                    <Box paddingRight={16} row>
                        <Text size={14} color={Color.White} paddingRight={8}>
                            {`${formatDayLong(date)}  ${formatDate(date)}  ${formatTime(date)}`}
                        </Text>
                    </Box>
                    <Box>
                        <Text size={14} color={Color.Grey}>
                            {translate('shift-notes.list.reported-by') + ' ' + item.reporterName }
                        </Text>
                    </Box>
                </Box>
            }
        >
            <Box padding={20}>
                <Text size={18} color={Color.White}>
                    {item.message}
                </Text>
            </Box>
        </StatusTile>
    )
}
