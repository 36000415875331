import { enumHasValue, isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { searchStringsContain } from 'aos-helpers/src/helpers/Filters'
import { dateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { FlightDto } from '../../../dataaccess/flightInformation/types/FlightDto'
import { AosAirport } from './AosAirport'
import { AosFlightBeltStatusImpl } from './AosFlightBeltStatusImpl'
import { AosHandlingAgent } from './AosHandlingAgent'
import { FlightNatureCode } from './AosNatureOfFlight'
import { PublicRemarkCode } from './AosPublicRemark'
import {
    AosNatureOfFlight,
    Flight,
    FlightAirline,
    FlightGate,
    FlightPark,
    FlightPrm,
    FlightRoute,
} from './Flight'
import { getAirportName, getPrmDateText } from './FlightInfoFormatter'
import { FlightListType } from './FlightListType'

const NBSP = '\xa0'

export class FlightImpl extends WrapperObject<FlightDto> implements Flight {
    public id = this.value.flightId
    public sdt = dateTime(this.value.sdt)

    public hapt = this.value.hapt as AosAirport
    public prfix = this.value.prfix
    public sffix = this.value.sffix

    public fltnr = this.value.fltnr
    public nr = this.value.prfix + NBSP + this.value.sffix
    public cflight = this.value.cflight

    public flightType = this.value.flightType as FlightListType
    public acreg = this.value.acreg
    public actype = this.value.actype
    public mfltnr = this.value.mfltnr
    public routes = this.value.routes as FlightRoute[]
    public park = this.value.park as FlightPark
    public gate = this.value.gate as FlightGate
    public irmsg = this.value.irmsg
    public prm = this.value.prm as FlightPrm

    public tobt = dateTimeFromOptional(this.value.tobt)
    public est = dateTimeFromOptional(this.value.est)
    public pest = dateTimeFromOptional(this.value.pest)
    public appr = dateTimeFromOptional(this.value.appr)
    public act = dateTimeFromOptional(this.value.act)
    public ablk = dateTimeFromOptional(this.value.ablk)
    public eibt = dateTimeFromOptional(this.value.eibt)
    public tsat = dateTimeFromOptional(this.value.tsat)

    public departureTime = this.ablk || this.act

    public bltarea = this.value.bltarea
    public belt = this.value.belt
    public belt2 = this.value.belt2
    public bltInd = this.value.bltInd

    public fibag = dateTimeFromOptional(this.value.fibag)
    public labag = dateTimeFromOptional(this.value.labag)

    public paxTotal = this.value.paxTotal
    public callsign = this.value.callsign
    public chkarea = this.value.chkarea
    public chkdsk = this.value.chkdsk
    public door = this.value.door
    public rwy = this.value.rwy
    public airline = this.value.airline as FlightAirline
    public key = this.value.key
    public cancelled = this.value.cancelled
    public aircraft = this.value.aircraft

    public get handl(): AosHandlingAgent | undefined {
        let { handl } = this.value
        if (!handl) {
            return undefined
        }
        //RT or RTG is RTG Ground Handling Ltd which is a part of Airpro
        //https://siilisolutions.slack.com/archives/G5BFKU7AS/p1615458162017300?thread_ts=1615305697.000700&cid=G5BFKU7AS
        if (handl === 'RT' || handl === 'RTG') {
            handl = AosHandlingAgent.APR
        }
        return isValidEnumEntry(AosHandlingAgent)<AosHandlingAgent>(handl)
            ? handl
            : AosHandlingAgent.Unknown
    }

    private hasFlightNatureCode = enumHasValue(FlightNatureCode)

    get naflt(): AosNatureOfFlight {
        const value = (this.value.naflt ?? FlightNatureCode.Unknown).substr(0, 2)
        const code = this.hasFlightNatureCode(value) ? (value as FlightNatureCode) : undefined
        return { code, value }
    }

    public quickMatch(query?: string) {
        const csValues = this.cflight
        const routes = this.routes.map(r => r.airportName)
        const routeCode = this.routes.map(r => r.airport)
        const sourceStrings = [
            getAirportName(this.hapt),
            getPrmDateText(this as any),
            ...routes,
            ...routeCode,
            ...csValues,
            this.fltnr,
            this.acreg,
            this.handl,
            this.callsign,
        ]
        return searchStringsContain(sourceStrings, query)
    }

    private isEstimated = () =>
        [PublicRemarkCode.E, PublicRemarkCode.Eta, PublicRemarkCode.Est].includes(this.prm.code)

    public get isDelayedBasedOnEstimated() {
        const { est, sdt } = this
        if (est) {
            const estUnix = est.unix()
            const sdtUnix = sdt.unix()
            return estUnix && this.isEstimated() ? estUnix > sdtUnix : false
        }
        return false
    }

    public get isHel() {
        return this.hapt === AosAirport.HEL
    }

    public bag = new AosFlightBeltStatusImpl(this.value)
}
