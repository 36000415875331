import React, { PropsWithChildren, PureComponent } from 'react'

import { cx } from '../../base/ClassNames'
import { ActionModalContent, ActionModalContentProps } from './ActionModalContent'

export class ActionModalTextContent extends PureComponent<
    PropsWithChildren<ActionModalTextContentProps>
> {
    public render() {
        return (
            <ActionModalContent
                className='action-modal-text-content container--align-center'
                titleClassName='text--center text__xxl-black'
                {...this.props}
            >
                {this.props.description && (
                    <div
                        className={cx(
                            this.props.descriptionClassName
                                ? { [this.props.descriptionClassName]: true }
                                : 'text__l-grey-txt',
                            'padding-top--small text__m-grey text--center',
                        )}
                    >
                        {this.props.description}
                    </div>
                )}
                {this.props.children}
            </ActionModalContent>
        )
    }
}

export interface ActionModalTextContentProps extends ActionModalContentProps {
    description?: string | JSX.Element
    descriptionClassName?: string
}
