import { Sortable } from 'aos-components/src/components/dnd/Sortable'
import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { removeAt, updateAt } from 'aos-helpers/src/helpers/Array'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    OperationalForecastGroup,
    OperationalForecastMode,
    OperationalItem,
    translateOperationalForecastGroup,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { IconBoxButton, IconBoxButtonVariant } from 'aos-ui/src/components/buttons/IconBoxButton'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import { EnumValues } from 'enum-values'
import React, { FC, useEffect, useState } from 'react'

import { OperationalForecastEditItem } from './OperationalForecastEditItem'

interface OperationalForecastEditModalProps {
    items: OperationalItem[]
    isOpen: boolean
    onSave(v: OperationalItem[]): void
    onClose(): void
}

interface IndexedItem {
    item: OperationalItem
    index: number
}

export const OperationalForecastEditModal: FC<OperationalForecastEditModalProps> = ({
    items,
    onSave,
    onClose,
    isOpen,
}) => {
    const toIndexed = (t: OperationalItem[]) => t.map((item, index) => ({ item, index }))
    const toBare = (t: IndexedItem[]) => t.map(item => item.item)

    const [group, setGroup] = useState<OperationalForecastGroup>(OperationalForecastGroup.Passenger)
    const [currentItems, setItems] = useState<IndexedItem[]>(toIndexed(items))
    useEffect(() => {
        setItems(toIndexed(items))
    }, [items])

    const tabs = EnumValues.getValues<OperationalForecastGroup>(OperationalForecastGroup)
    const tabItems = currentItems.filter(i => i.item.group === group)
    const changeItem = (indexedItem: IndexedItem) => {
        setItems(updateAt(currentItems, indexedItem.index, () => indexedItem))
    }
    const handleSave = () => {
        onSave(toBare(currentItems))
    }
    const handleRemove = (item: IndexedItem) => {
        setItems(removeAt(currentItems, item.index))
    }
    const handleChangeOrder = (newItems: IndexedItem[]) => {
        const currentIndexes = tabItems.map(i => i.index)
        const newBareItems = toBare(currentItems.filter(i => !currentIndexes.includes(i.index)))
        const newGroupItems = toBare(newItems)
        setItems(toIndexed([...newBareItems, ...newGroupItems]))
    }
    const handleAdd = () => {
        setItems([
            ...currentItems,
            {
                item: { mode: OperationalForecastMode.Warning, title: '', group, description: '' },
                index: currentItems.length,
            },
        ])
    }
    return (
        <WhiteModal
            size={ModalKind.Standard}
            isOpen={isOpen}
            closeAction={onClose}
            title={translate('dashboard.operational-forecast-edit-modal.title')}
            footer={
                <FormButton
                    label={translate('dashboard.operational-forecast-edit-modal.save')}
                    onClick={handleSave}
                />
            }
            footerSeparator
        >
            <Box
                className='border-bottom--light-grey'
                row
                justify='space-between'
                paddingHorizontal={30}
                paddingTop={20}
            >
                <UnderlineTabs
                    className='flex--1'
                    items={tabs}
                    value={group}
                    onChange={setGroup}
                    nameFormatter={translateOperationalForecastGroup}
                    underlined={false}
                />
                <IconBoxButton
                    svg={SvgIcon.ZoomIn}
                    onClick={handleAdd}
                    variant={IconBoxButtonVariant.Blue}
                />
            </Box>
            <LightScrollbar>
                <Sortable
                    items={tabItems}
                    onChange={handleChangeOrder}
                    renderItem={(indexedItem, index) => (
                        <OperationalForecastEditItem
                            item={indexedItem.item}
                            onChange={newItem =>
                                changeItem({ item: newItem, index: indexedItem.index })
                            }
                            onRemove={() => handleRemove(indexedItem)}
                            last={index === tabItems.length - 1}
                        />
                    )}
                    getKey={i => `${i.index}`}
                    lockAxis='y'
                    helperClass='dnd-white-box--dragging'
                    useDragHandle
                />
            </LightScrollbar>
        </WhiteModal>
    )
}
