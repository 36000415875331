import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { RestrictionsSyncWrapper } from './RestrictionsSyncWrapper'

interface RestrictionsItem {
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const RestrictionsItem: FC<PropsWithChildren<RestrictionsItem>> = props => (
    <RestrictionsSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.restrictions.title')}
            description={translate('dashboard.restrictions.description')}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </RestrictionsSyncWrapper>
)
