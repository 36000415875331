import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { Temperature } from './Temperature'
import { WindDirection } from './WindDirection'
import { WindGusts } from './WindGusts'
import { WindSpeed } from './WindSpeed'

interface WeatherMetricsProps {
    unitTab: WeatherUnit
    temperature?: number
    windSpeed?: number
    windDirection?: number
    windGusts?: number
}

export const WeatherMetrics: FC<WeatherMetricsProps> = props => {
    const { unitTab, temperature, windSpeed, windDirection, windGusts } = props
    return (
        <Box className='dashboard-weather__metrics' flex={1} row>
            <Temperature value={temperature} />
            <WindSpeed value={windSpeed} unit={unitTab} />
            <WindDirection value={windDirection} />
            <WindGusts value={windGusts} unit={unitTab} />
        </Box>
    )
}
