import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { BoxProps } from 'aos-ui/src/components/base/Box'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import React, { FCWithChildren } from 'react'

import { ThemeVariant } from '../../base/ThemeVariant'
import { DatetimeInput, DatetimeInputProps } from '../datetime/DatetimeInput'

interface LabeledDatetimeInputProps extends BoxProps, DatetimeInputProps {
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    errorMessage?: string
    inputVariant?: ThemeVariant
}

export const LabeledDatetimeInput: FCWithChildren<LabeledDatetimeInputProps> = ({
    keyPrefix,
    isRequired,
    isError,
    errorMessage,
    inputVariant = ThemeVariant.White,
    seleniumLocation,

    onChange,
    onBlur,
    mode = 'datetime',
    hideClear,
    icon,
    value,
    placeholder,
    timeRangeStart,
    isValidDate,
    viewMode,
    roundMinutesTo,
    timeConstraints,

    ...marginProps
}) => {
    const translateSuffix = (key: string) => translate(`${keyPrefix}.${key}`)

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={errorMessage ?? translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={inputVariant}
            {...marginProps}
        >
            <DatetimeInput
                value={value}
                placeholder={translateSuffix('placeholder')}
                onChange={onChange}
                onBlur={onBlur}
                timeConstraints={timeConstraints}
                mode={mode}
                hideClear={hideClear}
                timeRangeStart={timeRangeStart}
                isValidDate={isValidDate}
                viewMode={viewMode}
                roundMinutesTo={roundMinutesTo}
            />
        </LabeledFormElement>
    )
}
