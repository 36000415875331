import { Dictionary, fromPairs, mapValues, toPairs, uniq } from 'lodash'

export type Values<T> = T[keyof T]

export const defaultGetter = <T, U>(
    getter: f.Func1<T, U | undefined>,
    defGetter: f.Func1<T, U>,
) => (o: T) => {
    const value = getter(o)
    return value ? value : defGetter(o)
}

export const mapObjectValues = <T extends object, TResult>(
    obj: T | null | undefined,
    callback: (value: T[keyof T], key: string) => TResult,
): { [P in keyof T]: TResult } => mapValues<T, TResult>(obj, (v, k) => callback(v, k))

export const mergeDictionaries = <T extends object, U>(
    a: Dictionary<T>,
    b: Dictionary<T>,
    merger: f.Func2<T | undefined, T | undefined, U>,
): Dictionary<U> => {
    const result: Dictionary<U> = {}
    const keys = uniq([...Object.keys(a), ...Object.keys(b)])

    keys.forEach(k => {
        result[k] = merger(a[k], b[k])
    })

    return result
}

export const filterEntries = <V>(
    obj: Dictionary<V>,
    predicate: (value: V, key: string) => boolean,
) => {
    const filteredEntries = toPairs(obj).filter(([key, value]) => predicate(value, key))
    return fromPairs(filteredEntries)
}
