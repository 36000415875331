import { identity } from 'lodash'

import { FlightInfoSelectedFeature } from '../flightInformation/types/FlightInfoFilters'
import { gateToGatesArray } from '../layerData/properties/GatesProperties'
import { SelectedMapElementType } from '../map/types/SelectedMapElement'
import { Restriction } from './types/Restriction'
import { RestrictionFilters, toRestrictionAreaFilter } from './types/RestrictionFilters'
import { RestrictionResourceType } from './types/RestrictionResourceType'

class RestrictionFilterService {
    public getFilteredRestrictions = (restrictions: Restriction[], filters: RestrictionFilters) => {
        const byAreas = filters.checkboxFilters.areas.length
            ? this.filterByAreas(filters.checkboxFilters.areas)
            : identity

        return restrictions.filter(byAreas)
    }

    public findRestrictionForFeature = (
        restrictions: Restriction[],
        feature: FlightInfoSelectedFeature,
    ): Restriction | undefined => {
        return restrictions.find(restriction => {
            switch (feature.type) {
                case SelectedMapElementType.Gate:
                case SelectedMapElementType.BusGate:
                    return (
                        restriction.resourceType !== RestrictionResourceType.Stand &&
                        gateToGatesArray(feature.payload).includes(restriction.resourceNumber)
                    )
                case SelectedMapElementType.Stand:
                    return (
                        restriction.resourceType === RestrictionResourceType.Stand &&
                        feature.payload.teksti === restriction.resourceNumber
                    )
                default:
                    return false
            }
        })
    }

    private filterByAreas = (areas: string[]) => (restriction: Restriction) => {
        return areas.includes(toRestrictionAreaFilter(restriction))
    }
}
export const restrictionFilterService = new RestrictionFilterService()
