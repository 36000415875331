import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { initialTimelineState, TimelineState } from 'aos-services/src/core/timeline/state'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { AosEventProcessType } from 'aos-services/src/services/events/types/AosEventProcessType'
import { AosTimelineEvent } from 'aos-services/src/services/events/types/AosTimelineEvent'

export interface EventTimelineState {
    events: AosTimelineEvent[]
    timeline: TimelineState
    collapsedGroups: AosEventProcessType[]
    loadedRange?: TimeRange
    filter: EventTimelineFilter
}

export interface EventTimelineStateAware {
    eventTimeline: EventTimelineState
}

export interface EventTimelineFilter {
    selectedSeverities: AosSeverity[]
}

export const initialEventTimelineFilterState: EventTimelineFilter = {
    selectedSeverities: [],
}

export const initialEventTimelineState: EventTimelineState = {
    events: [],
    collapsedGroups: [],
    timeline: initialTimelineState,
    filter: initialEventTimelineFilterState,
}
