import { Action } from 'aos-helpers/src/helpers/ActionCreator'
import { optionalToArray } from 'aos-helpers/src/helpers/Array'
import { PropsWithChildren, PureComponent } from 'react'
import { connect } from 'react-redux'

/**
 * SyncWrapper is a component that dispatches actions when it mounts and unmounts.
 * It is determined to be removed in future and replaced with useSyncEffect.
 */

interface SyncWrapperDispatchProps {
    dispatch(v: Action<any>): void
}

interface SyncWrapperProps extends SyncWrapperDispatchProps {
    onEnter?: Action<any> | Action<any>[]
    onLeave?: Action<any> | Action<any>[]
}

class SyncWrapperClass extends PureComponent<PropsWithChildren<SyncWrapperProps>> {
    public render() {
        return this.props.children || null
    }

    public componentDidMount() {
        const { onEnter, dispatch } = this.props
        const onEnterActions = optionalToArray(onEnter)
        onEnterActions.forEach(dispatch)
    }

    public componentWillUnmount(): void {
        const { onLeave, dispatch } = this.props
        const onLeaveAction = optionalToArray(onLeave)
        onLeaveAction.forEach(dispatch)
    }
}

export const SyncWrapper = connect<{}, SyncWrapperDispatchProps>(null, dispatch => ({ dispatch }))(
    SyncWrapperClass,
)
