import {
    createCollectionSyncActionCreator,
    SyncActionType,
} from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'
import { syncCollection } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { currentUserFirebaseIdSelector } from 'aos-services/src/core/auth/state'
import { firebaseNotificationRepository } from 'aos-services/src/dataaccess/notifications/firebaseNotificationRepository'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { FirebaseUid } from 'aos-services/src/services/firebase/types/FirebaseUid'
import { notificationDtoToNotification } from 'aos-services/src/services/notifications/AosNotificationFactory'
import { AosNotification } from 'aos-services/src/services/notifications/types/Notification'
import { PRIVATE_CHANNEL_INVITE } from 'aos-services/src/services/notifications/types/PrivateChannelInviteNotification'
import { call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import Alarm from '../../../assets/sounds/alarm.mp3'
import { createPlaySoundSaga } from '../helpers/sound'
import {
    CLOSE_NOTIFICATIONS_CONTAINER,
    LOAD_MORE,
    loadMoreSuccessAction,
    MARK_AS_READ,
    MarkAsReadAction,
    markAsReadAction,
    NOTIFICATIONS_SYNC_SUCCESS,
    NotificationsSyncSuccessAction,
    notificationsSyncSuccessAction,
    OPEN_NOTIFICATIONS_CONTAINER,
    PIN_NOTIFICATIONS_CONTAINER,
    setNotificationsSuccessAction,
    SYNC_NOTIFICATIONS,
} from './actions'
import { getAllNotificationCountSelector, getUnreadNotificationIDsSelector } from './state'

const markAsReadTime = 2 * 1000
const uiRefreshDelay = 100

function* syncNotificationsSaga() {
    yield takeEvery(SYNC_NOTIFICATIONS, function* () {
        const firebaseUid: FirebaseUid = yield select(currentUserFirebaseIdSelector)
        yield* firebaseService.onListener('notifications', function* () {
            yield put(setNotificationsSuccessAction([]))
            // listen for changes on collection
            yield syncCollection(
                firebaseNotificationRepository.notificationsRef(firebaseUid),
                createCollectionSyncActionCreator(
                    notificationsSyncSuccessAction,
                    notificationDtoToNotification(false),
                ),
            )
        }) as Generator
    })
}

function* syncNotificationSuccessSaga() {
    yield takeEvery<NotificationsSyncSuccessAction>(NOTIFICATIONS_SYNC_SUCCESS, function* (action) {
        const [typeOfChange, notifications] = action.payload
        if (
            typeOfChange === SyncActionType.Add &&
            notifications.length <= 2 &&
            notifications.some(q => q.type === PRIVATE_CHANNEL_INVITE && q.hardAlert)
        ) {
            yield createPlaySoundSaga()(Alarm)
        }
    })
}

function* markAsReadSaga() {
    function* markAsReadInternal(action: MarkAsReadAction) {
        yield delay(markAsReadTime)
        const firebaseUid: FirebaseUid = yield select(currentUserFirebaseIdSelector)
        yield call(firebaseNotificationRepository.markAllAsRead, firebaseUid, action.payload)
    }

    yield takeEvery<MarkAsReadAction>(MARK_AS_READ, function* (action: MarkAsReadAction) {
        yield race({
            markAsRead: call(markAsReadInternal, action),
            hide: take(CLOSE_NOTIFICATIONS_CONTAINER),
        })
    })
}

export function* openNotificationsContainerSaga() {
    yield takeEvery(OPEN_NOTIFICATIONS_CONTAINER, function* () {
        const notificationIds: string[] = yield select(getUnreadNotificationIDsSelector)
        yield put(markAsReadAction(notificationIds))
    })
}

export function* pinNotificationsContainerSaga() {
    yield takeLatest(PIN_NOTIFICATIONS_CONTAINER, function* resizeWindow() {
        // TODO Find some better solution maybe?
        yield delay(uiRefreshDelay)
        yield window.dispatchEvent(new Event('resize'))
    })
}

function* loadMoreSaga() {
    yield takeEvery(LOAD_MORE, function* () {
        const firebaseUid: FirebaseUid = yield select(currentUserFirebaseIdSelector)
        const notificationsCount: number = yield select(getAllNotificationCountSelector)
        const newNotifications: AosNotification[] = yield call(
            firebaseNotificationRepository.loadNotificationsPage,
            firebaseUid,
            notificationsCount,
        )
        if (newNotifications.length) {
            yield put(loadMoreSuccessAction(newNotifications))
        }
    })
}

export const notificationsSagas = [
    syncNotificationsSaga,
    markAsReadSaga,
    openNotificationsContainerSaga,
    pinNotificationsContainerSaga,
    loadMoreSaga,
    syncNotificationSuccessSaga,
]
