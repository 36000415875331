import { SortDirection, SortEntry } from 'aos-helpers/src/helpers/Pageable'
import { EnumValues } from 'enum-values'
import { isEqual, toPairs } from 'lodash'

import { EventsFilter } from './EventsFilter'
// backend enum
export enum EventsSortingRequestParams {
    BySeverity = 'severity',
    ByStartTime = 'startTime',
    ByEndTime = 'endTime',
}

// ui enum
export enum EventsSorting {
    BySeverity = 'severity',
    ByTime = 'time',
}

const startTimeAsc: SortEntry = [EventsSortingRequestParams.ByStartTime, SortDirection.Asc]
const startTimeDesc: SortEntry = [EventsSortingRequestParams.ByStartTime, SortDirection.Desc]
const endTimeDesc: SortEntry = [EventsSortingRequestParams.ByEndTime, SortDirection.Desc]
const severityDesc: SortEntry = [EventsSortingRequestParams.BySeverity, SortDirection.Desc]

export const allEventSortings: EventsSorting[] = EnumValues.getValues<EventsSorting>(EventsSorting)

export const eventsSorting: Record<EventsFilter, Record<EventsSorting, SortEntry[]>> = {
    [EventsFilter.Closed]: {
        [EventsSorting.BySeverity]: [severityDesc, endTimeDesc],
        [EventsSorting.ByTime]: [endTimeDesc],
    },
    [EventsFilter.Active]: {
        [EventsSorting.BySeverity]: [severityDesc, startTimeDesc],
        [EventsSorting.ByTime]: [startTimeDesc],
    },
    [EventsFilter.Overdue]: {
        [EventsSorting.BySeverity]: [severityDesc, startTimeDesc],
        [EventsSorting.ByTime]: [startTimeDesc],
    },
    [EventsFilter.Upcoming]: {
        [EventsSorting.BySeverity]: [severityDesc, startTimeAsc],
        [EventsSorting.ByTime]: [startTimeAsc],
    },
}

export const eventsSortingForEntries = (f: EventsFilter, entries: SortEntry[]): EventsSorting => {
    const pair = toPairs(eventsSorting[f]).find(v => isEqual(v[1], entries))
    if (pair) {
        return pair[0] as EventsSorting
    }
    return EventsSorting.BySeverity
}
