import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    queueingTimeBorderTileSelector,
    QueueingTimeSelectorState,
} from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { BorderControlPoint } from 'aos-services/src/services/queueingTime/types/BorderControlPoint'
import { QueueingDataTypeFilter } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { QueueingTimeItem } from './partials/QueueingTimeItem'
import { QueueingStat } from './QueueingStat'
import { QueueingTimeOwnProps } from './QueueingTime'

interface QueueingTimeBorderTileProps {
    data: QueueingTimeSelectorState<BorderControlPoint>
    carouselTrigger: DateTime
}

export class QueueingTimeBorderTileComponent extends PureComponent<
    QueueingTimeBorderTileProps & QueueingTimeOwnProps
> {
    private link = generatePath(Link.StatusDashboardPreset, {
        preset: DashboardProcessType.Passengers,
    })

    public render() {
        const itemProps = pickDashboardItemProps(this.props)
        const { data, carouselTrigger } = this.props
        return (
            <QueueingTimeItem
                itemProps={itemProps}
                noDataWarning={data.noDataWarning}
                link={this.link}
                type={data.type}
                syncId='overview'
            >
                <TileContainer verticalSpacing={false}>
                    <Carousel changeTrigger={carouselTrigger}>
                        {Object.keys(data.stats).map((point: BorderControlPoint) => (
                            <QueueingStat
                                key={point}
                                stat={data.stats[point]}
                                config={data.config[point]}
                                filter={QueueingDataTypeFilter.WaitTime}
                            />
                        ))}
                    </Carousel>
                </TileContainer>
            </QueueingTimeItem>
        )
    }
}

export const QueueingTimeBorderTile = connect<
    QueueingTimeBorderTileProps,
    {},
    QueueingTimeOwnProps
>(
    createStructuredSelector({
        data: queueingTimeBorderTileSelector,
        carouselTrigger: carouselTriggerSelector,
    }),
    {},
)(QueueingTimeBorderTileComponent)
