import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { BaseUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import { AosAdUser, AosContact, AosUser } from 'aos-services/src/services/users/types/AosUser'
import { userManagementService } from 'aos-services/src/services/users/userManagementService'
import { userProfileService } from 'aos-services/src/services/users/userProfileService'
import {
    errorToUserResponseStatus,
    UserResponseStatus,
} from 'aos-services/src/services/users/userResponseConverter'
import { call, put, select } from 'redux-saga/effects'

import { State } from '../../../state'
import {
    ADD_AD_USER_PARENT,
    addAdUserActions,
    addContactActions,
    EDIT_AD_USER_PARENT,
    editAdUserActions,
    editContactActions,
} from '../../actions'
import {
    handleAddAdUserResponseStatus,
    reloadUserList,
    searchUserByEmail,
} from '../../common/sagas'
import {
    SWITCH_TO_EDIT_AD_USER,
    SWITCH_TO_EDIT_CONTACT,
    SwitchToEditAdUserAction,
    SwitchToEditContactAction,
} from '../actions'
import {
    CREATE_AD_USER,
    CreateAdUserAction,
    SEARCH_USER,
    SearchUserAction,
    SWITCH_TO_CONTACT,
    SwitchToContactAction,
    UPDATE_AD_USER,
    UpdateAdUserAction,
} from './actions'

function* handleUpdateAdUserResponseStatus(userStatus: UserResponseStatus) {
    if (userStatus) {
        if (userStatus[0] !== undefined) {
            yield put(editAdUserActions.setConflictUserIdAction(userStatus[0]!))
        }
        yield put(editAdUserActions.setUserStatusAction(userStatus[1]))
    }
}

function* searchUserSaga() {
    function* searchUser(action: SearchUserAction) {
        yield searchUserByEmail(action.payload)
    }

    yield takeEveryChildAction<SearchUserAction>(ADD_AD_USER_PARENT, SEARCH_USER, searchUser)
}

function* createAdUserSaga() {
    function* createAdUser(action: CreateAdUserAction) {
        try {
            const { formData, notificationSettings } = action.payload

            const user: AosUser = yield call(userManagementService.createAdUser, formData)

            if (notificationSettings) {
                yield call(
                    userProfileService.updateUserNotifications,
                    user.id,
                    notificationSettings,
                )
            }

            yield put(addAdUserActions.hideUserModalAction())
            yield reloadUserList()
        } catch (e) {
            const userResponseStatus = errorToUserResponseStatus(e as ResponseError)
            if (userResponseStatus) {
                yield handleAddAdUserResponseStatus(userResponseStatus)
            } else {
                throw e
            }
        }
    }

    yield takeEveryChildAction<CreateAdUserAction>(ADD_AD_USER_PARENT, CREATE_AD_USER, createAdUser)
}

function* updateAdUserSaga() {
    yield takeEveryChildAction<UpdateAdUserAction>(
        EDIT_AD_USER_PARENT,
        UPDATE_AD_USER,
        function* (action: UpdateAdUserAction) {
            try {
                const { formData, notificationSettings } = action.payload

                yield call(userManagementService.updateAdUser, formData)

                if (notificationSettings) {
                    yield call(
                        userProfileService.updateUserNotifications,
                        formData.id!,
                        notificationSettings,
                    )
                }

                yield put(editAdUserActions.hideUserModalAction())
                yield reloadUserList()
            } catch (e) {
                const userResponseStatus = errorToUserResponseStatus(e as ResponseError)
                if (userResponseStatus) {
                    yield handleUpdateAdUserResponseStatus(userResponseStatus)
                } else {
                    throw e
                }
            }
        },
    )
}

function* openAddContact() {
    const user: BaseUserFormData = yield select(
        (state: State) => state.groupManager.addAdUserState.user,
    )
    const userAsContact = { email: user.email, selectedGroupsIds: [...user.selectedGroupsIds] }
    yield put(addContactActions.setUserDataAction(userAsContact))

    yield put(addContactActions.showUserModalAction())
}

function* openEditAdUser(action: SwitchToEditAdUserAction) {
    const userId = action.payload
    const user: AosAdUser = yield call(userManagementService.getUserById, userId)

    yield put(editAdUserActions.showUserModalWithAdUserAction(user))
}

function* openEditContact(action: SwitchToEditContactAction) {
    const userId = action.payload
    const user: AosContact = yield call(userManagementService.getUserById, userId)

    yield put(editContactActions.showUserModalWithContactAction(user))
}

function* switchAddUserToAddContactSaga() {
    function* switchToAddContact() {
        yield openAddContact()
        yield put(addAdUserActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToContactAction>(
        ADD_AD_USER_PARENT,
        SWITCH_TO_CONTACT,
        switchToAddContact,
    )
}

function* switchAddUserToEditAdUserSaga() {
    function* switchToEditAdUser(action: SwitchToEditAdUserAction) {
        yield openEditAdUser(action)
        yield put(addAdUserActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditAdUserAction>(
        ADD_AD_USER_PARENT,
        SWITCH_TO_EDIT_AD_USER,
        switchToEditAdUser,
    )
}

function* switchAddUserToEditContactSaga() {
    function* switchToEditContact(action: SwitchToEditContactAction) {
        yield openEditContact(action)
        yield put(addAdUserActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditContactAction>(
        ADD_AD_USER_PARENT,
        SWITCH_TO_EDIT_CONTACT,
        switchToEditContact,
    )
}

function* switchEditUserToEditAdUserSaga() {
    function* switchToEditAdUser(action: SwitchToEditAdUserAction) {
        yield openEditAdUser(action)
    }

    yield takeEveryChildAction<SwitchToEditAdUserAction>(
        EDIT_AD_USER_PARENT,
        SWITCH_TO_EDIT_AD_USER,
        switchToEditAdUser,
    )
}

function* switchEditUserToEditContactSaga() {
    function* switchToEditContact(action: SwitchToEditContactAction) {
        yield openEditContact(action)
        yield put(editAdUserActions.hideUserModalAction())
    }

    yield takeEveryChildAction<SwitchToEditContactAction>(
        EDIT_AD_USER_PARENT,
        SWITCH_TO_EDIT_CONTACT,
        switchToEditContact,
    )
}

export const addAdUserSagas = [
    searchUserSaga,
    createAdUserSaga,
    updateAdUserSaga,
    switchAddUserToAddContactSaga,
    switchAddUserToEditAdUserSaga,
    switchEditUserToEditAdUserSaga,
    switchAddUserToEditContactSaga,
    switchEditUserToEditContactSaga,
]
