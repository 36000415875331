//craete react functional component PrivateChannelStatusSelection browser version
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { InvitationStatus } from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface Props {
    onBack: () => void
    selectAction: (action: InvitationStatus) => void
    atcAlert?: boolean
    isCommander?: boolean
}

export const PrivateChannelStatusSelection: FC<Props> = ({
    onBack,
    selectAction,
    atcAlert,
    isCommander,
}) => {
    return (
        <Box centered column rowGap={12}>
            <Box row justify='space-between' width='100%'>
                <Box row columnGap={4}>
                    <IconButton
                        onClick={onBack}
                        svg={SvgIcon.Back}
                        iconVariant={IconVariant.White}
                    />
                    <Text>{translate('back')}</Text>
                </Box>
            </Box>
            {atcAlert ? (
                <>
                    <SvgImage svg={SvgIcon.Atc} attributes={{ fill: Color.Red, color: 'red' }} />
                    <Text lineHeight='standard' textAlign='center' weight='bold'>
                        {translate('private-channel.emergency.title')}
                    </Text>
                    <Text lineHeight='standard' textAlign='center'>
                        {translate('private-channel.emergency.status.text')}
                    </Text>
                </>
            ) : (
                <>
                    <SvgImage svg={SvgIcon.PrivateInvitation} />
                    <Text lineHeight='standard' textAlign='center' weight='bold'>
                        {translate('private-channel.invitation.title')}
                    </Text>
                    <Text lineHeight='standard' textAlign='center'>
                        {translate('private-channel.invitation.text')}
                    </Text>
                </>
            )}

            <Box column paddingTop={30} rowGap={16}>
                <FormButton
                    size={FormComponentSize.Big}
                    label={
                        <Box row justify='center'>
                            <SvgImage
                                svg={SvgIcon.StatusAtAirport}
                                attributes={{ fill: Color.White, color: 'white' }}
                            />
                            <Text style={{ marginLeft: 10 }}>
                                {translate('private-channel.status.button.at-airport')}
                            </Text>
                        </Box>
                    }
                    variant={FormButtonVariant.WhiteOutlined}
                    onClick={() => selectAction(InvitationStatus.AtAirport)}
                    seleniumLocation='private-channel.status.button.participate'
                />
                <FormButton
                    size={FormComponentSize.Big}
                    label={
                        <Box row justify='center'>
                            <SvgImage
                                svg={SvgIcon.StatusComing}
                                attributes={{ fill: Color.White, color: 'white' }}
                            />
                            <Text style={{ marginLeft: 10 }}>
                                {translate('private-channel.status.button.coming')}
                            </Text>
                        </Box>
                    }
                    variant={FormButtonVariant.WhiteOutlined}
                    onClick={() => selectAction(InvitationStatus.Coming)}
                    seleniumLocation='private-channel.status.button.observe'
                />
                <FormButton
                    size={FormComponentSize.Big}
                    label={
                        <Box row justify='center'>
                            <SvgImage
                                svg={SvgIcon.StatusRemote}
                                attributes={{ fill: Color.Red, color: 'red' }}
                            />
                            <Text style={{ marginLeft: 10 }}>
                                {translate('private-channel.status.button.remote')}
                            </Text>
                        </Box>
                    }
                    variant={FormButtonVariant.WhiteOutlined}
                    onClick={() => selectAction(InvitationStatus.Remote)}
                    seleniumLocation='private-channel.status.button.leave'
                />
                {isCommander && (
                    <FormButton
                        size={FormComponentSize.Big}
                        label={translate('private-channel.status.button.skip')}
                        variant={FormButtonVariant.Flat}
                        onClick={() => selectAction(InvitationStatus.Skip)}
                        seleniumLocation='private-channel.status.button.skip'
                    />
                )}
            </Box>
        </Box>
    )
}
