import { removeAt, updateAt } from 'aos-helpers/src/helpers/Array'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Color } from '../../../../../aos-ui-common/src/styles/Color'
import { AlignItems, Box, NumberValues } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { Text } from '../../base/Text'
import { IconButton } from '../../buttons/IconButton'
import { IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'

export interface ValidationType {
    isError?: boolean
    errorMessage?: ReactNode
}

export interface EditableList<T> extends SeleniumProps {
    value: T[]
    alignItems?: AlignItems
    validation?: ValidationType[]
    spacing?: NumberValues
    separated?: boolean

    itemRenderer(p: ItemRendererProps<T>): ReactNode
    onChange(v: T[]): void
}

export interface ItemRendererProps<T> {
    item: T
    index: number
    isLast: boolean
    onChangeItem(v: T): void
}

export const EditableList = <T extends any>({
    value,
    itemRenderer,
    onChange,
    alignItems = 'flex-start',
    validation = [],
    spacing = 0,
    separated = false,
}: EditableList<T>) => {
    const onRemove = (index: number) => {
        onChange(removeAt(value, index))
    }
    const onChangeItem = (index: number, v: T) => {
        onChange(updateAt(value, index, () => v))
    }
    return (
        <Box>
            {value.map((item, index) => (
                <Box column key={index} marginTop={index > 0 ? spacing : 0}>
                    <Box row alignItems={alignItems}>
                        <Box flex={1}>
                            {itemRenderer({
                                item,
                                index,
                                isLast: index === value.length - 1,
                                onChangeItem: v => onChangeItem(index, v),
                            })}
                        </Box>
                        <Box row>
                            <IconButton
                                iconSize={BlockSize.Std}
                                iconVariant={IconVariant.Grey}
                                padding={6}
                                svg={SvgIcon.Delete}
                                onClick={() => onRemove(index)}
                            />
                        </Box>
                    </Box>
                    {validation[index]?.isError && validation[index]?.errorMessage && (
                        <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                            {validation[index]?.errorMessage}
                        </Text>
                    )}
                    {separated && (
                        <Separator paddingTop={spacing} last={index === value.length - 1} />
                    )}
                </Box>
            ))}
        </Box>
    )
}

const Separator = styled(Box)<{ last: boolean }>`
    ${p =>
        !p.last &&
        css`
            border-bottom: 1px solid ${Color.Grey1};
        `}
`
