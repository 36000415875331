import { dateTime } from 'aos-helpers/src/helpers/Time'
import { NOTAM_LOAD_SUCCESS, SNOWTAM_LOAD_SUCCESS } from 'aos-services/src/core/notam/actions'
import { timelineReducer } from 'aos-services/src/core/timeline/reducer'
import { xor } from 'lodash'

import {
    NOTAM_TIMELINE_PARENT,
    NOTAM_TOGGLE_GROUP,
    NotamActions,
    SELECT_NOTAM_ITEM,
} from './actions'
import { initialNotamState, NotamState } from './state'

export const notamReducer = (state: NotamState = initialNotamState, action: NotamActions) => {
    switch (action.type) {
        case NOTAM_LOAD_SUCCESS:
            return { ...state, list: action.payload, lastUpdated: dateTime() }
        case NOTAM_TIMELINE_PARENT:
            return {
                ...state,
                timeline: timelineReducer(state.timeline, action.payload),
            }
        case SELECT_NOTAM_ITEM:
            return {
                ...state,
                selectedItem: action.payload,
            }
        case NOTAM_TOGGLE_GROUP:
            return {
                ...state,
                collapsedGroups: xor(state.collapsedGroups, [action.payload]),
            }
        case SNOWTAM_LOAD_SUCCESS:
            return { ...state, snowtam: action.payload }

        default:
            return state
    }
}
