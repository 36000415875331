import { IconModal } from 'aos-components/src/components/modal/IconModal/IconModal'
import { SingleDateActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleDate/actions'
import {
    DateValueWrapper,
    SingleDateModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleDate/state'
import { SimpleFormModalProps } from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { closeEventActions } from '../../core/eventEditor/actions'
import { State } from '../../core/state'

export class CloseEventModalContainer extends PureComponent<
    CloseEventModalProps & SingleDateActionCreators & SingleDateModalState
> {
    public render() {
        const { title, description, okLabel } = modalContentFromKeyPrefix(this.props.keyPrefix)

        const form = this.props.form
        return (
            <IconModal
                isOpen={this.props.isOpen}
                closeAction={this.props.hideModelAction}
                modalKind={ModalKind.Icon}
                svg={SvgIcon.CloseEvent}
            >
                <Box flex={1} paddingVertical={30} paddingHorizontal={30}>
                    <Text color={Color.Black} size={22} lineHeight='standard' textAlign='center'>
                        {title}
                    </Text>
                    <Text
                        color={Color.Grey}
                        size={14}
                        paddingTop={8}
                        lineHeight='standard'
                        textAlign='center'
                    >
                        {description}
                    </Text>
                    <LabeledFormElement
                        marginTop={30}
                        label={this.translateSuffix('end-time.label')}
                        isRequired
                        isError={form.error.value}
                        seleniumLocation='first-input'
                    >
                        <DatetimeInput
                            value={this.props.currentValue.value}
                            onChange={this.onDateChange}
                            isValidDate={this.isValidEndTime}
                            viewMode='time'
                            placeholder={this.translateSuffix('end-time.placeholder')}
                        />
                    </LabeledFormElement>
                    <Box row flex={1} paddingTop={12}>
                        <ValidationInfo pristine={form.pristine} valid={form.valid} />
                        <FormButton label={okLabel} onClick={this.trySubmit} />
                    </Box>
                </Box>
            </IconModal>
        )
    }

    private onDateChange = (endTimeEventValue?: DateTime) => {
        const endTime =
            endTimeEventValue && endTimeEventValue.isBefore(this.props.startTime)
                ? dateTime(this.props.startTime).add(1, 'seconds')
                : endTimeEventValue

        const p: Partial<DateValueWrapper> = { value: endTime }
        this.props.changeFormValueAction(Object.assign({}, this.props.currentValue, p))
    }

    private trySubmit = () => {
        if (this.props.form.valid) {
            this.props.submitAction(this.props.currentValue)
            this.props.hideModelAction()
        } else {
            this.props.setFormPristineAction(false)
        }
    }

    private translateSuffix = (v: string) => translate(`${this.props.keyPrefix}.${v}`)

    private isValidEndTime = (currentDate: DateTime) => {
        const startTime = dateTime(this.props.startTime)
        return startTime ? currentDate.isSameOrAfter(startTime, 'day') : true
    }
}

interface CloseEventModalProps extends SimpleFormModalProps {
    startTime: DateTime
}

export const CloseEventModal = connect<
    SingleDateModalState,
    SingleDateActionCreators,
    CloseEventModalProps
>(
    (state: State) => state.eventEditor.closeEventModalState,
    closeEventActions,
)(CloseEventModalContainer)
