import {
    DashboardItemMode,
    dashboardItemModeColor,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ColorIndicator, ColorIndicatorType } from 'aos-ui/src/components/indicators/ColorIndicator'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { keys } from 'lodash'
import React, { PropsWithChildren, PureComponent, ReactNode } from 'react'

import { StatusDashboardItemCommonProps } from '../renderers/base/StatusDashboardItemCommonProps'
import { DashboardItemTitleIcon } from './DashboardItemTitleIcon'
import { ItemLeftColumn } from './ItemLeftColumn'

export interface StatusDashboardItemTitleProps extends StatusDashboardItemCommonProps {
    title: string
    labels?: ReactNode
    description?: ReactNode
    openHelp?(): void
}

export class StatusDashboardItemTitle extends PureComponent<
    PropsWithChildren<StatusDashboardItemTitleProps>
> {
    public render() {
        const { warning, status } = this.props.itemStatus
        const color = dashboardItemModeColor[status]
        const hasMoreThenOneSize = keys(this.props.sizes).length > 1
        const isSizeAvailable = (size: DashboardItemSizeVariant) =>
            hasMoreThenOneSize && !!this.props.sizes[size] && this.props.itemState.size !== size
        const dismissColor =
            status === DashboardItemMode.Warning ? IconVariant.Yellow : IconVariant.Red

        return (
            <Box paddingVertical={16} paddingRight={16}>
                <Box row>
                    <ItemLeftColumn>
                        <ColorIndicator
                            color={color}
                            type={this.getIndicatorType()}
                            marginLeft={16}
                        />
                    </ItemLeftColumn>
                    <Text
                        flex={1}
                        row
                        alignSelf='flex-start'
                        size={18}
                        weight='light'
                        color={Color.White}
                        lineHeight='standard'
                        cursor='move'
                        className='handler'
                    >
                        {this.props.title}
                        {this.props.children}
                    </Text>
                    <Box shrink={0} row>
                        {warning && this.props.isApoc && (
                            <DashboardItemTitleIcon
                                onClick={this.dismiss}
                                svg={SvgIcon.DismissNotification}
                                iconVariant={dismissColor}
                            />
                        )}
                        {this.props.openHelp && (
                            <DashboardItemTitleIcon
                                onClick={this.props.openHelp}
                                svg={SvgIcon.Help}
                            />
                        )}
                        {isSizeAvailable(DashboardItemSizeVariant.Big) && (
                            <DashboardItemTitleIcon
                                onClick={this.changeSize(DashboardItemSizeVariant.Big)}
                                svg={SvgIcon.ShowMore}
                            />
                        )}
                        {isSizeAvailable(DashboardItemSizeVariant.Small) && (
                            <DashboardItemTitleIcon
                                onClick={this.changeSize(DashboardItemSizeVariant.Small)}
                                svg={SvgIcon.ShowLess}
                            />
                        )}
                        {this.props.removeItem && (
                            <DashboardItemTitleIcon
                                onClick={this.removeItem}
                                svg={SvgIcon.Delete}
                            />
                        )}
                    </Box>
                </Box>
                <Box row paddingTop={4}>
                    <ItemLeftColumn />
                    <Text
                        flex='auto'
                        color={Color.Grey}
                        size={12}
                        weight='regular'
                        lineHeight='standard'
                    >
                        {this.props.description}
                    </Text>
                </Box>
            </Box>
        )
    }

    private getIndicatorType() {
        return this.props.itemStatus.status !== DashboardItemMode.NoData
            ? ColorIndicatorType.BigDot
            : ColorIndicatorType.Circle
    }

    private dismiss = () => {
        this.props.onDismissAlert(this.props.itemState.type)
    }

    private changeSize = (s: DashboardItemSizeVariant) => () => {
        this.props.onChangeSize([this.props.id, s])
    }

    private removeItem = () => this.props.removeItem && this.props.removeItem(this.props.id)
}
