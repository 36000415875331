import {
    Pageable,
    SortDirection,
    sortEntriesForPageable,
    SortEntryMap,
} from 'aos-helpers/src/helpers/Pageable'
import { isEqual, toPairs } from 'lodash'

// backend enum
export enum UsersSortingEntries {
    ByOrigin = 'origin',
    ByName = 'name',
}

// ui enum
export enum UsersSorting {
    ByNameAsc = 'name-asc',
    ByNameDesc = 'name-desc',
    ByOrigin = 'origin',
}

export const usersSorting: SortEntryMap = {
    [UsersSorting.ByNameAsc]: [[UsersSortingEntries.ByName, SortDirection.Asc]],
    [UsersSorting.ByNameDesc]: [[UsersSortingEntries.ByName, SortDirection.Desc]],
    [UsersSorting.ByOrigin]: [[UsersSortingEntries.ByOrigin, SortDirection.Asc]],
}

export const usersSortingFromPageable = (data: Pageable<any>) => {
    const entries = sortEntriesForPageable(data)
    const pair = toPairs(usersSorting).find(v => isEqual(v[1], entries))
    if (pair) {
        return pair[0] as UsersSorting
    }
    return UsersSorting.ByNameAsc
}
