import { conditionalFunction, conditionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import {
    currentUserFirebaseIdSelector,
    hasCurrentUserAnyUnitRole,
    isApocSelector,
    isCurrentUserCommander,
} from 'aos-services/src/core/auth/state'
import {
    toggleEventChecklistTask,
    updateEventItemAction,
} from 'aos-services/src/core/events/actions'
import { NewFeedOut } from 'aos-services/src/services/events/input/NewFeedOut'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { PrivateChannelParticipantInputPayload } from 'aos-services/src/services/firebaseEvents/input/PrivateChannelParticipantInput'
import { EtaStatus } from 'aos-services/src/services/firebaseEvents/privateChannelState'
import { checkIfCanEditCheckboxes } from 'aos-services/src/services/firebaseEvents/utils'
import { AosUserGroupType } from 'aos-services/src/services/users/types/AosUserGroupType'
import { Box } from 'aos-ui/src/components/base/Box'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { SplittedPanel } from 'aos-ui/src/components/container/SplittedPanel'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { openChecklistModalAction } from '../../core/checklistSelectionModal/actions'
import { userGroupsSelector } from '../../core/commonFrontend/state'
import {
    addFeedOutAction,
    changeChecklistTabAction,
    changeFeedOutFormAction,
    closeEventActions,
    copyMessageTemplateAction,
    deleteEventAction,
    loadEventInEventEditorAction,
    reopenEventAction,
    selectUserGroupActions,
    toggleEventChecklist,
    toggleFeedOutConfirmationAction,
    undeleteEventAction,
    updateInvitationAction,
} from '../../core/eventEditor/actions'
import { eventUnitTaskTemplateSelector } from '../../core/eventEditor/selector'
import { editEventShowModalAction } from '../../core/eventModals/actions'
import { previewEventAction } from '../../core/eventPreview/actions'
import { resizeEventEditorColumnAction } from '../../core/layout/actions'
import { eventEditorColumnStateKey, EventEditorDialogColumn } from '../../core/layout/state'
import { currentAbsoluteUrlSelector } from '../../core/router/state'
import { State } from '../../core/state'
import { ChecklistSelectionModal } from '../checklistSelectionModal/ChecklistSelectionModal'
import { CloseEventModal } from '../eventModals/CloseEventModal'
import { IWillArriveModal } from './privateChannel/IWillArriveModal'
import { PrivateChannel } from './privateChannel/PrivateChannel'
import { SelectUserGroupModal } from './privateChannel/SelectUserGroupModal'
import { AtcEventInfo } from './section/AtcEventInfo'
import { DashboardOption } from './section/AtcEventInfoContent'
import { EventChecklists } from './section/EventChecklists'
import { EventInfo } from './section/EventInfo'
import { EventMap } from './section/EventMap'
import { FeedOuts } from './section/FeedOuts'
import { AdditionalNoteModal } from './section/items/AdditionalNoteModal'

export const EventEditor = memo(
    (props: EventEditorProps) => {
        const eventEditor = useSelector((state: State) => state.eventEditor)
        const unitTaskTemplate = useSelector(eventUnitTaskTemplateSelector)
        const groups = useSelector(userGroupsSelector)
        const firebaseUid = useSelector(currentUserFirebaseIdSelector)
        const isApoc = useSelector(isApocSelector)
        const userHasAnyUnitRole = useSelector(hasCurrentUserAnyUnitRole)
        const columnSize = useSelector((state: State) => state.layout.columnSizes.eventEditor)
        const url = useSelector(currentAbsoluteUrlSelector)
        const isCommander = useSelector(isCurrentUserCommander)
        const participants = eventEditor.firebaseEvent
            ? eventEditor.firebaseEvent.participants(firebaseUid)
            : undefined
        const visibilityLayers = eventEditor.firebaseEvent
            ? eventEditor.firebaseEvent.visibilityLayers
            : []
        const me = participants?.[0]
        const [etaStatus, setEtaStatus] = useState(
            !!me?.arrivalTime ? EtaStatus.Specified : EtaStatus.NotSpecified,
        )
        const [dashboardState, setDashboardState] = useState(DashboardOption.DETAILS)
        const dispatch = useDispatch()

        const userCanEditOrViewChecklist = useMemo(
            () => checkIfCanEditCheckboxes(isApoc, me?.invitationRole, me?.invitationStatus),
            [me, isApoc],
        )

        const loadEvent = (id: number) => {
            dispatch(loadEventInEventEditorAction(id))
        }
        const routeIdParam = (props: EventEditorProps): number => {
            return parseInt(props.match.params.id, 10)
        }

        const closeEvent = useCallback(
            (event: AosEvent) => () => {
                const now = dateTime()
                const endTime = now.isBefore(event.startTime)
                    ? dateTime(event.startTime).add(1, 'seconds')
                    : now
                dispatch(
                    closeEventActions.showModalWithValueAction({
                        payload: { eventId: event.id },
                        value: endTime,
                    }),
                )
            },
            [],
        )

        const showIWillArrive = () => {
            setEtaStatus(EtaStatus.SpecifyAgain)
        }

        const getDefaultGroup = useCallback(
            (event: AosEvent): number | undefined => {
                if (event.privateGroup) {
                    return event.privateGroup.id
                }
                return groups.find(v => v.type === AosUserGroupType.ALL)?.id
            },
            [groups],
        )

        const renderEvent = (event: AosEvent) => {
            const { id, startTime, deleted, isClosed, isOpen, privateGroup, atcAlert } = event

            const isEditable = isOpen && !deleted && isApoc
            const showPrivateChannel = event.hasPrivateChannel
            const hasChecklist = userHasAnyUnitRole || atcAlert

            const editablePrivateChannel = isEditable && !event.hasPrivateChannel
            const columnKey = eventEditorColumnStateKey(!!hasChecklist, showPrivateChannel)
            const participants = eventEditor.firebaseEvent
                ? eventEditor.firebaseEvent.participants(firebaseUid)
                : undefined

            const editProps = {
                newFeedOutState: eventEditor.newFeedOut,
                onFeedOutSubmit: (payload: [number, NewFeedOut]) =>
                    dispatch(addFeedOutAction(payload)),
                onFeedOutChange: (payload: NewFeedOut) =>
                    dispatch(changeFeedOutFormAction(payload)),
                isSendingFeedOut: eventEditor.ui.isSendingFeedOut,
                isConfirmationVisible: eventEditor.ui.isConfirmationVisible,
                toggleConfirmation: (payload: boolean) =>
                    dispatch(toggleFeedOutConfirmationAction(payload)),
            }

            const createPrivateChannel = () =>
                dispatch(selectUserGroupActions.showModalWithPayloadAction(id))
            const previewEventLocation = () =>
                dispatch(previewEventAction({ eventId: id, openOnMap: true }))
            const reopenEvent = () => dispatch(reopenEventAction(id))
            const deleteEvent = () => dispatch(deleteEventAction(id))
            const undeleteEvent = () => dispatch(undeleteEventAction(id))
            const updateInvitation = (input: PrivateChannelParticipantInputPayload) => {
                dispatch(
                    updateInvitationAction({ ...input, firebaseUid: firebaseUid!, eventId: id }),
                )
            }
            const resizeColumn = (key: EventEditorDialogColumn) => (value: number[]) => {
                dispatch(resizeEventEditorColumnAction([key, value]))
            }
            const defaultGroupId = getDefaultGroup(event)

            const checklist = () =>
                hasChecklist && (
                    <EventChecklists
                        unitTasks={
                            unitTaskTemplate?.find(q => q.severity === event.severity)?.items
                        }
                        isApoc={isApoc}
                        isAtc={atcAlert}
                        isOpen={isOpen}
                        isEditable={isOpen && (atcAlert ? userCanEditOrViewChecklist : true)}
                        checklists={eventEditor.editableChecklists}
                        toggleChecklist={id => dispatch(toggleEventChecklist(id))}
                        changeTask={(itemId, item) =>
                            dispatch(
                                updateEventItemAction({
                                    status: item.status!,
                                    itemId,
                                    unitTaskId: event.unitTasks![0].id,
                                    eventId: event.id,
                                    additionalNote: item.additionalNote,
                                }),
                            )
                        }
                        toggleTask={payload => dispatch(toggleEventChecklistTask(payload))}
                        eventId={id}
                        openChecklistSelection={conditionalFunction(isOpen && !deleted, () =>
                            dispatch(openChecklistModalAction()),
                        )}
                        changeTab={payload => dispatch(changeChecklistTabAction(payload))}
                        copyMessageTemplate={payload =>
                            dispatch(copyMessageTemplateAction(payload))
                        }
                    />
                )

            const dashboardPanel = () => (
                <ResizableSplittedPanel
                    separated
                    fullSize
                    flex={1}
                    initialRightSizes={columnSize['dashboard']}
                    leftMinSizes={[50]}
                    rightMinSizes={[24]}
                    onResize={resizeColumn('dashboard')}
                >
                    <SplittedPanel>
                        <EventMap layersVisibility={visibilityLayers} event={eventEditor.event} />
                    </SplittedPanel>
                    <SplittedPanel>{checklist()}</SplittedPanel>
                </ResizableSplittedPanel>
            )

            const infoPanel = () => (
                <ResizableSplittedPanel
                    separated
                    fullSize
                    flex={1}
                    initialRightSizes={columnSize[columnKey]}
                    leftMinSizes={[30, 30]}
                    rightMinSizes={[30, 24]}
                    onResize={resizeColumn(columnKey)}
                >
                    {checklist()}
                    <FeedOuts
                        event={event}
                        editProps={editProps}
                        isEditable={isEditable}
                        groups={groups}
                        defaultGroupId={defaultGroupId}
                    />
                    {showPrivateChannel && (
                        <PrivateChannel
                            etaStatus={etaStatus}
                            setEtaStatus={setEtaStatus}
                            isCommander={isCommander}
                            participants={participants}
                            editable={editablePrivateChannel}
                            privateChannelAdded={event.hasPrivateChannel}
                            updateInvitation={updateInvitation}
                            showIWillArrive={showIWillArrive}
                            invitedGroup={privateGroup}
                            atcAlert={atcAlert}
                        />
                    )}
                </ResizableSplittedPanel>
            )

            const layout =
                dashboardState === DashboardOption.DASHBOARD ? dashboardPanel() : infoPanel()
            return (
                <Box fullSize row>
                    <SplittedPanel separatedHeader>
                        {event.atcAlert ? (
                            <AtcEventInfo
                                participants={participants!}
                                dashboardState={dashboardState}
                                onChangeDashboard={state => {
                                    setDashboardState(state)
                                }}
                                event={event}
                                editEvent={conditionalFunctionCall1Arg(
                                    isEditable,
                                    payload => dispatch(editEventShowModalAction(payload)),
                                    event,
                                )}
                                inviteAction={conditionalFunction(isEditable, createPrivateChannel)}
                                addPrivateChannel={conditionalFunction(
                                    isEditable && !event.hasPrivateChannel,
                                    createPrivateChannel,
                                )}
                                closeEvent={conditionalFunction(isEditable, closeEvent(event))}
                                reopenEvent={conditionalFunction(
                                    !deleted && isClosed && isApoc,
                                    reopenEvent,
                                )}
                                deleteEvent={conditionalFunction(!deleted && isApoc, deleteEvent)}
                                undeleteEvent={conditionalFunction(
                                    deleted && isApoc,
                                    undeleteEvent,
                                )}
                                withVisibilityField={isApoc}
                                selectLocation={previewEventLocation}
                            />
                        ) : (
                            <EventInfo
                                event={event}
                                editEvent={conditionalFunctionCall1Arg(
                                    isEditable,
                                    payload => dispatch(editEventShowModalAction(payload)),
                                    event,
                                )}
                                inviteAction={conditionalFunction(isEditable, createPrivateChannel)}
                                addPrivateChannel={conditionalFunction(
                                    isEditable && !event.hasPrivateChannel,
                                    createPrivateChannel,
                                )}
                                closeEvent={conditionalFunction(isEditable, closeEvent(event))}
                                reopenEvent={conditionalFunction(
                                    !deleted && isClosed && isApoc,
                                    reopenEvent,
                                )}
                                deleteEvent={conditionalFunction(!deleted && isApoc, deleteEvent)}
                                undeleteEvent={conditionalFunction(
                                    deleted && isApoc,
                                    undeleteEvent,
                                )}
                                withVisibilityField={isApoc}
                                selectLocation={previewEventLocation}
                                url={url}
                            />
                        )}
                        {layout}
                    </SplittedPanel>
                    <ChecklistSelectionModal eventId={id} />
                    <SelectUserGroupModal keyPrefix='invite-to-private-channel' />
                    <IWillArriveModal keyPrefix='i-will-arrive' />
                    <CloseEventModal startTime={startTime} keyPrefix='close-event' />
                    <AdditionalNoteModal />
                </Box>
            )
        }

        useEffect(() => {
            const { event } = eventEditor
            const eventId = event && event.id
            const routeEventId = routeIdParam(props)

            if (eventId !== routeEventId) {
                loadEvent(routeEventId)
            }
        }, [])

        const { event } = eventEditor
        return event ? renderEvent(event) : null
    },
    (prev, next) => prev.match.params.id !== next.match.params.id,
)

interface EventEditorProps extends RouteComponentProps<{ id: string }> {}
