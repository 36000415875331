import FlexSearch, { CreateOptions } from 'flexsearch'
import { useEffect, useRef, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'

export { CreateOptions as SearchIndexOptions } from 'flexsearch'

const DEFAULT_DELAY = 200

interface ResultsOptions {
    delay?: number
    limit?: number
}

const defaultIndexOptions: CreateOptions = {
    encode: 'advanced',
    tokenize: 'forward',
    depth: 2,
    async: false,
}

export const useSearch = <L extends any>(
    list: L[],
    extractText: (item: L) => string,
    resultsOptions: ResultsOptions = {},
    indexOptions: CreateOptions = {},
) => {
    const { limit, delay = DEFAULT_DELAY } = resultsOptions
    const [query, setQuery] = useState('')
    const [results, setResults] = useState(list)
    const searchRef = useRef(FlexSearch.create())
    const search = searchRef.current

    useEffect(() => {
        search.init({
            ...defaultIndexOptions,
            ...indexOptions,
        })

        list.forEach((value, id) => {
            search.add(id, extractText(value))
        })

        return () => search.destroy()
    }, [list, indexOptions])

    const updateResults = () => {
        if (query.trim()) {
            Promise.resolve(search.search({ query, limit })).then(ids => {
                setResults(ids.map(id => list[id as number]))
            })
        } else {
            setResults(list)
        }
    }
    useDebounce(updateResults, delay, [query])
    useEffect(updateResults, [list])

    return { results, query, setQuery }
}
