import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

import {
    allChemicalCompounds,
    ChemicalCompoundFilter,
    translateChemicalCompound,
} from '../../../../core/tasks/reports/types/ChemicalCompound'

interface ChemicalCompoundDropdownProps {
    value: ChemicalCompoundFilter
    onChange(value: ChemicalCompoundFilter): void
}

export const ReportChemicalCompoundDropdown: FC<ChemicalCompoundDropdownProps> = ({
    value,
    onChange,
}) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            width={DropdownWidth.Auto}
            items={[FilterOptionAll.All, ...allChemicalCompounds]}
            valueRenderer={translateChemicalCompound}
            onChange={onChange}
            minDropWidth={80}
        />
    )
}
