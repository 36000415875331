import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { flightFilterService } from 'aos-services/src/services/flightInformation/FlightFilterService'
import {
    Flight,
    flightDateTimeColumns,
    FlightKey,
    isListFlight,
} from 'aos-services/src/services/flightInformation/types/Flight'
import { AirportFilter } from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { quickCollectionFilter } from 'aos-services/src/services/flightInformation/types/QuickFilterable'
import { createSelector } from 'reselect'

import { FiltersObject } from '../helpers/urlFilters'
import { flightsUrlFiltersSelector, quickSearchQuerySelector, sortingSelector } from '../selectors'
import {
    FlightInformationColumnValues,
    FlightInformationStateAware,
    FlightSort,
    SortingRule,
} from '../state'
import { getColumnFunctions } from './flightColumnFunctions'
import {
    arrivalsColumns,
    departuresColumns,
    filterAndSortFlightColumns,
    FlightInfoColumnListMeta,
} from './flightColumnsState'
import { FlightFilters } from './flightFiltersState'

const getFlightInfoByAirport = (airport: AirportFilter, collection: Flight[]) =>
    collection.filter(flightFilterService.isAirportRelatedTo(airport))

const filterFlights = (flights: Flight[], filters: FlightFilters): Flight[] =>
    flights.filter(flight =>
        Object.keys(filters).every(filterName => {
            const colFunction = getColumnFunctions(filterName)
            const id = filterName as keyof FlightFilters
            const value = filters[id]

            const disabled = value === undefined || (Array.isArray(value) && value.length === 0)

            if (!colFunction || disabled) {
                return true
            }

            return colFunction.filter({ id, value }, flight)
        }),
    )

const sortFlightsBy = (flights: Flight[], sorting: SortingRule, now: DateTime): Flight[] => {
    const colFunctions = getColumnFunctions(sorting.field)

    if (!colFunctions || !colFunctions.sorter) {
        return flights
    }

    return flights.slice().sort((a, b) => colFunctions.sorter!(a, b, sorting.desc, now))
}

const getNowIndex = (
    sorting: SortingRule,
    now: DateTime,
    flights: Flight[],
): number | undefined => {
    const field = sorting.field as FlightKey
    if (!flightDateTimeColumns.includes(field)) {
        return
    }

    const ascPredicate = (f?: DateTime) => f && f.isAfter(now)
    const descPredicate = (f?: DateTime) => f && f.isBefore(now)
    const predicate = sorting.desc ? descPredicate : ascPredicate

    return flights.filter(flight => predicate(flight[field] as DateTime)).length
}

const getFilteredFlights = (
    filters: FiltersObject,
    sorting: SortingRule,
    now: DateTime,
    quickSearchQuery: string,
    flights: Flight[],
) => {
    const airportFlights = getFlightInfoByAirport(filters.airport, flights)
    const filteredFlights = filterFlights(airportFlights, filters as FlightFilters)
    const sortedFlights = sortFlightsBy(filteredFlights, sorting, now)

    if (quickSearchQuery) {
        return quickCollectionFilter(sortedFlights, quickSearchQuery)
    }

    return sortedFlights
}

export const flightsColumnValuesSelector = (state: FlightInformationStateAware) =>
    state.flights.columnValues
export const isFlightsLoadingSelector = (state: FlightInformationStateAware) =>
    state.flights.isLoading
export const isNowLockedSelector = (state: FlightInformationStateAware) => state.flights.nowLocked
export const showCustomizeModalSelector = (state: FlightInformationStateAware) =>
    state.flights.showCustomizeModal
export const flightListSelector = (state: FlightInformationStateAware) => state.flights.list

export const arrivalsFlightInfoColumnListSelector = createSelector(
    flightsUrlFiltersSelector,
    (filters): FlightInfoColumnListMeta[] =>
        filterAndSortFlightColumns(arrivalsColumns, filters.arrivalsColumns),
)

export const departuresFlightInfoColumnListSelector = createSelector(
    flightsUrlFiltersSelector,
    (filters): FlightInfoColumnListMeta[] =>
        filterAndSortFlightColumns(departuresColumns, filters.departuresColumns),
)

export interface FlightInfoTableSelectorState {
    flights: Flight[]
    columnValues: FlightInformationColumnValues
    nowLocked: boolean
    isLoading: boolean
    filters: FiltersObject
    columnList: FlightInfoColumnListMeta[]
    nowIndex: number | undefined
    sorting: SortingRule
    now: DateTime
}

export const arrivalsSelector = createSelector(
    flightListSelector,
    currentTimeSelector,
    (list, now) =>
        list
            .filter(v => isListFlight(v, now))
            .filter(({ flightType }) => flightType === FlightListType.Arrivals),
)

export const departuresSelector = createSelector(
    flightListSelector,
    currentTimeSelector,
    (list, now) =>
        list
            .filter(v => isListFlight(v, now))
            .filter(({ flightType }) => flightType === FlightListType.Departures),
)

const flightMerger = (
    isLoading: boolean,
    nowLocked: boolean,
    columnValues: FlightInformationColumnValues,
    quickSearch: string,
    flights: Flight[],
    filters: FiltersObject,
    columnList: FlightInfoColumnListMeta[],
    sorting: SortingRule,
    now: DateTime,
): FlightInfoTableSelectorState => {
    const filteredFlights = getFilteredFlights(filters, sorting, now, quickSearch, flights)
    return {
        isLoading,
        nowLocked,
        columnValues,
        flights: filteredFlights,
        filters,
        columnList,
        sorting,
        now,
        nowIndex: getNowIndex(sorting, now, filteredFlights),
    }
}

export const arrivalTableSelector = createSelector(
    isFlightsLoadingSelector,
    isNowLockedSelector,
    flightsColumnValuesSelector,
    quickSearchQuerySelector,
    arrivalsSelector,
    flightsUrlFiltersSelector,
    arrivalsFlightInfoColumnListSelector,
    sortingSelector(FlightSort.ARRIVALS),
    currentTimeSelector,
    flightMerger,
)

export const departureTableSelector = createSelector(
    isFlightsLoadingSelector,
    isNowLockedSelector,
    flightsColumnValuesSelector,
    quickSearchQuerySelector,
    departuresSelector,
    flightsUrlFiltersSelector,
    departuresFlightInfoColumnListSelector,
    sortingSelector(FlightSort.DEPARTURES),
    currentTimeSelector,
    flightMerger,
)
