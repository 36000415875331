import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'

export interface Feedback {
    status: FormFeedback
    message: string
}

export interface SimpleFormModalState<T, V extends object, P> {
    isOpen: boolean
    currentValue: ValueWrapper<T, P>
    form: FormValidation<V>
    feedback?: Feedback
}

export interface ValueWrapper<T, P> {
    value?: T
    payload?: P
}

export type ValueWrapperValidator<T, V extends object, P> = (e: ValueWrapper<T, P>) => Partial<V>

export enum FormFeedback {
    Positive,
    Negative,
}
