import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    allAirports,
    AosAirport,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { BaseUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import {
    allAccessLevels,
    getAllUserTaskPermissions,
    TaskAccessLevel,
} from 'aos-services/src/services/users/types/AosUser'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { PermissionFromRow } from './partial/PermissionFromRow'

interface PermissionsFormProps {
    user: UserFormData
    changeForm(value: Partial<UserFormData>): void
}

type UserFormData = BaseUserFormData | AdUserFormData

export const PermissionsForm: FC<PermissionsFormProps> = ({ user, changeForm }) => {
    const updateAirportAccessLevel = (airport: AosAirport, accessLevel: TaskAccessLevel) => {
        changeForm({
            taskPermissions: {
                ...user.taskPermissions,
                [airport]: accessLevel,
            },
        })
    }

    const updateAllAirportsAccessLevel = (accessLevel: TaskAccessLevel) => {
        changeForm({
            taskPermissions: getAllUserTaskPermissions(accessLevel),
        })
    }

    return (
        <Box paddingTop={30} fullHeight rowGap={24} column>
            {isAdUser(user) && (
                <>
                    <Box paddingHorizontal={30}>
                        <Text color={Color.UiBlack1} size={18} marginBottom={8}>
                            {translate('manage-user.permission.flight-info')}
                        </Text>

                        <Checkbox
                            checked={user.isFinaviaAuthority || user.isAdditionalFinaviaAuthority}
                            disabled={user.isFinaviaAuthority}
                            onChange={isAdditionalFinaviaAuthority =>
                                changeForm({ isAdditionalFinaviaAuthority })
                            }
                        >
                            <Text color={Color.UiBlack1} size={14} padding={8}>
                                {translate('manage-user.is-additional-finavia-authority')}
                            </Text>
                        </Checkbox>
                    </Box>
                    <Box paddingHorizontal={30}>
                        <Text color={Color.UiBlack1} size={18} marginBottom={8}>
                            {translate('manage-user.permission.command-role')}
                        </Text>

                        <Checkbox
                            checked={user.isEventCommander}
                            onChange={isEventCommander => changeForm({ isEventCommander })}
                        >
                            <Text color={Color.UiBlack1} size={14} padding={8}>
                                {translate('manage-user.permission.command-role-description')}
                            </Text>
                        </Checkbox>
                    </Box>
                </>
            )}

            <Box paddingHorizontal={30}>
                <Text color={Color.UiBlack1} size={18} marginBottom={8}>
                    {translate('manage-user.permission.tasks.title')}
                </Text>
                <Text size={14} color={Color.Grey}>
                    {translate('manage-user.permission.tasks.description')}
                </Text>
            </Box>

            <Box flex={1} column>
                <Header marginHorizontal={30} paddingBottom={2} row>
                    <Box flex={1} />
                    <Box columnGap={24} width={180} row centered>
                        {allAccessLevels.map(lvl => (
                            <HeaderItem key={lvl} onClick={() => updateAllAirportsAccessLevel(lvl)}>
                                {lvl}
                            </HeaderItem>
                        ))}
                    </Box>
                </Header>

                <Box flex={1}>
                    <LightScrollbar>
                        {allAirports.map(airport => (
                            <PermissionFromRow
                                key={airport}
                                airport={airport}
                                accessLevel={user.taskPermissions?.[airport]}
                                onChange={lvl => updateAirportAccessLevel(airport, lvl)}
                            />
                        ))}
                    </LightScrollbar>
                </Box>
            </Box>
        </Box>
    )
}

const isAdUser = (user: UserFormData): user is AdUserFormData => 'isFinaviaAuthority' in user

const Header = styled(Box)`
    border-bottom: 1px solid ${Color.GreyLight};
`

const HeaderItem = styled(Text)`
    color: ${Color.Primary};
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`
