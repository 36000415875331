import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { CleanableTextarea } from '../input/CleanableTextarea'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledTextAreaProps extends SeleniumProps, MarginBoxProps {
    keyPrefix: string
    value?: string | null
    isRequired?: boolean
    isError?: boolean
    errorMessage?: string
    type?: string
    variant?: ThemeVariant
    resizeable?: boolean
    rows?: number
    onChangeText?(v: string): void
    onBlur?(): void
}

export const LabeledTextArea: FCWithChildren<LabeledTextAreaProps> = ({
    variant,
    isRequired,
    seleniumLocation,
    value,
    onChangeText,
    onBlur,
    isError,
    errorMessage,
    keyPrefix,
    resizeable,
    rows = 3,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={errorMessage ?? translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <CleanableTextarea
                variant={variant}
                placeholder={translateSuffix('placeholder')}
                value={value}
                onChangeText={onChangeText}
                rows={rows}
                resizeable={resizeable}
                onBlur={onBlur}
            />
        </LabeledFormElement>
    )
}
