import { History } from 'history'
import React, { PropsWithChildren, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export let rootHistory: History | null = null

export const ToastNavigationConnectorWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const history = useHistory()
    useEffect(() => {
        rootHistory = history
    }, [history])
    return <>{children}</>
}
