import { PREFERENCES_LOCATION_CHANGED } from 'aos-services/src/core/auth/actions'
import { analyticsService } from 'aos-ui/src/components/base/AnalyticsService'
import { Location } from 'history'
import { LOCATION_CHANGE, replace } from 'redux-first-history'
import { put, select, takeEvery } from 'redux-saga/effects'

import { getNonHiddenParentPath, isOnHiddenRoute } from './selectors'

type LocationAction = {
    payload: {
        location: Location
    }
    type: string
}

function* locationChangedSaga() {
    yield takeEvery<LocationAction>(LOCATION_CHANGE, function* (action) {
        analyticsService.pageView(action.payload.location.pathname, action.payload.location.search)
    })
}

function* navigateAwayFromHiddenRouteSaga() {
    yield takeEvery([LOCATION_CHANGE, PREFERENCES_LOCATION_CHANGED], function* () {
        const shouldNavigateAway: boolean = yield select(isOnHiddenRoute)
        if (shouldNavigateAway) {
            const targetRoute: string = yield select(getNonHiddenParentPath)
            yield put(replace(targetRoute))
        }
    })
}

export const locationSagas = [locationChangedSaga, navigateAwayFromHiddenRouteSaga]
