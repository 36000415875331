import { parentActionWrapper } from 'aos-helpers/src/helpers/ActionCreator'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { NavigationMapElementType } from 'aos-services/src/services/mapui/types/NavigationMapElement'
import { navigateToMapAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { airportMapMapParentAction } from '../../../core/airportMap/actions'
import { ChangedValueCell } from '../../common/cells/ChangedValueCell'

interface ParkCellProps extends ParkCellDispatchProps {
    row: Flight
}

export const ParkCellComponent: FC<ParkCellProps> = props => {
    const { park, isHel } = props.row

    if (!park) {
        return null
    }

    const navigate = () => {
        if (isHel && park.current) {
            props.navigateToMap({
                type: NavigationMapElementType.Stand,
                standId: park.current,
            })
        }
    }

    return <ChangedValueCell newValue={park.current} oldValue={park.previous} onClick={navigate} />
}

interface ParkCellDispatchProps {
    navigateToMap: typeof navigateToMapAction
}

export const ParkCell = connect<null, ParkCellDispatchProps>(null, {
    navigateToMap: parentActionWrapper(airportMapMapParentAction, navigateToMapAction),
})(ParkCellComponent)
