import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'

import {
    AnimalAge,
    AnimalGender,
    AnimalSpecie,
    AnimalsTask,
    AnimalsTaskType,
    AnimalType,
} from '../task/AnimalsTask'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface AnimalsTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.AnimalPrevention
    animalTaskType?: AnimalsTaskType
    animalSpecies?: AnimalSpecie
    amount?: number
    cartridges?: number
    expulsions?: number
    gender?: AnimalGender
    lifeStage?: AnimalAge
    horns?: boolean
    amountOfSpikes?: number
}

export const initialAnimalsTaskPayload: AnimalsTaskFormPayload = {
    type: TaskType.AnimalPrevention,
    ...initialBaseTaskPayload,
}

export interface AnimalsTaskPayloadValidation extends BaseTaskPayloadValidation {
    taskType: boolean
    animalSpecies: boolean
    amount: boolean
    expulsions: boolean
    cartridges: boolean
    gender: boolean
    lifeStage: boolean
    amountOfSpikes: boolean
}

export const validateAnimalsTask = (t: AnimalsTaskFormPayload): AnimalsTaskPayloadValidation => ({
    ...baseValidateTask(t),
    taskType: isNotEmpty(t.animalTaskType),
    animalSpecies: isNotEmpty(t.animalSpecies),
    amount: isNotEmpty(t.amount) && t.amount! >= 0,
    cartridges:
        (t.animalTaskType !== AnimalsTaskType.Kill &&
            t.animalTaskType !== AnimalsTaskType.Banishment) ||
        (isNotEmpty(t.cartridges) && t.cartridges! >= 0),
    expulsions:
        t.animalTaskType !== AnimalsTaskType.Banishment ||
        (isNotEmpty(t.expulsions) && t.expulsions! >= 0),
    title: isNotEmpty(t.title),
    lifeStage:
        t.animalSpecies?.animalType === AnimalType.Mammal &&
        t.animalSpecies.horns &&
        t.animalTaskType === AnimalsTaskType.Kill
            ? isNotEmpty(t.lifeStage)
            : true,
    gender:
        t.animalSpecies?.animalType === AnimalType.Mammal &&
        t.animalSpecies.horns &&
        t.animalTaskType === AnimalsTaskType.Kill
            ? isNotEmpty(t.gender)
            : true,
    amountOfSpikes: t.horns
        ? isNotEmpty(t.amountOfSpikes) && t.amountOfSpikes! > 0 && t.amountOfSpikes! <= 50
        : true,
})

export const animalsTaskFormPayloadFromTask = (t: AnimalsTask): AnimalsTaskFormPayload => ({
    type: TaskType.AnimalPrevention,
    ...baseTaskFormPayloadFromTask(t),
    animalTaskType: t.animalTaskType,
    animalSpecies: t.animalSpecies,
    amount: t.amount,
    cartridges: t.cartridges,
    expulsions: t.expulsions,
    title: t.title,
    description: t.description,
    gender: t.gender,
    lifeStage: t.lifeStage,
    horns: t.horns,
    amountOfSpikes: t.amountOfSpikes,
})
