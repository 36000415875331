import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { AnimalsTaskType } from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface AnimalTaskTypeIconProps {
    taskType: AnimalsTaskType
    iconVariant?: IconVariant
    iconSize?: BlockSize
}

export const animalTaskTypeIcons: Record<AnimalsTaskType, Svg> = {
    [AnimalsTaskType.Observation]: SvgIcon.TaskAnimalObservation,
    [AnimalsTaskType.Banishment]: SvgIcon.TaskAnimalBanishment,
    [AnimalsTaskType.Confirmation]: SvgIcon.TaskAnimalConfirmation,
    [AnimalsTaskType.Kill]: SvgIcon.TaskAnimalKill,
}

export const AnimalTaskTypeIcon: FC<AnimalTaskTypeIconProps> = ({
    taskType,
    iconVariant,
    iconSize = BlockSize.Std,
}) => <Icon iconSize={iconSize} svg={animalTaskTypeIcons[taskType]} iconVariant={iconVariant} />
