import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { useDebounceValue } from 'aos-ui-common/src/components/hooks/useDebounce'
import FuzzySearch from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'

import { useStateEffect } from '../../../../hooks/useStateEffect'

type SearchFunction<T> = (value: string) => Promise<T[]>
export type DropdownItems<T> = T[] | SearchFunction<T>

export const useDropdownSearch = <T>(
    query: string,
    items: DropdownItems<T>,
    toItemString: (item: T) => string,
) => {
    const [dropdownItems, setDropdownItems] = useStateEffect(
        () => (Array.isArray(items) ? items : []),
        [items],
    )
    const searchValue = useDebounceValue(query.trim(), 300)

    const searchIndex = useMemo(() => {
        if (!Array.isArray(items)) {
            return null
        }
        return new FuzzySearch(items.map(toItemString))
    }, [items])

    const [loadingCount, setLoadingCount] = useState(0)
    const incLoading = () => setLoadingCount(count => count + 1)
    const decLoading = () => setLoadingCount(count => count - 1)

    useEffect(() => {
        if (!searchValue) {
            setDropdownItems(Array.isArray(items) ? items : [])
            return
        }

        if (Array.isArray(items)) {
            if (!searchIndex) {
                return
            }
            setDropdownItems(searchIndex.search(query).map(record => items[record.refIndex]))
        } else {
            incLoading()
            items(searchValue)
                .then(results => {
                    decLoading()
                    setDropdownItems(results)
                })
                .catch(error => {
                    decLoading()
                    logger.handleError(error, '[useDropdownSearch] Async search failed')
                })
        }
    }, [searchValue])

    return {
        isLoading: loadingCount > 0,
        dropdownItems,
    }
}
