export enum PointId {
    /* Security Point 3 is not in use anymore, but as the value comes from backend,
     * changing it would be multi-step operation:
     * 1. Duplicate points to have both points with new value SEC Point 2 and old value
     * 2. Deploy to to production, wait until both frontend is updated
     *    and mobile app versions using the old value are no longer supported
     * 3. Remove the duplication logic from point 1, leaving just SEC Point 2
     *
     * IMO, it's not worth it to just to rename one constant.
     * See: https://siilisolutions.atlassian.net/browse/FINAVIA-2114
     */
    //Only points below are used right now
    Point9 = 'SEC Point 9',
    Dep = 'Security Departures',
}
