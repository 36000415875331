import { DeIcingChemicals } from './DeIcingChemicals'

export interface DeIcingChemicalUsage {
    id: number
    amount: number
    chemicals: DeIcingChemicals
    totalAmount: number
}

export const chemicalUsageToLabel = (v: DeIcingChemicalUsage) =>
    `${v.chemicals.label} (${v.totalAmount} kg | ${v.amount} g/m²)`
