import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { VerticalLegend } from 'aos-ui/src/components/chart/VerticalLegend'
import { Orientation } from 'aos-ui/src/components/ui/LegendItem'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const RegularityLegend: FC = () => (
    <Box fullHeight column justify='flex-end'>
        <VerticalLegend
            items={[
                {
                    label: translate('dashboard.regularity.legend.regular'),
                    labelColor: Color.UiBlack3,
                },
                {
                    label: translate('dashboard.regularity.legend.cancelled'),
                    labelColor: Color.Red,
                },
            ]}
            itemOrientation={Orientation.Horizontal}
        />
    </Box>
)
