import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from '../../../../aos-helpers/src/helpers/ActionCreator'
import { ContactList, UnitAirportContact } from '../../services/contactList/types/ContactItem'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'

export const LOAD_CONTACT_LIST = 'CONTACT_LIST/LOAD_CONTACT_LIST'
export const LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS = 'CONTACT_LIST/LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS'
export const LOAD_CONTACT_LIST_SUCCESS = 'CONTACT_LIST/LOAD_CONTACT_LIST_SUCCESS'
export const CHANGE_AIRPORT = 'CONTACT_LIST/CHANGE_AIRPORT'
export const SEARCH_CONTACT = 'CONTACT_LIST/SEARCH_CONTACT'

export type LoadUnitAirportContactsSuccessAction = PayloadAction<
    typeof LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS,
    UnitAirportContact[]
>
export type LoadContactListAction = Action<typeof LOAD_CONTACT_LIST>
export type LoadContactListSuccessAction = PayloadAction<
    typeof LOAD_CONTACT_LIST_SUCCESS,
    ContactList
>
export type ChangeAirportAction = PayloadAction<typeof CHANGE_AIRPORT, AosAirport>
export type SearchContactAction = PayloadAction<typeof SEARCH_CONTACT, string>

export const loadUnitAirportContactsSuccess = payloadActionCreator<
    LoadUnitAirportContactsSuccessAction,
    UnitAirportContact[]
>(LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS)
export const loadContactList = emptyActionCreator<LoadContactListAction>(LOAD_CONTACT_LIST)
export const loadContactListSuccess = payloadActionCreator<
    LoadContactListSuccessAction,
    ContactList
>(LOAD_CONTACT_LIST_SUCCESS)
export const changeAirport = payloadActionCreator<ChangeAirportAction, AosAirport>(CHANGE_AIRPORT)
export const searchContact = payloadActionCreator<SearchContactAction, string>(SEARCH_CONTACT)

export type BaseContactListActions =
    | LoadUnitAirportContactsSuccessAction
    | LoadContactListAction
    | LoadContactListSuccessAction
    | ChangeAirportAction
    | SearchContactAction
