import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { TextSize } from 'aos-ui/src/components/base/Text'
import { CheckableLabel } from 'aos-ui/src/components/text/CheckableLabel'
import React, { forwardRef, ReactNode } from 'react'

import { ThemeVariant } from '../../base/ThemeVariant'
import { Radio } from './Radio'

interface RadioGroupProps<T extends any> extends MarginBoxProps {
    value?: T
    name: string
    items: T[]
    position?: 'left' | 'right'
    variant?: ThemeVariant
    fontSize?: TextSize
    onChange(v: T): void
    formatter(v: T, isSelected: boolean): ReactNode
    vertical?: boolean
}

export const RadioGroup = forwardRef(
    <T extends any>({
        value,
        name,
        items,
        onChange,
        formatter,
        position = 'left',
        variant = ThemeVariant.White,
        fontSize = 14,
        vertical = false,
        ...margins
    }: RadioGroupProps<T>) => {
        const paddingLeft = position === 'left' ? 8 : 0
        const paddingRight = position === 'left' ? 16 : 0

        return (
            <Box row={!vertical} wrap data-test-id='radio-group' {...margins}>
                {items.map((item: T, index: number) => (
                    <Radio
                        key={index}
                        value={index}
                        onSelect={() => onChange(item)}
                        checked={item === value}
                        name={name}
                        position={position}
                        marginBottom={vertical ? 8 : 0}
                    >
                        <CheckableLabel
                            paddingLeft={paddingLeft}
                            paddingRight={paddingRight}
                            checked={item === value}
                            variant={variant}
                            fontSize={fontSize}
                        >
                            {formatter(item, value === item)}
                        </CheckableLabel>
                    </Radio>
                ))}
            </Box>
        )
    },
)
