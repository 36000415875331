import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import React, { FC } from 'react'

export const RunwaysMetar: FC<RunwaysMetarProps> = props => (
    <Text lineHeight='large' paddingBottom={8} paddingTop={8}>
        <span className='text__m-white margin-right--small '>
            {translate('dashboard.runways.metar.metar')}
        </span>
        <span className='text__m-grey-txt'>{props.metar}</span>
    </Text>
)

interface RunwaysMetarProps {
    metar: string
}
