import { BaseUserProps } from '../types/AosUser'
import { AosUserAddStatus } from '../types/AosUserFindStatus'
import { AosUserType } from '../types/AosUserType'

export interface BaseUserFormData extends BaseUserProps {
    id?: number
    type: AosUserType
    selectedGroupsIds: number[]
    userStatus: AosUserAddStatus
}

export function isExistingUserFormData(arg: BaseUserFormData): arg is BaseUserFormData {
    return !!arg.id
}
