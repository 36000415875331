import { loadFirebaseValue, snapshotToValueList } from 'aos-helpers/src/helpers/firebase/Firebase'
import { firebaseApp } from 'firebaseApp'

import { notificationDtoListToNotificationList } from '../../services/notifications/AosNotificationFactory'
import { AosNotificationDto } from './types/NotificationDto'

export const maxCountOfNotifications = 99
export const notificationsPageSize = 50

export class FirebaseNotificationRepository {
    public notificationsRef = (userId: string) =>
        this.itemRef(userId).limitToLast(maxCountOfNotifications)

    public markAllAsRead = (userId: string, itemIds: string[]) =>
        Promise.all(
            itemIds.map(id =>
                firebaseApp.database().ref(`notifications/${userId}/${id}`).update({ read: true }),
            ),
        )

    public loadNotificationsPage = (userId: string, start: number) => {
        if (start >= maxCountOfNotifications) {
            const ref = this.itemRef(userId).limitToLast(start + notificationsPageSize)
            // loaded notifications are always mark as read
            return loadFirebaseValue<[string, AosNotificationDto][]>(ref, snapshotToValueList).then(
                notificationDtoListToNotificationList(true),
            )
        }
        return Promise.resolve([])
    }

    private itemRef = (userId: string) =>
        firebaseApp.database().ref(`notifications/${userId}`).orderByChild('timestamp')
}

export const firebaseNotificationRepository = new FirebaseNotificationRepository()
