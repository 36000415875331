import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../services/firebase/firebaseService'
import { restrictionsService } from '../../services/restrictions/restrictionsService'
import { Restriction } from '../../services/restrictions/types/Restriction'
import {
    RESTRICTIONS_LOAD,
    RESTRICTIONS_SYNC,
    RESTRICTIONS_SYNC_STOP,
    RestrictionsLoadAction,
    restrictionsLoadAction,
    restrictionsLoadSuccessAction,
    RestrictionsSyncAction,
    RestrictionsSyncStopAction,
} from './actions'

const LISTENER_NAME = 'restrictions'

function* restrictionSyncSaga() {
    yield takeEvery<RestrictionsSyncAction>(RESTRICTIONS_SYNC, function* () {
        yield* firebaseService.onListener(LISTENER_NAME, function* () {
            yield syncValue(
                sendToSyncRepository.stsRef(SendToSyncType.RESTRICTIONS),
                restrictionsLoadAction,
                false,
            )
        }) as Generator
    })
}

function* restrictionSyncStopSaga() {
    yield takeEvery<RestrictionsSyncStopAction>(RESTRICTIONS_SYNC_STOP, function* () {
        yield firebaseService.offListener(LISTENER_NAME)
    })
}

function* restrictionLoadSaga() {
    yield takeEvery<RestrictionsLoadAction>(RESTRICTIONS_LOAD, function* () {
        const data: Restriction[] = yield call(restrictionsService.loadRestrictions)
        yield put(restrictionsLoadSuccessAction(data))
    })
}

export const restrictionsSagas = [restrictionSyncSaga, restrictionSyncStopSaga, restrictionLoadSaga]
