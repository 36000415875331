import { Chart } from 'aos-components/src/components/chart/Chart'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import { ControlPointConfig } from 'aos-services/src/services/queueingTime/types/ControlPointConfig'
import { QueueingDataTypeFilter } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { format } from 'd3-format'
import React, { PureComponent } from 'react'

import { QueueingTimeLegend } from './QueueingTimeLegend'

export interface QueueingTimeChartProps<T extends string> {
    series: Record<T, TimeValuePoint[]>
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    config: Record<T, ControlPointConfig>
    currentTime: DateTime
    filter: QueueingDataTypeFilter
}

export class QueueingTimeChart<T extends string> extends PureComponent<QueueingTimeChartProps<T>> {
    // tslint:disable:member-ordering
    private chartTooltip = (p: TimeValuePoint) => <p>{p.value}</p>
    public xAccessor = (d: TimeValuePoint): DateTime => d.time
    public yAccessor = (d: TimeValuePoint): number => d.value

    public render() {
        const { xDomain, yDomain, currentTime, config, filter } = this.props
        return (
            <Chart
                xConfig={{
                    domain: xDomain,
                    tickFormat: formatHour,
                }}
                y1Config={{
                    tickFormat: format('d'),
                    domain: yDomain,
                    baseValues: [15],
                    unit: filter === QueueingDataTypeFilter.WaitTime ? Unit.Minute : Unit.People,
                }}
                bottomLegend={{
                    height: 30,
                    component: <QueueingTimeLegend config={config} />,
                }}
                currentTime={currentTime}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { series, config } = this.props
        const { xScale, y1Scale } = props

        if (!xScale || !y1Scale) {
            return null
        }

        return (
            <>
                {Object.keys(series).map((k: T, index: number) => (
                    <LineSeries
                        key={index}
                        data={series[k]}
                        seriesConfig={{
                            color: config[k].color,
                            withDots: true,
                            tooltip: this.chartTooltip,
                        }}
                        accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                        scales={{ xScale, yScale: y1Scale }}
                    />
                ))}
            </>
        )
    }
}
