import { Box } from 'aos-ui/src/components/base/Box'
import { ModalContainer } from 'aos-ui/src/components/modal/ModalContainer'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { ModalWrapper } from 'aos-ui/src/components/modal/ModalWrapper'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { toggleWhatsNewAction } from '../../core/layout/actions'
import { isWhatsNewModalOpenSelector } from '../../core/layout/state'
import { WhatsNewFooter } from './WhatsNewFooter'
import { WhatsNewLogo } from './WhatsNewLogo'
import { WhatsNewMain } from './WhatsNewMain'

export const WhatsNewModalComponent: FC<WhatsNewModalProps> = props => {
    const { showModal, toggleWhatsNew } = props
    const close = () => toggleWhatsNew(false)

    return (
        <ModalWrapper
            disableFullScreen
            closeAction={close}
            isOpen={showModal}
            shouldCloseOnOverlayClick
        >
            <ModalContainer size={ModalKind.Auto}>
                <section className='flex-grid container--stretch-items whats-new'>
                    <Box
                        className='col-4 whats-new__left'
                        paddingVertical={30}
                        column
                        justify='space-between'
                    >
                        <WhatsNewLogo />
                        <WhatsNewFooter />
                    </Box>
                    <Box className='col-8' padding={20}>
                        <WhatsNewMain onClose={close} />
                    </Box>
                </section>
            </ModalContainer>
        </ModalWrapper>
    )
}

interface WhatsNewModalProps extends WhatsNewModalStateProps, WhatsNewModalDispatchProps {}

interface WhatsNewModalStateProps {
    showModal: boolean
}

interface WhatsNewModalDispatchProps {
    toggleWhatsNew: typeof toggleWhatsNewAction
}

export const WhatsNewModal = connect<WhatsNewModalStateProps, WhatsNewModalDispatchProps>(
    createStructuredSelector({
        showModal: isWhatsNewModalOpenSelector,
    }),
    { toggleWhatsNew: toggleWhatsNewAction },
)(WhatsNewModalComponent)
