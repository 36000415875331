import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

interface YearDropdownProps {
    values: number[]
    value: number
    onChange(v: number): void
    overlapping?: boolean
}

export const YearDropdown: FC<YearDropdownProps> = ({ value, values, overlapping, onChange }) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            items={values}
            valueRenderer={year => (overlapping ? `${year}-${year + 1}` : `${year}`)}
            onChange={onChange}
        />
    )
}
