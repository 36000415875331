import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Bar } from './Bar'

export interface ProgressBarConfig {
    leftColor: Color
    rightColor: Color
}

export interface ProgressBarProps {
    seriesConfig: ProgressBarConfig
    value: number
    minValue: number
}

export const ProgressBar: FCWithChildren<ProgressBarProps> = ({
    seriesConfig,
    value,
    minValue,
}) => {
    const realValue = Math.max(value - minValue, 0)
    const data = [realValue, 100 - value]
    return <Bar colors={[seriesConfig.leftColor, seriesConfig.rightColor]} data={data} />
}
