import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { BaseContactListActions } from 'aos-services/src/core/contactList/actions'
import {
    ContactDTO,
    ContactItem,
    UnitAirportContactDTO,
} from 'aos-services/src/services/contactList/types/ContactItem'

export const OPEN_CONTACT_MODAL = 'CONTACT_LIST/OPEN_CONTACT_MODAL'
export const CLOSE_CONTACT_MODAL = 'CONTACT_LIST/CLOSE_CONTACT_MODAL'
export const CREATE_CONTACT = 'CONTACT_LIST/CREATE_CONTACT'
export const EDIT_CONTACT = 'CONTACT_LIST/EDIT_CONTACT'
export const DELETE_CONTACT = 'CONTACT_LIST/DELETE_CONTACT'
export const OPEN_AIRPORT_UNIT_CONTACT_MODAL = 'CONTACT_LIST/OPEN_AIRPORT_UNIT_CONTACT_MODAL'
export const CLOSE_AIRPORT_UNIT_CONTACT_MODAL = 'CONTACT_LIST/CLOSE_AIRPORT_UNIT_CONTACT_MODAL'
export const CREATE_UNIT_AIRPORT_CONTACT = 'CONTACT_LIST/CREATE_UNIT_AIRPORT_CONTACT'
export const EDIT_UNIT_AIRPORT_CONTACT = 'CONTACT_LIST/EDIT_UNIT_AIRPORT_CONTACT'
export const DELETE_UNIT_AIRPORT_CONTACT = 'CONTACT_LIST/DELETE_UNIT_AIRPORT_CONTACT'

export type DeleteUnitAirportContactAction = PayloadAction<
    typeof DELETE_UNIT_AIRPORT_CONTACT,
    number
>
export type EditUnitAirportContactAction = PayloadAction<
    typeof EDIT_UNIT_AIRPORT_CONTACT,
    UnitAirportContactDTO
>
export type CreateContactAction = PayloadAction<
    typeof CREATE_CONTACT,
    { data: ContactItem; createAnother: boolean }
>
export type EditContactAction = PayloadAction<typeof EDIT_CONTACT, ContactDTO>
export type DeleteContactAction = PayloadAction<typeof DELETE_CONTACT, number>

export type OpenContactModalAction = PayloadAction<typeof OPEN_CONTACT_MODAL, ContactDTO | null>
export type CloseContactModalAction = Action<typeof CLOSE_CONTACT_MODAL>
export type OpenAirportUnitContactModalAction = PayloadAction<
    typeof OPEN_AIRPORT_UNIT_CONTACT_MODAL,
    { data: UnitAirportContactDTO | null; reopen: boolean }
>
export type CloseAirportUnitContactModalAction = Action<typeof CLOSE_AIRPORT_UNIT_CONTACT_MODAL>
export type CreateUnitAirportContactAction = PayloadAction<
    typeof CREATE_UNIT_AIRPORT_CONTACT,
    UnitAirportContactDTO
>

export const openContactModal = payloadActionCreator<OpenContactModalAction, ContactDTO | null>(
    OPEN_CONTACT_MODAL,
)
export const closeContactModal = emptyActionCreator<CloseContactModalAction>(CLOSE_CONTACT_MODAL)
export const createContact = payloadActionCreator<
    CreateContactAction,
    { data: ContactDTO; createAnother: boolean }
>(CREATE_CONTACT)
export const editContact = payloadActionCreator<EditContactAction, ContactDTO>(EDIT_CONTACT)
export const deleteContact = payloadActionCreator<DeleteContactAction, number>(DELETE_CONTACT)
export const openAirportUnitContactModal = payloadActionCreator<
    OpenAirportUnitContactModalAction,
    { data: UnitAirportContactDTO | null; reopen: boolean }
>(OPEN_AIRPORT_UNIT_CONTACT_MODAL)
export const closeUnitAirportContactModal = emptyActionCreator<CloseAirportUnitContactModalAction>(
    CLOSE_AIRPORT_UNIT_CONTACT_MODAL,
)
export const createUnitAirportContact = payloadActionCreator<
    CreateUnitAirportContactAction,
    UnitAirportContactDTO
>(CREATE_UNIT_AIRPORT_CONTACT)
export const editUnitAirportContact = payloadActionCreator<
    EditUnitAirportContactAction,
    UnitAirportContactDTO
>(EDIT_UNIT_AIRPORT_CONTACT)
export const deleteUnitAirportContact = payloadActionCreator<
    DeleteUnitAirportContactAction,
    number
>(DELETE_UNIT_AIRPORT_CONTACT)

export type ContactListActions =
    | OpenContactModalAction
    | CloseContactModalAction
    | CreateContactAction
    | EditContactAction
    | DeleteContactAction
    | OpenAirportUnitContactModalAction
    | CloseAirportUnitContactModalAction
    | CreateUnitAirportContactAction
    | BaseContactListActions
