import { DateTime, startOfNextMonth } from 'aos-helpers/src/helpers/Time'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'

import { GroupingAxis } from './GroupingAxis'

class MonthAxisComponent extends GroupingAxis<DateTime> {
    protected getGroupEnd = startOfNextMonth

    protected hideLabelIfTooLong = true

    protected defaultTickFormat = (date: DateTime) => date.format('MMMM YYYY')
}

export const MonthAxis = withChartContext(MonthAxisComponent)
