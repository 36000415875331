import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import React, { FC } from 'react'

import { UserNameAutocomplete, UserNameAutocompleteProps } from './UserNameAutocomplete'

interface LabeledUserNamesAutocompleteProps
    extends SeleniumProps,
        UserNameAutocompleteProps,
        MarginBoxProps {
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    errorMessage?: string
    inputVariant?: ThemeVariant
}

export const LabeledUserNameAutocomplete: FC<LabeledUserNamesAutocompleteProps> = ({
    keyPrefix,
    isRequired,
    isError,
    errorMessage,
    inputVariant = ThemeVariant.White,
    seleniumLocation,

    onChange,
    value,

    ...marginProps
}) => {
    const translateSuffix = (key: string) => translate(`${keyPrefix}.${key}`)

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={errorMessage ?? translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={inputVariant}
            {...marginProps}
        >
            <UserNameAutocomplete
                value={value}
                onChange={onChange}
                placeholder={translateSuffix('placeholder')}
            />
        </LabeledFormElement>
    )
}
