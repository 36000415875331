import { BaseMapTask, newBaseAosMapTask } from '../../../map/types/MapTask'
import { AnimalsTask } from './AnimalsTask'
import { DeIcingTask } from './DeIcingTask'
import { InspectionTask } from './InspectionTask'
import { MaintenanceTask } from './MaintenanceTask'
import { RescueServiceTask } from './RescueServiceTask'
import { SimpleTask } from './SimpleTask'

export type Task =
    | AnimalsTask
    | MaintenanceTask
    | DeIcingTask
    | RescueServiceTask
    | InspectionTask
    | SimpleTask

export const mapTaskForTask = (task: Task): BaseMapTask | null => {
    if (task.location) {
        return newBaseAosMapTask(task.location, task)
    }
    return null
}
