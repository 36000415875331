import { Feature, FeatureCollection, GeometryObject, Point } from 'geojson'
import GeoJSON from 'ol/format/GeoJSON'

import { TravelServicesSubTheme } from '../properties/TravelServicesProperties'
import { RingRailTrainsInfo } from './RingRailTrain'

export type LayerData<T> = FeatureCollection<GeometryObject, T>
export type PointLayerData<T> = FeatureCollection<Point, T>

export interface LayersDataState {
    gates?: GeoJSON
    busGates?: GeoJSON
    arrivalServices?: GeoJSON
    securityCheck?: GeoJSON
    checkInKiosk?: GeoJSON
    checkInDesks?: GeoJSON
    baggageClaim?: GeoJSON
    customs?: GeoJSON
    passportControl?: GeoJSON
    parking?: GeoJSON
    stands?: GeoJSON
    busDoors?: GeoJSON
    ringRailTrainsInfo?: RingRailTrainsInfo
    walkingRoutes?: GeoJSON
    walkingRoutesIcon?: GeoJSON
    basemapServiceName?: string
}

export interface LayersDataStateAware {
    layersData: LayersDataState
}

export type LayerDataKey = keyof LayersDataState

export const travelServicesSubThemeToLayerDataKey: Record<TravelServicesSubTheme, LayerDataKey> = {
    [TravelServicesSubTheme.ArrivalServices]: 'arrivalServices',
    [TravelServicesSubTheme.SecurityCheck]: 'securityCheck',
    [TravelServicesSubTheme.CheckInKiosk]: 'checkInKiosk',
    [TravelServicesSubTheme.CheckInDesks]: 'checkInDesks',
    [TravelServicesSubTheme.BaggageClaim]: 'baggageClaim',
    [TravelServicesSubTheme.Customs]: 'customs',
    [TravelServicesSubTheme.PassportControl]: 'passportControl',
}

export const findPointInLayerData = <T>(
    data: GeoJSON | undefined,
    predicate: f.Func1<T, boolean>,
): Feature<Point, T> | undefined =>
    // @ts-ignore
    data ? data.features.find(d => predicate(d.properties)) : undefined
