import { DateTime } from 'aos-helpers/src/helpers/Time'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconBoxButton } from 'aos-ui/src/components/buttons/IconBoxButton'
import { CurrentTime } from 'aos-ui/src/components/CurrentTime'
import { JumpToDate } from 'aos-ui/src/components/form/datetime/JumpToDate'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useState } from 'react'

import { EventTimelineFilter } from '../../core/eventTimeline/state'
import { EventSeveritySelector } from './EventSeveritySelector'
import { ExportEventsModal } from './ExportEventsModal'

interface EventTimelineHeaderProps {
    filter: EventTimelineFilter
    currentTime: DateTime

    onSelectDate(d: DateTime): void
    jumpToNow(): void
    zoomIn(): void
    zoomOut(): void
    exportEvents(r: TimeRange): void
    setFilter(f: EventTimelineFilter): void
}

export const EventTimelineHeader: FC<EventTimelineHeaderProps> = props => {
    const [exportEventOpen, setExportEventOpen] = useState(false)
    const submit = (r: TimeRange) => {
        setExportEventOpen(false)
        props.exportEvents(r)
    }

    const selectJumpDate = (date: DateTime | undefined) => {
        if (date) {
            props.onSelectDate(date)
        }
    }

    const setSeveritiesFilter = (severitiesFilter: EventTimelineFilter) => {
        props.setFilter(severitiesFilter)
    }

    return (
        <Header variant={HeaderVariant.Dialog} paddingLeft={30} paddingRight={16}>
            <Box shrink={0}>
                <EventSeveritySelector onChange={setSeveritiesFilter} filter={props.filter} />
            </Box>
            <Box flex={1} />
            <Box shrink={0} row>
                <JumpToDate marginRight={30} onChange={selectJumpDate} />
                <CurrentTime marginRight={30} onClick={props.jumpToNow} date={props.currentTime} />
                <IconBoxButton
                    id='zoom-in'
                    svg={SvgIcon.ZoomIn}
                    marginRight={8}
                    onClick={props.zoomIn}
                />
                <IconBoxButton id='zoom-out' svg={SvgIcon.ZoomOut} onClick={props.zoomOut} />
                <More marginLeft={16}>
                    <MoreItem onClick={() => setExportEventOpen(true)}>
                        {translate('export-events.title')}
                    </MoreItem>
                </More>
            </Box>
            <ExportEventsModal
                isOpen={exportEventOpen}
                onClose={() => setExportEventOpen(false)}
                onSubmit={submit}
            />
        </Header>
    )
}
