import { chain } from 'lodash'
import { call, put, takeEvery } from 'redux-saga/effects'

import { contactListService } from '../../services/contactList/contactListService'
import {
    ContactItem,
    ContactList,
    UnitAirportContact,
} from '../../services/contactList/types/ContactItem'
import {
    LOAD_CONTACT_LIST,
    LoadContactListAction,
    loadContactListSuccess,
    loadUnitAirportContactsSuccess,
} from './actions'

function* loadContactListSaga() {
    yield takeEvery<LoadContactListAction>(LOAD_CONTACT_LIST, function* () {
        const result: ContactItem[] = yield call(contactListService.getContactList)
        const units: UnitAirportContact[] = yield call(contactListService.getUnitAirportContacts)

        const contactList = chain(result)
            .groupBy(q => q.unitAirportContact.id)
            .mapValues((item, key) => ({
                unit: units.find(q => q.id.toString() === key),
                contacts: item,
            }))
            .value() as ContactList

        yield put(loadContactListSuccess(contactList))
        yield put(loadUnitAirportContactsSuccess(units))
    })
}

export const baseContactListSagas = [loadContactListSaga]
