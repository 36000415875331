import { DateTime } from 'aos-helpers/src/helpers/Time'

import { AnimalSpecie, AnimalType } from './task/AnimalsTask'
import { BrushesBrand } from './task/BrushesBrand'
import { BrushesVehicle } from './task/BrushesVehicle'
import { DeIcingArea } from './task/DeIcingArea'
import { DeIcingCar } from './task/DeIcingCar'
import { DeIcingChemicals } from './task/DeIcingChemicals'
import { FireTruck } from './task/FireTruck'
import { InspectionGroup } from './task/InspectionGroup'
import { MaintenanceJob } from './task/MaintenanceTask'
import { RescueServiceTaskType } from './task/RescueServiceTask'

export interface TaskMetadata {
    animalSpecies: AnimalSpecie[]
    deicingCars: DeIcingCar[]
    deicingAreas: DeIcingArea[]
    deicingChemicals: DeIcingChemicals[]
    maintenanceJobs: MaintenanceJob[]
    rescueServiceTaskTypes: RescueServiceTaskType[]
    inspectionTypes: InspectionMetadataType[]
    brushesVehicles: BrushesVehicle[]
    fireTrucks: FireTruck[]
    brushesBrands: BrushesBrand[]
}

export interface InspectionMetadataType {
    id: number
    name: string
    period?: number
    inspectionGroup: InspectionGroup
    taskEndTime?: DateTime
}

export const animalSpeciesForType = (metadata: TaskMetadata, animalType?: AnimalType) =>
    animalType
        ? metadata.animalSpecies.filter(s => s.animalType === animalType)
        : metadata.animalSpecies
