import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { goBack } from 'redux-first-history'

class BackButtonClass extends PureComponent<BackButtonDispatchProps> {
    public render() {
        return (
            <IconButton
                iconSize={BlockSize.Std}
                iconVariant={IconVariant.White}
                svg={SvgIcon.Back}
                onClick={this.props.goBack}
            />
        )
    }
}

interface BackButtonDispatchProps {
    goBack: typeof goBack
}

export const BackButton = connect<{}, BackButtonDispatchProps, {}>(null, {
    goBack,
})(BackButtonClass)
