import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { EventMapUserLayerVisibilityPayload, EventMapUserLayerVisibilityState } from './state'

export const LOAD_EVENT_MAP_USER_LAYER_VISIBILITY =
    'EVENT_MAP_USER_LAYERS_VISIBILITY/LOAD_EVENT_MAP_USER_LAYER_VISIBILITY'
export type LoadEventMapUserLayerVisibilityAction = Action<
    typeof LOAD_EVENT_MAP_USER_LAYER_VISIBILITY
>
export const loadEventMapUserLayerVisibilityAction = emptyActionCreator<LoadEventMapUserLayerVisibilityAction>(
    LOAD_EVENT_MAP_USER_LAYER_VISIBILITY,
)

export const LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS =
    'EVENT_MAP_USER_LAYERS_VISIBILITY/LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS'
export type LoadEventMapUserLayerVisibilitySuccessAction = PayloadAction<
    typeof LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS,
    EventMapUserLayerVisibilityPayload
>
export const loadEventMapUserLayerVisibilitySuccessAction = payloadActionCreator<
    LoadEventMapUserLayerVisibilitySuccessAction,
    EventMapUserLayerVisibilityPayload
>(LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS)

export const SET_EVENT_MAP_USER_LAYERS_VISIBILITY =
    'EVENT_MAP_USER_LAYERS_VISIBILITY/SET_EVENT_MAP_USER_LAYERS_VISIBILITY'
export type SetEventMapUserLayersVisibilityAction = PayloadAction<
    typeof SET_EVENT_MAP_USER_LAYERS_VISIBILITY,
    EventMapUserLayerVisibilityState
>
export const setEventMapUserLayersVisibilityAction = payloadActionCreator<
    SetEventMapUserLayersVisibilityAction,
    EventMapUserLayerVisibilityState
>(SET_EVENT_MAP_USER_LAYERS_VISIBILITY)

export type EventMapUserLayerVisibilityAction =
    | LoadEventMapUserLayerVisibilityAction
    | LoadEventMapUserLayerVisibilitySuccessAction
    | SetEventMapUserLayersVisibilityAction
