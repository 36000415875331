import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import Zoom from 'ol/control/Zoom'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { BaseComponent } from '../../base/BaseComponent'

export class ZoomControl extends BaseComponent<{}, {}> {
    private control: Zoom
    private zoomInNode: HTMLElement
    private zoomOutNode: HTMLElement

    constructor(props: {}) {
        super(props)
        this.zoomInNode = document.createElement('div')
        this.zoomOutNode = document.createElement('div')
        this.control = new Zoom({
            zoomInLabel: this.zoomInNode,
            zoomOutLabel: this.zoomOutNode,
        })
    }

    public componentDidMount() {
        const rootZoomInNode = createRoot(this.zoomInNode)
        rootZoomInNode.render(
            <Icon
                svg={SvgIcon.ZoomIn}
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Small}
            />,
        )

        const rootZoomOutNode = createRoot(this.zoomOutNode)
        rootZoomOutNode.render(
            <Icon
                svg={SvgIcon.ZoomOut}
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Small}
            />,
        )

        this.context.map.addControl(this.control)
    }
}
