import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { TaskOrMessageTemplate } from 'aos-services/src/services/events/types/AosEventChecklist'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

const icons: Record<TaskOrMessageTemplate, Svg> = {
    [TaskOrMessageTemplate.Task]: SvgIcon.Checklist,
    [TaskOrMessageTemplate.MessageTemplate]: SvgIcon.Message,
}

interface ChecklistTabBarProps {
    value: TaskOrMessageTemplate
    hasMessageTemplates: boolean
    changeTab(v: TaskOrMessageTemplate): void
}

export const ChecklistTabBar: FC<ChecklistTabBarProps> = props => {
    const items = props.hasMessageTemplates
        ? [TaskOrMessageTemplate.Task, TaskOrMessageTemplate.MessageTemplate]
        : [TaskOrMessageTemplate.Task]
    return (
        <BlockRadioGroup
            items={items}
            value={props.value}
            Renderer={({ isSelected, item, onSelect }) => (
                <IconButton
                    onClick={e => {
                        e.stopPropagation()
                        onSelect()
                    }}
                    svg={icons[item]}
                    iconVariant={isSelected ? IconVariant.White : IconVariant.BlackGrey}
                    iconSize={BlockSize.Std}
                />
            )}
            onChange={props.changeTab}
        />
    )
}
