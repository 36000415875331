import {
    DateTime,
    dateTime,
    daysFrom,
    hoursFrom,
    startOfDay,
    startOfHour,
} from 'aos-helpers/src/helpers/Time'
import { startOfTimeOfDay, TimeOfDay } from 'aos-helpers/src/helpers/TimeOfDay'
import { EnumValues } from 'enum-values'
import { maxBy } from 'lodash'

import { aggregateByTime } from '../../../../services/airportStatus/base/types/TimePoint'
import {
    groupByArrDep,
    groupByDataSource,
    groupByLocalTransfer,
    PaxForecast,
    sumPax,
} from '../../../../services/airportStatus/paxForecast/types/PaxForecast'
import {
    PaxForecastChartData,
    PaxForecastChartType,
    PaxForecastDaysChartPoint,
    PaxForecastHoursChartPoint,
    PaxForecastTimeOfDayChartPoint,
} from '../../../../services/airportStatus/paxForecast/types/PaxForecastChartData'
import { PaxForecastDataSource } from '../../../../services/airportStatus/paxForecast/types/PaxForecastDataSource'
import { PaxForecastDayViewType } from '../../../../services/statusDashboard/types/filters/paxForecast/PaxForecastDayViewType'
import {
    filterPaxForecast,
    PaxForecastFilters,
} from '../../../../services/statusDashboard/types/filters/paxForecast/PaxForecastFilters'
import { PaxForecastTimeRange } from '../../../../services/statusDashboard/types/filters/paxForecast/PaxForecastTimeRange'

const getDaysChartData = (points: PaxForecast, domain: DateTime[]): PaxForecastDaysChartPoint[] =>
    aggregateByTime(points, startOfDay, groupByDataSource, domain)

const getHoursChartData = (points: PaxForecast, domain: DateTime[]): PaxForecastHoursChartPoint[] =>
    aggregateByTime(points, startOfHour, groupByArrDep(sumPax), domain)

const getTimeOfDayChartData = (
    points: PaxForecast,
    domain: DateTime[],
): PaxForecastTimeOfDayChartPoint[] =>
    aggregateByTime(points, startOfTimeOfDay, groupByLocalTransfer(sumPax), domain)

function getDaysChartDomain(points: PaxForecast, startDay: DateTime, daysRange: number) {
    const timeRangeEnd = startDay.clone().add(daysRange)
    const lastDay: DateTime =
        maxBy(
            points.map(p => p.time),
            time => time.valueOf(),
        ) || timeRangeEnd
    return daysFrom(startDay, lastDay.diff(startDay, 'days') + 1)
}

export const getChartData = (
    paxForecast: PaxForecast,
    filters: PaxForecastFilters,
    currentDay: DateTime,
): PaxForecastChartData | null => {
    if (paxForecast.length === 0) {
        return null
    }

    const points = filterPaxForecast(filters, paxForecast, currentDay)
    const forecastPoints = points.filter(p => p.dataSource === PaxForecastDataSource.Forecast)

    const startDay = dateTime(
        filters.timeRange !== PaxForecastTimeRange.Custom ? currentDay : filters.customDate,
    ).startOf('day')

    if (PaxForecastTimeRange.Next6Weeks === filters.timeRange) {
        const domain = getDaysChartDomain(points, startDay, filters.timeRange)
        return {
            type: PaxForecastChartType.Days,
            data: getDaysChartData(points, domain),
        }
    } else if (filters.dayViewType === PaxForecastDayViewType.Hours) {
        const domain = hoursFrom(startDay, 24)
        return {
            type: PaxForecastChartType.Hours,
            data: getHoursChartData(forecastPoints, domain),
        }
    } else {
        const domain = EnumValues.getValues<TimeOfDay>(TimeOfDay).map(t =>
            startOfTimeOfDay(startDay, t),
        )
        return {
            type: PaxForecastChartType.TimeOfDay,
            data: getTimeOfDayChartData(forecastPoints, domain),
        }
    }
}
