import { Duration } from 'moment'

import { FirebaseHealthForAirportItemType } from '../../../dataaccess/widgetHealths/types/FirebaseHealthForAirportItemType'
import { FirebaseHealthItemType } from '../../../dataaccess/widgetHealths/types/FirebaseHealthItemType'

export interface HealthConfigRegular {
    healths: FirebaseHealthItemType[]
    duration: Duration
}

export interface HealthConfigForAirport {
    healthsForAirport: FirebaseHealthForAirportItemType[]
    duration: Duration
}

export type HealthConfig = HealthConfigRegular | HealthConfigForAirport

export const isHealthConfigForAirport = (
    healthConfig: HealthConfig,
): healthConfig is HealthConfigForAirport => 'healthsForAirport' in healthConfig
