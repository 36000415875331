import { getMinMax } from '../helpers/getMinMax'
import { GaugeOptions } from '../types/GaugeOptions'
import { GaugeTickPosition } from '../types/GaugeTickPosition'
import { getCorner } from './getCorner'

export const getTickPosition = (
    s: number,
    angle: number,
    radius: number,
    options: GaugeOptions,
): GaugeTickPosition => {
    const { size, ranges } = options
    const width = size / 80
    const height = size / 40

    const [min, max] = getMinMax(ranges)
    const offset = size / Math.abs(min - max)
    const cornerOffset = Math.max(width / 2, offset)

    const corner = getCorner(s, angle, radius, cornerOffset)
    return {
        x: corner.x,
        y: corner.y,
        angle: radToDeg(corner.angle),
        width,
        height,
    }
}

const radToDeg = (radians: number) => (radians * 180) / Math.PI
