import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { useEffect } from 'react'

export const useLoadUserBimLayersVisibility = (
    userLayerVisibility: UserLayerVisibilityState,
    loadUserLayerVisibilityAction: () => void,
) => {
    useEffect(() => {
        if (userLayerVisibility.isLoading) {
            loadUserLayerVisibilityAction()
        }
    }, [])
}
