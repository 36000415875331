import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { FodDto } from 'aos-services/src/services/fod/types'

export const LOAD_SINGLE_FOD = 'FOD/LOAD_SINGLE_FOD'
export type LoadSingleFodAction = Action<typeof LOAD_SINGLE_FOD>
export const loadSingleFod = emptyActionCreator<LoadSingleFodAction>(LOAD_SINGLE_FOD)

export const LOAD_SINGLE_FOD_SUCCESS = 'FOD/LOAD_SINGLE_FOD_SUCCESS'
export type LoadSingleFodSuccessAction = PayloadAction<typeof LOAD_SINGLE_FOD_SUCCESS, FodDto>
export const loadSingleFodSuccess = payloadActionCreator<LoadSingleFodSuccessAction, FodDto>(
    LOAD_SINGLE_FOD_SUCCESS,
)

export const DELETE_FOD = 'FOD/DELETE_FOD'
export type DeleteFodAction = PayloadAction<typeof DELETE_FOD, number>
export const deleteFod = payloadActionCreator<DeleteFodAction, number>(DELETE_FOD)

export type FodSyncActions = LoadSingleFodAction | LoadSingleFodSuccessAction

export const DELETE_FOD_SUCCESS = 'FOD/DELETE_FOD_SUCCESS'
export type DeleteFodSuccessAction = PayloadAction<typeof DELETE_FOD_SUCCESS, number>
export const deleteFodSuccess = payloadActionCreator<DeleteFodSuccessAction, number>(
    DELETE_FOD_SUCCESS,
)
