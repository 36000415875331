import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'

import { ControlPointConfig } from './ControlPointConfig'

export enum SecurityControlPoint {
    SEC_BOX = 'SEC_BOX',
    SEC_T9 = 'SEC_T9',
    BC_ALL = 'BC_ALL',
    BC_GROUP = 'BC_GROUP',
}
export const allSecurityControlPoints: SecurityControlPoint[] = EnumValues.getValues<SecurityControlPoint>(
    SecurityControlPoint,
)

export const translateSecurityControlPoint = translateEnum<SecurityControlPoint>(
    SecurityControlPoint,
    'dashboard.queueing-time.security-control-point',
)

export const securityControlPointConfig: Record<SecurityControlPoint, ControlPointConfig> = {
    [SecurityControlPoint.SEC_BOX]: {
        color: Color.SeaBlue,
        fullName: 'Departures',
        code: 'SEC DEP',
    },
    [SecurityControlPoint.SEC_T9]: {
        color: Color.SoftBlue,
        fullName: 'Transfer',
        code: 'SEC TRF',
    },
    [SecurityControlPoint.BC_GROUP]: {
        color: Color.SoftMagenta,
        fullName: 'Border Control Departures',
        code: 'BC DEP',
    },
    [SecurityControlPoint.BC_ALL]: {
        color: Color.PaleOrange,
        fullName: 'Border Control Arrivals',
        code: 'BC ARR',
    },
}
