import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { ReactComponentLike } from 'prop-types'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { reportTypeSelector } from '../../../core/tasks/reports/selectors/filters'
import { ReportType } from '../../../core/tasks/reports/types/ReportType'
import { FilterType, TasksHeaderFilter } from '../common/TasksHeaderFilter'
import { AnimalsReport } from './animals/AnimalsReport'
import { ChemicalsReport } from './chemicals/ChemicalsReport'

interface TasksReportsProps extends TasksReportsStateProps {}

const TasksReportsComponent: FC<TasksReportsProps> = ({ reportType }) => {
    const Report = reportTypesRenderers[reportType]

    return (
        <PanelWithHeader
            header={<HeaderContainer title={<TasksHeaderFilter value={FilterType.Reports} />} />}
        >
            <Report />
        </PanelWithHeader>
    )
}

const reportTypesRenderers: Record<ReportType, ReactComponentLike> = {
    [ReportType.Chemicals]: ChemicalsReport,
    [ReportType.Animals]: AnimalsReport,
}

interface TasksReportsStateProps {
    reportType: ReportType
}

export const TasksReports = connect<TasksReportsStateProps>(
    createStructuredSelector({
        reportType: reportTypeSelector,
    }),
)(TasksReportsComponent)
