import { AxisTop } from 'aos-components/src/components/chart/axes/AxisTop'
import { Chart } from 'aos-components/src/components/chart/Chart'
import { calculateDomain, domainForTicks } from 'aos-helpers/src/helpers/domain/Domain'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import {
    getTimeOfDay,
    timeOfDayHourRangeString,
    translateTimeOfDay,
} from 'aos-helpers/src/helpers/TimeOfDay'
import { PaxForecastTimeOfDayChartPoint } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { totalPaxForecast } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { PaxForecastArrivalDepartureFilter } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import React, { PureComponent } from 'react'

import { PaxForecastTimeOfDayChartLegend } from './PaxForecastTimeOfDayChartLegend'
import { PaxForecastTimeOfDayTripleBarSeries } from './PaxForecastTimeOfDayTripleBarSeries'

interface PaxForecastTimeOfDayChartProps {
    data: PaxForecastTimeOfDayChartPoint[]
    arrivalDepartureFilter: PaxForecastArrivalDepartureFilter
}

export class PaxForecastTimeOfDayChart extends PureComponent<PaxForecastTimeOfDayChartProps> {
    public render() {
        const { data, arrivalDepartureFilter } = this.props
        const yDomain = calculateDomain(data, totalPaxForecast, 7)
        return (
            <Chart
                flex
                xBandConfig={{
                    domain: domainForTicks(this.getXDomain()),
                    tickFormat: formatHour,
                    bandPadding: { x0: { inner: 0.66, outer: 0.45 } },
                    barCount: 3,
                    showGrids: false,
                }}
                y1Config={{
                    domain: yDomain,
                    tickFormat: formatNumber,
                    showAxisLine: false,
                }}
                topAxisSize={60}
                showBottomAxis={false}
                bottomLegend={{
                    height: 30,
                    component: (
                        <PaxForecastTimeOfDayChartLegend
                            arrivalDepartureFilter={arrivalDepartureFilter}
                        />
                    ),
                }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { xBandScale, y1Scale, xBandConfig } = props
        if (!xBandScale || !y1Scale || !xBandConfig) {
            return null
        }
        const { data, arrivalDepartureFilter } = this.props
        const barSeriesScales = {
            x0Scale: xBandScale.x0,
            x1Scale: xBandScale.x1,
            yScale: y1Scale,
        }
        return (
            <>
                <AxisTop
                    axisConfig={{
                        tickValues: barSeriesScales.x0Scale.domain(),
                        tickFormat: (d: DateTime) =>
                            translateTimeOfDay(getTimeOfDay(d)) +
                            '\n' +
                            timeOfDayHourRangeString(getTimeOfDay(d)),
                        tickMarked: (d: DateTime) => getTimeOfDay(d) === getTimeOfDay(dateTime()),
                    }}
                    scale={barSeriesScales.x0Scale}
                />
                <PaxForecastTimeOfDayTripleBarSeries
                    data={data}
                    arrivalDepartureFilter={arrivalDepartureFilter}
                    scales={barSeriesScales}
                />
            </>
        )
    }

    private xAccessor = (d: PaxForecastTimeOfDayChartPoint) => d.time

    private getXDomain() {
        return this.props.data.map(this.xAccessor)
    }
}
