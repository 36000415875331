import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { ResponsiveWrapper } from 'aos-ui/src/components/ui/ResponsiveWrapper'
import React, { FC } from 'react'

import { BigItemStatsContainer } from '../../base/ItemContainer'
import { PaxForecastStatsBig } from './PaxForecastStatsBig'
import { PaxForecastStatsSmall } from './PaxForecastStatsSmall'

interface PaxForecastStatsSectionProps {
    stats: PaxForecastStats
}

export const PaxForecastStatsSection: FC<PaxForecastStatsSectionProps> = ({ stats }) => (
    <BigItemStatsContainer>
        <ResponsiveWrapper
            alignItems='flex-start'
            flex={1}
            alternativeContent={() => <PaxForecastStatsSmall stats={stats} />}
        >
            <PaxForecastStatsBig stats={stats} />
        </ResponsiveWrapper>
    </BigItemStatsContainer>
)
