import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

import { Box, MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { Text } from '../../base/Text'
import { ThemeVariant } from '../../base/ThemeVariant'
import { FormLabel } from './FormLabel'

interface LabeledRowFormElementProps extends SeleniumProps, MarginBoxProps {
    prefixLabel: ReactNode
    suffixLabel?: ReactNode
    isRequired?: boolean
    isError?: boolean
    errorMessage?: ReactNode
    variant?: ThemeVariant
    fullWidth?: boolean
}

export const LabeledRowFormElement: FCWithChildren<LabeledRowFormElementProps> = ({
    children,
    prefixLabel,
    suffixLabel,
    isError,
    isRequired,
    seleniumLocation,
    errorMessage,
    variant = ThemeVariant.White,
    fullWidth,
    ...marginProps
}) => (
    <Box data-test-id={`labeled-${seleniumLocation}`} {...marginProps} fullWidth={fullWidth}>
        <Box row>
            <FormLabel variant={variant} isError={isError} paddingRight={0}>
                {prefixLabel}
            </FormLabel>
            {children}
            <FormLabel variant={variant} isError={isError} isRequired={isRequired}>
                {suffixLabel}
            </FormLabel>
        </Box>
        {isError && errorMessage && (
            <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                {errorMessage}
            </Text>
        )}
    </Box>
)
