import { ContactItem, ContactList } from '../../services/contactList/types/ContactItem'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'

const applySearch = (contact: ContactItem, search: string) => {
    if (!search) {
        return true
    }

    const searchLower = search.toLowerCase().replace(/\s/g, '')

    return (
        contact.name.replace(/\s/g, '').toLowerCase().includes(searchLower) ||
        contact.description?.replace(/\s/g, '').toLowerCase().includes(searchLower) ||
        contact.email?.toLowerCase().includes(searchLower) ||
        contact.phoneNumber.replace(/\s/g, '').toLowerCase().includes(searchLower) ||
        contact.link?.toLowerCase().includes(searchLower) ||
        contact.linkLabel?.replace(/\s/g, '').toLowerCase().includes(searchLower)
    )
}

export const selectContactListWithFilter = (
    currentAirport: AosAirport,
    contactList: ContactList,
    search: string = '',
) => {
    if (!currentAirport) {
        return contactList
    }

    return Object.keys(contactList).reduce((acc, key) => {
        const unitContact = contactList[key]
        const filteredContacts = unitContact.contacts.filter(
            contact =>
                contact.siteLocations.includes(currentAirport) && applySearch(contact, search!),
        )

        if (filteredContacts.length > 0) {
            acc[key] = {
                ...unitContact,
                contacts: filteredContacts,
            }
        }

        return acc
    }, {} as ContactList)
}
