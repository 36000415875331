import {
    ChartContextProps,
    chartContextProvider,
    ChartProviderProps,
} from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { ChartConfig } from 'aos-ui-common/src/components/chart/types/Chart'
import React, { FC, PropsWithChildren, PureComponent } from 'react'
import { SizeMeProps } from 'react-sizeme'

import { ClassNameProps, cxp } from '../base/ClassNames'

class ChartClass extends PureComponent<PropsWithChildren<ChartProps & ChartContextProps>> {
    public render() {
        const {
            outerSize: { width, height },
        } = this.props
        return (
            <svg className={cxp(this.props)} width={width || 0} height={height || 0}>
                {this.props.children}
            </svg>
        )
    }
}

interface ChartProps extends ClassNameProps {
    chartConfig: ChartConfig
}

const ChartComponent = chartContextProvider<ChartProps & ChartProviderProps>(ChartClass)

/**
 * @deprecated
 */
export const LegacyChart: FC<PropsWithChildren<ChartProps & SizeMeProps>> = props => {
    const { size, chartConfig } = props
    const outerSize = { width: size.width || 0, height: size.height || 0 }

    return (
        <ChartComponent
            {...props}
            className={props.className}
            outerSize={outerSize}
            chartConfig={chartConfig}
        />
    )
}
