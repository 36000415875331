import { lonLatToCoordinate } from 'aos-helpers/src/helpers/coordinate/CoordinateTransformer'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import {
    SelectedMapElementType,
    selectionTargetKey,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import { differenceWith } from 'lodash'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'

import { getMapMarkerIconStyleForUrl } from '../../styleBuilder/eventLayerStyleBuilder'
import { BaseLayerProps } from '../base/BaseLayer'
import { BaseMarkerLayer } from '../base/BaseMarkerLayer'

interface MarkerLayerProps<T extends BaseItem> extends BaseLayerProps {
    items: T[]
    selectionTarget: SelectedMapElementType
    iconRenderer(v: Omit<T, 'id' | 'location'>): Promise<string>
    comparator(v1: T, v2: T): boolean
    setter(v: T): { [k: string]: any }
}

interface BaseItem {
    id: number
    location: AosLocation
}

export class MarkerLikeLayer<T extends BaseItem> extends BaseMarkerLayer<MarkerLayerProps<T>> {
    protected updateMarkers(nextProps: MarkerLayerProps<T>, prevProps?: MarkerLayerProps<T>) {
        const nextItems = nextProps.items
        const prevItems = prevProps ? prevProps.items : []
        const itemsToDelete = differenceWith(prevItems, nextItems, this.props.comparator)
        const itemsToAdd = differenceWith(nextItems, prevItems, this.props.comparator)
        itemsToDelete.forEach(e => this.removeFeatureById(e.id))
        Promise.all(itemsToAdd.map(this.itemToMarker)).then(f => this.markerSource.addFeatures(f))
    }

    private itemToMarker = async (item: T): Promise<Feature> => {
        const point = new Point(lonLatToCoordinate(item.location))
        const feature = new Feature(point)
        const src = await this.props.iconRenderer(item)
        feature.setStyle([getMapMarkerIconStyleForUrl(src)])
        feature.setId(item.id)
        feature.set(selectionTargetKey, this.props.selectionTarget)
        feature.setProperties(this.props.setter(item), true)
        return feature
    }
}
