import { SyncWrapper } from 'aos-frontend/src/app/components/sync/SyncWrapper'
import {
    restrictionsSyncAction,
    restrictionsSyncStopAction,
} from 'aos-services/src/core/restrictions/actions'
import {
    timelineScrollAction,
    timelineTimeChangeAction,
} from 'aos-services/src/core/timeline/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Timeline } from 'aos-ui/src/components/timeline/Timeline'
import { useDebounce } from 'aos-ui-common/src/components/hooks/useDebounce'
import { RestrictionBarContent } from 'aos-ui-restriction/src/components/restriction/RestrictionBarContent'
import { isEqual } from 'lodash'
import React, { FC } from 'react'
import { positionValues } from 'react-custom-scrollbars'
import { connect } from 'react-redux'

import {
    selectItemAction,
    standsGatesTimelineTimelineParentAction,
    toggleGroupAction,
} from '../../../core/standsGatesTimeline/actions'
import {
    standsGatesTimelineSelector,
    StandsGatesTimelineSelectorState,
} from '../../../core/standsGatesTimeline/selectors'
import { SidebarStack } from './sidebars/SidebarStack'
import { StandsGatesGroupRenderer } from './StandsGatesGroupRenderer'
import { StandsGatesTimelineFiltersModal } from './StandsGatesTimelineFiltersModal'

interface StandsGatesTimelineProps
    extends StandsGatesTimelineDispatchProps,
        StandsGatesTimelineSelectorState {}

const StandsGatesTimelineClass: FC<StandsGatesTimelineProps> = props => {
    const {
        currentTime,
        timelineState,
        timelineAction,
        groups,
        items,
        toggleGroup,
        selectItem,
    } = props

    const saveScrollPosition = useDebounce((newPosition: positionValues) => {
        if (!isEqual(timelineState.savedPosition, newPosition)) {
            timelineAction(timelineScrollAction(newPosition))
        }
    }, 1000)

    const onTimeChange = (ts: number, te: number) =>
        timelineAction(timelineTimeChangeAction([ts, te]))

    return (
        <SyncWrapper
            onEnter={[restrictionsSyncAction('timeline')]}
            onLeave={[restrictionsSyncStopAction('timeline')]}
        >
            <Box fullHeight row alignItems='stretch' flex={1}>
                <Box flex={1}>
                    <DarkScrollbar
                        onScroll={saveScrollPosition}
                        savedPosition={timelineState.savedPosition}
                    >
                        <Timeline
                            items={items}
                            groups={groups}
                            timeline={timelineState}
                            onTimeChange={onTimeChange}
                            openItem={item => selectItem(item)}
                            currentTime={currentTime}
                            ContentRenderer={({ item, inTooltip }) => (
                                <RestrictionBarContent item={item.payload} inTooltip={inTooltip} />
                            )}
                            GroupRenderer={wrapper => (
                                <StandsGatesGroupRenderer
                                    group={wrapper.group}
                                    toggleGroup={toggleGroup}
                                />
                            )}
                            toggleGroup={toggleGroup}
                            itemHeightRatio={0.9}
                        />
                    </DarkScrollbar>
                </Box>
                <SidebarStack />
            </Box>
            <StandsGatesTimelineFiltersModal />
        </SyncWrapper>
    )
}

interface StandsGatesTimelineDispatchProps {
    toggleGroup: typeof toggleGroupAction
    timelineAction: typeof standsGatesTimelineTimelineParentAction
    selectItem: typeof selectItemAction
}

export const StandsGatesTimeline = connect<
    StandsGatesTimelineSelectorState,
    StandsGatesTimelineDispatchProps
>(standsGatesTimelineSelector, {
    toggleGroup: toggleGroupAction,
    timelineAction: standsGatesTimelineTimelineParentAction,
    selectItem: selectItemAction,
})(StandsGatesTimelineClass)
