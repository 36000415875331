import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum AosHandlingAgent {
    APR = 'APR',
    AVI = 'AVI',
    DHL = 'DHL',
    FIN = 'FIN',
    GNP = 'GNP',
    SWP = 'SWP',
    JFG = 'JFG',
    Unknown = 'UN',
}

export const mapHandlingAgentToCompany = (agent: AosHandlingAgent) =>
    translateEnum<AosHandlingAgent>(
        AosHandlingAgent,
        'flight-information.handling-agent',
        AosHandlingAgent.Unknown,
    )(agent)
