import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { areObjectValuesEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { ReactNode } from 'react'

import { Box, MarginBoxProps, NumberValues } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { IconButton } from '../../buttons/IconButton'
import { IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'
import { EditableList, ItemRendererProps, ValidationType } from '../list/EditableList'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledListProps<T> extends MarginBoxProps, SeleniumProps {
    keyPrefix: string
    value: T[]
    isRequired?: boolean
    isError?: boolean
    readOnly?: boolean
    variant?: ThemeVariant
    emptyItem: T & { uuid: string }
    validation?: ValidationType[]
    spacing?: NumberValues
    separated?: boolean
    maxItems?: number
    showFirstValue?: boolean

    itemRenderer(p: ItemRendererProps<T>): ReactNode
    onChange(v: T[]): void
}

export const LabeledEditableList = <T extends {}>({
    keyPrefix,
    value,
    isRequired,
    isError,
    readOnly,
    variant,
    emptyItem,
    validation,
    spacing,
    separated,
    itemRenderer,
    onChange,
    maxItems,
    showFirstValue = true,
    ...restProps
}: LabeledListProps<T>) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    const update = (value: T[]) => {
        const filtered = value.filter(item => !areObjectValuesEmpty(item))
        onChange(filtered)
    }

    const addItem = () => {
        if (value.length === 0 && showFirstValue) {
            update([...value, emptyItem, emptyItem])
        } else {
            update([...value, emptyItem])
        }
    }

    const renderedValue = !value.length ? (showFirstValue ? [emptyItem] : []) : value

    const isMaxItem = maxItems ? value.length !== maxItems : true

    return (
        <LabeledFormElement
            label={
                <Box row flex={1}>
                    <Box flex={1}>
                        {translateSuffix('label')} {isRequired && '*'}
                    </Box>
                    {isMaxItem && (
                        <IconButton
                            iconSize={BlockSize.Std}
                            iconVariant={IconVariant.Grey}
                            svg={SvgIcon.ZoomIn}
                            padding={6}
                            onClick={addItem}
                        />
                    )}
                </Box>
            }
            isError={isError}
            errorMessage={translateSuffix('error')}
            variant={variant}
            {...restProps}
        >
            <EditableList
                value={renderedValue}
                itemRenderer={itemRenderer}
                onChange={update}
                validation={validation}
                spacing={spacing}
                separated={separated}
            />
        </LabeledFormElement>
    )
}
