import { TaskFormPayload } from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { every, isUndefined } from 'lodash'

export function removeUuidFromTaskPayload(payload: TaskFormPayload) {
    const payloadCopy = { ...payload }

    if ('items' in payloadCopy) {
        payloadCopy.items.forEach(item => 'uuid' in item && delete item.uuid)
    }

    if ('contractors' in payloadCopy) {
        payloadCopy.contractors?.forEach(
            contractor => 'uuid' in contractor && delete contractor.uuid,
        )
    }

    if ('assignments' in payloadCopy) {
        payloadCopy.assignments?.forEach(
            assignment => 'uuid' in assignment && delete assignment.uuid,
        )
    }

    return removeEmptyObjectsFromCollection(payloadCopy)
}

export function removeEmptyObjectsFromCollection(payload: TaskFormPayload) {
    const payloadCopy = { ...payload }

    if ('items' in payloadCopy) {
        payloadCopy.items = payloadCopy.items.filter(item => !every(item, isUndefined))
    }

    if ('contractors' in payloadCopy) {
        payloadCopy.contractors = payloadCopy.contractors.filter(
            contractor => !every(contractor, isUndefined),
        )
    }
    if ('assignments' in payloadCopy) {
        payloadCopy.assignments = payloadCopy.assignments?.filter(
            assignment => !every(assignment, isUndefined),
        )
    }

    return payloadCopy
}
