import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React from 'react'

interface SearchIconProps {
    toggleLayersSidebar(v: boolean): void
}

export const SearchIcon = ({ toggleLayersSidebar }: SearchIconProps) => (
    <IconButton
        svg={SvgIcon.SearchFilters}
        iconSize={BlockSize.Std}
        iconVariant={IconVariant.White}
        marginLeft={12}
        onClick={() => toggleLayersSidebar(true)}
    />
)
