import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../base/Box'
import { ThemeVariant } from '../base/ThemeVariant'
import { LabeledTextElement, LabeledTextElementSize } from '../form/labeled/LabeledTextElement'
import { AttachmentContainer } from './items/AttachmentContainer'

interface AttachmentReadonlySectionProps {
    attachments?: UploadedAttachment[]
    variant?: ThemeVariant
    size?: LabeledTextElementSize
    compact?: boolean
    noLabel?: boolean
}

export const AttachmentReadonlySection: FCWithChildren<AttachmentReadonlySectionProps> = ({
    attachments = [],
    variant,
    size,
    compact,
    noLabel,
}) =>
    attachments.length > 0 ? (
        <AttachmentReadonlySectionBox compact={compact}>
            <LabeledTextElement
                label={noLabel ? '' : `${translate('attachment.title')} (${attachments.length})`}
                spacing={8}
                marginTop={16}
                variant={variant}
                size={size}
            >
                <AttachmentContainer
                    attachmentsState={{ attachments, isUploading: false }}
                    editable={false}
                    gridSize={3}
                    variant={variant}
                />
            </LabeledTextElement>
        </AttachmentReadonlySectionBox>
    ) : null

const AttachmentReadonlySectionBox = styled(Box)<{ compact?: boolean }>`
    ${p =>
        p.compact &&
        css`
            max-width: 258px;
        `}
`
