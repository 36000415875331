import { AosEvent } from './AosEvent'
import { AosEventOrFeedIn } from './AosEventOrFeedIn'
import { AosFeedIn } from './AosFeedIn'

export interface AosEventGroup extends AosEvent {
    feedIns: AosFeedIn[]
}
export type AosEventGroupOrEventOrFeedIn = AosEventGroup | AosEventOrFeedIn

export function isAosEventGroup(arg: any): arg is AosEventGroup {
    return arg.isGroup
}
