import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'

interface TaskRef {
    severity: AosSeverity
}

export function taskItemIconVariant(inTooltip: boolean, item: TaskRef) {
    const isNotice = item.severity === AosSeverity.Notice
    return inTooltip || isNotice ? IconVariant.Black : IconVariant.White
}
