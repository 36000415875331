import { useIsMounted } from 'aos-ui-common/src/components/hooks/useIsMounted'
import { FCWithChildren, PropsWithChildren, RefObject, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { useAnimationEnd } from '../../../hooks/useAnimationEnd'
import { usePortal } from '../../../hooks/usePortal'
import { useSizeObserver } from '../../../hooks/useSizeObserver'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { DropdownContext } from './DropdownRoot'

export interface DropdownPortalProps {
    element: RefObject<Element>
    type?: DropdownPortalType
}

export const DropdownPortal: FCWithChildren<PropsWithChildren<DropdownPortalProps>> = props => {
    const context = useContext(DropdownContext)

    const dropdownRoot = context.dropdownRoot
    const container = context.dropdownRoot
    const { element, type = DropdownPortalType.ElementWidth } = props

    const target = usePortal(dropdownRoot)
    const size = useWindowSize()
    const elementSize = useSizeObserver(element)
    const animationTrigger = useAnimationEnd()
    const isMounted = useIsMounted()
    useEffect(() => {
        if (isMounted() && container.current && element.current) {
            const styles = rootStyleForType(type, container.current, element.current)
            Object.assign(target.style, styles)
        }
    }, [size, elementSize, element, animationTrigger])

    return ReactDOM.createPortal(props.children, target)
}

export enum DropdownPortalType {
    ElementWidth,
    BottomLeft,
    BottomRight,
    BottomCentered,
}

const rootStyleForType = (
    type: DropdownPortalType,
    root: Element,
    element: Element,
): Partial<CSSStyleDeclaration> => {
    const { left, top, height, width, right } = element.getBoundingClientRect()
    const scrollRect = root.getBoundingClientRect()
    const scrollTopOffset = -scrollRect.top + root.scrollTop
    const scrollLeftOffset = -scrollRect.left + root.scrollLeft

    switch (type) {
        case DropdownPortalType.ElementWidth:
            return {
                position: 'absolute',
                top: `${top + height + scrollTopOffset}px`,
                left: `${left + scrollLeftOffset}px`,
                width: `${width}px`,
            }
        case DropdownPortalType.BottomLeft:
            return {
                position: 'absolute',
                top: `${top + height + scrollTopOffset}px`,
                left: `${left + scrollLeftOffset}px`,
                minWidth: `${width}px`,
            }
        case DropdownPortalType.BottomRight:
            return {
                position: 'absolute',
                top: `${top + height + scrollTopOffset}px`,
                right: `${scrollRect.right - right}px`,
                minWidth: `${width}px`,
            }
        case DropdownPortalType.BottomCentered:
            return {
                position: 'absolute',
                top: `${top + height + scrollTopOffset}px`,
                left: `${left + scrollLeftOffset + width / 2}px`,
                transform: 'translate(-50%,0)',
            }
    }
}
