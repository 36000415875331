import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import React, { FCWithChildren } from 'react'
import styled, { css } from 'styled-components'

interface HeaderButtonWrapperProps extends BoxProps {
    disabled?: boolean
    onClick?(): void
}

export const HeaderButtonWrapper: FCWithChildren<HeaderButtonWrapperProps> = ({
    children,
    onClick,
    disabled,
    ...boxProps
}) => (
    <HeaderButtonBox
        disabled={disabled}
        centered
        paddingHorizontal={20}
        fullHeight
        onClick={onClick}
        {...boxProps}
    >
        {children}
    </HeaderButtonBox>
)

const HeaderButtonBox = styled(Box)<{ disabled?: boolean }>`
    min-width: 74px;
    position: relative;
    cursor: pointer;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    ${p =>
        p.disabled &&
        css`
            cursor: default;
            > * {
                opacity: 0.3;
            }
        `};
`
