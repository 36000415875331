import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { childActionMatcher, takeLatestChildAction } from 'aos-helpers/src/helpers/Saga'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import {
    JUMP_TO_DATE,
    JUMP_TO_NOW,
    jumpToNowAction,
    TIMELINE_TIME_CHANGE,
    ZOOM_IN,
    ZOOM_OUT,
} from 'aos-services/src/core/timeline/actions'
import { getTimelineLoadRange } from 'aos-services/src/core/timeline/state'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { eventsExportService } from 'aos-services/src/services/events/eventsExportService'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { AosTimelineEvent } from 'aos-services/src/services/events/types/AosTimelineEvent'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { call, debounce, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    EVENT_TIMELINE_TIMELINE_PARENT,
    eventTimelineTimelineParentAction,
    EXPORT_EVENTS,
    ExportEventsAction,
    LOAD_TIMELINE_EVENTS,
    loadEventsSuccessAction,
    loadTimelineEventsAction,
    OPEN_TIMELINE,
    SYNC_TIMELINE,
    SyncTimelineAction,
} from './actions'
import { EventTimelineStateAware } from './state'

function* loadEvents() {
    try {
        const timelineRange: TimeRange | undefined = yield select(
            (state: EventTimelineStateAware) =>
                getTimelineLoadRange(state.eventTimeline.timeline, state.eventTimeline.loadedRange),
        )
        if (timelineRange) {
            const events: AosTimelineEvent[] = yield call(
                eventsService.loadTimelineEvents,
                timelineRange,
            )
            yield put(loadEventsSuccessAction([events, timelineRange]))
        }
    } catch (e) {
        yield put(loadEventsSuccessAction([[], undefined]))
        throw e
    }
}

function* eventTimelineLoadEventSaga() {
    yield fork(function* () {
        yield takeLatest(LOAD_TIMELINE_EVENTS, loadEvents)
    })
    yield fork(function* () {
        yield takeLatestChildAction(
            EVENT_TIMELINE_TIMELINE_PARENT,
            [ZOOM_IN, ZOOM_OUT, JUMP_TO_DATE, JUMP_TO_NOW],
            loadEvents,
        )
    })
    yield fork(function* () {
        yield debounce(
            1000,
            childActionMatcher(EVENT_TIMELINE_TIMELINE_PARENT, TIMELINE_TIME_CHANGE),
            loadEvents,
        )
    })
}

function* openEventTimelineSaga() {
    yield takeEvery(OPEN_TIMELINE, function* openTimeline() {
        const hasItems: boolean = yield select(
            (state: EventTimelineStateAware) => !!state.eventTimeline.events.length,
        )
        if (!hasItems) {
            yield put(eventTimelineTimelineParentAction(jumpToNowAction()))
        } else {
            yield loadEvents()
        }
    })
}

function* syncDashboardEventsSts() {
    return (yield syncValue(
        sendToSyncRepository.stsRef(SendToSyncType.DASHBOARD),
        loadTimelineEventsAction,
        true,
    )) as Generator
}

function* exportEventsSaga() {
    yield takeEvery<ExportEventsAction>(EXPORT_EVENTS, function* (action: ExportEventsAction) {
        yield call(eventsExportService.exportEvents, action.payload)
    })
}

function* syncTimelineSaga() {
    yield takeEvery<SyncTimelineAction>(SYNC_TIMELINE, function* (action: SyncTimelineAction) {
        if (action.payload) {
            yield* firebaseService.onListener('eventTimeline', syncDashboardEventsSts) as Generator
        } else {
            yield firebaseService.offListener('eventTimeline')
        }
    })
}

export const eventTimelineSagas = [
    openEventTimelineSaga,
    syncTimelineSaga,
    eventTimelineLoadEventSaga,
    exportEventsSaga,
]
