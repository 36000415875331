import React, { forwardRef } from 'react'

import { Box } from '../../base/Box'
import { DatetimeInput } from '../datetime/DatetimeInput'
import { StartAndEndTimePickerProps } from './utils'

export const DateTimeInput = forwardRef<HTMLInputElement, StartAndEndTimePickerProps>(
    ({ value, label, onChange}) => {

        return (
            <Box column>
                <DatetimeInput
                    marginVertical={10}
                    value={value}
                    onChange={onChange}
                    placeholder={label}
                />
            </Box>
        )
    },
)
