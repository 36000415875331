import * as Sentry from '@sentry/browser'
import { LoggingProvider } from 'aos-helpers/src/helpers/logging/LoggingProvider'

import { AppConfig } from '../config/types/ExternalConfig'
import { LoggingConfigService } from './LoggingConfigService'

class SentryConfigService implements LoggingConfigService {
    public configure(config: AppConfig): LoggingProvider | null {
        if (config.sentryUrl) {
            Sentry.init({
                dsn: config.sentryUrl,
                environment: config.environmentName,
                release: config.version,
            })

            return {
                captureException: (ex: Error, options?: any) => {
                    if (options) {
                        Sentry.withScope(scope => {
                            scope.setExtras(options)
                            Sentry.captureException(ex)
                        })
                    } else {
                        Sentry.captureException(ex)
                    }
                },
            }
        }
        return null
    }
}

export const sentryConfigService = new SentryConfigService()
