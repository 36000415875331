import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { CommonAuthAction } from 'aos-services/src/core/auth/actions'
import { JwtTokenWrapper } from 'aos-services/src/services/auth/types/JwtToken'
import { AosAdUser } from 'aos-services/src/services/users/types/AosUser'

export const SIGN_IN_FROM_TOKEN = 'AUTH/SIGN_IN_FROM_TOKEN'
export type SignInFromTokenAction = PayloadAction<typeof SIGN_IN_FROM_TOKEN, JwtTokenWrapper>
export const signInFromTokenAction = payloadActionCreator<SignInFromTokenAction, JwtTokenWrapper>(
    SIGN_IN_FROM_TOKEN,
)

export const GET_USER_DETAILS = 'AUTH/GET_USER_DETAILS'
export type GetUserDetailsAction = Action<typeof GET_USER_DETAILS>
export const getUserDetailsAction = emptyActionCreator<GetUserDetailsAction>(GET_USER_DETAILS)

export const GET_USER_DETAILS_SUCCESS = 'AUTH/GET_USER_DETAILS_SUCCESS'
export type GetUserDetailsSuccessAction = PayloadAction<typeof GET_USER_DETAILS_SUCCESS, AosAdUser>
export const getUserDetailsSuccessAction = payloadActionCreator<
    GetUserDetailsSuccessAction,
    AosAdUser
>(GET_USER_DETAILS_SUCCESS)

export type AuthAction = CommonAuthAction | SignInFromTokenAction | GetUserDetailsSuccessAction
