import {
    AirportFilter,
    FilterOption,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'

import { UrlFiltersConfig } from '../helpers/urlFilters'
import { FlightInfoField } from './FlightInfoField'

export interface FlightColumnFilterItem {
    field: string
    visible: boolean
}

export interface FlightFilters {
    airport: AirportFilter // string
    arrivalsColumns: FlightColumnFilterItem[]
    departuresColumns: FlightColumnFilterItem[]
    filtersVisible: boolean
    sdt?: number
    hapt: string[]
    naflt: string[]
    fltnr?: string
    routes: string[]
    callsign?: string
    acreg?: string
    actype: string[]
    handl: string[]
    park?: string
    gate?: string
    est?: number
    pest?: string
    bltarea: string[]
    bltInd?: string
    chkarea?: string
    prm?: string
    act?: number
    ablk?: number
    bag?: string
    eibt?: number
    door: string[]
    rwy?: string
    tobt?: number
    tsat?: number
    paxTotal?: number
}

export type FlightFilterNames = (keyof FlightFilters)[]

const booleanFilters: FlightFilterNames = ['filtersVisible']
const numberFilters: FlightFilterNames = [
    'sdt',
    'est',
    'act',
    'ablk',
    'bag',
    'eibt',
    'tobt',
    'tsat',
    'paxTotal',
]
const stringFilters: FlightFilterNames = [
    'fltnr',
    'callsign',
    'acreg',
    'park',
    'gate',
    'pest',
    'bltInd',
    'chkarea',
    'prm',
    'rwy',
]
const arrayFilters: FlightFilterNames = [
    'naflt',
    'hapt',
    'routes',
    'handl',
    'bltarea',
    'door',
    'actype',
    'airport',
]
const columnsFilters: FlightFilterNames = ['arrivalsColumns', 'departuresColumns']

export const flightsUrlFiltersConfig: UrlFiltersConfig = {
    booleanFilters,
    numberFilters,
    stringFilters,
    columnsFilters,
    arrayFilters,
    fields: FlightInfoField,
}

export const defaultFlightFilters: FlightFilters = {
    airport: [FilterOption.All],
    filtersVisible: false,
    arrivalsColumns: [],
    departuresColumns: [],
    hapt: [],
    naflt: [],
    routes: [],
    handl: [],
    bltarea: [],
    door: [],
    actype: [],
}
