import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'

import { FontWeight, TextSize } from '../base/Text'
import { MultilineText } from '../text/MultilineText'
import { LegendDotConfig, LegendItemDot } from './LegendItemDot'

interface LegendItemProps extends MarginBoxProps {
    textColor?: Color
    textSize?: TextSize
    fontWeight?: FontWeight
    dotConfig: LegendDotConfig
    orientation?: Orientation
}

export const LegendItem: FCWithChildren<PropsWithChildren<LegendItemProps>> = props => {
    const {
        orientation = Orientation.Horizontal,
        textColor = Color.Grey,
        textSize = 12,
        dotConfig,
        fontWeight = 'regular',
        ...rest
    } = props

    switch (orientation) {
        case Orientation.Horizontal:
            return (
                <Box row {...rest}>
                    <LegendItemDot marginRight={8} {...dotConfig} />
                    <MultilineText color={textColor} size={textSize} weight={fontWeight}>
                        {props.children}
                    </MultilineText>
                </Box>
            )
        default:
            return (
                <Box column alignItems='center' {...rest}>
                    <LegendItemDot marginBottom={4} {...dotConfig} />
                    <MultilineText
                        color={textColor}
                        size={textSize}
                        textAlign='center'
                        weight={fontWeight}
                    >
                        {props.children}
                    </MultilineText>
                </Box>
            )
    }
}

export enum Orientation {
    Vertical = 1,
    Horizontal,
}
