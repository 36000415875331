import { isDefined } from 'aos-helpers/src/helpers/Function'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { emptyText } from 'aos-helpers/src/helpers/Text'
import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { LeftRight } from 'aos-ui/src/components/leftRight/LeftRight'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { DispersionBar, DispersionBarConfig } from './DispersionBar'

export interface DispersionDatum {
    value?: number
    displayValue?: string
    label: string
}

interface LabeledDispersionBarProps extends MarginBoxProps {
    barConfig: DispersionBarConfig
    barData: [DispersionDatum, DispersionDatum]
}

export const LabeledDispersionBar: FCWithChildren<LabeledDispersionBarProps> = ({
    barData: [leftValue, rightValue],
    barConfig,
    ...margins
}) => {
    const valueOrDisplayValue = (d: DispersionDatum) =>
        d.displayValue ? d.displayValue : isDefined(d.value) ? formatNumber(d.value) : emptyText()
    return (
        <Box flex='auto' {...margins}>
            <LeftRight
                paddingBottom={4}
                size={22}
                color={Color.White}
                weight='regular'
                left={valueOrDisplayValue(leftValue)}
                right={valueOrDisplayValue(rightValue)}
            />
            <LeftRight
                paddingBottom={8}
                size={12}
                weight='regular'
                color={Color.Grey}
                left={leftValue.label}
                right={rightValue.label}
            />
            <DispersionBar
                barConfig={barConfig}
                barData={[leftValue.value || 0, rightValue.value || 0]}
            />
        </Box>
    )
}
