import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { connect } from 'react-redux'

import { editAdUserActions, removeUserAction } from '../../../core/groupManager/actions'
import { State } from '../../../core/state'
import {
    AddAdUserModalsClass,
    AdUserModalsDispatchProps,
    AdUserModalsProps,
} from './AddAdUserModalsClass'

export const EditAdUserModal = connect<AdUserModalsProps, AdUserModalsDispatchProps, {}>(
    (state: State) => ({
        ...state.groupManager.editAdUserState,
        groups: state.groupManager.groups,
        headerTitle: translate('manage-user.ad-user.edit.title'),
    }),
    {
        ...editAdUserActions,
        removeUserAction,
        saveUserAction: editAdUserActions.updateAdUserAction,
    },
)(AddAdUserModalsClass)
