import {
    HoveredMapElement,
    HoveredMapElementType,
} from 'aos-services/src/services/map/types/HoveredMapElement'
import { EventBarContent } from 'aos-ui-event/src/components/event/EventBarContent'
import { TaskBarContent } from 'aos-ui-task/src/components/task/TaskBarContent'
import Feature from 'ol/Feature'
import { Pixel } from 'ol/pixel'
import Style from 'ol/style/Style'
import React from 'react'

import { featuresToHoveredMapElementsAndFeature } from '../../featureFilters/HoveredMapElementFeatureFilter'
import { renderMarkerMapOverlayIcon } from '../../icons/MapIconRenderer'
import { getMapMarkerIconStyleForUrl } from '../../styleBuilder/eventLayerStyleBuilder'
import {
    BaseInteractionCallbackParam,
    BaseMapInteractionHandler,
    BaseMapInteractionHandlerProps,
} from '../base/BaseMapInteractionHandler'
import { addStyleToFeature, removeLastStyleFromFeature } from '../base/FeatureUtils'
import { Overlay } from '../overlay/Overlay'

interface MapElementHoverOverlayState {
    hoveredItem?: HoveredMapElement
    hoveredFeature?: Feature
    overlayPositioning?: 'bottom-left' | 'bottom-right'
}

export class MapElementHoverOverlay extends BaseMapInteractionHandler<
    BaseMapInteractionHandlerProps,
    HoveredMapElement,
    MapElementHoverOverlayState
> {
    private overlayStyle: Style | null = null
    constructor(props: BaseMapInteractionHandlerProps) {
        super(props, featuresToHoveredMapElementsAndFeature, ['pointermove'])
        this.state = {}
    }

    public componentDidMount() {
        super.componentDidMount()
        renderMarkerMapOverlayIcon()
            .then(getMapMarkerIconStyleForUrl)
            .then(style => (this.overlayStyle = style))
    }

    public render() {
        const { hoveredItem } = this.state
        const location = hoveredItem?.payload?.location
        return (
            <Overlay
                position={location}
                isVisible={!!hoveredItem}
                positioning={this.state.overlayPositioning}
                stopEvent={false}
            >
                {hoveredItem && this.renderContent(hoveredItem)}
            </Overlay>
        )
    }

    protected onEventFeatureHover(param: BaseInteractionCallbackParam<HoveredMapElement>): void {
        this.displayPointer(param.event)
        const item = param.items.length ? param.items[0] : undefined
        const feature = param.features.length ? (param.features[0] as Feature) : undefined

        if (this.state.hoveredItem !== item) {
            if (this.state.hoveredFeature) {
                this.onElementHoverOut(this.state.hoveredFeature)
            }
            if (feature) {
                this.onElementHoverIn(feature)
            }
            this.setState({
                hoveredItem: item,
                hoveredFeature: feature,
                overlayPositioning: this.getPositioningForPixel(param.event.pixel),
            })
        }
    }

    private getPositioningForPixel = (pixel: Pixel): 'bottom-left' | 'bottom-right' => {
        const eventXPosition = pixel[0]
        const mapWidth = this.context.map.getSize()?.[0] ?? 0
        const maxOverlayWidth = 300

        if (eventXPosition + maxOverlayWidth > mapWidth) {
            return 'bottom-right'
        }

        return 'bottom-left'
    }

    private onElementHoverIn = (feature: Feature) => {
        addStyleToFeature(feature, this.overlayStyle)
    }

    private onElementHoverOut = (feature: Feature) => {
        removeLastStyleFromFeature(feature)
    }

    private renderContent = (hoveredItem: HoveredMapElement) => {
        switch (hoveredItem.type) {
            case HoveredMapElementType.Event:
                return <EventBarContent inTooltip item={hoveredItem.payload} />
            case HoveredMapElementType.Task:
                return <TaskBarContent inTooltip shorten item={hoveredItem.payload} />
        }
    }
}
