import { cx } from 'aos-components/src/components/base/ClassNames'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'
import { TableRowProps as ReactVirtualizedTableRowProps } from 'react-virtualized'

import { NowLine } from './NowLine'

export const ROW_HEIGHT = 50
export const NOW_ROW_HEIGHT = 100

interface TableRowProps {
    row: ReactVirtualizedTableRowProps
    now?: DateTime
    nowIndex?: number
}

export const TableRow: FC<TableRowProps> = props => {
    const { row, now, nowIndex } = props
    const showNowLine = row.index === nowIndex && now
    const odd = row.index % 2 === 0

    return (
        <Box
            style={row.style}
            className={cx('flight-table__row', { 'flight-table__row--odd': odd })}
        >
            {showNowLine && <NowLine now={now!} style={{ height: NOW_ROW_HEIGHT - ROW_HEIGHT }} />}
            <Box className={row.className} style={{ height: ROW_HEIGHT }}>
                {row.columns}
            </Box>
        </Box>
    )
}
