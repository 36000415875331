import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { TypedColumn } from 'aos-ui/src/components/table/TypedColumn'
import React from 'react'
import { connect } from 'react-redux'

import {
    changeSortingAction,
    lockNowAction,
    updateDeparturesFiltersAction,
} from '../../core/flightInformation/actions'
import { FlightFilters } from '../../core/flightInformation/flight/flightFiltersState'
import { FlightInfoField } from '../../core/flightInformation/flight/FlightInfoField'
import {
    departureTableSelector,
    FlightInfoTableSelectorState,
} from '../../core/flightInformation/flight/flightSelectors'
import { FlightSort } from '../../core/flightInformation/state'
import { CheckInCell } from './cells/CheckInCell'
import { FlightNumberCell } from './cells/FlightNumberCell'
import { GateCell } from './cells/GateCell'
import { NatureOfFlightCell } from './cells/NatureOfFlightCell'
import { ParkCell } from './cells/ParkCell'
import { RouteCell } from './cells/RouteCell'
import { AbstractFlightInfoTableClass, FlightInfoTableDispatchProps } from './FlightInfoTable'
import { FlightTableHeader, FlightTableHeaderSort } from './header/FlightTableHeader'

class FlightTableDeparturesClass extends AbstractFlightInfoTableClass {
    public type = FlightListType.Departures

    public renderColumns(): JSX.Element[] {
        return [
            this.textColumn({
                dataKey: FlightInfoField.paxTotal,
            }),
            this.timeColumn({
                dataKey: FlightInfoField.sdt,
                caption: 'sdt',
            }),
            this.checkboxDropdownColumn({
                dataKey: FlightInfoField.hapt,
                caption: 'apt',
            }),
            this.checkboxDropdownColumn(
                { dataKey: FlightInfoField.naflt },
                { cellRenderer: ({ rowData }) => <NatureOfFlightCell row={rowData} /> },
            ),
            this.textColumn(
                {
                    dataKey: FlightInfoField.fltnr,
                    width: 90,
                    maxWidth: 120,
                },
                {
                    cellRenderer: ({ rowData }) => (
                        <FlightNumberCell fltnr={rowData.nr} cflight={rowData.cflight} />
                    ),
                },
            ),
            this.checkboxDropdownColumn(
                {
                    dataKey: FlightInfoField.routes,
                    caption: 'route',
                    width: 50,
                    maxWidth: 100,
                },
                { cellRenderer: ({ rowData }) => <RouteCell row={rowData} /> },
            ),
            this.textColumn({
                dataKey: FlightInfoField.callsign,
                width: 80,
                maxWidth: 120,
            }),
            this.textColumn({
                dataKey: FlightInfoField.acreg,
                width: 70,
                maxWidth: 120,
            }),
            this.checkboxDropdownColumn({
                dataKey: FlightInfoField.actype,
                width: 70,
                maxWidth: 120,
            }),
            this.handlColumn({
                width: 60,
                maxWidth: 120,
            }),
            this.textColumn(
                {
                    dataKey: FlightInfoField.park,
                    caption: 'stand',
                    width: 90,
                    maxWidth: 120,
                },
                { cellRenderer: ({ rowData }) => <ParkCell row={rowData} /> },
            ),
            this.textColumn(
                {
                    dataKey: FlightInfoField.gate,
                    width: 70,
                },
                { cellRenderer: ({ rowData }) => <GateCell row={rowData} /> },
            ),
            this.timeColumn({
                dataKey: FlightInfoField.est,
                caption: 'eobt',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.act,
                caption: 'atot',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.ablk,
                caption: 'aobt',
            }),
            this.prmStatusColumn(),
            this.timeColumn({
                dataKey: FlightInfoField.pest,
                caption: 'public',
            }),
            this.timeColumn({
                dataKey: FlightInfoField.tobt,
            }),
            this.timeColumn({
                dataKey: FlightInfoField.tsat,
            }),
            this.chkColumn(),
        ]
    }

    protected override sortBy(sortKey: keyof FlightFilters): FlightTableHeaderSort {
        return {
            sortKey,
            sort: {
                key: this.props.sorting.field as FlightInfoField,
                direction: this.props.sorting.desc ? 'desc' : 'asc',
            },
            onChange: v =>
                this.props.changeSorting({
                    value: { field: v.key, desc: v.direction === 'desc' },
                    type: FlightSort.DEPARTURES,
                }),
        }
    }

    private chkColumn() {
        const { filtersVisible } = this.props.filters
        return (
            <TypedColumn<Flight>
                {...this.defaultColumnProps}
                width={105}
                key={FlightInfoField.chkarea}
                dataKey={FlightInfoField.chkarea}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={this.sortBy(FlightInfoField.chkarea)}
                        caption='chkarea'
                        filter={filtersVisible && this.renderStringFilter('chkarea')}
                    />
                )}
                cellRenderer={({ rowData }) => <CheckInCell row={rowData} />}
            />
        )
    }
}

export const FlightTableDepartures = connect<
    FlightInfoTableSelectorState,
    FlightInfoTableDispatchProps
>(departureTableSelector, {
    updateFilters: updateDeparturesFiltersAction,
    lockNow: lockNowAction,
    changeSorting: changeSortingAction,
})(FlightTableDeparturesClass)
