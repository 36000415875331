import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import React, { CSSProperties, FC } from 'react'

interface InspectionSelectedCounterProps {
    selectedInspectionsCounter: number
    inspectionTotalNumber: number
}
export const InspectionSelectedCounter: FC<InspectionSelectedCounterProps> = ({
    inspectionTotalNumber,
    selectedInspectionsCounter,
}) => (
    <div style={blockStyle}>
        <span style={spanStyles}>{selectedInspectionsCounter + '/' + inspectionTotalNumber}</span>
        <SvgImage style={checkBoxStyles} svg={SvgIcon.CheckboxChecked} />
    </div>
)

const blockStyle: CSSProperties = {
    position: 'absolute',
    top: '4px',
    right: '4px',
    lineHeight: 0,
}

const spanStyles: CSSProperties = {
    fontSize: '8px',
    verticalAlign: 'middle',
}

const checkBoxStyles: CSSProperties = {
    width: 8,
    height: 8,
    marginLeft: 2,
    verticalAlign: 'middle',
}
