import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { CircleIndicator, CircleIndicatorVariant } from 'aos-ui/src/components/ui/CircleIndicator'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { HeaderButtonWrapper } from './HeaderButtonWrapper'

interface HeaderButtonProps {
    icon: Svg
    text: string
    count?: number
    disabled?: boolean
    onClick(): void
}

export const HeaderButton: FCWithChildren<HeaderButtonProps> = ({
    disabled,
    onClick,
    icon,
    text,
    count,
}) => (
    <HeaderButtonWrapper
        disabled={disabled}
        centered
        paddingHorizontal={16}
        fullHeight
        onClick={disabled ? undefined : onClick}
    >
        <Box column centered>
            <Icon iconVariant={IconVariant.Black} iconSize={BlockSize.Large} svg={icon} />
            <Text paddingTop={8} color={Color.White} size={12}>
                {text}
            </Text>
        </Box>
        {!!count && (
            <Positioned top={7} right={7}>
                <CircleIndicator variant={CircleIndicatorVariant.Yellow}>{count}</CircleIndicator>
            </Positioned>
        )}
    </HeaderButtonWrapper>
)
