import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS'
export type LoadFeatureFlagsAction = Action<typeof LOAD_FEATURE_FLAGS>
export const loadFeatureFlags = emptyActionCreator<LoadFeatureFlagsAction>(LOAD_FEATURE_FLAGS)
export const LOAD_FEATURE_FLAGS_SUCCESS = 'LOAD_FEATURE_FLAGS_SUCCESS'
export type LoadFeatureFlagsSuccessAction = PayloadAction<
    typeof LOAD_FEATURE_FLAGS_SUCCESS,
    Record<string, boolean>
>

export const loadFeatureFlagsSuccess = payloadActionCreator<
    LoadFeatureFlagsSuccessAction,
    Record<string, boolean>
>(LOAD_FEATURE_FLAGS_SUCCESS)

export type FeatureFlagsAction = LoadFeatureFlagsAction | LoadFeatureFlagsSuccessAction
