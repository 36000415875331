import {
    DndDropSpec,
    DndItemType,
    DraggableItem,
    draggableWrapper,
    DropListProps,
    droppableWrapper,
} from 'aos-components/src/helpers/DnD'

interface EventItemProps<T extends DraggableItem> {
    event: T
}

const eventDropTargetSpec = <Props extends DropListProps<any>>(): DndDropSpec<Props> => ({
    drop(props, monitor) {
        if (monitor && props.onDrop) {
            if (monitor && monitor.didDrop()) {
                return
            }
            props.onDrop(monitor.getItem() as any)
        }
        return
    },
})

const eventSource = {
    beginDrag<T extends DraggableItem>(props: EventItemProps<T>) {
        return { id: props.event.id }
    },
}

export function droppableEventListWrapper<P>() {
    return droppableWrapper<P>(DndItemType.EventItem, {})
}

export function droppableEventWrapper<P>() {
    return droppableWrapper<P>(DndItemType.EventItem, eventDropTargetSpec() as DndDropSpec<P>)
}

export function draggableEventWrapper<T extends EventItemProps<any>>() {
    return draggableWrapper<T>(DndItemType.EventItem, eventSource)
}
