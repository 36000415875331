import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { attachmentReducer } from 'aos-services/src/core/attachments/reducer'
import { initialAttachmentsState } from 'aos-services/src/core/attachments/state'
import { AtcFieldsDtoSchema } from 'aos-services/src/dataaccess/feeds/types/AosCommonEventDto'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import { EventPayload } from 'aos-services/src/services/events/input/EventPayload'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosEventVisibility } from 'aos-services/src/services/events/types/AosEventVisibility'
import { newBaseAosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { PICK_LOCATION } from 'aos-ui-map/src/core/actions'
import { mapReducer } from 'aos-ui-map/src/core/reducer'
import { setPickedLocationFromEvent } from 'aos-ui-map/src/core/state'

import {
    ADD_ATC_LAYER,
    CHANGE_FORM_VALUE,
    CREATE_EVENT_SHOW_MODAL,
    CreateEventAction,
    EDIT_EVENT_SHOW_MODAL,
    EVENT_HIDE_MODAL,
    EVENT_MODALS_ATTACHMENTS_PARENT,
    EVENT_MODALS_MAP_PARENT,
    REMOVE_ATC_LAYER,
    REOPEN_EVENT_SHOW_MODAL,
    REPORT_EXTENDED_FEED_IN_SHOW_MODAL,
    REPORT_SIMPLE_FEED_IN_SHOW_MODAL,
    SAVE_LOCATION,
    SET_ATC_LAYERS,
    SET_EXTENDED_FORM_PRISTINE,
    SET_SIMPLE_FORM_PRISTINE,
    TRY_ACTIVATE_FEED_IN,
} from './actions'
import {
    emptyEvent,
    EventModal,
    EventModalsState,
    initialExtendedEventModalsState,
    initialSimpleEventModalsState,
    validateAosEventExtended,
    validateAosEventSimple,
} from './state'

export function eventModalsReducer(
    state = initialExtendedEventModalsState,
    action: CreateEventAction,
): EventModalsState {
    const initFromEventInternal = (
        e: EventPayload | AosEvent,
        attachments: UploadedAttachment[],
        parentId?: number,
    ) => {
        const getUserGroup = () => (e as AosEvent).privateGroup

        const newEvent = {
            visibility: e.visibility || AosEventVisibility.Public,
            severity: e.severity,
            process: e.process,
            category: e.category,
            title: e.title,
            description: e.description,
            startTime: e.startTime,
            endTime: e.endTime,
            location: e.location,
            atcAlert: e.atcAlert,
            atcFields: AtcFieldsDtoSchema.parse(e.atcFields || {}),
            aosUserGroup: getUserGroup(),
            exercise: e.exercise || false,
        }

        if (newEvent.atcAlert) {
            newEvent.visibility = AosEventVisibility.Restricted
        }

        return {
            ...initialExtendedEventModalsState,
            map: setPickedLocationFromEvent(initialExtendedEventModalsState.map, e),
            currentEvent: newEvent,
            extendedForm: FormValidation.fromFields(validateAosEventExtended(newEvent)),
            simpleForm: FormValidation.fromFields(validateAosEventSimple(newEvent)),
            parentFeedInId: parentId,
            attachmentsState: {
                ...initialAttachmentsState,
                attachments,
            },
        }
    }

    switch (action.type) {
        case EVENT_HIDE_MODAL:
            return {
                ...state,
                currentModal: EventModal.None,
            }

        case REPORT_SIMPLE_FEED_IN_SHOW_MODAL:
            return {
                ...initialSimpleEventModalsState,
                currentModal: EventModal.ReportSimpleFeedIn,
                currentEvent: emptyEvent,
                simpleForm: FormValidation.fromFields(validateAosEventSimple(emptyEvent)),
            }

        case REPORT_EXTENDED_FEED_IN_SHOW_MODAL:
            return {
                ...initialExtendedEventModalsState,
                currentModal: EventModal.ReportExtendedFeedIn,
                currentEvent: emptyEvent,
                extendedForm: FormValidation.fromFields(validateAosEventExtended(emptyEvent)),
            }

        case CREATE_EVENT_SHOW_MODAL:
            return {
                ...initialExtendedEventModalsState,
                currentModal: EventModal.CreateEvent,
            }

        case CHANGE_FORM_VALUE:
            return {
                ...state,
                currentEvent: action.payload,
                extendedForm: state.extendedForm.modify(validateAosEventExtended(action.payload)),
                simpleForm: state.simpleForm.modify(validateAosEventSimple(action.payload)),
            }

        case SET_SIMPLE_FORM_PRISTINE:
            return {
                ...state,
                simpleForm: state.simpleForm.setPristine(action.payload),
            }

        case SET_EXTENDED_FORM_PRISTINE:
            return {
                ...state,
                extendedForm: state.extendedForm.setPristine(action.payload),
            }

        case TRY_ACTIVATE_FEED_IN:
            return {
                ...initFromEventInternal(
                    action.payload,
                    action.payload.attachments,
                    action.payload.id,
                ),
                currentModal: EventModal.ActivateEvent,
            }
        case REOPEN_EVENT_SHOW_MODAL:
            return {
                ...state,
                ...action.payload,
                currentModal: EventModal.CreateEvent,
            }

        case EDIT_EVENT_SHOW_MODAL:
            return {
                ...initFromEventInternal(
                    action.payload,
                    action.payload.allAttachments,
                    action.payload.id,
                ),
                currentModal: EventModal.EditEvent,
            }

        case SAVE_LOCATION:
            const location = state.map.pickedEvent!.location
            return {
                ...state,
                currentEvent: {
                    ...state.currentEvent,
                    location: {
                        lon: location.lon,
                        lat: location.lat,
                        locationSource: state.map.variant,
                    },
                },
            }

        case ADD_ATC_LAYER:
            return {
                ...state,
                currentAtcLayers: [...state.currentAtcLayers, action.payload],
            }
        case REMOVE_ATC_LAYER:
            return {
                ...state,
                currentAtcLayers: state.currentAtcLayers.filter(l => l !== action.payload),
            }

        case SET_ATC_LAYERS:
            return {
                ...state,
                currentAtcLayers: action.payload,
            }
        case EVENT_MODALS_MAP_PARENT:
            switch (action.payload.type) {
                case PICK_LOCATION:
                    return {
                        ...state,
                        map: {
                            ...state.map,
                            pickedEvent: newBaseAosMapEvent(
                                action.payload.payload,
                                state.currentEvent,
                            ),
                        },
                    }
                default:
                    return {
                        ...state,
                        map: mapReducer(state.map, action.payload),
                    }
            }

        case EVENT_MODALS_ATTACHMENTS_PARENT:
            return {
                ...state,
                attachmentsState: attachmentReducer(state.attachmentsState, action.payload),
            }

        default:
            return state
    }
}
