import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { BaseCommonFlightFilters } from './CommonFlightFilters'

export enum FlightsGrouping {
    Hourly = 1,
    Total,
}

export const translateFlightsGrouping = translateEnum<FlightsGrouping>(
    FlightsGrouping,
    'dashboard.flights.stats-tab',
)

export interface ExtendedFlightsVolumeFilters extends BaseCommonFlightFilters, FlightsStats {}

interface FlightsStats {
    flightsStats: FlightsGrouping
}
