import { translate, translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskDuration } from 'aos-services/src/services/tasks/types/TaskStatus'
import React, { FC } from 'react'

import { Box } from '../../base/Box'
import { LabeledFormElement } from '../labeled/LabeledFormElement'
import { TimeField } from './TimeField'

interface TimePickerProps {
    value?: TaskDuration
    onChange(value: TaskDuration | undefined): void
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    readOnly?: boolean
}
export const TimePickerControlled: FC<TimePickerProps> = ({
    value,
    onChange,
    keyPrefix,
    isRequired,
    isError,
}) => {
    const translateSuffix = (v: string) => translateParts(keyPrefix, v)
    return (
        <LabeledFormElement
            marginBottom={16}
            label={translateSuffix('label')}
            isError={isError}
            isRequired={isRequired}
            errorMessage={translateSuffix('error')}
        >
            <Box row justify='space-between'>
                <TimeField
                    value={value?.hour}
                    onChange={hour => onChange({ minute: value?.minute ?? 0, hour })}
                    max={999}
                    label={translate('time-picker.hours')}
                />

                <TimeField
                    value={value?.minute}
                    onChange={minute => onChange({ hour: value?.hour ?? 0, minute })}
                    max={55}
                    step={5}
                    label={translate('time-picker.minutes')}
                />
            </Box>
        </LabeledFormElement>
    )
}
