import { ActionModalAction, HIDE_ACTION_MODAL, SHOW_ACTION_MODAL } from './actions'
import { ActionModalState, initialActionModalState } from './state'

export function actionModalReducer(
    state = initialActionModalState,
    action: ActionModalAction,
): ActionModalState {
    switch (action.type) {
        case SHOW_ACTION_MODAL:
            return { ...state, modalConfig: action.payload }
        case HIDE_ACTION_MODAL:
            return { ...state, modalConfig: undefined }
        default:
            return state
    }
}
