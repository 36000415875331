import {
    queueingTimeSecuritySelector,
    QueueingTimeSelectorState,
} from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { SecurityControlPoint } from 'aos-services/src/services/queueingTime/types/SecurityControlPoint'
import { connect } from 'react-redux'

import { setQueueingTimesFiltersAction } from '../../../../core/statusDashboard/actions'
import { QueueingTime, QueueingTimeDispatchProps, QueueingTimeOwnProps } from './QueueingTime'

export const QueueingTimeSecurity = connect<
    QueueingTimeSelectorState<SecurityControlPoint>,
    QueueingTimeDispatchProps,
    QueueingTimeOwnProps
>(queueingTimeSecuritySelector, {
    setQueueingTimesFiltersAction,
})(QueueingTime)
