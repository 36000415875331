/* tslint:disable:no-bitwise */
function getValueFromBuffer(buf: Uint32Array, idx: number) {
    return (buf[idx >> 3] >> ((idx % 8) * 4)) & 15
}

const max32Bit = Math.pow(2, 32) - 1

function random32Int() {
    return Math.floor(Math.random() * max32Bit)
}

export const uuid = () => {
    const buf = new Uint32Array([random32Int(), random32Int(), random32Int(), random32Int()])
    let idx = -1
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        idx++
        const r = getValueFromBuffer(buf, idx)
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
