import {
    jumpToDateAction,
    jumpToNowAction,
    timelineScrollAction,
    timelineTimeChangeAction,
    zoomInAction,
    zoomOutAction,
} from 'aos-services/src/core/timeline/actions'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { fromFlatId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Timeline } from 'aos-ui/src/components/timeline/Timeline'
import { useDebounce } from 'aos-ui-common/src/components/hooks/useDebounce'
import { TaskBarContent } from 'aos-ui-task/src/components/task/TaskBarContent'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { positionValues } from 'react-custom-scrollbars'
import { useDispatch, useSelector } from 'react-redux'

import {
    exportTasksAction,
    openTaskAction,
    openTimelineAction,
    setTimelineTaskFilterAction,
    syncTaskTimelineAction,
    taskManagerTimelineParentAction,
    toggleGroupAction,
} from '../../../core/tasks/actions'
import { taskTimelineSelector } from '../../../core/tasks/selectors'
import { TasksTimelineHeader } from './TasksTimelineHeader'
const INTERVAL_30_MINUTES = 30 * 60 * 1000
export const TasksTimeline = () => {
    const dispatch = useDispatch()
    const { currentTime, tasks, timelineState, groups, filters } = useSelector(taskTimelineSelector)
    useEffect(() => {
        dispatch(openTimelineAction())
        dispatch(syncTaskTimelineAction(true))
        const intervalId = setInterval(() => {
            dispatch(taskManagerTimelineParentAction(jumpToNowAction()))
        }, INTERVAL_30_MINUTES)
        return () => {
            dispatch(syncTaskTimelineAction(false))
            clearInterval(intervalId)
        }
    }, [])

    const onTimeChange = (ts: number, te: number) =>
        dispatch(taskManagerTimelineParentAction(timelineTimeChangeAction([ts, te])))
    const saveScrollPosition = useDebounce((newPosition: positionValues) => {
        if (!isEqual(timelineState.savedPosition, newPosition)) {
            dispatch(taskManagerTimelineParentAction(timelineScrollAction(newPosition)))
        }
    }, 1000)
    return (
        <PanelWithHeader
            header={
                <TasksTimelineHeader
                    categories={filters.categories}
                    date={currentTime}
                    jumpToNow={() => dispatch(taskManagerTimelineParentAction(jumpToNowAction()))}
                    zoomIn={() => dispatch(taskManagerTimelineParentAction(zoomInAction()))}
                    zoomOut={() => dispatch(taskManagerTimelineParentAction(zoomOutAction()))}
                    onSelectDate={v =>
                        dispatch(taskManagerTimelineParentAction(jumpToDateAction(v)))
                    }
                    changeCategory={categories =>
                        dispatch(setTimelineTaskFilterAction({ categories }))
                    }
                    exportTasks={e => dispatch(exportTasksAction(e))}
                />
            }
        >
            <Box flex={1}>
                <DarkScrollbar
                    onScroll={saveScrollPosition}
                    savedPosition={timelineState.savedPosition}
                >
                    <Timeline
                        items={tasks}
                        groups={groups}
                        timeline={timelineState}
                        onTimeChange={onTimeChange}
                        openItem={item =>
                            dispatch(
                                openTaskAction([TaskFilter.All, fromFlatId(item.id as string)]),
                            )
                        }
                        currentTime={currentTime}
                        ContentRenderer={({ item, inTooltip }) => (
                            <TaskBarContent item={item} inTooltip={inTooltip} />
                        )}
                        toggleGroup={g => dispatch(toggleGroupAction(g.processType))}
                    />
                </DarkScrollbar>
            </Box>
        </PanelWithHeader>
    )
}
