import { Trend } from 'aos-helpers/src/helpers/trend/Trend'

import { IconVariant } from '../../svg/Icon'

export type TrendIconVariants = Partial<Record<Trend, IconVariant>>

export const trendIncreasingGreenVariants = {
    [Trend.Increasing]: IconVariant.Green,
    [Trend.Decreasing]: IconVariant.Red,
}

export const trendIncreasingRedVariants = {
    [Trend.Increasing]: IconVariant.Red,
    [Trend.Decreasing]: IconVariant.Green,
}
