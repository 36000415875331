import { firebaseApp } from 'firebaseApp'

import { TaskId, toUrlHandle } from '../../services/tasks/types/TaskSnapshot'

class FirebaseTaskRepository {
    private taskRef = (taskId: TaskId) => firebaseApp.database().ref(`tasks/${toUrlHandle(taskId)}`)

    private parentRef = (taskId: TaskId) =>
        firebaseApp.database().ref(`tasks/${taskId.parentTaskId}`)

    public sendToSyncRef = (taskId: TaskId) => this.taskRef(taskId).child('sendToSync')

    public parentSendToSyncRef = (taskId: TaskId) => this.parentRef(taskId).child('sendToSync')
}
export const firebaseTaskRepository = new FirebaseTaskRepository()
