import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { FormFieldContainer } from 'aos-ui/src/components/container/FormFieldContainer'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { ModalFooter } from 'aos-ui/src/components/modal/Modal/ModalFooter'
import React, { PureComponent } from 'react'

import { CommonAdUserFormValidationState } from '../../../../core/groupManager/manageUser/state'

export class AdUserPreCheckForm extends PureComponent<AdUserPreCheckFormProps> {
    public render() {
        const changeEmail = (email: string) => this.changeValue({ email })
        const form = this.props.form
        return (
            <Box>
                <FormFieldContainer className='padding-horizontal--x-large padding-top--small'>
                    <LabeledInput
                        keyPrefix='manage-user.email'
                        onChangeText={changeEmail}
                        value={this.props.user.email}
                        type='email'
                        isError={form.error.email}
                        isRequired
                        seleniumLocation='first-input'
                    />
                </FormFieldContainer>
                <ModalFooter topSeparator={false}>
                    <FormButton label={translate('manage-user.add')} onClick={this.search} />
                    <ValidationInfo pristine={form.pristine} valid={form.valid} />
                </ModalFooter>
            </Box>
        )
    }

    private search = () => {
        if (this.props.form.valid) {
            this.props.searchUser(this.props.user.email!)
        } else {
            this.props.setFormPristine(false)
        }
    }

    private changeValue = (v: Partial<AdUserFormData>) => {
        this.props.changeForm(v)
    }
}

export interface AdUserPreCheckFormProps {
    user: AdUserFormData
    form: FormValidation<CommonAdUserFormValidationState>
    changeForm(v: Partial<AdUserFormData>): void
    searchUser(v: string): void
    setFormPristine(v: boolean): void
}
