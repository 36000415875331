import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

export class AdUserReadOnlyFieldsInfo extends PureComponent {
    public render() {
        return (
            <Text row flex={1} color={Color.Grey3} size={14} lineHeight='standard'>
                <Icon iconSize={BlockSize.Std} iconVariant={IconVariant.Grey} svg={SvgIcon.Info} />
                <span className='margin-left--small'>
                    {translate('manage-user.ad-user.read-only-fields-info')}
                </span>
            </Text>
        )
    }
}
