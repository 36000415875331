import { ListenerAggregator } from '../base/ListenerAggregator'

export class FirebaseAppClass extends ListenerAggregator {
    constructor(protected firebaseApp: firebase.app.App) {
        super()
    }

    public database = () => this.app().database()

    public storage = () => this.app().storage()

    public auth = () => this.app().auth()

    public analytics = () => (this.app() as any).analytics

    private app = () => this.firebaseApp
    public remoteConfig = () => this.app().remoteConfig()
}
