import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { CSSProperties, FC } from 'react'

interface NowLineProps {
    now: DateTime
    style?: CSSProperties
}

export const NowLine: FC<NowLineProps> = props => (
    <Box className='flight-table__now' style={props.style}>
        <b>{props.now.format('HH:mm')}</b>
    </Box>
)
