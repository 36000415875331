import { createFod } from 'aos-services/src/core/fod/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setFodCreateModalOpenAction } from '../../core/fod/actions'
import { FodStateAware } from '../../core/fod/state'
import { State } from '../../core/state'
import {
    fodCommonActions,
    fodCommonStateSelectors,
    FodModalComponent,
    FodModalDispatchProps,
    FodModalSelectorState,
} from './partial/FodModal'

export const FodCreateModal = connect<FodModalSelectorState, FodModalDispatchProps>(
    createStructuredSelector({
        location: (state: State) => state.auth.currentUser.siteLocation,
        isOpen: (state: FodStateAware) => state.fod.isCreateModalOpen,
        keyPrefix: () => 'create-fod-modal',
        bimLayers: (state: State) => state.bimLayers,
        userLayerVisibility: (state: State) => state.fod.userLayerVisibility,
        defaultReporter: (state: State) => state.auth.userProfile,
        ...fodCommonStateSelectors,
    }),
    {
        closeFodModal: () => setFodCreateModalOpenAction(false),
        ...fodCommonActions,
        saveFod: createFod,
    },
)(FodModalComponent)
