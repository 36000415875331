import { ActionModalContent } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import { IconModal } from 'aos-components/src/components/modal/IconModal/IconModal'
import { useFormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface ExportEventsModalProps {
    isOpen: boolean
    onClose(): void
    onSubmit(v: { timeStart: DateTime; timeEnd: DateTime }): void
}

interface ExportEventsForm {
    timeStart: boolean
    timeEnd: boolean
    isChronological: boolean
}

interface ExportEventsState {
    timeStart?: DateTime
    timeEnd?: DateTime
}

export const validate = (s: ExportEventsState): ExportEventsForm => ({
    timeStart: !!s.timeStart,
    timeEnd: !!s.timeEnd,
    isChronological: !!s.timeStart && !!s.timeEnd && s.timeStart.isBefore(s.timeEnd),
})

export const ExportEventsModal: FC<ExportEventsModalProps> = ({ isOpen, onClose, onSubmit }) => {
    const { state, form, setState, setPristine } = useFormValidation<
        ExportEventsState,
        ExportEventsForm
    >({}, validate)

    const trySubmit = () => {
        if (form.valid) {
            onSubmit(state as Required<ExportEventsState>)
        } else {
            setPristine(false)
        }
    }
    return (
        <IconModal
            isOpen={isOpen}
            modalKind={ModalKind.Icon}
            closeAction={onClose}
            svg={SvgIcon.ExportChecklistTemplates}
        >
            <ActionModalContent
                title={translate('export-events.title')}
                okLabel={translate('export-events.export')}
                className='padding-horizontal--x-large'
                onClickAction={trySubmit}
            >
                <Text
                    color={Color.Grey}
                    size={14}
                    paddingTop={16}
                    lineHeight='standard'
                    paddingBottom={20}
                >
                    {translate('export-events.description')}
                </Text>
                <DatetimeInput
                    value={state.timeStart}
                    onChange={v => setState({ timeStart: dateTime(v).startOf('day') })}
                    placeholder={translate('datetime.from')}
                />
                {form.error.timeStart && (
                    <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                        {translate('export-events.from.required')}
                    </Text>
                )}
                <DatetimeInput
                    value={state.timeEnd}
                    onChange={v => setState({ timeEnd: dateTime(v).endOf('day') })}
                    timeRangeStart={dateTime(state.timeStart)}
                    marginTop={8}
                    placeholder={translate('datetime.to')}
                />
                {form.error.timeEnd && (
                    <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                        {translate('export-events.to.required')}
                    </Text>
                )}
                {!form.error.timeEnd && !form.error.timeStart && form.error.isChronological && (
                    <Text size={12} color={Color.Red} paddingTop={4} lineHeight='standard'>
                        {translate('export-events.error.chronological')}
                    </Text>
                )}
            </ActionModalContent>
        </IconModal>
    )
}
