import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import {
    PaxForecastStats,
    totalPaxForecast,
} from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { paxForecastArrivalDepartureColors } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import { TrendLegendItem } from 'aos-ui/src/components/chart/trend/TrendLegendItem'
import React, { FC } from 'react'

interface PaxForecastArrivalDepartureTrendProps {
    stats: PaxForecastStats
    type: PaxForecastArrivalOrDeparture
}

export const PaxForecastArrivalDepartureTrend: FC<PaxForecastArrivalDepartureTrendProps> = props => (
    <TrendLegendItem
        label={translateParts(
            'dashboard.pax-forecast',
            enumToName(PaxForecastArrivalOrDeparture)(props.type),
            'total',
        )}
        labelColor={paxForecastArrivalDepartureColors[props.type]}
        trend={props.stats[props.type].trend}
        value={formatNumber(totalPaxForecast(props.stats[props.type]))}
    />
)
