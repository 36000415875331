import { enumRecord } from 'aos-helpers/src/helpers/Enum'
import { mapObjectValues } from 'aos-helpers/src/helpers/Object'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { getTrendData, timeRangeForDay } from 'aos-helpers/src/helpers/TimeRange'
import { computeTrend, TrendData } from 'aos-helpers/src/helpers/trend/Trend'
import { uniq } from 'lodash'
import { createSelector } from 'reselect'

import {
    groupByLocalTransfer,
    PaxForecast,
    PaxForecastPoint,
    sumPax,
} from '../../../../services/airportStatus/paxForecast/types/PaxForecast'
import { PaxForecastArrivalOrDeparture } from '../../../../services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastDataSource } from '../../../../services/airportStatus/paxForecast/types/PaxForecastDataSource'
import { PaxForecastStats } from '../../../../services/airportStatus/paxForecast/types/PaxForecastStats'
import {
    defaultPaxForecastFilters,
    PaxForecastFilters,
} from '../../../../services/statusDashboard/types/filters/paxForecast/PaxForecastFilters'
import { PaxForecastTimeRange } from '../../../../services/statusDashboard/types/filters/paxForecast/PaxForecastTimeRange'
import { currentDaySelector } from '../../../common/selectors'
import { FiltersAware } from '../../flights/selectors/common'
import { StatusDashboardDataStateAware } from '../../state'
import { getChartData } from './chart'

export const wholePaxForecastSelector = (state: StatusDashboardDataStateAware): PaxForecast =>
    state.statusDashboardData.paxForecast

export const filtersOwnPropsSelector = (_0: any, ownProps: FiltersAware<PaxForecastFilters>) =>
    ownProps.itemState.filters

const getStatsForPaxFlightType = (
    forecastFromPeriod: TrendData<PaxForecastPoint>,
    type: PaxForecastArrivalOrDeparture,
) => {
    const typeData = mapObjectValues(forecastFromPeriod, forecast =>
        forecast.filter(data => data.arrivalOrDeparture === type),
    )
    return {
        ...groupByLocalTransfer(sumPax)(typeData.current),
        trend: computeTrend(typeData, sumPax),
    }
}

const getPaxForecastStats = (paxForecast: PaxForecast, currentDay: DateTime): PaxForecastStats => {
    const statsTimeRange = timeRangeForDay(currentDay)
    const forecastFromPeriod = getTrendData((point: PaxForecastPoint) => point.time)(
        paxForecast,
        statsTimeRange,
    )

    const statsPerType = enumRecord(
        PaxForecastArrivalOrDeparture,
        (type: PaxForecastArrivalOrDeparture) => getStatsForPaxFlightType(forecastFromPeriod, type),
    )

    return {
        ...statsPerType,
        totalTrend: computeTrend(forecastFromPeriod, sumPax),
        day: currentDay,
    }
}

export const paxForecastTerminalsSelector = createSelector(wholePaxForecastSelector, paxForecast =>
    uniq(paxForecast.map(p => p.terminal)).sort(),
)

export const paxForecastStatsByFiltersMerger = (
    paxForecast: PaxForecast,
    filters: PaxForecastFilters,
    currentDay: DateTime,
): PaxForecastStats | null => {
    if (paxForecast.length === 0) {
        return null
    }

    const forecastPoints = paxForecast.filter(p => p.dataSource === PaxForecastDataSource.Forecast)
    const statsDay =
        filters.timeRange === PaxForecastTimeRange.Custom
            ? dateTime(filters.customDate)
            : currentDay
    return getPaxForecastStats(forecastPoints, statsDay)
}

export const makePaxForecastSelector = () =>
    createSelector(
        wholePaxForecastSelector,
        filtersOwnPropsSelector,
        currentDaySelector,
        paxForecastTerminalsSelector,
        (paxForecast, filters, currentDay, terminals) => ({
            stats: paxForecastStatsByFiltersMerger(paxForecast, filters, currentDay),
            chartData: getChartData(paxForecast, filters, currentDay),
            terminals,
        }),
    )

export const paxForecastStatsSelector = createSelector(
    wholePaxForecastSelector,
    currentDaySelector,
    (paxForecast, currentDay) =>
        paxForecastStatsByFiltersMerger(paxForecast, defaultPaxForecastFilters, currentDay),
)
