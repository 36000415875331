import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum EventsFilter {
    Active = 'active',
    Closed = 'closed',
    Upcoming = 'upcoming',
    Overdue = 'overdue',
}

export const translateEventsFilter = translateEnum<EventsFilter>(EventsFilter, 'events-filter')

export const allEventsFilters: EventsFilter[] = EnumValues.getValues<EventsFilter>(EventsFilter)
