import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { PaxSyncWrapper } from './PaxSyncWrapper'

interface PaxItemProps {
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const PaxItem: FC<PropsWithChildren<PaxItemProps>> = props => (
    <PaxSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.pax.title')}
            description={translate('dashboard.pax.description')}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </PaxSyncWrapper>
)
