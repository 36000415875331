import { isDefined } from 'aos-helpers/src/helpers/Function'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { ManyBarSeriesScales } from 'aos-ui-common/src/components/chart/series/manyBarScales'
import { Color } from 'aos-ui-common/src/styles/Color'
import { isFunction } from 'lodash'
import { Moment } from 'moment'
import React, { ReactNode } from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'
import { defaultChartTooltip } from '../base/tooltip'

const barStrokeWidth = 2

export interface ManyBarsSeriesDefinition<T, TY> {
    yAccessor: f.Func1<T, TY>
    fill: Color
    stroke?: Color
    tooltip: string | ((datum: T) => ReactNode)
}

export interface ManyBarsSeriesProps<T, TX extends Moment | string, TY> extends ClassNameProps {
    series: ManyBarsSeriesDefinition<T, TY>[]
    data: T[]
    xAccessor: f.Func1<T, TX>
    scales: ManyBarSeriesScales<TX, TY>
    strokeWidth?: number
}

class ManyBarsSeriesComponent<T, TX extends Moment | string, TY> extends BaseChartComponent<
    ManyBarsSeriesProps<T, TX, TY>
> {
    public render() {
        return (
            <g className={cxp(this.props, 'series series--double-bars')}>
                {this.props.data.map(this.renderSeries)}
            </g>
        )
    }

    private renderSeries = (datum: T, datumIndex: number) => (
        <g className='double-bars' key={datumIndex}>
            {this.props.series.map((seriesDef, index) => this.renderBar(datum, seriesDef, index))}
        </g>
    )

    private renderBar = (datum: T, series: ManyBarsSeriesDefinition<T, TY>, index: number) => {
        const {
            size,
            margins,
            scales: { x0Scale, x1Scale, yScale },
            xAccessor,
        } = this.props

        const value = yScale(series.yAccessor(datum))
        const x0 = x0Scale(xAccessor(datum))
        const x1 = x1Scale(index)

        if (!isDefined(value) || !isDefined(x0) || !isDefined(x1)) {
            return null
        }

        const tooltip = isFunction(series.tooltip)
            ? series.tooltip
            : defaultChartTooltip(series.stroke || series.fill, series.tooltip, series.yAccessor)

        return (
            <Tooltip key={index} body={tooltip(datum)} offset={[0, -10]} placement='top' withArrow>
                <rect
                    key={index}
                    x={x0 + x1}
                    y={value}
                    height={Math.max(size.height + margins.top - value, 0)}
                    width={x1Scale.bandwidth()}
                    stroke={series.stroke}
                    strokeWidth={this.props.strokeWidth || barStrokeWidth}
                    fill={series.fill ? series.fill : series.stroke}
                />
            </Tooltip>
        )
    }
}

export const ManyBarsSeries = withChartContext(ManyBarsSeriesComponent)
