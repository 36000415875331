import {
    RescueServiceTask,
    rescueServiceUnitLabel,
} from 'aos-services/src/services/tasks/types/task/RescueServiceTask'
import { Box } from 'aos-ui/src/components/base/Box'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import React from 'react'

export const rescueServiceTaskPropertyList = (t: RescueServiceTask): PropsListEntry[] => [
    ['tasks.task-preview.rescue-service.task-type', t.taskType?.label],
    ['tasks.task-preview.rescue-service.address', t.address],
    ['tasks.task-preview.rescue-service.aircraft-registration', t.aircraftRegistration],
    [
        'tasks.task-preview.rescue-service.units',
        !!t.units.length && (
            <Box>
                {t.units.map((ch, index) => (
                    <Box key={index}>{rescueServiceUnitLabel(ch)}</Box>
                ))}
            </Box>
        ),
    ],
    ['tasks.task-preview.rescue-service.additional-unit', t.additionalUnits],
]
