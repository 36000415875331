import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    AosSeverity,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { eventItemIconVariant } from './eventItemFormatter'

interface LockIconEventItemProps {
    severity: AosSeverity
}

export const LockIconEventItem: FC<LockIconEventItemProps> = props => {
    const eventRef = { hasPrivateChannel: false, severity: props.severity }
    return (
        <InfoBar color={severityColorsMapping[props.severity]} padding={8} shadowed>
            <Icon
                iconSize={BlockSize.Std}
                svg={SvgIcon.PrivateClosed}
                iconVariant={eventItemIconVariant(false, eventRef)!}
            />
        </InfoBar>
    )
}
