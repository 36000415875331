import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { OkButton } from 'aos-ui/src/components/buttons/OkButton'
import { MainPanelWithHeader } from 'aos-ui/src/components/container/MainPanelWithHeader'
import { PanelWithHeaderVariant } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { InfoBanner } from 'aos-ui/src/components/ui/InfoBanner'
import copyToClipboard from 'copy-to-clipboard'
import React, { PureComponent } from 'react'

import { BackButton } from '../../../components/buttons/BackButton'
import { EventInfoContent, EventInfoContentProps } from './EventInfoContent'

export class EventInfo extends PureComponent<EventInfoProps> {
    public render() {
        return (
            <MainPanelWithHeader
                variant={PanelWithHeaderVariant.Decreased}
                header={this.renderHeader()}
            >
                {this.renderContent()}
            </MainPanelWithHeader>
        )
    }

    private renderHeader = () => (
        <HeaderContainer
            leftButton={<BackButton />}
            title={translate('event-info.title')}
            rightButton={this.renderMore()}
        />
    )

    private renderMore = () => (
        <More>
            <MoreItem onClick={this.props.addPrivateChannel}>
                {translate('event-info.add-private-channel')}
            </MoreItem>
            <MoreItem onClick={this.props.closeEvent}>{translate('event-info.close')}</MoreItem>
            <MoreItem onClick={this.props.editEvent}>{translate('event-info.edit')}</MoreItem>
            <MoreItem onClick={this.props.reopenEvent}>{translate('event-info.reopen')}</MoreItem>
            <MoreItem onClick={this.props.deleteEvent}>{translate('event-info.delete')}</MoreItem>
            <MoreItem onClick={this.props.undeleteEvent}>
                {translate('event-info.undelete')}
            </MoreItem>
        </More>
    )

    private renderContent = () => {
        const { event } = this.props
        // Force scrollbar re-render when status or deleted changes
        const scrollbarKey = event.status + event.deleted

        return (
            <Box column fullHeight>
                <Box flex={1}>
                    <DarkScrollbar key={scrollbarKey}>
                        <Box padding={40}>
                            <EventInfoContent {...this.props} />
                        </Box>
                    </DarkScrollbar>
                </Box>
                {this.renderReopenBanner()}
                {this.renderDeletedBanner()}
            </Box>
        )
    }

    private renderReopenBanner() {
        if (!this.props.reopenEvent || this.props.event.deleted) {
            return null
        }

        return (
            <InfoBanner
                title={translate('event-details.closed.title')}
                message={this.getCloseMessage()}
            >
                <FormButton
                    fullWidth
                    label={translate('event-details.closed.reopen')}
                    onClick={this.props.reopenEvent}
                />
            </InfoBanner>
        )
    }

    private getCloseMessage() {
        const { event } = this.props

        if (!event.modifiedBy || !event.modifiedAt) {
            return
        }

        return translate('event-details.closed.message', {
            email: event.modifiedBy,
            dateTime: event.modifiedAt && formatDateTime(event.modifiedAt),
        })
    }

    private renderDeletedBanner() {
        if (!this.props.undeleteEvent) {
            return null
        }

        return (
            <InfoBanner
                title={translate('event-details.deleted.title')}
                message={translate('event-details.deleted.message')}
            >
                <FormButton
                    fullWidth
                    label={translate('event-details.deleted.undelete')}
                    onClick={this.props.undeleteEvent}
                />
                <OkButton
                    fullWidth
                    variant={FormButtonVariant.WhiteOutlined}
                    label={translate('event-details.deleted.copy')}
                    onClick={this.handleCopyToClipboard}
                />
            </InfoBanner>
        )
    }

    private handleCopyToClipboard = () => copyToClipboard(this.props.url)
}

interface EventInfoProps extends EventInfoContentProps {
    url: string
    editEvent?(): void
    closeEvent?(): void
    reopenEvent?(): void
    deleteEvent?(): void
    addPrivateChannel?(): void
    undeleteEvent?(): void
}
