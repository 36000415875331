import {
    CHANGE_AIRPORT,
    LOAD_CONTACT_LIST_SUCCESS,
    LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS,
    SEARCH_CONTACT,
} from 'aos-services/src/core/contactList/actions'

import {
    CLOSE_AIRPORT_UNIT_CONTACT_MODAL,
    CLOSE_CONTACT_MODAL,
    ContactListActions,
    OPEN_AIRPORT_UNIT_CONTACT_MODAL,
    OPEN_CONTACT_MODAL,
} from './actions'
import { initialContactListState } from './state'

export const contactListReducer = (state = initialContactListState, action: ContactListActions) => {
    switch (action.type) {
        case LOAD_CONTACT_LIST_SUCCESS:
            return {
                ...state,
                contactList: action.payload,
            }
        case LOAD_UNIT_AIRPORT_CONTACTS_SUCCESS:
            return {
                ...state,
                units: action.payload,
            }
        case CHANGE_AIRPORT:
            return {
                ...state,
                currentAirport: action.payload,
            }
        case SEARCH_CONTACT:
            return {
                ...state,
                searchValue: action.payload,
            }
        case OPEN_CONTACT_MODAL:
            return {
                ...state,
                modalOpen: true,
                selectedContact: action.payload,
            }
        case CLOSE_CONTACT_MODAL:
            return {
                ...state,
                modalOpen: false,
                selectedContact: null,
            }
        case OPEN_AIRPORT_UNIT_CONTACT_MODAL:
            return {
                ...state,
                modalOpen: action.payload.reopen,
                contactModalOpen: true,
                selectedAirportUnit: action.payload.data,
            }
        case CLOSE_AIRPORT_UNIT_CONTACT_MODAL:
            return {
                ...state,
                contactModalOpen: false,
                selectedAirportUnit: null,
            }

        default:
            return state
    }
}
