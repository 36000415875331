import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { TaskType } from '../TaskType'
import { BaseTask } from './BaseTask'
import { BrushesBrand } from './BrushesBrand'
import { BrushesVehicle } from './BrushesVehicle'

export interface MaintenanceTask extends BaseTask {
    type: TaskType.Maintenance
    assignments: MaintenanceJobAssignment[]
    billable: boolean
    contractors: MaintenanceContractor[]
}

export enum MaintenanceTaskType {
    General = 'GENERAL',
    Winter = 'WINTER',
    Summer = 'SUMMER',
    Brushes = 'BRUSHES',
}

export const translateMaintenanceTaskType = translateEnum(
    MaintenanceTaskType,
    'maintenance-task-type',
)
export const allMaintenanceTaskTypes = EnumValues.getValues<MaintenanceTaskType>(
    MaintenanceTaskType,
)

export interface MaintenanceJob {
    id: number
    label: string
    jobType: MaintenanceTaskType
}

export interface MaintenanceContractor {
    id: number
    name: string
    contractTime: DateTime
    jobStart: DateTime
    jobEnd: DateTime
    numberOfWorkers: number
}

export const maintenanceContractorLabel = (contractor: MaintenanceContractor) =>
    `${contractor.name}(${contractor.numberOfWorkers})`

export interface MaintenanceJobAssignment {
    job: MaintenanceJob | undefined
    brushesVehicle: BrushesVehicle
    brushesBrand: BrushesBrand
    engineLastShiftHours: number
    brushesLastShiftHours: number
    totalHours: number
}
