import React, { FCWithChildren } from 'react'

import { DarkScrollbar } from '../../../scrollbar/DarkScrollbar'
import { LightScrollbar } from '../../../scrollbar/LightScrollbar'
import { DropdownVariant } from './DropdownVariant'

export interface DropdownScrollbarProps {
    variant: DropdownVariant
    maxHeight?: number
}

export const DropdownScrollbar: FCWithChildren<DropdownScrollbarProps> = ({
    variant,
    children,
    maxHeight = 500,
}) =>
    variant === DropdownVariant.White ? (
        <LightScrollbar autoHeight autoHeightMax={maxHeight}>
            {children}
        </LightScrollbar>
    ) : (
        <DarkScrollbar autoHeight autoHeightMax={maxHeight}>
            {children}
        </DarkScrollbar>
    )
