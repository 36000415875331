import {
    AosSeverity,
    taskSeverityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import React, { FC } from 'react'

import { TaskIcon } from './TaskIcon'
import { taskItemIconVariant } from './taskItemFormatter'

interface IconTaskItemProps {
    severity: AosSeverity
    category: TaskCategory
}

export const IconTaskItem: FC<IconTaskItemProps> = props => {
    return (
        <InfoBar color={taskSeverityColorsMapping[props.severity]} padding={8} shadowed>
            <TaskIcon
                category={props.category}
                iconVariant={taskItemIconVariant(false, { severity: props.severity })}
            />
        </InfoBar>
    )
}
