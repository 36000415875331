import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Trend } from 'aos-helpers/src/helpers/trend/Trend'
import React, { FCWithChildren } from 'react'

import { Box, MarginBoxProps } from '../../base/Box'
import { Icon } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'
import { TrendIconVariants, trendIncreasingGreenVariants } from './TrendIconVariants'

interface TrendIconProps extends MarginBoxProps {
    trend: Trend
    iconsVariants?: TrendIconVariants
}

export const TrendIcon: FCWithChildren<TrendIconProps> = ({
    trend,
    iconsVariants = trendIncreasingGreenVariants,
    ...marginProps
}) => (
    <Box {...marginProps}>
        {trend === Trend.Increasing && (
            <Icon
                svg={SvgIcon.TrendArrowUp}
                iconSize={BlockSize.XXTiny}
                iconVariant={iconsVariants[trend]!}
            />
        )}
        {trend === Trend.Decreasing && (
            <Icon
                svg={SvgIcon.TrendArrowDown}
                iconSize={BlockSize.XXTiny}
                iconVariant={iconsVariants[trend]!}
            />
        )}
    </Box>
)
