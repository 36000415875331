import { Chart } from 'aos-components/src/components/chart/Chart'
import { LinearStackBarSeries } from 'aos-components/src/components/chart/series/LinearStackBarSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { isTimeInTimeRangeFilter } from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import {
    HourTobtItemUpdated,
    HourTobtListUpdated,
    TobtTimeColor,
    TobtTimeRange,
} from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

export interface PercentageFlightChartProps {
    flights: HourTobtListUpdated
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    color: Color
    timeRangeFilter: number
}

export function PercentageFlightChart(props: PercentageFlightChartProps) {
    const xAccessor = (d: HourTobtItemUpdated): DateTime => d.date

    const { yDomain, timeRangeFilter, xDomain } = props

    const { flights } = props

    const barSeriesConfig = {
        colors: [
            TobtTimeColor[TobtTimeRange.min0_5],
            TobtTimeColor[TobtTimeRange.later],
            TobtTimeColor[TobtTimeRange.suspended],
            'rgb(59, 75, 106)',
        ],
    }

    return (
        <Chart
            xConfig={{
                domain: xDomain,
                tickFormat: formatHour,
                tickMarked: isTimeInTimeRangeFilter(timeRangeFilter),
            }}
            y1Config={{
                domain: yDomain,
                baseValues: [40, 90],
                unit: Unit.Percent,
            }}
        >
            {({ xScale, y1Scale }) => {
                if (!xScale || !y1Scale) {
                    return null
                }
                return (
                    <LinearStackBarSeries
                        data={flights || []}
                        seriesConfig={barSeriesConfig}
                        accessors={{
                            xAccessor: xAccessor,
                            yAccessorKeys: [
                                TobtTimeRange.min0_5,
                                TobtTimeRange.later,
                                TobtTimeRange.suspended,
                                'invertedValue',
                            ],
                        }}
                        scales={{ xScale, yScale: y1Scale }}
                        minYValue={props.yDomain.domain[0]}
                    />
                )
            }}
        </Chart>
    )
}
