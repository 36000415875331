export interface FreezingPhenomenon {
    type: FreezingPhenomenonType
    intensity: FreezingPhenomenonIntensity
    value: string
}

export enum FreezingPhenomenonType {
    Fzra = 'FZRA',
    Fzdz = 'FZDZ',
    Fzfg = 'FZFG',
}

export enum FreezingPhenomenonIntensity {
    Weak = 'WEAK',
    Moderate = 'MODERATE',
    Strong = 'STRONG',
}
