import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../../StatusDashboardItemConfig'
import { RecentTasks } from './RecentTasks'

export const recentTasksConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 3 },
            maxSize: { w: 8, h: 10 },
        },
    },
    renderer: RecentTasks,
}
