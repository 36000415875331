import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { FileUpload } from '../../form/FileUpload'
import { SvgIcon } from '../../svg/SvgIcon'
import { SvgImage } from '../../svg/SvgImage'
import { AttachmentContainerItem } from './AttachmentContainerItem'
import { PreviewableProps } from './PreviewableProps'

interface AttachmentUploadProps extends PreviewableProps {
    onUpload(v: FileList): void
}

export const AttachmentUpload: FCWithChildren<AttachmentUploadProps> = ({ onUpload, ...rest }) => {
    const addText = 'Add'
    return (
        <AttachmentContainerItem {...rest}>
            <Box coverAll>
                <Box coverAll centered>
                    <Image svg={SvgIcon.ZoomIn} />
                    <AddText>{addText}</AddText>
                </Box>
                <FileUpload onChange={onUpload} />
            </Box>
        </AttachmentContainerItem>
    )
}

const Image = styled(SvgImage)`
    fill: ${Color.PrimaryLight};
`

const AddText = styled(Text)`
    font-size: 12px;
    color: ${Color.PrimaryLight};
    font-weight: 700;
`
