import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { Layer } from 'ol/layer'
import React, { FC, useContext, useEffect } from 'react'

import { BimMapContext } from '../BimMapContext'
import { layerIdKey } from '../OpenlayersMapContext'

interface BimMapLayerProps {
    mapLayerId: string
    layerId: string
    zIndex: number
    isVisible: boolean
}

export const BimMapLayer: FC<BimMapLayerProps> = ({ layerId, zIndex, isVisible, mapLayerId }) => {
    const mapContext = useContext(BimMapContext)
    const [layer, setLayer] = React.useState<Layer | undefined>(undefined)

    useEffect(() => {
        ;(async () => {
            try {
                const olLayer = await mapContext.bim.addMapLayer(mapLayerId)
                olLayer.set(layerIdKey, layerId)
                olLayer.setZIndex(zIndex)
                olLayer.setVisible(isVisible)
                setLayer(olLayer)
            } catch (error) {
                logger.handleError(error as Error)
            }
        })()
        return () => {
            if (layer) {mapContext.bim.removeMapLayer(mapLayerId)}
        }
    }, [])

    useEffect(() => {
        if (layer) {
            layer.setVisible(isVisible)
        }
    }, [isVisible])

    return null
}
