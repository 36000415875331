import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import { HeaderFilterVariant } from 'aos-ui/src/components/header/HeaderFilterItem'
import React, { FC } from 'react'

interface MapSingleVariantHeaderFilterProps {
    mapVariant: MapVariant
    variant?: HeaderFilterVariant
}

export const MapSingleVariantHeaderFilter: FC<MapSingleVariantHeaderFilterProps> = props => (
    <HeaderFilter
        items={[props.mapVariant]}
        value={props.mapVariant}
        variant={props.variant}
        onChange={() => {}}
        valueFormatter={translateEnum<MapVariant>(MapVariant, 'map.variant')}
    />
)
