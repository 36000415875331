import {
    queueingTimeBorderSelector,
    QueueingTimeSelectorState,
} from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { BorderControlPoint } from 'aos-services/src/services/queueingTime/types/BorderControlPoint'
import { connect } from 'react-redux'

import { setQueueingTimesFiltersAction } from '../../../../core/statusDashboard/actions'
import { QueueingTime, QueueingTimeDispatchProps, QueueingTimeOwnProps } from './QueueingTime'

export const QueueingTimeBorder = connect<
    QueueingTimeSelectorState<BorderControlPoint>,
    QueueingTimeDispatchProps,
    QueueingTimeOwnProps
>(queueingTimeBorderSelector, {
    setQueueingTimesFiltersAction,
})(QueueingTime)
