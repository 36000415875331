import { ArrivalsAndDepartures } from 'aos-services/src/services/flightInformation/types/ArrivalsAndDepartures'
import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    flightMapArrivalsSelector,
    flightMapDeparturesSelector,
    restrictionMapSelector,
} from '../../../core/airportMap/selectors'
import { SidebarOwnProps } from '../../../core/airportMap/state'
import { AirportResourceSidebar } from '../../sidebar/AirportResourceSidebar'

interface GateDetailsSidebarProps extends ArrivalsAndDepartures {
    restriction?: Restriction
}

export const GateDetailsSidebar = connect<GateDetailsSidebarProps, {}, SidebarOwnProps>(
    createStructuredSelector({
        arrivals: flightMapArrivalsSelector,
        departures: flightMapDeparturesSelector,
        restriction: restrictionMapSelector,
    }),
)(AirportResourceSidebar)
