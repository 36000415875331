import {
    createInitialSingleInputModalState,
    SingleInputModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/state'
import { filterableAndPageableForList } from 'aos-helpers/src/helpers/Pageable'
import { filterablePageAndSortRequestBuilder } from 'aos-helpers/src/helpers/PageRequest'
import {
    UserFilter,
    UserPageable,
} from 'aos-services/src/services/users/transport/UsersPageRequest'
import { UsersSorting, usersSorting } from 'aos-services/src/services/users/transport/UsersSorting'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'

import {
    initialAddAdUserState,
    initialAddContactState,
    ManageAdUserState,
    ManageContactState,
} from './manageUser/state'

export interface GroupManagerUIState {
    isSystemGroupsSectionExpanded: boolean
}

export interface GroupManagerState {
    groups: AosUserGroup[]
    selectedGroup?: AosUserGroup
    users: UserPageable
    selectedUser?: AosUser
    createGroupState: SingleInputModalState
    addAdUserState: ManageAdUserState
    addContactState: ManageContactState
    editAdUserState: ManageAdUserState
    editContactState: ManageContactState
    renameUserGroupState: SingleInputModalState
    showNotificationSettingsModal: boolean
    notificationSettingsGroup: AosUserGroup | null
    notificationSettingsSaveInProgress: boolean
    userGroupExporting: boolean
    ui: GroupManagerUIState
}

export interface GroupManagerStateAware {
    groupManager: GroupManagerState
}

export const initialUserFilter: UserFilter = {
    searchUserText: '',
}

export const emptyUserPageable = filterableAndPageableForList(
    [],
    initialUserFilter,
    usersSorting[UsersSorting.ByNameAsc],
)

export const initialGroupManagerState: GroupManagerState = {
    groups: [],
    users: emptyUserPageable,
    createGroupState: createInitialSingleInputModalState(),
    addAdUserState: initialAddAdUserState,
    editAdUserState: initialAddAdUserState,
    addContactState: initialAddContactState,
    editContactState: initialAddContactState,
    renameUserGroupState: createInitialSingleInputModalState(),
    showNotificationSettingsModal: false,
    notificationSettingsSaveInProgress: false,
    notificationSettingsGroup: null,
    userGroupExporting: false,
    ui: {
        isSystemGroupsSectionExpanded: true,
    },
}

export const firstUserPageRequest = (state: GroupManagerState) =>
    filterablePageAndSortRequestBuilder(state.users, { page: 0 })
