import {
    createInitialSingleDateModalState,
    SingleDateModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleDate/state'
import {
    createInitialSingleInputModalState,
    SingleInputModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/state'
import {
    createInitialSingleSelectModalState,
    SingleSelectModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleSelect/state'
import { NewFeedOut } from 'aos-services/src/services/events/input/NewFeedOut'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { EditableChecklist } from 'aos-services/src/services/events/types/AosEventChecklist'
import { AosFirebaseEvent } from 'aos-services/src/services/firebaseEvents/types/AosFirebaseEvent'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'

export interface EventEditorUIState {
    isConfirmationVisible: boolean
    isSendingFeedOut: boolean
}

export interface SelectUserGroupModalState<T> extends SingleSelectModalState<T> {
    isConfirmationVisible: boolean
}

export interface EventEditorState {
    event?: AosEvent
    firebaseEvent?: AosFirebaseEvent
    newFeedOut: NewFeedOut
    editableChecklists?: EditableChecklist[]
    selectUserGroupModalState: SelectUserGroupModalState<AosUserGroup>
    iWillArriveModalState: SingleDateModalState
    closeEventModalState: SingleDateModalState
    ui: EventEditorUIState
    setAdditionalNoteState: SingleInputModalState
}

export interface EventEditorStateAware {
    eventEditor: EventEditorState
}

export const initialNewFeedOutState: NewFeedOut = {
    text: '',
    channels: [AosMessageChannel.Notification],
}

export const initialEventEditorState: EventEditorState = {
    newFeedOut: initialNewFeedOutState,
    editableChecklists: undefined,
    selectUserGroupModalState: {
        ...createInitialSingleSelectModalState(),
        isConfirmationVisible: false,
    },
    iWillArriveModalState: createInitialSingleDateModalState(),
    closeEventModalState: createInitialSingleDateModalState(),
    ui: {
        isConfirmationVisible: false,
        isSendingFeedOut: false,
    },
    setAdditionalNoteState: createInitialSingleInputModalState(),
}

const getFirebaseEventSendToSyncValue = (state: EventEditorStateAware) => {
    if (state.eventEditor.firebaseEvent) {
        return state.eventEditor.firebaseEvent.sendToSync
    }
    return undefined
}

export const compareEventStsValues = (state: EventEditorStateAware, sendToSyncValue?: number) =>
    getFirebaseEventSendToSyncValue(state) !== sendToSyncValue

export const currentEventEditorId = (state: EventEditorStateAware) =>
    state.eventEditor.event ? state.eventEditor.event.id : undefined
