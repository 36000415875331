import { createSelector } from 'reselect'

import { getPaxData } from '../../../services/pax/types/PaxBlock'
import { StatusDashboardDataStateAware } from '../state'

export const paxSelector = createSelector(
    (state: StatusDashboardDataStateAware) => state.statusDashboardData.pax,
    pax => ({
        paxGroups: getPaxData(pax),
    }),
)
