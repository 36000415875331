import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import {
    flightsSyncAction,
    flightsSyncStopAction,
} from 'aos-services/src/core/flightInformation/actions'
import {
    restrictionsSyncAction,
    restrictionsSyncStopAction,
} from 'aos-services/src/core/restrictions/actions'
import {
    loadUserLayerVisibilityAction,
    setUserLayersVisibilityAction,
} from 'aos-services/src/core/userLayersVisibility/actions'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { firebaseMapLayersVisibilityRepository } from 'aos-services/src/dataaccess/mapLayers/firebaseMapLayersVisibilityRepository'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { LayersDataState } from 'aos-services/src/services/layerData/types/LayersDataState'
import { Box } from 'aos-ui/src/components/base/Box'
import { BimLayerCategory } from 'aos-ui-map/src/components/map/bim/BimLayerCategory'
import { MapStack } from 'aos-ui-map/src/components/map/MapStack'
import {
    closeMapAction,
    loadMapPointsOfInterestAction,
    MapAction,
    openMapAction,
} from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { airportMapMapParentAction, syncAirportMapAction } from '../../core/airportMap/actions'
import { AirportMapState } from '../../core/airportMap/state'
import { State } from '../../core/state'
import { AirportMapHeader } from './AirportMapHeader'
import { LayersVisibilitySidebar } from './LayersVisibilitySidebar'
import { SidebarStack } from './SidebarStack'

export const AirportMapClass: FC<AirportMapStateProps & AirportMapDispatchProps> = ({
    mapState,
    layersData,
    mapAction,
    bimLayers,
    loadBimLayersDataAction,
    userLayerVisibility,
    loadUserLayerVisibilityAction,
    setUserLayersVisibilityAction,
    userId,
}) => {
    const changeBimLayersVisibility = async (v: string[]) => {
        setUserLayersVisibilityAction({
            ...userLayerVisibility,
            list: v,
        })
        if (mapState.variant === MapVariant.Terminal && userId) {
            await firebaseMapLayersVisibilityRepository.getRef(userId).set(v)
        }
    }

    const updateFloorNumber = (floorNumber: number) => {
        changeFloorLayerVisibility(floorNumber)
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...userLayerVisibility,
            list: [
                ...userLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    const bimMapLayers =
        mapState.variant === MapVariant.Terminal
            ? bimLayers.list.filter(i => i.category && i.category !== BimLayerCategory.Atc)
            : bimLayers.list.filter(i => i.category === BimLayerCategory.TaskAndEvents)

    return (
        <SyncWrapper
            onEnter={[
                syncAirportMapAction(true),
                airportMapMapParentAction(openMapAction()),
                flightsSyncAction(FlightListType.Arrivals),
                flightsSyncAction(FlightListType.Departures),
                restrictionsSyncAction('map'),
            ]}
            onLeave={[
                syncAirportMapAction(false),
                airportMapMapParentAction(closeMapAction()),
                flightsSyncStopAction(FlightListType.Arrivals),
                flightsSyncStopAction(FlightListType.Departures),
                restrictionsSyncStopAction('map'),
            ]}
        >
            <Box fullSize column>
                <AirportMapHeader />
                <Box row alignItems='stretch' flex={1}>
                    <Box flex={1}>
                        <MapStack
                            layersData={layersData}
                            mapState={mapState}
                            mapAction={mapAction}
                            onBoundsChanged={v => mapAction(loadMapPointsOfInterestAction(v))}
                            loadBimLayersDataAction={loadBimLayersDataAction}
                            bimLayersState={bimLayers}
                            userLayerVisibility={userLayerVisibility}
                            loadUserLayerVisibilityAction={loadUserLayerVisibilityAction}
                        />
                    </Box>
                    {mapState.layersSidebarOpen ? (
                        <LayersVisibilitySidebar
                            userLayersVisibility={userLayerVisibility}
                            bimMapLayers={bimMapLayers}
                            setBimLayersVisibility={v => changeBimLayersVisibility(v)}
                            setFloorNumber={v => updateFloorNumber(v)}
                        />
                    ) : (
                        <SidebarStack />
                    )}
                </Box>
            </Box>
        </SyncWrapper>
    )
}

interface AirportMapStateProps {
    mapState: AirportMapState
    layersData: LayersDataState
    bimLayers: BimLayersState
    userLayerVisibility: UserLayerVisibilityState
    userId: number | undefined
}

interface AirportMapDispatchProps {
    mapAction(v: MapAction): void
    loadBimLayersDataAction(): void
    loadUserLayerVisibilityAction(): void
    setUserLayersVisibilityAction(v: UserLayerVisibilityState): void
}

export const AirportMap = connect<AirportMapStateProps, AirportMapDispatchProps, {}>(
    (state: State) => ({
        mapState: state.airportMap,
        layersData: state.layersData,
        bimLayers: state.bimLayers,
        userLayerVisibility: state.userLayerVisibility,
        userId: state.auth.current?.payload.aosId,
    }),
    {
        mapAction: airportMapMapParentAction,
        loadBimLayersDataAction,
        loadUserLayerVisibilityAction,
        setUserLayersVisibilityAction,
    },
)(AirportMapClass)
