import React, { PureComponent, ReactNode } from 'react'

import { HeaderFilterItem, HeaderFilterVariant } from './HeaderFilterItem'
import { HeaderItems } from './HeaderItems'

interface HeaderFilterProps<T> {
    items: T[]
    value?: T
    valueFormatter: f.Func1<T, ReactNode>
    variant?: HeaderFilterVariant
    onChange?(v: T): void
}

export class HeaderFilter<T> extends PureComponent<HeaderFilterProps<T>> {
    public render() {
        return <HeaderItems>{this.props.items.map(this.renderItem)}</HeaderItems>
    }

    private renderItem = (item: T, index: number) => (
        <HeaderFilterItem
            variant={this.props.variant}
            key={index}
            selected={item === this.props.value}
            onClick={() => this.onChange(item)}
        >
            {this.props.valueFormatter(item)}
        </HeaderFilterItem>
    )

    private onChange = (item: T) => {
        if (item !== this.props.value && this.props.onChange) {
            this.props.onChange(item)
        }
    }
}
