import { FlightColumnFilterItem } from './flightFiltersState'
import { FlightInfoField } from './FlightInfoField'

export interface FlightInfoColumnListInfo {
    field: FlightInfoField
    label?: string
}

export interface FlightInfoColumnListMeta extends FlightInfoColumnListInfo {
    visible: boolean
}

export const arrivalsColumns: FlightInfoColumnListInfo[] = [
    { field: FlightInfoField.sdt, label: 'sta' },
    { field: FlightInfoField.hapt, label: 'apt' },
    { field: FlightInfoField.naflt },
    { field: FlightInfoField.fltnr },
    { field: FlightInfoField.routes, label: 'origin' },
    { field: FlightInfoField.callsign },
    { field: FlightInfoField.acreg },
    { field: FlightInfoField.actype },
    { field: FlightInfoField.handl },
    { field: FlightInfoField.park, label: 'stand' },
    { field: FlightInfoField.gate },
    { field: FlightInfoField.est, label: 'eldt' },
    { field: FlightInfoField.act, label: 'aldt' },
    { field: FlightInfoField.ablk, label: 'aibt' },
    { field: FlightInfoField.paxTotal, label: 'paxTotal' },
    { field: FlightInfoField.bltarea },
    { field: FlightInfoField.bag },
    { field: FlightInfoField.eibt },
    { field: FlightInfoField.door },
    { field: FlightInfoField.rwy },
    { field: FlightInfoField.prm, label: 'status' },
]

export const departuresColumns: FlightInfoColumnListInfo[] = [
    { field: FlightInfoField.sdt, label: 'sdt' },
    { field: FlightInfoField.hapt, label: 'apt' },
    { field: FlightInfoField.naflt },
    { field: FlightInfoField.fltnr },
    { field: FlightInfoField.routes, label: 'route' },
    { field: FlightInfoField.callsign },
    { field: FlightInfoField.acreg },
    { field: FlightInfoField.actype },
    { field: FlightInfoField.handl },
    { field: FlightInfoField.park, label: 'stand' },
    { field: FlightInfoField.gate },
    { field: FlightInfoField.est, label: 'eobt' },
    { field: FlightInfoField.pest, label: 'public' },
    { field: FlightInfoField.ablk, label: 'aobt' },
    { field: FlightInfoField.act, label: 'atot' },
    { field: FlightInfoField.paxTotal, label: 'paxTotal' },
    { field: FlightInfoField.chkarea },
    { field: FlightInfoField.tobt },
    { field: FlightInfoField.tsat },
    { field: FlightInfoField.prm, label: 'status' },
]

export const filterAndSortFlightColumns = (
    columns: FlightInfoColumnListInfo[],
    filtered: FlightColumnFilterItem[],
): FlightInfoColumnListMeta[] =>
    columns
        .map(col => {
            const fromFilters = filtered.find(item => item.field === col.field)
            return {
                ...col,
                visible: fromFilters ? fromFilters.visible : true,
            }
        })
        .sort((aCol, bCol) => {
            const aIndex = filtered.findIndex(item => item.field === aCol.field)
            const bIndex = filtered.findIndex(item => item.field === bCol.field)
            return aIndex - bIndex
        })

export const nowLockableColumns: FlightInfoField[] = [
    FlightInfoField.sdt,
    FlightInfoField.est,
    FlightInfoField.pest,
    FlightInfoField.act,
    FlightInfoField.ablk,
]
