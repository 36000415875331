import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { put, takeEvery } from 'redux-saga/effects'

import { firebaseMetarRepository } from '../../../dataaccess/metar/firebaseMetarRepository'
import { MetarStateDto } from '../../../dataaccess/metar/types/MetarStateDto'
import { firebaseRunwayRepository } from '../../../dataaccess/runway/firebaseRunwayRepository'
import { RunwaysStateDto } from '../../../dataaccess/runway/types/RunwaysStateDto'
import { MetarStateImpl } from '../../../services/airportStatus/metar/types/MetarStateImpl'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { RunwaysStateImpl } from '../../../services/runways/types/RunwaysStateImpl'
import { syncAlertsAction, syncAlertsStopAction } from '../../alerts/actions'
import {
    metarSyncSuccessAction,
    RUNWAY_SYNC,
    RUNWAY_SYNC_STOP,
    RunwaySyncAction,
    RunwaySyncStopAction,
    runwaySyncSuccessAction,
} from './actions'

function* runwaySyncSaga() {
    yield takeEvery<RunwaySyncAction>(RUNWAY_SYNC, function* (action: RunwaySyncAction) {
        yield put(syncAlertsAction(`runway_${action.payload}`))
        yield* firebaseService.onListeners(
            {
                *runway() {
                    yield syncValue(
                        firebaseRunwayRepository.runwayRef(),
                        (t: [string, RunwaysStateDto]) =>
                            runwaySyncSuccessAction(new RunwaysStateImpl(t[1])),
                        false,
                    )
                },
                *metar() {
                    yield syncValue(
                        firebaseMetarRepository.metarRef(),
                        (t: [string, MetarStateDto]) =>
                            metarSyncSuccessAction(new MetarStateImpl(t[1])),
                        false,
                    )
                },
            },
            action.payload,
        )
    })
}

function* runwaySyncStopSaga() {
    yield takeEvery<RunwaySyncStopAction>(
        RUNWAY_SYNC_STOP,
        function* (action: RunwaySyncStopAction) {
            yield firebaseService.offListeners(['runway', 'metar'], action.payload)
            yield put(syncAlertsStopAction(`runway_${action.payload}`))
        },
    )
}

export const runwaySagas = [runwaySyncSaga, runwaySyncStopSaga]
