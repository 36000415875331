import { Color } from 'aos-ui-common/src/styles/Color'

import { THREE_H_IN_MS } from './constants'

export const getLVPCounterStyle = (counterDuration?: number) => {
    if (counterDuration === undefined) {
        return { borderColor: Color.Green, textColor: Color.White }
    }
    const borderColor =
        counterDuration <= 0
            ? Color.ProgressRed
            : counterDuration < THREE_H_IN_MS
            ? Color.ProgressYellow
            : Color.ProgressGreen

    const textColor = counterDuration <= 0 ? Color.ProgressRed : Color.White

    return { borderColor, textColor }
}
