import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isFeedOutValid, NewFeedOut } from 'aos-services/src/services/events/input/NewFeedOut'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { messagingChannels } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { ScrollPosition } from 'aos-ui/src/components/scrollbar/Scrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import _ from 'lodash'
import React, { PureComponent } from 'react'

import { toggleFeedOutConfirmationAction } from '../../../core/eventEditor/actions'
import { MessageEditor } from '../../common/messages/MessageEditor'
import { MessagesContainer } from '../../common/messages/MessagesContainer'

export class FeedOuts extends PureComponent<FeedOutsProps> {
    public render() {
        const { event, editProps, isEditable, groups, defaultGroupId } = this.props
        const groupsLoaded = groups && groups.length > 0 && defaultGroupId
        return (
            <PanelWithHeader
                header={this.renderHeader()}
                column
                justify='space-between'
                disableScroll
            >
                <Box flex={1}>
                    <DarkScrollbar
                        scrollOnChecksumUpdate={ScrollPosition.bottom}
                        startPosition={ScrollPosition.bottom}
                        checksum={event.feedOuts.length}
                    >
                        {event.feedOuts.length > 0
                            ? this.renderFeedOuts()
                            : this.renderPlaceholder()}
                    </DarkScrollbar>
                </Box>
                {editProps && isEditable && groupsLoaded && defaultGroupId && (
                    <MessageEditor
                        className='padding--x-large'
                        message={editProps.newFeedOutState}
                        onChange={editProps.onFeedOutChange}
                        onSubmit={this.submitFeedOut}
                        groups={groups}
                        defaultGroupId={defaultGroupId}
                        availableChannels={messagingChannels}
                        isMessageValid={isFeedOutValid}
                        isSendingMessage={editProps.isSendingFeedOut}
                        isConfirmationVisible={editProps.isConfirmationVisible}
                        toggleMessageConfirmation={editProps.toggleConfirmation}
                    />
                )}
            </PanelWithHeader>
        )
    }

    public renderHeader() {
        return (
            <HeaderContainer
                title={translate('event-preview.feed-outs')}
                titleCount={this.props.event.feedOuts.length}
            />
        )
    }

    private renderPlaceholder() {
        return (
            <PanelPlaceholder
                label={translate('feed-out.placeholder')}
                svg={SvgIcon.MessagePlaceholder}
            />
        )
    }

    private renderFeedOuts() {
        return (
            <MessagesContainer
                className='padding-horizontal--x-large padding-top--x-large container__item--align-bottom'
                messages={this.props.event.feedOuts}
                messagesTitleVariant={ThemeVariant.Black}
            />
        )
    }

    private submitFeedOut = () => {
        const { event, editProps, defaultGroupId } = this.props
        const { onFeedOutSubmit, newFeedOutState } = editProps
        onFeedOutSubmit([event.id, _.defaults(newFeedOutState, { groupId: defaultGroupId })])
    }
}

interface FeedOutsProps {
    event: AosEvent
    editProps: FeedOutsEditProps
    defaultGroupId?: number
    groups: AosUserGroup[]
    isEditable: boolean
}

interface FeedOutsEditProps {
    newFeedOutState: NewFeedOut
    isSendingFeedOut: boolean
    isConfirmationVisible: boolean
    toggleConfirmation: typeof toggleFeedOutConfirmationAction
    onFeedOutChange(v: NewFeedOut): void
    onFeedOutSubmit(v: [number, NewFeedOut]): void
}
