import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import React, { FC } from 'react'

import emptyDashboardSvg from '../assets/empty-dashboard.svg'

interface NoWidgetsPlaceholderProps {
    toggleSidebar(v: boolean): void
}

export const NoWidgetsPlaceholder: FC<NoWidgetsPlaceholderProps> = props => (
    <Box fullSize column centered>
        <Button onClick={() => props.toggleSidebar(true)} className='text__xxl-ow-grey'>
            <Icon svg={emptyDashboardSvg} iconSize={BlockSize.ModalIcon} />
            <Text className='text--lb' textAlign='center' lineHeight='standard' paddingTop={20}>
                {translate('dashboard.no-widgets-placeholder')}
            </Text>
        </Button>
    </Box>
)
