import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'

import {
    TaskChangeObject,
    UnitTaskChangeObject,
} from '../../services/events/types/AosEventChecklist'

type SyncChecklist = {
    eventId: number
    checklistId: number
}
export const SYNC_CHECKLIST = 'SYNC_CHECKLIST'
export type SyncChecklistAction = PayloadAction<typeof SYNC_CHECKLIST, SyncChecklist>
export const syncChecklistAction = payloadActionCreator<SyncChecklistAction, SyncChecklist>(
    SYNC_CHECKLIST,
)

export const TOGGLE_EVENT_CHECKLIST_TASK = 'EVENT_EDITOR/TOGGLE_EVENT_CHECKLIST_TASK'
export type ToggleEventChecklistTask = PayloadAction<
    typeof TOGGLE_EVENT_CHECKLIST_TASK,
    TaskChangeObject
>
export const toggleEventChecklistTask = payloadActionCreator<
    ToggleEventChecklistTask,
    TaskChangeObject
>(TOGGLE_EVENT_CHECKLIST_TASK)

export const UPDATE_EVENT_LAYER_VISIBILITY = 'EVENT_EDITOR/UPDATE_EVENT_LAYER_VISIBILITY'
export type UpdateEventLayerVisibility = PayloadAction<
    typeof UPDATE_EVENT_LAYER_VISIBILITY,
    BimLayerName
>
export const updateEventLayerVisibilityAction = payloadActionCreator<
    UpdateEventLayerVisibility,
    BimLayerName
>(UPDATE_EVENT_LAYER_VISIBILITY)

export const ADD_EVENT_LAYER_VISIBILITY = 'EVENT_EDITOR/ADD_EVENT_LAYER_VISIBILITY'
export type AddEventLayerVisibility = PayloadAction<
    typeof ADD_EVENT_LAYER_VISIBILITY,
    { eventId: number; visibilityLayers: string[] }
>
export const addEventLayerVisibilityAction = payloadActionCreator<
    AddEventLayerVisibility,
    { eventId: number; visibilityLayers: string[] }
>(ADD_EVENT_LAYER_VISIBILITY)

export const UPDATE_EVENT_ITEM = 'EVENT_EDITOR/UPDATE_EVENT_ITEM'
export type UpdateEventItemAction = PayloadAction<typeof UPDATE_EVENT_ITEM, UnitTaskChangeObject>
export const updateEventItemAction = payloadActionCreator<
    UpdateEventItemAction,
    UnitTaskChangeObject
>(UPDATE_EVENT_ITEM)
