import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskLogType } from 'aos-services/src/services/tasks/types/TaskLog'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

interface TaskLogProps {
    task: TaskSnapshot
}

const TaskLogMessage: FC<TaskLogProps> = ({ task }) => {
    if (!task.lastLogTime || !task.lastComment) {
        return null
    }

    return (
        <ChangeBox>
            <TaskLogTitle>
                {translate('tasks.comment-added', {
                    DATE: formatCalendarTime(task.lastLogTime),
                })}
            </TaskLogTitle>
            <ChangeInfo size={14} color={Color.White} paddingVertical={20}>
                {task.lastComment}
            </ChangeInfo>
        </ChangeBox>
    )
}

const TaskLogAssign: FC<TaskLogProps> = ({ task }) => {
    if (!task.lastLogTime) {
        return null
    }

    return (
        <ChangeBox>
            <TaskLogTitle>
                {translate('tasks.assign-changed', {
                    DATE: formatCalendarTime(task.lastLogTime),
                })}
            </TaskLogTitle>
        </ChangeBox>
    )
}

const TaskLogStatus: FC<TaskLogProps> = ({ task }) => {
    if (!task.lastLogTime) {
        return null
    }

    return (
        <ChangeBox>
            <TaskLogTitle>
                {translate('tasks.status-changed', {
                    DATE: formatCalendarTime(task.lastLogTime),
                })}
            </TaskLogTitle>
        </ChangeBox>
    )
}

export const taskLogTypes: Record<TaskLogType, FC<TaskLogProps> | undefined> = {
    [TaskLogType.Info]: undefined,
    [TaskLogType.Message]: TaskLogMessage,
    [TaskLogType.Assign]: TaskLogAssign,
    [TaskLogType.Status]: TaskLogStatus,
}

const TaskLogTitle: FC<PropsWithChildren> = ({ children }) => (
    <Text size={12} color={Color.Grey} paddingVertical={20} textAlign='center'>
        {children}
    </Text>
)

const ChangeBox = styled(Box)`
    border-top: 1px solid ${Color.UiBlack3};
    padding: 0 60px;
`

const ChangeInfo = styled(Text)`
    border-top: 1px solid ${Color.UiBlack3};
`
