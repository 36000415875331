import { fromPairs, toPairs } from 'lodash'
import { useEffect, useState } from 'react'

import { Validator, ValidatorResult, Validators } from './types'

export interface UseFormOptions<TData> {
    initialState: TData
    validators?: Validators<TData>
    onUpdate?(data: TData): void
}

export interface UseFormApi<TData> {
    submitHandler(callback: (values: TData) => void): () => void
    updateFieldValue<T extends keyof TData>(fieldName: T, value: TData[T]): void
    touchField<T extends keyof TData>(fieldName: T): void
    getFieldError<T extends keyof TData>(fieldName: T): Record<T, ValidatorResult>[T]
    getAllErrors(): Record<keyof TData, ValidatorResult>
    showErrors(): void
    isValid(): boolean
    reset(): void
    values: TData
    allErrorsShown: boolean
}

export const useForm = <TData>(options: UseFormOptions<TData>): UseFormApi<TData> => {
    type FieldName = keyof TData
    const { initialState, validators, onUpdate } = options
    const [values, setValues] = useState<TData>(initialState)

    type ValidatorErrors = Record<FieldName, ValidatorResult>
    type ShowErrors = Partial<Record<FieldName, boolean>>

    const getAllErrors = (): ValidatorErrors =>
        fromPairs(
            toPairs(validators).map(entries => {
                const [key, validator] = entries as [FieldName, Validator<TData[FieldName]>]
                const error = validator(values[key], values)
                return [key, error]
            }),
        ) as ValidatorErrors

    const [errors, setErrors] = useState<ValidatorErrors>(getAllErrors())
    const [showFieldError, setShowFieldError] = useState<ShowErrors>({})
    const [showAllErrors, setShowAllErrors] = useState(false)

    const validateField = <T extends FieldName>(
        fieldName: T,
        value: TData[T] = values[fieldName],
    ) => {
        const validator = validators?.[fieldName]
        if (validator) {
            return validator(value, values)
        }
        return undefined
    }

    const updateFieldValue = <T extends FieldName>(fieldName: T, value: TData[T]) => {
        setValues(values => {
            const newValues = {
                ...values,
                [fieldName]: value,
            }
            onUpdate?.(newValues)
            return newValues
        })
        setErrors(errors => ({
            ...errors,
            [fieldName]: validateField(fieldName, value),
        }))
    }

    const touchField = (fieldName: FieldName) => {
        setShowFieldError(fields => ({
            ...fields,
            [fieldName]: true,
        }))
        setErrors(errors => ({
            ...errors,
            [fieldName]: validateField(fieldName),
        }))
    }

    const getFieldError = (fieldName: FieldName) => {
        if ((showAllErrors || showFieldError[fieldName]) && errors[fieldName]) {
            return errors[fieldName]
        }
        return undefined
    }

    const isValid = () => {
        const allErrors = getAllErrors()
        return Object.values(allErrors).every(error => typeof error === 'undefined')
    }

    const showErrors = () => {
        setShowAllErrors(true)
        setErrors(getAllErrors())
    }

    const submitHandler = (callback: (values: TData) => void) => () => {
        showErrors()
        if (isValid()) {
            callback(values)
        }
    }

    const reset = () => {
        setValues(initialState)
        setShowAllErrors(false)
        setErrors(getAllErrors())
        setShowFieldError({})
    }

    useEffect(reset, [initialState])

    return {
        submitHandler,
        updateFieldValue,
        touchField,
        getFieldError,
        getAllErrors,
        showErrors,
        isValid,
        reset,
        values,
        allErrorsShown: showAllErrors,
    }
}
