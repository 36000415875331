import { mergeDictionaries } from 'aos-helpers/src/helpers/Object'
import { DateTime, dateTime, startOfHour } from 'aos-helpers/src/helpers/Time'
import { getPreviousRange, isTimeWithin, TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { getTrend } from 'aos-helpers/src/helpers/trend/Trend'
import { groupBy, toPairs } from 'lodash'

import { TrendStats } from '../../../common/types/TrendStats'
import { Flight } from '../../../flightInformation/types/Flight'
import { filterCollection, FlightCollection } from './FlightCollection'
import { FlightsSeries } from './FlightsInfo'
import { FlightsType } from './FlightsType'

export interface FlightCollectionPoint {
    time: DateTime
    collection: FlightCollection
}

export const toFlightCollectionPoints = (flights: FlightCollection): FlightCollectionPoint[] => {
    const sdtValue = (f: Flight) => startOfHour(f.sdt).valueOf()

    const merged = mergeDictionaries(
        groupBy(flights.arrivals, sdtValue),
        groupBy(flights.departures, sdtValue),
        (arrivals, departures) =>
            ({ arrivals: arrivals || [], departures: departures || [] } as FlightCollection),
    )

    return toPairs<FlightCollection>(merged)
        .map(([timestamp, collection]) => ({
            time: dateTime(parseInt(timestamp, 10)),
            collection,
        }))
        .sort((a, b) => a.time.valueOf() - b.time.valueOf())
}

export const toFlightPoints = <T>(
    points: FlightCollectionPoint[],
    range: TimeRange,
    mapper: f.Func1<FlightCollectionPoint, T>,
): T[] => points.filter(p => isTimeWithin(p.time, range)).map(mapper)

export const toTrendStats = (
    flights: Flight[],
    range: TimeRange,
    predicate: f.Func1<Flight, boolean>,
    valueMapper: (all: Flight[], applicable: Flight[]) => number = f => f.length,
): TrendStats => {
    const prevRange = getPreviousRange(range)
    const prevAll = flights.filter(f => isTimeWithin(f.sdt, prevRange))
    const currentAll = flights.filter(f => isTimeWithin(f.sdt, range))
    const prevApplicable = prevAll.filter(predicate)
    const currentApplicable = currentAll.filter(predicate)

    const prevValue = valueMapper(prevAll, prevApplicable)
    const currentValue = valueMapper(currentAll, currentApplicable)

    return {
        all: currentAll.length,
        applicable: currentApplicable.length,
        value: currentValue,
        trend: getTrend(currentValue, prevValue),
    }
}

export const toFlightSeries = <T>(
    points: FlightCollectionPoint[],
    range: TimeRange,
    mapper: f.Func2<Flight[], DateTime, T>,
): FlightsSeries<T> => ({
    arrivals: toFlightPoints(points, range, p => mapper(p.collection.arrivals, p.time)),
    departures: toFlightPoints(points, range, p => mapper(p.collection.departures, p.time)),
    all: toFlightPoints(points, range, p =>
        mapper([...p.collection.arrivals, ...p.collection.departures], p.time),
    ),
})

export const toFlightStats = (
    collection: FlightCollection,
    range: TimeRange,
    predicate: f.Func1<Flight, boolean>,
    merger: f.Func2<Flight[], Flight[], number>,
): Record<FlightsType, TrendStats> => ({
    [FlightsType.All]: toTrendStats(
        [...collection.arrivals, ...collection.departures],
        range,
        predicate,
        merger,
    ),
    [FlightsType.Arrivals]: toTrendStats(collection.arrivals, range, predicate, merger),
    [FlightsType.Departures]: toTrendStats(collection.departures, range, predicate, merger),
})

export const filterCollectionPoint = (
    point: FlightCollectionPoint,
    predicate: f.Func1<Flight, boolean>,
): FlightCollectionPoint => ({
    ...point,
    collection: filterCollection(point.collection, predicate),
})

export const filterCollectionPoints = (
    points: FlightCollectionPoint[],
    predicate: f.Func1<Flight, boolean>,
): FlightCollectionPoint[] => points.map(p => filterCollectionPoint(p, predicate))
