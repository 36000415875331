import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Page, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { BaseFirefighterAction } from 'aos-services/src/core/firefighters/actions'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { FirefighterShiftDto } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShiftDto'

export const FIREFIGHTERS_REFRESH = 'FIREFIGHTERS/REFRESH'
export type FirefightersRefreshAction = Action<typeof FIREFIGHTERS_REFRESH>
export const firefightersRefreshAction =
    emptyActionCreator<FirefightersRefreshAction>(FIREFIGHTERS_REFRESH)

export const FIREFIGHTERS_REFRESH_SUCCESS = 'FIREFIGHTERS/REFRESH_SUCCESS'
export type FirefightersRefreshSuccessAction = Action<typeof FIREFIGHTERS_REFRESH_SUCCESS>
export const firefightersRefreshSuccess = emptyActionCreator<FirefightersRefreshSuccessAction>(
    FIREFIGHTERS_REFRESH_SUCCESS,
)

export const FIREFIGHTERS_RELOAD = 'FIREFIGHTERS/RELOAD'
export type FirefightersReloadAction = PayloadAction<
    typeof FIREFIGHTERS_RELOAD,
    Pageable<FirefighterShiftDto>
>
export const firefightersReloadAction = payloadActionCreator<FirefightersReloadAction, Page>(
    FIREFIGHTERS_RELOAD,
)

export const FIREFIGHTERS_RELOAD_SUCCESS = 'FIREFIGHTERS/RELOAD_SUCCESS'
export type FirefightersReloadSuccessAction = PayloadAction<
    typeof FIREFIGHTERS_RELOAD_SUCCESS,
    Pageable<FirefighterShift>
>
export const firefightersReloadSuccessAction = payloadActionCreator<
    FirefightersReloadSuccessAction,
    Pageable<FirefighterShift>
>(FIREFIGHTERS_RELOAD_SUCCESS)

export const OPEN_FIRE_FIGHTER_FORM = 'FIREFIGHTER/OPEN_FORM'
export type OpenFireFighterFormAction = PayloadAction<
    typeof OPEN_FIRE_FIGHTER_FORM,
    FirefighterShift | undefined
>
export const openFireFighterFormAction = payloadActionCreator<
    OpenFireFighterFormAction,
    FirefighterShift | undefined
>(OPEN_FIRE_FIGHTER_FORM)

export const CLOSE_FIRE_FIGHTER_FORM = 'FIREFIGHTER/CLOSE_FORM'
export type CloseFireFighterFormAction = Action<typeof CLOSE_FIRE_FIGHTER_FORM>
export const closeFireFighterFormAction =
    emptyActionCreator<CloseFireFighterFormAction>(CLOSE_FIRE_FIGHTER_FORM)

export type FirefighterAction =
    | FirefightersRefreshAction
    | FirefightersRefreshSuccessAction
    | FirefightersReloadAction
    | FirefightersReloadSuccessAction
    | BaseFirefighterAction
    | OpenFireFighterFormAction
    | CloseFireFighterFormAction
