import { RunwayClosure } from 'aos-services/src/services/runways/types/RunwaysState'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { createNotificationFromClosure } from './RunwaysClosureSpan'

export const RunwayClosurePlan: FC<RunwayClosurePlanProps> = props => {
    let closures = props.runwayClosure.map(closure => createNotificationFromClosure(closure))

    return (
        <Box column className='runway-closure'>
            {closures}
        </Box>
    )
}

interface RunwayClosurePlanProps {
    runwayClosure: RunwayClosure[]
}
