import { parentActionWrapper } from 'aos-helpers/src/helpers/ActionCreator'
import { restrictionsSagas as baseRestrictionSagas } from 'aos-services/src/core/restrictions/sagas'
import { NavigationMapElementType } from 'aos-services/src/services/mapui/types/NavigationMapElement'
import { RestrictionResourceType } from 'aos-services/src/services/restrictions/types/RestrictionResourceType'
import { navigateToMapAction } from 'aos-ui-map/src/core/actions'
import { put, takeLatest } from 'redux-saga/effects'

import { airportMapMapParentAction } from '../airportMap/actions'
import { SHOW_MAP_LOCATION, ShowMapLocationAction } from './actions'

function* showMapLocationSaga() {
    yield takeLatest<ShowMapLocationAction>(SHOW_MAP_LOCATION, function* (action) {
        const restriction = action.payload
        const navigateAction = parentActionWrapper(airportMapMapParentAction, navigateToMapAction)

        switch (restriction.resourceType) {
            case RestrictionResourceType.Stand:
                yield put(
                    navigateAction({
                        type: NavigationMapElementType.Stand,
                        standId: restriction.resourceNumber,
                    }),
                )
                break
            case RestrictionResourceType.Gate:
            case RestrictionResourceType.BusGate:
                yield put(
                    navigateAction({
                        type: NavigationMapElementType.Gate,
                        gateId: restriction.resourceNumber,
                    }),
                )
                break
        }
    })
}

export const restrictionsSagas = [...baseRestrictionSagas, showMapLocationSaga]
