import { EnumValues } from 'enum-values'
import { fromPairs } from 'lodash'

export enum NotificationsPreferencesDay {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export const allNotificationsPreferencesDays = EnumValues.getValues(
    NotificationsPreferencesDay,
) as NotificationsPreferencesDay[]

export enum NotificationsPreferencesType {
    AIRCRAFT_STANDS_PASSENGER_BRIDGES = 'AIRCRAFT_STANDS_PASSENGER_BRIDGES',
    AIRLINES_GROUND_HANDLING = 'AIRLINES_GROUND_HANDLING',
    AIRPORT_FACILITY_INFRASTRUCTURE = 'AIRPORT_FACILITY_INFRASTRUCTURE',
    APRON_TAXIWAYS_RUNWAYS = 'APRON_TAXIWAYS_RUNWAYS',
    BAGGAGE = 'BAGGAGE',
    BORDER_CONTROL = 'BORDER_CONTROL',
    CHECK_IN = 'CHECK_IN',
    CONSTRUCTION_WORK = 'CONSTRUCTION_WORK',
    DE_ICING = 'DE_ICING',
    EFHK_SAFETY_SECURITY_INCIDENT = 'EFHK_SAFETY_SECURITY_INCIDENT',
    ELEVATORS_AND_ESCALATORS = 'ELEVATORS_AND_ESCALATORS',
    EUROPE_WORLD_NETWORK = 'EUROPE_WORLD_NETWORK',
    FINAVIA_NETWORK_AIRPORTS = 'FINAVIA_NETWORK_AIRPORTS',
    GATES = 'GATES',
    GENERAL_INFORMATION = 'GENERAL_INFORMATION',
    IT_SYSTEMS = 'IT_SYSTEMS',
    MEDIA_AND_FILMING = 'MEDIA_AND_FILMING',
    PASSENGER_TERMINAL = 'PASSENGER_TERMINAL',
    PASSENGER_WITH_REDUCED_MOBILITY = 'PASSENGER_WITH_REDUCED_MOBILITY',
    PUBLIC_TRANSPORTATION_LANDSITE_TRAFFIC = 'PUBLIC_TRANSPORTATION_LANDSITE_TRAFFIC',
    SECURITY_CONTROL = 'SECURITY_CONTROL',
    SHOPS_AND_SERVICES = 'SHOPS_AND_SERVICES',
    TRAFFIC_AND_CAPACITY = 'TRAFFIC_AND_CAPACITY',
    WEATHER_ALERT = 'WEATHER_ALERT',
    WEATHER = 'WEATHER',
}
export const allNotificationsPreferencesTypes = EnumValues.getValues<NotificationsPreferencesType>(
    NotificationsPreferencesType,
)

export interface AdditionalPreferenceItem {
    email: boolean
    push: boolean
}

export type AosUserNotificationsAdditionalPreferences = Record<
    NotificationsPreferencesType,
    AdditionalPreferenceItem
>

export interface AosUserNotificationsTimePreference {
    fromTime: string | null
    toTime: string | null
    days: NotificationsPreferencesDay[]
}

export const defaultDisabledTimeNotificationPreferences: AosUserNotificationsTimePreference = {
    fromTime: null,
    toTime: null,
    days: allNotificationsPreferencesDays,
}

export const defaultEnabledTimeNotificationPreferences: AosUserNotificationsTimePreference = {
    fromTime: '07:00',
    toTime: '17:00',
    days: allNotificationsPreferencesDays.slice(0, 5),
}

export interface AosUserNotificationsPreferences {
    timePreference: AosUserNotificationsTimePreference
    additional: AosUserNotificationsAdditionalPreferences
}

export const defaultUserNotificationsPreferences: AosUserNotificationsPreferences = {
    timePreference: defaultDisabledTimeNotificationPreferences,
    additional: fromPairs(
        allNotificationsPreferencesTypes.map(type => [type, { push: false, email: false }]),
    ) as AosUserNotificationsAdditionalPreferences,
}
