import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { AnimalSpecie } from 'aos-services/src/services/tasks/types/task/AnimalsTask'

export type ReportAnimals = FilterOptionAll | AnimalSpecie

export const translateReportAnimals = (value: ReportAnimals): string =>
    value === FilterOptionAll.All
        ? translateEnum(FilterOptionAll, 'tasks.reports.animals')(value)
        : value.label
