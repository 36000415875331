import { enumUnion, isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { optionalFunction } from 'aos-helpers/src/helpers/Function'
import { PredicateMatcher } from 'aos-helpers/src/helpers/PredicateMatcher'
import { translateEnumUnion } from 'aos-helpers/src/helpers/translations/Translations'
import { identity, round } from 'lodash'

import { DateTime } from '../../../../../../aos-helpers/src/helpers/Time'
import { WeatherUnit } from '../../../statusDashboard/types/filters/WeatherUnit'
import { NullableTimeValuePoint, TimeValuePoint } from '../../base/types/TimePoint'
import { FreezingPhenomenonPoint, WeatherCategoryPoint } from './WeatherPoints'
import { WeatherOtherSeriesType, WeatherValueSeriesType } from './WeatherSeriesType'

export type WeatherValueSeries = Record<WeatherValueSeriesType, NullableTimeValuePoint[]>
export type WeatherSeries = WeatherValueSeries & {
    [WeatherOtherSeriesType.WeatherCategory]: WeatherCategoryPoint[]
    [WeatherOtherSeriesType.FreezingWeatherPhenomenon]: FreezingPhenomenonPoint[]
    [WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon]: FreezingPhenomenonPoint[]
}

export type WeatherMetric = Partial<Record<WeatherValueSeriesType, number>> & {
    [WeatherOtherSeriesType.WeatherCategory]?: WeatherCategory
}

export type WeatherMetricV2 = Partial<
    Record<WeatherValueSeriesType, { value: number; time: string }>
> & {
    [WeatherOtherSeriesType.WeatherCategory]?: { value: WeatherCategory; time: string }
}

export const weatherLvpProbabilityThreshold = 70
export const weatherThunderstormThreshold = 0

export const isWeatherValueEntry = isValidEnumEntry(WeatherValueSeriesType)
export const isWeatherOtherEntry = isValidEnumEntry(WeatherOtherSeriesType)

export type WeatherTimePoint = WeatherCurrentTimePoint | WeatherForecastTimePoint

export enum WeatherCurrentTimePoint {
    Current = 0,
}

export enum WeatherForecastTimePoint {
    Plus1 = 1,
    Plus3,
    Plus6,
    Plus12,
    Plus24,
}

export const allWeatherTimePoints = enumUnion(WeatherCurrentTimePoint, WeatherForecastTimePoint)

export const isForecastTimePoint = (item: WeatherTimePoint): item is WeatherForecastTimePoint =>
    isValidEnumEntry(WeatherForecastTimePoint)(item)

export const translateWeatherTimePoint = (prefix: string) =>
    translateEnumUnion(prefix, WeatherCurrentTimePoint, WeatherForecastTimePoint)

const weatherForecastPointHourDiff: Record<WeatherForecastTimePoint, number> = {
    [WeatherForecastTimePoint.Plus1]: 1,
    [WeatherForecastTimePoint.Plus3]: 3,
    [WeatherForecastTimePoint.Plus6]: 6,
    [WeatherForecastTimePoint.Plus12]: 12,
    [WeatherForecastTimePoint.Plus24]: 24,
}

export const offsetTimeByForecastTimePoint = (
    originalTime: DateTime,
    offset: WeatherForecastTimePoint,
) => originalTime.clone().add(weatherForecastPointHourDiff[offset], 'hours')

export enum WeatherCategory {
    Normal = 'NORMAL',
    Rain = 'RAIN',
    Snow = 'SNOW',
    RainSnow = 'RAIN_SNOW',
}

export enum WeatherWindGustsType {
    Small = 0,
    Normal,
    Big,
    Extreme,
}

const ktToMs = (value: number) => value * 0.51444

export const convertAndRoundKtToMs = (value: number, decimalPlaces: number = 0) =>
    round(ktToMs(value), decimalPlaces)

export const convertAndRoundKtTo = (
    unit: WeatherUnit,
    decimalPlaces: number = 0,
): f.Func1<number, number> =>
    unit === WeatherUnit.Ms ? knots => convertAndRoundKtToMs(knots, decimalPlaces) : identity

export const convertKtTo = (unit: WeatherUnit): ((val: number) => number) =>
    unit === WeatherUnit.Ms ? ktToMs : identity

export const optionalConvertAndRoundKtTo = (unit: WeatherUnit, decimalPlaces: number = 0) =>
    optionalFunction(convertAndRoundKtTo(unit, decimalPlaces))

const windGustsKtThresholds: [number, WeatherWindGustsType][] = [
    [60, WeatherWindGustsType.Extreme],
    [40, WeatherWindGustsType.Big],
    [25, WeatherWindGustsType.Normal],
]

const windGustsMsThresholds: [number, WeatherWindGustsType][] = windGustsKtThresholds.map(t => [
    ktToMs(t[0]),
    t[1],
])

const windGustsThresholds: Record<WeatherUnit, [number, WeatherWindGustsType][]> = {
    [WeatherUnit.Kt]: windGustsKtThresholds,
    [WeatherUnit.Ms]: windGustsMsThresholds,
}

export const getTypeForWindGusts = (unit: WeatherUnit, weatherPoint: TimeValuePoint) => {
    const type = windGustsThresholds[unit].find(
        ([threshold, _]) => convertKtTo(unit)(weatherPoint.value) > threshold,
    )
    return type ? type[1] : WeatherWindGustsType.Small
}

export enum WeatherProbabilityType {
    None = 0,
    Small,
    Normal,
    Big,
    Extreme,
}

export const getWeatherProbabilityTypeForWeatherProbabilityValue = (
    weatherPoint: TimeValuePoint,
) => {
    const matcher = PredicateMatcher.of<number, WeatherProbabilityType>()
        .caseWhen(d => d > 74, WeatherProbabilityType.Extreme)
        .caseWhen(d => d > 49, WeatherProbabilityType.Big)
        .caseWhen(d => d > 24, WeatherProbabilityType.Normal)
        .caseWhen(d => d > 0, WeatherProbabilityType.Small)
        .else(WeatherProbabilityType.None)

    return matcher.match(weatherPoint.value) || WeatherProbabilityType.None
}

export const getLvpProbabilityTypeForWeatherProbabilityValue = (weatherPoint: TimeValuePoint) => {
    const matcher = PredicateMatcher.of<number, WeatherProbabilityType>()
        .caseWhen(d => d >= 40, WeatherProbabilityType.Big)
        .else(WeatherProbabilityType.None)

    return matcher.match(weatherPoint.value) || WeatherProbabilityType.None
}
