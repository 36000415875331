import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    defaultTaskRecurrenceWeekly,
    TaskRecurrenceWeeklyValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceWeeklyFormPayload'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { allWeekDays, translateTaskDay } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceWeekly'
import { BlockCheckboxGroup } from 'aos-ui/src/components/form/checkbox/BlockCheckboxGroup'
import { NumberInput } from 'aos-ui/src/components/form/input/NumberInput'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledRowFormElement } from 'aos-ui/src/components/form/labeled/LabeledRowFormElement'
import React, { FC } from 'react'

import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'

export const TaskRecurrenceWeekly: FC<TaskRecurrenceWeeklyProps> = ({
    value,
    onChange,
    errors,
    canEdit,
}) => {
    if (value?.type !== TaskRecurrenceType.Weekly) {
        return null
    }

    return (
        <>
            <LabeledRowFormElement
                prefixLabel={translate('tasks.every')}
                suffixLabel={translate('tasks.weeks')}
                marginVertical={8}
                isError={errors?.every}
                errorMessage={translate('tasks.every.error')}
            >
                <NumberInput
                    width={50}
                    value={value.every}
                    onChange={every => onChange({ every })}
                    fallback={defaultTaskRecurrenceWeekly.every}
                    marginHorizontal={8}
                    disabled={!canEdit}
                />
            </LabeledRowFormElement>
            <LabeledFormElement
                label={translate('tasks.on')}
                marginVertical={8}
                isError={errors?.weekDays}
                errorMessage={translate('tasks.weekly.on.error')}
            >
                <BlockCheckboxGroup
                    items={allWeekDays}
                    values={value.weekDays}
                    onChange={weekDays => onChange({ weekDays })}
                    renderer={translateTaskDay}
                    condensed
                    stretch
                    disabled={!canEdit}
                />
            </LabeledFormElement>
        </>
    )
}

interface TaskRecurrenceWeeklyProps extends TaskRecurrenceFormProps {
    errors?: TaskRecurrenceWeeklyValidation
}
