import { nullableToNotRequired } from 'aos-helpers/src/helpers/Function'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { arc, pie, PieArcDatum } from 'd3-shape'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

class PieSeriesComponent<T> extends BaseChartComponent<ChartPieSeriesProps<T>> {
    public render() {
        const {
            size,
            margins,
            data,
            seriesConfig: { outerRadius },
        } = this.props

        const pieGenerator = pie<T>()
            .value(this.props.yAccessor)
            .startAngle(0)
            .endAngle(Math.PI)
            .sort(null)

        const pieSeriesData = pieGenerator(data)

        return (
            <g
                className={cxp(this.props, 'series series--pie')}
                transform={`translate(${size.width / 2 + margins.left} ${outerRadius}) rotate(270)`}
            >
                {pieSeriesData.map(this.renderArc)}
            </g>
        )
    }

    private renderArc = (datum: PieArcDatum<T>, index: number) => {
        const {
            seriesConfig: { color, innerRadius, outerRadius },
        } = this.props

        const arcPieGenerator = arc<PieArcDatum<T>>()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)

        return (
            <path
                key={index}
                className='arc'
                fill={this.getAttributeValue(color, index)}
                d={nullableToNotRequired(arcPieGenerator(datum))}
            />
        )
    }
    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface PieSeriesConfig extends ChartSeriesConfig<number> {
    innerRadius: number
    outerRadius: number
}

export interface ChartPieSeriesProps<T> extends ClassNameProps {
    data: T[]
    seriesConfig: PieSeriesConfig
    yAccessor: f.Func1<T, number>
}

export const PieSeries = withChartContext(PieSeriesComponent)
