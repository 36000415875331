import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setPreviewFodLocationModalOpenAction } from '../../core/fod/actions'
import { FodStateAware } from '../../core/fod/state'
import { FodMap } from './FodMap'

export const FodPreviewLocationModal = () => {
    const isOpen = useSelector((state: FodStateAware) => state.fod.isPreviewModalOpen)
    const dispatch = useDispatch()

    return (
        <Modal
            closeAction={() => dispatch(setPreviewFodLocationModalOpenAction(false))}
            isOpen={isOpen}
            modalKind={ModalKind.Big}
            title={translate('location-fod-modal.title.fod-location')}
        >
            <FodMap />
        </Modal>
    )
}
