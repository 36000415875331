import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { sortParticipants } from 'aos-services/src/services/firebaseEvents/privateChannelState'
import { PrivateChannelParticipant } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { countRespondedInvitations } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { useMemo } from 'react'

import { ChannelParticipant } from './participants/ChannelParticipant'
import { PrivateChannelSection } from './PrivateChannelSection'

interface PrivateChannelParticipantsProps {
    participants: PrivateChannelParticipant[]
    me?: PrivateChannelParticipant
    invitedGroup?: AosUserGroup
    changeStatus(): void
    leaveEvent?(): void
    arriveAt(): void
}

export const PrivateChannelParticipants: React.FC<PrivateChannelParticipantsProps> = ({
    participants,
    me,
    changeStatus,
    arriveAt,
    invitedGroup,
    leaveEvent,
}) => {
    const sortedParticipants = useMemo(() => sortParticipants(participants), [participants])

    const renderParticipant = (p: PrivateChannelParticipant, index: number) => (
        <ChannelParticipant key={index} participant={p} />
    )

    const renderParticipants = (me: PrivateChannelParticipant | undefined) => {
        const [accepted, amount] = countRespondedInvitations([me, participants] ?? [undefined, []])

        return (
            <>
                {me && (
                    <PrivateChannelSection
                        title={translate('private-channel.your-role-and-status')}
                    >
                        <ChannelParticipant
                            participant={me}
                            changeStatus={changeStatus}
                            arriveAt={arriveAt}
                            leaveEvent={leaveEvent}
                        />
                    </PrivateChannelSection>
                )}
                <PrivateChannelSection
                    title={invitedGroup?.name ? invitedGroup.name : ''}
                    subTitle={translate('private-channel.accepted-participants', {
                        accepted,
                        amount,
                    })}
                >
                    {sortedParticipants.map(renderParticipant)}
                </PrivateChannelSection>
            </>
        )
    }

    return <Box>{sortedParticipants && renderParticipants(me)}</Box>
}
