import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import {
    NavigationFormButton,
    NavigationFormButtonVariant,
} from 'aos-ui/src/components/buttons/NavigationFormButton'
import React, { PureComponent } from 'react'

interface WizardNavigatorProps {
    itemCount: number
    current: number
    onPrev(): void
    onNext(): void
}

export class WizardNavigator extends PureComponent<WizardNavigatorProps> {
    public render() {
        return (
            <Box row>
                {this.hasPrev && (
                    <NavigationFormButton
                        onClick={this.props.onPrev}
                        label={translate('wizard.previous')}
                        buttonVariant={NavigationFormButtonVariant.Prev}
                    />
                )}
                <Box flex={1} />
                {this.hasNext && (
                    <NavigationFormButton
                        onClick={this.props.onNext}
                        label={translate('wizard.next')}
                        buttonVariant={NavigationFormButtonVariant.Next}
                    />
                )}
            </Box>
        )
    }

    private get hasNext() {
        const { itemCount, current } = this.props
        return current < itemCount - 1
    }

    private get hasPrev() {
        const { current } = this.props
        return current !== 0
    }
}
