import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import styled, { keyframes } from 'styled-components'

import { ZIndex } from '../base/Theme'

const ModalWrapperComponent: FCWithChildren<PropsWithChildren<ModalWrapperProps>> = ({
    children,
    isOpen,
    shouldCloseOnOverlayClick = false,
    className,
    closeAction,
    disableFullScreen,
}) => {
    if (disableFullScreen) {
        return (
            <ReactModal
                isOpen={isOpen}
                contentLabel='Modal'
                overlayClassName='overlay'
                className='content'
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick || false}
                portalClassName={className}
                onRequestClose={closeAction}
                ariaHideApp={false}
            >
                {children}
            </ReactModal>
        )
    } else {
        const parentRef = document.getElementById('full-screen-dialog')
        return (
            parentRef && (
                <ReactModal
                    parentSelector={() => parentRef}
                    isOpen={isOpen}
                    contentLabel='Modal'
                    overlayClassName='overlay'
                    className='content'
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick || false}
                    portalClassName={className}
                    onRequestClose={closeAction}
                    ariaHideApp={false}
                >
                    {children}
                </ReactModal>
            )
        )
    }
}

export interface ModalWrapperProps {
    isOpen: boolean
    shouldCloseOnOverlayClick?: boolean
    className?: string
    closeAction(): void
    disableFullScreen?: boolean
}

const overlayAnimation = keyframes`
    from {
        background-color: transparent;
    }
    to {
        background-color: color(overlay);
    }
`

export const ModalWrapper = styled(ModalWrapperComponent)`
    position: relative;
    z-index: ${ZIndex.Modal};

    .overlay {
        background-color: ${rgba(Color.UiBlack1, 0.7)};
        animation: ${overlayAnimation} 0.3s ease both;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`
