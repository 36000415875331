/* tslint:disable:member-ordering */
import { Chart } from 'aos-components/src/components/chart/Chart'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import {
    ManyBarsSeries,
    ManyBarsSeriesDefinition,
} from 'aos-components/src/components/chart/series/ManyBarsSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import {
    isTimeInTimeRangeFilter,
    TimeRangeFilter,
} from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import { FlightVolumePoint } from 'aos-services/src/services/airportStatus/flights/types/FlightVolumePoint'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import { format } from 'd3-format'
import React, { PureComponent } from 'react'

export interface FlightsChartProps {
    volumes: FlightVolumePoint[]
    volumesForecast: FlightVolumePoint[]
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    timeRangeFilter: TimeRangeFilter
}

export class FlightsChart extends PureComponent<FlightsChartProps> {
    private xAccessor = (d: FlightVolumePoint) => d.time
    private arrivalAccessor = (d: FlightVolumePoint) => d.arrivals
    private departureAccessor = (d: FlightVolumePoint) => d.departures
    private allAccessor = (d: FlightVolumePoint) => d.all

    private colors = [Color.PaleOrange, Color.Violet] as [Color, Color]

    private lineSeriesPastConfig = {
        color: Color.White,
        withDots: true,
        tooltip: this.allAccessor,
    }
    private lineSeriesFutureConfig = {
        color: Color.White,
        isDashed: true,
        withDots: true,
        dotColor: Color.UiBlack1,
        tooltip: this.allAccessor,
    }

    public render() {
        const { xDomain, yDomain, timeRangeFilter } = this.props
        return (
            <Chart
                currentTime={dateTime()}
                xBandConfig={{
                    domain: xDomain,
                    tickFormat: formatHour,
                    tickMarked: isTimeInTimeRangeFilter(timeRangeFilter),
                    bandPadding: { x0: { inner: 0.25, outer: 0 } },
                    barCount: 2,
                }}
                y1Config={{
                    domain: yDomain,
                    tickFormat: format('d'),
                }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private tooltip = (index: 0 | 1) => (data: FlightVolumePoint) => {
        const bar = index === 0 ? data.arrivals : data.departures
        const color = this.colors[index]
        if (!bar) {
            return null
        }
        return <span style={{ color }}>{bar}</span>
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { xBandScale, y1Scale } = props
        if (!xBandScale || !y1Scale) {
            return null
        }

        const { volumes, volumesForecast } = this.props

        const barSeriesScales = {
            x0Scale: xBandScale.x0,
            x1Scale: xBandScale.x1,
            yScale: y1Scale,
        }
        const lineSeriesScales = {
            xScale: xBandScale.x0,
            yScale: y1Scale,
        }

        return (
            <>
                <ManyBarsSeries
                    data={volumesForecast}
                    series={[
                        this.barSeriesDefinition(0, this.arrivalAccessor, Color.PaleOrange),
                        this.barSeriesDefinition(1, this.departureAccessor, Color.Violet),
                    ]}
                    scales={barSeriesScales}
                    xAccessor={this.xAccessor}
                />
                <ManyBarsSeries
                    data={volumes}
                    series={[
                        this.barSeriesDefinition(0, this.arrivalAccessor),
                        this.barSeriesDefinition(1, this.departureAccessor),
                    ]}
                    scales={barSeriesScales}
                    xAccessor={this.xAccessor}
                />

                <LineSeries
                    data={volumes}
                    seriesConfig={this.lineSeriesPastConfig}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.allAccessor }}
                    scales={lineSeriesScales}
                />
                <LineSeries
                    data={volumesForecast}
                    seriesConfig={this.lineSeriesFutureConfig}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.allAccessor }}
                    scales={lineSeriesScales}
                />
            </>
        )
    }

    private barSeriesDefinition(
        index: 0 | 1,
        yAccessor: f.Func1<FlightVolumePoint, number>,
        fillColor?: Color,
    ): ManyBarsSeriesDefinition<FlightVolumePoint, undefined | number> {
        return {
            stroke: this.colors[index],
            fill: fillColor || this.colors[index],
            yAccessor,
            tooltip: this.tooltip(index),
        }
    }
}
