import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { format, Query } from 'url'

import { TaskChangeObject } from '../../services/events/types/AosEventChecklist'
import { BaseRestService } from '../base/BaseRestService'
import { AosCommonEventDto } from '../feeds/types/AosCommonEventDto'
import { AosDashboardEventDto } from './types/AosDashboardEventDto'
import { AosEventDto, AosFeedOutInputDto } from './types/AosEventDto'
import { AosMapEventDto } from './types/AosMapEventDto'
import { AosTimelineEventDto } from './types/AosTimelineEventDto'
import { TimelineRequest } from './types/TimelineRequest'

class EventsRestServiceClass extends BaseRestService {
    public loadEvents(filter: string, query: Query): Promise<Pageable<AosEventDto>> {
        return this.getAndTransform<Pageable<AosEventDto>>(
            format({
                pathname: `/events/${filter}`,
                query,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public editEvent(id: number, e: AosCommonEventDto): Promise<Response> {
        return this.patchJson(`/events/${id}`, e)
    }

    public activateEvent(e: AosCommonEventDto): Promise<AosEventDto> {
        return this.postJsonAndTransform<AosEventDto>('/events', e, BaseRestService.jsonTransformer)
    }

    public activateAtcEvent(e: AosCommonEventDto): Promise<AosCommonEventDto> {
        return this.postJsonAndTransform<AosCommonEventDto>(
            '/events/create-atc-event',
            e,
            BaseRestService.jsonTransformer,
        )
    }

    public loadTimelineEvents(r: TimelineRequest): Promise<AosTimelineEventDto[]> {
        return this.getAndTransform<AosTimelineEventDto[]>(
            format({
                pathname: '/events/timeline',
                query: r as Record<string, any>,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public loadDashboardEvents(): Promise<AosDashboardEventDto[]> {
        return this.getAndTransform<AosDashboardEventDto[]>(
            '/events/status-dashboard',
            BaseRestService.jsonTransformer,
        )
    }

    public loadEvent(id: number): Promise<AosEventDto> {
        return this.getAndTransform<AosEventDto>(`/events/${id}`, BaseRestService.jsonTransformer)
    }

    public closeEvent(id: number, closeTimeISOString: string): Promise<any> {
        return this.postJson(`/events/${id}/close`, {
            closeTime: closeTimeISOString,
        })
    }

    public reopenEvent(id: number): Promise<any> {
        return this.post(`/events/${id}/reopen`)
    }

    public deleteEvent(id: number): Promise<any> {
        return this.deleteReq(`/events/${id}`)
    }

    public undeleteEvent(id: number): Promise<any> {
        return this.post(`/events/${id}/undelete`)
    }

    public createPrivateChannel(
        eventId: number,
        groupId: number,
        hardAlert: boolean,
    ): Promise<void> {
        return this.postJsonAndTransform<void>(
            `/events/${eventId}/private-channel/${groupId}`,
            {
                hardAlert,
            },
            BaseRestService.jsonTransformer,
        )
    }

    public createFeedOut(id: number, dto: AosFeedOutInputDto): Promise<any> {
        return this.postJson(`/events/${id}/feed-out`, dto)
    }

    public updateEventchecklistTask(changeObject: TaskChangeObject): Promise<void> {
        return this.patchJsonAndTransform<void>(
            `/events/${changeObject.eventId}/checklists/${changeObject.checklistId}/items/${changeObject.taskId}`,
            {
                done: changeObject.done,
            },
            BaseRestService.jsonTransformer,
        )
    }

    public updateChecklists(templateIds: number[], eventId: number): Promise<any> {
        return this.postJsonAndTransform(
            `/events/${eventId}/checklists`,
            {
                templateIds,
            },
            BaseRestService.jsonTransformer,
        )
    }

    public loadMapEvents(bbox: string): Promise<AosMapEventDto[]> {
        return this.getAndTransform<AosMapEventDto[]>(
            format({
                pathname: '/events/map',
                query: {
                    bbox,
                },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public exportEvents(r: TimelineRequest): Promise<[Blob, string]> {
        return this.getAndTransform(
            format({
                pathname: '/events/export',
                query: r as Record<string, any>,
            }),
            BaseRestService.fileDownloadTransformer,
        )
    }
}

export const eventsRestService = new EventsRestServiceClass()
