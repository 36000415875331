import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'

import { SimpleFormModalState, ValueWrapper } from '../simpleForm/state'

export interface SingleDateModalState
    extends SimpleFormModalState<DateTime, SingleDateModalFormValidation, any> {
    form: FormValidation<SingleDateModalFormValidation>
}

export type DateValueWrapper = ValueWrapper<DateTime, any>

export const createEmptyValueWrapper = (): DateValueWrapper => ({
    value: dateTime(),
})

export interface SingleDateModalFormValidation {
    value: boolean
}

export const validateValueWrapper = (e: DateValueWrapper): SingleDateModalFormValidation => ({
    value: isNotEmpty(e.value),
})

export const createInitialSingleDateModalState = (): SingleDateModalState => {
    const emptyValueWrapper = createEmptyValueWrapper()

    return {
        isOpen: false,
        currentValue: emptyValueWrapper,
        form: FormValidation.fromFields(validateValueWrapper(emptyValueWrapper)),
    }
}
