import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Notam, Snowtam } from '../../services/flightInformation/types/Notam'
import { SyncDataKey } from '../../services/statusDashboard/types/DashboardDataState'

export const SNOWTAM_SYNC = 'NOTAM/SNOWTAM_SYNC'
export type SnowtamSyncAction = PayloadAction<typeof SNOWTAM_SYNC, SyncDataKey>
export const snowtamSyncAction = payloadActionCreator<SnowtamSyncAction, SyncDataKey>(SNOWTAM_SYNC)

export const SNOWTAM_SYNC_STOP = 'NOTAM/SNOWTAM_SYNC_STOP'
export type SnowtamSyncStopAction = PayloadAction<typeof SNOWTAM_SYNC_STOP, SyncDataKey>
export const snowtamSyncStopAction = payloadActionCreator<SnowtamSyncStopAction, SyncDataKey>(
    SNOWTAM_SYNC_STOP,
)

export const SNOWTAM_LOAD = 'NOTAM/SNOWTAM_LOAD'
export type SnowtamLoadAction = Action<typeof SNOWTAM_LOAD>
export const snowtamLoadAction = emptyActionCreator<SnowtamLoadAction>(SNOWTAM_LOAD)

export const SNOWTAM_LOAD_SUCCESS = 'NOTAM/SNOWTAM_LOAD_SUCCESS'
export type SnowtamLoadSuccessAction = PayloadAction<typeof SNOWTAM_LOAD_SUCCESS, Snowtam[]>
export const snowtamLoadSuccessAction = payloadActionCreator<SnowtamLoadSuccessAction, Snowtam[]>(
    SNOWTAM_LOAD_SUCCESS,
)

export const NOTAM_SYNC = 'NOTAM/SYNC'
export type NotamSyncAction = PayloadAction<typeof NOTAM_SYNC, SyncDataKey>
export const notamSyncAction = payloadActionCreator<NotamSyncAction, SyncDataKey>(NOTAM_SYNC)

export const NOTAM_SYNC_STOP = 'NOTAM/SYNC_STOP'
export type NotamSyncStopAction = PayloadAction<typeof NOTAM_SYNC_STOP, SyncDataKey>
export const notamSyncStopAction = payloadActionCreator<NotamSyncStopAction, SyncDataKey>(
    NOTAM_SYNC_STOP,
)

export const NOTAM_LOAD = 'NOTAM/LOAD'
export type NotamLoadAction = Action<typeof NOTAM_LOAD>
export const notamLoadAction = emptyActionCreator<NotamLoadAction>(NOTAM_LOAD)

export const NOTAM_LOAD_SUCCESS = 'NOTAM/LOAD_SUCCESS'
export type NotamLoadSuccessAction = PayloadAction<typeof NOTAM_LOAD_SUCCESS, any>
export const notamLoadSuccessAction = payloadActionCreator<NotamLoadSuccessAction, Notam[]>(
    NOTAM_LOAD_SUCCESS,
)

export type NotamBaseActions =
    | NotamLoadAction
    | NotamLoadSuccessAction
    | NotamSyncAction
    | NotamSyncStopAction
    | SnowtamLoadAction
    | SnowtamLoadSuccessAction
