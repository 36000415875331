import moment from 'moment'

import { DateTime } from './Time'
import { defaultDateFormat, defaultTimeFormat, formatDate } from './TimeFormat'

export type TimeMode = 'date' | 'time' | 'datetime'

const formats: Record<TimeMode, string> = {
    date: defaultDateFormat,
    time: defaultTimeFormat,
    datetime: `${defaultDateFormat} ${defaultTimeFormat}`,
}

export const formatDateTimeByMode = (v: DateTime, mode: TimeMode) => formatDate(v, formats[mode])

// tslint:disable-next-line:arrow-return-shorthand
export const dateTimeByMode = (v: string, mode: TimeMode) => {
    // tslint:disable:ban
    return moment(v, formats[mode])
}
