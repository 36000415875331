import { BimLayerName } from '../../../../aos-ui-map/src/components/map/bim/BimLayerName'
import {
    AosEventChecklist,
    AosEventChecklistItem,
    EditableChecklist,
    TaskChangeObject,
    TaskOrMessageTemplate,
} from './types/AosEventChecklist'
import { AosEventChecklistItemImpl } from './types/AosEventChecklistItemImpl'

export function completeEditableChecklistTask(
    checklists: EditableChecklist[],
    change: TaskChangeObject,
): EditableChecklist[] {
    return checklists.map(
        mapChecklist(change.checklistId, (checklist: EditableChecklist) => ({
            ...checklist,
            items: checklist.items.map(task =>
                task.id === change.taskId
                    ? ({
                          ...(task instanceof AosEventChecklistItemImpl
                              ? task.toObject()
                              : { ...task, done: false }),
                          done: change.done,
                      } as AosEventChecklistItem)
                    : task,
            ),
        })),
    )
}

export const mapChecklist =
    (id: number, mapper: f.Func1<EditableChecklist, EditableChecklist>) =>
    (checklist: EditableChecklist): EditableChecklist =>
        checklist.id === id ? mapper(checklist) : checklist

export function implementEditableChecklists(
    checklists: AosEventChecklist[],
    previousChecklists: EditableChecklist[] | undefined,
): EditableChecklist[] {
    return checklists.map(checklist => ({
        id: checklist.id,
        name: checklist.name,
        sourceId: checklist.sourceId,
        items: checklist.items,
        isCollapsed: previousChecklists
            ? previousChecklists.find(c => c.id === checklist.id)?.isCollapsed ?? false
            : false,
        tab: TaskOrMessageTemplate.Task,
        tag: checklist.tag,
    }))
}

export const handleUserLayerVisibilityChange = (layer: BimLayerName, currentLayers: string[]) => {
    const state = [...(currentLayers || [])]
    const index = state.indexOf(layer)
    if (index > -1) {
        state.splice(index, 1)
    } else {
        state.push(layer)
    }
    return state
}
