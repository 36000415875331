import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { PieChart } from 'aos-components/src/components/chart/PieChart'
import { translateTrend, Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { TrendMeasure } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { identity } from 'lodash'
import React, { FC } from 'react'

export const DashboardPieChart: FC<DashboardPieChartProps> = props => {
    const renderTrend = ({
        value,
        trend,
        tooltipPrefix,
        trendVariant,
        unit,
    }: DashboardPieChartTrendProps) => (
        <Tooltip body={translateTrend(tooltipPrefix)(trend)} placement='top' withArrow>
            <div>
                <TrendMeasure
                    marginLeft={16}
                    centered
                    value={value}
                    trend={trend}
                    trendVariant={trendVariant}
                    unit={unit}
                />
            </div>
        </Tooltip>
    )
    return (
        <PieChart
            seriesConfig={{
                color: (i: number) => props.seriesColors[i],
            }}
            data={props.data}
            yAccessor={identity}
            arcContent={renderTrend(props.trend)}
            legendContent={props.legend}
            label={props.label}
            compact={props.compact}
        />
    )
}

interface DashboardPieChartTrendProps {
    value: string | number
    trend: Trend
    unit?: string
    tooltipPrefix: string
    trendVariant?: TrendChangeVariant
}

export interface DashboardPieChartProps extends ClassNameProps {
    trend: DashboardPieChartTrendProps
    seriesColors: { [index: number]: string }
    data: number[]
    legend?: JSX.Element
    label?: JSX.Element
    compact?: boolean
}
