import {
    paxSyncAction,
    paxSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/pax/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { FC, PropsWithChildren } from 'react'

import { useSyncHook } from '../../../../../hooks/useSync'

interface PaxSyncWrapperProps {
    id: SyncDataKey
}

export const PaxSyncWrapper: FC<PropsWithChildren<PaxSyncWrapperProps>> = ({ children, id }) => {
    useSyncHook([paxSyncAction(id)], [paxSyncStopAction(id)])
    return children as JSX.Element
}
