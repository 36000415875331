import { EnumValues } from 'enum-values'

export enum RecurrenceCustomOn {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    FOURTH = 'FOURTH',
    LAST = 'LAST',
    EVERY = 'EVERY',
}

export const recurrenceCustomOns = EnumValues.getValues<RecurrenceCustomOn>(RecurrenceCustomOn)

export enum RecurrenceCustomOnValue {
    MO = 'MO',
    TU = 'TU',
    WE = 'WE',
    TH = 'TH',
    FR = 'FR',
    SA = 'SA',
    SU = 'SU',
    DAY = 'DAY',
}

export const recurrenceCustomOnValues =
    EnumValues.getValues<RecurrenceCustomOnValue>(RecurrenceCustomOnValue)

export enum RecurrenceMonths {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export const recurrenceRecurrenceMonths = EnumValues.getValues<RecurrenceMonths>(RecurrenceMonths)
