import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../services/firebase/firebaseService'
import { flightsService } from '../../services/flightInformation/flightsService'
import { Flight } from '../../services/flightInformation/types/Flight'
import {
    FlightLoadAction,
    flightLoadAction,
    flightLoadSuccessAction,
    FLIGHTS_LOAD,
    FLIGHTS_SYNC,
    FLIGHTS_SYNC_STOP,
    FlightsSyncAction,
    FlightsSyncStopAction,
} from './actions'

const LISTENER_NAME = 'flights'

function* flightSyncSaga() {
    yield takeEvery<FlightsSyncAction>(FLIGHTS_SYNC, function* (action) {
        yield* firebaseService.onListener(
            `${LISTENER_NAME}/${action.payload}`,
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.FLIGHT),
                    flightLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* flightSyncStopSaga() {
    yield takeEvery<FlightsSyncStopAction>(FLIGHTS_SYNC_STOP, function* (action) {
        yield firebaseService.offListener(`${LISTENER_NAME}/${action.payload}`, action.payload)
    })
}

function* flightLoadSaga() {
    yield takeEvery<FlightLoadAction>(FLIGHTS_LOAD, function* () {
        const data: Flight[] = yield call(flightsService.loadFlights)
        yield put(flightLoadSuccessAction(data))
    })
}

export const flightSagas = [flightSyncSaga, flightSyncStopSaga, flightLoadSaga]
