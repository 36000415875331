import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { PageRequest } from 'aos-helpers/src/helpers/PageRequest'

import { BaseFodFormPayload, FodDto } from '../../services/fod/types'
import { SyncDataKey } from '../../services/statusDashboard/types/DashboardDataState'

export const FOD_CHANGE_FORM = 'FOD/CHANGE_FORM'
export type ChangeFodFormAction = PayloadAction<typeof FOD_CHANGE_FORM, Partial<BaseFodFormPayload>>
export const changeFodFormAction = payloadActionCreator<
    ChangeFodFormAction,
    Partial<BaseFodFormPayload>
>(FOD_CHANGE_FORM)

export const LOAD_FOD = 'FOD/LOAD'
export type LoadFodAction = PayloadAction<typeof LOAD_FOD, PageRequest>
export const loadFod = payloadActionCreator<LoadFodAction, PageRequest>(LOAD_FOD)

export const LOAD_FOD_SUCCESS = 'FOD/LOAD_SUCCESS'
export type LoadFodSuccessAction = PayloadAction<typeof LOAD_FOD_SUCCESS, Pageable<FodDto>>
export const loadFodSuccess = payloadActionCreator<LoadFodSuccessAction, Pageable<FodDto>>(
    LOAD_FOD_SUCCESS,
)

export const LOAD_FOD_FAILED = 'FOD/LOAD_FAILED'
export type LoadFodFailedAction = Action<typeof LOAD_FOD_FAILED>

export type FodPayload = Partial<Omit<FodDto, 'attachments'>>
export const CREATE_FOD = 'FOD/CREATE'
export type CreateFodAction = PayloadAction<typeof CREATE_FOD, FodPayload>
export const createFod = payloadActionCreator<CreateFodAction, FodPayload>(CREATE_FOD)

export const CREATE_FOD_FAILED = 'FOD/CREATE_FAILED'
export type CreateFodFailedAction = Action<typeof CREATE_FOD_FAILED>
export const createFodFailed = emptyActionCreator<CreateFodFailedAction>(CREATE_FOD_FAILED)

export const CREATE_FOD_SUCCESS = 'FOD/CREATE_SUCCESS'
export type CreateFodSuccessAction = Action<typeof CREATE_FOD_SUCCESS>
export const createFodSuccess = emptyActionCreator<CreateFodSuccessAction>(CREATE_FOD_SUCCESS)

export const EDIT_FOD = 'FOD/EDIT'
export type EditFodAction = PayloadAction<typeof EDIT_FOD, FodPayload>
export const editFod = payloadActionCreator<EditFodAction, FodPayload>(EDIT_FOD)

export const EDIT_FOD_FAILED = 'FOD/EDIT_FAILED'
export type EditFodFailedAction = Action<typeof EDIT_FOD_FAILED>
export const editFodFailed = emptyActionCreator<EditFodFailedAction>(EDIT_FOD_FAILED)

export const EDIT_FOD_SUCCESS = 'FOD/EDIT_SUCCESS'
export type EditFodSuccessAction = Action<typeof EDIT_FOD_SUCCESS>
export const editFodSuccess = emptyActionCreator<EditFodSuccessAction>(EDIT_FOD_SUCCESS)

export const FOD_CLEAR_FORM = 'FOD/CLEAR_FORM'
export type FodClearFormAction = PayloadAction<typeof FOD_CLEAR_FORM, boolean>
export const fodClearFormAction = payloadActionCreator<FodClearFormAction, boolean>(FOD_CLEAR_FORM)

export const FOD_PRISTINE_CLEAR_FORM = 'FOD/PRISTINE_CLEAR_FORM'
export type FodPristineClearFormAction = PayloadAction<typeof FOD_PRISTINE_CLEAR_FORM, boolean>
export const fodPristineClearFormAction = payloadActionCreator<FodPristineClearFormAction, boolean>(
    FOD_PRISTINE_CLEAR_FORM,
)

export const FOD_SYNC = 'STATUS_DASHBOARD_DATA/FOD/FOD_SYNC'
export type FodSyncAction = PayloadAction<typeof FOD_SYNC, SyncDataKey>
export const fodSyncAction = payloadActionCreator<FodSyncAction, SyncDataKey>(FOD_SYNC)

export const FOD_SYNC_STOP = 'STATUS_DASHBOARD_DATA/FOD/FOD_SYNC_STOP'
export type FodSyncStopAction = PayloadAction<typeof FOD_SYNC_STOP, SyncDataKey>
export const fodSyncStopAction = payloadActionCreator<FodSyncStopAction, SyncDataKey>(FOD_SYNC_STOP)

export type FodActions =
    | LoadFodAction
    | LoadFodFailedAction
    | LoadFodSuccessAction
    | CreateFodAction
    | CreateFodFailedAction
    | CreateFodSuccessAction
    | FodClearFormAction
    | ChangeFodFormAction
    | FodPristineClearFormAction
    | FodSyncAction
    | FodSyncStopAction
    | EditFodAction
    | EditFodFailedAction
    | EditFodSuccessAction
