import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { aprons } from '../../masterData/aprons'
import { terminals } from '../../masterData/terminals'

export interface RestrictionArea {
    areaType: RestrictionAreaType
    areaNumber: string
}

export enum RestrictionAreaType {
    Apron = 'APRON',
    Terminal = 'TERMINAL',
}

export const restrictionAreas: RestrictionArea[] = [
    ...terminals.map(terminal => ({
        areaNumber: terminal,
        areaType: RestrictionAreaType.Terminal,
    })),
    ...aprons.map(apron => ({
        areaNumber: apron,
        areaType: RestrictionAreaType.Apron,
    })),
]

const areaNumberLabel: Record<RestrictionAreaType, string> = {
    [RestrictionAreaType.Apron]: 'restrictions.apron.number',
    [RestrictionAreaType.Terminal]: 'restrictions.terminal.number',
}

const areaNumberShortLabel: Record<RestrictionAreaType, string> = {
    [RestrictionAreaType.Apron]: 'restrictions.apron.number.short',
    [RestrictionAreaType.Terminal]: 'restrictions.terminal.number.short',
}

export const translateAreaNumberLabel = (type: RestrictionAreaType, number: string): string =>
    translate(areaNumberLabel[type], { number })

export const translateAreaNumberShortLabel = (type: RestrictionAreaType, number: string): string =>
    translate(areaNumberShortLabel[type], { number })

export const translateRestrictionAreaResources = translateEnum<RestrictionAreaType>(
    RestrictionAreaType,
    'restrictions.area-resources',
)
