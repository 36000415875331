import { uniqueId } from 'lodash'
import { useEffect, useState } from 'react'

export const useAnimationEnd = () => {
    const [value, setValue] = useState('')

    const trigger = () => {
        setValue(uniqueId())
    }
    useEffect(() => {
        window.addEventListener('animationEnd', trigger)
        return () => {
            window.removeEventListener('animationEnd', trigger)
        }
    }, [])

    return { animationEndValue: value }
}

export const useAnimationEndTrigger = () => ({
    trigger: () => {
        window.dispatchEvent(new Event('animationEnd'))
    },
})
