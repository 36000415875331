import {
    DashboardItemType,
    translateDashboardItemType,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { SidebarButtonRow } from './SidebarButtonRow'

interface DashboardItemEntryProps {
    type: DashboardItemType
    numberOfItems: number
    addDashboardItem(v: DashboardItemType): void
}

export const DashboardItemTypeEntry: FC<DashboardItemEntryProps> = ({
    type,
    numberOfItems,
    ...props
}) => (
    <SidebarButtonRow icon={SvgIcon.ZoomIn} onClick={() => props.addDashboardItem(type)}>
        {translateDashboardItemType('dashboard.item.long-name')(type) + ` ( ${numberOfItems} )`}
    </SidebarButtonRow>
)
