import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import React, { FC, useEffect, useState } from 'react'

import { BimLayerName } from '../../../bim/BimLayerName'
import { LayerControl } from './LayerControl'
import { LayerModal } from './LayerModal'

interface AtcLayerProps {
    onSelectedLayer?(layer: BimLayerName): void
    userLayerVisibility: UserLayerVisibilityState
    isApoc: boolean
    isVisible?: boolean
}
export const AtcLayer: FC<AtcLayerProps> = ({
    onSelectedLayer,
    userLayerVisibility,
    isApoc,
    isVisible,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (!isVisible) {
            setIsModalOpen(false)
        }
    }, [isVisible])

    return (
        <>
            {isVisible && <LayerControl onClick={() => setIsModalOpen(true)} />}
            <LayerModal
                isApoc={isApoc}
                userLayerVisibility={userLayerVisibility}
                isOpen={isModalOpen}
                closeAction={() => setIsModalOpen(false)}
                onSelectedLayer={onSelectedLayer}
            />
        </>
    )
}
