import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'

export class VerticalTabs<T> extends PureComponent<VerticalTabsProps<T>> {
    public render() {
        return <Box>{this.props.items.map(this.renderItem)}</Box>
    }

    private renderItem = (item: T, index: number) => {
        const isActive = this.props.activeIndex === index
        return (
            <TabItem key={index} relative isActive={isActive}>
                {this.props.itemRenderer(item, isActive, index)}
            </TabItem>
        )
    }
}

interface VerticalTabsProps<T> {
    items: T[]
    activeIndex: number
    itemRenderer(a: T, b: boolean, c: number): JSX.Element
}

const TabItem = styled(Box)<{ isActive: boolean }>`
    ${p =>
        p.isActive &&
        css`
            ::before {
                position: absolute;
                top: 0;
                bottom: 0;
                display: block;
                content: '';
                width: 2px;
                height: 12px;
                background-color: ${Color.White};
                margin: auto 0;
            }
        `}
`
