import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'

import { Icon } from '../svg/Icon'
import { FormButton, FormButtonProps } from './FormButton'
import { FormButtonContentProps, LeftRightContent } from './FormButtonContent'

interface NavigationFormButtonProps extends FormButtonProps {
    buttonVariant?: NavigationFormButtonVariant
}

export enum NavigationFormButtonVariant {
    Prev,
    Next,
}

export const NavigationFormButton: FCWithChildren<NavigationFormButtonProps> = ({
    buttonVariant,
    ...rest
}) => {
    const LabelContentComponent = ({ size, children }: FormButtonContentProps) => {
        if (buttonVariant === NavigationFormButtonVariant.Prev) {
            return (
                <LeftRightContent
                    size={size}
                    left={<Icon svg={SvgIcon.Back} iconSize={BlockSize.Tiny} />}
                >
                    {children}
                </LeftRightContent>
            )
        }
        if (buttonVariant === NavigationFormButtonVariant.Next) {
            return (
                <LeftRightContent
                    size={size}
                    right={<Icon svg={SvgIcon.Forward} iconSize={BlockSize.Tiny} />}
                >
                    {children}
                </LeftRightContent>
            )
        }
        return null
    }
    return <FormButton LabelContentComponent={LabelContentComponent} {...rest} />
}
