import { ClassNameProps, cx } from 'aos-components/src/components/base/ClassNames'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC, PropsWithChildren } from 'react'

interface StatusDashboardItemFadeInProps extends ClassNameProps {}

export const StatusDashboardItemFadeIn: FC<
    PropsWithChildren<StatusDashboardItemFadeInProps>
> = props => {
    const { children, className = 'flex--auto container container--vertical' } = props
    return <Box className={cx('animation--fade-in', className)}>{children}</Box>
}
