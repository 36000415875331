import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { MarginBoxProps, marginStyleGenerator } from 'aos-ui/src/components/base/Box'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { textStyleBuilder } from 'aos-ui/src/components/base/Text'
import { borderRadius, boxShadow, FormComponentSize, time } from 'aos-ui/src/components/base/Theme'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { Color } from 'aos-ui-common/src/styles/Color'
import { lighten } from 'polished'
import React, { ComponentType, FCWithChildren, ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'

import { Icon } from '../svg/Icon'
import { Spinner } from '../ui/Spinner'
import { FormButtonContentProps, LabelContent } from './FormButtonContent'

export enum FormButtonVariant {
    Blue,
    White,
    WhiteOutlined,
    Mobile,
    Red,
    Green,
    Yellow,
    RedOutlined,
    Grey,
    Flat,
    BlueOutlined,
    Text,
}

export interface FormButtonProps extends ButtonProps, FormButtonStyleProps {
    label: ReactNode
    type?: 'submit' | 'reset' | 'button'
    overlayIcon?: Svg
    isLoading?: boolean
    style?: CSSObject
    LabelContentComponent?: ComponentType<FormButtonContentProps>
}

export interface FormButtonStyleProps extends MarginBoxProps {
    variant?: FormButtonVariant
    size?: FormComponentSize
    fullWidth?: boolean
}

export const FormButton: FCWithChildren<FormButtonProps> = props => {
    const {
        label,
        onClick,
        variant,
        overlayIcon,
        isLoading = false,
        size = FormComponentSize.Std,
        seleniumLocation,
        id,
        type,
        LabelContentComponent = LabelContent,
        fullWidth,
        style,
        ...margins
    } = props
    const hasOverlay = !!overlayIcon || isLoading
    return (
        <FormButtonWrapper
            id={id}
            onClick={onClick}
            style={style}
            variant={variant}
            size={size}
            type={type}
            fullWidth={fullWidth}
            data-test-id={seleniumLocation || 'form-button'}
            {...margins}
        >
            {isLoading && (
                <Overlay>
                    <Spinner />
                </Overlay>
            )}
            {!isLoading && overlayIcon && (
                <Overlay>
                    <Icon svg={overlayIcon} iconSize={BlockSize.Std} />
                </Overlay>
            )}
            <LabelContentComponent size={size} transparent={hasOverlay}>
                {label}
            </LabelContentComponent>
        </FormButtonWrapper>
    )
}

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FormButtonWrapper = styled(Button)<FormButtonStyleProps>(
    ({ variant = FormButtonVariant.Blue, size = FormComponentSize.Std, fullWidth, ...rest }) => {
        const margins = marginStyleGenerator(rest)
        const styles: CSSObject = {
            borderRadius: `${borderRadius.default}px`,
            transition: `background-color ${time.fast}s ease`,
            position: 'relative',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }

        const sizeStyleMap: Record<FormComponentSize, CSSObject> = {
            [FormComponentSize.Big]: {
                height: `${FormComponentSize.Big}px`,
                lineHeight: `${FormComponentSize.Big}px`,
                fontSize: '14px',
                boxShadow: boxShadow.big,
            },
            [FormComponentSize.Header]: {
                height: `${FormComponentSize.Header}px`,
                lineHeight: `${FormComponentSize.Header}px`,
                fontSize: '13px',
                boxShadow: boxShadow.std,
            },
            [FormComponentSize.Std]: {
                height: `${FormComponentSize.Std}px`,
                lineHeight: `${FormComponentSize.Std}px`,
                fontSize: '13px',
                boxShadow: boxShadow.std,
            },
            [FormComponentSize.Small]: {
                height: `${FormComponentSize.Small}px`,
                lineHeight: `${FormComponentSize.Small}px`,
                fontSize: '13px',
                boxShadow: boxShadow.std,
            },
            [FormComponentSize.VerySmall]: {
                height: `${FormComponentSize.VerySmall}px`,
                lineHeight: `${FormComponentSize.VerySmall}px`,
                fontSize: '12px',
                boxShadow: boxShadow.std,
            },
        }

        const variantStyleMap: Record<FormButtonVariant, CSSObject> = {
            [FormButtonVariant.Blue]: {
                'background': Color.Primary,
                ':hover': {
                    background: Color.PrimaryLight,
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
                ':disabled': {
                    background: Color.UiBlack3,
                    color: Color.Grey2,
                },
            },
            [FormButtonVariant.White]: {
                'background': Color.White,
                ':hover': {
                    ...textStyleBuilder({ color: Color.Grey }),
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.Black, weight: 'regular' }),
            },
            [FormButtonVariant.BlueOutlined]: {
                'background': Color.Transparent,
                ':hover': {
                    background: lighten(0.4, Color.Primary),
                    color: Color.White,
                },
                'border': `1px solid ${Color.Primary}`,
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.Primary, weight: 'regular' }),
            },
            [FormButtonVariant.WhiteOutlined]: {
                'background': Color.Transparent,
                ':hover': {
                    background: Color.White,
                    color: Color.Black,
                },
                'border': `1px solid ${Color.Grey2}`,
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.Red]: {
                'background': Color.Red,
                ':hover': {
                    background: lighten(0.1, Color.Red),
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.RedOutlined]: {
                'background': Color.Transparent,
                ':hover': {
                    background: lighten(0.1, Color.Red),
                    color: Color.White,
                },
                'border': `1px solid ${Color.Red}`,
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.Red, weight: 'regular' }),
            },
            [FormButtonVariant.Mobile]: {
                background: Color.Primary,
                borderRadius: '32px',
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.Green]: {
                'background': Color.Green,
                ':hover': {
                    background: lighten(0.1, Color.Green),
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.Yellow]: {
                'background': Color.Yellow,
                ':hover': {
                    background: lighten(0.1, Color.Yellow),
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.Black, weight: 'regular' }),
            },
            [FormButtonVariant.Grey]: {
                'background': Color.Grey,
                ':hover': {
                    background: lighten(0.1, Color.Grey),
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.Flat]: {
                'background': Color.Transparent,
                ':hover': {
                    background: Color.TransparentWhite,
                },
                ...sizeStyleMap[size],
                ...textStyleBuilder({ color: Color.White, weight: 'regular' }),
            },
            [FormButtonVariant.Text]: {
                'background': Color.Transparent,
                ':hover': {
                    color: Color.PrimaryLight,
                },
                ...sizeStyleMap[size],
                'boxShadow': 'none',
                ...textStyleBuilder({ color: Color.Primary, weight: 'regular' }),
            },
        }

        if (fullWidth) {
            styles.width = '100%'
        }

        return { ...margins, ...styles, ...variantStyleMap[variant] }
    },
)
