import { call, put, select, takeEvery } from 'redux-saga/effects'

import { syncValue } from '../../../../aos-helpers/src/helpers/firebase/FirebaseSaga'
import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firefightersService } from '../../dataaccess/tasks/firefighters/firefightersService'
import { firebaseService } from '../../services/firebase/firebaseService'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { currentUserSiteLocation } from '../auth/state'
import {
    FIREFIGHTER_STOP_SYNC,
    FIREFIGHTER_SYNC,
    FIREFIGHTER_UPDATE_SHIFT,
    FIREFIGHTERS_ADD_SHIFT,
    FirefightersAddShiftAction,
    firefightersAddShiftSuccessAction,
    firefightersLoadShiftAction,
    FirefighterStopSyncAction,
    FirefighterSyncAction,
    FirefighterUpdateShiftAction,
    firefighterUpdateShiftSuccessAction,
} from './actions'

function* addFirefighterShiftSaga() {
    yield takeEvery<FirefightersAddShiftAction>(FIREFIGHTERS_ADD_SHIFT, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const { data, onSuccess, onError } = action.payload

        try {
            yield call(firefightersService.add, siteLocation, data)
            onSuccess()
            yield put(firefightersAddShiftSuccessAction())
            yield put(firefightersLoadShiftAction())
        } catch (_) {
            onError && onError()
        }
    })
}

function* firefighterStopSyncSaga() {
    yield takeEvery<FirefighterStopSyncAction>(
        FIREFIGHTER_STOP_SYNC,
        function* (action: FirefighterStopSyncAction) {
            yield firebaseService.offListener('firefighter', action.payload)
        },
    )
}

function* updateFirefighterShiftSaga() {
    yield takeEvery<FirefighterUpdateShiftAction>(FIREFIGHTER_UPDATE_SHIFT, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const { data, onSuccess, onError } = action.payload

        try {
            yield call(firefightersService.update, siteLocation, data)
            onSuccess()
            yield put(firefighterUpdateShiftSuccessAction())
            yield put(firefightersLoadShiftAction())
        } catch (_) {
            onError && onError()
        }
    })
}

function* firefighterSyncSaga() {
    yield takeEvery<FirefighterSyncAction>(
        FIREFIGHTER_SYNC,
        function* (action: FirefighterSyncAction) {
            yield* firebaseService.onListener(
                'firefighter',
                function* () {
                    yield syncValue(
                        sendToSyncRepository.stsRef(SendToSyncType.FIREFIGHTERS),
                        firefightersLoadShiftAction,
                        false,
                    )
                },
                action.payload,
            ) as Generator
        },
    )
}

export const baseFirefightersSaga = [
    addFirefighterShiftSaga,
    updateFirefighterShiftSaga,
    firefighterSyncSaga,
    firefighterStopSyncSaga,
] as const
