import {
    operationalForecastSyncAction,
    operationalForecastSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/operationalForecast/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../../components/sync/SyncWrapper'

interface OperationalForecastSyncWrapperProps {
    id: SyncDataKey
}

export const OperationalForecastSyncWrapper: FC<
    PropsWithChildren<OperationalForecastSyncWrapperProps>
> = ({ children, id }) => (
    <SyncWrapper
        onEnter={operationalForecastSyncAction(id)}
        onLeave={operationalForecastSyncStopAction(id)}
    >
        {children}
    </SyncWrapper>
)
