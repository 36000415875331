import { syncValueWithCallback } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { attachmentsSagaFactory } from 'aos-services/src/core/attachments/sagas'
import { addEventLayerVisibilityAction } from 'aos-services/src/core/events/actions'
import { assureRingRailSync } from 'aos-services/src/core/layersData/sagas'
import { AosEventDto } from 'aos-services/src/dataaccess/events/types/AosEventDto'
import { AosCommonEventDto } from 'aos-services/src/dataaccess/feeds/types/AosCommonEventDto'
import { AosFeedInDto } from 'aos-services/src/dataaccess/feeds/types/AosFeedInDto'
import { firebaseEventsRepository } from 'aos-services/src/dataaccess/firebaseEvents/firebaseEventsRepository'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { feedsService } from 'aos-services/src/services/events/feedsService'
import { EventFormPayload, EventPayload } from 'aos-services/src/services/events/input/EventPayload'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { mapService } from 'aos-services/src/services/map/mapService'
import { MapRestriction } from 'aos-services/src/services/map/types/MapRestriction'
import { getGroupMembersCountTranslation } from 'aos-services/src/services/users/aosUserFormatter'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import {
    layerSyncRelatedActions,
    loadMapRestrictionsSuccessAction,
    OPEN_MAP,
} from 'aos-ui-map/src/core/actions'
import { shouldSyncRingRail } from 'aos-ui-map/src/core/state'
import { Task } from 'redux-saga'
import { call, cancel, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { rootHistory } from '../../components/providers/ToastNavigationConnectorWrapper'
import { AtcHardAlertContainer } from '../../views/eventModals/partial/AtcHardAlert'
import {
    HIDE_ACTION_MODAL,
    HideActionModalAction,
    showActionModalAction,
} from '../actionModal/actions'
import { loadEventInEventEditor } from '../eventEditor/sagas'
import { ADD_EVENT_TO_GROUP, AddEventToGroupAction } from '../eventManager/actions'
import { State } from '../state'
import {
    ACTIVATE_EVENT,
    ActivateEventAction,
    CREATE_EVENT,
    CreateEventActionType,
    EDIT_EVENT,
    EDIT_EVENT_SHOW_MODAL,
    EditEventAction,
    EditEventShowModalAction,
    EVENT_HIDE_MODAL,
    EVENT_MODALS_ATTACHMENTS_PARENT,
    EVENT_MODALS_MAP_PARENT,
    eventModalsAttachmentsParentAction,
    eventModalsMapParentAction,
    reopenEventShowModalAction,
    REPORT_EXTENDED_FEED_IN,
    REPORT_SIMPLE_FEED_IN,
    ReportExtendedFeedInAction,
    ReportSimpleFeedInAction,
    setAtcLayersAction,
    showThankYouForReportingAction,
    tryActivateFeedInAction,
} from './actions'
import { EventModalsStateAware } from './state'

function* eventPayload(form: EventFormPayload) {
    const attachments: UploadedAttachment[] = yield select(
        (state: EventModalsStateAware) => state.eventModals.attachmentsState.attachments,
    )
    return {
        ...form,
        attachments,
    }
}

function* reportSimpleFeedInSaga() {
    yield takeEvery<ReportSimpleFeedInAction>(
        REPORT_SIMPLE_FEED_IN,
        function* (action: ReportSimpleFeedInAction) {
            const payload: EventPayload = yield eventPayload(action.payload[0])
            yield call(feedsService.createSimpleFeedIn, payload)
            yield put(showThankYouForReportingAction())
        },
    )
}

function* reportExtendedFeedInSaga() {
    yield takeEvery<ReportExtendedFeedInAction>(
        REPORT_EXTENDED_FEED_IN,
        function* (action: ReportExtendedFeedInAction) {
            const payload: EventPayload = yield eventPayload(action.payload[0])
            yield call(feedsService.createExtendedFeedIn, payload)
            yield put(showThankYouForReportingAction())
        },
    )
}

function* activateEventSaga() {
    yield takeLatest<ActivateEventAction>(ACTIVATE_EVENT, function* (action: ActivateEventAction) {
        const isHardAlert =
            action.payload[0].severity === AosSeverity.Emergency && action.payload[0].atcAlert
        if (isHardAlert) {
            yield handleHardAlertEventWithConfirmation(
                { ...action.payload[0], activateFromFeedIn: true },
                action.payload[1],
            )
        } else if (action.payload[0].atcAlert) {
            yield handleAtcEventWithConfirmation(
                { ...action.payload[0], activateFromFeedIn: true },
                action.payload[1],
            )
        } else {
            const payload: EventPayload = yield eventPayload(action.payload[0])
            const result: AosFeedInDto = yield call(
                eventsService.activateEvent,
                payload,
                action.payload[1],
            )
            successToast(translate('event.messages.activated'), () => {
                rootHistory?.push(`/event-manager/${result.id}`)
            })
        }
    })
}

function* createEventSaga() {
    yield takeLatest<CreateEventActionType>(
        CREATE_EVENT,
        function* (action: CreateEventActionType) {
            const isHardAlert =
                action.payload[0].severity === AosSeverity.Emergency && action.payload[0].atcAlert
            if (isHardAlert) {
                yield handleHardAlertEventWithConfirmation(
                    { ...action.payload[0], activateFromFeedIn: false },
                    action.payload[1],
                )
            } else if (action.payload[0].atcAlert) {
                yield handleAtcEventWithConfirmation(
                    { ...action.payload[0], activateFromFeedIn: false },
                    action.payload[1],
                )
            } else {
                const payload: EventPayload = yield eventPayload(action.payload[0])
                const result: AosFeedInDto | AosEventDto = yield call(
                    eventsService.activateEvent,
                    payload,
                    action.payload[1],
                )
                successToast(translate('event.messages.created'), () => {
                    rootHistory?.push(`/event-manager/${result.id}`)
                })
            }
        },
    )
}

function* editEventSaga() {
    yield takeEvery<EditEventAction>(EDIT_EVENT, function* (action: EditEventAction) {
        const payload: EventPayload = yield eventPayload(action.payload[0])
        yield call(eventsService.editEvent, payload, action.payload[1])
        successToast(translate('event.messages.updated'), () => {
            rootHistory?.push(`/event-manager/${action.payload[1]}`)
        })
        yield loadEventInEventEditor(action.payload[1])
    })
}

function* addEventToGroupSaga() {
    yield takeEvery<AddEventToGroupAction>(
        ADD_EVENT_TO_GROUP,
        function* (action: AddEventToGroupAction) {
            yield call(feedsService.addEventToGroup, action.payload[0], action.payload[1])
        },
    )
}

function* syncRingRailSaga() {
    yield takeEveryChildAction(EVENT_MODALS_MAP_PARENT, layerSyncRelatedActions, function* () {
        yield assureRingRailSync(
            (state: State) => shouldSyncRingRail(state.eventModals.map),
            'eventModals',
        )
    })
}

function* stopSyncRingRailSaga() {
    yield takeEvery(EVENT_HIDE_MODAL, function* () {
        yield assureRingRailSync(() => false, 'eventModals')
    })
}

function* loadMapRestrictionsSaga() {
    yield takeEveryChildAction(EVENT_MODALS_MAP_PARENT, OPEN_MAP, function* () {
        const restrictions: MapRestriction[] = yield call(mapService.loadMapRestrictions)
        yield put(eventModalsMapParentAction(loadMapRestrictionsSuccessAction(restrictions)))
    })
}

function* handleOpenEditEventModal() {
    yield takeEvery(EDIT_EVENT_SHOW_MODAL, function* (action: EditEventShowModalAction) {
        if (action.payload.atcAlert) {
            yield syncValueWithCallback(
                firebaseEventsRepository.visibilityLayersRef(action.payload.id),
                function* (response: [string, string[]]) {
                    yield put(setAtcLayersAction(response[1]))
                },
                false,
            )
        }
    })
}

function* handleHardAlertEventWithConfirmation(event: EventFormPayload, id: number | undefined) {
    let takeLatestEffect: Task | undefined

    yield put(
        showActionModalAction({
            title: translate('send-hard-alert.text'),
            svg: SvgIcon.SendAlert,
            okLabel: translate('send-hard-alert.ok'),
            titleClassName: 'text__xl-black text--center',
            descriptionClassName: 'text__m_grey text--center',
            skipCloseAction: true,
            Content: AtcHardAlertContainer,
            Footer: () => null,
        }),
    )

    takeLatestEffect = yield takeLatest<HideActionModalAction>(
        HIDE_ACTION_MODAL,
        function* (response) {
            if (response.payload === true) {
                event.hardAlert = true
            }
            yield handleDialogResponse(response.payload !== false, event, id)
            if (takeLatestEffect) {
                yield cancel(takeLatestEffect)
            }
        },
    )
}

function* handleDialogResponse(response: boolean, event: EventFormPayload, id: number | undefined) {
    if (response) {
        const payload: EventPayload = yield eventPayload(event)
        let result: AosCommonEventDto | AosFeedInDto | undefined
        if (event.activateFromFeedIn) {
            result = yield call(eventsService.activateEvent, payload, id)
        } else {
            result = yield call(eventsService.activateAtcEvent, payload)
        }
        const atcLayers: string[] = yield select(
            (state: State) => state.eventModals.currentAtcLayers,
        )
        if (result?.id) {
            yield put(
                addEventLayerVisibilityAction({
                    eventId: result.id,
                    visibilityLayers: atcLayers,
                }),
            )
            successToast(
                translate(
                    event.activateFromFeedIn
                        ? 'event.messages.activated'
                        : 'event.messages.created',
                ),
                () => {
                    rootHistory?.push(`/event-manager/${result!.id}`)
                },
            )
        }
    } else {
        const attachments: UploadedAttachment[] = yield select(
            state => state.eventModals.attachmentsState.attachments,
        )
        if (event.activateFromFeedIn) {
            yield put(
                tryActivateFeedInAction({
                    ...event,
                    id,
                    hasLocation: !!event.location,
                    attachments: attachments,
                    allAttachments: attachments,
                } as AosFeedIn),
            )
        } else {
            yield put(reopenEventShowModalAction(event))
        }
    }
}

function* handleAtcEventWithConfirmation(event: EventFormPayload, id: number | undefined) {
    const aosUserGroup = event.aosUserGroup
    let takeLatestEffect: Task | undefined

    yield put(
        showActionModalAction({
            title: translate('invite-to-private-channel-confirmation.text'),
            description: `${aosUserGroup?.userCount} ${getGroupMembersCountTranslation(
                aosUserGroup?.userCount as number,
            )} (${aosUserGroup?.name})`,
            svg: SvgIcon.FeedOutConfirmation,
            okLabel: translate('invite-to-private-channel.ok'),
            titleClassName: 'text__s-grey text--center',
            descriptionClassName: 'text__m_black text--center',
            skipCloseAction: true,
        }),
    )

    takeLatestEffect = yield takeLatest<HideActionModalAction>(
        HIDE_ACTION_MODAL,
        function* (response) {
            yield handleDialogResponse(response.payload, event, id)

            if (takeLatestEffect) {
                yield cancel(takeLatestEffect)
            }
        },
    )
}

export const eventModalsSagas = [
    reportSimpleFeedInSaga,
    reportExtendedFeedInSaga,
    activateEventSaga,
    addEventToGroupSaga,
    editEventSaga,
    syncRingRailSaga,
    stopSyncRingRailSaga,
    loadMapRestrictionsSaga,
    createEventSaga,
    handleOpenEditEventModal,
    ...attachmentsSagaFactory(EVENT_MODALS_ATTACHMENTS_PARENT, eventModalsAttachmentsParentAction),
]
