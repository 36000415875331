import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { SyncableMapState } from 'aos-map-services/src/core/fullScreenMap/state'
import { loadBimLayersDataAction } from 'aos-services/src/core/bimLayersData/actions'
import {
    loadUserLayerVisibilityAction,
    setUserLayersVisibilityAction,
} from 'aos-services/src/core/userLayersVisibility/actions'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { LayersVisibility } from 'aos-services/src/services/map/types/LayersVisibility'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import { MapRestriction } from 'aos-services/src/services/map/types/MapRestriction'
import { MapTask } from 'aos-services/src/services/map/types/MapTask'
import { SelectedMapElement } from 'aos-services/src/services/map/types/SelectedMapElement'
import { NavigationMapElement } from 'aos-services/src/services/mapui/types/NavigationMapElement'

export const MAP_POSITION_CHANGED = 'MAP/MAP_POSITION_CHANGED'
export type MapPositionChangedAction = PayloadAction<
    typeof MAP_POSITION_CHANGED,
    [MapVariant, MapPosition]
>
export const mapPositionChangedAction = payloadActionCreator<
    MapPositionChangedAction,
    [MapVariant, MapPosition]
>(MAP_POSITION_CHANGED)

export const LOAD_MAP_POINTS_OF_INTEREST = 'MAP/LOAD_MAP_POINTS_OF_INTEREST'
export type LoadMapPointsOfInterestAction = PayloadAction<typeof LOAD_MAP_POINTS_OF_INTEREST, BBox>
export const loadMapPointsOfInterestAction = payloadActionCreator<
    LoadMapPointsOfInterestAction,
    BBox
>(LOAD_MAP_POINTS_OF_INTEREST)

export const LOAD_MAP_EVENTS_SUCCESS = 'MAP/LOAD_MAP_EVENTS_SUCCESS'
export type LoadMapEventsSuccessAction = PayloadAction<
    typeof LOAD_MAP_EVENTS_SUCCESS,
    [AosMapEvent[], BBox]
>
export const loadMapEventsSuccessAction = payloadActionCreator<
    LoadMapEventsSuccessAction,
    [AosMapEvent[], BBox]
>(LOAD_MAP_EVENTS_SUCCESS)

export const LOAD_MAP_TASKS_SUCCESS = 'MAP/LOAD_MAP_TASKS_SUCCESS'
export type LoadMapTasksSuccessAction = PayloadAction<
    typeof LOAD_MAP_TASKS_SUCCESS,
    [MapTask[], BBox]
>
export const loadMapTasksSuccessAction = payloadActionCreator<
    LoadMapTasksSuccessAction,
    [MapTask[], BBox]
>(LOAD_MAP_TASKS_SUCCESS)

export const LOAD_MAP_RESTRICTIONS_SUCCESS = 'MAP/LOAD_MAP_RESTRICTIONS_SUCCESS'
export type LoadMapRestrictionsSuccessAction = PayloadAction<
    typeof LOAD_MAP_RESTRICTIONS_SUCCESS,
    MapRestriction[]
>
export const loadMapRestrictionsSuccessAction = payloadActionCreator<
    LoadMapRestrictionsSuccessAction,
    MapRestriction[]
>(LOAD_MAP_RESTRICTIONS_SUCCESS)

export const CUSTOMIZE_MAP = 'MAP/CUSTOMIZE_MAP'
export type CustomizeMapAction = PayloadAction<typeof CUSTOMIZE_MAP, AosAirport>
export const customizeMapAction = payloadActionCreator<CustomizeMapAction, AosAirport>(
    CUSTOMIZE_MAP,
)

export const OPEN_MAP = 'MAP/OPEN_MAP'
export type OpenMapAction = Action<typeof OPEN_MAP>
export const openMapAction = emptyActionCreator<OpenMapAction>(OPEN_MAP)

export const CLOSE_MAP = 'MAP/CLOSE_MAP'
export type CloseMapAction = Action<typeof CLOSE_MAP>
export const closeMapAction = emptyActionCreator<CloseMapAction>(CLOSE_MAP)

export const RELOAD_MAP = 'MAP/RELOAD_MAP'
export type ReloadMapAction = Action<typeof RELOAD_MAP>
export const reloadMapAction = emptyActionCreator<ReloadMapAction>(RELOAD_MAP)

export const SET_LAYERS_VISIBILITY = 'MAP/SET_LAYERS_VISIBILITY'
export type SetLayersVisibilityAction = PayloadAction<
    typeof SET_LAYERS_VISIBILITY,
    LayersVisibility
>
export const setLayersVisibilityAction = payloadActionCreator<
    SetLayersVisibilityAction,
    LayersVisibility
>(SET_LAYERS_VISIBILITY)

export const SET_BASE_MAP_STATE = 'MAP/SET_BASE_MAP_STATE'
export type SetBaseMapStateAction = PayloadAction<typeof SET_BASE_MAP_STATE, SyncableMapState>
export const setBaseMapStateAction = payloadActionCreator<SetBaseMapStateAction, SyncableMapState>(
    SET_BASE_MAP_STATE,
)

export const SWITCH_MAP = 'MAP/SWITCH_MAP'
export type SwitchMapAction = PayloadAction<typeof SWITCH_MAP, MapVariant>
export const switchMapAction = payloadActionCreator<SwitchMapAction, MapVariant>(SWITCH_MAP)

export const SELECT_FEATURE = 'MAP/SELECT_FEATURE'
export type SelectFeatureAction = PayloadAction<typeof SELECT_FEATURE, SelectedMapElement>
export const selectFeatureAction = payloadActionCreator<SelectFeatureAction, SelectedMapElement>(
    SELECT_FEATURE,
)

export const DESELECT_ALL_FEATURES = 'MAP/DESELECT_ALL_FEATURES'
export type DeselectAllFeaturesAction = Action<typeof DESELECT_ALL_FEATURES>
export const deselectAllFeaturesAction = emptyActionCreator<DeselectAllFeaturesAction>(
    DESELECT_ALL_FEATURES,
)

export const NAVIGATE_TO_MAP = 'MAP/NAVIGATE_TO_MAP'
export type NavigateToMapAction = PayloadAction<typeof NAVIGATE_TO_MAP, NavigationMapElement>
export const navigateToMapAction = payloadActionCreator<NavigateToMapAction, NavigationMapElement>(
    NAVIGATE_TO_MAP,
)

export const PICK_LOCATION = 'MAP/PICK_LOCATION'
export type PickLocationAction = PayloadAction<typeof PICK_LOCATION, AosLocation>
export const pickLocationAction = payloadActionCreator<PickLocationAction, AosLocation>(
    PICK_LOCATION,
)

export const RESET_PICKED_LOCATION = 'MAP/RESET_PICKED_LOCATION'
export type ResetPickedLocationAction = Action<typeof RESET_PICKED_LOCATION>
export const resetPickedLocationAction = emptyActionCreator<ResetPickedLocationAction>(
    RESET_PICKED_LOCATION,
)

export const TOGGLE_LAYERS_SIDEBAR = 'MAP/TOGGLE_LAYERS_SIDEBAR'
export type ToggleLayersSidebarAction = PayloadAction<typeof TOGGLE_LAYERS_SIDEBAR, boolean>
export const toggleLayersSidebarAction = payloadActionCreator<ToggleLayersSidebarAction, boolean>(
    TOGGLE_LAYERS_SIDEBAR,
)

export const SET_BIM_LAYERS_VISIBILITY = 'MAP/SET_BIM_LAYERS_VISIBILITY'
export type SetBimLayersVisibility = PayloadAction<typeof SET_BIM_LAYERS_VISIBILITY, string[]>
export const setBimLayersVisibility = payloadActionCreator<SetBimLayersVisibility, string[]>(
    SET_BIM_LAYERS_VISIBILITY,
)

export const layerSyncRelatedActions = [
    SWITCH_MAP,
    SET_LAYERS_VISIBILITY,
    OPEN_MAP,
    SET_BASE_MAP_STATE,
]

export interface MapActionCreators {
    mapPositionChangedAction: typeof mapPositionChangedAction
    loadMapPointsOfInterestAction: typeof loadMapPointsOfInterestAction
    loadMapEventsSuccessAction: typeof loadMapEventsSuccessAction
    loadMapTasksSuccessAction: typeof loadMapTasksSuccessAction
    loadMapRestrictionsSuccessAction: typeof loadMapRestrictionsSuccessAction
    customizeMapAction: typeof customizeMapAction
    openMapAction: typeof openMapAction
    switchMapAction: typeof switchMapAction
    reloadMapAction: typeof reloadMapAction
    setBaseMapStateAction: typeof setBaseMapStateAction
    setLayersVisibilityAction: typeof setLayersVisibilityAction
    pickLocationAction: typeof pickLocationAction
    resetPickedLocationAction: typeof resetPickedLocationAction
    toggleLayersSidebarAction: typeof toggleLayersSidebarAction
    setBimLayersVisibility: typeof setBimLayersVisibility
    loadBimLayersDataAction: typeof loadBimLayersDataAction
    loadUserLayerVisibilityAction: typeof loadUserLayerVisibilityAction
    setUserLayersVisibilityAction: typeof setUserLayersVisibilityAction
}

export const mapActionCreators: MapActionCreators = {
    mapPositionChangedAction,
    loadMapPointsOfInterestAction,
    loadMapEventsSuccessAction,
    loadMapTasksSuccessAction,
    loadMapRestrictionsSuccessAction,
    customizeMapAction,
    openMapAction,
    switchMapAction,
    reloadMapAction,
    setBaseMapStateAction,
    setLayersVisibilityAction,
    pickLocationAction,
    resetPickedLocationAction,
    toggleLayersSidebarAction,
    setBimLayersVisibility,
    loadBimLayersDataAction,
    loadUserLayerVisibilityAction,
    setUserLayersVisibilityAction,
}

export type MapAction =
    | MapPositionChangedAction
    | LoadMapPointsOfInterestAction
    | LoadMapEventsSuccessAction
    | LoadMapTasksSuccessAction
    | LoadMapRestrictionsSuccessAction
    | CustomizeMapAction
    | OpenMapAction
    | CloseMapAction
    | SwitchMapAction
    | SetBaseMapStateAction
    | SetLayersVisibilityAction
    | SelectFeatureAction
    | DeselectAllFeaturesAction
    | PickLocationAction
    | ResetPickedLocationAction
    | NavigateToMapAction
    | ReloadMapAction
    | ToggleLayersSidebarAction
    | SetBimLayersVisibility
