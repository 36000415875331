import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

export const seriesBarHeight = 12
export const seriesBarPadding = 22

class GradientSeriesComponent<T> extends BaseChartComponent<ChartGradientSeriesProps<T>> {
    public render() {
        const { name, size, margins, data, seriesConfig } = this.props

        const stepWidth = 100 / (data.length - 1)
        const dataSteps = data.map((d: T, i: number) => [i * stepWidth, d]) as [number, T][]

        const gradientStops = dataSteps.map(this.renderGradientStop)
        const linearGradientId = `series-gradient-${name}`

        return (
            <g className={cxp(this.props, 'series series--gradient')}>
                <defs>
                    <linearGradient id={linearGradientId}>{gradientStops}</linearGradient>
                </defs>
                <rect
                    x={margins.left}
                    y={margins.top + seriesBarPadding}
                    rx='2'
                    ry='2'
                    height={seriesBarHeight}
                    width={size.width}
                    fill={`url(#${linearGradientId})`}
                />
                {seriesConfig.tooltip && this.renderTooltips(dataSteps)}
            </g>
        )
    }

    private renderGradientStop = (datum: [number, T]) => {
        const { seriesConfig } = this.props
        return (
            <stop
                key={datum[0]}
                stopColor={this.getAttributeValue(seriesConfig.color, datum[1])}
                offset={`${datum[0]}%`}
            />
        )
    }

    private renderTooltips(dataSteps: [number, T][]) {
        const { margins, seriesConfig, size } = this.props
        const { top, left } = margins

        return (
            <g>
                {dataSteps.map((s, i) => {
                    const count = dataSteps.length - 1
                    const stepWidth = size.width / count
                    const x = size.width * (i / count)
                    const body = seriesConfig.tooltip!(s[1])

                    if (!body) {
                        return null
                    }

                    return (
                        <Tooltip
                            key={i}
                            body={seriesConfig.tooltip!(s[1])}
                            offset={[0, -10]}
                            placement='top'
                            withArrow
                        >
                            <rect
                                x={x + left - stepWidth / 2}
                                y={top + seriesBarPadding}
                                height={seriesBarHeight}
                                width={stepWidth}
                                fill='transparent'
                            />
                        </Tooltip>
                    )
                })}
            </g>
        )
    }

    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartGradientSeriesProps<T> extends ClassNameProps {
    name: string
    data: T[]
    seriesConfig: ChartSeriesConfig<T>
}

export const GradientSeries = withChartContext(GradientSeriesComponent)
