import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum BimLayerCategory {
    Terminal = 'terminal',
    LandsideAndTraffic = 'landside-and-traffic',
    ApronAndManeuvering = 'apron-and-maneuvering',
    AirsideArea = 'airside-area',
    Basemaps = 'basemaps',
    ThematicMaps = 'thematic-maps',
    Buildings = 'buildings',
    RescueServices = 'rescue-services',
    TaskAndEvents = 'tasks-and-events',
    Trains = 'trains',
    Atc = 'atc',
}

export const allBimLayerCategories = EnumValues.getValues<BimLayerCategory>(BimLayerCategory)
export const translateBimLayerCategory = translateEnum(BimLayerCategory, 'bim.layer.category')
