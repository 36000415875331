import React from 'react'

import { ChartConfig, ChartSize, getRealChartSize } from './types/Chart'

export interface ChartProviderProps {
    outerSize: ChartSize
    chartConfig: ChartConfig
}

export interface ChartContextProps {
    size: ChartSize
    outerSize: ChartSize
    margins: { top: number; bottom: number; left: number; right: number }
}

const defaultChartContext: ChartContextProps = {
    size: { width: 0, height: 0 },
    outerSize: { width: 0, height: 0 },
    margins: { top: 0, bottom: 0, left: 0, right: 0 },
}

const { Consumer, Provider } = React.createContext<ChartContextProps>(defaultChartContext)
export const ChartProvider = Provider

// TODO remove any https://github.com/Microsoft/TypeScript/issues/28938
export const withChartContext =
    <T extends ChartContextProps, R = Subtract<T, ChartContextProps>>(
        Component: React.ComponentType<T>,
    ): React.ComponentType<R> =>
    (props: R) =>
        (
            <Consumer>
                {({ outerSize, size, margins }: ChartContextProps) => (
                    <Component
                        {...(props as any)}
                        size={size}
                        outerSize={outerSize}
                        margins={margins}
                    />
                )}
            </Consumer>
        )
export const chartContextProvider =
    <T extends ChartProviderProps>(Component: React.ComponentType<T>): React.ComponentType<T> =>
    (props: T) => {
        const { chartConfig, outerSize } = props
        const size = getRealChartSize(outerSize, chartConfig.margins)
        return (
            <Provider
                value={{
                    outerSize,
                    size,
                    margins: chartConfig.margins,
                }}
            >
                <Component {...props} />
            </Provider>
        )
    }
