import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { AttachmentContainer } from 'aos-ui/src/components/attachment/items/AttachmentContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { SectionHeader } from 'aos-ui/src/components/text/SectionHeader'
import React, { PureComponent } from 'react'

import { EventInfo } from './EventInfo'

export class FeedInContent extends PureComponent<FeedInContentProps> {
    public render() {
        const { feedIn } = this.props
        return (
            <Box className='event-preview' coverAll row>
                {this.renderEventInfo(feedIn)}
                {this.renderAttachments(feedIn)}
            </Box>
        )
    }

    private renderEventInfo(event: AosFeedIn) {
        const { setDialogModeAction } = this.props
        return (
            <EventInfo
                event={event}
                setDialogModeAction={setDialogModeAction}
                withVisibilityField
            />
        )
    }

    private renderAttachments = (feedIn: AosFeedIn) =>
        feedIn.allAttachments.length > 0 && (
            <Box fullHeight padding={30} flex={1}>
                <SectionHeader title={translate('event-preview.attachment')} />
                <AttachmentContainer
                    attachmentsState={{ attachments: feedIn.attachments, isUploading: false }}
                    editable={false}
                    gridSize={3}
                />
            </Box>
        )
}

interface FeedInContentProps {
    feedIn: AosFeedIn
    setDialogModeAction(view: DialogView): void
}
