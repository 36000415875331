import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'

import { CdmMetricType } from '../../airportStatus/cdm/types/CdmMetricType'
import { WidgetStatusRange } from '../../common/types/StatusRange'
import { StatusRangeType } from '../../common/types/StatusRangeType'

export interface Ranges {
    cdm: Record<CdmMetricType, WidgetStatusRange[]>
    weather: WeatherRange
    tobt: Array<GaugeRange>
}

export interface WeatherRange {
    windGust: WeatherStatusRange
    tsProbability: WeatherStatusRange
    lvpProbability: WeatherStatusRange
    temperature: WeatherStatusRange
    snowAccumulation: WeatherAccumulatingRange[]
    rainAccumulation: WeatherAccumulatingRange[]
}

export interface WeatherAccumulatingRange {
    minValue: number
    hourRange: number
    status: StatusRangeType
}

export interface WeatherStatusRange {
    hourRange: number
    statusRange: WidgetStatusRange[]
}
export const emptyWeatherStatusRange: WeatherStatusRange = {
    hourRange: 24,
    statusRange: [],
}

export const emptyWeatherRange: WeatherRange = {
    windGust: emptyWeatherStatusRange,
    tsProbability: emptyWeatherStatusRange,
    lvpProbability: emptyWeatherStatusRange,
    temperature: emptyWeatherStatusRange,
    snowAccumulation: [],
    rainAccumulation: [],
}

export const emptyRanges: Ranges = {
    cdm: getInitialValuesForEnumRecord(CdmMetricType, []),
    weather: emptyWeatherRange,
    tobt: [],
}
