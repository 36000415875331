import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { eventVisibilityTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { AosEventVisibility } from 'aos-services/src/services/events/types/AosEventVisibility'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

/* eslint-disable react/jsx-no-literals */
export const EventVisibility: FC<EventVisibilityProps> = ({
    eventVisibility,
    visibilityGroup,
    inviteAction,
}) => {
    const hasSelectAction =
        !visibilityGroup && !!inviteAction && eventVisibility === AosEventVisibility.Restricted
    return (
        <Box row fullWidth>
            <Box flex={1}>
                {eventVisibilityTitle(eventVisibility || AosEventVisibility.Public)}
                {!!visibilityGroup && (
                    <span className='padding-left--smallest'>
                        ({aosUserGroupName(visibilityGroup)})
                    </span>
                )}
            </Box>
            {hasSelectAction && (
                <Text className='pointer' color={Color.Primary} size={12} onClick={inviteAction}>
                    {translate('event-details.select-group')}
                </Text>
            )}
        </Box>
    )
}

interface EventVisibilityProps {
    eventVisibility: AosEventVisibility
    visibilityGroup?: AosUserGroup
    inviteAction?(): void
}
