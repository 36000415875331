import { EnumValues } from 'enum-values'

export enum AttachmentType {
    jpg = 'image/jpg',
    jpeg = 'image/jpeg',
    png = 'image/png',
    pdf = 'application/pdf',
    mp4 = 'video/mp4',
}

export const acceptString = EnumValues.getValues<AttachmentType>(AttachmentType)
    .map(m => `.${m.split('/').pop()}`)
    .join(',')

export const isMimeType = (i: string) => i.includes('/')

export const toMimeType = (typeOrExtension: string): AttachmentType | undefined => {
    if (isMimeType(typeOrExtension)) {
        return typeOrExtension as AttachmentType
    }
    return (AttachmentType as any)[typeOrExtension as any] as AttachmentType
}

export const attachmentMetadata = (typeOrExtension: string): any | undefined => {
    const type = toMimeType(typeOrExtension)
    if (type) {
        return {
            contentType: type,
        }
    }
    return undefined
}
