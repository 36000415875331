import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum DashboardItemType {
    Flights = 'FLIGHTS',
    Regularity = 'REGULARITY',
    Weather = 'WEATHER',
    WeatherForecast = 'WEATHER_FORECAST',
    Events = 'EVENTS',
    Runways = 'RUNWAYS',
    NewsFeed = 'NEWS_FEED',
    Pax = 'PAX',
    PaxForecast = 'PAX_FORECAST',
    Delays = 'DELAYS',
    Punctuality = 'PUNCTUALITY',
    Cdm = 'CDM',
    BaggageDelivery = 'BAGGAGE_DELIVERY',
    OperationalForecast = 'OPERATIONAL_FORECAST',
    QueueingTimeBorder = 'QUEUEING_TIME_BORDER',
    QueueingTimeSecurity = 'QUEUEING_TIME_SECURITY',
    FireFighters = 'Firefighters',
    ShiftNotes = 'ShiftNotes',
    Restrictions = 'RESTRICTIONS',
    RecentTasks = 'RECENT_TASKS',
    UpcomingTasks = 'UPCOMING_TASKS',
    FOD = 'FOD',
    NOTAM = 'NOTAM',
}

export const translateDashboardItemType = (prefix: string) =>
    translateEnum<DashboardItemType>(DashboardItemType, prefix)
