import { AosAirline } from './types/AosAirline'

export const airlines: AosAirline[] = [
    { code: 'SU', name: 'Aeroflot' },
    { code: 'BT', name: 'Baltic Air' },
    { code: 'BA', name: 'British Airways' },
    { code: 'OK', name: 'Czech Airlines' },
    { code: 'DK', name: 'Eastland Air' },
    { code: 'FZ', name: 'FLYDUBAI' },
    { code: 'FX', name: 'Federal Express' },
    { code: 'AY', name: 'Finnair' },
    { code: 'FI', name: 'Icelandair' },
    { code: 'JL', name: 'Japan International' },
    { code: 'LS', name: 'Jet2.com' },
    { code: 'KL', name: 'KLM' },
    { code: 'EI', name: 'Lingus Limited' },
    { code: 'LH', name: 'Lufthansa' },
    { code: 'D8', name: 'Norwegian Air International' },
    { code: 'TO', name: 'President Airlines' },
    { code: 'QR', name: 'Qatar' },
    { code: 'QY', name: 'Red Jet Canada' },
    { code: 'SK', name: 'SAS' },
    { code: '3U', name: 'Sichuan Airlines' },
    { code: '3V', name: 'TNT Airways' },
    { code: 'TP', name: 'Tap Portugal Air' },
    { code: 'ZT', name: 'Titan Airways' },
    { code: 'TK', name: 'Turkish Airlines' },
    { code: 'PS', name: 'UIA' },
    { code: 'W6', name: 'Wizz Air' },
]
