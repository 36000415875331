import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import { ChartConfig, getRanges } from 'aos-ui-common/src/components/chart/types/Chart'
import { HorizontalMargins } from 'aos-ui-common/src/components/chart/types/Margins'
import { extent } from 'd3-array'
import { scaleTime } from 'd3-scale'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'
import styled from 'styled-components'

import { LegacyChart } from './LegacyChart'
import { VerticalLineSeries } from './series/VerticalLineSeries'

export interface CurrentTimeChartProps {
    margins: HorizontalMargins
    domain: DateTime[]
}

class CurrentTimeChartClass extends PureComponent<CurrentTimeChartProps & SizeMeProps> {
    private verticalLineSeriesConfig = { color: 'rgba(255, 255, 255, 0.22)' }

    public render() {
        const { size, domain } = this.props

        const currentTime = dateTime()
        const domainExtent = extent(domain)

        if (domainExtent[0] === undefined) {
            return null
        }

        return (
            <Container coverAll>
                <LegacyChart chartConfig={this.chartConfig} size={size}>
                    <VerticalLineSeries
                        datum={currentTime}
                        seriesConfig={this.verticalLineSeriesConfig}
                        scales={{ xScale: this.getXScale(domainExtent) }}
                        size={this.size}
                    />
                </LegacyChart>
            </Container>
        )
    }

    private getXScale(domain: [DateTime, DateTime]) {
        const { xRange } = getRanges(this.chartConfig.margins, this.size)

        return scaleTime().domain(domain).rangeRound(xRange)
    }

    private get size() {
        return { width: this.props.size.width || 0, height: this.props.size.height || 0 }
    }

    private get chartConfig(): ChartConfig {
        return {
            margins: {
                ...this.props.margins,
                top: 0,
                bottom: 0,
            },
        }
    }
}

const Container = styled(Box)`
    pointer-events: none;
`

export const CurrentTimeChart = sizeMe({ monitorHeight: true })(CurrentTimeChartClass)
