import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import {
    RingRailStation,
    RingRailTrain,
    RingRailTrainRoute,
    RingRailTrainStatus,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { trainSpeed, trainStatus } from 'aos-services/src/services/ringRail/ringRailTrainFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Dictionary } from 'lodash'
import React, { PureComponent } from 'react'

import { CommuterLineCell } from './cells/CommuterLineCell'
import { ScheduleStatusCell } from './cells/ScheduleStatusCell'

export class TrainDetails extends PureComponent<TrainDetailsProps> {
    public render() {
        const { train } = this.props

        const items = [
            ['train-details.status', this.renderTrainStatus(train)],
            ['train-details.speed', trainSpeed(train.speed)],
            ['train-details.route', this.renderRoute(train.route)],
        ] as PropsListEntry[]

        return (
            <Box className={cxp(this.props)}>
                <Box row paddingBottom={16}>
                    <Box marginRight={8}>
                        <CommuterLineCell train={train} />
                    </Box>
                    <Text className='text--selectable' flex={1} color={Color.White} size={14}>
                        {train.trainType} {train.trainNumber}
                    </Text>
                    <Box>
                        <ScheduleStatusCell train={train} />
                    </Box>
                </Box>
                <PropsList
                    items={items}
                    size={LabeledTextElementSize.Small}
                    variant={ThemeVariant.Black}
                />
            </Box>
        )
    }

    private renderTrainStatus = (train: RingRailTrain) => {
        const { stations } = this.props
        const status = trainStatus(train.trainStatus)

        switch (train.trainStatus) {
            case RingRailTrainStatus.Running:
                return (
                    <Box>
                        <Box>{status}</Box>
                        <Box>
                            {train.currentRoute &&
                                train.currentRoute.from &&
                                `(${this.renderRoute(train.currentRoute)})`}
                        </Box>
                    </Box>
                )
            case RingRailTrainStatus.AtTheStation:
                return (
                    <Box>
                        {status}{' '}
                        {train.currentRoute.to
                            ? `(${stations[train.currentRoute.to].stationName})`
                            : ''}
                    </Box>
                )
            default:
                return <Box>{status}</Box>
        }
    }

    private renderRoute = (route: RingRailTrainRoute) => {
        const { stations } = this.props
        const { from, to } = route

        if (!from) {
            return null
        }

        return to
            ? `${stations[from].stationName} → ${stations[to].stationName}`
            : `${stations[from].stationName}`
    }
}

interface TrainDetailsProps extends ClassNameProps {
    train: RingRailTrain
    stations: Dictionary<RingRailStation>
}
