import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

import {
    ReportAirport,
    reportAirportValues,
    translateReportAirport,
} from '../../../../core/tasks/reports/types/ReportAirport'

interface ReportAirportDropdownProps {
    value: ReportAirport
    onChange(v: ReportAirport): void
}

export const ReportAirportDropdown: FC<ReportAirportDropdownProps> = ({ value, onChange }) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            width={160}
            items={reportAirportValues}
            valueRenderer={translateReportAirport}
            onChange={onChange}
        />
    )
}
