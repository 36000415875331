import { uniqBy } from 'lodash'

import { PayloadAction } from '../ActionCreator'

export enum SyncActionType {
    Add,
    Remove,
    Change,
    InitialSet,
}

export interface FirebaseEntity {
    id: string
}

export type FirebaseActionPayload<T extends FirebaseEntity> = [SyncActionType, T[]]

export function firebaseCollectionReducer<T extends FirebaseEntity>(
    items: T[],
    payload: FirebaseActionPayload<T>,
): T[] {
    function removeItems(collection: T[], item: T[]) {
        const ids = item.map(t => t.id)
        return collection.filter(i => ids.indexOf(i.id) < 0)
    }

    function addItems(collection: T[], item: T[]) {
        return [...removeItems(collection, item), ...uniqBy(item.reverse(), 'id')]
    }

    switch (payload[0]) {
        case SyncActionType.Add:
            return addItems(items, payload[1])

        case SyncActionType.Remove:
            return removeItems(items, payload[1])

        case SyncActionType.Change:
            return addItems(items, payload[1])

        case SyncActionType.InitialSet:
            return payload[1]
        default:
            return items
    }
}

export interface CollectionSyncActionCreators<T, U extends FirebaseEntity> {
    added: f.Func1<T[], PayloadAction<any, FirebaseActionPayload<U>>>
    removed: f.Func1<T[], PayloadAction<any, FirebaseActionPayload<U>>>
    changed: f.Func1<T[], PayloadAction<any, FirebaseActionPayload<U>>>
    initialSet: f.Func1<T[], PayloadAction<any, FirebaseActionPayload<U>>>
}

export function createCollectionSyncActionCreator<T, U extends FirebaseEntity>(
    actionCreator: f.Func1<FirebaseActionPayload<U>, PayloadAction<any, FirebaseActionPayload<U>>>,
    mapper: f.Func1<T, U>,
): CollectionSyncActionCreators<T, U> {
    const mapItems = (item: T[]) => item.map(mapper).filter(t => t !== undefined)
    return {
        added: (item: T[]) => actionCreator([SyncActionType.Add, mapItems(item)]),
        removed: (item: T[]) => actionCreator([SyncActionType.Remove, mapItems(item)]),
        changed: (item: T[]) => actionCreator([SyncActionType.Change, mapItems(item)]),
        initialSet: (item: T[]) => actionCreator([SyncActionType.InitialSet, mapItems(item)]),
    }
}
