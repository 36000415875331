import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { LegendItem, Orientation } from 'aos-ui/src/components/ui/LegendItem'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

export class RunwaysLegend extends PureComponent {
    public render() {
        return (
            <Box>
                {this.renderItem(Color.Primary, translate('dashboard.runways.legend.departures'))}
                {this.renderItem(
                    Color.Primary,
                    translate('dashboard.runways.legend.conditional'),
                    LegendDotVariant.Stroke,
                )}
                {this.renderItem(Color.Orange, translate('dashboard.runways.legend.arrivals'))}
            </Box>
        )
    }

    private renderItem = (color: Color, label: string, variant?: LegendDotVariant) => (
        <LegendItem
            dotConfig={{
                size: BlockSize.Small,
                mainColor: color,
                variant,
            }}
            orientation={Orientation.Horizontal}
            marginBottom={8}
        >
            {label}
        </LegendItem>
    )
}
