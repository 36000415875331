import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import { AosContact } from 'aos-services/src/services/users/types/AosUser'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

import {
    CHANGE_FORM_VALUE,
    ChangeFormValueAction,
    CommonUserAction,
    CommonUserActionCreators,
    commonUserActionCreators,
} from '../actions'

export const changeFormValueAction = payloadActionCreator<
    ChangeFormValueAction<ContactFormData>,
    ContactFormData
>(CHANGE_FORM_VALUE)

export interface ContactUserData {
    formData: ContactFormData
    notificationSettings?: AosUserNotificationsPreferences
}

export const SHOW_USER_MODAL_WITH_CONTACT = 'MANAGE_USER/SHOW_USER_MODAL_WITH_CONTACT'
export type ShowUserModalWithContactAction = PayloadAction<
    typeof SHOW_USER_MODAL_WITH_CONTACT,
    AosContact
>
export const showUserModalWithContactAction = payloadActionCreator<
    ShowUserModalWithContactAction,
    AosContact
>(SHOW_USER_MODAL_WITH_CONTACT)

export const CREATE_CONTACT = 'MANAGE_USER/CREATE_CONTACT'
export type CreateContactAction = PayloadAction<typeof CREATE_CONTACT, ContactUserData>
export const createContactAction = payloadActionCreator<CreateContactAction, ContactUserData>(
    CREATE_CONTACT,
)

export const UPDATE_CONTACT = 'MANAGE_USER/UPDATE_CONTACT'
export type UpdateContactAction = PayloadAction<typeof UPDATE_CONTACT, ContactUserData>
export const updateContactAction = payloadActionCreator<UpdateContactAction, ContactUserData>(
    UPDATE_CONTACT,
)

export const SWITCH_TO_AD_USER = 'MANAGE_USER/SWITCH_TO_AD_USER'
export type SwitchToAdUserAction = Action<typeof SWITCH_TO_AD_USER>
export const switchToAdUserAction = emptyActionCreator<SwitchToAdUserAction>(SWITCH_TO_AD_USER)

export type ContactAction =
    | CommonUserAction
    | ShowUserModalWithContactAction
    | ChangeFormValueAction<ContactFormData>
    | CreateContactAction
    | UpdateContactAction
    | SwitchToAdUserAction

export interface ContactActionCreators extends CommonUserActionCreators {
    showUserModalWithContactAction: typeof showUserModalWithContactAction
    changeFormValueAction: typeof changeFormValueAction
    createContactAction: typeof createContactAction
    updateContactAction: typeof updateContactAction
    switchToAdUserAction: typeof switchToAdUserAction
}

export const contactActionCreators: ContactActionCreators = {
    ...commonUserActionCreators,
    showUserModalWithContactAction,
    changeFormValueAction,
    createContactAction,
    updateContactAction,
    switchToAdUserAction,
}
