import { AnyEnum, enumUnion, EnumValue, isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import {
    translateEnum,
    translateEnumUnion,
} from 'aos-helpers/src/helpers/translations/Translations'

export enum FilterOptionAll {
    All = 'All',
}

export type StringFilterWithAllOption = FilterOptionAll | string

export const filterOptionsWithAll = <T extends EnumValue>(
    enumEntity: AnyEnum,
): (FilterOptionAll | T)[] => enumUnion(FilterOptionAll, enumEntity)

export const translateFilterWithAllOption = (enumEntity: AnyEnum, prefix: string) =>
    translateEnumUnion(prefix, FilterOptionAll, enumEntity)

export const translateStringFilterWithAllOption = (prefix: string) => (
    value: StringFilterWithAllOption,
) =>
    isValidEnumEntry(FilterOptionAll)<FilterOptionAll>(value)
        ? translateEnum(FilterOptionAll, prefix)(value)
        : value
