import {
    runwaySyncAction,
    runwaySyncStopAction,
} from 'aos-services/src/core/statusDashboardData/runway/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../../components/sync/SyncWrapper'

interface RunwaySyncWrapperProps {
    id: SyncDataKey
}
export const RunwaySyncWrapper: FC<PropsWithChildren<RunwaySyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={runwaySyncAction(id)} onLeave={runwaySyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
