import {
    timelineScrollAction,
    timelineTimeChangeAction,
} from 'aos-services/src/core/timeline/actions'
import { Notam, Snowtam } from 'aos-services/src/services/flightInformation/types/Notam'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Timeline } from 'aos-ui/src/components/timeline/Timeline'
import { useDebounce } from 'aos-ui-common/src/components/hooks/useDebounce'
import { isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import { positionValues } from 'react-custom-scrollbars'
import { useDispatch, useSelector } from 'react-redux'

import {
    notamTimelineParentAction,
    notamToggleGroupAction,
    selectNotamItemAction,
} from '../../../core/notamTimeline/actions'
import {
    notamTimelineSelector,
    snowtamPerAirportSelector,
} from '../../../core/notamTimeline/selectors'
import { NotamTimelineContent } from './NotamTimelineContent'
import { SidebarStack } from './sidebar/SidebarStack'
import { SnowtamBottomBar } from './sidebar/SnowtamBottomBar'

export const NotamTimeline = () => {
    const dispatch = useDispatch()
    const { timelineState, items, groups } = useSelector(notamTimelineSelector)
    const snowtam = useSelector(snowtamPerAirportSelector)
    const currentTime = moment()
    const realTime = currentTime.clone().subtract(currentTime.utcOffset(), 'minute')
    const saveScrollPosition = useDebounce((newPosition: positionValues) => {
        if (!isEqual(timelineState.savedPosition, newPosition)) {
            dispatch(notamTimelineParentAction(timelineScrollAction(newPosition)))
        }
    }, 1000)
    const toggleGroup = (group: string) => dispatch(notamToggleGroupAction(group))
    const selectItem = (item: Notam | Snowtam) => dispatch(selectNotamItemAction(item))
    const onTimeChange = (ts: number, te: number) =>
        dispatch(notamTimelineParentAction(timelineTimeChangeAction([ts, te])))

    const onlyOneGroup = groups.length === 1
    return (
        <Box fullHeight row alignItems='stretch' flex={1}>
            <Box flex={1} column>
                <DarkScrollbar
                    onScroll={saveScrollPosition}
                    savedPosition={timelineState.savedPosition}
                >
                    <Timeline
                        withTooltip={false}
                        utc
                        items={items}
                        groups={groups}
                        timeline={timelineState}
                        onTimeChange={onTimeChange}
                        openItem={item => selectItem(item.payload)}
                        currentTime={realTime}
                        ContentRenderer={({ item, inTooltip }) => (
                            <NotamTimelineContent inTooltip={inTooltip} item={item} />
                        )}
                        toggleGroup={x => toggleGroup(x.id as string)}
                    />
                </DarkScrollbar>
                {snowtam && onlyOneGroup && <SnowtamBottomBar item={snowtam} />}
            </Box>
            <SidebarStack />
        </Box>
    )
}
