import React, { FCWithChildren, PropsWithChildren } from 'react'
import { useMeasure } from 'react-use'
import styled from 'styled-components'

import { Box, BoxProps } from '../base/Box'
import { SeleniumProps } from '../base/SeleniumProps'

export const ResponsiveWrapper: FCWithChildren<PropsWithChildren<ResponsiveWrapperProps>> = ({
    children,
    alternativeContent,
    ...boxProps
}) => {
    const [expander, expanderSize] = useMeasure<HTMLDivElement>()
    const [wrapper, wrapperSize] = useMeasure<HTMLDivElement>()
    const isClipped = () => expanderSize.width > wrapperSize.width

    return (
        <WrapperBox relative row ref={wrapper}>
            <ExpanderBox ref={expander} flex={1} row>
                {children}
            </ExpanderBox>
            <Box flex='auto'>
                {isClipped() ? (
                    <Box {...boxProps}>{alternativeContent()}</Box>
                ) : (
                    <Box {...boxProps}>{children}</Box>
                )}
            </Box>
        </WrapperBox>
    )
}

const WrapperBox = styled(Box)`
    min-width: 0;
    overflow: hidden;
`

const ExpanderBox = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
`

interface ResponsiveWrapperProps extends SeleniumProps, BoxProps {
    alternativeContent(): JSX.Element
}
