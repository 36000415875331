import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'

export interface EventMapUserLayerVisibilityState {
    list: string[]
    isLoading: boolean
    floor: number
}

export interface EventMapUserLayerVisibilityStateAware {
    eventMapUserLayerVisibility: EventMapUserLayerVisibilityState
}

export const initialEventMapUserLayersVisibilityState: EventMapUserLayerVisibilityState = {
    list: [
        'de-icing-areas',
        'light-masts',
        'stands',
        'wa-lvp-screens',
        'apron-map',
        'aerodrome-maneuvering',
        'gates',
        'bus-doors',
        'arrival-halls',
        'arrival-services',
        'baby-care',
        'baggage-claim',
        'bus-gates',
        'check-in-desks',
        'check-in-kiosks',
        'customs',
        'disabled-toilets',
        'lifts',
        'non-schengen-area',
        'security-check-passengers',
        'security-check-staff',
        'sis-screens',
        'terminal-building',
        'toilets',
        'tasks',
        'events',
    ],
    isLoading: false,
    floor: 0,
}

export interface EventMapUserLayerVisibilityPayload {
    list: string[]
    floor: number
}

export const initialWorldEventMapUserLayersVisibilityState: EventMapUserLayerVisibilityState = {
    list: [BimLayerName.Tasks, BimLayerName.Events],
    isLoading: false,
    floor: 0,
}
