import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { dateTime, getMonthDay } from 'aos-helpers/src/helpers/Time'

import { TaskRecurrenceType } from '../task/TaskRecurrenceType'
import { RecurrenceCustomOn, RecurrenceCustomOnValue } from './RecurrencePayloadEnums'

export interface TaskRecurrenceMonthlyPayload {
    type: TaskRecurrenceType.Monthly
    duration: number
    every: number
    on: number
    customOn: RecurrenceCustomOn | undefined
    customOnValue: RecurrenceCustomOnValue[]
}

export const defaultTaskRecurrenceMonthly: TaskRecurrenceMonthlyPayload = {
    type: TaskRecurrenceType.Monthly,
    duration: 60,
    every: 1,
    on: getMonthDay(dateTime(new Date())),
    customOn: undefined,
    customOnValue: [],
}

export const defaultOnTaskRecurrenceMonthly = {
    on: defaultTaskRecurrenceMonthly.on,
    customOn: undefined,
    customOnValue: defaultTaskRecurrenceMonthly.customOnValue,
}

export const defaultCustomOnTaskRecurrenceMonthly = {
    on: defaultTaskRecurrenceMonthly.on,
    customOn: RecurrenceCustomOn.FIRST,
    customOnValue: defaultTaskRecurrenceMonthly.customOnValue,
}

export interface TaskRecurrenceMonthlyValidation {
    duration: boolean
    every: boolean
    on: boolean
    customOnValue: boolean
}

export const validateTaskRecurrenceMonthly = (
    r: TaskRecurrenceMonthlyPayload,
): TaskRecurrenceMonthlyValidation => ({
    duration: isNotEmpty(r.duration) && r.duration > 0,
    every: isNotEmpty(r.every) && r.every > 0,
    on: r.on === null ? true : isNotEmpty(r.on) && r.on > 0 && r.on <= 31,
    customOnValue:
        r.customOn !== undefined && r.customOnValue !== undefined && r.customOnValue !== null
            ? r.customOnValue.length > 0
            : true,
})
