import { EnumValues } from 'enum-values'

import { AosEvent } from './AosEvent'
import { AosEventStatus } from './AosEventStatus'
import { AosFeedIn } from './AosFeedIn'
import { AosFeedInStatus } from './AosFeedInStatus'

export type AosEventOrFeedIn = AosEvent | AosFeedIn

export function isAosEvent(arg: any): arg is AosEvent {
    return EnumValues.getValues(AosEventStatus).indexOf(arg.status) >= 0
}

export function isAosFeedIn(arg: any): arg is AosFeedIn {
    return EnumValues.getValues(AosFeedInStatus).indexOf(arg.status) >= 0
}
