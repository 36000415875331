import { optionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import _ from 'lodash'
import { fromLonLat } from 'ol/proj'

import { BaseComponent } from '../base/BaseComponent'

export class PositionTracker extends BaseComponent<PositionTrackerProps> {
    private isMoving: boolean = false
    private prevPosition: MapPosition | undefined

    public componentDidMount() {
        this.updatePositionIfNeeded(this.props.position)
        this.context.map.on('movestart', () => {
            this.isMoving = true
        })
        this.context.map.on('moveend', () => {
            this.isMoving = false
            this.handleMapChanged()
        })
    }

    public componentWillReceiveProps(props: PositionTrackerProps) {
        if (!this.isMoving) {
            this.updatePositionIfNeeded(props.position)
        }
    }

    private updatePositionIfNeeded = (nextPosition: MapPosition) => {
        if (!_.isEqual(nextPosition, this.prevPosition)) {
            const mapView = this.context.map.getView()
            mapView.animate({
                center: fromLonLat([nextPosition.lon, nextPosition.lat]),
                duration: 500,
                zoom: nextPosition.zoom,
            })
        }
        this.prevPosition = nextPosition
    }

    private handleMapChanged = () => {
        const currentMapPosition = this.context.currentPosition()!
        if (!_.isEqual(this.props.position, currentMapPosition)) {
            optionalFunctionCall1Arg(this.props.onPositionChanged, currentMapPosition)()
            optionalFunctionCall1Arg(this.props.onBoundsChanged, this.context.currentBounds()!)()
        }
    }
}

interface PositionTrackerProps {
    position: MapPosition
    onPositionChanged?(v: MapPosition): void
    onBoundsChanged?(v: BBox): void
}
