import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'

export interface UpcomingTasksStateAware {
    upcomingTasks: UpcomingTasksState
}

export interface UpcomingTasksState {
    tasks: TaskSnapshot[]
    isLoading: boolean
    isError: boolean
}

export const initialUpcomingTasksState: UpcomingTasksState = {
    tasks: [],
    isLoading: false,
    isError: false,
}
