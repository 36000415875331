import { compareByAlphanumeric } from 'aos-helpers/src/helpers/Compare'
import { compareDateTimeAsc } from 'aos-helpers/src/helpers/Time'

import { Restriction } from './Restriction'
import { isAnyGateRestrictionType, RestrictionResourceType } from './RestrictionResourceType'

export enum RestrictionSorting {
    StartDate,
    EndDate,
    ResourceNumber,
}

type RestrictionSortComparator = (a: Restriction, b: Restriction) => number

const resourceTypeComparator = (a: Restriction, b: Restriction) =>
    a.resourceType === b.resourceType ||
    (isAnyGateRestrictionType(a.resourceType) && isAnyGateRestrictionType(b.resourceType))

export const byResourceNumberComparator = (a: Restriction, b: Restriction) =>
    compareByAlphanumeric(a.resourceNumber, b.resourceNumber)

export const byResourceComparator = (a: Restriction, b: Restriction) =>
    resourceTypeComparator(a, b)
        ? byResourceNumberComparator(a, b)
        : a.resourceType !== RestrictionResourceType.Stand
        ? -1
        : 1

export const byStartDateComparator = (a: Restriction, b: Restriction) =>
    compareDateTimeAsc(a.startDate, b.startDate)

export const byEndDateComparator = (a: Restriction, b: Restriction) =>
    compareDateTimeAsc(a.endDate, b.endDate)

const restrictionSorting: Record<RestrictionSorting, RestrictionSortComparator> = {
    [RestrictionSorting.StartDate]: byStartDateComparator,
    [RestrictionSorting.EndDate]: byEndDateComparator,
    [RestrictionSorting.ResourceNumber]: byResourceComparator,
}

export const restrictionSortingComparator = (
    sorting: RestrictionSorting,
): RestrictionSortComparator => restrictionSorting[sorting]
