import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { sumBy } from 'lodash'

import { PaxForecastArrivalOrDeparture } from './PaxForecastArrivalOrDeparture'
import { PaxLocalOrTransfer } from './PaxLocalOrTransfer'

export interface PaxForecastStat extends Record<PaxLocalOrTransfer, number> {
    trend: Trend
}

export interface PaxForecastStats extends Record<PaxForecastArrivalOrDeparture, PaxForecastStat> {
    totalTrend: Trend
    day: DateTime
}

const isPaxForecastData = (x: unknown): x is Record<PaxLocalOrTransfer, number> =>
    typeof x === 'object' && x !== null && PaxLocalOrTransfer.Local in x

export const totalPaxForecast = (
    stats: Record<PaxLocalOrTransfer, number> | Record<PaxLocalOrTransfer, number>[],
): number => {
    if (isPaxForecastData(stats)) {
        return stats[PaxLocalOrTransfer.Local] + stats[PaxLocalOrTransfer.Transfer]
    }
    return sumBy(stats, totalPaxForecast)
}
