import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { MessageNotification } from 'aos-services/src/services/notifications/types/MessageNotification'
import { Box } from 'aos-ui/src/components/base/Box'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import React, { FC } from 'react'

import { BaseNotificationItem } from './BaseNotificationItem'

interface MessageNotificationItemProps {
    notification: MessageNotification
}

export const MessageNotificationItem: FC<MessageNotificationItemProps> = props => {
    const { groupName, text, read, timestamp } = props.notification
    return (
        <BaseNotificationItem
            title={groupName}
            read={read}
            leftColumn={
                <InfoBar padding={8}>
                    <Icon
                        iconSize={BlockSize.Std}
                        svg={SvgIcon.Messaging}
                        iconVariant={IconVariant.White}
                    />
                </InfoBar>
            }
        >
            <Box paddingBottom={12} paddingTop={8}>
                {text}
            </Box>
            <HourClock time={timestamp} />
        </BaseNotificationItem>
    )
}
