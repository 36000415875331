import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { RunwayClosure } from 'aos-services/src/services/runways/types/RunwaysState'
import React from 'react'

export const createNotificationFromClosure = (closure: RunwayClosure) => {
    const today = new Date()

    if (closure.dateStart.isSame(today, 'day') && closure.dateEnd.isSame(today, 'day')) {
        return (
            <span>
                {'RWY '} {closure.runway} {translate('dashboard.runways.closure.clearance.today')}{' '}
                {closure.dateStart.format('HH:mm')} {' - '} {closure.dateEnd.format('HH:mm')}
            </span>
        )
    }
    if (closure.dateStart.isSame(closure.dateEnd, 'day')) {
        return closureNotificationSpan(
            closure.runway,
            closure.dateStart.format('DD.MM HH:mm'),
            closure.dateEnd.format('HH:mm'),
        )
    }
    return closureNotificationSpan(
        closure.runway,
        closure.dateStart.format('DD.MM HH:mm'),
        closure.dateEnd.format('DD.MM HH:mm'),
    )
}

const closureNotificationSpan = (runway: String, dateStart: string, dateEnd: string) => {
    return (
        <span>
            {'RWY '} {runway} {translate('dashboard.runways.closure.clearance')} {dateStart} {' - '}{' '}
            {dateEnd}
        </span>
    )
}
