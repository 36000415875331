import {
    flightsSyncAction,
    flightsSyncStopAction,
} from 'aos-services/src/core/flightInformation/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { FC, PropsWithChildren } from 'react'

import { useSyncHook } from '../../../../hooks/useSync'

interface FlightsSyncWrapperProps {
    id: SyncDataKey
}

export const FlightsSyncWrapper: FC<PropsWithChildren<FlightsSyncWrapperProps>> = ({
    id,
    children,
}) => {
    useSyncHook([flightsSyncAction(id)], [flightsSyncStopAction(id)])

    return (children as React.ReactElement) ?? null
}
