import { isEqual } from 'lodash'
import React from 'react'

import { Box, BoxProps } from '../base/Box'
import { Tooltip } from '../tooltip/Tooltip'
import { RoundTabItem } from './RoundTabItem'

interface RoundTabsListProps<T> extends BoxProps {
    value?: T
    items: T[]
    formatter: f.Func1<T, string>
    tooltip?: string
    onChange(v: T): void
}

export const RoundTabsList = <T extends any>({
    value,
    items,
    formatter,
    onChange,
    tooltip,
    ...boxProps
}: RoundTabsListProps<T>) => {
    const list = (
        <Box row {...boxProps}>
            {items.map((item: T, index: number) => (
                <RoundTabItem
                    onClick={() => onChange(item)}
                    key={index}
                    active={isEqual(value, item)}
                >
                    {formatter(item)}
                </RoundTabItem>
            ))}
        </Box>
    )

    if (tooltip) {
        return (
            <Tooltip body={tooltip} placement='topLeft' withArrow>
                {list}
            </Tooltip>
        )
    }

    return list
}
