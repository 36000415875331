import React, { FC } from 'react'

import { FodCreateModal } from '../fod/FodCreateModal'
import { FodPreviewLocationModal } from '../fod/FodLocationModal'
import { TaskDurationModal } from './common/taskDuration/TaskDurationModal'
import { TaskCreateModal, TaskEditModal } from './createTask/TaskModal'
import { TaskPreviewLocationModal } from './createTask/TaskPreviewLocationModal'

export const TasksModals: FC = () => (
    <>
        <TaskEditModal />
        <TaskCreateModal />
        <TaskPreviewLocationModal />
        <FodCreateModal />
        <FodPreviewLocationModal />
        <TaskDurationModal />
    </>
)
