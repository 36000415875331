import { EnumValues } from 'enum-values'
import { uniqBy } from 'lodash'

import { DateTime, dateTime } from './Time'
import { formatTime } from './TimeFormat'
import { translateEnum } from './translations/Translations'

/** Encodes a time of day. The value of each constant is the hour given time of day starts at.
 * For example: Late morning starts at 9 and ends at 12.
 */
export enum TimeOfDay {
    EarlyMorning = 0,
    Morning = 6,
    LateMorning = 9,
    Noon = 12,
    Afternoon = 14,
    Evening = 18,
    LateEvening = 21,
}

export const translateTimeOfDay = translateEnum<TimeOfDay>(TimeOfDay, 'time-of-day')

export const timeOfDayHourRangeString = (timeOfDay: TimeOfDay) => {
    const startTime = dateTime().hours(timeOfDay).startOf('hour')
    const endTime = dateTime()
        .hours(getNextTimeOfDay(timeOfDay))
        .startOf('hour')
        .subtract(1, 'minute')
    return `(${formatTime(startTime)} - ${formatTime(endTime)})`
}

export const getTimeOfDay = (time: DateTime): TimeOfDay =>
    EnumValues.getValues<TimeOfDay>(TimeOfDay)
        .reverse()
        .find(startingHour => time.hours() >= startingHour)!

export const startOfTimeOfDay = (
    time: DateTime,
    timeOfDay: TimeOfDay = getTimeOfDay(time),
): DateTime => time.clone().hour(timeOfDay)

export const startOfTimeOfDays = (times: DateTime[]) =>
    uniqBy(
        times.map(t => startOfTimeOfDay(t)),
        t => t.valueOf(),
    )

export const getNextTimeOfDay = (timeOfDay: TimeOfDay): TimeOfDay => {
    const values = EnumValues.getValues<TimeOfDay>(TimeOfDay)
    const nexTimeOfDayIndex = (values.indexOf(timeOfDay) + 1) % values.length
    return values[nexTimeOfDayIndex]
}

export const startOfNextTimeOfDay = (time: DateTime): DateTime => {
    const timeOfDay = getTimeOfDay(time)
    const nextTimeOfDay = getNextTimeOfDay(timeOfDay)
    const result = time.clone().hour(nextTimeOfDay)
    return nextTimeOfDay === TimeOfDay.EarlyMorning ? result.add(1, 'day') : result
}
