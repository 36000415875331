import { GaugeOptions } from '../types/GaugeOptions'

export const getArcInnerOuterRadius = (options: GaugeOptions, additionalRadius = 0) => {
    const { size } = options

    const outerRadius = Math.round((size * 100) / 300)
    const innerRadius = Math.round((size * 95) / (300 + additionalRadius))

    return { innerRadius, outerRadius }
}
