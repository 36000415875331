import {
    eventsSyncAction,
    eventsSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/events/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../../components/sync/SyncWrapper'

interface EventsSyncWrapperProps {
    id: SyncDataKey
}

export const EventsSyncWrapper: FC<PropsWithChildren<EventsSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={eventsSyncAction(id)} onLeave={eventsSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
