import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { compact } from 'lodash'

import { AosGlobalMessageDto } from '../../../dataaccess/globalMessaging/types/AosGlobalMessageDto'
import { AosUserGroupImpl } from '../../users/types/AosUserGroupImpl'
import { AosGlobalMessage } from './AosGlobalMessage'

export class AosGlobalMessageImpl
    extends WrapperObject<AosGlobalMessageDto>
    implements AosGlobalMessage {
    public get id() {
        return this.value.id!
    }

    public get text() {
        return this.value.text
    }

    public get automated() {
        return this.value.automated
    }

    public get channels() {
        return compact([
            this.value.dashboard && AosMessageChannel.Notification,
            this.value.push && AosMessageChannel.Push,
            this.value.sms && AosMessageChannel.Sms,
            this.value.email && AosMessageChannel.Email,
        ])
    }

    public get createdAt() {
        return dateTime(this.value.createdAt)
    }

    public get group() {
        return new AosUserGroupImpl(this.value.group)
    }
}
