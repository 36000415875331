import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const t = (suffix: string) => translate(`dashboard.help.baggage-delivery-arrivals.${suffix}`)

export const baggageDeliveryArrivalsHelpConfig: HelpModalConfig = {
    title: t('title'),
    items: [
        {
            title: t('baggage-measurements'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsMeasurementsHelp')),
        },
        {
            title: t('baggage-c-and-i'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsColorsAndIconsHelp')),
        },

        {
            title: t('baggage-arc-diagram'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsArcDiagramHelp')),
        },
        {
            title: t('baggage-time-range'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsTimeRangeHelp')),
        },
        {
            title: t('baggage-chart'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsChartHelp')),
        },
        {
            title: t('baggage-flight-table'),
            renderer: lazy(() => import('./renderers/BaggageArrivalsFlightTableHelp')),
        },
    ],
}
