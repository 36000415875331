export interface Action<T> {
    type: T
}

export interface PayloadAction<T, U> extends Action<T> {
    payload: U
}

export function emptyActionCreator<T>(t: string): f.Func0<T> {
    return () =>
        ({
            type: t,
        } as any)
}

export function payloadActionCreator<T, U>(t: string): f.Func1<U, T> {
    return (p: U) =>
        ({
            type: t,
            payload: p,
        } as any)
}

export function parentActionWrapper<T, TA, TB>(parent: f.Func1<TA, TB>, actions: T): T {
    if (typeof actions === 'function') {
        return ((t: unknown) => parent(actions(t))) as T
    }

    const wrapped: Record<string, unknown> = {}
    Object.keys(actions as object).forEach((key: string) => {
        wrapped[key] = (t: T) =>
            parent((actions as Record<string, (param: TA) => TA>)[key]((t as unknown) as TA))
    })
    return wrapped as any
}
