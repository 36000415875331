import { SimpleFormModalActions } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import {
    SingleDateActionCreators,
    singleDateActionCreators,
} from 'aos-components/src/components/modal/InputModal/core/singleDate/actions'
import {
    SingleInputActionCreators,
    singleInputActionCreators,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import {
    SingleSelectModalAction,
    SingleSelectModalActionCreators,
    singleSelectModalActionCreators,
} from 'aos-components/src/components/modal/InputModal/core/singleSelect/actions'
import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import {
    ToggleEventChecklistTask,
    UpdateEventItemAction,
} from 'aos-services/src/core/events/actions'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { NewFeedOut } from 'aos-services/src/services/events/input/NewFeedOut'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { TaskOrMessageTemplate } from 'aos-services/src/services/events/types/AosEventChecklist'
import { PrivateChannelParticipantInput } from 'aos-services/src/services/firebaseEvents/input/PrivateChannelParticipantInput'
import { AosFirebaseEvent } from 'aos-services/src/services/firebaseEvents/types/AosFirebaseEvent'

export const OPEN_EVENT = 'EVENT_EDITOR/OPEN_EVENT'
export type OpenEventAction = PayloadAction<typeof OPEN_EVENT, number>
export const openEventAction = payloadActionCreator<OpenEventAction, number>(OPEN_EVENT)

export const LOAD_EVENT_IN_EVENT_EDITOR = 'EVENT_EDITOR/LOAD_EVENT_IN_EVENT_EDITOR'
export type LoadEventInEventEditorAction = PayloadAction<typeof LOAD_EVENT_IN_EVENT_EDITOR, number>
export const loadEventInEventEditorAction = payloadActionCreator<
    LoadEventInEventEditorAction,
    number
>(LOAD_EVENT_IN_EVENT_EDITOR)

export const LOAD_EVENT_SUCCESS = 'EVENT_EDITOR/LOAD_EVENT_SUCCESS'
export type LoadEventSuccessAction = PayloadAction<typeof LOAD_EVENT_SUCCESS, AosEvent | undefined>
export const loadEventSuccessAction = payloadActionCreator<
    LoadEventSuccessAction,
    AosEvent | undefined
>(LOAD_EVENT_SUCCESS)

export const REFRESH_EVENT = 'EVENT_EDITOR/REFRESH_EVENT'
export type RefreshEventAction = PayloadAction<typeof REFRESH_EVENT, AosEvent | undefined>
export const refreshEventAction = payloadActionCreator<RefreshEventAction, AosEvent | undefined>(
    REFRESH_EVENT,
)

export const LOAD_FIREBASE_EVENT_SUCCESS = 'EVENT_EDITOR/LOAD_FIREBASE_EVENT_SUCCESS'
export type LoadFirebaseEventSuccessAction = PayloadAction<
    typeof LOAD_FIREBASE_EVENT_SUCCESS,
    AosFirebaseEvent | undefined
>
export const loadFirebaseEventSuccessAction = payloadActionCreator<
    LoadFirebaseEventSuccessAction,
    AosFirebaseEvent | undefined
>(LOAD_FIREBASE_EVENT_SUCCESS)

export const SET_FIREBASE_EVENT_SUCCESS = 'EVENT_EDITOR/SET_FIREBASE_EVENT_SUCCESS'
export type SetFirebaseEventSuccessAction = PayloadAction<
    typeof SET_FIREBASE_EVENT_SUCCESS,
    AosFirebaseEvent
>
export const setFirebaseEventSuccessAction = payloadActionCreator<
    SetFirebaseEventSuccessAction,
    AosFirebaseEvent
>(SET_FIREBASE_EVENT_SUCCESS)

export const UPDATE_INVITATION = 'EVENT_EDITOR/UPDATE_INVITATION'
export type UpdateInvitationAction = PayloadAction<
    typeof UPDATE_INVITATION,
    PrivateChannelParticipantInput
>
export const updateInvitationAction = payloadActionCreator<
    UpdateInvitationAction,
    PrivateChannelParticipantInput
>(UPDATE_INVITATION)

export const CHANGE_FEED_OUT_FORM = 'EVENT_EDITOR/CHANGE_FEED_OUT_FORM'
export type ChangeFeedOutFormAction = PayloadAction<typeof CHANGE_FEED_OUT_FORM, NewFeedOut>
export const changeFeedOutFormAction = payloadActionCreator<ChangeFeedOutFormAction, NewFeedOut>(
    CHANGE_FEED_OUT_FORM,
)

export const ADD_FEED_OUT = 'EVENT_EDITOR/ADD_FEED_OUT'
export type AddFeedOutAction = PayloadAction<typeof ADD_FEED_OUT, [number, NewFeedOut]>
export const addFeedOutAction = payloadActionCreator<AddFeedOutAction, [number, NewFeedOut]>(
    ADD_FEED_OUT,
)

export const TOGGLE_FEEDOUT_CONFIRMATION = 'EVENT_EDITOR/SHOW_FEEDOUT_CONFIRMATION'
export type ToggleFeedOutConfirmationAction = PayloadAction<
    typeof TOGGLE_FEEDOUT_CONFIRMATION,
    boolean
>
export const toggleFeedOutConfirmationAction = payloadActionCreator<
    ToggleFeedOutConfirmationAction,
    boolean
>(TOGGLE_FEEDOUT_CONFIRMATION)

export const ADD_FEED_OUT_REQUEST = 'EVENT_EDITOR/ADD_FEED_OUT_REQUEST'
export type AddFeedOutRequestAction = Action<typeof ADD_FEED_OUT_REQUEST>
export const addFeedOutRequestAction =
    emptyActionCreator<AddFeedOutRequestAction>(ADD_FEED_OUT_REQUEST)

export const ADD_FEED_OUT_SUCCESS = 'EVENT_EDITOR/ADD_FEED_OUT_SUCCESS'
export type AddFeedOutSuccessAction = Action<typeof ADD_FEED_OUT_SUCCESS>
export const addFeedOutSuccessAction =
    emptyActionCreator<AddFeedOutSuccessAction>(ADD_FEED_OUT_SUCCESS)

export const TOGGLE_EVENT_CHECKLIST = 'EVENT_EDITOR/TOGGLE_EVENT_CHECKLIST'
export type ToggleEventChecklist = PayloadAction<typeof TOGGLE_EVENT_CHECKLIST, number>
export const toggleEventChecklist = payloadActionCreator<ToggleEventChecklist, number>(
    TOGGLE_EVENT_CHECKLIST,
)

export const CHANGE_CHECKLIST_TAB = 'EVENT_EDITOR/CHANGE_CHECKLIST_TAB'
export type ChangeChecklistTabAction = PayloadAction<
    typeof CHANGE_CHECKLIST_TAB,
    [number, TaskOrMessageTemplate]
>
export const changeChecklistTabAction = payloadActionCreator<
    ChangeChecklistTabAction,
    [number, TaskOrMessageTemplate]
>(CHANGE_CHECKLIST_TAB)

export const COPY_MESSAGE_TEMPLATE = 'EVENT_EDITOR/COPY_MESSAGE_TEMPLATE'
export type CopyMessageTemplateAction = PayloadAction<
    typeof COPY_MESSAGE_TEMPLATE,
    AosChecklistItem
>
export const copyMessageTemplateAction = payloadActionCreator<
    CopyMessageTemplateAction,
    AosChecklistItem
>(COPY_MESSAGE_TEMPLATE)

export const SELECT_USER_GROUP_PARENT = 'EVENT_EDITOR/SELECT_USER_GROUP_PARENT'
export type SelectUserGroupParentAction = PayloadAction<
    typeof SELECT_USER_GROUP_PARENT,
    SingleSelectModalAction
>
export const selectUserGroupParentAction = payloadActionCreator<
    SelectUserGroupParentAction,
    SingleSelectModalAction
>(SELECT_USER_GROUP_PARENT)

export const selectUserGroupActions: SingleSelectModalActionCreators = parentActionWrapper(
    selectUserGroupParentAction,
    singleSelectModalActionCreators,
)

export const TOGGLE_USER_GROUP_CONFIRMATION = 'EVENT_EDITOR/SELECT_USER_GROUP_CONFIRMATION'
export type ToggleUserGroupConfirmationAction = PayloadAction<
    typeof TOGGLE_USER_GROUP_CONFIRMATION,
    boolean
>
export const toggleUserGroupConfirmationAction = payloadActionCreator<
    ToggleUserGroupConfirmationAction,
    boolean
>(TOGGLE_USER_GROUP_CONFIRMATION)

export const I_WILL_ARRIVE_PARENT = 'EVENT_EDITOR/I_WILL_ARRIVE_PARENT'
export type IWillArriveParentAction = PayloadAction<
    typeof I_WILL_ARRIVE_PARENT,
    SimpleFormModalActions
>
export const iWillArriveParentAction = payloadActionCreator<
    IWillArriveParentAction,
    SimpleFormModalActions
>(I_WILL_ARRIVE_PARENT)

export const iWillArriveActions: SingleDateActionCreators = parentActionWrapper(
    iWillArriveParentAction,
    singleDateActionCreators,
)

export const CLOSE_EVENT_PARENT = 'EVENT_EDITOR/CLOSE_EVENT_PARENT'
export type CloseEventParentAction = PayloadAction<
    typeof CLOSE_EVENT_PARENT,
    SimpleFormModalActions
>
export const closeEventParentAction = payloadActionCreator<
    CloseEventParentAction,
    SimpleFormModalActions
>(CLOSE_EVENT_PARENT)

export const REOPEN_EVENT = 'EVENT_EDITOR/REOPEN_EVENT'
export type ReopenEventAction = PayloadAction<typeof REOPEN_EVENT, number>
export const reopenEventAction = payloadActionCreator<ReopenEventAction, number>(REOPEN_EVENT)

export const DELETE_EVENT = 'EVENT_EDITOR/DELETE_EVENT'
export type DeleteEventAction = PayloadAction<typeof DELETE_EVENT, number>
export const deleteEventAction = payloadActionCreator<DeleteEventAction, number>(DELETE_EVENT)

export const UNDELETE_EVENT = 'EVENT_EDITOR/UNDELETE_EVENT'
export type UndeleteEventAction = PayloadAction<typeof UNDELETE_EVENT, number>
export const undeleteEventAction = payloadActionCreator<UndeleteEventAction, number>(UNDELETE_EVENT)

export const closeEventActions: SingleDateActionCreators = parentActionWrapper(
    closeEventParentAction,
    singleDateActionCreators,
)

export const SET_ADDITIONAL_NOTE_PARENT = 'EVENT_EDITOR/SET_ADDITIONAL_NOTE_PARENT'
export type SetAdditionalNoteParentAction = PayloadAction<
    typeof SET_ADDITIONAL_NOTE_PARENT,
    SimpleFormModalActions
>
export const setAdditionalNoteParentAction = payloadActionCreator<
    SetAdditionalNoteParentAction,
    SimpleFormModalActions
>(SET_ADDITIONAL_NOTE_PARENT)

export const setAdditionalNoteActions: SingleInputActionCreators = parentActionWrapper(
    setAdditionalNoteParentAction,
    singleInputActionCreators,
)

export type EventEditorAction =
    | LoadEventInEventEditorAction
    | LoadEventSuccessAction
    | RefreshEventAction
    | LoadFirebaseEventSuccessAction
    | SetFirebaseEventSuccessAction
    | ChangeFeedOutFormAction
    | ToggleFeedOutConfirmationAction
    | AddFeedOutRequestAction
    | AddFeedOutAction
    | AddFeedOutSuccessAction
    | ToggleEventChecklist
    | ToggleEventChecklistTask
    | SelectUserGroupParentAction
    | ToggleUserGroupConfirmationAction
    | IWillArriveParentAction
    | CloseEventParentAction
    | ChangeChecklistTabAction
    | CopyMessageTemplateAction
    | ReopenEventAction
    | DeleteEventAction
    | UndeleteEventAction
    | UpdateEventItemAction
    | SetAdditionalNoteParentAction
