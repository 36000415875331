import React, { FCWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { LightScrollbar } from '../../scrollbar/LightScrollbar'
import { FormSidebar } from './FormSidebar'

interface TwoColumnFormContainerProps {
    mainContent: ReactNode
    sideContent: ReactNode
}

export const TwoColumnFormContainer: FCWithChildren<TwoColumnFormContainerProps> = ({
    mainContent,
    sideContent,
}) => (
    <Box row height='100%' alignItems='stretch'>
        <LightScrollbar>
            <FormBox fullHeight flex={1} paddingLeft={30} paddingVertical={20}>
                {mainContent}
            </FormBox>
        </LightScrollbar>
        <Box>
            <FormSidebar>{sideContent}</FormSidebar>
        </Box>
    </Box>
)

const FormBox = styled(Box)`
    padding-right: 66px;
`
