import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC } from 'react'
import styled from 'styled-components'

import { RunwaysLegend } from './RunwaysLegend'

interface RunwaysContentProps {
    lastChange: DateTime
}

export const RunwaysContent: FC<RunwaysContentProps> = ({ lastChange }) => {
    return (
        <Container className='top-left'>
            <Tooltip
                body={translate('dashboard.runways.lastChange.tooltip')}
                placement='top'
                withArrow
            >
                <div>
                    <Box className='runways-date' marginBottom={20}>
                        {formatDateTime(lastChange, 'DD.MM.YYYY HH:mm')}
                    </Box>
                </div>
            </Tooltip>
            <RunwaysLegend />
        </Container>
    )
}

const Container = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
`
