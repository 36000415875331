import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import {
    PaxForecastStats,
    totalPaxForecast,
} from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { TrendMeasure } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import React, { FC } from 'react'

interface PaxForecastTotalTrendProps {
    stats: PaxForecastStats
}

export const PaxForecastTotalTrend: FC<PaxForecastTotalTrendProps> = ({ stats }) => (
    <TrendMeasure
        value={formatNumber(
            totalPaxForecast([
                stats[PaxForecastArrivalOrDeparture.Arrivals],
                stats[PaxForecastArrivalOrDeparture.Departures],
            ]),
        )}
        trend={stats.totalTrend}
        label={translate('dashboard.pax-forecast.total')}
    />
)
