import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'

export const EXPORT_ALL_TASK_CHECKLIST_TEMPLATES =
    'CHECKLIST_MANAGER/EXPORT_ALL_TASK_CHECKLIST_TEMPLATES'
export type ExportAllTaskChecklistTemplatesAction = Action<
    typeof EXPORT_ALL_TASK_CHECKLIST_TEMPLATES
>
export const exportAllTaskChecklistTemplatesAction =
    emptyActionCreator<ExportAllTaskChecklistTemplatesAction>(EXPORT_ALL_TASK_CHECKLIST_TEMPLATES)

export const EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE =
    'CHECKLIST_MANAGER/EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE'
export type ExportSingleTaskChecklistTemplateAction = PayloadAction<
    typeof EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE,
    number
>
export const exportSingleTaskChecklistTemplateAction = payloadActionCreator<
    ExportSingleTaskChecklistTemplateAction,
    number
>(EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE)

export const LOAD_TASK_CHECKLIST_TEMPLATES = 'CHECKLIST_MANAGER/LOAD_TASK_CHECKLIST_TEMPLATES'
export type LoadTaskChecklistTemplatesAction = Action<typeof LOAD_TASK_CHECKLIST_TEMPLATES>
export const loadTaskChecklistTemplatesAction =
    emptyActionCreator<LoadTaskChecklistTemplatesAction>(LOAD_TASK_CHECKLIST_TEMPLATES)

export const LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS =
    'CHECKLIST_MANAGER/LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS'
export type LoadTaskChecklistTemplatesSuccessAction = PayloadAction<
    typeof LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS,
    AosTaskChecklistTemplate[]
>
export const loadTaskChecklistTemplatesSuccessAction = payloadActionCreator<
    LoadTaskChecklistTemplatesSuccessAction,
    AosTaskChecklistTemplate[]
>(LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS)

export const SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL'
export type ShowCreateTaskChecklistTemplateModalAction = Action<
    typeof SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL
>
export const showCreateTaskChecklistTemplateModalAction =
    emptyActionCreator<ShowCreateTaskChecklistTemplateModalAction>(
        SHOW_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL,
    )

export const HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL'
export type HideCreateTaskChecklistTemplateModalAction = Action<
    typeof HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL
>
export const hideCreateTaskChecklistTemplateModalAction =
    emptyActionCreator<HideCreateTaskChecklistTemplateModalAction>(
        HIDE_CREATE_TASK_CHECKLIST_TEMPLATE_MODAL,
    )

export const SELECT_TASK_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/SELECT_TASK_CHECKLIST_TEMPLATE'
export type SelectTaskChecklistTemplateAction = PayloadAction<
    typeof SELECT_TASK_CHECKLIST_TEMPLATE,
    AosTaskChecklistTemplate
>
export const selectTaskChecklistTemplateAction = payloadActionCreator<
    SelectTaskChecklistTemplateAction,
    AosTaskChecklistTemplate
>(SELECT_TASK_CHECKLIST_TEMPLATE)

export const LOAD_TASK_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/LOAD_TASK_CHECKLIST_TEMPLATE'
export type LoadTaskChecklistTemplateAction = PayloadAction<
    typeof LOAD_TASK_CHECKLIST_TEMPLATE,
    number
>
export const loadTaskChecklistTemplateAction = payloadActionCreator<
    LoadTaskChecklistTemplateAction,
    number
>(LOAD_TASK_CHECKLIST_TEMPLATE)

export const LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS =
    'CHECKLIST_MANAGER/LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS'
export type LoadTaskChecklistTemplateSuccessAction = PayloadAction<
    typeof LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS,
    AosTaskChecklistTemplate
>
export const loadTaskChecklistTemplateSuccessAction = payloadActionCreator<
    LoadTaskChecklistTemplateSuccessAction,
    AosTaskChecklistTemplate
>(LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS)

export const CREATE_TASK_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/CREATE_TASK_CHECKLIST_TEMPLATE'
export type CreateTaskChecklistTemplateAction = PayloadAction<
    typeof CREATE_TASK_CHECKLIST_TEMPLATE,
    AosTaskChecklistTemplate
>
export const createTaskChecklistTemplateAction = payloadActionCreator<
    CreateTaskChecklistTemplateAction,
    AosTaskChecklistTemplate
>(CREATE_TASK_CHECKLIST_TEMPLATE)

export const DELETE_TASK_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/DELETE_TASK_CHECKLIST_TEMPLATE'
export type DeleteTaskChecklistTemplateAction = PayloadAction<
    typeof DELETE_TASK_CHECKLIST_TEMPLATE,
    number
>
export const deleteTaskChecklistTemplateAction = payloadActionCreator<
    DeleteTaskChecklistTemplateAction,
    number
>(DELETE_TASK_CHECKLIST_TEMPLATE)

export const UPDATE_TASK_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/UPDATE_TASK_CHECKLIST_TEMPLATE'
export type UpdateTaskChecklistTemplateAction = PayloadAction<
    typeof UPDATE_TASK_CHECKLIST_TEMPLATE,
    AosTaskChecklistTemplate
>
export const updateTaskChecklistTemplateAction = payloadActionCreator<
    UpdateTaskChecklistTemplateAction,
    AosTaskChecklistTemplate
>(UPDATE_TASK_CHECKLIST_TEMPLATE)

export const OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL'
export type OpenEditTaskChecklistTemplateModalAction = PayloadAction<
    typeof OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL,
    AosTaskChecklistTemplate
>
export const openEditTaskChecklistTemplateModalAction = payloadActionCreator<
    OpenEditTaskChecklistTemplateModalAction,
    AosTaskChecklistTemplate
>(OPEN_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL)

export const HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL'
export type HideEditTaskChecklistTemplateModalAction = Action<
    typeof HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL
>
export const hideEditTaskChecklistTemplateModalAction =
    emptyActionCreator<HideEditTaskChecklistTemplateModalAction>(
        HIDE_EDIT_TASK_CHECKLIST_TEMPLATE_MODAL,
    )

export type TaskChecklistManagerActions =
    | ExportAllTaskChecklistTemplatesAction
    | ExportSingleTaskChecklistTemplateAction
    | LoadTaskChecklistTemplatesAction
    | LoadTaskChecklistTemplatesSuccessAction
    | ShowCreateTaskChecklistTemplateModalAction
    | SelectTaskChecklistTemplateAction
    | LoadTaskChecklistTemplateAction
    | LoadTaskChecklistTemplateSuccessAction
    | HideCreateTaskChecklistTemplateModalAction
    | CreateTaskChecklistTemplateAction
    | DeleteTaskChecklistTemplateAction
    | UpdateTaskChecklistTemplateAction
    | OpenEditTaskChecklistTemplateModalAction
    | HideEditTaskChecklistTemplateModalAction
