import { Pageable } from 'aos-helpers/src/helpers/Pageable'

import { TaskCategory } from './TaskCategory'
import { TaskFilter } from './TaskFilter'
import { TaskSnapshot } from './TaskSnapshot'
import { TaskSorting, TaskSortingMobile } from './TaskSorting'

export type TaskPageable = Pageable<TaskSnapshot>

export interface TaskPageableFilters {
    category: TaskCategory[]
    page: number
    pageSize: number
    sort: TaskSorting | TaskSortingMobile
    taskFilter: TaskFilter
}

export const initialTaskPageableFilters: TaskPageableFilters = {
    category: [],
    page: 0,
    pageSize: 10,
    sort: TaskSorting.ByTime,
    taskFilter: TaskFilter.All,
}
