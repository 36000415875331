import { BBox } from 'aos-services/src/services/map/types/BBox'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import Layer from 'ol/layer/Layer'
import Map from 'ol/Map'
import React from 'react'

export const layerIdKey = 'layerId'

export interface OpenlayersMapContextProps {
    map: Map
    currentPosition: () => MapPosition | null
    currentBounds: () => BBox | null
    getLayersById: <T extends Layer>(id: string) => T[]
}

export type OpenlayersMapChildContext = OpenlayersMapContextProps

const defaultOpenlayersMapContext: OpenlayersMapContextProps = {
    map: null as any,
    currentPosition: () => null,
    currentBounds: () => null,
    getLayersById: () => [],
}

export const MapContext = React.createContext<OpenlayersMapContextProps>(
    defaultOpenlayersMapContext,
)
export const { Provider } = MapContext
