import { Box } from 'aos-ui/src/components/base/Box'
import { TooltipOverlay, TooltipOverlayVariant } from 'aos-ui/src/components/tooltip/TooltipOverlay'
import React from 'react'
import styled from 'styled-components'

interface Props {
    tooltip: string
    colorList: Array<any>
}

function WeatherTooltipFreezingPhenomenon({ tooltip, colorList }: Props) {
    return (
        <TooltipOverlay variant={TooltipOverlayVariant.Black} padding={16} size={13} withArrow>
            <TooltipWrapper>
                <Title>{tooltip}</Title>
                <ColorSectionWrapper>
                    {colorList.map((colorSection: any, index: number) => {
                        return (
                            <ColorSection key={colorSection[0] + index}>
                                {colorSection[0]}
                                {colorSection[1].map((color: any, index: number) => {
                                    return (
                                        <ColorWrapper key={color[1] + index}>
                                            <ColorBox color={color[0]} />
                                            <ColorTitle>{color[1]}</ColorTitle>
                                        </ColorWrapper>
                                    )
                                })}
                            </ColorSection>
                        )
                    })}
                </ColorSectionWrapper>
            </TooltipWrapper>
        </TooltipOverlay>
    )
}

export default WeatherTooltipFreezingPhenomenon

const Title = styled.div`
    font-size: 12px;
    font-weight: 600;
`

const TooltipWrapper = styled.div`
    display: grid;
    color: '#FFFFFF';
    font-weight: 500;
    min-width: 350px;
`

const ColorSectionWrapper = styled.div`
    display: flex;
    padding: 8px 0 0 0;
    justify-content: space-between;
    height: 100%;
    align-items: flex-start;
`

const ColorSection = styled.div`
    display: grid;
    align-items: center;
`

const ColorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
`

const ColorBox = styled(Box)<{ color: string }>`
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${props => props.color};
`

const ColorTitle = styled.div`
    font-size: 13px;
    margin-left: 8px;
`
