import { DateTime } from 'aos-helpers/src/helpers/Time'

import { TaskAssignee } from './task/TaskAssignee'
import { TaskStatus } from './TaskStatus'

export enum TaskLogType {
    Info = 'INFO',
    Message = 'MESSAGE',
    Assign = 'ASSIGN',
    Status = 'STATUS',
}

export enum TaskLogAssignType {
    Assign = 'ASSIGN',
    Remove = 'REMOVE',
}

export interface TaskBaseLog {
    id: string
    type: TaskLogType
    createdAt: DateTime
    createdBy: string
}

// Reported task
export interface TaskLogInfo extends TaskBaseLog {
    type: TaskLogType.Info
    messageKey: string
}

// "Text message"
export interface TaskLogMessage extends TaskBaseLog {
    type: TaskLogType.Message
    message: string
}

// Assigned task to ...
export interface TaskLogAssign extends TaskBaseLog {
    type: TaskLogType.Assign
    assigns: TaskLogAssignEntry[]
}

// Changed TODO -> In Progress
export interface TaskLogStatus extends TaskBaseLog {
    type: TaskLogType.Status
    from: TaskStatus
    to: TaskStatus
}

export interface TaskLogAssignEntry {
    user: TaskAssignee
    assignType: TaskLogAssignType
}

export const splitAssignees = (entry: TaskLogAssign): [string[], string[]] => {
    const assigned = entry.assigns
        .filter(v => v.assignType === TaskLogAssignType.Assign)
        .map(v => v.user.name)
    const unassigned = entry.assigns
        .filter(v => v.assignType === TaskLogAssignType.Remove)
        .map(v => v.user.name)
    return [assigned, unassigned]
}

export type TaskLog = TaskLogInfo | TaskLogMessage | TaskLogAssign | TaskLogStatus
