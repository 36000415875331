import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum InspectionGroup {
    Runway = 'RUNWAY',
    Taxiway = 'TAXIWAY',
    Terminal = 'TERMINAL',
    FireTrucks = 'FIRE_TRUCKS',
    Rope = 'ROPE',
    Contractor = 'CONTRACTOR',
    Other = 'OTHER',
    Fod = 'FOD',
    OtherActors = 'OTHER_ACTORS',
    OtherAreas = 'OTHER_AREAS',
    AreaInspection = 'AREA_INSPECTION',
}
export const allInspectionGroups = EnumValues.getValues<InspectionGroup>(InspectionGroup)
export const translateInspectionGroup = translateEnum(InspectionGroup, 'inspection-group')
