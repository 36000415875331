import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent } from 'react'

import { ListTooltipBody } from './ListTooltipBody'

interface FlightNumberCellProps {
    fltnr?: string
    cflight: string[]
}

export class FlightNumberCell extends PureComponent<FlightNumberCellProps> {
    public render() {
        const { fltnr, cflight } = this.props
        if (!fltnr) {
            return null
        }

        if (cflight.length > 0) {
            return (
                <Tooltip body={<ListTooltipBody items={cflight} />} withArrow>
                    <span>{fltnr}</span>
                </Tooltip>
            )
        }

        return fltnr
    }
}
