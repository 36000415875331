import {
    FzdzColors,
    FzfgColors,
    FzraColors,
} from 'aos-frontend/src/app/views/statusDashboard/renderers/weather/fullView/FreezingPhenomenon'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    FreezingPhenomenonIntensity,
    FreezingPhenomenonType,
} from 'aos-services/src/services/airportStatus/weather/types/FreezingPhenomenon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { TooltipOverlay, TooltipOverlayVariant } from 'aos-ui/src/components/tooltip/TooltipOverlay'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisScale } from 'aos-ui-common/src/components/chart/types/AxisScale'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'

export const seriesBarHeight = 12
export const seriesBarPadding = 22

class BlockSeriesComponent<T> extends BaseChartComponent<BlockSeriesProps<T>> {
    public render() {
        const { size, margins, data } = this.props

        return (
            <g className={cxp(this.props, 'series series--block')}>
                <rect
                    x={margins.left}
                    y={margins.top + seriesBarPadding}
                    rx='2'
                    ry='2'
                    height={seriesBarHeight}
                    width={size.width}
                    fill={Color.UiBlack2}
                />
                {data.map(this.renderBlock)}
            </g>
        )
    }

    private selectBlockColor = (elementData: BaseElement<any>) => {
        const { value } = elementData
        if (value.type === FreezingPhenomenonType.Fzdz) {
            switch (value.intensity) {
                case FreezingPhenomenonIntensity.Strong:
                    return FzdzColors.Heavy
                case FreezingPhenomenonIntensity.Moderate:
                    return FzdzColors.Moderate
                case FreezingPhenomenonIntensity.Weak:
                    return FzdzColors.Light
            }
        }

        if (value.type === FreezingPhenomenonType.Fzra) {
            switch (value.intensity) {
                case FreezingPhenomenonIntensity.Strong:
                    return FzraColors.Heavy
                case FreezingPhenomenonIntensity.Moderate:
                    return FzraColors.Moderate
                case FreezingPhenomenonIntensity.Weak:
                    return FzraColors.Light
            }
        }

        if (value.type === FreezingPhenomenonType.Fzfg) {
            switch (value.intensity) {
                case FreezingPhenomenonIntensity.Moderate:
                    return FzfgColors.Moderate
            }
        }

        return Color.Yellow
    }

    private renderBlock = (t: BaseElement<T>, index: number) => {
        const { margins, scale } = this.props
        const left = scale(t.from) || 0
        const right = scale(t.to) || 0
        return (
            <Tooltip key={index} overlay={this.overlay(t)}>
                <g transform={`translate(${left + 1} ${margins.top + seriesBarPadding})`}>
                    <rect
                        x={0}
                        y={0}
                        height={seriesBarHeight}
                        width={right - left - 2}
                        fill={this.selectBlockColor(t)}
                        rx={3}
                    />
                </g>
            </Tooltip>
        )
    }

    private overlay = (t: BaseElement<T>) => {
        return (
            <TooltipOverlay
                variant={TooltipOverlayVariant.Black}
                padding={8}
                size={13}
                color={Color.Yellow}
                withArrow
            >
                {this.props.labelFormatter(t.value)}
            </TooltipOverlay>
        )
    }
}

interface BaseElement<T> {
    from: DateTime
    to: DateTime
    value: T
}

export interface BlockSeriesProps<T> extends ClassNameProps {
    name: string
    data: BaseElement<T>[]
    scale: AxisScale<DateTime>
    labelFormatter: f.Func1<T, string>
    valueFormatter: f.Func1<T, string>
}

export const BlockSeries = withChartContext(BlockSeriesComponent)
