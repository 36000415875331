import { match, matchPath } from 'react-router'

import { Link } from '../../app/core/Links'
import { State } from '../../app/core/state'

export const createMatchSelector = <
    S extends State,
    P extends { [K in keyof P]?: string | undefined },
>(
    path: Link,
): ((state: S) => match<P> | null) => {
    let lastPathname: string | null | undefined = null
    let lastMatch: match<P> | null = null

    return (state: S) => {
        const pathname = state.router.location?.pathname
        if (pathname === lastPathname) {
            return lastMatch
        }
        lastPathname = pathname
        const match = matchPath<P>(pathname as string, path)
        if (
            !match ||
            !lastMatch ||
            match.url !== (lastMatch as match).url ||
            // When URL matched for nested routes, URL is the same but isExact is not.
            match.isExact !== (lastMatch as match).isExact
        ) {
            lastMatch = match
        }

        return lastMatch as match<P> | null
    }
}
