import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { flightSagas } from 'aos-services/src/core/flightInformation/sagas'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { mailingService } from 'aos-services/src/services/mailing/mailingService'
import { Dictionary, flatten, isEqual, omitBy, pick } from 'lodash'
import { push } from 'redux-first-history'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
    RESTORE_LAST_FILTERS,
    RestoreLastFiltersAction,
    saveLastFiltersAction,
    SEND_SHARE_EMAIL,
    SendShareEmailAction,
    sendShareEmailSuccessAction,
    UPDATE_FILTERS,
    UpdateFiltersAction,
    updateFiltersAction,
} from './actions'
import { defaultFlightFilters, flightsUrlFiltersConfig } from './flight/flightFiltersState'
import { clearFilters } from './helpers/filters'
import { FiltersObject, stringifyFilters, UrlFiltersConfig } from './helpers/urlFilters'
import { flightsUrlFiltersSelector, hasUrlFiltersSelector, lastFiltersSelector } from './selectors'
import { defaultTowingFilters, towingUrlFiltersConfig } from './towing/towingFiltersState'

function* restoreLastFiltersSaga() {
    yield takeEvery<RestoreLastFiltersAction>(RESTORE_LAST_FILTERS, function* (action) {
        const hasUrlFilters: boolean = yield select(hasUrlFiltersSelector)
        const lastFilters: FiltersObject = yield select(lastFiltersSelector(action.payload))
        const urlFilters: ReturnType<typeof flightsUrlFiltersSelector> = yield select(
            flightsUrlFiltersSelector,
        )
        yield put(updateFiltersAction([action.payload, hasUrlFilters ? urlFilters : lastFilters]))
    })
}

function* updateFiltersURLSaga() {
    yield takeEvery<UpdateFiltersAction>(UPDATE_FILTERS, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)

        const [type, filters] = action.payload
        const lastFilters: string[] = yield select(lastFiltersSelector(type))
        const clearedFilters = clearFilters({ ...lastFilters, ...filters })
        const omittedFilters = omitDefaultsByType(type, clearedFilters)
        const newFilters = siteLocation
            ? retainAirportValue(clearedFilters, omittedFilters)
            : omittedFilters
        const combinedFilters = combineFiltersByType(type, newFilters)

        yield put(push({ search: stringifyFilters(combinedFilters) }))
        yield put(saveLastFiltersAction([type, combinedFilters]))
    })
}

function* sendShareEmailSaga() {
    yield takeEvery<SendShareEmailAction>(SEND_SHARE_EMAIL, function* (action) {
        const { email, link, note } = action.payload
        yield call(mailingService.sendLink, { email, link, note })
        yield put(sendShareEmailSuccessAction())
    })
}

const omitDefaultsByType = (type: FlightListType, filters: FiltersObject) => {
    switch (type) {
        case FlightListType.Arrivals:
        case FlightListType.Departures: {
            return omitDefaults(filters, defaultFlightFilters)
        }
        case FlightListType.Towing: {
            return omitDefaults(filters, defaultTowingFilters)
        }
    }
    return filters
}

const combineFiltersByType = (type: FlightListType, filters: FiltersObject) => {
    switch (type) {
        case FlightListType.Arrivals:
        case FlightListType.Departures: {
            const keys = configFilterFields(flightsUrlFiltersConfig)
            return pick(filters, keys)
        }
        case FlightListType.Towing: {
            const keys = configFilterFields(towingUrlFiltersConfig)
            return pick(filters, keys)
        }
    }
    return filters
}

const retainAirportValue = (oldFilters: FiltersObject, newFilters: FiltersObject) => {
    newFilters.airport = oldFilters.airport
    return newFilters
}

const configFilterFields = (config: UrlFiltersConfig): string[] => flatten(Object.values(config))

const omitDefaults = (filters: FiltersObject, defaults: Dictionary<any>): FiltersObject =>
    omitBy(filters, (value, key: string) => isEqual(value, defaults[key]))

export const flightInformationSagas = [
    ...flightSagas,
    restoreLastFiltersSaga,
    updateFiltersURLSaga,
    sendShareEmailSaga,
]
