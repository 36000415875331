import { coordinateToLonLat } from 'aos-helpers/src/helpers/coordinate/CoordinateTransformer'
import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { MapBrowserEvent } from 'ol'

import { BaseComponent } from '../../base/BaseComponent'

export class LocationPicker extends BaseComponent<LocationPickerProps> {
    constructor(props: LocationPickerProps) {
        super(props)
    }

    public componentDidMount() {
        this.registerListeners(this.props.isActive)
    }

    public componentWillReceiveProps(next: LocationPickerProps) {
        this.registerListeners(next.isActive)
    }

    private registerListeners = (active: boolean) => {
        if (active) {
            this.context.map.on('click', this.clickHandler)
        } else {
            this.context.map.un('click', this.clickHandler)
        }
    }

    private clickHandler = (e: MapBrowserEvent<UIEvent>) => {
        const coordinate = this.context.map.getCoordinateFromPixel(e.pixel)
        if (this.props.onPick) {
            this.props.onPick(coordinateToLonLat(coordinate))
        }
    }
}

interface LocationPickerProps {
    isActive: boolean
    onPick?(v: LonLat): void
}
