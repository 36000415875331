import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { WhatsNewContent } from './WhatsNewContent'

interface WhatsNewMainProps {
    onClose(): void
}

export const WhatsNewMain: FC<WhatsNewMainProps> = props => {
    const { onClose } = props
    const closeIcon = (
        <IconButton
            onClick={onClose}
            iconSize={BlockSize.Small}
            iconVariant={IconVariant.Black}
            svg={SvgIcon.Close}
            seleniumLocation='modal-container-button-close'
        />
    )

    return (
        <Box fullHeight column>
            <Box row justify='space-between'>
                <h2 className='text__header-black text--light'>{translate('whats-new.title')}</h2>
                {closeIcon}
            </Box>
            <Box fullHeight paddingTop={16}>
                <WhatsNewContent />
            </Box>
        </Box>
    )
}
