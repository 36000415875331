export const USER_PRE_CHECK = 'USER_PRE_CHECK'
export const USER_FORM_READY = 'USER_FORM_READY'

export const USER_NOT_FOUND_IN_AD = 'USER_NOT_FOUND_IN_AD'
export const USER_FOUND_IN_AD = 'USER_FOUND_IN_AD'

export const USER_REMOVED = 'USER_REMOVED'

export const AOS_USER_EXISTS = 'AOS_USER_EXISTS'
export const AOS_USER_EXISTS_NOT_FOUND_IN_AD = 'AOS_USER_EXISTS_NOT_FOUND_IN_AD'
export const CONTACT_EXISTS = 'CONTACT_EXISTS'

export const USER_OR_GROUP_NOT_FOUND = 'USER_OR_GROUP_NOT_FOUND'

export type AosUserAddStatus =
    | undefined
    | typeof USER_FORM_READY
    | typeof USER_PRE_CHECK
    | typeof USER_NOT_FOUND_IN_AD
    | typeof USER_FOUND_IN_AD
    | typeof USER_REMOVED
    | typeof AOS_USER_EXISTS
    | typeof AOS_USER_EXISTS_NOT_FOUND_IN_AD
    | typeof CONTACT_EXISTS
    | typeof USER_OR_GROUP_NOT_FOUND

export function addUserStatus(v: AosUserAddStatus) {
    return v !== USER_PRE_CHECK && v !== USER_FORM_READY
}
