import { AbstractEntity } from '../../../services/base/types/AbstractEntity'
import { Auditable } from '../../../services/base/types/Auditable'
import { AosChecklistItemType } from '../../../services/checklists/types/AosCheckListItemType'
import { TaskTag } from '../../../services/checklists/types/AosTaskChecklistTemplate'
import { AnimalAge, AnimalGender } from '../../../services/tasks/types/task/AnimalsTask'
import { TaskCategory } from '../../../services/tasks/types/TaskCategory'
import { AuditableDto } from '../../common/types/AuditableDto'
import { LocationDto } from '../../common/types/LocationDto'
import { InspectionTypeDto } from './InspectionTypeDto'
import { TaskAssigneeDto } from './TaskAssigneeDto'
import { TaskLogDto } from './TaskLogDto'
import {
    AnimalSpeciesDto,
    BrushesBrandDto,
    BrushesVehicleDto,
    DeIcingAreaDto,
    DeIcingCarDto,
    DeIcingChemicalsDto,
    MaintenanceJobDto,
    RescueServiceTaskTypeDto,
} from './TaskMetadataDto'
import { TaskRecurrenceDTO } from './TaskRecurrenceDTO'

export interface BaseTaskDto extends AuditableDto {
    id?: number
    parentTaskId?: number
    instanceId?: number

    siteLocation: string
    severity: string
    category: string

    status: string
    title: string
    description: string
    location: LocationDto
    startTime: string
    endTime?: string
    deleted: boolean
    attachments: Attachment[]
    spendTimeInMinutes?: number
    assignees: TaskAssigneeDto[]
    log: TaskLogDto[]
    recurrence: TaskRecurrenceDTO
    processType: string
    checklists?: TaskChecklist[]
}

export interface TaskChecklist extends Auditable, AbstractEntity {
    taskCategory: TaskCategory
    name: string
    tag: TaskTag
    templateId: number
    items: TaskChecklistItem[]
}

export interface TaskChecklistItem extends Auditable, AbstractEntity {
    content: string
    done: boolean
    sequenceNumber: number
    type: AosChecklistItemType
}

export interface AnimalsTaskDto extends BaseTaskDto {
    type: 'ANIMAL_PREVENTION'
    animalTaskType: string
    animalSpecies: AnimalSpeciesDto
    amount: number
    cartridges: number
    expulsions: number
    gender: AnimalGender
    lifeStage: AnimalAge
    horns: boolean
    amountOfSpikes: number
}

export interface MaintenanceTaskDto extends BaseTaskDto {
    type: 'MAINTENANCE'
    billable: boolean
    assignments: MaintenanceJobAssignmentDto[]
    contractors: MaintenanceContractorDto[]
}

export interface MaintenanceJobAssignmentDto {
    job: MaintenanceJobDto
    brushesVehicle: BrushesVehicleDto
    brushesBrand: BrushesBrandDto
    engineLastShiftHours: number
    brushesLastShiftHours: number
    totalHours: number
}

export interface MaintenanceContractorDto {
    id: number
    name: string
    contractTime: string
    jobStart: string
    jobEnd: string
    numberOfWorkers: number
}

export interface DeIcingTaskDto extends BaseTaskDto {
    type: 'DE_ICING'
    temperature: number
    area: DeIcingAreaDto
    surfaceType: string
    car: DeIcingCarDto
    chemicalsUsage: DeIcingChemicalUsageDto[]
}

export interface DeIcingChemicalUsageDto {
    id: number
    amount: number
    chemicals: DeIcingChemicalsDto
}

export interface RescueServiceTaskDto extends BaseTaskDto {
    type: 'RESCUE_SERVICE'
    units: RescueServiceUnitDto[]
    address?: string
    aircraftRegistration?: string
    taskType: RescueServiceTaskTypeDto
    additionalUnits?: string
}

export interface RescueServiceUnitDto {
    id: number
    name: string
    numberOfWorkers: number
}

export interface SecurityInspectionTaskDto extends BaseTaskDto {
    type: 'INSPECTION'
    items: InspectionItemDto[]
}

export interface InspectionItemDto {
    id: number
    inspectionType: InspectionTypeDto
    comment?: string
}

export interface SimpleTask extends BaseTaskDto {
    type: 'SIMPLE'
}

export type TaskDto =
    | AnimalsTaskDto
    | MaintenanceTaskDto
    | DeIcingTaskDto
    | RescueServiceTaskDto
    | SecurityInspectionTaskDto
    | SimpleTask

export interface Attachment {
    id?: number
    link: string
}

export enum TaskVariant {
    TASKS = 'tasks',
    FOD = 'fod',
    SHIFT_NOTES = 'shift-notes',
    FIREFIGHTER_RESCUE_UNIT = 'firefighter-rescue-unit',
}

export type TaskChecklistItemChange = [number, number, number, boolean]
export type TaskRecurrentChecklistItemChange = [number, number, number, number, boolean]
