import { Feature } from 'geojson'
import { Dictionary } from 'lodash'
import { fromPairs } from 'lodash'

import { TravelServicesProperties } from './TravelServicesProperties'

export enum BaggageClaimPictogramCategory {
    One = 'Baggage claim 1',
    TwoA = 'Baggage claim 2A',
    TwoB = 'Baggage claim 2B',
}

export interface BaggageClaimProperties extends TravelServicesProperties {
    belt: string
}

export const featureToBaggageClaimFeature = (
    feature: Feature<any, TravelServicesProperties>,
): Feature<any, BaggageClaimProperties> => ({
    ...feature,
    properties: {
        ...feature.properties,
        belt: baggageClaimPictogramToBeltMapping[feature.properties.Pictogram_category],
    },
})

const baggageClaimPictogramToBeltMapping: Dictionary<string> = fromPairs([
    [BaggageClaimPictogramCategory.One, '1'],
    [BaggageClaimPictogramCategory.TwoA, '2A'],
    [BaggageClaimPictogramCategory.TwoB, '2B'],
])
