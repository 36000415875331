import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    isImage,
    UploadedAttachment,
} from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'

import { ThemeVariant } from '../../base/ThemeVariant'
import { PreviewableFile } from './PreviewableFile'
import { PreviewablePhoto } from './PreviewablePhoto'

interface PreviewableProps {
    attachment: UploadedAttachment
    variant?: ThemeVariant
    previewable?: boolean
    onDelete?(): void
    onClick(): void
}

export const Previewable: FCWithChildren<PreviewableProps> = ({
    attachment,
    onDelete,
    onClick,
    previewable,
    variant = ThemeVariant.White,
}) => {
    if (isImage(attachment)) {
        return (
            <PreviewablePhoto
                variant={variant}
                previewText={translate('attachment.preview')}
                url={attachment.link}
                onDelete={onDelete}
                onClick={onClick}
                previewable={previewable}
            />
        )
    }
    return (
        <PreviewableFile
            variant={variant}
            previewText={translate('attachment.preview')}
            onDelete={onDelete}
            onClick={onClick}
            attachment={attachment}
            previewable={previewable}
        />
    )
}
