import { createSelector } from 'reselect'

export enum OneTimeKey {
    // Increment it if you want to whats-new be shown again
    CurrentWhatsNew = 'whats-new-14',
}

export interface OneTimeState {
    alreadyShown: OneTimeKey[]
}

export interface OneTimeStateAware {
    oneTime: OneTimeState
}

export const initialOneTimeState: OneTimeState = {
    alreadyShown: [],
}

export const alreadyShownSelector = createSelector(
    (state: OneTimeStateAware) => state.oneTime.alreadyShown,
    alreadyShown => (key: OneTimeKey) => alreadyShown.includes(key),
)
