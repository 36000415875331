import { DashboardItemSizeVariant } from './DashboardItemSizeVariant'

export interface Size {
    w: number
    h: number
}

export interface DashboardWidgetSize {
    minSize: Size
    maxSize: Size
}

export type DashboardWidgetSizes = Partial<Record<DashboardItemSizeVariant, DashboardWidgetSize>>

export const sizeInRange = (e: Size, minSize: Size, maxSize: Size) => {
    const putInRange = (a: number, min: number, max: number) => {
        if (a > max) {
            return max
        } else if (a < min) {
            return min
        } else {
            return a
        }
    }
    return {
        w: putInRange(e.w, minSize.w, maxSize.w),
        h: putInRange(e.h, minSize.h, maxSize.h),
    }
}
