import { Rotating } from 'aos-ui/src/components/base/Rotating'
import React from 'react'

import SpinnerSvg from './assets/large-spinner.svg'

export const LargeSpinner = () => (
    <Rotating reverse>
        <SpinnerSvg style={{ display: 'block' }} />
    </Rotating>
)
