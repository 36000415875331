import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    flightsSelector,
    FlightsVolumesSelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/flights'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { CommonFlightView } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import {
    ExtendedFlightsVolumeFilters,
    FlightsGrouping,
} from 'aos-services/src/services/statusDashboard/types/filters/FlightsVolumesFilters'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setFlightsTabStateAction } from '../../../../core/statusDashboard/actions'
import { FlightsItemState } from '../../../../services/statusDashboard/types/itemStates/FlightsItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { FlightChartStatsWrapper } from '../base/FlightChartStatsWrapper'
import { FlightFooterFilters } from '../base/FlightFooterFilters'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { flightFilterConfigs } from './flightFilterConfig'
import { FlightsChart } from './FlightsChart'
import { FlightsTable } from './FlightsTable'
import { FlightsItem } from './partials/FlightsItem'
import { FlightStatsSection } from './partials/FlightStatsSection'

class FlightsClass extends PureComponent<
    FlightsVolumesSelectorState & FlightsOwnProps & FlightsDispatchProps
> {
    public render() {
        const { itemState } = this.props
        const isSmall = itemState.size === DashboardItemSizeVariant.Small
        return (
            <FlightsItem
                noDataWarning={this.props.noDataWarning}
                itemProps={pickDashboardItemProps(this.props)}
                syncId={this.props.id}
            >
                {isSmall ? this.renderSmallVariant() : this.renderBigVariant()}
                <FlightFooterFilters
                    timeRange={itemState.timeRange}
                    airline={itemState.airline}
                    handlingAgent={itemState.handlingAgent}
                    flightType={itemState.flightType}
                    fullViewTab={itemState.fullViewTab}
                    setFilterAction={this.handleSetFilter}
                    isSmall={isSmall}
                    tabConfiguration={flightFilterConfigs}
                />
            </FlightsItem>
        )
    }

    private renderSmallVariant = () => (
        <ItemContainer verticalSpacing horizontalSpacing autoHeight horizontal>
            <FlightStatsSection stats={this.props.stats} tab={this.props.itemState.flightsStats} />
        </ItemContainer>
    )

    private renderBigVariant() {
        const { flights, itemState } = this.props
        return (
            <FlightChartStatsWrapper
                chart={this.renderChart()}
                statsContent={this.renderStatsContent()}
                flightTable={() => <FlightsTable flights={flights} />}
                flightTableTitle={translate('dashboard.flight-filters.flight-table-header')}
                showChart={itemState.fullViewTab === CommonFlightView.History}
            />
        )
    }

    private renderStatsContent() {
        return (
            <Box row justify='space-between'>
                <Box fullHeight flex='auto' marginRight={40}>
                    <FlightStatsSection stats={this.props.stats} tab={FlightsGrouping.Total} />
                </Box>
                <Box fullHeight flex='auto' marginLeft={16}>
                    <FlightStatsSection stats={this.props.stats} tab={FlightsGrouping.Hourly} />
                </Box>
            </Box>
        )
    }

    private renderChart() {
        return (
            <FlightsChart
                volumes={this.props.volumes}
                volumesForecast={this.props.volumesForecast}
                xDomain={this.props.xDomain}
                yDomain={this.props.yDomain}
                timeRangeFilter={this.props.itemState.timeRange}
            />
        )
    }

    private handleSetFilter = (f: Partial<ExtendedFlightsVolumeFilters>) => {
        this.props.setFlightsTabStateAction([this.props.id, f])
    }
}

interface FlightsDispatchProps {
    setFlightsTabStateAction: typeof setFlightsTabStateAction
}

type FlightsOwnProps = StatusDashboardItemCommonProps<FlightsItemState>

export const Flights = connect<FlightsVolumesSelectorState, FlightsDispatchProps, FlightsOwnProps>(
    flightsSelector,
    {
        setFlightsTabStateAction,
    },
)(FlightsClass)
