import { AppConfig, ExternalConfig } from './ExternalConfig'

export class AppConfigImpl implements AppConfig {
    constructor(
        public externalConfig: ExternalConfig,
        public gitHash: string,
        private gitTag: string,
    ) {}

    get environmentName() {
        return this.externalConfig.environmentName
    }

    get version() {
        return this.gitTag
    }

    get apiUrl() {
        return this.externalConfig.apiUrl
    }

    get mapUrl() {
        return this.externalConfig.mapUrl
    }

    get isLogging() {
        return this.externalConfig.logging
    }

    get firebaseConfig() {
        return this.externalConfig.firebase
    }

    get sentryUrl() {
        return this.externalConfig.sentryUrl
    }

    get iosAppUrl() {
        return this.externalConfig.iosAppUrl
    }

    get androidAppUrl() {
        return this.externalConfig.androidAppUrl
    }

    get origin() {
        return this.externalConfig.originUrl
    }

    get corsProxyUrl() {
        return this.externalConfig.corsProxyUrl
    }

    get analyticsKey() {
        return this.externalConfig.analyticsKey
    }

    get wsOrigin() {
        if (this.origin) {
            return this.origin.replace(/^http/, 'ws')
        }
        return undefined
    }

    get keycloakConfig() {
        return this.externalConfig.keycloakConfig
    }
}
