import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { QueueingTimeSelectorState } from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { QueueingTimePoint } from 'aos-services/src/services/queueingTime/types/QueueingTimePoint'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import {
    QueueingDataTypeFilter,
    translateQueueingDataTypeFilter,
} from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'

import { setQueueingTimesFiltersAction } from '../../../../core/statusDashboard/actions'
import { QueueingTimeBorderItemState } from '../../../../services/statusDashboard/types/itemStates/QueueingTimeBorderItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { Footer } from '../base/Footer'
import { BigItemStatsContainer, ChartItemContainer, ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { QueueingTimeItem } from './partials/QueueingTimeItem'
import { QueueingStatSection } from './QueueingStatSection'
import { QueueingTimeChart } from './QueueingTimeChart'

export class QueueingTime<T extends QueueingTimePoint> extends PureComponent<
    QueueingTimeSelectorState<T> & QueueingTimeOwnProps & QueueingTimeDispatchProps
> {
    public render() {
        const { itemState, type, id } = this.props
        return (
            <QueueingTimeItem
                itemProps={pickDashboardItemProps(this.props)}
                noDataWarning={this.props.noDataWarning}
                type={type}
                syncId={id}
            >
                {itemState.size === DashboardItemSizeVariant.Small
                    ? this.renderSmallVariant()
                    : this.renderBigVariant()}
            </QueueingTimeItem>
        )
    }

    private renderSmallVariant = () => {
        const { stats, config, filters } = this.props
        return (
            <>
                <ItemContainer horizontalSpacing autoHeight horizontal>
                    <QueueingStatSection
                        stats={stats}
                        config={config}
                        filter={filters.queueingTimeDataType}
                    />
                </ItemContainer>
                <Footer>
                    <MultiRoundTabs items={this.tabsConfig} />
                </Footer>
            </>
        )
    }

    private renderBigVariant = () => {
        const { series, yDomain, xDomain, currentTime, stats, config, filters } = this.props
        return (
            <>
                <BigItemStatsContainer>
                    <QueueingStatSection
                        stats={stats}
                        config={config}
                        filter={filters.queueingTimeDataType}
                    />
                </BigItemStatsContainer>
                <ChartItemContainer>
                    <QueueingTimeChart
                        series={series}
                        yDomain={yDomain}
                        xDomain={xDomain}
                        config={config}
                        currentTime={currentTime}
                        filter={filters.queueingTimeDataType}
                    />
                </ChartItemContainer>
                <Footer>
                    <MultiRoundTabs items={this.tabsConfig} />
                </Footer>
            </>
        )
    }

    private get tabsConfig(): RoundTabsProps<number | string>[] {
        return [
            {
                items: EnumValues.getValues(QueueingDataTypeFilter),
                value: this.props.itemState.queueingTimeDataType,
                name: 'queueing-time-range-tab',
                formatter: translateQueueingDataTypeFilter,
                onChange: this.changeTimeRangeTab,
                tooltip: translate('dashboard.queueing-time.filter-tab.tooltip'),
                width: DropdownWidth.Medium,
            },
        ]
    }

    private changeTimeRangeTab = (queueingTimeDataType: QueueingDataTypeFilter) =>
        this.props.setQueueingTimesFiltersAction([this.props.id, { queueingTimeDataType }])
}

export interface QueueingTimeDispatchProps {
    setQueueingTimesFiltersAction: typeof setQueueingTimesFiltersAction
}

export type QueueingTimeOwnProps = StatusDashboardItemCommonProps<QueueingTimeBorderItemState>
