import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { NotamTile } from './NotamTile'

export const notamConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 2, h: 2 },
            maxSize: { w: 6, h: 12 },
        },
    },
    tileRenderer: NotamTile,
}
