import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatOptionalDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FCWithChildren } from 'react'

export interface ByAtTextProps {
    by?: string
    at?: DateTime
}

export const ByAtText: FCWithChildren<ByAtTextProps> = ({ by, at }) => (
    <Box>
        {by && <Box>{`${translate('by-at.by')} ${by}`}</Box>}
        {at && <Box>{`${translate('by-at.at')} ${formatOptionalDateTime(at)}`}</Box>}
    </Box>
)
