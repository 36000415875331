import { mapPageableContent } from 'aos-helpers/src/helpers/Pageable'
import { sortEntryToQuery } from 'aos-helpers/src/helpers/PageRequest'
import { dateTime, dateTimeFromOptional, dateTimeToISOString } from 'aos-helpers/src/helpers/Time'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'

import { isPartOfEnum } from '../../../../aos-helpers/src/helpers/Enum'
import { tasksRestService } from '../../dataaccess/tasks/tasksRestService'
import { TaskDto } from '../../dataaccess/tasks/types/TaskDto'
import { TaskSnapshotDto } from '../../dataaccess/tasks/types/TaskSnapshotDto'
import { AosLocation } from '../common/types/AosLocation'
import { AosSeverity } from '../common/types/AosSeverity'
import { AosAirport } from '../flightInformation/types/AosAirport'
import { TaskUpdateMode } from './types/payload/BaseTaskFormPayload'
import { TaskPayload } from './types/payload/TaskPayload'
import { AnimalSpecie, AnimalsTaskType } from './types/task/AnimalsTask'
import { BaseTask } from './types/task/BaseTask'
import { DeIcingChemicalUsage } from './types/task/DeIcingChemicalUsage'
import { DeIcingSurfaceType } from './types/task/DeIcingSurfaceType'
import { InspectionItem } from './types/task/InspectionItem'
import { MaintenanceJobAssignment } from './types/task/MaintenanceTask'
import { RescueServiceTaskType } from './types/task/RescueServiceTask'
import { Task } from './types/task/Task'
import { TaskRecurrence } from './types/task/TaskRecurrence'
import { TaskCategory } from './types/TaskCategory'
import { TaskLog, TaskLogType } from './types/TaskLog'
import { TaskPageableFilters } from './types/TaskPageable'
import { TaskProcessType } from './types/TaskProcessType'
import { TaskId, TaskSnapshot, toUrlHandle } from './types/TaskSnapshot'
import { TaskSorting, taskSorting, taskSortingMobile } from './types/TaskSorting'
import { TaskStatus, TaskStatusUpdateDto } from './types/TaskStatus'
import { TaskType } from './types/TaskType'

class TasksService {
    public getTasks = (
        { taskFilter, category, pageSize, page, sort }: TaskPageableFilters,
        siteLocation: AosAirport,
    ) => {
        return tasksRestService
            .loadTasks(taskFilter, {
                ...sortEntryToQuery(
                    isPartOfEnum(sort, TaskSorting)
                        ? taskSorting[taskFilter][sort]
                        : taskSortingMobile.find(v => v[0] === sort)![1],
                ),
                siteLocation,
                category,
                size: pageSize,
                page,
            })
            .then(mapPageableContent(this.toFlatTask))
    }

    public getTask = (id: TaskId) =>
        tasksRestService.loadTask(toUrlHandle(id, TaskUpdateMode.Instance)).then(this.toTask)

    public getTaskById = (id: number) => tasksRestService.loadTask(`${id}`).then(this.toTask)

    private removeFieldsFromObject = <T>(obj: T, fields: (keyof T)[]) => {
        const newObj = { ...obj }
        fields.forEach(field => delete newObj[field])
        return newObj
    }

    public createOrUpdateTask = (taskPayload: TaskPayload): Promise<Task | null> => {
        const { id, parentTaskId } = taskPayload.form
        const payload = {
            ...this.removeFieldsFromObject(taskPayload.form, ['status', 'spendTimeInMinutes']),
            taskStatusInput: {
                status: taskPayload.form.status,
                spendTimeInMinutes: taskPayload.form.spendTimeInMinutes,
            },
        }
        if (taskPayload.form.assignments !== undefined) {
            const brushAssignmentIndex = taskPayload.form.assignments.findIndex(
                v => v.brushesVehicle !== undefined,
            )
            const brushMission = taskPayload.form.assignments[brushAssignmentIndex]
            if (
                brushMission?.brushesVehicle?.id === undefined &&
                brushMission?.brushesVehicle?.label === 'Other' &&
                brushMission.newBrushVehicle !== undefined
            ) {
                taskPayload.form.assignments[brushAssignmentIndex] = {
                    ...brushMission,
                    brushesVehicle: {
                        label: brushMission.newBrushVehicle,
                    },
                }
            }
        }
        if (id || parentTaskId) {
            return this.updateTask(payload, { ...taskPayload, form: payload })
        }
        return this.createTask({ ...taskPayload, form: payload })
    }

    public deleteTask = (id: TaskId): Promise<void> =>
        tasksRestService.deleteTask(toUrlHandle(id)).then()

    public deleteTaskById = (id: number): Promise<void> =>
        tasksRestService.deleteTask(`${id}`).then()

    public changeStatus = (id: TaskId, taskStatus: TaskStatusUpdateDto): Promise<void> =>
        tasksRestService.changeStatus(toUrlHandle(id), taskStatus).then()

    public changeStatusById = (id: number, taskStatus: TaskStatusUpdateDto): Promise<void> =>
        tasksRestService.changeStatus(`${id}`, taskStatus).then()

    public sendMessage = (id: TaskId, message: string): Promise<void> =>
        tasksRestService.sendMessage(toUrlHandle(id), message).then()

    public changeTaskChecklistItem = (
        taskId: number,
        checklistId: number,
        itemId: number,
        value: boolean,
    ): Promise<Response> =>
        tasksRestService.changeTaskChecklistItem(taskId, checklistId, itemId, value)

    public changeRecurrentTaskChecklistItem = (
        taskId: number,
        instanceId: number,
        templateId: number,
        seq: number,
        value: boolean,
    ): Promise<TaskDto> =>
        tasksRestService.changeRecurrentTaskChecklistItem(
            taskId,
            instanceId,
            templateId,
            seq,
            value,
        )

    public loadTimelineTasks = (
        range: TimeRange,
        category: TaskCategory[],
        siteLocation: AosAirport,
    ): Promise<TaskSnapshot[]> =>
        tasksRestService
            .loadTimelineTasks({
                startTime: dateTimeToISOString(dateTime(range.timeStart)),
                endTime: dateTimeToISOString(dateTime(range.timeEnd)),
                siteLocation,
                category,
            })
            .then(items => items.map(this.toFlatTask))

    public getRecentTasks = (siteLocation: AosAirport): Promise<TaskSnapshot[]> =>
        tasksRestService //
            .getRecentTasks({ siteLocation })
            .then(items => items.map(this.toFlatTask))

    public getUpcomingTasks = (siteLocation: AosAirport): Promise<TaskSnapshot[]> =>
        tasksRestService
            .getUpcomingTasks({ siteLocation })
            .then(items => items.map(this.toFlatTask))

    protected toFlatTask = (dto: TaskSnapshotDto): TaskSnapshot => ({
        id: dto.id,
        parentTaskId: dto.parentTaskId,
        instanceId: dto.instanceId,
        title: dto.title,
        startTime: dateTime(dto.startTime),
        endTime: dateTimeFromOptional(dto.endTime),
        severity: dto.severity as AosSeverity,
        category: dto.category as TaskCategory,
        assignees: dto.assignees || [],
        location: dto.location as AosLocation,
        status: dto.status as TaskStatus,
        attachmentsLength: dto.attachmentsLength,
        createdAt: dateTime(dto.createdAt),
        createdBy: dto.createdBy,
        hasLocation: dto.hasLocation,
        hasRecurrence: !!dto.parentTaskId,
        overdue: dto.overdue,
        processType: dto.processType as TaskProcessType,

        lastComment: dto.lastComment,
        lastLogTime: dateTimeFromOptional(dto.lastLogTime),
        lastLogType: dto.lastLogType as TaskLogType,
        hasChecklist: dto.hasChecklists,
    })

    protected toTask = (dto: TaskDto): Task | null => {
        const log = dto.log.map(v => ({ ...v, createdAt: dateTime(v.createdAt) })) as TaskLog[]

        const base: BaseTask = {
            id: dto.id,
            parentTaskId: dto.parentTaskId,
            instanceId: dto.instanceId,

            title: dto.title,
            description: dto.description,
            startTime: dateTime(dto.startTime),
            endTime: dateTimeFromOptional(dto.endTime),
            severity: dto.severity as AosSeverity,
            category: dto.category as TaskCategory,
            assignees: dto.assignees || [],
            attachments: dto.attachments,
            location: dto.location as AosLocation,
            createdAt: dateTime(dto.createdAt),
            spendTimeInMinutes: dto.spendTimeInMinutes,
            createdBy: dto.createdBy,
            deleted: dto.deleted,
            status: dto.status as TaskStatus,
            recurrence: dto.recurrence as TaskRecurrence,
            log: log.sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf()),
            processType: dto.processType as TaskProcessType,
            modifiedAt: dateTimeFromOptional(dto.modifiedAt),
            modifiedBy: dto.modifiedBy,
            checklists: dto.checklists?.map(checklist => ({
                ...checklist,
                items: checklist.items.map(item => ({
                    ...item,
                    createdAt: dateTime(item.createdAt),
                    modifiedAt: dateTime(item.modifiedAt),
                })),
            })),
        }

        switch (dto.type) {
            case TaskType.Simple:
                return {
                    type: TaskType.Simple,
                    ...base,
                }

            case TaskType.AnimalPrevention:
                return {
                    type: TaskType.AnimalPrevention,
                    ...base,
                    animalTaskType: dto.animalTaskType as AnimalsTaskType,
                    animalSpecies: dto.animalSpecies as AnimalSpecie,
                    amount: dto.amount,
                    cartridges: dto.cartridges,
                    expulsions: dto.expulsions,
                    horns: dto.horns,
                    lifeStage: dto.lifeStage,
                    amountOfSpikes: dto.amountOfSpikes,
                    gender: dto.gender,
                }

            case TaskType.DeIcing:
                return {
                    type: TaskType.DeIcing,
                    ...base,
                    temperature: dto.temperature,
                    area: dto.area,
                    surfaceType: dto.surfaceType as DeIcingSurfaceType,
                    car: dto.car,
                    chemicalsUsage: (dto.chemicalsUsage as DeIcingChemicalUsage[]) || [],
                }

            case TaskType.Maintenance:
                return {
                    type: TaskType.Maintenance,
                    billable: dto.billable,
                    assignments: dto.assignments.map(
                        assignmentDto => assignmentDto as MaintenanceJobAssignment,
                    ),
                    contractors: dto.contractors.map(c => ({
                        id: c.id,
                        name: c.name,
                        contractTime: dateTime(c.contractTime),
                        jobEnd: dateTime(c.jobEnd),
                        jobStart: dateTime(c.jobStart),
                        numberOfWorkers: c.numberOfWorkers,
                    })),
                    ...base,
                }

            case TaskType.RescueService:
                return {
                    type: TaskType.RescueService,
                    ...base,
                    taskType: dto.taskType as RescueServiceTaskType,
                    units: dto.units,
                    aircraftRegistration: dto.aircraftRegistration,
                    address: dto.address,
                    additionalUnits: dto.additionalUnits,
                }

            case TaskType.Inspection:
                return {
                    type: TaskType.Inspection,
                    ...base,
                    items: dto.items as InspectionItem[],
                }
        }

        return null
    }

    private createTask = ({ form, ...payload }: TaskPayload): Promise<Task | null> =>
        tasksRestService.createTask({ ...form, ...payload }).then(this.toTask)

    private updateTask = (
        taskId: TaskId,
        { form: { id, parentTaskId, instanceId, updateMode, ...payload }, ...rest }: TaskPayload,
    ): Promise<Task | null> =>
        tasksRestService
            .updateTask(toUrlHandle(taskId, updateMode), { ...payload, ...rest })
            .then(this.toTask)
}

export const tasksService = new TasksService()
