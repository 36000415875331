import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { enumToLabel } from 'aos-helpers/src/helpers/Enum'
import { translateFirst } from 'aos-helpers/src/helpers/translations/Translations'
import {
    FlightNatureCode,
    getColorForFlightNature,
} from 'aos-services/src/services/flightInformation/types/AosNatureOfFlight'
import { AosNatureOfFlight, Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent } from 'react'

interface NatureOfFlightCellProps extends ClassNameProps {
    row: Flight
}

export class NatureOfFlightCell extends PureComponent<NatureOfFlightCellProps> {
    public render() {
        const { naflt } = this.props.row

        if (!naflt) {
            return null
        }

        if (naflt.code) {
            return (
                <Tooltip body={this.tooltipBody(naflt.code)} withArrow>
                    {this.renderCell(naflt)}
                </Tooltip>
            )
        }

        return this.renderCell(naflt)
    }

    private renderCell(naflt: AosNatureOfFlight) {
        const style = { backgroundColor: getColorForFlightNature(naflt.code) }
        const cellClass = this.props.className ? '' : 'flight-table-row--nature-of-flight'

        return (
            <Box className={cxp(this.props, cellClass)} style={style}>
                {naflt.value}
            </Box>
        )
    }

    private tooltipBody(code: FlightNatureCode) {
        const additionalKeys =
            this.props.row.flightType === FlightListType.Arrivals
                ? [enumToLabel(FlightNatureCode, 'flight-information.naflt.arrivals')(code)]
                : []

        return translateFirst([
            ...additionalKeys,
            enumToLabel(FlightNatureCode, 'flight-information.naflt')(code),
        ])
    }
}
