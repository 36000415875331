import { stack } from 'd3-shape'

export interface LinearStackBarSeriesAccessors<T, TX, Key extends string | number> {
    xAccessor: f.Func1<T, TX>
    yAccessorKeys: Key[]
    yAccessor?: (point: T, key: Key) => number
}

export const transformToStackData = <T, TX, Key extends string | number>(
    data: T[],
    accessors: LinearStackBarSeriesAccessors<T, TX, Key>,
) => {
    const stackGenerator = stack<T, Key>().keys(accessors.yAccessorKeys)
    if (accessors.yAccessor) {
        stackGenerator.value(accessors.yAccessor)
    }

    return {
        stackedSeriesData: stackGenerator(data),
    }
}
