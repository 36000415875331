import {
    PunctualitySelectorState,
    punctualityTileSelector,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/punctuality'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PunctualityItem } from './partials/PunctualityItem'
import { PunctualityStatsSection } from './partials/PunctualityStatsSection'

type PunctualityTileOwnProps = StatusDashboardItemCommonProps

interface PunctualityTileProps extends PunctualitySelectorState, PunctualityTileOwnProps {}

export const PunctualityTileComponent: FC<PunctualityTileProps> = props => {
    const link = generatePath(Link.StatusDashboardPreset, {
        preset: DashboardProcessType.Operations,
    })

    return (
        <PunctualityItem
            noDataWarning={props.noDataWarning}
            itemProps={pickDashboardItemProps(props)}
            link={link}
            syncId='overview'
        >
            <TileContainer>
                <PunctualityStatsSection stats={props.stats[FlightsType.All]} />
            </TileContainer>
        </PunctualityItem>
    )
}

export const PunctualityTile = connect<PunctualitySelectorState>(punctualityTileSelector)(
    PunctualityTileComponent,
)
