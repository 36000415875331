import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { ArrivalsAndDepartures } from 'aos-services/src/services/flightInformation/types/ArrivalsAndDepartures'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import React, { PureComponent } from 'react'

import { ArrivalFlightItem } from './ArrivalFlightItem'
import { DepartureFlightItem } from './DepartureFlightItem'

export class FlightTablePreview extends PureComponent<
    FlightTablePreviewProps,
    FlightTablePreviewState
> {
    public state: FlightTablePreviewState = {
        type: this.props.flightSections[0],
    }

    public render() {
        return (
            <Box className='flight-table-preview' column>
                <Box shrink={0} paddingBottom={20}>
                    <UnderlineTabs
                        items={this.props.flightSections}
                        value={this.state.type}
                        onChange={this.setFlightsSection}
                        nameFormatter={translateEnum<FlightListType>(
                            FlightListType,
                            'flight-information.section',
                        )}
                        stretch
                        variant={ThemeVariant.Black}
                    />
                </Box>
                <Box flex={1}>
                    {this.state.type === FlightListType.Arrivals && this.renderArrivals()}
                    {this.state.type === FlightListType.Departures && this.renderDepartures()}
                </Box>
            </Box>
        )
    }

    private setFlightsSection = (type: FlightListType) => {
        this.setState({ type })
    }

    private renderArrivals = () => {
        const { arrivals } = this.props

        return arrivals.length === 0 ? (
            <PanelPlaceholder
                label={translate('flight-information.arrivals.placeholder')}
                svg={SvgIcon.ArrivalsPlaceholder}
            />
        ) : (
            arrivals.map(this.renderArrivalItem)
        )
    }

    private renderDepartures = () => {
        const { departures } = this.props

        return departures.length === 0 ? (
            <PanelPlaceholder
                label={translate('flight-information.departures.placeholder')}
                svg={SvgIcon.DeparturesPlaceholder}
            />
        ) : (
            departures.map(this.renderDepartureItem)
        )
    }

    private renderArrivalItem = (item: Flight) => <ArrivalFlightItem item={item} key={item.id} />

    private renderDepartureItem = (item: Flight) => (
        <DepartureFlightItem item={item} key={item.id} />
    )
}

interface FlightTablePreviewState {
    type: FlightListType
}

export interface FlightTablePreviewProps extends ArrivalsAndDepartures {
    flightSections: FlightListType[]
}
