import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { findIndex } from 'lodash'
import { arrayMove } from 'react-sortable-hoc'

import { initialEventChecklistManagerState } from '../state'
import {
    CHANGE_CHECKLIST_TEMPLATE_SEQ,
    EDIT_CHECKLIST_TEMPLATE,
    EventChecklistManagerActions,
    HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL,
    HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL,
    LOAD_CHECKLIST_TEMPLATES_SUCCESS,
    SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL,
} from './actions'

export const eventChecklistReducer = (
    state = initialEventChecklistManagerState,
    action: EventChecklistManagerActions,
) => {
    switch (action.type) {
        case LOAD_CHECKLIST_TEMPLATES_SUCCESS:
            return {
                ...initialEventChecklistManagerState,
                checklistTemplates: action.payload,
            }
        case SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isCreateChecklistTemplateModalOpen: true,
            }
        case HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                isCreateChecklistTemplateModalOpen: false,
            }
        case EDIT_CHECKLIST_TEMPLATE:
            return {
                ...state,
                editedChecklistTemplate: {
                    id: action.payload.id,
                    name: action.payload.name,
                    tag: action.payload.tag ?? '',
                },
            }
        case HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL:
            return {
                ...state,
                editedChecklistTemplate: undefined,
            }
        case CHANGE_CHECKLIST_TEMPLATE_SEQ:
            return {
                ...state,
                checklistTemplates: reorderChecklistTemplates(
                    state.checklistTemplates,
                    action.payload,
                ),
            }
        default:
            return state
    }
}

const reorderChecklistTemplates = (
    checklistTemplates: AosSimpleTemplate[],
    changeSeqVector: ChangeSeqVector,
) => {
    const fromIndex = findIndex(
        checklistTemplates,
        t => t.sequenceNumber === changeSeqVector.fromId,
    )
    const toIndex = findIndex(checklistTemplates, t => t.sequenceNumber === changeSeqVector.toId)

    return arrayMove(checklistTemplates, fromIndex, toIndex)
}
