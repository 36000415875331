import { useInterval } from 'aos-ui-common/src/components/hooks/useInterval'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import useToggle from 'react-use/lib/useToggle'

import { Spinner } from './Spinner'

interface WaitForItProps {
    ms: number
    placeholder?: ReactNode
}

export const WaitForIt: FCWithChildren<PropsWithChildren<WaitForItProps>> = props => {
    const { ms, children, placeholder = <Spinner /> } = props
    const [timeout, toggleTimeout] = useToggle(false)

    useInterval(
        () => {
            toggleTimeout()
        },
        timeout ? null : ms,
    )

    if (!timeout) {
        return <>{placeholder}</>
    }

    return <>{children}</>
}
