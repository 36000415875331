import { isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosAirport,
    locationName,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    FilterOptionAll,
    filterOptionsWithAll,
} from 'aos-services/src/services/statusDashboard/types/filters/common'

export type ReportAirport = FilterOptionAll | AosAirport

export const reportAirportValues: ReportAirport[] = filterOptionsWithAll(AosAirport)

export const translateReportAirport = (value: ReportAirport): string =>
    isValidEnumEntry(AosAirport)<AosAirport>(value)
        ? locationName(value)
        : translateEnum(FilterOptionAll, 'tasks.reports.airport')(value)
