import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Pageable } from '../../../../aos-helpers/src/helpers/Pageable'
import { FirefighterFormFields } from '../../services/tasks/firefighters/types/FirefighterFormFields'
import { FirefighterShift } from '../../services/tasks/firefighters/types/FirefighterShift'
import { FireTruck } from '../../services/tasks/types/task/FireTruck'

interface FirefightersShiftFormPayloadPayload {
    data: FirefighterFormFields
    onSuccess: () => void
    onError?: () => void
}

export const FIREFIGHTER_SYNC = 'FIREFIGHTER/SYNC'
export type FirefighterSyncAction = PayloadAction<typeof FIREFIGHTER_SYNC, string>
export const firefighterSyncAction = payloadActionCreator<FirefighterSyncAction, string>(
    FIREFIGHTER_SYNC,
)

export const FIREFIGHTER_STOP_SYNC = 'FIREFIGHTER/STOP_SYNC'
export type FirefighterStopSyncAction = PayloadAction<typeof FIREFIGHTER_STOP_SYNC, string>
export const firefighterStopSyncAction = payloadActionCreator<FirefighterStopSyncAction, string>(
    FIREFIGHTER_STOP_SYNC,
)

export const FIREFIGHTERS_ADD_SHIFT = 'FIREFIGHTERS/ADD_SHIFT'
export type FirefightersAddShiftAction = PayloadAction<
    typeof FIREFIGHTERS_ADD_SHIFT,
    FirefightersShiftFormPayloadPayload
>
export const firefightersAddShiftAction = payloadActionCreator<
    FirefightersAddShiftAction,
    FirefightersShiftFormPayloadPayload
>(FIREFIGHTERS_ADD_SHIFT)

export const FIREFIGHTERS_ADD_SHIFT_SUCCESS = 'FIREFIGHTERS/ADD_SHIFT_SUCCESS'
export type FirefightersAddShiftSuccessAction = Action<typeof FIREFIGHTERS_ADD_SHIFT_SUCCESS>
export const firefightersAddShiftSuccessAction =
    emptyActionCreator<FirefightersAddShiftSuccessAction>(FIREFIGHTERS_ADD_SHIFT_SUCCESS)

export const FIREFIGHTER_UPDATE_SHIFT = 'FIREFIGHTER/UPDATE_SHIFT'
export type FirefighterUpdateShiftAction = PayloadAction<
    typeof FIREFIGHTER_UPDATE_SHIFT,
    FirefightersShiftFormPayloadPayload
>
export const firefighterUpdateShiftAction = payloadActionCreator<
    FirefighterUpdateShiftAction,
    FirefightersShiftFormPayloadPayload
>(FIREFIGHTER_UPDATE_SHIFT)

export const FIREFIGHTER_UPDATE_SHIFT_SUCCESS = 'FIREFIGHTER/UPDATE_SHIFT_SUCCESS'
export type FirefighterUpdateShiftSuccessAction = Action<typeof FIREFIGHTER_UPDATE_SHIFT_SUCCESS>
export const firefighterUpdateShiftSuccessAction =
    emptyActionCreator<FirefighterUpdateShiftSuccessAction>(FIREFIGHTER_UPDATE_SHIFT_SUCCESS)

export const FIRETRUCKS_LOAD = 'FIRETRUCKS/LOAD'
export type FiretrucksLoadAction = Action<typeof FIRETRUCKS_LOAD>
export const firetrucksLoadAction = emptyActionCreator<FiretrucksLoadAction>(FIRETRUCKS_LOAD)

export const FIRETRUCKS_LOAD_SUCCESS = 'FIRETRUCKS/LOAD_SUCCESS'
export type FiretrucksLoadSuccessAction = PayloadAction<typeof FIRETRUCKS_LOAD_SUCCESS, FireTruck[]>
export const firetruckLoadSuccessAction = payloadActionCreator<
    FiretrucksLoadSuccessAction,
    FireTruck[]
>(FIRETRUCKS_LOAD_SUCCESS)

export const FIREFIGHTERS_LOAD_SHIFT = 'FIREFIGHTERS/LOAD_SHIFT'
export type FirefightersLoadShiftAction = Action<typeof FIREFIGHTERS_LOAD_SHIFT>
export const firefightersLoadShiftAction =
    emptyActionCreator<FirefightersLoadShiftAction>(FIREFIGHTERS_LOAD_SHIFT)

export const FIREFIGHTERS_LOAD_SHIFT_SUCCESS = 'FIREFIGHTERS/LOAD_SHIFT_SUCCESS'
export type FirefightersLoadShiftSuccessAction = PayloadAction<
    typeof FIREFIGHTERS_LOAD_SHIFT_SUCCESS,
    Pageable<FirefighterShift>
>
export const firefighterLoadShiftsSuccessAction = payloadActionCreator<
    FirefightersLoadShiftSuccessAction,
    Pageable<FirefighterShift>
>(FIREFIGHTERS_LOAD_SHIFT_SUCCESS)

export type BaseFirefighterAction =
    | FirefightersAddShiftAction
    | FirefightersAddShiftSuccessAction
    | FirefighterUpdateShiftAction
    | FirefighterUpdateShiftSuccessAction
    | FiretrucksLoadAction
    | FiretrucksLoadSuccessAction
    | FirefightersLoadShiftAction
    | FirefightersLoadShiftSuccessAction
    | FirefighterSyncAction
    | FirefighterStopSyncAction
