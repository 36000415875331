import { ActionModalMode } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import {
    AOS_USER_EXISTS,
    AOS_USER_EXISTS_NOT_FOUND_IN_AD,
    CONTACT_EXISTS,
    USER_FOUND_IN_AD,
    USER_OR_GROUP_NOT_FOUND,
    USER_REMOVED,
} from 'aos-services/src/services/users/types/AosUserFindStatus'
import React, { PureComponent } from 'react'

import { UserError } from './UserError'
import { UserExists } from './UserExists'
import { UserFound } from './UserFound'

export class ContactStatus extends PureComponent<ContactStatusProps> {
    public render() {
        const { user, goBack, addAsAdUser, hide } = this.props
        const { userStatus } = user

        switch (userStatus) {
            case USER_FOUND_IN_AD:
                return (
                    <UserFound
                        email={this.props.user.email!}
                        back={goBack}
                        addAdUser={addAsAdUser}
                    />
                )
            case AOS_USER_EXISTS:
                return (
                    <UserExists
                        email={this.props.user.email!}
                        back={goBack}
                        description={translate('manage-user.contact.user-exists')}
                        userActionLabel={translate('manage-user.contact.user-exists.edit-ad-user')}
                        userAction={this.props.editAdUser}
                    />
                )
            case CONTACT_EXISTS:
                return (
                    <UserExists
                        email={this.props.user.email!}
                        back={goBack}
                        description={translate('manage-user.contact.contact-exists')}
                        userActionLabel={translate(
                            'manage-user.contact.contact-exists.edit-contact',
                        )}
                        userAction={this.props.editContact}
                    />
                )
            case AOS_USER_EXISTS_NOT_FOUND_IN_AD:
                return (
                    <UserExists
                        email={this.props.user.email!}
                        description={translate('manage-user.ad-user.user-exists-gone-in-ldap')}
                        userActionLabel={translate(
                            'manage-user.ad-user.user-exists-gone-in-ldap.remove',
                        )}
                        userAction={this.props.removeAdUser}
                        mode={ActionModalMode.Important}
                    />
                )
            case USER_REMOVED:
                return (
                    <UserError
                        email={this.props.user.email!}
                        description={translate('manage-user.user-removed')}
                        hide={hide}
                    />
                )
            case USER_OR_GROUP_NOT_FOUND:
                return (
                    <UserError
                        email={this.props.user.email!}
                        description={translate('manage-user.group-not-found')}
                        hide={hide}
                    />
                )
            default:
                return (
                    <span>
                        {translate(
                            `manage-user.status.${this.props.user.userStatus!.toLowerCase()}`,
                        )}
                    </span>
                )
        }
    }
}

export interface ContactStatusProps {
    user: AdUserFormData
    goBack(): void
    hide(): void
    addAsAdUser(): void
    removeAdUser?(): void
    editAdUser?(): void
    editContact?(): void
}
