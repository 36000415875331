import { optionalText } from 'aos-helpers/src/helpers/Text'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { BoxProps, NumberValues } from 'aos-ui/src/components/base/Box'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import {
    LabeledTextElement,
    LabeledTextElementSize,
} from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import React, { FCWithChildren, ReactNode } from 'react'

import { ThemeVariant } from '../base/ThemeVariant'

type Item = ReactNode | undefined | false

export type PropsListEntry = [string, Item]

export interface PropsListProps extends BoxProps {
    items: PropsListEntry[]
    variant?: ThemeVariant
    size?: LabeledTextElementSize
    spacing?: NumberValues
    reversed?: boolean
}

export const PropsList: FCWithChildren<PropsListProps> = ({
    variant,
    items,
    size,
    spacing = 16,
    reversed,
    ...boxProps
}) => (
    <SeparatedContainer spacing={spacing} {...boxProps}>
        {items.map((t: PropsListEntry, index: number) => (
            <LabeledTextElement
                key={index}
                size={size}
                label={translate(t[0])}
                variant={variant}
                reversed={reversed}
            >
                {optionalText(t[1] as string)}
            </LabeledTextElement>
        ))}
    </SeparatedContainer>
)
