import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { PropsWithChildren, PureComponent } from 'react'

export class ScheduledRerender extends PureComponent<PropsWithChildren<ScheduledRerenderProps>> {
    private updateHandler: ReturnType<typeof setTimeout> | undefined

    public render() {
        return this.props.children
    }

    public componentDidMount() {
        this.setupRerenderHandler(this.props)
    }

    public componentWillReceiveProps(next: ScheduledRerenderProps) {
        if (next.updateTime !== this.props.updateTime) {
            this.setupRerenderHandler(next)
        }
    }

    private setupRerenderHandler = (props: ScheduledRerenderProps) => {
        if (this.updateHandler !== undefined) {
            clearTimeout(this.updateHandler)
            this.updateHandler = undefined
        }
        const timeDiff = props.updateTime
            ? dateTime(props.updateTime).valueOf() - dateTime().valueOf()
            : 0
        if (timeDiff > 0) {
            this.updateHandler = setTimeout(() => this.props.updateCallback(), timeDiff)
        }
    }
}

interface ScheduledRerenderProps {
    updateTime?: number | DateTime
    updateCallback(): void
}
