import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import {
    FallbackInvitationStatus,
    InvitationRole,
    invitationRoleOrder,
    InvitationStatus,
} from './types/InvitationStatus'
import {
    isInvitationAccepted,
    isStatusSelected,
    PrivateChannelParticipant,
} from './types/PrivateChannelParticipant'

export enum EtaStatus {
    NotSpecified = 'NotSpecified',
    Specified = 'Specified',
    SpecifyAgain = 'SpecifyAgain',
}

export enum PrivateChannelState {
    Invitation,
    PrivateChannelStatus,
    PrivateChannelAdded,
    PrivateChannelNotAdded,
    PrivateChannelEtaRequired,
}

export const getPrivateChannelState = (
    me: PrivateChannelParticipant | undefined,
    etaStatus: EtaStatus,
    privateChannelAdded?: boolean,
) => {
    const hasAccepted = me && isInvitationAccepted(me)
    const hasStatus = me && isStatusSelected(me)

    if (!privateChannelAdded) {
        return PrivateChannelState.PrivateChannelNotAdded
    } else if (me && !hasAccepted) {
        return PrivateChannelState.Invitation
    } else if (me && hasAccepted && !hasStatus) {
        return PrivateChannelState.PrivateChannelStatus
    } else {
        if (StatusWithEta.includes(me?.invitationStatus as InvitationStatus)) {
            return etaStatus === EtaStatus.Specified
                ? PrivateChannelState.PrivateChannelAdded
                : PrivateChannelState.PrivateChannelEtaRequired
        }
        return PrivateChannelState.PrivateChannelAdded
    }
}

export const sortParticipants = (participants: PrivateChannelParticipant[]) =>
    participants.sort((a, b) => {
        const roleA = invitationRoleOrder[a.invitationRole] || 4
        const roleB = invitationRoleOrder[b.invitationRole] || 4
        return roleA - roleB
    })

export const invitationRoleToLabel = (role: InvitationRole) =>
    ({
        [InvitationRole.Pending]: translate(
            'mobile.event.private-channel.invitation-status.pending',
        ),
        [InvitationRole.Observe]: translate(
            'mobile.event.private-channel.invitation-status.follower',
        ),
        [InvitationRole.Command]: translate(
            'mobile.event.private-channel.invitation-status.commander',
        ),
        [InvitationRole.Participate]: translate(
            'mobile.event.private-channel.invitation-status.participate',
        ),
        [InvitationRole.Left]: translate('mobile.event.private-channel.invitation-status.left'),
    }[role || InvitationRole.Pending])

function translateRole(role: InvitationRole) {
    return translateEnum<InvitationRole>(
        InvitationRole,
        'private-channel.list-description.role',
    )(role)
}

function translateFallbackStatus(status: FallbackInvitationStatus) {
    return translateEnum<FallbackInvitationStatus>(
        FallbackInvitationStatus,
        'private-channel.list-description.fallback-status',
    )(status)
}

function translateStatus(status: InvitationStatus) {
    return translateEnum<InvitationStatus>(
        InvitationStatus,
        'private-channel.list-description.status',
    )(status)
}

const observeLeft = [InvitationRole.Observe, InvitationRole.Left]
const commandParticipate = [InvitationRole.Command, InvitationRole.Participate]
export const CommandObserveParticipate = [
    InvitationRole.Command,
    InvitationRole.Observe,
    InvitationRole.Participate,
]

export const roleStatusSelector = (
    role: InvitationRole,
    status: InvitationStatus | FallbackInvitationStatus,
    timePassed: boolean,
) => {
    if (isFallbackStatus(status)) {
        return translateFallbackStatus(status)
    }
    if (observeLeft.includes(role) || status === InvitationStatus.Skip || (role && !status)) {
        return translateRole(role)
    }
    if (commandParticipate.includes(role)) {
        return `${translateRole(role)} ${
            timePassed && status === InvitationStatus.Coming
                ? translate('private-channel.status-description.at-airport')
                : `| ${translateStatus(status)}`
        }`
    }

    return translateRole(InvitationRole.Pending)
}

export const isFallbackStatus = (
    status: InvitationStatus | FallbackInvitationStatus,
): status is FallbackInvitationStatus => {
    return Object.values(FallbackInvitationStatus).includes(status as FallbackInvitationStatus)
}

export const StatusWithEta = [InvitationStatus.Coming]
