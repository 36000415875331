import { Color } from 'aos-ui-common/src/styles/Color'

export const paxForecastRangesEdges = [65000, 75000]

export const getPaxForecastColor = (value: number) => {
    if (value < paxForecastRangesEdges[0]) {
        return Color.Primary
    }
    if (value <= paxForecastRangesEdges[1]) {
        return Color.Yellow
    }
    return Color.Red
}
