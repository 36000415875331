import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisBase } from './AxisBase'

const axisTickSize = 10

class AxisTicksComponent<T extends AxisDomain> extends AxisBase<T> {
    protected gElementClass: string = 'axis--ticks'

    protected get elementTranslate() {
        const { margins } = this.props
        const moveY = Math.max(margins.top, 0)

        return `translate(${0} ${moveY})`
    }

    protected renderAxisLine = () => null
    protected renderUnit = () => null

    protected renderTick = (tick: T, index: number) => {
        const { scale } = this.props

        const { tickClass, tickSize } = this.getTickConfig(tick, {
            defaultPadding: 0,
            defaultTickSize: axisTickSize,
        })

        const tickOffset = scale.bandwidth ? scale.bandwidth() / 2 : 0
        const xPos = (scale(tick) || 0) + tickOffset

        return (
            <g className={tickClass} key={index} transform={`translate(${xPos} ${0})`}>
                <line y2={tickSize} />
            </g>
        )
    }
}

export const AxisTicks = withChartContext(AxisTicksComponent)
