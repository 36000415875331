import { singleInputModalReducer } from 'aos-components/src/components/modal/InputModal/core/singleInput/reducer'

import {
    ADD_AD_USER_PARENT,
    ADD_CONTACT_PARENT,
    APPLY_USERS_FILTER,
    CLOSE_NOTIFICATION_SETTINGS_MODAL,
    CREATE_GROUP_PARENT,
    EDIT_AD_USER_PARENT,
    EDIT_CONTACT_PARENT,
    EDIT_NOTIFICATIONS_SETTINGS_MODAL,
    GroupManagerAction,
    LOAD_GROUPS_SUCCESS,
    LOAD_USERS_SUCCESS,
    RELOAD_GROUPS_SUCCESS,
    RENAME_USER_GROUP,
    SAVE_NOTIFICATIONS_SETTINGS,
    SAVE_NOTIFICATIONS_SETTINGS_SUCCESS,
    SELECT_GROUP,
    SELECT_USER,
    SET_USER_GROUP_EXPORTING,
    TOGGLE_SYSTEM_GROUPS_EXPANDED,
} from './actions'
import { adUserReducer } from './manageUser/adUser/reducer'
import { contactReducer } from './manageUser/contact/reducer'
import {
    emptyUserPageable,
    GroupManagerState,
    initialGroupManagerState,
    initialUserFilter,
} from './state'

export function groupManagerReducer(
    state = initialGroupManagerState,
    action: GroupManagerAction,
): GroupManagerState {
    switch (action.type) {
        case LOAD_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload,
                selectedUser: undefined,
                selectedGroup: undefined,
                users: emptyUserPageable,
            }
        case RELOAD_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload[0],
                selectedGroup: action.payload[1],
                selectedUser: undefined,
            }

        case SELECT_GROUP:
            return {
                ...state,
                users: { ...state.users, filter: initialUserFilter },
                selectedGroup: action.payload,
                selectedUser: undefined,
            }

        case APPLY_USERS_FILTER:
            return {
                ...state,
                users: { ...state.users, filter: { searchUserText: action.payload } },
                selectedUser: undefined,
            }

        case LOAD_USERS_SUCCESS:
            return {
                ...state,
                users: { ...state.users, data: action.payload },
            }

        case SELECT_USER:
            return {
                ...state,
                selectedUser: action.payload,
            }

        case CREATE_GROUP_PARENT:
            return {
                ...state,
                createGroupState: singleInputModalReducer(state.createGroupState, action.payload),
            }

        case RENAME_USER_GROUP:
            return {
                ...state,
                renameUserGroupState: singleInputModalReducer(
                    state.renameUserGroupState,
                    action.payload,
                ),
            }

        case EDIT_NOTIFICATIONS_SETTINGS_MODAL:
            return {
                ...state,
                showNotificationSettingsModal: true,
                notificationSettingsGroup: action.payload,
            }

        case CLOSE_NOTIFICATION_SETTINGS_MODAL:
            return {
                ...state,
                showNotificationSettingsModal: false,
                notificationSettingsGroup: null,
            }

        case SAVE_NOTIFICATIONS_SETTINGS:
            return {
                ...state,
                notificationSettingsSaveInProgress: true,
            }

        case SAVE_NOTIFICATIONS_SETTINGS_SUCCESS:
            return {
                ...state,
                notificationSettingsSaveInProgress: false,
            }

        case ADD_AD_USER_PARENT:
            return {
                ...state,
                addAdUserState: adUserReducer(state.addAdUserState, action.payload),
            }

        case ADD_CONTACT_PARENT:
            return {
                ...state,
                addContactState: contactReducer(state.addContactState, action.payload),
            }

        case EDIT_AD_USER_PARENT:
            return {
                ...state,
                editAdUserState: adUserReducer(state.editAdUserState, action.payload),
            }

        case EDIT_CONTACT_PARENT:
            return {
                ...state,
                editContactState: contactReducer(state.editContactState, action.payload),
            }

        case SET_USER_GROUP_EXPORTING:
            return {
                ...state,
                userGroupExporting: action.payload,
            }

        case TOGGLE_SYSTEM_GROUPS_EXPANDED:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isSystemGroupsSectionExpanded: !state.ui.isSystemGroupsSectionExpanded,
                },
            }

        default:
            return state
    }
}
