import { mapRecordValues } from 'aos-helpers/src/helpers/Record'
import { isApocSelector } from 'aos-services/src/core/auth/state'
import {
    availableItemTypesSelector,
    dashboardHealthsSelector,
    dashboardStatusesSelector,
} from 'aos-services/src/core/statusDashboard/selectors'
import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import {
    DashboardItemStatuses,
    sortItemTypesBySeverity,
} from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { WidgetHealths } from 'aos-services/src/services/widgetHealths/type/DashboardItemHealths'
import { compact, toPairs, uniq } from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'

import { createMatchSelector } from '../../../helpers/old-lib-migrations/connected-react-router'
import {
    allStatusDashboardTabs,
    DashboardProcessType,
    isPresetTab,
    RegularDashboardPresetWithTab,
    StatusDashboardOverviewTab,
    StatusDashboardTab,
} from '../../services/statusDashboard/types/DashboardPreset'
import { Link, StatusDashboardLinkParams } from '../Links'
import { State } from '../state'
import { dashboardItemGroups, defaultPresets } from './presets'
import { StatusDashboardStateAware } from './state'

const urlPresetSelector = createMatchSelector<State, StatusDashboardLinkParams>(
    Link.StatusDashboardPreset,
)

const isSidebarOpenSelector = (state: StatusDashboardStateAware) =>
    state.statusDashboard.isSidebarOpen
export const carouselTriggerSelector = (state: StatusDashboardStateAware) =>
    state.statusDashboard.carouselTrigger

const dashboardPresetsSelector = createSelector(
    (state: StatusDashboardStateAware) => state.statusDashboard.presets,
    availableItemTypesSelector,
    (preset, available) =>
        mapRecordValues(preset, items => items.filter(i => available.includes(i.state.type))),
)

export const defaultPresetsSelector = createSelector(
    () => defaultPresets,
    availableItemTypesSelector,
    (preset, available) =>
        mapRecordValues(preset, items => items.filter(i => available.includes(i.state.type))),
)

export interface DashboardTabStatus {
    count: number
    mode: DashboardItemMode
}

const tabStatusForTabTypes = (
    allStatuses: DashboardItemStatuses,
    tabTypes: DashboardItemType[],
): DashboardTabStatus => {
    const statuses = compact(tabTypes.map(type => allStatuses[type]))
    const warningsAndAlerts = statuses.filter(
        item =>
            item.warning &&
            (item.status === DashboardItemMode.Warning || item.status === DashboardItemMode.Alert),
    )
    const hasAlert = warningsAndAlerts.some(item => item.status === DashboardItemMode.Alert)
    return {
        count: warningsAndAlerts.length,
        mode: hasAlert ? DashboardItemMode.Alert : DashboardItemMode.Warning,
    }
}

const currentDashboardTabSelector = createSelector(
    dashboardPresetsSelector,
    urlPresetSelector,
    (presets, url): StatusDashboardTab => {
        const urlPreset = url && (url.params.preset as StatusDashboardTab)

        if (!urlPreset) {
            return StatusDashboardOverviewTab.Overview
        }

        if (urlPreset in presets) {
            return urlPreset
        }

        if (urlPreset in StatusDashboardOverviewTab) {
            return urlPreset as StatusDashboardOverviewTab
        }

        return StatusDashboardOverviewTab.Overview
    },
)

const currentDashboardPresetSelector = createSelector(
    currentDashboardTabSelector,
    dashboardPresetsSelector,
    (tab, presets): RegularDashboardPresetWithTab | null => {
        if (!isPresetTab(tab)) {
            return null
        }
        return { tab, items: presets[tab] }
    },
)

const overviewDashboardItemsSelector = createSelector(
    dashboardStatusesSelector,
    availableItemTypesSelector,
    (statuses, available) => sortItemTypesBySeverity(available, statuses),
)

const dashboardTabStatusSelector = createSelector(
    dashboardPresetsSelector,
    dashboardStatusesSelector,
    availableItemTypesSelector,
    (presets, allStatuses, availableTypes): DashboardHeaderStates => {
        const dashboardTabToItemTypes = (tab: StatusDashboardTab): DashboardItemType[] =>
            tab === StatusDashboardOverviewTab.Overview
                ? availableTypes
                : uniq(presets[tab].map(item => item.state.type))

        return allStatusDashboardTabs
            .map(t => [t, dashboardTabToItemTypes(t)] as [StatusDashboardTab, DashboardItemType[]])
            .filter(([_, itemTypes]) => itemTypes.length > 0)
            .map(([tab, tabTypes]) => {
                const tabStatus = tabStatusForTabTypes(allStatuses, tabTypes)
                return [tab, tabStatus] as [StatusDashboardTab, DashboardTabStatus]
            })
    },
)

const availablePresetTypesSelector = createSelector(
    availableItemTypesSelector,
    () => dashboardItemGroups,
    (available, groups) =>
        toPairs(groups)
            .map(
                ([type, items]) =>
                    [type, items.filter(i => available.includes(i))] as [
                        DashboardProcessType,
                        DashboardItemType[],
                    ],
            )
            .filter(([_, items]) => items.length > 0),
)

export interface StatusDashboardSelectorState {
    tab: StatusDashboardTab
    preset: RegularDashboardPresetWithTab | null
    overviewItems: DashboardItemType[]
    isApoc: boolean
    isSidebarOpen: boolean
    statuses: DashboardItemStatuses
    healths: WidgetHealths
    headerTabStates: DashboardHeaderStates
    availablePresetTypes: [DashboardProcessType, DashboardItemType[]][]
}

export type DashboardHeaderStates = [StatusDashboardTab, DashboardTabStatus][]

export const statusDashboardSelector = createStructuredSelector({
    preset: currentDashboardPresetSelector,
    overviewItems: overviewDashboardItemsSelector,
    tab: currentDashboardTabSelector,
    isSidebarOpen: isSidebarOpenSelector,
    isApoc: isApocSelector,
    statuses: dashboardStatusesSelector,
    healths: dashboardHealthsSelector,
    headerTabStates: dashboardTabStatusSelector,
    availablePresetTypes: availablePresetTypesSelector,
})
