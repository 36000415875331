import React, { FC } from 'react'

import { PictogramIcon } from './PictogramIcon'

interface BusDoorIconProps {
    door: number
}

// tslint:disable:max-line-length
export const BusDoorIcon: FC<BusDoorIconProps> = props => (
    <PictogramIcon width={100} height={100}>
        <path
            fill='#FFF'
            d='M9.985 70.168h3.971c1.475 0 3.631.416 3.631 2.912 0 1.362-.908 2.27-2.27 2.534v.038c1.552.151 2.61 1.172 2.61 2.572 0 2.836-2.571 3.29-3.97 3.29H9.984V70.168zm2.27 4.538h.907c.946 0 2.156-.227 2.156-1.361 0-1.324-1.135-1.4-2.194-1.4h-.87v2.761zm0 4.993h1.058c1.06 0 2.345-.265 2.345-1.589 0-1.475-1.21-1.702-2.345-1.702h-1.059v3.29zM19.819 70.168h2.269v6.543c0 1.929.605 3.177 2.345 3.177 1.74 0 2.345-1.248 2.345-3.177v-6.543h2.27v7.224c0 3.025-1.703 4.274-4.615 4.274s-4.614-1.249-4.614-4.274v-7.224zM37.67 72.21c-.718-.302-1.512-.492-2.307-.492-.794 0-1.777.34-1.777 1.589 0 1.967 4.954 1.135 4.954 4.917 0 2.496-1.966 3.404-4.236 3.404-1.248 0-1.777-.152-2.912-.454l.227-2.043c.794.417 1.664.681 2.572.681.87 0 2.004-.454 2.004-1.475 0-2.156-4.954-1.248-4.954-4.992 0-2.534 1.967-3.442 3.971-3.442.983 0 1.891.113 2.723.416l-.265 1.891zM45.084 70.168h3.101c3.48 0 6.543 1.172 6.543 5.673 0 4.5-3.063 5.673-6.543 5.673h-3.101V70.168zm2.269 9.53H48.6c1.891 0 3.744-1.399 3.744-3.857s-1.815-3.858-3.744-3.858h-1.248V79.7zM61.8 69.94c3.556 0 5.56 2.384 5.56 5.825 0 3.442-1.966 5.9-5.56 5.9-3.63 0-5.559-2.42-5.559-5.9 0-3.441 1.967-5.824 5.56-5.824zm0 9.948c2.308 0 3.178-2.042 3.178-4.085 0-2.004-.908-4.047-3.177-4.047-2.27 0-3.177 2.043-3.177 4.047 0 2.005.87 4.085 3.177 4.085zM74.395 69.94c3.556 0 5.56 2.384 5.56 5.825 0 3.442-1.966 5.9-5.56 5.9-3.63 0-5.56-2.42-5.56-5.9.038-3.441 1.967-5.824 5.56-5.824zm0 9.948c2.308 0 3.177-2.042 3.177-4.085 0-2.004-.907-4.047-3.177-4.047-2.269 0-3.177 2.043-3.177 4.047 0 2.005.87 4.085 3.177 4.085zM81.846 70.168h2.459c2.42 0 5.333-.076 5.333 3.063 0 1.324-.908 2.42-2.345 2.61v.038c.605.038.983.68 1.21 1.172l1.815 4.425h-2.571l-1.362-3.517c-.34-.832-.605-1.172-1.589-1.172h-.718v4.69h-2.27v-11.31h.038zm2.27 4.803h.756c1.135 0 2.383-.151 2.383-1.588 0-1.362-1.248-1.475-2.383-1.475h-.756v3.063z'
        />
        <path
            fill='#FFF'
            d='M45.273 53.942c-.454.038-1.816.114-3.556.19v3.025c0 .416.34.756.757.756h2.08c.416 0 .756-.34.756-.756v-3.215h-.037zM23.26 54.17c-1.74-.077-3.101-.152-3.555-.19v3.253c0 .416.34.756.757.756h2.08c.416 0 .756-.34.756-.756v-3.064h-.038zM16.906 50.69c.152 1.664.832 1.966 2.232 2.042 1.059.076 8.623.378 13.35.378 4.69 0 12.293-.302 13.352-.378 1.362-.114 2.08-.378 2.231-2.042.379-4.577.303-8.473.152-10.628-.19-3.14-1.173-16.49-2.043-17.814-.68-1.022-1.702-1.74-3.517-1.892-1.778-.15-6.921-.378-10.136-.378-3.215 0-8.359.227-10.137.378-1.777.152-2.798.87-3.517 1.892-.87 1.323-1.853 14.674-2.08 17.814-.151 2.155-.265 6.051.113 10.628zM41.68 24.933H23.298v-2.496H41.68v2.496zm-1.21 20.461c0-.983.794-1.777 1.777-1.777h1.664c.983 0 1.778.794 1.778 1.777 0 .984-.795 1.778-1.778 1.778h-1.664c-.983-.038-1.778-.794-1.778-1.778zm-21.18 0c0-.983.793-1.777 1.777-1.777h1.626c.983 0 1.778.794 1.778 1.777 0 .984-.795 1.778-1.778 1.778H21.03c-.983-.038-1.74-.794-1.74-1.778zm-1.211-6.164c.265-5.106.567-7.98.983-10.893.19-1.248.416-1.816 2.42-2.08 1.59-.19 7.716-.152 11.007-.152 3.29 0 9.418-.075 11.006.152 2.005.264 2.232.832 2.42 2.08.417 2.912.72 5.787.984 10.893.038.907-.454 1.172-1.135 1.361-1.513.378-6.694.605-13.275.605s-11.763-.227-13.276-.605c-.718-.189-1.21-.454-1.134-1.361z'
        />
        <text fontFamily='Open Sans' fontWeight='400' fontSize='50' x='56' y='56' fill='#FFF'>
            {props.door}
        </text>
    </PictogramIcon>
)
