import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { globalMessagingService } from 'aos-services/src/services/messages/globalMessagingService'
import { AosGlobalMessage } from 'aos-services/src/services/messages/types/AosGlobalMessage'
import { call, put, takeLatest } from 'redux-saga/effects'

import { assureCommonUserGroup } from '../commonFrontend/sagas'
import {
    BROADCAST_GLOBAL_MESSAGE_REQUEST,
    BroadcastGlobalMessageRequestAction,
    broadcastGlobalMessageSuccessAction,
    LOAD_GLOBAL_MESSAGES,
    LoadGlobalMessagesAction,
    loadGlobalMessagesAction,
    loadGlobalMessagesSuccessAction,
    TOGGLE_GLOBAL_MESSAGING,
    ToggleGlobalMessagingAction,
} from './actions'

function* loadGlobalMessagesSaga() {
    yield takeLatest<LoadGlobalMessagesAction>(LOAD_GLOBAL_MESSAGES, function* () {
        try {
            const messages: AosGlobalMessage[] = yield call(globalMessagingService.loadMessages)
            yield put(loadGlobalMessagesSuccessAction(messages))
        } catch (e) {
            logger.handleError(e as Error)
            throw e
        }
    })
}

function* openGlobalMessagesPanelSaga() {
    yield takeLatest<ToggleGlobalMessagingAction>(
        TOGGLE_GLOBAL_MESSAGING,
        function* (action: ToggleGlobalMessagingAction) {
            if (action.payload) {
                yield assureCommonUserGroup()
                yield put(loadGlobalMessagesAction())
                yield* firebaseService.onListener('globalMessaging', function* () {
                    return (yield syncValue(
                        sendToSyncRepository.stsRef(SendToSyncType.GLOBAL_MESSAGING),
                        loadGlobalMessagesAction,
                        true,
                    )) as Generator
                }) as Generator
            } else {
                yield firebaseService.offListener('globalMessaging')
            }
        },
    )
}

function* broadcastGlobalMessageSaga() {
    yield takeLatest<BroadcastGlobalMessageRequestAction>(
        BROADCAST_GLOBAL_MESSAGE_REQUEST,
        function* (action: BroadcastGlobalMessageRequestAction) {
            try {
                yield call(globalMessagingService.broadcastMessage, action.payload)
                yield put(broadcastGlobalMessageSuccessAction())
                yield put(loadGlobalMessagesAction())
            } catch (e) {
                logger.handleError(e as Error)
                throw e
            }
        },
    )
}

export const globalMessagingSagas = [
    loadGlobalMessagesSaga,
    broadcastGlobalMessageSaga,
    openGlobalMessagesPanelSaga,
]
