import React, { ReactNode } from 'react'

import { IdAware } from '../../base/IdAware'
import { BaseDropdown, DropdownItemProps } from './base/BaseDropdown'
import {
    dropdownHeight,
    DropdownLabel,
    dropdownPadding,
    DropdownPlaceholder,
} from './base/DropdownContent'
import { DropdownItem } from './base/DropdownItem'
import { DropdownPortalType } from './base/DropdownPortal'
import { DropdownVariant } from './base/DropdownVariant'
import { DropdownWidth } from './base/DropdownWidth'

export interface DropdownProps<T> extends IdAware {
    items: T[]
    value?: T

    variant?: DropdownVariant
    placeholder?: string
    width?: DropdownWidth
    minDropWidth?: number
    small?: boolean
    portalType?: DropdownPortalType
    toggleVisible?: boolean
    tooltip?: ReactNode
    outlined?: boolean

    valueRenderer(v: T): ReactNode
    onChange?(v: T): void
    clearAction?(): void
    itemRenderer?: React.ComponentType<DropdownItemProps<T>>
}

export const Dropdown = <T extends any>({
    value,
    id,
    items,
    variant = DropdownVariant.White,
    valueRenderer,
    placeholder,
    width,
    minDropWidth,
    onChange,
    clearAction,
    small,
    portalType,
    toggleVisible,
    tooltip,
    outlined,
    itemRenderer,
}: DropdownProps<T>) => {
    const content =
        value !== undefined ? (
            <DropdownLabel size={12} as='span' variant={variant} isSelected>
                {valueRenderer(value)}
            </DropdownLabel>
        ) : (
            <DropdownPlaceholder variant={variant}>{placeholder}</DropdownPlaceholder>
        )

    const height = dropdownHeight(variant, small)
    const padding = dropdownPadding(variant)

    const itemClick = (val: T, toggle: () => void) => {
        onChange?.(val)
        toggle()
    }

    const defaultItemRenderer = ({ onClick, variant, v }: DropdownItemProps<T>) => (
        <DropdownItem
            onClick={onClick}
            variant={variant}
            isSelected={value === v}
            height={height}
            padding={padding}
        >
            {valueRenderer(v)}
        </DropdownItem>
    )

    return (
        <BaseDropdown
            items={items}
            variant={variant}
            id={id}
            clearAction={clearAction}
            width={width}
            height={height}
            content={content}
            cleanVisible={!!value}
            onItemClick={itemClick}
            paddingHorizontal={padding}
            type={portalType}
            toggleVisible={toggleVisible}
            tooltip={tooltip}
            outlined={outlined}
            minDropWidth={minDropWidth}
            ItemRenderer={itemRenderer || defaultItemRenderer}
        />
    )
}
