import {
    AosSeverity,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { MarkerMapIcon } from './EventMapIcon'

export interface MarkerMapIconRendererProps {
    severity: AosSeverity
    icon: Svg
    colorMapping?: Record<AosSeverity, Color>
}

export const MarkerMapIconRenderer: FC<MarkerMapIconRendererProps> = ({
    icon,
    severity,
    colorMapping = severityColorsMapping,
}) => {
    const color = colorMapping[severity]
    const iconColor = severity === AosSeverity.Notice ? '#000' : '#fff'
    return (
        <MarkerMapIcon color={color} iconColor={iconColor}>
            <SvgImage style={{ fill: iconColor }} svg={icon} />
        </MarkerMapIcon>
    )
}
