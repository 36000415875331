import { chain, identity, last, sortBy, unionBy } from 'lodash'

import { isDefined } from './Function'

export const mapIf = <T>(
    input: T[],
    predicate: f.Func1<T, boolean>,
    mapFunction: f.Func1<T, T>,
): T[] =>
    input.map(t => {
        if (predicate(t)) {
            return mapFunction(t)
        } else {
            return t
        }
    })

export const arrayMove = <T>(array: T[], moveIndex: number, toIndex: number) => {
    /* #move - Moves an array item from one position in an array to another.
       Note: This is a pure function so a new array will be returned, instead
       of altering the array argument.
      Arguments:
      1. array     (String) : Array in which to move an item.         (required)
      2. moveIndex (Object) : The index of the item to move.          (required)
      3. toIndex   (Object) : The index to move item at moveIndex to. (required)
    */
    const itemRemovedArray = [
        ...array.slice(0, moveIndex),
        ...array.slice(moveIndex + 1, array.length),
    ]
    return [
        ...itemRemovedArray.slice(0, toIndex),
        array[moveIndex],
        ...itemRemovedArray.slice(toIndex, itemRemovedArray.length),
    ]
}

export const toArray = <T>(t: T | T[]): T[] => {
    if (Array.isArray(t)) {
        return t
    } else {
        return [t]
    }
}

export const updateAt = <T>(t: T[], index: number, updater: f.Func1<T, T>): T[] =>
    t.map((e, i) => (i === index ? updater(e) : e))

export const removeAt = <T>(t: T[], index: number): T[] => {
    const newArray = [...t]
    newArray.splice(index, 1)
    return newArray
}

export const optionalToArray = <T>(t: T | T[] | undefined): T[] => {
    if (Array.isArray(t)) {
        return t
    } else {
        return isDefined(t) ? [t] : []
    }
}

export const streamGroupArrayByEquality = <T>(
    items: T[],
    isEqual: f.Func2<T, T, boolean>,
): T[][] => {
    const lastProcessed = (blocks: T[][]) => {
        const lastBlock = last(blocks)
        if (lastBlock) {
            return last(lastBlock)
        }
        return undefined
    }

    const startNewBlock = (blocks: T[][], element: T): T[][] => [...blocks, [element]]

    const addToLastBlock = (blocks: T[][], element: T): T[][] => {
        const lastBlock = last(blocks)
        if (!lastBlock) {
            return startNewBlock(blocks, element)
        }
        lastBlock.push(element)
        return blocks
    }

    return items.reduce((prev, current) => {
        const lastElement = lastProcessed(prev)
        if (!lastElement || isEqual(current, lastElement)) {
            return addToLastBlock(prev, current)
        }
        return startNewBlock(prev, current)
    }, [] as T[][])
}

export const getUniqueValues = <T, U>(items: T[], picker: f.Func1<T, U | undefined>): U[] =>
    chain(items).map(picker).filter(identity).uniq().sort().value() as U[]

export const sortedUnionBy = <T, U>(items1: T[], items2: T[], picker: f.Func1<T, U>): T[] =>
    sortBy(unionBy(items1, items2, picker), picker)
