import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import {
    AosUserNotificationsPreferences,
    defaultUserNotificationsPreferences,
} from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { OkButton } from 'aos-ui/src/components/buttons/OkButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
    closeNotificationSettingsModalAction,
    saveNotificationSettingsAction,
} from '../../core/groupManager/actions'
import { GroupManagerStateAware } from '../../core/groupManager/state'
import { NotificationSettingsForm } from '../profile/NotificationSettings/NotificationSettingsForm'

class GroupNotificationsModalClass extends PureComponent<
    GroupNotificationsModalProps,
    GroupNotificationsModalState
> {
    public initialState: GroupNotificationsModalState = {
        notificationSettings: defaultUserNotificationsPreferences,
        confirmed: false,
    }
    public state: GroupNotificationsModalState = this.initialState

    public render() {
        const { showModal } = this.props
        const { notificationSettings } = this.state

        return (
            <Modal
                isOpen={showModal}
                closeAction={this.handleClose}
                title={translate('group-manager.notification-settings.title')}
                footer={this.renderFooter()}
                modalKind={ModalKind.Big}
            >
                <NotificationSettingsForm
                    settings={notificationSettings}
                    onUpdate={this.handleUpdateSettings}
                />
            </Modal>
        )
    }

    private renderFooter() {
        const { confirmed } = this.state
        return (
            <Box row justify='space-between' fullWidth>
                <Checkbox checked={confirmed} onChange={this.handleConfirm}>
                    <Text size={14} color={Color.Red} padding={8}>
                        {translate('group-manager.notification-settings.warning')}
                    </Text>
                </Checkbox>
                <OkButton
                    label={translate('group-manager.notification-settings.save')}
                    isLoading={this.props.isLoading}
                    onClick={this.handleSave}
                    onDone={this.handleClose}
                    disabled={!confirmed}
                    checkTime={1500}
                    minimumLoadingTime
                />
            </Box>
        )
    }

    private handleConfirm = () => {
        this.setState(state => ({ confirmed: !state.confirmed }))
    }

    private handleUpdateSettings = (newSettings: Partial<AosUserNotificationsPreferences>) => {
        this.setState(state => ({
            notificationSettings: {
                ...state.notificationSettings,
                ...newSettings,
            },
        }))
    }

    private handleSave = () => {
        this.props.save(this.state.notificationSettings)
    }

    private handleClose = () => {
        this.props.close()
        this.setState(this.initialState)
    }
}

interface GroupNotificationsModalState {
    notificationSettings: AosUserNotificationsPreferences
    confirmed: boolean
}

interface GroupNotificationsModalStateProps {
    showModal: boolean
    group: AosUserGroup | null
    isLoading: boolean
}

interface GroupNotificationsModalDispatchProps {
    close: typeof closeNotificationSettingsModalAction
    save: typeof saveNotificationSettingsAction
}

interface GroupNotificationsModalProps
    extends GroupNotificationsModalStateProps,
        GroupNotificationsModalDispatchProps {}

export const GroupNotificationsModal = connect<
    GroupNotificationsModalStateProps,
    GroupNotificationsModalDispatchProps
>(
    (state: GroupManagerStateAware) => ({
        showModal: state.groupManager.showNotificationSettingsModal,
        group: state.groupManager.notificationSettingsGroup,
        isLoading: state.groupManager.notificationSettingsSaveInProgress,
    }),
    {
        close: closeNotificationSettingsModalAction,
        save: saveNotificationSettingsAction,
    },
)(GroupNotificationsModalClass)
