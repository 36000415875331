import { downloadFileFromResponse } from 'aos-helpers/src/helpers/Download'
import { dateTimeToISOString } from 'aos-helpers/src/helpers/Time'

import { TaskExport } from '../../../../aos-helpers/src/helpers/TaskExport'
import { tasksRestService } from '../../dataaccess/tasks/tasksRestService'

export class TasksExportService {
    public async exportTasks(payload: TaskExport): Promise<void> {
        const result = await tasksRestService.exportTasks({
            startTime: dateTimeToISOString(payload.range.timeStart),
            endTime: dateTimeToISOString(payload.range.timeEnd),
            siteLocations: payload.siteLocations,
            taskCategories: payload.taskCategories,
            tasks: payload.tasks,
            fods: payload.fods,
            shiftNotes: payload.shiftNotes,
            fireFighterShifts: payload.fireFighterShifts,
        })
        return downloadFileFromResponse(result)
    }

    public async exportTaskLogsForTask(id: number): Promise<void> {
        const result = await tasksRestService.exportTaskLogsForTask(id)
        return downloadFileFromResponse(result)
    }
}

export const tasksExportService = new TasksExportService()
