import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import {
    getColorForRingRailTrainScheduleStatus,
    RingRailTrain,
    RingRailTrainScheduleStatus,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { trainScheduleStatus } from 'aos-services/src/services/ringRail/ringRailTrainFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { Component } from 'react'

export class ScheduleStatusCell extends Component<ScheduleStatusCellProps, {}> {
    public render() {
        const { scheduleStatus } = this.props.train

        return this.renderCell(scheduleStatus)
    }

    private renderCell = (status: RingRailTrainScheduleStatus) => {
        const style = {
            backgroundColor: getColorForRingRailTrainScheduleStatus(status),
        }

        return (
            <Box className={cxp(this.props, 'train-information__schedule-status')} style={style}>
                {trainScheduleStatus(status)}
            </Box>
        )
    }
}

interface ScheduleStatusCellProps extends ClassNameProps {
    train: RingRailTrain
}
