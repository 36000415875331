import { simpleFormModalReducerCreator } from '../simpleForm/reducer'
import { CHANGE_SELECT_VALUE, SET_OPTIONS, SingleSelectModalAction } from './actions'
import { createInitialSingleSelectModalState, SingleSelectModalState, validateForm } from './state'

export function singleSelectModalReducer<T>(
    state: SingleSelectModalState<T> = createInitialSingleSelectModalState(),
    initialState: SingleSelectModalState<T> = createInitialSingleSelectModalState(),
    action: SingleSelectModalAction,
): SingleSelectModalState<T> {
    const simpleFormModalReducer = simpleFormModalReducerCreator(validateForm, initialState)

    switch (action.type) {
        case CHANGE_SELECT_VALUE:
            return {
                ...state,
                currentValue: action.payload,
                form: state.form.modify(validateForm(action.payload)),
            }

        case SET_OPTIONS:
            return { ...state, options: action.payload }

        default:
            return simpleFormModalReducer(state, action) as SingleSelectModalState<T>
    }
}
