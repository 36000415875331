import { DateTime, dateTime, mapOptionalDateTime } from './Time'
import { translate } from './translations/Translations'

export const formatCalendarTime = (t: DateTime): string =>
    t.calendar(undefined, {
        sameDay: `[${translate('time.today')}], HH:mm`,
        nextDay: `[${translate('time.tomorrow')}], HH:mm`,
        nextWeek: defaultDateTimeFormat,
        lastDay: `[${translate('time.yesterday')}], HH:mm`,
        lastWeek: defaultDateTimeFormat,
        sameElse: defaultDateTimeFormat,
    })

export const formatCalendarDay = (t: DateTime): string =>
    t.calendar(undefined, {
        sameDay: `[${translate('time.today')}]`,
        nextDay: `[${translate('time.tomorrow')}]`,
        nextWeek: defaultDateFormat,
        lastDay: `[${translate('time.yesterday')}]`,
        lastWeek: defaultDateFormat,
        sameElse: defaultDateFormat,
    })

export const formatOptionalCalendarTime = (t?: DateTime): string | undefined =>
    mapOptionalDateTime(x => formatCalendarTime(x))(t)

export const formatDateTime = (t: DateTime, format?: string): string =>
    t.format(format || defaultDateTimeFormat)

export const formatTime = (t: DateTime, format?: string): string =>
    t.format(format || defaultTimeFormat)

export const formatHour = (t: DateTime): string => t.format('HH')

export const formatDay = (t: DateTime): string => t.format('DD')
export const formatDayLong = (t: DateTime): string => `${t.format('ddd')}.`

export const formatDate = (t: DateTime, format?: string): string =>
    t.format(format || defaultDateFormat)

export const formatOptionalDateTime = (value?: DateTime, format?: string): string | undefined =>
    mapOptionalDateTime(t => formatDateTime(t, format))(value)

export const formatOptionalTime = (value?: DateTime, format?: string): string | undefined =>
    mapOptionalDateTime(t => formatTime(t, format))(value)

export const defaultDateFormat = 'DD.MM.YYYY'
export const defaultDateTimeFormat = 'DD.MM.YYYY, HH:mm'
export const defaultTimeFormat = 'HH:mm'

export const fromDateTimeFormat = () => `[${translate('datetime.from')}] ${defaultDateTimeFormat}`
export const toDateTimeFormat = () => `[${translate('datetime.to')}] ${defaultDateTimeFormat}`

export const fromDateFormat = () => `[${translate('datetime.from')}] ${defaultDateFormat}`
export const toDateFormat = () => `[${translate('datetime.to')}] ${defaultDateFormat}`

export const formatFromDateTime = (t: DateTime): string => formatDateTime(t, fromDateTimeFormat())

export const formatToDateTime = (t: DateTime): string => formatDateTime(t, toDateTimeFormat())

export const getMonthName = (m: number): string => dateTime(m, 'M').format('MMMM')
