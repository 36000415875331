import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { UserResult } from 'aos-services/src/services/users/types/UserResult'
import { userSearchService } from 'aos-services/src/services/users/userSearchService'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownMultiAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownMultiAutocomplete'
import React, { FC } from 'react'

interface TaskAssigneesAutocompleteProps {
    value: UserResult[]
    onChange(v: UserResult[]): void
}

export const TaskAssigneesAutocomplete: FC<TaskAssigneesAutocompleteProps> = ({
    value,
    onChange,
}) => (
    <DropdownMultiAutocomplete
        value={value}
        items={userSearchService.searchForUsers}
        variant={DropdownVariant.White}
        valueRenderer={v => v.name}
        labelRenderer={v => v.name}
        placeholder={translate('tasks.autocomplete.select-users.placeholder')}
        onChange={onChange}
        outlined
    />
)
