import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { InfoBarContent, InfoBarText } from 'aos-ui/src/components/infobar/InfoBar'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { RestrictionIcon } from './RestrictionIcon'

export interface RestrictionBarContentProps {
    item: Restriction
    inTooltip?: boolean
}

export const RestrictionBarContent: FC<RestrictionBarContentProps> = ({
    item,
    inTooltip = false,
}) => {
    const iconVariant = inTooltip ? IconVariant.Black : IconVariant.White
    const textColor = inTooltip ? Color.Black : Color.White

    return (
        <InfoBarContent
            inTooltip={inTooltip}
            row
            paddingHorizontal={8}
            paddingVertical={2}
            overflow='hidden'
        >
            <RestrictionIcon type={item.resourceType} iconVariant={iconVariant} />
            <InfoBarText
                size={14}
                color={textColor}
                lineHeight='large'
                paddingLeft={8}
                paddingRight={16}
                data-timeline-text
            >
                {item.resourceNumber}
            </InfoBarText>
            <InfoBarText
                size={14}
                color={textColor}
                overflow='hidden'
                lineHeight='large'
                flex={1}
                paddingLeft={8}
                data-timeline-text
            >
                {item.aosMessage}
            </InfoBarText>
        </InfoBarContent>
    )
}
