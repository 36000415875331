import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { dateTime, getMonth, getMonthDay } from 'aos-helpers/src/helpers/Time'

import { TaskRecurrenceType } from '../task/TaskRecurrenceType'
import { RecurrenceCustomOn, RecurrenceCustomOnValue } from './RecurrencePayloadEnums'

export interface TaskRecurrenceYearlyPayload {
    type: TaskRecurrenceType.Yearly
    duration: number
    every: number
    on: number
    customOn: RecurrenceCustomOn | undefined
    customOnValue: RecurrenceCustomOnValue[]
    month: number[]
}

export const defaultTaskRecurrenceYearly: TaskRecurrenceYearlyPayload = {
    type: TaskRecurrenceType.Yearly,
    duration: 60,
    every: 1,
    on: getMonthDay(dateTime(new Date())),
    month: [getMonth(dateTime(new Date()))],
    customOn: undefined,
    customOnValue: [],
}

export const defaultOnTaskRecurrenceYearly = {
    on: defaultTaskRecurrenceYearly.on,
    customOn: undefined,
    customOnValue: defaultTaskRecurrenceYearly.customOnValue,
}

export const defaultCustomOnTaskRecurrenceYearly = {
    on: defaultTaskRecurrenceYearly.on,
    customOn: RecurrenceCustomOn.FIRST,
    customOnValue: defaultTaskRecurrenceYearly.customOnValue,
}

export interface TaskRecurrenceYearlyValidation {
    duration: boolean
    every: boolean
    on: boolean
    customOnValue: boolean
    month: boolean
}

export const validateTaskRecurrenceYearly = (
    r: TaskRecurrenceYearlyPayload,
): TaskRecurrenceYearlyValidation => ({
    duration: isNotEmpty(r.duration) && r.duration > 0,
    every: isNotEmpty(r.every) && r.every > 0,
    on: isNotEmpty(r.on) && r.on > 0 && r.on <= 31,
    customOnValue:
        r.customOn !== undefined && r.customOnValue !== undefined && r.customOnValue !== null
            ? r.customOnValue.length > 0
            : true,
    month: r.month !== undefined ? r.month.length > 0 : true,
})
