import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC, PropsWithChildren } from 'react'

type Position = 'top' | 'left' | 'right'

interface WhatsNewItemProps {
    prefix: string
    image?: string
    position?: Position
}

export const WhatsNewItem: FC<WhatsNewItemProps> = props => {
    const { prefix, image, position = 'top' } = props

    const content = (
        <>
            <h3 className='text__l-black padding-bottom--small'>{translate(`${prefix}.title`)}</h3>
            <p className='text__s-grey-txt'>{translate(`${prefix}.text`)}</p>
        </>
    )

    return (
        <article className='padding-bottom--large text--lh-x-large text--selectable'>
            {image ? (
                <ContentWithImage position={position} image={image}>
                    {content}
                </ContentWithImage>
            ) : (
                content
            )}
        </article>
    )
}

interface ContentWithImageProps {
    image?: string
    position?: Position
}

const ContentWithImage: FC<PropsWithChildren<ContentWithImageProps>> = props => {
    if (props.position === 'top') {
        return (
            <>
                <img
                    alt='whats_new_image'
                    src={props.image}
                    className='block full-width margin-bottom--large'
                />
                {props.children}
            </>
        )
    }

    const image = <img alt='whats-new-image' src={props.image} className='block full-width' />

    return (
        <Box className='flex-grid' alignItems='flex-start'>
            {props.position === 'left' && (
                <Box className='col-4' paddingRight={16}>
                    {image}
                </Box>
            )}
            <Box className='col-8'>{props.children}</Box>
            {props.position === 'right' && (
                <Box className='col-4' paddingLeft={16}>
                    {image}
                </Box>
            )}
        </Box>
    )
}
