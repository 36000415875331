import {
    AosUnitTaskItem,
    AosUnitTaskItemDTO,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    hideEditUnitTaskItemModalAction,
    updateUnitTaskItemAction,
} from '../../../../core/checklistManager/unit/actions'
import { State } from '../../../../core/state'
import { UnitTaskItemModal } from './UnitTaskItemModal'

export const EditUnitTaskItemModal = () => {
    const dispatch = useDispatch()
    const currentTemplate = useSelector(
        (state: State) => state.checklistManager.unitTaskManager.itemToEdit as AosUnitTaskItem,
    )
    const onSubmit = (item: AosUnitTaskItemDTO) => dispatch(updateUnitTaskItemAction(item))
    const isOpen = useSelector(
        (state: State) => state.checklistManager.unitTaskManager.isEditItemModalOpen,
    )

    return (
        <UnitTaskItemModal
            item={{
                ...currentTemplate,
                hasAdditionalNote: !!currentTemplate?.additionalNote,
                createAnother: false,
            }}
            lockSeverityChange
            isOpen={isOpen}
            keyPrefix='update-tasklist-template'
            onHide={() => dispatch(hideEditUnitTaskItemModalAction())}
            onSubmit={onSubmit}
        />
    )
}
