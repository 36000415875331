import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum DeIcingSurfaceType {
    CleanAndDry = 'CLEAN_AND_DRY',
    Moisture = 'MOISTURE',
    Wet = 'WET',
    VeryWet = 'VERY_WET',
    CoveredByDuctOrFrost = 'COVERED_BY_DUCT_OR_FROST',
    DrySnow = 'DRY_SNOW',
    WetTheSnow = 'WET_THE_SNOW',
    Sound = 'SOUND',
    Ice = 'ICE',
    PackedOrRolledSnow = 'PACKED_OR_ROLLED_SNOW',
    FrozenWheelTracksOrExercises = 'FROZEN_WHEEL',
}

export const allSurfaceTypes = EnumValues.getValues<DeIcingSurfaceType>(DeIcingSurfaceType)
export const translateSurfaceType = translateEnum(DeIcingSurfaceType, 'surface-type')
