import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { EventMapUserLayerVisibilityState } from 'aos-services/src/core/eventUserLayersVisibility/state'
import {
    changeFodFormAction,
    FodPayload,
    fodPristineClearFormAction,
} from 'aos-services/src/core/fod/actions'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    BaseFodFormPayload,
    BaseFodPayloadValidation,
} from 'aos-services/src/services/fod/types/FodNotesFormData'
import { LayersVisibility } from 'aos-services/src/services/map/types/LayersVisibility'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { AosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { MapFormAwareModal } from 'aos-ui-map/src/components/map/MapFormAwareModal'
import { MapState } from 'aos-ui-map/src/core/state'
import moment from 'moment'
import React, { FC } from 'react'

import {
    fodAttachmentsParentAction,
    fodEditMapActions,
    fodMapActions,
    saveLocationAction,
} from '../../../core/fod/actions'
import { FodStateAware } from '../../../core/fod/state'
import { loadMetadataAction } from '../../../core/tasks/actions'
import { FodMap } from '../FodMap'
import { FodNotesForm } from '../FodNotesForm'

interface FodModalProps extends FodModalSelectorState, FodModalDispatchProps {}

export const FodModalComponent: FC<FodModalProps> = ({
    isOpen,
    closeFodModal,
    saveFod,
    task,
    keyPrefix,
    form,
    map,
    setFormPristine,
    saveLocation,
    setLayersVisibility,
    switchMap,
    attachmentsState,
    attachmentsAction,
    metadata,
    loadMetadata,
    bimLayers,
    userLayerVisibility,
    setUserLayersVisibilityAction,
    resetLocation,
    customizeMap,
    ...props
}) => {
    const submitForm = () => {
        if (!form.valid) {
            setFormPristine(false)
        } else {
            saveFod({
                siteLocation: task.siteLocation,
                placeOfDiscovery: task.placeOfDiscovery,
                description: task.description,
                fodSource: task.fodSource,
                fodType: task.fodType,
                location: task.location,
                discoveryDate: (task.discoveryDate as moment.Moment).toISOString(),
                anonymousReport: task.anonymousReport,
                anonymousType: task.anonymousType,
                id: task.id,
            })
        }
    }

    const changeForm = (payload: Partial<BaseFodFormPayload>) => {
        props.changeForm({ ...task, ...payload } as BaseFodFormPayload)
    }

    const changeUserMapLayersVisibility = (v: string[]) => {
        setUserLayersVisibilityAction({
            ...userLayerVisibility,
            list: v,
        })
    }

    const changeFloorLayerVisibility = (floorNumber: number) => {
        const changedUserLayerVisibilityState = {
            ...userLayerVisibility,
            list: [
                ...userLayerVisibility.list.filter(item => !item.includes('floor')),
                'floor' + floorNumber,
            ],
            floor: floorNumber,
        }
        setUserLayersVisibilityAction(changedUserLayerVisibilityState)
    }

    return (
        <>
            <MapFormAwareModal
                map={() => <FodMap />}
                content={({ setView }) => (
                    <FodNotesForm
                        setViewMode={setView}
                        task={task}
                        form={form}
                        attachmentsState={attachmentsState}
                        attachmentsAction={attachmentsAction}
                        metadata={metadata!}
                        changeForm={changeForm}
                    />
                )}
                modalKind={ModalKind.Big}
                isOpen={isOpen}
                keyPrefix={keyPrefix}
                form={form}
                mapState={map}
                hideAction={closeFodModal}
                saveLocation={saveLocation}
                setLayersVisibility={setLayersVisibility}
                switchMap={switchMap}
                submitForm={submitForm}
                hasPickedLocation={!!map.pickedFod}
                isLoading={false}
                bimLayers={bimLayers}
                userLayerVisibility={userLayerVisibility}
                setUserLayersVisibilityAction={v => changeUserMapLayersVisibility(v)}
                changeFloorLayerVisibility={v => changeFloorLayerVisibility(v)}
                resetSelectedLocation={resetLocation}
            />
        </>
    )
}

export interface FodModalSelectorState {
    isOpen: boolean
    task: BaseFodFormPayload
    keyPrefix: string
    form: FormValidation<BaseFodPayloadValidation>
    map: MapState
    attachmentsState: AttachmentsState
    metadata?: TaskMetadata
    bimLayers: BimLayersState
    userLayerVisibility: UserLayerVisibilityState
    defaultReporter?: AosAdUser
}

export interface FodModalDispatchProps {
    closeFodModal(): void
    setFormPristine(v: boolean): void
    saveFod(v: FodPayload): void
    changeForm(v: BaseFodFormPayload): void
    // map
    saveLocation(): void
    setLayersVisibility(m: LayersVisibility): void
    switchMap(m: MapVariant): void
    attachmentsAction(m: AttachmentsAction): void
    loadMetadata(): void
    setUserLayersVisibilityAction(v: EventMapUserLayerVisibilityState): void
    resetLocation(): void
    customizeMap(v: AosAirport): void
}

export const fodCommonActions = {
    setFormPristine: fodPristineClearFormAction,
    changeForm: changeFodFormAction,
    setLayersVisibility: fodMapActions.setLayersVisibilityAction,
    // map
    switchMap: fodEditMapActions.switchMapAction,
    saveLocation: saveLocationAction,
    attachmentsAction: fodAttachmentsParentAction,
    loadMetadata: loadMetadataAction,
    setUserLayersVisibilityAction: fodMapActions.setUserLayersVisibilityAction,
    resetLocation: fodEditMapActions.resetPickedLocationAction,
    customizeMap: fodEditMapActions.customizeMapAction,
}

export const fodCommonStateSelectors = {
    task: (state: FodStateAware) => state.fod.payload,
    form: (state: FodStateAware) => state.fod.form,
    map: (state: FodStateAware) => state.fod.fodMap,
    attachmentsState: (state: FodStateAware) => state.fod.attachmentsState,
    metadata: (state: FodStateAware) => state.fod.metadata,
}
