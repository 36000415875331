import React from 'react'
import { useSelector } from 'react-redux'

import { firefighterEditorOpenSelector } from '../../../core/tasks/firefighters/selectors'
import { FirefighterEditForm } from './form/FirefighterEditForm'

export const FirefightersModal = () => {
    const isOpen = useSelector(firefighterEditorOpenSelector)

    return isOpen ? <FirefighterEditForm /> : null
}
