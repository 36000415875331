import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'

export const LOAD_COMMON_GROUPS = 'COMMON/LOAD_COMMON_GROUPS'
export type LoadCommonGroupsAction = Action<typeof LOAD_COMMON_GROUPS>
export const loadCommonGroupsAction = emptyActionCreator<LoadCommonGroupsAction>(LOAD_COMMON_GROUPS)

export const LOAD_COMMON_GROUPS_SUCCESS = 'COMMON/LOAD_COMMON_GROUPS_SUCCESS'
export type LoadCommonGroupsSuccessAction = PayloadAction<
    typeof LOAD_COMMON_GROUPS_SUCCESS,
    AosUserGroup[]
>
export const loadCommonGroupsSuccessAction = payloadActionCreator<
    LoadCommonGroupsSuccessAction,
    AosUserGroup[]
>(LOAD_COMMON_GROUPS_SUCCESS)

export type CommonFrontendAction = LoadCommonGroupsAction | LoadCommonGroupsSuccessAction
