import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { TextProps } from 'aos-ui/src/components/base/Text'
import React, { FCWithChildren, ReactNode } from 'react'

interface LeftRightProps extends TextProps {
    left: ReactNode
    right: ReactNode
}

export const LeftRight: FCWithChildren<LeftRightProps> = ({ left, right, ...textProps }) => (
    <Text row justify='space-between' overflow='hidden' {...textProps}>
        <Box marginRight={8}>{left}</Box>
        <Box>{right}</Box>
    </Text>
)
