import { forkWrap } from 'aos-helpers/src/helpers/Saga'
import { alertsSagas } from 'aos-services/src/core/alerts/sagas'
import { bimLayersDataSagas } from 'aos-services/src/core/bimLayersData/sagas'
import { commonSagas } from 'aos-services/src/core/common/sagas'
import { eventMapUserLayerVisibilitySagas } from 'aos-services/src/core/eventUserLayersVisibility/sagas'
import { featureFlagsSagas } from 'aos-services/src/core/featureFlags/saga'
import { layersDataSagas } from 'aos-services/src/core/layersData/sagas'
import { lvpSagas } from 'aos-services/src/core/lvp/sagas'
import { notamSagas } from 'aos-services/src/core/notam/sagas'
import { statusDashboardDataSagas } from 'aos-services/src/core/statusDashboardData/sagas'
import { towingSagas } from 'aos-services/src/core/towings/sagas'
import { userLayerVisibilitySagas } from 'aos-services/src/core/userLayersVisibility/sagas'
import { all } from 'redux-saga/effects'

import { globalResponseErrorToast } from '../../helpers/globalResponseErrorToast'
import { airportMapSagas } from './airportMap/sagas'
import { authSagas } from './auth/sagas'
import { checklistManagerSagas } from './checklistManager/sagas'
import { checklistSelectionModalSagas } from './checklistSelectionModal/sagas'
import { commonFrontendSagas } from './commonFrontend/sagas'
import { contactListSagas } from './contactList/sagas'
import { eventEditorSagas } from './eventEditor/sagas'
import { eventManagerSagas } from './eventManager/sagas'
import { eventModalsSagas } from './eventModals/sagas'
import { eventPreviewSagas } from './eventPreview/sagas'
import { eventTimelineSagas } from './eventTimeline/sagas'
import { flightInformationSagas } from './flightInformation/sagas'
import { globalMessagingSagas } from './globalMessaging/sagas'
import { groupManagerSagas } from './groupManager/sagas'
import { layoutSagas } from './layout/sagas'
import { notificationsSagas } from './notifications/sagas'
import { removeAccountSagas } from './removeAccount/sagas'
import { restrictionsSagas } from './restrictions/sagas'
import { locationSagas } from './router/sagas'
import { statusDashboardSagas } from './statusDashboard/sagas'
import { tasksSagas } from './tasks'
import { userProfileModalSagas } from './userProfileModal/sagas'

export function* sagas() {
    yield all(
        [
            ...commonFrontendSagas,
            ...commonSagas,
            ...alertsSagas,
            ...locationSagas,
            ...eventTimelineSagas,
            ...layoutSagas,
            ...layersDataSagas,
            ...authSagas,
            ...eventPreviewSagas,
            ...eventModalsSagas,
            ...eventManagerSagas,
            ...eventEditorSagas,
            ...groupManagerSagas,
            ...checklistManagerSagas,
            ...notificationsSagas,
            ...checklistSelectionModalSagas,
            ...globalMessagingSagas,
            ...airportMapSagas,
            ...flightInformationSagas,
            ...towingSagas,
            ...statusDashboardSagas,
            ...userProfileModalSagas,
            ...statusDashboardDataSagas,
            ...restrictionsSagas,
            ...bimLayersDataSagas,
            ...tasksSagas,
            ...userLayerVisibilitySagas,
            ...eventMapUserLayerVisibilitySagas,
            ...featureFlagsSagas,
            ...removeAccountSagas,
            ...notamSagas,
            ...contactListSagas,
            ...lvpSagas,
        ].map(actions => forkWrap(actions, globalResponseErrorToast)),
    )
}
