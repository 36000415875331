import { Action, PayloadAction } from 'aos-helpers/src/helpers/ActionCreator'

export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'
export const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS'
export const REMOVE_ACCOUNT_ERROR = 'REMOVE_ACCOUNT_ERROR'
export const RESET_REMOVE_ACCOUNT = 'RESET_REMOVE_ACCOUNT'

export type RemoveAccountAction = PayloadAction<typeof REMOVE_ACCOUNT, string>
export type RemoveAccountSuccessAction = Action<typeof REMOVE_ACCOUNT_SUCCESS>
export type RemoveAccountErrorAction = Action<typeof REMOVE_ACCOUNT_ERROR>
export type ResetRemoveAccountAction = Action<typeof RESET_REMOVE_ACCOUNT>

export const removeAccount = (email: string): RemoveAccountAction => ({
    type: REMOVE_ACCOUNT,
    payload: email,
})

export const removeAccountSuccess = (): RemoveAccountSuccessAction => ({
    type: REMOVE_ACCOUNT_SUCCESS,
})

export const removeAccountError = (): RemoveAccountErrorAction => ({
    type: REMOVE_ACCOUNT_ERROR,
})

export const resetRemoveAccount = (): ResetRemoveAccountAction => ({ type: RESET_REMOVE_ACCOUNT })

export type RemoveAccountActions =
    | RemoveAccountAction
    | RemoveAccountSuccessAction
    | RemoveAccountErrorAction
    | ResetRemoveAccountAction
