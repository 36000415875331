import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { dateTime } from 'aos-helpers/src/helpers/Time'

import { CdmMetricSubType } from '../../services/airportStatus/cdm/types/CdmMetricSubType'
import { CdmMetricType } from '../../services/airportStatus/cdm/types/CdmMetricType'
import { emptyMetarState } from '../../services/airportStatus/metar/types/MetarState'
import { emptyTobtData } from '../../services/airportStatus/tobt/TobtFilters'
import { emptyWeatherState } from '../../services/airportStatus/weather/types/WeatherState'
import { FodDto } from '../../services/fod/types'
import { emptyForecast } from '../../services/operationalForecast/types/OperationalForecast'
import { emptyQueueingData } from '../../services/queueingTime/types/QueueingData'
import { DashboardDataState } from '../../services/statusDashboard/types/DashboardDataState'

export type StatusDashboardData = DashboardDataState

export interface StatusDashboardDataStateAware {
    statusDashboardData: StatusDashboardData
}

export const paxStartDate = () => dateTime().startOf('hour')
export const paxEndDate = () => dateTime().endOf('hour').add({ hour: 19 })

export const initialStatusDashboardDataState: StatusDashboardData = {
    events: [],
    newsFeeds: {},
    pax: [],
    paxForecast: [],
    cdm: getInitialValuesForEnumRecord(
        CdmMetricType,
        getInitialValuesForEnumRecord(CdmMetricSubType, null),
    ),
    tobt: emptyTobtData,
    weatherMetrics: emptyWeatherState,
    metarState: emptyMetarState,
    operationalForecast: emptyForecast,
    queueingTime: emptyQueueingData,
    fod: {} as FodDto,
}
