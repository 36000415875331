import { WidgetStatesDto } from '../../dataaccess/widgetStates/types/WidgetStatesDto'
import { widgetStatesRepository } from '../../dataaccess/widgetStates/widgetStatesRepository'
import { AosAirport } from '../flightInformation/types/AosAirport'
import { DashboardItemMode } from '../statusDashboard/types/DashboardItemMode'
import {
    DashboardItemStatus,
    DashboardItemStatuses,
    statusToNumber,
} from '../statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from '../statusDashboard/types/DashboardItemType'

export class WidgetStatesService {
    public dismissAlert = (location: AosAirport, type: DashboardItemType) =>
        widgetStatesRepository.updateWidgetState(location, type, { warning: false })

    public itemStatusFromDto = (dto: WidgetStatesDto) => (dto as DashboardItemStatuses) || {}

    public updateStatus = (
        location: AosAirport,
        type: DashboardItemType,
        status: DashboardItemMode,
    ) =>
        widgetStatesRepository.getWidgetStatus(location, type).then(s => {
            const currentStatus = s as DashboardItemStatus
            const isNotNormal = status !== DashboardItemMode.Normal
            if (currentStatus) {
                const isHigherStatus = statusToNumber[status] < statusToNumber[currentStatus.status]
                const warning = isHigherStatus ? true : isNotNormal
                return widgetStatesRepository.updateWidgetState(location, type, {
                    ...currentStatus,
                    status,
                    warning,
                })
            }
            return widgetStatesRepository.updateWidgetState(location, type, {
                status,
                warning: isNotNormal,
            })
        })
}

export const widgetStatesService = new WidgetStatesService()
