import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { loadContactList } from 'aos-services/src/core/contactList/actions'
import { baseContactListSagas } from 'aos-services/src/core/contactList/sagas'
import { contactListService } from 'aos-services/src/services/contactList/contactListService'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { call, put, takeEvery } from 'redux-saga/effects'

import {
    closeContactModal,
    closeUnitAirportContactModal,
    CREATE_CONTACT,
    CREATE_UNIT_AIRPORT_CONTACT,
    CreateContactAction,
    CreateUnitAirportContactAction,
    DELETE_CONTACT,
    DELETE_UNIT_AIRPORT_CONTACT,
    DeleteContactAction,
    DeleteUnitAirportContactAction,
    EDIT_CONTACT,
    EDIT_UNIT_AIRPORT_CONTACT,
    EditContactAction,
    EditUnitAirportContactAction,
} from './actions'

function* createContactSaga() {
    yield takeEvery<CreateContactAction>(CREATE_CONTACT, function* (action) {
        yield call(contactListService.createContact, action.payload.data)
        if (action.payload.createAnother) {
            successToast(translate('contact-list.create-contact-success'))
        }
        yield put(loadContactList())
    })
}

function* createUnitAirportContactSaga() {
    yield takeEvery<CreateUnitAirportContactAction>(
        CREATE_UNIT_AIRPORT_CONTACT,
        function* (action) {
            yield call(contactListService.createUnitAirportContact, action.payload)
            successToast(translate('contact-list.create-unit-success'))
            yield put(loadContactList())
            yield put(closeUnitAirportContactModal())
        },
    )
}

function* editUnitAirportContactSaga() {
    yield takeEvery<EditUnitAirportContactAction>(EDIT_UNIT_AIRPORT_CONTACT, function* (action) {
        yield call(contactListService.editUnitAirportContact, action.payload)
        successToast(translate('contact-list.edit-unit-success'))
        yield put(loadContactList())
        yield put(closeUnitAirportContactModal())
    })
}

function* editContactSaga() {
    yield takeEvery<EditContactAction>(EDIT_CONTACT, function* (action) {
        yield call(contactListService.editContact, action.payload)
        successToast(translate('contact-list.edit-contact-success'))
        yield put(loadContactList())
        yield put(closeContactModal())
    })
}

function* deleteUnitAirportContactSaga() {
    yield takeEvery<DeleteUnitAirportContactAction>(
        DELETE_UNIT_AIRPORT_CONTACT,
        function* (action) {
            yield call(contactListService.deleteUnitAirportContact, action.payload)
            successToast(translate('contact-list.delete-unit-success'))
            yield put(loadContactList())
        },
    )
}

function* deleteContactSaga() {
    yield takeEvery<DeleteContactAction>(DELETE_CONTACT, function* (action) {
        yield call(contactListService.deleteContact, action.payload)
        successToast(translate('contact-list.delete-contact-success'))
        yield put(loadContactList())
    })
}

export const contactListSagas = [
    ...baseContactListSagas,
    createContactSaga,
    createUnitAirportContactSaga,
    editContactSaga,
    editUnitAirportContactSaga,
    deleteUnitAirportContactSaga,
    deleteContactSaga,
]
