import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { USER_FORM_READY } from 'aos-services/src/services/users/types/AosUserFindStatus'

import {
    CHANGE_FORM_VALUE,
    DESELECT_ALL_GROUPS,
    HIDE_USER_MODAL,
    SELECT_ALL_GROUPS,
    SET_CONFLICT_USER_ID,
    SET_FORM_PRISTINE,
    SET_USER_DATA,
    SET_USER_STATUS,
    SHOW_USER_EMPTY_MODAL,
    SHOW_USER_MODAL,
    UPDATE_NOTIFICATIONS_SETTINGS,
} from '../actions'
import {
    emptyAdUser,
    initEditAdUserState,
    initialAddAdUserState,
    ManageAdUserState,
    validateAdUser,
} from '../state'
import { AdUserAction, SEARCH_USER_SET_RESULT, SHOW_USER_MODAL_WITH_AD_USER } from './actions'

export function adUserReducer(
    state = initialAddAdUserState,
    action: AdUserAction,
): ManageAdUserState {
    switch (action.type) {
        case SHOW_USER_MODAL:
            return { ...state, isOpen: true }

        case SHOW_USER_EMPTY_MODAL:
            return { ...initialAddAdUserState, isOpen: true }

        case SHOW_USER_MODAL_WITH_AD_USER:
            return {
                ...initEditAdUserState(action.payload),
                isOpen: true,
            }

        case HIDE_USER_MODAL:
            return { ...state, isOpen: false }

        case SET_FORM_PRISTINE:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
            }

        case SET_USER_STATUS:
            return {
                ...state,
                user: { ...state.user, userStatus: action.payload },
            }

        case SET_CONFLICT_USER_ID:
            return {
                ...state,
                conflictUserId: action.payload,
            }

        case SELECT_ALL_GROUPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedGroupsIds: [...action.payload],
                },
            }

        case DESELECT_ALL_GROUPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedGroupsIds: [],
                },
            }

        case CHANGE_FORM_VALUE:
            return {
                ...state,
                user: action.payload,
                form: state.form.modify(validateAdUser(action.payload, action.payload.userStatus)),
            }

        case SET_USER_DATA: {
            const user = { ...emptyAdUser, ...action.payload } as AdUserFormData
            return {
                ...state,
                user: {
                    ...user,
                },
                form: FormValidation.fromFields(validateAdUser(user, user.userStatus)),
                conflictUserId: undefined,
            }
        }

        case SEARCH_USER_SET_RESULT: {
            const user = {
                ...state.user,
                ...action.payload,
                userStatus: USER_FORM_READY,
            } as AdUserFormData
            return {
                ...state,
                user: {
                    ...user,
                },
                form: FormValidation.fromFields(validateAdUser(user, user.userStatus)),
            }
        }

        case UPDATE_NOTIFICATIONS_SETTINGS: {
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings!,
                    ...action.payload,
                },
            }
        }

        default:
            return state
    }
}
