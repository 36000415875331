import { firebaseCollectionReducer } from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'

import {
    CLOSE_NOTIFICATIONS_CONTAINER,
    LOAD_MORE_SUCCESS,
    NOTIFICATIONS_SYNC_SUCCESS,
    NotificationsAction,
    OPEN_NOTIFICATIONS_CONTAINER,
    PIN_NOTIFICATIONS_CONTAINER,
    SET_NOTIFICATIONS_SUCCESS,
} from './actions'
import { initialNotificationsState, NotificationsState } from './state'

export function notificationsReducer(
    state = initialNotificationsState,
    action: NotificationsAction,
): NotificationsState {
    switch (action.type) {
        case NOTIFICATIONS_SYNC_SUCCESS:
            return {
                ...state,
                notifications: firebaseCollectionReducer(state.notifications, action.payload),
            }

        case OPEN_NOTIFICATIONS_CONTAINER:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isOpen: true,
                },
                lazyLoadedNotifications: [],
            }

        case CLOSE_NOTIFICATIONS_CONTAINER:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isOpen: false,
                },
            }

        case PIN_NOTIFICATIONS_CONTAINER:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isPinned: action.payload,
                },
            }

        case SET_NOTIFICATIONS_SUCCESS:
            return { ...state, notifications: action.payload }

        case LOAD_MORE_SUCCESS:
            const firstElementsCount = action.payload.length - state.notifications.length
            return {
                ...state,
                lazyLoadedNotifications: action.payload.splice(0, firstElementsCount),
            }

        default:
            return state
    }
}
