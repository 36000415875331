import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { DashboardItemState } from '../../../services/statusDashboard/types/DashboardItemState'
import {
    DashboardProcessType,
    translatePresetType,
} from '../../../services/statusDashboard/types/DashboardPreset'
import { DashboardItemTypeEntry } from './DashboardItemTypeEntry'

interface StatusDashboardItemGroupProps {
    type: DashboardProcessType
    states: DashboardItemState[]
    availableItems: DashboardItemType[]
    addDashboardItem(v: DashboardItemType): void
}

export const DashboardItemGroup: FC<StatusDashboardItemGroupProps> = ({
    type,
    states,
    addDashboardItem,
    availableItems,
}) => (
    <Box paddingBottom={30}>
        <Text size={18} color={Color.White} weight='light' marginBottom={12}>
            {translatePresetType('dashboard.widget-type')(type)}
        </Text>
        <ul>
            {availableItems.map(itemType => (
                <li key={itemType}>
                    <DashboardItemTypeEntry
                        type={itemType}
                        numberOfItems={states.filter(item => item.type === itemType).length}
                        addDashboardItem={addDashboardItem}
                    />
                </li>
            ))}
        </ul>
    </Box>
)
