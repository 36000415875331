import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from '../../base/Box'
import { LightScrollbar } from '../../scrollbar/LightScrollbar'

export const FormSidebar: FCWithChildren<BoxProps> = ({ children, ...props }) => (
    <FormSidebarBox bg={Color.Grey1} fullHeight {...props}>
        <LightScrollbar>
            <Box paddingVertical={20} paddingHorizontal={30}>
                {children}
            </Box>
        </LightScrollbar>
    </FormSidebarBox>
)

const FormSidebarBox = styled(Box)`
    width: 320px;
`
