import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import {
    FilterOptionAll,
    StringFilterWithAllOption,
    translateStringFilterWithAllOption,
} from './common'
import { HandlingAgentFilter } from './HandlingAgentFilter'

export interface BaseBaggageDeliveryFilters {
    timeRange: BaggageDeliveryTimeRange
    handlingAgent: HandlingAgentFilter
    beltArea: BeltAreaFilter
}

export interface BaggageDeliveryFilters extends BaseBaggageDeliveryFilters {
    fullViewTab: BaggageDeliveryFullViewTab
}

export enum BaggageDeliveryTimeRange {
    Today = 0,
    Last1 = -1,
    Last3 = -3,
    Last8 = -8,
}

export const translateBaggageDeliveryTimeRange = translateEnum<BaggageDeliveryTimeRange>(
    BaggageDeliveryTimeRange,
    'dashboard.baggage-delivery.time-range-tab',
)

export enum BaggageDeliveryFullViewTab {
    History = 1,
    Flights,
}

export type BeltAreaFilter = StringFilterWithAllOption
export const beltAreaFilterValues = (beltAreaValues: string[]): BeltAreaFilter[] =>
    EnumValues.getValues<BeltAreaFilter>(FilterOptionAll).concat(beltAreaValues)

export const translateBeltArea = translateStringFilterWithAllOption(
    'dashboard.baggage-delivery.belt-area-tab',
)
