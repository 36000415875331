import {
    createInitialSingleInputModalState,
    SingleInputModalState,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/state'
import { AbstractEntity } from 'aos-services/src/services/base/types/AbstractEntity'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import {
    AosChecklistTemplate,
    AosSimpleTemplate,
} from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import {
    AosUnitTaskItem,
    AosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { ChecklistTemplateItemEditPayload } from 'aos-services/src/services/checklists/types/input/ChecklistTemplateItemEditPayload'
import { ChecklistTemplateEditPayload } from 'aos-services/src/services/checklists/types/input/CheclistTemplatePayload'

import {
    createInitialTaskOrDescriptionModalState,
    TaskOrDescriptionModalState,
} from './addTaskOrDescription/state'
import {
    createInitialMessageTemplateModalState,
    MessageTemplateModalState,
} from './editMessageTemplate/state'

export interface UnitTaskManagerState {
    unitTaskTemplates: AosUnitTaskTemplate[]
    isCreateUnitTaskItemModalOpen: boolean
    createdUnitTaskItem?: AosUnitTaskItem
    lockSeverityChange: boolean
    isEditItemModalOpen: boolean
    itemToEdit?: AosUnitTaskItem
}

export interface EventChecklistManagerState {
    checklistTemplates: AosSimpleTemplate[]
    isCreateChecklistTemplateModalOpen: boolean
    editedChecklistTemplate?: ChecklistTemplateEditPayload
}

export interface TaskChecklistManagerState {
    isCreateModalOpen: boolean
    isEditModalOpen: boolean
    editedTaskChecklistTemplate?: AosTaskChecklistTemplate
    taskChecklistTemplates: AosTaskChecklistTemplate[]
}

export interface ChecklistManagerState {
    exportingChecklistTemplates: boolean
    headerFilter: AosChecklistFilter
    selectedItemFromTemplate?: AosSimpleTemplate | AbstractEntity
    selectedTemplate?: AosChecklistTemplate | AosUnitTaskTemplate | AosTaskChecklistTemplate
    editTaskOrDescriptionModal: SingleInputModalState<ChecklistTemplateItemEditPayload>
    createTaskOrDescriptionModal: TaskOrDescriptionModalState
    editMessageTemplateModal: MessageTemplateModalState
    unitTaskManager: UnitTaskManagerState
    eventChecklistManager: EventChecklistManagerState
    taskChecklistManager: TaskChecklistManagerState
}

export interface ChecklistManagerStateAware {
    checklistManager: ChecklistManagerState
}

export const initialUnitTaskManagerState: UnitTaskManagerState = {
    unitTaskTemplates: [],
    isCreateUnitTaskItemModalOpen: false,
    lockSeverityChange: false,
    isEditItemModalOpen: false,
}

export const initialTaskChecklistManagerState: TaskChecklistManagerState = {
    taskChecklistTemplates: [],
    isCreateModalOpen: false,
    isEditModalOpen: false,
}

export const initialEventChecklistManagerState: EventChecklistManagerState = {
    checklistTemplates: [],
    isCreateChecklistTemplateModalOpen: false,
}

export const initialChecklistManagerState: ChecklistManagerState = {
    exportingChecklistTemplates: false,
    headerFilter: AosChecklistFilter.EventChecklist,
    editTaskOrDescriptionModal: createInitialSingleInputModalState(),
    createTaskOrDescriptionModal: createInitialTaskOrDescriptionModalState(),
    editMessageTemplateModal: createInitialMessageTemplateModalState(),
    unitTaskManager: initialUnitTaskManagerState,
    taskChecklistManager: initialTaskChecklistManagerState,
    eventChecklistManager: initialEventChecklistManagerState,
}
