import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { FC } from 'react'
import React from 'react'
import styled from 'styled-components'

interface LayerControlProps {
    onClick(): void
}
export const LayerControl: FC<LayerControlProps> = ({ onClick }) => {
    return (
        <LayerControlWrapper onClick={onClick}>
            <Icon svg={SvgIcon.Layers} iconVariant={IconVariant.Black} iconSize={BlockSize.Std} />
        </LayerControlWrapper>
    )
}

const LayerControlWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background: white;
    border-radius: 4px;
    padding: 4px 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    :hover {
        background: #f5f5f5;
    }
    :active {
        background: #e5e5e5;
    }
`
