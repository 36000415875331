import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { FlightsType } from '../../../airportStatus/flights/types/FlightsType'
import { AirlineFilter } from './AirlineFilter'
import { HandlingAgentFilter } from './HandlingAgentFilter'

export interface BaseCommonFlightFilters {
    airline: AirlineFilter
    handlingAgent: HandlingAgentFilter
    flightType: FlightsType
    fullViewTab: CommonFlightView
    timeRange: CommonTimeRange
}

export type CommonTimeRange = FlightsHistoryTimeRange | FlightsPredictionTimeRange

export interface FlightFooterConfiguration {
    small: FlightFilterConfigOption[]
    history: FlightFilterConfigOption[]
    flights: FlightFilterConfigOption[]
}

export enum FlightsHistoryTimeRange {
    Today = 0,
    Last1 = -1,
    Last3 = -3,
    Last8 = -8,
}

export enum FlightsPredictionTimeRange {
    Today = 0,
    Last1 = -1,
    Last3 = -3,
    Last8 = -8,
    Next1 = 1,
    Next3 = 3,
    Next8 = 8,
    Next16 = 16,
}

export enum CommonFlightView {
    History = 1,
    Flights,
}

export enum FlightFilterConfigOption {
    HistoryTimeRange = 1,
    PredictionTimeRange,
    FlightType,
    Airline,
    HandlingAgent,
}

export const flightTypeFilterValues: FlightsType[] = EnumValues.getValues(FlightsType)

export const translateFlightType = translateEnum(
    FlightsType,
    'dashboard.flight-filters.flights-type',
)

export const translateTimeRange = translateEnum<CommonTimeRange>(
    FlightsPredictionTimeRange,
    'dashboard.flight-filters.time-range-tab',
)

export const translateFullView = translateEnum<CommonFlightView>(
    CommonFlightView,
    'dashboard.flight-filters.full-view-tab',
)

export const flightsTimeRangeToHoursCount = (range: CommonTimeRange, now: DateTime) => {
    if (range === 0) {
        return Math.max(now.diff(now.clone().startOf('day'), 'hour'), 1)
    }
    return Math.abs(range)
}
