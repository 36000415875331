import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { svgIconForName, SvgIconType } from 'aos-ui/src/components/svg/Icon'

const channelIconMapping: Record<AosMessageChannel, SvgIconType> = {
    [AosMessageChannel.Notification]: 'ChannelNotification',
    [AosMessageChannel.Push]: 'ChannelPush',
    [AosMessageChannel.Sms]: 'ChannelSms',
    [AosMessageChannel.Email]: 'ChannelMail',
}

export const messageChannelIcon = (c: AosMessageChannel) => svgIconForName(channelIconMapping[c])
