import React, { PureComponent } from 'react'

export class TrainWithDirectionBgIcon extends PureComponent<TrainWithDirectionBgIconProps> {
    public render() {
        const { color, withBorder } = this.props

        // tslint:disable:max-line-length
        return (
            <svg
                width='32'
                height='48'
                viewBox='0 0 32 48'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
            >
                <path
                    fill={color}
                    fillRule='evenodd'
                    d='M15.844 16.494c-8.7 0-15.753 7.053-15.753 15.753S7.144 48 15.844 48s15.753-7.053 15.753-15.753-7.052-15.753-15.753-15.753zm.015-13.002L9.763 14.324a14.043 14.043 0 0 1 6.096-1.38c2.127 0 4.307.46 6.54 1.38l-6.54-10.832z'
                />
                {withBorder && (
                    <path
                        stroke='#FFF'
                        strokeWidth='2'
                        fill='none'
                        d='M15.844 16.494c-8.7 0-15.753 7.053-15.753 15.753S7.144 48 15.844 48s15.753-7.053 15.753-15.753-7.052-15.753-15.753-15.753zm.015-13.002L9.763 14.324a14.043 14.043 0 0 1 6.096-1.38c2.127 0 4.307.46 6.54 1.38l-6.54-10.832z'
                    />
                )}
            </svg>
        )
        // tslint:enable
    }
}

interface TrainWithDirectionBgIconProps {
    color: string
    withBorder?: boolean
}
