import { Box, MarginBoxProps, marginStyleGenerator } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { CSSObject } from 'styled-components'

export enum ColorIndicatorColor {
    Green = 'green',
    Blue = 'blue',
    Grey = 'grey',
    Red = 'red',
    Yellow = 'yellow',
}

export enum ColorIndicatorType {
    Dot = 'dot',
    Circle = 'circle',
    BigDot = 'big-dot',
}

export interface ColorIndicatorProps extends MarginBoxProps {
    color: Color
    isAbsolutePositioned?: boolean
    type?: ColorIndicatorType
}

export const ColorIndicator = styled(Box)<ColorIndicatorProps>(
    ({ isAbsolutePositioned, color, type = ColorIndicatorType.Dot, ...marginProps }) => {
        const styles: CSSObject = {
            display: 'inline-block',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            verticalAlign: 'baseline',
        }

        if (isAbsolutePositioned) {
            styles.position = 'absolute'
            styles.left = '10px'
            styles.top = '10px'
        }

        const typeStyle: Record<ColorIndicatorType, CSSObject> = {
            [ColorIndicatorType.Dot]: {
                width: '6px',
                height: '6px',
                background: color,
            },
            [ColorIndicatorType.Circle]: {
                width: '12px',
                height: '12px',
                borderWidth: '2px',
                borderStyle: 'solid',
                background: 'transparent',
                borderColor: color,
            },
            [ColorIndicatorType.BigDot]: {
                width: '12px',
                height: '12px',
                background: color,
            },
        }

        const margins = marginStyleGenerator(marginProps)

        return { ...styles, ...margins, ...typeStyle[type] }
    },
)
