import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { TaskFormPayload } from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import {
    defaultTaskRecurrence,
    TaskRecurrencePayload,
    TaskRecurrenceValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceFormPayload'
import {
    defaultTaskRecurrenceHourly,
} from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceHourlyFormPayload'
import {
    allTaskRecurrenceTypes,
    translateTaskRecurrenceType,
} from 'aos-services/src/services/tasks/types/task/TaskRecurrence'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { DurationInput } from 'aos-ui/src/components/form/datetime/DurationInput'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'
import { TaskRecurrenceHourly } from './TaskRecurrenceHourly'
import { TaskRecurrenceMonthly } from './TaskRecurrenceMonthly'
import { TaskRecurrenceWeekly } from './TaskRecurrenceWeekly'
import { TaskRecurrenceYearly } from './TaskRecurrenceYearly'

interface TaskRecurrenceProps {
    task: TaskFormPayload
    recurrenceChangeable: boolean

    changeForm(task: Partial<TaskFormPayload>): void

    errors?: TaskRecurrenceValidation
    recurrenceScheduleError?: boolean
    disabled?: boolean
}

export const TaskRecurrence: FC<TaskRecurrenceProps> = ({
                                                            task,
                                                            changeForm,
                                                            recurrenceChangeable,
                                                            errors,
                                                            recurrenceScheduleError,
                                                            disabled,
                                                        }) => {
    const toggle = (enabled: boolean) => {
        changeForm({ recurrence: enabled ? defaultTaskRecurrenceHourly : undefined })
    }

    const changeType = (type: TaskRecurrenceType) => {
        const recurrence = defaultTaskRecurrence[type]
        changeForm({ recurrence })
    }

    const updateRecurrence = (value: Partial<TaskRecurrencePayload>) => {
        const recurrence = { ...task.recurrence, ...value } as TaskRecurrencePayload
        changeForm({ recurrence })
    }

    const RecurrenceTypeFromComponent = task.recurrence && recurrenceTypeFrom[task.recurrence.type]

    return (
        <>
            <Box justify='space-between' marginBottom={16} row>
                <Text size={16} color={Color.Black}>
                    {translate('tasks.recurrence')}
                </Text>

                {task.severity === AosSeverity.Emergency ? (
                    <Tooltip
                        white
                        placement='top'
                        body={translate('tasks.recurrence.disabled')}
                        withArrow
                    >
                        <div>
                            <Toggle value={!!task.recurrence} onChange={toggle} disabled />
                        </div>
                    </Tooltip>
                ) : (
                    <Toggle
                        value={!!task.recurrence}
                        onChange={toggle}
                        disabled={!recurrenceChangeable || disabled}
                    />
                )}
            </Box>

            {task.recurrence && (
                <LabeledFormElement
                    label={translate('tasks.repeats')}
                    marginBottom={8}
                    errorMessage={translate('tasks.recurrence.error')}
                    isError={recurrenceScheduleError}
                >
                    <Dropdown
                        value={task.recurrence.type}
                        items={allTaskRecurrenceTypes}
                        valueRenderer={translateTaskRecurrenceType}
                        onChange={changeType}
                        outlined
                    />
                </LabeledFormElement>
            )}

            {RecurrenceTypeFromComponent && (
                <RecurrenceTypeFromComponent
                    value={task.recurrence!}
                    onChange={updateRecurrence}
                    errors={errors}
                    canEdit
                />
            )}

            {task.recurrence && (
                <LabeledFormElement
                    label={translate('tasks.duration')}
                    marginTop={8}
                    marginBottom={16}
                >
                    <DurationInput
                        value={task.recurrence.duration}
                        onChange={duration => updateRecurrence({ duration })}
                    />
                </LabeledFormElement>
            )}
        </>
    )
}

const recurrenceTypeFrom: Record<TaskRecurrenceType, FC<TaskRecurrenceFormProps>> = {
    [TaskRecurrenceType.Hourly]: TaskRecurrenceHourly,
    [TaskRecurrenceType.Weekly]: TaskRecurrenceWeekly,
    [TaskRecurrenceType.Monthly]: TaskRecurrenceMonthly,
    [TaskRecurrenceType.Yearly]: TaskRecurrenceYearly,
}
