import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { runwayCombinationSelector } from 'aos-services/src/core/statusDashboardData/runway/selectors'
import { getRunwaysByMode, RunwayMode } from 'aos-services/src/services/runways/types/RunwayMode'
import { RunwaysState } from 'aos-services/src/services/runways/types/RunwaysState'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { RunwayItem } from './partial/RunwayItem'
import { RunwayModes } from './partial/RunwayModes'
import { RunwayClosureCarousel } from './RunwaysClosureCarousel'
import { RunwaysMetar } from './RunwaysMetar'

interface RunwaysTileStateProps {
    runwaysState?: RunwaysState
    metar: string
}

interface RunwaysTileProps extends RunwaysTileStateProps, StatusDashboardItemCommonProps {}

export const RunwaysTileComponent: FC<RunwaysTileProps> = props => {
    const link = generatePath(Link.StatusDashboardPreset, {
        preset: DashboardProcessType.Operations,
    })

    return (
        <RunwayItem
            syncId='overview'
            runwaysState={props.runwaysState}
            itemProps={pickDashboardItemProps(props)}
            link={link}
        >
            {props.runwaysState && props.runwaysState.combinationDetails && (
                <ItemContainer horizontalSpacing horizontal>
                    <Box>
                        <Box row>
                            <RunwayModes
                                className='margin-right--large'
                                type='arrivals'
                                runways={getRunwaysByMode(
                                    props.runwaysState.combinationDetails,
                                    RunwayMode.Arrival,
                                )}
                            />
                            <RunwayModes
                                type='departures'
                                runways={getRunwaysByMode(
                                    props.runwaysState.combinationDetails,
                                    RunwayMode.Departure,
                                )}
                                conditionalRunways={getRunwaysByMode(
                                    props.runwaysState.combinationDetails,
                                    RunwayMode.Conditional,
                                )}
                            />
                        </Box>
                        <RunwaysMetar metar={props.metar} />

                        {props.runwaysState.runwayClosure && (
                            <RunwayClosureCarousel
                                runwayClosure={props.runwaysState.runwayClosure}
                            />
                        )}
                    </Box>
                </ItemContainer>
            )}
        </RunwayItem>
    )
}

export const RunwaysTile = connect<RunwaysTileStateProps, {}, StatusDashboardItemCommonProps>(
    runwayCombinationSelector,
    {},
)(RunwaysTileComponent)
