import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { CdmMetrics } from '../../../services/airportStatus/cdm/types/CdmMetrics'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const CDM_SYNC = 'STATUS_DASHBOARD_DATA/CDM/CDM_SYNC'
export type CdmSyncAction = PayloadAction<typeof CDM_SYNC, SyncDataKey>
export const cdmSyncAction = payloadActionCreator<CdmSyncAction, SyncDataKey>(CDM_SYNC)

export const CDM_SYNC_STOP = 'STATUS_DASHBOARD_DATA/CDM/CDM_SYNC_STOP'
export type CdmSyncStopAction = PayloadAction<typeof CDM_SYNC_STOP, SyncDataKey>
export const cdmSyncStopAction = payloadActionCreator<CdmSyncStopAction, SyncDataKey>(CDM_SYNC_STOP)

export const CDM_LOAD = 'STATUS_DASHBOARD_DATA/CDM/CDM_LOAD'
export type CdmLoadAction = Action<typeof CDM_LOAD>
export const cdmLoadAction = emptyActionCreator<CdmLoadAction>(CDM_LOAD)

export const CDM_LOAD_SUCCESS = 'STATUS_DASHBOARD_DATA/CDM/CDM_LOAD_SUCCESS'
export type CdmLoadSuccessAction = PayloadAction<typeof CDM_LOAD_SUCCESS, CdmMetrics>
export const cdmLoadSuccessAction = payloadActionCreator<CdmLoadSuccessAction, CdmMetrics>(
    CDM_LOAD_SUCCESS,
)

export const TOBT_SYNC = 'STATUS_DASHBOARD_DATA/CDM/TOBT_SYNC'
export type TobtSyncAction = PayloadAction<typeof TOBT_SYNC, SyncDataKey>
export const tobtSyncAction = payloadActionCreator<TobtSyncAction, SyncDataKey>(TOBT_SYNC)

export const TOBT_SYNC_STOP = 'STATUS_DASHBOARD_DATA/CDM/TOBT_SYNC_STOP'
export type TobtSyncStopAction = PayloadAction<typeof TOBT_SYNC_STOP, SyncDataKey>
export const tobtSyncStopAction = payloadActionCreator<TobtSyncStopAction, SyncDataKey>(
    TOBT_SYNC_STOP,
)

export const TOBT_LOAD = 'STATUS_DASHBOARD_DATA/CDM/TOBT_LOAD'
export type TobtLoadAction = Action<typeof TOBT_LOAD>
export const tobtLoadAction = emptyActionCreator<TobtLoadAction>(TOBT_LOAD)

export const TOBT_LOAD_SUCCESS = 'STATUS_DASHBOARD_DATA/CDM/TOBT_LOAD_SUCCESS'
export type TobtLoadSuccessAction = PayloadAction<typeof TOBT_LOAD_SUCCESS, any>
export const tobtLoadSuccessAction = payloadActionCreator<TobtLoadSuccessAction, any>(
    TOBT_LOAD_SUCCESS,
)

export type CdmAction =
    | CdmSyncAction
    | CdmSyncStopAction
    | CdmLoadAction
    | CdmLoadSuccessAction
    | TobtSyncAction
    | TobtSyncStopAction
    | TobtLoadAction
    | TobtLoadSuccessAction
