import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { channelTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { NewCommonMessage } from 'aos-services/src/services/messages/input/NewAosMessage'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { CleanableTextarea } from 'aos-ui/src/components/form/input/CleanableTextarea'
import { xor } from 'lodash'
import React, { PureComponent } from 'react'

import { MessageChannelSelector } from './MessageChannelSelector'
import { MessageConfirmationModal } from './MessageConfirmationModal'

interface MessageEditorProps extends ClassNameProps {
    message: NewCommonMessage
    groups: AosUserGroup[]
    defaultGroupId: number
    availableChannels: AosMessageChannel[]
    isMessageValid?: f.Func1<NewCommonMessage, boolean>
    isSendingMessage: boolean
    isConfirmationVisible: boolean
    onChange(v: NewCommonMessage): void
    onSubmit(): void
    toggleMessageConfirmation(v: boolean): void
}

export class MessageEditor extends PureComponent<MessageEditorProps> {
    public render() {
        const { message, availableChannels, groups } = this.props

        return (
            <Box className={cxp(this.props)}>
                <Box paddingBottom={16}>
                    <DropdownAutocomplete
                        variant={DropdownVariant.Black}
                        placeholder={translate('feed-out.group.placeholder')}
                        items={groups}
                        labelRenderer={g => `${aosUserGroupName(g, 'select')} (${g.userCount})`}
                        value={this.getSelectedGroup()}
                        onChange={this.handleChangeGroup}
                        maxHeight={200}
                    />
                </Box>
                <CleanableTextarea
                    resizeable
                    rows={4}
                    variant={ThemeVariant.Black}
                    placeholder={translate('feed-out.text.placeholder')}
                    value={message.text}
                    onChangeText={this.handleChangeText}
                />
                <Box row paddingTop={12}>
                    <MessageChannelSelector
                        channels={availableChannels}
                        onChange={this.handleToggleCheckbox}
                        channelsState={message.channels}
                    />
                </Box>
                <FormButton
                    label={this.getSubmitLabel()}
                    onClick={this.handleSubmit}
                    disabled={!this.isValidMessage()}
                    fullWidth
                />
                {this.renderConfirmationModal()}
            </Box>
        )
    }

    private getSubmitLabel(): string {
        const channels = this.props.message.channels
            .map(enumToName(AosMessageChannel))
            .map(channelTitle)
            .join(' + ')

        return `${translate('message-editor.send')} ${channels}`
    }

    private renderConfirmationModal() {
        const group = this.getSelectedGroup()
        const { isConfirmationVisible, message, onSubmit, isSendingMessage } = this.props

        return (
            <MessageConfirmationModal
                isOpen={isConfirmationVisible}
                closeAction={this.handleCloseConfirmation}
                group={group}
                message={message}
                onSubmit={onSubmit}
                isSendingMessage={isSendingMessage}
            />
        )
    }

    private handleChangeText = (text: string) => {
        this.onChange({ text })
    }

    private handleToggleCheckbox = (channel: AosMessageChannel) => {
        const { message } = this.props
        return this.onChange({ channels: xor(message.channels, [channel]) })
    }

    private handleChangeGroup = (v?: AosUserGroup) => {
        this.onChange({ groupId: v?.id })
    }

    private handleCloseConfirmation = () => {
        if (!this.props.isSendingMessage) {
            this.props.toggleMessageConfirmation(false)
        }
    }

    private handleSubmit = () => {
        this.props.toggleMessageConfirmation(true)
    }

    private getSelectedGroup(): AosUserGroup | undefined {
        const groupId = this.props.message.groupId || this.props.defaultGroupId
        return this.props.groups.find(g => g.id === groupId)
    }

    private isValidMessage(): boolean {
        const { message, isMessageValid } = this.props
        const externalValidation = isMessageValid ? isMessageValid(message) : true

        const group = this.getSelectedGroup()
        if (group) {
            const hasUsers = group.userCount > 0

            return !!(message.text && hasUsers && externalValidation)
        }
        return false
    }

    private onChange = (message: Partial<NewCommonMessage>) => {
        this.props.onChange({
            ...this.props.message,
            ...message,
        })
    }
}
