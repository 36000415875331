import _ from 'lodash'
import { MapBrowserEvent } from 'ol'
import BaseEvent from 'ol/events/Event'
import Feature from 'ol/Feature'
import { EventTypes } from 'ol/Observable'
import RenderFeature from 'ol/render/Feature'

import { BaseComponent } from './BaseComponent'

export abstract class BaseMapInteractionHandler<
    T extends BaseMapInteractionHandlerProps,
    C,
    U = {},
> extends BaseComponent<T, U> {
    private isListening: boolean = false
    private featureConverter: FeatureConverter<C>
    private listeners: Listener[]

    constructor(props: T, featureConverter: FeatureConverter<C>, listeners: Listener[]) {
        super(props)
        this.featureConverter = featureConverter
        this.listeners = listeners
    }

    public componentDidMount() {
        this.toggleListeners(this.props.isActive)
    }

    public componentWillReceiveProps(next: BaseMapInteractionHandlerProps) {
        this.toggleListeners(next.isActive)
    }

    protected onEventFeatureClick(param: BaseInteractionCallbackParam<C>): void {
        _.noop(param)
    }

    protected onEventFeatureHover(param: BaseInteractionCallbackParam<C>): void {
        _.noop(param)
    }

    protected displayPointer = (event: MapBrowserEvent<UIEvent>) => {
        const hit = this.context.map.hasFeatureAtPixel(event.pixel)
        const target = this.context.map.getTarget() as HTMLDivElement
        target.style.cursor = hit ? 'pointer' : ''
    }

    private toggleListeners = (active: boolean) => {
        if (active !== this.isListening) {
            const { map } = this.context
            const handlers = { click: this.clickHandler, pointermove: this.pointMoveHandler }
            _.forEach(handlers, (handler, event) => {
                if (this.listeners.indexOf(event as Listener) >= 0) {
                    return active
                        ? map.on([event as EventTypes], handler)
                        : map.un([event as EventTypes], handler)
                }
            })
            this.isListening = active
        }
    }

    private pointMoveHandler = (e: Event | BaseEvent) => {
        if ((e as MapBrowserEvent<UIEvent>).dragging) {
            return
        }
        this.onEventFeatureHover(this.getPayloadForEvent(e as MapBrowserEvent<UIEvent>))
    }

    private clickHandler = (e: Event | BaseEvent) => {
        this.onEventFeatureClick(this.getPayloadForEvent(e as MapBrowserEvent<UIEvent>))
    }

    private getPayloadForEvent = (e: MapBrowserEvent<UIEvent>) => {
        const features = this.context.map.getFeaturesAtPixel(e.pixel)
        const convertedFeatures = this.featureConverter(features)
        convertedFeatures.map(f => f[0])
        return {
            event: e,
            features: convertedFeatures.map(f => f[0]),
            items: convertedFeatures.map(f => f[1]),
        }
    }
}

export type Listener = 'click' | 'pointermove'

export type FeatureConverter<T> = (
    features: (Feature | RenderFeature)[],
) => [Feature | RenderFeature, T][]

export interface BaseInteractionCallbackParam<T> {
    event: MapBrowserEvent<UIEvent>
    features: (Feature | RenderFeature)[]
    items: T[]
}

export interface BaseMapInteractionHandlerProps {
    isActive: boolean
}
