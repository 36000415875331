import { busDoorNumber } from 'aos-services/src/services/layerData/properties/BusDoorProperties'
import {
    SelectedMapElement,
    SelectedMapElementType,
} from 'aos-services/src/services/map/types/SelectedMapElement'

import { IconHeight, travelServiceMapper } from './MapFeatureIconBuilder'
import {
    renderBusDoorIcon,
    renderEventIcon,
    renderGateIcon,
    renderStandIcon,
    renderSvgIconWithShadow,
} from './MapIconRenderer'

export const renderSelectionTargetIcon = (
    selectedElement: SelectedMapElement,
): Promise<string | undefined> => {
    if (selectedElement.payload) {
        switch (selectedElement.type) {
            case SelectedMapElementType.Event:
                return renderEventIcon(selectedElement.payload.event)
            case SelectedMapElementType.Gate:
                return renderGateIcon(selectedElement.payload.Gate, IconHeight.Gate)

            case SelectedMapElementType.BusGate:
                return renderGateIcon(selectedElement.payload.Gate, IconHeight.Gate)

            case SelectedMapElementType.Stand:
                return renderStandIcon(selectedElement.payload.teksti)

            case SelectedMapElementType.BusDoor:
                return renderBusDoorIcon(busDoorNumber(selectedElement.payload))

            case SelectedMapElementType.BaggageClaim:
                return renderSvgIconWithShadow(
                    travelServiceMapper(selectedElement.payload),
                    IconHeight.TravelServices,
                )

            default:
                return Promise.resolve(undefined)
        }
    } else {
        return Promise.resolve(undefined)
    }
}
