import { mapRecordValues } from 'aos-helpers/src/helpers/Record'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { mapValues } from 'lodash'
import { Duration } from 'moment'
import moment from 'moment-timezone'

import { FirebaseHealthForAirportItemType } from '../../dataaccess/widgetHealths/types/FirebaseHealthForAirportItemType'
import { FirebaseHealthItemType } from '../../dataaccess/widgetHealths/types/FirebaseHealthItemType'
import { WidgetHealthsDto } from '../../dataaccess/widgetHealths/types/WidgetHealthsDto'
import {
    DashboardItemHealths,
    DashboardItemHealthsForAirport,
    WidgetHealth,
    WidgetHealths,
} from './type/DashboardItemHealths'
import { DashboardItemWithHealthChecks } from './type/DashboardItemWithHealthChecks'
import { HealthConfig, isHealthConfigForAirport } from './type/HealthConfig'

class WidgetHealthsService {
    private healthConfigs: Record<DashboardItemWithHealthChecks, HealthConfig> = {
        [DashboardItemWithHealthChecks.Punctuality]: {
            healths: [FirebaseHealthItemType.FLIGHT_STATS],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.Delays]: {
            healths: [FirebaseHealthItemType.FLIGHT_STATS],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.Flights]: {
            healths: [FirebaseHealthItemType.FLIGHT_STATS],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.Runways]: {
            healths: [FirebaseHealthItemType.RUNWAYS],
            duration: moment.duration(15, 'm'),
        },
        [DashboardItemWithHealthChecks.Cdm]: {
            healths: [FirebaseHealthItemType.CDM],
            duration: moment.duration(15, 'm'),
        },
        [DashboardItemWithHealthChecks.Pax]: {
            healths: [FirebaseHealthItemType.PAX],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.PaxForecast]: {
            healths: [FirebaseHealthItemType.PAX_FORECAST],
            duration: moment.duration(10, 'days'),
        },
        [DashboardItemWithHealthChecks.BaggageDelivery]: {
            healths: [FirebaseHealthItemType.FLIGHT_STATS],
            duration: moment.duration(15, 'm'),
        },
        [DashboardItemWithHealthChecks.Regularity]: {
            healths: [FirebaseHealthItemType.FLIGHT_STATS],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.Weather]: {
            healthsForAirport: [
                FirebaseHealthForAirportItemType.WEATHER_CURRENT,
                FirebaseHealthForAirportItemType.WEATHER_FORECAST,
            ],
            duration: moment.duration(1, 'h'),
        },
        [DashboardItemWithHealthChecks.Notam]: {
            healths: [FirebaseHealthItemType.NOTAM],
            duration: moment.duration(15, 'minutes'),
        },
    }

    public widgetHealthsForDto = (dto: WidgetHealthsDto) =>
        mapValues(dto, v => dateTime(v)) as DashboardItemHealths

    public widgetHealthsForAirportForDto = (dto: WidgetHealthsDto) =>
        mapValues(dto, v => dateTime(v)) as DashboardItemHealthsForAirport

    public getHealths = (
        healths: DashboardItemHealths,
        healthsForAirport: DashboardItemHealthsForAirport,
        currentTime: DateTime,
    ): WidgetHealths =>
        mapRecordValues(
            this.healthConfigs,
            (config): WidgetHealth =>
                isHealthConfigForAirport(config)
                    ? this.getWidgetHealth(
                          config.healthsForAirport,
                          healthsForAirport,
                          currentTime,
                          config.duration,
                      )
                    : this.getWidgetHealth(config.healths, healths, currentTime, config.duration),
        )

    private getWidgetHealth = <T extends FirebaseHealthItemType | FirebaseHealthForAirportItemType>(
        healthsToCheck: T[],
        healthValues: Record<T, DateTime | undefined>,
        currentTime: DateTime,
        maxAge: Duration,
    ) => {
        const lastChangedDates = healthsToCheck.map(item => healthValues[item])

        const isAnyOutdated = lastChangedDates.some(lastChangedDate => {
            const expirationDate = (lastChangedDate ?? currentTime).clone().add(maxAge)
            return expirationDate.isBefore(currentTime)
        })

        return {
            lastChanged: lastChangedDates[0],
            upToDate: !isAnyOutdated,
        }
    }
}

export const widgetHealthsService = new WidgetHealthsService()
