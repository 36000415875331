import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { ShiftNotesWidget } from './ShiftNotes'

export const ShiftNotesConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Big]: {
            minSize: { w: 5, h: 3 },
            maxSize: { w: 12, h: 12 },
        },
    },
    renderer: ShiftNotesWidget,
}
