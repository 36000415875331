import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'

interface SidebarButtonRowProps {
    icon: Svg
    onClick(): void
}

export const SidebarButtonRow: FC<PropsWithChildren<SidebarButtonRowProps>> = props => (
    <Text row marginVertical={4} lineHeight='standard' size={13} color={Color.Grey}>
        <Box flex={1}>{props.children}</Box>
        <IconButton
            iconSize={BlockSize.Std}
            svg={props.icon}
            iconVariant={IconVariant.White}
            onClick={props.onClick}
        />
    </Text>
)
