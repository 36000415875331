import { State } from 'aos-frontend/src/app/core/state'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { createSelector } from 'reselect'

import {
    getBlocksForForecastItems,
    getGroupsForForecastItems,
    OperationalForecast,
    OperationalForecastGroup,
    OperationalForecastGroupItems,
    OperationalForecastMode,
    OperationalItem,
} from '../../../services/operationalForecast/types/OperationalForecast'
import { FilterOptionAll } from '../../../services/statusDashboard/types/filters/common'
import {
    OperationalForecastFilters,
    OperationalForecastGroupFilter,
} from '../../../services/statusDashboard/types/filters/OperationalForecastFilters'
import { isApocSelector } from '../../auth/state'
import { ItemStateAware } from '../flights/selectors/common'
import { StatusDashboardDataStateAware } from '../state'

export const operationalForecastDataSelector = (state: StatusDashboardDataStateAware) =>
    state.statusDashboardData.operationalForecast
export const operationalForecastGroupOwnPropsSelector = (
    _0: State,
    ownProps: ItemStateAware<OperationalForecastFilters>,
) => ownProps.itemState.group

export type OperationalBlock = [
    OperationalForecastGroup,
    OperationalForecastMode,
    OperationalItem[],
]

export interface OperationalForecastTileSelectorState {
    blocks: OperationalBlock[]
    lastUpdated: DateTime
}

export interface OperationalForecastSelectorState {
    items: OperationalForecastGroupItems[]
    allItems: OperationalItem[]
    blocks: OperationalBlock[]
    lastUpdated: DateTime
    isApoc: boolean
}

export const operationalForecastTileSelector = createSelector(
    (state: StatusDashboardDataStateAware) => state.statusDashboardData.operationalForecast,
    forecast => ({
        lastUpdated: forecast.lastUpdated || dateTime(),
        blocks: getBlocksForForecastItems(forecast.items),
    }),
)

const getItemsForGroup = (items: OperationalItem[], group: OperationalForecastGroupFilter) =>
    getGroupsForForecastItems(items).filter(i =>
        group === FilterOptionAll.All ? true : i.group === group,
    )

export const operationalForecastMerger = (
    forecast: OperationalForecast,
    group: OperationalForecastGroupFilter,
    isApoc: boolean,
) => ({
    lastUpdated: forecast.lastUpdated || dateTime(),
    blocks: getBlocksForForecastItems(forecast.items),
    items: getItemsForGroup(forecast.items, group),
    allItems: forecast.items,
    isApoc,
})

export const operationalForecastSelector = createSelector(
    operationalForecastDataSelector,
    operationalForecastGroupOwnPropsSelector,
    isApocSelector,
    operationalForecastMerger,
)
