import {
    jumpToDateAction,
    jumpToNowAction,
    timelineScrollAction,
    timelineTimeChangeAction,
    zoomInAction,
    zoomOutAction,
} from 'aos-services/src/core/timeline/actions'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Timeline } from 'aos-ui/src/components/timeline/Timeline'
import { useDebounce } from 'aos-ui-common/src/components/hooks/useDebounce'
import { isEqual } from 'lodash'
import React, { FC } from 'react'
import { positionValues } from 'react-custom-scrollbars'
import { connect } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { openEventAction } from '../../core/eventEditor/actions'
import {
    eventTimelineTimelineParentAction,
    exportEventsAction,
    openTimelineAction,
    setFilterAction,
    syncTimelineAction,
    toggleGroupAction,
} from '../../core/eventTimeline/actions'
import {
    eventTimelineSelector,
    EventTimelineSelectorState,
} from '../../core/eventTimeline/selectors'
import { EventTimelineContent } from './EventTimelineContent'
import { EventTimelineHeader } from './EventTimelineHeader'

const EventTimelineClass: FC<EventTimelineSelectorState & EventTimelineDispatchProps> = props => {
    const {
        events,
        groups,
        currentTime,
        timelineState,
        savedPosition,
        openEvent,
        toggleGroup,
        setFilter,
        exportEvents,
        filter,
        timelineAction,
    } = props
    const saveScrollPosition = useDebounce((newPosition: positionValues) => {
        if (!isEqual(timelineState.savedPosition, newPosition)) {
            timelineAction(timelineScrollAction(newPosition))
        }
    }, 1000)
    const onTimeChange = (ts: number, te: number) =>
        timelineAction(timelineTimeChangeAction([ts, te]))
    return (
        <SyncWrapper
            onEnter={[openTimelineAction(), syncTimelineAction(true)]}
            onLeave={syncTimelineAction(false)}
        >
            <Box fullSize column>
                <EventTimelineHeader
                    currentTime={currentTime}
                    zoomIn={() => timelineAction(zoomInAction())}
                    zoomOut={() => timelineAction(zoomOutAction())}
                    setFilter={setFilter}
                    onSelectDate={v => timelineAction(jumpToDateAction(v))}
                    jumpToNow={() => timelineAction(jumpToNowAction())}
                    exportEvents={exportEvents}
                    filter={filter}
                />
                <Box flex={1}>
                    <DarkScrollbar onScroll={saveScrollPosition} savedPosition={savedPosition}>
                        <Timeline
                            items={events}
                            groups={groups}
                            timeline={timelineState}
                            onTimeChange={onTimeChange}
                            openItem={item => openEvent(item.id)}
                            currentTime={currentTime}
                            ContentRenderer={({ item, inTooltip }) => (
                                <EventTimelineContent item={item} inTooltip={inTooltip} />
                            )}
                            toggleGroup={g => toggleGroup(g.process)}
                        />
                    </DarkScrollbar>
                </Box>
            </Box>
        </SyncWrapper>
    )
}

interface EventTimelineDispatchProps {
    openEvent: typeof openEventAction
    toggleGroup: typeof toggleGroupAction
    setFilter: typeof setFilterAction
    exportEvents: typeof exportEventsAction
    timelineAction: typeof eventTimelineTimelineParentAction
}

export const EventTimeline = connect<EventTimelineSelectorState, EventTimelineDispatchProps>(
    eventTimelineSelector,
    {
        openEvent: openEventAction,
        toggleGroup: toggleGroupAction,
        setFilter: setFilterAction,
        timelineAction: eventTimelineTimelineParentAction,
        exportEvents: exportEventsAction,
    },
)(EventTimelineClass)
