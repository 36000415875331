import { SimpleHorizontalLegend } from 'aos-components/src/components/chart/SimpleHorizontalLegend'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface YearChartLegendProps {
    yearRange: string[]
    getColor(v: number): Color
}

export const YearChartLegend: FC<YearChartLegendProps> = ({ yearRange, getColor }) => {
    return (
        <SimpleHorizontalLegend
            rightItems={yearRange.map((year, index) => [
                {
                    mainColor: getColor(index),
                    secondaryColor: getColor(index),
                    variant: LegendDotVariant.Circle,
                },
                year,
            ])}
            size={BlockSize.Tiny}
        />
    )
}
