import { DESELECT_ALL_FEATURES, SELECT_FEATURE, SWITCH_MAP } from 'aos-ui-map/src/core/actions'
import { mapReducer } from 'aos-ui-map/src/core/reducer'

import { AIRPORT_MAP_MAP_PARENT, AirportMapAction, LOAD_SELECTION_DETAILS_SUCCESS } from './actions'
import { AirportMapState, initialAirportMapState } from './state'

export function airportMapReducer(
    state = initialAirportMapState,
    action: AirportMapAction,
): AirportMapState {
    switch (action.type) {
        case LOAD_SELECTION_DETAILS_SUCCESS:
            return { ...state, selectionDetails: action.payload, layersSidebarOpen: false }

        case AIRPORT_MAP_MAP_PARENT:
            switch (action.payload.type) {
                case SELECT_FEATURE:
                    return {
                        ...state,
                        selectedElement: action.payload.payload,
                        selectionDetails: undefined,
                    }

                case SWITCH_MAP:
                    return {
                        ...state,
                        selectedElement: null,
                        selectionDetails: undefined,
                        variant: action.payload.payload,
                    }

                case DESELECT_ALL_FEATURES:
                    return {
                        ...state,
                        selectedElement: null,
                        selectionDetails: undefined,
                    }

                default:
                    return mapReducer(state, action.payload)
            }

        default:
            return state
    }
}
