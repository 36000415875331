import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { FixedHeightList } from 'aos-ui/src/components/list/FixedHeightList'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Searchable } from 'aos-ui/src/components/search/Searchable'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'

import { getExistingTagsSortedSelector } from '../../core/checklistManager/selectors'
import {
    checkChecklistAction,
    closeChecklistModalAction,
    submitChecklistsAction,
} from '../../core/checklistSelectionModal/actions'
import {
    ChecklistSelectionModalState,
    SelectableSimpleChecklistTemplate,
} from '../../core/checklistSelectionModal/state'
import { State } from '../../core/state'

const LIST_HEIGHT = 450

const ChecklistSelectionModalInternal: FC<
    ChecklistSelectionModalState &
        ChecklistSelectionModalComputedState &
        ChecklistSelectionModalDispatchProps &
        ChecklistSelectionModalProps
> = ({
    isOpen,
    closeModal,
    checklists,
    submitChecklists,
    eventId,
    checkChecklist,
    existingTags,
}) => {
    const [tagFilter, setTagFilter] = useState<string | undefined>(undefined)

    const handleSubmit = () => {
        const selectedChecklistsId = checklists
            .filter(item => item.selected)
            .map(checklist => checklist.id)
        submitChecklists({ selectedChecklistsId, eventId })
    }

    const toggleChecklist = (checklist: SelectableSimpleChecklistTemplate) => {
        checkChecklist({
            id: checklist.id,
            selected: !checklist.selected,
        })
    }

    const renderFooter = () => {
        return (
            <FormButton
                label={translate('checklist-selection-modal.submit')}
                onClick={handleSubmit}
            />
        )
    }

    const renderChecklistItem = (checklist: SelectableSimpleChecklistTemplate, index: number) => (
        <li key={index} className='margin-bottom--decreased'>
            <Checkbox
                checkableLabel
                checked={checklist.selected}
                onChange={() => toggleChecklist(checklist)}
            >
                {checklist.name}
            </Checkbox>
        </li>
    )

    const renderChecklistColumn = () => {
        return (
            <Searchable list={checklists} extractText={item => item.name}>
                {(filteredAvailableChecklists, query, setQuery) => (
                    <FixedHeightList
                        label={translate('checklist-selection-modal.available')}
                        height={LIST_HEIGHT}
                    >
                        <li className='margin-bottom--decreased'>
                            <Box row>
                                <Box flex={2} marginRight={30}>
                                    <CleanableInput
                                        type='text'
                                        variant={ThemeVariant.White}
                                        placeholder={translate(
                                            'checklist-manager.search.placeholder',
                                        )}
                                        value={query}
                                        leftSvg={SvgIcon.SearchWhite}
                                        onChangeText={e => setQuery(e || '')}
                                        small
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Dropdown
                                        placeholder={translate(
                                            'checklist-manager.filter-by-tag.placeholder',
                                        )}
                                        value={tagFilter}
                                        items={existingTags}
                                        valueRenderer={tag => tag as string}
                                        onChange={setTagFilter}
                                        clearAction={() => {
                                            setTagFilter(undefined)
                                        }}
                                        outlined
                                        width={DropdownWidth.Auto}
                                        variant={DropdownVariant.White}
                                        small
                                    />
                                </Box>
                            </Box>
                        </li>
                        {filteredAvailableChecklists
                            .filter(checklist => !tagFilter || checklist.tag === tagFilter)
                            .map(renderChecklistItem)}
                    </FixedHeightList>
                )}
            </Searchable>
        )
    }

    const renderContent = () => {
        return (
            <Box row padding={30}>
                <Box flex={1}>{renderChecklistColumn()}</Box>
            </Box>
        )
    }

    return (
        <WhiteModal
            title={translate('checklist-selection-modal.title')}
            isOpen={isOpen}
            closeAction={closeModal}
            shouldCloseOnOverlayClick
            size={ModalKind.Medium}
            footer={renderFooter()}
        >
            {renderContent()}
        </WhiteModal>
    )
}

interface ChecklistSelectionModalProps {
    eventId: number
}

interface ChecklistSelectionModalComputedState {
    existingTags: string[]
}

interface ChecklistSelectionModalDispatchProps {
    closeModal: typeof closeChecklistModalAction
    checkChecklist: typeof checkChecklistAction
    submitChecklists: typeof submitChecklistsAction
}

export const ChecklistSelectionModal = connect<
    ChecklistSelectionModalState & ChecklistSelectionModalComputedState,
    ChecklistSelectionModalDispatchProps,
    ChecklistSelectionModalProps
>(
    (state: State) => ({
        ...state.checklistSelectionModal,
        existingTags: getExistingTagsSortedSelector(state.checklistSelectionModal.checklists),
    }),
    {
        closeModal: closeChecklistModalAction,
        checkChecklist: checkChecklistAction,
        submitChecklists: submitChecklistsAction,
    },
)(ChecklistSelectionModalInternal)
