import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { Location } from '../location/Location'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledLocationProps extends SeleniumProps, MarginBoxProps {
    keyPrefix: string
    value?: AosLocation
    isRequired?: boolean
    isError?: boolean
    variant?: ThemeVariant

    onChange(v?: AosLocation): void
    onOpenOnMap(): void
}

export const LabeledLocation: FCWithChildren<LabeledLocationProps> = ({
    variant,
    isRequired,
    seleniumLocation,
    value,
    onChange,
    isError,
    keyPrefix,
    onOpenOnMap,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    const resetLocation = () => onChange(undefined)
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <Location location={value} onEdit={onOpenOnMap} onClear={resetLocation} />
        </LabeledFormElement>
    )
}
