import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import {
    isTimeWithin,
    roundTimeRange,
    TimeRange,
    timeRange,
    timeRangeForDay,
} from 'aos-helpers/src/helpers/TimeRange'

import { TimePoint } from './TimePoint'

export type TimeRangeFilter = number

export const isTodayRange = (f: TimeRangeFilter): boolean => f === 0

export const filterToTimeRange = (
    range: TimeRangeFilter,
    now: DateTime,
    unit: 'hours' | 'days' = 'hours',
): TimeRange => {
    if (isTodayRange(range)) {
        return timeRangeForDay(now)
    }
    if (range <= 0) {
        return timeRange(now.clone().add(range, unit), now)
    } else {
        return timeRange(now, now.clone().add(range, unit))
    }
}

export const isTimePointInTimeRangeFilter = (filter: TimeRangeFilter) => (p: TimePoint): boolean =>
    isTimeInTimeRangeFilter(filter)(p.time)

export const isTimeInTimeRangeFilter = (filter: TimeRangeFilter) => (time: DateTime): boolean =>
    isTimeWithin(time, filterToTimeRange(filter, dateTime()))

export const filterToRoundedTimeRange = (
    range: TimeRangeFilter,
    now: DateTime,
    unit: 'hours' | 'days' = 'hours',
) => roundTimeRange(filterToTimeRange(range, now, unit), unit)
