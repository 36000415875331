import Feature from 'ol/Feature'
import Style from 'ol/style/Style'

export const addStyleToFeature = (feature: Feature, style: Style | null) => {
    const currentStyle = feature.getStyle() as Style[]
    if (style) {
        feature.setStyle([...currentStyle, style])
    }
}

export const removeLastStyleFromFeature = (feature: Feature) => {
    const currentStyle = feature.getStyle() as Style[]
    feature.setStyle(currentStyle.slice(0, currentStyle.length - 1))
}
