import { DateTime } from 'aos-helpers/src/helpers/Time'
import { isString } from 'lodash'

export const stringSorter = (a: string | undefined, b: string | undefined, desc: boolean) => {
    if (isString(a) && isString(b)) {
        return desc ? a.localeCompare(b) : b.localeCompare(a)
    }
    if (isString(a)) {
        return -1
    }
    if (isString(b)) {
        return 1
    }
    return 0
}

export const timeSorter = (a: DateTime | undefined, b: DateTime | undefined, desc: boolean) => {
    const defaultValue = desc ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER
    const t1 = a?.unix() ?? defaultValue
    const t2 = b?.unix() ?? defaultValue
    return desc ? t1 - t2 : t2 - t1
}

export const createStringSorter = <T extends {}>(field: keyof T) => (a: T, b: T, desc: boolean) => {
    const s1 = (a[field] as unknown) as string | undefined
    const s2 = (b[field] as unknown) as string | undefined
    return stringSorter(s1, s2, desc)
}

export const createTimeSorter = <T extends {}>(field: keyof T) => (a: T, b: T, desc: boolean) => {
    const t1 = (a[field] as unknown) as DateTime | undefined
    const t2 = (b[field] as unknown) as DateTime | undefined
    return timeSorter(t1, t2, desc)
}
