import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'

export interface RecentTasksStateAware {
    recentTasks: RecentTasksState
}

export interface RecentTasksState {
    tasks: TaskSnapshot[]
    isLoading: boolean
    isError: boolean
}

export const initialRecentTasksState: RecentTasksState = {
    tasks: [],
    isLoading: false,
    isError: false,
}
