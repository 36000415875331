import { RunwayClosure } from 'aos-services/src/services/runways/types/RunwaysState'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { createNotificationFromClosure } from './RunwaysClosureSpan'

export const RunwayClosureCarousel: FC<RunwayClosureCarouselProps> = props => {
    let closures = props.runwayClosure.map(closure => createNotificationFromClosure(closure))

    return (
        <Box row>
            <Carousel
                autoPlay
                infiniteLoop
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={closures.length > 1}
                className='runway-closure'
            >
                {closures}
            </Carousel>
        </Box>
    )
}

interface RunwayClosureCarouselProps {
    runwayClosure: RunwayClosure[]
}
