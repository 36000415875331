import { parentActionWrapper } from 'aos-helpers/src/helpers/ActionCreator'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { TowingStand } from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { NavigationMapElementType } from 'aos-services/src/services/mapui/types/NavigationMapElement'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { navigateToMapAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { airportMapMapParentAction } from '../../../core/airportMap/actions'
import Check from './assets/check.svg'

interface StandCellProps extends StandCellDispatchProps {
    stand?: TowingStand
}

export const StandCellComponent: FC<StandCellProps> = props => {
    if (!props.stand) {
        return null
    }

    const { stand, confirmed } = props.stand

    const navigate = () => {
        props.navigateToMap({
            type: NavigationMapElementType.Stand,
            standId: stand.value,
        })
    }

    return (
        <Box row>
            <Label
                color={stand.updated ? Color.White : undefined}
                textAlign='center'
                onClick={navigate}
            >
                {stand.value}
            </Label>
            <Text size={14} color={confirmed.updated ? Color.White : undefined}>
                <Icon svg={Check} iconSize={BlockSize.Tiny} transparent={!confirmed.value} />
            </Text>
        </Box>
    )
}

const Label = styled(Text)`
    display: inline-block;
    min-width: 42px;
    cursor: pointer;
    text-decoration: underline;
`

interface StandCellDispatchProps {
    navigateToMap: typeof navigateToMapAction
}

export const StandCell = connect<null, StandCellDispatchProps>(null, {
    navigateToMap: parentActionWrapper(airportMapMapParentAction, navigateToMapAction),
})(StandCellComponent)
