import AirsideFence from '../../../assets/images/airside-fence.png'
import Buildings from '../../../assets/images/buildings.png'
import DeicingArea from '../../../assets/images/deicing-area.png'
import NavigationGrid from '../../../assets/images/navigation-grid.png'
import NavigationMap from '../../../assets/images/navigation-map.png'
import RescueService from '../../../assets/images/rescue-service.png'
import RescueStations from '../../../assets/images/rescue-stations.png'
import ServiceRoads from '../../../assets/images/service-roads.png'
import TaxiwayLabels from '../../../assets/images/taxiway-labels.png'
import TaxiwayLines from '../../../assets/images/taxiway-lines.png'
import TaxiwatNames from '../../../assets/images/taxiway-names.png'
import { AtcLayers, BimLayerName } from './BimLayerName'

export const bimLayerImage: Record<AtcLayers, string> = {
    [BimLayerName.NavigationGridAtc]: NavigationGrid,
    [BimLayerName.TaxiwayLines]: TaxiwayLines,
    [BimLayerName.TaxiwayNames]: TaxiwatNames,
    [BimLayerName.RescueService]: RescueService,
    [BimLayerName.ServiceRoads]: ServiceRoads,
    [BimLayerName.AirsideFence]: AirsideFence,
    [BimLayerName.DeicingArea]: DeicingArea,
    [BimLayerName.Buildings]: Buildings,
    [BimLayerName.TaxiwayLabels]: TaxiwayLabels,
    [BimLayerName.RescueStationsAtc]: RescueStations,
    [BimLayerName.NavigationMap]: NavigationMap,
}
