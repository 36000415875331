import { firebaseCollectionReducer } from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'

import { StatusDashboardDataAction } from './actions'
import { CDM_LOAD_SUCCESS, TOBT_LOAD_SUCCESS } from './cdm/actions'
import { EVENTS_LOAD_SUCCESS } from './events/actions'
import { LOAD_SINGLE_FOD_SUCCESS } from './fod/actions'
import { NEWS_FEED_LOAD_SUCCESS } from './newsFeed/actions'
import { OPERATIONAL_FORECAST_SYNC_SUCCESS } from './operationalForecast/actions'
import { PAX_SYNC_SUCCESS } from './pax/actions'
import { PAX_FORECAST_LOAD_SUCCESS } from './paxForecast/actions'
import { QUEUEING_TIME_LOAD_SUCCESS } from './queueingTime/actions'
import { METAR_SYNC_SUCCESS, RUNWAY_SYNC_SUCCESS } from './runway/actions'
import { initialStatusDashboardDataState, StatusDashboardData } from './state'
import { WEATHER_LOAD_SUCCESS } from './weather/actions'

export function statusDashboardDataReducer(
    state: StatusDashboardData = initialStatusDashboardDataState,
    action: StatusDashboardDataAction,
): StatusDashboardData {
    switch (action.type) {
        case PAX_SYNC_SUCCESS:
            return {
                ...state,
                pax: firebaseCollectionReducer(state.pax, action.payload),
            }

        case RUNWAY_SYNC_SUCCESS:
            return {
                ...state,
                runwayState: action.payload,
            }

        case METAR_SYNC_SUCCESS:
            return {
                ...state,
                metarState: action.payload,
            }

        case OPERATIONAL_FORECAST_SYNC_SUCCESS:
            return {
                ...state,
                operationalForecast: action.payload,
            }

        case CDM_LOAD_SUCCESS:
            return {
                ...state,
                cdm: action.payload,
            }

        case QUEUEING_TIME_LOAD_SUCCESS:
            return {
                ...state,
                queueingTime: action.payload,
            }

        case EVENTS_LOAD_SUCCESS:
            return {
                ...state,
                events: action.payload,
            }

        case WEATHER_LOAD_SUCCESS:
            return {
                ...state,
                weatherMetrics: action.payload,
            }

        case PAX_FORECAST_LOAD_SUCCESS:
            return {
                ...state,
                paxForecast: action.payload,
            }

        case NEWS_FEED_LOAD_SUCCESS:
            return {
                ...state,
                newsFeeds: { ...state.newsFeeds, [action.payload[0]]: action.payload[1] },
            }

        case TOBT_LOAD_SUCCESS:
            return {
                ...state,
                tobt: action.payload,
            }

        case LOAD_SINGLE_FOD_SUCCESS:
            return {
                ...state,
                fod: action.payload,
            }

        default:
            return state
    }
}
