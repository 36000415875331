import { AbstractEntity } from '../../base/types/AbstractEntity'
import { Auditable } from '../../base/types/Auditable'
import { AosChecklistItem } from '../../checklists/types/AosChecklistItem'
import { AosChecklistItemType } from '../../checklists/types/AosCheckListItemType'
import { UnitTaskStatus } from '../../checklists/types/AosUnitTaskTemplate'

export interface AosEventChecklist extends AbstractEntity {
    name: string
    sourceId: number
    items: AosEventChecklistItem[]
    tag: string
}

export interface EditableChecklist extends AosEventChecklist {
    isCollapsed: boolean
    tab: TaskOrMessageTemplate
}

export enum TaskOrMessageTemplate {
    Task,
    MessageTemplate,
}

export interface AosEventChecklistItem extends AosChecklistItem, Auditable {
    done?: boolean
}

export const isTask = (item: AosEventChecklistItem) => item.type === AosChecklistItemType.Task

export const isMessageTemplate = (item: AosEventChecklistItem | AosChecklistItem) =>
    item.type === AosChecklistItemType.MessageTemplate

export interface TaskChangeObject {
    eventId: number
    checklistId: number
    taskId: number
    done: boolean
}

export interface UnitTaskChangeObject {
    eventId: number
    unitTaskId: number
    itemId: number
    additionalNote?: string
    status: UnitTaskStatus
}

export const ATCDashboardTag = 'EMGDASHBOARD'
