import { emptyRanges, Ranges } from '../../services/ranges/types/Ranges'
import { DashboardItemStatuses } from '../../services/statusDashboard/types/DashboardItemStatus'
import {
    DashboardItemHealths,
    DashboardItemHealthsForAirport,
    emptyDashboardItemHealths,
    emptyDashboardItemHealthsForAirport,
} from '../../services/widgetHealths/type/DashboardItemHealths'

export interface BaseStatusDashboardState {
    statuses: DashboardItemStatuses
    healths: DashboardItemHealths
    healthsForAirport: DashboardItemHealthsForAirport
    ranges: Ranges
}

export interface BaseStatusDashboardStateAware {
    statusDashboard: BaseStatusDashboardState
}

export const initialBaseStatusDashboardState: BaseStatusDashboardState = {
    statuses: {},
    healths: emptyDashboardItemHealths,
    healthsForAirport: emptyDashboardItemHealthsForAirport,
    ranges: emptyRanges,
}
