import {
    baggageDeliverySelector,
    BaggageDeliverySelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/baggageDelivery/baggageDelivery'
import { StatusDashboardStateAware } from 'aos-services/src/core/statusDashboardData/flights/selectors/common'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { BaggageDeliveryFilters } from 'aos-services/src/services/statusDashboard/types/filters/BaggageDeliveryFilters'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setBaggageDeliveryFilterAction } from '../../../../core/statusDashboard/actions'
import { BaggageDeliveryItemState } from '../../../../services/statusDashboard/types/itemStates/BaggageDeliveryItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { BaggageDeliveryFooter } from './BaggageDeliveryFooter'
import { BaggageDeliveryCompactView } from './compactView/BaggageDeliveryCompactView'
import { BaggageDeliveryFullView } from './fullView/BaggageDeliveryFullView'
import { BaggageDeliveryItem } from './partials/BaggageDeliveryItem'

class BaggageDeliveryClass extends PureComponent<BaggageDeliveryProps> {
    public render() {
        const { itemState, noDataWarning } = this.props
        const isSmall = itemState.size === DashboardItemSizeVariant.Small

        return (
            <BaggageDeliveryItem
                itemProps={pickDashboardItemProps(this.props)}
                noDataWarning={noDataWarning}
            >
                {isSmall ? this.renderCompact() : this.renderFull()}
                <BaggageDeliveryFooter
                    timeRange={this.props.itemState.timeRange}
                    handlingAgent={this.props.itemState.handlingAgent}
                    beltArea={this.props.itemState.beltArea}
                    fullViewTab={this.props.itemState.fullViewTab}
                    beltAreaValues={this.props.beltAreaValues}
                    setFilterAction={this.handleSetFilter}
                    withFullViewTabSwitch={!isSmall}
                />
            </BaggageDeliveryItem>
        )
    }

    private renderCompact() {
        return <BaggageDeliveryCompactView stats={this.props.stats} />
    }

    private renderFull() {
        const {
            itemState,
            stats,
            flights,
            chartData,
            now,
            lineDomain,
            barDomain,
            xDomain,
        } = this.props
        return (
            <BaggageDeliveryFullView
                itemState={itemState}
                stats={stats}
                flights={flights}
                chartData={chartData}
                lineDomain={lineDomain}
                barDomain={barDomain}
                xDomain={xDomain}
                now={now}
            />
        )
    }

    private handleSetFilter = (f: Partial<BaggageDeliveryFilters>) => {
        const { id } = this.props
        this.props.setBaggageDeliveryFilterAction([id, f])
    }
}

type BaggageDeliveryProps = BaggageDeliveryStateProps &
    BaggageDeliveryOwnProps &
    BaggageDeliveryDispatchProps

export interface BaggageDeliveryStateProps extends BaggageDeliverySelectorState {}

interface BaggageDeliveryDispatchProps {
    setBaggageDeliveryFilterAction: typeof setBaggageDeliveryFilterAction
}

type BaggageDeliveryOwnProps = StatusDashboardItemCommonProps<BaggageDeliveryItemState>

export const BaggageDelivery = connect<
    BaggageDeliveryStateProps,
    BaggageDeliveryDispatchProps,
    BaggageDeliveryOwnProps,
    StatusDashboardStateAware
>(baggageDeliverySelector, { setBaggageDeliveryFilterAction })(BaggageDeliveryClass)
