import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { notamTileSelector } from '../../../../core/notamTimeline/selectors'
import { NotamStateAware } from '../../../../core/notamTimeline/state'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { NotamItem } from './NotamItem'

export const NotamTile: FC<StatusDashboardItemCommonProps> = props => {
    const { notam, snowtam } = useSelector(notamTileSelector)
    const lastUpdated = useSelector((state: NotamStateAware) => state.notam.lastUpdated)

    return (
        <NotamItem
            lastUpdated={lastUpdated}
            itemProps={pickDashboardItemProps(props)}
            link={link}
            syncId='overview'
        >
            <TileContainer verticalSpacing={false} autoHeight>
                <Box columnGap={24} row>
                    <Box row columnGap={12} alignItems='flex-end'>
                        <Text size={52}>{notam}</Text>
                        <Text size={18}>{translate('dashboard.notam.title-notam')}</Text>
                    </Box>
                    <Box row columnGap={12} alignItems='flex-end'>
                        <Text size={52}>{snowtam}</Text>
                        <Text size={18}>{translate('dashboard.notam.title-snowtam')}</Text>
                    </Box>
                </Box>
            </TileContainer>
        </NotamItem>
    )
}

const link = generatePath(Link.FlightsNotam)
