import { editFod } from 'aos-services/src/core/fod/actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setFodEditModalOpenAction } from '../../core/fod/actions'
import { FodStateAware } from '../../core/fod/state'
import { State } from '../../core/state'
import {
    fodCommonActions,
    fodCommonStateSelectors,
    FodModalComponent,
    FodModalDispatchProps,
    FodModalSelectorState,
} from './partial/FodModal'

export const FodEditModal = connect<FodModalSelectorState, FodModalDispatchProps>(
    createStructuredSelector({
        isOpen: (state: FodStateAware) => state.fod.isEditModalOpen,
        keyPrefix: () => 'edit-fod-modal',
        bimLayers: (state: State) => state.bimLayers,
        userLayerVisibility: (state: State) => state.fod.fodMap.userLayerVisibility,
        ...fodCommonStateSelectors,
    }),
    {
        closeFodModal: () => setFodEditModalOpenAction(null),
        ...fodCommonActions,
        saveFod: editFod,
    },
)(FodModalComponent)
