import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'

import { useStateEffect } from '../../../hooks/useStateEffect'
import { Box } from '../../base/Box'
import { IdAware } from '../../base/IdAware'
import { BaseDropdown } from './base/BaseDropdown'
import {
    dropdownHeight,
    DropdownInput,
    DropdownLabel,
    DropdownPlaceholder,
} from './base/DropdownContent'
import { DropdownItemHoverable } from './base/DropdownItemHoverable'
import { DropdownVariant } from './base/DropdownVariant'
import { DropdownWidth } from './base/DropdownWidth'
import { useDropdownKeyboard } from './hooks/useDropdownKeyboard'
import { DropdownItems, useDropdownSearch } from './hooks/useDropdownSearch'

export interface DropdownAutocompleteProps<T extends any> extends IdAware {
    items: DropdownItems<T>
    value?: T

    variant?: DropdownVariant
    placeholder?: string
    width?: DropdownWidth
    small?: boolean
    outlined?: boolean
    maxHeight?: number
    hasClear?: boolean
    disabled?: boolean

    valueRenderer?(v: T): ReactNode
    labelRenderer(v: T): string
    onChange(v?: T): void
}

export const DropdownAutocomplete = <T extends any>({
    value,
    id,
    items,
    variant = DropdownVariant.White,
    labelRenderer,
    valueRenderer = labelRenderer,
    placeholder,
    width,
    onChange,
    small,
    outlined,
    maxHeight,
    disabled,
    hasClear = true,
}: DropdownAutocompleteProps<T>) => {
    const [isOpen, setOpen] = useState(false)
    const [inputValue, setInputValue] = useStateEffect('', [value, isOpen])

    const [hoveredIndex, setHoveredIndex] = useStateEffect<number | undefined>(undefined, [
        inputValue,
        items,
        value,
    ])

    const { dropdownItems, isLoading } = useDropdownSearch(inputValue, items, labelRenderer)

    const itemClick = (val: T, toggle: () => void) => {
        onChange(val)
        setInputValue(labelRenderer(val))
        toggle()
    }

    const clear = () => {
        onChange(undefined)
        setInputValue('')
    }

    const height = dropdownHeight(variant, small)
    const padding = variant === DropdownVariant.BlackGrey ? 12 : 8

    return (
        <BaseDropdown
            isOpen={isOpen}
            toggleVisible={!disabled}
            setOpen={setOpen}
            items={dropdownItems}
            variant={variant}
            id={id}
            clearAction={clear}
            width={width}
            height={height}
            disabled={disabled}
            content={
                <DropdownContent
                    inputValue={inputValue}
                    items={dropdownItems}
                    setInputValue={setInputValue}
                    disabled={disabled}
                    setOpen={setOpen}
                    value={value}
                    variant={variant}
                    hoveredIndex={hoveredIndex}
                    setHoveredIndex={setHoveredIndex}
                    placeholder={placeholder}
                    valueRenderer={valueRenderer}
                    onChange={onChange}
                />
            }
            cleanVisible={!!value && hasClear}
            onItemClick={(val, toggle) => itemClick(val, toggle)}
            paddingHorizontal={padding}
            emptyContent={
                <DropdownPlaceholder
                    size={12}
                    as='span'
                    variant={variant}
                    paddingHorizontal={padding}
                >
                    {isLoading ? translate('dropdown.loading') : translate('dropdown.empty')}
                </DropdownPlaceholder>
            }
            outlined={outlined}
            maxHeight={maxHeight}
            ItemRenderer={props => (
                <DropdownItemHoverable
                    onClick={props.onClick}
                    variant={props.variant}
                    isSelected={value === props.v}
                    height={height}
                    padding={padding}
                    isHovered={props.index === hoveredIndex}
                    onMouseEnter={() => setHoveredIndex(props.index)}
                    onMouseLeave={() => setHoveredIndex(undefined)}
                >
                    {valueRenderer(props.v)}
                </DropdownItemHoverable>
            )}
        />
    )
}

interface DropdownContentProps<T extends any> {
    inputValue: string
    setInputValue(v: string): void
    setOpen(v: boolean): void
    value?: T
    variant: DropdownVariant
    placeholder?: string
    valueRenderer(v: T): ReactNode
    onChange?(v?: T): void
    disabled?: boolean

    hoveredIndex?: number
    setHoveredIndex(v: number | undefined): void
    items: T[]
}

export const DropdownContent = <T extends any>({
    value,
    variant,
    setInputValue,
    valueRenderer,
    placeholder,
    setOpen,
    inputValue,
    hoveredIndex = -1,
    setHoveredIndex,
    items,
    onChange,
    disabled,
}: DropdownContentProps<T>) => {
    const { inputRef, handleKeyDown } = useDropdownKeyboard({
        itemsCount: items.length,
        currentIndex: hoveredIndex,
        close: () => setOpen(false),
        changeIndex: setHoveredIndex,
        select: index => onChange?.(items[index]),
    })

    const handleOpen = () => {
        inputRef.current?.focus()
        setOpen(true)
    }

    return (
        <Box flex={1} row onClick={handleOpen} relative>
            <DropdownLabel
                style={{ opacity: disabled ? 0.6 : 1 }}
                size={12}
                as='span'
                variant={variant}
                isSelected
            >
                {value && !inputValue && valueRenderer(value)}
            </DropdownLabel>
            {!value && !inputValue && (
                <DropdownPlaceholder variant={variant}>{placeholder}</DropdownPlaceholder>
            )}
            <Input
                disabled={disabled}
                variant={variant}
                type='text'
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                onKeyDown={handleKeyDown}
                ref={inputRef}
            />
        </Box>
    )
}

const Input = styled(DropdownInput)`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`
