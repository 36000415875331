import { Color } from 'aos-ui-common/src/styles/Color'
import { values } from 'lodash'

import { BaggageStatSection, BaggageStatusRange } from './types/BaggageStatSection'

export const baggageDeliveryFullChartHours = 16

export const baggageDeliveryRanges: Record<BaggageStatSection, BaggageStatusRange> = {
    [BaggageStatSection.Normal]: {
        from: 0,
        to: 30,
        status: BaggageStatSection.Normal,
    },
    [BaggageStatSection.Warning]: {
        from: 30,
        to: 60,
        status: BaggageStatSection.Warning,
    },
    [BaggageStatSection.Alert]: {
        from: 60,
        to: 60,
        status: BaggageStatSection.Alert,
    },
}

export const baggageDeliveryRangesValues = values(baggageDeliveryRanges)

export const baggageDeliveryChartColors: Record<BaggageStatSection, Color> = {
    [BaggageStatSection.Normal]: Color.SeaBlue,
    [BaggageStatSection.Warning]: Color.Primary,
    [BaggageStatSection.Alert]: Color.Magenta,
}
