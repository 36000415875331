import {
    AlertItemDto,
    AlertsStateDto,
    AlertType,
} from '../../dataaccess/alerts/types/AlertsStateDto'

export type AlertsState = AlertsStateDto
export type AlertItem = AlertItemDto

export interface AlertsStateAware {
    alerts: AlertsState
}

const initialAlert: AlertItem = {
    enabled: false,
    comment: '',
    reporter: '',
}

export const initialAlertsState: AlertsState = {
    [AlertType.LVP_COUNTER]: {
        enabled: false,
        endTime: '',
        taskId: 0,
    },
    [AlertType.LVP]: initialAlert,
    [AlertType.WA]: initialAlert,
}
