import {
    operationalForecastTileSelector,
    OperationalForecastTileSelectorState,
} from 'aos-services/src/core/statusDashboardData/operationalForecast/selectors'
import { OperationalForecastMode } from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { FC, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { OperationalForecastItemState } from '../../../../services/statusDashboard/types/itemStates/OperationalForecastItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { OperationalForecastGroupBlocks } from './OperationalForecastGroupBlocks'
import { OperationalForecastTileContentItem } from './OperationalForecastTileItem'
import { OperationalForecastItem } from './partials/OperationalForecastItem'

interface OperationalForecastTileProps
    extends StatusDashboardItemCommonProps<OperationalForecastItemState>,
        OperationalForecastTileSelectorState {}

export const OperationalForecastTileComponent: FC<OperationalForecastTileProps> = props => {
    const link = generatePath(Link.StatusDashboardPreset, {
        preset: DashboardProcessType.Operations,
    })
    const carouselTrigger = useSelector(carouselTriggerSelector)

    const renderContent = useMemo(() => {
        if (
            props.blocks.some(
                ([, mode]) =>
                    mode === OperationalForecastMode.Alert ||
                    mode === OperationalForecastMode.Warning,
            )
        ) {
            return (
                <TileContainer autoHeight>
                    <Carousel changeTrigger={carouselTrigger}>
                        {props.blocks.map(([group, , items]) =>
                            items
                                .filter(q => q.mode !== OperationalForecastMode.Normal)
                                .map((item, index) => (
                                    <OperationalForecastTileContentItem
                                        key={index}
                                        group={group}
                                        mode={item.mode}
                                        title={item?.title}
                                        description={item?.description}
                                    />
                                )),
                        )}
                    </Carousel>
                </TileContainer>
            )
        }
        return <OperationalForecastGroupBlocks items={props.blocks} />
    }, [props.blocks, carouselTrigger])

    return (
        <OperationalForecastItem
            itemProps={pickDashboardItemProps(props)}
            noDataWarning={false}
            lastUpdated={props.lastUpdated}
            link={link}
            syncId='overview'
        >
            {renderContent}
        </OperationalForecastItem>
    )
}

export const OperationalForecastTile = connect<OperationalForecastTileSelectorState>(
    operationalForecastTileSelector,
)(OperationalForecastTileComponent)
