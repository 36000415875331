import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskRecurrenceHourlyValidation } from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceHourlyFormPayload'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { Input } from 'aos-ui/src/components/form/input/Input'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import React, { FC } from 'react'

import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'

export const TaskRecurrenceHourly: FC<TaskRecurrenceHourlyProps> = ({
    value,
    onChange,
    errors,
    canEdit,
}) => {
    if (value?.type !== TaskRecurrenceType.Hourly) {
        return null
    }

    return (
        <LabeledFormElement
            label={translate('tasks.every')}
            marginVertical={8}
            isError={errors?.every}
        >
            {canEdit ? (
                <Dropdown
                    value={value.every}
                    items={repeatsHourlyEvery}
                    valueRenderer={value => `${value}h`}
                    onChange={every => onChange({ every })}
                    outlined
                />
            ) : (
                <Input
                    type='text'
                    value={value.every}
                    disabled
                />
             )}
        </LabeledFormElement>
    )
}

const repeatsHourlyEvery = [4, 6, 8, 12, 24]

interface TaskRecurrenceHourlyProps extends TaskRecurrenceFormProps {
    errors?: TaskRecurrenceHourlyValidation
}
