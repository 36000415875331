import { Action } from 'aos-helpers/src/helpers/ActionCreator'
import {
    newsFeedSyncAction,
    newsFeedSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/newsFeed/actions'
import { FeedChannelsFilter } from 'aos-services/src/services/newsFeed/types/FeedChannelsFilter'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { isEqual } from 'lodash'
import { FC, PropsWithChildren, PureComponent } from 'react'
import { connect } from 'react-redux'

interface NewsFeedSyncWrapperProps extends NewsFeedSyncWrapperDispatchProps {
    id: SyncDataKey
    filters: FeedChannelsFilter[]
}

interface NewsFeedSyncWrapperDispatchProps {
    dispatch(v: Action<any>): void
}

class SyncWrapperClass extends PureComponent<PropsWithChildren<NewsFeedSyncWrapperProps>> {
    public render() {
        return this.props.children
    }

    public componentDidMount() {
        const { dispatch, id, filters } = this.props
        dispatch(newsFeedSyncAction([id, filters]))
    }

    public componentDidUpdate(prevProps: Readonly<NewsFeedSyncWrapperProps>) {
        const { dispatch, id, filters } = this.props
        if (!isEqual(filters, prevProps.filters)) {
            dispatch(newsFeedSyncAction([id, filters]))
        }
    }

    public componentWillUnmount(): void {
        const { id, dispatch } = this.props
        dispatch(newsFeedSyncStopAction(id))
    }
}

export const NewsFeedSyncWrapper = connect<{}, NewsFeedSyncWrapperDispatchProps>(
    null,
    dispatch => ({ dispatch }),
)(SyncWrapperClass) as FC<PropsWithChildren<Partial<NewsFeedSyncWrapperProps>>>
