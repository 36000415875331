import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isApocSelector } from 'aos-services/src/core/auth/state'
import {
    ContactItem,
    ContactList,
    ContactListRow,
    UnitAirportContact,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { keys, map, sortBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { TableRowProps } from 'react-virtualized/dist/es/Table'

import { showActionModalAction } from '../../../core/actionModal/actions'
import {
    deleteContact,
    deleteUnitAirportContact,
    openAirportUnitContactModal,
    openContactModal,
} from '../../../core/contactList/actions'
import { filteredContactListSelector } from '../../../core/contactList/selectors'
import { ContactListItem } from './ContactListItem'
import { ContactUnitRow } from './ContactUnitRow'

const CELL_SIZE = 0.18

export const ContactListTable = () => {
    const ref = useRef<Table | null>(null)
    const dispatch = useDispatch()
    const mapContactListToExpandedUnits = useCallback((contactList: Record<string, any>) => {
        return keys(contactList).reduce((acc, key) => {
            acc[contactList[key].unit.id] = true
            return acc
        }, {} as Record<number, boolean>)
    }, [])
    const isApocUser = useSelector(isApocSelector)
    const contactList = useSelector(filteredContactListSelector)
    const [expandedUnits, setExpandedUnits] = useState<Record<number, boolean>>(
        mapContactListToExpandedUnits(contactList),
    )

    const onDeleteContact = (contact: ContactItem) => {
        dispatch(
            showActionModalAction({
                svg: SvgIcon.DeleteRound,
                title: translate('contact-list.delete-contact-title'),
                description: translate('contact-list.delete-contact-content'),
                onClickAction() {
                    dispatch(deleteContact(contact.id))
                },
                okLabel: translate('contact-list.delete'),
            }),
        )
    }

    const onEditContact = (contact: ContactItem) => {
        dispatch(openContactModal(contact))
    }

    const onEditUnit = (unit: UnitAirportContact) => {
        dispatch(openAirportUnitContactModal({ data: unit, reopen: false }))
    }

    const onDeleteUnit = (unit: UnitAirportContact) => {
        dispatch(
            showActionModalAction({
                svg: SvgIcon.DeleteRound,
                title: translate('contact-list.delete-unit-title'),
                description: translate('contact-list.delete-unit-content'),
                onClickAction() {
                    dispatch(deleteUnitAirportContact(unit.id))
                },
                okLabel: translate('contact-list.delete'),
            }),
        )
    }

    useEffect(() => {
        setExpandedUnits(mapContactListToExpandedUnits(contactList))
    }, [contactList])

    const handleToggleExpand = (unitId: number) => {
        setExpandedUnits(prev => ({
            ...prev,
            [unitId]: !prev[unitId],
        }))
    }

    const transformAndSortContactList = useCallback(
        (contactList: ContactList) => {
            let units = map(contactList, ({ unit, contacts }) => ({
                unit,
                contacts,
            }))

            units = sortBy(units, ({ unit }) => unit.name.toLowerCase())

            units.forEach(unitGroup => {
                unitGroup.contacts = sortBy(unitGroup.contacts, contact =>
                    contact.name.toLowerCase(),
                )
            })

            let sortedRows: ContactListRow[] = []
            units.forEach(unitGroup => {
                sortedRows.push({ type: 'unit', unit: unitGroup.unit })
                if (expandedUnits[unitGroup.unit.id]) {
                    unitGroup.contacts.forEach(contact => {
                        sortedRows.push({ type: 'contact', contact })
                    })
                }
            })

            return sortedRows
        },
        [expandedUnits],
    )

    const rows = useMemo(
        () => transformAndSortContactList(contactList),
        [contactList, JSON.stringify(expandedUnits)],
    )

    const rowRenderer = useCallback(
        (cellWidth: number) =>
            ({ index, style, key }: TableRowProps) => {
                const row = rows[index]
                if (row.type === 'unit') {
                    return (
                        <ContactUnitRow
                            isApoc={isApocUser}
                            onEdit={onEditUnit}
                            onDelete={onDeleteUnit}
                            row={row}
                            key={key}
                            style={style}
                            isExpanded={expandedUnits[row.unit.id]}
                            handleToggleExpand={handleToggleExpand}
                        />
                    )
                } else {
                    return (
                        <ContactListItem
                            isApocUser={isApocUser}
                            itemWidth={cellWidth}
                            key={key}
                            contact={row.contact}
                            style={style}
                            onDelete={onDeleteContact}
                            onEdit={onEditContact}
                        />
                    )
                }
            },
        [CELL_SIZE, expandedUnits, isApocUser, rows],
    )

    return (
        <AutoSizer>
            {({ height, width }) => {
                const cellWidth = (width - 600) * CELL_SIZE

                return (
                    <Table
                        headerStyle={{
                            color: Color.GreyLight,
                            fontSize: 14,
                            fontWeight: 'normal',
                            textTransform: 'none',
                        }}
                        ref={ref}
                        width={width}
                        height={height}
                        headerHeight={40}
                        rowHeight={70}
                        rowCount={rows.length}
                        rowGetter={({ index }) => rows[index]}
                        rowRenderer={rowRenderer(cellWidth)}
                    >
                        <Column
                            label={translate('contact-list.table.name')}
                            dataKey='name'
                            width={cellWidth}
                        />
                        <Column
                            label={translate('contact-list.table.description')}
                            dataKey='description'
                            width={cellWidth}
                            flexGrow={1}
                        />
                        <Column
                            label={translate('contact-list.table.phoneNumber')}
                            dataKey='phoneNumber'
                            width={cellWidth}
                        />
                        <Column
                            label={translate('contact-list.table.email')}
                            dataKey='email'
                            width={cellWidth}
                            flexGrow={1}
                        />
                        <Column
                            label={translate('contact-list.table.link')}
                            dataKey='link'
                            width={cellWidth}
                        />
                        {isApocUser && (
                            <Column
                                label={translate('contact-list.table.visibility')}
                                dataKey='contactVisibility'
                                width={cellWidth}
                            />
                        )}
                        <Column dataKey='actions' width={64} />
                    </Table>
                )
            }}
        </AutoSizer>
    )
}
