import { InspectionTask } from '../task/InspectionTask'
import { InspectionType } from '../task/InspectionType'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface InspectionTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.Inspection
    items: InspectionItemPayload[]
}

export const initialInspectionTaskPayload: InspectionTaskFormPayload = {
    type: TaskType.Inspection,
    ...initialBaseTaskPayload,
    items: [],
}

export interface InspectionItemPayload {
    inspectionType: InspectionType
    comment?: string
    uuid?: string
}

export interface InspectionTaskPayloadValidation extends BaseTaskPayloadValidation {
    items: boolean
}

export const validateInspectionTask = (
    t: InspectionTaskFormPayload,
): InspectionTaskPayloadValidation => ({
    ...baseValidateTask(t),
    items: t.items.length > 0,
})

export const inspectionTaskFormPayloadFromTask = (
    t: InspectionTask,
): InspectionTaskFormPayload => ({
    type: TaskType.Inspection,
    ...baseTaskFormPayloadFromTask(t),
    items: t.items,
})
