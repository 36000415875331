import {
    translateIconWeatherSeriesType,
    WeatherOtherSeriesType,
    WeatherSeriesType,
    WeatherValueSeriesType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import {
    TooltipColorList,
    TooltipColorsOverlay,
} from 'aos-ui/src/components/tooltip/TooltipColorsOverlay'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent, ReactNode } from 'react'

import { chartIconBlockSize, chartIconSize } from '../WeatherConsts'
import { getIconsForSeriesType } from '../WeatherSeriesIcons'
import { freezingPhenomenonColorList, FreezingPhenomenonColorListType } from './FreezingPhenomenon'
import { lvpProbabilityColorList, probabilityColorList } from './Probability'
import WeatherTooltipFreezingPhenomenon from './WeatherTooltipFreezingPhenomenon'

export class WeatherIcons extends PureComponent<WeatherIconsProps> {
    public render() {
        const { components, additionalIcons, left, top } = this.props
        return (
            <g transform={`translate(${left} ${top})`}>
                {components.map(this.renderIconByType)}
                {additionalIcons &&
                    additionalIcons.map((icon, index) =>
                        this.renderAdditionalIcons(icon, index + components.length),
                    )}
            </g>
        )
    }
    private selectColorListType = (type: WeatherSeriesType) => {
        if (
            type === WeatherValueSeriesType.LvpProbability ||
            type === WeatherValueSeriesType.ThunderstormProbability ||
            type === WeatherValueSeriesType.CumulonimbusCloudsProbability
        ) {
            return tooltipColorLists[type]
        }
        if (
            type === WeatherOtherSeriesType.FreezingWeatherPhenomenon ||
            type === WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon
        ) {
            return tooltipSectionsColorLists[type]
        }
        return
    }

    private selectOverlayType = (
        type: WeatherSeriesType,
        colors: FreezingPhenomenonColorListType | TooltipColorList,
        tooltip: string,
    ) => {
        if (
            type === WeatherValueSeriesType.LvpProbability ||
            type === WeatherValueSeriesType.ThunderstormProbability ||
            type === WeatherValueSeriesType.CumulonimbusCloudsProbability
        ) {
            return this.tooltipOverlay(colors as TooltipColorList, tooltip)
        }

        if (
            type === WeatherOtherSeriesType.FreezingWeatherPhenomenon ||
            type === WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon
        ) {
            return this.tooltipFreezingOverlay(colors as FreezingPhenomenonColorListType, tooltip)
        }
        return
    }

    private renderIconByType = (type: WeatherSeriesType, index: number) => {
        const icon = getIconsForSeriesType(type)
        const colors = this.selectColorListType(type)
        const tooltip = translateIconWeatherSeriesType(type)

        return (
            <Tooltip
                key={type + index}
                overlay={colors && this.selectOverlayType(type, colors, tooltip)}
                body={!colors && tooltip}
                withArrow
            >
                {this.renderIcon(icon, index)}
            </Tooltip>
        )
    }

    private renderAdditionalIcons = (additionalIcon: WeatherAdditionalIcon, index: number) => (
        <Tooltip key={index} overlay={() => additionalIcon.tooltip as React.ReactElement} withArrow>
            {this.renderIcon(additionalIcon.svg, index)}
        </Tooltip>
    )

    private renderIcon = (icon: Svg, index: number) => {
        const { iconVariant } = this.props
        return (
            <g>
                <Icon
                    iconVariant={iconVariant}
                    raw={!iconVariant}
                    svg={icon}
                    attributes={{ y: index * chartIconBlockSize }}
                    color={Color.White}
                />
                <rect
                    width={chartIconSize}
                    height={chartIconSize}
                    fill='transparent'
                    x={0}
                    y={index * chartIconBlockSize}
                />
            </g>
        )
    }

    private tooltipFreezingOverlay(colorList: FreezingPhenomenonColorListType, tooltip: string) {
        return <WeatherTooltipFreezingPhenomenon tooltip={tooltip} colorList={colorList} />
    }

    private tooltipOverlay(colors: TooltipColorList, tooltip: string) {
        return (
            <TooltipColorsOverlay colors={colors} withArrow>
                {tooltip}
            </TooltipColorsOverlay>
        )
    }
}

const tooltipColorLists: { [key: string]: TooltipColorList } = {
    [WeatherValueSeriesType.LvpProbability]: lvpProbabilityColorList,
    [WeatherValueSeriesType.ThunderstormProbability]: probabilityColorList,
    [WeatherValueSeriesType.CumulonimbusCloudsProbability]: probabilityColorList,
}

const tooltipSectionsColorLists = {
    [WeatherOtherSeriesType.FreezingWeatherPhenomenon]: freezingPhenomenonColorList,
    [WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon]: freezingPhenomenonColorList,
}

export interface WeatherAdditionalIcon {
    svg: Svg
    tooltip: ReactNode
}

interface WeatherIconsProps {
    components: WeatherSeriesType[]
    additionalIcons?: WeatherAdditionalIcon[]
    iconVariant?: IconVariant
    left: number
    top: number
}
