import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { Pageable, pageableForList } from 'aos-helpers/src/helpers/Pageable'

import { BimLayerName } from '../../../../aos-ui-map/src/components/map/bim/BimLayerName'
import { AnonymousType, BaseFodFormPayload, FodDto } from '../../services/fod/types'

export interface FodBaseState<TTaskMap, TAttachment> {
    fodTasks: Pageable<FodDto>
    payload: BaseFodFormPayload
    form: FormValidation<FodDto>
    attachmentsState: TAttachment
    fodMap: TTaskMap
}

export function initialBaseFodState<TTaskMap, TAttachment>(): FodBaseState<TTaskMap, TAttachment> {
    return {
        fodTasks: pageableForList([]),
        payload: { anonymousType: AnonymousType.FINAVIA } as BaseFodFormPayload,
        form: FormValidation.fromFields({} as FodDto),
        attachmentsState: {} as TAttachment,
        fodMap: {} as TTaskMap,
    }
}

export const defaultMapLayers = [
    BimLayerName.AerodromeManeuvering,
    BimLayerName.ApronBasemap,
    BimLayerName.LightMasts,
    BimLayerName.Stands,
    BimLayerName.WaLvpScreens,
]
