import { ActionModalMode } from 'aos-components/src/components/modal/ActionModal/ActionModalContent'
import {
    showModalAction,
    showModalWithValueAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { TwoColumn } from 'aos-helpers/src/helpers/ColumnLayout'
import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { UserPageRequest } from 'aos-services/src/services/users/transport/UsersPageRequest'
import { AosUser, isAosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { SplittedPanel } from 'aos-ui/src/components/container/SplittedPanel'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import { showActionModalAction } from '../../core/actionModal/actions'
import {
    addAdUserActions,
    addContactActions,
    createGroupActions,
    downloadGroupsAction,
    editAdUserActions,
    editContactActions,
    editNotificationSettingsModalAction,
    loadGroupsAction,
    loadUsersAction,
    removeUserAction,
    removeUserFromGroupAction,
    removeUserGroupAction,
    removeUserPermanentlyAction,
    renameUserGroupActions,
    restoreUserAction,
    selectGroupAction,
    selectUserAction,
    setUsersFilterAction,
    toggleSystemGroupsExpandedAction,
} from '../../core/groupManager/actions'
import { showUserEmptyModalAction } from '../../core/groupManager/manageUser/actions'
import { showUserModalWithAdUserAction } from '../../core/groupManager/manageUser/adUser/actions'
import { showUserModalWithContactAction } from '../../core/groupManager/manageUser/contact/actions'
import { GroupManagerState } from '../../core/groupManager/state'
import { resizeColumnActionFactory } from '../../core/layout/actions'
import { State } from '../../core/state'
import { CreateUserGroupModal } from './CreateUserGroupModal'
import { GroupList } from './groupList/GroupList'
import { GroupNotificationsModal } from './GroupNotificationsModal'
import { RenameUserGroupModal } from './RenameUserGroupModal'
import { UserList } from './userList/UserList'
import { AddAdUserModal } from './userModals/AddAdUserModal'
import { AddContactModal } from './userModals/AddContactModal'
import { EditAdUserModal } from './userModals/EditAdUserModal'
import { EditContactModal } from './userModals/EditContactModal'
import { UserPreview } from './UserPreview'

class GroupManagerClass extends PureComponent<GroupManagerProps> {
    public render() {
        const loadUserList = (pr: UserPageRequest) =>
            this.props.loadUsersAction([this.props.selectedGroup!.id, pr])

        const addAdUser = () => this.props.showAddAdUserModalAction(this.props.selectedGroup)
        const addContact = () => this.props.showAddContactModalAction(this.props.selectedGroup)
        const editUser = (u: AosUser) =>
            isAosAdUser(u)
                ? this.props.showEditAdUserModalAction(u)
                : this.props.showEditContactModalAction(u)

        return (
            <SyncWrapper onEnter={loadGroupsAction()}>
                <SplittedPanel separatedHeader>
                    <GroupList
                        groups={this.props.groups}
                        onSelect={this.props.selectGroupAction}
                        selectedGroup={this.props.selectedGroup}
                        addGroup={this.props.showCreateGroupModalAction}
                        removeUserGroup={this.removeGroup}
                        renameUserGroup={this.renameGroup}
                        editNotificationSettings={this.editNotificationSettings}
                        downloadGroups={this.downloadGroupsAction}
                        downloadGroupsDisabled={this.props.userGroupExporting}
                        toggleSystemGroups={this.props.toggleSystemGroupsExpandedAction}
                        isSystemGroupsSectionExpanded={this.props.ui.isSystemGroupsSectionExpanded}
                    />
                    <ResizableSplittedPanel
                        separated
                        flex={1}
                        initialRightSizes={this.props.columnSize}
                        onResize={this.props.resizeColumnAction}
                        leftMinSizes={[20]}
                        rightMinSizes={[20]}
                    >
                        <UserList
                            users={this.props.users}
                            selectedGroup={this.props.selectedGroup!}
                            selectedUser={this.props.selectedUser}
                            onSelectUser={this.props.selectUserAction}
                            loadUsers={loadUserList}
                            onEditUser={editUser}
                            onRemoveUser={this.removeUser}
                            onRemoveUserFromGroup={this.removeUserFromGroup}
                            onRestoreUser={this.restoreUser}
                            onRemoveUserPermanently={this.removeUserPermanently}
                            filterUsers={this.props.setUsersFilterAction}
                            addAdUser={addAdUser}
                            addContact={addContact}
                        />
                        <UserPreview
                            selectedGroup={this.props.selectedGroup!}
                            user={this.props.selectedUser}
                            onEditUser={editUser}
                        />
                    </ResizableSplittedPanel>
                    <CreateUserGroupModal keyPrefix='create-user-group' />
                    <AddContactModal />
                    <AddAdUserModal />
                    <EditContactModal />
                    <EditAdUserModal />
                    <RenameUserGroupModal keyPrefix='rename-user-group' />
                    <GroupNotificationsModal />
                </SplittedPanel>
            </SyncWrapper>
        )
    }

    private removeUser = (u: AosUser) => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.remove-user'),
            svg: SvgIcon.DeleteRound,
            onClickAction: () => this.props.removeUserAction(u.id),
            mode: ActionModalMode.Important,
        })
    }

    private removeUserFromGroup = (g: AosUserGroup, u: AosUser) => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.remove-from-group'),
            svg: SvgIcon.DeleteRound,
            onClickAction: () => this.props.removeUserFromGroupAction([g, u]),
        })
    }

    private restoreUser = (u: AosUser) => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.restore-user'),
            svg: SvgIcon.RestoreUser,
            onClickAction: () => this.props.restoreUserAction(u),
        })
    }

    private removeUserPermanently = (u: AosUser) => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.remove-user-permanently'),
            svg: SvgIcon.DeleteRound,
            onClickAction: () => this.props.removeUserPermanentlyAction(u.id),
            mode: ActionModalMode.Important,
        })
    }

    private removeGroup = (group: AosUserGroup) => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.remove-group'),
            svg: SvgIcon.DeleteRound,
            onClickAction: () => this.props.removeGroupAction(group),
            okLabel: translate('group-manager.remove-group.okLabel'),
        })
    }

    private downloadGroupsAction = () => {
        this.props.showActionModalAction({
            ...modalContentFromKeyPrefix('group-manager.download-groups'),
            svg: SvgIcon.ExportChecklistTemplates,
            onClickAction: this.props.downloadGroupsAction,
        })
    }

    private renameGroup = (group: AosUserGroup) => {
        this.props.showRenameGroupModalAction({
            payload: group.id,
            value: group.name,
        })
    }

    private editNotificationSettings = (group: AosUserGroup) => {
        this.props.editNotificationSettingsModalAction(group)
    }
}

interface GroupManagerProps extends GroupManagementStateProps, GroupManagementDispatchProps {}

interface GroupManagementStateProps extends GroupManagerState {
    columnSize: TwoColumn
}

interface GroupManagementDispatchProps {
    selectGroupAction: typeof selectGroupAction
    selectUserAction: typeof selectUserAction
    loadUsersAction: typeof loadUsersAction
    setUsersFilterAction: typeof setUsersFilterAction
    showCreateGroupModalAction: typeof showModalAction
    removeUserAction: typeof removeUserAction
    removeUserFromGroupAction: typeof removeUserFromGroupAction
    restoreUserAction: typeof restoreUserAction
    removeUserPermanentlyAction: typeof removeUserPermanentlyAction
    showActionModalAction: typeof showActionModalAction
    showAddAdUserModalAction: typeof showUserEmptyModalAction
    showAddContactModalAction: typeof showUserEmptyModalAction
    showEditAdUserModalAction: typeof showUserModalWithAdUserAction
    showEditContactModalAction: typeof showUserModalWithContactAction
    removeGroupAction: typeof removeUserGroupAction
    showRenameGroupModalAction: typeof showModalWithValueAction
    downloadGroupsAction: typeof downloadGroupsAction
    toggleSystemGroupsExpandedAction: typeof toggleSystemGroupsExpandedAction
    editNotificationSettingsModalAction: typeof editNotificationSettingsModalAction
    resizeColumnAction(v: TwoColumn): void
}

export const GroupManager = connect<GroupManagementStateProps, GroupManagementDispatchProps>(
    (state: State) => ({
        ...state.groupManager,
        columnSize: state.layout.columnSizes.groupManager,
    }),
    {
        selectGroupAction,
        selectUserAction,
        loadUsersAction,
        setUsersFilterAction,
        downloadGroupsAction,
        removeUserAction,
        removeUserFromGroupAction,
        restoreUserAction,
        removeUserPermanentlyAction,
        showActionModalAction,
        showCreateGroupModalAction: createGroupActions.showModalAction,
        showAddAdUserModalAction: addAdUserActions.showUserEmptyModalAction,
        showAddContactModalAction: addContactActions.showUserEmptyModalAction,
        showEditAdUserModalAction: editAdUserActions.showUserModalWithAdUserAction,
        showEditContactModalAction: editContactActions.showUserModalWithContactAction,
        removeGroupAction: removeUserGroupAction,
        showRenameGroupModalAction: renameUserGroupActions.showModalWithValueAction,
        resizeColumnAction: resizeColumnActionFactory('groupManager'),
        toggleSystemGroupsExpandedAction,
        editNotificationSettingsModalAction,
    },
)(GroupManagerClass)
