import {
    ContactDTO,
    ContactList,
    UnitAirportContact,
    UnitAirportContactDTO,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'

export interface ContactListState {
    contactList: ContactList
    units: UnitAirportContact[]
    currentAirport: AosAirport | null
    searchValue?: string
    modalOpen: boolean
    contactModalOpen: boolean
    selectedContact: ContactDTO | null
    selectedAirportUnit: UnitAirportContactDTO | null
}

export const initialContactListState: ContactListState = {
    contactList: {},
    units: [],
    currentAirport: null,
    modalOpen: false,
    contactModalOpen: false,
    selectedContact: null,
    selectedAirportUnit: null,
}

export interface ContactListStateAware {
    contactList: ContactListState
}
