import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { taskUnitService } from 'aos-services/src/services/checklists/taskUnitService'
import { AosUnitTaskTemplate } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { State } from '../../state'
import { setExportingChecklistTemplatesAction } from '../event/actions'
import {
    CREATE_UNIT_TASK_ITEM,
    CreateUnitTaskItemAction,
    createUnitTaskItemSuccessAction,
    EXPORT_ALL_UNIT_TASK_TEMPLATES,
    EXPORT_SINGLE_UNIT_TASK_TEMPLATE,
    ExportSingleUnitTaskTemplateAction,
    LOAD_UNIT_TASK_TEMPLATE,
    LOAD_UNIT_TASK_TEMPLATES,
    LoadUnitTaskTemplateAction,
    loadUnitTaskTemplateAction,
    loadUnitTaskTemplatesAction,
    loadUnitTaskTemplatesSuccessAction,
    loadUnitTaskTemplateSuccessAction,
    SELECT_UNIT_TASK_TEMPLATE,
    SelectUnitTaskTemplateAction,
    UPDATE_UNIT_TASK_ITEM,
    UpdateUnitTaskItemAction,
} from './actions'

function* loadUnitTasksSaga() {
    yield takeEvery<LoadUnitTaskTemplateAction>(LOAD_UNIT_TASK_TEMPLATES, function* () {
        const result: AosUnitTaskTemplate[] = yield call(taskUnitService.getAll)
        yield put(loadUnitTaskTemplatesSuccessAction(result))
    })
}

function* addUnitTaskSaga() {
    yield takeEvery<CreateUnitTaskItemAction>(CREATE_UNIT_TASK_ITEM, function* (action) {
        yield call(taskUnitService.create, action.payload)
        yield put(loadUnitTaskTemplatesAction())
        yield put(createUnitTaskItemSuccessAction(action.payload))
        successToast(
            `${translate('create-unit-task.task')} ${action.payload.content} ${translate(
                'create-unit-task.task-created',
            )}`,
        )
    })
}

function* updateUnitTaskSaga() {
    yield takeEvery<UpdateUnitTaskItemAction>(UPDATE_UNIT_TASK_ITEM, function* (action) {
        const currentTemplate: AosUnitTaskTemplate = yield select(
            (state: State) => state.checklistManager.selectedTemplate,
        )
        yield call(taskUnitService.update, currentTemplate.id, action.payload)
        yield put(loadUnitTaskTemplatesAction())
        successToast(translate('unit-task.updated'))
    })
}

function* selectUnitTaskTemplateSaga() {
    function* selectUnitTaskTemplate(action: SelectUnitTaskTemplateAction) {
        const unitId = action.payload.id
        yield put(loadUnitTaskTemplateAction(unitId))
    }

    yield takeLatest<SelectUnitTaskTemplateAction>(
        SELECT_UNIT_TASK_TEMPLATE,
        selectUnitTaskTemplate,
    )
}

function* loadUnitTaskSaga() {
    function* loadUnitTask(action: LoadUnitTaskTemplateAction) {
        const result: AosUnitTaskTemplate = yield call(taskUnitService.getOne, action.payload)
        yield put(loadUnitTaskTemplateSuccessAction(result))
    }

    yield takeLatest<LoadUnitTaskTemplateAction>(LOAD_UNIT_TASK_TEMPLATE, loadUnitTask)
}

function* exportSingleUnitTaskSaga() {
    yield takeEvery<ExportSingleUnitTaskTemplateAction>(
        EXPORT_SINGLE_UNIT_TASK_TEMPLATE,
        function* (action: ExportSingleUnitTaskTemplateAction) {
            yield put(setExportingChecklistTemplatesAction(true))
            yield call(taskUnitService.exportSingle, action.payload)
            yield put(setExportingChecklistTemplatesAction(false))
        },
    )
}

function* exportAllUnitTasksSaga() {
    yield takeEvery(EXPORT_ALL_UNIT_TASK_TEMPLATES, function* () {
        yield put(setExportingChecklistTemplatesAction(true))
        yield call(taskUnitService.exportAll)
        yield put(setExportingChecklistTemplatesAction(false))
    })
}

export const unitTaskSagas = [
    loadUnitTasksSaga,
    addUnitTaskSaga,
    updateUnitTaskSaga,
    selectUnitTaskTemplateSaga,
    loadUnitTaskSaga,
    exportSingleUnitTaskSaga,
    exportAllUnitTasksSaga,
]
