import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { AttachmentsState, initialAttachmentsState } from 'aos-services/src/core/attachments/state'
import { defaultMapLayers, initialBaseFodState } from 'aos-services/src/core/fod/state'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import {
    BaseFodFormPayload,
    BaseFodPayloadValidation,
    baseValidateFod,
    initialFodNotesForm,
} from 'aos-services/src/services/fod/types/FodNotesFormData'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { initialMapStateWithAdjustedZoom, MapState } from 'aos-ui-map/src/core/state'

export interface FodStateAware {
    fod: FodState
}

export interface FodState {
    // modal
    isEditModalOpen: boolean
    isCreateModalOpen: boolean
    isPreviewModalOpen: boolean

    payload: BaseFodFormPayload
    form: FormValidation<BaseFodPayloadValidation>
    fodMap: MapState
    attachmentsState: AttachmentsState
    metadata?: TaskMetadata
    list: Pageable<FodDto> | undefined
    userLayerVisibility: UserLayerVisibilityState
}

export const initialFodModalState = {
    ...initialBaseFodState(),
    payload: initialFodNotesForm,
    form: FormValidation.fromFields(baseValidateFod(initialFodNotesForm)),
    attachmentsState: initialAttachmentsState,
    fodMap: {
        ...initialMapStateWithAdjustedZoom(-0.5),
        siteLocationCustomization: MapSiteLocationCustomization.Other,
        mode: MapMode.EditSingleLocation,
    },
}

export const initialFodState: FodState = {
    // modal
    isEditModalOpen: false,
    isCreateModalOpen: false,
    isPreviewModalOpen: false,
    list: undefined,
    userLayerVisibility: {
        list: defaultMapLayers,
        isLoading: false,
        floor: 0,
    },

    ...initialFodModalState,
}
