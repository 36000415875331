import { DateTime, dateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { flatten, sortBy } from 'lodash'

import { AosEventDto, AosFeedOutDto } from '../../../dataaccess/events/types/AosEventDto'
import { AosUnitTaskTemplateImpl } from '../../checklists/types/AosUnitTaskTemplate'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosUserGroupImpl } from '../../users/types/AosUserGroupImpl'
import { AtcFieldsImpl } from '../../users/types/AtcFieldsImpl'
import { AosEvent } from './AosEvent'
import { AosEventChecklistImpl } from './AosEventChecklistImpl'
import { AosEventProcessType } from './AosEventProcessType'
import { AosEventScenario } from './AosEventScenario'
import { AosEventStatus } from './AosEventStatus'
import { AosEventVisibility } from './AosEventVisibility'
import { AosFeedInImpl } from './AosFeedInImpl'
import { AosFeedOutImpl } from './AosFeedOutImpl'

export class AosEventImpl extends WrapperObject<AosEventDto> implements AosEvent {
    public get id() {
        return this.value.id!
    }

    public get hasPrivateChannel() {
        return !!this.value.privateGroup
    }

    public get hasLocation() {
        return !!this.value.location
    }

    public get location() {
        return this.value.location as AosLocation
    }

    public get visibility() {
        return this.value.visibility as AosEventVisibility
    }

    public get isSecret() {
        return this.visibility === AosEventVisibility.Restricted
    }

    public get startTime() {
        return dateTime(this.value.startTime)
    }

    public get endTime() {
        return dateTimeFromOptional(this.value.endTime)
    }

    public get process() {
        return this.value.process as AosEventProcessType
    }

    public get title() {
        return this.value.title
    }

    public get description() {
        return this.value.description
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get category() {
        return this.value.category as AosEventScenario
    }

    public get status() {
        return this.value.status as AosEventStatus
    }

    public get isClosed() {
        return this.status === AosEventStatus.Closed
    }

    public get isOpen() {
        return !this.isClosed
    }

    public get deleted() {
        return this.value.deleted
    }

    public get discussionLength() {
        return this.value.feedOuts ? this.value.feedOuts.length : 0
    }

    public get createdAt() {
        return dateTime(this.value.createdAt)
    }

    public get createdBy() {
        return this.value.createdBy
    }

    public get modifiedAt() {
        return dateTimeFromOptional(this.value.modifiedAt)
    }

    public get modifiedBy() {
        return this.value.modifiedBy
    }

    public get attachments() {
        return this.value.attachments || []
    }

    public get feedIns() {
        return this.value.feedIns ? this.value.feedIns.map(t => new AosFeedInImpl(t)) : []
    }

    public get allAttachments() {
        const feedAttachments = flatten(this.feedIns.map(t => t.attachments))
        const eventAttachments = this.attachments
        return [...eventAttachments, ...feedAttachments]
    }

    public get feedOuts() {
        return sortBy<AosFeedOutDto>(this.value.feedOuts, (f: AosFeedOutDto) =>
            dateTime(f.createdAt),
        ).map(t => new AosFeedOutImpl(t))
    }

    public get checklists() {
        return this.value.checklists
            ? this.value.checklists.map(checklist => new AosEventChecklistImpl(checklist))
            : []
    }

    public get privateGroup() {
        return this.value.privateGroup ? new AosUserGroupImpl(this.value.privateGroup) : undefined
    }

    public get overdue() {
        return this.value.overdue
    }

    public get presentationTime(): DateTime | undefined {
        return this.isClosed ? this.endTime : this.startTime
    }

    public get atcAlert() {
        return this.value.atcAlert
    }

    public get atcFields() {
        return this.value.atcFields ? new AtcFieldsImpl(this.value.atcFields) : {}
    }

    public get exercise() {
        return this.value.exercise
    }

    public get unitTasks() {
        return this.value.unitTasks
            ? this.value.unitTasks.map(q => new AosUnitTaskTemplateImpl(q))
            : undefined
    }
}
