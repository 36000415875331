import { enumHasValue, getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'

import { FirebaseHealthForAirportItemType } from '../../../dataaccess/widgetHealths/types/FirebaseHealthForAirportItemType'
import { FirebaseHealthItemType } from '../../../dataaccess/widgetHealths/types/FirebaseHealthItemType'
import { DashboardItemType } from '../../statusDashboard/types/DashboardItemType'
import { DashboardItemWithHealthChecks } from './DashboardItemWithHealthChecks'

export type DashboardItemHealths = Record<FirebaseHealthItemType, DateTime>
export type DashboardItemHealthsForAirport = Record<FirebaseHealthForAirportItemType, DateTime>

export interface WidgetHealth {
    lastChanged?: DateTime
    upToDate: boolean
}

export type WidgetHealths = Record<DashboardItemWithHealthChecks, WidgetHealth>

export const emptyDashboardItemHealths: DashboardItemHealths = getInitialValuesForEnumRecord(
    FirebaseHealthItemType,
    dateTime(),
)

export const emptyDashboardItemHealthsForAirport: DashboardItemHealthsForAirport = getInitialValuesForEnumRecord(
    FirebaseHealthForAirportItemType,
    dateTime(),
)

export const getHealthForItemType = (
    healths: WidgetHealths,
    itemType: DashboardItemType,
): WidgetHealth | undefined => {
    if (enumHasValue(DashboardItemWithHealthChecks)(itemType)) {
        return healths[(itemType as unknown) as DashboardItemWithHealthChecks]
    }
    return undefined
}

export const isUpToDate = (health?: WidgetHealth) => !health || health.upToDate
