import { ActionType } from '@redux-saga/types'
import { Action } from 'redux'
import { Task } from 'redux-saga'
import { call, debounce, fork, takeEvery, takeLatest, throttle } from 'redux-saga/effects'

import { logger } from './logging/Logger'

export function* takeEveryChildAction<A extends Action>(
    parentActionType: ActionType,
    childActionType: ActionType | ActionType[],
    worker: (action: A) => any,
) {
    yield takeEvery<A>(childActionMatcher(parentActionType, childActionType), function* (p: any) {
        yield worker(p.payload)
    })
}
export function* takeLatestChildAction<A extends Action>(
    parentActionType: ActionType,
    childActionType: ActionType | ActionType[],
    worker: (action: A) => any,
    autoCancel: boolean = false,
) {
    const task: Task = yield takeLatest<A>(
        childActionMatcher(parentActionType, childActionType),
        function* (p: any) {
            yield worker(p.payload)
            if (autoCancel) {
                task.cancel()
            }
        },
    )
}

export function* throttleChildAction<A extends Action>(
    time: number,
    parentActionType: ActionType,
    childActionType: ActionType | ActionType[],
    worker: (action: A) => any,
) {
    yield throttle<A>(
        time,
        childActionMatcher(parentActionType, childActionType),
        function* (p: any) {
            yield worker(p.payload)
        },
    )
}

export function* debounceChildAction<A extends Action>(
    time: number,
    parentActionType: ActionType,
    childActionType: ActionType | ActionType[],
    worker: (action: A) => any,
) {
    yield debounce<A>(
        time,
        childActionMatcher(parentActionType, childActionType),
        function* (p: any) {
            yield worker(p.payload)
        },
    )
}

export const childActionMatcher =
    (parentActionType: ActionType, childActionType: ActionType | ActionType[]) => (arg: any) => {
        const childTypes = Array.isArray(childActionType) ? childActionType : [childActionType]
        return arg.type === parentActionType && childTypes.indexOf(arg.payload.type) >= 0
    }

type AnyGeneratorAction = () => Generator<any>
type AnyGenerator = Generator<any>

type ErrorCallback = (error: any) => void

function* wrapWithLogging(action: () => AnyGenerator, onError?: ErrorCallback) {
    while (true) {
        try {
            yield call(action)
        } catch (error) {
            logger.handleError(error as Error)
            onError?.(error)
        }
    }
}

export function forkWrap(action: AnyGeneratorAction, onError?: ErrorCallback) {
    return fork(() => wrapWithLogging(action, onError))
}
