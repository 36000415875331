import { TobtList } from '../../services/airportStatus/cdm/types/TobtTypes'
import { BaseRestService } from '../base/BaseRestService'
import { CdmMetricsDto } from './types/CdmMetricsDto'

class CdmRestService extends BaseRestService {
    public getCdmMetrics(): Promise<CdmMetricsDto> {
        return this.getAndTransform<CdmMetricsDto>(
            '/airport-status/cdm',
            BaseRestService.jsonTransformer,
        )
    }

    public getTobtList(): Promise<TobtList> {
        return this.getAndTransform<TobtList>(
            '/airport-status/tobt-lists',
            BaseRestService.jsonTransformer,
        )
    }
}

export const cdmRestService = new CdmRestService()
