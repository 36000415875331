import {
    paxForecastSyncAction,
    paxForecastSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/paxForecast/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../../components/sync/SyncWrapper'

interface PaxForecastSyncWrapperProps {
    id: SyncDataKey
}

export const PaxForecastSyncWrapper: FC<PropsWithChildren<PaxForecastSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={paxForecastSyncAction(id)} onLeave={paxForecastSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
