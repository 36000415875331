import { lonLatToCoordinate } from 'aos-helpers/src/helpers/coordinate/CoordinateTransformer'
import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { Box } from 'aos-ui/src/components/base/Box'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Overlay as OlOverlay } from 'ol'
import { Positioning } from 'ol/Overlay'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { BaseComponent } from '../base/BaseComponent'

interface OverlayProps {
    positioning?: Positioning
    position?: LonLat
    stopEvent?: boolean
    isVisible: boolean
}

export class Overlay extends BaseComponent<PropsWithChildren<OverlayProps>> {
    private container: HTMLDivElement | null = null
    private overlayElement: OlOverlay | null = null
    private isAddedToMap: boolean = false

    public componentDidMount() {
        this.overlayElement = new OlOverlay({
            positioning: this.props.positioning,
            element: this.container!,
            stopEvent: this.props.stopEvent,
        })
        this.updateFromProps(this.props)
    }

    public componentWillUnmount() {
        this.context.map.removeOverlay(this.overlay)
    }

    public componentWillReceiveProps(next: OverlayProps) {
        this.updateFromProps(next)
    }

    public render() {
        return <OverlayBox ref={ref => (this.container = ref)}>{this.props.children}</OverlayBox>
    }

    private updateFromProps = (props: OverlayProps) => {
        const { position, isVisible, positioning } = props
        const { map } = this.context
        if (position) {
            this.overlay.setPosition(lonLatToCoordinate(position))
        }

        if (isVisible !== this.isAddedToMap) {
            isVisible ? map.addOverlay(this.overlay) : map.removeOverlay(this.overlay)
            this.isAddedToMap = isVisible
        }

        if (positioning) {
            this.overlay.setPositioning(positioning)
        }
    }
    private get overlay() {
        return this.overlayElement!
    }
}

const OverlayBox = styled(Box)`
    box-shadow: ${boxShadow.std};
    max-width: 300px;
    margin: 0 -25px;
    transform: translate(0, -66px);
`
