import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { quickCollectionFilter } from 'aos-services/src/services/flightInformation/types/QuickFilterable'
import {
    Towing,
    TowingDataTimeTypes,
    towingDateTimeColumns,
    TowingKey,
} from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { createSelector } from 'reselect'

import { urlQueryStringSelector } from '../../router/selectors'
import { urlFiltersParser } from '../helpers/urlFilters'
import { quickSearchQuerySelector, sortingSelector, towingUrlFiltersSelector } from '../selectors'
import { FlightInformationStateAware, FlightSort, SortingRule } from '../state'
import { towingColumnFunctions } from './towingColumnFunctions'
import { defaultTowingFilters, TowingFilters, towingUrlFiltersConfig } from './towingFiltersState'

const towingListSelector = (state: FlightInformationStateAware) => state.flights.towingList
export const towingListColumnValuesSelector = (state: FlightInformationStateAware) =>
    state.flights.columnValues

const sortTowingListBy = (towingList: Towing[], sorting: SortingRule): Towing[] => {
    const colFunctions = towingColumnFunctions[sorting.field as keyof Towing]

    if (!colFunctions || !colFunctions.sorter) {
        return towingList
    }

    return towingList.slice().sort((a, b) => colFunctions.sorter!(a, b, sorting.desc))
}

const filterTowingList = (towingList: Towing[], filters: TowingFilters) =>
    towingList.filter(towing =>
        Object.keys(filters).every(filterName => {
            const colFunction = towingColumnFunctions[filterName as keyof Towing]
            const id = filterName as keyof TowingFilters
            const filed = filterName as keyof Towing
            const value = filters[id]

            if (!colFunction || !colFunction.filter || !value || towing[filed] === undefined) {
                return true
            }

            return colFunction.filter({ id, value }, towing)
        }),
    )

export const sortedAndFilteredTowingListSelector = createSelector(
    towingListSelector,
    towingUrlFiltersSelector,
    sortingSelector(FlightSort.TOWING),
    quickSearchQuerySelector,
    (towingList, filters, sorting, quickSearchQuery): Towing[] => {
        const sortedList = sortTowingListBy(towingList, sorting as SortingRule)
        const filteredList = filterTowingList(sortedList, filters as TowingFilters)

        if (quickSearchQuery) {
            return quickCollectionFilter(filteredList, quickSearchQuery)
        }

        return filteredList
    },
)

export const towingListNowIndexSelector = createSelector(
    sortedAndFilteredTowingListSelector,
    currentTimeSelector,
    sortingSelector(FlightSort.TOWING),
    (towingList, now, sorting) => {
        const field = sorting?.field as TowingKey

        if (!towingDateTimeColumns.includes(field)) {
            return
        }

        const descPredicate = (f?: DateTime) => f && f.isBefore(now)
        const ascPredicate = (f?: DateTime) => f && f.isAfter(now)
        const predicate = sorting?.desc ? descPredicate : ascPredicate

        const data: DateTime[] = towingList.map(towing => {
            const item = towing[field] as TowingDataTimeTypes
            if ('value' in item) {
                return item.value
            }
            return item
        })

        return data.filter(predicate).length
    },
)

export const isLoadingTowingSelector = (state: FlightInformationStateAware) =>
    state.flights.isTowingLoading

export const towingFiltersSelector = createSelector(
    urlQueryStringSelector,
    params =>
        urlFiltersParser(
            params as string,
            towingUrlFiltersConfig,
            defaultTowingFilters,
        ) as TowingFilters,
)
