import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosFeedOut } from 'aos-services/src/services/events/types/AosFeedOut'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { ScrollPosition } from 'aos-ui/src/components/scrollbar/Scrollbar'
import { SectionHeader } from 'aos-ui/src/components/text/SectionHeader'
import React, { PureComponent } from 'react'

import { MessagesContainer } from '../common/messages/MessagesContainer'

export class EventFeedOuts extends PureComponent<EventAttachmentsProps> {
    public render() {
        const { feedOuts } = this.props
        return (
            <Box className={cxp(this.props, 'panel--grey full-height')}>
                <DarkScrollbar
                    scrollOnChecksumUpdate={ScrollPosition.bottom}
                    startPosition={ScrollPosition.bottom}
                    checksum={feedOuts.length}
                >
                    <SectionHeader
                        title={translate('event-preview.messages')}
                        numberNote={feedOuts.length}
                        paddingLeft={30}
                    />
                    <MessagesContainer
                        messages={feedOuts}
                        className='padding-horizontal--x-large'
                        messagesTitleVariant={ThemeVariant.White}
                    />
                </DarkScrollbar>
            </Box>
        )
    }
}

interface EventAttachmentsProps extends ClassNameProps {
    feedOuts: AosFeedOut[]
}
