import { Color } from '../../../styles/Color'
import { GaugeStatus } from '../types/GaugeStatus'

const segmentColors: Record<GaugeStatus, Color> = {
    [GaugeStatus.Normal]: Color.SoftGreen,
    [GaugeStatus.Warning]: Color.Yellow,
    [GaugeStatus.Alert]: Color.Red,
}

export const getColor = (segment: GaugeStatus): Color => segmentColors[segment]
