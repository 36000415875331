import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Sort } from 'aos-ui/src/components/table/TableHeaderButton'
import { TypedColumn } from 'aos-ui/src/components/table/TypedColumn'
import { chain, compact } from 'lodash'
import React, { FC, useCallback, useMemo } from 'react'

import { FlightInfoField } from '../../../../core/flightInformation/flight/FlightInfoField'
import { SortingRule } from '../../../../core/flightInformation/state'
import { FlightNumberCell } from '../../../flightInformation/cells/FlightNumberCell'
import { HandlingAgentCell } from '../../../flightInformation/cells/HandlingAgentCell'
import { NatureOfFlightCell } from '../../../flightInformation/cells/NatureOfFlightCell'
import { RouteCell } from '../../../flightInformation/cells/RouteCell'
import { TimeCell } from '../../../flightInformation/cells/TimeCell'
import { FlightTableHeader } from '../../../flightInformation/header/FlightTableHeader'
import { WidgetFlights } from './WidgetFlights'
interface StandardFlightTableProps {
    flights: Flight[]
    sort: SortingRule
    onChangeSorting: (v: Sort<FlightInfoField>) => void
}
export const StandardFlightTable: FC<StandardFlightTableProps> = ({
    flights,
    sort,
    onChangeSorting,
}) => {
    const handleSort = useCallback((sort: SortingRule) => {
        switch (sort.field) {
            case FlightInfoField.naflt:
                return (v: Flight) => v.naflt.code
            case FlightInfoField.routes:
                return (v: Flight) => compact(v.routes.map(r => r.airport)).join()
            default:
                return (v: Flight) => v[sort.field as keyof Flight]
        }
    }, [])

    const flightsWithSort = useMemo(() => {
        return chain(flights)
            .orderBy(f => handleSort(sort)(f), sort.desc ? 'desc' : 'asc')
            .value()
    }, [sort, flights])

    return (
        <WidgetFlights flights={flightsWithSort}>
            <TypedColumn<Flight, FlightInfoField.sdt>
                dataKey={FlightInfoField.sdt}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.sdt,
                            onChange: onChangeSorting,
                        }}
                        caption='sdt'
                    />
                )}
                cellRenderer={({ cellData }) => <TimeCell value={cellData} />}
            />
            <TypedColumn<Flight, FlightInfoField.hapt>
                dataKey={FlightInfoField.hapt}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.hapt,
                            onChange: onChangeSorting,
                        }}
                        caption='apt'
                    />
                )}
            />
            <TypedColumn<Flight, FlightInfoField.naflt>
                dataKey={FlightInfoField.naflt}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.naflt,
                            onChange: onChangeSorting,
                        }}
                        caption='naflt'
                    />
                )}
                cellRenderer={({ rowData }) => <NatureOfFlightCell row={rowData} />}
            />
            <TypedColumn<Flight, FlightInfoField.fltnr>
                dataKey={FlightInfoField.fltnr}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.fltnr,
                            onChange: onChangeSorting,
                        }}
                        caption='fltnr'
                    />
                )}
                cellRenderer={({ rowData }) => (
                    <FlightNumberCell fltnr={rowData.nr} cflight={rowData.cflight} />
                )}
            />
            <TypedColumn<Flight, FlightInfoField.routes>
                dataKey={FlightInfoField.routes}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.routes,
                            onChange: onChangeSorting,
                        }}
                        caption='route'
                    />
                )}
                cellRenderer={({ rowData }) => <RouteCell row={rowData} />}
            />
            <TypedColumn<Flight, FlightInfoField.callsign>
                dataKey={FlightInfoField.callsign}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.callsign,
                            onChange: onChangeSorting,
                        }}
                        caption='callsign'
                    />
                )}
            />
            <TypedColumn<Flight, FlightInfoField.acreg>
                dataKey={FlightInfoField.acreg}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.acreg,
                            onChange: onChangeSorting,
                        }}
                        caption='acreg'
                    />
                )}
            />
            <TypedColumn<Flight, FlightInfoField.actype>
                dataKey={FlightInfoField.actype}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.actype,
                            onChange: onChangeSorting,
                        }}
                        caption='actype'
                    />
                )}
            />
            <TypedColumn<Flight, FlightInfoField.handl>
                dataKey={FlightInfoField.handl}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.handl,
                            onChange: onChangeSorting,
                        }}
                        caption='handl'
                    />
                )}
                cellRenderer={({ cellData }) => <HandlingAgentCell value={cellData} />}
            />
        </WidgetFlights>
    )
}
