import { Projection } from 'aos-helpers/src/helpers/Projection'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { defaults } from 'ol/control'
import { Layer, Tile } from 'ol/layer'
import Map from 'ol/Map'
import { toLonLat, transformExtent } from 'ol/proj'
import OSM from 'ol/source/OSM'
import View from 'ol/View'
import React, { PropsWithChildren, PureComponent } from 'react'
// TODO remove it in favour of size-me ?
import ResizeObserver from 'resize-observer-polyfill'

import { layerIdKey, Provider as MapContextProvider } from './OpenlayersMapContext'

export interface OpenlayersMapProps {
    maxResolution?: number
    minResolution?: number
    maxZoom?: number
    minZoom?: number
    initialBoundsSet: f.Action1<BBox> | undefined
    bg?: Color
}

export class OpenlayersMap extends PureComponent<PropsWithChildren<OpenlayersMapProps>> {
    private mapRef: HTMLDivElement | null = null
    private readonly olMap: Map

    constructor(props: OpenlayersMapProps) {
        super(props)
        const mapView = new View(props)

        this.olMap = new Map({
            layers: [
                new Tile({
                    source: new OSM(),
                }),
            ],
            view: mapView,
            controls: defaults({
                attribution: false,
                zoom: false,
                rotate: false,
            }),
        })
    }

    public componentDidMount() {
        this.olMap.setTarget(this.mapRef!)

        // trigger map redraw on container size change
        new ResizeObserver(() => {
            this.olMap.updateSize()
        }).observe(this.mapRef!)

        if (this.props.initialBoundsSet) {
            const bounds = this.currentBounds
            if (bounds) {
                this.props.initialBoundsSet(bounds)
            }
        }
    }

    public render() {
        return (
            <Box
                fullSize
                relative
                ref={input => {
                    this.mapRef = input
                }}
            >
                <MapContextProvider
                    value={{
                        map: this.olMap,
                        currentPosition: () => this.currentPosition,
                        currentBounds: () => this.currentBounds,
                        getLayersById: this.layerById,
                    }}
                >
                    {this.props.children}
                </MapContextProvider>
            </Box>
        )
    }

    private layerById = <T extends Layer>(id: string): T[] =>
        this.olMap
            .getLayers()
            .getArray()
            .filter(l => l.get(layerIdKey) === id) as T[]

    private get currentPosition(): MapPosition | null {
        const center = this.mapView.getCenter()
        const zoom = this.mapView.getZoom()
        if (zoom && center) {
            const coordinate = toLonLat(center)
            return {
                lon: coordinate[0],
                lat: coordinate[1],
                zoom,
            }
        }
        return null
    }

    private get currentBounds(): BBox | null {
        const extent = this.mapView.calculateExtent(this.olMap.getSize())
        return transformExtent(extent, Projection.Mercator, Projection.WorldGeodeticSystem) as BBox
    }

    private get mapView() {
        return this.olMap.getView()
    }
}
