import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum QueueingDataTypeFilter {
    WaitTime = 1,
    QueueLength = 0,
}

export const translateQueueingDataTypeFilter = translateEnum<QueueingDataTypeFilter>(
    QueueingDataTypeFilter,
    'dashboard.queueing-time.filter-tab',
)

export interface QueueingTimeFilters {
    queueingTimeDataType: QueueingDataTypeFilter
}

export const defaultQueueingTimeFilters = {
    queueingTimeDataType: QueueingDataTypeFilter.WaitTime,
}
