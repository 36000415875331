import { conditionalWrapper } from 'aos-helpers/src/helpers/Function'
import React, { FC, ReactNode } from 'react'

import { BaseDragDropTargetProps } from './DragAndDrop'

interface DnDWrapperProps extends BaseDragDropTargetProps {
    draggable?: boolean
    droppable?: boolean
    children: ReactNode
    onClick?(): void
}

export const DnDWrapper: FC<DnDWrapperProps> = ({
    onClick,
    children,
    connectDragSource,
    draggable,
    connectDropTarget,
    droppable,
}) => {
    const dragSourceWrapper = conditionalWrapper(draggable, connectDragSource)
    const dropTargetWrapper = conditionalWrapper(droppable, connectDropTarget)
    return dropTargetWrapper(dragSourceWrapper(<div onClick={onClick}>{children}</div>))
}
