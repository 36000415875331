import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DatetimeInput } from 'aos-ui/src/components/form/datetime/DatetimeInput'
import React, { PureComponent } from 'react'

import { SimpleFormModalActionCreators } from './core/simpleForm/actions'
import { DateValueWrapper, SingleDateModalState } from './core/singleDate/state'
import { SimpleFormModal, SimpleFormModalProps } from './SimpleFormModal'

export class SingleDateModalClass extends PureComponent<
    SimpleFormModalProps & SimpleFormModalActionCreators & SingleDateModalState,
    {}
> {
    public render() {
        const changeDate = (value?: DateTime) => this.changeForm({ value })
        return (
            <SimpleFormModal {...this.props}>
                <div className='padding-vertical--large'>
                    <DatetimeInput
                        value={this.props.currentValue.value}
                        onChange={changeDate}
                        placeholder={this.translateSuffix('placeholder')}
                    />
                </div>
            </SimpleFormModal>
        )
    }

    protected changeForm = (p: Partial<DateValueWrapper>) => {
        this.props.changeFormValueAction(Object.assign({}, this.props.currentValue, p))
    }

    private translateSuffix = (v: string) => translate(`${this.props.keyPrefix}.${v}`)
}
