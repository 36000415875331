import { FeedOutNotification } from 'aos-services/src/services/notifications/types/FeedOutNotification'
import { LvpWaAlertNotification } from 'aos-services/src/services/notifications/types/LvpWaAlertNotification'
import { Box } from 'aos-ui/src/components/base/Box'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import React, { PropsWithChildren, PureComponent } from 'react'

import { EventRelatedNotificationItem } from './EventRelatedNotificationItem'

export class FeedOutNotificationItem extends PureComponent<
    PropsWithChildren<FeedOutNotificationItemProps>
> {
    public render() {
        const { notification } = this.props
        const onClick = () => this.props.openEventAction(notification.eventId)
        return (
            <EventRelatedNotificationItem notification={notification} onClick={onClick}>
                <Box paddingBottom={12} paddingTop={8}>
                    {notification.text}
                </Box>
                <HourClock time={notification.timestamp} />
            </EventRelatedNotificationItem>
        )
    }
}

interface FeedOutNotificationItemProps {
    notification: FeedOutNotification | LvpWaAlertNotification
    openEventAction(v: number): void
}
