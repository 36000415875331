import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { BlueLink } from '../../components/buttons/BlueLink'
import { toggleWhatsNewAction } from '../../core/layout/actions'
import { resetRemoveAccount } from '../../core/removeAccount/actions'
import { RemoveAccountActionTypes, RemoveAccountStateAware } from '../../core/removeAccount/state'
import errorMessage from './assets/error-message.png'
import logo from './assets/logo.svg'
import successMessage from './assets/success-message.png'
import { DeleteAccount } from './DeleteAccount'
import { RemoveAccountDialog } from './RemoveAccountDialog'

const SUPPORT_EMAIL = 'support@finavia.fi'

export const RemoveAccount = () => {
    const result = useSelector((state: RemoveAccountStateAware) => state.removeAccount.result)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(toggleWhatsNewAction(false))
        return () => {
            dispatch(resetRemoveAccount())
        }
    }, [])

    return (
        <Box alignContent='stretch' fullSize column bg={Color.White} padding={40}>
            <SvgImage svg={logo} />
            <BackgroundImage row flex={1} justify='center'>
                {result === null && <DeleteAccount />}
                {result === RemoveAccountActionTypes.SUCCESS && (
                    <RemoveAccountDialog
                        title={translate('remove-account.success.title')}
                        description={
                            <Text>
                                {translate('remove-account.success.description')}
                                <BlueLink
                                    to='#'
                                    style={{ paddingRight: 4 }}
                                    onClick={() => {
                                        window.location.href = `mailto:${SUPPORT_EMAIL}`
                                    }}
                                >
                                    {SUPPORT_EMAIL}
                                </BlueLink>
                            </Text>
                        }
                    >
                        <Box row justify='center'>
                            <img alt='success-image' src={successMessage} style={{ height: 180 }} />
                        </Box>
                    </RemoveAccountDialog>
                )}
                {result === RemoveAccountActionTypes.ERROR && (
                    <RemoveAccountDialog
                        title={translate('remove-account.error.title')}
                        description={
                            <Text>
                                {translate('remove-account.error.description-1')}
                                <BlueLink
                                    to='#'
                                    style={{ paddingRight: 4 }}
                                    onClick={() => {
                                        window.location.href = `mailto:${SUPPORT_EMAIL}`
                                    }}
                                >
                                    {SUPPORT_EMAIL}
                                </BlueLink>
                                {translate('remove-account.error.description-2')}
                            </Text>
                        }
                    >
                        <Box row justify='center'>
                            <img alt='error-image' src={errorMessage} style={{ height: 180 }} />
                        </Box>
                    </RemoveAccountDialog>
                )}
            </BackgroundImage>
        </Box>
    )
}

const BackgroundImage = styled(Box)`
    background-image: url(bg-logo.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-clip: content-box;
    width: 100%;
    height: 100%;
    @media (min-width: 1200px) and (max-width: 1800px) {
        background-size: 75%;
    }
    @media (max-width: 1199px) {
        background-size: 100%;
        background-position: bottom;
    }
`
