import React, { RefObject, useEffect } from 'react'

export const usePortal = (parent: RefObject<Element>) => {
    const rootElemRef = React.useRef(document.createElement('div'))

    useEffect(() => {
        if (parent.current) {
            // Add the detached element to the parent
            parent.current.appendChild(rootElemRef.current)
            // This function is run on unmount
        }
        return () => {
            rootElemRef.current.remove()
        }
    }, [parent])

    return rootElemRef.current
}
