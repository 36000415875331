import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import React, { FC } from 'react'

interface DashboardItemTitleIconProps extends SvgProps {
    iconVariant?: IconVariant
    onClick(): void
}

export const DashboardItemTitleIcon: FC<DashboardItemTitleIconProps> = props => (
    <IconButton
        marginLeft={4}
        onClick={props.onClick}
        iconSize={BlockSize.Std}
        svg={props.svg}
        iconVariant={props.iconVariant || IconVariant.White}
    />
)
