import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { QueueingData } from '../../../services/queueingTime/types/QueueingData'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const QUEUEING_TIME_SYNC = 'STATUS_DASHBOARD_DATA/QUEUEING/QUEUEING_TIME_SYNC'
export type QueueingTimeSyncAction = PayloadAction<typeof QUEUEING_TIME_SYNC, SyncDataKey>
export const queueingTimeSyncAction = payloadActionCreator<QueueingTimeSyncAction, SyncDataKey>(
    QUEUEING_TIME_SYNC,
)

export const QUEUEING_TIME_SYNC_STOP = 'STATUS_DASHBOARD_DATA/QUEUEING/QUEUEING_TIME_SYNC_STOP'
export type QueueingTimeSyncStopAction = PayloadAction<typeof QUEUEING_TIME_SYNC_STOP, SyncDataKey>
export const queueingTimeSyncStopAction = payloadActionCreator<
    QueueingTimeSyncStopAction,
    SyncDataKey
>(QUEUEING_TIME_SYNC_STOP)

export const QUEUEING_TIME_LOAD = 'STATUS_DASHBOARD_DATA/QUEUEING/QUEUEING_TIME_LOAD'
export type QueueingTimeLoadAction = Action<typeof QUEUEING_TIME_LOAD>
export const queueingTimeLoadAction = emptyActionCreator<QueueingTimeLoadAction>(QUEUEING_TIME_LOAD)

export const QUEUEING_TIME_LOAD_SUCCESS =
    'STATUS_DASHBOARD_DATA/QUEUEING/QUEUEING_TIME_LOAD_SUCCESS'
export type QueueingTimeLoadSuccessAction = PayloadAction<
    typeof QUEUEING_TIME_LOAD_SUCCESS,
    QueueingData
>
export const queueingTimeLoadSuccessAction = payloadActionCreator<
    QueueingTimeLoadSuccessAction,
    QueueingData
>(QUEUEING_TIME_LOAD_SUCCESS)

export type QueueingTimeAction =
    | QueueingTimeSyncAction
    | QueueingTimeSyncStopAction
    | QueueingTimeLoadAction
    | QueueingTimeLoadSuccessAction
