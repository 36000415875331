import { dateTime } from 'aos-helpers/src/helpers/Time'
import { makePaxForecastSelector } from 'aos-services/src/core/statusDashboardData/paxForecast/selectors/paxForecast'
import { PaxForecastChartData } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { setPaxForecastFiltersAction } from '../../../../core/statusDashboard/actions'
import { PaxForecastItemState } from '../../../../services/statusDashboard/types/itemStates/PaxForecastItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PaxForecastChartSection } from './chart/PaxForecastChartSection'
import { PaxForecastFooter } from './partials/PaxForecastFooter'
import { PaxForecastItem } from './partials/PaxForecastItem'
import { PaxForecastStatsSection } from './stats/PaxForecastStatsSection'

interface PaxForecastStateProps {
    stats: PaxForecastStats | null
    chartData: PaxForecastChartData | null
    terminals: string[]
}

interface PaxForecastDispatchProps {
    setFilters: typeof setPaxForecastFiltersAction
}

type PaxForecastOwnProps = StatusDashboardItemCommonProps<PaxForecastItemState>

interface PaxForecastProps
    extends PaxForecastStateProps,
        PaxForecastDispatchProps,
        PaxForecastOwnProps {}

const PaxForecastClass: FC<PaxForecastProps> = ({ stats, chartData, terminals, ...props }) => {
    const filters = props.itemState.filters
    return (
        <PaxForecastItem
            stats={stats}
            itemProps={pickDashboardItemProps(props)}
            showDate
            syncId={props.id}
        >
            {stats && <PaxForecastStatsSection stats={stats} />}
            {chartData && props.itemState.size === DashboardItemSizeVariant.Big && (
                <>
                    <PaxForecastChartSection
                        data={chartData}
                        timeRangeFilter={filters.timeRange}
                        customDateFilter={dateTime(filters.customDate)}
                        arrivalDepartureFilter={filters.arrivalDeparture}
                    />
                    <PaxForecastFooter
                        filters={filters}
                        terminals={terminals}
                        setFilterAction={filter => props.setFilters([props.id, filter])}
                    />
                </>
            )}
        </PaxForecastItem>
    )
}

export const PaxForecast = connect<
    PaxForecastStateProps | null,
    PaxForecastDispatchProps,
    PaxForecastOwnProps
>(makePaxForecastSelector, {
    setFilters: setPaxForecastFiltersAction,
})(PaxForecastClass)
