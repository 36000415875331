import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormComponentSize, ZIndex } from 'aos-ui/src/components/base/Theme'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

import { useLVPCounterController } from './useLVPCounterController'

export const LVPCounter = () => {
    const {
        canEditTasks,
        borderColor,
        textColor,
        counterValue,
        isCounterVisible,
        handleInspectionClick,
        handleOffClick,
        handleMouseEnter,
        handleMouseLeave,
        isCounterExpanded,
    } = useLVPCounterController()

    if (!isCounterVisible) {
        return null
    }

    return (
        <LVPCounterWrapper
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            paddingHorizontal={24}
            paddingVertical={16}
            rounded
            borderWidth={2}
            border={borderColor}
            rowGap={8}
            column
            alignItems='center'
            minWidth={250}
            overflow='hidden'
            style={{
                transition: 'height 0.5s ease',
                height: isCounterExpanded ? '145px' : '95px',
            }}
        >
            <Text color={Color.GreyLight} size={14} textTransform='uppercase'>
                {translate('tasks.lvpCounter.title')}
            </Text>

            <Text width='100%' color={textColor} size={36} textTransform='uppercase'>
                {counterValue}
            </Text>

            {canEditTasks && (
                <Box
                    row
                    columnGap={8}
                    fullWidth
                    style={{
                        opacity: isCounterExpanded ? 1 : 0,
                        transition: 'opacity 0.5s ease',
                    }}
                >
                    <FormButton
                        onClick={handleInspectionClick}
                        LabelContentComponent={LVPCounterButtonLabel}
                        fullWidth
                        label={translate('tasks.lvpCounter.inspectionButton.label')}
                        variant={FormButtonVariant.WhiteOutlined}
                        size={FormComponentSize.Small}
                    />
                    <FormButton
                        onClick={handleOffClick}
                        LabelContentComponent={LVPCounterButtonLabel}
                        fullWidth
                        label={translate('tasks.lvpCounter.offButton.label')}
                        variant={FormButtonVariant.WhiteOutlined}
                        size={FormComponentSize.Small}
                    />
                </Box>
            )}
        </LVPCounterWrapper>
    )
}

const LVPCounterButtonLabel = styled.div`
    padding: auto;
    color: inherit;
    text-align: center;
`

const LVPCounterWrapper = styled(Box)`
    background-color: ${Color.UiBlack1};
    position: absolute;
    left: 45%;
    top: 20px;
    z-index: ${ZIndex.Notifications};
`
