import { Chart } from 'aos-components/src/components/chart/Chart'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { calculateTimeDomainForSeries, Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { PaxEntry } from 'aos-services/src/services/pax/types/PaxEntry'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import { Color } from 'aos-ui-common/src/styles/Color'
import { AxisScale } from 'd3-axis'
import { chain } from 'lodash'
import React, { FC, useMemo } from 'react'

import { PaxBarSeries } from './PaxBarSeries'
import { PaxLegend } from './PaxLegend'

export const PaxChart: FC<PaxChartProps> = ({ showLegend, data, y2Domain, y1Domain }) => {
    const xAccessor = (d: PaxEntry) => d.time
    const yAccessor = (d: PaxEntry) => d.pax

    if (!data?.length) {
        return null
    }
    const linearOrderedData = useMemo(
        () =>
            chain(data)
                .orderBy(q => q.time)
                .value(),
        [data],
    )
    const xDomain = calculateTimeDomainForSeries(linearOrderedData)

    const currentTimeVerticalLine = ({
        xPos,
        chartHeight,
    }: {
        xPos: number
        chartHeight: number
    }) => {
        return (
            <line
                x1={xPos}
                y1={10}
                x2={xPos}
                strokeWidth={3}
                y2={chartHeight - 70}
                stroke={Color.PrimaryLight}
            />
        )
    }
    const renderContent = ({ y1Scale, y2Scale, xRange, xScale, outerSize }: ChartChildrenProps) => {
        if (!xScale || !y1Scale) {
            return null
        }
        const timeDomain = xScale.domain
        const lineSeriesScales = {
            xScale,
            yScale: y1Scale,
        }
        const barSeriesScales = {
            xScale,
            yScale: y2Scale as AxisScale<number>,
        }
        const chartHeight = outerSize.height
        const domain = timeDomain()
        const xPos =
            Scales.timeScale(xRange, { domain: [domain[0], domain[1]], ticks: [] })(dateTime()) || 0

        return (
            <>
                <PaxBarSeries domain={y2Domain} data={linearOrderedData} scales={barSeriesScales} />
                {currentTimeVerticalLine({ xPos, chartHeight })}
                <LineSeries
                    data={linearOrderedData}
                    seriesConfig={{
                        color: Color.White,
                        withDots: true,
                    }}
                    accessors={{ xAccessor, yAccessor }}
                    scales={lineSeriesScales as any}
                />
            </>
        )
    }

    return (
        <Chart
            flex
            bottomLegend={{
                height: 30,
                component: showLegend && <PaxLegend />,
            }}
            xConfig={{
                domain: xDomain,
                tickFormat: formatHour,
            }}
            y1Config={{
                domain: y1Domain,
                tickFormat: formatNumber,
            }}
            y2Config={{ domain: y2Domain, unit: 'min', tickFormat: formatNumber }}
        >
            {renderContent}
        </Chart>
    )
}

export interface PaxChartProps {
    data: PaxEntry[]
    y1Domain: Domain<number>
    y2Domain: Domain<number>
    showLegend: boolean
}
