import { GatesProperties, gateToGatesArray } from './GatesProperties'

export interface StandProperties {
    teksti: string
}

export const standAsGatesProperties = (gate: GatesProperties): StandProperties => {
    const gates = gateToGatesArray(gate)
    return {
        teksti: gates[0],
    }
}
