import { setEventMapUserLayersVisibilityAction } from 'aos-services/src/core/eventUserLayersVisibility/actions'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { connect } from 'react-redux'

import {
    changeFormValueAction,
    eventHideModalAction,
    eventModalsAttachmentsParentAction,
    eventModalsMapAction,
    reportExtendedFeedInAction,
    reportSimpleFeedInShowModalAction,
    saveLocationAction,
    setExtendedFormPristineAction,
} from '../../core/eventModals/actions'
import { EventModal } from '../../core/eventModals/state'
import { State } from '../../core/state'
import {
    EventModalsDispatchProps,
    EventModalsExtendedFormClass,
    EventModalsProps,
} from './EventModalsClass'
import { EventFormType } from './partial/EventFormProps'

export const ReportExtendedFeedInModal = connect<EventModalsProps, EventModalsDispatchProps>(
    (state: State) => ({
        simpleForm: state.eventModals.simpleForm,
        extendedForm: state.eventModals.extendedForm,
        currentEvent: state.eventModals.currentEvent,
        attachmentsState: state.eventModals.attachmentsState,
        map: state.eventModals.map,
        parentFeedInId: state.eventModals.parentFeedInId,
        keyPrefix: 'report-feed-in',
        isOpen: state.eventModals.currentModal === EventModal.ReportExtendedFeedIn,
        isEditingEvent: true,
        isApoc: state.auth.currentUser.isApoc(),
        modalKind: ModalKind.Big,
        groups: state.commonFrontend.groups,
        bimLayers: state.bimLayers,
        eventMapUserLayerVisibility: state.eventMapUserLayerVisibility,
        formType: EventFormType.ReportExtendedFeedInModal,
    }),
    {
        saveAction: reportExtendedFeedInAction,
        changeFormValue: changeFormValueAction,
        attachmentsAction: eventModalsAttachmentsParentAction,

        saveLocation: saveLocationAction,
        switchMap: eventModalsMapAction.switchMapAction,
        setLayersVisibility: eventModalsMapAction.setLayersVisibilityAction,
        resetLocation: eventModalsMapAction.resetPickedLocationAction,
        pickLocation: eventModalsMapAction.pickLocationAction,

        hideAction: eventHideModalAction,
        setFormPristine: setExtendedFormPristineAction,
        toggleModeAction: reportSimpleFeedInShowModalAction,
        setEventMapUserLayersVisibilityAction,
    },
)(EventModalsExtendedFormClass)
