import { flatMap } from 'lodash'

export enum PublicRemarkCode {
    Bor = 'BOR',
    Cld = 'CLD',
    Cnl = 'CNL',
    Del = 'DEL',
    Dep = 'DEP',
    Div = 'DIV',
    Dlu = 'DLU',
    Est = 'EST',
    Eta = 'ETA',
    Fin = 'FIN',
    Gtc = 'GTC',
    Gto = 'GTO',
    Inf = 'INF',
    Lan = 'LAN',
    Ndt = 'NDT',
    Rmv = 'RMV',
    A = 'A',
    B = 'B',
    Bbr = 'BBR',
    Bbg = 'BBG',
    Bc = 'BC',
    C = 'C',
    Chk = 'CHK',
    D = 'D',
    Dla = 'DLA',
    E = 'E',
    F = 'F',
    Go = 'GO',
    I = 'I',
    Lg = 'LG',
    V = 'V',
}

export enum FlightPrmGroup {
    Departed = 'Departed',
    Cancelled = 'Cancelled',
    Unknown = 'Unknown',
    Landed = 'Landed',
    Estimated = 'Estimated',
    Delayed = 'Delayed',
    NextInfo = 'NextInfo',
}

export enum PrmColor {
    Grey = '#879dc1',
    Red = '#ff2222',
    Yellow = '#ffd145',
    Green = '#57cc5d',
    LightGrey = '#f7f7fa',
}

type PrmColorMappingEntry = [PrmColor, PublicRemarkCode[]]

const prmColorMapping: PrmColorMappingEntry[] = [
    [
        PrmColor.Green,
        [
            PublicRemarkCode.Dep,
            PublicRemarkCode.Lan,
            PublicRemarkCode.A,
            PublicRemarkCode.B,
            PublicRemarkCode.D,
        ],
    ],
    [
        PrmColor.Red,
        [PublicRemarkCode.Cld, PublicRemarkCode.Cnl, PublicRemarkCode.Cld, PublicRemarkCode.C],
    ],
    [PrmColor.Yellow, [PublicRemarkCode.Del, PublicRemarkCode.Dla]],
    [PrmColor.Yellow, [PublicRemarkCode.Del, PublicRemarkCode.Dla]],
    [
        PrmColor.LightGrey,
        [
            PublicRemarkCode.Bor,
            PublicRemarkCode.Eta,
            PublicRemarkCode.Fin,
            PublicRemarkCode.Gtc,
            PublicRemarkCode.Ndt,
            PublicRemarkCode.Rmv,
            PublicRemarkCode.Bbr,
            PublicRemarkCode.Bbg,
            PublicRemarkCode.Bc,
            PublicRemarkCode.F,
            PublicRemarkCode.Go,
        ],
    ],
]

const prmColorMap = new Map<PublicRemarkCode, PrmColor>(
    flatMap(prmColorMapping, (t: PrmColorMappingEntry) =>
        t[1].map((code: PublicRemarkCode) => [code, t[0]] as [PublicRemarkCode, PrmColor]),
    ),
)

export const getColorForPrm = (code: PublicRemarkCode): PrmColor => {
    if (prmColorMap.has(code)) {
        return prmColorMap.get(code)!
    }
    return PrmColor.Grey
}

export interface AosPublicRemark {
    code: PublicRemarkCode
    text: string
}
