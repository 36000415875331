import { LVP_COUNTER_DEFAULT_VALUE } from 'aos-helpers/src/helpers/lvp/constants'
import { getLVPCounterStyle } from 'aos-helpers/src/helpers/lvp/utils'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { syncAlertsAction, syncAlertsStopAction } from 'aos-services/src/core/alerts/actions'
import { AlertsStateAware } from 'aos-services/src/core/alerts/state'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import { dismissLVPCounterAction, updateLVPCounterAction } from 'aos-services/src/core/lvp/actions'
import { AlertType } from 'aos-services/src/dataaccess/alerts/types/AlertsStateDto'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ActionModalStateAware } from '../../core/actionModal/state'
import { useSyncHook } from '../../hooks/useSync'

export const useLVPCounterController = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { enabled, endTime, taskId } =
        useSelector((state: AlertsStateAware) => state.alerts[AlertType.LVP_COUNTER]) || {}
    const [isHovered, setIsHovered] = useState(false)
    const timeoutRef = useRef<NodeJS.Timeout>()
    const canEditTasks = useSelector(canEditTasksSelector)

    const intervalRef = useRef<NodeJS.Timeout>()
    const [counterDuration, setCounterDuration] = useState<number>()

    const { borderColor, textColor } = useMemo(
        () => getLVPCounterStyle(counterDuration),
        [counterDuration],
    )

    const handleInspectionClick = useCallback(() => {
        if (!taskId) {
            return
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        const newEndTime = dateTime().add(LVP_COUNTER_DEFAULT_VALUE, 'h').format()
        dispatch(updateLVPCounterAction({ endTime: newEndTime, taskId }))
    }, [dispatch, taskId])

    const handleOffClick = useCallback(() => {
        if (!taskId) {
            return
        }
        dispatch(
            dismissLVPCounterAction({
                taskId,
            }),
        )
    }, [dispatch, taskId])

    const updateCounter = useCallback(() => {
        const now = dateTime()
        const lvpCounterEndTime = dateTime(endTime)

        const diff = lvpCounterEndTime.diff(now)
        setCounterDuration(diff <= 0 ? 0 : diff)
        if (now.isSameOrAfter(lvpCounterEndTime) && intervalRef.current) {
            clearInterval(intervalRef.current)
        }
    }, [enabled, taskId, endTime])

    const isVisible = useMemo(() => pathname.includes('tasks') && enabled, [pathname, enabled])

    const counterValue = useMemo(() => {
        if (counterDuration === undefined) {
            return
        }
        const duration = moment.duration(counterDuration)
        const hours = String(duration.hours()).padStart(2, '0')
        const minutes = String(duration.minutes()).padStart(2, '0')
        const seconds = String(duration.seconds()).padStart(2, '0')
        return `${hours} : ${minutes} : ${seconds}`
    }, [counterDuration])

    useEffect(() => {
        if (isVisible) {
            intervalRef.current = setInterval(updateCounter, 1000)
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
            setCounterDuration(undefined)
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [enabled, endTime, isVisible, intervalRef.current])

    const handleMouseEnter = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setIsHovered(true)
    }, [timeoutRef.current])

    const handleMouseLeave = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            setIsHovered(false)
        }, 5000)
    }, [timeoutRef.current])

    const isActionModalActive = useSelector(
        (state: ActionModalStateAware) => !!state.actionModal.modalConfig,
    )
    const isCounterExpanded = useMemo(
        () => (isHovered || isActionModalActive) && canEditTasks,
        [canEditTasks, isActionModalActive, isHovered],
    )

    useSyncHook(
        [syncAlertsAction(AlertType.LVP_COUNTER)],
        [syncAlertsStopAction(AlertType.LVP_COUNTER)],
    )

    return {
        canEditTasks,
        borderColor,
        textColor,
        counterValue,
        isCounterVisible: enabled && counterDuration !== undefined,
        handleInspectionClick,
        handleOffClick,
        handleMouseEnter,
        handleMouseLeave,
        isCounterExpanded,
    }
}
