import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { eventSeverityTitle } from 'aos-services/src/services/common/types/AosSeverity'
import { eventScenarioTitle } from 'aos-services/src/services/events/aosEventFormatter'
import { AosDashboardEvent } from 'aos-services/src/services/events/types/AosDashboardEvent'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import { IconEventItem } from 'aos-ui-event/src/components/event/IconEventItem'
import React, { FC } from 'react'

export const EventItem: FC<EventItemItemProps> = props => {
    const { event } = props
    const onClick = () => props.openEventAction(event.id)
    const tooltip = `${eventScenarioTitle(event.category)} - ${eventSeverityTitle(event.severity)}`

    return (
        <Box relative cursor='pointer' column onClick={onClick}>
            <Box row alignItems='center' flex={1}>
                <Tooltip body={tooltip} withArrow>
                    <div>
                        <Box paddingRight={12} shrink={0}>
                            <IconEventItem severity={event.severity} category={event.category} />
                        </Box>
                    </div>
                </Tooltip>
                <Box flex={1} row alignItems='center'>
                    <Box flex={1}>
                        <Text lineHeight='standard' textClomp={2} color={Color.White} size={16}>
                            {event.title}
                        </Text>
                    </Box>
                    <Box>
                        {event.hasPrivateChannel && (
                            <Icon
                                svg={SvgIcon.PrivateClosed}
                                iconSize={BlockSize.Small}
                                iconVariant={IconVariant.White}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box flex={1} column paddingTop={12}>
                <Box flex={1}>
                    <Text lineHeight='large' textClomp={2} color={Color.Grey} size={13}>
                        {event.description}
                    </Text>
                </Box>
                <Box paddingTop={20}>
                    {event.startTime && <HourClock time={event.startTime} showFullDate />}
                </Box>
            </Box>
        </Box>
    )
}

interface EventItemItemProps {
    event: AosDashboardEvent
    openEventAction(v: number): void
}
