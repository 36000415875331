import { FeatureFlagsAction, LOAD_FEATURE_FLAGS_SUCCESS } from './actions'
import { initialFeatureFlagState } from './state'

export const featureFlagReducer = (
    initialState = initialFeatureFlagState,
    action: FeatureFlagsAction,
) => {
    switch (action.type) {
        case LOAD_FEATURE_FLAGS_SUCCESS:
            return {
                ...initialState,
                flags: action.payload,
            }
        default:
            return initialState
    }
}
