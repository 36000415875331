import { SeleniumProps } from 'aos-helpers/src/helpers/SeleniumProps'
import React, { FCWithChildren } from 'react'

import { analyticsService } from '../base/AnalyticsService'
import { IdAware } from '../base/IdAware'

interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        IdAware,
        SeleniumProps {}

export const Button: FCWithChildren<ButtonProps> = props => {
    const { onClick, type = 'button', id, className, disabled, children } = props
    const buttonId = props.seleniumLocation || props.id

    const onClickWrapper = onClick
        ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              onClick(e)
              if (buttonId) {
                  analyticsService.buttonClick(buttonId)
              }
          }
        : onClick
    return (
        <button
            className={className}
            onClick={onClickWrapper}
            type={type}
            id={id}
            disabled={disabled}
        >
            {children}
        </button>
    )
}
