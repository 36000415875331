export const fontFamily = '"montserrat", sans-serif'

export const borderRadius = {
    small: 3,
    default: 5,
    big: 8,
    roundFilter: 14,
}

export const time = {
    fast: 0.2,
    normal: 0.3,
    slow: 0.5,
}

export enum ShadowColor {
    'Big' = 'rgba(0,0,0,0.4)',
    'Std' = 'rgba(0,0,0,0.2)',
    'Small' = 'rgba(0,0,0,0.15)',
}

export const boxShadow = {
    large: `0 0 20px 0 ${ShadowColor.Big}`,
    big: `0 0 15px 0 ${ShadowColor.Big}`,
    std: `0 2px 10px 0 ${ShadowColor.Std}`,
    stdInset: `inset 0 2px 10px 0 ${ShadowColor.Std}`,
    popover: `0 10px 15px 0 ${ShadowColor.Std}`,
    dnd: `0 20px 20px 0 ${ShadowColor.Big}`,
    dndSmall: `0 2px 10px 0 ${ShadowColor.Small}`,
    dndHover: `0 0 5px 0 ${ShadowColor.Small}`,
    none: 'none',
}

export enum ZIndex {
    Below = -1,
    Bottom = 1,
    Middle = 2,
    Top = 3,
    Overlay = 100,
    Header = 200,
    HeaderButton = 210,
    GlobalMessaging = 400,
    Menu = 500,
    AppHeader = 600,
    Notifications = 1500,
    Modal = 2000,
    DropdownMenu = 2500,
    Tooltip = 3000,
}

export enum FormComponentSize {
    VerySmall = 24,
    Small = 32,
    Header = 36,
    Std = 40,
    Big = 48,
}
