import { SearchIndexOptions, useSearch } from 'aos-ui/src/components/hooks/useSearch'
import React, { ReactNode } from 'react'

interface SearchableProps<L> {
    list: L[]
    extractText: (item: L) => string
    children: (filteredList: L[], query: string, setQuery: (text: string) => void) => ReactNode
    delay?: number
    limit?: number
    indexOptions?: SearchIndexOptions
}

export const Searchable = <L extends {}>(props: SearchableProps<L>) => {
    const { list, children, extractText, delay, limit, indexOptions = {} } = props
    const { results, query, setQuery } = useSearch(
        list,
        extractText,
        { delay, limit },
        indexOptions,
    )

    if (query.trim()) {
        return <>{children(results, query, setQuery)}</>
    }

    return <>{children(list, query, setQuery)}</>
}
