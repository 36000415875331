import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { CheckableBlock } from '../base/CheckableBlock'

export interface CircleRadioRendererProps {
    isSelected: boolean
    label: ReactNode
    color: Color
    disabled?: boolean
    onSelect(): void
}

interface CircleIconProps {
    isSelected: boolean
    size: BlockSize
    color: Color
}

const CircleIconSvg = styled.svg<CircleIconProps>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    fill: ${p => (p.isSelected ? Color.White : p.color)};
`

export const CircleIcon: FCWithChildren<CircleIconProps> = props => (
    <CircleIconSvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
        <defs>
            <mask id='not-selected-mask'>
                <circle r='8' cx='8' cy='8' fill='white' />
                <circle r='5' cx='8' cy='8' fill='black' />
            </mask>
            <mask id='selected-mask'>
                <circle r='8' cx='8' cy='8' fill='white' />
                <circle r='5' cx='8' cy='8' fill='black' />
                <circle r='3' cx='8' cy='8' fill='white' />
            </mask>
        </defs>
        <circle
            r='7'
            cx='8'
            cy='8'
            mask={props.isSelected ? 'url(#selected-mask)' : 'url(#not-selected-mask)'}
        />
    </CircleIconSvg>
)

export const CircleRadioRenderer: FCWithChildren<CircleRadioRendererProps> = ({
    isSelected,
    label,
    color,
    onSelect,
    disabled,
}) => (
    <CheckableBlock
        row
        isSelected={isSelected}
        color={color}
        cursor='pointer'
        disabled={disabled}
        onClick={() => !disabled && onSelect()}
        paddingHorizontal={8}
    >
        <Box shrink={0} marginRight={8}>
            <CircleIcon size={BlockSize.Tiny} color={color} isSelected={isSelected} />
        </Box>
        {label}
    </CheckableBlock>
)
