import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

import { BaseLayer, BaseLayerProps } from './BaseLayer'

export abstract class BaseMarkerLayer<T extends BaseLayerProps> extends BaseLayer<T> {
    constructor(props: T) {
        super(
            props,
            new VectorLayer({
                source: new VectorSource(),
                updateWhileAnimating: false,
                updateWhileInteracting: false,
            }),
        )
    }

    protected get markerSource(): VectorSource {
        return this.vectorLayer.getSource() as VectorSource
    }

    protected get vectorLayer(): VectorLayer<VectorSource> {
        return this.layer as VectorLayer<VectorSource>
    }

    public componentDidMount() {
        super.componentDidMount()
        this.updateMarkers(this.props)
    }

    public componentWillReceiveProps(nextProps: T) {
        super.componentWillReceiveProps(nextProps)
        this.updateMarkers(nextProps, this.props)
    }

    protected abstract updateMarkers(props: Readonly<T>, prevProps?: Readonly<T>): void

    protected removeFeatureById = (id: number) => {
        const featureById = this.markerSource.getFeatureById(id) as Feature<Geometry>
        if (featureById) {
            this.markerSource.removeFeature(featureById)
        }
    }
}
