import { DropdownRoot } from 'aos-ui/src/components/appcontainer/AppContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { isEqual } from 'lodash'
import React, { Component, CSSProperties, PropsWithChildren } from 'react'
import Scrollbars, { positionValues } from 'react-custom-scrollbars'

import { DropdownRootProvider } from '../form/dropdown/base/DropdownRoot'

export abstract class Scrollbar extends Component<PropsWithChildren<ScrollbarProps>> {
    private lastScrollPosition: number = 0
    private scrollbarRef: Scrollbars | null = null
    private dropdownRootRef = React.createRef<HTMLDivElement>()

    public render() {
        const container = this.scrollbarRef && (this.scrollbarRef as any).view
        return (
            <Scrollbars
                ref={ref => {
                    this.scrollbarRef = ref
                }}
                autoHide={false}
                autoHeight={this.props.autoHeight}
                autoHeightMin={this.props.autoHeightMin}
                autoHeightMax={this.props.autoHeightMax}
                renderThumbVertical={this.renderThumb}
                renderThumbHorizontal={this.renderThumb}
                renderTrackHorizontal={this.renderHorizontalTrack}
                renderTrackVertical={this.renderVerticalTrack}
                renderView={this.renderView}
                hideTracksWhenNotNeeded
                onUpdate={this.onUpdateScroll}
            >
                <DropdownRootProvider
                    value={{
                        container: { current: container },
                        dropdownRoot: this.dropdownRootRef,
                    }}
                >
                    {this.props.children}
                    <DropdownRoot ref={this.dropdownRootRef} />
                </DropdownRootProvider>
            </Scrollbars>
        )
    }

    public componentDidUpdate(prevProps: ScrollbarProps) {
        const { scrollOnChecksumUpdate, checksum } = this.props
        if (scrollOnChecksumUpdate && this.scrollbarRef && !isEqual(prevProps.checksum, checksum)) {
            this.scrollTo(scrollOnChecksumUpdate, this.scrollbarRef)
        }
    }

    public componentDidMount() {
        const { startPosition, savedPosition } = this.props

        if (this.scrollbarRef) {
            if (savedPosition) {
                this.scrollToTopPosition(savedPosition, this.scrollbarRef)
            } else if (startPosition) {
                this.scrollTo(startPosition, this.scrollbarRef)
            }
        }
    }

    protected abstract getThumbStyle(): Partial<CSSProperties>

    private scrollTo(scrollPosition: ScrollPosition, scrollbarRef: Scrollbars) {
        const { scrollToBottom, scrollToTop, scrollToLeft, scrollToRight } = scrollbarRef
        switch (scrollPosition) {
            case ScrollPosition.bottom:
                scrollToBottom()
                break
            case ScrollPosition.top:
                scrollToTop()
                break
            case ScrollPosition.left:
                scrollToLeft()
                break
            case ScrollPosition.right:
                scrollToRight()
                break
        }
    }

    private scrollToTopPosition(savedPosition: positionValues, scrollbarRef: Scrollbars) {
        const { scrollTop, getScrollTop } = scrollbarRef

        if (!isEqual(savedPosition.scrollTop, getScrollTop())) {
            scrollTop(savedPosition.scrollTop)
        }
    }

    private onUpdateScroll = (t: positionValues) => {
        if (this.props.onEnd) {
            if (this.lastScrollPosition !== 1 && t.top === 1) {
                this.props.onEnd()
            }
            this.lastScrollPosition = t.top
        }
        if (this.props.onScroll) {
            this.props.onScroll(t)
        }
    }

    private renderHorizontalTrack = ({ style, ...props }: any) => {
        const trackStyle = {
            zIndex: 11,
            right: 2,
            bottom: 2,
            left: 2,
        }
        return <Box {...props} style={{ ...style, ...trackStyle }} />
    }

    private renderVerticalTrack = ({ style, ...props }: any) => {
        const trackStyle = {
            zIndex: 11,
            right: 4,
            bottom: 2,
            top: 2,
        }
        return <Box {...props} style={{ ...style, ...trackStyle }} />
    }

    private renderView = ({ style, ...props }: any) => {
        const viewStyle = {
            display: 'flex',
            flexDirection: 'column',
            right: '-0.5px',
        }
        return <Box id={this.props.viewId} {...props} style={{ ...style, ...viewStyle }} />
    }

    private renderThumb = ({ style, ...props }: any) => {
        const thumbStyle = this.getThumbStyle()
        return <Box {...props} style={{ ...style, ...thumbStyle }} />
    }
}

export enum ScrollPosition {
    right,
    bottom,
    left,
    top,
}

interface ScrollbarProps {
    scrollOnChecksumUpdate?: ScrollPosition
    startPosition?: ScrollPosition
    savedPosition?: positionValues
    checksum?: any
    autoHeight?: boolean
    autoHeightMax?: number | string
    autoHeightMin?: number | string
    viewId?: string
    onEnd?(): void
    onContentChanged?(): void
    onScroll?(v: positionValues): void
}
