import { formatDate } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { PaxForecastSyncWrapper } from './PaxForecastSyncWrapper'

interface PaxForecastItemProps {
    stats: PaxForecastStats | null
    itemProps: StatusDashboardItemCommonProps
    link?: string
    showDate?: boolean
    syncId: SyncDataKey
}

export const PaxForecastItem: FC<PropsWithChildren<PaxForecastItemProps>> = props => {
    let title = translate('dashboard.pax-forecast.title')
    if (props.stats && props.showDate) {
        title += ' ' + formatDate(props.stats.day)
    }

    return (
        <PaxForecastSyncWrapper id={props.syncId}>
            <StatusDashboardItem
                title={title}
                description={translate('dashboard.pax-forecast.description')}
                noDataWarning={props.stats === null}
                link={props.link}
                {...props.itemProps}
            >
                {props.children}
            </StatusDashboardItem>
        </PaxForecastSyncWrapper>
    )
}
