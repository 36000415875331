import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { forwardRef, useState } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { DropdownVariant } from '../dropdown/base/DropdownVariant'
import { CheckboxDropdown, CheckboxDropdownProps } from '../dropdown/CheckboxDropdown'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledCheckboxDropdown<T>
    extends SeleniumProps,
        CheckboxDropdownProps<T>,
        MarginBoxProps {
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    inputVariant?: ThemeVariant
    selectAllOption?: T
    preventEmpty?: boolean
}

export const LabeledCheckboxDropdown = forwardRef(
    <T extends unknown>(props: LabeledCheckboxDropdown<T>) => {
        const {
            inputVariant = ThemeVariant.White,
            value,
            items,
            seleniumLocation,
            isError,
            isRequired,
            small,
            allContent,
            valueRenderer,
            partialContent,
            keyPrefix,
            onChange,
            width,
            selectAllOption,
            preventEmpty = true,
            ...rest
        } = props
        const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
        const [prev, setPrev] = useState(value)
        const dropdownVariant =
            inputVariant === ThemeVariant.White ? DropdownVariant.White : DropdownVariant.Black

        const onChangeValue = (values: T[]) => {
            let result: T[] = []
            if (selectAllOption) {
                if (values.includes(selectAllOption) && !prev.includes(selectAllOption)) {
                    result = items
                } else {
                    if (!values.includes(selectAllOption) && prev.includes(selectAllOption)) {
                        result = [items[1]]
                    } else {
                        result = values.filter(i => i !== selectAllOption) as T[]
                    }
                }
            } else {
                result = values
            }
            setPrev(result)
            onChange(result)
        }

        return (
            <LabeledFormElement
                {...rest}
                label={translateSuffix('label')}
                isRequired={isRequired}
                isError={isError}
                errorMessage={translateSuffix('error')}
                seleniumLocation={seleniumLocation}
                variant={ThemeVariant.White}
            >
                <CheckboxDropdown
                    value={value}
                    preventEmpty={preventEmpty}
                    items={items}
                    small={small}
                    variant={dropdownVariant}
                    allContent={allContent}
                    valueRenderer={valueRenderer}
                    partialContent={x => partialContent(x as T[])}
                    onChange={onChangeValue}
                    outlined
                    width={width}
                />
            </LabeledFormElement>
        )
    },
)
