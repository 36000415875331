import { IdAware } from 'aos-ui/src/components/base/IdAware'
import { ModalContainer } from 'aos-ui/src/components/modal/ModalContainer'
import { ModalSizeProps } from 'aos-ui/src/components/modal/ModalKind'
import { ModalWrapper, ModalWrapperProps } from 'aos-ui/src/components/modal/ModalWrapper'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'
import { IconModalHeader, IconModalHeaderProps } from './IconModalHeader'

interface IconModalProps
    extends ClassNameProps,
        IdAware,
        ModalSizeProps,
        IconModalHeaderProps,
        ModalWrapperProps {
    isOpen: boolean
}

export const IconModal: React.FC<React.PropsWithChildren<IconModalProps>> = ({
    disableFullScreen,
    isOpen,
    closeAction,
    shouldCloseOnOverlayClick,
    id,
    modalKind,
    svg,
    isNonDismissible,
    children,
    ...props
}) => {
    return (
        <ModalWrapper
            disableFullScreen={disableFullScreen}
            isOpen={isOpen}
            closeAction={closeAction}
            className='rc-modal--overflow-visible'
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <ModalContainer id={id} className={cxp(props)} size={modalKind}>
                <IconModalHeader
                    closeAction={closeAction}
                    svg={svg}
                    isNonDismissible={isNonDismissible}
                />
                {children}
            </ModalContainer>
        </ModalWrapper>
    )
}
