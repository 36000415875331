import { AxisScale } from 'd3-axis'
import { EnumValues } from 'enum-values'
import { chain } from 'lodash'
import moment from 'moment/moment'

import { dateTime } from '../../../../../aos-helpers/src/helpers/Time'
import { formatTime } from '../../../../../aos-helpers/src/helpers/TimeFormat'
import { PaxEntry } from '../../../services/pax/types/PaxEntry'
import { PointId } from '../../../services/pax/types/PointId'
import { waitingTimeConfig } from './waitingTimeConfig'

const getContinuousSeries = (
    data: PaxEntry[],
    predicate: (value: number) => boolean,
): PaxEntry[] => {
    const continuousSeries: PaxEntry[] = []
    data?.forEach((paxEntry, index) => {
        if (predicate(paxEntry.waitingTime)) {
            if (index > 0 && paxEntry.halfHourOffset === data[index - 1].halfHourOffset + 1) {
                continuousSeries.push(paxEntry)
            } else {
                continuousSeries.length = 0
                continuousSeries.push(paxEntry)
            }
        }
    })

    return continuousSeries
}

const withIndex = (fn: Function) => {
    let index = 0
    return (thing: unknown) => fn(thing, index++)
}

const groupedArray = (data: PaxEntry[]) => {
    let currentGroupIndex = 0
    return chain(data)
        .groupBy(
            withIndex((item: PaxEntry, index: number) => {
                if (index === 0) {
                    return currentGroupIndex
                }

                const prevItem = data[index - 1]
                const currentOffset = item.halfHourOffset
                const prevOffset = prevItem.halfHourOffset

                if (currentOffset - prevOffset === 1) {
                    return currentGroupIndex
                }

                currentGroupIndex++
                return currentGroupIndex
            }),
        )
        .values()
        .value()
}

export const findTimeRangeForLargeWaitingTime = (
    data: Record<PointId, PaxEntry[]>,
): WaitingTimeSeries[] => {
    const valuesOfEnum = EnumValues.getValues(PointId)
    return chain(valuesOfEnum)
        .map(enumValue => {
            const latestData = chain(data[enumValue as PointId])
                .filter(paxBlock => paxBlock.time >= dateTime())
                .orderBy(paxBlock => paxBlock.time)
                .value()

            const orangeContinuousSeries = getContinuousSeries(
                latestData,
                (value: number) =>
                    value >= waitingTimeConfig.MEDIUM.value &&
                    value <= waitingTimeConfig.LONG.value,
            )

            const redContinuousSeries = getContinuousSeries(
                latestData,
                (value: number) => value > waitingTimeConfig.LONG.value,
            )

            return {
                orangeContinuous: groupedArray(orangeContinuousSeries),
                redContinuous: groupedArray(redContinuousSeries),
            } as WaitingTimeSeries
        })
        .value()
}

export const renderTimeEntry = (entries: PaxEntry[]) => {
    if (entries.length > 0) {
        if (entries.length > 1) {
            return `${formatTime(entries[0].time)} - ${formatTime(
                entries[entries.length - 1].time.clone().add(30, 'minutes'),
            )}`
        } else {
            return `${formatTime(entries[0].time)} - ${formatTime(
                dateTime(entries[0].time).clone().add(30, 'minutes'),
            )}`
        }
    }

    return null
}

export interface WaitingTimeSeries {
    orangeContinuous: PaxEntry[][]
    redContinuous: PaxEntry[][]
}

export interface PaxSeriesScales {
    yScale: AxisScale<number>
    xScale: AxisScale<moment.Moment>
}
