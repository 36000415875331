import {
    FormValidation,
    isEmpty,
    isNotEmpty,
    isValidEmail,
    isValidPhone,
} from 'aos-helpers/src/helpers/FormValidation'
import { getCustomGroupList } from 'aos-services/src/services/users/aosUserFormatter'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { BaseUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import {
    AdUserProps,
    AosAdUser,
    AosContact,
    ContactProps,
} from 'aos-services/src/services/users/types/AosUser'
import {
    AosUserAddStatus,
    USER_FORM_READY,
    USER_PRE_CHECK,
} from 'aos-services/src/services/users/types/AosUserFindStatus'
import {
    AosUserNotificationsPreferences,
    defaultUserNotificationsPreferences,
} from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { AosUserType } from 'aos-services/src/services/users/types/AosUserType'

export interface CommonManageUserState<T extends BaseUserFormData> {
    isOpen: boolean
    conflictUserId?: number
    form: FormValidation<any>
    user: T
    notificationSettings?: AosUserNotificationsPreferences
}

export interface CommonAdUserFormValidationState {
    email: boolean
    alternativePhone: boolean
    selectedGroupsIds: boolean
}

export interface ManageAdUserState extends CommonManageUserState<AdUserFormData> {
    form: FormValidation<CommonAdUserFormValidationState>
}

export interface ManageContactState extends CommonManageUserState<ContactFormData> {
    form: FormValidation<CommonContactFormValidationState>
}

const emptyAdUserFormValidationState: CommonAdUserFormValidationState = {
    email: true,
    alternativePhone: true,
    selectedGroupsIds: true,
}

export const validateAdUser = (
    user: AdUserProps,
    status: AosUserAddStatus,
): CommonAdUserFormValidationState => {
    if (status === USER_PRE_CHECK) {
        const emailOk = isValidEmail(user.email)
        return {
            ...emptyAdUserFormValidationState,
            email: emailOk,
        }
    } else {
        const phoneOk = isEmpty(user.alternativePhone) || isValidPhone(user.alternativePhone)
        const groupsOk = true
        return {
            ...emptyAdUserFormValidationState,
            alternativePhone: phoneOk,
            selectedGroupsIds: groupsOk,
        }
    }
}

export interface CommonContactFormValidationState {
    email: boolean
    phone: boolean
    contactInformation: boolean
    name: boolean
    selectedGroupsIds: boolean
}

const newEmptyContactFormValidationState: CommonContactFormValidationState = {
    email: true,
    phone: true,
    name: true,
    contactInformation: true,
    selectedGroupsIds: true,
}

export const validateContact = (contact: ContactProps): CommonContactFormValidationState => {
    const emailOk = isValidEmail(contact.email)
    const phoneOk = isValidPhone(contact.phone)
    const groupsOk = true
    return {
        ...newEmptyContactFormValidationState,
        name: isNotEmpty(contact.name),
        email: isEmpty(contact.email) || emailOk,
        phone: isEmpty(contact.phone) || phoneOk,
        contactInformation: emailOk || phoneOk,
        selectedGroupsIds: groupsOk,
    }
}

export const emptyNewAdUser: AdUserFormData = {
    name: '',
    selectedGroupsIds: [],
    type: AosUserType.INTERNAL,
    userStatus: USER_PRE_CHECK,
    isFinaviaAuthority: false,
    isAdditionalFinaviaAuthority: false,
    taskPermissions: {},
    isEventCommander: false,
}

export const emptyAdUser: AdUserFormData = {
    ...emptyNewAdUser,
    userStatus: USER_FORM_READY,
}

export const emptyContact: ContactFormData = {
    name: '',
    selectedGroupsIds: [],
    type: AosUserType.MANUAL,
    userStatus: USER_FORM_READY,
    taskPermissions: {},
    isEventCommander: false,
}

export const initialAddAdUserState: ManageAdUserState = {
    isOpen: false,
    user: {
        ...emptyNewAdUser,
    },
    form: FormValidation.fromFields(validateAdUser(emptyNewAdUser, emptyNewAdUser.userStatus)),
    conflictUserId: undefined,
}

export const initEditAdUserState = (adUser: AosAdUser): ManageAdUserState => {
    const { groups } = adUser
    const user = {
        ...emptyAdUser,
        id: adUser.id,
        name: adUser.name,
        email: adUser.email,
        phone: adUser.phone,
        company: adUser.company,
        role: adUser.role,
        unit: adUser.unit,
        alternativePhone: adUser.alternativePhone,
        type: adUser.type,
        isFinaviaAuthority: adUser.isFinaviaAuthority,
        isAdditionalFinaviaAuthority: adUser.isAdditionalFinaviaAuthority,
        taskPermissions: adUser.taskPermissions,
        selectedGroupsIds: getCustomGroupList(groups).map(g => g.id),
        isEventCommander: adUser.isEventCommander,
    }

    return {
        isOpen: false,
        user,
        form: FormValidation.fromFields(validateAdUser(user, user.userStatus)),
        conflictUserId: undefined,
        notificationSettings: adUser.notificationPreferences,
    }
}

export const initialAddContactState: ManageContactState = {
    isOpen: false,
    user: { ...emptyContact },
    form: FormValidation.fromFields(validateContact(emptyContact)),
    conflictUserId: undefined,
    notificationSettings: defaultUserNotificationsPreferences,
}

export const initEditContactState = (contact: AosContact): ManageContactState => {
    const { groups } = contact
    const user = {
        ...emptyContact,
        id: contact.id,
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        company: contact.company,
        role: contact.role,
        taskPermissions: contact.taskPermissions,
        selectedGroupsIds: getCustomGroupList(groups).map(g => g.id),
    }

    return {
        isOpen: false,
        user,
        form: FormValidation.fromFields(validateContact(user)),
        conflictUserId: undefined,
        notificationSettings: contact.notificationPreferences,
    }
}
