import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Box } from '../base/Box'
import { IdAware } from '../base/IdAware'
import { Spinner } from '../ui/Spinner'
import { Modal } from './Modal/Modal'
import { ModalSizeProps } from './ModalKind'

interface MapAwareModalProps extends IdAware, ModalSizeProps {
    isOpen: boolean
    content?: ReactNode
    contentHeader?: ReactNode
    contentFooter?: ReactNode
    map?: ReactNode
    mapHeader?: ReactNode
    mapFooter?: ReactNode
    view: DialogView
    isLoading?: boolean
    closeAction(): void
}

export enum DialogView {
    Content,
    Map,
}

export const MapAwareModal: FCWithChildren<PropsWithChildren<MapAwareModalProps>> = ({
    modalKind,
    id,
    isOpen,
    isLoading,
    closeAction,
    view,
    map,
    mapFooter,
    mapHeader,
    content,
    contentFooter,
    contentHeader,
}) => {
    const [header, footer] =
        view === DialogView.Content ? [contentHeader, contentFooter] : [mapHeader, mapFooter]

    if (isLoading) {
        return (
            <Modal
                id={id}
                isOpen={isOpen}
                closeAction={closeAction}
                title={header}
                modalKind={modalKind}
            >
                <Spinner onLight />
            </Modal>
        )
    }
    return (
        <Modal
            id={id}
            isOpen={isOpen}
            closeAction={closeAction}
            title={header}
            footer={footer}
            modalKind={modalKind}
        >
            <Wrapper view={view}>
                <Content>{view === DialogView.Content && content}</Content>
                <Map>{view === DialogView.Map && map}</Map>
            </Wrapper>
        </Modal>
    )
}

const Wrapper = styled(Box).attrs(() => ({ flex: 1 }))<{ view: DialogView }>`
    position: relative;
    transform: translateX(${p => (p.view === DialogView.Map ? '-100%' : '0')});
    transition: transform 0.3s ease-in-out;
`

const Content = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

const Map = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
`
