import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren } from 'react'

import { checkMinMax } from '../../../../../aos-helpers/src/helpers/Number'
import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { CleanableNumberInput } from '../input/CleanableNumberInput'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledNumberInputProps extends MarginBoxProps, SeleniumProps {
    keyPrefix: string
    value?: number
    isRequired?: boolean
    isError?: boolean
    readOnly?: boolean
    variant?: ThemeVariant
    min?: number
    max?: number
    onChange?(v: number | undefined): void
}

export const LabeledNumberInput: FCWithChildren<LabeledNumberInputProps> = ({
    value,
    onChange,
    isRequired,
    isError,
    readOnly,
    seleniumLocation,
    variant,
    keyPrefix,
    min,
    max,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError || !checkMinMax(value, min, max)}
            errorMessage={translateSuffix(checkMinMax(value, min, max) ? 'error' : 'min-max-error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <CleanableNumberInput
                variant={variant}
                placeholder={translateSuffix('placeholder')}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
            />
        </LabeledFormElement>
    )
}
