import React, { RefObject } from 'react'

export interface DropdownRootProviderProps {
    dropdownRoot: RefObject<HTMLDivElement>
    container: RefObject<HTMLDivElement>
}

const defaultContext: DropdownRootProviderProps = {
    container: React.createRef<HTMLDivElement>(),
    dropdownRoot: React.createRef<HTMLDivElement>(),
}

export const DropdownContext = React.createContext<DropdownRootProviderProps>(defaultContext)

export const DropdownRootProvider = DropdownContext.Provider
export const DropdownRootConsumer = DropdownContext.Consumer
