import { SimpleFormModalActionCreators } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { SingleInputActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import {
    SimpleFormModal,
    SimpleFormModalProps,
} from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskOrDescription } from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { createTaskOrDescriptionActions } from '../../../../core/checklistManager/actions'
import {
    TaskOrDescriptionModalState,
    TaskOrDescriptionState,
} from '../../../../core/checklistManager/addTaskOrDescription/state'
import { State } from '../../../../core/state'

export class CreateTaskOrDescriptionModalClass extends PureComponent<
    TaskOrDescriptionModalState & SimpleFormModalActionCreators & SimpleFormModalProps
> {
    public render() {
        const changeText = (content: string) => this.changeForm({ content })
        const changeType = (type: TaskOrDescription) => this.changeForm({ type })
        const currentValue = this.props.currentValue.value!
        const form = this.props.form
        return (
            <SimpleFormModal {...this.props}>
                <Box marginBottom={16} column rowGap={8}>
                    <RadioGroup
                        name='taskOrDescription'
                        items={EnumValues.getValues<TaskOrDescription>(TaskOrDescription)}
                        formatter={translateEnum(TaskOrDescription, 'checklist-manager.item-type')}
                        value={currentValue.type}
                        fontSize={13}
                        variant={ThemeVariant.White}
                        onChange={changeType}
                    />
                    <LabeledTextArea
                        isError={form.error.content}
                        value={currentValue.content}
                        onChangeText={changeText}
                        isRequired
                        keyPrefix={this.props.keyPrefix}
                        seleniumLocation='first-input'
                    />
                </Box>
            </SimpleFormModal>
        )
    }

    protected changeForm = (p: Partial<TaskOrDescriptionState>) => {
        const value = { ...this.props.currentValue.value, ...p }
        this.props.changeFormValueAction({
            ...this.props.currentValue,
            value,
        })
    }
}

export const CreateTaskOrDescriptionModal = connect<
    TaskOrDescriptionModalState,
    SingleInputActionCreators,
    SimpleFormModalProps
>(
    (state: State) => state.checklistManager.createTaskOrDescriptionModal,
    createTaskOrDescriptionActions,
)(CreateTaskOrDescriptionModalClass)
