import { firebaseApp } from 'firebaseApp'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { WidgetItemStateDto } from './types/WidgetStatesDto'

export class WidgetStatesRepository {
    public widgetStatesRef = (location: AosAirport) =>
        firebaseApp.database().ref(`widgetStatesForAirports/${location}`)

    public widgetStatesItemRef = (location: AosAirport, t: string) =>
        firebaseApp.database().ref(`widgetStatesForAirports/${location}/${t}`)

    public updateWidgetState = (
        location: AosAirport,
        t: string,
        payload: Partial<WidgetItemStateDto>,
    ) => this.widgetStatesItemRef(location, t).update(payload)

    public getWidgetStatus = (
        location: AosAirport,
        t: string,
    ): Promise<WidgetItemStateDto | null> =>
        new Promise((resolve, reject) => {
            this.widgetStatesItemRef(location, t).once(
                'value',
                (s: firebase.database.DataSnapshot) => {
                    resolve(s.val() as WidgetItemStateDto)
                },
                (failure: any) => {
                    reject(failure)
                },
            )
        })
}

export const widgetStatesRepository = new WidgetStatesRepository()
