import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'

import { BoxProps } from '../../base/Box'
import { IdAware } from '../../base/IdAware'
import { ModalContainer } from '../ModalContainer'
import { ModalSizeProps } from '../ModalKind'
import { ModalWrapper, ModalWrapperProps } from '../ModalWrapper'
import { ModalFooter } from './ModalFooter'
import { ModalHeader, ModalHeaderProps } from './ModalHeader'

export interface ModalProps
    extends IdAware,
        ModalSizeProps,
        ModalHeaderProps,
        ModalWrapperProps,
        BoxProps {
    footer?: ReactNode
}

export const Modal: FCWithChildren<PropsWithChildren<ModalProps>> = props => {
    const {
        id,
        isOpen,
        modalKind,
        title,
        closeAction,
        children,
        footer,
        isNonDismissible,
        disableFullScreen,
        ...boxProps
    } = props
    return (
        <ModalWrapper
            disableFullScreen={disableFullScreen}
            isOpen={isOpen}
            closeAction={closeAction}
        >
            <ModalContainer id={id} size={modalKind} {...boxProps}>
                {!!title && (
                    <ModalHeader
                        isNonDismissible={isNonDismissible}
                        closeAction={closeAction}
                        title={title}
                    />
                )}
                {children}
                {footer && <ModalFooter topSeparator>{footer}</ModalFooter>}
            </ModalContainer>
        </ModalWrapper>
    )
}
