import { BaseRestService } from '../base/BaseRestService'
import { AosGlobalMessageDto, AosGlobalMessageInputDto } from './types/AosGlobalMessageDto'

class GlobalMessagingRestService extends BaseRestService {
    public getAllMessages(): Promise<AosGlobalMessageDto[]> {
        return this.getAndTransform<AosGlobalMessageDto[]>(
            '/messages',
            BaseRestService.jsonTransformer,
        )
    }

    public broadcastMessage(message: AosGlobalMessageInputDto): Promise<any> {
        return this.postJson('/messages', message)
    }
}

export const globalMessagingRestService = new GlobalMessagingRestService()
