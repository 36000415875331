import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { tasksService } from 'aos-services/src/services/tasks/tasksService'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
    LOAD_UPCOMING_TASKS,
    LoadUpcomingTasksAction,
    loadUpcomingTasksSuccessAction,
} from './actions'

function* loadUpcomingTasks() {
    yield takeEvery<LoadUpcomingTasksAction>(LOAD_UPCOMING_TASKS, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: TaskSnapshot[] = yield call(tasksService.getUpcomingTasks, siteLocation)
        yield put(loadUpcomingTasksSuccessAction(response))
    })
}

export const upcomingTasksSagas = [loadUpcomingTasks]
