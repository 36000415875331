import { chkString, Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import React, { PureComponent } from 'react'

interface CheckInCellProps {
    row: Flight
}

export class CheckInCell extends PureComponent<CheckInCellProps> {
    public render() {
        const flight = this.props.row
        return !!flight.chkarea && <span>{chkString(flight)}</span>
    }
}
