import { FilterCollection } from 'aos-helpers/src/helpers/Filters'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { RestrictionArea, restrictionAreas, translateAreaNumberLabel } from './RestrictionArea'

export enum FilterType {
    RestrictionArea = 'areas',
}

export const translateFilterType = translateEnum<FilterType>(
    FilterType,
    'restrictions.filters.section',
)

export interface RestrictionCheckboxFilters {
    areas: string[]
    [key: string]: string[]
}

export interface RestrictionFilters {
    checkboxFilters: RestrictionCheckboxFilters
    recentFilters: FilterCollection<RestrictionCheckboxFilters>
    filteredText: string
    [key: string]:
        | RestrictionCheckboxFilters
        | string
        | FilterCollection<RestrictionCheckboxFilters>
}

export const emptyRestrictionFilters: RestrictionFilters = {
    checkboxFilters: { areas: [] },
    recentFilters: [],
    filteredText: '',
}

export const toRestrictionAreaFilter = (area: RestrictionArea) =>
    `${area.areaType}-${area.areaNumber}`

export const translateRestrictionAreaFilter = (filter: string) => {
    const area = restrictionAreas.find(area => toRestrictionAreaFilter(area) === filter)

    return area
        ? translateAreaNumberLabel(area.areaType, area.areaNumber)
        : translate('restrictions.filters.unknown')
}
