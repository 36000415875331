import { dateTime, dateTimeToUTC, startOfDay } from 'aos-helpers/src/helpers/Time'
import { createSelector } from 'reselect'

import { CommonStateAware } from './state'

export const currentTimeSelector = (state: CommonStateAware) => state.common.currentTime
export const currentDaySelector = createSelector(
    (state: CommonStateAware) => startOfDay(currentTimeSelector(state)).valueOf(),
    dateTime,
)

export const currentTimeUTCSelector = createSelector(
    (state: CommonStateAware) => state.common.currentTime,
    dateTimeToUTC,
)

export const currentTimeRawSelector = (state: CommonStateAware) => state.common.currentTime
