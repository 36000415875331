import { Feature } from 'geojson'

export interface GatesProperties {
    Gate: string
}

export const featureToGate = (feature: Feature<any, GatesProperties>) =>
    feature.properties ? feature.properties.Gate : null

export const groupedGateToSingleGates = (groupedGate: string | null): string[] => {
    if (groupedGate) {
        const num = groupedGate.replace(/[A-Z,]+/g, '')
        const letters = groupedGate.replace(/[0-9,]+/g, '').split('')
        return letters.map(l => `${num}${l}`)
    }
    return []
}

export const isAggregateGate = (gate: string | null) => gate && gate.indexOf(',') > 0

export const gateToGatesArray = (gate: GatesProperties) =>
    isAggregateGate(gate.Gate) ? groupedGateToSingleGates(gate.Gate) : [gate.Gate]
