import { preferencesChangedStartAction } from 'aos-services/src/core/auth/actions'
import { currentUserIdSelector } from 'aos-services/src/core/auth/state'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { userProfileService } from 'aos-services/src/services/users/userProfileService'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
    getUserDetailsSuccessAction,
    SAVE_USER_PROFILE,
    SaveUserProfileAction,
    TOGGLE_USER_PROFILE_MODAL,
    ToggleUserProfileModalAction,
    toggleUserProfileModalAction,
} from './actions'

function* toggleUserProfileModalSaga() {
    yield takeEvery<ToggleUserProfileModalAction>(
        TOGGLE_USER_PROFILE_MODAL,
        function* (action: ToggleUserProfileModalAction) {
            if (action.payload) {
                const id: number = yield select(currentUserIdSelector)
                const userData: AosUser = yield call(userProfileService.getUserProfileById, id)
                yield put(getUserDetailsSuccessAction(userData))
            }
        },
    )
}

function* saveUserProfileSaga() {
    yield takeEvery<SaveUserProfileAction>(
        SAVE_USER_PROFILE,
        function* (action: SaveUserProfileAction) {
            yield put(toggleUserProfileModalAction(false))
            const id: number = yield select(currentUserIdSelector)
            yield call(userProfileService.updateUserProfile, id, {
                localisation: action.payload.localisation,
                siteLocation: action.payload.siteLocation,
            })
            if (action.payload.notificationPreferences) {
                yield call(
                    userProfileService.updateUserNotifications,
                    id,
                    action.payload.notificationPreferences,
                )
            }
            yield put(preferencesChangedStartAction())
        },
    )
}

export const userProfileModalSagas = [toggleUserProfileModalSaga, saveUserProfileSaga]
