import React from 'react'

import { Scrollbar } from './Scrollbar'

export class LightScrollbar extends Scrollbar {
    protected getThumbStyle(): Partial<React.CSSProperties> {
        return {
            backgroundColor: 'rgba(193, 193, 193, .8)',
            borderRadius: '5px',
        }
    }
}
