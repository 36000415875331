import { SingleInputActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import { SingleInputModalState } from 'aos-components/src/components/modal/InputModal/core/singleInput/state'
import { SimpleFormModalProps } from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { SingleInputModalClass } from 'aos-components/src/components/modal/InputModal/SingleInputModal'
import { connect } from 'react-redux'

import { createGroupActions } from '../../core/groupManager/actions'
import { State } from '../../core/state'

export const CreateUserGroupModal = connect<
    SingleInputModalState,
    SingleInputActionCreators,
    SimpleFormModalProps
>(
    (state: State) => state.groupManager.createGroupState,
    createGroupActions,
)(SingleInputModalClass)
