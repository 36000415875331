import { Box } from 'aos-ui/src/components/base/Box'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { TooltipOverlay, TooltipOverlayProps } from './TooltipOverlay'

interface TooltipColorsOverlayProps extends TooltipOverlayProps, ColorTableProps {}

export const TooltipColorsOverlay: FCWithChildren<
    PropsWithChildren<TooltipColorsOverlayProps>
> = props => {
    const { children, colors, ...rest } = props
    return (
        <TooltipOverlay padding={16} {...rest}>
            {children}
            <ColorTable colors={colors} />
        </TooltipOverlay>
    )
}

export type TooltipColorListItem = [string, string]
export type TooltipColorList = TooltipColorListItem[]

interface ColorTableProps {
    colors: TooltipColorList
}

const ColorTable: FCWithChildren<ColorTableProps> = props => {
    const { colors } = props

    return (
        <Table marginTop={16}>
            {colors.map(([color, name], i) => (
                <Box padding={4} marginRight={16} key={color + i}>
                    <Color as='span' marginRight={8} style={{ backgroundColor: color }} />
                    {name}
                </Box>
            ))}
        </Table>
    )
}

const Table = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
`

const Color = styled(Box)`
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-top: -4px;
    border-radius: 4px;
    background-color: currentColor;
`
