import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    allAirports,
    AosAirport,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { AosUserLocalisation } from 'aos-services/src/services/users/types/AosUserLocalisation'
import { EnumValues } from 'enum-values'
import { fromPairs } from 'lodash'

import { AbstractEntity } from '../../base/types/AbstractEntity'
import { AosBaseUserGroup } from './AosUserGroup'
import { AosUserNotificationsPreferences } from './AosUserNotificationsPreferences'
import { AosUserType } from './AosUserType'

export interface BaseUserProps {
    name: string
    email?: string
    phone?: string
    company?: string
    role?: string
    siteLocation?: AosAirport
    localisation?: AosUserLocalisation
    taskPermissions?: UserTaskPermissions
    isEventCommander: boolean
}

export interface AdUserProps extends BaseUserProps {
    unit?: string
    alternativePhone?: string
    isFinaviaAuthority: boolean
    isAdditionalFinaviaAuthority: boolean
}

export type ContactProps = BaseUserProps

interface AosBaseUser extends AbstractEntity, BaseUserProps {
    groups: AosBaseUserGroup[]
    type: AosUserType
    createdAt?: DateTime
    createdBy?: string
    modifiedAt?: DateTime
    modifiedBy?: string
    currentPhone?: string
    notificationPreferences?: AosUserNotificationsPreferences
}

export interface AosAdUser extends AosBaseUser, AdUserProps {
    thumbnail?: string
}

export interface AosContact extends AosBaseUser, ContactProps {
    createdBy: string
    modifiedBy: string
}

export type AosUser = AosAdUser | AosContact

export const isAosAdUser = (arg: any): arg is AosAdUser => {
    return arg.type !== AosUserType.MANUAL
}

export const isAosContact = (arg: any): arg is AosContact => {
    return arg.type === AosUserType.MANUAL
}

export type UserTaskPermissions = Partial<Record<AosAirport, TaskAccessLevel>>

export enum TaskAccessLevel {
    None = 'NONE',
    View = 'VIEW',
    Edit = 'EDIT',
}

export const allAccessLevels = EnumValues.getValues<TaskAccessLevel>(TaskAccessLevel)

export const getAllUserTaskPermissions = (accessLevel: TaskAccessLevel): UserTaskPermissions =>
    fromPairs(allAirports.map(airport => [airport, accessLevel]))
