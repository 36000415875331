import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Box } from '../base/Box'
import { FormComponentSize } from '../base/Theme'
import { Button } from './Button'
import { FormButton, FormButtonVariant } from './FormButton'

interface ToggleButtonProps<T extends Item<TK>, TK> {
    items: T[]
    value: TK | undefined
    onChange: (value: TK) => void
    defaultValue?: T
    ButtonComponent?: typeof Button
}

export interface Item<T> {
    value: T
    label: string
}

export function ToggleButton<T extends Item<TK>, TK>({
    items,
    defaultValue,
    value,
    onChange,
    ButtonComponent,
}: ToggleButtonProps<T, TK>) {
    const [innerValue, setValue] = useState<TK>(defaultValue?.value ?? items[0].value)

    useEffect(() => {
        onChange(innerValue)
    }, [innerValue])

    useEffect(() => {
        if (value && value !== innerValue) {
            setValue(value)
        }
    }, [value])

    const totalItems = items.length

    const getStyle = (index: number) => {
        if (index === 0) {
            return {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            }
        }
        if (index === totalItems - 1) {
            return {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeftWidth: 0,
            }
        }
        return {
            borderRadius: 0,
        }
    }

    const getButtonForIndex = (index: number) => {
        if (index === 0) {
            return LeftButton
        }
        if (index === totalItems - 1) {
            return RightButton
        }
        return MiddleButton
    }

    return (
        <Box row>
            {items.map((item, index) => {
                const Button = ButtonComponent || getButtonForIndex(index)
                return (
                    <Button
                        style={getStyle(index)}
                        key={item.label}
                        size={FormComponentSize.Small}
                        variant={
                            innerValue === item.value
                                ? FormButtonVariant.Red
                                : FormButtonVariant.Grey
                        }
                        label={item.label}
                        onClick={() => setValue(item.value)}
                    />
                )
            })}
        </Box>
    )
}

const LeftButton = styled(FormButton)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`

const RightButton = styled(FormButton)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
`

const MiddleButton = styled(FormButton)`
    border-radius: 0;
`
