import { cx } from 'aos-components/src/components/base/ClassNames'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { UpdatableValue } from 'aos-helpers/src/helpers/UpdatableValue'
import React, { FC } from 'react'

import { TimeCell } from './TimeCell'

export interface HighlightedTimeCellProps {
    value?: UpdatableValue<DateTime>
}

export const HighlightedTimeCell: FC<HighlightedTimeCellProps> = props => {
    if (!props.value) {
        return null
    }

    const { value, updated } = props.value

    return <TimeCell value={value} className={cx(updated && 'text__l-white')} />
}
