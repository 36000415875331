import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { FlightsSyncWrapper } from '../../base/FlightsSyncWrapper'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'

interface PunctualityItemProps {
    itemProps: StatusDashboardItemCommonProps
    link?: string
    noDataWarning: boolean
    syncId: SyncDataKey
}

export const PunctualityItem: FC<PropsWithChildren<PunctualityItemProps>> = props => (
    <FlightsSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.punctuality.title')}
            description={translate('dashboard.punctuality.description')}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </FlightsSyncWrapper>
)
