import { Chart } from 'aos-components/src/components/chart/Chart'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { isTimeInTimeRangeFilter } from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import {
    FlightPoint,
    FlightsSeries,
} from 'aos-services/src/services/airportStatus/flights/types/FlightsInfo'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import { format } from 'd3-format'
import React, { PureComponent } from 'react'

export interface DelaysChartProps {
    flights: FlightsSeries
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    timeRangeFilter: number
}

/* eslint-disable react/jsx-no-literals */
export class DelaysChart extends PureComponent<DelaysChartProps> {
    // tslint:disable:member-ordering
    private chartTooltip = (p: FlightPoint) =>
        p.flightsCount > 0 ? (
            <p>
                {translate('dashboard.delays.label.delayed-flights')} ({p.flightsCount}):{' '}
                {p.flightNumbers}
            </p>
        ) : (
            <p>{translate('dashboard.delays.label.no-delayed-flights')}</p>
        )
    private lineSeriesArrivalsConfig = {
        color: Color.PaleOrange,
        withDots: true,
        tooltip: this.chartTooltip,
    }
    private lineSeriesDeparturesConfig = {
        color: Color.Violet,
        withDots: true,
        tooltip: this.chartTooltip,
    }

    public xAccessor = (d: FlightPoint): DateTime => d.time
    public yAccessor = (d: FlightPoint): number => d.value

    public render() {
        const { xDomain, yDomain, timeRangeFilter } = this.props
        return (
            <Chart
                xConfig={{
                    domain: xDomain,
                    tickFormat: formatHour,
                    tickMarked: isTimeInTimeRangeFilter(timeRangeFilter),
                }}
                y1Config={{
                    tickFormat: format('d'),
                    domain: yDomain,
                    baseValues: [15],
                    unit: Unit.Minute,
                }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderContent = (props: ChartChildrenProps) => {
        const { flights } = this.props
        const { xScale, y1Scale } = props
        return (
            <>
                {xScale && y1Scale && (
                    <LineSeries
                        data={flights.arrivals}
                        seriesConfig={this.lineSeriesArrivalsConfig}
                        accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                        scales={{ xScale, yScale: y1Scale }}
                    />
                )}
                {xScale && y1Scale && (
                    <LineSeries
                        data={flights.departures}
                        seriesConfig={this.lineSeriesDeparturesConfig}
                        accessors={{ xAccessor: this.xAccessor, yAccessor: this.yAccessor }}
                        scales={{ xScale, yScale: y1Scale! }}
                    />
                )}
            </>
        )
    }
}
