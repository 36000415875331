import { format } from 'url'

import { Pageable } from '../../../../../aos-helpers/src/helpers/Pageable'
import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import {
    FirefighterShiftDto,
    FirefighterShiftReqBodyDto,
} from '../../../services/tasks/firefighters/types/FirefighterShiftDto'
import { FireTruck } from '../../../services/tasks/types/task/FireTruck'
import { BaseRestService } from '../../base/BaseRestService'

class FirefightersRestService extends BaseRestService {
    public getShifts = (
        siteLocation: AosAirport,
        pageNumber?: number,
        pageSize?: number,
    ): Promise<Pageable<FirefighterShiftDto>> => {
        return this.getAndTransform<Pageable<FirefighterShiftDto>>(
            format({
                pathname: '/firefightershifts',
                query: { siteLocation, pageNumber, pageSize },
            }),
            BaseRestService.jsonTransformer,
        )
    }
    public getShift = (
        id: Number,
        location: AosAirport,
    ): Promise<Pageable<FirefighterShiftDto>> => {
        return this.getAndTransform<Pageable<FirefighterShiftDto>>(
            format({
                pathname: `/firefightershifts/${id}`,
                query: { siteLocation: location },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public getFireTrucks = (location: AosAirport): Promise<FireTruck> => {
        return this.getAndTransform<FireTruck>(
            format({
                pathname: '/fire-truck',
                query: { siteLocation: location },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public add = (data: FirefighterShiftReqBodyDto) => {
        return this.postJson(
            format({
                pathname: '/firefightershifts',
            }),
            data,
        )
    }
    public update = (data: FirefighterShiftReqBodyDto) => {
        return this.putJson(
            format({
                pathname: `/firefightershifts/${data.id}`,
            }),
            data,
        )
    }
}

export const firefightersRestService = new FirefightersRestService()
