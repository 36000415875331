import { createSelector } from 'reselect'

import { filterEventUserVisibility } from '../../../services/events/aosEventFilters'
import { CommonAuthStateAware, currentUserSiteLocation } from '../../auth/state'
import { StatusDashboardDataStateAware } from '../state'

export const eventsDataSelector = createSelector(
    (state: StatusDashboardDataStateAware) => state.statusDashboardData.events,
    currentUserSiteLocation,
    (state: CommonAuthStateAware) => state.auth.currentUser.isApoc(),
    (state: CommonAuthStateAware) => state.auth.currentUser.userHasAnyUnitRole(),
    (allEvents, siteLocation, isApoc) => {
        const events = allEvents.filter(e => filterEventUserVisibility(e, siteLocation, isApoc))
        return {
            events,
            noDataWarning: !events.length,
        }
    },
)
