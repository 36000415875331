import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    getTimeOfDay,
    startOfNextTimeOfDay,
    translateTimeOfDay,
} from 'aos-helpers/src/helpers/TimeOfDay'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'

import { GroupingAxis } from './GroupingAxis'

class TimeOfDayAxisComponent extends GroupingAxis<DateTime> {
    protected getGroupEnd = startOfNextTimeOfDay

    protected defaultTickFormat = (date: DateTime) => translateTimeOfDay(getTimeOfDay(date))
}

export const TimeOfDayAxis = withChartContext(TimeOfDayAxisComponent)
