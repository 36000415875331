import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { NotamBaseActions } from 'aos-services/src/core/notam/actions'
import { TimelineAction } from 'aos-services/src/core/timeline/actions'
import { Notam, Snowtam } from 'aos-services/src/services/flightInformation/types/Notam'

export const NOTAM_TIMELINE_PARENT = 'NOTAM/NOTAM_TIMELINE_PARENT'
export type NotamTimelineParentAction = PayloadAction<typeof NOTAM_TIMELINE_PARENT, TimelineAction>
export const notamTimelineParentAction = payloadActionCreator<
    NotamTimelineParentAction,
    TimelineAction
>(NOTAM_TIMELINE_PARENT)

export const SELECT_NOTAM_ITEM = 'NOTAM/SELECT_ITEM'
export type SelectNotamItemAction = PayloadAction<typeof SELECT_NOTAM_ITEM, Notam | Snowtam>
export const selectNotamItemAction = payloadActionCreator<SelectNotamItemAction, Notam | Snowtam>(
    SELECT_NOTAM_ITEM,
)

export const NOTAM_TOGGLE_GROUP = 'NOTAM/TOGGLE_GROUP'
export type NotamToggleGroupAction = PayloadAction<typeof NOTAM_TOGGLE_GROUP, string>
export const notamToggleGroupAction = payloadActionCreator<NotamToggleGroupAction, string>(
    NOTAM_TOGGLE_GROUP,
)

export type NotamActions =
    | NotamBaseActions
    | NotamTimelineParentAction
    | SelectNotamItemAction
    | NotamToggleGroupAction
