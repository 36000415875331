import { max } from 'lodash'

import { AxisYData } from './AxisYData'
import { AxisBandConfig } from './ChartProps'
import { BottomLegendConfig, SiteLegendConfig } from './LegendConfig'
import { Margins, maxMargin } from './types/Margins'

export interface AxisConstants {
    characterWidth: number
    tickSize: number
    tickPadding: number
}

export interface ChartMarginsProps {
    topAxisSize?: number
    bottomAxisSize?: number
    margins?: Margins
    leftLegend?: SiteLegendConfig
    rightLegend?: SiteLegendConfig
    bottomLegend?: BottomLegendConfig
    xBandConfig?: AxisBandConfig<any>
    y1Config?: AxisYData
    y2Config?: AxisYData
}

export class ChartMargins {
    public static chartMargin(
        props: ChartMarginsProps,
        minMargins: Margins,
        axisConstants: AxisConstants,
    ) {
        const { y1Config, leftLegend, rightLegend, bottomLegend, y2Config, xBandConfig } = props
        const leftLegendOffset = leftLegend?.width ?? 0
        const rightLegendOffset = rightLegend?.width ?? 0
        const bottomLegendOffset = bottomLegend?.height ?? 0
        const isBand = !!xBandConfig
        const y1TickWidth = y1Config
            ? ChartMargins.widthForTicks(y1Config, axisConstants, isBand)
            : 0
        const y2TickWidth = y2Config
            ? ChartMargins.widthForTicks(y2Config, axisConstants, isBand)
            : 0
        const y1BaseWidth = y1Config ? ChartMargins.widthForAxisData(y1Config, axisConstants) : 0
        const y2BaseWidth = y2Config ? ChartMargins.widthForAxisData(y2Config, axisConstants) : 0
        const topMargin = props.topAxisSize ?? 0
        const bottomMargin = props.bottomAxisSize ?? 40
        const margins = {
            top: topMargin,
            bottom: bottomLegendOffset + bottomMargin,
            left: Math.max(leftLegendOffset + y1TickWidth, y1BaseWidth, 0),
            right: Math.max(rightLegendOffset + y2TickWidth, y2BaseWidth, 0),
        }
        return maxMargin(margins, minMargins)
    }

    private static ticksLength = (data: AxisYData) => {
        const ticks = data.domain.ticks
        // some heuristic
        return (max(ticks) || 0).toString().length
    }

    private static widthForTicks = (
        data: AxisYData,
        axisConstants: AxisConstants,
        isBand: boolean,
    ) => {
        const defaultOffset = isBand ? 40 : axisConstants.tickPadding + axisConstants.tickSize
        const biggestNumber = ChartMargins.ticksLength(data)
        return biggestNumber * axisConstants.characterWidth + defaultOffset
    }

    private static widthForAxisData = (data: AxisYData, axisConstants: AxisConstants) => {
        const ticks = data.domain.ticks
        const biggestNumber = ChartMargins.ticksLength(data)
        const baseValueSize = ticks.length ? ticks[0].toString().length : 0
        // unit and spacing
        const unitOffset = data.unit ? data.unit.length + 3 : 0
        const maxLength = Math.max(biggestNumber, baseValueSize + unitOffset)
        const defaultOffset = axisConstants.tickPadding + axisConstants.tickSize
        return maxLength * axisConstants.characterWidth + defaultOffset
    }
}
