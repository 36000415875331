import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { QueueingTimeType } from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { QueueingTimeSyncWrapper } from './QueueingTimeSyncWrapper'

interface QueueingTimeProps {
    itemProps: StatusDashboardItemCommonProps
    noDataWarning: boolean
    link?: string
    type: QueueingTimeType
    syncId: SyncDataKey
}

export const QueueingTimeItem: FC<PropsWithChildren<QueueingTimeProps>> = props => (
    <QueueingTimeSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate(`dashboard.${enumToName(QueueingTimeType)(props.type)}.title`)}
            description={translate(
                `dashboard.${enumToName(QueueingTimeType)(props.type)}.description`,
            )}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </QueueingTimeSyncWrapper>
)
