import zod from 'zod'

import { DateTime } from '../../../../../aos-helpers/src/helpers/Time'
import { translate } from '../../../../../aos-helpers/src/helpers/translations/Translations'
import { AosSimpleChecklistTemplateDto } from '../../../dataaccess/checklists/types/AosChecklistTemplateDto'
import { AosAirport } from '../../flightInformation/types/AosAirport'
import { TaskCategory } from '../../tasks/types/TaskCategory'
import { AosSimpleTemplate } from './AosChecklistTemplate'
import { AosChecklistTemplateStatus } from './AosChecklistTemplateStatus'
import { AosUnitTaskItem } from './AosUnitTaskTemplate'

export interface AosTaskChecklistTemplate extends AosSimpleTemplate {
    taskCategory: TaskCategory
    items: AosUnitTaskItem[]
    lastUsed?: DateTime
    usedCount: number
    status: AosChecklistTemplateStatus
    icon?: Svg
    tag: TaskTag
}

export interface AosTaskChecklistTemplateDTO extends AosSimpleChecklistTemplateDto {
    taskCategory: TaskCategory
    items: AosUnitTaskItem[]
    lastUsed: string
    usedCount: number
    tag: TaskTag
}

export const CommonTag = 'COMMON'

export type TaskTag = AosAirport | typeof CommonTag

export const aosTaskChecklistSchema = zod.object({
    id: zod.number().optional(),
    name: zod
        .string()
        .trim()
        .min(1, translate('create-checklist-group.error-too-short'))
        .max(64, translate('create-checklist-group.error-too-long')),
    taskCategory: zod.nativeEnum(TaskCategory),
    sequenceNumber: zod.number().optional(),
    tag: zod.union([zod.nativeEnum(AosAirport), zod.literal(CommonTag)]),
})
