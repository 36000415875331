import { DateTime, dateTime } from "aos-helpers/src/helpers/Time";
import { currentTimeSelector } from "aos-services/src/core/common/selectors";
import { getTimelineLoadRange, TimelineState } from "aos-services/src/core/timeline/state";
import { AosSeverity } from "aos-services/src/services/common/types/AosSeverity";
import { TaskCommonProps } from "aos-services/src/services/tasks/types/task/BaseTask";
import { Task } from "aos-services/src/services/tasks/types/task/Task";
import { TaskCategory } from "aos-services/src/services/tasks/types/TaskCategory";
import { TaskPageable, TaskPageableFilters } from "aos-services/src/services/tasks/types/TaskPageable";
import { TaskProcessType, translateTaskProcessType } from "aos-services/src/services/tasks/types/TaskProcessType";
import { TaskSnapshot, toFlatTaskId } from "aos-services/src/services/tasks/types/TaskSnapshot";
import {
    emergencyStatusBorderColorsMapping,
    emergencyStatusColorsMapping,
    statusBorderColorMappingInfo,
    statusBorderColorMappingNormal,
    statusBorderColorsMapping,
    statusColorsMapping,
    statusFillColorMappingInfo,
    statusFillColorMappingNormal,
    TaskStatus
} from "aos-services/src/services/tasks/types/TaskStatus";
import { BaseCalendarTimelineGroup } from "aos-ui/src/components/timeline/types/BaseCalendarTimelineGroup";
import { BaseCalendarTimelineItem } from "aos-ui/src/components/timeline/types/BaseCalendarTimelineItem";
import { BorderStyle } from "aos-ui-common/src/styles/Borders";
import { Color } from "aos-ui-common/src/styles/Color";
import { EnumValues } from "enum-values";
import moment from "moment";
import { positionValues } from "react-custom-scrollbars";
import { createSelector } from "reselect";

import { currentAbsoluteUrlSelector } from "../router/state";
import { TaskManagerStateAware, TimelineFilterState } from "./state";

export interface TaskTimelineItem extends BaseCalendarTimelineItem, TaskCommonProps {}

export interface TaskTimelineSelectorState {
    tasks: TaskTimelineItem[]
    groups: GroupTaskTimelineItem[]
    currentTime: DateTime
    timelineState: TimelineState
    savedPosition?: positionValues
    filters: TimelineFilterState
}

export interface GroupTaskTimelineItem extends BaseCalendarTimelineGroup {
    processType: TaskProcessType
}

export const filterTask = (task: TaskSnapshot, category: TaskCategory[]) => {
    if (category.length === 0) {
        return true
    } else {
        return category.includes(task.category)
    }
}

const endTimeWithDefault = (startTime: DateTime, endTime?: DateTime) =>
    endTime || moment.max(startTime.clone().add(1, 'hour'), dateTime().add(1, 'hour'))

const getBorderColor = (task: TaskSnapshot): Color => {
    switch (task.severity) {
        case AosSeverity.Emergency:
            return task.overdue ? Color.TaskYellow : emergencyStatusBorderColorsMapping[task.status]
        case AosSeverity.Maintenance:
            return task.overdue ? Color.TaskYellow : statusBorderColorMappingInfo[task.status]
        case AosSeverity.Info:
            return task.overdue ? Color.TaskYellow : statusBorderColorMappingNormal[task.status]
        default:
            return statusBorderColorsMapping[task.status]
    }
}

const getFillColor = (task: TaskSnapshot): Color => {
    switch (task.severity) {
        case AosSeverity.Emergency:
            return emergencyStatusColorsMapping[task.status]
        case AosSeverity.Maintenance:
            return statusFillColorMappingInfo[task.status]
        case AosSeverity.Info:
            return statusFillColorMappingNormal[task.status]
        default:
            return statusColorsMapping[task.status]
    }
}

const groupsAndTasksSelector = createSelector(
    (state: TaskManagerStateAware) => state.taskManager.timelineTasks,
    (state: TaskManagerStateAware) => state.taskManager.collapsedGroups,
    (state: TaskManagerStateAware) => state.taskManager.timelineFilters,
    (timelineTasks, collapsedGroups, filter) => {
        const groups: GroupTaskTimelineItem[] = EnumValues.getValues<TaskProcessType>(
            TaskProcessType,
        ).map(processType => {
            const isCollapsed = collapsedGroups.includes(processType)
            return {
                id: processType,
                processType,
                timelineLabel: translateTaskProcessType(processType),
                isCollapsed,
                lineHeight: isCollapsed ? 10 : 50,
                minHeight: isCollapsed ? 50 : 245,
            }
        })

        const tasks: TaskTimelineItem[] = timelineTasks
            .filter(t => filterTask(t, filter.categories))
            .map(t => ({
                id: toFlatTaskId(t),
                outlined: false,
                startTime: t.startTime,
                endTime:
                    t.overdue && t.status !== TaskStatus.Done
                        ? dateTime()
                        : endTimeWithDefault(t.startTime, t.endTime),
                isCollapsed: collapsedGroups.includes(t.processType),
                title: t.title,
                severity: t.severity,
                color: getFillColor(t),
                borderColor: getBorderColor(t),
                borderStyle: t.status === TaskStatus.Done ? BorderStyle.dashed : BorderStyle.solid,
                category: t.category,
                group: t.processType,
                assignees: t.assignees,
                location: t.location,
                status: t.status,
                overdue: t.overdue,
                processType: t.processType,
                lastComment: t.lastComment,
                realEndTime: t.endTime,
            }))

        return {
            tasks,
            groups,
        }
    },
)
export const taskTimelineSelector = createSelector(
    (state: TaskManagerStateAware) => state.taskManager.timeline,
    (state: TaskManagerStateAware) => state.taskManager.timelineFilters,
    currentTimeSelector,
    groupsAndTasksSelector,
    (timelineState, filters, currentTime, tasksAndGroups): TaskTimelineSelectorState => ({
        timelineState,
        currentTime,
        filters,
        tasks: tasksAndGroups.tasks,
        groups: tasksAndGroups.groups,
    }),
)

export interface TaskListSelectorState {
    tasks: TaskPageable
    tasksFilters: TaskPageableFilters
    selectedTask?: Task
    isLoading: boolean
    isSendingMessage: boolean
    url: string
}

export const taskListSelector = createSelector(
    (state: TaskManagerStateAware) => state.taskManager.tasks,
    (state: TaskManagerStateAware) => state.taskManager.selectedTask,
    (state: TaskManagerStateAware) => state.taskManager.isLoading,
    (state: TaskManagerStateAware) => state.taskManager.isSendingMessage,
    (state: TaskManagerStateAware) => state.taskManager.tasksFilters,
    currentAbsoluteUrlSelector,
    (
        tasks,
        selectedTask,
        isLoading,
        isSendingMessage,
        tasksFilters,
        url,
    ): TaskListSelectorState => ({
        tasks,
        selectedTask,
        isLoading,
        isSendingMessage,
        tasksFilters,
        url,
    }),
)

export const currentRangeSelector = (state: TaskManagerStateAware) =>
    getTimelineLoadRange(state.taskManager.timeline, state.taskManager.loadedRange)

export const currentTaskFilters = (state: TaskManagerStateAware) => state.taskManager.tasksFilters

export const tasksFiretrucksMetadataSelector = (state: TaskManagerStateAware) =>
    state.taskManager.metadata?.fireTrucks
