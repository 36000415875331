import { parse } from 'query-string'
import { RouterState } from 'redux-first-history'
import { createSelector } from 'reselect'

export interface CustomRouterStateAware {
    router: RouterState
}

export const currentPathSelector = (state: CustomRouterStateAware): string =>
    state.router.location?.pathname as string

export const isRootSelector = (state: CustomRouterStateAware) => currentPathSelector(state) === '/'

export const querySelector = (state: CustomRouterStateAware) =>
    parse(state.router.location?.search as string)

const routerLocationSelector = (state: CustomRouterStateAware) => state.router.location

export const currentUrlSelector = createSelector(
    routerLocationSelector,
    location => `${location?.pathname}${location?.search}`,
)

export const currentAbsoluteUrlSelector = createSelector(currentUrlSelector, currentUrl => {
    if (!document.location) {
        return ''
    }
    return `${document.location.origin}${currentUrl}`
})
