import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    InspectionItemPayload,
    InspectionTaskFormPayload,
    InspectionTaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/InspectionTaskFormPayload'
import { InspectionGroup } from 'aos-services/src/services/tasks/types/task/InspectionGroup'
import {
    diffSelection,
    getLateDuration,
    InspectionTypeGroup,
    inspectionTypeToGroups,
} from 'aos-services/src/services/tasks/types/task/InspectionSelection'
import { translateInspectionType } from 'aos-services/src/services/tasks/types/task/InspectionType'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { FormLabel } from 'aos-ui/src/components/form/labeled/FormLabel'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { EditableList, ItemRendererProps } from 'aos-ui/src/components/form/list/EditableList'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { EnumValues } from 'enum-values'
import React, { FC, useState } from 'react'

import { InspectionTypeIconRenderer } from './partials/InspectionTypeIconRenderer'
import { LateInspectionLabel } from './partials/LateInspectionLabel'

interface InspectionTaskFormProps {
    form: FormValidation<InspectionTaskPayloadValidation>
    payload: InspectionTaskFormPayload
    metadata: TaskMetadata

    changeForm(t: Partial<InspectionTaskFormPayload>): void
}

export const InspectionTaskForm: FC<InspectionTaskFormProps> = ({
    form,
    payload,
    metadata,
    changeForm,
}) => {
    const now = dateTime()
    const [inspectionGroup, setInspectionGroup] = useState<InspectionGroup>()
    const [inspectionTypeGroup, setInspectionTypeGroup] = useState<InspectionTypeGroup>(
        inspectionTypeToGroups(metadata.inspectionTypes, now, payload.items),
    )
    const groupConfigs = inspectionTypeToGroups(metadata.inspectionTypes, now, payload.items)
    const groups = Object.keys(groupConfigs) as InspectionGroup[]
    const onChangeSelection = (v: InspectionItemPayload[]) => {
        if (inspectionGroup) {
            changeForm({
                items: diffSelection(v, payload.items, inspectionGroup),
                title: v[0].inspectionType.name,
            })
            setInspectionTypeGroup({
                ...inspectionTypeGroup,
                [inspectionGroup]: {
                    ...inspectionTypeGroup[inspectionGroup],
                    selectedItems: v,
                },
            })
        }
    }

    const isLateInspection = (i: InspectionItemPayload, now: DateTime) => {
        const lateLabel = getLateDuration(i, now)
        return (
            <Box row>
                <Box marginRight={12}>{i.inspectionType.name}</Box>
                {lateLabel && <LateInspectionLabel duration={lateLabel} />}
            </Box>
        )
    }
    const onDelete = (items: InspectionItemPayload[]) => {
        changeForm({ items })
        return EnumValues.getValues<InspectionGroup>(InspectionGroup).map(inspectionName => {
            const updatedItems = inspectionTypeGroup[inspectionName].selectedItems.filter(i =>
                items.map(i => i.inspectionType.id).includes(i.inspectionType.id),
            )
            setInspectionTypeGroup({
                ...inspectionTypeGroup,
                [inspectionName]: {
                    ...inspectionTypeGroup[inspectionName],
                    selectedItems: updatedItems,
                },
            })
        })
    }

    return (
        <Box paddingBottom={12}>
            <LabeledFormElement
                isRequired
                isError={form.error.items}
                label={translate('tasks.form.inspection.items.label')}
            >
                <BlockRadioGroup
                    items={groups}
                    value={inspectionGroup}
                    marginBottom={8}
                    gridSize={4}
                    Renderer={({ isSelected, item, onSelect }) => (
                        <InspectionTypeIconRenderer
                            isSelected={isSelected}
                            onSelect={() => {
                                onSelect()
                            }}
                            group={item}
                            isLate={inspectionTypeGroup[item].inspectionLate}
                            selectedInspectionsCounter={
                                inspectionTypeGroup[item].selectedItems.length
                            }
                            inspectionTotalNumber={inspectionTypeGroup[item].items.length}
                        />
                    )}
                    onChange={i => setInspectionGroup(i)}
                />
            </LabeledFormElement>
            {inspectionGroup && inspectionTypeGroup[inspectionGroup].items.length > 0 && (
                <CheckboxDropdown
                    items={inspectionTypeGroup[inspectionGroup].items}
                    value={inspectionTypeGroup[inspectionGroup].selectedItems}
                    allContent={'All ' + inspectionGroup}
                    partialContent={i => `Inspections: ${i.length}`}
                    onChange={onChangeSelection}
                    valueRenderer={(i: InspectionItemPayload) => isLateInspection(i, now)}
                />
            )}
            {!!payload.items.length && (
                <Box paddingTop={16}>
                    <EditableList
                        value={payload.items}
                        itemRenderer={SelectedItemRenderer}
                        onChange={items => onDelete(items)}
                    />
                </Box>
            )}
        </Box>
    )
}

export const SelectedItemRenderer: FC<ItemRendererProps<InspectionItemPayload>> = ({
    item,
    onChangeItem,
}) => (
    <Box row paddingBottom={12} paddingRight={20}>
        <Box flex={1}>
            <FormLabel size={13}>{translateInspectionType(item.inspectionType)}</FormLabel>
        </Box>
        <Box flex={1}>
            <CleanableInput
                placeholder={translate('tasks.form.inspection.comment.placeholder')}
                value={item.comment}
                onChangeText={comment => onChangeItem({ ...item, comment })}
            />
        </Box>
    </Box>
)
