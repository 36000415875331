import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { find, isEqual, xorWith } from 'lodash'
import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { IdAware } from '../../base/IdAware'
import { Chip } from '../../chip/Chip'
import { BaseDropdown } from './base/BaseDropdown'
import { dropdownHeight, DropdownInput, DropdownPlaceholder } from './base/DropdownContent'
import { DropdownItem } from './base/DropdownItem'
import { DropdownVariant } from './base/DropdownVariant'
import { DropdownWidth } from './base/DropdownWidth'
import { DropdownItems, useDropdownSearch } from './hooks/useDropdownSearch'

export interface DropdownMultiAutocompleteProps<T> extends IdAware {
    items: DropdownItems<T>
    value: T[]

    variant?: DropdownVariant
    placeholder?: string
    width?: DropdownWidth
    small?: boolean
    outlined?: boolean
    maxHeight?: number

    valueRenderer?(item: T): ReactNode
    labelRenderer(item: T): string
    onChange(value: T[]): void
}

export const DropdownMultiAutocomplete = <T extends object | string | number>({
    value,
    id,
    items,
    labelRenderer,
    valueRenderer = labelRenderer,
    placeholder,
    width,
    onChange,
    small,
    outlined,
    maxHeight,
    variant = DropdownVariant.White,
}: DropdownMultiAutocompleteProps<T>) => {
    const [inputValue, setInputValue] = useState('')
    const [isOpen, setOpen] = useState(false)

    const { dropdownItems, isLoading } = useDropdownSearch(inputValue, items, labelRenderer)

    const height = dropdownHeight(variant, small)
    const padding = variant === DropdownVariant.BlackGrey ? 12 : 8

    const filteredDropdownItems = dropdownItems.filter(
        item => !find(value, val => isEqual(val, item)),
    )

    const toggleItem = (newValue: T) => {
        onChange(xorWith(value, [newValue], isEqual))
    }

    const selectItem = (newView: T) => {
        toggleItem(newView)
        setInputValue('')
        setOpen(false)
    }

    return (
        <BaseDropdown
            isOpen={isOpen}
            setOpen={setOpen}
            items={filteredDropdownItems}
            variant={variant}
            id={id}
            width={width}
            height={height}
            content={
                <DropdownMultiContent
                    valueRenderer={valueRenderer}
                    onRemove={toggleItem}
                    variant={variant}
                    value={value}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setOpen={setOpen}
                    placeholder={placeholder}
                />
            }
            emptyContent={
                <DropdownPlaceholder
                    size={12}
                    as='span'
                    variant={variant}
                    paddingHorizontal={padding}
                >
                    {isLoading ? translate('dropdown.loading') : translate('dropdown.empty')}
                </DropdownPlaceholder>
            }
            onItemClick={val => selectItem(val)}
            paddingHorizontal={padding}
            outlined={outlined}
            maxHeight={maxHeight}
            ItemRenderer={p => (
                <DropdownItem
                    onClick={p.onClick}
                    variant={p.variant}
                    isSelected={value === p.v}
                    height={height}
                    padding={padding}
                >
                    {valueRenderer(p.v)}
                </DropdownItem>
            )}
        />
    )
}

interface DropdownMultiContentProps<T> {
    variant: DropdownVariant
    value: T[]
    inputValue: string
    placeholder?: string
    valueRenderer?(item: T): ReactNode
    onRemove(item: T): void
    setOpen(isOpen: boolean): void
    setInputValue(value: string): void
}

export const DropdownMultiContent = <T extends object | string | number>({
    variant,
    value,
    valueRenderer,
    onRemove,
    setInputValue,
    inputValue,
    placeholder,
    setOpen,
}: DropdownMultiContentProps<T>) => {
    const input = useRef<HTMLInputElement>(null)

    const handleKeyDown = (code: string) => {
        if (code === 'Backspace' && value.length && inputValue.length === 0) {
            onRemove(value[value.length - 1])
        }
    }

    const handleClick = () => {
        input.current?.focus()
    }

    const setValue = (val: string) => {
        setOpen(!!val)
        setInputValue(val)
    }

    const inputWidth =
        !inputValue && !value.length ? '100%' : `${Math.max(inputValue.length * 10, 10)}px`

    return (
        <Box flex={1} row wrap paddingBottom={8} onClick={handleClick}>
            {value.map((val, index) => (
                <Chip key={index} onRemove={() => onRemove(val)} marginRight={8} marginTop={8}>
                    {valueRenderer ? (valueRenderer(val) as ReactNode) : (val as ReactNode)}
                </Chip>
            ))}
            <Input
                ref={input}
                onKeyDown={event => handleKeyDown(event.key)}
                variant={variant}
                type='text'
                value={inputValue}
                placeholder={!value.length ? placeholder : ''}
                onChange={event => setValue(event.target.value)}
                style={{ width: inputWidth }}
            />
        </Box>
    )
}

const Input = styled(DropdownInput)`
    height: 24px;
    line-height: 24px;
    margin-top: 8px;
    width: auto;
`
