import { checklistTemplatesService } from 'aos-services/src/services/checklists/checklistTemplateService'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { loadEventInEventEditorAction } from '../eventEditor/actions'
import { EventEditorStateAware } from '../eventEditor/state'
import {
    closeChecklistModalAction,
    getAvailableChecklistsSuccess,
    OPEN_CHECKLIST_MODAL,
    SUBMIT_CHECKLISTS,
    SubmitChecklistsAction,
} from './actions'

function* prepareAvailableChecklistsSaga() {
    yield takeLatest(OPEN_CHECKLIST_MODAL, function* () {
        const allChecklistsGroupRefs: AosSimpleTemplate[] = yield call(
            checklistTemplatesService.getForEventManager,
        )
        const selectedChecklistsId: number[] = yield select(
            (state: EventEditorStateAware) =>
                state.eventEditor.editableChecklists &&
                state.eventEditor.editableChecklists.map(checklist => checklist.sourceId),
        )
        yield put(getAvailableChecklistsSuccess([allChecklistsGroupRefs, selectedChecklistsId]))
    })
}

function* submitChecklistsSaga() {
    function* submitChecklists(action: SubmitChecklistsAction) {
        yield put(closeChecklistModalAction())
        yield call(
            eventsService.updateChecklists,
            action.payload.selectedChecklistsId,
            action.payload.eventId,
        )
        yield put(loadEventInEventEditorAction(action.payload.eventId))
    }

    yield takeLatest(SUBMIT_CHECKLISTS, submitChecklists)
}

export const checklistSelectionModalSagas = [prepareAvailableChecklistsSaga, submitChecklistsSaga]
