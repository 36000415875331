import { SimpleTask } from '../task/SimpleTask'
import { TaskStatus } from '../TaskStatus'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface SimpleTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.Simple
}

export const initialSimpleTaskPayload: SimpleTaskFormPayload = {
    type: TaskType.Simple,
    status: TaskStatus.Todo,
    ...initialBaseTaskPayload,
}

export interface SimpleTaskPayloadValidation extends BaseTaskPayloadValidation {}

export const validateSimpleTask = (t: SimpleTaskFormPayload): SimpleTaskPayloadValidation =>
    baseValidateTask(t)

export const simpleTaskFormPayloadFromTask = (t: SimpleTask): SimpleTaskFormPayload => ({
    type: TaskType.Simple,
    ...baseTaskFormPayloadFromTask(t),
})
