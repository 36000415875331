import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { newsFeedService } from '../../../services/airportStatus/newsFeed/newsFeedService'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { Feed } from '../../../services/newsFeed/types/Feed'
import {
    NEWS_FEED_LOAD,
    NEWS_FEED_SYNC,
    NEWS_FEED_SYNC_STOP,
    NewsFeedLoadAction,
    newsFeedLoadAction,
    newsFeedLoadSuccessAction,
    NewsFeedSyncAction,
    NewsFeedSyncStopAction,
} from './actions'

function* newsFeedSyncSaga() {
    yield takeEvery<NewsFeedSyncAction>(NEWS_FEED_SYNC, function* (action: NewsFeedSyncAction) {
        yield firebaseService.restartListener(`newsFeed_${action.payload[0]}`, function* () {
            yield syncValue(
                sendToSyncRepository.stsRef(SendToSyncType.NEWS_FEED),
                () => newsFeedLoadAction(action.payload),
                false,
            )
        })
    })
}

function* newsFeedSyncStopSaga() {
    yield takeEvery<NewsFeedSyncStopAction>(
        NEWS_FEED_SYNC_STOP,
        function* (action: NewsFeedSyncStopAction) {
            yield firebaseService.offListener(`newsFeed_${action.payload}`)
        },
    )
}

function* newsFeedLoadSaga() {
    yield takeEvery<NewsFeedLoadAction>(NEWS_FEED_LOAD, function* (action: NewsFeedLoadAction) {
        const data: Feed[] = yield call(newsFeedService.getNewsFeed)
        yield put(newsFeedLoadSuccessAction([action.payload[0], data]))
    })
}

export const newsFeedSagas = [newsFeedSyncSaga, newsFeedSyncStopSaga, newsFeedLoadSaga]
