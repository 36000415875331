import { includesNormalizes } from 'aos-helpers/src/helpers/Filters'
import { Optional } from 'aos-helpers/src/helpers/Optional'
import { TimeRangeFilter } from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import { isWithinRange } from 'aos-services/src/services/flightInformation/types/TimeRangeFilter'
import { Towing } from 'aos-services/src/services/flightInformation/types/towing/Towing'

import { stringFilter } from '../helpers/filters'
import { createStringSorter, createTimeSorter, stringSorter, timeSorter } from '../helpers/sorters'
import { TableFilter } from '../state'

interface TowingColumnFunctions {
    filter?: (filter: TableFilter<any>, towing: Towing) => boolean
    sorter?: (a: Towing, b: Towing, desc: boolean) => number
}

type TowingTimeFields = 'after' | 'until' | 'arrTime' | 'depTime'

const createTowingTimeSorter = (field: TowingTimeFields) => (
    a: Towing,
    b: Towing,
    desc: boolean,
) => {
    const t1 = Optional.ofNullable(a[field])
        .map(v => v.value)
        .orElseUndefined()
    const t2 = Optional.ofNullable(b[field])
        .map(v => v.value)
        .orElseUndefined()
    return timeSorter(t1, t2, desc)
}

const createStandSorter = (field: 'standFrom' | 'standTo') => (
    a: Towing,
    b: Towing,
    desc: boolean,
) => {
    const s1 = Optional.ofNullable(a[field])
        .map(v => v.stand.value)
        .orElseUndefined()
    const s2 = Optional.ofNullable(b[field])
        .map(v => v.stand.value)
        .orElseUndefined()
    return stringSorter(s1, s2, desc)
}

const createStandFilter = (filter: TableFilter, towing: Towing) => {
    const field = filter.id as 'standFrom' | 'standTo'
    const towingStand = towing[field]
    if (!towingStand) {
        return false
    }
    return includesNormalizes(towingStand.stand.value, filter.value)
}

export const timeFilter = (filter: TableFilter<TimeRangeFilter>, towing: Towing) => {
    const field = filter.id as TowingTimeFields
    const updatableTime = towing[field]
    if (!updatableTime) {
        return false
    }
    return isWithinRange(filter.value, updatableTime.value)
}

export const statusInfoFilter = (filter: TableFilter, towing: Towing) => {
    const field = filter.id as 'status'
    const status = towing[field]
    return status === filter.value
}

export const towingColumnFunctions: Partial<Record<keyof Towing, TowingColumnFunctions>> = {
    acreg: {
        sorter: createStringSorter<Towing>('acreg'),
        filter: stringFilter,
    },
    handl: {
        sorter: createStringSorter<Towing>('handl'),
        filter: stringFilter,
    },

    standFrom: {
        sorter: createStandSorter('standFrom'),
        filter: createStandFilter,
    },
    standTo: {
        sorter: createStandSorter('standTo'),
        filter: createStandFilter,
    },

    after: {
        sorter: createTowingTimeSorter('after'),
        filter: timeFilter,
    },
    until: {
        sorter: createTowingTimeSorter('until'),
        filter: timeFilter,
    },

    arrTime: {
        sorter: createTowingTimeSorter('arrTime'),
        filter: timeFilter,
    },
    arrFltnr: {
        sorter: createStringSorter<Towing>('arrFltnr'),
        filter: stringFilter,
    },
    arrCallsign: {
        sorter: createStringSorter<Towing>('arrCallsign'),
        filter: stringFilter,
    },

    depTime: {
        sorter: createTowingTimeSorter('depTime'),
        filter: timeFilter,
    },
    depFltnr: {
        sorter: createStringSorter<Towing>('depFltnr'),
        filter: stringFilter,
    },
    depCallsign: {
        sorter: createStringSorter<Towing>('depCallsign'),
        filter: stringFilter,
    },

    status: {
        sorter: createStringSorter<Towing>('status'),
        filter: statusInfoFilter,
    },
    lastUpdated: {
        sorter: createTimeSorter<Towing>('lastUpdated'),
    },
}
