import { MapSiteLocationCustomization } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { HeaderFilterVariant } from 'aos-ui/src/components/header/HeaderFilterItem'
import { ModalTitle } from 'aos-ui/src/components/modal/Modal/ModalTitle'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

import {
    LayersVisibilityPopover,
    LayersVisibilityPopoverProps,
} from '../layersVisibility/LayersVisibilityPopover'
import { MapSingleVariantHeaderFilter } from './MapSingleVariantHeaderFilter'
import { MapVariantHeaderFilter } from './MapVariantHeaderFilter'

interface ModalMapHeaderProps extends LayersVisibilityPopoverProps {
    mapVariant: MapVariant
    mapFilter: boolean
    location: MapSiteLocationCustomization
    onChangeVariant(v: MapVariant): void
    setUserLayersVisibilityAction(v: string[]): void
    changeFloorLayerVisibility(v: number): void
}

export const ModalMapHeader: FC<PropsWithChildren<ModalMapHeaderProps>> = ({
    mapVariant,
    mapFilter,
    location,
    onChangeVariant,
    bimLayers,
    userLayersVisibility,
    setUserLayersVisibilityAction,
    changeFloorLayerVisibility,
    ...props
}) => {
    return (
        <Wrapper>
            <ModalTitle>{props.children}</ModalTitle>
            <div>
                {mapFilter && (
                    <Box justify='center' alignContent='center'>
                        <VariantHeaderRender
                            location={location}
                            mapVariant={mapVariant}
                            variant={HeaderFilterVariant.Small}
                            onChangeVariant={onChangeVariant}
                        />
                    </Box>
                )}
            </div>
            {mapVariant === MapVariant.Terminal && (
                <LayersVisibilityPopover
                    userLayersVisibility={userLayersVisibility}
                    bimLayers={bimLayers}
                    setUserLayersVisibilityAction={setUserLayersVisibilityAction}
                    changeFloorLayerVisibility={changeFloorLayerVisibility}
                    mapVariant={mapVariant}
                />
            )}
        </Wrapper>
    )
}

interface VariantHeaderRenderProps {
    mapVariant: MapVariant
    variant?: HeaderFilterVariant
    location: MapSiteLocationCustomization
    onChangeVariant(v: MapVariant): void
}

export const VariantHeaderRender: FC<VariantHeaderRenderProps> = ({
    mapVariant,
    variant,
    location,
    onChangeVariant,
}) => {
    switch (location) {
        case MapSiteLocationCustomization.Helsinki:
            return (
                <MapVariantHeaderFilter
                    mapVariant={mapVariant}
                    onChange={onChangeVariant}
                    variant={variant}
                />
            )

        case MapSiteLocationCustomization.Other:
        default:
            return <MapSingleVariantHeaderFilter mapVariant={MapVariant.World} variant={variant} />
    }
}

const Wrapper = styled.div`
    width: 100%;
    flex: 1;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
`
