import { sortableHandle } from 'aos-components/src/components/dnd/Sortable'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    OperationalForecastMode,
    OperationalItem,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { CleanableTextarea } from 'aos-ui/src/components/form/input/CleanableTextarea'
import { BlockRadioGroup } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { OperationalForecastRadioRenderer } from './OperationalForecastRadioRenderer'

interface OperationalForecastEditItemProps {
    item: OperationalItem
    last: boolean
    onChange(v: OperationalItem): void
    onRemove(): void
}

export const OperationalForecastEditItem: FC<OperationalForecastEditItemProps> = ({
    item,
    onChange,
    onRemove,
    last,
}) => {
    const changeDescription = (description: string) => onChange({ ...item, description })
    const changeTitle = (title: string) => onChange({ ...item, title })
    const changeMode = (mode: OperationalForecastMode) => onChange({ ...item, mode })
    return (
        <Box paddingHorizontal={30} bg={Color.White}>
            <Wrapper paddingTop={8} paddingBottom={16} last={last}>
                <Text color={Color.UiBlack1} size={14} paddingVertical={8}>
                    <Input
                        type='text'
                        placeholder={translate(
                            'dashboard.operational-forecast-edit-modal.title-placeholder',
                        )}
                        value={item.title}
                        onChange={event => changeTitle(event.currentTarget.value)}
                    />
                </Text>
                <Box row alignItems='flex-start'>
                    <Box flex={1}>
                        <CleanableTextarea
                            placeholder={translate(
                                'dashboard.operational-forecast-edit-modal.description-placeholder',
                            )}
                            value={item.description}
                            onChangeText={changeDescription}
                            rows={2}
                        />
                    </Box>
                    <Box row paddingLeft={30}>
                        <BlockRadioGroup
                            items={EnumValues.getValues<OperationalForecastMode>(
                                OperationalForecastMode,
                            )}
                            value={item.mode}
                            gridGap={8}
                            gridSize={3}
                            Renderer={OperationalForecastRadioRenderer}
                            onChange={changeMode}
                        />
                        <IconButton
                            iconSize={BlockSize.Std}
                            iconVariant={IconVariant.Grey}
                            padding={6}
                            marginLeft={30}
                            svg={SvgIcon.Delete}
                            onClick={onRemove}
                        />
                        <Handle />
                    </Box>
                </Box>
            </Wrapper>
        </Box>
    )
}

const Input = styled.input`
    width: 100%;
`

const Wrapper = styled(Box)<{ last: boolean }>`
    ${p =>
        !p.last &&
        css`
            border-bottom: 1px solid ${Color.Grey1};
        `}
`

const Handle = sortableHandle(() => (
    <IconButton
        iconSize={BlockSize.Std}
        iconVariant={IconVariant.Grey}
        cursor='grab'
        padding={6}
        svg={SvgIcon.DraggingHandle}
    />
))
