import { Box } from 'aos-ui/src/components/base/Box'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { VariantHeaderRender } from 'aos-ui-map/src/components/map/header/ModalMapHeader'
import { MapLayersVisibility } from 'aos-ui-map/src/components/map/layersVisibility/MapLayersVisibility'
import { MapAction, switchMapAction, toggleLayersSidebarAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { airportMapMapParentAction } from '../../core/airportMap/actions'
import { AirportMapState } from '../../core/airportMap/state'
import { State } from '../../core/state'

export const AirportMapHeaderClass: FC<AirportMapHeaderProps & AirportMapHeaderDispatchProps> = ({
    mapState,
    mapAction,
}) => {
    return (
        <Header variant={HeaderVariant.Dialog} paddingHorizontal={30}>
            <Box flex={1}>
                <VariantHeaderRender
                    location={mapState.siteLocationCustomization}
                    mapVariant={mapState.variant}
                    onChangeVariant={v => mapAction(switchMapAction(v))}
                />
            </Box>
            <MapLayersVisibility
                layersSidebarOpen={mapState.layersSidebarOpen}
                toggleLayersSidebar={v => mapAction(toggleLayersSidebarAction(v))}
            />
        </Header>
    )
}

interface AirportMapHeaderProps {
    mapState: AirportMapState
}

interface AirportMapHeaderDispatchProps {
    mapAction(v: MapAction): void
}

export const AirportMapHeader = connect<AirportMapHeaderProps, AirportMapHeaderDispatchProps, {}>(
    (state: State) => ({
        mapState: state.airportMap,
    }),
    {
        mapAction: airportMapMapParentAction,
    },
)(AirportMapHeaderClass)
