import { FlightFilterConfigOption } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'

const timeRange = FlightFilterConfigOption.HistoryTimeRange

export const regularityFilterConfigs = {
    small: [timeRange, FlightFilterConfigOption.Airline, FlightFilterConfigOption.HandlingAgent],
    history: [timeRange, FlightFilterConfigOption.Airline, FlightFilterConfigOption.HandlingAgent],
    flights: [
        timeRange,
        FlightFilterConfigOption.FlightType,
        FlightFilterConfigOption.Airline,
        FlightFilterConfigOption.HandlingAgent,
    ],
}
