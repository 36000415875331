import { dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    firefightersAddShiftAction,
    firefighterUpdateShiftAction,
} from 'aos-services/src/core/firefighters/actions'
import { firefightersService } from 'aos-services/src/dataaccess/tasks/firefighters/firefightersService'
import { firefighterFormInitialState } from 'aos-services/src/services/tasks/firefighters/types/FirefighterFormFields'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { replace } from 'redux-first-history'

import { closeFireFighterFormAction } from '../../../../core/tasks/firefighters/actions'
import { firefighterEditorShiftToUpdateSelector } from '../../../../core/tasks/firefighters/selectors'
import { FirefighterForm } from './FirefighterForm'

export const FirefighterEditForm = () => {
    const shiftToUpdate = useSelector(firefighterEditorShiftToUpdateSelector)
    const memoizedInitialStateForm = useMemo(
        () =>
            shiftToUpdate
                ? firefightersService.mapShiftToFormFields(shiftToUpdate)
                : firefighterFormInitialState,
        [shiftToUpdate?.id],
    )
    const dispatch = useDispatch()

    const onCloseCreated = () => {
        dispatch(closeFireFighterFormAction())
        successToast(translate('firefighters.messages.created'), () => {
            dispatch(replace('/tasks/fire-fighters'))
        })
    }

    const onCloseEdit = () => {
        dispatch(closeFireFighterFormAction())
        successToast(translate('firefighters.messages.updated'), () => {
            dispatch(replace('/tasks/fire-fighters'))
        })
    }

    return (
        <FirefighterForm
            initialState={memoizedInitialStateForm}
            validFromDate={shiftToUpdate ? dateTime(shiftToUpdate.validFrom) : dateTime()}
            onSubmit={data =>
                shiftToUpdate?.id
                    ? dispatch(firefighterUpdateShiftAction({ data, onSuccess: onCloseEdit }))
                    : dispatch(firefightersAddShiftAction({ data, onSuccess: onCloseCreated }))
            }
            onClose={() => {
                dispatch(closeFireFighterFormAction())
            }}
        />
    )
}
