import { recordEntries } from 'aos-helpers/src/helpers/Record'
import { flatten } from 'lodash'

export enum RunwayMode {
    Departure = 'departure',
    Conditional = 'conditional',
    Arrival = 'arrival',
}

const runwayModeSort: Record<RunwayMode, number> = {
    [RunwayMode.Departure]: 1,
    [RunwayMode.Conditional]: 2,
    [RunwayMode.Arrival]: 3,
}

export const compareModes = (a: RunwayMode, b: RunwayMode) => runwayModeSort[a] - runwayModeSort[b]

export type Runway = '22R' | '15' | '22L' | '04R' | '04L' | '33'

export const runway1: [Runway, Runway] = ['22R', '04L']
export const runway2: [Runway, Runway] = ['22L', '04R']
export const runway3: [Runway, Runway] = ['15', '33']

export const isSectionActive = (runwayModes: RunwayMode[]) =>
    runwayModes.some(mode => mode !== RunwayMode.Conditional)

export type RunwayCombination = Record<Runway, RunwayMode[]>

export const emptyCombination: RunwayCombination = {
    '22R': [],
    '22L': [],
    '04R': [],
    '04L': [],
    '15': [],
    '33': [],
}

export const isRunwayActive = (combination: RunwayCombination, runway: [Runway, Runway]) =>
    isSectionActive(combination[runway[0]]) || isSectionActive(combination[runway[1]])

export const isRunwayMode = (
    combination: RunwayCombination,
    runway: [Runway, Runway],
    mode: RunwayMode,
) => sectionHasMode(combination[runway[0]], mode) || sectionHasMode(combination[runway[1]], mode)

export const getInactiveRunways = (combination: RunwayCombination) =>
    flatten([runway1, runway2, runway3].filter(r => !isRunwayActive(combination, r)))

export const getRunwaysByMode = (combination: RunwayCombination, mode: RunwayMode): Runway[] =>
    recordEntries(combination)
        .filter(c => c[1].indexOf(mode) >= 0)
        .map(c => c[0])

const sectionHasMode = (runwayModes: RunwayMode[], mode: RunwayMode) =>
    runwayModes.some(m => m === mode)
