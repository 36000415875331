import { FEED_OUT } from '../../dataaccess/notifications/types/FeedOutNotificationDto'
import { MESSAGE } from '../../dataaccess/notifications/types/MessageNotificationDto'
import { AosNotificationDto } from '../../dataaccess/notifications/types/NotificationDto'
import { PRIVATE_CHANNEL_INVITE } from '../../dataaccess/notifications/types/PrivateChannelInviteNotificationDto'
import { FeedOutNotificationImpl } from './types/FeedOutNotificationImpl'
import { LVP_WA_ALERT_HEL } from './types/LvpWaAlertNotification'
import { LvpWaAlertNotificationImpl } from './types/LvpWaAlertNotificationImpl'
import { MessageNotificationImpl } from './types/MessageNotificationImpl'
import { AosNotification } from './types/Notification'
import { PrivateChannelInviteNotificationImpl } from './types/PrivateChannelInviteNotificationImpl'

export const notificationDtoToNotification =
    (markAlwaysAsRead: boolean) =>
    ([id, dto]: [string, AosNotificationDto]): AosNotification => {
        switch (dto.type) {
            case FEED_OUT:
                return new FeedOutNotificationImpl(id, dto, markAlwaysAsRead)
            case PRIVATE_CHANNEL_INVITE:
                return new PrivateChannelInviteNotificationImpl(id, dto, markAlwaysAsRead)
            case MESSAGE:
                return new MessageNotificationImpl(id, dto, markAlwaysAsRead)
            case LVP_WA_ALERT_HEL:
                return new LvpWaAlertNotificationImpl(id, dto, markAlwaysAsRead)
            default:
                return undefined as any
        }
    }

export const notificationDtoListToNotificationList =
    (markAlwaysAsRead: boolean) =>
    (items: [string, AosNotificationDto][]): AosNotification[] =>
        items.map(notificationDtoToNotification(markAlwaysAsRead)).filter(t => t)
