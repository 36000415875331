import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosBaseUserGroupDto } from '../../../dataaccess/groups/types/AosUserGroupDto'
import { AosBaseUserGroup } from './AosUserGroup'
import { AosUserGroupType } from './AosUserGroupType'

export class AosBaseUserGroupImpl
    extends WrapperObject<AosBaseUserGroupDto>
    implements AosBaseUserGroup {
    constructor(value: AosBaseUserGroupDto) {
        super(value)
    }

    public get name() {
        return this.value.name
    }

    public get type() {
        return this.value.type as AosUserGroupType
    }

    public get id() {
        return this.value.id
    }
}
