import { SubmitAction } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { SingleSelectModalActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleSelect/actions'
import {
    SimpleFormModal,
    SimpleFormModalProps,
} from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { Booleanish } from 'aos-helpers/src/helpers/Boolean'
import { isDefined } from 'aos-helpers/src/helpers/Function'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
    selectUserGroupActions,
    toggleUserGroupConfirmationAction,
} from '../../../core/eventEditor/actions'
import { SelectUserGroupModalState } from '../../../core/eventEditor/state'
import { State } from '../../../core/state'
import { SelectUserGroupConfirmationModal } from './SelectUserGroupConfirmationModal'

type SelectUserGroupModalOwnProps = {
    group: SelectUserGroupModalState<AosUserGroup>
    isHardAlert: boolean
}
class SelectUserGroupModalClass extends PureComponent<
    SimpleFormModalProps & SelectUserGroupModalOwnProps & SelectUserGroupModalActionCreators
> {
    public render() {
        const {
            group: { isConfirmationVisible },
        } = this.props

        return (
            <Invisible>
                {isConfirmationVisible && this.renderConfirmationModal()}
                {!isConfirmationVisible && this.renderSelectFormModal()}
            </Invisible>
        )
    }

    private renderSelectFormModal = () => {
        const changeValue = (value: AosUserGroup) =>
            this.props.changeSelectValueAction({ ...this.props.group.currentValue, value })
        const confirmSubmitAction = (): SubmitAction | any => {
            if (this.props.group.currentValue && this.props.group.currentValue.value) {
                this.props.toggleUserGroupConfirmation(true)
            }
        }

        return (
            <SimpleFormModal
                {...{ ...this.props.group, ...this.props, submitAction: confirmSubmitAction }}
            >
                <LabeledSelect
                    isError={this.props.group.form.error.value}
                    value={this.props.group.currentValue.value}
                    onChange={changeValue}
                    isRequired
                    keyPrefix={this.props.keyPrefix}
                    items={this.props.group.options}
                    labelRenderer={aosUserGroupName}
                    maxHeight={300}
                    seleniumLocation='user-group'
                />
            </SimpleFormModal>
        )
    }

    private renderConfirmationModal = () => {
        const submitAction = (value: Booleanish) => {
            this.props.toggleUserGroupConfirmation(false)
            this.props.submitAction({
                value: {
                    group: this.props.group.currentValue.value,
                    eventId: this.props.group.currentValue.payload,
                    isHardAlert: value === true,
                },
            })
        }

        const closeAction = () => {
            this.props.toggleUserGroupConfirmation(false)
        }

        const {
            group: { isConfirmationVisible, currentValue },
        } = this.props
        const group = currentValue.value

        return isDefined(group) ? (
            <SelectUserGroupConfirmationModal
                isHardAlert={this.props.isHardAlert}
                isOpen={isConfirmationVisible}
                closeAction={closeAction}
                onSubmit={submitAction}
                group={group as AosUserGroup}
            />
        ) : null
    }
}

const Invisible = styled(Box)`
    width: 0;
    height: 0;
    overflow: hidden;
`

interface SelectUserGroupModalActionCreators extends SingleSelectModalActionCreators {
    toggleUserGroupConfirmation: typeof toggleUserGroupConfirmationAction
}

export const SelectUserGroupModal = connect<
    SelectUserGroupModalOwnProps,
    SelectUserGroupModalActionCreators,
    SimpleFormModalProps
>(
    (state: State) => ({
        group: state.eventEditor.selectUserGroupModalState,
        isHardAlert: state.eventEditor.event?.severity === AosSeverity.Emergency,
    }),
    {
        ...selectUserGroupActions,
        toggleUserGroupConfirmation: toggleUserGroupConfirmationAction,
    },
)(SelectUserGroupModalClass)
