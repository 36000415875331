import { groupedGateToSingleGates } from '../../services/layerData/properties/GatesProperties'
import {
    findPointInLayerData,
    LayersDataState,
    LayersDataStateAware,
} from '../../services/layerData/types/LayersDataState'

export const initialLayersDataState: LayersDataState = {}

export const standStateSelector1 = (id: string) => (state: LayersDataStateAware) =>
    // @ts-ignore
    findPointInLayerData(state.layersData.stands, props => props.teksti === id)

export const gateStateSelector1 = (id: string) => (state: LayersDataStateAware) =>
    // @ts-ignore
    findPointInLayerData(state.layersData.gates, props => props.Gate === id)

export const busGateStateSelector1 = (id: string) => (state: LayersDataStateAware) =>
    findPointInLayerData(state.layersData.busGates, props =>
        // @ts-ignore
        groupedGateToSingleGates(props.Gate).includes(id),
    )
