import { SingleDateActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleDate/actions'
import { SingleDateModalState } from 'aos-components/src/components/modal/InputModal/core/singleDate/state'
import { SimpleFormModalProps } from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { SingleDateModalClass } from 'aos-components/src/components/modal/InputModal/SingleDateModal'
import { connect } from 'react-redux'

import { iWillArriveActions } from '../../../core/eventEditor/actions'
import { State } from '../../../core/state'

export const IWillArriveModal = connect<
    SingleDateModalState,
    SingleDateActionCreators,
    SimpleFormModalProps
>(
    (state: State) => state.eventEditor.iWillArriveModalState,
    iWillArriveActions,
)(SingleDateModalClass)
