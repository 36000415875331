import {
    notamSyncAction,
    notamSyncStopAction,
    snowtamSyncAction,
    snowtamSyncStopAction,
} from 'aos-services/src/core/notam/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { FC, PropsWithChildren } from 'react'

import { useSyncHook } from '../../../../hooks/useSync'

interface NotamSyncWrapperProps {
    id: SyncDataKey
}

export const NotamSyncWrapper: FC<PropsWithChildren<NotamSyncWrapperProps>> = ({
    children,
    id,
}) => {
    useSyncHook(
        [notamSyncAction(id), snowtamSyncAction(id)],
        [notamSyncStopAction(id), snowtamSyncStopAction(id)],
    )
    return children as JSX.Element
}
