import { DependencyList, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

/**
 * `useState` with dependency list, similar to `useMemo` but you can change state
 *
 * @example const [error, setError] = useStateEffect(!url, [url]);
 * @example const [table, setTable] = useStateEffect(() => makeTable(data), [data]);
 */
export const useStateEffect = <T>(
    value: T | (() => T),
    deps: DependencyList,
): [T, Dispatch<SetStateAction<T>>] => {
    const [state, setState] = useState<T>(value)
    const isInitialMount = useRef(true)

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
        } else {
            setState(value)
        }
    }, deps)

    return [state, setState]
}
