import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Page, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { ShiftNotesFormData } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesFormData'
import { ShiftNotesDto } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesListDto'

export const SHIFT_NOTES_SYNC = 'STATUS_DASHBOARD_DATA/SHIFT_NOTES/SHIFT_NOTES_SYNC'
export type ShiftNotesSyncAction = PayloadAction<typeof SHIFT_NOTES_SYNC, SyncDataKey>
export const shiftNotesSyncAction = payloadActionCreator<ShiftNotesSyncAction, SyncDataKey>(
    SHIFT_NOTES_SYNC,
)

export const SHIFT_NOTES_SYNC_STOP = 'STATUS_DASHBOARD_DATA/SHIFT_NOTES/SHIFT_NOTES_SYNC_STOP'
export type ShiftNotesSyncStopAction = PayloadAction<typeof SHIFT_NOTES_SYNC_STOP, SyncDataKey>
export const shiftNotesSyncStopAction = payloadActionCreator<ShiftNotesSyncStopAction, SyncDataKey>(
    SHIFT_NOTES_SYNC_STOP,
)

export const SHIFT_NOTES_LOAD = 'SHIFT_NOTES/LOAD'
export type ShiftNotesLoadAction = Action<typeof SHIFT_NOTES_LOAD>
export const shiftNotesLoadAction = emptyActionCreator<ShiftNotesLoadAction>(SHIFT_NOTES_LOAD)

export const SHIFT_NOTES_LOAD_SUCCESS = 'SHIFT_NOTES/LOAD_SUCCESS'
export type ShiftNotesLoadSuccessAction = PayloadAction<
    typeof SHIFT_NOTES_LOAD_SUCCESS,
    Pageable<ShiftNotesDto>
>
export const shiftNotesLoadSuccessAction = payloadActionCreator<
    ShiftNotesLoadSuccessAction,
    Pageable<ShiftNotesDto>
>(SHIFT_NOTES_LOAD_SUCCESS)

export const SHIFT_NOTES_RELOAD = 'SHIFT_NOTES/RELOAD'
export type ShiftNotesReloadAction = PayloadAction<
    typeof SHIFT_NOTES_RELOAD,
    Pageable<ShiftNotesDto>
>
export const shiftNotesReloadAction = payloadActionCreator<ShiftNotesReloadAction, Page>(
    SHIFT_NOTES_RELOAD,
)

export const SHIFT_NOTES_RELOAD_SUCCESS = 'SHIFT_NOTES/RELOAD_SUCCESS'
export type ShiftNotesReloadSuccessAction = PayloadAction<
    typeof SHIFT_NOTES_RELOAD_SUCCESS,
    Pageable<ShiftNotesDto>
>
export const shiftNotesReloadSuccessAction = payloadActionCreator<
    ShiftNotesReloadSuccessAction,
    Pageable<ShiftNotesDto>
>(SHIFT_NOTES_RELOAD_SUCCESS)

export const SHIFT_NOTES_ADD = 'SHIFT_NOTES/ADD'
export type ShiftNotesAddAction = PayloadAction<typeof SHIFT_NOTES_ADD, ShiftNotesFormData>
export const shiftNotesAddAction = payloadActionCreator<ShiftNotesAddAction, ShiftNotesFormData>(
    SHIFT_NOTES_ADD,
)

export const SHIFT_NOTES_ADD_SUCCESS = 'SHIFT_NOTES/ADD_SUCCESS'
export type ShiftNotesAddSuccessAction = Action<typeof SHIFT_NOTES_ADD_SUCCESS>
export const shiftNotesAddSuccessAction =
    emptyActionCreator<ShiftNotesAddSuccessAction>(SHIFT_NOTES_ADD_SUCCESS)

export const OPEN_SHIFT_NOTES_MODAL = 'SHIFT_NOTES/OPEN_MODAL'
export type OpenShiftNotesModalAction = Action<typeof OPEN_SHIFT_NOTES_MODAL>
export const openShiftNotesModalAction =
    emptyActionCreator<OpenShiftNotesModalAction>(OPEN_SHIFT_NOTES_MODAL)

export const CLOSE_SHIFT_NOTES_MODAL = 'SHIFT_NOTES/CLOSE_MODAL'
export type CloseShiftNotesModalAction = Action<typeof CLOSE_SHIFT_NOTES_MODAL>
export const closeShiftNotesModalAction =
    emptyActionCreator<CloseShiftNotesModalAction>(CLOSE_SHIFT_NOTES_MODAL)

export type ShiftNotesAction =
    | ShiftNotesLoadAction
    | ShiftNotesLoadSuccessAction
    | ShiftNotesReloadAction
    | ShiftNotesReloadSuccessAction
    | ShiftNotesAddAction
    | ShiftNotesAddSuccessAction
    | ShiftNotesSyncAction
    | ShiftNotesSyncStopAction
    | OpenShiftNotesModalAction
    | CloseShiftNotesModalAction
