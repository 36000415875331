import { dateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import moment from 'moment'

import { AosTimelineEventDto } from '../../../dataaccess/events/types/AosTimelineEventDto'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosEventProcessType } from './AosEventProcessType'
import { AosEventScenario } from './AosEventScenario'
import { AosEventVisibility } from './AosEventVisibility'
import { AosTimelineEvent } from './AosTimelineEvent'

export class AosTimelineEventImpl<T extends AosTimelineEventDto = AosTimelineEventDto>
    extends WrapperObject<T>
    implements AosTimelineEvent {
    constructor(value: T) {
        super(value)
    }

    public get id() {
        return this.value.id!
    }

    public get hasPrivateChannel() {
        return this.value.hasPrivateChannel
    }

    public get startTime() {
        return dateTime(this.value.startTime)
    }

    public get endTime() {
        const defaultEndTime = moment.max(this.startTime.add(8, 'hour'), dateTime().add(8, 'hour'))
        return dateTimeFromOptional(this.value.endTime) || defaultEndTime
    }

    public get process() {
        return this.value.process as AosEventProcessType
    }

    public get title() {
        return this.value.title
    }

    public get visibility() {
        return this.value.visibility as AosEventVisibility
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get category() {
        return this.value.category as AosEventScenario
    }
}
