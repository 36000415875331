import { EnumValues } from 'enum-values'

import { translateFilterWithAllOption } from './common'

export enum TobtType {
    Airline = 'Airline',
    GroundHandling = 'Ground Handling',
}

export const translateTobtFiltersValues = translateFilterWithAllOption(
    TobtType,
    'dashboard.tobt.type',
)

export const tobtFiltersValues = EnumValues.getValues(TobtType)

export enum TobtDataTimeRangeType {
    Today = 'Today',
    Yesterday = 'Yesterday',
}

export const translateTobtDataTimeFiltersValues = translateFilterWithAllOption(
    TobtDataTimeRangeType,
    'dashboard.tobt.time-range',
)

export const tobtDataTimeFiltersValues = EnumValues.getValues(TobtDataTimeRangeType)
