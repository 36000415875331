import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { RunwaysState } from 'aos-services/src/services/runways/types/RunwaysState'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { RunwaySyncWrapper } from './RunwaySyncWrapper'

interface RunwaysItemProps {
    runwaysState?: RunwaysState
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const RunwayItem: FC<PropsWithChildren<RunwaysItemProps>> = props => {
    const combination = props.runwaysState && props.runwaysState.combinationDetails
    return (
        <RunwaySyncWrapper id={props.syncId}>
            <StatusDashboardItem
                title={translate('dashboard.runways.title')}
                description={translate('dashboard.runways.description')}
                noDataWarning={!combination}
                link={props.link}
                {...props.itemProps}
            >
                {props.children}
            </StatusDashboardItem>
        </RunwaySyncWrapper>
    )
}
