import { appConfig } from 'appConfig'

import { BaseRestService, RequestInterceptorParam } from '../../dataaccess/base/BaseRestService'

const enhanceUrlWithBaseUrl = (url: string) => {
    const urlPattern = /^https?:\/\//i
    // do not enhance absolute urls
    if (!urlPattern.test(url)) {
        return `${appConfig.apiUrl}${url}`
    }
    return url
}

export const baseUrlInterceptor = ([
    url,
    init,
]: RequestInterceptorParam): RequestInterceptorParam => [enhanceUrlWithBaseUrl(url), init]

export const registerBaseUrlInterceptor = () => {
    BaseRestService.requestInterceptors.push(baseUrlInterceptor)
}
