import { isValidEnumEntry } from 'aos-helpers/src/helpers/Enum'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { fromPairs } from 'lodash'

import { airlines } from '../../../masterData/airlines'
import { FilterOptionAll } from './common'

const airlineDict = fromPairs(airlines.map(a => [a.code, a.name]))

export type AirlineFilter = FilterOptionAll | string

export const airlinesFilterValues: AirlineFilter[] = [
    FilterOptionAll.All,
    ...airlines.map(a => a.code),
]

export const translateAirlineFilter = (value: AirlineFilter) =>
    isValidEnumEntry(FilterOptionAll)<FilterOptionAll>(value)
        ? translate('dashboard.regularity.airline.all')
        : airlineDict[value]
