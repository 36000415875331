import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'

export interface UserLayerVisibilityState {
    list: string[]
    isLoading: boolean
    floor: number
}

export interface UserLayerVisibilityStateAware {
    userLayerVisibility: UserLayerVisibilityState
}

export const initialUserLayersVisibilityState: UserLayerVisibilityState = {
    list: [],
    isLoading: true,
    floor: 0,
}

export interface UserLayerVisibilityPayload {
    list: string[]
    floor: number
}

export const initialWorldUserLayersVisibilityState: UserLayerVisibilityState = {
    list: [BimLayerName.Tasks, BimLayerName.Events],
    isLoading: false,
    floor: 0,
}
