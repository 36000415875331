import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DeIcingChemicals } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import {
    allSeasons,
    allTimeFrequencies,
    ReportSeason,
    ReportsFilters,
    ReportTimeFrequency,
    translateSeason,
    translateTimeFrequency,
} from '../../../../core/tasks/reports/state'
import { TimeRangeDropdowns } from '../components/TimeRangeDropdowns'
import { ReportAirportDropdown } from '../dropdowns/ReportAirportDropdown'
import { ReportChemicalCompoundDropdown } from '../dropdowns/ReportChemicalCompoundDropdown'
import { ReportChemicalsDropdown } from '../dropdowns/ReportChemicalsDropdown'
import { ReportChemicalTypeDropdown } from '../dropdowns/ReportChemicalTypeDropdown'
import { ReportTypeDropdown } from '../dropdowns/ReportTypeDropdown'
import { UnitToggle } from './UnitToggle'

interface ChemicalsReportContentProps {
    filters: ReportsFilters
    chemicals: DeIcingChemicals[]
    yearRange: number[]
    onChange(filters: ReportsFilters): void
}

export const ChemicalsReportContent: FC<ChemicalsReportContentProps> = ({
    filters,
    onChange,
    chemicals,
    yearRange,
}) => {
    const updateFilter = (newFilters: Partial<ReportsFilters>) =>
        onChange({ ...filters, ...newFilters })
    const overlapping = filters.season === ReportSeason.Winter

    return (
        <Box bg={Color.UiBlack2}>
            <SeparatedContainer
                spacing={12}
                paddingHorizontal={24}
                paddingTop={24}
                orientation='horizontal'
            >
                <ReportAirportDropdown
                    value={filters.airport}
                    onChange={airport => updateFilter({ airport })}
                />
                <ReportTypeDropdown
                    value={filters.report}
                    onChange={report => updateFilter({ report })}
                />

                <Dropdown<ReportTimeFrequency>
                    variant={DropdownVariant.BlackGrey}
                    value={filters.timeFrequency}
                    items={allTimeFrequencies}
                    valueRenderer={translateTimeFrequency}
                    onChange={timeFrequency => updateFilter({ timeFrequency })}
                />

                <Dropdown<ReportSeason>
                    variant={DropdownVariant.BlackGrey}
                    value={filters.season}
                    items={allSeasons}
                    valueRenderer={translateSeason}
                    onChange={season => updateFilter({ season })}
                />

                <TimeRangeDropdowns
                    filters={filters}
                    yearRange={yearRange}
                    updateFilter={updateFilter}
                    overlapping={overlapping}
                />
            </SeparatedContainer>

            <SeparatedContainer
                spacing={12}
                paddingHorizontal={24}
                paddingTop={16}
                paddingBottom={24}
                orientation='horizontal'
            >
                <Text size={12} color={Color.White}>
                    {translate('reports.chemicals.anti-slip-agent')}
                </Text>
                <ReportChemicalTypeDropdown
                    value={filters.chemicalType}
                    onChange={chemicalType => updateFilter({ chemicalType })}
                />
                <Text size={12} color={Color.White}>
                    {translate('reports.chemicals.anti-slip-agent.type')}
                </Text>
                <ReportChemicalCompoundDropdown
                    value={filters.chemicalCompound}
                    onChange={chemicalCompound => updateFilter({ chemicalCompound })}
                />
                <ReportChemicalsDropdown
                    value={filters.chemical}
                    onChange={chemical => updateFilter({ chemical, airport: FilterOptionAll.All })}
                    chemicals={chemicals}
                />
                <UnitToggle value={filters.unit} onChange={unit => updateFilter({ unit })} />
            </SeparatedContainer>
        </Box>
    )
}
