import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { Box } from 'aos-ui/src/components/base/Box'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import React, { FC, ReactNode } from 'react'

import { FlightTablePreview } from '../flightInformation/preview/FlightTablePreview'
import { RestrictionPreview } from '../restrictions/preview/RestrictionPreview'

interface AirportResourceSidebarProps {
    title: ReactNode
    restriction?: Restriction
    arrivals?: Flight[]
    departures?: Flight[]
    subtitle?: ReactNode
}

export const AirportResourceSidebar: FC<AirportResourceSidebarProps> = props => {
    const { title, restriction, arrivals, departures, subtitle } = props
    const flightSections = [
        ...(arrivals ? [FlightListType.Arrivals] : []),
        ...(departures ? [FlightListType.Departures] : []),
    ]

    return (
        <Sidebar title={title} subtitle={subtitle}>
            {restriction && (
                <Box paddingBottom={20}>
                    <RestrictionPreview restriction={restriction} />
                </Box>
            )}
            <FlightTablePreview
                flightSections={flightSections}
                arrivals={arrivals || []}
                departures={departures || []}
            />
        </Sidebar>
    )
}
