import { EnumValues } from 'enum-values'

import { translateEnum } from '../../../../../aos-helpers/src/helpers/translations/Translations'

export enum BimLayerName {
    // BimLayerName - Terminal
    ArrivalHalls = 'arrival-halls',
    ArrivalServices = 'arrival-services',
    BabyCare = 'baby-care',
    BusDoors = 'bus-doors',
    BusGates = 'bus-gates',
    CheckInDesks = 'check-in-desks',
    CheckInKiosks = 'check-in-kiosks',
    Customs = 'customs',
    DisabledToilets = 'disabled-toilets',
    Gates = 'gates',
    Lifts = 'lifts',
    NonSchengenArea = 'non-schengen-area',
    PassportCheck = 'passport-check',
    SchengenArea = 'schengen-area',
    SecurityChecksPassengers = 'security-check-passengers',
    SecurityCheckStaff = 'security-check-staff',
    SisScreens = 'sis-screens',
    TerminalBuilding = 'terminal-building',
    Toilets = 'toilets',

    // BimLayerName - LandsideAndParking
    BusStops = 'bus-stops',
    ParkingHalls = 'parking-halls',
    ParkingLabels = 'parking-labels',
    ParkingPaymentMachine = 'parking-payment-machine',
    ShuttleBusRoute = 'shuttle-bus-route',
    ShuttleBusStops = 'shuttle-bus-stops',
    TaxiStands = 'taxi-stands',
    TrainStation = 'train-station',
    WalkingRoutes = 'walking-routes',

    // Apron and Maneuvering
    DeIcingAreas = 'de-icing-areas',
    LightMasts = 'light-masts',
    Stands = 'stands',
    WaLvpScreens = 'wa-lvp-screens',

    // AirsideArea
    ConstructionArea = 'construction-areas',
    SecurityFence = 'security-fence',
    SecurityFeceGatesAndCheckpoints = 'security-fence-gates-and-checkpoints',

    //Basemaps
    HelsinkiAirportBasemap = 'helsinki-airport-basemap',
    TrainRingRail = 'train-ring-rail',

    //Trains
    Trains = 'trains',

    //Thematic maps
    ApronBasemap = 'apron-map',
    AerodromeManeuvering = 'aerodrome-maneuvering',

    //Real estate and buildings
    BuildingsOtherAirports = 'buildings-other-airports',

    //Rescue services
    NavigationGrid = 'navigation-grid',
    FireAreas = 'fire-areas',
    FireDoors = 'fire-doors',

    //Tasks and events
    Tasks = 'tasks',
    Events = 'events',

    //Floors
    Floor0 = 'floor0',
    Floor1 = 'floor1',
    Floor2 = 'floor2',
    Floor3 = 'floor3',

    // Atc
    NavigationGridAtc = 'navigation-grid-atc',
    TaxiwayLines = 'taxiway-lines',
    TaxiwayNames = 'taxiway-names',
    RescueService = 'rescue-service',
    ServiceRoads = 'service-roads',
    AirsideFence = 'airside-fence',
    DeicingArea = 'deicing-area',
    Buildings = 'buildings',
    TaxiwayLabels = 'taxiway-labels',
    RescueStationsAtc = 'rescue-stations-atc',
    NavigationMap = 'navigation-map',

    //Network
    NetworkBaseLayer = 'network-base-layer',
}

export type AtcLayers =
    | BimLayerName.AirsideFence
    | BimLayerName.Buildings
    | BimLayerName.DeicingArea
    | BimLayerName.NavigationGridAtc
    | BimLayerName.NavigationMap
    | BimLayerName.RescueService
    | BimLayerName.RescueStationsAtc
    | BimLayerName.ServiceRoads
    | BimLayerName.TaxiwayLabels
    | BimLayerName.TaxiwayLines
    | BimLayerName.TaxiwayNames

export const allEventDetailsTabs = EnumValues.getValues<BimLayerName>(BimLayerName)
export const translateBimLayerName = translateEnum(BimLayerName, 'bim.layer.name')
