import { firebaseMapLayersVisibilityRepository } from 'aos-services/src/dataaccess/mapLayers/firebaseMapLayersVisibilityRepository'

export class UserLayerVisibilityService {
    constructor() {}
    public loadUserLayers = async (userId: number): Promise<string[]> => {
        return await firebaseMapLayersVisibilityRepository.load(userId)
    }
}

export const userLayerVisibilityService = new UserLayerVisibilityService()
