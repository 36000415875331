import { enumRecord, getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { positionInGrid } from 'aos-helpers/src/helpers/Grid'
import { CdmMetricSubType } from 'aos-services/src/services/airportStatus/cdm/types/CdmMetricSubType'
import { CdmMetricType } from 'aos-services/src/services/airportStatus/cdm/types/CdmMetricType'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import {
    WeatherCurrentTimePoint,
    WeatherForecastTimePoint,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { AosHandlingAgent } from 'aos-services/src/services/flightInformation/types/AosHandlingAgent'
import { FeedChannelsFilter } from 'aos-services/src/services/newsFeed/types/FeedChannelsFilter'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { Size } from 'aos-services/src/services/statusDashboard/types/DashboardSizes'
import {
    BaggageDeliveryFullViewTab,
    BaggageDeliveryTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/BaggageDeliveryFilters'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import {
    CommonFlightView,
    FlightsHistoryTimeRange,
    FlightsPredictionTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { FlightsGrouping } from 'aos-services/src/services/statusDashboard/types/filters/FlightsVolumesFilters'
import { defaultOperationalForecastFilters } from 'aos-services/src/services/statusDashboard/types/filters/OperationalForecastFilters'
import { PaxSecurityPoint } from 'aos-services/src/services/statusDashboard/types/filters/PaxFilters'
import { defaultPaxForecastFilters } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastFilters'
import { defaultQueueingTimeFilters } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import {
    TobtDataTimeRangeType,
    TobtType,
} from 'aos-services/src/services/statusDashboard/types/filters/TobtFilters'
import {
    WeatherGroup,
    WeatherTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Breakpoint, dashboardGridColumns } from 'aos-ui/src/components/grid/ResponsiveGrid'
import { EnumValues } from 'enum-values'
import { uniqueId } from 'lodash'

import { DashboardItemState } from '../../services/statusDashboard/types/DashboardItemState'
import {
    DashboardItem,
    DashboardPresets,
    DashboardProcessType,
    MyDashboardTab,
    selectBreakpoint,
} from '../../services/statusDashboard/types/DashboardPreset'

export const defaultNewsFeedFilters = [
    FeedChannelsFilter.Finavia,
    FeedChannelsFilter.Eurocontrol,
    FeedChannelsFilter.HelsinkiAirport,
    FeedChannelsFilter.Lentopostifi,
    FeedChannelsFilter.Ansfinland,
]

const id = () => parseInt(uniqueId(), 10)

const defaultCdmSubType = getInitialValuesForEnumRecord<CdmMetricType, CdmMetricSubType>(
    CdmMetricType,
    CdmMetricSubType.Last30m,
)

const basePunctualityItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Punctuality as DashboardItemType.Punctuality,
    timeRange: FlightsHistoryTimeRange.Today,
    flightType: FlightsType.All,
    fullViewTab: CommonFlightView.History,
    handlingAgent: FilterOptionAll.All,
    airline: FilterOptionAll.All,
}

const baseBaggageDeliveryItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.BaggageDelivery as DashboardItemType.BaggageDelivery,
    timeRange: BaggageDeliveryTimeRange.Today,
    handlingAgent: FilterOptionAll.All,
    beltArea: FilterOptionAll.All,
    fullViewTab: BaggageDeliveryFullViewTab.History,
}

const baseRegularityItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Regularity as DashboardItemType.Regularity,
    timeRange: FlightsHistoryTimeRange.Today,
    handlingAgent: FilterOptionAll.All,
    airline: FilterOptionAll.All,
    flightType: FlightsType.All,
    fullViewTab: CommonFlightView.History,
}

export const baseEventsItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Events as DashboardItemType.Events,
}

const baseNewsFeedItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.NewsFeed as DashboardItemType.NewsFeed,
    filters: defaultNewsFeedFilters,
}

const basePaxItem = {
    size: DashboardItemSizeVariant.Big,
    type: DashboardItemType.Pax as DashboardItemType.Pax,
    paxSecurityPoint: PaxSecurityPoint.Dep,
}

const baseRunwaysItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Runways as DashboardItemType.Runways,
}

const baseFlightsItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Flights as DashboardItemType.Flights,
    flightsStats: FlightsGrouping.Total,
    timeRange: FlightsPredictionTimeRange.Today,
    flightType: FlightsType.All,
    fullViewTab: CommonFlightView.History,
    handlingAgent: FilterOptionAll.All,
    airline: FilterOptionAll.All,
}

const baseDelaysItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Delays as DashboardItemType.Delays,
    timeRange: FlightsHistoryTimeRange.Today,
    flightType: FlightsType.All,
    fullViewTab: CommonFlightView.History,
    handlingAgent: FilterOptionAll.All,
    airline: FilterOptionAll.All,
}

const baseCdmItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Cdm as DashboardItemType.Cdm,
    cdmMetricSubType: defaultCdmSubType,
    tobtType: TobtType.GroundHandling,
    tobtDataTimeRange: TobtDataTimeRangeType.Today,
}

const baseWeatherItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Weather as DashboardItemType.Weather,
    unit: WeatherUnit.Ms,
    timePoint: WeatherCurrentTimePoint.Current,
    timeRange: WeatherTimeRange.Range12,
    group: WeatherGroup.Conditions,
}

const forecastWeatherItem = {
    type: DashboardItemType.WeatherForecast as DashboardItemType.WeatherForecast,
    size: DashboardItemSizeVariant.Small,
    group: WeatherGroup.Conditions,
}

const basePaxForecastItem = {
    type: DashboardItemType.PaxForecast as DashboardItemType.PaxForecast,
    size: DashboardItemSizeVariant.Big,
    filters: defaultPaxForecastFilters,
}

const baseOperationalForecastItem = {
    type: DashboardItemType.OperationalForecast as DashboardItemType.OperationalForecast,
    size: DashboardItemSizeVariant.Big,
    ...defaultOperationalForecastFilters,
}

const baseQueueingTimeBorderItem = {
    type: DashboardItemType.QueueingTimeBorder as DashboardItemType.QueueingTimeBorder,
    size: DashboardItemSizeVariant.Big,
    ...defaultQueueingTimeFilters,
}

const baseQueueingTimeSecurityItem = {
    type: DashboardItemType.QueueingTimeSecurity as DashboardItemType.QueueingTimeSecurity,
    size: DashboardItemSizeVariant.Big,
    ...defaultQueueingTimeFilters,
}

const baseFireFightersItem = {
    type: DashboardItemType.FireFighters as DashboardItemType.FireFighters,
    size: DashboardItemSizeVariant.Big,
}
const baseShiftNotesItem = {
    type: DashboardItemType.ShiftNotes as DashboardItemType.ShiftNotes,
    size: DashboardItemSizeVariant.Big,
}

const baseRestrictionsItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.Restrictions as DashboardItemType.Restrictions,
}

const baseRecentTasksItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.RecentTasks as DashboardItemType.RecentTasks,
}

const baseUpcomingTasksItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.UpcomingTasks as DashboardItemType.UpcomingTasks,
}

const baseNotamItem = {
    size: DashboardItemSizeVariant.Small,
    type: DashboardItemType.NOTAM as DashboardItemType.NOTAM,
}

const defaultItemStates: Record<DashboardItemType, DashboardItemState> = {
    [DashboardItemType.BaggageDelivery]: baseBaggageDeliveryItem,
    [DashboardItemType.Cdm]: baseCdmItem,
    [DashboardItemType.Delays]: baseDelaysItem,
    [DashboardItemType.Events]: baseEventsItem,
    [DashboardItemType.Flights]: baseFlightsItem,
    [DashboardItemType.NewsFeed]: baseNewsFeedItem,
    [DashboardItemType.Pax]: basePaxItem,
    [DashboardItemType.FireFighters]: baseFireFightersItem,
    [DashboardItemType.ShiftNotes]: baseShiftNotesItem,
    [DashboardItemType.Punctuality]: basePunctualityItem,
    [DashboardItemType.Runways]: baseRunwaysItem,
    [DashboardItemType.Weather]: baseWeatherItem,
    [DashboardItemType.WeatherForecast]: forecastWeatherItem,
    [DashboardItemType.PaxForecast]: basePaxForecastItem,
    [DashboardItemType.Regularity]: baseRegularityItem,
    [DashboardItemType.OperationalForecast]: baseOperationalForecastItem,
    [DashboardItemType.QueueingTimeBorder]: baseQueueingTimeBorderItem,
    [DashboardItemType.QueueingTimeSecurity]: baseQueueingTimeSecurityItem,
    [DashboardItemType.Restrictions]: baseRestrictionsItem,
    [DashboardItemType.RecentTasks]: baseRecentTasksItem,
    [DashboardItemType.UpcomingTasks]: baseUpcomingTasksItem,
    [DashboardItemType.FOD]: {} as DashboardItemState, // TODO: replace with actual item state
    [DashboardItemType.NOTAM]: baseNotamItem,
}

export const defaultItemSizes = {
    [DashboardItemType.Punctuality]: {
        lg: { w: 3, h: 3 },
        md: { w: 4, h: 3 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.BaggageDelivery]: {
        lg: { w: 6, h: 4 },
        md: { w: 8, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.Regularity]: {
        lg: { w: 6, h: 4 },
        md: { w: 8, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.Events]: {
        lg: { w: 3, h: 5 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.NewsFeed]: {
        lg: { w: 3, h: 8 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.Pax]: {
        lg: { w: 5, h: 10 },
        md: { w: 8, h: 8 },
        sm: { w: 4, h: 9 },
    },
    [DashboardItemType.Runways]: {
        lg: { w: 3, h: 4 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.Flights]: {
        lg: { w: 3, h: 3 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.Delays]: {
        lg: { w: 3, h: 3 },
        md: { w: 4, h: 3 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.Cdm]: {
        lg: { w: 9, h: 4 },
        md: { w: 8, h: 6 },
        sm: { w: 4, h: 7 },
    },
    [DashboardItemType.Weather]: {
        lg: { w: 6, h: 3 },
        md: { w: 8, h: 3 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.WeatherForecast]: {
        lg: { w: 6, h: 3 },
        md: { w: 8, h: 3 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.PaxForecast]: {
        lg: { w: 8, h: 7 },
        md: { w: 8, h: 7 },
        sm: { w: 4, h: 7 },
    },
    [DashboardItemType.OperationalForecast]: {
        lg: { w: 3, h: 3 },
        md: { w: 4, h: 3 },
        sm: { w: 4, h: 3 },
    },
    [DashboardItemType.QueueingTimeBorder]: {
        lg: { w: 6, h: 7 },
        md: { w: 8, h: 7 },
        sm: { w: 4, h: 7 },
    },
    [DashboardItemType.QueueingTimeSecurity]: {
        lg: { w: 6, h: 7 },
        md: { w: 8, h: 7 },
        sm: { w: 4, h: 7 },
    },
    [DashboardItemType.FireFighters]: {
        lg: { w: 6, h: 5 },
        md: { w: 8, h: 5 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.ShiftNotes]: {
        lg: { w: 6, h: 4 },
        md: { w: 8, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.Restrictions]: {
        lg: { w: 3, h: 5 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 4 },
    },
    [DashboardItemType.RecentTasks]: {
        lg: { w: 6, h: 5 },
        md: { w: 5, h: 5 },
        sm: { w: 4, h: 5 },
    },
    [DashboardItemType.UpcomingTasks]: {
        lg: { w: 6, h: 5 },
        md: { w: 5, h: 5 },
        sm: { w: 4, h: 5 },
    },
    [DashboardItemType.NOTAM]: {
        lg: { w: 3, h: 5 },
        md: { w: 4, h: 4 },
        sm: { w: 4, h: 4 },
    },
}

export const addDefaultItem = (
    items: DashboardItem<DashboardItemState>[],
    itemType: DashboardItemType,
): DashboardItem<DashboardItemState>[] => addItem(items, { state: defaultItemStates[itemType] })

const getItemSize = (item: PresetItemDeclaration, breakpoint: Breakpoint): Size => {
    const size = typeof item.size === 'function' ? item.size(breakpoint) : item.size
    return Object.assign({}, defaultItemSizes[item.state.type][breakpoint], size)
}

interface PresetItemDeclaration {
    state: DashboardItemState
    size?: Partial<Size> | f.Func1<Breakpoint, Partial<Size> | undefined>
}

type PresetDeclaration = PresetItemDeclaration[]

const addItem = (
    items: DashboardItem<DashboardItemState>[],
    item: PresetItemDeclaration,
): DashboardItem<DashboardItemState>[] => [
    ...items,
    {
        id: id(),
        state: item.state,
        layouts: enumRecord(Breakpoint, breakpoint => {
            const size = getItemSize(item, breakpoint)
            return {
                ...size,
                ...positionInGrid(
                    items.map(i => i.layouts[breakpoint]),
                    dashboardGridColumns[breakpoint],
                    size,
                ),
            }
        }),
    },
]

const weatherPreset: PresetDeclaration = [
    { state: forecastWeatherItem, size: { w: 12, h: 4 } },
    { state: baseWeatherItem },
    { state: { ...baseWeatherItem, timePoint: WeatherForecastTimePoint.Plus24 } },
    {
        state: {
            ...baseWeatherItem,
            size: DashboardItemSizeVariant.Big,
            timeRange: WeatherTimeRange.Range32,
        },
        size: { h: 7 },
    },
    {
        state: {
            ...baseWeatherItem,
            size: DashboardItemSizeVariant.Big,
            group: WeatherGroup.WindAndClouds,
            timeRange: WeatherTimeRange.Range32,
        },
        size: { h: 7 },
    },
]

const passengerPreset: PresetDeclaration = [
    { state: baseQueueingTimeSecurityItem, size: { w: 6, h: 7 } },
    { state: baseQueueingTimeBorderItem, size: { w: 6, h: 7 } },
    { state: basePaxForecastItem, size: { w: 9, h: 8 } },
    { state: basePaxItem, size: { w: 9 } },
]
const fireFightersPreset: PresetDeclaration = [{ state: baseFireFightersItem, size: { w: 12 } }]
const shiftNotesPreset: PresetDeclaration = [{ state: baseFireFightersItem, size: { w: 12 } }]

const baggagePreset: PresetDeclaration = [
    {
        state: {
            ...baseBaggageDeliveryItem,
            size: DashboardItemSizeVariant.Big,
            fullViewTab: BaggageDeliveryFullViewTab.Flights,
        },
        size: { h: 8 },
    },
    ...EnumValues.getValues<AosHandlingAgent>(AosHandlingAgent)
        .filter(a => a !== AosHandlingAgent.Unknown)
        .map(handlingAgent => ({ state: { ...baseBaggageDeliveryItem, handlingAgent } })),
]

const operationsPreset: PresetDeclaration = [
    {
        state: baseOperationalForecastItem,
        size: breakpoint => ({
            w: selectBreakpoint(breakpoint, { lg: 3, md: 4, sm: 9 }),
            h: selectBreakpoint(breakpoint, { lg: 6, md: 6, sm: 4 }),
        }),
    },
    {
        state: { ...baseFlightsItem, size: DashboardItemSizeVariant.Big },
        size: breakpoint => ({ w: selectBreakpoint(breakpoint, { lg: 6, md: 9, sm: 9 }), h: 6 }),
    },
    {
        state: baseRunwaysItem,
        size: breakpoint => ({
            w: selectBreakpoint(breakpoint, { lg: 3, md: 4, sm: 9 }),
            h: selectBreakpoint(breakpoint, { lg: 6, md: 6, sm: 4 }),
        }),
    },
    {
        state: { ...baseDelaysItem, size: DashboardItemSizeVariant.Big },
        size: breakpoint => ({ w: selectBreakpoint(breakpoint, { lg: 4, md: 4, sm: 4 }), h: 6 }),
    },
    { state: { ...basePunctualityItem, size: DashboardItemSizeVariant.Big }, size: { w: 4, h: 6 } },
    {
        state: { ...baseRegularityItem, size: DashboardItemSizeVariant.Big },
        size: breakpoint => ({ w: selectBreakpoint(breakpoint, { lg: 4, md: 9, sm: 9 }), h: 6 }),
    },
    {
        state: baseCdmItem,
        size: breakpoint => ({
            w: selectBreakpoint(breakpoint, { lg: 9, md: 9, sm: 9 }),
            h: selectBreakpoint(breakpoint, { lg: 12, md: 12, sm: 12 }),
        }),
    },
]

const eventsPreset: PresetDeclaration = [
    { state: baseEventsItem, size: { w: 4, h: 10 } },
    { state: baseNewsFeedItem, size: { w: 4, h: 10 } },
]

const recentTasksPreset: PresetDeclaration = [{ state: baseRecentTasksItem, size: { w: 12 } }]

const createPreset = (items: PresetDeclaration): DashboardItem<DashboardItemState>[] =>
    items.reduce((acc: DashboardItem<DashboardItemState>[], item) => addItem(acc, item), [])

export const defaultPresets: DashboardPresets = {
    [DashboardProcessType.Weather]: createPreset(weatherPreset),
    [DashboardProcessType.Passengers]: createPreset(passengerPreset),
    [DashboardProcessType.Baggage]: createPreset(baggagePreset),
    [DashboardProcessType.Operations]: createPreset(operationsPreset),
    [DashboardProcessType.Events]: createPreset(eventsPreset),
    [DashboardProcessType.FireFighters]: createPreset(fireFightersPreset),
    [DashboardProcessType.ShiftNotes]: createPreset(shiftNotesPreset),
    [DashboardProcessType.Tasks]: createPreset(recentTasksPreset),
    [MyDashboardTab.MyDashboard]: createPreset([]),
}

export const dashboardItemGroups: Record<DashboardProcessType, DashboardItemType[]> = {
    [DashboardProcessType.Weather]: [DashboardItemType.Weather, DashboardItemType.WeatherForecast],
    [DashboardProcessType.FireFighters]: [DashboardItemType.FireFighters],
    [DashboardProcessType.ShiftNotes]: [DashboardItemType.ShiftNotes],

    [DashboardProcessType.Passengers]: [
        DashboardItemType.Pax,
        DashboardItemType.PaxForecast,
        DashboardItemType.QueueingTimeSecurity,
        DashboardItemType.QueueingTimeBorder,
    ],
    [DashboardProcessType.Baggage]: [DashboardItemType.BaggageDelivery],
    [DashboardProcessType.Operations]: [
        DashboardItemType.OperationalForecast,
        DashboardItemType.Flights,
        DashboardItemType.Regularity,
        DashboardItemType.Punctuality,
        DashboardItemType.Delays,
        DashboardItemType.Runways,
        DashboardItemType.Cdm,
    ],
    [DashboardProcessType.Events]: [
        DashboardItemType.Events,
        DashboardItemType.NewsFeed,
        DashboardItemType.NOTAM,
    ],
    [DashboardProcessType.Tasks]: [DashboardItemType.UpcomingTasks, DashboardItemType.RecentTasks],
}
