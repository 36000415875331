import {
    AosUnitTaskItemDTO,
    AosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    createUnitTaskItemAction,
    hideCreateUnitTaskItemModalAction,
} from '../../../../core/checklistManager/unit/actions'
import { State } from '../../../../core/state'
import { UnitTaskItemModal } from './UnitTaskItemModal'

export const AddUnitTaskItemModal = () => {
    const dispatch = useDispatch()
    const lockSeverityChange = useSelector(
        (state: State) => state.checklistManager.unitTaskManager.lockSeverityChange,
    )
    const currentTemplate = useSelector(
        (state: State) => state.checklistManager.selectedTemplate as AosUnitTaskTemplate,
    )
    const onSubmit = (item: AosUnitTaskItemDTO) => {
        dispatch(createUnitTaskItemAction(item))
    }
    const isOpen = useSelector(
        (state: State) => state.checklistManager.unitTaskManager.isCreateUnitTaskItemModalOpen,
    )

    return (
        <UnitTaskItemModal
            lockSeverityChange={lockSeverityChange}
            item={
                {
                    severity: lockSeverityChange ? currentTemplate?.severity : undefined,
                } as AosUnitTaskItemDTO
            }
            isOpen={isOpen}
            keyPrefix='create-tasklist-template'
            onHide={() => dispatch(hideCreateUnitTaskItemModalAction())}
            onSubmit={onSubmit}
        />
    )
}
