import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { AosAirport } from './AosAirport'
import { FlightPrmGroup } from './AosPublicRemark'
import { Flight } from './Flight'
import { FilterOption } from './FlightInfoFilters'

export const getAirportName = (airport: AosAirport | FilterOption) =>
    airport === FilterOption.All
        ? translate('airports.all')
        : translateEnum<AosAirport>(AosAirport, 'airports')(airport as AosAirport)

export const getAirportNameWithCode = (airport: AosAirport | FilterOption) =>
    airport === FilterOption.All
        ? translate('airports.all')
        : `${getAirportName(airport)} (${airport})`

export const getAirportFilterOptions = () => [FilterOption.All, ...EnumValues.getValues(AosAirport)]

export const getAirportSettingOptions = () => [...EnumValues.getValues(AosAirport)]

const prmToLabelAndField: Partial<
    Record<FlightPrmGroup, { label: string; flightTimeField: keyof Flight }>
> = {
    [FlightPrmGroup.Departed]: {
        label: translate('flight-information.prm.departed'),
        flightTimeField: 'departureTime',
    },
    [FlightPrmGroup.Estimated]: {
        label: translate('flight-information.prm.estimated'),
        flightTimeField: 'pest',
    },
    [FlightPrmGroup.NextInfo]: {
        label: translate('flight-information.prm.next-info'),
        flightTimeField: 'pest',
    },
    [FlightPrmGroup.Landed]: {
        label: translate('flight-information.prm.landed'),
        flightTimeField: 'est',
    },
}

export const getPrmDateText = (flight: Flight): string => {
    const prm = flight.prm
    const labelAndTime = prmToLabelAndField[flight.prm.group]

    if (labelAndTime?.flightTimeField && flight[labelAndTime.flightTimeField]) {
        const { label, flightTimeField } = labelAndTime
        const date = formatTime(flight[flightTimeField] as DateTime)

        if (date) {
            return `${label} ${date}`
        }
    }

    return prm.text
}
