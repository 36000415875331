import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import { format } from 'url'

import { BaseRestService } from '../../dataaccess/base/BaseRestService'
import { AosAirport } from '../flightInformation/types/AosAirport'

class FodService extends BaseRestService {
    getFodList = async (
        siteLocation: AosAirport,
        pageNumber: number = 0,
        pageSize: number = 10,
    ): Promise<Pageable<FodDto>> => {
        return this.getAndTransform<Pageable<FodDto>>(
            format({
                pathname: '/fod',
                query: { siteLocation: siteLocation, pageSize, pageNumber },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    add = async (data: FodDto) => {
        return this.postJsonAndTransform<FodDto>(
            format({
                pathname: '/fod',
            }),
            data,
            BaseRestService.jsonTransformer,
        )
    }

    deleteFod = async (id: number) => {
        return this.deleteReq(
            format({
                pathname: `/fod/${id}`,
            }),
        )
    }

    edit = async (id: number, data: FodDto) => {
        return this.patchJson(
            format({
                pathname: `/fod/${id}`,
            }),
            data,
        )
    }
}

export const fodService = new FodService()
