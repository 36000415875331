import { Chart } from 'aos-components/src/components/chart/Chart'
import { LinearStackBarSeries } from 'aos-components/src/components/chart/series/LinearStackBarSeries'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatHour } from 'aos-helpers/src/helpers/TimeFormat'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { baggageDeliveryChartColors } from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryConstants'
import { baggageDeliveryLegendConfig } from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryLegendConfig'
import { BaggageDeliveryHourlyStat } from 'aos-services/src/services/airportStatus/baggage/types/BaggageDeliveryStat'
import { BaggageStatSection } from 'aos-services/src/services/airportStatus/baggage/types/BaggageStatSection'
import {
    isTimeInTimeRangeFilter,
    TimeRangeFilter,
} from 'aos-services/src/services/airportStatus/base/types/TimeRangeFilter'
import { VerticalLegend } from 'aos-ui/src/components/chart/VerticalLegend'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { LinearStackBarSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { Color } from 'aos-ui-common/src/styles/Color'
import { format } from 'd3-format'
import { EnumValues } from 'enum-values'
import { values } from 'lodash'
import React, { PureComponent } from 'react'

import { BaggageDeliveryFullChartTooltip } from './BaggageDeliveryFullChartTooltip'

interface BaggageDeliveryByTimeChartProps {
    data: BaggageDeliveryHourlyStat[]
    timeRange: TimeRangeFilter
    xDomain: Domain<DateTime>
    lineDomain: Domain<number>
    barDomain: Domain<number>
}

export class BaggageDeliveryByTimeChart extends PureComponent<BaggageDeliveryByTimeChartProps> {
    private barSeriesConfig: LinearStackBarSeriesConfig<BaggageDeliveryHourlyStat> = {
        colors: values(baggageDeliveryChartColors),
        tooltip: BaggageDeliveryFullChartTooltip,
    }

    public render() {
        const { timeRange, xDomain, lineDomain, barDomain } = this.props
        return (
            <Chart
                leftLegend={{
                    width: 45,
                    component: this.renderLeftLegend(),
                }}
                rightLegend={{
                    width: 45,
                    component: this.renderRightLegend(),
                }}
                xConfig={{
                    domain: xDomain,
                    tickFormat: formatHour,
                    tickMarked: isTimeInTimeRangeFilter(timeRange),
                }}
                y1Config={{
                    domain: lineDomain,
                    showGrids: false,
                    showAxisLine: false,
                    baseValues: [0],
                    unit: Unit.Minute,
                    tickFormat: format('d'),
                }}
                y2Config={{
                    domain: barDomain,
                    baseValues: [0],
                    unit: Unit.Percent,
                }}
            >
                {this.renderContent}
            </Chart>
        )
    }

    private renderRightLegend = () => (
        <VerticalLegend items={values(baggageDeliveryLegendConfig).reverse()} />
    )

    private renderLeftLegend = () => (
        <VerticalLegend items={[{ label: 'Time', labelColor: Color.White }]} />
    )

    private renderContent = (props: ChartChildrenProps) => {
        const { xScale, y1Scale, y2Scale } = props
        if (!xScale || !y1Scale || !y2Scale) {
            return null
        }
        return (
            <>
                <LinearStackBarSeries
                    data={this.props.data}
                    seriesConfig={this.barSeriesConfig}
                    accessors={{
                        xAccessor: this.xAccessor,
                        yAccessorKeys: EnumValues.getValues(BaggageStatSection),
                        yAccessor: (stat: BaggageDeliveryHourlyStat, key: BaggageStatSection) =>
                            stat.distribution[key].percentage,
                    }}
                    scales={{
                        xScale,
                        yScale: y2Scale,
                    }}
                    minYValue={0}
                />
                <LineSeries
                    data={this.props.data}
                    seriesConfig={{
                        color: Color.White,
                        withDots: true,
                        tooltip: BaggageDeliveryFullChartTooltip,
                    }}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: this.getMeanDeliveryTime }}
                    scales={{
                        xScale,
                        yScale: y1Scale,
                    }}
                />
            </>
        )
    }

    private getMeanDeliveryTime = (v: BaggageDeliveryHourlyStat) => v.meanDeliveryTime.asMinutes()

    private xAccessor = (d: BaggageDeliveryHourlyStat) => d.time
}
