import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskLog } from 'aos-services/src/services/tasks/types/TaskLog'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { OkButton } from 'aos-ui/src/components/buttons/OkButton'
import { CleanableTextarea } from 'aos-ui/src/components/form/input/CleanableTextarea'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { ScrollPosition } from 'aos-ui/src/components/scrollbar/Scrollbar'
import React, { FC, useState } from 'react'

import { TaskLogBubble } from './TaskLogBubble'

interface TaskDetailsMessagesProps {
    logs: TaskLog[]
    isSendingMessage: boolean
    onMessage(message: string): void
}

export const TaskDetailsMessages: FC<TaskDetailsMessagesProps> = ({
    logs,
    onMessage,
    isSendingMessage,
}) => {
    const [message, setMessage] = useState('')

    const send = () => {
        onMessage(message)
        setMessage('')
    }

    return (
        <Box fullHeight column>
            <Box flex={1}>
                <DarkScrollbar
                    scrollOnChecksumUpdate={ScrollPosition.bottom}
                    startPosition={ScrollPosition.bottom}
                    checksum={logs.length}
                >
                    <Box padding={30}>
                        {logs.map(log => (
                            <TaskLogBubble key={log.id} log={log} />
                        ))}
                    </Box>
                </DarkScrollbar>
            </Box>
            <Box paddingHorizontal={30} paddingBottom={30}>
                <CleanableTextarea
                    resizeable
                    rows={4}
                    variant={ThemeVariant.Black}
                    placeholder={translate('tasks.messages.placeholder')}
                    value={message}
                    onChangeText={setMessage}
                />
                <Box paddingTop={16}>
                    <OkButton
                        label={translate('tasks.messages.send')}
                        onClick={send}
                        isLoading={isSendingMessage}
                        disabled={!message.length}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
    )
}
