import {
    WhiteModalContent,
    WhiteModalContentVariant,
} from 'aos-components/src/components/modal/WhiteModal/WhiteModalContent'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

export class UserFound extends PureComponent<UserFoundProps> {
    public render() {
        return (
            <WhiteModalContent
                title={this.props.email}
                variant={WhiteModalContentVariant.Blue}
                svg={SvgIcon.Warning}
                buttons={this.getButtons()}
                description={translate('manage-user.ldap-user-found')}
            />
        )
    }

    private getButtons = () => [
        <FormButton
            key='1'
            label={translate('manage-user.ldap-user-found.create-user')}
            onClick={this.props.addAdUser}
        />,
        <GreyTextButton key='2' label={translate('manage-user.back')} onClick={this.props.back} />,
    ]
}

interface UserFoundProps {
    email: string
    back(): void
    addAdUser(): void
}
