import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    punctualitySelector,
    PunctualitySelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/punctuality'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { CommonFlightView } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { PunctualityFilters } from 'aos-services/src/services/statusDashboard/types/filters/PunctualityFilters'
import { LabeledMultiProgressBar } from 'aos-ui/src/components/bar/LabeledMultiProgressBar'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setPunctualityTabStateAction } from '../../../../core/statusDashboard/actions'
import { PunctualityItemState } from '../../../../services/statusDashboard/types/itemStates/PunctualityItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { FlightChartStatsWrapper } from '../base/FlightChartStatsWrapper'
import { FlightFooterFilters } from '../base/FlightFooterFilters'
import { InvertedPercentageFlightChart } from '../base/InvertedPercentageFlightChart'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PunctualityItem } from './partials/PunctualityItem'
import { PunctualityStatsSection } from './partials/PunctualityStatsSection'
import { punctualityFilterConfigs } from './punctualityFilterConfig'
import { PunctualityFlights } from './PunctualityFlights'
import { PunctualityTooltip } from './PunctualityTooltip'

class PunctualityClass extends PureComponent<PunctualityProps> {
    public render() {
        const { itemState } = this.props
        const isSmall = itemState.size === DashboardItemSizeVariant.Small
        return (
            <PunctualityItem
                noDataWarning={this.props.noDataWarning}
                itemProps={pickDashboardItemProps(this.props)}
                syncId={this.props.id}
            >
                {isSmall ? this.renderSmallVariant() : this.renderBigVariant()}
                <FlightFooterFilters
                    timeRange={itemState.timeRange}
                    airline={itemState.airline}
                    handlingAgent={itemState.handlingAgent}
                    flightType={itemState.flightType}
                    fullViewTab={itemState.fullViewTab}
                    setFilterAction={this.handleSetFilter}
                    isSmall={isSmall}
                    tabConfiguration={punctualityFilterConfigs}
                />
            </PunctualityItem>
        )
    }
    private renderSmallVariant() {
        const { itemState } = this.props
        return (
            <ItemContainer verticalSpacing horizontalSpacing autoHeight horizontal>
                {this.renderStatsSection(itemState.flightType)}
            </ItemContainer>
        )
    }
    private renderBigVariant() {
        const { flights, itemState } = this.props
        return (
            <FlightChartStatsWrapper
                chart={this.renderChart()}
                statsContent={this.renderStatsContent()}
                flightTable={() => <PunctualityFlights flights={flights} />}
                flightTableTitle={translate('dashboard.punctuality.flight-table-header')}
                showChart={itemState.fullViewTab === CommonFlightView.History}
            />
        )
    }

    private renderStatsContent() {
        return (
            <Box row justify='space-between' alignItems='flex-end'>
                <Box marginRight={20} flex='auto' columnReverse>
                    {this.renderStatsSection(FlightsType.All)}
                </Box>
                {this.renderStatsByTypeSection()}
            </Box>
        )
    }
    private renderChart() {
        const { series, yDomain, xDomain, itemState } = this.props
        return (
            <InvertedPercentageFlightChart
                flights={series}
                yDomain={yDomain}
                xDomain={xDomain}
                tooltip={p => <PunctualityTooltip point={p} />}
                color={Color.Magenta}
                timeRangeFilter={itemState.timeRange}
            />
        )
    }

    private renderStatsSection(flightType: FlightsType) {
        const { stats } = this.props
        return <PunctualityStatsSection stats={stats[flightType]} />
    }

    private renderStatsByTypeSection() {
        const { stats } = this.props
        const barData = [
            {
                value: Math.round(stats[FlightsType.Arrivals].value || 0),
                label: translate('dashboard.punctuality.stats.arrivals'),
                color: Color.PaleOrange,
            },
            {
                value: Math.round(stats[FlightsType.Departures].value || 0),
                label: translate('dashboard.punctuality.stats.departures'),
                color: Color.Violet,
            },
        ]

        return (
            <LabeledMultiProgressBar
                flex='auto'
                marginLeft={16}
                title={translate('dashboard.punctuality.stats.flight-type')}
                minValue={this.props.yDomain.domain[0]}
                barData={barData}
            />
        )
    }

    private handleSetFilter = (f: Partial<PunctualityFilters>) => {
        this.props.setPunctualityTabStateAction([this.props.id, f])
    }
}

interface PunctualityDispatchProps {
    setPunctualityTabStateAction: typeof setPunctualityTabStateAction
}

type PunctualityOwnProps = StatusDashboardItemCommonProps<PunctualityItemState>

interface PunctualityProps
    extends PunctualitySelectorState,
        PunctualityOwnProps,
        PunctualityDispatchProps {}

export const Punctuality = connect<
    PunctualitySelectorState,
    PunctualityDispatchProps,
    PunctualityOwnProps
>(punctualitySelector, {
    setPunctualityTabStateAction,
})(PunctualityClass)
