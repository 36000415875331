import { dateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosFeedInDto } from '../../../dataaccess/feeds/types/AosFeedInDto'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosEventProcessType } from './AosEventProcessType'
import { AosEventScenario } from './AosEventScenario'
import { AosFeedIn } from './AosFeedIn'
import { AosFeedInStatus } from './AosFeedInStatus'

export class AosFeedInImpl extends WrapperObject<AosFeedInDto> implements AosFeedIn {
    constructor(value: AosFeedInDto) {
        super(value)
    }

    public get id() {
        return this.value.id!
    }

    public get startTime() {
        return dateTimeFromOptional(this.value.startTime)
    }

    public get endTime() {
        return dateTimeFromOptional(this.value.endTime)
    }

    public get process() {
        return this.value.process as AosEventProcessType
    }

    public get title() {
        return this.value.title
    }

    public get description() {
        return this.value.description
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get category() {
        return this.value.category as AosEventScenario
    }

    public get status() {
        return this.value.status as AosFeedInStatus
    }
    public get dismissed() {
        return this.status === AosFeedInStatus.Dismissed
    }

    public get createdAt() {
        return dateTime(this.value.createdAt)
    }

    public get createdBy() {
        return this.value.createdBy
    }

    public get attachments() {
        return this.value.attachments || []
    }

    public get allAttachments() {
        return this.attachments
    }

    public get hasLocation() {
        return !!this.value.location
    }

    public get location() {
        return this.value.location as AosLocation
    }

    public get presentationTime() {
        return this.createdAt
    }

    public get overdue(): boolean {
        return false
    }

    public get atcAlert(): boolean {
        return this.value.atcAlert || false
    }

    public get exercise() {
        return this.value.exercise
    }
}
