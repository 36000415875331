import {
    SelectedMapElement,
    SelectedMapElementType,
    selectionTargetKey,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import { chain, flatten, isEqual, omit } from 'lodash'
import { click } from 'ol/events/condition'
import Feature from 'ol/Feature'
import { Geometry } from 'ol/geom'
import Select, { SelectEvent } from 'ol/interaction/Select'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

import { BaseComponent } from '../base/BaseComponent'
import { layerIdKey } from '../OpenlayersMapContext'

export class SelectControl extends BaseComponent<SelectControlProps> {
    private interaction: Select
    private layers: VectorLayer<VectorSource>[] = []

    constructor(props: SelectControlProps) {
        super(props)
        // @ts-ignore Typings should allow null style: https://github.com/openlayers/openlayers/pull/10138
        this.interaction = new Select({ style: null })
        this.interaction.on('select', this.selectFeatures)
        this.state = {}
    }

    public componentDidMount() {
        this.layers = flatten(
            this.props.layerIds.map(i => this.context.getLayersById<VectorLayer<VectorSource>>(i)),
        )
        this.interaction = new Select({
            condition: click,
            filter: (_feature, layer) => {
                const layerId = layer.get(layerIdKey)
                return layerId && this.props.layerIds.includes(layerId)
            },
            // @ts-ignore Typings should allow null style: https://github.com/openlayers/openlayers/pull/10138
            style: null,
        })
        this.interaction.on('select', this.selectFeatures)
        this.registerHandler(this.props.isActive)
        this.updateSelection(this.props.selectedElement)
    }

    public componentWillReceiveProps(next: SelectControlProps) {
        if (next.isActive !== this.props.isActive) {
            this.registerHandler(next.isActive)
        }
        this.updateSelection(next.selectedElement)
    }

    private registerHandler = (isActive: boolean) => {
        if (isActive) {
            this.context.map.addInteraction(this.interaction)
        } else {
            this.context.map.removeInteraction(this.interaction)
        }
    }

    private selectFeatures = (event: SelectEvent) => {
        const { selected, deselected } = event

        selected.forEach(this.selectFeature)
        deselected.forEach(this.deselectFeature)

        if (selected.length === 0) {
            if (this.props.onResetSelection) {
                this.props.onResetSelection()
            }
        }
    }

    private selectFeature = (f: Feature) => {
        const config = this.getSelectConfigForFeature(f)
        if (config) {
            config.onSelectStyleHandler(f)

            if (this.props.onSelect) {
                this.props.onSelect({
                    type: config.target,
                    payload: this.propertiesToPayload(f.getProperties()),
                } as SelectedMapElement)
            }
        }
    }

    private deselectFeature = (f: Feature) => {
        const config = this.getSelectConfigForFeature(f)
        if (config) {
            // Applying selected style is asynchronous, so deselect might be called when the selected style is not yet present.
            // In such case, there is nothing to remove, and we don't want to remove base style at 0
            config.onDeselectStyleHandler(f)
        }
    }

    private getSelectConfigForFeature = (f: Feature) => {
        const selectTarget = f.get(selectionTargetKey)
        if (selectTarget) {
            return this.props.selectConfigs.find(c => c.target === selectTarget)
        }
        return undefined
    }

    private updateSelection = (selectedElement?: SelectedMapElement) => {
        const features = this.interaction.getFeatures()
        if (!this.isSelectedElementEqualFeature(selectedElement, features.getArray()[0])) {
            features.forEach(this.deselectFeature)
            features.clear()
            if (selectedElement) {
                const feature = this.findFeature(selectedElement)
                if (feature) {
                    this.interaction.getFeatures().push(feature)
                    this.selectFeature(feature)
                }
            }
        }
    }

    private isSelectedElementEqualFeature = (
        selectedElement: SelectedMapElement | undefined,
        feature: Feature | undefined,
    ) => {
        const properties = selectedElement ? this.propertiesToPayload(selectedElement.payload) : {}
        const featureProperties = feature ? this.propertiesToPayload(feature.getProperties()) : {}

        return isEqual(properties, featureProperties)
    }

    private findFeature = (selectedElement: SelectedMapElement) =>
        this.findFeatures()?.find(f => this.isSelectedElementEqualFeature(selectedElement, f))

    private findFeatures = (): Feature<Geometry>[] | undefined =>
        chain(this.layers)
            .flatMap(l => l.getSource()?.getFeatures() as Feature<Geometry>[])
            .value()

    private propertiesToPayload = (properties: { [k: string]: any }) =>
        omit(properties, 'geometry', selectionTargetKey)
}

export interface SelectControlProps extends SelectableProps {
    isActive: boolean
    layerIds: string[]
    selectConfigs: SelectControlConfig[]
}

export interface SelectableProps {
    selectedElement?: SelectedMapElement

    onSelect?(v: SelectedMapElement): void

    onResetSelection?(): void
}

export interface SelectControlConfig {
    target: SelectedMapElementType
    onSelectStyleHandler: (feature: Feature) => void
    onDeselectStyleHandler: (feature: Feature) => void
}
