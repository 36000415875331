import { DateTime } from 'aos-helpers/src/helpers/Time'
import { firebaseApp } from 'firebaseApp'

export class FirebasePaxRepository {
    public paxRef = (timeStart: DateTime, timeEnd: DateTime) =>
        firebaseApp
            .database()
            .ref('kpi/pax')
            .orderByChild('time')
            .startAt(timeStart.valueOf() - 1, 'time')
            .endAt(timeEnd.valueOf(), 'time')
}

export const firebasePaxRepository = new FirebasePaxRepository()
