import { DateTime } from 'aos-helpers/src/helpers/Time'
import { chain } from 'lodash'

import { TimePoint } from '../../base/types/TimePoint'
import { FreezingPhenomenon } from './FreezingPhenomenon'
import { WeatherCategory } from './WeatherMetrics'

export interface WeatherCategoryPoint extends TimePoint {
    value: WeatherCategory
}

export interface FreezingPhenomenonPoint {
    from: DateTime
    to: DateTime
    value: FreezingPhenomenon
}

export const trimFreezingPhenomenonPointsToTimeRange = (
    points: FreezingPhenomenonPoint[],
    [from, to]: [DateTime, DateTime],
): FreezingPhenomenonPoint[] => {
    const trimPoint = (point: FreezingPhenomenonPoint): FreezingPhenomenonPoint | undefined => {
        if (point.to.isSameOrBefore(from) || point.from.isSameOrAfter(to)) {
            return undefined
        }
        const newFrom = point.from.isBefore(from) ? from : point.from
        const newTo = point.to.isAfter(to) ? to : point.to

        return {
            ...point,
            from: newFrom,
            to: newTo,
        }
    }

    return chain(points).map(trimPoint).compact().value()
}
