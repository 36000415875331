import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'

import { CheckableBlockWithDisable } from '../base/CheckableBlockWithDisable'

interface DefaultRadioRendererWithDisableProps {
    isSelected: boolean
    label: ReactNode
    color?: Color
    onSelect(): void
    disabled?: boolean
}

export const DefaultRadioRendererWithDisable: FCWithChildren<
    DefaultRadioRendererWithDisableProps
> = ({ isSelected, label, onSelect, color = Color.Primary, disabled }) => (
    <CheckableBlockWithDisable
        row
        isSelected={isSelected}
        color={color}
        cursor='pointer'
        onClick={onSelect}
        paddingHorizontal={8}
        disabled={disabled}
    >
        {label}
    </CheckableBlockWithDisable>
)
