import { getCurrentValueInRange } from '../helpers/getCurrentValueInRange'
import { getMinMax } from '../helpers/getMinMax'
import { isActive } from '../helpers/isActive'
import { GaugeOptions } from '../types/GaugeOptions'
import { GaugeRange } from '../types/GaugeRange'

export const getLabelsToShow = (range: GaugeRange, options: GaugeOptions) => {
    const value = getCurrentValueInRange(options)

    if (isActive(value, range)) {
        return { showLeft: true, showRight: true }
    }

    const { from, to } = range
    const [min, max] = getMinMax(options.ranges)

    if (from === min) {
        return { showLeft: true }
    }

    if (to === max) {
        return { showRight: true }
    }

    return null
}
