import { DateTime } from 'aos-helpers/src/helpers/Time'
import { WeatherMetric } from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { FC } from 'react'

import { Temperature } from '../compactView/metrics/Temperature'
import { WindDirection } from '../compactView/metrics/WindDirection'
import { WindGusts } from '../compactView/metrics/WindGusts'
import { WindSpeed } from '../compactView/metrics/WindSpeed'

interface WeatherCarouselProps {
    metric: WeatherMetric
    changeTrigger: DateTime
}

export const WeatherCarousel: FC<WeatherCarouselProps> = props => (
    <Carousel changeTrigger={props.changeTrigger}>
        <Temperature
            key={WeatherValueSeriesType.Temperature}
            value={props.metric[WeatherValueSeriesType.Temperature]}
        />
        <WindSpeed
            key={WeatherValueSeriesType.WindSpeed}
            value={props.metric[WeatherValueSeriesType.WindSpeed]}
            unit={WeatherUnit.Ms}
        />
        <WindDirection
            key={WeatherValueSeriesType.WindDirection}
            value={props.metric[WeatherValueSeriesType.WindDirection]}
        />
        <WindGusts
            key={WeatherValueSeriesType.MaxWindGust}
            value={props.metric[WeatherValueSeriesType.MaxWindGust]}
            unit={WeatherUnit.Ms}
        />
    </Carousel>
)
