import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskType } from 'aos-services/src/services/tasks/types/TaskType'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'

import { animalsTaskPropertyList } from './AnimalsTaskPropertyList'
import { deicingTaskPropertyList } from './DeicingTaskPropertyList'
import { inspectionTaskPropertyList } from './InspectionTaskPropertyList'
import { maintenanceTaskPropertyList } from './MaintenanceTaskPropertyList'
import { rescueServiceTaskPropertyList } from './RescueServiceTaskPropertyList'

export const detailedTaskPropertyList = (t: Task): PropsListEntry[] => {
    switch (t.type) {
        case TaskType.Simple:
            return []
        case TaskType.AnimalPrevention:
            return animalsTaskPropertyList(t)
        case TaskType.DeIcing:
            return deicingTaskPropertyList(t)
        case TaskType.Maintenance:
            return maintenanceTaskPropertyList(t)
        case TaskType.RescueService:
            return rescueServiceTaskPropertyList(t)
        case TaskType.Inspection:
            return inspectionTaskPropertyList(t)
        default:
            return []
    }
}
