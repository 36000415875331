import { some } from 'lodash'

import { AosEventDto } from '../../../dataaccess/events/types/AosEventDto'
import { isNotActivatedFeedIn } from '../../../dataaccess/feeds/types/AosFeedInDto'
import { AosEventGroup } from './AosEventGroup'
import { AosEventImpl } from './AosEventImpl'

export class AosEventGroupImpl extends AosEventImpl implements AosEventGroup {
    constructor(protected value: AosEventDto) {
        super(value)
    }

    public static eventOrEventGroupFromDto(value: AosEventDto) {
        if (value.feedIns && value.feedIns.length && some(value.feedIns, isNotActivatedFeedIn)) {
            return new AosEventGroupImpl(value)
        } else {
            return new AosEventImpl(value)
        }
    }

    public get isGroup() {
        // for isAosEvent distinguishing
        return true
    }
}
