import {
    OperationalForecastMode,
    operationalForecastModeColors,
    translateOperationalForecastMode,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { RendererProps } from 'aos-ui/src/components/form/radio/BlockRadioGroup'
import { CircleRadioRenderer } from 'aos-ui/src/components/form/radio/CircleRadioRenderer'
import React, { FC } from 'react'

export const OperationalForecastRadioRenderer: FC<RendererProps<OperationalForecastMode>> = ({
    isSelected,
    item,
    onSelect,
}) => (
    <CircleRadioRenderer
        label={translateOperationalForecastMode(item)}
        color={operationalForecastModeColors[item]}
        isSelected={isSelected}
        onSelect={onSelect}
    />
)
