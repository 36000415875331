import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'

import { BaseLayer, BaseLayerProps } from '../base/BaseLayer'

export class OSMLayer extends BaseLayer {
    constructor(props: BaseLayerProps) {
        super(
            props,
            new TileLayer({
                source: new OSM({
                    maxZoom: 19,
                }),
                preload: Infinity,
            }),
        )
    }
}
