import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FoldIcon } from 'aos-ui/src/components/header/FoldIcon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FCWithChildren, RefObject } from 'react'
import useFullscreen from 'react-use/lib/useFullscreen'
import useToggle from 'react-use/lib/useToggle'

interface FullscreenProps {
    refEl: RefObject<Element>
}

export const FullscreenButton: FCWithChildren<FullscreenProps> = props => {
    const { refEl } = props
    const [enabled, toggle] = useToggle(false)
    const isFullscreen = useFullscreen(refEl, enabled, {
        onClose: () => toggle(false),
    })

    return (
        <Tooltip body={translate('fullscreen.tooltip')}>
            <div>
                <FoldIcon isFold={isFullscreen} onToggleFold={toggle} />
            </div>
        </Tooltip>
    )
}
