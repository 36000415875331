import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { PaxForecast } from '../../../services/airportStatus/paxForecast/types/PaxForecast'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const PAX_FORECAST_SYNC = 'STATUS_DASHBOARD_DATA/PAX_FORECAST/PAX_FORECAST_SYNC'
export type PaxForecastSyncAction = PayloadAction<typeof PAX_FORECAST_SYNC, SyncDataKey>
export const paxForecastSyncAction = payloadActionCreator<PaxForecastSyncAction, SyncDataKey>(
    PAX_FORECAST_SYNC,
)

export const PAX_FORECAST_SYNC_STOP = 'STATUS_DASHBOARD_DATA/PAX_FORECAST/PAX_FORECAST_SYNC_STOP'
export type PaxForecastSyncStopAction = PayloadAction<typeof PAX_FORECAST_SYNC_STOP, SyncDataKey>
export const paxForecastSyncStopAction = payloadActionCreator<
    PaxForecastSyncStopAction,
    SyncDataKey
>(PAX_FORECAST_SYNC_STOP)

export const PAX_FORECAST_LOAD = 'STATUS_DASHBOARD_DATA/PAX_FORECAST/PAX_FORECAST_LOAD'
export type PaxForecastLoadAction = Action<typeof PAX_FORECAST_LOAD>
export const paxForecastLoadAction = emptyActionCreator<PaxForecastLoadAction>(PAX_FORECAST_LOAD)

export const PAX_FORECAST_LOAD_SUCCESS =
    'STATUS_DASHBOARD_DATA/PAX_FORECAST/PAX_FORECAST_LOAD_SUCCESS'
export type PaxForecastLoadSuccessAction = PayloadAction<
    typeof PAX_FORECAST_LOAD_SUCCESS,
    PaxForecast
>
export const paxForecastLoadSuccessAction = payloadActionCreator<
    PaxForecastLoadSuccessAction,
    PaxForecast
>(PAX_FORECAST_LOAD_SUCCESS)

export type PaxForecastAction =
    | PaxForecastSyncAction
    | PaxForecastSyncStopAction
    | PaxForecastLoadAction
    | PaxForecastLoadSuccessAction
