import { SimpleFormModalActionCreators } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { SingleInputActionCreators } from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import {
    SimpleFormModal,
    SimpleFormModalProps,
} from 'aos-components/src/components/modal/InputModal/SimpleFormModal'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { editMessageTemplateActions } from '../../../../core/checklistManager/actions'
import {
    MessageTemplateModalState,
    MessageTemplateState,
} from '../../../../core/checklistManager/editMessageTemplate/state'
import { State } from '../../../../core/state'

export class EditMessageTemplateModalClass extends PureComponent<
    MessageTemplateModalState & SimpleFormModalActionCreators & SimpleFormModalProps
> {
    public render() {
        const changeContent = (content: string) => this.changeForm({ content })
        const changeTitle = (title: string) => this.changeForm({ title })
        const currentValue = this.props.currentValue.value!
        const form = this.props.form
        return (
            <SimpleFormModal {...this.props}>
                <LabeledInput
                    isError={form.error.title}
                    value={currentValue.title}
                    onChangeText={changeTitle}
                    isRequired
                    keyPrefix={`${this.props.keyPrefix}.title`}
                    seleniumLocation='first-input'
                />
                <LabeledTextArea
                    isError={form.error.content}
                    value={currentValue.content}
                    onChangeText={changeContent}
                    isRequired
                    keyPrefix={`${this.props.keyPrefix}.content`}
                    seleniumLocation='second-input'
                />
            </SimpleFormModal>
        )
    }

    protected changeForm = (p: Partial<MessageTemplateState>) => {
        const value = { ...this.props.currentValue.value, ...p }
        this.props.changeFormValueAction({
            ...this.props.currentValue,
            value,
        })
    }
}

export const EditMessageTemplateModal = connect<
    MessageTemplateModalState,
    SingleInputActionCreators,
    SimpleFormModalProps
>(
    (state: State) => state.checklistManager.editMessageTemplateModal,
    editMessageTemplateActions,
)(EditMessageTemplateModalClass)
