import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { FodDto } from 'aos-services/src/services/fod/types'
import { BaseAosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { BaseMapTask } from 'aos-services/src/services/map/types/MapTask'
import { SelectedMapElement } from 'aos-services/src/services/map/types/SelectedMapElement'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { noop } from 'lodash'
import React, { FC } from 'react'

import { BimLayerName } from '../bim/BimLayerName'
import { LayerId } from '../LayerId'
import { AtcLayer } from '../openlayers/controls/atcLayers/AtcLayer'
import { LocationPicker } from '../openlayers/controls/locationPicker/LocationPicker'
import { MobileLocationPicker } from '../openlayers/controls/locationPicker/MobileLocationPicker'
import { MapElementHoverOverlay } from '../openlayers/controls/MapElementHoverOverlay'
import { NavigationControls } from '../openlayers/controls/NavigationControls'
import { SelectBottomSheet } from '../openlayers/controls/SelectBottomSheet'
import { SelectControl } from '../openlayers/controls/SelectControl'
import { EventLayer } from '../openlayers/layers/EventLayer'
import { FodLayer } from '../openlayers/layers/FodLayer'
import { TasksLayer } from '../openlayers/layers/TasksLayer'
import { airportMapSelectConfigs } from '../styleBuilder/selectControlStyleBuilder'

interface CommonMapElementsProps {
    mode: MapMode
    variant: MapVariant
    events: BaseAosMapEvent[]
    tasks: BaseMapTask[]
    fods: FodDto[]
    eventsVisible: boolean
    tasksVisible: boolean
    isMobile: boolean
    atcLayerControl?: boolean
    selectedElement?: SelectedMapElement
    userLayerVisibility: UserLayerVisibilityState
    isApoc: boolean
    onFeatureSelect?(v: SelectedMapElement): void
    onFeatureReset?(): void
    onSelectLocation?(v: AosLocation): void
    onOpenFeature?(v: SelectedMapElement): void
    onSelectedLayer?(layer: BimLayerName): void
}

export const availableFloors = [0, 1, 2, 3]

export const CommonMapElements: FC<CommonMapElementsProps> = ({
    mode,
    events,
    eventsVisible,
    tasks,
    fods,
    tasksVisible,
    isMobile,
    selectedElement,
    variant,
    onFeatureSelect = noop,
    onFeatureReset = noop,
    onSelectLocation = noop,
    onOpenFeature = noop,
    atcLayerControl,
    onSelectedLayer,
    userLayerVisibility,
    isApoc,
}) => {
    const selectLocation = (location: LonLat) => {
        onSelectLocation({
            ...location,
            locationSource: variant,
        })
    }

    const locationPickerActive = mode === MapMode.EditSingleLocation
    const eventHandlerActive = mode === MapMode.Standard
    const hoverHandlerActive = mode === MapMode.Standard
    const bottomSheetActive = mode === MapMode.Standard

    const layersName = [
        BimLayerName.Gates,
        BimLayerName.BusGates,
        BimLayerName.Stands,
        BimLayerName.BusDoors,
    ]

    let layerIds: string[] = []

    availableFloors.map(floor => {
        layersName.map(layerName => {
            layerIds.push(layerName + floor)
        })
    })

    layerIds.push(LayerId.Events)
    layerIds.push(LayerId.Tasks)
    layerIds.push(LayerId.Trains)
    layerIds.push(LayerId.Fods)

    return (
        <>
            <AtcLayer
                isVisible={atcLayerControl}
                isApoc={isApoc}
                userLayerVisibility={userLayerVisibility}
                onSelectedLayer={onSelectedLayer}
            />
            <NavigationControls />
            <EventLayer
                zIndex={101}
                events={events}
                layerId={LayerId.Events}
                isVisible={eventsVisible}
            />
            <TasksLayer
                zIndex={102}
                tasks={tasks}
                layerId={LayerId.Tasks}
                isVisible={tasksVisible}
            />
            <FodLayer zIndex={103} fods={fods} layerId={LayerId.Fods} isVisible={fods.length > 0} />
            {isMobile ? (
                <>
                    <MobileLocationPicker isActive={locationPickerActive} />
                    <SelectBottomSheet
                        isActive={bottomSheetActive}
                        openFeature={onOpenFeature}
                        onSelect={onFeatureSelect}
                        onResetSelection={onFeatureReset}
                        selectedElement={selectedElement}
                    />
                </>
            ) : (
                <>
                    <LocationPicker isActive={locationPickerActive} onPick={selectLocation} />
                    <MapElementHoverOverlay isActive={hoverHandlerActive} />
                    <SelectControl
                        isActive={eventHandlerActive}
                        layerIds={layerIds}
                        selectConfigs={airportMapSelectConfigs}
                        onSelect={onFeatureSelect}
                        onResetSelection={onFeatureReset}
                        selectedElement={selectedElement}
                    />
                </>
            )}
        </>
    )
}
