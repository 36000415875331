import {
    AirlineTobtList,
    HourTobtItemUpdated,
    HourTobtList,
    HourTobtListUpdated,
    TobtKeyTypes,
    TobtTableData,
    TobtTimeRange,
} from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import { TobtDataTimeRangeType } from 'aos-services/src/services/statusDashboard/types/filters/TobtFilters'
import { round } from 'lodash'

import { dateTime } from '../Time'

export const keyConverterTobt = (key: string) => {
    switch (key) {
        case 'Before':
            return TobtTimeRange.before
        case 'Late':
            return TobtTimeRange.late
        case 'Later':
            return TobtTimeRange.later
        case 'Suspended':
            return TobtTimeRange.suspended
        case '0-5 min':
            return TobtTimeRange.min0_5
        case '6-10 min':
            return TobtTimeRange.min6_10
        case '11-20 min':
            return TobtTimeRange.min11_20
        case '21-30 min':
            return TobtTimeRange.min21_30
        case '31-40 min':
            return TobtTimeRange.min31_40
        case '41-50 min':
            return TobtTimeRange.min41_50
        case '51-60 min':
            return TobtTimeRange.min51_60
        case '> 60 min':
            return TobtTimeRange.min60
        default:
            return TobtTimeRange.later
    }
}

function addMissingKeys(arr: any[]) {
    return arr.map(obj => {
        if (!obj.hasOwnProperty(TobtTimeRange.min0_5)) {
            obj.min0_5 = 0
        }
        if (!obj.hasOwnProperty(TobtTimeRange.suspended)) {
            obj.suspended = 0
        }
        if (!obj.hasOwnProperty(TobtTimeRange.later)) {
            obj.later = 0
        }
        if (!obj.hasOwnProperty('invertedValue')) {
            obj.invertedValue = 0
        }
        return obj
    })
}

export const groupByHours = (data: HourTobtList): HourTobtListUpdated => {
    if (!data) {
        return []
    }
    const groupedByHours = data?.reduce<HourTobtListUpdated>((acc, curr) => {
        const indexHour = acc.findIndex(item => item.dateTime === curr.dateTime)
        if (indexHour === -1) {
            const newHourObject = {
                dateTime: curr.dateTime,
                date: dateTime(curr.dateTime),
            } as HourTobtItemUpdated
            newHourObject[keyConverterTobt(curr.tobtUpdate)] = round(
                round(curr.percentage, 2) * 100,
                0,
            )

            acc.push(newHourObject)
        } else {
            acc[indexHour][keyConverterTobt(curr.tobtUpdate)] = round(
                round(curr.percentage, 2) * 100,
                0,
            )
        }

        return acc
    }, [])

    const missingKeysAdded = addMissingKeys(groupedByHours)

    return missingKeysAdded.map(item => {
        let invertedValue = 100
        let sumOfValues = 0
        if (TobtTimeRange.later in item) {
            sumOfValues += item[TobtTimeRange.later]
        }
        if (TobtTimeRange.suspended in item) {
            sumOfValues += item[TobtTimeRange.suspended]
        }
        if (TobtTimeRange.min0_5 in item) {
            sumOfValues += item[TobtTimeRange.min0_5]
        }

        item.invertedValue = invertedValue - sumOfValues <= 0 ? 0 : invertedValue - sumOfValues
        return item
    })
}

const groupedData = (data: any[], type: TobtKeyTypes) =>
    data.reduce((acc: TobtTableData[], curr: any) => {
        const indexAirline = acc.findIndex((item: TobtTableData) => item[type] === curr[type])
        if (indexAirline === -1) {
            const newAirlineObject = {
                [TobtKeyTypes[type]]: curr[type],
                [TobtTimeRange.before]: '',
                [TobtTimeRange.late]: '',
                [TobtTimeRange.later]: '',
                [TobtTimeRange.suspended]: '',
                [TobtTimeRange.min0_5]: '',
                [TobtTimeRange.min6_10]: '',
                [TobtTimeRange.min11_20]: '',
                [TobtTimeRange.min21_30]: '',
                [TobtTimeRange.min31_40]: '',
                [TobtTimeRange.min41_50]: '',
                [TobtTimeRange.min51_60]: '',
                [TobtTimeRange.min60]: '',
            }
            newAirlineObject[keyConverterTobt(curr.tobtUpdate)] =
                round(round(curr.percentage, 2) * 100, 0) + '%'

            acc.push(newAirlineObject)
        } else {
            acc[indexAirline][keyConverterTobt(curr.tobtUpdate)] =
                round(round(curr.percentage, 2) * 100, 0) + '%'
        }

        return acc
    }, [])

const selectProperDate = (tobtDataTimeRange: TobtDataTimeRangeType, data: any[]) => {
    return tobtDataTimeRange === TobtDataTimeRangeType.Today
        ? data.filter(i => dateTime(i.date).isSame(dateTime(), 'day'))
        : data.filter(i => dateTime(i.date).isBefore(dateTime(), 'day'))
}

const moveTotalToBottom = (data: TobtTableData[], key: TobtKeyTypes) => {
    const total = data.find(item => item[key] === 'Total')
    const withoutTotal = data.filter(item => item[key] !== 'Total')
    return total ? [...withoutTotal, total] : withoutTotal
}

export const groupByTobtType = (
    data: any[],
    type: TobtKeyTypes,
    tobtDataTimeRange: TobtDataTimeRangeType,
): TobtTableData[] | [] => {
    if (!data || data.length === 0) {
        return []
    }

    return moveTotalToBottom(groupedData(selectProperDate(tobtDataTimeRange, data), type), type)
}

export const getTobtValue = (tobt: AirlineTobtList) => {
    const today = dateTime().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    const tobtValue = tobt.filter(tobt => {
        if (
            tobt.airline === 'Total' &&
            tobt.tobtUpdate === 'Late' &&
            dateTime(tobt.date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isSame(today)
        ) {
            return tobt
        }
        return
    })

    return tobtValue[0]?.percentage ? round(round(tobtValue[0].percentage, 2) * 100, 0) : undefined
}
