import { authRestService } from '../../dataaccess/auth/authRestService'
import { BaseRestService, RequestInterceptorParam } from '../../dataaccess/base/BaseRestService'

const enhanceRequestWithAuthHeader = (
    token: string | null,
    requestInit?: RequestInit,
): RequestInit => {
    let currentRequestInit = requestInit || {}
    const headers: Headers = (currentRequestInit.headers || new Headers()) as Headers
    if (token) {
        headers.delete('Authorization')
        headers.append('Authorization', `Bearer ${token}`)
        currentRequestInit = Object.assign(currentRequestInit, { headers })
    }
    return currentRequestInit
}

export const authRequestInterceptor =
    (apiPath: string, token: string | null) =>
    ([url, init]: RequestInterceptorParam): RequestInterceptorParam => {
        if (authRestService.isAuthAuditablePath(apiPath, url)) {
            return [url, enhanceRequestWithAuthHeader(token, init)]
        } else {
            return [url, init]
        }
    }

export const registerAuthInterceptor = (apiPath: string, token: string | null) => {
    BaseRestService.requestInterceptors.push(authRequestInterceptor(apiPath, token))
}
