import { uuid } from './Uuid'

export const getExtension = (filename: string) => filename.split('.').pop()

export const uniqueFileName = (filename: string) => {
    const name = uuid()
    const extension = getExtension(filename)
    if (extension) {
        return `${name}.${extension}`
    }
    return name
}
