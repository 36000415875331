import { DateTime } from 'aos-helpers/src/helpers/Time'
import { ScaleBand } from 'd3-scale'

import { AxisScale } from './AxisScale'
import { HorizontalMargins, Margins } from './Margins'

export interface ChartConfig {
    margins: Margins
}

export interface ChartSize {
    width: number
    height: number
}

export interface ChartXYAccessors<T, TX, TY> {
    xAccessor: f.Func1<T, TX>
    yAccessor: f.Func1<T, TY>
}

export interface ChartScales<TX, TY> {
    xScale: AxisScale<TX>
    yScale: AxisScale<TY>
}

export enum ChartScaleType {
    X = 1,
    Y1,
    Y2,
}

export interface LinearScaleConfig {
    scale: AxisScale<number>
    tickValues: number[]
}

export interface DateScaleConfig {
    scale: AxisScale<DateTime>
    tickValues: DateTime[]
}

export interface ChartRange {
    xRange: [number, number]
    yRange: [number, number]
}

export const getRealChartSize = (chartWrapperSize: ChartSize, margins: Margins): ChartSize => ({
    width: Math.max(chartWrapperSize.width - margins.left - margins.right, 0),
    height: Math.max(chartWrapperSize.height - margins.top - margins.bottom, 0),
})

export const getRanges = (margins: Margins, size: ChartSize): ChartRange => ({
    xRange: [margins.left, Math.max(size.width - margins.right, 0)],
    yRange: [Math.max(size.height - margins.bottom, 0), margins.top],
})

export const bandScaleMargins = <T extends string>(
    bandScale: ScaleBand<T>,
    chartWidth: number,
): HorizontalMargins => {
    const domain = bandScale.domain()
    if (domain.length < 2) {
        return { left: 0, right: 0 }
    }

    const scale = (x: T) => bandScale.bandwidth() / 2 + (bandScale(x) || 0)

    return {
        left: scale(domain[0]),
        right: chartWidth - scale(domain[domain.length - 1]),
    }
}

/**
 * Returns the extents of the group (the place where group title can be placed.
 *
 * Normally it should be [scale(groupStart), scale(nextGroupStart), though for groups "touching" any end of the chart,
 * we have to trim it, and take into the potential additional space beyond edge point locations (coming from the bandwidth).
 */
export const getGroupRange = <T>(
    groupStart: T,
    groupEnd: T,
    scale: AxisScale<T>,
): [number, number] => {
    const domain = scale.domain()
    const first = domain[0]
    const last = domain[domain.length - 1]

    let left = scale(groupStart) || scale(first)!
    let right = scale(groupEnd) || scale(last)!

    if (scale.step && left === scale(first)) {
        left -= scale.step() / 2
    }

    if (scale.step && right === scale(last)) {
        right += scale.step() / 2
    }

    return [left, right]
}
