import React, { FC } from 'react'

import { FodCreateModal } from './FodCreateModal'
import { FodEditModal } from './FodEditModal'
import { FodPreviewLocationModal } from './FodLocationModal'

const FodModals: FC = () => (
    <>
        <FodCreateModal />
        <FodEditModal />
        <FodPreviewLocationModal />
    </>
)

export default FodModals
