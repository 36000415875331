import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import { fodService } from '../../../services/fod'
import { FodDto } from '../../../services/fod/types'
import { currentUserSiteLocation } from '../../auth/state'
import {
    DELETE_FOD,
    DeleteFodAction,
    deleteFodSuccess,
    LOAD_SINGLE_FOD,
    LoadSingleFodAction,
    loadSingleFodSuccess,
} from './actions'

function* loadSingleFodSaga() {
    yield takeEvery<LoadSingleFodAction>(LOAD_SINGLE_FOD, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<FodDto> = yield call(fodService.getFodList, siteLocation, 0, 1)
        yield put(loadSingleFodSuccess(response.content[0]))
    })
}

function* deleteFodSaga() {
    yield takeEvery<DeleteFodAction>(DELETE_FOD, function* (action) {
        yield call(fodService.deleteFod, action.payload)
        yield put(deleteFodSuccess(action.payload))
    })
}
export const fodSyncSagas = [loadSingleFodSaga, deleteFodSaga]
