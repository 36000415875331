import { unitOfTime } from 'moment'

import { DateTime, dateTime, endOfDay, startOfDay, startOfHour } from './Time'

export interface TimeRange {
    timeStart: DateTime
    timeEnd: DateTime
}

export const timeRange = (timeStart: DateTime | number, timeEnd: DateTime | number) => ({
    timeStart: dateTime(timeStart),
    timeEnd: dateTime(timeEnd),
})

export const timeRangeFromNow = (now: DateTime, fromHours: number, toHours: number) =>
    timeRange(startOfHour(now).add(fromHours, 'hour'), startOfHour(now).add(toHours, 'hour'))

export const timeRangeForDay = (timeStart: DateTime) =>
    timeRange(startOfDay(timeStart), endOfDay(timeStart))

export const getTimeRangeWithBuffer = (tr: TimeRange, factor: number): TimeRange => {
    const timeStart = tr.timeStart.valueOf()
    const timeEnd = tr.timeEnd.valueOf()
    const buffer = (timeEnd - timeStart) * factor
    return {
        timeStart: dateTime(timeStart - buffer),
        timeEnd: dateTime(timeEnd + buffer),
    }
}

export const isTimeRangeWithin = (newRange: TimeRange, range: TimeRange): boolean =>
    newRange.timeStart > range.timeStart && newRange.timeEnd < range.timeEnd

export const isTimeWithin = (date: DateTime, period: TimeRange): boolean =>
    date.isBetween(period.timeStart, period.timeEnd, 'millisecond', '[)')

export const getDataFromPeriod = <T>(dateGetter: f.Func1<T, DateTime>) => (
    data: T[],
    period: TimeRange,
): T[] => data.filter(dataPoint => isTimeWithin(dateGetter(dataPoint), period))

export const getTrendData = <T>(dateGetter: f.Func1<T, DateTime>) => (
    data: T[],
    period: TimeRange,
) => ({
    current: getDataFromPeriod(dateGetter)(data, period),
    previous: getDataFromPeriod(dateGetter)(data, getPreviousRange(period)),
})

export const getPreviousRange = ({ timeStart, timeEnd }: TimeRange): TimeRange => ({
    timeStart: dateTime(timeStart.valueOf() - (timeEnd.valueOf() - timeStart.valueOf())),
    timeEnd: timeStart,
})

export const unionTimeRange = (range1: TimeRange, range2: TimeRange) => {
    const newStart = Math.min(range1.timeStart.valueOf(), range2.timeStart.valueOf())
    const newEnd = Math.max(range1.timeEnd.valueOf(), range2.timeEnd.valueOf())
    return timeRange(newStart, newEnd)
}

export const roundTimeRange = ({ timeStart, timeEnd }: TimeRange, unit: unitOfTime.StartOf) =>
    timeRange(dateTime(timeStart).startOf(unit), dateTime(timeEnd).endOf(unit))
