import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { chain } from 'lodash'

import { RunwaysStateDto } from '../../../dataaccess/runway/types/RunwaysStateDto'
import { compareModes, emptyCombination, RunwayMode } from './RunwayMode'
import { RunwayClosure, RunwaysState } from './RunwaysState'

export class RunwaysStateImpl extends WrapperObject<RunwaysStateDto> implements RunwaysState {
    constructor(entity: RunwaysStateDto) {
        super(entity)
    }

    public get airport() {
        return this.value.airport
    }

    public get combinationId() {
        return this.value.combinationId
    }

    public get combinationName() {
        return this.value.combinationName
    }

    public get combinationDescription() {
        return this.value.combinationDescription
    }

    public get lastChanged() {
        return dateTime(this.value.lastChanged)
    }

    public get combinationDetails() {
        if (this.value.combinationDetails) {
            const combination = chain(this.value.combinationDetails)
                .map((v, k) => v.map(i => [k, i]))
                .flatten()
                .groupBy(i => i[1])
                .mapValues(v => v.map(i => i[0] as RunwayMode).sort(compareModes))
                .value()
            return { ...emptyCombination, ...combination }
        }
        return undefined
    }

    public get runwayClosure() {
        if (this.value.runwayClosure) {
            return this.value.runwayClosure.map(closure => {
                return <RunwayClosure>{
                    runway: closure.runway,
                    dateStart: dateTime(closure.dateStart),
                    dateEnd: dateTime(closure.dateEnd),
                }
            })
        }
        return undefined
    }
}
