import { conditionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { getCustomGroupList } from 'aos-services/src/services/users/aosUserFormatter'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { isExistingUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import { USER_PRE_CHECK } from 'aos-services/src/services/users/types/AosUserFindStatus'
import React from 'react'

import {
    AdUserActionCreators,
    AdUserData,
} from '../../../core/groupManager/manageUser/adUser/actions'
import { ManageAdUserState } from '../../../core/groupManager/manageUser/state'
import { AdUserReadOnlyFieldsInfo } from './AdUserReadOnlyFieldsInfo'
import { AdUserForm } from './forms/AdUserForm'
import { AdUserPreCheckForm } from './forms/AdUserPreCheckForm'
import { PermissionsForm } from './forms/PermissionsForm'
import { AdUserStatus } from './partial/AdUserStatus'
import { ExistingAdUserStatus } from './partial/ExistingAdUserStatus'
import { UserModalsClass, UserModalsDispatchProps, UserModalsProps } from './UserModalsClass'

export interface AdUserModalsProps extends ManageAdUserState, UserModalsProps {}

export class AddAdUserModalsClass extends UserModalsClass<
    AdUserModalsProps & AdUserModalsDispatchProps,
    AdUserFormData
> {
    protected renderFooterContent() {
        return <AdUserReadOnlyFieldsInfo />
    }

    protected renderPreCheckFormContent() {
        const { user, form } = this.props
        return (
            <AdUserPreCheckForm
                user={user}
                form={form}
                changeForm={this.changeValue}
                searchUser={this.props.searchUserAction}
                setFormPristine={this.props.setFormPristineAction}
            />
        )
    }

    protected renderFormContent() {
        const { user, form, groups } = this.props
        const customGroups = getCustomGroupList(groups)

        return (
            <AdUserForm
                user={user}
                form={form}
                groups={customGroups}
                changeForm={this.changeValue}
                selectAllGroups={this.selectAll(customGroups)}
                deselectAllGroups={this.props.deselectAllGroupsAction}
            />
        )
    }

    protected renderStatusContent() {
        const { user, conflictUserId } = this.props
        const goBack = () => this.changeValue({ userStatus: USER_PRE_CHECK })
        const editAdUser = conditionalFunctionCall1Arg(
            !!conflictUserId,
            this.props.switchToEditAdUserAction,
            conflictUserId,
        )
        const editContact = conditionalFunctionCall1Arg(
            !!conflictUserId,
            this.props.switchToEditContactAction,
            conflictUserId,
        )

        return isExistingUserFormData(user) ? (
            <ExistingAdUserStatus
                user={user}
                hide={this.props.hideUserModalAction}
                removeAdUser={this.removeUser}
            />
        ) : (
            <AdUserStatus
                user={user}
                goBack={goBack}
                hide={this.props.hideUserModalAction}
                addManually={this.props.switchToContactAction}
                removeAdUser={this.removeUser}
                editAdUser={editAdUser}
                editContact={editContact}
            />
        )
    }

    protected renderPermissionSettings = () => {
        const { user } = this.props
        return <PermissionsForm user={user} changeForm={this.changeValue} />
    }

    protected saveUserAction() {
        this.props.saveUserAction({
            formData: this.props.user,
            notificationSettings: this.props.notificationSettings,
        })
    }

    private changeValue = (v: Partial<ContactFormData>) => {
        this.props.changeFormValueAction(Object.assign({}, this.props.user, v))
    }
}

export interface AdUserModalsDispatchProps extends AdUserActionCreators, UserModalsDispatchProps {
    saveUserAction(v: AdUserData): void
}
