import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { SelectedMapElement } from 'aos-services/src/services/map/types/SelectedMapElement'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { initialMapState, MapState } from 'aos-ui-map/src/core/state'

export interface AirportMapState extends MapState {
    selectionDetails?: AosEvent | Task
}

export interface AirportMapStateAware {
    airportMap: AirportMapState
}

export const initialAirportMapState = initialMapState

export interface SidebarOwnProps {
    selectionTarget?: SelectedMapElement
}
