import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { groupService } from 'aos-services/src/services/users/groupService'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
    LOAD_COMMON_GROUPS,
    LoadCommonGroupsAction,
    loadCommonGroupsSuccessAction,
} from './actions'
import { CommonFrontendStateAware } from './state'

function* loadGroups() {
    try {
        const refs: AosUserGroup[] = yield call(groupService.getActiveGroups)
        yield put(loadCommonGroupsSuccessAction(refs))
    } catch (e) {
        logger.handleError(e as Error)
        throw e
    }
}

function* loadGroupsSaga() {
    yield takeEvery<LoadCommonGroupsAction>(LOAD_COMMON_GROUPS, loadGroups)
}

export function* assureCommonUserGroup() {
    const groups: AosUserGroup[] = yield select(
        (state: CommonFrontendStateAware) => state.commonFrontend.groups,
    )
    if (!groups.length) {
        yield loadGroups()
    }
}

export const commonFrontendSagas = [loadGroupsSaga]
