import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosAirport,
    locationName,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    AosUserLocalisation,
    languageName,
} from 'aos-services/src/services/users/types/AosUserLocalisation'
import { AosUserProfileSettings } from 'aos-services/src/services/users/types/AosUserSetting'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
    saveUserProfileAction,
    setActiveTabAction,
    setFormPristineAction,
    toggleUserProfileModalAction,
    updateFormStateAction,
} from '../../core/userProfileModal/actions'
import {
    allAirports,
    languageOptions,
} from '../../core/userProfileModal/helpers/userProfileOptions'
import {
    TabId,
    UserProfileModalState,
    UserProfileModalStateAware,
} from '../../core/userProfileModal/state'
import { NotificationSettingsForm } from './NotificationSettings/NotificationSettingsForm'

class UserProfileModalClass extends PureComponent<UserProfileModalProps> {
    public render() {
        return (
            <Modal
                id='user-profile-modal'
                isOpen={this.props.userProfileModal}
                closeAction={this.closeModal}
                title={this.header()}
                footer={this.footer()}
                modalKind={ModalKind.Big}
                className='user-modal'
            >
                <Box column flex={1}>
                    {this.renderTabs()}
                </Box>
            </Modal>
        )
    }

    private header = () => <span>{translate('user-profile.settings.title')}</span>

    private footer = () => (
        <Box row fullWidth>
            <ValidationInfo pristine={this.props.form.pristine} valid={this.props.form.valid} />
            <FormButton label={translate('user-profile.settings.save')} onClick={this.submitForm} />
        </Box>
    )

    private renderTabs = () => (
        <Box fullHeight column>
            <UnderlineTabs
                items={EnumValues.getValues<TabId>(TabId)}
                nameFormatter={translateEnum<TabId>(TabId, 'user-profile.tabs')}
                value={this.props.activeTab}
                onChange={tab => this.props.setActiveTab(tab)}
                paddingLeft={30}
            />
            <Box relative flex={1}>
                {this.props.activeTab === TabId.UserSettings
                    ? this.renderLabelSelectors()
                    : this.renderNotificationSettings()}
            </Box>
        </Box>
    )

    private closeModal = () => this.props.toggleModal(false)

    private renderLabelSelectors = () => (
        <Box margin={40}>
            <Box margin={40}>
                <LabeledSelect
                    isError={this.props.form.error.siteLocation}
                    value={this.props.formState.siteLocation}
                    onChange={this.changeSiteLocation}
                    isRequired
                    keyPrefix='user-profile.site-location'
                    items={allAirports}
                    labelRenderer={locationName}
                />
            </Box>
            <Box margin={40}>
                <LabeledSelect
                    isError={this.props.form.error.localisation}
                    value={this.props.formState.localisation}
                    onChange={this.changeLocalisation}
                    isRequired
                    keyPrefix='user-profile.localisation'
                    items={languageOptions}
                    labelRenderer={languageName}
                />
            </Box>
        </Box>
    )

    private renderNotificationSettings() {
        const { notificationPreferences } = this.props.formState

        if (!notificationPreferences) {
            return (
                <Box flex={1}>
                    <Spinner onLight />
                </Box>
            )
        }
        return (
            <Box fullHeight column>
                <NotificationSettingsForm
                    settings={notificationPreferences}
                    onUpdate={preference =>
                        this.updateForm({
                            notificationPreferences: { ...notificationPreferences, ...preference },
                        })
                    }
                />
            </Box>
        )
    }

    private changeSiteLocation = (siteLocation: AosAirport) => {
        this.updateForm({ siteLocation })
        this.resetPristine()
    }

    private changeLocalisation = (localisation: AosUserLocalisation) => {
        this.updateForm({ localisation })
        this.resetPristine()
    }

    private resetPristine = () => {
        if (!this.props.form.pristine && this.props.form.valid) {
            this.props.setFormPristine(true)
        }
    }

    private updateForm = (f: Partial<AosUserProfileSettings>) => {
        this.props.updateFormState({ ...this.props.formState, ...f })
    }

    private submitForm = () => {
        if (!this.props.form.valid) {
            this.props.setFormPristine(false)
        } else {
            this.props.saveProfile(this.props.formState)
        }
    }
}

interface UserProfileModalDispatchProps {
    toggleModal: typeof toggleUserProfileModalAction
    saveProfile: typeof saveUserProfileAction
    setFormPristine: typeof setFormPristineAction
    setActiveTab: typeof setActiveTabAction
    updateFormState: typeof updateFormStateAction
}

interface UserProfileModalProps extends UserProfileModalState, UserProfileModalDispatchProps {}

export const UserProfilePreferencesModal = connect<
    UserProfileModalState,
    UserProfileModalDispatchProps
>((state: UserProfileModalStateAware) => state.userProfileModal, {
    toggleModal: toggleUserProfileModalAction,
    saveProfile: saveUserProfileAction,
    setFormPristine: setFormPristineAction,
    setActiveTab: setActiveTabAction,
    updateFormState: updateFormStateAction,
})(UserProfileModalClass)
