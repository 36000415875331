import { severityColorsMapping } from 'aos-services/src/services/common/types/AosSeverity'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AttachmentReadonlySection } from 'aos-ui/src/components/attachment/AttachmentReadonlySection'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import { EventBarContent } from 'aos-ui-event/src/components/event/EventBarContent'
import React, { FC } from 'react'

import { EventDetails } from '../EventDetails'

export interface EventInfoContentProps {
    event: AosEvent
    withVisibilityField: boolean
    inviteAction?(): void
    selectLocation?(): void
}

export const EventInfoContent: FC<EventInfoContentProps> = ({
    event,
    inviteAction,
    withVisibilityField,
    selectLocation,
}) => (
    <>
        <Box paddingBottom={30}>
            <InfoBar
                color={severityColorsMapping[event.severity]}
                outlined={event.hasPrivateChannel}
            >
                <EventBarContent item={event} />
            </InfoBar>
        </Box>
        <EventDetails
            event={event}
            variant={ThemeVariant.Black}
            size={LabeledTextElementSize.Small}
            inviteAction={inviteAction}
            withVisibilityField={withVisibilityField}
            selectLocation={selectLocation}
        />
        <AttachmentReadonlySection
            attachments={event.allAttachments}
            variant={ThemeVariant.Black}
            size={LabeledTextElementSize.Small}
        />
    </>
)
