import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import { OperationalForecast } from '../../../services/operationalForecast/types/OperationalForecast'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const SAVE_OPERATIONAL_FORECAST =
    'STATUS_DASHBOARD_DATA/OPERATIONAL_FORECAST/SAVE_OPERATIONAL_FORECAST'
export type SaveOperationalForecastAction = PayloadAction<
    typeof SAVE_OPERATIONAL_FORECAST,
    OperationalForecast
>
export const saveOperationalForecastAction = payloadActionCreator<
    SaveOperationalForecastAction,
    OperationalForecast
>(SAVE_OPERATIONAL_FORECAST)

export const OPERATIONAL_FORECAST_SYNC =
    'STATUS_DASHBOARD_DATA/OPERATIONAL_FORECAST/OPERATIONAL_FORECAST_SYNC'
export type OperationalForecastSyncAction = PayloadAction<
    typeof OPERATIONAL_FORECAST_SYNC,
    SyncDataKey
>
export const operationalForecastSyncAction = payloadActionCreator<
    OperationalForecastSyncAction,
    SyncDataKey
>(OPERATIONAL_FORECAST_SYNC)

export const OPERATIONAL_FORECAST_SYNC_STOP =
    'STATUS_DASHBOARD_DATA/OPERATIONAL_FORECAST/OPERATIONAL_FORECAST_SYNC_STOP'
export type OperationalForecastSyncStopAction = PayloadAction<
    typeof OPERATIONAL_FORECAST_SYNC_STOP,
    SyncDataKey
>
export const operationalForecastSyncStopAction = payloadActionCreator<
    OperationalForecastSyncStopAction,
    SyncDataKey
>(OPERATIONAL_FORECAST_SYNC_STOP)

export const OPERATIONAL_FORECAST_SYNC_SUCCESS =
    'STATUS_DASHBOARD_DATA/OPERATIONAL_FORECAST/OPERATIONAL_FORECAST_SYNC_SUCCESS'
export type OperationalForecastSyncSuccessAction = PayloadAction<
    typeof OPERATIONAL_FORECAST_SYNC_SUCCESS,
    OperationalForecast
>
export const operationalForecastSyncSuccessAction = payloadActionCreator<
    OperationalForecastSyncSuccessAction,
    OperationalForecast
>(OPERATIONAL_FORECAST_SYNC_SUCCESS)

export type OperationalForecastAction =
    | OperationalForecastSyncAction
    | OperationalForecastSyncStopAction
    | OperationalForecastSyncSuccessAction
    | SaveOperationalForecastAction
