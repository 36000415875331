import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'

import {
    CHANGE_FORM_VALUE,
    DESELECT_ALL_GROUPS,
    HIDE_USER_MODAL,
    SELECT_ALL_GROUPS,
    SET_CONFLICT_USER_ID,
    SET_FORM_PRISTINE,
    SET_USER_DATA,
    SET_USER_STATUS,
    SHOW_USER_EMPTY_MODAL,
    SHOW_USER_MODAL,
    UPDATE_NOTIFICATIONS_SETTINGS,
} from '../actions'
import {
    emptyContact,
    initEditContactState,
    initialAddContactState,
    ManageContactState,
    validateContact,
} from '../state'
import { ContactAction, SHOW_USER_MODAL_WITH_CONTACT } from './actions'

export function contactReducer(
    state = initialAddContactState,
    action: ContactAction,
): ManageContactState {
    switch (action.type) {
        case SHOW_USER_MODAL:
            return { ...state, isOpen: true }

        case SHOW_USER_EMPTY_MODAL:
            return { ...initialAddContactState, isOpen: true }

        case SHOW_USER_MODAL_WITH_CONTACT:
            return {
                ...initEditContactState(action.payload),
                isOpen: true,
            }

        case HIDE_USER_MODAL:
            return { ...state, isOpen: false }

        case SET_FORM_PRISTINE:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
            }

        case SET_USER_STATUS:
            return {
                ...state,
                user: { ...state.user, userStatus: action.payload },
            }

        case SET_CONFLICT_USER_ID:
            return {
                ...state,
                conflictUserId: action.payload,
            }

        case SELECT_ALL_GROUPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedGroupsIds: [...action.payload],
                },
            }

        case DESELECT_ALL_GROUPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedGroupsIds: [],
                },
            }

        case CHANGE_FORM_VALUE:
            return {
                ...state,
                user: action.payload,
                form: state.form.modify(validateContact(action.payload)),
            }

        case SET_USER_DATA:
            const user = { ...emptyContact, ...action.payload } as ContactFormData
            return {
                ...state,
                user: {
                    ...user,
                },
                form: FormValidation.fromFields(validateContact(user)),
                conflictUserId: undefined,
            }

        case UPDATE_NOTIFICATIONS_SETTINGS: {
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings!,
                    ...action.payload,
                },
            }
        }

        default:
            return state
    }
}
