import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum TaskFilter {
    Todo = 'todo',
    AssignedToMe = 'my',
    Overdue = 'overdue',
    InProgress = 'in-progress',
    OnHold = 'on-hold',
    Done = 'done',
    All = 'all',
}

export const allTaskFilters: TaskFilter[] = EnumValues.getValues<TaskFilter>(TaskFilter)

export const translateTaskFilter = translateEnum<TaskFilter>(TaskFilter, 'task-filter')
