import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { currentUserSelector } from 'aos-services/src/core/auth/state'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { showActionModalAction } from '../../../core/actionModal/actions'
import { applyChecklistTemplateSeqChangesAction } from '../../../core/checklistManager/actions'
import { changeChecklistTemplateSeqAction } from '../../../core/checklistManager/event/actions'
import {
    exportSingleUnitTaskTemplateAction,
    selectUnitTaskTemplateAction,
} from '../../../core/checklistManager/unit/actions'
import { State } from '../../../core/state'
import { TemplatesList } from '../TemplatesList'
import { UnitTaskTemplateItem } from './partials/UnitTaskTemplateItem'

export const UnitTaskTemplateList = () => {
    const dispatch = useDispatch()
    const {
        selectedTemplate,
        exportingChecklistTemplates,
        unitTaskManager: { unitTaskTemplates },
    } = useSelector((state: State) => state.checklistManager)
    const currentUser = useSelector(currentUserSelector)
    const exportSingleUnitTaskAction = (id: number) => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-tasklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportSingleUnitTaskTemplateAction(id)),
            }),
        )
    }

    return (
        <TemplatesList
            canEdit
            Component={UnitTaskTemplateItem}
            onSelect={value => dispatch(selectUnitTaskTemplateAction(value))}
            checklistTemplates={unitTaskTemplates}
            selectedChecklistTemplate={selectedTemplate}
            onExport={exportSingleUnitTaskAction}
            exportingChecklistTemplates={exportingChecklistTemplates}
            currentUser={currentUser}
            onChangeSeq={changeChecklistTemplateSeqAction}
            onApplySeqChanges={applyChecklistTemplateSeqChangesAction}
        />
    )
}
