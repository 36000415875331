import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { DateTime } from 'aos-helpers/src/helpers/Time'

import { ScrollPosition } from './state'

export const ZOOM_IN = 'TIMELINE/ZOOM_IN'
export type ZoomInAction = Action<typeof ZOOM_IN>
export const zoomInAction = emptyActionCreator<ZoomInAction>(ZOOM_IN)

export const ZOOM_OUT = 'TIMELINE/ZOOM_OUT'
export type ZoomOutAction = Action<typeof ZOOM_OUT>
export const zoomOutAction = emptyActionCreator<ZoomOutAction>(ZOOM_OUT)

export const JUMP_TO_DATE = 'TIMELINE/JUMP_TO_DATE'
export type JumpToDateAction = PayloadAction<typeof JUMP_TO_DATE, DateTime>
export const jumpToDateAction = payloadActionCreator<JumpToDateAction, DateTime>(JUMP_TO_DATE)

export const JUMP_TO_NOW = 'TIMELINE/JUMP_TO_NOW'
export type JumpToNowAction = Action<typeof JUMP_TO_NOW>
export const jumpToNowAction = emptyActionCreator<JumpToNowAction>(JUMP_TO_NOW)

export const TIMELINE_TIME_CHANGE = 'TIMELINE/TIMELINE_TIME_CHANGE'
export type TimelineTimeChangeAction = PayloadAction<typeof TIMELINE_TIME_CHANGE, [number, number]>
export const timelineTimeChangeAction = payloadActionCreator<
    TimelineTimeChangeAction,
    [number, number]
>(TIMELINE_TIME_CHANGE)

export const TIMELINE_SCROLL = 'TIMELINE/TIMELINE_SCROLL'
export type TimelineScrollAction = PayloadAction<typeof TIMELINE_SCROLL, ScrollPosition>
export const timelineScrollAction = payloadActionCreator<TimelineScrollAction, ScrollPosition>(
    TIMELINE_SCROLL,
)

export type TimelineAction =
    | ZoomInAction
    | ZoomOutAction
    | JumpToDateAction
    | JumpToNowAction
    | TimelineScrollAction
    | TimelineTimeChangeAction
