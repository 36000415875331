import { LayersVisibilitySidebar } from 'aos-frontend/src/app/views/airportMap/LayersVisibilitySidebar'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Box } from 'aos-ui/src/components/base/Box'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { BimLayerCategory } from '../bim/BimLayerCategory'

export interface LayersVisibilityPopoverProps {
    bimLayers: BimLayersState
    mapVariant: MapVariant
    userLayersVisibility: UserLayerVisibilityState
    setUserLayersVisibilityAction(v: string[]): void
    changeFloorLayerVisibility(v: number): void
}

export const LayersVisibilityPopover: FC<LayersVisibilityPopoverProps> = ({
    bimLayers,
    setUserLayersVisibilityAction,
    changeFloorLayerVisibility,
    mapVariant,
    userLayersVisibility,
}) => {
    const bimMapLayers =
        mapVariant === MapVariant.Terminal
            ? bimLayers.list.filter(
                  i =>
                      i.category &&
                      i.category !== BimLayerCategory.TaskAndEvents &&
                      i.category !== BimLayerCategory.Atc,
              )
            : bimLayers.list.filter(i => i.category === BimLayerCategory.TaskAndEvents)
    const overlay = (
        <OverlayBox paddingHorizontal={12} paddingVertical={8} bg={Color.White}>
            <LayersVisibilitySidebar
                bimMapLayers={bimMapLayers}
                setBimLayersVisibility={v => setUserLayersVisibilityAction(v)}
                setFloorNumber={v => changeFloorLayerVisibility(v)}
                userLayersVisibility={userLayersVisibility}
                isTooltip
            />
        </OverlayBox>
    )

    return (
        <Box rowReverse>
            <Tooltip placement='bottomRight' overlay={overlay} trigger={['click']} withArrow>
                <div>
                    <IconButton
                        iconVariant={IconVariant.White}
                        iconSize={BlockSize.Std}
                        svg={SvgIcon.Layers}
                    />
                </div>
            </Tooltip>
        </Box>
    )
}

const OverlayBox = styled(Box)`
    box-shadow: ${boxShadow.std};
    ::before {
        position: absolute;
        background-color: inherit;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        margin: auto;
        top: 0;
        right: 4px;
        border-right-width: 0;
        border-bottom-width: 0;
        transform: translate(-50%, -50%) rotate(45deg);
    }
`
