import { RingRailTrainMapProperties } from 'aos-services/src/services/layerData/properties/RingRailTrainMapProperties'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import Feature from 'ol/Feature'
import Style from 'ol/style/Style'

import { renderMarkerMapOverlayIcon } from '../icons/MapIconRenderer'
import { addStyleToFeature, removeLastStyleFromFeature } from '../openlayers/base/FeatureUtils'
import { SelectControlConfig } from '../openlayers/controls/SelectControl'
import { getMapMarkerIconStyleForUrl } from './eventLayerStyleBuilder'
import { getBackgroundOverlayItemStyle } from './ringRailTrainStyleBuilder'

const markerSelectStyleHandler = (feature: Feature) => {
    renderMarkerMapOverlayIcon()
        .then(getMapMarkerIconStyleForUrl)
        .then(newStyle => addStyleToFeature(feature, newStyle))
}

const deselectStyleHandler = (feature: Feature) => {
    if ((feature.getStyle() as Style[]).length > 1) {
        removeLastStyleFromFeature(feature)
    }
}

const bimSelectStyleHandler = (feature: Feature) => {
    feature.set('vektorBimComponentCustomColoring', {
        color: '#99ddff',
    })
}

const bimDeselectStyleHandler = (feature: Feature) => {
    feature.set('vektorBimComponentCustomColoring', {
        color: '#ffffff',
    })
}

export const trainSelectStyleGetter = (feature: Feature): Promise<Style> => {
    const p = feature.getProperties() as { train: RingRailTrainMapProperties }
    return getBackgroundOverlayItemStyle(p.train)
}

const trainSelectStyleHandler = (feature: Feature) => {
    const p = feature.getProperties() as { train: RingRailTrainMapProperties }
    getBackgroundOverlayItemStyle(p.train).then(newStyle => addStyleToFeature(feature, newStyle))
}

export const airportMapSelectConfigs: SelectControlConfig[] = [
    {
        target: SelectedMapElementType.Event,
        onSelectStyleHandler: markerSelectStyleHandler,
        onDeselectStyleHandler: deselectStyleHandler,
    },
    {
        target: SelectedMapElementType.Task,
        onSelectStyleHandler: markerSelectStyleHandler,
        onDeselectStyleHandler: deselectStyleHandler,
    },
    {
        target: SelectedMapElementType.Gate,
        onSelectStyleHandler: bimSelectStyleHandler,
        onDeselectStyleHandler: bimDeselectStyleHandler,
    },
    {
        target: SelectedMapElementType.BusGate,
        onSelectStyleHandler: bimSelectStyleHandler,
        onDeselectStyleHandler: bimDeselectStyleHandler,
    },
    {
        target: SelectedMapElementType.Stand,
        onSelectStyleHandler: bimSelectStyleHandler,
        onDeselectStyleHandler: bimDeselectStyleHandler,
    },
    {
        target: SelectedMapElementType.BusDoor,
        onSelectStyleHandler: bimSelectStyleHandler,
        onDeselectStyleHandler: bimDeselectStyleHandler,
    },
    {
        target: SelectedMapElementType.BaggageClaim,
        onSelectStyleHandler: bimSelectStyleHandler,
        onDeselectStyleHandler: bimDeselectStyleHandler,
    },
    {
        target: SelectedMapElementType.Train,
        onSelectStyleHandler: trainSelectStyleHandler,
        onDeselectStyleHandler: deselectStyleHandler,
    },
]
