import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum PaxSecurityPoint {
    Dep = 1,
    Point9,
}

export const translatePaxSecurityPoint = translateEnum<PaxSecurityPoint>(
    PaxSecurityPoint,
    'dashboard.pax.tab',
)

export interface PaxFilters {
    paxSecurityPoint: PaxSecurityPoint
}
