import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import classNames from 'classnames'
import React, { FC } from 'react'
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'

import image1 from './assets/notam.png'
import { WhatsNewItem } from './WhatsNewItem'

const dot = (selected?: boolean) =>
    classNames({
        dot: true,
        selected: selected,
    })

const items = [
    {
        prefix: 'whats-new.notam',
        image: image1,
    },
]

// If you want to change it, remember to update OneTimeKey:
// aos-frontend/src/app/core/oneTime/state.ts
export const WhatsNewContent: FC = () => (
    <Box fullHeight>
        {items.length > 1 ? (
            <StyledCarousel
                swipeable
                className='full-height'
                emulateTouch
                infiniteLoop
                autoPlay
                showArrows={false}
                showStatus={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => (
                    <Dot
                        className={dot(isSelected)}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role='button'
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                    />
                )}
            >
                {items.map((item, index) => (
                    <WhatsNewItem key={index} prefix={item.prefix} image={item.image} />
                ))}
            </StyledCarousel>
        ) : (
            <Box style={{ textAlign: 'center' }}>
                <WhatsNewItem prefix={items[0].prefix} image={items[0].image} />
            </Box>
        )}
    </Box>
)

const StyledCarousel = styled(Carousel)`
    .carousel {
        height: 100%;
    }
`

const Dot = styled.li`
    background: ${Color.Primary} !important;
`
