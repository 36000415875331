import { eventsDataSelector } from 'aos-services/src/core/statusDashboardData/events/selectors'
import { AosDashboardEvent } from 'aos-services/src/services/events/types/AosDashboardEvent'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollableList } from 'aos-ui/src/components/list/DarkScrollableList'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { openEventAction } from '../../../../core/eventEditor/actions'
import { EventsItemState } from '../../../../services/statusDashboard/types/itemStates/EventsItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { Footer } from '../base/Footer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { EventItem } from './EventItem'
import { EventsItem } from './partials/EventsItem'

export class EventsClass extends PureComponent<EventsProps> {
    public render() {
        const { events, noDataWarning } = this.props
        return (
            <EventsItem
                noDataWarning={noDataWarning}
                itemProps={pickDashboardItemProps(this.props)}
                syncId={this.props.id}
            >
                <DarkScrollableList paddingTop={20}>
                    {events.map(this.renderEvent)}
                </DarkScrollableList>
                <Footer />
            </EventsItem>
        )
    }

    private renderEvent = (event: AosDashboardEvent) => (
        <Box paddingHorizontal={20} paddingBottom={20} key={event.id}>
            <EventItem event={event} openEventAction={this.props.openEventAction} />
        </Box>
    )
}

type EventsOwnProps = StatusDashboardItemCommonProps<EventsItemState>

interface EventsStateProps {
    events: AosDashboardEvent[]
    noDataWarning: boolean
}

interface EventsDispatchProps {
    openEventAction: typeof openEventAction
}

interface EventsProps extends EventsStateProps, EventsDispatchProps, EventsOwnProps {}

export const Events = connect<EventsStateProps, EventsDispatchProps, EventsOwnProps>(
    eventsDataSelector,
    {
        openEventAction,
    },
)(EventsClass)
