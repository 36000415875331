import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { WeatherState } from '../../../services/airportStatus/weather/types/WeatherState'
import { weatherService } from '../../../services/airportStatus/weather/weatherService'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import { syncAlertsAction, syncAlertsStopAction } from '../../alerts/actions'
import { PREFERENCES_LOCATION_CHANGED } from '../../auth/actions'
import { currentUserSiteLocation } from '../../auth/state'
import {
    WEATHER_LOAD,
    WEATHER_SYNC,
    WEATHER_SYNC_STOP,
    weatherLoadAction,
    weatherLoadSuccessAction,
    WeatherSyncAction,
    WeatherSyncStopAction,
} from './actions'

const SYNC_KEY = 'weather'

function* weatherSyncSaga() {
    yield takeEvery<WeatherSyncAction>(WEATHER_SYNC, function* (action: WeatherSyncAction) {
        yield put(syncAlertsAction(SYNC_KEY))
        yield* firebaseService.onListener(
            SYNC_KEY,
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.WEATHER),
                    weatherLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* weatherSyncStopSaga() {
    yield takeEvery<WeatherSyncStopAction>(
        WEATHER_SYNC_STOP,
        function* (action: WeatherSyncStopAction) {
            yield put(syncAlertsStopAction(SYNC_KEY))
            yield firebaseService.offListener(SYNC_KEY, action.payload)
        },
    )
}

function* weatherLoadSaga() {
    yield takeEvery(WEATHER_LOAD, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const data: WeatherState = yield call(weatherService.getWeatherSeries, siteLocation)
        yield put(weatherLoadSuccessAction(data))
    })
}

function* weatherLocationChangeSaga() {
    yield takeEvery(PREFERENCES_LOCATION_CHANGED, function* () {
        yield put(weatherLoadAction())
    })
}

export const weatherSagas = [
    weatherSyncSaga,
    weatherSyncStopSaga,
    weatherLoadSaga,
    weatherLocationChangeSaga,
]
