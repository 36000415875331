import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { DashboardItemState } from '../../../services/statusDashboard/types/DashboardItemState'
import { DashboardProcessType } from '../../../services/statusDashboard/types/DashboardPreset'
import { DashboardItemGroup } from '../sidebar/DashboardItemGroup'

interface StatusDashboardSidebarContentProps {
    states: DashboardItemState[]
    availableItems: [DashboardProcessType, DashboardItemType[]][]
    addDashboardItem(v: DashboardItemType): void
}

export const StatusDashboardSidebarContent: FC<StatusDashboardSidebarContentProps> = ({
    states,
    availableItems,
    addDashboardItem,
}) => (
    <Box padding={30}>
        {availableItems.map(([presetType, items]) => (
            <DashboardItemGroup
                key={presetType}
                type={presetType}
                states={states}
                availableItems={items}
                addDashboardItem={addDashboardItem}
            />
        ))}
    </Box>
)
