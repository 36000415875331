import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { ZIndex } from '../base/Theme'
import { DropdownRootProvider } from '../form/dropdown/base/DropdownRoot'

export const AppContainer: FCWithChildren<PropsWithChildren> = props => {
    const rootRef = React.createRef<HTMLDivElement>()
    const containerRef = React.createRef<HTMLDivElement>()
    return (
        <DropdownRootProvider
            value={{
                container: containerRef,
                dropdownRoot: rootRef,
            }}
        >
            <ContainerBox ref={containerRef}>
                {props.children}
                <DropdownRoot ref={rootRef} />
            </ContainerBox>
        </DropdownRootProvider>
    )
}

const ContainerBox = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${Color.UiBlack1};
    overflow: auto;
`

export const DropdownRoot = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${ZIndex.DropdownMenu};
`
