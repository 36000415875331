import { languageProvider } from 'aos-helpers/src/helpers/translations/LanguageProvider'
import {
    LOG_OUT,
    PREFERENCES_CHANGED_START,
    preferencesLanguageChangedAction,
    preferencesLocationChangedAction,
    RESTORE_AUTH,
    SIGN_IN_SUCCESS,
} from 'aos-services/src/core/auth/actions'
import {
    currentUserIdSelector,
    preferencesSelector,
    UserPreference,
} from 'aos-services/src/core/auth/state'
import { firebaseAuthService } from 'aos-services/src/services/auth/firebaseAuthService'
import { websocketListenerService } from 'aos-services/src/services/common/WebsockerListenerService'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { AosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { userProfileService } from 'aos-services/src/services/users/userProfileService'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import keycloak from '../../keycloak/keycloak'
import { resetStateAction } from '../actions'
import { setLoginStatusAction } from '../login/actions'
import { LoginStatus } from '../login/state'
import { navigateToLoginAction } from '../router/actions'
import {
    GET_USER_DETAILS,
    GetUserDetailsAction,
    getUserDetailsAction,
    getUserDetailsSuccessAction,
} from './actions'

function* loginSuccess() {
    const newPreferences: UserPreference = yield select(preferencesSelector)
    languageProvider.setLanguage(newPreferences.localisation)
    yield call(firebaseAuthService.assureUserLoggedIn)
    yield put(setLoginStatusAction(LoginStatus.Success))
    yield put(getUserDetailsAction())
}

function* loadUserDetailsSaga() {
    yield takeEvery<GetUserDetailsAction>(GET_USER_DETAILS, function* () {
        const id: number = yield select(currentUserIdSelector)
        const userData: AosAdUser = yield call(userProfileService.getUserProfileById, id)
        yield put(getUserDetailsSuccessAction(userData))
    })
}

function* logout() {
    yield call(firebaseAuthService.logout)
    yield firebaseService.offAllListeners()
    yield websocketListenerService.offAllListeners()
    yield put(navigateToLoginAction(false))
    yield put(resetStateAction())
}

function* logoutSaga() {
    yield takeEvery(LOG_OUT, logout as any)
}

function* restoreAuthSaga() {
    yield takeEvery(RESTORE_AUTH, loginSuccess)
}

function* preferencesChangedStartSaga() {
    yield takeEvery(PREFERENCES_CHANGED_START, function* () {
        const id: number = yield select(currentUserIdSelector)
        const currentPreferences: UserPreference = yield select(preferencesSelector)
        const userData: AosAdUser = yield call(userProfileService.getUserProfileById, id)
        yield put(getUserDetailsSuccessAction(userData))
        const newPreferences: UserPreference = yield select(preferencesSelector)
        if (newPreferences.localisation !== currentPreferences.localisation) {
            yield put(preferencesLanguageChangedAction(newPreferences.localisation))
        }
        if (newPreferences.siteLocation !== currentPreferences.siteLocation) {
            yield put(preferencesLocationChangedAction(newPreferences.siteLocation))
        }
    })
}

function* changeLanguageSaga() {
    yield takeEvery([SIGN_IN_SUCCESS], function* () {
        const newPreferences: UserPreference = yield select(preferencesSelector)
        languageProvider.setLanguage(newPreferences.localisation)
    })
}

function* preferencesChanged() {
    yield takeEvery(PREFERENCES_CHANGED_START, function* () {
        keycloak.clearToken()
        yield keycloak.updateToken()
    })
}

export const authSagas = [
    logoutSaga,
    restoreAuthSaga,
    loadUserDetailsSaga,
    preferencesChangedStartSaga,
    changeLanguageSaga,
    preferencesChanged,
]
