import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'

import { globalMessagingRestService } from '../../dataaccess/globalMessaging/globalMessagingRestService'
import { NewGlobalMessage } from './input/NewGlobalMessage'
import { AosGlobalMessage } from './types/AosGlobalMessage'
import { AosGlobalMessageImpl } from './types/AosGlobalMessageImpl'

export class GlobalMessagingService {
    public loadMessages(): Promise<AosGlobalMessage[]> {
        return globalMessagingRestService
            .getAllMessages()
            .then(items => items.reverse().map(t => new AosGlobalMessageImpl(t)))
    }

    public broadcastMessage(message: NewGlobalMessage): Promise<void> {
        const { channels, groupId, ...restMessage } = message
        return globalMessagingRestService.broadcastMessage({
            ...restMessage,
            groupId: groupId!,
            sms: channels.includes(AosMessageChannel.Sms),
            email: channels.includes(AosMessageChannel.Email),
            dashboard: channels.includes(AosMessageChannel.Notification),
            push: channels.includes(AosMessageChannel.Push),
        })
    }
}

export const globalMessagingService = new GlobalMessagingService()
