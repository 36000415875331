import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Sort } from 'aos-ui/src/components/table/TableHeaderButton'
import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeSortingAction } from '../../../../core/flightInformation/actions'
import { FlightInfoField } from '../../../../core/flightInformation/flight/FlightInfoField'
import { sortingSelector } from '../../../../core/flightInformation/selectors'
import { FlightSort } from '../../../../core/flightInformation/state'
import { StandardFlightTable } from '../base/StandardFlightTable'

interface DelaysFlightsProps {
    flights: Flight[]
}

export const DelaysFlights: FC<DelaysFlightsProps> = memo(({ flights }) => {
    const dispatch = useDispatch()
    const sort = useSelector(sortingSelector(FlightSort.DELAYS))

    const onChangeSorting = (v: Sort<FlightInfoField>) =>
        dispatch(
            changeSortingAction({
                value: { field: v.key },
                type: FlightSort.DELAYS,
            }),
        )

    return <StandardFlightTable flights={flights} sort={sort} onChangeSorting={onChangeSorting} />
})
