import {
    EventManagerAction,
    LOAD_EVENTS_SUCCESS,
    LOAD_FEED_IN_SUCCESS,
    LOAD_OVERDUE_EVENTS_COUNT_SUCCESS,
    SET_EVENTS_FILTERING,
    SET_EVENTS_SORTING,
    SET_FEED_IN_FILTERING,
} from './actions'
import { EventManagerState, initialEventManagerState } from './state'

export function eventManagerReducer(
    state = initialEventManagerState,
    action: EventManagerAction,
): EventManagerState {
    switch (action.type) {
        case LOAD_FEED_IN_SUCCESS:
            return { ...state, feedInEvents: action.payload }

        case LOAD_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload,
            }

        case LOAD_OVERDUE_EVENTS_COUNT_SUCCESS:
            return {
                ...state,
                overdueEventsCount: action.payload,
            }

        case SET_FEED_IN_FILTERING:
            return {
                ...state,
                feedInFilter: action.payload,
            }

        case SET_EVENTS_FILTERING:
            return {
                ...state,
                eventsFilter: action.payload,
            }

        case SET_EVENTS_SORTING:
            return {
                ...state,
                eventsSorting: action.payload,
            }

        default:
            return state
    }
}
