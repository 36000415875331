export const getNeedlePath = (angle: number, length: number = 40, radius: number = 3) => {
    const halfPI = Math.PI / 2
    const rad = degToRad(angle)

    const centerX = 0
    const centerY = 0

    const topX = centerX - length * Math.cos(rad)
    const topY = centerY - length * Math.sin(rad)

    const leftX = centerX - radius * Math.cos(rad - halfPI)
    const leftY = centerY - radius * Math.sin(rad - halfPI)

    const rightX = centerX - radius * Math.cos(rad + halfPI)
    const rightY = centerY - radius * Math.sin(rad + halfPI)

    return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`
}

const degToRad = (deg: number) => (deg * Math.PI) / 180
