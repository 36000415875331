import { ChartContextProps } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { getGroupRange } from 'aos-ui-common/src/components/chart/types/Chart'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisProps } from './AxisProps'
import { HorizontalAxis } from './HorizontalAxis'

const defaultTickConfig = {
    defaultPadding: -5,
    defaultTickSize: 25,
}

const MIN_PX_FOR_TEXT_LETTER = 7

export abstract class GroupingAxis<T extends AxisDomain> extends HorizontalAxis<T> {
    protected abstract defaultTickFormat: f.Func1<T, string>
    protected hideLabelIfTooLong: boolean = false

    constructor(props: AxisProps<T> & ChartContextProps) {
        super(props, 'top')
    }

    protected renderTick = (groupStart: T, index: number) => {
        const { scale } = this.props

        const { tickClass, tickOffset, tickFormat, tickSize } = this.getTickConfig(groupStart, {
            defaultTickFormat: this.defaultTickFormat,
            ...defaultTickConfig,
        })
        const xTickOffset = scale.bandwidth ? scale.bandwidth() / 2 : 0

        const [left, right] = getGroupRange(groupStart, this.getGroupEnd(groupStart), scale)

        const xPos = (left + right) / 2 + xTickOffset
        const lineXPos = scale(groupStart)
        const label = tickFormat(groupStart)

        const showText =
            !this.hideLabelIfTooLong || right - left > label.length * MIN_PX_FOR_TEXT_LETTER
        return (
            <g className={tickClass} key={index}>
                {lineXPos && (
                    <line y2={-tickSize} transform={`translate(${lineXPos + xTickOffset} 0)`} />
                )}
                {showText && (
                    <text
                        y={-tickOffset}
                        dy='0.71em'
                        textAnchor='middle'
                        transform={`translate(${xPos} 0)`}
                    >
                        {label}
                    </text>
                )}
            </g>
        )
    }

    protected abstract getGroupEnd(groupStart: T): T
}
