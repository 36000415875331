import { sortedUnionBy } from 'aos-helpers/src/helpers/Array'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { groupBy } from 'lodash'

export interface TimePoint {
    time: DateTime
}

export interface TimeValuePoint extends TimePoint {
    value: number
}

export interface NullableTimeValuePoint extends TimePoint {
    /** null has a different meaning than "no point" - in line series, when there is no point at all,
     * the line will be interpolated in that place. For null, it will be split into two segments with a hole
     * where the null value is.
     */
    value: number | null
}

export const findTimePointInTime = <T extends TimePoint>(
    timePoints: T[],
    now: DateTime,
    allowedRangeInMinutes = 30,
): T | undefined => timePoints.find(tp => isTimePointInTime(tp, now, allowedRangeInMinutes))

export const isTimePointInTime = (
    pointTime: TimePoint,
    now: DateTime,
    allowedRangeInMinutes = 30,
) => {
    const offset = (time: DateTime, amount: number) => time.clone().add(amount, 'minutes')
    return pointTime.time.isBetween(
        offset(now, -allowedRangeInMinutes),
        offset(now, allowedRangeInMinutes),
    )
}

export const aggregateByTime = <S extends TimePoint, T extends TimePoint>(
    points: S[],
    timeGroupGetter: f.Func1<DateTime, DateTime>,
    aggregator: f.Func1<S[], Omit<T, 'time'>>,
    domain: DateTime[] = [],
): T[] => {
    const groupedByTime = groupBy(points, p => timeGroupGetter(p.time).valueOf())
    const pointTimes = Object.keys(groupedByTime).map(time => dateTime(Number.parseInt(time, 10)))
    const sortedDomain = sortedUnionBy(pointTimes, domain, time => time.valueOf())
    return sortedDomain.map(
        time =>
            (({
                time,
                ...aggregator(groupedByTime[time.valueOf()] || []),
            } as unknown) as T),
    )
}
