import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { recurrenceRecurrenceMonths } from 'aos-services/src/services/tasks/types/payload/RecurrencePayloadEnums'
import {
    defaultCustomOnTaskRecurrenceYearly,
    defaultOnTaskRecurrenceYearly,
    defaultTaskRecurrenceYearly,
    TaskRecurrenceYearlyValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceYearlyFormPayload'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { Box } from 'aos-ui/src/components/base/Box'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { NumberInput } from 'aos-ui/src/components/form/input/NumberInput'
import { LabeledRowFormElement } from 'aos-ui/src/components/form/labeled/LabeledRowFormElement'
import React, { FC, useState } from 'react'

import { RadioCheckbox } from '../../../common/form/RadioCheckbox'
import { RecurrenceCustomOnComponent } from './RecurrenceCustomOnComponent'
import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'

export interface RecurrenceOnState {
    customOn: boolean
    regularOn: boolean
}

export const TaskRecurrenceYearly: FC<TaskRecurrenceYearlyProps> = ({
    value,
    onChange,
    errors,
    canEdit,
}) => {
    if (value?.type !== TaskRecurrenceType.Yearly) {
        return null
    }
    const [recurrenceOn, setRecurrenceOn] = useState<RecurrenceOnState>({
        customOn: value.customOn !== undefined && value.customOn !== null,
        regularOn: value.customOn === undefined || value.customOn === null,
    })

    const changeOnRecurrenceType = (regularOn: boolean) => {
        if (canEdit) {
            setRecurrenceOn({
                regularOn: regularOn,
                customOn: !recurrenceOn.customOn,
            })
            regularOn
                ? onChange(defaultOnTaskRecurrenceYearly)
                : onChange(defaultCustomOnTaskRecurrenceYearly)
        }
    }

    const changeCustomOnRecurrenceType = (customOn: boolean) => {
        if (canEdit) {
            setRecurrenceOn({
                customOn: customOn,
                regularOn: !recurrenceOn.regularOn,
            })
            customOn
                ? onChange(defaultCustomOnTaskRecurrenceYearly)
                : onChange(defaultOnTaskRecurrenceYearly)
        }
    }

    const recurrenceMonthsCheckboxesComponent = () => (
        <Box row>
            <LabeledRowFormElement
                prefixLabel={translate('tasks.of')}
                suffixLabel=''
                marginVertical={8}
                marginLeft={30}
                isError={errors?.month}
                errorMessage={translate('tasks.yearly.months.error')}
                fullWidth
            >
                <Box marginLeft={14}>
                    <CheckboxDropdown
                        items={recurrenceRecurrenceMonths}
                        value={value.month}
                        allContent={translate('tasks.selected') + `: ${value.month.length}`}
                        partialContent={i => `Selected: ${i.length}`}
                        onChange={(month: number[]) => onChange({ month })}
                        valueRenderer={i => translate('tasks.recurrence-month-value.' + i)}
                        width={201}
                        outlined
                        disabled={!canEdit}
                    />
                </Box>
            </LabeledRowFormElement>
        </Box>
    )

    return (
        <>
            <LabeledRowFormElement
                prefixLabel={translate('tasks.every')}
                suffixLabel={translate('tasks.year')}
                marginVertical={8}
                isError={errors?.every}
                errorMessage={translate('tasks.every.error')}
            >
                <NumberInput
                    value={value.every}
                    onChange={every => onChange({ every })}
                    width={50}
                    fallback={defaultTaskRecurrenceYearly.every}
                    marginHorizontal={8}
                    marginLeft={20}
                    disabled={!canEdit}
                />
            </LabeledRowFormElement>
            <Box flex={1}>
                <Box row>
                    <RadioCheckbox
                        checked={recurrenceOn.regularOn}
                        onChange={regularOn => changeOnRecurrenceType(regularOn)}
                    />
                    <LabeledRowFormElement
                        prefixLabel={translate('tasks.on')}
                        suffixLabel={translate('tasks.day')}
                        marginVertical={8}
                        marginLeft={16}
                        isError={errors?.on}
                        errorMessage={translate('tasks.monthly.on.error')}
                    >
                        <NumberInput
                            value={value.on}
                            onChange={on => onChange({ on })}
                            width={50}
                            fallback={defaultTaskRecurrenceYearly.on}
                            marginHorizontal={8}
                            disabled={!canEdit}
                        />
                    </LabeledRowFormElement>
                </Box>
                {recurrenceMonthsCheckboxesComponent()}
            </Box>
            <Box flex={1}>
                <Box row fullWidth>
                    <RadioCheckbox
                        checked={recurrenceOn.customOn}
                        onChange={customOn => changeCustomOnRecurrenceType(customOn)}
                    />
                    <RecurrenceCustomOnComponent
                        value={value}
                        onChange={onChange}
                        errors={errors}
                        canEdit={canEdit}
                    />
                </Box>
                {recurrenceMonthsCheckboxesComponent()}
            </Box>
        </>
    )
}

export interface TaskRecurrenceYearlyProps extends TaskRecurrenceFormProps {
    errors?: TaskRecurrenceYearlyValidation
}
