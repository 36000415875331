import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'
import { chain, partition, sortBy } from 'lodash'

import { AosFirebaseEventDto } from '../../../dataaccess/firebaseEvents/types/AosFirebaseEventDto'
import { AosFirebaseEvent } from './AosFirebaseEvent'
import { invitationRoleOrder } from './InvitationStatus'
import { SplittedParticipants } from './PrivateChannelParticipant'
import { PrivateChannelParticipantImpl } from './PrivateChannelParticipantImpl'

export class AosFirebaseEventImpl
    extends WrapperObject<AosFirebaseEventDto>
    implements AosFirebaseEvent
{
    id: number
    constructor(value: AosFirebaseEventDto, id: string) {
        super(value)
        this.id = parseInt(id, 10)
    }

    get invitations() {
        return chain(this.value.invitations)
            .toPairs()
            .map(([key, value]) => new PrivateChannelParticipantImpl(value, key))
            .value()
    }

    participants(firebaseUid: string | null): SplittedParticipants {
        const [me, participants] = partition(this.invitations, t => t.firebaseUid === firebaseUid)
        return [
            me[0],
            sortBy(participants, participant => invitationRoleOrder[participant.invitationRole]),
        ]
    }

    get sendToSync(): number | undefined {
        return this.value.sendToSync ? this.value.sendToSync : undefined
    }

    get visibilityLayers(): string[] | undefined {
        return this.value.visibilityLayers ? this.value.visibilityLayers : undefined
    }
}
