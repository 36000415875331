export interface ModalSizeProps {
    modalKind: ModalKind
}

export enum ModalKind {
    Big,
    Icon,
    White,
    Medium,
    Standard,
    MediumFixedHeight,
    Small,
    Mobile,
    Auto,
    LessThanMedium,
}
