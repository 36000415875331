import { required, useBindForm, useForm } from 'aos-form'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    emptyNotesForm,
    ShiftNotesFormData,
} from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesFormData'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    closeShiftNotesModalAction,
    shiftNotesAddAction,
} from '../../../core/tasks/shiftNotes/actions'
import { isShiftNotesModalOpenSelector } from '../../../core/tasks/shiftNotes/selectors'
import { LabeledUserNamesAutocomplete } from '../common/form/LabeledUserNamesAutocomplete'

export const ShiftNotesEditModal = () => {
    const isOpen = useSelector(isShiftNotesModalOpenSelector)
    const dispatch = useDispatch()
    const form = useForm<ShiftNotesFormData>({
        initialState: emptyNotesForm,
        validators: {
            message: required(),
            reporter: required(),
        },
    })
    const onClose = () => {
        dispatch(closeShiftNotesModalAction())
        form.reset()
    }

    const { bindLabeledTextArea, bindDropdown } = useBindForm(form)
    const submit = form.submitHandler(data => {
        dispatch(shiftNotesAddAction(data))
        onClose()
    })
    return (
        <Modal
            id='shift-notes-edit-modal'
            title={translate('shift-notes.edit.title')}
            modalKind={ModalKind.Medium}
            closeAction={onClose}
            footer={<FormButton label={translate('shift-notes.edit.save')} onClick={submit} />}
            isOpen={isOpen}
        >
            <Box fullHeight column>
                <Box flex={1} padding={40}>
                    <LabeledTextArea
                        keyPrefix='shift-notes.form.note'
                        rows={14}
                        marginBottom={16}
                        {...bindLabeledTextArea('message')}
                    />

                    <LabeledUserNamesAutocomplete
                        keyPrefix='shift-notes.form.reporter'
                        {...bindDropdown('reporter')}
                    />
                </Box>
            </Box>
        </Modal>
    )
}
