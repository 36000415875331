import { cx } from 'aos-components/src/components/base/ClassNames'
import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { BaseUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import {
    addUserStatus,
    USER_FORM_READY,
    USER_PRE_CHECK,
} from 'aos-services/src/services/users/types/AosUserFindStatus'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { AosUserType } from 'aos-services/src/services/users/types/AosUserType'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { UnderlineTabs } from 'aos-ui/src/components/tabs/UnderlineTabs'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { EnumValues } from 'enum-values'
import React, { Component, ReactNode } from 'react'

import { removeUserAction } from '../../../core/groupManager/actions'
import { CommonUserActionCreators } from '../../../core/groupManager/manageUser/actions'
import { CommonManageUserState } from '../../../core/groupManager/manageUser/state'
import { NotificationSettingsForm } from '../../profile/NotificationSettings/NotificationSettingsForm'

enum TabId {
    UserSettings,
    NotificationSettings,
    PermissionSettings,
}

enum TabIdManualUser {
    UserSettings,
    NotificationSettings,
}

const tabIds = EnumValues.getValues<TabId>(TabId)
const tabIdsManualUser = EnumValues.getValues<TabIdManualUser>(TabIdManualUser)

const tabsLabels: Record<TabId, string> = {
    [TabId.UserSettings]: translate('manage-user.tabs.user-settings'),
    [TabId.NotificationSettings]: translate('manage-user.tabs.notification-settings'),
    [TabId.PermissionSettings]: translate('manage-user.tabs.permission-settings'),
}

export interface UserModalsProps {
    groups: AosUserGroup[]
    headerTitle: string
}

export abstract class UserModalsClass<
    T extends CommonManageUserState<U> & UserModalsProps & UserModalsDispatchProps,
    U extends BaseUserFormData
> extends Component<T> {
    public state: { activeTab: TabIdManualUser | TabId } = {
        activeTab: TabId.UserSettings,
    }

    public render() {
        const { userStatus } = this.props.user
        return (
            <>
                {userStatus === USER_PRE_CHECK && this.renderUserPreCheckForm()}
                {userStatus === USER_FORM_READY && this.renderUserForm()}
                {addUserStatus(userStatus) && this.renderUserStatus()}
            </>
        )
    }

    protected abstract renderFormContent(): ReactNode

    protected abstract renderPreCheckFormContent(): ReactNode

    protected abstract renderStatusContent(): ReactNode

    protected abstract renderPermissionSettings(): ReactNode

    protected abstract renderFooterContent(): ReactNode

    protected abstract saveUserAction(): void

    protected selectAll = (groups: AosUserGroup[]) => () =>
        this.props.selectAllGroupsAction(groups.map(g => g.id))

    protected removeUser = () => {
        if (!!this.props.conflictUserId) {
            this.props.removeUserAction(this.props.conflictUserId!)
            this.props.hideUserModalAction()
        }
    }

    private header = () => <span>{this.props.headerTitle}</span>

    private footer = () => (
        <Box row fullWidth>
            {this.renderFooterContent()}
            <FormButton label={translate('manage-user.save')} onClick={this.submitForm} />
        </Box>
    )

    private renderUserPreCheckForm = () => (
        <WhiteModal
            isOpen={this.props.isOpen}
            title={this.props.headerTitle}
            closeAction={this.props.hideUserModalAction}
        >
            {this.renderPreCheckFormContent()}
        </WhiteModal>
    )

    private renderUserForm = () => (
        <Modal
            id='create-user-modal'
            isOpen={this.props.isOpen}
            closeAction={this.props.hideUserModalAction}
            title={this.header()}
            footer={this.footer()}
            modalKind={ModalKind.Big}
            className={cx('user-modal')}
        >
            <Box className='user-modal__container' flex={1}>
                <Box fullHeight column>
                    <UnderlineTabs
                        items={
                            this.props.user.type === AosUserType.MANUAL ? tabIdsManualUser : tabIds
                        }
                        value={this.state.activeTab}
                        onChange={tabId => this.setState({ activeTab: tabId })}
                        nameFormatter={tabId => tabsLabels[tabId]}
                        paddingLeft={30}
                    />
                    <Box relative flex={1}>
                        {this.state.activeTab === TabId.UserSettings && this.renderFormContent()}
                        {this.state.activeTab === TabId.NotificationSettings &&
                            this.renderNotificationSettings()}
                        {this.state.activeTab === TabId.PermissionSettings &&
                            this.renderPermissionSettings()}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )

    private renderNotificationSettings() {
        const { notificationSettings, updateNotificationSettingsAction } = this.props

        if (!notificationSettings) {
            return <Spinner onLight />
        }

        return (
            <Box fullHeight column>
                <NotificationSettingsForm
                    settings={notificationSettings as AosUserNotificationsPreferences}
                    onUpdate={updateNotificationSettingsAction}
                />
            </Box>
        )
    }

    private renderUserStatus = () => (
        <WhiteModal
            isOpen={this.props.isOpen}
            title={translate(`manage-user.status.${this.props.user.userStatus!.toLowerCase()}`)}
            closeAction={this.props.hideUserModalAction}
        >
            {this.renderStatusContent()}
        </WhiteModal>
    )

    private submitForm = () => {
        if (!this.props.form.valid) {
            this.props.setFormPristineAction(false)
        } else {
            this.saveUserAction()
        }
    }
}

export interface UserModalsDispatchProps extends CommonUserActionCreators {
    removeUserAction: typeof removeUserAction
}
