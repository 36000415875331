import { emptyText } from 'aos-helpers/src/helpers/Text'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import {
    AosUnitTaskTemplate,
    isAosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { ByAtTextOptional } from 'aos-ui/src/components/text/ByAtTextOptional'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface ChecklistTemplateStatusProps {
    checklistTemplate: AosChecklistTemplate | AosUnitTaskTemplate
}

export const ChecklistTemplateStatus: FC<ChecklistTemplateStatusProps> = props => {
    const { lastUsed, createdBy, createdAt, reviewedBy, reviewedAt } = props.checklistTemplate
    const isUnitTask = isAosUnitTaskTemplate(props.checklistTemplate)
    const propPairs: PropsListEntry[] = [
        [
            'last-reviewed',
            reviewedBy ? <ByAtTextOptional key='0' by={reviewedBy} at={reviewedAt} /> : emptyText(),
        ],
        ['created', <ByAtTextOptional key='1' by={createdBy} at={createdAt} />],
        ['last-used', lastUsed ? formatCalendarTime(lastUsed) : emptyText()],
    ]
        .filter(q => q[1] !== emptyText())
        .map(pair => [`checklist-manager.checklist-status.${pair[0]}`, pair[1]] as [string, string])

    return (
        <Box fullHeight paddingHorizontal={30}>
            <Text
                className='text_header-white'
                marginTop={30}
                marginBottom={16}
                color={Color.White}
                size={18}
                lineHeight='standard'
            >
                {translate(
                    `${
                        isUnitTask
                            ? 'tasklist-manager.tasklist-status'
                            : 'checklist-manager.checklist-status'
                    }.label`,
                )}
            </Text>
            <PropsList
                items={propPairs}
                size={LabeledTextElementSize.Small}
                variant={ThemeVariant.Black}
            />
        </Box>
    )
}
