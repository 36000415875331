import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Sort, TableHeaderButton } from 'aos-ui/src/components/table/TableHeaderButton'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC, ReactNode } from 'react'

import { FlightInfoField } from '../../../core/flightInformation/flight/FlightInfoField'

export interface FlightTableHeaderSort {
    sortKey: string
    sort: Sort<FlightInfoField>
    onChange(v: Sort<FlightInfoField>): void
}

interface FlightTableHeaderProps {
    caption: string
    sort?: FlightTableHeaderSort
    filter?: ReactNode
}

export const FlightTableHeader: FC<FlightTableHeaderProps> = ({ caption, sort, filter }) => {
    return (
        <Text textAlign='center'>
            <Tooltip body={translate(`flight-information.table.${caption}.description`)} withArrow>
                <TableHeaderButton
                    sort={sort}
                    label={translate(`flight-information.table.${caption}.title`)}
                />
            </Tooltip>
            {filter}
        </Text>
    )
}
