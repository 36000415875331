// tslint:disable
/**
 * rata.digitraffic.fi
 * API for trains operating in Finland
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * A code that is used to categorize reasons for a train not being on schedule
 * @export
 * @interface CategoryCode
 */
export interface CategoryCode {
    /**
     * Official code
     * @type {string}
     * @memberof CategoryCode
     */
    categoryCode: string
    /**
     * Official name
     * @type {string}
     * @memberof CategoryCode
     */
    categoryName: string
    /**
     *
     * @type {number}
     * @memberof CategoryCode
     */
    id?: number
    /**
     *
     * @type {PassengerTerm}
     * @memberof CategoryCode
     */
    passengerTerm?: PassengerTerm
    /**
     * Start date when this code is used
     * @type {string}
     * @memberof CategoryCode
     */
    validFrom: string
    /**
     * End date when this code is used. Empty means category is used until further notice
     * @type {string}
     * @memberof CategoryCode
     */
    validTo?: string
}

/**
 * Details why a train is not on schedule. Train-responses only have ids and codes populated.
 * @export
 * @interface Cause
 */
export interface Cause {
    /**
     * Official code
     * @type {string}
     * @memberof Cause
     */
    categoryCode: string
    /**
     *
     * @type {number}
     * @memberof Cause
     */
    categoryCodeId?: number
    /**
     * Official name
     * @type {string}
     * @memberof Cause
     */
    categoryName: string
    /**
     * Detailed description
     * @type {string}
     * @memberof Cause
     */
    description?: string
    /**
     *
     * @type {string}
     * @memberof Cause
     */
    detailedCategoryCode?: string
    /**
     *
     * @type {number}
     * @memberof Cause
     */
    detailedCategoryCodeId?: number
    /**
     *
     * @type {string}
     * @memberof Cause
     */
    detailedCategoryName?: string
    /**
     *
     * @type {number}
     * @memberof Cause
     */
    id?: number
    /**
     *
     * @type {PassengerTerm}
     * @memberof Cause
     */
    passengerTerm?: PassengerTerm
    /**
     *
     * @type {string}
     * @memberof Cause
     */
    thirdCategoryCode?: string
    /**
     *
     * @type {number}
     * @memberof Cause
     */
    thirdCategoryCodeId?: number
    /**
     *
     * @type {string}
     * @memberof Cause
     */
    thirdCategoryName?: string
    /**
     * Start date when this code is used
     * @type {string}
     * @memberof Cause
     */
    validFrom: string
    /**
     * End date when this code is used. Empty means category is used until further notice
     * @type {string}
     * @memberof Cause
     */
    validTo?: string
}

/**
 * Describes train's locomotives and wagons
 * @export
 * @interface Composition
 */
export interface Composition {
    /**
     * Date of the train's first departure
     * @type {string}
     * @memberof Composition
     */
    departureDate: string
    /**
     *
     * @type {Array&lt;JourneySection&gt;}
     * @memberof Composition
     */
    journeySections?: Array<JourneySection>
    /**
     * Short code of the operator
     * @type {string}
     * @memberof Composition
     */
    operatorShortCode?: string
    /**
     * Official UIC code of the operator
     * @type {number}
     * @memberof Composition
     */
    operatorUICCode?: number
    /**
     *
     * @type {string}
     * @memberof Composition
     */
    trainCategory: string
    /**
     * Identifies the train inside a single departure date
     * @type {number}
     * @memberof Composition
     */
    trainNumber: number
    /**
     *
     * @type {string}
     * @memberof Composition
     */
    trainType: string
    /**
     * When was this data last modified
     * @type {number}
     * @memberof Composition
     */
    version?: number
}

/**
 * Describes a point in a trains schedule where its composition changes
 * @export
 * @interface CompositionTimeTableRow
 */
export interface CompositionTimeTableRow {
    /**
     *
     * @type {string}
     * @memberof CompositionTimeTableRow
     */
    countryCode?: string
    /**
     *
     * @type {Date}
     * @memberof CompositionTimeTableRow
     */
    scheduledTime?: Date
    /**
     *
     * @type {string}
     * @memberof CompositionTimeTableRow
     */
    stationShortCode?: string
    /**
     *
     * @type {number}
     * @memberof CompositionTimeTableRow
     */
    stationUICCode?: number
    /**
     *
     * @type {string}
     * @memberof CompositionTimeTableRow
     */
    type?: TimeTableRowTypeEnum
}

/**
 * Category code that is one step more detailed from its parent CategoryCode
 * @export
 * @interface DetailedCategoryCode
 */
export interface DetailedCategoryCode {
    /**
     *
     * @type {string}
     * @memberof DetailedCategoryCode
     */
    detailedCategoryCode?: string
    /**
     *
     * @type {string}
     * @memberof DetailedCategoryCode
     */
    detailedCategoryName?: string
    /**
     *
     * @type {number}
     * @memberof DetailedCategoryCode
     */
    id?: number
    /**
     *
     * @type {PassengerTerm}
     * @memberof DetailedCategoryCode
     */
    passengerTerm?: PassengerTerm
    /**
     * Start date when this category code is used
     * @type {string}
     * @memberof DetailedCategoryCode
     */
    validFrom: string
    /**
     * End date when this code is used. Empty means category is used until further notice
     * @type {string}
     * @memberof DetailedCategoryCode
     */
    validTo?: string
}

/**
 *
 * @export
 * @interface IterableTrainLocation
 */
export interface IterableTrainLocation {}

/**
 * Describes a leg where train's composition is in effect
 * @export
 * @interface JourneySection
 */
export interface JourneySection {
    /**
     * Point in schedule where composition starts
     * @type {CompositionTimeTableRow}
     * @memberof JourneySection
     */
    beginTimeTableRow?: CompositionTimeTableRow
    /**
     * Point in schedule where composition ends
     * @type {CompositionTimeTableRow}
     * @memberof JourneySection
     */
    endTimeTableRow?: CompositionTimeTableRow
    /**
     * List of locomotives used on this leg
     * @type {Array&lt;Locomotive&gt;}
     * @memberof JourneySection
     */
    locomotives?: Array<Locomotive>
    /**
     *
     * @type {number}
     * @memberof JourneySection
     */
    maximumSpeed?: number
    /**
     *
     * @type {number}
     * @memberof JourneySection
     */
    totalLength?: number
    /**
     * List of wagons used on this leg
     * @type {Array&lt;Wagon&gt;}
     * @memberof JourneySection
     */
    wagons?: Array<Wagon>
}

/**
 *
 * @export
 * @interface Locomotive
 */
export interface Locomotive {
    /**
     * Location of the locomotive in the train. 1=start of the train
     * @type {number}
     * @memberof Locomotive
     */
    location?: number
    /**
     *
     * @type {string}
     * @memberof Locomotive
     */
    locomotiveType?: string
    /**
     *
     * @type {string}
     * @memberof Locomotive
     */
    powerType?: string
}

/**
 *
 * @export
 * @interface Operator
 */
export interface Operator {
    /**
     *
     * @type {number}
     * @memberof Operator
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof Operator
     */
    operatorName?: string
    /**
     *
     * @type {string}
     * @memberof Operator
     */
    operatorShortCode?: string
    /**
     *
     * @type {number}
     * @memberof Operator
     */
    operatorUICCode?: number
    /**
     *
     * @type {Array&lt;OperatorTrainNumber&gt;}
     * @memberof Operator
     */
    trainNumbers?: Array<OperatorTrainNumber>
}

/**
 * Operators own a range of train numbers
 * @export
 * @interface OperatorTrainNumber
 */
export interface OperatorTrainNumber {
    /**
     * Where operator's train numbers start
     * @type {number}
     * @memberof OperatorTrainNumber
     */
    bottomLimit?: number
    /**
     *
     * @type {number}
     * @memberof OperatorTrainNumber
     */
    id?: number
    /**
     * Where operator's train numbers end
     * @type {number}
     * @memberof OperatorTrainNumber
     */
    topLimit?: number
    /**
     *
     * @type {string}
     * @memberof OperatorTrainNumber
     */
    trainCategory?: string
}

/**
 *
 * @export
 * @interface PassengerTerm
 */
export interface PassengerTerm {
    /**
     * English passenger friendly term for the code
     * @type {string}
     * @memberof PassengerTerm
     */
    en?: string
    /**
     * Finnish passenger friendly term for the code
     * @type {string}
     * @memberof PassengerTerm
     */
    fi?: string
    /**
     * Swedish passenger friendly term for the code
     * @type {string}
     * @memberof PassengerTerm
     */
    sv?: string
}

/**
 *
 * @export
 * @interface Station
 */
export interface Station {
    /**
     *
     * @type {string}
     * @memberof Station
     */
    countryCode?: string
    /**
     *
     * @type {number}
     * @memberof Station
     */
    latitude?: number
    /**
     *
     * @type {number}
     * @memberof Station
     */
    longitude?: number
    /**
     * Does this station have passenger traffic
     * @type {boolean}
     * @memberof Station
     */
    passengerTraffic?: boolean
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationName?: string
    /**
     *
     * @type {string}
     * @memberof Station
     */
    stationShortCode?: string
    /**
     *
     * @type {number}
     * @memberof Station
     */
    stationUICCode?: number
    /**
     * Type of station
     * @type {string}
     * @memberof Station
     */
    type?: StationTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum StationTypeEnum {
    STATION = <any>'STATION',
    STOPPINGPOINT = <any>'STOPPING_POINT',
    TURNOUTINTHEOPENLINE = <any>'TURNOUT_IN_THE_OPEN_LINE',
}

/**
 *
 * @export
 * @interface SwaggerPoint
 */
export interface SwaggerPoint {
    /**
     *
     * @type {Array&lt;number&gt;}
     * @memberof SwaggerPoint
     */
    coordinates?: Array<number>
    /**
     *
     * @type {string}
     * @memberof SwaggerPoint
     */
    type?: string
}

/**
 * Most detailed category code for a Cause
 * @export
 * @interface ThirdCategoryCode
 */
export interface ThirdCategoryCode {
    /**
     * Detailed description
     * @type {string}
     * @memberof ThirdCategoryCode
     */
    description?: string
    /**
     *
     * @type {number}
     * @memberof ThirdCategoryCode
     */
    id?: number
    /**
     *
     * @type {PassengerTerm}
     * @memberof ThirdCategoryCode
     */
    passengerTerm?: PassengerTerm
    /**
     *
     * @type {string}
     * @memberof ThirdCategoryCode
     */
    thirdCategoryCode?: string
    /**
     *
     * @type {string}
     * @memberof ThirdCategoryCode
     */
    thirdCategoryName?: string
    /**
     * Start date when this category code is used
     * @type {string}
     * @memberof ThirdCategoryCode
     */
    validFrom: string
    /**
     * End date when this code is used. Empty means category is used until further notice
     * @type {string}
     * @memberof ThirdCategoryCode
     */
    validTo?: string
}

/**
 * A part of train's schedule
 * @export
 * @interface TimeTableRow
 */
export interface TimeTableRow {
    /**
     * Actual time when train departured or arrived on the station
     * @type {Date}
     * @memberof TimeTableRow
     */
    actualTime?: Date
    /**
     * Is the schedule part cancelled
     * @type {boolean}
     * @memberof TimeTableRow
     */
    cancelled?: boolean
    /**
     *
     * @type {Array&lt;Cause&gt;}
     * @memberof TimeTableRow
     */
    causes?: Array<Cause>
    /**
     * Is the stop 'commercial' ie. loading/unloading of passengers or cargo
     * @type {boolean}
     * @memberof TimeTableRow
     */
    commercialStop?: boolean
    /**
     * Track where the train stops
     * @type {string}
     * @memberof TimeTableRow
     */
    commercialTrack?: string
    /**
     *
     * @type {string}
     * @memberof TimeTableRow
     */
    countryCode?: string
    /**
     * Difference between schedule and actual time in minutes
     * @type {number}
     * @memberof TimeTableRow
     */
    differenceInMinutes?: number
    /**
     * Source for the estimate
     * @type {string}
     * @memberof TimeTableRow
     */
    estimateSource?: TimeTableRowEstimateSourceEnum
    /**
     * Estimated time for departure/arrival of the train
     * @type {Date}
     * @memberof TimeTableRow
     */
    liveEstimateTime?: Date
    /**
     * Scheduled time for departure/arrival of the train
     * @type {Date}
     * @memberof TimeTableRow
     */
    scheduledTime?: Date
    /**
     *
     * @type {string}
     * @memberof TimeTableRow
     */
    stationShortCode?: string
    /**
     *
     * @type {number}
     * @memberof TimeTableRow
     */
    stationUICCode?: number
    /**
     *
     * @type {TrainReady}
     * @memberof TimeTableRow
     */
    trainReady?: TrainReady
    /**
     * Does the train actual stop on the station
     * @type {boolean}
     * @memberof TimeTableRow
     */
    trainStopping?: boolean
    /**
     *
     * @type {string}
     * @memberof TimeTableRow
     */
    type?: TimeTableRowTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum TimeTableRowEstimateSourceEnum {
    LIIKEUSER = <any>'LIIKE_USER',
    MIKUUSER = <any>'MIKU_USER',
    LIIKEAUTOMATIC = <any>'LIIKE_AUTOMATIC',
    DIGITRAFFICAUTOMATIC = <any>'DIGITRAFFIC_AUTOMATIC',
}
/**
 * @export
 * @enum {string}
 */
export enum TimeTableRowTypeEnum {
    ARRIVAL = <any>'ARRIVAL',
    DEPARTURE = <any>'DEPARTURE',
}

/**
 * A location on the track. Kilometres and meters are distance from the start of the track
 * @export
 * @interface TrackLocation
 */
export interface TrackLocation {
    /**
     *
     * @type {number}
     * @memberof TrackLocation
     */
    kilometres?: number
    /**
     *
     * @type {number}
     * @memberof TrackLocation
     */
    metres?: number
    /**
     *
     * @type {string}
     * @memberof TrackLocation
     */
    track?: string
}

/**
 *
 * @export
 * @interface TrackRange
 */
export interface TrackRange {
    /**
     *
     * @type {TrackLocation}
     * @memberof TrackRange
     */
    endLocation?: TrackLocation
    /**
     *
     * @type {number}
     * @memberof TrackRange
     */
    id?: number
    /**
     *
     * @type {TrackLocation}
     * @memberof TrackRange
     */
    startLocation?: TrackLocation
}

/**
 *
 * @export
 * @interface TrackSection
 */
export interface TrackSection {
    /**
     *
     * @type {number}
     * @memberof TrackSection
     */
    id?: number
    /**
     *
     * @type {Array&lt;TrackRange&gt;}
     * @memberof TrackSection
     */
    ranges?: Array<TrackRange>
    /**
     *
     * @type {string}
     * @memberof TrackSection
     */
    station?: string
    /**
     *
     * @type {string}
     * @memberof TrackSection
     */
    trackSectionCode?: string
}

/**
 *
 * @export
 * @interface Train
 */
export interface Train {
    /**
     * Is the train wholly cancelled
     * @type {boolean}
     * @memberof Train
     */
    cancelled?: boolean
    /**
     *
     * @type {string}
     * @memberof Train
     */
    commuterLineID?: string
    /**
     * Is the train deleted which means cancelled 10 days before its departure date
     * @type {boolean}
     * @memberof Train
     */
    deleted?: boolean
    /**
     * Date of the train's first departure
     * @type {string}
     * @memberof Train
     */
    departureDate: string
    /**
     * Short code of the operator
     * @type {string}
     * @memberof Train
     */
    operatorShortCode?: string
    /**
     * Official UIC code of the operator
     * @type {number}
     * @memberof Train
     */
    operatorUICCode?: number
    /**
     * Is the train running currently or does it have actual times
     * @type {boolean}
     * @memberof Train
     */
    runningCurrently?: boolean
    /**
     *
     * @type {Array&lt;TimeTableRow&gt;}
     * @memberof Train
     */
    timeTableRows?: Array<TimeTableRow>
    /**
     * When was this train accepted to run on Finnish railways by the FTA
     * @type {Date}
     * @memberof Train
     */
    timetableAcceptanceDate?: Date
    /**
     * Is the train ADHOC or REGULAR. REGULAR trains are run for example every monday, ADHOC trains are one-time trains
     * @type {string}
     * @memberof Train
     */
    timetableType?: TrainTimetableTypeEnum
    /**
     *
     * @type {string}
     * @memberof Train
     */
    trainCategory?: string
    /**
     * Identifies the train inside a single departure date
     * @type {number}
     * @memberof Train
     */
    trainNumber: number
    /**
     *
     * @type {string}
     * @memberof Train
     */
    trainType?: string
    /**
     * When was train last modified
     * @type {number}
     * @memberof Train
     */
    version?: number
}

/**
 * @export
 * @enum {string}
 */
export enum TrainTimetableTypeEnum {
    REGULAR = <any>'REGULAR',
    ADHOC = <any>'ADHOC',
}

/**
 *
 * @export
 * @interface TrainCategory
 */
export interface TrainCategory {
    /**
     *
     * @type {string}
     * @memberof TrainCategory
     */
    name?: string
}

/**
 *
 * @export
 * @interface TrainLocation
 */
export interface TrainLocation {
    /**
     *
     * @type {string}
     * @memberof TrainLocation
     */
    departureDate?: string
    /**
     *
     * @type {SwaggerPoint}
     * @memberof TrainLocation
     */
    location?: SwaggerPoint
    /**
     *
     * @type {number}
     * @memberof TrainLocation
     */
    speed?: number
    /**
     *
     * @type {Date}
     * @memberof TrainLocation
     */
    timestamp?: Date
    /**
     *
     * @type {number}
     * @memberof TrainLocation
     */
    trainNumber?: number
}

/**
 * Operator has to ask permission (=TrainReady) to leave certain stations
 * @export
 * @interface TrainReady
 */
export interface TrainReady {
    /**
     * Was the permission given
     * @type {boolean}
     * @memberof TrainReady
     */
    accepted?: boolean
    /**
     * How was the permission given
     * @type {string}
     * @memberof TrainReady
     */
    source?: TrainReadySourceEnum
    /**
     * When was the permission given
     * @type {Date}
     * @memberof TrainReady
     */
    timestamp?: Date
}

/**
 * @export
 * @enum {string}
 */
export enum TrainReadySourceEnum {
    PHONE = <any>'PHONE',
    LIIKE = <any>'LIIKE',
    UNKNOWN = <any>'UNKNOWN',
    KUPLA = <any>'KUPLA',
}

/**
 * TrainRunMessages are generated when a train either enters or exists a TrackSection
 * @export
 * @interface TrainRunningMessage
 */
export interface TrainRunningMessage {
    /**
     * Date of the train's first departure
     * @type {string}
     * @memberof TrainRunningMessage
     */
    departureDate: string
    /**
     *
     * @type {number}
     * @memberof TrainRunningMessage
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    nextStation?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    nextTrackSection?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    previousStation?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    previousTrackSection?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    station?: string
    /**
     * Timestamp when the message was generated
     * @type {Date}
     * @memberof TrainRunningMessage
     */
    timestamp?: Date
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessage
     */
    trackSection?: string
    /**
     * Identifies the train inside a single departure date
     * @type {string}
     * @memberof TrainRunningMessage
     */
    trainNumber: string
    /**
     * OCCUPY = train entered TrackSection, RELEASE=train exited TrackSection
     * @type {string}
     * @memberof TrainRunningMessage
     */
    type?: TrainRunningMessageTypeEnum
    /**
     *
     * @type {number}
     * @memberof TrainRunningMessage
     */
    version?: number
}

/**
 * @export
 * @enum {string}
 */
export enum TrainRunningMessageTypeEnum {
    OCCUPY = <any>'OCCUPY',
    RELEASE = <any>'RELEASE',
}

/**
 * These rules define how TrainRunningMessages trigger actual times for TimeTableRows
 * @export
 * @interface TrainRunningMessageRule
 */
export interface TrainRunningMessageRule {
    /**
     *
     * @type {number}
     * @memberof TrainRunningMessageRule
     */
    id?: number
    /**
     * TrainRunningMessages timestamp is adjusted for offset when generating actual time
     * @type {number}
     * @memberof TrainRunningMessageRule
     */
    offset?: number
    /**
     * For which station is the actual time generated
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    timeTableRowStationShortCode?: string
    /**
     * For which TimeTableRow is the actual time generated
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    timeTableRowType?: TimeTableRowTypeEnum
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    trainRunningMessageNextStationShortCode?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    trainRunningMessageStationShortCode?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    trainRunningMessageTrackSection?: string
    /**
     *
     * @type {string}
     * @memberof TrainRunningMessageRule
     */
    trainRunningMessageType?: TrainRunningMessageRuleTrainRunningMessageTypeEnum
}

/**
 * @export
 * @enum {string}
 */
/**
 * @export
 * @enum {string}
 */
export enum TrainRunningMessageRuleTrainRunningMessageTypeEnum {
    OCCUPY = <any>'OCCUPY',
    RELEASE = <any>'RELEASE',
}
/**
 *
 * @export
 * @interface TrainType
 */
export interface TrainType {
    /**
     *
     * @type {string}
     * @memberof TrainType
     */
    name?: string
    /**
     *
     * @type {TrainCategory}
     * @memberof TrainType
     */
    trainCategory?: TrainCategory
}

/**
 *
 * @export
 * @interface Wagon
 */
export interface Wagon {
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    catering?: boolean
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    disabled?: boolean
    /**
     * Wagon length in decimeters
     * @type {number}
     * @memberof Wagon
     */
    length?: number
    /**
     *
     * @type {number}
     * @memberof Wagon
     */
    location?: number
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    luggage?: boolean
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    pet?: boolean
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    playground?: boolean
    /**
     * wagon number in customer's ticket
     * @type {number}
     * @memberof Wagon
     */
    salesNumber?: number
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    smoking?: boolean
    /**
     *
     * @type {boolean}
     * @memberof Wagon
     */
    video?: boolean
    /**
     *
     * @type {string}
     * @memberof Wagon
     */
    wagonType?: string
}
