import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { EventFormPayload } from 'aos-services/src/services/events/input/EventPayload'
import { BaseAosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'

import { EventModalExtendedFormValidationState } from '../../../core/eventModals/state'

export type EventExtendedFormProps = EventFormProps<EventModalExtendedFormValidationState>

export interface EventFormProps<V extends object> {
    currentEvent: EventFormPayload
    form: FormValidation<V>
    isApoc: boolean
    isEditingEvent?: boolean
    groups: AosUserGroup[]

    attachmentsState: AttachmentsState

    location?: BaseAosMapEvent
    attachmentsAction(a: AttachmentsAction): void
    resetLocation?(): void

    changeForm(v: Partial<EventFormPayload>): void
    setViewMode(v: DialogView): void
    formType: EventFormType
}

export enum EventFormType {
    ActivateEventModal,
    EditEventModal,
    CreateEventModal,
    ReportSimpleFeedInModal,
    ReportExtendedFeedInModal,
}
