import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    createTaskChecklistTemplateAction,
    hideCreateTaskChecklistTemplateModalAction,
} from '../../../../core/checklistManager/task/actions'
import { State } from '../../../../core/state'
import { TaskChecklistModal } from './TaskChecklistModal'

export const AddTaskChecklistModal = () => {
    const dispatch = useDispatch()

    const onSubmit = (item: AosTaskChecklistTemplate) => {
        dispatch(createTaskChecklistTemplateAction(item))
    }
    const isOpen = useSelector(
        (state: State) => state.checklistManager.taskChecklistManager.isCreateModalOpen,
    )

    return (
        <TaskChecklistModal
            isOpen={isOpen}
            keyPrefix='create-checklist-template'
            onHide={() => dispatch(hideCreateTaskChecklistTemplateModalAction())}
            onSubmit={onSubmit}
        />
    )
}
