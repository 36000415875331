import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate } from 'aos-helpers/src/helpers/TimeFormat'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { RoundTabItem } from 'aos-ui/src/components/tabs/RoundTabItem'
import React, { FCWithChildren } from 'react'

import { DatetimeInput, DatetimeInputProps } from './DatetimeInput'

export const RoundDatePicker: FCWithChildren<DatetimeInputProps> = props => (
    <DatetimeInput {...props}>
        <RoundTabItem active>
            <Box row cursor='pointer'>
                <Box paddingRight={8}>{formatDate(dateTime(props.value))}</Box>
                <Icon
                    iconVariant={IconVariant.White}
                    iconSize={BlockSize.Small}
                    svg={SvgIcon.Datapicker}
                />
            </Box>
        </RoundTabItem>
    </DatetimeInput>
)
