import { arc, PieArcDatum } from 'd3-shape'
import { transparentize } from 'polished'

import { getColor } from '../helpers/getColor'
import { getCurrentValueInRange } from '../helpers/getCurrentValueInRange'
import { isActive } from '../helpers/isActive'
import { GaugeArc } from '../types/GaugeArc'
import { GaugeOptions } from '../types/GaugeOptions'
import { GaugeRange } from '../types/GaugeRange'
import { getArcInnerOuterRadius } from './getArcInnerOuterRadius'

export const createArc = (datum: PieArcDatum<GaugeRange>, options: GaugeOptions): GaugeArc[] => {
    const { size } = options
    const { activeArcSize = size / 7, activeArcLightSize = size * 4 } = options

    const fill = getColor(datum.data.status)
    const value = getCurrentValueInRange(options)
    const active = isActive(value, datum.data)

    const additionalRadius = active ? activeArcSize : 0
    const arcs: GaugeArc[] = []

    const path = arcPath(datum, options, additionalRadius)
    arcs.push({ path, fill })

    if (active) {
        const lightPath = arcPath(datum, options, activeArcLightSize)
        const lightFill = transparentize(0.9, fill)
        arcs.push({ path: lightPath, fill: lightFill })
    }

    return arcs
}

const arcPath = (datum: PieArcDatum<GaugeRange>, options: GaugeOptions, additionalRadius = 0) => {
    const { innerRadius, outerRadius } = getArcInnerOuterRadius(options, additionalRadius)

    const arcArcGenerator = arc<PieArcDatum<GaugeRange>>()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)

    return arcArcGenerator(datum) as string
}
