import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'

export interface CommonState {
    currentTime: DateTime
}

export const initialCommonState: CommonState = {
    currentTime: dateTime().startOf('minute'),
}

export interface CommonStateAware {
    common: CommonState
}
