import { curveCatmullRom, line } from 'd3-shape'

import { AxisScale } from '../types/AxisScale'
import { ChartScales, ChartXYAccessors } from '../types/Chart'
import { ChartLineSeriesConfig } from '../types/ChartSeries'

export const lineX = <T, TX>(d: T, xScale: AxisScale<TX>, xAccessor: f.Func1<T, TX>) =>
    (xScale(xAccessor(d)) || 0) + (xScale.bandwidth ? xScale.bandwidth() / 2 : 0)

export const createLine = <T, TX, TY>(
    data: T[],
    scales: ChartScales<TX, TY>,
    accessors: ChartXYAccessors<T, TX, TY>,
    seriesConfig: ChartLineSeriesConfig<T>,
) => {
    const lineSeries = line<T>()
        .x(d => lineX(d, scales.xScale, accessors.xAccessor))
        .y(d => scales.yScale(accessors.yAccessor(d)) || 0)

    if (seriesConfig.isCurved) {
        lineSeries.curve(curveCatmullRom)
    }

    lineSeries.defined(d => accessors.yAccessor(d) !== null)

    return {
        path: lineSeries(data),
    }
}
