import React, { CSSProperties, FCWithChildren, SVGAttributes } from 'react'

import { SvgProps } from '../base/SvgProps'

export interface SvgImageProps extends SvgProps {
    style?: CSSProperties
    attributes?: SVGAttributes<HTMLDivElement>
    className?: string
}

export const SvgImage: FCWithChildren<SvgImageProps> = props => {
    const SvgItem = props.svg
    const attributes = props.attributes || {}
    return <SvgItem className={props.className} style={props.style} {...attributes} />
}
