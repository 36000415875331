import { AxisTicks } from 'aos-components/src/components/chart/axes/AxisTicks'
import { LegacyChart } from 'aos-components/src/components/chart/LegacyChart'
import { BlockSeries } from 'aos-components/src/components/chart/series/BlockSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    FreezingPhenomenon,
    FreezingPhenomenonType,
} from 'aos-services/src/services/airportStatus/weather/types/FreezingPhenomenon'
import {
    FreezingPhenomenonPoint,
    trimFreezingPhenomenonPointsToTimeRange,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherPoints'
import { WeatherOtherSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import {
    ChartConfig,
    ChartScaleType,
    getRanges,
} from 'aos-ui-common/src/components/chart/types/Chart'
import { AxisScale } from 'd3-axis'
import React, { Component } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import { chartIconOffset } from '../WeatherConsts'
import { WeatherIcons } from './WeatherIcons'

export const seriesIconPadding = 12

class WeatherPhenomenonChartClass extends Component<WeatherPhenomenonChartProps & SizeMeProps> {
    private translatePhenomenon = translateEnum<FreezingPhenomenonType>(
        FreezingPhenomenonType,
        'dashboard.weather.phenomenon',
    )

    public render() {
        const { chartConfig, size, seriesData, type } = this.props
        const config = this.scalesConfig
        const fwpVariant = this.props.seriesData.length ? IconVariant.Yellow : IconVariant.White
        const domain = config[ChartScaleType.X].scale.domain() as [DateTime, DateTime]
        const filteredPoints = trimFreezingPhenomenonPointsToTimeRange(seriesData, domain)

        return (
            <Box className='block-chart'>
                <LegacyChart chartConfig={chartConfig} size={size}>
                    <AxisTicks
                        axisConfig={{ tickValues: config[ChartScaleType.X].tickValues }}
                        scale={config[ChartScaleType.X].scale}
                    />
                    <BlockSeries
                        name={this.props.name}
                        data={filteredPoints}
                        scale={config[ChartScaleType.X].scale}
                        labelFormatter={(t: FreezingPhenomenon) => this.translatePhenomenon(t.type)}
                        valueFormatter={(t: FreezingPhenomenon) => t.value}
                    />
                    <WeatherIcons
                        components={[type]}
                        left={chartIconOffset}
                        top={seriesIconPadding}
                        iconVariant={fwpVariant}
                    />
                </LegacyChart>
            </Box>
        )
    }

    private get scalesConfig(): ScalesConfig {
        const { chartConfig, xDomain } = this.props
        const { xRange } = getRanges(chartConfig.margins, this.size)

        const xScale = Scales.scaleDateTime(xDomain.domain, xRange)

        return {
            [ChartScaleType.X]: {
                scale: xScale,
                tickValues: xDomain.ticks,
            },
        }
    }

    private get size() {
        return { width: this.props.size.width || 0, height: this.props.size.height || 0 }
    }
}

interface DateConfig {
    scale: AxisScale<DateTime>
    tickValues: DateTime[]
}

interface ScalesConfig {
    [ChartScaleType.X]: DateConfig
}

export const WeatherPhenomenonChart = sizeMe({ monitorHeight: true })(WeatherPhenomenonChartClass)

export interface WeatherPhenomenonChartProps {
    name: string
    chartConfig: ChartConfig
    seriesData: FreezingPhenomenonPoint[]
    xDomain: Domain<DateTime>
    type: WeatherOtherSeriesType
}
