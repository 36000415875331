import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { eventsService } from '../../../services/events/eventsService'
import { AosDashboardEvent } from '../../../services/events/types/AosDashboardEvent'
import { firebaseService } from '../../../services/firebase/firebaseService'
import {
    EVENTS_LOAD,
    EVENTS_SYNC,
    EVENTS_SYNC_STOP,
    eventsLoadAction,
    eventsLoadSuccessAction,
    EventsSyncAction,
    EventsSyncStopAction,
} from './actions'

function* eventsSyncSaga() {
    yield takeEvery<EventsSyncAction>(EVENTS_SYNC, function* (action: EventsSyncAction) {
        yield* firebaseService.onListener(
            'events',
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.DASHBOARD),
                    eventsLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* eventsSyncStopSaga() {
    yield takeEvery<EventsSyncStopAction>(
        EVENTS_SYNC_STOP,
        function* (action: EventsSyncStopAction) {
            yield firebaseService.offListener('events', action.payload)
        },
    )
}

function* eventsLoadSaga() {
    yield takeEvery(EVENTS_LOAD, function* () {
        const data: AosDashboardEvent[] = yield call(eventsService.loadDashboardEvents)
        yield put(eventsLoadSuccessAction(data))
    })
}

export const eventsSagas = [eventsSyncSaga, eventsSyncStopSaga, eventsLoadSaga]
