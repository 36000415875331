import { DateTime } from 'aos-helpers/src/helpers/Time'

import { InspectionGroup, translateInspectionGroup } from './InspectionGroup'

export interface InspectionType {
    id: number
    name: string
    inspectionGroup: InspectionGroup
    period?: number
    taskEndTime?: DateTime
}

export const translateInspectionType = (v: InspectionType) =>
    `${translateInspectionGroup(v.inspectionGroup)} / ${v.name}`
