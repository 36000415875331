import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

export interface ChartTitleProps {
    withLeftColumn?: boolean
}

export const ChartTitle: FC<PropsWithChildren<ChartTitleProps>> = props => (
    <ChartTitleBox
        color={Color.White}
        size={13}
        paddingVertical={20}
        weight='regular'
        withLeftColumn={props.withLeftColumn}
    >
        {props.children}
    </ChartTitleBox>
)

const ChartTitleBox = styled(Text)<{ withLeftColumn?: boolean }>`
    padding-left: ${p => (p.withLeftColumn ? 48 : 0)}px;
`
