import { AosFlightRoute } from 'aos-services/src/services/flightInformation/types/AosFlightRoute'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { compact } from 'lodash'
import React, { PureComponent } from 'react'

import { ListTooltipBody } from './ListTooltipBody'

interface RouteCellProps {
    row: Flight
}

export class RouteCell extends PureComponent<RouteCellProps> {
    public render() {
        const { routes } = this.props.row
        const airportNames = compact(routes.map(r => r.airportName))
        return (
            <Tooltip body={<ListTooltipBody items={airportNames} />} withArrow>
                <ul className='flight-table-row--routes'>{routes.map(this.renderRoute)}</ul>
            </Tooltip>
        )
    }

    private renderRoute = (r: AosFlightRoute, i: number) => <li key={i}>{r.airport}</li>
}
