import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, forwardRef, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'

import { Box } from '../base/Box'
import { Text } from '../base/Text'

export interface Step {
    title: string
    content: JSX.Element
}

interface StepperProps {
    steps: Step[]
    onStepChange?(step: number): void
}

export interface StepperRef {
    changeStep: (step: number) => void
    prevStep: () => void
    nextStep: () => void
}

const NumberOfEllipses = 3

export const Stepper = forwardRef<StepperRef, StepperProps>(({ steps, onStepChange }, ref) => {
    const [activeStep, setActiveStep] = useState(0)
    useImperativeHandle(
        ref,
        () => ({
            changeStep,
            nextStep: () => activeStep < steps.length && changeStep(activeStep + 1),
            prevStep: () => activeStep > 0 && changeStep(activeStep - 1),
        }),
        [ref],
    )

    const changeStep = (step: number) => {
        setActiveStep(step)
        onStepChange && onStepChange(step)
    }

    return (
        <Box column>
            <Box column justify='center'>
                <Box
                    width='95%'
                    justify='space-between'
                    alignContent='center'
                    alignSelf='center'
                    row
                >
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <Step
                                key={index}
                                title={step.title}
                                index={index}
                                active={index <= activeStep}
                                onClick={() => changeStep(index)}
                                isCompleted={index < activeStep}
                            />
                            {index !== steps.length - 1 &&
                                Array(NumberOfEllipses)
                                    .fill(0)
                                    .map((_, i) => (
                                        <Ellipse
                                            key={i}
                                            light={index < activeStep ? NumberOfEllipses - i : i}
                                        />
                                    ))}
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
            <Box flex={1} paddingTop={30}>
                {steps[activeStep].content}
            </Box>
        </Box>
    )
})

interface StepProps {
    title: string
    index: number
    active: boolean
    onClick: () => void
    isCompleted?: boolean
}

const Step: FC<StepProps> = ({ index, active, title, onClick, isCompleted }) => {
    return (
        <Box width={50} column alignItems='center'>
            <Box alignItems='center' row>
                <StepBox completed={!!isCompleted} active={active} onClick={onClick}>
                    <Text weight={active && !isCompleted ? 'bold' : 'light'} size={22}>
                        {index + 1}
                    </Text>
                </StepBox>
            </Box>
            <Text
                weight={active && !isCompleted ? 'medium' : 'regular'}
                color={Color.UiBlack1}
                marginTop={8}
                size={14}
            >
                {title}
            </Text>
        </Box>
    )
}

const StepBox = styled(Box)<{ active: boolean; completed: boolean }>`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: inline-flex;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    ${({ active, completed }) => {
        const rgb = hexToRGB(Color.PrimaryLight)
        const bg = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${completed ? 0.6 : 1})`
        const borderColor = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${completed ? 0.1 : 1})`
        return active
            ? `
        background-color: ${bg};
        color: ${Color.White};
        border: 2px solid ${borderColor};
    `
            : `
        color: ${Color.Grey7};
        border: 2px solid ${Color.PrimaryPale};
    `
    }}
`

const Ellipse = styled(Box)<{ light: number }>`
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: -18px 5px 0 5px;
    background-color: ${({ light }) => {
        const rgb = hexToRGB(Color.PrimaryPale)
        return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${1 - light * 0.2})`
    }};
`

const hexToRGB = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}
