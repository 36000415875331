import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { attachmentReducer } from 'aos-services/src/core/attachments/reducer'
import { timelineReducer } from 'aos-services/src/core/timeline/reducer'
import { newBaseAosMapTask } from 'aos-services/src/services/map/types/MapTask'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { initialBaseTaskPayload } from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import {
    migrateTaskPayload,
    TaskFormPayload,
    taskPayloadFromTask,
    validateTaskPayload,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { mapTaskForTask } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskStatus } from 'aos-services/src/services/tasks/types/TaskStatus'
import { PICK_LOCATION } from 'aos-ui-map/src/core/actions'
import { mapReducer } from 'aos-ui-map/src/core/reducer'
import { setPickedLocationFromTask } from 'aos-ui-map/src/core/state'
import { xor } from 'lodash'
import moment from 'moment/moment'

import {
    CHANGE_FILTER,
    CHANGE_FORM,
    CHANGE_STATUS,
    CLOSE_TASK_DURATION_MODAL,
    LOAD_ANIMALS_REPORT_SUCCESS,
    LOAD_CHEMICALS_REPORT_SUCCESS,
    LOAD_METADATA_SUCCESS,
    LOAD_TASK,
    LOAD_TASK_FAILED,
    LOAD_TASK_SUCCESS,
    LOAD_TASKS,
    LOAD_TASKS_SUCCESS,
    LOAD_TIMELINE_TASKS_SUCCESS,
    OPEN_TASK_DURATION_MODAL,
    OPEN_TASK_FOR_CLONE,
    OPEN_TASK_FOR_EDIT,
    PREVIEW_TASK_LOCATION,
    RESET_TASK_MODAL_STATE,
    SAVE_LOCATION,
    SAVE_TASK_SUCCESS,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SET_FORM_PRISTINE,
    SET_PREVIEW_LOCATION_MODAL_OPEN,
    SET_TASK_CREATE_MODAL_OPEN,
    SET_TASK_EDIT_MODAL_OPEN,
    SET_TIMELINE_TASK_FILTER,
    TASK_EDIT_MAP_PARENT,
    TASK_MANAGER_ATTACHMENTS_PARENT,
    TASK_MANAGER_TIMELINE_PARENT,
    TaskManagerAction,
    TOGGLE_GROUP,
} from './actions'
import { initialTaskModalState, initialTasksState, TasksState, withCustomizedMap } from './state'

export const taskManagerReducer = (
    state = initialTasksState,
    action: TaskManagerAction,
): TasksState => {
    switch (action.type) {
        case OPEN_TASK_FOR_CLONE:
            const [task, siteLocation] = action.payload
            const taskPayload: TaskFormPayload = {
                ...taskPayloadFromTask(task, true),
                id: undefined,
                parentTaskId: undefined,
                instanceId: undefined,
                location: undefined,
                status: TaskStatus.Todo,
                recurrence: initialBaseTaskPayload.recurrence,
                startTime: moment(),
                endTime: moment().add(1, 'hours'),
                spendTimeInMinutes: undefined,
            }

            return withCustomizedMap(
                {
                    ...state,
                    payload: taskPayload,
                    attachmentsState: initialTaskModalState.attachmentsState,
                    isCreateModalOpen: true,
                    form: state.form.modify(validateTaskPayload(taskPayload)),
                    editMap: initialTaskModalState.editMap,
                },
                siteLocation,
            )
        case SET_TIMELINE_TASK_FILTER:
            return { ...state, timelineFilters: action.payload }

        case LOAD_TASKS:
            return { ...state, tasksFilters: action.payload }

        case CHANGE_STATUS:
        case LOAD_TASK:
            return { ...state, isLoading: true }

        case LOAD_TASK_SUCCESS:
            return {
                ...state,
                selectedTask: action.payload,
                isLoading: false,
            }

        case LOAD_TASKS_SUCCESS:
            return { ...state, tasks: action.payload }

        case LOAD_TASK_FAILED:
            return { ...state, isLoading: false }

        case OPEN_TASK_DURATION_MODAL:
            return { ...state, isTaskDurationModalOpen: true, modeState: action.payload }

        case CLOSE_TASK_DURATION_MODAL:
            return { ...state, isTaskDurationModalOpen: false }

        case SEND_MESSAGE:
            return { ...state, isSendingMessage: true }

        case SEND_MESSAGE_SUCCESS:
            return { ...state, isSendingMessage: false }

        case SET_TASK_CREATE_MODAL_OPEN:
            return {
                ...state,
                isCreateModalOpen: action.payload,
            }

        case SET_TASK_EDIT_MODAL_OPEN:
            return {
                ...state,
                isEditModalOpen: action.payload,
            }

        case SET_PREVIEW_LOCATION_MODAL_OPEN:
            return {
                ...state,
                isPreviewModalOpen: action.payload,
            }

        case RESET_TASK_MODAL_STATE:
            return withCustomizedMap(
                {
                    ...state,
                    ...initialTaskModalState,
                    payload: {
                        ...initialTaskModalState.payload,
                        startTime: action.payload.startDate,
                        endTime: action.payload.endTime,
                    },
                },
                action.payload.siteLocation,
            )

        case TASK_EDIT_MAP_PARENT:
            switch (action.payload.type) {
                case PICK_LOCATION:
                    return {
                        ...state,
                        editMap: {
                            ...state.editMap,
                            pickedTask: newBaseAosMapTask(action.payload.payload, state.payload),
                        },
                    }
                default:
                    return {
                        ...state,
                        editMap: mapReducer(state.editMap, action.payload),
                    }
            }

        case CHANGE_FORM:
            const pickedTask = state.editMap.pickedTask
            const payload = migrateTaskPayload(state.payload, action.payload)
            return {
                ...state,
                payload,
                form: FormValidation.fromFields(validateTaskPayload(payload), state.form.pristine),
                editMap: {
                    ...state.editMap,
                    pickedTask: pickedTask ? newBaseAosMapTask(pickedTask.location, payload) : null,
                },
            }

        case SET_FORM_PRISTINE:
            return {
                ...state,
                form: state.form.setPristine(action.payload),
            }

        case TASK_MANAGER_ATTACHMENTS_PARENT:
            return {
                ...state,
                attachmentsState: attachmentReducer(state.attachmentsState, action.payload),
            }

        case TASK_MANAGER_TIMELINE_PARENT:
            return {
                ...state,
                timeline: timelineReducer(state.timeline, action.payload),
            }

        case LOAD_TIMELINE_TASKS_SUCCESS:
            return {
                ...state,
                timelineTasks: action.payload[0],
                loadedRange: action.payload[1],
            }

        case SAVE_LOCATION:
            const newPayload = { ...state.payload, location: state.editMap.pickedTask?.location }
            return {
                ...state,
                payload: newPayload,
                form: FormValidation.fromFields(
                    validateTaskPayload(newPayload),
                    state.form.pristine,
                ),
            }

        case OPEN_TASK_FOR_EDIT: {
            const [task, updateMode, siteLocation] = action.payload
            const taskPayload: TaskFormPayload = { ...taskPayloadFromTask(task), updateMode }
            return withCustomizedMap(
                {
                    ...state,
                    payload: taskPayload,
                    attachmentsState: { ...state.attachmentsState, attachments: task.attachments },
                    isEditModalOpen: true,
                    form: state.form.modify(validateTaskPayload(taskPayload)),
                    editMap: {
                        ...state.editMap,
                        pickedTask: mapTaskForTask(task),
                    },
                },
                siteLocation,
            )
        }

        case PREVIEW_TASK_LOCATION:
            const previewTask = action.payload
            return {
                ...state,
                isPreviewModalOpen: true,
                payload: taskPayloadFromTask(previewTask),
                editMap: setPickedLocationFromTask(
                    { ...state.editMap, mode: MapMode.ViewSingleLocation },
                    action.payload,
                ),
            }

        case LOAD_METADATA_SUCCESS:
            return { ...state, metadata: action.payload }

        case SAVE_TASK_SUCCESS:
            const createAnotherPayload = {
                ...action.payload.payload,
                ...initialBaseTaskPayload,
                status: TaskStatus.Todo,
                recurrence: undefined,
                location: undefined,
                startTime: moment(),
                endTime: moment().add(1, 'hours'),
                spendTimeInMinutes: undefined,
            } as TaskFormPayload
            return {
                ...state,
                isEditModalOpen: false,
                isCreateModalOpen: action.payload.createAnother,
                payload: createAnotherPayload,
                attachmentsState: initialTaskModalState.attachmentsState,
                editMap: { ...state.editMap, pickedTask: null },
                form: FormValidation.fromFields(validateTaskPayload(createAnotherPayload)),
            }

        case LOAD_CHEMICALS_REPORT_SUCCESS:
            return { ...state, chemicalsReport: action.payload }

        case LOAD_ANIMALS_REPORT_SUCCESS:
            return { ...state, animalsReport: action.payload }

        case CHANGE_FILTER:
            return { ...state, reportFilters: action.payload }

        case TOGGLE_GROUP:
            return {
                ...state,
                collapsedGroups: xor(state.collapsedGroups, [action.payload]),
            }
    }

    return state
}
