import { fodReducer } from '../fod/reducer'
import { fodManagerSagas } from '../fod/sagas'
import { firefightersReducer } from './firefighters/reducer'
import { firefightersSagas } from './firefighters/sagas'
import { FirefightersStateAware } from './firefighters/state'
import { recentTasksReducer } from './recent/reducer'
import { recentTasksSagas } from './recent/sagas'
import { RecentTasksStateAware } from './recent/state'
import { taskManagerReducer } from './reducer'
import { taskManagerSagas } from './sagas'
import { shiftNotesReducer } from './shiftNotes/reducer'
import { shiftNotesSagas } from './shiftNotes/sagas'
import { ShiftNotesStateAware } from './shiftNotes/state'
import { TaskManagerStateAware } from './state'
import { upcomingTasksReducer } from './upcoming/reducer'
import { upcomingTasksSagas } from './upcoming/sagas'
import { UpcomingTasksStateAware } from './upcoming/state'

export const tasksSagas = [
    ...taskManagerSagas,
    ...firefightersSagas,
    ...shiftNotesSagas,
    ...recentTasksSagas,
    ...upcomingTasksSagas,
    ...fodManagerSagas,
]

export const tasksReducers = {
    fod: fodReducer,
    taskManager: taskManagerReducer,
    shiftNotes: shiftNotesReducer,
    firefighters: firefightersReducer,
    recentTasks: recentTasksReducer,
    upcomingTasks: upcomingTasksReducer,
}

export type TasksStateAware = TaskManagerStateAware &
    ShiftNotesStateAware &
    FirefightersStateAware &
    RecentTasksStateAware &
    UpcomingTasksStateAware
