export interface HorizontalMargins {
    left: number
    right: number
}

export interface VerticalMargins {
    top: number
    bottom: number
}

export interface Margins extends HorizontalMargins, VerticalMargins {}

export const emptyMargins: Margins = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
}

export const maxMargin = (a: Margins, b: Margins) => ({
    top: Math.max(a.top, b.top),
    left: Math.max(a.left, b.left),
    right: Math.max(a.right, b.right),
    bottom: Math.max(a.bottom, b.bottom),
})
