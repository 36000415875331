import { HelpModalConfig } from 'aos-components/src/components/modal/HelpModal'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { lazy } from 'react'

const title = (suffix: string) => translate(`dashboard.help.runways.${suffix}`)
const note = (suffix: string) => translate(`dashboard.help.runways.${suffix}.note`)

export const runwaysHelpConfig: HelpModalConfig = {
    title: translate('dashboard.help.runways.title'),
    items: [
        {
            title: title('open-close'),
            note: note('open-close'),
            renderer: lazy(() => import('./renderers/RunwaysOpenCloseHelp')),
        },
        {
            title: title('arr-dep'),
            note: note('arr-dep'),
            renderer: lazy(() => import('./renderers/RunwaysArrDepHelp')),
        },
        {
            title: title('situation-updates'),
            renderer: lazy(() => import('./renderers/RunwaysSituationUpdatesHelp')),
        },
    ],
}
