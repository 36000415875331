import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC, ReactNode } from 'react'

interface FirefighterHeaderProps {
    title: ReactNode
    createButton: ReactNode
}

export const HeaderListView: FC<FirefighterHeaderProps> = ({ title, createButton }) => {
    return (
        <Box fullHeight flex={1} row>
            <Box flex='auto' overflow='hidden' row fullHeight>
                {title}
            </Box>

            <Box paddingRight={4} paddingLeft={16}>
                {createButton}
            </Box>
        </Box>
    )
}
