import { DateTime } from 'aos-helpers/src/helpers/Time'

import { translate } from '../../../../../../aos-helpers/src/helpers/translations/Translations'
import { TaskChecklist } from '../../../../dataaccess/tasks/types/TaskDto'
import { UploadedAttachment } from '../../../attachments/types/UploadedAttachment'
import { Auditable } from '../../../base/types/Auditable'
import { AosLocation } from '../../../common/types/AosLocation'
import { AosSeverity } from '../../../common/types/AosSeverity'
import { TaskCategory } from '../TaskCategory'
import { TaskLog } from '../TaskLog'
import { TaskProcessType } from '../TaskProcessType'
import { TaskStatus } from '../TaskStatus'
import { TaskAssignee } from './TaskAssignee'
import { TaskRecurrence } from './TaskRecurrence'

export interface BaseTask extends TaskCommonProps, Auditable {
    id?: number
    parentTaskId?: number
    instanceId?: number

    attachments: UploadedAttachment[]
    startTime: DateTime
    endTime?: DateTime
    description?: string
    log: TaskLog[]
    checklists?: TaskChecklist[]

    recurrence?: TaskRecurrence

    deleted: boolean
    processType: TaskProcessType
}

export interface TaskCommonProps {
    title: string
    severity: AosSeverity
    category: TaskCategory
    assignees: TaskAssignee[]
    location?: AosLocation
    status: TaskStatus
    overdue?: boolean
    lastComment?: string
    realEndTime?: DateTime
    processType: TaskProcessType
    spendTimeInMinutes?: number
}

export const assigneesLabel = (users: { name: string }[]) => {
    switch (users.length) {
        case 0:
            return ''
        case 1:
            return users[0].name
        default:
            return `${users[0].name} +${users.length - 1}`
    }
}

export const translateTrueFalse = (value: boolean) => (value ? translate('yes') : translate('no'))
