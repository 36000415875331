import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { SeparatedContainer } from 'aos-ui/src/components/container/SeparatedContainer'
import React, { FC } from 'react'

import { ReportsFilters } from '../../../../core/tasks/reports/state'
import { YearDropdown } from '../dropdowns/YearDropdown'

interface TimeRangeDropdownsProps {
    yearRange: number[]
    filters: ReportsFilters
    updateFilter(v: Partial<ReportsFilters>): void
    overlapping?: boolean
}

export const TimeRangeDropdowns: FC<TimeRangeDropdownsProps> = ({
    filters,
    yearRange,
    updateFilter,
    overlapping = false,
}) => {
    const from = yearRange
    const to = yearRange

    const updateYears = (newYearFrom: number, newYearTo: number) => {
        const [yearFrom, yearTo] =
            newYearTo > newYearFrom //
                ? [newYearFrom, newYearTo]
                : [newYearTo, newYearFrom]
        updateFilter({ yearFrom, yearTo })
    }

    const changeFrom = (yearFrom: number) => updateYears(yearFrom, filters.yearTo)
    const changeTo = (yearTo: number) => updateYears(filters.yearFrom, yearTo)

    return (
        <SeparatedContainer row spacing={12} orientation='horizontal'>
            <Box row>
                <Text marginRight={8} size={13}>
                    {translate('reports.filter.year-start')}
                </Text>
                <YearDropdown
                    values={from}
                    value={filters.yearFrom}
                    onChange={changeFrom}
                    overlapping={overlapping}
                />
            </Box>
            <Box row>
                <Text marginRight={8} size={13}>
                    {translate('reports.filter.year-end')}
                </Text>
                <YearDropdown
                    values={to}
                    value={filters.yearTo}
                    onChange={changeTo}
                    overlapping={overlapping}
                />
            </Box>
        </SeparatedContainer>
    )
}
