import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { BaseUserFormData } from 'aos-services/src/services/users/input/BaseUserFormData'
import {
    AOS_USER_EXISTS,
    CONTACT_EXISTS,
    USER_OR_GROUP_NOT_FOUND,
    USER_REMOVED,
} from 'aos-services/src/services/users/types/AosUserFindStatus'
import React, { PureComponent } from 'react'

import { UserError } from './UserError'
import { UserExists } from './UserExists'

export class ExistingContactStatus extends PureComponent<ExistingContactStatusProps> {
    public render() {
        const { user, goBack, hide } = this.props
        const { userStatus } = user

        switch (userStatus) {
            case AOS_USER_EXISTS:
                return (
                    <UserExists
                        email={user.email!}
                        back={goBack}
                        description={translate('manage-user.existing-contact.user-exists')}
                        userActionLabel={translate('manage-user.contact.user-exists.edit-ad-user')}
                        userAction={this.props.editAdUser}
                    />
                )
            case CONTACT_EXISTS:
                return (
                    <UserExists
                        email={user.email!}
                        back={goBack}
                        description={translate('manage-user.existing-contact.contact-exists')}
                        userActionLabel={translate(
                            'manage-user.contact.contact-exists.edit-contact',
                        )}
                        userAction={this.props.editContact}
                    />
                )
            case USER_REMOVED:
                return (
                    <UserError
                        email={user.email!}
                        description={translate('manage-user.user-removed')}
                        hide={hide}
                    />
                )
            case USER_OR_GROUP_NOT_FOUND:
                return (
                    <UserError
                        email={user.email!}
                        description={translate('manage-user.group-not-found')}
                        hide={hide}
                    />
                )
            default:
                return <span>{translate(`manage-user.status.${userStatus!.toLowerCase()}`)}</span>
        }
    }
}

export interface ExistingContactStatusProps {
    user: BaseUserFormData
    goBack(): void
    hide(): void
    removeAdUser?(): void
    editAdUser?(): void
    editContact?(): void
}
