import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { MessageNotificationDto } from '../../../dataaccess/notifications/types/MessageNotificationDto'
import { MessageNotification } from './MessageNotification'

export class MessageNotificationImpl
    extends WrapperObject<MessageNotificationDto>
    implements MessageNotification {
    constructor(
        public id: string,
        value: MessageNotificationDto,
        private markAlwaysAsRead: boolean,
    ) {
        super(value)
    }

    public get text() {
        return this.value.text
    }

    public get groupName() {
        return this.value.group && this.value.group.name
    }

    public get type() {
        return this.value.type
    }

    public get read() {
        return this.markAlwaysAsRead || this.value.read
    }

    public get timestamp() {
        return dateTime(this.value.timestamp)
    }
}
