import { logger } from 'aos-helpers/src/helpers/logging/Logger'

export interface ShadowStyle {
    shadowOffsetX: number
    shadowOffsetY: number
    shadowColor: string
    shadowBlur: number
}

const defaultShadow: ShadowStyle = {
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'transparent',
    shadowBlur: 0,
}

interface DomUrl {
    createObjectURL(object: any): string
    revokeObjectURL(url: string): void
}

export class SvgPngConverter {
    private canvas: HTMLCanvasElement
    private ctx: CanvasRenderingContext2D
    private domURL: DomUrl

    public static additionalSpaceForStyle = (shadowStyle: ShadowStyle) =>
        Math.max(
            shadowStyle.shadowOffsetX + shadowStyle.shadowBlur,
            shadowStyle.shadowOffsetY + shadowStyle.shadowBlur,
        )

    constructor() {
        this.canvas = document.createElement('canvas')
        this.ctx = this.canvas.getContext('2d')!
        this.domURL = self.URL || (self as any).webkitURL || self
    }

    public svgToPng = (url: string, height?: number, shadowStyle?: ShadowStyle): Promise<string> =>
        this.loadImage(url).then(img => {
            const factor = height ? height / img.height : 1
            this.drawImage(img, factor, shadowStyle)
            this.domURL.revokeObjectURL(url)
            const png = this.canvas.toDataURL()
            this.clearImage(img, factor, shadowStyle)
            return png
        })

    private loadImage = (url: string): Promise<HTMLImageElement> =>
        new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image()
            img.crossOrigin = 'Anonymous'
            img.src = url
            img.onload = () => {
                // Safari bug
                setTimeout(() => {
                    resolve(img)
                }, 100)
            }
            img.onerror = (error: Event | string) => {
                if (typeof error === 'string') {
                    logger.handleError(new Error(error))
                } else {
                    logger.handleError(new Error(error.toString()))
                }
                reject(error)
            }
        })

    private drawImage = (
        img: HTMLImageElement,
        factor: number,
        shadowStyle: ShadowStyle = defaultShadow,
    ) => {
        const targetWidth = img.width * factor
        const targetHeight = img.height * factor
        const additionalSpace = SvgPngConverter.additionalSpaceForStyle(shadowStyle)
        this.canvas.width = targetWidth + 2 * additionalSpace
        this.canvas.height = targetHeight + 2 * additionalSpace
        this.ctx.shadowOffsetX = shadowStyle.shadowOffsetX
        this.ctx.shadowOffsetY = shadowStyle.shadowOffsetY
        this.ctx.shadowColor = shadowStyle.shadowColor
        this.ctx.shadowBlur = shadowStyle.shadowBlur
        this.ctx.drawImage(img, additionalSpace, additionalSpace, targetWidth, targetHeight)
    }

    private clearImage = (
        img: HTMLImageElement,
        factor: number,
        shadowStyle: ShadowStyle = defaultShadow,
    ) => {
        const targetWidth = img.width * factor
        const targetHeight = img.height * factor
        const additionalSpace = SvgPngConverter.additionalSpaceForStyle(shadowStyle)
        this.ctx.clearRect(
            0,
            0,
            targetWidth + 2 * additionalSpace,
            targetHeight + 2 * additionalSpace,
        )
    }
}
