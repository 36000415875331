import { Validator } from 'aos-form'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    FirefighterFiretruck,
    FiretruckMember,
} from 'aos-services/src/services/tasks/firefighters/types/FirefighterFormFields'

export const notEmpty = (
    errorMessage = [
        translate('firefighters.form.error.member.required'),
        translate('firefighters.form.error.lp3kat12.required'),
    ].join(' '),
): Validator<any[]> => value => {
    if (Array.isArray(value)) {
        return value.length ? undefined : errorMessage
    }
    return errorMessage
}

export const validValidUntilDate = (validFromDate: DateTime): Validator<DateTime> => value => {
    if (!value) {
        return translate('form.required-field')
    }
    return value.isAfter(validFromDate)
        ? undefined
        : translate('firefighters.form.error.valid-till.invalid') +
              validFromDate.format('DD.MM.YYYY HH:mm')
}

export const validFirefighterFiretrucks = (): Validator<
    FirefighterFiretruck[]
> => firetrucklist => {
    let errorMessage: string[] = []
    if (Array.isArray(firetrucklist)) {
        let allMembers: FiretruckMember[] = []

        for (const firetruck of firetrucklist) {
            const members = firetruck.fireFighters || []
            allMembers = allMembers.concat(members)
        }

        const isMembersSelected = isAllMembersSelected(allMembers)
        const isMembersUnique = isAllMembersUnique(allMembers)
        const isLp3Kat12Valid =
            isOnlyOneLp3(allMembers) || isOnlyOneKat12(allMembers) || isOnlyOneLp5(allMembers)

        if (!isMembersSelected) {
            errorMessage.push(translate('firefighters.form.error.member.required'))
        }
        if (!isLp3Kat12Valid) {
            errorMessage.push(translate('firefighters.form.error.lp3kat12.required'))
        }
        if (!isMembersUnique) {
            errorMessage.push(translate('firefighters.form.error.member.duplicated'))
        }

        return isMembersSelected && isMembersUnique && isLp3Kat12Valid
            ? undefined
            : errorMessage.join(' ')
    }
    return errorMessage.join(' ')
}

const isEmpty = (value?: String): boolean => {
    return !value || !value.trim().length
}

const isOnlyOneLp3 = (members: FiretruckMember[]) => {
    const membersWithLp3 = members.filter(member => member.lp3).length
    return membersWithLp3 === 1
}

const isOnlyOneLp5 = (members: FiretruckMember[]) => {
    const membersWithLp5 = members.filter(member => member.lp5).length
    return membersWithLp5 === 1
}

const isOnlyOneKat12 = (members: FiretruckMember[]) => {
    const membersWithLp3 = members.filter(member => member.kat12).length
    return membersWithLp3 === 1
}

const isAllMembersSelected = (members: FiretruckMember[]) => {
    return !members.filter(member => isEmpty(member.user?.name)).length
}

const isAllMembersUnique = (members: FiretruckMember[]) => {
    const memberNames = members.map(member => member.user?.name)
    return !memberNames.some((member, idx) => member && memberNames.indexOf(member) !== idx)
}
