import React, { FC, PropsWithChildren } from 'react'

export const TrainLabelIcon: FC<PropsWithChildren> = ({ children }) => (
    <svg
        width='76'
        height='42'
        viewBox='0 0 76 42'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <defs>
            <rect id='b' width='59' height='28' x='8.5' y='6' rx='2' />
            <filter
                id='a'
                width='157.6%'
                height='221.4%'
                x='-28.8%'
                y='-46.4%'
                filterUnits='objectBoundingBox'
            >
                <feOffset dy='4' in='SourceAlpha' result='shadowOffsetOuter1' />
                <feGaussianBlur
                    in='shadowOffsetOuter1'
                    result='shadowBlurOuter1'
                    stdDeviation='5'
                />
                <feColorMatrix
                    in='shadowBlurOuter1'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
                />
            </filter>
        </defs>
        <g fill='none' fillRule='evenodd'>
            <use fill='#000' filter='url(#a)' xlinkHref='#b' />
            <use fill='#FFF' xlinkHref='#b' />
            <text
                fill='#0B2545'
                textAnchor='middle'
                fontFamily='Helvetica, Arial, sans-serif'
                fontWeight='500'
                fontSize='14'
            >
                <tspan x='38' y='25'>
                    {children}
                </tspan>
            </text>
        </g>
    </svg>
)
