import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { TaskCommonProps } from 'aos-services/src/services/tasks/types/task/BaseTask'
import {
    statusTextColorsMapping,
    TaskStatus,
} from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { InfoBarContent, InfoBarText } from 'aos-ui/src/components/infobar/InfoBar'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { UserAssignment } from 'aos-ui/src/components/ui/UserAssignment'
import { Color } from 'aos-ui-common/src/styles/Color'
import { TaskIcon, taskIconVariant } from 'aos-ui-task/src/components/task/TaskIcon'
import moment from 'moment'
import React, { FC } from 'react'

export interface TaskBarContentProps {
    item: TaskCommonProps
    inTooltip?: boolean
    shorten?: boolean
}

export const TaskBarContent: FC<TaskBarContentProps> = ({
    item,
    inTooltip = false,
    shorten = false,
}) => {
    const statusTextColor =
        item.overdue && item.severity !== AosSeverity.Emergency && item.status !== TaskStatus.OnHold
            ? Color.White
            : statusTextColorsMapping[item.status]
    const statusColorIcon =
        item.overdue && item.severity !== AosSeverity.Emergency && item.status !== TaskStatus.OnHold
            ? IconVariant.White
            : taskIconVariant[item.status]
    const iconVariant = inTooltip ? IconVariant.Black : statusColorIcon
    const textColor = inTooltip ? Color.Black : statusTextColor

    const titleFragment = (
        <>
            <TaskIcon iconVariant={iconVariant} category={item.category} />
            <InfoBarText
                size={14}
                color={textColor}
                overflow='hidden'
                lineHeight='large'
                paddingLeft={8}
                data-timeline-text
            >
                {item.title}
            </InfoBarText>
        </>
    )

    const userFragment = !shorten && (
        <Box marginLeft={8}>
            <UserAssignment
                iconVariant={statusColorIcon}
                textColor={statusTextColor}
                users={item.assignees}
                onWhite={inTooltip}
            />
        </Box>
    )

    return inTooltip ? (
        <InfoBarContent
            inTooltip={inTooltip}
            wrap
            paddingLeft={8}
            paddingRight={8}
            paddingVertical={2}
            overflow='hidden'
        >
            <Box row>
                <Box flex={1} row>
                    {titleFragment}
                </Box>
                <Box>{userFragment}</Box>
            </Box>
            <Box row>
                {item.lastComment && (
                    <InfoBarText
                        flex={1}
                        size={14}
                        color={textColor}
                        overflow='hidden'
                        lineHeight='large'
                        paddingLeft={8}
                        data-timeline-text
                    >
                        {`"${item.lastComment}"`}
                    </InfoBarText>
                )}
                {item.overdue && (
                    <InfoBarText
                        size={14}
                        color={textColor}
                        overflow='hidden'
                        lineHeight='large'
                        paddingLeft={8}
                        data-timeline-text
                    >
                        {formatOverdue(item.realEndTime)}
                    </InfoBarText>
                )}
            </Box>
        </InfoBarContent>
    ) : (
        <InfoBarContent
            inTooltip={inTooltip}
            row
            paddingLeft={8}
            paddingRight={8}
            paddingVertical={2}
            overflow='hidden'
        >
            {titleFragment}

            {item.lastComment && (
                <InfoBarText
                    size={14}
                    color={textColor}
                    overflow='hidden'
                    lineHeight='large'
                    flex={1}
                    textAlign='right'
                    paddingLeft={8}
                    paddingRight={8}
                    data-timeline-text
                >
                    {`"${item.lastComment}"`}
                </InfoBarText>
            )}
            {userFragment}
        </InfoBarContent>
    )
}

const formatOverdue = (endTime?: DateTime) => {
    return `${translate('task-filter.overdue')}: ${moment
        .duration(dateTime().diff(endTime))
        .format('h [hrs], m [min]')}`
}
