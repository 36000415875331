import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { UserLayerVisibilityPayload, UserLayerVisibilityState } from './state'

export const LOAD_USER_LAYER_VISIBILITY = 'USER_LAYERS_VISIBILITY/LOAD_USER_LAYER_VISIBILITY'
export type LoadUserLayerVisibilityAction = Action<typeof LOAD_USER_LAYER_VISIBILITY>
export const loadUserLayerVisibilityAction = emptyActionCreator<LoadUserLayerVisibilityAction>(
    LOAD_USER_LAYER_VISIBILITY,
)

export const LOAD_USER_LAYER_VISIBILITY_SUCCESS =
    'USER_LAYERS_VISIBILITY/LOAD_USER_LAYER_VISIBILITY_SUCCESS'
export type LoadUserLayerVisibilitySuccessAction = PayloadAction<
    typeof LOAD_USER_LAYER_VISIBILITY_SUCCESS,
    UserLayerVisibilityPayload
>
export const loadUserLayerVisibilitySuccessAction = payloadActionCreator<
    LoadUserLayerVisibilitySuccessAction,
    UserLayerVisibilityPayload
>(LOAD_USER_LAYER_VISIBILITY_SUCCESS)

export const SET_USER_LAYERS_VISIBILITY = 'USER_LAYERS_VISIBILITY/SET_USER_LAYERS_VISIBILITY'
export type SetUserLayersVisibilityAction = PayloadAction<
    typeof SET_USER_LAYERS_VISIBILITY,
    UserLayerVisibilityState
>
export const setUserLayersVisibilityAction = payloadActionCreator<
    SetUserLayersVisibilityAction,
    UserLayerVisibilityState
>(SET_USER_LAYERS_VISIBILITY)

export type UserLayerVisibilityAction =
    | LoadUserLayerVisibilityAction
    | LoadUserLayerVisibilitySuccessAction
    | SetUserLayersVisibilityAction
