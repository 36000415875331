import { DateTime } from 'aos-helpers/src/helpers/Time'
import { paxForecastStatsSelector } from 'aos-services/src/core/statusDashboardData/paxForecast/selectors/paxForecast'
import { PaxForecastArrivalOrDeparture } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { PaxForecastStats } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PaxForecastItem } from './partials/PaxForecastItem'
import { PaxForecastArrivalDepartureTrend } from './stats/PaxForecastArrivalDepartureTrend'
import { PaxForecastTotalTrend } from './stats/PaxForecastTotalTrend'

interface PaxForecastTileStateProps {
    stats: PaxForecastStats | null
    carouselTrigger: DateTime
}

type PaxForecastTileOwnProps = StatusDashboardItemCommonProps

interface PaxForecastTileProps extends PaxForecastTileStateProps, PaxForecastTileOwnProps {}

const PaxForecastTileComponent: FC<PaxForecastTileProps> = props => (
    <PaxForecastItem
        stats={props.stats}
        itemProps={pickDashboardItemProps(props)}
        link={link}
        syncId='overview'
    >
        <TileContainer>
            {props.stats && (
                <Carousel changeTrigger={props.carouselTrigger}>
                    <PaxForecastTotalTrend stats={props.stats} />
                    <PaxForecastArrivalDepartureTrend
                        stats={props.stats}
                        type={PaxForecastArrivalOrDeparture.Arrivals}
                    />
                    <PaxForecastArrivalDepartureTrend
                        stats={props.stats}
                        type={PaxForecastArrivalOrDeparture.Departures}
                    />
                </Carousel>
            )}
        </TileContainer>
    </PaxForecastItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Passengers })

export const PaxForecastTile = connect<PaxForecastTileStateProps>(
    createStructuredSelector({
        stats: paxForecastStatsSelector,
        carouselTrigger: carouselTriggerSelector,
    }),
)(PaxForecastTileComponent)
