import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { queueingTimeService } from '../../../services/queueingTime/queueingTimeService'
import { QueueingData } from '../../../services/queueingTime/types/QueueingData'
import {
    QUEUEING_TIME_LOAD,
    QUEUEING_TIME_SYNC,
    QUEUEING_TIME_SYNC_STOP,
    queueingTimeLoadAction,
    queueingTimeLoadSuccessAction,
    QueueingTimeSyncAction,
    QueueingTimeSyncStopAction,
} from './actions'

function* queueingTimeSyncSaga() {
    yield takeEvery<QueueingTimeSyncAction>(
        QUEUEING_TIME_SYNC,
        function* (action: QueueingTimeSyncAction) {
            yield* firebaseService.onListener(
                'queueingTime',
                function* () {
                    yield syncValue(
                        sendToSyncRepository.stsRef(SendToSyncType.QUEUES),
                        queueingTimeLoadAction,
                        false,
                    )
                },
                action.payload,
            ) as Generator
        },
    )
}

function* queueingTimeSyncStopSaga() {
    yield takeEvery<QueueingTimeSyncStopAction>(
        QUEUEING_TIME_SYNC_STOP,
        function* (action: QueueingTimeSyncStopAction) {
            yield firebaseService.offListener('queueingTime', action.payload)
        },
    )
}

function* queueingTimeLoadSaga() {
    yield takeEvery(QUEUEING_TIME_LOAD, function* () {
        const data: QueueingData = yield call(queueingTimeService.getQueueTime)
        yield put(queueingTimeLoadSuccessAction(data))
    })
}

export const queueingTimeSagas = [
    queueingTimeSyncSaga,
    queueingTimeSyncStopSaga,
    queueingTimeLoadSaga,
]
