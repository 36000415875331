import { contactListRestService } from '../../dataaccess/contactList/contactListRestService'
import { FilterOption } from '../flightInformation/types/FlightInfoFilters'
import {
    ContactDTO,
    ContactItem,
    UnitAirportContact,
    UnitAirportContactDTO,
} from './types/ContactItem'

class ContactListService {
    public async getContactList(): Promise<ContactItem[]> {
        return contactListRestService.getAllContacts()
    }

    public async getUnitAirportContacts(): Promise<UnitAirportContact[]> {
        return contactListRestService.getAllUnits()
    }

    public async createContact(contact: ContactDTO): Promise<Response> {
        return contactListRestService.createContact({
            ...contact,
            siteLocations: contact.siteLocations.filter(q => q !== FilterOption.All),
            unitAirportContact: { id: contact.unitAirportContact.id },
        })
    }

    public async createUnitAirportContact(unit: UnitAirportContactDTO): Promise<Response> {
        return contactListRestService.createUnitAirportContact(unit)
    }

    public async editContact(contact: ContactDTO): Promise<Response> {
        return contactListRestService.editContact({
            ...contact,
            siteLocations: contact.siteLocations.filter(q => q !== FilterOption.All),
            unitAirportContact: { id: contact.unitAirportContact.id },
        })
    }

    public async editUnitAirportContact(unit: UnitAirportContactDTO): Promise<Response> {
        return contactListRestService.editUnitAirportContact(unit)
    }

    public async deleteContact(id: number): Promise<Response> {
        return contactListRestService.deleteContact(id)
    }

    public async deleteUnitAirportContact(id: number): Promise<Response> {
        return contactListRestService.deleteUnitAirportContact(id)
    }
}

export const contactListService = new ContactListService()
