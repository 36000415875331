import { cx } from 'aos-components/src/components/base/ClassNames'
import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserType } from 'aos-services/src/services/users/types/AosUserType'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormFieldContainer } from 'aos-ui/src/components/container/FormFieldContainer'
import { InnerFormContainer } from 'aos-ui/src/components/container/InnerFormContainer'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { LabeledReadOnlyInput } from 'aos-ui/src/components/form/labeled/LabeledReadOnlyInput'
import { FormSidebar } from 'aos-ui/src/components/form/ui/FormSidebar'
import React, { FC } from 'react'

import { CommonAdUserFormValidationState } from '../../../../core/groupManager/manageUser/state'
import { GroupsCheckList } from '../GroupsCheckList'

export interface AdUserFormProps {
    user: AdUserFormData
    form: FormValidation<CommonAdUserFormValidationState>
    groups: AosUserGroup[]
    changeForm(v: Partial<AdUserFormData>): void
    selectAllGroups(): void
    deselectAllGroups(): void
}

export const AdUserForm: FC<AdUserFormProps> = ({
    user,
    form,
    groups,
    changeForm,
    selectAllGroups,
    deselectAllGroups,
}) => {
    return (
        <Box className='scrollable' coverAll row>
            <FormFieldContainer
                className={cx(
                    'flex--1 full-height scrollable user-modal__l-container',
                    'padding-left--x-large padding-top--small',
                )}
            >
                <LabeledReadOnlyInput
                    keyPrefix='manage-user.fullname'
                    value={user.name}
                    type='text'
                    seleniumLocation='username'
                />
                <LabeledFormElement
                    label={translate('manage-user.contact-information')}
                    labelComment={` (${translate('manage-user.alternative-phone-info')})`}
                >
                    <InnerFormContainer paddingHorizontal={20} withBorder>
                        <LabeledReadOnlyInput
                            keyPrefix='manage-user.email'
                            value={user.email}
                            type='email'
                            seleniumLocation='email'
                        />
                        <Box fullWidth row alignItems='flex-start'>
                            <LabeledReadOnlyInput
                                keyPrefix='manage-user.phone-ad'
                                value={user.phone}
                                type='text'
                                seleniumLocation='phone'
                            />
                            <LabeledInput
                                marginLeft={30}
                                keyPrefix='manage-user.alternative-phone'
                                onChangeText={alternativePhone => changeForm({ alternativePhone })}
                                value={user.alternativePhone}
                                type='text'
                                isError={form.error.alternativePhone}
                                seleniumLocation='alternativePhone'
                            />
                        </Box>
                    </InnerFormContainer>
                </LabeledFormElement>
                <LabeledReadOnlyInput
                    keyPrefix='manage-user.company'
                    value={user.company}
                    type='text'
                    seleniumLocation='company'
                />
                {user.type === AosUserType.INTERNAL && (
                    <LabeledReadOnlyInput
                        keyPrefix='manage-user.unit'
                        value={user.unit ? translate(`unit.${user.unit}`) : ''}
                        type='text'
                        seleniumLocation='unit'
                    />
                )}
                <LabeledReadOnlyInput
                    keyPrefix='manage-user.role'
                    value={user.role}
                    type='text'
                    seleniumLocation='role'
                />
            </FormFieldContainer>
            <FormSidebar>
                <GroupsCheckList
                    groups={groups}
                    selectedGroupsIds={user.selectedGroupsIds}
                    changeGroups={groups => changeForm({ selectedGroupsIds: groups })}
                    selectAllGroups={selectAllGroups}
                    deselectAllGroups={deselectAllGroups}
                />
            </FormSidebar>
        </Box>
    )
}
