import { firebaseApp } from 'firebaseApp'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { OperationalForecastDto } from './types/OperationalForecastDto'

export class OperationalForecastRepository {
    public operationalForecastRef = (location: AosAirport) =>
        firebaseApp.database().ref(`operationalForecast/${location}`)

    public updateOperationalForecast = (location: AosAirport, payload: OperationalForecastDto) =>
        this.operationalForecastRef(location).update(payload)
}

export const operationalForecastRepository = new OperationalForecastRepository()
