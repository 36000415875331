import { WhiteModal } from 'aos-components/src/components/modal/WhiteModal/WhiteModal'
import { translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import {
    TaskUpdateMode,
    translateUpdateMode,
    updateModes,
} from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { RadioGroup } from 'aos-ui/src/components/form/radio/RadioGroup'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

interface TaskEditModeModalProps {
    isOpen: boolean
    keyPrefix: string
    onSubmit(mode: TaskUpdateMode): void
    onClose(): void
}

export const TaskEditModeModal: FC<TaskEditModeModalProps> = ({
    isOpen,
    onSubmit,
    onClose,
    keyPrefix,
}) => {
    const [updateMode, setMode] = useState<TaskUpdateMode>(TaskUpdateMode.Instance)
    return (
        <WhiteModal
            id='aaa'
            isOpen={isOpen}
            title={translateParts(keyPrefix, 'title')}
            closeAction={onClose}
            size={ModalKind.Small}
            footer={
                <FormButton
                    label={translateParts(keyPrefix, 'action')}
                    onClick={() => onSubmit(updateMode!)}
                    disabled={!updateMode}
                />
            }
        >
            <Wrapper padding={30} marginTop={30}>
                <RadioGroup
                    name='update-mode'
                    items={updateModes}
                    value={updateMode}
                    formatter={v => (
                        <Box paddingVertical={4}>{translateUpdateMode(v as TaskUpdateMode)}</Box>
                    )}
                    onChange={setMode}
                    vertical
                />
            </Wrapper>
        </WhiteModal>
    )
}

const Wrapper = styled(Box)`
    border-top: 1px solid ${Color.Grey2};
    border-bottom: 1px solid ${Color.Grey2};
`
