import { Feed } from 'aos-services/src/services/newsFeed/types/Feed'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface FeedItemProps {
    feed: Feed
    bigWidget?: boolean
}

export const FeedItem: FC<FeedItemProps> = ({ feed}) => (
    <Box justify="space-between" column paddingTop={24} paddingLeft={20} paddingRight={6} height={148} fullHeight>
        <Text color={Color.White} size={14}>{feed.title}</Text>
        <Box paddingBottom={24}>
            {feed.pubDate && <HourClock time={feed.pubDate} showFullDate />}
        </Box>
    </Box>
)
