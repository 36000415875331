import { zodResolver } from '@hookform/resolvers/zod'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    ContactDTO,
    ContactItemSchema,
    ContactVisibility,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import { Modal } from 'aos-ui/src/components/modal/Modal/Modal'
import { ModalFooter } from 'aos-ui/src/components/modal/Modal/ModalFooter'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { Color } from 'aos-ui-common/src/styles/Color'
import { differenceBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from 'react-use'

import {
    closeContactModal,
    createContact,
    editContact,
    openAirportUnitContactModal,
} from '../../core/contactList/actions'
import { State } from '../../core/state'
import { ContactForm } from './partial/ContactForm'

export const ContactListModal = () => {
    const { modalOpen, selectedContact, contactModalOpen, units } = useSelector(
        (state: State) => state.contactList,
    )

    const [createAnother, setCreateAnother] = useState(false)
    const dispatch = useDispatch()
    const title = selectedContact
        ? translate('contact-list.edit-contact')
        : translate('contact-list.create-contact')
    const canCreateAnother = !selectedContact
    const currentAirport = useSelector(currentUserSiteLocation)
    const prevUnits = usePrevious(units)

    const form = useForm<ContactDTO>({
        defaultValues: selectedContact || {
            contactVisibility: ContactVisibility.AllUsers,
            siteLocations: [currentAirport],
            email: '',
        },
        mode: 'onSubmit',
        resolver: zodResolver(ContactItemSchema),
    })
    const handleSubmit = (data: ContactDTO) => {
        if (!createAnother) {
            dispatch(closeContactModal())
        }
        dispatch(
            selectedContact
                ? editContact(data)
                : createContact({ data, createAnother: createAnother! }),
        )
        if (createAnother) {
            form.reset({
                contactVisibility: ContactVisibility.AllUsers,
                siteLocations: data.siteLocations,
                unitAirportContact: data.unitAirportContact,
            })
        }
    }
    useEffect(() => {
        form.reset(
            selectedContact || {
                contactVisibility: ContactVisibility.AllUsers,
                siteLocations: [currentAirport],
                email: '',
            },
        )
    }, [selectedContact, modalOpen])

    useEffect(() => {
        if (form.getValues().unitAirportContact) {
            form.setValue(
                'unitAirportContact',
                units.find(unit => unit.id === form.getValues().unitAirportContact.id)!,
            )
        }
        const differentUnit = differenceBy(units, prevUnits!, 'id')[0]
        if (differentUnit) {
            form.setValue('unitAirportContact', differentUnit)
        }
    }, [units, prevUnits])

    return (
        <Modal
            title={title}
            modalKind={ModalKind.LessThanMedium}
            isOpen={modalOpen && !contactModalOpen}
            closeAction={() => dispatch(closeContactModal())}
        >
            <Box overflow='auto' flex={1}>
                <Box paddingVertical={20} paddingHorizontal={30}>
                    <ContactForm
                        form={form}
                        onAddNewUnit={() => {
                            dispatch(openAirportUnitContactModal({ data: null, reopen: true }))
                        }}
                        onEditUnit={unit => {
                            dispatch(openAirportUnitContactModal({ data: unit, reopen: true }))
                        }}
                    />
                </Box>
                <ModalFooter topSeparator>
                    <Box fullWidth row columnGap={8}>
                        <ValidationInfo
                            pristine={!form.formState.isDirty || !form.formState.isSubmitted}
                            valid={form.formState.isValid}
                        />
                        {canCreateAnother && (
                            <Box row justify='center' columnGap={8}>
                                <Checkbox checked={createAnother} onChange={setCreateAnother} />
                                <Text size={13} color={Color.UiBlack1}>
                                    {translate('contact-list.create-another')}
                                </Text>
                            </Box>
                        )}
                        <FormButton
                            onClick={form.handleSubmit(handleSubmit)}
                            label={translate('contact-list.form.save')}
                        />
                    </Box>
                </ModalFooter>
            </Box>
        </Modal>
    )
}
