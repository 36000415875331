import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { TrainTimeTableRow } from './TrainTimeTableRow'

/* eslint-disable react/jsx-no-literals */
export const TrainTimeTableEmptyItem: FC = () => (
    <Box paddingVertical={4}>
        <TrainTimeTableRow>
            <Box row>
                <Box marginRight={8}>
                    <Box className='train-information__delay-marker' />
                </Box>
                <Box flex={1} marginLeft={20}>
                    ...
                </Box>
            </Box>
            <Box>...</Box>
            <Box>...</Box>
        </TrainTimeTableRow>
    </Box>
)
