import { cx } from 'aos-components/src/components/base/ClassNames'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PropsWithChildren, PureComponent } from 'react'

export class BaseNotificationItem extends PureComponent<
    PropsWithChildren<BaseNotificationItemProps>
> {
    public render() {
        const { title, children, leftColumn, read, onClick } = this.props
        return (
            <Box
                maxFullWidth
                className={cx(
                    'notification-list-item padding-horizontal--x-large padding-vertical--large',
                    'container container--horizontal container--align-start',
                    'border-bottom--black',
                    { 'notification-list-item--not-read': !read, 'pointer': onClick },
                )}
                onClick={onClick}
            >
                {leftColumn && (
                    <Box className='notification-list-item__l-column' shrink={0}>
                        {leftColumn}
                    </Box>
                )}
                <Text className='text-box' flex={1} lineHeight='standard'>
                    <Text color={Color.White} size={14}>
                        {title}
                    </Text>
                    <Text color={Color.Grey} size={12}>
                        {children}
                    </Text>
                </Text>
            </Box>
        )
    }
}

interface BaseNotificationItemProps {
    title: string | JSX.Element
    read: boolean
    leftColumn?: JSX.Element
    onClick?(): void
}
