export interface UpdatableValue<T> {
    updated: boolean
    value: T
}

export const mapUpdatableValue = <T, U>(
    uv: UpdatableValue<T>,
    mapper: f.Func1<T, U>,
): UpdatableValue<U> => ({
    ...uv,
    value: mapper(uv.value),
})
