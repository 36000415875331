import { downloadFileFromResponse } from 'aos-helpers/src/helpers/Download'

import { groupRestService } from '../../dataaccess/groups/groupRestService'
import { AosUserGroupDto } from '../../dataaccess/groups/types/AosUserGroupDto'
import { sortGroupListByName } from './aosUserFormatter'
import { AosUserGroup } from './types/AosUserGroup'
import { AosUserGroupImpl } from './types/AosUserGroupImpl'
import { AosUserGroupType } from './types/AosUserGroupType'
import { AosUserNotificationsPreferences } from './types/AosUserNotificationsPreferences'

class GroupService {
    public getAllGroups = (): Promise<AosUserGroup[]> =>
        groupRestService
            .getAllGroups()
            .then(t => sortGroupListByName(t.map(g => new AosUserGroupImpl(g))))

    public getActiveGroups = (): Promise<AosUserGroup[]> =>
        this.getAllGroups().then(t => t.filter(g => g.type !== AosUserGroupType.REMOVED))

    public createNewGroup = (name: string): Promise<AosUserGroupDto> =>
        groupRestService.createNewGroup(name)

    public removeUserFromGroup = (groupId: number, userId: number): Promise<Response> =>
        groupRestService.removeUserFromGroup(groupId, userId)

    public addUserToGroup = (group: AosUserGroup, userId: number): Promise<Response> =>
        groupRestService.addUserToGroup(group.id, userId)

    public removeGroup = (groupId: number): Promise<Response> =>
        groupRestService.removeGroup(groupId)

    public renameGroup = (groupId: number, newName: string): Promise<Response> =>
        groupRestService.renameGroup(groupId, newName)

    public exportGroups = (): Promise<void> =>
        groupRestService.exportGroups().then(downloadFileFromResponse)

    public updateNotifications = (
        groupId: number,
        notificationPreferences: AosUserNotificationsPreferences,
    ) => groupRestService.updateNotifications(groupId, notificationPreferences)
}

export const groupService = new GroupService()
