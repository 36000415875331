import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { isApocSelector } from 'aos-services/src/core/auth/state'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { feedsService } from 'aos-services/src/services/events/feedsService'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import NotificationSound from '../../../assets/sounds/notification.mp3'
import { createPlaySoundSaga } from '../helpers/sound'
import { addAlreadyShownAction } from '../oneTime/actions'
import { OneTimeKey } from '../oneTime/state'
import {
    RELOAD_EVENT_MANAGER_DOT,
    reloadEventManagerDotAction,
    setEventManagerDotAction,
    SYNC_EVENT_MANAGER_DOT,
    TOGGLE_WHATS_NEW,
    ToggleWhatsNewAction,
} from './actions'
import { eventManagerDotListenerName } from './state'

function* reloadEventManagerDotSaga() {
    yield takeEvery(RELOAD_EVENT_MANAGER_DOT, function* () {
        const hasActiveFeedOuts: boolean = yield call(feedsService.getHasActiveFeedIns)
        const isEventManagerDotVisible: boolean = yield select(
            state => state.layout.isEventManagerDotVisible,
        )
        const lightingUpDot = !isEventManagerDotVisible && hasActiveFeedOuts
        yield put(setEventManagerDotAction(hasActiveFeedOuts))
        if (lightingUpDot) {
            yield createPlaySoundSaga()(NotificationSound)
        }
    })
}

function* syncEventManagerDotSaga() {
    yield takeEvery(SYNC_EVENT_MANAGER_DOT, function* () {
        const isApoc: boolean = yield select(isApocSelector)
        if (isApoc) {
            yield* firebaseService.onListener(eventManagerDotListenerName, function* () {
                return (yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.EVENT_MANAGER_FEEDS),
                    reloadEventManagerDotAction,
                    false,
                )) as Generator
            }) as Generator
        }
    })
}

function* rememberWhatsNewClose() {
    yield takeEvery<ToggleWhatsNewAction>(TOGGLE_WHATS_NEW, function* (action) {
        if (action.payload === false) {
            yield put(addAlreadyShownAction(OneTimeKey.CurrentWhatsNew))
        }
    })
}

export const layoutSagas = [
    syncEventManagerDotSaga,
    reloadEventManagerDotSaga,
    rememberWhatsNewClose,
]
