import React, { FC } from 'react'

import { GeolocationControl } from './navigation/GeolocationControl'
import { RotateControl } from './navigation/RotateControl'
import { ZoomControl } from './navigation/ZoomControl'

export const NavigationControls: FC = () => (
    <>
        <ZoomControl />
        <RotateControl />
        <GeolocationControl />
    </>
)
