import { DateTime } from 'aos-helpers/src/helpers/Time'

import { Auditable } from '../../base/types/Auditable'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { TaskUpdateMode } from './payload/BaseTaskFormPayload'
import { TaskAssignee } from './task/TaskAssignee'
import { TaskCategory } from './TaskCategory'
import { TaskLogType } from './TaskLog'
import { TaskProcessType } from './TaskProcessType'
import { TaskStatus } from './TaskStatus'

export interface TaskSnapshot extends Auditable, TaskId {
    category: TaskCategory
    severity: AosSeverity
    title: string
    startTime: DateTime
    endTime?: DateTime
    assignees: TaskAssignee[]
    location?: AosLocation
    status: TaskStatus
    attachmentsLength: number
    hasChecklist: boolean
    hasLocation: boolean
    hasRecurrence: boolean
    overdue: boolean
    processType: TaskProcessType

    lastComment?: string
    lastLogTime?: DateTime
    lastLogType?: TaskLogType
}

export interface TaskId {
    id?: number
    parentTaskId?: number
    instanceId?: number
}

export const toFlatTaskId = (id: TaskId) => {
    if (id.id) {
        return `${id.id}`
    } else {
        return `${id.parentTaskId}_${id.instanceId}`
    }
}

export const fromFlatId = (id: string): TaskId => {
    const items = id.split('_').map(v => parseInt(v, 10))
    if (items.length === 1) {
        return {
            id: items[0],
        }
    }
    return {
        parentTaskId: items[0],
        instanceId: items[1],
    }
}

export const toUrlHandle = (
    id: TaskId,
    updateMode?: TaskUpdateMode,
    includeTaskId: boolean = false,
): string => {
    if (updateMode === TaskUpdateMode.Parent && id.parentTaskId) {
        return `${id.parentTaskId}`
    } else if (id.parentTaskId) {
        return includeTaskId && id.id
            ? `${id.parentTaskId}/${id.id}/${id.instanceId}`
            : `${id.parentTaskId}/${id.instanceId}`
    } else {
        return `${id.id}`
    }
}

export const taskIsEqual = (a: TaskId, b: TaskId = {}) => {
    if (typeof a.id === 'number' && typeof b.id === 'number') {
        return a.id === b.id
    }

    if (typeof a.parentTaskId === 'number' && typeof b.parentTaskId === 'number') {
        // eslint-disable-next-line eqeqeq
        return a.parentTaskId === b.parentTaskId && a.instanceId == b.instanceId
    }

    return false
}
