import { InvitationRole, InvitationStatus } from './types/InvitationStatus'

const ChecklistEditableStatusesParticipant = [InvitationStatus.Coming, InvitationStatus.AtAirport]
const OtherRolesAllowedToEditChecklist = [InvitationRole.Participate]
const ChecklistEditableStatusesCommander = [
    InvitationStatus.Coming,
    InvitationStatus.AtAirport,
    InvitationStatus.Remote,
    InvitationStatus.Skip,
]

export function checkIfCanEditCheckboxes(
    isApoc: boolean,
    invitationRole: InvitationRole | undefined,
    invitationStatus: InvitationStatus | undefined,
) {
    if (!isApoc) {
        return invitationRole === InvitationRole.Command
            ? ChecklistEditableStatusesCommander.some(status => status === invitationStatus)
            : OtherRolesAllowedToEditChecklist.some(role => role === invitationRole) &&
                  ChecklistEditableStatusesParticipant.some(status => status === invitationStatus)
    }
    return true
}
