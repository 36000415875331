import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { select, takeEvery } from 'redux-saga/effects'

import { firebaseAlertsRepository } from '../../dataaccess/alerts/firebaseAlertsRepository'
import { AlertsStateDto } from '../../dataaccess/alerts/types/AlertsStateDto'
import { firebaseService } from '../../services/firebase/firebaseService'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { PREFERENCES_LOCATION_CHANGED } from '../auth/actions'
import { currentUserSiteLocation } from '../auth/state'
import {
    SEND_ALERTS_STATE,
    SendAlertsStateAction,
    setAlertsStateAction,
    SYNC_ALERTS,
    SYNC_ALERTS_STOP,
    SyncAlertsAction,
    SyncAlertsStopAction,
} from './actions'

const alertsListener = 'alertsListener'

function* syncTask() {
    const location: AosAirport = yield select(currentUserSiteLocation)
    yield syncValue<[string, AlertsStateDto]>(
        firebaseAlertsRepository.ref(location),
        ([_id, state]) => setAlertsStateAction(state),
        false,
    )
}

function* syncAlertsSaga() {
    yield takeEvery<SyncAlertsAction>(SYNC_ALERTS, function* (action) {
        yield* firebaseService.onListener(alertsListener, syncTask, action.payload) as Generator
    })
}

function* syncAlertsStopSaga() {
    yield takeEvery<SyncAlertsStopAction>(SYNC_ALERTS_STOP, function* (action) {
        yield firebaseService.offListener(alertsListener, action.payload)
    })
}

function* sendAlertsSaga() {
    yield takeEvery<SendAlertsStateAction>(SEND_ALERTS_STATE, function* (action) {
        const location: AosAirport = yield select(currentUserSiteLocation)
        yield firebaseAlertsRepository.ref(location).update(action.payload)
    })
}

function* alertsLocationChangeSaga() {
    yield takeEvery(PREFERENCES_LOCATION_CHANGED, function* () {
        yield firebaseService.restartListener(alertsListener, syncTask)
    })
}

export const alertsSagas = [
    syncAlertsSaga,
    syncAlertsStopSaga,
    sendAlertsSaga,
    alertsLocationChangeSaga,
]
