import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TrendStats } from 'aos-services/src/services/common/types/TrendStats'
import { ChartLabel } from 'aos-ui/src/components/text/ChartLabel'
import React, { FC } from 'react'

interface RegularityChartLegendProps {
    item: TrendStats
}

export const RegularityChartLegend: FC<RegularityChartLegendProps> = props => (
    <ChartLabel
        marginTop={16}
        justify='center'
        row
        items={[
            {
                left: props.item.all,
                right: translate('dashboard.regularity.unit.all-flights'),
            },
            {
                left: props.item.applicable,
                right: translate('dashboard.regularity.unit.cancelled'),
            },
        ]}
    />
)
