import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { sentryConfigService } from 'aos-services/src/services/logging/sentryConfigService'
import { GlobalStyle } from 'aos-ui/src/components/base/GlobalStyle'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { ErrorBoundary } from 'aos-ui/src/components/errorhandling/ErrorBoundary'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { StyledToastContainer } from 'aos-ui/src/components/toast/StyledToastContainer'
import { appConfig } from 'appConfig'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'

import { store } from './core/store'
import { WebKeycloakProvider } from './keycloak/WebKeycloakProvider'
import { AppRouter } from './views/AppRouter'

logger.init(sentryConfigService.configure(appConfig))

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <StyleSheetManager disableVendorPrefixes>
        <>
            <GlobalStyle />
            <Provider store={store}>
                <WebKeycloakProvider>
                    <ErrorBoundary>
                        <AppRouter />
                        <StyledToastContainer
                            closeButton={props => (
                                <IconButton svg={SvgIcon.Close} onClick={props.closeToast} />
                            )}
                        />
                    </ErrorBoundary>
                </WebKeycloakProvider>
            </Provider>
        </>
    </StyleSheetManager>,
)
