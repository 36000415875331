import { cx } from 'aos-components/src/components/base/ClassNames'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC, PropsWithChildren } from 'react'

interface TowingMultiCellProps {
    darker?: boolean
}

export const TowingMultiCell: FC<PropsWithChildren<TowingMultiCellProps>> = props => {
    const { children, darker } = props
    return (
        <Box
            className={cx(
                'flight-table-equal-columns flight-table-equal-columns--center full-height',
                {
                    'flight-table-cell--darker': darker,
                },
            )}
        >
            {children}
        </Box>
    )
}
