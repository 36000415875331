import {
    EventMapUserLayerVisibilityAction,
    LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS,
    SET_EVENT_MAP_USER_LAYERS_VISIBILITY,
} from './actions'
import {
    EventMapUserLayerVisibilityState,
    initialWorldEventMapUserLayersVisibilityState,
} from './state'

export function eventMapUserLayerVisibilityReducer(
    state = initialWorldEventMapUserLayersVisibilityState,
    action: EventMapUserLayerVisibilityAction,
): EventMapUserLayerVisibilityState {
    switch (action.type) {
        case LOAD_EVENT_MAP_USER_LAYER_VISIBILITY_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                isLoading: false,
                floor: action.payload.floor,
            }

        case SET_EVENT_MAP_USER_LAYERS_VISIBILITY:
            return {
                ...state,
                list: action.payload.list,
                floor: action.payload.floor,
            }

        default:
            return state
    }
}
