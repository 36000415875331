import {
    AttachmentsAction,
    deleteAttachmentAction,
    replaceAttachmentAction,
    uploadAttachmentAction,
} from 'aos-services/src/core/attachments/actions'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { AttachmentUpdatePayload } from 'aos-services/src/services/attachments/types/AttachmentUpdatePayload'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { noop } from 'lodash'
import React, { FCWithChildren, useState } from 'react'
import styled, { css } from 'styled-components'

import { PreviewModal } from '../PreviewModal'
import { AttachmentPlaceholder } from './AttachmentPlaceholder'
import { AttachmentUpload } from './AttachmentUpload'
import { Previewable } from './Previewable'

interface AttachmentContainerProps {
    attachmentsState: AttachmentsState
    editable: boolean
    gridSize: number
    variant?: ThemeVariant
    reversePhotos?: boolean

    attachmentsAction?(a: AttachmentsAction): void
}

export const AttachmentContainer: FCWithChildren<AttachmentContainerProps> = ({
    editable,
    attachmentsAction = noop,
    attachmentsState,
    gridSize,
    variant = ThemeVariant.White,
    reversePhotos,
}) => {
    const [isOpen, setOpen] = useState(false)
    const [attachmentsIndex, setIndex] = useState(0)
    const updateAttachment = (p: AttachmentUpdatePayload) =>
        attachmentsAction(replaceAttachmentAction(p))
    const deleteAttachment = (p: UploadedAttachment) => attachmentsAction(deleteAttachmentAction(p))
    const uploadAttachment = (p: FileList) => attachmentsAction(uploadAttachmentAction(p))
    const previewPhoto = (index: number) => {
        setOpen(true)
        setIndex(index)
    }
    return (
        <>
            <BoxWrapper gridSize={gridSize} reversePhotos={reversePhotos}>
                {editable && <AttachmentUpload variant={variant} onUpload={uploadAttachment} />}
                {attachmentsState.isUploading && <AttachmentPlaceholder variant={variant} />}
                {attachmentsState.attachments.map((a, index) => (
                    <Previewable
                        key={index}
                        variant={variant}
                        attachment={a}
                        onDelete={() => deleteAttachment(attachmentsState.attachments[index])}
                        onClick={() => previewPhoto(index)}
                        previewable
                    />
                ))}
            </BoxWrapper>
            <PreviewModal
                attachments={attachmentsState.attachments}
                attachmentIndex={attachmentsIndex}
                isOpen={isOpen}
                closeAction={() => setOpen(false)}
                editable={editable}
                updateAttachment={updateAttachment}
                onIndexChange={setIndex}
            />
        </>
    )
}

const BoxWrapper = styled.div<{ gridSize: number; reversePhotos?: boolean }>`
    display: grid;
    grid-template-columns: repeat(${p => p.gridSize}, 1fr);
    grid-gap: 12px;

    ${props =>
        props.reversePhotos &&
        css`
            grid-auto-flow: dense;
            direction: rtl;
        `}
`
