import {
    cdmSyncAction,
    cdmSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/cdm/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { FC, PropsWithChildren } from 'react'

import { useSyncHook } from '../../../../../hooks/useSync'

interface CdmSyncWrapperProps {
    id: SyncDataKey
}

export const CdmSyncWrapper: FC<PropsWithChildren<CdmSyncWrapperProps>> = ({ children, id }) => {
    useSyncHook([cdmSyncAction(id)], [cdmSyncStopAction(id)])
    return (children as JSX.Element) || null
}
