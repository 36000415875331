import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Page, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { FodActions } from 'aos-services/src/core/fod/actions'
import { UserLayerVisibilityAction } from 'aos-services/src/core/userLayersVisibility/actions'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import { MapAction, mapActionCreators } from 'aos-ui-map/src/core/actions'

export const SET_FOD_CREATE_MODAL_OPEN = 'FOD/SET_FOD_CREATE_MODAL_OPEN'
export type SetFodCreateModalOpenAction = PayloadAction<typeof SET_FOD_CREATE_MODAL_OPEN, boolean>
export const setFodCreateModalOpenAction = payloadActionCreator<
    SetFodCreateModalOpenAction,
    boolean
>(SET_FOD_CREATE_MODAL_OPEN)

export const SET_FOD_EDIT_MODAL_OPEN = 'FOD/SET_FOD_EDIT_MODAL_OPEN'
export type SetFodEditModalOpenAction = PayloadAction<typeof SET_FOD_EDIT_MODAL_OPEN, FodDto>
export const setFodEditModalOpenAction = payloadActionCreator<
    SetFodEditModalOpenAction,
    FodDto | null
>(SET_FOD_EDIT_MODAL_OPEN)

export const SAVE_LOCATION = 'FOD/SAVE_LOCATION'
export type SaveLocationAction = Action<typeof SAVE_LOCATION>
export const saveLocationAction = emptyActionCreator<SaveLocationAction>(SAVE_LOCATION)

export const FOD_EDIT_MAP_PARENT = 'FOD/FOD_EDIT_MAP_PARENT'
export type FodEditMapParentAction = PayloadAction<
    typeof FOD_EDIT_MAP_PARENT,
    MapAction | UserLayerVisibilityAction
>
export const fodEditMapParentAction = payloadActionCreator<FodEditMapParentAction, MapAction>(
    FOD_EDIT_MAP_PARENT,
)

export const fodEditMapActions = parentActionWrapper(fodEditMapParentAction, mapActionCreators)

export const FOD_ATTACHMENTS_PARENT = 'FOD/FOD_ATTACHMENTS_PARENT'
export type FodAttachmentsParentAction = PayloadAction<
    typeof FOD_ATTACHMENTS_PARENT,
    AttachmentsAction
>
export const fodAttachmentsParentAction = payloadActionCreator<
    FodAttachmentsParentAction,
    AttachmentsAction
>(FOD_ATTACHMENTS_PARENT)

export const FOD_RELOAD = 'FOD/RELOAD'
export type FodReloadAction = PayloadAction<typeof FOD_RELOAD, Pageable<FodDto>>
export const fodReloadAction = payloadActionCreator<FodReloadAction, Page>(FOD_RELOAD)

export const FOD_RELOAD_SUCCESS = 'FOD/RELOAD_SUCCESS'
export type FodReloadSuccessAction = PayloadAction<typeof FOD_RELOAD_SUCCESS, Pageable<FodDto>>
export const fodReloadSuccessAction = payloadActionCreator<
    FodReloadSuccessAction,
    Pageable<FodDto>
>(FOD_RELOAD_SUCCESS)

export const SET_AIRPORT = 'FOD/SET_AIRPORT'
export type SetAirportAction = PayloadAction<typeof SET_AIRPORT, string>
export const setAirportAction = payloadActionCreator<SetAirportAction, string>(SET_AIRPORT)

export const SET_PREVIEW_FOD_LOCATION_MODAL_OPEN = 'FOD/SET_PREVIEW_FOD_LOCATION_MODAL_OPEN'
export type SetPreviewFodLocationModalOpenAction = PayloadAction<
    typeof SET_PREVIEW_FOD_LOCATION_MODAL_OPEN,
    boolean
>
export const setPreviewFodLocationModalOpenAction = payloadActionCreator<
    SetPreviewFodLocationModalOpenAction,
    boolean
>(SET_PREVIEW_FOD_LOCATION_MODAL_OPEN)

export const PREVIEW_FOD_LOCATION = 'FOD/PREVIEW_FOD_LOCATION'
export type PreviewFodLocationAction = PayloadAction<typeof PREVIEW_FOD_LOCATION, FodDto>
export const previewFodLocationAction = payloadActionCreator<PreviewFodLocationAction, FodDto>(
    PREVIEW_FOD_LOCATION,
)

export const fodMapActions = parentActionWrapper(fodEditMapParentAction, mapActionCreators)

export type FodWebActions =
    | SetFodCreateModalOpenAction
    | SaveLocationAction
    | FodEditMapParentAction
    | FodAttachmentsParentAction
    | FodReloadAction
    | FodReloadSuccessAction
    | SetAirportAction
    | SetPreviewFodLocationModalOpenAction
    | PreviewFodLocationAction
    | FodActions
    | SetFodEditModalOpenAction
