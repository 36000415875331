import React, { FC, PropsWithChildren } from 'react'

import { PictogramTextIcon } from './PictogramTextIcon'

interface StandIconProps {
    text: string
}

// tslint:disable:max-line-length
export const StandIcon: FC<PropsWithChildren<StandIconProps>> = props => (
    <PictogramTextIcon t1={props.text}>
        <path
            d='M53.155 47.977c-8.855-.085-18.943-.09-30.264-.02l-5.062-.074-4.047-15.098 4.568-.094 4.58 6.15 15.572-.034-6.471-24.16 6.013-.03L53.176 38.79l16.632-.015c1.257.012 2.335.479 3.232 1.393.897.91 1.351 1.994 1.363 3.248.006 1.251-.427 2.296-1.308 3.132-.879.84-1.94 1.273-3.187 1.306l-16.753.124zm-38.152 8.204h57.655v6.107H15.003v-6.107z'
            fill='#FFF'
            fillRule='nonzero'
        />
    </PictogramTextIcon>
)
