import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'

export const LOAD_RECENT_TASKS = 'TASKS/LOAD_RECENT_TASKS'
export type LoadRecentTasksAction = Action<typeof LOAD_RECENT_TASKS>
export const loadRecentTasksAction = emptyActionCreator<LoadRecentTasksAction>(LOAD_RECENT_TASKS)

export const LOAD_RECENT_TASKS_SUCCESS = 'TASKS/LOAD_RECENT_TASKS_SUCCESS'
export type LoadRecentTasksSuccessAction = PayloadAction<
    typeof LOAD_RECENT_TASKS_SUCCESS,
    TaskSnapshot[]
>
export const loadRecentTasksSuccessAction = payloadActionCreator<
    LoadRecentTasksSuccessAction,
    TaskSnapshot[]
>(LOAD_RECENT_TASKS_SUCCESS)

export const LOAD_RECENT_TASKS_FAILURE = 'TASKS/LOAD_RECENT_TASKS_FAILURE'
export type LoadRecentTasksFailureAction = Action<typeof LOAD_RECENT_TASKS_FAILURE>
export const loadRecentTasksFailureAction = emptyActionCreator<LoadRecentTasksFailureAction>(
    LOAD_RECENT_TASKS_FAILURE,
)

export type RecentTasksAction =
    | LoadRecentTasksAction
    | LoadRecentTasksSuccessAction
    | LoadRecentTasksFailureAction
