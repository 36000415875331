import {
    AttachmentsAction,
    DELETE_ATTACHMENT,
    REPLACE_ATTACHMENT_SUCCESS,
    UPLOAD_ATTACHMENT,
    UPLOAD_ATTACHMENT_ADD,
    UPLOAD_ATTACHMENT_SUCCESS,
} from './actions'
import { AttachmentsState, initialAttachmentsState, replaceAttachment } from './state'

export function attachmentReducer(
    state = initialAttachmentsState,
    action: AttachmentsAction,
): AttachmentsState {
    switch (action.type) {
        case UPLOAD_ATTACHMENT:
            return {
                ...state,
                isUploading: true,
            }

        case UPLOAD_ATTACHMENT_ADD: {
            return {
                ...state,
                attachments: [...state.attachments, action.payload],
            }
        }

        case UPLOAD_ATTACHMENT_SUCCESS:
            return {
                ...state,
                isUploading: false,
            }

        case DELETE_ATTACHMENT:
            return {
                ...state,
                attachments: state.attachments.filter(({ link }) => link !== action.payload.link),
            }

        case REPLACE_ATTACHMENT_SUCCESS:
            return replaceAttachment(state, action.payload.oldUrl, action.payload.newUrl)

        default:
            return state
    }
}
