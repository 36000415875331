import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { PropsWithChildren, PureComponent } from 'react'
import { AutoSizer, Table } from 'react-virtualized'

import { TableRow } from '../../../flightInformation/partial/TableRow'

interface WidgetFlightsProps<T extends Flight> {
    flights: T[]
}

export class WidgetFlights<T extends Flight> extends PureComponent<
    PropsWithChildren<WidgetFlightsProps<T>>
> {
    public render() {
        const { flights, children } = this.props
        return (
            <Box flex={1} fullHeight>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={50}
                            rowHeight={50}
                            rowCount={flights.length}
                            rowGetter={({ index }) => flights[index]}
                            rowClassName='flight-table-row text__l-grey text--center'
                            rowRenderer={row => <TableRow key={row.index} row={row} />}
                        >
                            {children}
                        </Table>
                    )}
                </AutoSizer>
            </Box>
        )
    }
}
