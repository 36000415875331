import { SimpleFormModalActions } from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import {
    SingleInputActionCreators,
    singleInputActionCreators,
} from 'aos-components/src/components/modal/InputModal/core/singleInput/actions'
import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { UserPageRequest } from 'aos-services/src/services/users/transport/UsersPageRequest'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

import {
    AdUserAction,
    AdUserActionCreators,
    adUserActionCreators,
} from './manageUser/adUser/actions'
import {
    ContactAction,
    ContactActionCreators,
    contactActionCreators,
} from './manageUser/contact/actions'

export const LOAD_GROUPS = 'GROUP_MANAGER/LOAD_GROUPS'
export type LoadGroupsAction = Action<typeof LOAD_GROUPS>
export const loadGroupsAction = emptyActionCreator<LoadGroupsAction>(LOAD_GROUPS)

export const LOAD_GROUPS_SUCCESS = 'GROUP_MANAGER/LOAD_GROUPS_SUCCESS'
export type LoadGroupsSuccessAction = PayloadAction<typeof LOAD_GROUPS_SUCCESS, AosUserGroup[]>
export const loadGroupsSuccessAction = payloadActionCreator<
    LoadGroupsSuccessAction,
    AosUserGroup[]
>(LOAD_GROUPS_SUCCESS)

export const SET_USERS_FILTER = 'GROUP_MANAGER/SET_USERS_FILTER'
export type SetUsersFilterAction = PayloadAction<typeof SET_USERS_FILTER, string>
export const setUsersFilterAction = payloadActionCreator<SetUsersFilterAction, string>(
    SET_USERS_FILTER,
)

export const APPLY_USERS_FILTER = 'GROUP_MANAGER/APPLY_USERS_FILTER'
export type ApplyUsersFilterAction = PayloadAction<typeof APPLY_USERS_FILTER, string>
export const applyUsersFilterAction = payloadActionCreator<ApplyUsersFilterAction, string>(
    APPLY_USERS_FILTER,
)

export const SELECT_GROUP = 'GROUP_MANAGER/SELECT_GROUP'
export type SelectGroupAction = PayloadAction<typeof SELECT_GROUP, AosUserGroup>
export const selectGroupAction = payloadActionCreator<SelectGroupAction, AosUserGroup>(SELECT_GROUP)

export const REMOVE_USER_GROUP = 'GROUP_MANAGER/REMOVE_USER_GROUP'
export type RemoveUserGroupAction = PayloadAction<typeof REMOVE_USER_GROUP, AosUserGroup>
export const removeUserGroupAction = payloadActionCreator<RemoveUserGroupAction, AosUserGroup>(
    REMOVE_USER_GROUP,
)

export const RENAME_USER_GROUP = 'GROUP_MANAGER/RENAME_USER_GROUP'
export type RenameUserGroupParentAction = PayloadAction<
    typeof RENAME_USER_GROUP,
    SimpleFormModalActions
>
export const renameUserGroupParentAction = payloadActionCreator<
    RenameUserGroupParentAction,
    SimpleFormModalActions
>(RENAME_USER_GROUP)
export const renameUserGroupActions: SingleInputActionCreators = parentActionWrapper(
    renameUserGroupParentAction,
    singleInputActionCreators,
)

export const EDIT_NOTIFICATIONS_SETTINGS_MODAL = 'GROUP_MANAGER/EDIT_NOTIFICATIONS_SETTINGS_MODAL'
export type EditNotificationSettingsModalAction = PayloadAction<
    typeof EDIT_NOTIFICATIONS_SETTINGS_MODAL,
    AosUserGroup
>
export const editNotificationSettingsModalAction = payloadActionCreator<
    EditNotificationSettingsModalAction,
    AosUserGroup
>(EDIT_NOTIFICATIONS_SETTINGS_MODAL)

export const CLOSE_NOTIFICATION_SETTINGS_MODAL = 'GROUP_MANAGER/CLOSE_NOTIFICATION_SETTINGS_MODAL'
export type CloseNotificationSettingsModalAction = Action<typeof CLOSE_NOTIFICATION_SETTINGS_MODAL>
export const closeNotificationSettingsModalAction = emptyActionCreator<CloseNotificationSettingsModalAction>(
    CLOSE_NOTIFICATION_SETTINGS_MODAL,
)

export const SAVE_NOTIFICATIONS_SETTINGS = 'GROUP_MANAGER/SAVE_NOTIFICATIONS_SETTINGS'
export type SaveNotificationSettingsAction = PayloadAction<
    typeof SAVE_NOTIFICATIONS_SETTINGS,
    AosUserNotificationsPreferences
>
export const saveNotificationSettingsAction = payloadActionCreator<
    SaveNotificationSettingsAction,
    AosUserNotificationsPreferences
>(SAVE_NOTIFICATIONS_SETTINGS)

export const SAVE_NOTIFICATIONS_SETTINGS_SUCCESS =
    'GROUP_MANAGER/SAVE_NOTIFICATIONS_SETTINGS_SUCCESS'
export type SaveNotificationSettingsSuccessAction = Action<
    typeof SAVE_NOTIFICATIONS_SETTINGS_SUCCESS
>
export const saveNotificationSettingsSuccessAction = emptyActionCreator<SaveNotificationSettingsSuccessAction>(
    SAVE_NOTIFICATIONS_SETTINGS_SUCCESS,
)

export const LOAD_USERS = 'GROUP_MANAGER/LOAD_USERS'
export type LoadUsersAction = PayloadAction<typeof LOAD_USERS, [number, UserPageRequest]>
export const loadUsersAction = payloadActionCreator<LoadUsersAction, [number, UserPageRequest]>(
    LOAD_USERS,
)

export const LOAD_USERS_SUCCESS = 'GROUP_MANAGER/LOAD_USERS_SUCCESS'
export type LoadUsersSuccessAction = PayloadAction<typeof LOAD_USERS_SUCCESS, Pageable<AosUser>>
export const loadUsersSuccessAction = payloadActionCreator<
    LoadUsersSuccessAction,
    Pageable<AosUser>
>(LOAD_USERS_SUCCESS)

export const SELECT_USER = 'GROUP_MANAGER/SELECT_USER'
export type SelectUserAction = PayloadAction<typeof SELECT_USER, AosUser>
export const selectUserAction = payloadActionCreator<SelectUserAction, AosUser>(SELECT_USER)

export const REMOVE_USER = 'GROUP_MANAGER/REMOVE_USER'
export type RemoveUserAction = PayloadAction<typeof REMOVE_USER, number>
export const removeUserAction = payloadActionCreator<RemoveUserAction, number>(REMOVE_USER)

export const REMOVE_USER_FROM_GROUP = 'GROUP_MANAGER/REMOVE_USER_FROM_GROUP'
export type RemoveUserFromGroupAction = PayloadAction<
    typeof REMOVE_USER_FROM_GROUP,
    [AosUserGroup, AosUser]
>
export const removeUserFromGroupAction = payloadActionCreator<
    RemoveUserFromGroupAction,
    [AosUserGroup, AosUser]
>(REMOVE_USER_FROM_GROUP)

export const RESTORE_USER = 'GROUP_MANAGER/RESTORE_USER'
export type RestoreUserAction = PayloadAction<typeof RESTORE_USER, AosUser>
export const restoreUserAction = payloadActionCreator<RestoreUserAction, AosUser>(RESTORE_USER)

export const REMOVE_USER_PERMANENTLY = 'GROUP_MANAGER/REMOVE_USER_PERMANENTLY'
export type RemoveUserPermanentlyAction = PayloadAction<typeof REMOVE_USER_PERMANENTLY, number>
export const removeUserPermanentlyAction = payloadActionCreator<
    RemoveUserPermanentlyAction,
    number
>(REMOVE_USER_PERMANENTLY)

export const CREATE_GROUP_PARENT = 'GROUP_MANAGER/CREATE_GROUP_PARENT'
export type CreateGroupParentAction = PayloadAction<
    typeof CREATE_GROUP_PARENT,
    SimpleFormModalActions
>
export const createGroupParentAction = payloadActionCreator<
    CreateGroupParentAction,
    SimpleFormModalActions
>(CREATE_GROUP_PARENT)

export const ADD_AD_USER_PARENT = 'GROUP_MANAGER/ADD_AD_USER_PARENT'
export type AddAdUserParentAction = PayloadAction<typeof ADD_AD_USER_PARENT, AdUserAction>
export const addAdUserParentAction = payloadActionCreator<AddAdUserParentAction, AdUserAction>(
    ADD_AD_USER_PARENT,
)

export const ADD_CONTACT_PARENT = 'GROUP_MANAGER/ADD_CONTACT_PARENT'
export type AddContactParentAction = PayloadAction<typeof ADD_CONTACT_PARENT, ContactAction>
export const addContactParentAction = payloadActionCreator<AddContactParentAction, ContactAction>(
    ADD_CONTACT_PARENT,
)

export const EDIT_AD_USER_PARENT = 'GROUP_MANAGER/EDIT_AD_USER_PARENT'
export type EditAdUserParentAction = PayloadAction<typeof EDIT_AD_USER_PARENT, AdUserAction>
export const editAdUserParentAction = payloadActionCreator<EditAdUserParentAction, AdUserAction>(
    EDIT_AD_USER_PARENT,
)

export const EDIT_CONTACT_PARENT = 'GROUP_MANAGER/EDIT_CONTACT_PARENT'
export type EditContactParentAction = PayloadAction<typeof EDIT_CONTACT_PARENT, ContactAction>
export const editContactParentAction = payloadActionCreator<EditContactParentAction, ContactAction>(
    EDIT_CONTACT_PARENT,
)

export const RELOAD_GROUPS_SUCCESS = 'GROUP_MANAGER/RELOAD_GROUPS_SUCCESS'
export type ReloadGroupsSuccessAction = PayloadAction<
    typeof RELOAD_GROUPS_SUCCESS,
    [AosUserGroup[], AosUserGroup | undefined]
>
export const reloadGroupsSuccessAction = payloadActionCreator<
    ReloadGroupsSuccessAction,
    [AosUserGroup[], AosUserGroup | undefined]
>(RELOAD_GROUPS_SUCCESS)

export const DOWNLOAD_GROUPS = 'GROUP_MANAGER/DOWNLOAD_GROUPS'
export type DownloadGroupsAction = Action<typeof DOWNLOAD_GROUPS>
export const downloadGroupsAction = emptyActionCreator<DownloadGroupsAction>(DOWNLOAD_GROUPS)

export const SET_USER_GROUP_EXPORTING = 'GROUP_MANAGER/SET_USER_GROUP_EXPORTING'
export type SetUserGroupExportingAction = PayloadAction<typeof SET_USER_GROUP_EXPORTING, boolean>
export const setUserGroupExportingAction = payloadActionCreator<
    SetUserGroupExportingAction,
    boolean
>(SET_USER_GROUP_EXPORTING)

export const TOGGLE_SYSTEM_GROUPS_EXPANDED = 'GROUP_MANAGER/TOGGLE_SYSTEM_GROUPS_EXPANDED'
export type ToggleSystemGroupsExpandedAction = Action<typeof TOGGLE_SYSTEM_GROUPS_EXPANDED>
export const toggleSystemGroupsExpandedAction = emptyActionCreator<ToggleSystemGroupsExpandedAction>(
    TOGGLE_SYSTEM_GROUPS_EXPANDED,
)

export const createGroupActions: SingleInputActionCreators = parentActionWrapper(
    createGroupParentAction,
    singleInputActionCreators,
)

export const addAdUserActions: AdUserActionCreators = parentActionWrapper(
    addAdUserParentAction,
    adUserActionCreators,
)

export const addContactActions: ContactActionCreators = parentActionWrapper(
    addContactParentAction,
    contactActionCreators,
)

export const editAdUserActions: AdUserActionCreators = parentActionWrapper(
    editAdUserParentAction,
    adUserActionCreators,
)

export const editContactActions: ContactActionCreators = parentActionWrapper(
    editContactParentAction,
    contactActionCreators,
)

export type GroupManagerAction =
    | LoadGroupsAction
    | LoadGroupsSuccessAction
    | SelectGroupAction
    | LoadUsersSuccessAction
    | SelectUserAction
    | LoadUsersAction
    | SetUsersFilterAction
    | ApplyUsersFilterAction
    | CreateGroupParentAction
    | RemoveUserAction
    | RemoveUserFromGroupAction
    | RestoreUserAction
    | RemoveUserPermanentlyAction
    | AddAdUserParentAction
    | AddContactParentAction
    | EditAdUserParentAction
    | EditContactParentAction
    | RemoveUserGroupAction
    | RenameUserGroupParentAction
    | EditNotificationSettingsModalAction
    | CloseNotificationSettingsModalAction
    | SaveNotificationSettingsAction
    | SaveNotificationSettingsSuccessAction
    | ReloadGroupsSuccessAction
    | SetUserGroupExportingAction
    | ToggleSystemGroupsExpandedAction
