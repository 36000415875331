import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { ReactNode } from 'react'

import { ThemeVariant } from '../../base/ThemeVariant'
import { Checkbox } from './Checkbox'

interface CheckboxListItemProps<T> {
    item: CheckboxItem<T>
    variant?: ThemeVariant
    itemToLabel: f.Func1<T, ReactNode>
    indeterminate?: boolean
    onChange(v: CheckboxItem<T>): void
}

export const CheckboxListItem = <T extends any>(props: CheckboxListItemProps<T>) => {
    const isItemSelected = (i: CheckboxItem<T>): boolean => i[1]
    const { itemToLabel, onChange, item, variant } = props
    return (
        <Checkbox
            indeterminate={props.indeterminate}
            position='left'
            checked={isItemSelected(item)}
            checkableLabel
            variant={variant}
            fontSize={12}
            onChange={() => onChange([props.item[0], !props.item[1]])}
        >
            <Box paddingVertical={8}>{itemToLabel(props.item[0])}</Box>
        </Checkbox>
    )
}
