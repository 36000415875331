import { IconModal } from 'aos-components/src/components/modal/IconModal/IconModal'
import { NewCommonMessage } from 'aos-services/src/services/messages/input/NewAosMessage'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

import { MessageConfirmation } from './MessageConfirmation'

export class MessageConfirmationModal extends PureComponent<MessageConfirmationModalProps> {
    public render() {
        const { isOpen, closeAction, group, message, onSubmit, isSendingMessage } = this.props

        return (
            <IconModal
                isOpen={isOpen}
                modalKind={ModalKind.Icon}
                closeAction={closeAction}
                svg={SvgIcon.FeedOutConfirmation}
                shouldCloseOnOverlayClick={!isSendingMessage}
            >
                <MessageConfirmation
                    group={group}
                    message={message}
                    onConfirm={onSubmit}
                    inProgress={isSendingMessage}
                />
            </IconModal>
        )
    }
}

interface MessageConfirmationModalProps {
    isOpen: boolean
    group: AosUserGroup | undefined
    message: NewCommonMessage
    isSendingMessage: boolean
    closeAction(): void
    onSubmit(): void
}
