import {
    LOAD_UPCOMING_TASKS,
    LOAD_UPCOMING_TASKS_FAILURE,
    LOAD_UPCOMING_TASKS_SUCCESS,
    UpcomingTasksAction,
} from './actions'
import { initialUpcomingTasksState, UpcomingTasksState } from './state'

export const upcomingTasksReducer = (
    state = initialUpcomingTasksState,
    action: UpcomingTasksAction,
): UpcomingTasksState => {
    switch (action.type) {
        case LOAD_UPCOMING_TASKS:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case LOAD_UPCOMING_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload,
                isLoading: false,
                isError: false,
            }
        case LOAD_UPCOMING_TASKS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state
    }
}
