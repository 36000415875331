import { normalizeVersion } from 'aos-helpers/src/helpers/Version'

import { BaseRestService, RequestInterceptorParam } from '../../dataaccess/base/BaseRestService'

const enhanceRequestWithClientVersionHeader = (
    version: string,
    requestInit?: RequestInit,
): RequestInit => {
    let currentRequestInit = requestInit || {}
    const headers: Headers = (currentRequestInit.headers || new Headers()) as Headers
    headers.append('Client-Version', version)
    currentRequestInit = Object.assign(currentRequestInit, { headers })
    return currentRequestInit
}

const apiVersioningInterceptor = (apiPath: string, version: string) => ([
    url,
    init,
]: RequestInterceptorParam): RequestInterceptorParam => {
    const normalizedVersion = normalizeVersion(version)
    if (BaseRestService.isApiPath(apiPath, url) && normalizedVersion) {
        return [url, enhanceRequestWithClientVersionHeader(normalizedVersion, init)]
    } else {
        return [url, init]
    }
}

export const registerApiVersioningInterceptor = (apiPath: string, version: string) => {
    BaseRestService.requestInterceptors.push(apiVersioningInterceptor(apiPath, version))
}
