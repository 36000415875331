import { DispersionBarConfig } from 'aos-ui/src/components/bar/DispersionBar'
import { Color } from 'aos-ui-common/src/styles/Color'

export const flightsDispersionBarConfig: DispersionBarConfig = {
    leftColor: Color.PaleOrange,
    rightColor: Color.Violet,
    showValues: true,
}

export const flightsChartConfig = {
    margins: { top: 10, bottom: 40, left: 96, right: 0 },
}
