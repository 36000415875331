import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { BaseChartChildrenProps } from 'aos-ui-common/src/components/chart/BaseChart'
import { ChartMargins } from 'aos-ui-common/src/components/chart/ChartMargins'
import { ChartChildrenProps, ChartProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import { Margins } from 'aos-ui-common/src/components/chart/types/Margins'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisBottom } from './axes/AxisBottom'
import { AxisLeft } from './axes/AxisLeft'
import { AxisRight } from './axes/AxisRight'
import { BaseChart } from './BaseChart'
import { ChartLegends } from './ChartLegends'
import { VerticalLineSeries } from './series/VerticalLineSeries'

export const Chart = <T extends AxisDomain>(props: ChartProps<T>) => {
    const {
        margins,
        flex,
        leftLegend,
        rightLegend,
        bottomLegend,
        xConfig,
        y1Config,
        y2Config,
        xBandConfig,
        currentTime,
        children,
        bottomLabelsRotation,
    } = props
    const minMargins: Margins = { top: 10, bottom: 40, left: 10, right: 10 }
    const axisConstants = {
        characterWidth: 9,
        tickPadding: 10,
        tickSize: 12,
    }
    const finalMargins = margins || ChartMargins.chartMargin(props, minMargins, axisConstants)
    const renderContent = (p: BaseChartChildrenProps) => {
        const { xRange, yRange, outerSize } = p
        const xScale = xConfig ? Scales.xScale(xRange, xConfig.domain) : undefined
        const xBandScale = xBandConfig ? Scales.xScaleBand(xRange, xBandConfig) : undefined
        const y1Scale = y1Config ? Scales.yScale(yRange, y1Config.domain) : undefined
        const y2Scale = y2Config ? Scales.yScale(yRange, y2Config.domain) : undefined
        const childProps: ChartChildrenProps<T> = {
            ...p,
            xScale,
            xBandScale,
            y1Scale,
            y2Scale,
            xBandConfig,
        }
        const chartHeight = outerSize.height - (bottomLegend?.height ?? 0)
        const timeDomain = xConfig?.domain || xBandConfig?.domain
        return (
            <>
                {xConfig && xScale && (
                    <AxisBottom
                        axisConfig={{
                            tickFormat: xConfig.tickFormat,
                            tickValues: xConfig.domain.ticks,
                            tickMarked: xConfig.tickMarked,
                        }}
                        rotate={bottomLabelsRotation}
                        scale={xScale}
                    />
                )}
                {xBandConfig && xBandScale && (
                    <AxisBottom
                        axisConfig={{
                            tickFormat: xBandConfig.tickFormat,
                            tickValues: xBandConfig.domain.ticks,
                            tickMarked: xBandConfig.tickMarked,
                            showGrids: xBandConfig.showGrids,
                        }}
                        rotate={bottomLabelsRotation}
                        scale={xBandScale.x0}
                    />
                )}
                {y1Config && y1Scale && (
                    <AxisLeft
                        axisConfig={{
                            tickValues: y1Config.domain.ticks,
                            tickFormat: y1Config.tickFormat,
                            showGrids: y1Config.showGrids,
                            showAxisLine: y1Config.showAxisLine,
                            unit: y1Config.unit,
                        }}
                        scale={y1Scale}
                        baseValues={y1Config.baseValues}
                    />
                )}
                {y2Config && y2Scale && (
                    <AxisRight
                        axisConfig={{
                            tickValues: y2Config.domain.ticks,
                            tickFormat: y2Config.tickFormat,
                            showGrids: y2Config.showGrids,
                            showAxisLine: y2Config.showAxisLine,
                            unit: y2Config.unit,
                        }}
                        scale={y2Scale}
                        baseValues={y2Config.baseValues}
                    />
                )}
                {currentTime && timeDomain && (
                    <VerticalLineSeries
                        datum={currentTime}
                        seriesConfig={{ color: 'rgba(255, 255, 255, 0.22)' }}
                        scales={{
                            xScale: Scales.timeScale(xRange, timeDomain as Domain<DateTime>),
                        }}
                        size={{ width: outerSize.width, height: chartHeight }}
                    />
                )}
                {children(childProps)}
            </>
        )
    }

    return (
        <BaseChart
            margins={finalMargins}
            flex={flex}
            legends={
                <ChartLegends
                    margins={finalMargins}
                    leftLegend={leftLegend}
                    rightLegend={rightLegend}
                    bottomLegend={bottomLegend}
                />
            }
        >
            {renderContent}
        </BaseChart>
    )
}
