import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { TokenPairDto } from './types/TokenPairDto'

class AuthRestServiceClass extends BaseRestService {
    public login(
        username: string,
        password: string,
        deviceId: string,
    ): Promise<TokenPairDto | null> {
        return this.postJsonAndTransform<TokenPairDto>(
            format({
                pathname: '/auth/login',
                query: {
                    deviceId,
                },
            }),
            {
                username,
                password,
            },
            BaseRestService.jsonTransformer,
        ).catch((e: ResponseError) =>
            e.originalResponse.status === 401 ? null : Promise.reject(e),
        )
    }

    public refresh = (deviceId: string, token: string): Promise<TokenPairDto> => {
        const headers = new Headers()
        headers.append('Authorization', `Bearer ${token}`)
        return this.postJson(
            format({
                pathname: '/auth/refresh',
                query: {
                    deviceId,
                },
            }),
            undefined,
            headers,
        ).then<TokenPairDto>(BaseRestService.jsonTransformer)
    }

    public isAuthAuditablePath = (apiPath: string, url: string) =>
        BaseRestService.isApiPath(apiPath, url) && !this.isAuthPath(url)

    /**
     * Used to check if request is related to authorization.
     *
     * @param url
     */
    private isAuthPath = (url: string) => {
        const isLoginPath = url.indexOf('/auth/login') >= 0
        const isRefreshPath = url.indexOf('/auth/refresh') >= 0
        return isLoginPath || isRefreshPath
    }
}

export const authRestService = new AuthRestServiceClass()
