import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { Moment } from 'moment/moment'

import { AosLocation } from '../../common/types/AosLocation'
import { AosAirport } from '../../flightInformation/types/AosAirport'

export enum AnonymousType {
    FINAVIA = 'FINAVIA',
    OTHER = 'OTHER',
}

export interface BaseFodFormPayload {
    id?: number
    placeOfDiscovery: PlaceOfDiscovery | undefined
    fodType: FodType | undefined
    fodSource: FodSource | undefined
    description?: string
    discoveryDate: Moment | DateTime | string | undefined
    location: AosLocation | undefined
    siteLocation?: AosAirport | undefined
    anonymousReport?: boolean
    anonymousType?: AnonymousType
}

export interface BaseFodPayloadValidation {
    placeOfDiscovery: boolean
    fodType: boolean
    fodSource: boolean
    date: boolean
    location: boolean
}

export const initialFodNotesForm: BaseFodFormPayload = {
    placeOfDiscovery: undefined,
    fodType: undefined,
    fodSource: undefined,
    description: '',
    discoveryDate: undefined,
    location: undefined,
    anonymousType: AnonymousType.FINAVIA,
    anonymousReport: false,
    id: undefined,
}

export const baseValidateFod = (e: BaseFodFormPayload): BaseFodPayloadValidation => ({
    placeOfDiscovery: isNotEmpty(e.placeOfDiscovery),
    fodType: isNotEmpty(e.fodType),
    fodSource: isNotEmpty(e.fodSource),
    date: isNotEmpty(e.discoveryDate),
    location: isNotEmpty(e.location),
})

export enum PlaceOfDiscovery {
    APRON = 'APRON',
    RUNWAY = 'RUNWAY',
    RUNWAY_AREA = 'RUNWAY_AREA',
    TAXIWAY = 'TAXIWAY',
    OTHER = 'OTHER',
}

export enum FodType {
    AIRCRAFT_PART = 'AIRCRAFT_PART',
    ANIMAL = 'ANIMAL',
    DETACHED_FROM_PAVEMENT = 'DETACHED_FROM_PAVEMENT',
    LOOSE_GARBAGE = 'LOOSE_GARBAGE',
    SNOW_OR_ICE_BLOCK = 'SNOW_OR_ICE_BLOCK',
    TOOL = 'TOOL',
    VEHICLE_PART = 'VEHICLE_PART',
    OTHER = 'OTHER',
}

export enum FodSource {
    AIRPORT = 'AIRPORT',
    AIRPORT_INFRASTRUCTURE = 'AIRPORT_INFRASTRUCTURE',
    DRIFTED_WITH_THE_WIND = 'DRIFTED_WITH_THE_WIND',
    GROUND_HANDLING = 'GROUND_HANDLING',
    SKYDIVING_ACTIVITY = 'SKYDIVING_ACTIVITY',
    VEHICLE_OR_STAKEHOLDER_ACTIVITY = 'VEHICLE_OR_STAKEHOLDER_ACTIVITY',
    OTHER = 'OTHER',
}

export const placeOfDiscoveryOptions = EnumValues.getValues<PlaceOfDiscovery>(PlaceOfDiscovery)
export const translatedPlaceOfDiscovery = translateEnum(
    PlaceOfDiscovery,
    'fod.options.placeOfDiscovery',
)

export const fodTypeOptions = EnumValues.getValues<FodType>(FodType)
export const translatedFodType = translateEnum(FodType, 'fod.options.type')

export const fodSourceOptions = EnumValues.getValues<FodSource>(FodSource)
export const translatedFodSource = translateEnum(FodSource, 'fod.options.source')
