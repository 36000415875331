import { formatDuration } from 'aos-helpers/src/helpers/Time'
import { baggageDeliveryChartColors } from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryConstants'
import { baggageDeliveryLegendConfig } from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryLegendConfig'
import { BaggageDeliveryStat } from 'aos-services/src/services/airportStatus/baggage/types/BaggageDeliveryStat'
import { trendIncreasingRedVariants } from 'aos-ui/src/components/chart/trend/TrendIconVariants'
import { merge, values } from 'lodash'
import React, { FC } from 'react'

import { DashboardPieChart } from '../../common/DashboardPieChart'
import { BaggageDeliveryChartLegend } from './BaggageDeliveryChartLabel'
import { BaggageDeliveryLegend, BaggageDeliveryLegendItemProps } from './BaggageDeliveryLegend'

const seriesColors: { [index: number]: string } = values(baggageDeliveryChartColors)

export const BaggageDeliveryChart: FC<BaggageDeliveryStat> = props => {
    const trendProps = {
        value: formatDuration(props.times.mean),
        trend: props.meanTrend,
        tooltipPrefix: 'dashboard.baggage-delivery.trend.tooltip',
        iconVariants: trendIncreasingRedVariants,
    }

    const stats = props.distributionStats
    const legendItems: BaggageDeliveryLegendItemProps[] = values(
        merge({}, stats, baggageDeliveryLegendConfig),
    )
    const percentages = values(stats).map(sectionStats => sectionStats.percentage)

    const legend = <BaggageDeliveryLegend items={legendItems} />
    const label = (
        <BaggageDeliveryChartLegend
            marginTop={16}
            row
            justify='center'
            counts={props.flightCounts}
            maxTime={props.times.max}
            minTime={props.times.min}
        />
    )
    return (
        <DashboardPieChart
            trend={trendProps}
            seriesColors={seriesColors}
            data={percentages}
            legend={legend}
            label={label}
        />
    )
}
