import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import {
    DropdownAutocomplete,
    DropdownAutocompleteProps,
} from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import React, { forwardRef } from 'react'

import { ThemeVariant } from '../../base/ThemeVariant'

interface LabeledSelectProps<T>
    extends SeleniumProps,
        DropdownAutocompleteProps<T>,
        MarginBoxProps {
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    inputVariant?: ThemeVariant
    tooltip?: string
    addNew?: boolean
}

export const LabeledSelect = forwardRef(<T extends unknown>(props: LabeledSelectProps<T>) => {
    const {
        keyPrefix,
        isRequired,
        isError,
        inputVariant = ThemeVariant.White,
        items,
        value,
        placeholder,
        width,
        small,
        valueRenderer,
        labelRenderer,
        onChange,
        seleniumLocation,
        variant,
        maxHeight,
        addNew,
        ...marginProps
    } = props
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    const dropdownVariant =
        inputVariant === ThemeVariant.White ? DropdownVariant.White : DropdownVariant.Black
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={inputVariant}
            {...marginProps}
        >
            <DropdownAutocomplete
                value={value}
                items={items}
                variant={dropdownVariant}
                valueRenderer={valueRenderer}
                labelRenderer={labelRenderer}
                placeholder={translateSuffix('placeholder')}
                onChange={onChange}
                maxHeight={maxHeight}
                outlined
                width={width}
            />
        </LabeledFormElement>
    )
})
