import { dateTime } from '../../../../aos-helpers/src/helpers/Time'
import { tasksReportingRestService } from '../../dataaccess/tasksReporting/tasksReportingRestService'
import { AnimalsOccurrenceDto } from '../../dataaccess/tasksReporting/types/AnimalsOccurrenceDto'
import { ChemicalsUsageDto } from '../../dataaccess/tasksReporting/types/ChemicalsUsageDto'
import { AosAirport } from '../flightInformation/types/AosAirport'
import { AnimalSpecie, AnimalsTaskType } from '../tasks/types/task/AnimalsTask'
import { DeIcingChemicals } from '../tasks/types/task/DeIcingChemicals'
import { AnimalsOccurrence } from './types/AnimalsOccurrence'
import { AnimalsReportData } from './types/AnimalsReportData'
import { ChemicalsReportData } from './types/ChemicalsReportData'
import { ChemicalsUsage } from './types/ChemicalsUsage'

class TasksReportingServiceClass {
    public loadChemicalsReport = async (): Promise<ChemicalsReportData> => {
        const data = await tasksReportingRestService.loadChemicalsReport()
        const types = await tasksReportingRestService.loadChemicalsTypes()
        return {
            data: data.map(this.chemicalsOccurrenceFromDto),
            types: types as DeIcingChemicals[],
        }
    }

    public loadAnimalsReport = async (): Promise<AnimalsReportData> => {
        const data = await tasksReportingRestService.loadAnimalsReport()
        const types = await tasksReportingRestService.loadAnimalsTypes()
        return {
            data: data.map(this.animalOccurrenceFromDto),
            types: types as AnimalSpecie[],
        }
    }

    private animalOccurrenceFromDto = (v: AnimalsOccurrenceDto): AnimalsOccurrence => {
        return {
            ...v,
            airport: v.airport as AosAirport,
            taskType: v.taskType as AnimalsTaskType,
            monthTime: dateTime(v.monthTime),
        }
    }

    private chemicalsOccurrenceFromDto = (v: ChemicalsUsageDto): ChemicalsUsage => {
        return {
            ...v,
            airport: v.airport as AosAirport,
            weekTime: dateTime(v.weekTime),
        }
    }
}

export const tasksReportingService = new TasksReportingServiceClass()
