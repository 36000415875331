import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { WeatherMetric } from './WeatherMetric'

interface TemperatureProps {
    value?: number
}

export const Temperature: FC<TemperatureProps> = props => (
    <WeatherMetric
        label={translate('dashboard.weather.temperature')}
        value={props.value}
        unit={translate('dashboard.weather.unit-tabs.degC')}
        unitClass='dashboard-weather__metric__unit--c-degr'
    >
        <Icon
            svg={SvgIcon.WeatherTemperature}
            iconSize={BlockSize.Large}
            iconVariant={IconVariant.White}
        />
    </WeatherMetric>
)
