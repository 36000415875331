import { flatten } from 'lodash'

import { GaugeRange } from '../types/GaugeRange'

const { min, max } = Math

export const getMinMax = (gaugeRange: GaugeRange[]) => {
    const ranges = flatten(gaugeRange.map(({ from, to }) => [from, to]))

    return [min(...ranges), max(...ranges)]
}
