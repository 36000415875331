import classnames from 'classnames'
export { default as cx } from 'classnames'

interface ClassDictionary {
    [id: string]: boolean | undefined | null
}
type ClassValue = string | number | ClassDictionary | undefined | null | false

export interface ClassNameProps {
    className?: string
}

// @deprecated use box
export function cxp(props: ClassNameProps, ...classes: ClassValue[]): string {
    return classnames(classes, props.className)
}
