import { BaseRestService } from '../base/BaseRestService'
import { PaxForecastDto } from './types/PaxForecastDto'

class PaxForecastRestService extends BaseRestService {
    public getPaxForecast(): Promise<PaxForecastDto> {
        return this.getAndTransform<PaxForecastDto>(
            '/airport-status/pax-forecast',
            BaseRestService.jsonTransformer,
        )
    }
}

export const paxForecastRestService = new PaxForecastRestService()
