import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatCalendarTime, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Icon, IconVariant } from '../svg/Icon'

interface HourClockProps {
    time: DateTime
    showFullDate?: boolean
}

export const HourClock: FCWithChildren<HourClockProps> = props => (
    <Text color={Color.Grey} size={12} row>
        <Icon
            iconVariant={IconVariant.GreyTxt}
            iconSize={BlockSize.Tiny}
            svg={SvgIcon.CurrentTime}
        />
        <Box paddingLeft={4} as='span'>
            {props.showFullDate ? formatCalendarTime(props.time) : formatTime(props.time)}
        </Box>
    </Text>
)
