import { Arrivals } from 'aos-services/src/services/flightInformation/types/ArrivalsAndDepartures'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { flightMapArrivalsSelector } from '../../../core/airportMap/selectors'
import { SidebarOwnProps } from '../../../core/airportMap/state'
import { AirportResourceSidebar } from '../../sidebar/AirportResourceSidebar'

export const BaggageClaimDetailsSidebar = connect<Arrivals, {}, SidebarOwnProps>(
    createStructuredSelector({
        arrivals: flightMapArrivalsSelector,
    }),
)(AirportResourceSidebar)
