import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'

interface ShiftNotesItemProps {
    noDataWarning: boolean
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const ShiftNotesItem: FC<PropsWithChildren<ShiftNotesItemProps>> = props => (
    <StatusDashboardItem
        title='Shift Notes'
        description={translate('shift-notes.list.description')}
        noDataWarning={props.noDataWarning}
        link={props.link}
        {...props.itemProps}
    >
        {props.children}
    </StatusDashboardItem>
)
