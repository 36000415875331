import { PaxLocalOrTransfer } from '../../../../airportStatus/paxForecast/types/PaxLocalOrTransfer'
import { FilterOptionAll, filterOptionsWithAll, translateFilterWithAllOption } from '../common'

export type PaxForecastLocalOrTransferFilter = FilterOptionAll | PaxLocalOrTransfer

export const paxForecastLocalOrTransferFilterValues: PaxForecastLocalOrTransferFilter[] = filterOptionsWithAll(
    PaxLocalOrTransfer,
)
export const translatePaxLocalOrTransferFilter: f.Func1<
    PaxForecastLocalOrTransferFilter,
    string
> = translateFilterWithAllOption(
    PaxLocalOrTransfer,
    'dashboard.pax-forecast.filters.local-or-transfer',
)
