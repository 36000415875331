import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { AosChecklistItemType } from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

export const ChecklistItemIcon: FC<ChecklistItemIconProps> = props => {
    const getIcon = (type: AosChecklistItemType) => {
        switch (type) {
            case AosChecklistItemType.Task:
                return SvgIcon.Task

            case AosChecklistItemType.Description:
                return SvgIcon.Info

            default:
                return null
        }
    }
    const icon = getIcon(props.type)
    return (
        icon && (
            <Icon
                marginRight={8}
                iconSize={BlockSize.Std}
                iconVariant={IconVariant.BlackGrey}
                svg={icon}
            />
        )
    )
}

interface ChecklistItemIconProps {
    type: AosChecklistItemType
}
