import { ActionModalTextContentProps } from 'aos-components/src/components/modal/ActionModal/ActionModalTextContent'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'

export type ActionModalConfig = ActionModalTextContentProps & SvgProps

export interface ActionModalState {
    modalConfig?: ActionModalConfig
}

export interface ActionModalStateAware {
    actionModal: ActionModalState
}

export const initialActionModalState: ActionModalState = {}
