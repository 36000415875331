import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Feed } from 'aos-services/src/services/newsFeed/types/Feed'
import { FeedChannelsFilter } from 'aos-services/src/services/newsFeed/types/FeedChannelsFilter'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { NewsFeedItemState } from '../../../../../services/statusDashboard/types/itemStates/NewsFeedItemState'
import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { NewsFeedSyncWrapper } from './NewsFeedSyncWrapper'

interface NewsFeedItemProps {
    newsFeed: Feed[]
    itemProps: StatusDashboardItemCommonProps<NewsFeedItemState>
    link?: string
    filters: FeedChannelsFilter[]
    syncId: SyncDataKey
}

export const NewsFeedItem: FC<PropsWithChildren<NewsFeedItemProps>> = props => (
    <NewsFeedSyncWrapper id={props.syncId} filters={props.filters}>
        <StatusDashboardItem
            title={translate('dashboard.news-feed.title')}
            description={translate('dashboard.news-feed.description')}
            noDataWarning={props.newsFeed === null}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </NewsFeedSyncWrapper>
)
