import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Text } from '../../base/Text'
import { CollapsibleButtonVariant } from '../../buttons/CollapsibleButton'
import { CollapsibleContainer } from '../../container/CollapsibleContainer'

interface CollapsibleTimelineGroupProps {
    isCollapsed: boolean
    label: string
    toggle(): void
}

export const CollapsibleTimelineGroup: FCWithChildren<CollapsibleTimelineGroupProps> = ({
    isCollapsed,
    label,
    toggle,
    children,
}) => (
    <CollapsibleContainer
        row
        justify='space-between'
        alignItems='flex-start'
        label={
            !isCollapsed && (
                <Label size={14} color={Color.White} marginTop={8} marginRight={8}>
                    {label}
                </Label>
            )
        }
        variant={CollapsibleButtonVariant.RightBottom}
        isCollapsed={isCollapsed}
        toggle={toggle}
        padding={8}
    >
        {children}
    </CollapsibleContainer>
)

const Label = styled(Text)`
    transform: rotate(-180deg);
    writing-mode: tb-rl;
`
