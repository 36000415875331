import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { TaskRecurrenceType } from './TaskRecurrenceType'

export interface TaskRecurrenceWeekly {
    type: TaskRecurrenceType.Weekly
    duration: number
    every: number
    weekDays: TaskRecurrenceWeekDay[]
}

export enum TaskRecurrenceWeekDay {
    Monday = 'MONDAY',
    Tuesday = 'TUESDAY',
    Wednesday = 'WEDNESDAY',
    Thursday = 'THURSDAY',
    Friday = 'FRIDAY',
    Saturday = 'SATURDAY',
    Sunday = 'SUNDAY',
}

export const allWeekDays: TaskRecurrenceWeekDay[] = EnumValues.getValues(TaskRecurrenceWeekDay)
export const translateTaskDay = translateEnum<TaskRecurrenceWeekDay>(
    TaskRecurrenceWeekDay,
    'tasks.day',
)
