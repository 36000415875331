import { createSelector } from 'reselect'

import { StatusDashboardDataStateAware } from '../state'

export const runwayCombinationSelector = createSelector(
    (state: StatusDashboardDataStateAware) => state.statusDashboardData.runwayState,
    (state: StatusDashboardDataStateAware) => state.statusDashboardData.metarState.message,
    (runwaysState, metar) => ({
        runwaysState,
        metar,
    }),
)
