export function snapshotToValue<T>(ds: firebase.database.DataSnapshot): [string, T] | null {
    if (ds.key) {
        return [ds.key, ds.val() as T]
    }
    return null
}

export function snapshotToValueList<T>(snapshot: firebase.database.DataSnapshot): [string, T][] {
    const items: [string, T][] = []
    snapshot.forEach(n => {
        const item = snapshotToValue<T>(n)
        if (item !== null) {
            items.push(item)
        }
        return false
    })
    return items
}

export function loadFirebaseValue<T>(
    ref: firebase.database.Query,
    converter: f.Func1<firebase.database.DataSnapshot, T>,
): Promise<T> {
    return new Promise((resolve, reject) => {
        ref.once('value', t => {
            resolve(converter(t))
        }).catch(reject)
    })
}
