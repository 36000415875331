import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { StandsGatesTimelineGroup } from '../../../core/standsGatesTimeline/types/StandsGatesTimelineGroup'

interface StandsGatesGroupRendererProps {
    group: StandsGatesTimelineGroup
    toggleGroup(id: StandsGatesTimelineGroup): void
}

export const StandsGatesGroupRenderer: FC<StandsGatesGroupRendererProps> = ({
    group,
    toggleGroup,
}) => {
    const renderRootGroup = () => (
        <CollapsibleContainer
            row
            justify='flex-start'
            alignItems='flex-start'
            label={
                <Text size={14} color={Color.White} marginTop={4} marginRight={8}>
                    {group.title}
                </Text>
            }
            variant={CollapsibleButtonVariant.RightBottom}
            isCollapsed={group.isCollapsed}
            toggle={() => toggleGroup(group)}
            padding={8}
        />
    )
    const renderSubgroup = () => (
        <Box fullSize alignItems='center' justify='flex-end' paddingRight={12}>
            <Text>{group.title}</Text>
        </Box>
    )

    return <Wrapper fullSize>{group.isRoot ? renderRootGroup() : renderSubgroup()}</Wrapper>
}

const Wrapper = styled(Box)`
    line-height: 1rem;
    display: flex;
    align-items: center;
`
