import { SyncWrapper } from 'aos-frontend/src/app/components/sync/SyncWrapper'
import {
    restrictionsSyncAction,
    restrictionsSyncStopAction,
} from 'aos-services/src/core/restrictions/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

interface RestrictionsSyncWrapperProps {
    id: SyncDataKey
}

export const RestrictionsSyncWrapper: FC<PropsWithChildren<RestrictionsSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={restrictionsSyncAction(id)} onLeave={restrictionsSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
