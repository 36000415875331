import {
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { checklistTemplatesService } from 'aos-services/src/services/checklists/checklistTemplateService'
import { taskChecklistTemplateService } from 'aos-services/src/services/checklists/taskChecklistTemplateService'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { AosChecklistItemType } from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import { AosChecklistTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { ChecklistTemplateItemEditPayload } from 'aos-services/src/services/checklists/types/input/ChecklistTemplateItemEditPayload'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES,
    ApplyChecklistTemplateSeqChangesAction,
    CREATE_TASK_OR_DESCRIPTION,
    EDIT_MESSAGE_TEMPLATE_PARENT,
    EDIT_TASK_OR_DESCRIPTION_PARENT,
    EXPORT_ALL_CHECKLIST_TEMPLATES,
    EXPORT_SINGLE_CHECKLIST_TEMPLATE,
    ExportSingleChecklistTemplateAction,
} from '../actions'
import { MessageTemplateState } from '../editMessageTemplate/state'
import { ChecklistManagerStateAware } from '../state'
import { loadTaskChecklistTemplateAction } from '../task/actions'
import {
    CREATE_CHECKLIST_TEMPLATE,
    CreateChecklistTemplateAction,
    DELETE_CHECKLIST_TEMPLATE,
    DeleteChecklistTemplateAction,
    LOAD_CHECKLIST_TEMPLATE,
    LOAD_CHECKLIST_TEMPLATES,
    LoadChecklistTemplateAction,
    loadChecklistTemplateAction,
    LoadChecklistTemplatesAction,
    loadChecklistTemplatesAction,
    loadChecklistTemplatesSuccessAction,
    loadChecklistTemplateSuccessAction,
    SELECT_CHECKLIST_TEMPLATE,
    SelectChecklistTemplateAction,
    setExportingChecklistTemplatesAction,
    UPDATE_CHECKLIST_TEMPLATE,
    UpdateChecklistTemplateAction,
} from './actions'

function* loadChecklistsSaga() {
    yield takeEvery<LoadChecklistTemplatesAction>(LOAD_CHECKLIST_TEMPLATES, function* () {
        try {
            const checklistTemplates: AosChecklistTemplate[] = yield call(
                checklistTemplatesService.getAll,
            )
            yield put(loadChecklistTemplatesSuccessAction(checklistTemplates))
        } catch (e) {
            loadChecklistTemplatesSuccessAction([])
            throw e
        }
    })
}

function* addChecklistSaga() {
    yield takeEvery<CreateChecklistTemplateAction>(CREATE_CHECKLIST_TEMPLATE, function* (action) {
        yield call(checklistTemplatesService.create, action.payload)
        yield put(loadChecklistTemplatesAction())
        successToast(translate('create-checklist.checklist-created'))
    })
}

function* deleteChecklistSaga() {
    yield takeEvery<DeleteChecklistTemplateAction>(
        DELETE_CHECKLIST_TEMPLATE,
        function* (action: DeleteChecklistTemplateAction) {
            yield call(checklistTemplatesService.delete, action.payload)
            yield put(loadChecklistTemplatesAction())
            successToast(translate('checklist.deleted'))
        },
    )
}

function* updateChecklistSaga() {
    yield takeEvery<UpdateChecklistTemplateAction>(UPDATE_CHECKLIST_TEMPLATE, function* (action) {
        yield call(checklistTemplatesService.update, action.payload)
        yield put(loadChecklistTemplatesAction())
        successToast(translate('checklist.updated'))
    })
}

function* selectChecklistTemplateSaga() {
    function* selectChecklistTemplate(action: SelectChecklistTemplateAction) {
        const unitId = action.payload.id
        yield put(loadChecklistTemplateAction(unitId))
    }

    yield takeLatest<SelectChecklistTemplateAction>(
        SELECT_CHECKLIST_TEMPLATE,
        selectChecklistTemplate,
    )
}

function* loadChecklistSaga() {
    function* loadChecklist(action: LoadChecklistTemplateAction) {
        const result: AosChecklistTemplate = yield call(
            checklistTemplatesService.getOne,
            action.payload,
        )
        yield put(loadChecklistTemplateSuccessAction(result))
    }

    yield takeLatest<LoadChecklistTemplateAction>(LOAD_CHECKLIST_TEMPLATE, loadChecklist)
}

function* syncChecklistTemplatesSaga() {
    function* syncChecklistTemplates() {
        const checklistTemplates: AosChecklistTemplate[] = yield select(
            (state: ChecklistManagerStateAware) =>
                state.checklistManager.eventChecklistManager.checklistTemplates,
        )
        yield call(checklistTemplatesService.reorder, checklistTemplates)
    }

    yield takeLatest<ApplyChecklistTemplateSeqChangesAction>(
        APPLY_CHECKLIST_TEMPLATE_SEQ_CHANGES,
        syncChecklistTemplates,
    )
}

function* createTaskOrDescriptionSaga() {
    function* createTaskOrDescription(action: SubmitAction<AosChecklistItem, number>) {
        const checklistTemplateId = action.payload.payload!
        const type: AosChecklistFilter = yield select(
            (state: ChecklistManagerStateAware) => state.checklistManager.headerFilter,
        )
        if (type === AosChecklistFilter.EventChecklist) {
            yield call(checklistTemplatesService.createItem, {
                checklistTemplateId,
                item: action.payload.value!,
            })
            yield put(loadChecklistTemplateAction(checklistTemplateId))
        } else if (type === AosChecklistFilter.TaskChecklist) {
            yield call(taskChecklistTemplateService.createItem, {
                checklistTemplateId,
                item: action.payload.value!,
            })
            yield put(loadTaskChecklistTemplateAction(checklistTemplateId))
        }

        successToast(translate('checklist.checklist-item-created'))
    }

    yield takeEveryChildAction<SubmitAction>(
        CREATE_TASK_OR_DESCRIPTION,
        SUBMIT,
        createTaskOrDescription,
    )
}

function* editTaskOrDescriptionSaga() {
    function* editTaskOrDescription(
        action: SubmitAction<string, ChecklistTemplateItemEditPayload>,
    ) {
        const payload = action.payload.payload!
        const type: AosChecklistFilter = yield select(
            (state: ChecklistManagerStateAware) => state.checklistManager.headerFilter,
        )

        if (type === AosChecklistFilter.EventChecklist) {
            yield call(checklistTemplatesService.editTaskOrDescription, {
                checklistTemplateId: payload.checklistTemplateId,
                item: {
                    id: payload.item?.id,
                    type: payload.item?.type,
                    content: action.payload.value!,
                },
            })
            yield put(loadChecklistTemplateAction(payload.checklistTemplateId))
        } else if (type === AosChecklistFilter.TaskChecklist) {
            yield call(taskChecklistTemplateService.editTaskOrDescription, {
                checklistTemplateId: payload.checklistTemplateId,
                item: {
                    id: payload.item?.id,
                    type: payload.item?.type,
                    content: action.payload.value!,
                },
            })
            yield put(loadTaskChecklistTemplateAction(payload.checklistTemplateId))
        }
        successToast(translate('checklist.checklist-item-updated'))
    }

    yield takeEveryChildAction<SubmitAction>(
        EDIT_TASK_OR_DESCRIPTION_PARENT,
        SUBMIT,
        editTaskOrDescription,
    )
}

function* editMessageTemplateSaga() {
    yield takeEveryChildAction<SubmitAction>(
        EDIT_MESSAGE_TEMPLATE_PARENT,
        SUBMIT,
        function* (action: SubmitAction<MessageTemplateState, ChecklistTemplateItemEditPayload>) {
            const payload = action.payload.payload!
            yield call(checklistTemplatesService.editMessageTemplate, {
                checklistTemplateId: payload.checklistTemplateId,
                item: {
                    content: '',
                    ...action.payload.value,
                    id: payload.item ? payload.item.id : 0,
                    type: AosChecklistItemType.MessageTemplate,
                },
            })
            yield put(loadChecklistTemplateAction(payload.checklistTemplateId))
            successToast(
                translate(
                    payload.item?.id
                        ? 'checklist.checklist-item-updated'
                        : 'checklist.checklist-item-created',
                ),
            )
        },
    )
}

function* exportAllChecklistsSaga() {
    yield takeEvery(EXPORT_ALL_CHECKLIST_TEMPLATES, function* () {
        yield put(setExportingChecklistTemplatesAction(true))
        yield call(checklistTemplatesService.exportAll)
        yield put(setExportingChecklistTemplatesAction(false))
    })
}

function* exportSingleChecklistSaga() {
    yield takeEvery<ExportSingleChecklistTemplateAction>(
        EXPORT_SINGLE_CHECKLIST_TEMPLATE,
        function* (action: ExportSingleChecklistTemplateAction) {
            yield put(setExportingChecklistTemplatesAction(true))
            yield call(checklistTemplatesService.exportSingle, action.payload)
            yield put(setExportingChecklistTemplatesAction(false))
        },
    )
}

export const eventChecklistSagas = [
    loadChecklistsSaga,
    addChecklistSaga,
    deleteChecklistSaga,
    updateChecklistSaga,
    selectChecklistTemplateSaga,
    loadChecklistSaga,
    syncChecklistTemplatesSaga,
    createTaskOrDescriptionSaga,
    editTaskOrDescriptionSaga,
    editMessageTemplateSaga,
    exportAllChecklistsSaga,
    exportSingleChecklistSaga,
]
