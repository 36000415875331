export enum DashboardItemWithHealthChecks {
    Pax = 'PAX',
    PaxForecast = 'PAX_FORECAST',
    Cdm = 'CDM',
    Weather = 'WEATHER',
    Punctuality = 'PUNCTUALITY',
    Delays = 'DELAYS',
    Flights = 'FLIGHTS',
    Runways = 'RUNWAYS',
    BaggageDelivery = 'BAGGAGE_DELIVERY',
    Regularity = 'REGULARITY',
    Notam = 'NOTAM',
}
