import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastChartData } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { PaxForecastArrivalDepartureFilter } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import {
    PaxForecastTimeRange,
    translatePaxForecastTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastTimeRange'
import React, { FC } from 'react'

import { ChartTitle } from '../../../partials/ChartTitle'
import { ChartItemContainer } from '../../base/ItemContainer'
import { PaxForecastChart } from './PaxForecastChart'

interface PaxForecastDaysChartSectionProps extends ClassNameProps {
    data: PaxForecastChartData
    timeRangeFilter: PaxForecastTimeRange
    customDateFilter: DateTime
    arrivalDepartureFilter: PaxForecastArrivalDepartureFilter
}

export const PaxForecastChartSection: FC<PaxForecastDaysChartSectionProps> = props => {
    const forecastRange =
        props.timeRangeFilter !== PaxForecastTimeRange.Custom
            ? translatePaxForecastTimeRange(props.timeRangeFilter)
            : formatDate(props.customDateFilter)

    return (
        <ChartItemContainer vertical>
            <ChartTitle withLeftColumn>
                {translate('dashboard.pax-forecast.chart.title', {
                    time: forecastRange,
                })}
            </ChartTitle>
            <PaxForecastChart
                data={props.data}
                arrivalDepartureFilter={props.arrivalDepartureFilter}
                className='flex--auto'
            />
        </ChartItemContainer>
    )
}
