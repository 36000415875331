import { createSelector } from 'reselect'

import { defaultItemStatuses } from '../../services/statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from '../../services/statusDashboard/types/DashboardItemType'
import { widgetHealthsService } from '../../services/widgetHealths/widgetHealthsService'
import { isAirportNetworkSelector } from '../auth/state'
import { currentTimeSelector } from '../common/selectors'
import { BaseStatusDashboardStateAware } from './state'

export const dashboardStatusesSelector = createSelector(
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.statuses,
    statuses => ({ ...defaultItemStatuses, ...statuses }),
)

export const dashboardHealthsSelector = createSelector(
    currentTimeSelector,
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.healths,
    (state: BaseStatusDashboardStateAware) => state.statusDashboard.healthsForAirport,
    (time, healths, healthsForAirport) =>
        widgetHealthsService.getHealths(healths, healthsForAirport, time),
)

export const availableItems = [
    DashboardItemType.Flights,
    DashboardItemType.Regularity,
    DashboardItemType.Weather,
    DashboardItemType.Events,
    DashboardItemType.Runways,
    DashboardItemType.NewsFeed,
    DashboardItemType.Pax,
    DashboardItemType.PaxForecast,
    DashboardItemType.Delays,
    DashboardItemType.Punctuality,
    DashboardItemType.Cdm,
    DashboardItemType.BaggageDelivery,
    DashboardItemType.OperationalForecast,
    DashboardItemType.WeatherForecast,
    DashboardItemType.QueueingTimeSecurity,
    DashboardItemType.Restrictions,
    DashboardItemType.FireFighters,
    DashboardItemType.ShiftNotes,
    DashboardItemType.RecentTasks,
    DashboardItemType.UpcomingTasks,
    DashboardItemType.FOD,
    DashboardItemType.NOTAM,
]

export const availableItemsForNetwork = [
    DashboardItemType.Events,
    DashboardItemType.Flights,
    DashboardItemType.Regularity,
    DashboardItemType.Weather,
    DashboardItemType.NewsFeed,
    DashboardItemType.Delays,
    DashboardItemType.Punctuality,
    DashboardItemType.OperationalForecast,
    DashboardItemType.FireFighters,
    DashboardItemType.ShiftNotes,
    DashboardItemType.RecentTasks,
    DashboardItemType.UpcomingTasks,
    DashboardItemType.FOD,
    DashboardItemType.NOTAM,
]

export const availableItemTypesSelector = createSelector(
    isAirportNetworkSelector,
    isAirportNetwork => {
        if (isAirportNetwork) {
            return availableItemsForNetwork
        }
        return availableItems
    },
)
