import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { WaitForIt } from 'aos-ui/src/components/ui/WaitForIt'
import React, { FC } from 'react'

import { StatusDashboardItemWarning } from './StatusDashboardItemWarning'

interface StatusDashboardItemNoDataWarningProps {
    lastChanged?: DateTime
}

export const StatusDashboardItemNoDataWarning: FC<StatusDashboardItemNoDataWarningProps> = props => {
    const { lastChanged } = props
    return (
        <WaitForIt ms={5000}>
            <StatusDashboardItemWarning title={translate('dashboard.no-up-to-date-data-title')}>
                {lastChanged
                    ? translate('dashboard.no-up-to-date-data', {
                          date: formatDate(lastChanged),
                          time: formatTime(lastChanged),
                      })
                    : translate('dashboard.no-data')}
            </StatusDashboardItemWarning>
        </WaitForIt>
    )
}
