import { Notam, Snowtam } from '../../services/flightInformation/types/Notam'
import { BaseRestService } from '../base/BaseRestService'
import { FlightDto, FlightsResponseDto } from './types/FlightDto'

class FlightsRestService extends BaseRestService {
    public async getAllFlights(): Promise<FlightDto[]> {
        const { flights } = await this.getAndTransform<FlightsResponseDto>(
            '/airport-status/flights',
            BaseRestService.jsonTransformer,
        )
        return flights
    }

    public async getAllNotam(): Promise<Notam[]> {
        return await this.getAndTransform<Notam[]>(
            '/airport-status/notam',
            BaseRestService.jsonTransformer,
        )
    }

    public async getAllSnowtam(): Promise<Snowtam[]> {
        return await this.getAndTransform<Snowtam[]>(
            '/airport-status/snowtam',
            BaseRestService.jsonTransformer,
        )
    }
}

export const flightsRestService = new FlightsRestService()
