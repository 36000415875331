import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PrivateChannelParticipantInputPayload } from 'aos-services/src/services/firebaseEvents/input/PrivateChannelParticipantInput'
import {
    EtaStatus,
    getPrivateChannelState,
    PrivateChannelState,
} from 'aos-services/src/services/firebaseEvents/privateChannelState'
import {
    InvitationRole,
    InvitationStatus,
} from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import {
    countRespondedInvitations,
    SplittedParticipants,
} from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { InviteEta } from './InviteEta'
import { PrivateChannelInvitation } from './PrivateChannelnvitation'
import { PrivateChannelParticipants } from './PrivateChannelParticipants'
import { PrivateChannelStatusSelection } from './PrivateChannelStatusSelection'

export const PrivateChannel: React.FC<PrivateChannelProps> = React.memo(
    ({
        participants,
        privateChannelAdded,
        updateInvitation,
        showIWillArrive,
        invitedGroup,
        isCommander,
        etaStatus,
        setEtaStatus,
        atcAlert,
    }) => {
        const me = participants?.[0]
        const history = useHistory()

        const renderHeader = useCallback(
            (privateChannelState: PrivateChannelState, participants?: SplittedParticipants) => {
                const [accepted, all] = countRespondedInvitations(participants ?? [undefined, []])
                const titleNote =
                    privateChannelState === PrivateChannelState.PrivateChannelAdded
                        ? `${accepted}/${all}`
                        : undefined
                return (
                    <HeaderContainer
                        title={translate('private-channel.header.title')}
                        seleniumLocation='private-channel.header.title'
                        titleCount={titleNote}
                    />
                )
            },
            [],
        )

        const privateChannelState = getPrivateChannelState(me, etaStatus, privateChannelAdded)
        const header = renderHeader(privateChannelState, participants)

        useEffect(() => {
            if (me) {
                setEtaStatus(!!me.arrivalTime ? EtaStatus.Specified : EtaStatus.NotSpecified)
            }
        }, [JSON.stringify(me?.arrivalTime)])

        return (
            <PanelWithHeader header={header} seleniumLocation='private-channel'>
                <Box padding={30}>
                    {privateChannelState === PrivateChannelState.Invitation && (
                        <PrivateChannelInvitation
                            isCommander={isCommander}
                            selectAction={action => updateInvitation({ invitationRole: action })}
                            atcAlert={atcAlert}
                        />
                    )}
                    {privateChannelState === PrivateChannelState.PrivateChannelStatus && (
                        <PrivateChannelStatusSelection
                            isCommander={me?.invitationRole === InvitationRole.Command}
                            selectAction={action => updateInvitation({ invitationStatus: action })}
                            atcAlert={atcAlert}
                            onBack={() => {
                                if (etaStatus === EtaStatus.SpecifyAgain) {
                                    setEtaStatus(EtaStatus.Specified)
                                } else {
                                    updateInvitation({ invitationRole: InvitationRole.Left })
                                }
                            }}
                        />
                    )}

                    {privateChannelState === PrivateChannelState.PrivateChannelAdded && (
                        <PrivateChannelParticipants
                            me={participants?.[0]!}
                            participants={participants?.[1] ?? []}
                            changeStatus={() => {
                                updateInvitation({
                                    invitationRole: InvitationRole.Pending,
                                    invitationStatus: InvitationStatus.NotSelected,
                                    arrivalTime: 'null',
                                })
                                setEtaStatus(EtaStatus.NotSpecified)
                            }}
                            leaveEvent={() => {
                                updateInvitation({
                                    invitationRole: InvitationRole.Left,
                                    invitationStatus: InvitationStatus.NotSelected,
                                    arrivalTime: 'null',
                                })
                                history.push('/status-dashboard/overview')
                            }}
                            arriveAt={() => {
                                updateInvitation({ invitationStatus: InvitationStatus.Coming })
                                showIWillArrive()
                            }}
                            invitedGroup={invitedGroup}
                        />
                    )}
                    {privateChannelState === PrivateChannelState.PrivateChannelEtaRequired && (
                        <InviteEta
                            me={participants![0]!}
                            onSubmit={date => {
                                setEtaStatus(EtaStatus.Specified)
                                updateInvitation({ arrivalTime: date })
                            }}
                            onBack={() => {
                                if (etaStatus === EtaStatus.SpecifyAgain) {
                                    setEtaStatus(EtaStatus.Specified)
                                } else {
                                    updateInvitation({
                                        invitationStatus: InvitationStatus.NotSelected,
                                        arrivalTime: 'null',
                                    })
                                }
                            }}
                            status={participants?.[0]?.invitationRole}
                        />
                    )}
                </Box>
            </PanelWithHeader>
        )
    },
)

export interface PrivateChannelProps {
    participants?: SplittedParticipants
    privateChannelAdded: boolean
    editable: boolean
    invitedGroup?: AosUserGroup
    updateInvitation(v: PrivateChannelParticipantInputPayload): void
    showIWillArrive(): void
    isCommander: boolean
    etaStatus: EtaStatus
    setEtaStatus: (v: EtaStatus) => void
    atcAlert: boolean | undefined
}
