import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

import { TaskType } from '../TaskType'
import { BaseTask } from './BaseTask'

export enum AnimalsTaskType {
    Observation = 'OBSERVATION',
    Confirmation = 'CONFIRMATION',
    Banishment = 'BANISHMENT',
    Kill = 'KILL',
}

export enum AnimalGender {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
}

export enum AnimalAge {
    CALF = 'CALF',
    ADULT = 'ADULT',
}

export const allAnimalAges = EnumValues.getValues<AnimalAge>(AnimalAge)
export const allAnimalGenders = EnumValues.getValues<AnimalGender>(AnimalGender)
export const allAnimalsTaskTypes = EnumValues.getValues<AnimalsTaskType>(AnimalsTaskType)

export const translateAnimalsTaskType = translateEnum(AnimalsTaskType, 'animals-task-type')
export const translateAnimalGender = translateEnum(AnimalGender, 'animals-gender')
export const translateAnimalAge = translateEnum(AnimalAge, 'animals-age')

export enum AnimalType {
    Bird = 'BIRD',
    Mammal = 'MAMMAL',
    Other = 'OTHER',
}

export const allAnimalTypes = EnumValues.getValues<AnimalType>(AnimalType)
export const translateAnimalType = translateEnum(AnimalType, 'animal-type')

export interface AnimalSpecie {
    id: number
    animalType: AnimalType
    label: string
    horns: boolean
}

export interface AnimalsTask extends BaseTask {
    type: TaskType.AnimalPrevention
    animalTaskType: AnimalsTaskType
    animalSpecies: AnimalSpecie
    amount: number
    cartridges: number
    expulsions: number
    gender: AnimalGender
    lifeStage: AnimalAge
    horns: boolean
    amountOfSpikes: number
}
