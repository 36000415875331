import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

export const GaugeText: FCWithChildren<GaugeTextProps> = props => {
    const fontSize = props.size / 5
    const noteFontSize = fontSize / 3
    const renderUnit = () => (
        <Text
            color={Color.Grey}
            size={13}
            textAlign='center'
            paddingTop={8}
            lineHeight='large'
            style={{ fontSize: noteFontSize }}
        >
            {props.unit}
        </Text>
    )

    return (
        <Box column centered fullHeight>
            {props.unit && renderUnit()}
            <Text relative textAlign='center' weight='light' style={{ fontSize }}>
                {props.children}
            </Text>
        </Box>
    )
}

interface GaugeTextProps extends MarginBoxProps {
    unit?: string
    size: number
}
