import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { FirebaseActionPayload } from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'
import { AosNotification } from 'aos-services/src/services/notifications/types/Notification'

export const NOTIFICATIONS_SYNC_SUCCESS = 'NOTIFICATIONS/NOTIFICATION_SYNC_SUCCESS'
export type NotificationsSyncSuccessAction = PayloadAction<
    typeof NOTIFICATIONS_SYNC_SUCCESS,
    FirebaseActionPayload<AosNotification>
>
export const notificationsSyncSuccessAction = payloadActionCreator<
    NotificationsSyncSuccessAction,
    FirebaseActionPayload<AosNotification>
>(NOTIFICATIONS_SYNC_SUCCESS)

export const SET_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/SET_NOTIFICATIONS_SUCCESS'
export type SetNotificationsSuccessAction = PayloadAction<
    typeof SET_NOTIFICATIONS_SUCCESS,
    AosNotification[]
>
export const setNotificationsSuccessAction = payloadActionCreator<
    SetNotificationsSuccessAction,
    AosNotification[]
>(SET_NOTIFICATIONS_SUCCESS)

export const SYNC_NOTIFICATIONS = 'NOTIFICATIONS/SYNC_NOTIFICATIONS'
export type SyncNotificationsAction = Action<typeof SYNC_NOTIFICATIONS>
export const syncNotificationsAction = emptyActionCreator<SyncNotificationsAction>(
    SYNC_NOTIFICATIONS,
)

export const OPEN_NOTIFICATIONS_CONTAINER = 'NOTIFICATIONS/OPEN_NOTIFICATIONS_CONTAINER'
export type OpenNotificationsContainerAction = Action<typeof OPEN_NOTIFICATIONS_CONTAINER>
export const openNotificationsContainerAction = emptyActionCreator<OpenNotificationsContainerAction>(
    OPEN_NOTIFICATIONS_CONTAINER,
)

export const CLOSE_NOTIFICATIONS_CONTAINER = 'NOTIFICATIONS/CLOSE_NOTIFICATIONS_CONTAINER'
export type CloseNotificationsContainerAction = Action<typeof CLOSE_NOTIFICATIONS_CONTAINER>
export const closeNotificationsContainerAction = emptyActionCreator<CloseNotificationsContainerAction>(
    CLOSE_NOTIFICATIONS_CONTAINER,
)

export const PIN_NOTIFICATIONS_CONTAINER = 'NOTIFICATIONS/PIN_NOTIFICATIONS_CONTAINER'
export type PinNotificationsContainerAction = PayloadAction<
    typeof PIN_NOTIFICATIONS_CONTAINER,
    boolean
>
export const pinNotificationsContainerAction = payloadActionCreator<
    PinNotificationsContainerAction,
    boolean
>(PIN_NOTIFICATIONS_CONTAINER)

export const MARK_AS_READ = 'NOTIFICATIONS/MARK_AS_READ'
export type MarkAsReadAction = PayloadAction<typeof MARK_AS_READ, string[]>
export const markAsReadAction = payloadActionCreator<MarkAsReadAction, string[]>(MARK_AS_READ)

export const LOAD_MORE = 'NOTIFICATIONS/LOAD_MORE'
export type LoadMoreAction = Action<typeof LOAD_MORE>
export const loadMoreAction = emptyActionCreator<LoadMoreAction>(LOAD_MORE)

export const LOAD_MORE_SUCCESS = 'NOTIFICATIONS/LOAD_MORE_SUCCESS'
export type LoadMoreSuccessAction = PayloadAction<typeof LOAD_MORE_SUCCESS, AosNotification[]>
export const loadMoreSuccessAction = payloadActionCreator<LoadMoreSuccessAction, AosNotification[]>(
    LOAD_MORE_SUCCESS,
)

export type NotificationsAction =
    | SyncNotificationsAction
    | NotificationsSyncSuccessAction
    | OpenNotificationsContainerAction
    | CloseNotificationsContainerAction
    | PinNotificationsContainerAction
    | SetNotificationsSuccessAction
    | MarkAsReadAction
    | LoadMoreAction
    | LoadMoreSuccessAction
