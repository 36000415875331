import React, { FCWithChildren } from 'react'

import { NumberValues } from '../../../base/Box'
import { DropdownItemWrapperHoverable, DropdownLabel, dropdownSizes } from './DropdownContent'
import { DropdownHeight } from './DropdownHeight'
import { DropdownVariant } from './DropdownVariant'

interface DropdownItemHoverableProps {
    variant: DropdownVariant
    height: DropdownHeight
    padding: NumberValues
    isSelected: boolean
    onClick(e: React.MouseEvent<any>): void

    isHovered?: boolean
    onMouseEnter?(): void
    onMouseLeave?(): void
}

export const DropdownItemHoverable: FCWithChildren<DropdownItemHoverableProps> = ({
    isSelected,
    isHovered,
    onMouseEnter,
    onMouseLeave,
    children,
    variant,
    height,
    padding,
    onClick,
}) => {
    return (
        <DropdownItemWrapperHoverable
            row
            onClick={onClick}
            variant={variant}
            isSelected={isSelected}
            paddingHorizontal={padding}
            itemHeight={dropdownSizes[height].itemHeight}
            isHovered={isHovered}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <DropdownLabel size={12} as='span' variant={variant} isSelected={isSelected}>
                {children}
            </DropdownLabel>
        </DropdownItemWrapperHoverable>
    )
}
