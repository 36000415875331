import { AosAdUserDto } from '../../../dataaccess/users/types/AosUserDto'
import { AosAirport } from '../../flightInformation/types/AosAirport'
import { AosAdUser } from './AosUser'
import { AosUserBaseImpl } from './AosUserBaseImpl'
import { AosUserLocalisation } from './AosUserLocalisation'
import { AosUserNotificationsPreferences } from './AosUserNotificationsPreferences'

export class AosAdUserImpl extends AosUserBaseImpl<AosAdUserDto> implements AosAdUser {
    constructor(value: AosAdUserDto) {
        super(value)
    }

    public get thumbnail() {
        return this.value.thumbnail ? `data:image/png;base64,${this.value.thumbnail}` : undefined
    }

    public get unit() {
        return this.value.unit
    }

    public get alternativePhone() {
        return this.value.alternativePhoneNumber
    }

    public get notificationPreferences() {
        return this.value.notificationPreferences as AosUserNotificationsPreferences
    }

    public get siteLocation() {
        return this.value.siteLocation as AosAirport
    }

    public get localisation() {
        return this.value.localisation as AosUserLocalisation
    }

    public get currentPhone() {
        return this.value.alternativePhoneNumber || this.value.phoneNumber
    }

    public get isFinaviaAuthority() {
        return this.value.isFinaviaAuthority
    }

    public get isAdditionalFinaviaAuthority() {
        return this.value.isAdditionalFinaviaAuthority
    }

    public get dto() {
        return this.value
    }
}
