import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { PageSizeDropdown } from 'aos-ui/src/components/form/dropdown/PageSizeDropdown'
import { kebabCase } from 'lodash'
import React from 'react'

import { DropdownWidth } from './base/DropdownWidth'
import { Dropdown } from './Dropdown'

interface PageSizeAndSortDropdownProps<T extends string> {
    pageSize: number
    keyPrefix: string
    sortEntries: T[]
    sort: T

    onChangeSize(v: number): void

    onChangeSort(v: T): void
}

export const PageSizeAndSortDropdown = <T extends string>(
    props: PageSizeAndSortDropdownProps<T>,
) => {
    const { pageSize, keyPrefix, sortEntries, onChangeSize, onChangeSort, sort } = props
    const formatSortEntry = (key: T) => translate(`${keyPrefix}.${kebabCase(key)}`)
    return (
        <Box row>
            <Dropdown
                value={sort}
                items={sortEntries}
                valueRenderer={formatSortEntry}
                onChange={onChangeSort}
                width={DropdownWidth.Std}
                small
            />
            <Box marginLeft={20}>
                <PageSizeDropdown value={pageSize} onChange={onChangeSize} />
            </Box>
        </Box>
    )
}
