import {
    translate,
    translateFirst,
    translateParts,
} from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'
import { kebabCase } from 'lodash'

import { WeatherUnit } from '../../../statusDashboard/types/filters/WeatherUnit'

export enum WeatherValueSeriesType {
    LvpProbability = 'LVPPROB',
    ThunderstormProbability = 'TSPROB',
    Rain = 'RAIN',
    Snow = 'SNOW',
    MaxWindGust = 'WG',
    WindDirection = 'WD',
    WindSpeed = 'WS',
    CumulonimbusCloudsProbability = 'CBPROB',
    CumulonimbusCloudsBase = 'CBBASE',
    Temperature = 'T',
    DewPoint = 'TD',
    RainAndSnow = 'RAINSNOW',
    PresentWeather = 'WAWA',
}

export enum WeatherOtherSeriesType {
    WeatherCategory = 'WEATHERCATEGORY',
    FreezingWeatherPhenomenon = 'FREEZINGWEATHERPHENOMENON',
    TemporaryFreezingWeatherPhenomenon = 'TEMPORARYFREEZINGWEATHERPHENOMENON',
}

export type WeatherSeriesType = WeatherOtherSeriesType | WeatherValueSeriesType

export const translateIconWeatherSeriesType = (type: WeatherSeriesType) =>
    translateParts('dashboard.weather.icon', kebabCase(type))

export const translateChartWeatherSeriesType = (
    type: WeatherSeriesType,
    value: number,
    unitTab?: WeatherUnit,
) => {
    const label = `dashboard.weather.chart.${kebabCase(type)}`
    const payload = { value }

    if (unitTab !== undefined) {
        const unit = kebabCase(EnumValues.getNameFromValue(WeatherUnit, unitTab)!)
        return translateFirst([`${label}.${unit}`, label], payload)
    }

    return translate(label, payload)
}
