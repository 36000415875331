import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { BimLayer } from 'aos-ui-map/src/components/map/bim/BimLayer'

export const LOAD_BIM_LAYERS_DATA = 'LAYERS_DATA/LOAD_BIM_LAYERS_DATA'
export type LoadBimLayersDataAction = Action<typeof LOAD_BIM_LAYERS_DATA>
export const loadBimLayersDataAction =
    emptyActionCreator<LoadBimLayersDataAction>(LOAD_BIM_LAYERS_DATA)

export const LOAD_BIM_LAYERS_DATA_SUCCESS = 'LAYERS_DATA/LOAD_BIM_LAYERS_DATA_SUCCESS'
export type LoadBimLayersDataSuccessAction = PayloadAction<
    typeof LOAD_BIM_LAYERS_DATA_SUCCESS,
    BimLayer[]
>
export const loadBimLayersDataSuccessAction = payloadActionCreator<
    LoadBimLayersDataSuccessAction,
    BimLayer[]
>(LOAD_BIM_LAYERS_DATA_SUCCESS)

export type BimLayersDataAction = LoadBimLayersDataAction | LoadBimLayersDataSuccessAction
