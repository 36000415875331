import {
    calculateChartDomainForRange,
    calculateDomain,
    Domain,
} from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime, startOfHour } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, TimeRange, timeRangeFromNow } from 'aos-helpers/src/helpers/TimeRange'
import { createSelector } from 'reselect'

import { filterToTimeRange } from '../../../../services/airportStatus/base/types/TimeRangeFilter'
import {
    filterCollection,
    flightsForType,
} from '../../../../services/airportStatus/flights/types/FlightCollection'
import { FlightCollectionAndPoints } from '../../../../services/airportStatus/flights/types/FlightCollectionAndPoints'
import {
    FlightCollectionPoint,
    toFlightStats,
} from '../../../../services/airportStatus/flights/types/FlightCollectionPoint'
import { FlightsType } from '../../../../services/airportStatus/flights/types/FlightsType'
import { FlightVolumePoint } from '../../../../services/airportStatus/flights/types/FlightVolumePoint'
import { FlightVolumesStats } from '../../../../services/airportStatus/flights/types/FlightVolumesStats'
import {
    Flight,
    getFlightCount,
    getFlightCountPerHour,
    isCompleted,
} from '../../../../services/flightInformation/types/Flight'
import { FilterOptionAll } from '../../../../services/statusDashboard/types/filters/common'
import {
    CommonFlightView,
    FlightsPredictionTimeRange,
    flightsTimeRangeToHoursCount,
} from '../../../../services/statusDashboard/types/filters/CommonFlightFilters'
import {
    ExtendedFlightsVolumeFilters,
    FlightsGrouping,
} from '../../../../services/statusDashboard/types/filters/FlightsVolumesFilters'
import { currentTimeSelector } from '../../../common/selectors'
import { flightsPredicate } from '../../../statusDashboard/selectors/flightCommon/common'
import { flightCollectionAndPointsSelector, flightsLoadedSelector, ItemStateAware } from './common'

export interface FlightsVolumesSelectorState {
    flights: Flight[]
    filters: ExtendedFlightsVolumeFilters
    volumes: FlightVolumePoint[]
    volumesForecast: FlightVolumePoint[]
    stats: FlightVolumesStats
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    noDataWarning: boolean
}

const flightVolumesFiltersOwnPropsSelector = createSelector(
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) =>
        ownProps.itemState.flightsStats,
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) =>
        ownProps.itemState.timeRange,
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) =>
        ownProps.itemState.flightType,
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) => ownProps.itemState.airline,
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) =>
        ownProps.itemState.handlingAgent,
    (_: any, ownProps: ItemStateAware<ExtendedFlightsVolumeFilters>) =>
        ownProps.itemState.fullViewTab,
    (flightsStats, timeRange, flightType, airline, handlingAgent, fullViewTab) => ({
        flightsStats,
        timeRange,
        flightType,
        airline,
        handlingAgent,
        fullViewTab,
    }),
)

const flightCollectionPointToVolumes = (
    p: FlightCollectionPoint,
    predicate: f.Func1<Flight, boolean>,
): FlightVolumePoint => {
    const collection = filterCollection(p.collection, predicate)
    return {
        time: p.time,
        all: collection.arrivals.length + collection.departures.length,
        departures: collection.departures.length,
        arrivals: collection.arrivals.length,
    }
}

const getVolumes = (
    points: FlightCollectionPoint[],
    range: TimeRange,
    predicate: f.Func1<Flight, boolean>,
) =>
    points
        .filter(p => isTimeWithin(p.time, range))
        .map(p => flightCollectionPointToVolumes(p, predicate))

export const flightsMerger = (
    collection: FlightCollectionAndPoints,
    filters: ExtendedFlightsVolumeFilters,
    now: DateTime,
    dataLoaded: boolean,
): FlightsVolumesSelectorState => {
    const range = timeRangeFromNow(now, -7, 16)
    const statTimeRange = filterToTimeRange(filters.timeRange, now)
    const volumes = getVolumes(
        collection.points,
        {
            timeStart: range.timeStart,
            timeEnd: startOfHour(now).add(1, 'hour'),
        },
        isCompleted,
    )

    const volumesForecast = getVolumes(
        collection.points,
        {
            timeStart: startOfHour(now),
            timeEnd: range.timeEnd,
        },
        () => true,
    )

    const applicableFlightsPredicate = (f: Flight) =>
        flightsPredicate(f, filters.handlingAgent, filters.airline)
    const fullApplicableFlightsPredicate = (f: Flight) =>
        applicableFlightsPredicate(f) && isTimeWithin(f.sdt, statTimeRange)
    const prefilteredFlights = filterCollection(collection.flights, applicableFlightsPredicate)
    const flights = flightsForType(
        prefilteredFlights,
        filters.flightType,
        fullApplicableFlightsPredicate,
    )

    const hourCount = flightsTimeRangeToHoursCount(filters.timeRange, now)
    const totalStats = toFlightStats(collection.flights, statTimeRange, _t => true, getFlightCount)
    const hourlyStats = toFlightStats(
        collection.flights,
        statTimeRange,
        _t => true,
        getFlightCountPerHour(hourCount),
    )
    const yDomain = calculateDomain([...volumes, ...volumesForecast], f => f.all, {
        count: 5,
        fixed: true,
    })

    const xDomain = calculateChartDomainForRange(range)

    const noDataWarning = !dataLoaded
    return {
        flights,
        filters,
        volumes,
        volumesForecast,
        stats: {
            [FlightsGrouping.Hourly]: hourlyStats,
            [FlightsGrouping.Total]: totalStats,
        },
        yDomain,
        xDomain,
        noDataWarning,
    }
}

export const flightsSelector = createSelector(
    flightCollectionAndPointsSelector,
    flightVolumesFiltersOwnPropsSelector,
    currentTimeSelector,
    flightsLoadedSelector,
    flightsMerger,
)

export const flightsTileSelector = createSelector(
    flightCollectionAndPointsSelector,
    () => ({
        flightsStats: FlightsGrouping.Total,
        timeRange: FlightsPredictionTimeRange.Today,
        flightType: FlightsType.All,
        airline: FilterOptionAll.All,
        handlingAgent: FilterOptionAll.All,
        fullViewTab: CommonFlightView.History,
    }),
    currentTimeSelector,
    flightsLoadedSelector,
    flightsMerger,
)
