import { Flight } from '../../../flightInformation/types/Flight'
import { filterCollection, FlightCollection } from './FlightCollection'
import { filterCollectionPoints, FlightCollectionPoint } from './FlightCollectionPoint'

export interface FlightCollectionAndPoints {
    flights: FlightCollection
    points: FlightCollectionPoint[]
}

export const filterCollectionAndPoints = (
    collectionAndPoints: FlightCollectionAndPoints,
    predicate: f.Func1<Flight, boolean>,
): FlightCollectionAndPoints => ({
    ...collectionAndPoints,
    flights: filterCollection(collectionAndPoints.flights, predicate),
    points: filterCollectionPoints(collectionAndPoints.points, predicate),
})
