import { optionalText } from 'aos-helpers/src/helpers/Text'
import { minutesToTaskDuration } from 'aos-helpers/src/helpers/Time'
import { formatFromDateTime, formatToDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { taskSeverityTitle } from 'aos-services/src/services/common/types/AosSeverity'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { translateTaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import { translateTaskProcessType } from 'aos-services/src/services/tasks/types/TaskProcessType'
import { translateTaskTitle } from 'aos-services/src/services/tasks/types/TaskStatus'
import { AttachmentReadonlySection } from 'aos-ui/src/components/attachment/AttachmentReadonlySection'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { ChipList } from 'aos-ui/src/components/chip/ChipList'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { ByAtText } from 'aos-ui/src/components/text/ByAtText'
import { Link } from 'aos-ui/src/components/text/Link'
import { TextWithLinks } from 'aos-ui/src/components/text/TextWithLinks'
import React, { FC } from 'react'

import { detailedTaskPropertyList } from '../taskPropertyList/DetailedTaskPropertyList'

interface TaskDetailsPropsListProps {
    task: Task
    openLocation(): void
}

export const TaskDetailsPropsList: FC<TaskDetailsPropsListProps> = ({ task, openLocation }) => {
    const atOrBy = task.createdAt || task.createdBy
    const modifiedAtOrBy = task.modifiedAt || task.modifiedBy

    const fromToText = task.startTime && (
        <Box>
            <Box>{formatFromDateTime(task.startTime)}</Box>
            {task.endTime && <Box>{formatToDateTime(task.endTime)}</Box>}
        </Box>
    )

    const locationField = task.location && (
        <Link onClick={openLocation} as='span'>
            {translate('event-details.select-location')}
        </Link>
    )

    const assigneesField = task.assignees.length && (
        <ChipList
            items={task.assignees}
            renderer={v => v.name}
            marginTop={4}
            horizontalSpacing={6}
            verticalSpacing={6}
        />
    )

    const description = <TextWithLinks key='0' text={optionalText(task.description)} />
    const duration = task.spendTimeInMinutes && minutesToTaskDuration(task.spendTimeInMinutes)
    const durationField = duration && `${duration.hour}h ${duration.minute}min`
    const modifier = modifiedAtOrBy && (
        <ByAtText key='1' at={task.modifiedAt} by={task.modifiedBy} />
    )

    const items: PropsListEntry[] = [
        ['tasks.task-preview.status', translateTaskTitle(task.status)],
        ['tasks.task-preview.severity', taskSeverityTitle(task.severity)],
        ['tasks.task-preview.process-type', translateTaskProcessType(task.processType)],
        ['tasks.task-preview.category', translateTaskCategory(task.category)],
        ['tasks.task-preview.title', task.title],
        ['tasks.task-preview.description', description],
        ['tasks.task-preview.assignees', assigneesField],
        ['tasks.task-preview.duration', durationField],
        ['tasks.task-preview.datetime', fromToText],
        ...detailedTaskPropertyList(task),
        ['tasks.task-preview.location', locationField],
        [
            'tasks.task-preview.reporter',
            atOrBy && <ByAtText key='2' at={task.createdAt} by={task.createdBy} />,
        ],
        ['tasks.task-preview.modifier', modifier],
    ]

    return (
        <>
            <PropsList
                items={items}
                variant={ThemeVariant.Black}
                size={LabeledTextElementSize.Small}
            />
            <AttachmentReadonlySection
                attachments={task.attachments}
                variant={ThemeVariant.Black}
                size={LabeledTextElementSize.Small}
            />
        </>
    )
}
