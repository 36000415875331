export const compareBy = <T, U>(getter: f.Func1<T, U>, comparator: f.Func2<U, U, number>) => (
    o1: T,
    o2: T,
) => comparator(getter(o1), getter(o2))

const toNumeric = (alphanumeric: string) => parseInt(alphanumeric.replace(/\D/g, ''), 10)

const toAlphabetic = (alphanumeric: string) => alphanumeric.replace(/\d/g, '')

// Even with regex matching for Alpha & Numeric this is 6x faster than numeric localeCompare
export const compareByAlphanumeric = (a: string, b: string): number => {
    const aNumeric = toNumeric(a)
    const bNumeric = toNumeric(b)

    if (aNumeric === bNumeric) {
        const aAlphabetic = toAlphabetic(a)
        const bAlphabetic = toAlphabetic(b)

        return aAlphabetic === bAlphabetic ? 0 : aAlphabetic > bAlphabetic ? 1 : -1
    } else {
        return aNumeric - bNumeric
    }
}
