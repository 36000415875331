import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Color } from 'aos-ui-common/src/styles/Color'

import { baggageDeliveryChartColors, baggageDeliveryRanges } from './baggageDeliveryConstants'
import { BaggageStatSection } from './types/BaggageStatSection'

export interface BaggageDeliveryLegendItemConfig {
    label: string
    labelColor: Color
    trendVariant: TrendChangeVariant
}

export const baggageDeliveryLegendConfig: Record<
    BaggageStatSection,
    BaggageDeliveryLegendItemConfig
> = {
    [BaggageStatSection.Normal]: {
        label: translate(
            'dashboard.baggage-delivery.time-group-1',
            baggageDeliveryRanges[BaggageStatSection.Normal],
        ),
        labelColor: baggageDeliveryChartColors[BaggageStatSection.Normal],
        trendVariant: TrendChangeVariant.PreferredUp,
    },
    [BaggageStatSection.Warning]: {
        label: translate(
            'dashboard.baggage-delivery.time-group-2',
            baggageDeliveryRanges[BaggageStatSection.Warning],
        ),
        labelColor: baggageDeliveryChartColors[BaggageStatSection.Warning],
        trendVariant: TrendChangeVariant.PreferredUp,
    },
    [BaggageStatSection.Alert]: {
        label: translate(
            'dashboard.baggage-delivery.time-group-3',
            baggageDeliveryRanges[BaggageStatSection.Alert],
        ),
        labelColor: baggageDeliveryChartColors[BaggageStatSection.Alert],
        trendVariant: TrendChangeVariant.PreferredUp,
    },
}
