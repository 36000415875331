import { arriveTimeSplit, countDiffTime } from 'aos-helpers/src/helpers/emergencyDashboard/helpers'
import { formatDateMonthTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    CommandObserveParticipate,
    roleStatusSelector,
} from 'aos-services/src/services/firebaseEvents/privateChannelState'
import { InvitationStatus } from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { PrivateChannelParticipant } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

import { ParticipantStatusPictogram } from './ParticipantStatusPictogram'

interface ChannelParticipantProps {
    participant: PrivateChannelParticipant
    changeStatus?: () => void
    arriveAt?(): void
    leaveEvent?(): void
}

export const ChannelParticipant = (props: ChannelParticipantProps) => {
    const { displayName, invitationRole, invitationStatus, arrivalTime } = props.participant
    const formattedTime = arrivalTime ? formatDateMonthTime(arrivalTime) : undefined
    const diffTime = arrivalTime ? countDiffTime(arrivalTime) : undefined
    const arriveTimeSpitted = arrivalTime && arriveTimeSplit(arrivalTime)
    const timePassed = diffTime?.when === 'after'

    const renderArriveAtBox = () => {
        if (
            diffTime &&
            arriveTimeSpitted &&
            invitationStatus === InvitationStatus.Coming &&
            (diffTime.days >= 1 || diffTime.days <= -1)
        ) {
            return (
                <ArriveAtBox>
                    <At>{formattedTime}</At>
                </ArriveAtBox>
            )
        }

        if (
            diffTime &&
            arriveTimeSpitted &&
            diffTime.days === 0 &&
            invitationStatus === InvitationStatus.Coming
        ) {
            const textIn = diffTime.when === 'before' ? 'in' : ''
            const textAgo = diffTime.when === 'after' ? 'ago' : ''
            return (
                <ArriveAtBox>
                    <At>{`${arriveTimeSpitted.hours}:${arriveTimeSpitted.minutes}`}</At>
                    <In>{`${textIn} ${diffTime.hours}h ${diffTime.minutes} min ${textAgo}`}</In>
                </ArriveAtBox>
            )
        }
        return null
    }

    return (
        <Box row paddingVertical={16}>
            <ParticipantStatusPictogram
                className='flex-shrink--0'
                role={invitationRole}
                status={invitationStatus}
                timePassed={timePassed}
            />
            <Box className='flex-grow--1' column paddingLeft={16} data-test-id='participant'>
                <Text color={Color.White} size={14} data-test-id='name'>
                    {displayName}
                </Text>
                <Text color={Color.Grey} size={13} paddingTop={4} data-test-id='status'>
                    {roleStatusSelector(invitationRole, invitationStatus, timePassed)}
                </Text>
            </Box>
            <Box>
                {CommandObserveParticipate.includes(props.participant.invitationRole) &&
                    renderArriveAtBox()}
            </Box>
            {props.changeStatus && renderMore()}
        </Box>
    )

    function renderMore() {
        return (
            <More>
                <MoreItem onClick={props.changeStatus}>
                    {`${translate('private-channel.action.change-status')}`}
                </MoreItem>
                {props.participant.invitationStatus === InvitationStatus.Coming && (
                    <MoreItem onClick={props.arriveAt}>
                        {translate('private-channel.action.arrival-time')}
                    </MoreItem>
                )}
                <MoreItem onClick={props.leaveEvent}>
                    {translate('private-channel.action.leave-event')}
                </MoreItem>
            </More>
        )
    }
}

const ArriveAtBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border: 1px solid ${Color.White};
    padding: 4px 6px;
    border-radius: 2px;
`

const At = styled.p`
    color: ${Color.White};
    font-size: 12px;
    font-weight: 500;
    margin: 0;
`

const In = styled.p`
    color: ${Color.White};
    font-size: 12px;
    margin: 3px 0 0 0;
`
