import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../components/sync/SyncWrapper'
import {
    shiftNotesSyncAction,
    shiftNotesSyncStopAction,
} from '../../../../core/tasks/shiftNotes/actions'

interface ShiftNotesSyncWrapperProps {
    id: SyncDataKey
}

export const ShiftNotesSyncWrapper: FC<PropsWithChildren<ShiftNotesSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={shiftNotesSyncAction(id)} onLeave={shiftNotesSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
