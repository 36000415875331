import { ChartScales } from '../types/Chart'

export const getBarPositionAndSize = <TX, TY>(
    scales: ChartScales<TX, TY>,
    xValue: TX,
    defaultBarWidth: number,
) => {
    const x = scales.xScale(xValue) || 0
    const bandwidth = scales.xScale.bandwidth
    const width = bandwidth ? bandwidth() : defaultBarWidth
    const xOffset = bandwidth ? 0 : width / 2 // band scale returns start of band as x coordinate
    return { x: x - xOffset, width }
}
