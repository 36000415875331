import { Box } from 'aos-ui/src/components/base/Box'
import React, { Component } from 'react'

/* eslint-disable react/jsx-no-literals */
export class ChangedValueCell extends Component<ChangedValueCellProps, {}> {
    public render() {
        const { newValue, oldValue } = this.props
        if (!newValue) {
            return null
        }

        return oldValue ? (
            <Box>
                {this.renderItem(oldValue)}
                <span className='text__m-ow-grey'> → </span>
                {this.renderItem(newValue)}
            </Box>
        ) : (
            <Box>{this.renderItem(newValue)}</Box>
        )
    }

    private renderItem = (v?: string) => {
        if (v && this.props.onClick) {
            return this.renderAsLink(v, this.props.onClick)
        }

        return <span>{v}</span>
    }

    private renderAsLink = (v: string, onClick: f.Action1<string>) => {
        const action = () => onClick(v)

        return (
            <span onClick={action} className='text--link text--underline'>
                {v}
            </span>
        )
    }
}

interface ChangedValueCellProps {
    newValue?: string
    oldValue?: string
    onClick?(v: string): void
}
