import { pickBy } from 'lodash'
import { format } from 'url'

import { AosUserNotificationsPreferences } from '../../services/users/types/AosUserNotificationsPreferences'
import { AosUserProfile } from '../../services/users/types/AosUserSetting'
import { BaseRestService } from '../base/BaseRestService'
import { AosFcmToken } from './types/AosFcmToken'
import { AosAdUserDto } from './types/AosUserDto'

class UserProfileRestService extends BaseRestService {
    public getProfileById(id: number): Promise<AosAdUserDto> {
        return this.getAndTransform<AosAdUserDto>(`/users/${id}`, BaseRestService.jsonTransformer)
    }

    public addFcmTokenToUser(userId: number, token: AosFcmToken) {
        return this.postJson(`/users/${userId}/devices`, token)
    }

    public removeFcmTokenFromUser(userId: number, token: string) {
        return this.deleteReq(`/users/${userId}/devices/${token}`)
    }

    public updateUserNotifications(
        userId: number,
        preferences: AosUserNotificationsPreferences,
    ): Promise<Response> {
        return this.postJson(`/users/${userId}/notifications`, preferences)
    }

    public updateUserProfile(userId: number, profile: AosUserProfile): Promise<Response> {
        return this.postJson(
            format({
                pathname: `/users/${userId}/profile`,
            }),
            pickBy(profile, v => !!v),
        )
    }

    public deleteAccount(email: string) {
        return this.postJson(`/ext/account-deletion`, { email })
    }
}

export const userProfileRestService = new UserProfileRestService()
