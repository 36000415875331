import { UrlFiltersConfig } from '../helpers/urlFilters'

export interface TowingFilters {
    filtersVisible?: boolean
    after?: number
    until?: number
    standFrom?: string
    standTo?: string
    acreg?: string
    actype?: string
    handl?: string
    status?: string

    arrFltnr?: string
    arrTime?: number
    arrTimeType?: string
    arrCallsign?: string

    depFltnr?: string
    depTime?: number
    depTimeType?: string
    depCallsign?: string

    lastUpdated?: number
}

export type TowingFilterNames = (keyof TowingFilters)[]

const booleanFilters: TowingFilterNames = ['filtersVisible']

const numberFilters: TowingFilterNames = ['after', 'until', 'arrTime', 'depTime', 'lastUpdated']

const stringFilters: TowingFilterNames = [
    'standFrom',
    'standTo',
    'acreg',
    'actype',
    'handl',
    'arrFltnr',
    'arrTimeType',
    'arrCallsign',
    'depFltnr',
    'depTimeType',
    'depCallsign',
    'status',
]

export const towingUrlFiltersConfig: UrlFiltersConfig = {
    booleanFilters,
    numberFilters,
    stringFilters,
}

export const defaultTowingFilters: TowingFilters = {
    filtersVisible: false,
}
