import { AttachmentUpdatePayload } from 'aos-services/src/services/attachments/types/AttachmentUpdatePayload'
import {
    isImage,
    UploadedAttachment,
} from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'

import { PreviewEditBox } from './PreviewEditBox'
import { PreviewViewBox } from './PreviewViewBox'

interface PreviewBoxProps {
    attachments: UploadedAttachment[]
    attachmentIndex: number
    editable: boolean
    updateAttachment?(v: AttachmentUpdatePayload): void
    onIndexChange(v: number): void
    close(): void
}

export const PreviewBox: FCWithChildren<PreviewBoxProps> = props => {
    const { editable, attachments, attachmentIndex, close, onIndexChange, updateAttachment } = props
    const attachment = attachments[attachmentIndex]
    const displayAsEdit = editable && isImage(attachment)
    const prevAction = attachments[attachmentIndex - 1]
        ? () => onIndexChange(attachmentIndex - 1)
        : undefined
    const nextAction = attachments[attachmentIndex + 1]
        ? () => onIndexChange(attachmentIndex + 1)
        : undefined

    return displayAsEdit ? (
        <PreviewEditBox
            attachment={attachment}
            close={close}
            previous={prevAction}
            next={nextAction}
            updateAttachment={updateAttachment}
        />
    ) : (
        <PreviewViewBox
            attachment={attachment}
            close={close}
            previous={prevAction}
            next={nextAction}
        />
    )
}
