import {
    inspectionItemLabel,
    InspectionTask,
} from 'aos-services/src/services/tasks/types/task/InspectionTask'
import { Box } from 'aos-ui/src/components/base/Box'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import React from 'react'

export const inspectionTaskPropertyList = (t: InspectionTask): PropsListEntry[] => [
    [
        'tasks.task-preview.inspection',
        !!t.items.length && (
            <Box>
                {t.items.map((ch, index) => (
                    <Box key={index}>{inspectionItemLabel(ch)}</Box>
                ))}
            </Box>
        ),
    ],
]
