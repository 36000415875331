import {
    ContactDTO,
    ContactItem,
    UnitAirportContact,
    UnitAirportContactDTO,
} from '../../services/contactList/types/ContactItem'
import { BaseRestService } from '../base/BaseRestService'

export class ContactListRestService extends BaseRestService {
    getAllContacts() {
        return this.getAndTransform<ContactItem[]>(
            '/utilities-service/airport-contact',
            BaseRestService.jsonTransformer,
        )
    }

    getAllUnits() {
        return this.getAndTransform<UnitAirportContact[]>(
            '/utilities-service/unit-airport-contact',
            BaseRestService.jsonTransformer,
        )
    }

    createContact(contact: ContactDTO) {
        return this.postJson('/utilities-service/airport-contact', contact)
    }

    createUnitAirportContact(unit: UnitAirportContactDTO) {
        return this.postJson('/utilities-service/unit-airport-contact', unit)
    }

    editContact(contact: ContactDTO) {
        return this.putJson(`/utilities-service/airport-contact/${contact.id}`, {
            ...contact,
            id: undefined,
        })
    }

    editUnitAirportContact(unit: UnitAirportContactDTO) {
        return this.putJson(`/utilities-service/unit-airport-contact/${unit.id}`, {
            ...unit,
            id: undefined,
        })
    }

    deleteUnitAirportContact(id: number) {
        return this.deleteReq(`/utilities-service/unit-airport-contact/${id}`)
    }

    deleteContact(id: number) {
        return this.deleteReq(`/utilities-service/airport-contact/${id}`)
    }
}

export const contactListRestService = new ContactListRestService()
