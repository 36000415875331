import {
    BROADCAST_GLOBAL_MESSAGE_REQUEST,
    BROADCAST_GLOBAL_MESSAGE_SUCCESS,
    CHANGE_GLOBAL_MESSAGING_FORM,
    LOAD_GLOBAL_MESSAGES_SUCCESS,
    MessagingAction,
    TOGGLE_GLOBAL_MESSAGE_CONFIRMATION,
    TOGGLE_GLOBAL_MESSAGING,
} from './actions'
import {
    GlobalMessagingState,
    initialGlobalMessagingState,
    initialNewGlobalMessageState,
} from './state'

export function globalMessagingReducer(
    state = initialGlobalMessagingState,
    action: MessagingAction,
): GlobalMessagingState {
    switch (action.type) {
        case TOGGLE_GLOBAL_MESSAGING:
            return { ...state, isOpen: action.payload }

        case TOGGLE_GLOBAL_MESSAGE_CONFIRMATION: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isConfirmationVisible: action.payload,
                },
            }
        }

        case BROADCAST_GLOBAL_MESSAGE_REQUEST: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    isSendingMessage: true,
                },
            }
        }

        case BROADCAST_GLOBAL_MESSAGE_SUCCESS: {
            return {
                ...state,
                ui: {
                    isSendingMessage: false,
                    isConfirmationVisible: false,
                },
            }
        }

        case LOAD_GLOBAL_MESSAGES_SUCCESS: {
            return {
                ...state,
                messages: action.payload,
                newMessage: initialNewGlobalMessageState,
            }
        }

        case CHANGE_GLOBAL_MESSAGING_FORM:
            return { ...state, newMessage: action.payload }

        default:
            return state
    }
}
