import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum ReportType {
    Chemicals = 1,
    Animals,
}

export const allReportTypes = EnumValues.getValues<ReportType>(ReportType)

export const translateReportType = translateEnum(ReportType, 'report-type')
