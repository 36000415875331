import styled from 'styled-components'

interface RotatingProps {
    reverse?: boolean
}

export const Rotating = styled.div<RotatingProps>`
    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
    animation: rotating 1s linear infinite ${p => (p.reverse ? 'reverse' : '')};
`
