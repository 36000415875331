import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Duration } from 'moment'

import { BaggageStatSection } from '../../airportStatus/baggage/types/BaggageStatSection'

export interface AosFlightBeltStatus {
    indicator: string
    firstBag?: DateTime
    lastBag?: DateTime
    deliveryTime: (now: DateTime) => Duration
    deliveryTimeInMinutes: (now: DateTime) => number
    isDeliveryTimeValid: (now: DateTime) => boolean
    hasBag: boolean
    progress: BaggageIndicatorProgress
    deliveryStatus: (now: DateTime) => BaggageStatSection
}

export enum BaggageIndicatorProgress {
    NotLanded,
    Landed,
    DeliveryStarted,
    DeliveryCompleted,
}
