import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { isSnowtam } from 'aos-services/src/services/flightInformation/types/Notam'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import moment from 'moment'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
    lastUpdatedSelector,
    notamBorderColor,
    notamItemSelector,
} from '../../../../core/notamTimeline/selectors'
import Dots from './assets/dots.svg'
import { DefaultSidebar } from './DefaultSidebar'

export const SidebarStack: FC = () => {
    const item = useSelector(notamItemSelector)
    const lastUpdated = useSelector(lastUpdatedSelector)
    const textColor = useMemo(() => item && notamBorderColor(item), [item])

    const title = useMemo(() => {
        return (
            <Box height={50} row alignContent='center'>
                <SvgImage style={iconStyle} svg={Dots} />
                <Box column rowGap={20}>
                    <Text size={18} weight='regular'>
                        {moment(item?.startTime).utc().format('ddd. DD.MM.YYYY, HH:mm UTC')}
                    </Text>
                    <Text size={18} weight='regular'>
                        {moment(item?.endTime).utc().format('ddd. DD.MM.YYYY, HH:mm UTC')}
                    </Text>
                </Box>
            </Box>
        )
    }, [textColor, item])

    if (item) {
        const snowtamItem = isSnowtam(item)

        return (
            <Sidebar title={title}>
                <Box fullHeight column>
                    <Box column rowGap={12} flex={1}>
                        {snowtamItem ? (
                            <>
                                <Box column rowGap={6}>
                                    <Text size={14} color={Color.Grey}>
                                        {translate('notam.sidebar.content.aeroplane-performance')}
                                    </Text>
                                    <Text size={14}>{item.aeroplanePerformance}</Text>
                                </Box>
                                <Box column rowGap={6}>
                                    <Text size={14} color={Color.Grey}>
                                        {translate('notam.sidebar.content.situational-awareness')}
                                    </Text>
                                    <Text size={14}>{item.situationalAwareness}</Text>
                                </Box>
                            </>
                        ) : (
                            <Box column rowGap={6}>
                                <Text size={14} color={Color.Grey}>
                                    {translate('notam.sidebar.content.text')}
                                </Text>
                                <Text size={14}>{item.text}</Text>
                            </Box>
                        )}
                        <Box column rowGap={6}>
                            <Text size={14} color={Color.Grey}>
                                {translate(
                                    snowtamItem
                                        ? 'notam.sidebar.content.number-snowtam'
                                        : 'notam.sidebar.content.number',
                                )}
                            </Text>
                            <Text size={14}>{item.number}</Text>
                        </Box>
                    </Box>
                    <Box paddingVertical={16} column rowGap={8}>
                        <a target='_blank' href='https://fintraffic.fi' rel='noreferrer'>
                            <Text underline size={12} color={Color.Grey8}>
                                {translate('notam.sidebar.content.data-source')}
                            </Text>
                        </a>
                        <Text size={12} color={Color.Grey8}>
                            {translate('notam.sidebar.last-updated', {
                                time: lastUpdated?.utc().format('DD:MM:YYYY, HH:mm UTC'),
                            })}
                        </Text>
                    </Box>
                </Box>
            </Sidebar>
        )
    }

    return <DefaultSidebar />
}

const iconStyle = {
    width: 20,
    height: '100%',
    marginRight: 12,
}
