import { mapPageableContent, Pageable, SortDirection } from 'aos-helpers/src/helpers/Pageable'
import { PageRequest } from 'aos-helpers/src/helpers/PageRequest'

import { feedsRestService } from '../../dataaccess/feeds/feedsRestService'
import { aosFeedInPropsToAosBaseEventDto } from './aosEventConverter'
import { EventPayload } from './input/EventPayload'
import { FeedInFilter } from './transport/FeedInPageRequest'
import { AosFeedIn } from './types/AosFeedIn'
import { AosFeedInImpl } from './types/AosFeedInImpl'
import { AosFeedInType } from './types/AosFeedInType'

export class FeedsService {
    public async createExtendedFeedIn(feedIn: EventPayload): Promise<AosFeedIn> {
        const dto = await aosFeedInPropsToAosBaseEventDto(feedIn as AosFeedIn)
        const result = await feedsRestService.createFeedIn({ ...dto, type: AosFeedInType.Extended })
        return new AosFeedInImpl(result)
    }

    public async createSimpleFeedIn(feedIn: EventPayload): Promise<AosFeedIn> {
        const result = await feedsRestService.createFeedIn({
            description: feedIn.description,
            attachments: feedIn.attachments,
            location: feedIn.location,
            type: AosFeedInType.Simple,
        })
        return new AosFeedInImpl(result)
    }

    public loadFeeds(pageRequest: PageRequest, filter: FeedInFilter): Promise<Pageable<AosFeedIn>> {
        return feedsRestService
            .loadFeeds(filter, {
                ...pageRequest,
                sortBy: 'createdAt',
                sortOrder: SortDirection.Desc,
            })
            .then(mapPageableContent(t => new AosFeedInImpl(t)))
    }

    public dismissFeed(id: number): Promise<void> {
        return feedsRestService.dismissEvent(id)
    }

    public async addEventToGroup(parentId: number, feedInId: number): Promise<void> {
        await feedsRestService.addEventToGroup(parentId, feedInId)
        return undefined
    }

    public async loadFeedIn(id: number): Promise<AosFeedIn> {
        const result = await feedsRestService.loadFeedIn(id)
        return new AosFeedInImpl(result)
    }

    public getHasActiveFeedIns = () =>
        feedsRestService
            .loadFeeds(FeedInFilter.Current, {
                page: 0,
                size: 1,
            })
            .then(pageable => pageable.content.length > 0)
}

export const feedsService = new FeedsService()
