import { translate } from 'aos-helpers/src/helpers/translations/Translations'

export interface BusDoorProperties {
    label: string
}

export const busDoorNumber = (props: BusDoorProperties) =>
    parseInt(props.label.replace(/\D/g, ''), 10)

export const busDoorSubtitle = (props: BusDoorProperties) =>
    `${translate('map.sidebar.bus-door.' + busDoorNumber(props))}`
