import { EventRelatedNotification } from 'aos-services/src/services/notifications/types/EventRelatedNotification'
import { IconEventItem } from 'aos-ui-event/src/components/event/IconEventItem'
import React, { PropsWithChildren, PureComponent } from 'react'

import { BaseNotificationItem } from './BaseNotificationItem'

export class EventRelatedNotificationItem extends PureComponent<
    PropsWithChildren<EventRelatedNotificationItemProps>
> {
    public render() {
        const {
            notification: { title, read },
            children,
            onClick,
        } = this.props
        return (
            <BaseNotificationItem
                title={title}
                read={read}
                leftColumn={this.leftColumn}
                onClick={onClick}
            >
                {children}
            </BaseNotificationItem>
        )
    }

    private get leftColumn() {
        const {
            notification: { severity, category },
        } = this.props
        return <IconEventItem severity={severity} category={category} />
    }
}

interface EventRelatedNotificationItemProps {
    notification: EventRelatedNotification<any>
    onClick?(): void
}
