import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    allWeatherTimePoints,
    translateWeatherTimePoint,
    WeatherCategory,
    WeatherMetric,
    WeatherTimePoint,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'

import { Footer } from '../../base/Footer'
import { ItemContainer } from '../../base/ItemContainer'
import { WeatherMetrics } from './metrics/WeatherMetrics'
import { WeatherIcon } from './WeatherIcon'

export class WeatherCompactView extends PureComponent<WeatherCompactProps> {
    public render() {
        const tabsConfig: RoundTabsProps<number | string>[] = [
            {
                items: allWeatherTimePoints,
                value: this.props.tab,
                name: 'weather-tab',
                formatter: translateWeatherTimePoint('dashboard.weather.tabs'),
                onChange: this.props.onChangeTime,
                tooltip: translate('dashboard.weather.tabs.time-tooltip'),
                width: DropdownWidth.Medium,
            },
            {
                items: EnumValues.getValues(WeatherUnit),
                value: this.props.unitTab,
                name: 'weather-unit-tab',
                formatter: translateEnum<WeatherUnit>(WeatherUnit, 'dashboard.weather.unit-tabs'),
                onChange: this.props.onChangeUnit,
                tooltip: translate('dashboard.weather.tabs.unit-tooltip'),
                width: DropdownWidth.Small,
            },
        ]
        return (
            <>
                <ItemContainer horizontalSpacing autoHeight>
                    <Box fullHeight row paddingVertical={20}>
                        {this.renderWeatherIcon()}
                        {this.renderWeatherMetrics()}
                    </Box>
                </ItemContainer>
                <Footer>
                    <MultiRoundTabs items={tabsConfig} />
                </Footer>
            </>
        )
    }

    private renderWeatherMetrics = () => {
        const temperature = this.getWeather(WeatherValueSeriesType.Temperature)
        const windSpeed = this.getWeather(WeatherValueSeriesType.WindSpeed)
        const windDirection = this.getWeather(WeatherValueSeriesType.WindDirection)
        const windGusts = this.getWeather(WeatherValueSeriesType.MaxWindGust)

        return (
            <WeatherMetrics
                unitTab={this.props.unitTab}
                temperature={temperature}
                windSpeed={windSpeed}
                windDirection={windDirection}
                windGusts={windGusts}
            />
        )
    }

    private renderWeatherIcon = () => {
        const { weatherCategory } = this.props
        const lvp = this.getWeather(WeatherValueSeriesType.LvpProbability)
        const thunderstorm = this.getWeather(WeatherValueSeriesType.ThunderstormProbability)
        return (
            <WeatherIcon
                weatherCategory={weatherCategory}
                lvp={lvp || 0}
                thunderstorm={thunderstorm || 0}
            />
        )
    }

    private getWeather = (seriesType: WeatherValueSeriesType): number | undefined =>
        this.props.weatherSeriesMetric[seriesType]
}

export interface WeatherCompactProps {
    weatherSeriesMetric: WeatherMetric
    weatherCategory?: WeatherCategory
    tab: WeatherTimePoint
    unitTab: WeatherUnit
    onChangeTime(v: WeatherTimePoint): void
    onChangeUnit(v: WeatherUnit): void
}
