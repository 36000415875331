import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../services/firebase/firebaseService'
import { flightsService } from '../../services/flightInformation/flightsService'
import { Notam, Snowtam } from '../../services/flightInformation/types/Notam'
import {
    NOTAM_LOAD,
    NOTAM_SYNC,
    NOTAM_SYNC_STOP,
    NotamLoadAction,
    notamLoadAction,
    notamLoadSuccessAction,
    NotamSyncAction,
    NotamSyncStopAction,
    SNOWTAM_LOAD,
    SNOWTAM_SYNC,
    SNOWTAM_SYNC_STOP,
    snowtamLoadAction,
    snowtamLoadSuccessAction,
    SnowtamSyncAction,
    SnowtamSyncStopAction,
} from './actions'

const NOTAM_LISTENER_NAME = 'notam'
const SNOWTAM_LISTENER_NAME = 'snowtam'

function* notamSyncSaga() {
    yield takeEvery<NotamSyncAction>(NOTAM_SYNC, function* (action) {
        yield* firebaseService.onListener(
            `${NOTAM_LISTENER_NAME}/${action.payload}`,
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.NOTAM),
                    notamLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* notamSyncStopSaga() {
    yield takeEvery<NotamSyncStopAction>(NOTAM_SYNC_STOP, function* (action) {
        yield firebaseService.offListener(
            `${NOTAM_LISTENER_NAME}/${action.payload}`,
            action.payload,
        )
    })
}

function* notamLoadSaga() {
    yield takeEvery<NotamLoadAction>(NOTAM_LOAD, function* () {
        const data: Notam[] = yield call(flightsService.loadNotam)
        yield put(notamLoadSuccessAction(data))
    })
}

function* snowtamSyncSaga() {
    yield takeEvery<SnowtamSyncAction>(SNOWTAM_SYNC, function* (action) {
        yield* firebaseService.onListener(
            `${SNOWTAM_LISTENER_NAME}/${action.payload}`,
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.SNOWTAM),
                    snowtamLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* snowtamSyncStopSaga() {
    yield takeEvery<SnowtamSyncStopAction>(SNOWTAM_SYNC_STOP, function* (action) {
        yield firebaseService.offListener(
            `${SNOWTAM_LISTENER_NAME}/${action.payload}`,
            action.payload,
        )
    })
}

function* snowtamLoadSaga() {
    yield takeEvery(SNOWTAM_LOAD, function* () {
        const data: Snowtam[] = yield call(flightsService.loadSnowtam)
        yield put(snowtamLoadSuccessAction(data))
    })
}

export const notamSagas = [
    notamSyncSaga,
    notamSyncStopSaga,
    notamLoadSaga,
    snowtamSyncSaga,
    snowtamSyncStopSaga,
    snowtamLoadSaga,
]
