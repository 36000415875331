import { createSelector } from 'reselect'

import { filterCollectionAndPoints } from '../../../../services/airportStatus/flights/types/FlightCollectionAndPoints'
import { toFlightCollectionPoints } from '../../../../services/airportStatus/flights/types/FlightCollectionPoint'
import { isCompleted } from '../../../../services/flightInformation/types/Flight'
import { FlightListType } from '../../../../services/flightInformation/types/FlightListType'
import { currentUserSiteLocation } from '../../../auth/state'
import { FlightsStateAware } from '../../../flightInformation/state'
import { BaseStatusDashboardState } from '../../../statusDashboard/state'

export interface StatusDashboardStateAware {
    statusDashboard: BaseStatusDashboardState
}

export interface ItemStateAware<T> {
    itemState: T
}

export interface IdAware {
    id: number
}

export type FiltersAware<T> = ItemStateAware<{ filters: T }>

const flightsCollectionSelector = createSelector(
    (state: FlightsStateAware) => state.flights.list,
    currentUserSiteLocation,
    (flights, location) => {
        const perLocationFlights = flights.filter(f => f.hapt === location)
        return {
            arrivals: perLocationFlights.filter(
                ({ flightType }) => flightType === FlightListType.Arrivals,
            ),
            departures: perLocationFlights.filter(
                ({ flightType }) => flightType === FlightListType.Departures,
            ),
        }
    },
)

export const flightsLoadedSelector = (state: FlightsStateAware): boolean =>
    !!state.flights.list.length

export const flightCollectionAndPointsSelector = createSelector(
    flightsCollectionSelector,
    flights => ({
        flights,
        points: toFlightCollectionPoints(flights),
    }),
)

export const completedFlightCollectionAndPointsSelector = createSelector(
    flightCollectionAndPointsSelector,
    collection => filterCollectionAndPoints(collection, isCompleted),
)
