import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'

import Finavia from './assets/finavia.svg'
import Logo from './assets/logo.svg'

export const WhatsNewLogo = () => (
    <Box column centered justify='flex-start'>
        <Logo className='margin-bottom--std' />
        <Finavia className='margin-bottom--std' />
        <Text color={Color.White} size={10}>
            {translate('generic.full-name')}
        </Text>
    </Box>
)
