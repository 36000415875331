import { EnumValues } from 'enum-values'
import { chain } from 'lodash'

import { PaxEntry } from './PaxEntry'
import { PointId } from './PointId'

export function getPaxData(pax: PaxEntry[]): Record<PointId, PaxEntry[]> {
    return chain(pax)
        .filter(a => !!EnumValues.getNameFromValue(PointId, a.pointId))
        .groupBy('pointId')
        .value() as Record<PointId, PaxEntry[]>
}
