import { SvgMultilineText } from 'aos-ui/src/components/svg/SvgMultilineText'
import {
    ChartContextProps,
    withChartContext,
} from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisProps } from './AxisProps'
import { HorizontalAxis } from './HorizontalAxis'

const defaultTickConfig = {
    defaultPadding: 3,
    defaultTickSize: 32,
}

export class AxisTopComponent<T extends AxisDomain> extends HorizontalAxis<T> {
    constructor(props: AxisProps<T> & ChartContextProps) {
        super(props, 'top')
    }
    protected renderTick = (tick: T, index: number) => {
        const { scale, axisConfig, size } = this.props

        const { tickClass, tickOffset, tickFormat, tickSize } = this.getTickConfig(tick, {
            ...defaultTickConfig,
        })
        const textOffset = scale.bandwidth ? scale.bandwidth() / 2 : 0

        const step = scale.step ? scale.step() : 0
        const x = scale(tick) || 0
        const textPos = x + textOffset
        const lineXPos = textPos - step / 2
        const label = tickFormat(tick)

        const gridSize = axisConfig.showGrids !== false ? size.height : 0

        return (
            <g className={tickClass} key={index}>
                <line y1={-tickSize} y2={gridSize} transform={`translate(${lineXPos}, 0)`} />
                <SvgMultilineText
                    dy='0.71em'
                    y={-tickOffset}
                    textAnchor='middle'
                    transform={`translate(${textPos} ${0})`}
                >
                    {label}
                </SvgMultilineText>
                <line y1={-tickSize} y2={gridSize} transform={`translate(${lineXPos + step}, 0)`} />
            </g>
        )
    }
}

export const AxisTop = withChartContext(AxisTopComponent)
