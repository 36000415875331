import {
    queueingTimeSyncAction,
    queueingTimeSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/queueingTime/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../../components/sync/SyncWrapper'

interface QueueingTimeSyncWrapperProps {
    id: SyncDataKey
}

export const QueueingTimeSyncWrapper: FC<PropsWithChildren<QueueingTimeSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={queueingTimeSyncAction(id)} onLeave={queueingTimeSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
