import { Unit } from 'aos-helpers/src/helpers/Unit'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import { RegularityStats } from 'aos-services/src/services/airportStatus/flights/types/RegularityStats'
import { regularityLegendConfig } from 'aos-services/src/services/airportStatus/regularity/regularityLegendConfig'
import { trendIncreasingGreenVariants } from 'aos-ui/src/components/chart/trend/TrendIconVariants'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { DashboardPieChart } from '../../common/DashboardPieChart'
import { RegularityChartLegend } from './RegularityChartLabel'
import { RegularityLegend } from './RegularityLegend'
import { RegularityStatsSection } from './RegularityStatsSection'

interface RegularityPieChartProps {
    stat: RegularityStats
    simple?: boolean
}

export const RegularityPieChart: FC<RegularityPieChartProps> = props => {
    const { stat, simple = false } = props
    const all = stat[FlightsType.All]
    const arrivals = stat[FlightsType.Arrivals]
    const departures = stat[FlightsType.Departures]

    const trendProps = {
        value: all.value,
        trend: all.trend,
        tooltipPrefix: 'dashboard.regularity.trend.tooltip',
        iconVariants: trendIncreasingGreenVariants,
        unit: Unit.Percent,
    }
    const legend = simple ? (
        <RegularityLegend />
    ) : (
        <RegularityStatsSection
            arrivals={{ ...arrivals, ...regularityLegendConfig[FlightsType.Arrivals] }}
            departures={{ ...departures, ...regularityLegendConfig[FlightsType.Departures] }}
        />
    )
    const label = simple ? undefined : <RegularityChartLegend item={all} />

    return (
        <DashboardPieChart
            compact={simple}
            trend={trendProps}
            seriesColors={{ 0: Color.UiBlack3, 1: Color.Red }}
            data={[all.value, 100 - all.value]}
            legend={legend}
            label={label}
        />
    )
}
