import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { OperationalForecastSyncWrapper } from './OperationalForecastSyncWrapper'

interface OperationalForecastItemProps {
    itemProps: StatusDashboardItemCommonProps
    noDataWarning?: boolean
    link?: string
    lastUpdated: DateTime
    syncId: SyncDataKey
}

export const OperationalForecastItem: FC<
    PropsWithChildren<OperationalForecastItemProps>
> = props => (
    <OperationalForecastSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.operational-forecast.title')}
            description={translate('dashboard.operational-forecast.description', {
                time: formatDateTime(props.lastUpdated),
            })}
            noDataWarning={props.noDataWarning}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </OperationalForecastSyncWrapper>
)
