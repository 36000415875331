import { RingRailTrainMapProperties } from 'aos-services/src/services/layerData/properties/RingRailTrainMapProperties'
import { RingRailTrain } from 'aos-services/src/services/layerData/types/RingRailTrain'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { curry } from 'lodash'
import Icon, { Options as IconOptions } from 'ol/style/Icon'
import Style from 'ol/style/Style'

import {
    renderSvgIcon,
    renderTrainBackgroundIcon,
    renderTrainLabelIcon,
    renderTrainOverlayIcon,
} from '../icons/MapIconRenderer'

export const getStyleForTrain = (
    trainWithSelection: [RingRailTrain, boolean],
): Promise<Style[]> => {
    const [train, isSelected] = trainWithSelection
    return isSelected
        ? Promise.all([
              getBackgroundItemStyle(train),
              getTrainStyle(),
              getTrainLabelStyle(train),
              getBackgroundOverlayItemStyle(train),
          ])
        : Promise.all([getBackgroundItemStyle(train), getTrainStyle(), getTrainLabelStyle(train)])
}

const commonBackgroundImageOptions: IconOptions = {
    opacity: 1,
    scale: 1,
    anchor: [16, 32],
    anchorXUnits: 'pixels',
    anchorYUnits: 'pixels',
    rotateWithView: false,
    size: [32, 48],
}

const getBackgroundItemStyle = (train: RingRailTrainMapProperties) => {
    const rotation = train.angle || 0
    return renderTrainBackgroundIcon(train).then(getTrainBackgroundStyleForUrl(rotation))
}

export const getBackgroundOverlayItemStyle = (train: RingRailTrainMapProperties) => {
    const rotation = train.angle || 0
    return renderTrainOverlayIcon(train).then(getTrainBackgroundStyleForUrl(rotation))
}

const getTrainBackgroundStyleForUrlAndRotation = (rotation: number, src: string) =>
    new Style({
        image: new Icon({
            ...commonBackgroundImageOptions,
            rotation,
            src,
        }),
    })

const getTrainBackgroundStyleForUrl = curry(getTrainBackgroundStyleForUrlAndRotation)

const getTrainStyle = () =>
    renderSvgIcon(SvgIcon.TrainInner).then(
        src =>
            new Style({
                image: new Icon({
                    opacity: 1,
                    scale: 1,
                    anchor: [0.5, 0.5],
                    rotateWithView: false,
                    src,
                }),
            }),
    )

const getTrainLabelStyle = (train: RingRailTrainMapProperties) =>
    renderTrainLabelIcon(train).then(
        src =>
            new Style({
                image: new Icon({
                    opacity: 1,
                    scale: 1,
                    anchor: [38, -24],
                    anchorXUnits: 'pixels',
                    anchorYUnits: 'pixels',
                    rotateWithView: false,
                    src,
                }),
            }),
    )
