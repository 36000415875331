import { AosDashboardEventDto } from '../../../dataaccess/events/types/AosDashboardEventDto'
import { AosDashboardEvent } from './AosDashboardEvent'
import { AosTimelineEventImpl } from './AosTimelineEventImpl'

export class AosDashboardEventImpl
    extends AosTimelineEventImpl<AosDashboardEventDto>
    implements AosDashboardEvent {
    constructor(value: AosDashboardEventDto) {
        super(value)
    }

    public get description() {
        return this.value.description
    }
}
