import { emptyActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { Action } from 'redux'

export const OPEN_REPORTING_MODAL = 'OPEN_REPORTING_MODAL'
export type OpenReportingModalAction = Action<typeof OPEN_REPORTING_MODAL>
export const openReportingModalAction =
    emptyActionCreator<OpenReportingModalAction>(OPEN_REPORTING_MODAL)
export const CLOSE_REPORTING_MODAL = 'CLOSE_REPORTING_MODAL'
export type CloseReportingModalAction = Action<typeof CLOSE_REPORTING_MODAL>
export const closeReportingModalAction =
    emptyActionCreator<CloseReportingModalAction>(CLOSE_REPORTING_MODAL)

export type ReportingAction = OpenReportingModalAction | CloseReportingModalAction
