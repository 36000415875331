import {
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { FormFeedback } from 'aos-components/src/components/modal/InputModal/core/simpleForm/state'
import { isResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { groupService } from 'aos-services/src/services/users/groupService'
import { call, put } from 'redux-saga/effects'

import { CREATE_GROUP_PARENT, createGroupActions, loadGroupsAction } from '../actions'

function* createNewGroupSaga() {
    function* createGroup(action: SubmitAction) {
        try {
            yield call(groupService.createNewGroup, action.payload.value)
            yield put(loadGroupsAction())
        } catch (e) {
            if (isResponseError(e) && e.originalResponse.status === 409) {
                yield put(
                    createGroupActions.setFeedback({
                        status: FormFeedback.Negative,
                        message: translate('create-user-group.already-exists'),
                    }),
                )
            } else {
                throw e
            }
        }
    }

    yield takeEveryChildAction<SubmitAction>(CREATE_GROUP_PARENT, SUBMIT, createGroup)
}

export const createGroupSagas = [createNewGroupSaga]
