import { syncMqttWebsocket } from 'aos-helpers/src/helpers/websocket/MqttSaga'
import { Feature, Point } from 'geojson'
import { Dictionary } from 'lodash'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { ringRailDataAccessService } from '../../dataaccess/layerData/ringRailDataAccessService'
import { websocketListenerService } from '../../services/common/WebsockerListenerService'
import { layerDataService } from '../../services/layerData/LayerDataService'
import { GatesProperties } from '../../services/layerData/properties/GatesProperties'
import { LayerDataServiceEntry } from '../../services/layerData/types/LayerDataServiceEntry'
import { LayersDataState } from '../../services/layerData/types/LayersDataState'
import { RingRailStation, RingRailTrain } from '../../services/layerData/types/RingRailTrain'
import { SelectedMapElementType } from '../../services/map/types/SelectedMapElement'
import { ringRailService } from '../../services/ringRail/ringRailService'
import {
    LOAD_LAYERS_DATA,
    LoadLayersDataAction,
    loadLayersDataSuccessAction,
    updateTrainLocationsAction,
    updateTrainsAction,
} from './actions'
import { busGateStateSelector1, gateStateSelector1 } from './state'

function* loadLayersData(layers: LayerDataServiceEntry[]) {
    const data: LayersDataState = yield call(layerDataService.loadLayers, layers)
    yield put(loadLayersDataSuccessAction(data))
}

function* loadLayersDataSaga() {
    yield takeEvery<LoadLayersDataAction>(
        LOAD_LAYERS_DATA,
        function* (action: LoadLayersDataAction) {
            yield loadLayersData(action.payload)
        },
    )
}

export const ringRailTrainsSync = 'ringRailTrains'
export const ringRailLocationsSync = 'ringRailLocations'

export function* assureRingRailSync(
    ringRailSyncGetter: (state: any) => boolean,
    componentName: string,
) {
    const ringRailSync: boolean = yield select(ringRailSyncGetter)
    if (ringRailSync) {
        yield websocketListenerService.onListeners(
            {
                *[ringRailTrainsSync]() {
                    const stations: Dictionary<RingRailStation> = yield call(
                        ringRailService.getTrainStations,
                    )
                    const trains: RingRailTrain[] = yield call(
                        ringRailService.getCurrentTrains,
                        stations,
                    )
                    yield put(
                        loadLayersDataSuccessAction({ ringRailTrainsInfo: { trains, stations } }),
                    )
                    yield syncMqttWebsocket(
                        ringRailDataAccessService.getTrainsRef(),
                        updateTrainsAction,
                        2000,
                    )
                },
                *[ringRailLocationsSync]() {
                    yield syncMqttWebsocket(
                        ringRailDataAccessService.getTrainLocationsRef(),
                        updateTrainLocationsAction,
                        2000,
                    )
                },
            },
            componentName,
        )
    } else {
        yield websocketListenerService.offListeners(
            [ringRailTrainsSync, ringRailLocationsSync],
            componentName,
        )
    }
}

function* findGateInState(gateId: string) {
    const gate: Feature<Point, GatesProperties> | undefined = yield select(
        gateStateSelector1(gateId),
    )
    const busGate: Feature<Point, GatesProperties> | undefined = yield select(
        busGateStateSelector1(gateId),
    )
    const gateWithTarget = gate ? [gate, SelectedMapElementType.Gate] : undefined
    const busGateWithTarget = busGate ? [busGate, SelectedMapElementType.BusGate] : undefined
    return gateWithTarget || busGateWithTarget
}

export function* findGateFeature(gateId: string) {
    let gate:
        | [Feature<Point, GatesProperties>, SelectedMapElementType]
        | undefined = yield findGateInState(gateId)
    if (!gate) {
        yield loadLayersData([LayerDataServiceEntry.Gates, LayerDataServiceEntry.BusGates])
        gate = yield findGateInState(gateId)
    }
    return gate
}

export const layersDataSagas = [loadLayersDataSaga]
