import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import {
    childActionMatcher,
    takeEveryChildAction,
    throttleChildAction,
} from 'aos-helpers/src/helpers/Saga'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, timeRange } from 'aos-helpers/src/helpers/TimeRange'
import { PREFERENCES_LOCATION_CHANGED, SIGN_IN_SUCCESS } from 'aos-services/src/core/auth/actions'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { assureRingRailSync } from 'aos-services/src/core/layersData/sagas'
import {
    RestrictionLoadSuccessAction,
    RESTRICTIONS_LOAD_SUCCESS,
} from 'aos-services/src/core/restrictions/actions'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { mapService } from 'aos-services/src/services/map/mapService'
import { AosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { BBox } from 'aos-services/src/services/map/types/BBox'
import { fromRestriction } from 'aos-services/src/services/map/types/MapRestrictionImpl'
import { MapTask } from 'aos-services/src/services/map/types/MapTask'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import { tasksService } from 'aos-services/src/services/tasks/tasksService'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import {
    CLOSE_MAP,
    customizeMapAction,
    layerSyncRelatedActions,
    LOAD_MAP_POINTS_OF_INTEREST,
    loadMapEventsSuccessAction,
    LoadMapPointsOfInterestAction,
    loadMapRestrictionsSuccessAction,
    loadMapTasksSuccessAction,
    NAVIGATE_TO_MAP,
    NavigateToMapAction,
    RELOAD_MAP,
    reloadMapAction,
    SELECT_FEATURE,
    SelectFeatureAction,
} from 'aos-ui-map/src/core/actions'
import { navigateToMap } from 'aos-ui-map/src/core/sagas'
import { shouldSyncRingRail } from 'aos-ui-map/src/core/state'
import { push } from 'redux-first-history'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { Link } from '../Links'
import { State } from '../state'
import {
    AIRPORT_MAP_MAP_PARENT,
    airportMapMapParentAction,
    loadSelectionDetailsSuccessAction,
    SYNC_AIRPORT_MAP,
    SyncAirportMapAction,
} from './actions'

function* customizeMapAfterSignInSaga() {
    yield takeEvery([SIGN_IN_SUCCESS, PREFERENCES_LOCATION_CHANGED], function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        yield put(airportMapMapParentAction(customizeMapAction(siteLocation)))
    })
}

function* reloadMapSaga() {
    yield takeEveryChildAction(AIRPORT_MAP_MAP_PARENT, RELOAD_MAP, function* () {
        const eventsBBox: BBox = yield select((state: State) => state.airportMap.eventsBBox)
        const tasksBBox: BBox = yield select((state: State) => state.airportMap.tasksBBox)

        yield loadMapEventsForBBox(eventsBBox)
        yield loadMapTasksForBBox(tasksBBox)
    })
}

function* syncRingRailSaga() {
    yield takeEvery(
        childActionMatcher(AIRPORT_MAP_MAP_PARENT, layerSyncRelatedActions),
        function* () {
            yield assureRingRailSync(
                (state: State) => shouldSyncRingRail(state.airportMap),
                'airportMap',
            )
        },
    )
}

function* syncRingRailStopSaga() {
    yield takeEveryChildAction(AIRPORT_MAP_MAP_PARENT, CLOSE_MAP, function* () {
        yield assureRingRailSync(() => false, 'airportMap')
    })
}

function* selectFeatureSaga() {
    yield takeEveryChildAction<SelectFeatureAction>(
        AIRPORT_MAP_MAP_PARENT,
        SELECT_FEATURE,
        function* (action: SelectFeatureAction) {
            switch (action.payload.type) {
                case SelectedMapElementType.Event:
                    const event: AosEvent = yield call(
                        eventsService.loadEvent,
                        action.payload.payload.event.id,
                    )
                    yield put(loadSelectionDetailsSuccessAction(event))
                    break
                case SelectedMapElementType.Task:
                    const task: Task = yield call(
                        tasksService.getTaskById,
                        action.payload.payload.task.id,
                    )
                    yield put(loadSelectionDetailsSuccessAction(task))
                    break
                default:
                    yield put(loadSelectionDetailsSuccessAction(undefined))
                    break
            }
        },
    )
}

function* navigateToMapSaga() {
    yield takeEveryChildAction<NavigateToMapAction>(
        AIRPORT_MAP_MAP_PARENT,
        NAVIGATE_TO_MAP,
        function* (action: NavigateToMapAction) {
            yield navigateToMap(airportMapMapParentAction, action.payload)
            yield put(push(Link.Map))
        },
    )
}

export function* loadMapEventsForBBox(bbox: BBox) {
    const events: AosMapEvent[] = yield call(mapService.loadMapEvents, bbox)
    yield put(airportMapMapParentAction(loadMapEventsSuccessAction([events, bbox])))
}

function* loadMapPointsOfInterestSaga() {
    yield throttleChildAction<LoadMapPointsOfInterestAction>(
        500,
        AIRPORT_MAP_MAP_PARENT,
        LOAD_MAP_POINTS_OF_INTEREST,
        function* (action: LoadMapPointsOfInterestAction) {
            yield loadMapEventsForBBox(action.payload)
            yield loadMapTasksForBBox(action.payload)
        },
    )
}

export function* loadMapTasksForBBox(bbox: BBox) {
    const tasks: MapTask[] = yield call(mapService.loadMapTasks, bbox)
    yield put(airportMapMapParentAction(loadMapTasksSuccessAction([tasks, bbox])))
}

function* syncMapRestrictions() {
    yield takeEvery<RestrictionLoadSuccessAction>(RESTRICTIONS_LOAD_SUCCESS, function* (action) {
        const mapRestrictions = action.payload
            .filter(restriction =>
                isTimeWithin(dateTime(), timeRange(restriction.startDate, restriction.endDate)),
            )
            .map(fromRestriction)
        yield put(airportMapMapParentAction(loadMapRestrictionsSuccessAction(mapRestrictions)))
    })
}

function* syncEventsSts() {
    return (yield syncValue(
        sendToSyncRepository.stsRef(SendToSyncType.DASHBOARD),
        () => airportMapMapParentAction(reloadMapAction()),
        true,
    )) as Generator
}

function* syncAirportMapSaga() {
    yield takeEvery<SyncAirportMapAction>(
        SYNC_AIRPORT_MAP,
        function* (action: SyncAirportMapAction) {
            if (action.payload) {
                yield* firebaseService.onListener('airportMapEvents', syncEventsSts) as Generator
            } else {
                yield firebaseService.offListener('airportMapEvents')
            }
        },
    )
}

export const airportMapSagas = [
    customizeMapAfterSignInSaga,
    loadMapPointsOfInterestSaga,
    syncAirportMapSaga,
    reloadMapSaga,
    syncRingRailSaga,
    syncRingRailStopSaga,
    selectFeatureSaga,
    navigateToMapSaga,
    syncAirportMapSaga,
    syncMapRestrictions,
]
