import { enumRecord } from 'aos-helpers/src/helpers/Enum'
import { mapRecordValues } from 'aos-helpers/src/helpers/Record'
import {
    BaseStatusDashboardAction,
    SET_DASHBOARD_STATUSES,
} from 'aos-services/src/core/statusDashboard/actions'
import { statusDashboardBaseReducer } from 'aos-services/src/core/statusDashboard/reducer'
import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { DashboardItemStatuses } from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { sizeInRange } from 'aos-services/src/services/statusDashboard/types/DashboardSizes'
import { Breakpoint } from 'aos-ui/src/components/grid/ResponsiveGrid'
import { fromPairs } from 'lodash'
import { Layout, Layouts } from 'react-grid-layout'

import { DashboardItemState } from '../../services/statusDashboard/types/DashboardItemState'
import { DashboardItem, MyDashboardTab } from '../../services/statusDashboard/types/DashboardPreset'
import { CdmItemState } from '../../services/statusDashboard/types/itemStates/CdmItemState'
import { NewsFeedItemState } from '../../services/statusDashboard/types/itemStates/NewsFeedItemState'
import { PaxForecastItemState } from '../../services/statusDashboard/types/itemStates/PaxForecastItemState'
import { PaxItemState } from '../../services/statusDashboard/types/itemStates/PaxItemState'
import { statusDashboardConfig } from '../../views/statusDashboard/StatusDashboardConfig'
import {
    ADD_DASHBOARD_ITEM,
    CHANGE_SIZE_VARIANT,
    REMOVE_DASHBOARD_ITEM,
    SET_BAGGAGE_DELIVERY_FILTER,
    SET_CDM_FILTERS,
    SET_CURRENT_BREAKPOINT,
    SET_DELAYS_TAB_STATE,
    SET_FLIGHTS_TAB_STATE,
    SET_NEWS_FEED_FILTER,
    SET_OPERATIONAL_FORECAST_TAB_STATE,
    SET_PAX_FORECAST_FILTERS,
    SET_PAX_TAB,
    SET_PRESETS,
    SET_PUNCTUALITY_TAB_STATE,
    SET_QUEUEING_TIMES_FILTERS,
    SET_REGULARITY_FILTER,
    SET_TOBT_FILTERS,
    SET_WEATHER_TAB_STATE,
    StatusDashboardAction,
    TOGGLE_SIDEBAR,
    TRIGGER_CAROUSEL_CHANGE,
    UPDATE_LAYOUT,
} from './actions'
import { addDefaultItem } from './presets'
import { initialStatusDashboardState, StatusDashboardState } from './state'

const updatePresetFromLayout = (layouts: Layouts) => (items: DashboardItem[]) => {
    const positions = enumRecord(Breakpoint, breakpoint =>
        fromPairs(layouts[breakpoint].map(layout => [layout.i, layout] as [string, Layout])),
    )
    return items.map(item => ({
        ...item,
        layouts: mapRecordValues(item.layouts, (layout, breakpoint) => {
            const id = item.id.toString()
            return positions[breakpoint][id] || layout
        }),
    }))
}

const updatePresets = <T extends DashboardItemState>(
    state: StatusDashboardState,
    updateItems: f.Func1<DashboardItem<T>[], DashboardItem<T>[]>,
): StatusDashboardState => ({
    ...state,
    presets: mapRecordValues(state.presets, updateItems),
})

const updateItem = <T extends DashboardItemState>(
    state: StatusDashboardState,
    itemId: number,
    update: f.Func1<DashboardItem<T>, DashboardItem<T>>,
): StatusDashboardState => {
    const updateMatchingItem = (itemState: DashboardItem<T>) =>
        itemState.id === itemId ? update(itemState) : itemState
    return updatePresets(state, (items: DashboardItem[]) => items.map(updateMatchingItem))
}

const updateItemState = <T extends DashboardItemState>(
    state: StatusDashboardState,
    itemId: number,
    update: f.Func1<T, T>,
): StatusDashboardState => {
    const updateState = (itemState: DashboardItem<T>) => ({
        ...itemState,
        state: update(itemState.state),
    })
    return updateItem<T>(state, itemId, updateState)
}

const addItem = (
    state: StatusDashboardState,
    itemType: DashboardItemType,
): StatusDashboardState => ({
    ...state,
    presets: {
        ...state.presets,
        [MyDashboardTab.MyDashboard]: addDefaultItem(
            state.presets[MyDashboardTab.MyDashboard],
            itemType,
        ),
    },
})

const removeItem = (state: StatusDashboardState, id: number): StatusDashboardState =>
    updatePresets(state, (items: DashboardItem<DashboardItemState>[]) =>
        items.filter(item => item.id !== id),
    )

const setFilters = <T extends DashboardItemState>(
    state: StatusDashboardState,
    payload: [number, Partial<T>],
) =>
    updateItemState<DashboardItemState>(
        state,
        payload[0],
        (itemState: DashboardItemState): DashboardItemState => ({
            ...itemState,
            ...payload[1],
        }),
    )

const mergeDashboardItemStatuses = (statuses: DashboardItemStatuses) => {
    let tobtPrioritizedForCdmWidget
    if (
        statuses.TOBT &&
        (statuses.TOBT.status === DashboardItemMode.Warning ||
            statuses.TOBT.status === DashboardItemMode.Alert)
    ) {
        tobtPrioritizedForCdmWidget = statuses.TOBT
    } else {
        tobtPrioritizedForCdmWidget = statuses.CDM
    }
    return {
        ...statuses,
        [DashboardItemType.Cdm]: tobtPrioritizedForCdmWidget,
    }
}

export function statusDashboardReducer(
    state = initialStatusDashboardState,
    action: StatusDashboardAction,
): StatusDashboardState {
    switch (action.type) {
        case SET_PRESETS:
            return {
                ...state,
                presets: action.payload,
            }

        case UPDATE_LAYOUT:
            return {
                ...state,
                presets: {
                    ...state.presets,
                    [action.payload.tab]: updatePresetFromLayout(action.payload.layouts)(
                        state.presets[action.payload.tab],
                    ),
                },
            }

        case CHANGE_SIZE_VARIANT:
            return updateItem(
                state,
                action.payload[0],
                (itemState: DashboardItem<DashboardItemState>) => {
                    const sizeVariant = action.payload[1]
                    const sizeConfig =
                        statusDashboardConfig[itemState.state.type].sizes[sizeVariant]
                    if (!sizeConfig) {
                        return itemState
                    }
                    return {
                        ...itemState,
                        state: {
                            ...itemState.state,
                            size: sizeVariant,
                        },
                        layouts: mapRecordValues(itemState.layouts, layout => ({
                            ...layout,
                            ...sizeInRange(layout, sizeConfig.minSize, sizeConfig.maxSize),
                        })),
                    }
                },
            )
        // Pax
        case SET_PAX_TAB:
            return updateItemState(
                state,
                action.payload[0],
                (itemState: PaxItemState): PaxItemState => ({
                    ...itemState,
                    paxSecurityPoint: action.payload[1],
                }),
            )

        case SET_FLIGHTS_TAB_STATE:
        case SET_DELAYS_TAB_STATE:
        case SET_PUNCTUALITY_TAB_STATE:
        case SET_WEATHER_TAB_STATE:
        case SET_BAGGAGE_DELIVERY_FILTER:
        case SET_REGULARITY_FILTER:
        case SET_QUEUEING_TIMES_FILTERS:
        case SET_OPERATIONAL_FORECAST_TAB_STATE:
            return setFilters(state, action.payload)

        case SET_PAX_FORECAST_FILTERS:
            return updateItemState<PaxForecastItemState>(
                state,
                action.payload[0],
                (itemState: PaxForecastItemState): PaxForecastItemState => ({
                    ...itemState,
                    filters: { ...itemState.filters, ...action.payload[1] },
                }),
            )

        // News Feed
        case SET_NEWS_FEED_FILTER:
            return updateItemState(
                state,
                action.payload.id,
                (itemState: NewsFeedItemState): NewsFeedItemState => ({
                    ...itemState,
                    filters: action.payload.filters,
                }),
            )

        // CDM
        case SET_CDM_FILTERS: {
            return updateItemState(
                state,
                action.payload[0],
                (itemState: CdmItemState): CdmItemState => ({
                    ...itemState,
                    cdmMetricSubType: {
                        ...itemState.cdmMetricSubType,
                        ...action.payload[1],
                    },
                }),
            )
        }

        case SET_TOBT_FILTERS: {
            return updateItemState(
                state,
                action.payload[0],
                (itemState: CdmItemState): CdmItemState => ({
                    ...itemState,
                    ...action.payload[1],
                }),
            )
        }

        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: action.payload,
            }

        case SET_CURRENT_BREAKPOINT:
            return {
                ...state,
                currentBreakpoint: action.payload,
            }

        case ADD_DASHBOARD_ITEM:
            return addItem(state, action.payload)

        case REMOVE_DASHBOARD_ITEM:
            return removeItem(state, action.payload)

        case TRIGGER_CAROUSEL_CHANGE:
            return { ...state, carouselTrigger: action.payload }

        case SET_DASHBOARD_STATUSES:
            const newState = statusDashboardBaseReducer(state, action) as StatusDashboardState
            return {
                ...newState,
                statuses: mergeDashboardItemStatuses(newState.statuses),
            }

        default:
            return statusDashboardBaseReducer(state, action as BaseStatusDashboardAction)
    }
}
