import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    CommonTag,
    TaskTag,
} from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import { createSelector } from 'reselect'

import { ChecklistManagerStateAware } from '../state'

export const selectTemplatesForCurrentAirport = createSelector(
    currentUserSiteLocation,
    (state: ChecklistManagerStateAware) =>
        state.checklistManager.taskChecklistManager.taskChecklistTemplates,
    (siteLocation, templates) =>
        templates.filter(
            template => template.tag === siteLocation || (template.tag as TaskTag) === CommonTag,
        ),
)
