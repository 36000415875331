import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { selectContactListWithFilter } from 'aos-services/src/core/contactList/selectors'
import { createSelector } from 'reselect'

import { ContactListStateAware } from './state'

export const currentAirportSelector = createSelector(
    (state: ContactListStateAware) => state.contactList.currentAirport,
    currentUserSiteLocation,
    (currentAirport, currentUserSiteLocation) => currentAirport || currentUserSiteLocation,
)
export const searchValueSelector = (state: ContactListStateAware) => state.contactList.searchValue

export const filteredContactListSelector = createSelector(
    (state: ContactListStateAware) => state.contactList.contactList,
    currentAirportSelector,
    searchValueSelector,

    (contactList, currentAirport, search) =>
        selectContactListWithFilter(currentAirport, contactList, search),
)
