// http://wiki.openstreetmap.org/wiki/Bounding_Box
export type BBox = [number, number, number, number]

export const extendBBox = (bbox: BBox, factor: number): BBox => {
    const centerX = (bbox[0] + bbox[2]) / 2
    const centerY = (bbox[1] + bbox[3]) / 2
    const width = (bbox[2] - bbox[0]) * factor
    const height = (bbox[3] - bbox[1]) * factor
    return [centerX - width / 2, centerY - height / 2, centerX + width / 2, centerY + height / 2]
}

export const normalizeBBox = (bbox: BBox): BBox => [
    Math.max(bbox[0], -180),
    Math.max(bbox[1], -90),
    Math.min(bbox[2], 180),
    Math.min(bbox[3], 90),
]
