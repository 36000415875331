import {
    RegularitySelectorState,
    regularityTileSelector,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/regularity/regularity'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { RegularityItemState } from '../../../../services/statusDashboard/types/itemStates/RegularityItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { RegularityItem } from './partials/RegularityItem'
import { RegularityPieChart } from './RegularityPieChart'

type RegularityOwnProps = StatusDashboardItemCommonProps<RegularityItemState>

export class RegularityTileComponent extends PureComponent<
    RegularitySelectorState & RegularityOwnProps
> {
    private link = generatePath(Link.StatusDashboardPreset, {
        preset: DashboardProcessType.Operations,
    })

    public render() {
        const itemProps = pickDashboardItemProps(this.props)
        const { stats, noDataWarning } = this.props
        return (
            <RegularityItem
                itemProps={itemProps}
                noDataWarning={noDataWarning}
                link={this.link}
                syncId='overview'
            >
                <TileContainer>
                    <RegularityPieChart simple stat={stats} />
                </TileContainer>
            </RegularityItem>
        )
    }
}

export const RegularityTile = connect<RegularitySelectorState, {}, RegularityOwnProps>(
    regularityTileSelector,
    {},
)(RegularityTileComponent)
