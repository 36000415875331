import React, { PureComponent } from 'react'

export class TrainNoDirectionBgIcon extends PureComponent<TrainNoDirectionBgIconProps> {
    public render() {
        const { color, withBorder } = this.props

        return (
            <svg
                width='32'
                height='48'
                viewBox='0 0 32 48'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
            >
                <circle cx='15.844' cy='32.247' r='15.753' fill={color} fillRule='evenodd' />
                {withBorder && (
                    <circle
                        cx='15.844'
                        cy='32.247'
                        r='15.753'
                        fill='none'
                        stroke='#FFF'
                        strokeWidth='2'
                    />
                )}
            </svg>
        )
    }
}

interface TrainNoDirectionBgIconProps {
    color: string
    withBorder?: boolean
}
