import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosEventOrFeedIn } from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { SectionHeader } from 'aos-ui/src/components/text/SectionHeader'
import React, { FC, PropsWithChildren } from 'react'

import { EventDetails } from '../../eventEditor/EventDetails'

interface EventInfoProps {
    event: AosEventOrFeedIn
    withVisibilityField: boolean
    setDialogModeAction(view: DialogView): void
}

export const EventInfo: FC<PropsWithChildren<EventInfoProps>> = props => {
    const { event, withVisibilityField, setDialogModeAction } = props
    const previewOnMap = () => setDialogModeAction(DialogView.Map)
    return (
        <Box className='event-preview__info' fullHeight>
            <LightScrollbar>
                <Box padding={30}>
                    <SectionHeader title={translate('event-preview.event-info')} />
                    <EventDetails
                        event={event}
                        variant={ThemeVariant.White}
                        size={LabeledTextElementSize.Large}
                        withVisibilityField={withVisibilityField}
                        selectLocation={previewOnMap}
                    />
                    {props.children}
                </Box>
            </LightScrollbar>
        </Box>
    )
}
