import { VALUE_DOWN, VALUE_ESCAPE, VALUE_RETURN, VALUE_UP } from 'keycode-js'
import { KeyboardEvent, useRef } from 'react'

interface DropdownKeyboardConfig {
    itemsCount: number
    currentIndex: number
    close: () => void
    changeIndex: (index: number) => void
    select: (index: number) => void
}

export const useDropdownKeyboard = ({
    itemsCount,
    currentIndex,
    close,
    changeIndex,
    select,
}: DropdownKeyboardConfig) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const handleKeyDown = (event: KeyboardEvent) => {
        const key = event.key

        if (!itemsCount) {
            return
        }

        switch (key) {
            case VALUE_UP:
                event.preventDefault()
                if (currentIndex === 0) {
                    changeIndex(itemsCount - 1)
                } else {
                    changeIndex(currentIndex - 1)
                }
                break

            case VALUE_DOWN:
                event.preventDefault()
                changeIndex((currentIndex + 1) % itemsCount)
                break

            case VALUE_RETURN:
                event.preventDefault()
                if (currentIndex > -1) {
                    select(currentIndex)
                    close()
                }
                break

            case VALUE_ESCAPE:
                event.preventDefault()
                close()
                break
        }
    }

    return { inputRef, handleKeyDown }
}
