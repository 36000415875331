import { ChartContextProps } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisBase } from './AxisBase'
import { AxisProps } from './AxisProps'

export abstract class HorizontalAxis<T extends AxisDomain> extends AxisBase<T> {
    constructor(props: AxisProps<T> & ChartContextProps, private position: 'top' | 'bottom') {
        super(props)
    }

    protected get elementTranslate() {
        const { size, margins } = this.props
        const yOffset = this.position === 'bottom' ? size.height : 0
        const moveY = Math.max(yOffset + margins.top, 0)

        return `translate(0 ${moveY})`
    }

    protected renderAxisLine = () => {
        const { scale } = this.props
        const range = scale.range() as [number, number]

        return <line x1={range[0]} x2={range[1]} y2={0} />
    }

    protected renderUnit = () => null
}
