import { enumToClassModifier } from './Enum'

export enum BlockSize {
    XXTiny = 10,
    XTiny = 12,
    Tiny = 16,
    Small = 20,
    Std = 24,
    Large = 32,
    XLarge = 42,
    Header = 96,
    ModalIcon = 100,
    Status = 60,
    XXLarge = 50,
}

export function blockSizeClass(size: BlockSize) {
    return enumToClassModifier(BlockSize, 'block')(size)
}
