import { Coordinate } from 'ol/coordinate'
import { transform } from 'ol/proj'

import { Projection } from '../Projection'
import { LonLat } from './LonLat'

export const lonLatToCoordinate = (position: LonLat): Coordinate =>
    lonLatArrayToCoordinate([position.lon, position.lat])

export const coordinateToLonLat = (position: Coordinate): LonLat => {
    const lonLat = coordinateToLonLatArray(position)
    return { lon: lonLat[0], lat: lonLat[1] }
}

export const lonLatArrayToCoordinate = (position: [number, number]): Coordinate =>
    transform(position, Projection.WorldGeodeticSystem, Projection.Mercator)

export const coordinateToLonLatArray = (position: Coordinate): [number, number] =>
    transform(position, Projection.Mercator, Projection.WorldGeodeticSystem) as [number, number]
