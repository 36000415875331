import { FeedOutNotificationDto } from '../../../dataaccess/notifications/types/FeedOutNotificationDto'
import { EventRelatedNotificationImpl } from './EventRelatedNotificationImpl'
import { FEED_OUT, FeedOutNotification } from './FeedOutNotification'

export class FeedOutNotificationImpl
    extends EventRelatedNotificationImpl<FeedOutNotificationDto, typeof FEED_OUT>
    implements FeedOutNotification {
    constructor(public id: string, value: FeedOutNotificationDto, markAlwaysAsRead: boolean) {
        super(id, value, markAlwaysAsRead)
    }

    public get text() {
        return this.value.text
    }
}
