import { Color } from 'aos-ui-common/src/styles/Color'

const colors = [
    Color.PaleOrange,
    Color.Violet,
    Color.SeaBlue,
    Color.SoftMagenta,
    Color.LightGreen,
    Color.SoftBlue,
    Color.SoftGreen,
    Color.Orange,
    Color.Yellow,
    Color.Primary,
]

export const getColor = (index: number) => colors[index % colors.length]
