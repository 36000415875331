import { Unit } from 'aos-helpers/src/helpers/Unit'
import { TrendStats } from 'aos-services/src/services/common/types/TrendStats'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { DashboardPieChart } from '../../../common/DashboardPieChart'
import { PunctualityLegend } from '../PunctualityLegend'

interface PunctualityStatsSectionProps {
    stats: TrendStats
}

export const PunctualityStatsSection: FC<PunctualityStatsSectionProps> = props => {
    const { stats } = props

    const trendProps = {
        trend: stats.trend,
        value: stats.value,
        tooltipPrefix: 'dashboard.punctuality.trend.tooltip',
        unit: Unit.Percent,
    }

    return (
        <DashboardPieChart
            compact
            trend={trendProps}
            seriesColors={{ 0: Color.UiBlack3, 1: Color.Magenta }}
            data={[stats.value, 100 - stats.value]}
            legend={<PunctualityLegend />}
        />
    )
}
