import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { ChartSelectorState } from '../../../../core/tasks/reports/selectors/types/ChartSelectorState'
import { ReportsUnit } from '../../../../core/tasks/reports/state'
import { ReportMultiChart } from './ReportMultiChart'
import { ReportSingleChart } from './ReportSingleChart'

interface ReportChartProps {
    chartData: ChartSelectorState
    unit?: ReportsUnit
    getColor(index: number): Color
    rotateBottomLabels?: boolean
}

export const ReportChart: FC<ReportChartProps> = ({ chartData, ...props }) => {
    return (
        <Box height={500} bg={Color.UiBlack2} paddingRight={24} paddingLeft={16} paddingBottom={24}>
            {'dataSubKeys' in chartData ? (
                <ReportMultiChart chartData={chartData} {...props} />
            ) : (
                <ReportSingleChart chartData={chartData} {...props} />
            )}
        </Box>
    )
}
