import { literal } from 'aos-helpers/src/helpers/Type'

export type AosEventScenarioIcon = typeof AosEventScenarioIcon[keyof typeof AosEventScenarioIcon]

export const AosEventScenarioIcon = {
    AircraftStands: literal('AircraftStands'),
    AirfiledMaintanace: literal('AirfiledMaintanace'),
    AirlineGroundHandling: literal('AirlineGroundHandling'),
    ApronBuses: literal('ApronBuses'),
    ApronConstruction: literal('ApronConstruction'),
    Arrivals: literal('Arrivals'),
    AtcReducedCapacity: literal('AtcReducedCapacity'),
    AutomatedBorderControl: literal('AutomatedBorderControl'),
    BaggageArrival: literal('BaggageArrival'),
    BaggageClaim: literal('BaggageClaim'),
    BaggageDepartures: literal('BaggageDepartures'),
    BaggageGeneralInfo: literal('BaggageGeneralInfo'),
    BaggageHandling: literal('BaggageHandling'),
    BaggageTransfer: literal('BaggageTransfer'),
    BorderControl: literal('BorderControl'),
    Bus: literal('Bus'),
    CheckIn: literal('CheckIn'),
    CheckinAutomat: literal('CheckinAutomat'),
    Construction: literal('Construction'),
    DeIcing: literal('DeIcing'),
    DivergedFlights: literal('DivergedFlights'),
    Electricity: literal('Electricity'),
    Elevators: literal('Elevators'),
    Enf: literal('Enf'),
    Fog: literal('Fog'),
    Gates: literal('Gates'),
    HeavyDelays: literal('HeavyDelays'),
    Hel: literal('Hel'),
    Hem: literal('Hem'),
    Hyv: literal('Hyv'),
    InfoManagement: literal('InfoManagement'),
    Info: literal('Info'),
    Ivl: literal('Ivl'),
    Joe: literal('Joe'),
    Jyv: literal('Jyv'),
    Kaj: literal('Kaj'),
    Kao: literal('Kao'),
    Kem: literal('Kem'),
    Kev: literal('Kev'),
    Kok: literal('Kok'),
    Ktt: literal('Ktt'),
    Kuo: literal('Kuo'),
    LandSideConstruction: literal('LandSideConstruction'),
    Lightning: literal('Lightning'),
    Lpp: literal('Lpp'),
    Lvp: literal('Lvp'),
    Maintenance: literal('Maintenance'),
    Media: literal('Media'),
    Mhq: literal('Mhq'),
    Mik: literal('Mik'),
    NetworkGeneralInfo: literal('NetworkGeneralInfo'),
    Other: literal('Other'),
    Oul: literal('Oul'),
    ParkingArea: literal('ParkingArea'),
    PassangerBridges: literal('PassangerBridges'),
    PassengerService: literal('PassengerService'),
    PassengerServices: literal('PassengerServices'),
    PaxGeneralInfo: literal('PaxGeneralInfo'),
    Por: literal('Por'),
    Prm: literal('Prm'),
    Rain: literal('Rain'),
    RescueService: literal('RescueService'),
    Roads: literal('Roads'),
    Rvn: literal('Rvn'),
    Rwy: literal('Rwy'),
    SafetySecurity: literal('SafetySecurity'),
    SecurityGates: literal('SecurityGates'),
    Services: literal('Services'),
    Sjy: literal('Sjy'),
    SlipperyApron: literal('SlipperyApron'),
    Snow: literal('Snow'),
    Svl: literal('Svl'),
    Taxi: literal('Taxi'),
    Taxiways: literal('Taxiways'),
    TerminalConstruction: literal('TerminalConstruction'),
    TerminalFacilities: literal('TerminalFacilities'),
    TerminalPeakTimes: literal('TerminalPeakTimes'),
    Tku: literal('Tku'),
    Tmp: literal('Tmp'),
    TrafficStatus: literal('TrafficStatus'),
    Train: literal('Train'),
    Transfer: literal('Transfer'),
    TurnaroundGeneralInfo: literal('TurnaroundGeneralInfo'),
    Uti: literal('Uti'),
    Vaa: literal('Vaa'),
    Vrk: literal('Vrk'),
    Wa: literal('Wa'),
    WaterDistribution: literal('WaterDistribution'),
    Wind: literal('Wind'),
    World: literal('World'),
}
