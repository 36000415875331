import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import { Layouts, Responsive } from 'react-grid-layout'
import { createBreakpoint } from 'react-use'
import styled from 'styled-components'

interface ResponsiveGridProps {
    layouts?: Layouts
    width: number
    className?: string
    onLayoutChange(allLayouts: Layouts): void
    onBreakpointChange(b: Breakpoint): void
}

export enum Breakpoint {
    lg = 'lg',
    md = 'md',
    sm = 'sm',
}

export type BreakpointValues = Record<Breakpoint, number>

export const dashboardGridColumns: BreakpointValues = { lg: 12, md: 8, sm: 4 }

export const breakpoints: BreakpointValues = { lg: 1200, md: 768, sm: 0 }

export const useBreakpoint = createBreakpoint(breakpoints)

const ResponsiveGridComponent: FCWithChildren<PropsWithChildren<ResponsiveGridProps>> = ({
    layouts,
    width,
    onLayoutChange,
    onBreakpointChange,
    children,
    className,
}) => (
    <Responsive
        className={className}
        layouts={layouts}
        cols={dashboardGridColumns}
        breakpoints={breakpoints}
        rowHeight={70}
        margin={[24, 24]}
        containerPadding={[24, 24]}
        autoSize={false}
        compactType='vertical'
        onLayoutChange={(_l, all) => onLayoutChange(all)}
        onBreakpointChange={onBreakpointChange}
        isResizable
        isDraggable
        draggableHandle='.handler'
        width={width}
    >
        {children}
    </Responsive>
)

export const ResponsiveGrid = styled(ResponsiveGridComponent)`
    position: relative;
    transition: height time(fast) ease;

    .react-grid-item {
        transition: all 0.2s ease;
        transition-property: left, top;
    }

    .cssTransforms {
        transition-property: transform;
    }

    .resizing {
        z-index: 1;
        will-change: width, height;
    }

    .react-draggable-dragging {
        transition: none;
        z-index: 3;
        will-change: transform;
    }

    .react-grid-placeholder {
        transition-duration: 100ms;
        border: 1px solid ${Color.Primary};
        border-radius: 3px;
        background-color: ${rgba(Color.Primary, 0.3)};
        z-index: 2;
        user-select: none;
    }

    .react-resizable-handle {
        position: absolute;
        right: 4px;
        bottom: 4px;
        width: 24px;
        height: 24px;
        cursor: se-resize;
    }

    .react-resizable-handle::after {
        display: block;
        position: absolute;
        right: 4px;
        bottom: 4px;
        width: 0;
        height: 0;
        border-bottom: 12px solid ${Color.UiBlack3};
        border-left: 12px solid transparent;
        content: '';
    }
`
