import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, timeRangeForDay } from 'aos-helpers/src/helpers/TimeRange'
import { compact, identity } from 'lodash'

import { filterToRoundedTimeRange } from '../../../../airportStatus/base/types/TimeRangeFilter'
import {
    PaxForecast,
    PaxForecastPoint,
} from '../../../../airportStatus/paxForecast/types/PaxForecast'
import { FilterOptionAll, StringFilterWithAllOption } from '../common'
import { PaxForecastArrivalDepartureFilter } from './PaxForecastArrivalDepartureFilter'
import { PaxForecastDayViewType } from './PaxForecastDayViewType'
import { PaxForecastLocalOrTransferFilter } from './PaxForecastLocalOrTransferFilter'
import { PaxForecastTimeRange } from './PaxForecastTimeRange'

export interface PaxForecastFilters {
    timeRange: PaxForecastTimeRange
    // epoch milliseconds instead of moment - storing objects in state is not recommended,
    // especially with persisting to localstorage
    customDate: number
    dayViewType: PaxForecastDayViewType
    localOrTransfer: PaxForecastLocalOrTransferFilter
    arrivalDeparture: PaxForecastArrivalDepartureFilter
    terminal: StringFilterWithAllOption
}

export const defaultPaxForecastFilters: PaxForecastFilters = {
    timeRange: PaxForecastTimeRange.Today,
    customDate: dateTime().valueOf(),
    dayViewType: PaxForecastDayViewType.Hours,
    localOrTransfer: FilterOptionAll.All,
    arrivalDeparture: FilterOptionAll.All,
    terminal: FilterOptionAll.All,
}

const getTimeRange = (filters: PaxForecastFilters, currentDay: DateTime) => {
    if (filters.timeRange === PaxForecastTimeRange.Custom) {
        return timeRangeForDay(dateTime(filters.customDate))
    }
    return filterToRoundedTimeRange(filters.timeRange, currentDay, 'days')
}

export const getApplicablePaxForecastFilters = ({
    timeRange,
    dayViewType,
}: PaxForecastFilters): (keyof PaxForecastFilters)[] => {
    const showDayViewTab = [PaxForecastTimeRange.Today, PaxForecastTimeRange.Custom].includes(
        timeRange,
    )
    const showCustomDatePicker = timeRange === PaxForecastTimeRange.Custom
    const isNext6Weeks = PaxForecastTimeRange.Next6Weeks === timeRange
    const showArrDepTab = isNext6Weeks || dayViewType === PaxForecastDayViewType.TimeOfDay
    const showLocalTransferTab = isNext6Weeks || dayViewType === PaxForecastDayViewType.Hours

    return compact([
        'timeRange',
        showDayViewTab && 'dayViewType',
        showCustomDatePicker && 'customDate',
        showArrDepTab && 'arrivalDeparture',
        showLocalTransferTab && 'localOrTransfer',
        'terminal',
    ])
}
export const filterPaxForecast = (
    filters: PaxForecastFilters,
    paxForecast: PaxForecast,
    today: DateTime,
) => {
    const applicableFilters = getApplicablePaxForecastFilters(filters)
    const timeRange = getTimeRange(filters, today)
    const filterTimeRange = (p: PaxForecastPoint) => isTimeWithin(p.time, timeRange)
    const filterArrivalOrDeparture = (p: PaxForecastPoint) =>
        filters.arrivalDeparture === FilterOptionAll.All ||
        p.arrivalOrDeparture === filters.arrivalDeparture
    const filterLocalOrTransfer = (p: PaxForecastPoint) =>
        filters.localOrTransfer === FilterOptionAll.All ||
        p.localOrTransfer === filters.localOrTransfer
    const filterTerminal = (p: PaxForecastPoint) =>
        filters.terminal === FilterOptionAll.All || p.terminal === filters.terminal

    return paxForecast
        .filter(filterTimeRange)
        .filter(
            applicableFilters.includes('arrivalDeparture') ? filterArrivalOrDeparture : identity,
        )
        .filter(applicableFilters.includes('localOrTransfer') ? filterLocalOrTransfer : identity)
        .filter(applicableFilters.includes('terminal') ? filterTerminal : identity)
}
