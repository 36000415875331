import { CommonAuthState, initialCommonAuthState } from 'aos-services/src/core/auth/state'

export interface AuthStateAware {
    auth: AuthState
}

export interface AuthState extends CommonAuthState {}

export const initialAuthState: AuthState = {
    ...initialCommonAuthState,
}
