import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { formatCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { taskSeverityColorsMapping } from 'aos-services/src/services/common/types/AosSeverity'
import { assigneesLabel } from 'aos-services/src/services/tasks/types/task/BaseTask'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { translateTaskTitle } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BorderedChip } from 'aos-ui/src/components/chip/BorderedChip'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import { DarkListItemIconsSection } from 'aos-ui/src/components/darkListItem/DarkListItemIconsSection'
import { DarkListItemTextBlock } from 'aos-ui/src/components/darkListItem/DarkListItemTextBlock'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { TaskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import React, { FC } from 'react'
import styled from 'styled-components'

interface TasksListItemProps {
    task: TaskSnapshot
    isSelected?: boolean
    onOpen(): void
}

export const TasksListItem: FC<TasksListItemProps> = ({ task, onOpen, isSelected }) => (
    <DarkListItem
        borderColor={taskSeverityColorsMapping[task.severity]}
        onClick={onOpen}
        marginBottom={20}
        paddingVertical={16}
        paddingLeft={20}
        paddingRight={16}
        cursor='pointer'
        selected={isSelected}
        row
    >
        <TaskIcon category={task.category} iconVariant={IconVariant.White} />
        <Text
            size={14}
            color={task.overdue ? Color.Yellow : Color.White}
            overflow='hidden'
            lineHeight='large'
            flex={1}
            paddingLeft={8}
            ellipsis
            textNoWrap
            data-timeline-text
        >
            {task.title}
        </Text>

        {task.hasRecurrence && (
            <Icon
                svg={SvgIcon.TaskRecurrence}
                iconVariant={IconVariant.White}
                iconSize={BlockSize.Std}
            />
        )}
        <Box paddingHorizontal={8}>
            <BorderedChip marginHorizontal={8}>{translateTaskTitle(task.status)}</BorderedChip>
        </Box>
        <Section centered>
            <DarkListItemTextBlock
                title={formatCalendarTime(task.startTime)}
                subtitle={assigneesLabel(task.assignees)}
                isOverdue={task.overdue}
            />
        </Section>
        <DarkListItemIconsSection
            config={[
                [SvgIcon.Location, task.hasLocation],
                [SvgIcon.Pictures, task.attachmentsLength],
                [SvgIcon.Checklist, task.hasChecklist],
            ]}
        />
    </DarkListItem>
)

const Section = styled(Box)`
    min-width: 180px;
`
