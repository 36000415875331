import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translateParts } from 'aos-helpers/src/helpers/translations/Translations'
import { BimLayersState } from 'aos-services/src/core/bimLayersData/state'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { LayersVisibility } from 'aos-services/src/services/map/types/LayersVisibility'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { PickLocationInfo } from 'aos-ui/src/components/form/ui/PickLocationInfo'
import { ValidationInfo } from 'aos-ui/src/components/form/ui/ValidationInfo'
import {
    HeaderFilterItem,
    HeaderFilterVariant,
} from 'aos-ui/src/components/header/HeaderFilterItem'
import { BackButtonTitleSection } from 'aos-ui/src/components/modal/BackButtonTitleSection'
import { DialogView, MapAwareModal } from 'aos-ui/src/components/modal/MapAwareModal'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { MapState } from 'aos-ui-map/src/core/state'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import { ModalMapHeader } from './header/ModalMapHeader'

export interface EventModalsDispatchProps {
    hideAction(): void
    toggleModeAction?(): void
    submitForm(): void
    // map
    saveLocation(): void
    setLayersVisibility(m: LayersVisibility): void
    switchMap(m: MapVariant): void
    setUserLayersVisibilityAction(v: string[]): void
    changeFloorLayerVisibility(v: number): void
    resetSelectedLocation?(): void
}

export interface EventModalsProps {
    form: FormValidation<any>
    mapState: MapState
    isOpen: boolean
    isLoading?: boolean
    keyPrefix: string
    modalKind: ModalKind
    hasPickedLocation: boolean
    bimLayers: BimLayersState
    userLayerVisibility: UserLayerVisibilityState
    customFooter?: ReactNode

    map(props: ChildProps): ReactNode
    content(props: ChildProps): ReactNode
}

interface ChildProps {
    setView(v: DialogView): void
}

export const MapFormAwareModal: FC<EventModalsProps & EventModalsDispatchProps> = ({
    isOpen,
    isLoading,
    hideAction,
    modalKind,
    mapState,
    form,
    toggleModeAction,
    submitForm,
    saveLocation,
    keyPrefix,
    map,
    content,
    switchMap,
    hasPickedLocation,
    bimLayers,
    userLayerVisibility,
    setUserLayersVisibilityAction,
    changeFloorLayerVisibility,
    resetSelectedLocation,
    customFooter,
}) => {
    const [view, setView] = useState<DialogView>(DialogView.Content)

    useEffect(() => {
        setView(DialogView.Content)
    }, [isOpen])

    const t = (v: string) => translateParts(keyPrefix, v)

    const renderModeSwitch = () => {
        if (!toggleModeAction) {
            return null
        }

        return (
            <Box paddingLeft={20}>
                <HeaderFilterItem
                    variant={HeaderFilterVariant.Small}
                    selected={false}
                    onClick={toggleModeAction}
                >
                    {t('toggle-mode')}
                </HeaderFilterItem>
            </Box>
        )
    }

    const onSave = () => {
        saveLocation()
        setView(DialogView.Content)
    }

    const contentHeader = () => (
        <Box alignItems='baseline' row>
            {t('title')}
            {renderModeSwitch()}
        </Box>
    )

    const mapHeader = () => (
        <BackButtonTitleSection onClick={() => setView(DialogView.Content)}>
            <ModalMapHeader
                mapVariant={mapState.variant}
                onChangeVariant={switchMap}
                mapFilter
                location={mapState.siteLocationCustomization}
                bimLayers={bimLayers}
                userLayersVisibility={userLayerVisibility}
                setUserLayersVisibilityAction={setUserLayersVisibilityAction}
                changeFloorLayerVisibility={changeFloorLayerVisibility}
            >
                {t('modal-title')}
            </ModalMapHeader>
        </BackButtonTitleSection>
    )

    const contentFooter = () => (
        <Box row fullWidth columnGap={12}>
            <ValidationInfo pristine={form.pristine} valid={form.valid} />
            {customFooter}
            <FormButton label={t('action-button')} onClick={submitForm} />
        </Box>
    )

    const mapFooter = () => (
        <Box row fullWidth>
            <PickLocationInfo />
            <GreyTextButton
                label={t('map-cancel')}
                onClick={() => {
                    setView(DialogView.Content)
                    resetSelectedLocation && resetSelectedLocation()
                }}
            />
            <FormButton label={t('map-save')} onClick={onSave} disabled={!hasPickedLocation} />
        </Box>
    )

    return (
        <MapAwareModal
            id='create-event-modal'
            isOpen={isOpen}
            modalKind={modalKind}
            closeAction={hideAction}
            view={view}
            mapFooter={mapFooter()}
            mapHeader={mapHeader()}
            contentHeader={contentHeader()}
            contentFooter={contentFooter()}
            content={content({ setView })}
            map={map({ setView })}
            isLoading={isLoading}
        />
    )
}
