import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'

import { RescueServiceTask, RescueServiceTaskType } from '../task/RescueServiceTask'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface RescueServiceTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.RescueService
    address?: string
    aircraftRegistration?: string
    taskType?: RescueServiceTaskType
    units: RescueServiceUnitPayload[]
    additionalUnits?: string
}

export const initialRescueServiceTaskPayload: RescueServiceTaskFormPayload = {
    type: TaskType.RescueService,
    ...initialBaseTaskPayload,
    units: [],
}

export interface RescueServiceTaskPayloadValidation extends BaseTaskPayloadValidation {
    taskType: boolean
    units: Array<{
        name: boolean
        numberOfWorkers: boolean
    }>
}

export const validateRescueServiceTask = (
    t: RescueServiceTaskFormPayload,
): RescueServiceTaskPayloadValidation => ({
    ...baseValidateTask(t),
    taskType: isNotEmpty(t.taskType),
    units: t.units.map(u => ({
        name: isNotEmpty(u.name),
        numberOfWorkers: isNotEmpty(u.numberOfWorkers),
    })),
})

export const rescueServiceTaskFormPayloadFromTask = (
    t: RescueServiceTask,
): RescueServiceTaskFormPayload => ({
    type: TaskType.RescueService,
    ...baseTaskFormPayloadFromTask(t),
    address: t.address,
    aircraftRegistration: t.aircraftRegistration,
    taskType: t.taskType,
    units: t.units,
    additionalUnits: t.additionalUnits,
})

export interface RescueServiceUnitPayload {
    name?: string
    numberOfWorkers?: number
}
