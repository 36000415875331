import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { ResponsiveWrapper } from 'aos-ui/src/components/ui/ResponsiveWrapper'
import React, { isValidElement } from 'react'

import { RoundTabsList } from './RoundTabsList'
import { RoundTabDefinition, RoundTabsProps } from './RoundTabsProps'

export interface MultiRoundTabsProps<T extends number | string | undefined> {
    items: RoundTabDefinition<T>[]
}

export const MultiRoundTabs = <T extends number | string | undefined>(
    props: MultiRoundTabsProps<T>,
) => {
    const isNotLast = (index: number) => {
        return index !== props.items.length - 1
    }
    const tabsAsDropdown = () => <Box row>{props.items.map(renderTabAsDropdown)}</Box>
    const renderTabAsDropdown = (child: RoundTabDefinition<T>, index: number) => {
        if (!child) {
            return null
        }

        if (isValidElement(child)) {
            return child
        }

        if ((child as RoundTabsProps<T>).isAlwaysExpanded) {
            return renderTabAsList(child, index)
        }
        const roundTabProps = child as RoundTabsProps<T>
        return (
            <Box key={index} marginRight={isNotLast(index) ? 30 : 0}>
                <Dropdown
                    variant={DropdownVariant.BlackGrey}
                    value={roundTabProps.value}
                    width={roundTabProps.width || DropdownWidth.Auto}
                    items={roundTabProps.items}
                    valueRenderer={roundTabProps.formatter}
                    onChange={roundTabProps.onChange}
                    tooltip={roundTabProps.tooltip}
                />
            </Box>
        )
    }

    const renderTabAsList = (child: RoundTabDefinition<T>, index: number) => {
        if (!child) {
            return null
        }
        if (isValidElement(child)) {
            return child
        }
        const roundTabProps = child as RoundTabsProps<T>

        return (
            <RoundTabsList
                paddingRight={isNotLast(index) ? 20 : 2}
                key={index}
                value={roundTabProps.value}
                items={roundTabProps.items}
                formatter={roundTabProps.formatter}
                onChange={roundTabProps.onChange}
                tooltip={roundTabProps.tooltip}
            />
        )
    }
    return (
        <ResponsiveWrapper
            height={28}
            seleniumLocation='round-tabs'
            alternativeContent={tabsAsDropdown}
        >
            <Box row flex={1}>
                {props.items.map(renderTabAsList)}
            </Box>
        </ResponsiveWrapper>
    )
}
