import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import React, { FC } from 'react'
import { Redirect, Route } from 'react-router'

import { Link } from '../../core/Links'
import { FodNotes } from '../fod/FodNotes'
import { Firefighters } from './firefighters/Firefighters'
import { TasksListPreview } from './list/TasksListContainer'
import { TasksReports } from './reports/TasksReports'
import { ShiftNotes } from './shiftNotes/ShiftNotes'
import { TasksTimeline } from './timeline/TasksTimeline'

export const TasksRouter: FC = () => {
    return (
        <Route
            path={Link.Tasks}
            render={() => (
                <>
                    <Route path={`${Link.Tasks}/`} exact>
                        <Redirect to={Link.TasksTimeline} />
                    </Route>

                    <Route exact path={Link.TasksTimeline}>
                        <TasksTimeline />
                    </Route>

                    <Route path={`${Link.TasksList}/`} exact>
                        <Redirect to={`${Link.TasksList}/${TaskFilter.All}`} />
                    </Route>
                    <Route path={Link.TasksListMatch}>
                        {p =>
                            p.match?.params.filter ? (
                                <TasksListPreview filter={p.match?.params.filter as TaskFilter} />
                            ) : null
                        }
                    </Route>

                    <Route exact path={Link.TasksShiftNotes}>
                        <ShiftNotes />
                    </Route>

                    <Route exact path={Link.TasksFireFighters}>
                        <Firefighters />
                    </Route>

                    <Route exact path={Link.TasksReports}>
                        <TasksReports />
                    </Route>

                    <Route exact path={Link.FOD}>
                        <FodNotes />
                    </Route>
                </>
            )}
        />
    )
}
