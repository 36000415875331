import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'

import { eventsHelpConfig } from '../../help/events/eventsHelpConfig'
import { StatusDashboardItemConfig } from '../../StatusDashboardItemConfig'
import { Events } from './Events'
import { EventsTile } from './EventsTile'

export const eventsConfig: StatusDashboardItemConfig = {
    sizes: {
        [DashboardItemSizeVariant.Small]: {
            minSize: { w: 4, h: 4 },
            maxSize: { w: 6, h: 12 },
        },
    },
    renderer: Events,
    tileRenderer: EventsTile,
    helpConfig: eventsHelpConfig,
}
