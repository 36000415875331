import { AttachmentType } from 'aos-services/src/services/attachments/types/AttachmentType'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

interface PreviewItemProps {
    attachment: UploadedAttachment
}

export const PreviewItem: FCWithChildren<PreviewItemProps> = ({ attachment }) => {
    if (attachment.type) {
        switch (attachment.type) {
            case AttachmentType.jpeg:
            case AttachmentType.jpg:
            case AttachmentType.png:
                return <Max80 as='img' src={attachment.link} />
            case AttachmentType.mp4:
                return <Max80 as='video' src={attachment.link} controls autoPlay />
            case AttachmentType.pdf:
                return <Fixed80 as='iframe' frameBorder={0} src={`${attachment.link}#toolbar=0`} />
        }
    }

    return <Max80 as='img' src={attachment.link} />
}

const Max80 = styled.div`
    max-width: 80vw;
    max-height: 80vh;
`

const Fixed80 = styled.div`
    width: 80vw;
    height: 80vh;
`
