import styled from 'styled-components'

interface RotatedProps {
    angle: number
    animated?: boolean
}

export const Rotated = styled.div<RotatedProps>`
    transition: transform 0.3s ease-in-out;
    transform: rotate(${p => p.angle}deg);
`
