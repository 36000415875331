import { Color } from 'aos-ui-common/src/styles/Color'

export enum AosUserType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    MANUAL = 'MANUAL',
}

export const mapAosUserTypeToColor: Record<AosUserType, Color> = {
    [AosUserType.EXTERNAL]: Color.Green,
    [AosUserType.INTERNAL]: Color.Primary,
    [AosUserType.MANUAL]: Color.UiBlack4,
}
