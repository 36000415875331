import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import React from 'react'

import { AxisBase } from './AxisBase'

const axisTickPadding = 10
const axisTickSize = 12

class AxisLeftComponent<T extends AxisDomain> extends AxisBase<T> {
    protected gElementClass: string = 'axis--left'

    protected get elementTranslate() {
        const { margins } = this.props

        return `translate(${margins.left} ${0})`
    }

    protected renderAxisLine = () => {
        const { scale } = this.props
        const range = scale.range() as [number, number]

        return <line className='' x2={0} y1={range[0]} y2={range[1]} />
    }

    protected renderTick = (tick: T, index: number) => {
        const { scale, size, axisConfig } = this.props

        const { tickClass, tickSize, tickOffset, tickFormat } = this.getTickConfig(tick, {
            defaultPadding: axisTickPadding,
            defaultTickSize: axisTickSize,
        })

        const tickOrTickGridSize =
            axisConfig.showGrids === false ? tickSize : size.width + tickSize * 2

        const yTickOffset = scale.bandwidth ? scale.bandwidth() / 2 : 0
        const yPos = (scale(tick) || 0) - yTickOffset

        return (
            <g className={tickClass} key={index} transform={`translate(${0} ${yPos})`}>
                <line x2={tickOrTickGridSize} transform={`translate(${-tickSize} 0)`} />
                <text x={-tickOffset} dy='0.32em' textAnchor='end'>
                    {index === 0 && axisConfig.unit && this.renderUnit(axisConfig.unit)}
                    {tickFormat(tick)}
                </text>
            </g>
        )
    }

    private renderUnit = (unit: string) => (
        <tspan className='axis-unit'>
            {unit}
            {'　'}
        </tspan>
    )
}

export const AxisLeft = withChartContext(AxisLeftComponent)
