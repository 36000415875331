import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { Layer } from 'ol/layer'
import { FC, useContext, useEffect, useState } from 'react'

import { BimMapContext } from '../BimMapContext'
import { layerIdKey } from '../OpenlayersMapContext'

export interface BimVectorLayerProps {
    mapLayerId: string
    isVisible: boolean
    layerId?: string
    zIndex: number
}

export const BimVectorLayer: FC<BimVectorLayerProps> = ({
    mapLayerId,
    isVisible,
    layerId,
    zIndex,
}) => {
    const mapContext = useContext(BimMapContext)
    const [layer, setLayer] = useState<Layer | undefined>(undefined)

    const loadBimLayer = async () => {
        try {
            const olLayer = await mapContext.bim.addMapLayerAsVector(mapLayerId)
            olLayer.set(layerIdKey, layerId)
            olLayer.setZIndex(zIndex)
            olLayer.setVisible(isVisible)
            setLayer(olLayer)
        } catch (error) {
            logger.handleError(error as Error)
        }
    }

    useEffect(() => {
        ;(async () => {
            await loadBimLayer()
        })()
        return () => {
            if (layer) {mapContext.bim.removeMapLayer(mapLayerId)}
        }
    }, [])

    return null
}
