import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { WeatherMetric } from './WeatherMetric'

interface WindDirectionProps {
    value?: number
}

export const WindDirection: FC<WindDirectionProps> = props => (
    <WeatherMetric
        label={translate('dashboard.weather.wind-direction')}
        value={props.value}
        topUnit={translate('dashboard.weather.unit-tabs.deg')}
        unitClass='dashboard-weather__metric__unit--degr'
        iconStyle={{ transform: `rotate(${(props.value || 0) - 180}deg)` }}
    >
        <Icon
            svg={SvgIcon.WeatherWindDirection}
            iconSize={BlockSize.Large}
            iconVariant={IconVariant.White}
        />
    </WeatherMetric>
)
