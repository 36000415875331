import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, select, takeEvery } from 'redux-saga/effects'

import { operationalForecastRepository } from '../../../dataaccess/operationalForecast/operationalForecastRepository'
import { OperationalForecastDto } from '../../../dataaccess/operationalForecast/types/OperationalForecastDto'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import { operationalForecastService } from '../../../services/operationalForecast/operationalForecastService'
import { PREFERENCES_LOCATION_CHANGED } from '../../auth/actions'
import { currentUserSiteLocation } from '../../auth/state'
import {
    OPERATIONAL_FORECAST_SYNC,
    OPERATIONAL_FORECAST_SYNC_STOP,
    OperationalForecastSyncAction,
    OperationalForecastSyncStopAction,
    operationalForecastSyncSuccessAction,
    SAVE_OPERATIONAL_FORECAST,
    SaveOperationalForecastAction,
} from './actions'

function* syncTask() {
    const location: AosAirport = yield select(currentUserSiteLocation)
    yield syncValue(
        operationalForecastRepository.operationalForecastRef(location),
        (t: [string, OperationalForecastDto]) =>
            operationalForecastSyncSuccessAction(
                operationalForecastService.itemForecastFromDto(t[1]),
            ),
        false,
    )
}

function* operationalForecastSyncSaga() {
    yield takeEvery<OperationalForecastSyncAction>(
        OPERATIONAL_FORECAST_SYNC,
        function* (action: OperationalForecastSyncAction) {
            yield* firebaseService.onListener(
                'operationalForecast',
                syncTask,
                action.payload,
            ) as Generator
        },
    )
}

function* operationalForecastLocationChangeSaga() {
    yield takeEvery(PREFERENCES_LOCATION_CHANGED, function* () {
        yield firebaseService.restartListener('operationalForecast', syncTask)
    })
}

function* operationalForecastSyncStopSaga() {
    yield takeEvery<OperationalForecastSyncStopAction>(
        OPERATIONAL_FORECAST_SYNC_STOP,
        function* (action: OperationalForecastSyncStopAction) {
            yield firebaseService.offListener('operationalForecast', action.payload)
        },
    )
}

function* saveOperationalForecastSaga() {
    yield takeEvery<SaveOperationalForecastAction>(
        SAVE_OPERATIONAL_FORECAST,
        function* (action: SaveOperationalForecastAction) {
            const location: AosAirport = yield select(currentUserSiteLocation)
            yield call(operationalForecastService.save, location, action.payload)
        },
    )
}

export const operationalForecastSagas = [
    operationalForecastSyncSaga,
    operationalForecastLocationChangeSaga,
    operationalForecastSyncStopSaga,
    saveOperationalForecastSaga,
]
