import { Box, CenteredBox } from 'aos-ui/src/components/base/Box'
import { Rotating } from 'aos-ui/src/components/base/Rotating'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import spinnerImg from './assets/spinner.png'
import spinnerBlueImg from './assets/spinner-blue.png'

interface SpinnerProps {
    onLight?: boolean
}

export const Spinner: FCWithChildren<SpinnerProps> = props => (
    <CenteredBox absolute>
        <Rotating>
            <ImgBox as='img' rect={25} src={props.onLight ? spinnerBlueImg : spinnerImg} />
        </Rotating>
    </CenteredBox>
)

const ImgBox = styled(Box)`
    display: block;
`
