import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { mapChecklistUnitToUserRole } from 'aos-services/src/services/checklists/types/AosChecklistUnits'
import {
    AosUnitTaskItem,
    EventPhase,
    UnitTaskStatus,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { CollapsibleButtonVariant } from 'aos-ui/src/components/buttons/CollapsibleButton'
import { CollapsibleContainer } from 'aos-ui/src/components/container/CollapsibleContainer'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import moment from 'moment'
import React, { FC } from 'react'

import { UnitTaskItem } from './items/UnitTaskItem'

interface CollapsableChecklistProps {
    name: EventPhase
    isCollapsed: boolean
    items: AosUnitTaskItem[]
    onToggle(): void
    userRole: string | undefined
    isApoc: boolean
    disabled: boolean
    userEmail: string
    onTaskChange(taskId: number, item: AosUnitTaskItem): void
}

export const CollapsableUnitTaskList: FC<CollapsableChecklistProps> = ({
    name,
    isCollapsed,
    items,
    onToggle,
    userRole,
    userEmail,
    disabled,
    isApoc,
    onTaskChange,
}) => {
    const renderHeader = () => (
        <Box row fullWidth>
            <Box flex={1}>
                <Text fontVariant='all-small-caps'>
                    {translateEnum<EventPhase>(EventPhase, 'tasklist-manager')(name)}
                </Text>
            </Box>
        </Box>
    )
    const isDisabled = (task: AosUnitTaskItem) => {
        if (disabled) {
            return true
        }
        return userRole !== mapChecklistUnitToUserRole[task.unit] && !isApoc
    }
    const renderItem = (task: AosUnitTaskItem) => (
        <Box marginBottom={16}>
            <UnitTaskItem
                footer={`${task.modifiedBy} | ${formatDateTime(moment(task.modifiedAt))}`}
                onEdit={() => onTaskChange(task.id, task)}
                additionalNote={task.additionalNote}
                editable={
                    (task.modifiedBy === userEmail || isApoc) &&
                    !disabled &&
                    task.status === UnitTaskStatus.DONE
                }
                isSameUnit={userRole === mapChecklistUnitToUserRole[task.unit] || isApoc}
                title={translate(`unit.${mapChecklistUnitToUserRole[task.unit]}`)}
                description={task.content}
                disabled={isDisabled(task)}
                value={task.status || UnitTaskStatus.TO_DO}
                onChange={value => onTaskChange(task.id, { ...task, status: value })}
            />
        </Box>
    )

    return (
        <CollapsibleContainer
            label={renderHeader()}
            toggle={onToggle}
            isCollapsed={isCollapsed}
            variant={CollapsibleButtonVariant.RightBottom}
            iconVariant={IconVariant.White}
            paddingVertical={16}
        >
            {items.map(task => renderItem(task))}
        </CollapsibleContainer>
    )
}
