import {
    ContactItem,
    ContactVisibility,
} from 'aos-services/src/services/contactList/types/ContactItem'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ContactListItemProps {
    contact: ContactItem
    style?: React.CSSProperties
    itemWidth: number
    isApocUser: boolean
    onEdit: (contact: ContactItem) => void
    onDelete: (contact: ContactItem) => void
}

export const ContactListItem = ({
    contact,
    style,
    itemWidth,
    isApocUser,
    onEdit,
    onDelete,
}: ContactListItemProps) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <HoverableBox
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ ...style, height: 58 }}
            row
            rounded
            flex={1}
            paddingHorizontal={12}
            paddingVertical={16}
            bg={Color.Grey11}
        >
            <Box marginRight={10} width={itemWidth}>
                <Text overflow='hidden' ellipsis size={14}>
                    {contact.name}
                </Text>
            </Box>
            <Box marginRight={10} flex={1}>
                <Text overflow='hidden' textClomp={2} ellipsis size={14}>
                    {contact.description}
                </Text>
            </Box>
            <Box marginRight={10} width={itemWidth}>
                <Text overflow='hidden' ellipsis size={14}>
                    {contact.phoneNumber}
                </Text>
            </Box>
            <Box flex={1} marginRight={10} width={itemWidth}>
                <Text overflow='hidden' ellipsis size={14}>
                    {contact.email}
                </Text>
            </Box>
            <Box marginRight={10} width={itemWidth}>
                <a target='_blank' href={contact.link} rel='noreferrer'>
                    <Text
                        overflow='hidden'
                        textClomp={2}
                        ellipsis
                        size={14}
                        color={Color.PrimaryPale}
                    >
                        {contact.linkLabel}
                    </Text>
                </a>
            </Box>
            {isApocUser && (
                <Box marginRight={10} width={itemWidth}>
                    {contact.contactVisibility === ContactVisibility.APOCOnly && (
                        <Icon svg={SvgIcon.CheckWhite} />
                    )}
                </Box>
            )}

            <Box width={64} marginRight={10} columnGap={8}>
                {isHovered && isApocUser && (
                    <>
                        <IconButton
                            iconVariant={IconVariant.White}
                            svg={SvgIcon.EditDark}
                            onClick={() => onEdit(contact)}
                        />
                        <IconButton
                            iconVariant={IconVariant.White}
                            svg={SvgIcon.Delete}
                            onClick={() => onDelete(contact)}
                        />
                    </>
                )}
            </Box>
        </HoverableBox>
    )
}

const HoverableBox = styled(Box)`
    &:hover {
        background-color: ${Color.ActiveGrey};
    }
`
