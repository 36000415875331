import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { MetarStateDto } from '../../../../dataaccess/metar/types/MetarStateDto'
import { MetarState } from './MetarState'

export class MetarStateImpl extends WrapperObject<MetarStateDto> implements MetarState {
    constructor(entity: MetarStateDto) {
        super(entity)
    }

    public get message(): string {
        const prefix = 'METAR'
        let metar = this.value.message
        metar = metar.startsWith(prefix) ? metar.substring(prefix.length) : metar
        return metar.trim()
    }
}
