import { getExtension } from 'aos-helpers/src/helpers/File'

import { AttachmentType } from './AttachmentType'

export interface UploadedAttachment {
    id?: number
    link: string
    name?: string
    type?: AttachmentType
}

export const isImage = (attachment: UploadedAttachment) =>
    !attachment.type || attachment.type.startsWith('image/')

export const getAttachmentExtension = (attachment: UploadedAttachment) => {
    if (attachment.name) {
        return getExtension(attachment.name) || ''
    }
    return ''
}
