import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import { EnumValues } from 'enum-values'

import { ControlPointConfig } from './ControlPointConfig'

export enum BorderControlPoint {
    BOR1 = 'BOR1',
    BOR2 = 'BOR2',
    BOR3 = 'BOR3',
}

export const allBorderControlPoints: BorderControlPoint[] = EnumValues.getValues<BorderControlPoint>(
    BorderControlPoint,
)

export const translateBorderControlPoint = translateEnum<BorderControlPoint>(
    BorderControlPoint,
    'dashboard.queueing-time.border-control-point',
)

export const borderControlPointConfig: Record<BorderControlPoint, ControlPointConfig> = {
    [BorderControlPoint.BOR1]: {
        color: Color.UiBlack4,
        fullName: 'Border Control\n' + 'Non-Schengen to Schengen',
        code: 'BOR1',
    },
    [BorderControlPoint.BOR2]: {
        color: Color.SoftMagenta,
        fullName: 'Border Control \n' + 'Schengen to Non-Schengen',
        code: 'BOR2',
    },
    [BorderControlPoint.BOR3]: {
        color: Color.SoftBlue,
        fullName: 'Border Control \n' + 'Schengen to Non-Schengen',
        code: 'BOR3',
    },
}
