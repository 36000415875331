import { Chart } from 'aos-components/src/components/chart/Chart'
import { LinearBarSeries } from 'aos-components/src/components/chart/series/LinearBarSeries'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import {
    ChartSelectorState,
    ChartSinglePoint,
} from '../../../../core/tasks/reports/selectors/types/ChartSelectorState'
import { ReportsUnit } from '../../../../core/tasks/reports/state'

interface ReportSingleChartProps {
    chartData: ChartSelectorState
    unit?: ReportsUnit
    rotateBottomLabels?: boolean
}

export const ReportSingleChart: FC<ReportSingleChartProps> = ({
    chartData,
    unit,
    rotateBottomLabels,
}) => {
    return (
        <Chart<string>
            xBandConfig={{
                domain: { ticks: chartData.dataKeys },
                bandPadding: { x0: { inner: 0.25, outer: 0 } },
                tickFormat: key => key,
                showGrids: false,
                barCount: 1,
            }}
            y1Config={{
                domain: chartData.yDomain,
                tickFormat: formatNumber,
                unit,
            }}
            bottomLabelsRotation={rotateBottomLabels ? 10 : 0}
        >
            {({ xBandScale, y1Scale }: ChartChildrenProps<string>) => {
                if (!xBandScale || !y1Scale) {
                    return null
                }

                return (
                    <LinearBarSeries
                        data={chartData.data}
                        seriesConfig={{
                            color: () => Color.PaleOrange,
                            tooltip: (point: ChartSinglePoint) => formatNumber(point.data),
                        }}
                        scales={{
                            xScale: xBandScale.x0,
                            yScale: y1Scale,
                        }}
                        accessors={{
                            xAccessor: (point: ChartSinglePoint) => point.key,
                            yAccessor: (point: ChartSinglePoint) => point.data,
                        }}
                    />
                )
            }}
        </Chart>
    )
}
