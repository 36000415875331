export interface FeatureFlagState {
    flags: { [key: string]: boolean }
}

export const initialFeatureFlagState: FeatureFlagState = {
    flags: {},
}

export type FeatureFlagStateAware = {
    featureFlags: FeatureFlagState
}
