import { SingleInputModalClass } from 'aos-components/src/components/modal/InputModal/SingleInputModal'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAdditionalNoteActions } from '../../../../core/eventEditor/actions'
import { setAdditionalNoteStateSelector } from '../../../../core/eventEditor/selector'

export const AdditionalNoteModal = () => {
    const state = useSelector(setAdditionalNoteStateSelector)
    const dispatch = useDispatch()

    return (
        <SingleInputModalClass
            keyPrefix='additional-note-modal'
            {...state}
            hideModelAction={() => dispatch(setAdditionalNoteActions.hideModelAction())}
            submitAction={text => dispatch(setAdditionalNoteActions.submitAction(text))}
            setFormPristineAction={value =>
                dispatch(setAdditionalNoteActions.setFormPristineAction(value))
            }
            showModalWithPayloadAction={payload =>
                dispatch(setAdditionalNoteActions.showModalWithPayloadAction(payload))
            }
            changeFormValueAction={value =>
                dispatch(setAdditionalNoteActions.changeFormValueAction(value))
            }
            clearFeedback={() => dispatch(setAdditionalNoteActions.clearFeedback())}
            showModalAction={() => dispatch(setAdditionalNoteActions.showModalAction())}
            setFeedback={feedback => dispatch(setAdditionalNoteActions.setFeedback(feedback))}
            showModalWithValueAction={value =>
                dispatch(setAdditionalNoteActions.showModalWithValueAction(value))
            }
        />
    )
}
