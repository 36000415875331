import { isNumber } from 'aos-helpers/src/helpers/Number'
import { isNil } from 'lodash'
import React, { FCWithChildren, useEffect, useState } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'

interface CleanableNumberInputProps extends MarginBoxProps, SeleniumProps {
    value?: number
    onFocus?: () => void
    onBlur?: () => void
    placeholder?: string
    leftSvg?: Svg
    svg?: Svg
    variant?: ThemeVariant
    readOnly?: boolean
    onClick?: () => void
    small?: boolean
    hideClean?: boolean
    onChange?(v: number | undefined): void
}

import { CleanableInput } from './CleanableInput'

export const CleanableNumberInput: FCWithChildren<CleanableNumberInputProps> = ({
    value,
    onChange,
    ...rest
}) => {
    const numberPattern = /^-?([0-9]+)?(\.)?([0-9]+)?$/g
    const [textValue, setTextValue] = useState<string | undefined>(
        !isNil(value) ? value.toString() : undefined,
    )

    const onChangeText = (v?: string) => {
        setTextValue(v)
        if (!onChange) {
            return
        }
        if (isNumber(v)) {
            onChange(parseInt(v as string, 10))
        } else {
            onChange(undefined)
        }
    }

    useEffect(() => {
        setTextValue(value?.toString())
    }, [value])

    return (
        <CleanableInput
            onKeyPress={event => {
                if (!numberPattern.test(event.key)) {
                    event.preventDefault()
                }
            }}
            value={textValue}
            onChangeText={onChangeText}
            {...rest}
        />
    )
}
