import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps, PaddingBoxProps } from '../base/Box'
import { IconVariant } from '../svg/Icon'
import { IconButton } from './IconButton'

interface CleanValueButtonProps extends ButtonProps, PaddingBoxProps, MarginBoxProps {
    iconVariant?: IconVariant
    type?: 'submit' | 'reset' | 'button'
}

export const CleanValueButton: FCWithChildren<CleanValueButtonProps> = props => (
    <IconButton
        {...props}
        svg={SvgIcon.Close}
        iconVariant={props.iconVariant || IconVariant.Grey}
        iconSize={BlockSize.Tiny}
        tabIndex={-1}
        block
    />
)
