import { DarkListItemIconsSection } from 'aos-ui/src/components/darkListItem/DarkListItemIconsSection'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface EventItemIconsSectionProps {
    hasPrivateChannel: boolean
    hasLocation: boolean
    attachmentsLength: number
    discussionLength: number
}

export const EventItemIconsSection: FC<EventItemIconsSectionProps> = props => (
    <DarkListItemIconsSection
        config={[
            [SvgIcon.Private, props.hasPrivateChannel],
            [SvgIcon.Location, props.hasLocation],
            [SvgIcon.Pictures, props.attachmentsLength],
            [SvgIcon.Message, props.discussionLength],
        ]}
    />
)
