import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import React from 'react'

import { BaseChartComponent } from '../base/BaseChartComponent'

class AxisUnitComponent extends BaseChartComponent<AxisUnitProps> {
    public render() {
        return (
            <g className='axis-unit' transform={`translate(${this.props.left} ${this.props.top})`}>
                <text dy='0.32em' textAnchor={this.props.textAnchor || 'start'}>
                    {this.props.unit}
                </text>
            </g>
        )
    }
}

interface AxisUnitProps {
    unit: string
    top: number
    left: number
    textAnchor?: string
}

export const AxisUnit = withChartContext(AxisUnitComponent)
