import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    baggageDeliveryChartColors,
    baggageDeliveryRanges,
} from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryConstants'
import { BaggageDeliveryHourlyStat } from 'aos-services/src/services/airportStatus/baggage/types/BaggageDeliveryStat'
import {
    BaggageStatSection,
    baggageStatsRecordToPairs,
} from 'aos-services/src/services/airportStatus/baggage/types/BaggageStatSection'
import { DistributionValue } from 'aos-services/src/services/common/types/DistributionValue'
import { Box } from 'aos-ui/src/components/base/Box'
import { LegendItem } from 'aos-ui/src/components/ui/LegendItem'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { BaggageDeliveryFullChartTooltipHeader } from './BaggageDeliveryFullChartTooltipHeader'

const label = (i: number, section: BaggageStatSection, val: DistributionValue) =>
    translate(`dashboard.baggage-delivery.full-chart.tooltip-${i}`, {
        ...baggageDeliveryRanges[section],
        count: val.count,
        percentage: val.percentage,
    })

export const BaggageDeliveryFullChartTooltip: FC<BaggageDeliveryFullChartTooltipProps> = props => {
    const stats = baggageStatsRecordToPairs(props.distribution).reverse()
    return (
        <Box padding={4}>
            <BaggageDeliveryFullChartTooltipHeader counts={props.flightCounts} time={props.time} />
            <LegendItem
                dotConfig={{
                    size: BlockSize.XTiny,
                    mainColor: Color.White,
                    variant: LegendDotVariant.Line,
                }}
                textColor={Color.White}
                marginVertical={4}
            >
                {translate('dashboard.baggage-delivery.full-chart.tooltip-average-time', {
                    time: Math.round(props.meanDeliveryTime.asMinutes()),
                })}
            </LegendItem>
            {stats.map(([section, sectionStats], i) => (
                <LegendItem
                    key={i}
                    dotConfig={{
                        size: BlockSize.XXTiny,
                        mainColor: baggageDeliveryChartColors[section],
                    }}
                    textColor={baggageDeliveryChartColors[section]}
                    marginVertical={4}
                >
                    {label(stats.length - i, section, sectionStats)}
                </LegendItem>
            ))}
        </Box>
    )
}

interface BaggageDeliveryFullChartTooltipProps extends BaggageDeliveryHourlyStat {}
