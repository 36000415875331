import Keycloak from 'keycloak-js'
import React, { PropsWithChildren, useEffect, useState } from 'react'

import { IAuthContextProps, keycloakContext } from './KeycloakContext'

export type KeycloakProviderProps = PropsWithChildren & {
    onLoginSuccess: (instance: Keycloak) => void
}

const createAuthProvider =
    (AuthContext: React.Context<IAuthContextProps>) => (instance: Keycloak) => {
        const KeycloakAuthProvider: React.FC<KeycloakProviderProps> = ({
            children,
            onLoginSuccess,
        }) => {
            const [client, setClient] = useState<Keycloak | null>(null)
            useEffect(() => {
                ;(async () => {
                    instance.onAuthRefreshSuccess = () => {
                        onLoginSuccess(instance)
                    }
                    await instance.init({
                        checkLoginIframe: false,
                        onLoad: 'login-required',
                        pkceMethod: 'S256',
                    })
                    setClient(instance)
                    onLoginSuccess(instance)
                })()
            }, [])

            return (
                client && <AuthContext.Provider value={{ client }}>{children}</AuthContext.Provider>
            )
        }

        return KeycloakAuthProvider
    }

const KeycloakProvider = createAuthProvider(keycloakContext)
export const KeycloakConsumer = keycloakContext.Consumer
export default KeycloakProvider
