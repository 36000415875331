import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { AlertItemLVPCounterDto } from '../../dataaccess/alerts/types/AlertsStateDto'

export const UPDATE_LVP_COUNTER = 'LVP_COUNTER/UPDATE_LVP_COUNTER'
export type UpdateLVPCounterAction = PayloadAction<
    typeof UPDATE_LVP_COUNTER,
    Required<Pick<AlertItemLVPCounterDto, 'endTime' | 'taskId'>>
>
export const updateLVPCounterAction = payloadActionCreator<
    UpdateLVPCounterAction,
    Required<Pick<AlertItemLVPCounterDto, 'endTime' | 'taskId'>>
>(UPDATE_LVP_COUNTER)

export const DISMISS_LVP_COUNTER = 'LVP_COUNTER/DISMISS_LVP_COUNTER'
export type DismissLVPCounterAction = PayloadAction<typeof DISMISS_LVP_COUNTER, { taskId: number }>
export const dismissLVPCounterAction = payloadActionCreator<
    DismissLVPCounterAction,
    { taskId: number }
>(DISMISS_LVP_COUNTER)

export const HIDE_LVP_COUNTER = 'LVP_COUNTER/HIDE_LVP_COUNTER'
export type HideLVPCounterAction = PayloadAction<typeof HIDE_LVP_COUNTER, { taskId: number }>
export const hideLVPCounterAction = payloadActionCreator<HideLVPCounterAction, { taskId: number }>(
    HIDE_LVP_COUNTER,
)

export const LVP_SYNC = 'LVP_COUNTER/LVP_SYNC'
export type LVPSyncAction = Action<typeof LVP_SYNC>
export const lvpSyncAction = emptyActionCreator<LVPSyncAction>(LVP_SYNC)

export type LVPCounterAction =
    | UpdateLVPCounterAction
    | DismissLVPCounterAction
    | HideLVPCounterAction
    | LVPSyncAction
