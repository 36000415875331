import { FilterCollection } from 'aos-helpers/src/helpers/Filters'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { SelectedMapElement } from '../../map/types/SelectedMapElement'
import { AosAirport } from './AosAirport'
import { emptyFlightInfoCommonFilters, FlightInfoCommonFilters } from './FlightInfoCommonFilters'

export enum FilterOption {
    All = 'ALL',
}

export type AirportFilter = (AosAirport | FilterOption)[]

export type AirportFilterSingle = AosAirport | FilterOption

export interface FlightInfoCheckboxFilters {
    airlines: string[]
    handling: string[]
    natureOfTheFlight: string[]
    aircrafts: string[]
    [key: string]: string[]
}

export interface FlightInfoFilters extends FlightInfoCommonFilters {
    recentFilters: FilterCollection<FlightInfoCheckboxFilters>
    checkboxFilters: FlightInfoCheckboxFilters
}

// mapping to keyof FlightInfoFilters
export enum FilterType {
    Airlines = 'airlines',
    HandlingCompany = 'handling',
    NatureOfTheFlight = 'natureOfTheFlight',
    AircraftType = 'aircrafts',
}

export const translateFilterType = translateEnum<FilterType>(
    FilterType,
    'mobile.flight-information.filters.section',
)

export const emptyFlightInfoFilters: FlightInfoFilters = {
    ...emptyFlightInfoCommonFilters,
    checkboxFilters: {
        airlines: [],
        handling: [],
        natureOfTheFlight: [],
        aircrafts: [],
    },
    recentFilters: [],
}

export type FlightInfoSelectedFeature = SelectedMapElement
