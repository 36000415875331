import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { taskSeverityColorsMapping } from 'aos-services/src/services/common/types/AosSeverity'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { InfoBar } from 'aos-ui/src/components/infobar/InfoBar'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { TaskBarContent } from 'aos-ui-task/src/components/task/TaskBarContent'
import React, { FC } from 'react'

import { TaskDetailsPropsList } from '../../tasks/details/TaskDetailsPropsList'

interface TaskDetailsSidebarProps {
    task: Task
    mapVariant: MapVariant
    openTaskAction(v: TaskId): void
    setMapPositionAction(v: [MapVariant, LonLat]): void
}

export const TaskDetailsSidebar: FC<TaskDetailsSidebarProps> = ({
    task,
    mapVariant,
    openTaskAction,
    setMapPositionAction,
}) => {
    const renderTitle = () => {
        return (
            <Box row>
                <Box flex={1}>{translate('map.sidebar.task-details')}</Box>
                <IconButton
                    svg={SvgIcon.OpenIn}
                    onClick={() => openTaskAction({ id: task.id! })}
                    iconVariant={IconVariant.White}
                    iconSize={BlockSize.Tiny}
                />
            </Box>
        )
    }

    const selectLocation = () => {
        if (!!task.location) {
            setMapPositionAction([mapVariant, task.location])
        }
    }

    return (
        <Sidebar title={renderTitle()}>
            <Box paddingBottom={30}>
                <InfoBar color={taskSeverityColorsMapping[task.severity]}>
                    <TaskBarContent item={task} shorten />
                </InfoBar>
            </Box>
            <Box column fullHeight>
                <TaskDetailsPropsList task={task} openLocation={selectLocation} />
            </Box>
        </Sidebar>
    )
}
