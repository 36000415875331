import { ChartScaleType } from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { chain } from 'lodash'

import { NullableTimeValuePoint } from '../../base/types/TimePoint'
import { WeatherValueSeriesType } from './WeatherSeriesType'

export interface WeatherSingleSeriesDefinition {
    data: NullableTimeValuePoint[]
    config: ChartSeriesConfig<NullableTimeValuePoint>
    scale: ChartScaleType.Y1 | ChartScaleType.Y2
    valueGetter: f.Func1<NullableTimeValuePoint, number>
}

export type WeatherSeriesDefinition = Partial<
    Record<WeatherValueSeriesType, WeatherSingleSeriesDefinition>
>

type Predicate = f.Func1<WeatherSingleSeriesDefinition | undefined, boolean>

// tslint:disable:trailing-comma
export const getTypesByPredicate = (
    p: Predicate,
    ...items: WeatherSeriesDefinition[]
): WeatherValueSeriesType[] =>
    chain(items)
        .map(d => chain(d).pickBy(p).keys().value() as WeatherValueSeriesType[])
        .flatten()
        .value()
// tslint:disable:trailing-comma
export const getSeriesByPredicate = (
    p: Predicate,
    ...items: WeatherSeriesDefinition[]
): WeatherSingleSeriesDefinition[] =>
    chain(items)
        .map(d => chain(d).pickBy(p).values().value() as WeatherSingleSeriesDefinition[])
        .flatten()
        .value()
