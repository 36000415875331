import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import {
    AosChecklistTemplate,
    AosSimpleTemplate,
} from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import {
    ChecklistTemplateEditPayload,
    ChecklistTemplatePayload,
} from 'aos-services/src/services/checklists/types/input/CheclistTemplatePayload'

export const LOAD_CHECKLIST_TEMPLATES = 'CHECKLIST_MANAGER/LOAD_CHECKLIST_TEMPLATES'
export type LoadChecklistTemplatesAction = Action<typeof LOAD_CHECKLIST_TEMPLATES>
export const loadChecklistTemplatesAction =
    emptyActionCreator<LoadChecklistTemplatesAction>(LOAD_CHECKLIST_TEMPLATES)

export const LOAD_CHECKLIST_TEMPLATES_SUCCESS = 'CHECKLIST_MANAGER/LOAD_CHECKLIST_TEMPLATES_SUCCESS'
export type LoadChecklistTemplatesSuccessAction = PayloadAction<
    typeof LOAD_CHECKLIST_TEMPLATES_SUCCESS,
    AosChecklistTemplate[]
>
export const loadChecklistTemplatesSuccessAction = payloadActionCreator<
    LoadChecklistTemplatesSuccessAction,
    AosChecklistTemplate[]
>(LOAD_CHECKLIST_TEMPLATES_SUCCESS)

export const CREATE_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/CREATE_CHECKLIST_TEMPLATE'
export type CreateChecklistTemplateAction = PayloadAction<
    typeof CREATE_CHECKLIST_TEMPLATE,
    ChecklistTemplatePayload
>
export const createChecklistTemplateAction = payloadActionCreator<
    CreateChecklistTemplateAction,
    ChecklistTemplatePayload
>(CREATE_CHECKLIST_TEMPLATE)

export const UPDATE_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/UPDATE_CHECKLIST_TEMPLATE'
export type UpdateChecklistTemplateAction = PayloadAction<
    typeof UPDATE_CHECKLIST_TEMPLATE,
    ChecklistTemplateEditPayload
>
export const updateChecklistTemplateAction = payloadActionCreator<
    UpdateChecklistTemplateAction,
    ChecklistTemplateEditPayload
>(UPDATE_CHECKLIST_TEMPLATE)

export const SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL'
export type ShowCreateChecklistTemplateModalAction = Action<
    typeof SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL
>
export const showCreateChecklistTemplateModalAction =
    emptyActionCreator<ShowCreateChecklistTemplateModalAction>(SHOW_CREATE_CHECKLIST_TEMPLATE_MODAL)

export const HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL'
export type HideCreateChecklistTemplateModalAction = Action<
    typeof HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL
>
export const hideCreateChecklistTemplateModalAction =
    emptyActionCreator<HideCreateChecklistTemplateModalAction>(HIDE_CREATE_CHECKLIST_TEMPLATE_MODAL)

export const DELETE_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/DELETE_CHECKLIST_TEMPLATE'
export type DeleteChecklistTemplateAction = PayloadAction<typeof DELETE_CHECKLIST_TEMPLATE, number>
export const deleteChecklistTemplateAction = payloadActionCreator<
    DeleteChecklistTemplateAction,
    number
>(DELETE_CHECKLIST_TEMPLATE)

export const EDIT_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/EDIT_CHECKLIST_TEMPLATE'
export type EditChecklistTemplateAction = PayloadAction<
    typeof EDIT_CHECKLIST_TEMPLATE,
    AosSimpleTemplate
>
export const editChecklistTemplateAction = payloadActionCreator<
    EditChecklistTemplateAction,
    AosSimpleTemplate
>(EDIT_CHECKLIST_TEMPLATE)

export const HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL =
    'CHECKLIST_MANAGER/HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL'
export type HideEditChecklistTemplateModalAction = Action<typeof HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL>
export const hideEditChecklistTemplateModalAction =
    emptyActionCreator<HideEditChecklistTemplateModalAction>(HIDE_EDIT_CHECKLIST_TEMPLATE_MODAL)

export const SELECT_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/SELECT_CHECKLIST_TEMPLATE'
export type SelectChecklistTemplateAction = PayloadAction<
    typeof SELECT_CHECKLIST_TEMPLATE,
    AosSimpleTemplate
>
export const selectChecklistTemplateAction = payloadActionCreator<
    SelectChecklistTemplateAction,
    AosSimpleTemplate
>(SELECT_CHECKLIST_TEMPLATE)

export const LOAD_CHECKLIST_TEMPLATE = 'CHECKLIST_MANAGER/LOAD_CHECKLIST_TEMPLATE'
export type LoadChecklistTemplateAction = PayloadAction<typeof LOAD_CHECKLIST_TEMPLATE, number>
export const loadChecklistTemplateAction = payloadActionCreator<
    LoadChecklistTemplateAction,
    number
>(LOAD_CHECKLIST_TEMPLATE)

export const LOAD_CHECKLIST_TEMPLATE_SUCCESS = 'CHECKLIST_MANAGER/LOAD_CHECKLIST_TEMPLATE_SUCCESS'
export type LoadChecklistTemplateSuccessAction = PayloadAction<
    typeof LOAD_CHECKLIST_TEMPLATE_SUCCESS,
    AosChecklistTemplate
>
export const loadChecklistTemplateSuccessAction = payloadActionCreator<
    LoadChecklistTemplateSuccessAction,
    AosChecklistTemplate
>(LOAD_CHECKLIST_TEMPLATE_SUCCESS)

export const CHANGE_CHECKLIST_TEMPLATE_SEQ = 'CHECKLIST_MANAGER/CHANGE_CHECKLIST_TEMPLATE_SEQ'
export type ChangeChecklistTemplateSeqAction = PayloadAction<
    typeof CHANGE_CHECKLIST_TEMPLATE_SEQ,
    ChangeSeqVector
>
export const changeChecklistTemplateSeqAction = payloadActionCreator<
    ChangeChecklistTemplateSeqAction,
    ChangeSeqVector
>(CHANGE_CHECKLIST_TEMPLATE_SEQ)

export const SET_EXPORTING_CHECKLIST_TEMPLATES =
    'CHECKLIST_MANAGER/SET_EXPORTING_CHECKLIST_TEMPLATES'
export type SetExportingChecklistTemplatesAction = PayloadAction<
    typeof SET_EXPORTING_CHECKLIST_TEMPLATES,
    boolean
>
export const setExportingChecklistTemplatesAction = payloadActionCreator<
    SetExportingChecklistTemplatesAction,
    boolean
>(SET_EXPORTING_CHECKLIST_TEMPLATES)

export type EventChecklistManagerActions =
    | LoadChecklistTemplatesAction
    | LoadChecklistTemplatesSuccessAction
    | DeleteChecklistTemplateAction
    | EditChecklistTemplateAction
    | SelectChecklistTemplateAction
    | LoadChecklistTemplateAction
    | LoadChecklistTemplateSuccessAction
    | CreateChecklistTemplateAction
    | UpdateChecklistTemplateAction
    | ShowCreateChecklistTemplateModalAction
    | HideCreateChecklistTemplateModalAction
    | HideEditChecklistTemplateModalAction
    | ChangeChecklistTemplateSeqAction
    | SetExportingChecklistTemplatesAction
