import { Color } from 'aos-ui-common/src/styles/Color'

export enum DashboardItemMode {
    Normal = 'NORMAL',
    Warning = 'WARNING',
    Alert = 'ALERT',
    NoData = 'NO_DATA', // this one is computed in frontend, it doesn't come from Firebase
}

export const dashboardItemModeColor: Record<DashboardItemMode, Color> = {
    [DashboardItemMode.Normal]: Color.Green,
    [DashboardItemMode.Alert]: Color.Red,
    [DashboardItemMode.Warning]: Color.Yellow,
    [DashboardItemMode.NoData]: Color.UiBlack4,
}
