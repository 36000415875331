import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeUTCSelector } from 'aos-services/src/core/common/selectors'
import { jumpToDateAction, jumpToNowAction } from 'aos-services/src/core/timeline/actions'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { Box } from 'aos-ui/src/components/base/Box'
import { CurrentTime } from 'aos-ui/src/components/CurrentTime'
import { JumpToDate } from 'aos-ui/src/components/form/datetime/JumpToDate'
import { HeaderButtonWrapper } from 'aos-ui/src/components/header/HeaderButtonWrapper'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AirportFilterDropdown } from '../../../components/inputs/AirportFilter'
import { updateFiltersAction } from '../../../core/flightInformation/actions'
import { flightsUrlFiltersSelector } from '../../../core/flightInformation/selectors'
import { notamTimelineParentAction } from '../../../core/notamTimeline/actions'

export const NotamTableHeader = () => {
    const onSelectDate = (date: DateTime) =>
        dispatch(notamTimelineParentAction(jumpToDateAction(date)))
    const jumpToNow = () => dispatch(notamTimelineParentAction(jumpToNowAction()))
    const filters = useSelector(flightsUrlFiltersSelector)

    const currentTime = useSelector(currentTimeUTCSelector)
    const dispatch = useDispatch()
    const selectJumpDate = (date: DateTime | undefined) => date && onSelectDate(date)

    return (
        <Box shrink={0} row>
            <HeaderButtonWrapper>
                <AirportFilterDropdown
                    value={filters.airport}
                    onChange={value =>
                        dispatch(updateFiltersAction([FlightListType.NOTAM, { airport: value }]))
                    }
                />
            </HeaderButtonWrapper>
            <JumpToDate marginRight={30} onChange={selectJumpDate} />
            <CurrentTime utc marginRight={30} onClick={jumpToNow} date={currentTime} />
        </Box>
    )
}
