import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { mapReducer } from 'aos-ui-map/src/core/reducer'
import { setPickedLocationFromEvent } from 'aos-ui-map/src/core/state'

import {
    EVENT_PREVIEW_MAP_PARENT,
    EventPreviewActions,
    PREVIEW_EVENT_HIDE_MODAL,
    PREVIEW_EVENT_LOAD_SUCCESS,
    PREVIEW_EVENT_REFRESH_SUCCESS,
    PREVIEW_FEED_IN_SUCCESS,
    SET_DIALOG_SUB_CONTENT,
} from './actions'
import { EventPreviewMode, EventPreviewState, initialEventPreviewState } from './state'

const updateLocation = (state: EventPreviewState): EventPreviewState => ({
    ...state,
    map: setPickedLocationFromEvent(state.map, state.event),
})

export function eventPreviewReducer(
    state = initialEventPreviewState,
    action: EventPreviewActions,
): EventPreviewState {
    switch (action.type) {
        case PREVIEW_EVENT_HIDE_MODAL:
            return { ...state, event: undefined }

        case PREVIEW_EVENT_LOAD_SUCCESS:
            return updateLocation({
                ...state,
                event: action.payload.event,
                subContent: action.payload.openOnMap ? DialogView.Map : DialogView.Content,
                mode: action.payload.openOnMap
                    ? EventPreviewMode.MapOnly
                    : EventPreviewMode.ContentAndMap,
            })

        case PREVIEW_EVENT_REFRESH_SUCCESS:
            return updateLocation({ ...state, event: action.payload })

        case PREVIEW_FEED_IN_SUCCESS:
            return {
                ...state,
                event: action.payload,
                subContent: DialogView.Content,
                mode: EventPreviewMode.ContentAndMap,
            }

        case EVENT_PREVIEW_MAP_PARENT:
            return {
                ...state,
                map: mapReducer(state.map, action.payload),
            }

        case SET_DIALOG_SUB_CONTENT:
            const newState = action.payload === DialogView.Map ? updateLocation(state) : state
            return {
                ...newState,
                subContent: action.payload,
            }

        default:
            return state
    }
}
