import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Feedback, ValueWrapper } from './state'

type Payload = any

type Value = any

export const SHOW_MODAL = 'SIMPLE_FORM/SHOW_MODAL'
export type ShowModalAction = Action<typeof SHOW_MODAL>
export const showModalAction = emptyActionCreator<ShowModalAction>(SHOW_MODAL)

export const SHOW_MODAL_WITH_PAYLOAD = 'SIMPLE_FORM/SHOW_MODAL_WITH_PAYLOAD'
export type ShowModalWithPayloadAction = PayloadAction<typeof SHOW_MODAL_WITH_PAYLOAD, Payload>
export const showModalWithPayloadAction = payloadActionCreator<ShowModalAction, Payload>(
    SHOW_MODAL_WITH_PAYLOAD,
)

export const SHOW_MODAL_WITH_VALUE = 'SIMPLE_FORM/SHOW_MODAL_WITH_VALUE'
export type ShowModalWithValueAction = PayloadAction<
    typeof SHOW_MODAL_WITH_VALUE,
    ValueWrapper<Value, Payload>
>
export const showModalWithValueAction = payloadActionCreator<
    ShowModalWithValueAction,
    ValueWrapper<Value, Payload>
>(SHOW_MODAL_WITH_VALUE)

export const HIDE_MODAL = 'SIMPLE_FORM/HIDE_MODAL'
export type HideModalAction = Action<typeof HIDE_MODAL>
export const hideModelAction = emptyActionCreator<HideModalAction>(HIDE_MODAL)

export const SET_FORM_PRISTINE = 'SIMPLE_FORM/SET_FORM_PRISTINE'
export type SetFormPristineAction = PayloadAction<typeof SET_FORM_PRISTINE, boolean>
export const setFormPristineAction = payloadActionCreator<SetFormPristineAction, boolean>(
    SET_FORM_PRISTINE,
)

export const SUBMIT = 'SIMPLE_FORM/SUBMIT'
export type SubmitAction<V = Value, P = Payload> = PayloadAction<typeof SUBMIT, ValueWrapper<V, P>>
export const submitAction = payloadActionCreator<SubmitAction, ValueWrapper<Value, Payload>>(SUBMIT)

export const SET_FEEDBACK = 'SIMPLE_FORM/SET_FEEDBACK'
export type SetFeedback = PayloadAction<typeof SET_FEEDBACK, Feedback>
export const setFeedback = payloadActionCreator<SetFeedback, Feedback>(SET_FEEDBACK)

export const CLEAR_FEEDBACK = 'SIMPLE_FORM/CLEAR_FEEDBACK'
export type ClearFeedback = Action<typeof CLEAR_FEEDBACK>
export const clearFeedback = emptyActionCreator<ClearFeedback>(CLEAR_FEEDBACK)

export const CHANGE_FORM_VALUE = 'SIMPLE_FORM/CHANGE_FORM_VALUE'
export type ChangeFormValueAction = PayloadAction<
    typeof CHANGE_FORM_VALUE,
    ValueWrapper<Value, Payload>
>
export const changeFormValueAction = payloadActionCreator<
    ChangeFormValueAction,
    ValueWrapper<Value, Payload>
>(CHANGE_FORM_VALUE)

export type SimpleFormModalActions =
    | ShowModalAction
    | ShowModalWithPayloadAction
    | ShowModalWithValueAction
    | SetFormPristineAction
    | HideModalAction
    | SubmitAction
    | SetFeedback
    | ChangeFormValueAction
    | ClearFeedback

export interface SimpleFormModalActionCreators {
    hideModelAction: typeof hideModelAction
    showModalAction: typeof showModalAction
    showModalWithPayloadAction: typeof showModalWithPayloadAction
    showModalWithValueAction: typeof showModalWithValueAction
    setFormPristineAction: typeof setFormPristineAction
    submitAction: typeof submitAction
    setFeedback: typeof setFeedback
    clearFeedback: typeof clearFeedback
    changeFormValueAction: typeof changeFormValueAction
}

export const simpleFormModalActionCreators: SimpleFormModalActionCreators = {
    hideModelAction,
    showModalAction,
    showModalWithPayloadAction,
    showModalWithValueAction,
    setFormPristineAction,
    submitAction,
    setFeedback,
    clearFeedback,
    changeFormValueAction,
}
