import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateTrend, Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { Box } from 'aos-ui/src/components/base/Box'
import { ColumnsGrid } from 'aos-ui/src/components/base/ColumnsGrid'
import { TrendLegendItem } from 'aos-ui/src/components/chart/trend/TrendLegendItem'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const BaggageDeliveryLegend: FC<BaggageDeliveryLegendProps> = props => {
    const renderTrend = (itemProps: BaggageDeliveryLegendItemProps, index: number) => (
        <Tooltip
            key={index}
            body={translateTrend('dashboard.baggage-delivery.trend.tooltip')(itemProps.trend)}
            placement='top'
            withArrow
        >
            <div>
                <Box marginRight={30}>
                    <TrendLegendItem
                        value={itemProps.percentage}
                        unit={Unit.Percent}
                        subvalue={itemProps.count}
                        subunit={'\u00A0' + translate('dashboard.baggage-delivery.unit.flights')}
                        trendVariant={itemProps.trendVariant}
                        trend={itemProps.trend}
                        label={itemProps.label}
                        labelColor={itemProps.labelColor}
                    />
                </Box>
            </div>
        </Tooltip>
    )

    return <ColumnsGrid>{props.items.map(renderTrend)}</ColumnsGrid>
}

export interface BaggageDeliveryLegendItemProps {
    trend: Trend
    percentage: number
    count: number
    label: string
    labelColor: Color
    trendVariant: TrendChangeVariant
}

export interface BaggageDeliveryLegendProps {
    items: BaggageDeliveryLegendItemProps[]
}
