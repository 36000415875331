import { ZIndex } from 'aos-ui/src/components/base/Theme'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import React from 'react'
import styled from 'styled-components'

import { BaseComponent } from '../../base/BaseComponent'

interface MobileLocationPickerProps {
    isActive: boolean
}

export class MobileLocationPicker extends BaseComponent<MobileLocationPickerProps> {
    constructor(props: MobileLocationPickerProps) {
        super(props)
    }

    public render() {
        return <Picker svg={SvgIcon.Target} hidden={!this.props.isActive} />
    }
}

const Picker = styled(SvgImage)<{ hidden?: boolean }>`
    z-index: ${ZIndex.Top};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${p => (p.hidden ? 'none' : 'block')};
`
