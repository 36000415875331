import { AosContactDto } from '../../../dataaccess/users/types/AosUserDto'
import { AosContact } from './AosUser'
import { AosUserBaseImpl } from './AosUserBaseImpl'
import { AosUserNotificationsPreferences } from './AosUserNotificationsPreferences'

export class AosContactImpl extends AosUserBaseImpl<AosContactDto> implements AosContact {
    constructor(value: AosContactDto) {
        super(value)
    }

    public get createdBy() {
        return this.value.createdBy
    }

    public get modifiedBy() {
        return this.value.modifiedBy
    }

    public get currentPhone() {
        return this.value.phoneNumber
    }

    public get notificationPreferences() {
        return this.value.notificationPreferences as AosUserNotificationsPreferences
    }
}
