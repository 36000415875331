import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { Positioned } from '../../base/Positioned'
import { Text } from '../../base/Text'
import { IconButton } from '../../buttons/IconButton'
import { IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'
import { PreviewableProps } from './PreviewableProps'

export const PreviewableOverlay: FCWithChildren<PreviewableProps> = ({
    onClick,
    onDelete,
    previewText,
}) => {
    const handleDelete = (e?: React.MouseEvent<any>) => {
        if (e) {
            e.stopPropagation()
        }
        onDelete!()
    }
    return (
        <PreviewableOverlayBox coverAll onClick={onClick} bg={rgba(Color.UiBlack2, 0.8) as Color}>
            {previewText && (
                <Text fullSize centered color={Color.White} size={13}>
                    {previewText}
                </Text>
            )}
            {onDelete && (
                <Positioned top={0} right={0}>
                    <IconButton
                        onClick={handleDelete}
                        padding={4}
                        iconVariant={IconVariant.White}
                        iconSize={BlockSize.Tiny}
                        svg={SvgIcon.Close}
                    />
                </Positioned>
            )}
        </PreviewableOverlayBox>
    )
}

export const PreviewableOverlayBox = styled(Box)``
