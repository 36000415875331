import { initialUnitTaskManagerState, UnitTaskManagerState } from '../state'
import {
    CREATE_UNIT_TASK_ITEM_SUCCESS,
    HIDE_CREATE_UNIT_TASK_ITEM_MODAL,
    HIDE_EDIT_UNIT_TASK_ITEM_MODAL,
    LOAD_UNIT_TASK_TEMPLATES_SUCCESS,
    SHOW_CREATE_UNIT_TASK_ITEM_MODAL,
    SHOW_EDIT_UNIT_TASK_ITEM_MODAL,
    UnitTaskManagerActions,
} from './actions'

export const unitTaskChecklistReducer = (
    state = initialUnitTaskManagerState,
    action: UnitTaskManagerActions,
): UnitTaskManagerState => {
    switch (action.type) {
        case LOAD_UNIT_TASK_TEMPLATES_SUCCESS:
            return {
                ...initialUnitTaskManagerState,
                unitTaskTemplates: action.payload,
                isCreateUnitTaskItemModalOpen: state.isCreateUnitTaskItemModalOpen,
                createdUnitTaskItem: state.createdUnitTaskItem,
                lockSeverityChange: state.lockSeverityChange,
            }
        case CREATE_UNIT_TASK_ITEM_SUCCESS:
            return {
                ...state,
                createdUnitTaskItem: action.payload,
            }
        case SHOW_EDIT_UNIT_TASK_ITEM_MODAL:
            return {
                ...state,
                isEditItemModalOpen: true,
                itemToEdit: action.payload,
            }

        case HIDE_EDIT_UNIT_TASK_ITEM_MODAL:
            return {
                ...state,
                isEditItemModalOpen: false,
                itemToEdit: undefined,
            }

        case SHOW_CREATE_UNIT_TASK_ITEM_MODAL:
            return {
                ...state,
                isCreateUnitTaskItemModalOpen: true,
                lockSeverityChange: !!action.payload,
            }

        case HIDE_CREATE_UNIT_TASK_ITEM_MODAL:
            return {
                ...state,
                isCreateUnitTaskItemModalOpen: false,
                createdUnitTaskItem: undefined,
            }

        default:
            return state
    }
}
