import { nullableToNotRequired } from 'aos-helpers/src/helpers/Function'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { withChartContext } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { createLine, lineX } from 'aos-ui-common/src/components/chart/series/lineSeries'
import { ChartScales, ChartXYAccessors } from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartLineSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cx, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'
import { defaultChartTooltip } from '../base/tooltip'

const seriesDotSize = 6
const strokeDashArray = '8,5'

class LineSeriesComponent<T, TX, TY> extends BaseChartComponent<ChartLineSeriesProps<T, TX, TY>> {
    public render() {
        const { data, seriesConfig, scales, accessors } = this.props

        const { path } = createLine(data, scales, accessors, seriesConfig)

        return (
            <g className={cxp(this.props, 'series series--line')}>
                <g className={cxp(this.props, 'series series--line-path')}>
                    <path
                        className='line'
                        stroke={this.getAttributeValue(seriesConfig.color)}
                        strokeDasharray={seriesConfig.isDashed ? strokeDashArray : undefined}
                        d={nullableToNotRequired(path)}
                    />
                </g>
                {seriesConfig.withDots && this.renderDots()}
            </g>
        )
    }

    private renderDots = () => (
        <g className={cxp(this.props, 'series series--dots')}>
            {this.props.data.map(this.renderDot)}
        </g>
    )

    private renderDot = (datum: T, index: number) => {
        const { seriesConfig, accessors, scales } = this.props

        if (accessors.yAccessor(datum) === null) {
            return null
        }

        const tooltip = isFunction(seriesConfig.tooltip)
            ? seriesConfig.tooltip
            : defaultChartTooltip(
                  this.getAttributeValue(seriesConfig.color),
                  seriesConfig.tooltip,
                  accessors.yAccessor,
              )

        const color = this.getAttributeValue(seriesConfig.color)
        const body = <span style={{ color }}>{tooltip(datum)}</span>

        const x = lineX(datum, scales.xScale, accessors.xAccessor)
        const y = scales.yScale(accessors.yAccessor(datum)) || 0
        return (
            <Tooltip key={index} body={body} placement='top' offset={[0, -10]} withArrow>
                {this.renderCircle(x, y)}
            </Tooltip>
        )
    }

    private renderCircle = (x: number, y: number) => {
        const { seriesConfig } = this.props

        return (
            <circle
                className={cx('dot', { 'dot--bordered': !!seriesConfig.dotColor })}
                cx={x}
                cy={y}
                r={seriesDotSize / 2}
                fill={this.getAttributeValue(seriesConfig.dotColor || seriesConfig.color)}
                stroke={this.getAttributeValue(seriesConfig.color)}
            />
        )
    }
    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartLineSeriesProps<T, TX, TY> extends ClassNameProps {
    data: T[]
    seriesConfig: ChartLineSeriesConfig<T>
    accessors: ChartXYAccessors<T, TX, TY>
    scales: ChartScales<TX, TY>
}

export const LineSeries = withChartContext(LineSeriesComponent)
