import { DateTime } from 'aos-helpers/src/helpers/Time'

import { UploadedAttachment } from '../../attachments/types/UploadedAttachment'
import { AbstractEntity } from '../../base/types/AbstractEntity'
import { Auditable } from '../../base/types/Auditable'
import { AosCommonFeedInEventProps } from './AosCommonFeedInEventProps'
import { AosFeedInStatus } from './AosFeedInStatus'

export interface AosFeedIn extends AbstractEntity, AosCommonFeedInEventProps, Auditable {
    hasLocation: boolean
    discussionLength?: number
    dismissed?: boolean
    status: AosFeedInStatus
    attachments: UploadedAttachment[]
    allAttachments: UploadedAttachment[]
    startTime?: DateTime
    overdue: boolean
    presentationTime?: DateTime
    atcAlert?: boolean
    exercise?: boolean
}

export const isNotActivated = (feedIn: AosFeedIn) => feedIn.status !== AosFeedInStatus.Activated
