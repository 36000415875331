import { BaseRestService } from '../base/BaseRestService'
import { QueueDto } from './types/QueueDto'

class QueuesRestService extends BaseRestService {
    public getSecurityQueues(): Promise<QueueDto> {
        return this.getAndTransform<QueueDto>(
            '/airport-status/security-queue',
            BaseRestService.jsonTransformer,
        )
    }
}

export const queuesRestService = new QueuesRestService()
