import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum TaskCategory {
    Animals = 'ANIMAL_PREVENTION',
    Maintenance = 'MAINTENANCE',
    DeIcing = 'DE_ICING',
    RescueService = 'RESCUE_SERVICE',
    Inspection = 'INSPECTION',
    LVP = 'LVP',
    HBA = 'HBA',
}

export const taskCategories = EnumValues.getValues<TaskCategory>(TaskCategory)

export const translateTaskCategory = translateEnum(TaskCategory, 'task-category')
