import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'

export interface SelectableSimpleChecklistTemplate extends AosSimpleTemplate {
    selected: boolean
}

export interface ChecklistSelectionModalState {
    isOpen: boolean
    checklists: SelectableSimpleChecklistTemplate[]
}

export interface ChecklistSelectionModalStateAware {
    checklistSelectionModal: ChecklistSelectionModalState
}

export const initialChecklistSelectionModalState: ChecklistSelectionModalState = {
    isOpen: false,
    checklists: [],
}
