import { AxisTicks } from 'aos-components/src/components/chart/axes/AxisTicks'
import { AxisUnit } from 'aos-components/src/components/chart/axes/AxisUnit'
import { LegacyChart } from 'aos-components/src/components/chart/LegacyChart'
import {
    GradientSeries,
    seriesBarHeight,
    seriesBarPadding,
} from 'aos-components/src/components/chart/series/GradientSeries'
import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import {
    NullableTimeValuePoint,
    TimeValuePoint,
} from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import {
    ChartConfig,
    ChartScaleType,
    getRanges,
} from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { AxisScale } from 'd3-axis'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import { chartIconOffset, chartUnitOffset } from '../WeatherConsts'
import {
    getLvpColorForWeatherProbabilityValue,
    getWeatherColorForWeatherProbabilityValue,
} from '../WeatherSettings'
import { getLvpProbabilityLabel, getWeatherProbabilityLabel } from './Probability'
import { WeatherIcons } from './WeatherIcons'

export const probabilityGradientSeriesConfig = {
    color: getWeatherColorForWeatherProbabilityValue,
    tooltip: (point: TimeValuePoint) => {
        const label = getWeatherProbabilityLabel(point)

        if (!label) {
            return null
        }

        return <span style={{ color: label.color }}>{label.text}</span>
    },
}

export const lvpProbabilityGradientSeriesConfig = {
    color: getLvpColorForWeatherProbabilityValue,
    tooltip: (point: TimeValuePoint) => {
        const label = getLvpProbabilityLabel(point)

        if (!label) {
            return null
        }

        return <span style={{ color: label.color }}>{label.text}</span>
    },
}

export const seriesIconPadding = 12

class WeatherGradientChartClass extends PureComponent<WeatherGradientChartProps & SizeMeProps> {
    public render() {
        const { chartConfig, size, unit, seriesConfig } = this.props
        const config = this.scalesConfig

        return (
            <Box className='block-chart'>
                <LegacyChart chartConfig={chartConfig} size={size}>
                    <AxisTicks
                        axisConfig={{ tickValues: config[ChartScaleType.X].tickValues }}
                        scale={config[ChartScaleType.X].scale}
                    />
                    {unit && (
                        <AxisUnit
                            unit={unit}
                            left={(this.props.size.width || 0) - chartUnitOffset}
                            top={
                                this.props.chartConfig.margins.top +
                                seriesBarPadding +
                                seriesBarHeight / 2
                            }
                            textAnchor='middle'
                        />
                    )}
                    <GradientSeries
                        name={this.props.name}
                        data={this.props.seriesData}
                        seriesConfig={seriesConfig}
                    />
                    <WeatherIcons
                        components={[this.props.type]}
                        left={chartIconOffset}
                        top={seriesIconPadding}
                    />
                </LegacyChart>
            </Box>
        )
    }

    private get scalesConfig(): ScalesConfig {
        const { chartConfig, xDomain } = this.props
        const { xRange } = getRanges(chartConfig.margins, this.size)
        const xScale = Scales.scaleDateTime(xDomain.domain, xRange)

        return {
            [ChartScaleType.X]: {
                scale: xScale,
                tickValues: xDomain.ticks,
            },
        }
    }

    private get size() {
        return { width: this.props.size.width || 0, height: this.props.size.height || 0 }
    }
}

interface DateConfig {
    scale: AxisScale<DateTime>
    tickValues: DateTime[]
}

interface ScalesConfig {
    [ChartScaleType.X]: DateConfig
}

export const WeatherGradientChart = sizeMe({ monitorHeight: true })(WeatherGradientChartClass)

export interface WeatherGradientChartProps {
    name: string
    type: WeatherValueSeriesType
    chartConfig: ChartConfig
    seriesData: NullableTimeValuePoint[]
    xDomain: Domain<DateTime>
    unit?: string
    seriesConfig: ChartSeriesConfig<NullableTimeValuePoint>
}
