import { Box, BoxProps } from 'aos-ui/src/components/base/Box'
import { FormFieldContainer } from 'aos-ui/src/components/container/FormFieldContainer'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

export interface InnerFormContainerProps extends BoxProps {
    withBorder?: boolean
}

export const InnerFormContainer: FCWithChildren<PropsWithChildren<InnerFormContainerProps>> = ({
    withBorder,
    children,
    ...margins
}) => (
    <FormBox bg={Color.Grey1} withBorder={withBorder} {...margins}>
        <FormFieldContainer>{children}</FormFieldContainer>
    </FormBox>
)

const FormBox = styled(Box)<{ withBorder?: boolean }>`
    ${p =>
        p.withBorder &&
        css`
            border: 1px solid ${Color.Grey2};
        `}
`
