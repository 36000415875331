import { BimLayersDataAction, LOAD_BIM_LAYERS_DATA_SUCCESS } from './actions'
import { BimLayersState, initialBimLayersState } from './state'

export function bimLayersDataReducer(
    state = initialBimLayersState,
    action: BimLayersDataAction,
): BimLayersState {
    switch (action.type) {
        case LOAD_BIM_LAYERS_DATA_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }

        default:
            return state
    }
}
