import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { MapRestrictionDto } from '../../../dataaccess/restrictions/types/MapRestrictionDto'
import { Restriction } from '../../restrictions/types/Restriction'
import { RestrictionResourceType } from '../../restrictions/types/RestrictionResourceType'
import { MapRestriction } from './MapRestriction'

export class MapRestrictionImpl extends WrapperObject<MapRestrictionDto> implements MapRestriction {
    public resourceType = this.value.resourceType as RestrictionResourceType
    public resourceNumber = this.value.resourceNumber
}

export const fromRestriction = (restriction: Restriction) =>
    new MapRestrictionImpl({
        resourceType: restriction.resourceType,
        resourceNumber: restriction.resourceNumber,
    })
