import { downloadFileFromResponse } from 'aos-helpers/src/helpers/Download'
import { dateTimeToISOString } from 'aos-helpers/src/helpers/Time'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'

import { eventsRestService } from '../../dataaccess/events/eventsRestService'

export class EventsExportService {
    public exportEvents(range: TimeRange): Promise<void> {
        return eventsRestService
            .exportEvents({
                startTime: dateTimeToISOString(range.timeStart),
                endTime: dateTimeToISOString(range.timeEnd),
            })
            .then(downloadFileFromResponse)
    }
}

export const eventsExportService = new EventsExportService()
