import { MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { HeaderIconVariant, HeaderLabel } from 'aos-ui/src/components/ui/HeaderLabel'
import React, { FC } from 'react'

export const WeatherAlertLabel: FC<
    MarginBoxProps & { title: string; variant: HeaderIconVariant; tooltip: string }
> = ({ variant, tooltip, ...props }) => (
    <Tooltip body={tooltip} placement='top' withArrow>
        <div>
            <HeaderLabel variant={variant} {...props}>
                {props.title}
            </HeaderLabel>
        </div>
    </Tooltip>
)
