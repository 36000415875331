import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'

import { TextMeasurer } from '../../../helpers/TextMeasurer'
import { PictogramIcon } from './PictogramIcon'

interface PictogramTextIconProps {
    t1: string
    t2?: string
    backgroundColor?: Color
    textColor?: Color
}

// tslint:disable:max-line-length
export const PictogramTextIcon: FC<PropsWithChildren<PictogramTextIconProps>> = props => {
    const { t1, t2, backgroundColor, textColor = '#FFF' } = props
    const t2space = t2 && ` ${t2}`
    const t1size = TextMeasurer.Instance.measure(t1, 100, 'Open Sans')
    const t2size = t2space ? TextMeasurer.Instance.measure(t2space, 70, 'Open Sans') : 0
    return (
        <PictogramIcon width={100 + 40 + t1size + t2size} height={100} color={backgroundColor}>
            {props.children}
            <text
                fontFamily='Open Sans'
                fontWeight='400'
                fontSize='100'
                x='95'
                y='86'
                fill={textColor}
            >
                <tspan fontSize='100'>{t1}</tspan>
                {t2space && <tspan fontSize='70'>{t2space}</tspan>}
            </text>
        </PictogramIcon>
    )
}
