export enum AosMessageChannel {
    Notification = 'DASHBOARD',
    Push = 'PUSH_NOTIFICATION',
    Sms = 'SMS',
    Email = 'EMAIL',
}

export const messagingChannels = [
    AosMessageChannel.Notification,
    AosMessageChannel.Push,
    AosMessageChannel.Email,
    AosMessageChannel.Sms,
]
