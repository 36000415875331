import { DateTime } from 'aos-helpers/src/helpers/Time'
import { eventsDataSelector } from 'aos-services/src/core/statusDashboardData/events/selectors'
import { AosDashboardEvent } from 'aos-services/src/services/events/types/AosDashboardEvent'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import { noop } from 'lodash'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { EventItem } from './EventItem'
import { EventsItem } from './partials/EventsItem'

interface EventsTileStateProps {
    carouselTrigger: DateTime
    eventsData: {
        events: AosDashboardEvent[]
        noDataWarning: boolean
    }
}

interface EventsTileProps extends EventsTileStateProps, StatusDashboardItemCommonProps {}

export const EventsTileComponent: FC<EventsTileProps> = ({ eventsData, ...props }) => (
    <EventsItem
        noDataWarning={eventsData.noDataWarning}
        itemProps={pickDashboardItemProps(props)}
        link={link}
        syncId='overview'
    >
        <TileContainer>
            <Carousel changeTrigger={props.carouselTrigger}>
                {eventsData.events.map(event => (
                    <EventItem key={event.id} event={event} openEventAction={noop} />
                ))}
            </Carousel>
        </TileContainer>
    </EventsItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Events })

export const EventsTile = connect<EventsTileStateProps>(
    createStructuredSelector({
        eventsData: eventsDataSelector,
        carouselTrigger: carouselTriggerSelector,
    }),
)(EventsTileComponent)
