import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

import {
    allReportTypes,
    ReportType,
    translateReportType,
} from '../../../../core/tasks/reports/types/ReportType'

interface ReportTypeDropdownProps {
    value: ReportType
    onChange(v: ReportType): void
}

export const ReportTypeDropdown: FC<ReportTypeDropdownProps> = ({ value, onChange }) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            width={DropdownWidth.Auto}
            items={allReportTypes}
            valueRenderer={translateReportType}
            onChange={onChange}
        />
    )
}
