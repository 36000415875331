import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import { RingRailTrain } from 'aos-services/src/services/layerData/types/RingRailTrain'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { Component } from 'react'

export class CommuterLineCell extends Component<CommuterLineCellProps, {}> {
    public render() {
        const { commuterLine } = this.props.train

        return this.renderCell(commuterLine)
    }

    private renderCell = (commuterLine: string) => {
        const style = {
            backgroundColor: '#3b4b6a',
        }
        return (
            <Box className={cxp(this.props, 'train-information__commuter-line')} style={style}>
                {commuterLine}
            </Box>
        )
    }
}

interface CommuterLineCellProps extends ClassNameProps {
    train: RingRailTrain
}
