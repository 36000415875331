import { flightsRestService } from '../../dataaccess/flightInformation/flightsRestService'
import { Flight } from './types/Flight'
import { FlightImpl } from './types/FlightImpl'
import { Notam, Snowtam } from './types/Notam'

class FlightsService {
    public async loadFlights(): Promise<Flight[]> {
        const flights = await flightsRestService.getAllFlights()
        return flights.map(flight => new FlightImpl(flight))
    }

    public async loadNotam(): Promise<Notam[]> {
        return flightsRestService.getAllNotam()
    }

    public async loadSnowtam(): Promise<Snowtam[]> {
        return flightsRestService.getAllSnowtam()
    }
}

export const flightsService = new FlightsService()
