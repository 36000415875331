import {
    tobtDataTimeFiltersValues,
    tobtFiltersValues,
    translateTobtDataTimeFiltersValues,
    translateTobtFiltersValues,
} from 'aos-services/src/services/statusDashboard/types/filters/TobtFilters'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React from 'react'
import styled from 'styled-components'

import { CdmItemState } from '../../../../services/statusDashboard/types/itemStates/CdmItemState'
import { Footer } from './Footer'

interface Props {
    setFilterAction: (f: Partial<CdmItemState>) => void
    itemState: CdmItemState
}

function TobtFooterFilters({ setFilterAction, itemState }: Props) {
    const changeHandlingTobtType = (tobtType: any) => {
        setFilterAction({ tobtType })
    }

    const changeHandlingTobtDataTimeRange = (tobtDataTimeRange: any) => {
        setFilterAction({ tobtDataTimeRange })
    }

    const tabsConfig = [
        {
            items: tobtFiltersValues,
            value: itemState.tobtType,
            name: 'cdm-tobt-dropdown',
            formatter: translateTobtFiltersValues,
            onChange: changeHandlingTobtType,
            tooltip: 'Tobt Filter',
            width: DropdownWidth.Big,
            isAlwaysExpanded: false,
        },
        {
            items: tobtDataTimeFiltersValues,
            value: itemState.tobtDataTimeRange,
            name: 'cdm-tobt-time-range-dropdown',
            formatter: translateTobtDataTimeFiltersValues,
            onChange: changeHandlingTobtDataTimeRange,
            tooltip: 'Time Range Filter',
            width: DropdownWidth.Big,
            isAlwaysExpanded: false,
        },
    ]

    return (
        <Footer>
            <TabsWrapper>
                <Dropdown
                    variant={DropdownVariant.BlackGrey}
                    value={tabsConfig[0].value}
                    items={tabsConfig[0].items}
                    valueRenderer={tabsConfig[0].formatter}
                    onChange={tabsConfig[0].onChange}
                    width={tabsConfig[0].width}
                    tooltip={tabsConfig[0].tooltip}
                />
                <Dropdown
                    variant={DropdownVariant.BlackGrey}
                    value={tabsConfig[1].value}
                    items={tabsConfig[1].items}
                    valueRenderer={tabsConfig[1].formatter}
                    onChange={tabsConfig[1].onChange}
                    width={tabsConfig[1].width}
                    tooltip={tabsConfig[1].tooltip}
                />
            </TabsWrapper>
        </Footer>
    )
}

export default TobtFooterFilters

const TabsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 420px;
    height: 100%;
`
