import { BaseAosMapEvent } from 'aos-services/src/services/map/types/AosMapEvent'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import { isEqual } from 'lodash'
import React, { FC } from 'react'

import { renderEventIcon } from '../../icons/MapIconRenderer'
import { BaseLayerProps } from '../base/BaseLayer'
import { MarkerLikeLayer } from './MarkerLikeLayer'

interface EventLayerProps extends BaseLayerProps {
    events: BaseAosMapEvent[]
}

export const EventLayer: FC<EventLayerProps> = ({
    events,
    isVisible,
    zIndex,
    layerId,
    minZoom,
    maxZoom,
}) => {
    const comparator = (e1: BaseAosMapEvent, e2: BaseAosMapEvent) =>
        e1.id === e2.id &&
        isEqual(e1.location, e2.location) &&
        e1.severity === e2.severity &&
        e1.category === e2.category

    return (
        <MarkerLikeLayer
            comparator={comparator}
            items={events}
            iconRenderer={renderEventIcon}
            isVisible={isVisible}
            zIndex={zIndex}
            layerId={layerId}
            minZoom={minZoom}
            maxZoom={maxZoom}
            selectionTarget={SelectedMapElementType.Event}
            setter={event => ({
                event,
            })}
        />
    )
}
