import { OperationalForecastGroup } from '../../../operationalForecast/types/OperationalForecast'
import { FilterOptionAll, filterOptionsWithAll, translateFilterWithAllOption } from './common'

export interface OperationalForecastFilters {
    group: OperationalForecastGroupFilter
}

export const defaultOperationalForecastFilters = {
    group: FilterOptionAll.All,
}

export type OperationalForecastGroupFilter = FilterOptionAll | OperationalForecastGroup

export const operationalForecastFiltersValues: OperationalForecastGroupFilter[] = filterOptionsWithAll(
    OperationalForecastGroup,
)

export const translateOperationalForecastFiltersValues = translateFilterWithAllOption(
    OperationalForecastGroup,
    'dashboard.operational-forecast.group',
)
