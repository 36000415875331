import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { FlightNumberCell } from '../cells/FlightNumberCell'
import { NatureOfFlightCell } from '../cells/NatureOfFlightCell'
import { PublicRemarkCell } from '../cells/PublicRemarkCell'
import { RouteCell } from '../cells/RouteCell'
import { TimeCell } from '../cells/TimeCell'
import { BaseFlightItem, BaseFlightItemProps } from './BaseFlightItem'
import { FlightRow } from './FlightRow'

interface ArrivalFlightProps extends BaseFlightItemProps {
    item: Flight
}

export class ArrivalFlightItem extends PureComponent<ArrivalFlightProps> {
    public render() {
        const { item } = this.props

        return (
            <BaseFlightItem {...this.props}>
                <FlightRow>
                    <Text color={Color.White} size={14}>
                        <TimeCell value={item.sdt} />
                    </Text>
                    <Text color={Color.White} size={12}>
                        <FlightNumberCell fltnr={item.nr} cflight={item.cflight} />
                    </Text>
                    <PublicRemarkCell row={item} className='flight-table-preview__prm' />
                </FlightRow>
                <FlightRow>
                    <NatureOfFlightCell
                        row={item}
                        className='flight-table-preview__nature-of-flight'
                    />
                    <Text color={Color.Grey} size={13}>
                        <RouteCell row={item} />
                    </Text>
                </FlightRow>
            </BaseFlightItem>
        )
    }
}
