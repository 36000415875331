import { logger } from 'aos-helpers/src/helpers/logging/Logger'
import { loadFeatureFlags } from 'aos-services/src/core/featureFlags/actions'
import { registerIncorrectStatusesResponseInterceptor } from 'aos-services/src/dataaccess/base/interceptors/incorrectStatusesResponseInterceptor'
import { registerStringifyBodyInterceptor } from 'aos-services/src/dataaccess/base/interceptors/stringifyBodyRequestInterceptor'
import { registerApiVersioningInterceptor } from 'aos-services/src/services/auth/apiVersioningInterceptor'
import { registerBaseUrlInterceptor } from 'aos-services/src/services/config/baseUrlInterceptor'
import { appConfig } from 'appConfig'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import { registerGoogleAnalytics } from './analytics'
import { persistenceEnhancer } from './persistence'
import { reducers } from './reducers'
import { sagas } from './sagas'
import { initialState, State } from './state'

const { routerMiddleware, routerReducer, createReduxHistory } = createReduxHistoryContext({
    history: createBrowserHistory(),
})
const sagaMiddleware = createSagaMiddleware({
    onError: logger.handleError as f.Action1<Error>,
})

const middlewares = [sagaMiddleware, routerMiddleware]

let composeEnhancers = compose

if (appConfig.isLogging) {
    middlewares.push(
        createLogger({
            collapsed: true,
        }) as Middleware,
    )

    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
        : compose
}

const enhancers = composeEnhancers(applyMiddleware(...middlewares), persistenceEnhancer)

export const store: Store<State> = createStore(
    reducers(routerReducer),
    initialState() as any,
    enhancers,
)

export const history = createReduxHistory(store)

sagaMiddleware.run(sagas)

registerIncorrectStatusesResponseInterceptor()

registerApiVersioningInterceptor(appConfig.apiUrl, appConfig.version)
registerBaseUrlInterceptor()
registerStringifyBodyInterceptor()

registerGoogleAnalytics()

store.dispatch(loadFeatureFlags())
