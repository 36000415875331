import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { AxisScale } from 'd3-axis'
import { isFunction } from 'lodash'
import React from 'react'

import { ClassNameProps, cxp } from '../../base/ClassNames'

const seriesIconSize = 8
const seriesLinePadding = 10
const strokeDasharray = '8,5'

export class VerticalLineSeries<T> extends React.PureComponent<ChartVerticalLineSeriesProps<T>> {
    public render() {
        const {
            datum,
            seriesConfig,
            scales: { xScale },
            size,
        } = this.props

        const xPos = xScale(datum) || 0

        return (
            <g className={cxp(this.props, 'series series--vertical-line')}>
                <line
                    x1={xPos}
                    y1={seriesIconSize + seriesLinePadding}
                    x2={xPos}
                    y2={size.height - seriesLinePadding - seriesIconSize}
                    stroke={this.getAttributeValue(seriesConfig.color)}
                    strokeDasharray={strokeDasharray}
                />
                <SvgImage
                    svg={SvgIcon.ChartTriangleDown}
                    attributes={{
                        width: seriesIconSize,
                        height: seriesIconSize,
                        x: xPos - seriesIconSize / 2,
                        y: 0,
                    }}
                />
                <SvgImage
                    svg={SvgIcon.ChartTriangleUp}
                    attributes={{
                        width: seriesIconSize,
                        height: seriesIconSize,
                        x: xPos - seriesIconSize / 2,
                        y: size.height - seriesIconSize,
                    }}
                />
            </g>
        )
    }

    protected getAttributeValue<U>(value: string | f.Func1<U | undefined, string>, datum?: U) {
        if (isFunction(value)) {
            return value(datum)
        }
        return value
    }
}

export interface ChartVerticalLineSeriesProps<T> extends ClassNameProps {
    datum: T
    seriesConfig: ChartSeriesConfig<T>
    scales: { xScale: AxisScale<T> }
    size: { width: number; height: number }
}
