import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { MaintenanceTaskType } from 'aos-services/src/services/tasks/types/task/MaintenanceTask'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

interface MaintenanceTaskTypeIconProps {
    taskType: MaintenanceTaskType
    iconVariant?: IconVariant
    iconSize?: BlockSize
}

export const maintenanceTaskTypeIcons: Record<MaintenanceTaskType, Svg> = {
    [MaintenanceTaskType.General]: SvgIcon.TaskMaintenanceGeneral,
    [MaintenanceTaskType.Winter]: SvgIcon.TaskMaintenanceWinter,
    [MaintenanceTaskType.Summer]: SvgIcon.TaskMaintenanceSummer,
    [MaintenanceTaskType.Brushes]: SvgIcon.TaskMaintenanceBrushes,
}

export const MaintenanceTaskTypeIcon: FC<MaintenanceTaskTypeIconProps> = ({
    taskType,
    iconVariant,
    iconSize = BlockSize.Std,
}) => (
    <Icon iconSize={iconSize} svg={maintenanceTaskTypeIcons[taskType]} iconVariant={iconVariant} />
)
