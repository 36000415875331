import { RestrictionsStats } from 'aos-services/src/services/restrictions/types/RestrictionsStats'
import React, { FC } from 'react'

import { RestrictionsCount } from './RestrictionsCount'
import { RestrictionsSummary } from './RestrictionsSummary'

interface RestrictionsStatsItemProps {
    stats: RestrictionsStats
}

export const RestrictionsStatsItem: FC<RestrictionsStatsItemProps> = ({ stats }) => (
    <>
        <RestrictionsCount active={stats.active} type={stats.areaType} />
        <RestrictionsSummary stats={stats.grouped} />
    </>
)
