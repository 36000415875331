import { SimpleHorizontalLegend } from 'aos-components/src/components/chart/SimpleHorizontalLegend'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const PaxForecastDaysChartLegend: FC = () => (
    <SimpleHorizontalLegend
        leftItems={[
            [
                { mainColor: Color.White, variant: LegendDotVariant.Line },
                translate('dashboard.pax-forecast.chart.days.total-flights'),
            ],
        ]}
        rightItems={[
            [{ mainColor: Color.Primary }, translate('dashboard.pax-forecast.chart.days.range-1')],
            [{ mainColor: Color.Yellow }, translate('dashboard.pax-forecast.chart.days.range-2')],
            [{ mainColor: Color.Red }, translate('dashboard.pax-forecast.chart.days.range-3')],
        ]}
        size={BlockSize.Tiny}
    />
)
