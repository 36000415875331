import { ManyBarsSeries } from 'aos-components/src/components/chart/series/ManyBarsSeries'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PaxForecastTimeOfDayChartPoint } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { totalPaxForecast } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastStats'
import { PaxLocalOrTransfer } from 'aos-services/src/services/airportStatus/paxForecast/types/PaxLocalOrTransfer'
import {
    paxForecastArrivalDepartureColors,
    PaxForecastArrivalDepartureFilter,
    paxForecastArrivalDepartureTransparentColors,
    translatePaxArrivalDepartureFilterForChart,
} from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import { ManyBarSeriesScales } from 'aos-ui-common/src/components/chart/series/manyBarScales'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface PaxForecastTimeOfDayTripleBarSeriesProps {
    data: PaxForecastTimeOfDayChartPoint[]
    arrivalDepartureFilter: PaxForecastArrivalDepartureFilter
    scales: ManyBarSeriesScales<DateTime, number>
}

export const PaxForecastTimeOfDayTripleBarSeries: FC<
    PaxForecastTimeOfDayTripleBarSeriesProps
> = props => (
    <ManyBarsSeries
        data={props.data}
        xAccessor={(d: PaxForecastTimeOfDayChartPoint) => d.time}
        scales={props.scales}
        strokeWidth={1}
        series={[
            {
                fill: paxForecastArrivalDepartureColors[props.arrivalDepartureFilter],
                stroke: paxForecastArrivalDepartureColors[props.arrivalDepartureFilter],
                yAccessor: totalPaxForecast,
                tooltip: translatePaxArrivalDepartureFilterForChart(props.arrivalDepartureFilter),
            },
            {
                fill: paxForecastArrivalDepartureTransparentColors[props.arrivalDepartureFilter],
                stroke: paxForecastArrivalDepartureColors[props.arrivalDepartureFilter],
                yAccessor: (d: PaxForecastTimeOfDayChartPoint) => d[PaxLocalOrTransfer.Local],
                tooltip: translate('dashboard.pax-forecast.chart.time-of-day.local'),
            },
            {
                fill: Color.Transparent,
                stroke: paxForecastArrivalDepartureColors[props.arrivalDepartureFilter],
                yAccessor: (d: PaxForecastTimeOfDayChartPoint) => d[PaxLocalOrTransfer.Transfer],
                tooltip: translate('dashboard.pax-forecast.chart.time-of-day.transfer'),
            },
        ]}
    />
)
