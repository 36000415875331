import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canViewTasksSelector } from 'aos-services/src/core/auth/state'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { openTaskAction } from '../../../../../core/tasks/actions'
import { loadUpcomingTasksAction } from '../../../../../core/tasks/upcoming/actions'
import {
    upcomingTasksIsErrorSelector,
    upcomingTasksIsLoadingSelector,
    upcomingTasksSelector,
} from '../../../../../core/tasks/upcoming/selectors'
import { UpcomingTasksItemState } from '../../../../../services/statusDashboard/types/itemStates/UpcomingTasksItemState'
import { pickDashboardItemProps, StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { TaskCardList } from '../partial/TaskCardList'

interface UpcomingTasksProps
    extends UpcomingTasksStateProps,
        UpcomingTasksDispatchProps,
        StatusDashboardItemCommonProps<UpcomingTasksItemState> {}

const UpcomingTasksComponent: FC<UpcomingTasksProps> = ({
    tasks,
    isLoading,
    isError,
    loadUpcomingTasks,
    openTask,
    canView,
    ...props
}) => {
    useEffect(() => {
        loadUpcomingTasks()
    }, [])

    return (
        <StatusDashboardItem
            title={translate('dashboard.tasks.upcoming-tasks.title')}
            description={translate('dashboard.tasks.upcoming-tasks.description')}
            {...pickDashboardItemProps(props)}
        >
            {canView ? (
                <TaskCardList
                    tasks={tasks}
                    isLoading={isLoading}
                    isError={isError}
                    onOpen={task => openTask([TaskFilter.All, task])}
                    empty={
                        <PanelPlaceholder
                            svg={SvgIcon.ChecklistPlaceholder}
                            label={translate('dashboard.tasks.upcoming-tasks.no-upcoming-tasks')}
                        />
                    }
                />
            ) : (
                <PanelPlaceholder svg={SvgIcon.NoData} label={translate('tasks.no-permissions')} />
            )}
        </StatusDashboardItem>
    )
}

interface UpcomingTasksStateProps {
    tasks: TaskSnapshot[]
    isLoading: boolean
    isError: boolean
    canView: boolean
}

interface UpcomingTasksDispatchProps {
    loadUpcomingTasks: typeof loadUpcomingTasksAction
    openTask: typeof openTaskAction
}

export const UpcomingTasks = connect<UpcomingTasksStateProps, UpcomingTasksDispatchProps>(
    createStructuredSelector({
        tasks: upcomingTasksSelector,
        isLoading: upcomingTasksIsLoadingSelector,
        isError: upcomingTasksIsErrorSelector,
        canView: canViewTasksSelector,
    }),
    {
        loadUpcomingTasks: loadUpcomingTasksAction,
        openTask: openTaskAction,
    },
)(UpcomingTasksComponent)
