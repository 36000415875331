import { keys } from 'lodash'
import { Task } from 'redux-saga'
import { all, cancel, fork } from 'redux-saga/effects'

import { ListenerAggregator } from '../../dataaccess/base/ListenerAggregator'

export class ListenerService {
    constructor(private listeners: ListenerAggregator) {}

    // common
    public *offAllListeners() {
        const runningTasks = this.app().getRunningTasks()
        if (runningTasks.length) {
            yield cancel(runningTasks)
            this.app().resetListeners()
        }
    }

    public hasListener = (listener: string) => this.app().hasListener(listener)

    // shared
    public *onListeners(items: { [listenerName: string]: f.Action }, component: string | number) {
        yield all(
            keys(items).map(listenerName =>
                this.onListener(listenerName, items[listenerName], component),
            ),
        )
    }

    public *offListeners(listeners: string[], component: string | number) {
        yield all(listeners.map(listener => this.offListener(listener, component)))
    }

    public *onListener(
        listenerName: string,
        action: f.Action,
        component: string | number = 'component',
    ): Task | Generator | undefined {
        if (this.app().hasListener(listenerName)) {
            this.app().registerComponent(listenerName, component)
        } else {
            const task: Task = yield fork(action)
            this.app().registerListener(listenerName, task, component)
            return task
        }
    }

    public *restartListener(listenerName: string, action: f.Action): Task | Generator | undefined {
        if (this.app().hasListener(listenerName)) {
            yield cancel(this.app().getTask(listenerName))
            const task: Task = yield fork(action)
            this.app().changeListenerTask(listenerName, task)
        } else {
            const task: Task = yield fork(action)
            this.app().registerListener(listenerName, task, 'component')
            return task
        }
    }

    public *offListener(listenerName: string, component: string | number = 'component') {
        if (this.app().hasListener(listenerName)) {
            this.app().freeComponent(listenerName, component)
            if (this.app().getComponents(listenerName).size === 0) {
                yield cancel(this.app().getTask(listenerName))
                this.app().resetListener(listenerName)
            }
        }
    }

    protected app = () => this.listeners
}
