import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { EnumValues } from 'enum-values'

export enum AosChecklistFilter {
    EventChecklist = 'EVENT_CHECKLIST',
    UnitTasks = 'UNIT_TASKS',
    TaskChecklist = 'TASK_CHECKLIST',
}

export const allAosChecklistFilters = EnumValues.getValues<AosChecklistFilter>(AosChecklistFilter)
export const translateAosChecklistFilter = translateEnum<AosChecklistFilter>(
    AosChecklistFilter,
    'checklist-manager.label',
)
