import {
    AosTaskChecklistTemplate,
    AosTaskChecklistTemplateDTO,
} from '../../services/checklists/types/AosTaskChecklistTemplate'
import { ChecklistTemplateItemEditPayload } from '../../services/checklists/types/input/ChecklistTemplateItemEditPayload'
import { AosLocation } from '../../services/common/types/AosLocation'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { BaseRestService } from '../base/BaseRestService'
import { AosTaskInSeq } from './types/AosTaskInSeq'

class TaskChecklistTemplatesRestService extends BaseRestService {
    public getAll(location: AosLocation): Promise<AosTaskChecklistTemplateDTO[]> {
        return this.getAndTransform<AosTaskChecklistTemplateDTO[]>(
            `/task-manager/checklists/templates?siteLocation=${location}`,
            BaseRestService.jsonTransformer,
        )
    }

    public create(checklistTemplate: AosTaskChecklistTemplate): Promise<{}> {
        return this.postJson(`/task-manager/checklists/templates`, checklistTemplate)
    }

    public delete(templateId: number): Promise<Response> {
        return this.deleteReq(`/task-manager/checklists/templates/${templateId}`)
    }

    public update({ id, ...checklistTemplate }: AosTaskChecklistTemplate): Promise<Response> {
        return this.patchJson(`/task-manager/checklists/templates/${id}`, checklistTemplate)
    }

    public getOne(checklistTemplateId: number): Promise<AosTaskChecklistTemplateDTO> {
        return this.getAndTransform<AosTaskChecklistTemplateDTO>(
            `/task-manager/checklists/templates/${checklistTemplateId}`,
            BaseRestService.jsonTransformer,
        )
    }

    public createItem(
        checklistTemplateId: number,
        item: ChecklistTemplateItemEditPayload,
    ): Promise<{}> {
        return this.postJson(
            `/task-manager/checklists/templates/${checklistTemplateId}/items`,
            item,
        )
    }

    public updateItem(
        checklistTemplateId: number,
        itemId: number,
        payload: ChecklistTemplateItemEditPayload,
    ): Promise<{}> {
        return this.patchJsonAndTransform<{}>(
            `/task-manager/checklists/templates/${checklistTemplateId}/items/${itemId}`,
            payload,
            BaseRestService.jsonTransformer,
        )
    }

    public deleteItem(checklistTemplateId: number, itemId: number): Promise<{}> {
        return this.deleteReq(
            `/task-manager/checklists/templates/${checklistTemplateId}/items/${itemId}`,
        )
    }

    public exportAll(location: AosAirport): Promise<[Blob, string]> {
        return this.getAndTransform(
            `/task-manager/checklists/templates/export?siteLocation=${location}`,
            BaseRestService.fileDownloadTransformer,
        )
    }

    public markReviewed(id: number): Promise<{}> {
        return this.post(`/task-manager/checklists/templates/${id}/reviewed`)
    }

    public exportSingle(id: number): Promise<[Blob, string]> {
        return this.getAndTransform(
            `/task-manager/checklists/templates/export/template/${id}`,
            BaseRestService.fileDownloadTransformer,
        )
    }

    public reorderItems(checklistTemplateId: number, tasksOrder: AosTaskInSeq[]): Promise<{}> {
        return this.patchJsonAndTransform<{}>(
            `/task-manager/checklists/templates/${checklistTemplateId}/items`,
            tasksOrder,
            BaseRestService.jsonTransformer,
        )
    }
}

export const taskChecklistTemplatesRestService = new TaskChecklistTemplatesRestService()
