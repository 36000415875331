import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { EnumValues } from 'enum-values'

export interface DeIcingChemicals {
    id: number
    label: string
    chemicalsType: DeIcingChemicalType
    chemicalCompound: string | null
    /** Density for kg → l calculation in g/cm3 */
    density: number | null
}

export enum DeIcingChemicalType {
    Liquid = 'LIQUID',
    Grain = 'GRAIN',
}

export const allDeIcingChemicalsTypes = EnumValues.getValues<DeIcingChemicalType>(
    DeIcingChemicalType,
)

export type DeIcingChemicalTypeFilter = FilterOptionAll | DeIcingChemicalType

export const translateDeIcingChemicalsType = (value: DeIcingChemicalTypeFilter) =>
    value === FilterOptionAll.All
        ? translate('reports.chemical-type.all')
        : translateEnum(DeIcingChemicalType, 'reports.chemical-type')(value)
