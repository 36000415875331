import { TaskType } from '../TaskType'
import { BaseTask } from './BaseTask'

export interface RescueServiceTask extends BaseTask {
    type: TaskType.RescueService
    address?: string
    aircraftRegistration?: string
    taskType: RescueServiceTaskType
    units: RescueServiceUnit[]
    additionalUnits?: string
}

export interface RescueServiceTaskType {
    id: number
    label: string
}

export interface RescueServiceUnit {
    id: number
    name: string
    numberOfWorkers: number
}

export const rescueServiceUnitLabel = (r: RescueServiceUnit) => `${r.name} (${r.numberOfWorkers})`
