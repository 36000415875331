import { conditionalFunction } from 'aos-helpers/src/helpers/Function'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosUser, isAosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserGroupType } from 'aos-services/src/services/users/types/AosUserGroupType'
import { AosUserType } from 'aos-services/src/services/users/types/AosUserType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { EditButton } from 'aos-ui/src/components/buttons/EditButton'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { PropsList, PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { ByAtTextOptional } from 'aos-ui/src/components/text/ByAtTextOptional'
import { UserPhoto } from 'aos-ui/src/components/UserPhoto'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

export class UserPreview extends PureComponent<UserPreviewProps> {
    public render() {
        return (
            <PanelWithHeader
                header={this.renderHeader()}
                splitterRightPane
                seleniumLocation='user-preview'
            >
                {this.props.user ? this.renderContent(this.props.user) : this.renderPlaceholder()}
            </PanelWithHeader>
        )
    }

    private renderHeader = () => {
        const editUser = conditionalFunction(!!this.props.user, () =>
            this.props.onEditUser(this.props.user!),
        )

        const title = translate('group-manager.user-preview.title')
        const canEditUser =
            !!this.props.user && this.props.selectedGroup.type !== AosUserGroupType.REMOVED
        return (
            <HeaderContainer
                rightButton={
                    <EditButton onClick={editUser} seleniumLocation='user-preview-action-button' />
                }
                title={title}
                rightButtonVisible={canEditUser}
            />
        )
    }

    private renderContent = (user: AosUser) => (
        <Box padding={30}>
            {this.renderPhotoSection(user)}
            {this.renderUserDetails(user)}
        </Box>
    )

    private renderUserDetails(user: AosUser) {
        const items = [
            ['user-preview.company', user.company],
            ...this.renderUnit(user),
            ...this.renderRole(user),
            ['user-preview.email', user.email],
            ['user-preview.phone-number', user.phone],
            ...this.renderAlternativePhone(user),
            ['user-preview.belongs-to', this.renderGroups(user)],
            ...this.renderAuditSection(user),
        ] as PropsListEntry[]

        return (
            <PropsList
                items={items}
                variant={ThemeVariant.Black}
                size={LabeledTextElementSize.Large}
                reversed
                paddingTop={30}
            />
        )
    }

    private renderAuditSection = (user: AosUser) => {
        const { createdAt, createdBy, modifiedAt, modifiedBy } = user
        if (user.type === AosUserType.MANUAL) {
            return [
                [
                    'user-preview.created-by',
                    <ByAtTextOptional key='0' at={createdAt} by={createdBy} />,
                ],
                [
                    'user-preview.modified-by',
                    <ByAtTextOptional key='1' at={modifiedAt} by={modifiedBy} />,
                ],
            ]
        } else {
            return []
        }
    }

    private renderAlternativePhone = (user: AosUser) =>
        isAosAdUser(user) ? [['user-preview.alt-phone-number', user.alternativePhone]] : []

    private renderUnit = (user: AosUser) =>
        isAosAdUser(user) && user.unit && user.type === AosUserType.INTERNAL
            ? [['user-preview.unit', translate(`unit.${user.unit}`)]]
            : []

    private renderRole = (user: AosUser) => (user.role ? [['user-preview.role', user.role]] : [])

    private renderGroups = (user: AosUser) => {
        const items = user.groups
            .filter(group => group.type !== AosUserGroupType.ALL)
            .map(t => t.name)
            .map((t, index) => <Box key={index}>{`- ${t}`}</Box>)
        return <Box>{items}</Box>
    }

    private renderPhotoSection = (user: AosUser) => (
        <Box>
            <Box centered>
                <UserPhoto
                    base64Img={isAosAdUser(user) ? user.thumbnail : ''}
                    marginTop={20}
                    marginBottom={12}
                    large
                />
            </Box>
            <Text selectable color={Color.White} size={18} textAlign='center'>
                {user.name}
            </Text>
        </Box>
    )

    private renderPlaceholder = () => (
        <PanelPlaceholder
            label={translate('group-manager.user-preview.placeholder')}
            svg={SvgIcon.UserPlaceholder}
        />
    )
}

interface UserPreviewProps {
    selectedGroup: AosUserGroup
    user?: AosUser
    onEditUser(v: AosUser): void
}
