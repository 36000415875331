import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TooltipColorList } from 'aos-ui/src/components/tooltip/TooltipColorsOverlay'

export enum WindGustsColors {
    Small = '#417EFF',
    Normal = '#4DC8C9',
    Big = '#6C55A7',
    Extreme = '#DA58AB',
}

export const windGustsColorList: TooltipColorList = [
    [WindGustsColors.Small, translate('dashboard.weather.wind-gusts.small')],
    [WindGustsColors.Normal, translate('dashboard.weather.wind-gusts.normal')],
    [WindGustsColors.Big, translate('dashboard.weather.wind-gusts.big')],
    [WindGustsColors.Extreme, translate('dashboard.weather.wind-gusts.extreme')],
]
