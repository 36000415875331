import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Sort } from 'aos-ui/src/components/table/TableHeaderButton'
import { chain, compact } from 'lodash'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Column } from 'react-virtualized'

import { changeSortingAction } from '../../../../../core/flightInformation/actions'
import { FlightInfoField } from '../../../../../core/flightInformation/flight/FlightInfoField'
import { sortingSelector } from '../../../../../core/flightInformation/selectors'
import { FlightSort, SortingRule } from '../../../../../core/flightInformation/state'
import { BeltBaggageCell } from '../../../../flightInformation/cells/BeltBaggageCell'
import { FlightNumberCell } from '../../../../flightInformation/cells/FlightNumberCell'
import { HandlingAgentCell } from '../../../../flightInformation/cells/HandlingAgentCell'
import { RouteCell } from '../../../../flightInformation/cells/RouteCell'
import { TimeCell } from '../../../../flightInformation/cells/TimeCell'
import { FlightTableHeader } from '../../../../flightInformation/header/FlightTableHeader'
import { WidgetFlights } from '../../base/WidgetFlights'

interface BaggageDeliveryFlightsProps {
    flights: Flight[]
    now: DateTime
}

export const BaggageDeliveryFlights: FC<BaggageDeliveryFlightsProps> = memo(({ flights, now }) => {
    const dispatch = useDispatch()
    const sort = useSelector(sortingSelector(FlightSort.BAGGAGE))

    const handleSort = useCallback(
        (sort: SortingRule) => {
            switch (sort.field) {
                case FlightInfoField.bag:
                    return (v: Flight) => v.bag.deliveryTime(now)
                case FlightInfoField.routes:
                    return (v: Flight) => compact(v.routes.map(r => r.airport)).join()
                default:
                    return (v: Flight) => v[sort.field as keyof Flight]
            }
        },
        [now],
    )

    const flightsWithSort = useMemo(() => {
        return chain(flights)
            .orderBy(f => handleSort(sort)(f), sort.desc ? 'desc' : 'asc')
            .value()
    }, [sort, flights])

    const onChangeSorting = (v: Sort<FlightInfoField>) =>
        dispatch(
            changeSortingAction({
                value: { field: v.key },
                type: FlightSort.BAGGAGE,
            }),
        )

    return (
        <WidgetFlights flights={flightsWithSort}>
            <Column
                dataKey={FlightInfoField.sdt}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.sdt,
                            onChange: onChangeSorting,
                        }}
                        caption='sdt'
                    />
                )}
                cellRenderer={({ cellData }) => <TimeCell value={cellData} />}
            />
            <Column
                dataKey={FlightInfoField.ablk}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.ablk,
                            onChange: onChangeSorting,
                        }}
                        caption='aibt'
                    />
                )}
                cellRenderer={({ cellData }) => <TimeCell value={cellData} />}
            />
            <Column
                dataKey={FlightInfoField.fltnr}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.fltnr,
                            onChange: onChangeSorting,
                        }}
                        caption='fltnr'
                    />
                )}
                cellRenderer={({ rowData }) => (
                    <FlightNumberCell fltnr={rowData.nr} cflight={rowData.cflight} />
                )}
            />
            <Column
                dataKey={FlightInfoField.routes}
                width={80}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.routes,
                            onChange: onChangeSorting,
                        }}
                        caption='origin'
                    />
                )}
                cellRenderer={({ rowData }) => <RouteCell row={rowData} />}
            />
            <Column
                dataKey={FlightInfoField.acreg}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.acreg,
                            onChange: onChangeSorting,
                        }}
                        caption='acreg'
                    />
                )}
            />
            <Column
                dataKey={FlightInfoField.handl}
                width={120}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.handl,
                            onChange: onChangeSorting,
                        }}
                        caption='handl'
                    />
                )}
                cellRenderer={({ cellData }) => <HandlingAgentCell value={cellData} />}
            />
            <Column
                dataKey={FlightInfoField.bag}
                width={160}
                headerRenderer={() => (
                    <FlightTableHeader
                        sort={{
                            sort: {
                                key: sort.field as FlightInfoField,
                                direction: sort.desc ? 'desc' : 'asc',
                            },
                            sortKey: FlightInfoField.bag,
                            onChange: onChangeSorting,
                        }}
                        caption='bag'
                    />
                )}
                cellRenderer={({ rowData }) => <BeltBaggageCell row={rowData} now={now} />}
                className='container container--centered'
            />
        </WidgetFlights>
    )
})
