import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { FilterOption } from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import { FlightListType } from 'aos-services/src/services/flightInformation/types/FlightListType'
import { isEmpty, without } from 'lodash'
import { parse } from 'query-string'
import { createSelector } from 'reselect'

import { urlQueryStringSelector } from '../router/selectors'
import { defaultFlightFilters, flightsUrlFiltersConfig } from './flight/flightFiltersState'
import { getAirportUrlParam, urlFiltersParser } from './helpers/urlFilters'
import { FlightInformationStateAware, FlightSort } from './state'
import { defaultTowingFilters, towingUrlFiltersConfig } from './towing/towingFiltersState'

export const flightsUrlFiltersSelector = createSelector(
    urlQueryStringSelector,
    currentUserSiteLocation,
    (params, siteLocation) =>
        urlFiltersParser(
            params || getAirportUrlParam(siteLocation) || FilterOption.All,
            flightsUrlFiltersConfig,
            defaultFlightFilters,
        ),
)

export const towingUrlFiltersSelector = createSelector(urlQueryStringSelector, params =>
    urlFiltersParser(params as string, towingUrlFiltersConfig, defaultTowingFilters),
)

export const hasUrlFiltersSelector = createSelector(
    urlQueryStringSelector,
    (params): boolean => !isEmpty(params),
)

export const filtersCountSelector = createSelector(urlQueryStringSelector, (params): number => {
    const keys = Object.keys(parse(params as string))
    return without(keys, 'filtersVisible', 'arrivalsColumns', 'departuresColumns').length
})

export const lastFiltersSelector = (type: FlightListType) => (state: FlightInformationStateAware) =>
    state.flights.lastFilters[type]

export const sortingSelector = (type: FlightSort) => (state: FlightInformationStateAware) =>
    state.flights.sorting[type]

export const quickSearchQuerySelector = (state: FlightInformationStateAware) =>
    state.flights.quickSearch
