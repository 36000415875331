import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { CountStats, formatCountStats } from 'aos-services/src/services/common/types/Stats'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

export const BaggageDeliveryFullChartTooltipHeader: FC<BaggageDeliveryFullChartTooltipHeaderProps> = props => (
    <Box paddingBottom={8}>
        <span className='text__s-grey'>
            {translate('dashboard.baggage-delivery.full-chart.tooltip-based-on-flights-1')}
        </span>
        <span className='text__s-white'> {formatCountStats(props.counts)}</span>
        <span className='text__s-grey'>
            {translate('dashboard.baggage-delivery.full-chart.tooltip-based-on-flights-2')}
        </span>
        <span className='text__s-white'> {props.time.format('hh:mm')} </span>
    </Box>
)

interface BaggageDeliveryFullChartTooltipHeaderProps {
    counts: CountStats
    time: DateTime
}
