import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PrivateChannelInviteNotification } from 'aos-services/src/services/notifications/types/PrivateChannelInviteNotification'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { HourClock } from 'aos-ui/src/components/time/HourClock'
import { LockIconEventItem } from 'aos-ui-event/src/components/event/LockIconEventItem'
import React, { PureComponent } from 'react'

import { BaseNotificationItem } from './BaseNotificationItem'

export class PrivateChannelInviteNotificationItem extends PureComponent<
    PrivateChannelInviteNotificationItemProps,
    {}
> {
    public render() {
        const {
            notification: { title, read, timestamp, eventId },
        } = this.props
        const onClick = () => this.props.openEventAction(eventId)
        return (
            <BaseNotificationItem
                title={title}
                read={read}
                leftColumn={this.leftColumn}
                onClick={onClick}
            >
                <Box row paddingTop={20}>
                    <FormButton
                        label={translate('private-channel.notification.respond')}
                        onClick={onClick}
                        marginRight={20}
                    />
                    <HourClock time={timestamp} />
                </Box>
            </BaseNotificationItem>
        )
    }

    private get leftColumn() {
        const {
            notification: { severity },
        } = this.props
        return <LockIconEventItem severity={severity} />
    }
}

interface PrivateChannelInviteNotificationItemProps {
    notification: PrivateChannelInviteNotification
    openEventAction(v: number): void
}
