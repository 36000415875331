import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import {
    allDeIcingChemicalsTypes,
    DeIcingChemicalTypeFilter,
    translateDeIcingChemicalsType,
} from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

interface ReportChemicalTypeDropdownProps {
    value: DeIcingChemicalTypeFilter
    onChange(value: DeIcingChemicalTypeFilter): void
}

export const ReportChemicalTypeDropdown: FC<ReportChemicalTypeDropdownProps> = ({
    value,
    onChange,
}) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            width={DropdownWidth.Auto}
            items={[FilterOptionAll.All, ...allDeIcingChemicalsTypes]}
            valueRenderer={translateDeIcingChemicalsType}
            onChange={onChange}
        />
    )
}
