import { cx } from 'aos-components/src/components/base/ClassNames'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { FC, PropsWithChildren } from 'react'

interface StatusDashboardSidebarProps {
    isOpen: boolean
}

export const DashboardSidebar: FC<PropsWithChildren<StatusDashboardSidebarProps>> = props => (
    <aside
        className={cx(
            props.isOpen ? 'dashboard-sidebar--open' : 'dashboard-sidebar--closed',
            'dashboard-sidebar container container--vertical full-height flex-shrink--0 ',
        )}
    >
        <DarkScrollbar>{props.children}</DarkScrollbar>
    </aside>
)
