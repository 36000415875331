import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { DropdownPortalType } from 'aos-ui/src/components/form/dropdown/base/DropdownPortal'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import React from 'react'

interface TableDropdownProps<T> {
    items: T[]
    values: T[]
    onChange(v: T[]): void
    itemToLabel(v: T): string
}

export const TableCheckboxDropdown = <T extends any>(props: TableDropdownProps<T>) => {
    const { values, items, itemToLabel, onChange } = props
    const renderPartialContent = () => {
        if (!values || values?.length === 0) {
            return translate('filter.placeholder.all')
        }

        if (values.length === 1) {
            return itemToLabel(values[0])
        }

        return `(${values.length})`
    }
    return (
        <CheckboxDropdown
            variant={DropdownVariant.Black}
            items={items}
            value={values}
            onChange={onChange}
            allContent={translate('filter.placeholder.all')}
            width={DropdownWidth.Auto}
            partialContent={renderPartialContent}
            valueRenderer={itemToLabel}
            clearAction={() => onChange([])}
            toggleVisible={false}
            portalType={DropdownPortalType.BottomLeft}
        />
    )
}
