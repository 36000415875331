import { calculateDomainForPositiveMax, Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { sum } from 'aos-helpers/src/helpers/Number'
import { getMonth, getWeek, getYear } from 'aos-helpers/src/helpers/Time'
import { getMonthName } from 'aos-helpers/src/helpers/TimeFormat'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DeIcingChemicals } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import { emptyChemicalsReportData } from 'aos-services/src/services/tasksReporting/types/ChemicalsReportData'
import { ChemicalsUsage } from 'aos-services/src/services/tasksReporting/types/ChemicalsUsage'
import { groupBy, keyBy, mapValues, max, range, round, toPairs, uniq, values } from 'lodash'
import { createSelector } from 'reselect'

import { TaskManagerStateAware } from '../../state'
import {
    ReportsFilters,
    ReportsUnit,
    ReportTimeFrequency,
    seasonMonths,
    seasonWeeks,
} from '../state'
import {
    filtersYearsRangeKeys,
    getYearLabel,
    isYearOverlapping,
    reportFiltersSelector,
} from './filters'
import {
    ChartMultiPoint,
    ChartMultiSelectorState,
    ChartSelectorState,
    ChartSinglePoint,
    ChartSingleSelectorState,
    ReportSummarySelectorState,
} from './types/ChartSelectorState'

export interface ChemicalsSelectorState {
    filters: ReportsFilters
    allChemicals: DeIcingChemicals[]
    chartState: ChartSelectorState
    maxYearRange: number[]
    totalSummary: ReportSummarySelectorState
    yearsSummary: ReportSummarySelectorState[]
}

const chemicalsReportDataSelector = (state: TaskManagerStateAware) =>
    state.taskManager.chemicalsReport || emptyChemicalsReportData

export const chemicalsReportSelector = createSelector(
    chemicalsReportDataSelector,
    reportFiltersSelector,
    (report, filters): ChemicalsSelectorState => {
        const maxYearRange = yearRangeForData(report.data)
        const filteredData = filterChemicalsUsage(report.data, report.types, filters)

        const allChemicals = chemicalsForData(report.data, report.types)
        const filteredChemicals = chemicalsForData(filteredData, report.types)

        const isKg = filters.unit === ReportsUnit.Kg
        const convertedData = isKg ? filteredData : convertToLiters(filteredData, report.types)

        const chartState = getChartState(convertedData, filters, filteredChemicals)

        const overlapping = isYearOverlapping(filters)
        const totalSummary = getTotalSummary(
            convertedData,
            filters.yearFrom,
            filters.yearTo,
            overlapping,
        )
        const yearsSummary = range(filters.yearFrom, filters.yearTo).map(year =>
            getSummaryForYear(convertedData, year, overlapping),
        )

        return {
            maxYearRange,
            allChemicals,
            filters,
            chartState,
            totalSummary,
            yearsSummary,
        }
    },
)

const getChartState = (
    data: ChemicalsUsage[],
    filters: ReportsFilters,
    chemicals: DeIcingChemicals[],
) => {
    switch (filters.timeFrequency) {
        case ReportTimeFrequency.Annually:
            if (filters.airport === FilterOptionAll.All) {
                return chartStateAnnuallyForAllAirports(data, filters)
            } else {
                return chartStateAnnuallyForAirport(data, chemicals, filters)
            }

        case ReportTimeFrequency.Monthly:
            return chartStateMonthlyForAllAirport(data, filters)

        case ReportTimeFrequency.Weekly:
            return chartStateWeeklyForAllAirport(data, filters)
    }
}

const chartStateAnnuallyForAllAirports = (
    data: ChemicalsUsage[],
    filters: ReportsFilters,
): ChartMultiSelectorState => {
    const airports = uniq(data.map(item => item.airport))
    const dataByAirport = groupBy(data, item => item.airport)
    const groupedData = toPairs(dataByAirport).map(
        ([airport, items]): ChartMultiPoint => ({
            key: airport,
            data: mapValues(groupBySeasonYear(items, filters), sumUsage),
        }),
    )

    return {
        dataKeys: airports,
        dataSubKeys: filtersYearsRangeKeys(filters),
        data: groupedData,
        yDomain: domainForMultiPoints(groupedData),
    }
}

const chartStateMonthlyForAllAirport = (
    data: ChemicalsUsage[],
    filters: ReportsFilters,
): ChartSingleSelectorState => {
    const { currentYear, nextYear } = seasonMonths[filters.season]
    const months = [...currentYear, ...nextYear].map(getMonthName)

    const dataByMonth = groupBy(data, item => getMonthName(getMonth(item.weekTime)))
    const groupedData = toPairs(dataByMonth).map(
        ([month, items]): ChartSinglePoint => ({
            key: month,
            data: sumUsage(items),
        }),
    )

    return {
        dataKeys: months,
        data: groupedData,
        yDomain: domainForSinglePoints(groupedData),
    }
}

const chartStateWeeklyForAllAirport = (
    data: ChemicalsUsage[],
    filters: ReportsFilters,
): ChartSingleSelectorState => {
    const { currentYear, nextYear } = seasonWeeks[filters.season]
    const weeks = [...currentYear, ...nextYear].map(week => week.toString())

    const dataByWeek = groupBy(data, item => getWeek(item.weekTime))
    const groupedData = toPairs(dataByWeek).map(
        ([week, items]): ChartSinglePoint => ({
            key: week,
            data: sumUsage(items),
        }),
    )

    return {
        dataKeys: weeks,
        data: groupedData,
        yDomain: domainForSinglePoints(groupedData),
    }
}

const chartStateAnnuallyForAirport = (
    data: ChemicalsUsage[],
    chemicals: DeIcingChemicals[],
    filters: ReportsFilters,
): ChartMultiSelectorState => {
    const chemicalLabels = chemicals.map(chemical => chemical.label)
    const chemicalsMap = keyBy(chemicals, 'id')

    const dataByChemicals = groupBy(data, chemical => chemical.chemicalsId)
    const groupedData = toPairs(dataByChemicals).map(
        ([chemicalsId, items]): ChartMultiPoint => ({
            key: chemicalsMap[chemicalsId].label,
            data: mapValues(groupBySeasonYear(items, filters), sumUsage),
        }),
    )

    return {
        dataKeys: chemicalLabels,
        dataSubKeys: filtersYearsRangeKeys(filters),
        data: groupedData,
        yDomain: domainForMultiPoints(groupedData),
    }
}

const groupBySeasonYear = (items: ChemicalsUsage[], filters: ReportsFilters) => {
    const { nextYear } = seasonMonths[filters.season]
    const overlapping = isYearOverlapping(filters)

    return groupBy(items, item => {
        const month = getMonth(item.weekTime)
        const year = getYear(item.weekTime)
        return getYearLabel(nextYear.includes(month) ? year - 1 : year, overlapping)
    })
}

const filterChemicalsUsage = (
    data: ChemicalsUsage[],
    chemicals: DeIcingChemicals[],
    filters: ReportsFilters,
): ChemicalsUsage[] => {
    const chemicalsMap = keyBy(chemicals, 'id')
    const overlapping = isYearOverlapping(filters)

    return data
        .filter(({ chemicalsId }) => chemicalsId)
        .filter(({ airport }) =>
            filters.airport !== FilterOptionAll.All ? airport === filters.airport : true,
        )
        .filter(({ chemicalsId }) => {
            if (filters.chemical !== FilterOptionAll.All) {
                return filters.chemical.id === chemicalsId
            }
            return true
        })
        .filter(({ weekTime }) => {
            const year = getYear(weekTime)
            const week = getWeek(weekTime)
            const month = getMonth(weekTime)
            const toYear = overlapping ? filters.yearTo + 1 : filters.yearTo

            const isWeekly = filters.timeFrequency === ReportTimeFrequency.Weekly
            const weekOrMonth = isWeekly ? week : month
            const { currentYear, nextYear } = isWeekly //
                ? seasonWeeks[filters.season]
                : seasonMonths[filters.season]

            // Strip non selected season months
            // eg: in 2020-2021 winter season we don't wont 2020.01 and 2021.12
            if (overlapping) {
                if (year === filters.yearFrom && nextYear.includes(weekOrMonth)) {
                    return false
                }

                if (year === toYear && currentYear.includes(weekOrMonth)) {
                    return false
                }
            }

            const isBetweenYears = year >= filters.yearFrom && year <= toYear
            const inCurrentSeason =
                currentYear.includes(weekOrMonth) || nextYear.includes(weekOrMonth)
            return isBetweenYears && inCurrentSeason
        })
        .filter(({ chemicalsId }) => {
            const chemicalInfo = chemicalsMap[chemicalsId]

            if (!chemicalInfo) {
                return false
            }

            const chemicalCompoundFilter =
                filters.chemicalCompound !== FilterOptionAll.All
                    ? chemicalInfo.chemicalCompound === filters.chemicalCompound
                    : true

            const chemicalsTypeFilter =
                filters.chemicalType !== FilterOptionAll.All
                    ? chemicalInfo.chemicalsType === filters.chemicalType
                    : true

            return chemicalCompoundFilter && chemicalsTypeFilter
        })
}

const convertToLiters = (
    data: ChemicalsUsage[],
    chemicals: DeIcingChemicals[],
): ChemicalsUsage[] => {
    const chemicalsDensity = (chemicalsId: number) => {
        const chemicalType = chemicals.find(({ id }) => chemicalsId === id)
        if (chemicalType?.density) {
            return chemicalType.density
        }
        return 0
    }

    return data.map(item => ({
        ...item,
        usage: (item.usage / 1000) * chemicalsDensity(item.chemicalsId) * 1000,
    }))
}

const yearRangeForData = (data: ChemicalsUsage[]): number[] => {
    return uniq(data.map(item => getYear(item.weekTime)))
}

const chemicalsForData = (
    data: ChemicalsUsage[],
    chemicals: DeIcingChemicals[],
): DeIcingChemicals[] => {
    const uniqIds = uniq(data.map(v => v.chemicalsId))
    return chemicals.filter(v => uniqIds.includes(v.id))
}

const domainForSinglePoints = (items: ChartSinglePoint[]): Domain<number> => {
    const allValues = items.map(v => v.data)
    return calculateDomain(allValues)
}

const domainForMultiPoints = (items: ChartMultiPoint[]): Domain<number> => {
    const allValues = items.flatMap(v => values(v.data))
    return calculateDomain(allValues)
}

const calculateDomain = (items: number[]): Domain<number> => {
    const maxValue = max(items) || 0
    return calculateDomainForPositiveMax(maxValue)
}

const getTotalSummary = (
    data: ChemicalsUsage[],
    yearFrom: number,
    yearTo: number,
    overlapping: boolean,
): ReportSummarySelectorState => ({
    value: sumUsage(data),
    yearRange: [yearFrom, overlapping ? yearTo + 1 : yearTo],
})

const getSummaryForYear = (
    data: ChemicalsUsage[],
    year: number,
    overlapping: boolean,
): ReportSummarySelectorState => ({
    value: sumUsage(data.filter(({ weekTime }) => getYear(weekTime) === year)),
    yearRange: overlapping ? [year, year + 1] : [year],
})

const sumUsage = (data: ChemicalsUsage[]): number => {
    return round(sum(data.map(item => item.usage)))
}
