import { UserResult } from 'aos-services/src/services/users/types/UserResult'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import React, { FC } from 'react'

import { findUsers } from './findUsers'

export interface UserNamesAutocompleteProps {
    value: UserResult | string | undefined
    onChange(value: UserResult | undefined): void
    placeholder?: string
}

export const UserNamesAutocomplete: FC<UserNamesAutocompleteProps> = ({
    value,
    onChange,
    placeholder,
}) => {
    return (
        <DropdownAutocomplete
            value={value}
            items={findUsers}
            variant={DropdownVariant.White}
            labelRenderer={value => (typeof value === 'string' ? value : value.name)}
            placeholder={placeholder}
            onChange={onChange}
            outlined
        />
    )
}
