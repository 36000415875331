import {
    LOAD_USER_LAYER_VISIBILITY_SUCCESS,
    SET_USER_LAYERS_VISIBILITY,
    UserLayerVisibilityAction,
} from './actions'
import { initialUserLayersVisibilityState, UserLayerVisibilityState } from './state'

export function userLayerVisibilityReducer(
    state = initialUserLayersVisibilityState,
    action: UserLayerVisibilityAction,
): UserLayerVisibilityState {
    switch (action.type) {
        case LOAD_USER_LAYER_VISIBILITY_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                isLoading: false,
                floor: action.payload.floor,
            }

        case SET_USER_LAYERS_VISIBILITY:
            return {
                ...state,
                list: action.payload.list,
                floor: action.payload.floor,
            }

        default:
            return state
    }
}
