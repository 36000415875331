import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { assureRingRailSync } from 'aos-services/src/core/layersData/sagas'
import { firebaseEventsRepository } from 'aos-services/src/dataaccess/firebaseEvents/firebaseEventsRepository'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { feedsService } from 'aos-services/src/services/events/feedsService'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { layerSyncRelatedActions } from 'aos-ui-map/src/core/actions'
import { shouldSyncRingRail } from 'aos-ui-map/src/core/state'
import { call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { State } from '../state'
import {
    EVENT_PREVIEW_MAP_PARENT,
    PREVIEW_EVENT,
    PREVIEW_EVENT_REFRESH,
    PREVIEW_FEED_IN,
    PreviewEventAction,
    previewEventLoadSuccessAction,
    PreviewEventRefreshAction,
    previewEventRefreshAction,
    previewEventRefreshSuccessAction,
    PreviewFeedInAction,
    previewFeedInSuccessAction,
} from './actions'
import { compareEventPreviewStsValues, getEventId } from './state'

function* previewFeedInSaga() {
    function* previewFeedIn(action: PreviewFeedInAction) {
        const event: AosFeedIn = yield call(feedsService.loadFeedIn, action.payload)
        yield put(previewFeedInSuccessAction(event))
    }

    yield takeEvery<PreviewFeedInAction>(PREVIEW_FEED_IN, previewFeedIn)
}

function* previewEventSaga() {
    yield takeEvery<PreviewEventAction>(PREVIEW_EVENT, function* (action: PreviewEventAction) {
        const { eventId: id, openOnMap } = action.payload
        yield fork(syncFirebaseEvent, id)
        const event: AosEvent = yield call(eventsService.loadEvent, id)
        yield put(previewEventLoadSuccessAction({ event, openOnMap }))
    })
}

function* previewEventRefreshSaga() {
    function* previewEventRefresh(action: PreviewEventRefreshAction) {
        const shouldEventPreviewUpdate: boolean = yield select(
            compareEventPreviewStsValues,
            action.payload,
        )
        const eventId: number = yield select(getEventId)
        if (shouldEventPreviewUpdate && eventId) {
            const event: AosEvent = yield call(eventsService.loadEvent, eventId)
            yield put(previewEventRefreshSuccessAction(event))
        }
    }

    yield takeEvery<PreviewEventRefreshAction>(PREVIEW_EVENT_REFRESH, previewEventRefresh)
}

function* syncFirebaseEvent(eventId: number) {
    yield firebaseService.restartListener('eventPreviewEvent', function* () {
        yield syncValue(
            firebaseEventsRepository.sendToSyncRef(eventId),
            ([, sendToSync]: [string, number]) => previewEventRefreshAction(sendToSync),
            true,
        )
    })
}

function* syncRingRailSaga() {
    yield takeEveryChildAction(EVENT_PREVIEW_MAP_PARENT, layerSyncRelatedActions, function* () {
        yield assureRingRailSync(
            (state: State) => shouldSyncRingRail(state.eventPreview.map),
            'eventPreview',
        )
    })
}

export const eventPreviewSagas = [
    previewFeedInSaga,
    previewEventSaga,
    previewEventRefreshSaga,
    syncRingRailSaga,
]
