import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { optionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { AosChecklistItemType } from 'aos-services/src/services/checklists/types/AosCheckListItemType'
import {
    AosEventChecklistItem,
    isTask,
} from 'aos-services/src/services/events/types/AosEventChecklist'
import CheckboxGreenDone from 'aos-ui/src/assets/icons/checkbox-green-done.svg'
import CheckboxWhite from 'aos-ui/src/assets/icons/checkbox-white.svg'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import {
    DarkListItemTextBlock,
    DarkListItemTextBlockVariant,
} from 'aos-ui/src/components/darkListItem/DarkListItemTextBlock'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { ChecklistItemIcon } from '../../../common/ChecklistItemIcon'

interface ChecklistItemProps {
    item: AosEventChecklistItem
    isEditable: boolean
    isEven: boolean
    toggleTask?(): void
    copyMessageTemplate?(v: AosEventChecklistItem): void
}

export const ChecklistItem: FC<ChecklistItemProps> = ({
    item,
    isEditable,
    isEven,
    toggleTask,
    copyMessageTemplate,
}) => {
    const isEditableTask = isTask(item) && isEditable
    const renderContent = () => {
        const { content, title } = item
        return (
            <DarkListItemTextBlock
                title={title}
                subtitle={content}
                noTextOverflow
                variant={DarkListItemTextBlockVariant.DarkListItemChecklist}
            />
        )
    }

    const renderDescription = () => (
        <>
            <ChecklistItemIcon type={AosChecklistItemType.Description} />
            {renderContent()}
        </>
    )

    const renderMessageTemplate = () => {
        const { content } = item
        return (
            <DarkListItemTextBlock
                fullWidth
                title={renderMessageTemplateTitle()}
                subtitle={content}
                noTextOverflow
                variant={DarkListItemTextBlockVariant.DarkListItemChecklist}
            />
        )
    }

    const renderTask = () => (
        <>
            <Box>{renderTaskIcon(!!item.done, isEditable)}</Box>
            {renderContent()}
        </>
    )

    const renderTaskIcon = (done: boolean, editable: boolean) => {
        if (editable) {
            return (
                <Checkbox
                    CustomUncheckedIcon={<CheckboxWhite />}
                    CustomCheckedIcon={<CheckboxGreenDone />}
                    marginLeft={4}
                    marginRight={12}
                    variant={ThemeVariant.White}
                    checked={done}
                    onChange={() => toggleTask && toggleTask()}
                    tickColor={Color.Black}
                    checkBoxBackgroundColor={Color.White}
                />
            )
        } else {
            return (
                <Icon
                    iconSize={BlockSize.Std}
                    iconVariant={IconVariant.GreyTxt}
                    svg={SvgIcon.CheckWhite}
                    transparent={!done}
                />
            )
        }
    }

    const renderMessageTemplateTitle = () => {
        const { title } = item
        const onClick = optionalFunctionCall1Arg(copyMessageTemplate, item)
        return (
            <Box row fullWidth>
                <Box flex={1}>{title}</Box>
                {isEditable && (
                    <IconButton
                        onClick={onClick}
                        iconVariant={IconVariant.BlackGrey}
                        iconSize={BlockSize.Std}
                        svg={SvgIcon.ForwardFat}
                    />
                )}
            </Box>
        )
    }

    const renderMainContent = () => {
        switch (item.type) {
            case AosChecklistItemType.Task:
                return renderTask()

            case AosChecklistItemType.Description:
                return renderDescription()

            case AosChecklistItemType.MessageTemplate:
                return renderMessageTemplate()
        }
    }

    return (
        <>
            <DarkListItem
                rounded
                outlined
                onClick={isEditableTask ? toggleTask : undefined}
                checkedGreen
                column
                padding={12}
                cursor={isEditableTask ? 'pointer' : 'default'}
                justify='space-between'
                lightBg={!isEven}
                selected={item?.done}
                marginBottom={12}
            >
                <Box flex={1} row fullWidth>
                    {renderMainContent()}
                </Box>
                {item?.done && item?.modifiedAt && (
                    <Text
                        marginTop={8}
                        row
                        fullWidth
                        justify='flex-end'
                        size={12}
                        color={Color.Grey8}
                    >
                        {item?.modifiedBy + ' | ' + formatDateTime(item?.modifiedAt)}
                    </Text>
                )}
            </DarkListItem>
        </>
    )
}
