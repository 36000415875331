import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { checklistTemplatesService } from 'aos-services/src/services/checklists/checklistTemplateService'
import { taskChecklistTemplateService } from 'aos-services/src/services/checklists/taskChecklistTemplateService'
import { taskUnitService } from 'aos-services/src/services/checklists/taskUnitService'
import { AosChecklistFilter } from 'aos-services/src/services/checklists/types/AosChecklist'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { AosChecklistTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import { AosUnitTaskItem } from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
    APPLY_ITEM_SEQ_CHANGES,
    ApplyItemSeqChangesAction,
    DELETE_ITEM,
    DeleteItemAction,
    REVIEWED_CHECKLIST_TEMPLATE,
    ReviewedChecklistTemplateAction,
} from './actions'
import { loadChecklistTemplateAction, loadChecklistTemplatesSuccessAction } from './event/actions'
import { eventChecklistSagas } from './event/sagas'
import { ChecklistManagerStateAware } from './state'
import {
    loadTaskChecklistTemplateAction,
    loadTaskChecklistTemplatesSuccessAction,
} from './task/actions'
import { taskChecklistSagas } from './task/sagas'
import { loadUnitTaskTemplatesAction } from './unit/actions'
import { unitTaskSagas } from './unit/sagas'

function* syncChecklistTasksSaga() {
    function* syncList(payload: ApplyItemSeqChangesAction) {
        function* syncChecklistTasks({ payload: checklistTemplateId }: ApplyItemSeqChangesAction) {
            const tasks: AosChecklistItem[] = yield select(
                (state: ChecklistManagerStateAware) =>
                    state.checklistManager.selectedTemplate!.items,
            )
            yield call(checklistTemplatesService.reorderItems, checklistTemplateId, tasks)
        }

        function* syncUnitTaskTasks({ payload: unitTaskTemplateId }: ApplyItemSeqChangesAction) {
            const tasks: AosUnitTaskItem[] = yield select(
                (state: ChecklistManagerStateAware) =>
                    state.checklistManager.selectedTemplate!.items,
            )
            yield call(taskUnitService.reorderItems, unitTaskTemplateId, tasks)
        }

        function* syncTaskChecklistTasks({
            payload: taskChecklistTemplateId,
        }: ApplyItemSeqChangesAction) {
            const tasks: AosChecklistItem[] = yield select(
                (state: ChecklistManagerStateAware) =>
                    state.checklistManager.selectedTemplate!.items,
            )
            yield call(taskChecklistTemplateService.reorderItems, taskChecklistTemplateId, tasks)
        }

        const type: AosChecklistFilter = yield select(
            (state: ChecklistManagerStateAware) => state.checklistManager.headerFilter,
        )

        if (type === AosChecklistFilter.UnitTasks) {
            yield call(syncUnitTaskTasks, payload)
        } else if (type === AosChecklistFilter.EventChecklist) {
            yield call(syncChecklistTasks, payload)
        } else if (type === AosChecklistFilter.TaskChecklist) {
            yield call(syncTaskChecklistTasks, payload)
        }
    }

    yield takeLatest<ApplyItemSeqChangesAction>(APPLY_ITEM_SEQ_CHANGES, syncList)
}

const getDeleteFnByType = (type: AosChecklistFilter) => {
    switch (type) {
        case AosChecklistFilter.EventChecklist:
            return checklistTemplatesService.deleteItem
        case AosChecklistFilter.UnitTasks:
            return taskUnitService.delete
        case AosChecklistFilter.TaskChecklist:
            return taskChecklistTemplateService.deleteItem
    }
}

const getSuccessActionByType = (type: AosChecklistFilter) => {
    switch (type) {
        case AosChecklistFilter.EventChecklist:
            return loadChecklistTemplateAction
        case AosChecklistFilter.UnitTasks:
            return loadUnitTaskTemplatesAction
        case AosChecklistFilter.TaskChecklist:
            return loadTaskChecklistTemplateAction
    }
}

function* deleteChecklistTaskSaga() {
    function* deleteChecklistTask(action: DeleteItemAction) {
        const type: AosChecklistFilter = yield select(
            (state: ChecklistManagerStateAware) => state.checklistManager.headerFilter,
        )
        const deleteFn = getDeleteFnByType(type)
        const successAction = getSuccessActionByType(type)
        yield call(deleteFn, action.payload[0].id, action.payload[1].id)
        yield put(successAction(action.payload[0].id))
        successToast(
            type === AosChecklistFilter.UnitTasks
                ? translate('unit-task.task-deleted')
                : translate('checklist.checklist-item-deleted'),
        )
    }

    yield takeEvery<DeleteItemAction>(DELETE_ITEM, deleteChecklistTask)
}

function* markReviewedSaga() {
    yield takeEvery<ReviewedChecklistTemplateAction>(
        REVIEWED_CHECKLIST_TEMPLATE,
        function* (action) {
            const type: AosChecklistFilter = yield select(
                (state: ChecklistManagerStateAware) => state.checklistManager.headerFilter,
            )
            if (type === AosChecklistFilter.EventChecklist) {
                yield call(checklistTemplatesService.markReviewed, action.payload)
                const checklistTemplates: AosChecklistTemplate[] = yield call(
                    checklistTemplatesService.getAll,
                )
                yield put(loadChecklistTemplatesSuccessAction(checklistTemplates))
                yield put(loadChecklistTemplateAction(action.payload))
            } else if (type === AosChecklistFilter.TaskChecklist) {
                yield call(taskChecklistTemplateService.markReviewed, action.payload)
                const currentLocation: AosLocation = yield select(currentUserSiteLocation)
                const result: AosTaskChecklistTemplate[] = yield call(
                    taskChecklistTemplateService.getAll,
                    currentLocation,
                )
                yield put(loadTaskChecklistTemplatesSuccessAction(result))
                yield put(loadTaskChecklistTemplateAction(action.payload))
            }
            successToast(translate('checklist.marked-reviewed'))
        },
    )
}

export const checklistManagerSagas = [
    syncChecklistTasksSaga,
    deleteChecklistTaskSaga,
    markReviewedSaga,
    ...unitTaskSagas,
    ...taskChecklistSagas,
    ...eventChecklistSagas,
]
