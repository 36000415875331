import { DateTime } from 'aos-helpers/src/helpers/Time'
import { getDataFromPeriod, TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { EnumValues } from 'enum-values'
import { chain, identity, uniq } from 'lodash'
import { Duration } from 'moment'

import { IataCodeName } from '../iata/types/IataCodeName'
import { BaggageClaimProperties } from '../layerData/properties/BaggageClaimProperties'
import { busDoorNumber, BusDoorProperties } from '../layerData/properties/BusDoorProperties'
import { gateToGatesArray } from '../layerData/properties/GatesProperties'
import { standAsGatesProperties } from '../layerData/properties/StandProperties'
import { SelectedMapElementType } from '../map/types/SelectedMapElement'
import { AosAirport } from './types/AosAirport'
import { AosHandlingAgent } from './types/AosHandlingAgent'
import {
    FlightNatureCode,
    NatureOfFlightGroup,
    natureOfFlightGroups,
} from './types/AosNatureOfFlight'
import { Flight } from './types/Flight'
import {
    AirportFilter,
    FilterOption,
    FlightInfoFilters,
    FlightInfoSelectedFeature,
} from './types/FlightInfoFilters'

class FlightFilterService {
    public getPriorityAirlines = () => ['Finnair', 'Norwegian Air International', 'SAS']

    public pickUniqAirlinesFromList(flights: Flight[]) {
        return chain(flights)
            .map(f => f.airline)
            .compact()
            .uniqBy('code')
            .sortBy('name')
            .value() as IataCodeName[]
    }

    public pickUniqAircrafts = (state: Flight[]): string[] =>
        chain(state)
            .map(item => item.aircraft?.name)
            .compact()
            .uniq()
            .value()

    public pickUniqHandling = (state: Flight[]): AosHandlingAgent[] =>
        chain(state)
            .map(item => item.handl)
            .compact()
            .uniq()
            .filter(item => item !== AosHandlingAgent.Unknown)
            .value()

    public pickUniqNatureOfTheFlight = (state: Flight[]): string[] => {
        const nafltData = chain(state)
            .map(item => item.naflt.code)
            .compact()
            .uniq()
            .value()
        return EnumValues.getValues<keyof typeof natureOfFlightGroups>(NatureOfFlightGroup).filter(
            (groupName: keyof typeof natureOfFlightGroups) =>
                groupName === NatureOfFlightGroup.Other ||
                natureOfFlightGroups[groupName].some(code => nafltData.includes(code)),
        )
    }

    public pickUniqPrioritizedAirlines = (state: Flight[]) => {
        const airlines = chain(state)
            .map(item => item.airline)
            .compact()
            .map((item: IataCodeName) => item!.name)
            .value()

        return uniq([...this.getPriorityAirlines(), ...airlines.sort((a, b) => a.localeCompare(b))])
    }

    public isAirportRelatedTo = (filter: AirportFilter) => (flight: Flight) => {
        if ((filter as FilterOption[]).includes(FilterOption.All)) {
            return true
        }
        return (filter as AosAirport[]).includes(flight.hapt)
    }

    public isGateRelatedTo = (filter: string) => (flight: Flight) => flight.gate.current === filter

    public isAnyGateRelatedTo = (filter: string[]) => (flight: Flight) =>
        flight.gate && flight.gate.current ? filter.indexOf(flight.gate.current) !== -1 : false

    public isLooseStandRelatedTo = (filter: string) => (flight: Flight) =>
        flight.park ? flight.park.current === filter : false

    public isBeltRelatedTo = (filter: BaggageClaimProperties) => (flight: Flight) =>
        flight.bltarea === filter.belt

    public isBusDoorRelatedTo = (filter: number) => (flight: Flight) => `${filter}` === flight.door

    public getFilteredArrivals = (
        arrivals: Flight[],
        filters: FlightInfoFilters,
        airport: AirportFilter,
        selectedFeature?: FlightInfoSelectedFeature,
    ) => {
        if (selectedFeature) {
            const byAirport = this.isAirportRelatedTo(airport)
            return this.getArrivalsForFeature(arrivals, selectedFeature).filter(byAirport)
        }

        return this.getFilteredItems(arrivals, filters, airport)
    }

    public getFilteredDepartures = (
        departures: Flight[],
        filters: FlightInfoFilters,
        airport: AirportFilter,
        selectedFeature?: FlightInfoSelectedFeature,
    ) => {
        if (selectedFeature) {
            const byAirport = this.isAirportRelatedTo(airport)
            return this.getDeparturesForFeature(departures, selectedFeature).filter(byAirport)
        }

        return this.getFilteredItems(departures, filters, airport)
    }

    public getFlightsWithBags = <T extends Flight>(flights: T[]) =>
        flights.filter(flight => flight.bag.hasBag)

    public flightsToDeliveryTimes = <T extends Flight>(flights: T[], now: DateTime): Duration[] =>
        flights.map(f => f.bag.deliveryTime(now))

    public getFlightsFromPeriod = <T extends Flight>(flights: T[], period: TimeRange): T[] =>
        getDataFromPeriod((flight: T) => flight.sdt)(flights, period)

    private getFilteredItems = <T extends Flight>(
        items: T[],
        filters: FlightInfoFilters,
        airport: AirportFilter,
    ): T[] => {
        const byText = filters.filteredText
            ? this.isFlightMatchedByText(filters.filteredText)
            : identity
        const byAirport = this.isAirportRelatedTo(airport)
        const checkboxFilters = filters.checkboxFilters
        const byAirline = checkboxFilters.airlines.length
            ? this.filterByAirline(checkboxFilters.airlines)
            : identity
        const byHandling = checkboxFilters.handling.length
            ? this.filterByHandling(checkboxFilters.handling)
            : identity
        const byAircraft = checkboxFilters.aircrafts.length
            ? this.filterByAircraft(checkboxFilters.aircrafts)
            : identity
        const byNatureOfFlight = checkboxFilters.natureOfTheFlight.length
            ? this.filterByNatureOfFlight(
                  checkboxFilters.natureOfTheFlight as NatureOfFlightGroup[],
              )
            : identity

        return items
            .filter(byAirport)
            .filter(byAirline)
            .filter(byHandling)
            .filter(byAircraft)
            .filter(byNatureOfFlight)
            .filter(byText)
    }

    private filterByAirline = (airlines: string[]) => (flight: Flight) => {
        if (!flight.airline) {
            return false
        }
        return airlines.includes(flight.airline.name)
    }

    private filterByHandling = (handling: string[]) => (flight: Flight) => {
        if (!flight.handl) {
            return false
        }
        return handling.includes(flight.handl)
    }

    private filterByAircraft = (aircrafts: string[]) => (flight: Flight) => {
        if (!flight.aircraft) {
            return false
        }
        return aircrafts.includes(flight.aircraft.name)
    }

    private filterByNatureOfFlight = (nafltGroups: NatureOfFlightGroup[]) => (flight: Flight) => {
        const code = flight.naflt.code
        if (!code || !EnumValues.getNameFromValue(FlightNatureCode, code)) {
            return nafltGroups.includes(NatureOfFlightGroup.Other)
        }

        return nafltGroups.some(groupName => natureOfFlightGroups[groupName].includes(code))
    }

    private isFlightMatchedByText = (text?: string) => (flight: Flight) => flight.quickMatch(text)

    private filterItemByGates =
        (filter: string[]) =>
        (f: Flight): boolean =>
            this.isAnyGateRelatedTo(filter)(f)

    private filterItemByStand =
        (filter: string) =>
        (f: Flight): boolean =>
            this.isLooseStandRelatedTo(filter)(f)

    private filterItemByBelt =
        (filter: BaggageClaimProperties) =>
        (f: Flight): boolean =>
            this.isBeltRelatedTo(filter)(f)

    private getDeparturesForFeature = <T extends Flight>(
        items: T[],
        selectedFeature: FlightInfoSelectedFeature,
    ): T[] => {
        switch (selectedFeature.type) {
            case SelectedMapElementType.Gate:
            case SelectedMapElementType.BusGate:
                return this.getItemsForGates(items, gateToGatesArray(selectedFeature.payload))

            case SelectedMapElementType.Stand:
                return this.getItemsForStand(items, selectedFeature.payload.teksti)
        }

        return []
    }
    private getArrivalsForFeature = (
        items: Flight[],
        selectedFeature: FlightInfoSelectedFeature,
    ): Flight[] => {
        switch (selectedFeature.type) {
            case SelectedMapElementType.Gate:
            case SelectedMapElementType.BusGate:
                return this.getItemsForStand(
                    items,
                    standAsGatesProperties(selectedFeature.payload).teksti,
                )

            case SelectedMapElementType.Stand:
                return this.getItemsForStand(items, selectedFeature.payload.teksti)

            case SelectedMapElementType.BaggageClaim:
                return this.getItemsForBelt(items, selectedFeature.payload)

            case SelectedMapElementType.BusDoor:
                return this.getItemsForBusDoor(items, selectedFeature.payload)
        }

        return []
    }

    private getItemsForGates = <T extends Flight>(items: T[], gates: string[]): T[] =>
        items.filter(this.filterItemByGates(gates))

    private getItemsForStand = <T extends Flight>(items: T[], stand: string): T[] =>
        items.filter(this.filterItemByStand(stand))

    private getItemsForBelt = (items: Flight[], belt: BaggageClaimProperties): Flight[] =>
        items.filter(this.filterItemByBelt(belt))

    private getItemsForBusDoor = (items: Flight[], door: BusDoorProperties): Flight[] =>
        items.filter(this.isBusDoorRelatedTo(busDoorNumber(door)))
}

export const flightFilterService = new FlightFilterService()
