import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { mapValues } from 'lodash'

import { RangesDto, WeatherRangeDto, WidgetRangeDto } from '../../dataaccess/ranges/types/RangesDto'
import { CdmMetricType } from '../airportStatus/cdm/types/CdmMetricType'
import { WidgetStatusRange } from '../common/types/StatusRange'
import { Ranges, WeatherRange } from './types/Ranges'

class RangesService {
    public rangesFromDto = (dto: RangesDto): Ranges => ({
        cdm: mapValues<Record<CdmMetricType, WidgetStatusRange[]>, WidgetStatusRange[]>(
            getInitialValuesForEnumRecord<CdmMetricType, WidgetStatusRange[]>(CdmMetricType, []),
            (_v: WidgetStatusRange[], key: string) =>
                this.widgetRangeDtosToStatusRanges(dto.CDM[key] || []),
        ),
        weather: this.weatherRangesForDto(dto.WEATHER),
        tobt: dto.TOBT,
    })

    private widgetRangeDtosToStatusRanges = (dtos: WidgetRangeDto[]): WidgetStatusRange[] =>
        (dtos || []).map(this.widgetRangeDtoToStatusRange)

    private widgetRangeDtoToStatusRange = (dto: WidgetRangeDto): WidgetStatusRange =>
        dto as WidgetStatusRange

    private weatherRangesForDto = (dto: WeatherRangeDto): WeatherRange => dto as WeatherRange
}

export const rangesService = new RangesService()
