import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { CSSObject, keyframes } from 'styled-components'

import { DropdownRoot } from '../appcontainer/AppContainer'
import { Box, BoxProps } from '../base/Box'
import { DropdownRootProvider } from '../form/dropdown/base/DropdownRoot'
import { useAnimationEndTrigger } from '../hooks/useAnimationEnd'
import { ModalKind } from './ModalKind'

interface ModalContainerProps extends BoxProps {
    id?: string
    size?: ModalKind
}

export const ModalContainer: FCWithChildren<PropsWithChildren<ModalContainerProps>> = ({
    id,
    size = ModalKind.Auto,
    children,
    ...rest
}) => {
    const { trigger } = useAnimationEndTrigger()
    const rootRef = React.createRef<HTMLDivElement>()
    const containerRef = React.createRef<HTMLDivElement>()
    return (
        <DropdownRootProvider
            value={{
                container: containerRef,
                dropdownRoot: rootRef,
            }}
        >
            <Box relative ref={containerRef}>
                <Container
                    id={id}
                    column
                    data-test-id='modal-container'
                    onAnimationEnd={trigger}
                    size={size}
                    {...rest}
                >
                    {children}
                </Container>
                <DropdownRoot ref={rootRef} />
            </Box>
        </DropdownRootProvider>
    )
}

const kindStyles: Record<ModalKind, CSSObject> = {
    [ModalKind.Big]: {
        width: '860px',
        height: '810px',
    },
    [ModalKind.Icon]: {
        width: '400px',
        minHeight: '200px',
    },
    [ModalKind.White]: {
        width: '560px',
        minHeight: '100px',
    },
    [ModalKind.Medium]: {
        width: '660px',
        minHeight: '100px',
    },
    [ModalKind.Standard]: { width: '860px', height: '600px' },
    [ModalKind.MediumFixedHeight]: { width: '660px', height: '580px' },
    [ModalKind.Small]: { width: '460px', minHeight: '100px' },
    [ModalKind.Mobile]: { width: '80vw', minHeight: '100px' },
    [ModalKind.Auto]: { minHeight: '100px' },
    [ModalKind.LessThanMedium]: { width: '500px', minHeight: '100px' },
}

const showIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Container = styled(Box)<{ size: ModalKind }>`
    max-height: 95vh;
    border-radius: 5px;
    background: ${Color.White};
    animation: ${showIn} 0.3s ease both;
    overflow: hidden;
    ${p => kindStyles[p.size]};
`
