import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Ranges } from '../../services/ranges/types/Ranges'
import { DashboardItemStatuses } from '../../services/statusDashboard/types/DashboardItemStatus'
import {
    DashboardItemHealths,
    DashboardItemHealthsForAirport,
} from '../../services/widgetHealths/type/DashboardItemHealths'

export const SET_DASHBOARD_STATUSES = 'STATUS_DASHBOARD/SET_DASHBOARD_STATUSES'
export type SetDashboardStatusesAction = PayloadAction<
    typeof SET_DASHBOARD_STATUSES,
    DashboardItemStatuses
>
export const setDashboardStatusesAction = payloadActionCreator<
    SetDashboardStatusesAction,
    DashboardItemStatuses
>(SET_DASHBOARD_STATUSES)

export const SET_DASHBOARD_RANGES = 'STATUS_DASHBOARD/SET_DASHBOARD_RANGES'
export type SetDashboardRangesAction = PayloadAction<typeof SET_DASHBOARD_RANGES, Ranges>
export const setDashboardRangesAction = payloadActionCreator<SetDashboardRangesAction, Ranges>(
    SET_DASHBOARD_RANGES,
)

export const SET_DASHBOARD_HEALTHS = 'STATUS_DASHBOARD/SET_DASHBOARD_HEALTHS'
export type SetDashboardHealthsAction = PayloadAction<
    typeof SET_DASHBOARD_HEALTHS,
    DashboardItemHealths
>
export const setDashboardHealthsAction = payloadActionCreator<
    SetDashboardHealthsAction,
    DashboardItemHealths
>(SET_DASHBOARD_HEALTHS)

export const SET_DASHBOARD_HEALTHS_FOR_AIRPORT =
    'STATUS_DASHBOARD/SET_DASHBOARD_HEALTHS_FOR_AIRPORT'
export type SetDashboardHealthsForAirportAction = PayloadAction<
    typeof SET_DASHBOARD_HEALTHS_FOR_AIRPORT,
    DashboardItemHealthsForAirport
>
export const setDashboardHealthsForAirportAction = payloadActionCreator<
    SetDashboardHealthsForAirportAction,
    DashboardItemHealthsForAirport
>(SET_DASHBOARD_HEALTHS_FOR_AIRPORT)

export const SYNC_STATUS_DASHBOARD = 'STATUS_DASHBOARD/SYNC_STATUS_DASHBOARD'
export type SyncStatusDashboardAction = Action<typeof SYNC_STATUS_DASHBOARD>
export const syncStatusDashboardAction = emptyActionCreator<SyncStatusDashboardAction>(
    SYNC_STATUS_DASHBOARD,
)

export const SYNC_STATUS_DASHBOARD_STOP = 'STATUS_DASHBOARD/SYNC_STATUS_DASHBOARD_STOP'
export type SyncStatusDashboardStopAction = Action<typeof SYNC_STATUS_DASHBOARD_STOP>
export const syncStatusDashboardStopAction = emptyActionCreator<SyncStatusDashboardStopAction>(
    SYNC_STATUS_DASHBOARD_STOP,
)

export type BaseStatusDashboardAction =
    | SetDashboardStatusesAction
    | SetDashboardHealthsAction
    | SetDashboardHealthsForAirportAction
    | SetDashboardRangesAction
    | SyncStatusDashboardAction
    | SyncStatusDashboardStopAction
