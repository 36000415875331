import { emptyText } from 'aos-helpers/src/helpers/Text'
import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

import { RingRailTrainScheduleStatus, RingRailTrainStatus } from '../layerData/types/RingRailTrain'

export const trainScheduleStatus = (s: RingRailTrainScheduleStatus) =>
    translateEnum<RingRailTrainScheduleStatus>(
        RingRailTrainScheduleStatus,
        'train-details.schedule.status',
    )(s)

export const trainStatus = (s: RingRailTrainStatus) =>
    translateEnum<RingRailTrainStatus>(RingRailTrainStatus, 'train-details.status')(s)

export const trainSpeed = (s?: number) => (s ? `${s.toString()} km/h` : emptyText())
