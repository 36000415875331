import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { NewGlobalMessage } from 'aos-services/src/services/messages/input/NewGlobalMessage'
import { AosGlobalMessage } from 'aos-services/src/services/messages/types/AosGlobalMessage'

export const TOGGLE_GLOBAL_MESSAGING = 'GLOBAL_MESSAGING/TOGGLE_GLOBAL_MESSAGING'
export type ToggleGlobalMessagingAction = PayloadAction<typeof TOGGLE_GLOBAL_MESSAGING, boolean>
export const toggleGlobalMessagingAction = payloadActionCreator<
    ToggleGlobalMessagingAction,
    boolean
>(TOGGLE_GLOBAL_MESSAGING)

export const LOAD_GLOBAL_MESSAGES = 'GLOBAL_MESSAGING/LOAD_EVENTS'
export type LoadGlobalMessagesAction = Action<typeof LOAD_GLOBAL_MESSAGES>
export const loadGlobalMessagesAction = emptyActionCreator<LoadGlobalMessagesAction>(
    LOAD_GLOBAL_MESSAGES,
)

export const LOAD_GLOBAL_MESSAGES_SUCCESS = 'GLOBAL_MESSAGING/LOAD_EVENTS_SUCCESS'
export type LoadGlobalMessagesSuccessAction = PayloadAction<
    typeof LOAD_GLOBAL_MESSAGES_SUCCESS,
    AosGlobalMessage[]
>
export const loadGlobalMessagesSuccessAction = payloadActionCreator<
    LoadGlobalMessagesSuccessAction,
    AosGlobalMessage[]
>(LOAD_GLOBAL_MESSAGES_SUCCESS)

export const CHANGE_GLOBAL_MESSAGING_FORM = 'GLOBAL_MESSAGING/CHANGE_FORM'
export type ChangeGlobalMessagingFormAction = PayloadAction<
    typeof CHANGE_GLOBAL_MESSAGING_FORM,
    NewGlobalMessage
>
export const changeGlobalMessagingFormAction = payloadActionCreator<
    ChangeGlobalMessagingFormAction,
    NewGlobalMessage
>(CHANGE_GLOBAL_MESSAGING_FORM)

export const TOGGLE_GLOBAL_MESSAGE_CONFIRMATION =
    'GLOBAL_MESSAGING/TOGGLE_GLOBAL_MESSAGE_CONFIRMATION'
export type ToggleGlobalMessageConfirmationAction = PayloadAction<
    typeof TOGGLE_GLOBAL_MESSAGE_CONFIRMATION,
    boolean
>
export const toggleGlobalMessageConfirmationAction = payloadActionCreator<
    ToggleGlobalMessageConfirmationAction,
    boolean
>(TOGGLE_GLOBAL_MESSAGE_CONFIRMATION)

export const BROADCAST_GLOBAL_MESSAGE_REQUEST = 'GLOBAL_MESSAGING/BROADCAST_GLOBAL_MESSAGE_REQUEST'
export type BroadcastGlobalMessageRequestAction = PayloadAction<
    typeof BROADCAST_GLOBAL_MESSAGE_REQUEST,
    NewGlobalMessage
>
export const broadcastGlobalMessageRequestAction = payloadActionCreator<
    BroadcastGlobalMessageRequestAction,
    NewGlobalMessage
>(BROADCAST_GLOBAL_MESSAGE_REQUEST)

export const BROADCAST_GLOBAL_MESSAGE_SUCCESS = 'GLOBAL_MESSAGING/BROADCAST_GLOBAL_MESSAGE_SUCCESS'
export type BroadcastGlobalMessageSuccessAction = Action<typeof BROADCAST_GLOBAL_MESSAGE_SUCCESS>
export const broadcastGlobalMessageSuccessAction = emptyActionCreator<BroadcastGlobalMessageSuccessAction>(
    BROADCAST_GLOBAL_MESSAGE_SUCCESS,
)

export type MessagingAction =
    | ToggleGlobalMessagingAction
    | LoadGlobalMessagesAction
    | LoadGlobalMessagesSuccessAction
    | ChangeGlobalMessagingFormAction
    | ToggleGlobalMessageConfirmationAction
    | BroadcastGlobalMessageRequestAction
    | BroadcastGlobalMessageSuccessAction
