import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { LoginStatus } from './state'

export const SET_LOGIN_STATUS = 'LOGIN/SET_LOGIN_STATUS'
export type SetLoginStatusAction = PayloadAction<typeof SET_LOGIN_STATUS, LoginStatus>
export const setLoginStatusAction = payloadActionCreator<SetLoginStatusAction, LoginStatus>(
    SET_LOGIN_STATUS,
)

export const RESET_LOGIN = 'LOGIN/RESET_DIALOG'
export type ResetLoginAction = Action<typeof RESET_LOGIN>
export const resetLoginAction = emptyActionCreator<ResetLoginAction>(RESET_LOGIN)

export type LoginAction = SetLoginStatusAction | ResetLoginAction
