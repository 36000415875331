import { MailingRestService } from '../../dataaccess/mailing/MailingRestService'

enum MailTemplates {
    Link = 'link',
}

export interface SendLinkPayload {
    email: string
    note: string
    link: string
}

export class MailingService {
    constructor(private mailingRestService: MailingRestService) {}

    public sendLink = ({ email, link, note }: SendLinkPayload) => {
        this.mailingRestService.send(MailTemplates.Link, email, { link, note })
    }
}

export const mailingService = new MailingService(new MailingRestService())
