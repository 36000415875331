import { DateTime } from 'aos-helpers/src/helpers/Time'

import { TimePoint } from '../../base/types/TimePoint'
import { getPaxForecastColor } from '../paxForecastConstants'
import { PaxForecastArrivalOrDeparture } from './PaxForecastArrivalOrDeparture'
import { PaxForecastDataSource } from './PaxForecastDataSource'
import { PaxLocalOrTransfer } from './PaxLocalOrTransfer'

export interface PaxForecastDaysChartPoint {
    time: DateTime
    [PaxForecastDataSource.Forecast]: { pax: number; flightsCount: number }
    [PaxForecastDataSource.LastYear]: { pax: number; flightsCount: number }
}

export const getMaxPaxForecastChartPoint = (p: PaxForecastDaysChartPoint) =>
    Math.max(p[PaxForecastDataSource.Forecast].pax, p[PaxForecastDataSource.LastYear].pax)

export const getDayColor = (val: PaxForecastDaysChartPoint) =>
    getPaxForecastColor(val[PaxForecastDataSource.Forecast].pax)

export interface PaxForecastHoursChartPoint
    extends Record<PaxForecastArrivalOrDeparture, number>,
        TimePoint {}

export interface PaxForecastTimeOfDayChartPoint
    extends Record<PaxLocalOrTransfer, number>,
        TimePoint {}

export enum PaxForecastChartType {
    Days,
    Hours,
    TimeOfDay,
}

export type PaxForecastChartData =
    | { type: PaxForecastChartType.Hours; data: PaxForecastHoursChartPoint[] }
    | { type: PaxForecastChartType.Days; data: PaxForecastDaysChartPoint[] }
    | { type: PaxForecastChartType.TimeOfDay; data: PaxForecastTimeOfDayChartPoint[] }
