export const formatNumber = (n: number): string => {
    const parts = n.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
}

export const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0)

export const isNumber = (value: string | undefined): boolean => {
    if (value) {
        const parsed = parseInt(value, 10)

        return !isNaN(parsed)
    }

    return false
}

export const checkMinMax = (
    v: number | undefined,
    min: number | undefined,
    max: number | undefined,
) => {
    if (v === undefined) {
        return true
    }
    if (min !== undefined && v < min) {
        return false
    }
    return !(max !== undefined && v > max)
}
