import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { busDoorSubtitle } from 'aos-services/src/services/layerData/properties/BusDoorProperties'
import { selectionTargetTitle } from 'aos-services/src/services/map/selectionTargetFormatter'
import { MapPosition } from 'aos-services/src/services/map/types/MapPosition'
import {
    SelectedMapElement,
    SelectedMapElementType,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { mapPositionChangedAction } from 'aos-ui-map/src/core/actions'
import React, { FC } from 'react'
import { connect } from 'react-redux'

import { airportMapMapParentAction } from '../../core/airportMap/actions'
import { openEventAction } from '../../core/eventEditor/actions'
import { State } from '../../core/state'
import { openTaskAction } from '../../core/tasks/actions'
import { BaggageClaimDetailsSidebar } from './sidebars/BaggageClaimDetailsSidebar'
import { BusDoorDetailsSidebar } from './sidebars/BusDoorDetailsSidebar'
import { BusGateDetailsSidebar } from './sidebars/BusGateDetailsSidebar'
import { DefaultSidebar } from './sidebars/DefaultSidebar'
import { EventDetailsSidebar } from './sidebars/EventDetailsSidebar'
import { GateDetailsSidebar } from './sidebars/GateDetailsSidebar'
import { StandDetailsSidebar } from './sidebars/StandDetailsSidebar'
import { TaskDetailsSidebar } from './sidebars/TaskDetailsSidebar'
import { TrainDetailsSidebar } from './sidebars/TrainDetailsSidebar'

export const SidebarStackClass: FC<SidebarStackStateProps & SidebarStackDispatchProps> = ({
    variant,
    selectedElement,
    selectionDetails,
    openEvent,
    openTask,
    mapPositionChangedAction,
}) => {
    if (selectedElement) {
        const title = selectedElement && selectionTargetTitle(selectedElement)

        switch (selectedElement.type) {
            case SelectedMapElementType.Gate:
                return <GateDetailsSidebar selectionTarget={selectedElement} title={title} />

            case SelectedMapElementType.BusGate:
                return <BusGateDetailsSidebar selectionTarget={selectedElement} title={title} />

            case SelectedMapElementType.Stand:
                return <StandDetailsSidebar selectionTarget={selectedElement} title={title} />

            case SelectedMapElementType.BaggageClaim:
                return (
                    <BaggageClaimDetailsSidebar selectionTarget={selectedElement} title={title} />
                )

            case SelectedMapElementType.BusDoor:
                const subtitle = busDoorSubtitle(selectedElement.payload)
                return (
                    <BusDoorDetailsSidebar
                        selectionTarget={selectedElement}
                        title={title}
                        subtitle={subtitle}
                    />
                )

            case SelectedMapElementType.Train:
                return <TrainDetailsSidebar train={selectedElement.payload.train} />

            case SelectedMapElementType.Event:
                if (selectionDetails) {
                    return (
                        <EventDetailsSidebar
                            mapVariant={variant}
                            event={selectionDetails as AosEvent}
                            openEventAction={openEvent}
                            setMapPositionAction={mapPositionChangedAction}
                        />
                    )
                }
                break

            case SelectedMapElementType.Task:
                if (selectionDetails) {
                    return (
                        <TaskDetailsSidebar
                            mapVariant={variant}
                            openTaskAction={openTask}
                            task={selectionDetails as Task}
                            setMapPositionAction={mapPositionChangedAction}
                        />
                    )
                }
                break
        }
    }

    return <DefaultSidebar variant={variant} />
}

interface SidebarStackStateProps {
    variant: MapVariant
    selectedElement: SelectedMapElement | null
    selectionDetails?: AosEvent | Task
}

interface SidebarStackDispatchProps {
    openEvent(v: number): void
    openTask(v: TaskId): void
    mapPositionChangedAction(v: [MapVariant, MapPosition]): void
}

export const SidebarStack = connect<SidebarStackStateProps, SidebarStackDispatchProps>(
    (state: State) => ({
        variant: state.airportMap.variant,
        selectedElement: state.airportMap.selectedElement,
        selectionDetails: state.airportMap.selectionDetails,
    }),
    {
        openEvent: openEventAction,
        openTask: (v: TaskId) => openTaskAction([TaskFilter.All, v]),
        mapPositionChangedAction: (v: [MapVariant, MapPosition]) =>
            airportMapMapParentAction(mapPositionChangedAction(v)),
    },
)(SidebarStackClass)
