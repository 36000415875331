import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskFormPayload } from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'

export const clearingTitleOnCategoryChange = (
    prevTask: TaskFormPayload | undefined,
    task: TaskFormPayload | undefined,
    changeForm: (t: TaskFormPayload) => void,
) => {
    if (
        (prevTask?.type !== task?.type || prevTask?.category !== task?.category) &&
        task?.title !== undefined
    ) {
        changeForm({ ...task, title: undefined })
    }
}

export function changeTitleForSimpleCategory(
    prevTask: TaskFormPayload | undefined,
    task: TaskFormPayload,
    changeForm: (t: TaskFormPayload) => void,
) {
    if (prevTask?.category !== task?.category && task?.category === TaskCategory.HBA) {
        changeForm({
            ...task,
            title: translate('tasks.form.category.selector.title.heavy-bird-activity'),
        })
    }

    if (prevTask?.category !== task?.category && task?.category === TaskCategory.LVP) {
        changeForm({
            ...task,
            title: translate('tasks.form.category.selector.title.low-visibility-procedure'),
        })
    }
}
