import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Towing } from '../../services/flightInformation/types/towing/Towing'
import { SyncDataKey } from '../../services/statusDashboard/types/DashboardDataState'

export const TOWING_SYNC = 'TOWINGS/SYNC'
export type TowingSyncAction = PayloadAction<typeof TOWING_SYNC, SyncDataKey>
export const towingSyncAction = payloadActionCreator<TowingSyncAction, SyncDataKey>(TOWING_SYNC)

export const TOWING_SYNC_STOP = 'TOWINGS/SYNC_STOP'
export type TowingSyncStopAction = PayloadAction<typeof TOWING_SYNC_STOP, SyncDataKey>
export const towingSyncStopAction = payloadActionCreator<TowingSyncStopAction, SyncDataKey>(
    TOWING_SYNC_STOP,
)

export const TOWING_LOAD = 'TOWINGS/LOAD'
export type TowingLoadAction = Action<typeof TOWING_LOAD>
export const towingLoadAction = emptyActionCreator<TowingLoadAction>(TOWING_LOAD)

export const TOWING_LOAD_SUCCESS = 'TOWINGS/LOAD_SUCCESS'
export type TowingLoadSuccessAction = PayloadAction<typeof TOWING_LOAD_SUCCESS, Towing[]>
export const towingLoadSuccessAction = payloadActionCreator<TowingLoadSuccessAction, Towing[]>(
    TOWING_LOAD_SUCCESS,
)

export type TowingActions =
    | TowingSyncAction
    | TowingSyncStopAction
    | TowingLoadAction
    | TowingLoadSuccessAction
