import { enumRecord } from 'aos-helpers/src/helpers/Enum'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { some } from 'lodash'
import { uniqBy } from 'lodash'
import moment, { Duration } from 'moment'

import { InspectionItemPayload } from '../payload/InspectionTaskFormPayload'
import { InspectionMetadataType } from '../TaskMetadata'
import { InspectionGroup } from './InspectionGroup'

export type InspectionSelection = [InspectionMetadataType | 'ALL', boolean]

export interface InspectionConfig {
    group: InspectionGroup
    items: InspectionItemPayload[]
    inspectionLate: boolean
    selectedItems: InspectionItemPayload[]
}

export type InspectionTypeGroup = Record<InspectionGroup, InspectionConfig>

export const inspectionTypeToGroups = (
    types: InspectionMetadataType[],
    now: DateTime,
    selectedInspections: InspectionItemPayload[],
): InspectionTypeGroup => {
    const selectionForInspection = (group: InspectionGroup): InspectionConfig => {
        const allOfGroup = types
            .filter(i => i.inspectionGroup === group)
            .map(type => ({
                inspectionType: {
                    id: type.id,
                    name: type.name,
                    period: type.period,
                    inspectionGroup: type.inspectionGroup,
                    taskEndTime: type.taskEndTime,
                },
            })) as InspectionItemPayload[]

        const inspectionLate = some(allOfGroup, i => !!getLateDuration(i, now))
        const selectedGroupedInspections = allOfGroup.filter(i =>
            selectedInspections.map(i => i.inspectionType.id).includes(i.inspectionType.id),
        )
        return {
            items: allOfGroup,
            inspectionLate,
            group,
            selectedItems: selectedGroupedInspections,
        }
    }

    return enumRecord(InspectionGroup, selectionForInspection)
}

export const diffSelection = (
    selectedInspections: InspectionItemPayload[],
    currentPayload: InspectionItemPayload[],
    inspectionGroup: InspectionGroup,
): InspectionItemPayload[] => {
    const clearedPayload = currentPayload.filter(
        i => i.inspectionType.inspectionGroup !== inspectionGroup,
    )
    return uniqBy(clearedPayload.concat(selectedInspections), (i: InspectionItemPayload) => {
        return i.inspectionType.id
    })
}

export const getLateDuration = (v: InspectionItemPayload, now: DateTime): Duration | undefined => {
    if (!v.inspectionType.taskEndTime || !v.inspectionType.period) {
        return undefined
    }
    const nextInspectionTime = v.inspectionType.taskEndTime.add(v.inspectionType.period, 'hours')
    if (nextInspectionTime.isBefore(now)) {
        return moment.duration(now.diff(nextInspectionTime))
    }
    return undefined
}
