import { Box } from 'aos-ui/src/components/base/Box'
import { Text, TextProps } from 'aos-ui/src/components/base/Text'
import { isString } from 'lodash'
import React, { PropsWithChildren, PureComponent } from 'react'

export class MultilineText extends PureComponent<PropsWithChildren<TextProps>> {
    public render() {
        const { children, ...rest } = this.props
        return (
            <Text {...rest}>
                {isString(children) ? this.renderSplittedLines(children) : children}
            </Text>
        )
    }

    private renderSplittedLines = (text: string) => {
        const lines = text.split('\n')
        return (
            <>
                {lines.map((line, index) => (
                    <Box key={index}>{line}</Box>
                ))}
            </>
        )
    }
}
