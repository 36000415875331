import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { FlightCollectionDto } from './types/FlightCollectionDto'
import { WeatherLatestInfo, WeatherMetricDto, WeatherSeriesDto } from './types/WeatherSeriesDto'

class AirportStatusRestService extends BaseRestService {
    public getWeatherLatestInfo(airport?: AosAirport): Promise<WeatherLatestInfo> {
        return this.getAndTransform<WeatherLatestInfo>(
            this.getPathWithSiteLocation('/airport-status/weather/latest-info', airport),
            BaseRestService.jsonTransformer,
        )
    }

    public getWeatherForecast(airport?: AosAirport): Promise<WeatherSeriesDto> {
        return this.getAndTransform<WeatherSeriesDto>(
            this.getPathWithSiteLocation('/airport-status/weather', airport),
            BaseRestService.jsonTransformer,
        )
    }

    public getWeatherCurrent(airport?: AosAirport): Promise<WeatherMetricDto> {
        return this.getAndTransform<WeatherMetricDto>(
            this.getPathWithSiteLocation('/airport-status/weather/current', airport),
            BaseRestService.jsonTransformer,
        )
    }
    public getFlights(airport?: AosAirport): Promise<FlightCollectionDto> {
        return this.getAndTransform<FlightCollectionDto>(
            this.getFlightsPath(airport),
            BaseRestService.jsonTransformer,
        )
    }

    private getFlightsPath(airport?: AosAirport): string {
        const pathname = '/flights'
        return airport
            ? format({
                  pathname,
                  query: {
                      airport,
                  },
              })
            : pathname
    }

    private getPathWithSiteLocation(pathname: string, siteLocation?: AosAirport): string {
        return siteLocation
            ? format({
                  pathname,
                  query: {
                      siteLocation,
                  },
              })
            : pathname
    }
}

export const airportStatusRestService = new AirportStatusRestService()
