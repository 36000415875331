import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { AosDashboardEvent } from '../../../services/events/types/AosDashboardEvent'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const EVENTS_SYNC = 'STATUS_DASHBOARD_DATA/EVENTS/EVENTS_SYNC'
export type EventsSyncAction = PayloadAction<typeof EVENTS_SYNC, SyncDataKey>
export const eventsSyncAction = payloadActionCreator<EventsSyncAction, SyncDataKey>(EVENTS_SYNC)

export const EVENTS_SYNC_STOP = 'STATUS_DASHBOARD_DATA/EVENTS/EVENTS_SYNC_STOP'
export type EventsSyncStopAction = PayloadAction<typeof EVENTS_SYNC_STOP, SyncDataKey>
export const eventsSyncStopAction = payloadActionCreator<EventsSyncStopAction, SyncDataKey>(
    EVENTS_SYNC_STOP,
)

export const EVENTS_LOAD = 'STATUS_DASHBOARD_DATA/EVENTS/EVENTS_LOAD'
export type EventsLoadAction = Action<typeof EVENTS_LOAD>
export const eventsLoadAction = emptyActionCreator<EventsLoadAction>(EVENTS_LOAD)

export const EVENTS_LOAD_SUCCESS = 'STATUS_DASHBOARD_DATA/EVENTS/EVENTS_LOAD_SUCCESS'
export type EventsLoadSuccessAction = PayloadAction<typeof EVENTS_LOAD_SUCCESS, AosDashboardEvent[]>
export const eventsLoadSuccessAction = payloadActionCreator<
    EventsLoadSuccessAction,
    AosDashboardEvent[]
>(EVENTS_LOAD_SUCCESS)

export type EventsAction =
    | EventsSyncAction
    | EventsSyncStopAction
    | EventsLoadAction
    | EventsLoadSuccessAction
