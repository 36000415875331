import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Box } from '../base/Box'
import { LegendItem, Orientation } from '../ui/LegendItem'

export const VerticalLegend: FCWithChildren<VerticalLegendProps> = props => {
    const renderItem = (itemProps: VerticalLegendItemProps, index: number) => {
        const { itemOrientation = Orientation.Vertical } = props
        return (
            <LegendItem
                dotConfig={{
                    size: BlockSize.Tiny,
                    mainColor: itemProps.labelColor,
                }}
                orientation={itemOrientation}
                marginBottom={itemOrientation === Orientation.Vertical ? 16 : 8}
                key={index}
            >
                {itemProps.label}
            </LegendItem>
        )
    }
    return <Box>{props.items.map(renderItem)}</Box>
}

export interface VerticalLegendItemProps {
    label: string
    labelColor: Color
}

interface VerticalLegendProps {
    items: VerticalLegendItemProps[]
    itemOrientation?: Orientation
}
