import { scaleLinear } from 'd3-scale'
import { max, min, range } from 'lodash'

import { DateTime, dateTime } from '../Time'
import { TimeRange } from '../TimeRange'

export interface Domain<T> {
    ticks: T[]
    domain: [T, T]
}

export interface TicksSpecification {
    count: number
    fixed: boolean
}

const defaultSteps = [
    1,
    2,
    5,
    10,
    15,
    20,
    25,
    50,
    100,
    200,
    250,
    300,
    500,
    1000,
    2000,
    2500,
    5000,
    10000,
    25000,
    50000,
    100000,
    200000,
    250000,
    500000,
    1000000,
    2500000,
    5000000,
    10000000,
    25000000,
    50000000,
]
const timeSteps = [1, 2, 3, 4, 6, 12]
const hourInMs = 3600 * 1000

export const calculateDomain = <T>(
    dataSeries: T[],
    accessor: f.Func1<T, number>,
    ticks: number | TicksSpecification,
    offset?: number,
    stepsValues: number[] = defaultSteps,
): Domain<number> => {
    const allPoints = dataSeries.map(accessor)
    const minV = Math.min(min(allPoints) || 0, 0)
    const maxV = Math.max(max(allPoints) || 0, 0) + (offset || 0)
    return calculateDomainForValues(minV, maxV, ticks, stepsValues)
}

export const domainForTicks = <T>(ticks: T[]): Domain<T> => ({
    ticks,
    domain: [ticks[0], ticks[ticks.length - 1]] as [T, T],
})

export const calculateDomainForValues = (
    minV: number,
    maxV: number,
    ticksParam: number | TicksSpecification,
    stepsValues: number[] = defaultSteps,
): Domain<number> => {
    const ticks = typeof ticksParam === 'number' ? { count: ticksParam, fixed: false } : ticksParam

    const stepV = (maxV - minV) / (ticks.count - 1)
    const step = stepsValues.filter(s => s >= stepV)[0] || stepsValues[stepsValues.length - 1]
    const rangeStart = Math.floor(minV / step)

    let finalTickCount = ticks.count
    if (!ticks.fixed) {
        const lastTick = (rangeStart + ticks.count - 1) * step
        const emptyTicks = Math.floor((lastTick - maxV) / step)
        finalTickCount = Math.max(ticks.count - emptyTicks, 2)
    }
    return domainForTicks(
        range(rangeStart, rangeStart + finalTickCount).map(v => v * step) as number[],
    )
}

export const calculateTimeDomain = (
    dataSeries: { time: DateTime }[],
    tickCount: number,
    stepsValues: number[] = timeSteps,
): Domain<DateTime> => {
    const { rangeInHours, startTime } = minMaxForSeries(dataSeries)
    const stepV = rangeInHours / tickCount
    const stepInH = stepsValues.filter(s => s >= stepV)[0] || 1
    const ticks = range(0, tickCount + 1).map(index =>
        dateTime(startTime).add(index * stepInH, 'hour'),
    )
    return domainForTicks(ticks)
}

const domainForTimeAndRange = (startTime: DateTime, numberOfSteps: number): Domain<DateTime> =>
    domainForTicks(range(0, numberOfSteps + 1).map(index => dateTime(startTime).add(index, 'hour')))

const minMaxForSeries = (dataSeries: { time: DateTime }[]) => {
    const allPoints = dataSeries.map(p => p.time.valueOf())
    const minTime = min(allPoints) || 0
    const maxTime = max(allPoints) || 0
    const rangeInHours = Math.ceil((maxTime - minTime) / hourInMs)
    const startTime = dateTime(minTime).startOf('hour')
    return {
        startTime,
        minTime,
        maxTime,
        rangeInHours,
    }
}

export const calculateTimeDomainForSeries = (
    dataSeries: { time: DateTime }[],
): Domain<DateTime> => {
    const { rangeInHours, startTime } = minMaxForSeries(dataSeries)
    return domainForTimeAndRange(startTime, rangeInHours)
}

export const calculateTimeDomainFromTo = (from: DateTime, to: DateTime): Domain<DateTime> =>
    calculateTimeDomainForSeries([{ time: from }, { time: to }])

export const calculateChartDomainForRange = (timeRange: TimeRange): Domain<DateTime> => {
    const numberOfSteps = timeRange.timeEnd.diff(timeRange.timeStart, 'hour')
    return domainForTicks(
        range(0, numberOfSteps).map(index => dateTime(timeRange.timeStart).add(index, 'hour')),
    )
}

export const calculateDomainForPositiveMax = (max: number): Domain<number> => {
    const scale = scaleLinear().domain([0, max]).nice()

    return {
        ticks: scale.ticks(),
        domain: scale.domain() as [number, number],
    }
}
