import { renderTimeEntry } from 'aos-services/src/core/statusDashboardData/pax/paxCalculations'
import { PaxEntry } from 'aos-services/src/services/pax/types/PaxEntry'
import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

interface SecurityPointBoxProps {
    pax: PaxEntry[]
    color: Color
}

export const SecurityPointBox: FC<SecurityPointBoxProps> = ({ pax, color }) => (
    <Box row columnGap={8} alignContent='center'>
        <Point color={color} />
        <Label>{renderTimeEntry(pax)} </Label>
    </Box>
)

const Point = styled.div`
    border-radius: 2px;
    background-color: ${props => props.color};
    width: 24px;
    height: 24px;
`

const Label = styled.span`
    font-size: 36px;
    font-weight: 500;
    color: ${Color.White};
`
