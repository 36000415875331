import { Box } from 'aos-ui/src/components/base/Box'
import { BaseChartChildrenProps } from 'aos-ui-common/src/components/chart/BaseChart'
import { ChartSize } from 'aos-ui-common/src/components/chart/types/Chart'
import { ChartSeriesConfig } from 'aos-ui-common/src/components/chart/types/ChartSeries'
import { emptyMargins } from 'aos-ui-common/src/components/chart/types/Margins'
import React, { CSSProperties, PureComponent } from 'react'

import { BaseChart } from './BaseChart'
import { PieSeries, PieSeriesConfig } from './series/PieSeries'

const innerRadiusSize = 14

export interface PieChartInnerProps<T> {
    seriesConfig: ChartSeriesConfig<number>
    data: T[]
    yAccessor: f.Func1<T, number>
    arcContent?: JSX.Element
}

export class PieChartInner<T> extends PureComponent<PieChartInnerProps<T>> {
    public render() {
        return (
            <BaseChart margins={emptyMargins} legends={this.renderArcContent}>
                {this.renderContent}
            </BaseChart>
        )
    }

    private renderContent = (props: BaseChartChildrenProps) => {
        const { data, yAccessor } = this.props
        const size = this.getArcSize(props.size)

        const radius = size.height
        const seriesConfig: PieSeriesConfig = {
            ...this.props.seriesConfig,
            innerRadius: radius - innerRadiusSize,
            outerRadius: radius,
        }

        return <PieSeries seriesConfig={seriesConfig} data={data} yAccessor={yAccessor} />
    }

    private renderArcContent = (props: BaseChartChildrenProps) => {
        const size = this.getArcSize(props.size)
        const style: CSSProperties = {
            width: size.width,
            height: size.height,
            position: 'absolute',
            top: 0,
        }
        return (
            this.props.arcContent && (
                <Box columnReverse style={style}>
                    {this.props.arcContent}
                </Box>
            )
        )
    }

    private getArcSize = (size: ChartSize) => {
        const width = size.width || 0
        const height = size.height || 0
        const radius = Math.min(width / 2, height)

        return {
            width: 2 * radius,
            height: radius,
        }
    }
}
