import {
    weatherSyncAction,
    weatherSyncStopAction,
} from 'aos-services/src/core/statusDashboardData/weather/actions'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { SyncWrapper } from '../../../../components/sync/SyncWrapper'

export interface WeatherSyncWrapperProps {
    id: SyncDataKey
}

export const WeatherSyncWrapper: FC<PropsWithChildren<WeatherSyncWrapperProps>> = ({
    children,
    id,
}) => (
    <SyncWrapper onEnter={weatherSyncAction(id)} onLeave={weatherSyncStopAction(id)}>
        {children}
    </SyncWrapper>
)
