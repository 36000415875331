export enum SendToSyncType {
    DASHBOARD = 'DASHBOARD',
    EVENT_MANAGER_FEEDS = 'EVENT_MANAGER_FEEDS',
    EVENT_MANAGER_EVENTS = 'EVENT_MANAGER_EVENTS',
    GLOBAL_MESSAGING = 'GLOBAL_MESSAGING',
    WEATHER = 'WEATHER',
    FLIGHT_STATUS = 'FLIGHT_STATUS',
    CDM = 'CDM',
    PAX_FORECAST = 'PAX_FORECAST',
    NEWS_FEED = 'NEWS_FEED',
    QUEUES = 'QUEUES',
    TASKS = 'TASKS',
    FLIGHT = 'FLIGHT',
    TOWING = 'TOWING',
    RESTRICTIONS = 'RESTRICTIONS',
    TOBT = 'TOBT',
    SHIFT_NOTES = 'SHIFT_NOTES',
    FOD = 'FOD',
    FIREFIGHTERS = 'FIRE_FIGHTER_SHIFTS',
    NOTAM = 'NOTAM',
    SNOWTAM = 'SNOWTAM',
}

export enum SendToSyncForAirportType {
    MOBILE_AIRPORT = 'mobileDashboardAirportStatusSync',
    MOBILE_BACKEND = 'mobileDashboardAosBackendSync',
}
