import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import React, { FCWithChildren } from 'react'

import { BoxProps, MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { Toggle } from '../checkbox/Toggle'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledToggleProps extends MarginBoxProps, SeleniumProps, BoxProps {
    keyPrefix: string
    value: boolean
    isRequired?: boolean
    isError?: boolean
    variant?: ThemeVariant
    horizontal?: boolean
    onChange(v: boolean): void
}

export const LabeledToggle: FCWithChildren<LabeledToggleProps> = ({
    value,
    seleniumLocation,
    keyPrefix,
    isError,
    isRequired,
    variant,
    onChange,
    horizontal,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            horizontal={horizontal}
            {...marginProps}
        >
            <Toggle value={value} onChange={onChange} />
        </LabeledFormElement>
    )
}
