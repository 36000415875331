import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosChecklistItemDto } from '../../../dataaccess/checklists/types/AosChecklistItemDto'
import { AosChecklistItem } from './AosChecklistItem'
import { AosChecklistItemType } from './AosCheckListItemType'

export class AosChecklistItemImpl
    extends WrapperObject<AosChecklistItemDto>
    implements AosChecklistItem {
    public get content() {
        return this.value.content
    }

    public get title() {
        return this.value.title
    }

    public get type() {
        return this.value.type as AosChecklistItemType
    }

    public get id() {
        return this.value.id
    }
}
