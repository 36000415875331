import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import {
    getColorForRingRailTrainScheduleStatus,
    RingRailTimeTableRow,
    RingRailTrainScheduleStatus,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { Component } from 'react'

/* eslint-disable react/jsx-no-literals */
export class DelayInMinutesCell extends Component<DelayInMinutesCellProps, {}> {
    public render() {
        const { differenceInMinutes, scheduleStatus } = this.props.row

        return this.renderCell(scheduleStatus, differenceInMinutes)
    }

    private renderCell = (status: RingRailTrainScheduleStatus, differenceInMinutes?: number) => {
        const style = {
            backgroundColor: getColorForRingRailTrainScheduleStatus(status),
        }

        return (
            <Box className={cxp(this.props, 'train-information__delay-marker')} style={style}>
                {differenceInMinutes && differenceInMinutes < 0 && (
                    <span className='text__xxs-white'>+</span>
                )}
                {differenceInMinutes ? Math.abs(differenceInMinutes) : ''}
            </Box>
        )
    }
}

interface DelayInMinutesCellProps extends ClassNameProps {
    row: RingRailTimeTableRow
}
