import { format } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { UserResultDto } from './types/UserResult'

class UserSearchRestService extends BaseRestService {
    public search(q: string): Promise<UserResultDto[]> {
        return this.getAndTransform<UserResultDto[]>(
            format({
                pathname: '/user-search',
                query: {
                    q,
                },
            }),
            BaseRestService.jsonTransformer,
        )
    }
}
export const userSearchRestService = new UserSearchRestService()
