import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { RestrictionArea } from 'aos-services/src/services/restrictions/types/RestrictionArea'
import { BaseCalendarTimelineGroup } from 'aos-ui/src/components/timeline/types/BaseCalendarTimelineGroup'

export interface StandsGatesTimelineGroup extends BaseCalendarTimelineGroup {
    isRoot: boolean
    rootKey: string
}

const groupSeparator = '_'

export const getRootGroupKey = (area: RestrictionArea | Restriction) =>
    area.areaType + groupSeparator + area.areaNumber

export const getGroupKey = (restriction: Restriction) =>
    getRootGroupKey(restriction) +
    groupSeparator +
    restriction.resourceType +
    groupSeparator +
    restriction.resourceNumber
