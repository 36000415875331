import { Box } from 'aos-ui/src/components/base/Box'
import React, { FCWithChildren } from 'react'

import { AttachmentContainerItem } from './AttachmentContainerItem'
import { PreviewableProps } from './PreviewableProps'

interface PreviewablePhotoProps extends PreviewableProps {
    url: string
}

export const PreviewablePhoto: FCWithChildren<PreviewablePhotoProps> = ({ url, ...rest }) => {
    const style = {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    return (
        <AttachmentContainerItem {...rest}>
            <Box coverAll style={style} />
        </AttachmentContainerItem>
    )
}
