import { getExtension, uniqueFileName } from 'aos-helpers/src/helpers/File'

import { firebaseService } from '../firebase/firebaseService'
import { attachmentMetadata, toMimeType } from './types/AttachmentType'
import { AttachmentUpdatePayload } from './types/AttachmentUpdatePayload'
import { PickedAttachment } from './types/PickedAttachment'
import { UploadedAttachment } from './types/UploadedAttachment'

type File = any

class AttachmentsService {
    public uploadAttachment = async (f: File): Promise<UploadedAttachment> => {
        const ref = this.attachmentRefForName(f.name)
        await ref.put(f)
        const link = await ref.getDownloadURL()
        return { link, name: f.name, type: toMimeType(f.type) }
    }

    public upload = async (attachment: PickedAttachment): Promise<UploadedAttachment> => {
        const ref = this.attachmentRef(attachment)
        await ref.putFile(attachment.uri, attachmentMetadata(attachment.type))
        const link = await ref.getDownloadURL()
        return { link, name: attachment.name, type: toMimeType(attachment.type) }
    }

    public uploadAll = (attachments: PickedAttachment[]): Promise<UploadedAttachment[]> =>
        Promise.all(attachments.map(this.upload))

    public deleteAttachment = (url: string) => firebaseService.storage().refFromURL(url).delete()

    public replaceAttachment = async (payload: AttachmentUpdatePayload) => {
        const ref = this.attachmentRefForName(
            uniqueFileName(firebaseService.storage().refFromURL(payload.url).fullPath),
        )
        await ref.put(payload.data)
        return await ref.getDownloadURL()
    }

    public extensionForAttachment = (attachment: UploadedAttachment) =>
        getExtension(firebaseService.storage().refFromURL(attachment.link).fullPath)

    private attachmentRefForName = (name: string) => {
        const uniqueName = uniqueFileName(name)
        return firebaseService.storageRef().child(`attachments/${uniqueName}`)
    }

    private attachmentRef = (attachment: PickedAttachment) => {
        const uniqueName = uniqueFileName(attachment.uri)
        return firebaseService.storageRef().child(`attachments/${uniqueName}`)
    }
}

export const attachmentsService = new AttachmentsService()
