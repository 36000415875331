import { dateTime } from 'aos-helpers/src/helpers/Time'
import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { DashboardItemStatus } from 'aos-services/src/services/statusDashboard/types/DashboardItemStatus'

import { FirefightersStateAware } from './state'

export const firefighterListSelector = (state: FirefightersStateAware) => state.firefighters.list
export const firetrucksListSelector = (state: FirefightersStateAware) => state.firefighters.trucks
export const firetrucksFormLoadingSelector = (state: FirefightersStateAware) =>
    state.firefighters.isFormLoading

export const firetrucksStatusSelector = (state: FirefightersStateAware): DashboardItemStatus => {
    const now = dateTime()

    const hasValidShift = state.firefighters.list.content.some(({ validUntil }) =>
        validUntil.isAfter(now),
    )

    return {
        status: hasValidShift ? DashboardItemMode.Normal : DashboardItemMode.Warning,
        warning: false,
        reasonCodes: [],
    }
}

export const firefighterEditorOpenSelector = (state: FirefightersStateAware) =>
    state.firefighters.isOpen

export const firefighterEditorShiftToUpdateSelector = (state: FirefightersStateAware) => {
    return state.firefighters.shiftToUpdate
}
