import { dateTime } from 'aos-helpers/src/helpers/Time'
import { call, select, takeEvery } from 'redux-saga/effects'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { tasksService } from '../../services/tasks/tasksService'
import { taskPayloadFromTask } from '../../services/tasks/types/payload/TaskFormPayload'
import { Task } from '../../services/tasks/types/task/Task'
import { currentUserSiteLocation } from '../auth/state'
import { UPDATE_LVP_COUNTER, UpdateLVPCounterAction } from './actions'

function* updateLVPCounterSaga() {
    yield takeEvery<UpdateLVPCounterAction>(UPDATE_LVP_COUNTER, function* (action) {
        const { endTime, taskId } = action.payload
        const task: Task = yield call(tasksService.getTask, { id: taskId })
        const taskPayload = taskPayloadFromTask(task)
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)

        yield call(tasksService.createOrUpdateTask, {
            attachments: task.attachments,
            siteLocation: siteLocation,
            form: {
                ...taskPayload,
                endTime: dateTime(endTime),
            },
            lvpCounterOn: true,
        })
    })
}

export const lvpSagas = [updateLVPCounterSaga]
