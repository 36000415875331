import { useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

interface DOMRectReadOnly {
    readonly x: number
    readonly y: number
    readonly width: number
    readonly height: number
    readonly top: number
    readonly right: number
    readonly bottom: number
    readonly left: number
}

const emptyRect: DOMRectReadOnly = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}

export const useSizeObserver = (element: React.RefObject<Element>) => {
    const observer = useRef<ResizeObserver | null>(null)

    const [rect, setRect] = useState<DOMRectReadOnly>(emptyRect)

    useEffect(() => {
        if (!element.current) {
            return
        }
        setRect(element.current.getBoundingClientRect())
        observer.current = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentRect) {
                    setRect(entry.contentRect)
                }
            }
        })
        if (element && observer.current) {
            observer.current.observe(element.current)
        }
        return () => {
            if (element && element.current && observer && observer.current) {
                observer.current.unobserve(element.current)
            }
        }
    }, [element])

    return rect
}
