import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    FIREFIGHTER_UPDATE_SHIFT_SUCCESS,
    firefighterLoadShiftsSuccessAction,
    FIREFIGHTERS_ADD_SHIFT_SUCCESS,
    FIREFIGHTERS_LOAD_SHIFT,
    FirefightersLoadShiftAction,
    firefightersLoadShiftAction,
    firetruckLoadSuccessAction,
    FIRETRUCKS_LOAD,
    FiretrucksLoadAction,
} from 'aos-services/src/core/firefighters/actions'
import { baseFirefightersSaga } from 'aos-services/src/core/firefighters/saga'
import { firefightersRestService } from 'aos-services/src/dataaccess/tasks/firefighters/firefightersRestService'
import { firefightersService } from 'aos-services/src/dataaccess/tasks/firefighters/firefightersService'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { FireTruck } from 'aos-services/src/services/tasks/types/task/FireTruck'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
    FIREFIGHTERS_RELOAD,
    FirefightersReloadAction,
    firefightersReloadSuccessAction,
} from './actions'

function* loadFirefighterShiftsSaga() {
    yield takeEvery<FirefightersLoadShiftAction>(FIREFIGHTERS_LOAD_SHIFT, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<FirefighterShift> = yield call(
            firefightersService.getShifts,
            siteLocation,
        )
        yield put(firefighterLoadShiftsSuccessAction(response))
    })
}
function* loadFireTrucksSaga() {
    yield takeEvery<FiretrucksLoadAction>(FIRETRUCKS_LOAD, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: FireTruck[] = yield call(
            firefightersRestService.getFireTrucks,
            siteLocation,
        )
        yield put(firetruckLoadSuccessAction(response))
    })
}

function* reloadFireFightersSaga() {
    yield takeEvery<FirefightersReloadAction>(FIREFIGHTERS_RELOAD, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<FirefighterShift> = yield call(
            firefightersService.getReloadedFightersList,
            siteLocation,
            action.payload,
        )
        yield put(firefightersReloadSuccessAction(response))
    })
}

function* reloadAfterSaveSaga() {
    yield takeEvery(
        [FIREFIGHTERS_ADD_SHIFT_SUCCESS, FIREFIGHTER_UPDATE_SHIFT_SUCCESS],
        function* () {
            yield put(firefightersLoadShiftAction())
        },
    )
}

export const firefightersSagas = [
    loadFirefighterShiftsSaga,
    reloadFireFightersSaga,
    loadFireTrucksSaga,
    reloadAfterSaveSaga,
    ...baseFirefightersSaga,
]
