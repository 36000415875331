import { isUndefined, omit, omitBy } from 'lodash'

import { downloadFileFromResponse } from '../../../../aos-helpers/src/helpers/Download'
import { dateTimeFromOptional } from '../../../../aos-helpers/src/helpers/Time'
import { taskChecklistTemplatesRestService } from '../../dataaccess/checklists/taskChecklistTemplatesRestService'
import { AosLocation } from '../common/types/AosLocation'
import { AosAirport } from '../flightInformation/types/AosAirport'
import { AosChecklistItem } from './types/AosChecklistItem'
import { AosChecklistTemplateStatus } from './types/AosChecklistTemplateStatus'
import { AosChecklistUnits } from './types/AosChecklistUnits'
import {
    AosTaskChecklistTemplate,
    AosTaskChecklistTemplateDTO,
} from './types/AosTaskChecklistTemplate'
import { ChecklistTemplateItemEditPayload } from './types/input/ChecklistTemplateItemEditPayload'

class TaskChecklistTemplateService {
    public convertToChecklistTemplate = (
        checklistTemplate: AosTaskChecklistTemplateDTO,
    ): AosTaskChecklistTemplate => ({
        ...checklistTemplate,
        createdAt: dateTimeFromOptional(checklistTemplate.createdAt),
        modifiedAt: dateTimeFromOptional(checklistTemplate.modifiedAt),
        lastUsed: dateTimeFromOptional(checklistTemplate.lastUsed),
        status: checklistTemplate.status as AosChecklistTemplateStatus,
        unit: checklistTemplate.unit as AosChecklistUnits,
        reviewedAt: dateTimeFromOptional(checklistTemplate.reviewedAt),
        reviewedBy: checklistTemplate.reviewedBy,
    })
    public getAll = (location: AosLocation): Promise<AosTaskChecklistTemplate[]> =>
        taskChecklistTemplatesRestService
            .getAll(location)
            .then(checklistTemplates => checklistTemplates.map(this.convertToChecklistTemplate))

    public create = (checklistTemplate: AosTaskChecklistTemplate): Promise<{}> =>
        taskChecklistTemplatesRestService.create(checklistTemplate)

    public delete = (templateId: number): Promise<Response> =>
        taskChecklistTemplatesRestService.delete(templateId)

    public update = (checklistTemplate: AosTaskChecklistTemplate): Promise<Response> =>
        taskChecklistTemplatesRestService.update(checklistTemplate)

    public getOne = (checklistTemplateId: number): Promise<AosTaskChecklistTemplate> =>
        taskChecklistTemplatesRestService
            .getOne(checklistTemplateId)
            .then(this.convertToChecklistTemplate)

    public createItem = (payload: ChecklistTemplateItemEditPayload): Promise<{}> =>
        taskChecklistTemplatesRestService.createItem(
            payload.checklistTemplateId,
            this.clearPayload(payload.item),
        )

    public deleteItem = (checklistTemplateId: number, itemId: number): Promise<{}> =>
        taskChecklistTemplatesRestService.deleteItem(checklistTemplateId, itemId)

    public markReviewed = (id: number): Promise<{}> =>
        taskChecklistTemplatesRestService.markReviewed(id)

    public editTaskOrDescription = (payload: ChecklistTemplateItemEditPayload): Promise<{}> =>
        taskChecklistTemplatesRestService.updateItem(
            payload.checklistTemplateId,
            payload.item.id,
            this.clearPayload(payload.item),
        )

    public reorderItems = (checklistTemplateId: number, tasks: AosChecklistItem[]): Promise<{}> =>
        taskChecklistTemplatesRestService.reorderItems(
            checklistTemplateId,
            tasks.map((task, index) => ({
                id: task.id,
                sequenceNumber: index,
            })),
        )

    public exportAll = (location: AosAirport): Promise<void> =>
        taskChecklistTemplatesRestService.exportAll(location).then(downloadFileFromResponse)

    public exportSingle = (id: number): Promise<void> =>
        taskChecklistTemplatesRestService.exportSingle(id).then(downloadFileFromResponse)

    private clearPayload = (payload: AosChecklistItem): any =>
        omit(omitBy(payload, isUndefined), 'id')
}

export const taskChecklistTemplateService = new TaskChecklistTemplateService()
