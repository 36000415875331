import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { tasksService } from 'aos-services/src/services/tasks/tasksService'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { LOAD_RECENT_TASKS, LoadRecentTasksAction, loadRecentTasksSuccessAction } from './actions'

function* loadRecentTasks() {
    yield takeEvery<LoadRecentTasksAction>(LOAD_RECENT_TASKS, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: TaskSnapshot[] = yield call(tasksService.getRecentTasks, siteLocation)
        yield put(loadRecentTasksSuccessAction(response))
    })
}

export const recentTasksSagas = [loadRecentTasks]
