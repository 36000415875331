import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { css } from 'styled-components'

import { Box } from '../base/Box'
import { boxShadow } from '../base/Theme'

interface BottomSheetProps {
    isOpen: boolean
}

export const BottomSheet = styled(Box)<BottomSheetProps>`
    background: ${Color.White};
    display: flex;
    flex-direction: row;
    box-shadow: ${boxShadow.large};
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: -80px;
    left: 0;
    min-height: 80px;
    transition: bottom 0.3s ease-in-out;
    ${p =>
        p.isOpen &&
        css`
            bottom: 0;
        `}
`
