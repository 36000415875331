import { LvpWaAlertNotificationDto } from '../../../dataaccess/notifications/types/LvpWaAlertNotificationDto'
import { EventRelatedNotificationImpl } from './EventRelatedNotificationImpl'
import { LVP_WA_ALERT_HEL, LvpWaAlertNotification } from './LvpWaAlertNotification'

export class LvpWaAlertNotificationImpl
    extends EventRelatedNotificationImpl<LvpWaAlertNotificationDto, typeof LVP_WA_ALERT_HEL>
    implements LvpWaAlertNotification {
    constructor(public id: string, value: LvpWaAlertNotificationDto, markAlwaysAsRead: boolean) {
        super(id, value, markAlwaysAsRead)
    }

    public get text() {
        return this.value.text
    }
}
