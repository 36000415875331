import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { EnumValues } from 'enum-values'

export enum ChemicalCompound {
    Formate = 'Formiaattineste',
    Acetate = 'Asetaattineste',
}

export type ChemicalCompoundFilter = FilterOptionAll | ChemicalCompound

export const allChemicalCompounds = EnumValues.getValues<ChemicalCompound>(ChemicalCompound)

export const translateChemicalCompound = (value: ChemicalCompoundFilter) =>
    value === FilterOptionAll.All
        ? translate('reports.chemical-compound.all')
        : translateEnum(ChemicalCompound, 'reports.chemical-compound')(value)
