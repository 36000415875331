import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { shiftNotesRestService } from 'aos-services/src/dataaccess/tasks/shiftNotes/shiftNotesRestService'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { ShiftNotesDto } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesListDto'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { rootHistory } from '../../../components/providers/ToastNavigationConnectorWrapper'
import {
    SHIFT_NOTES_ADD,
    SHIFT_NOTES_ADD_SUCCESS,
    SHIFT_NOTES_LOAD,
    SHIFT_NOTES_RELOAD,
    SHIFT_NOTES_SYNC,
    SHIFT_NOTES_SYNC_STOP,
    ShiftNotesAddAction,
    shiftNotesAddSuccessAction,
    ShiftNotesLoadAction,
    shiftNotesLoadAction,
    shiftNotesLoadSuccessAction,
    ShiftNotesReloadAction,
    shiftNotesReloadSuccessAction,
    ShiftNotesSyncAction,
    ShiftNotesSyncStopAction,
} from './actions'

function* shiftNotesSyncSaga() {
    yield takeEvery<ShiftNotesSyncAction>(
        SHIFT_NOTES_SYNC,
        function* (action: ShiftNotesSyncAction) {
            yield* firebaseService.onListener(
                'shiftNotes',
                function* () {
                    yield syncValue(
                        sendToSyncRepository.stsRef(SendToSyncType.SHIFT_NOTES),
                        shiftNotesLoadAction,
                        false,
                    )
                },
                action.payload,
            ) as Generator
        },
    )
}

function* shiftNotesSyncStopSaga() {
    yield takeEvery<ShiftNotesSyncStopAction>(
        SHIFT_NOTES_SYNC_STOP,
        function* (action: ShiftNotesSyncStopAction) {
            yield firebaseService.offListener('shiftNotes', action.payload)
        },
    )
}

function* loadShiftNotesSaga() {
    yield takeEvery<ShiftNotesLoadAction>(SHIFT_NOTES_LOAD, function* () {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<ShiftNotesDto> = yield call(
            shiftNotesRestService.getShiftNotes,
            siteLocation,
        )
        yield put(shiftNotesLoadSuccessAction(response))
    })
}

function* reloadShiftNotesSaga() {
    yield takeEvery<ShiftNotesReloadAction>(SHIFT_NOTES_RELOAD, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<ShiftNotesDto> = yield call(
            shiftNotesRestService.reloadShiftNotes,
            siteLocation,
            action.payload,
        )
        yield put(shiftNotesReloadSuccessAction(response))
    })
}

function* addShiftNotesSaga() {
    yield takeEvery<ShiftNotesAddAction>(SHIFT_NOTES_ADD, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        yield call(shiftNotesRestService.add, siteLocation, action.payload)
        yield put(shiftNotesAddSuccessAction())
        successToast(translate('shift-notes.messages.created'), () => {
            rootHistory?.push('/tasks/shift-notes')
        })
    })
}

function* reloadAfterSaveSaga() {
    yield takeEvery([SHIFT_NOTES_ADD_SUCCESS], function* () {
        yield put(shiftNotesLoadAction())
    })
}

export const shiftNotesSagas = [
    loadShiftNotesSaga,
    addShiftNotesSaga,
    reloadShiftNotesSaga,
    reloadAfterSaveSaga,
    shiftNotesSyncSaga,
    shiftNotesSyncStopSaga,
]
