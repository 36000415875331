import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { FC } from 'react'

import { TaskDetailsPropsList } from './TaskDetailsPropsList'

interface TaskDetailsPropsListContainerProps {
    task: Task
    openLocation(): void
}

export const TaskDetailsPropsListContainer: FC<TaskDetailsPropsListContainerProps> = ({
    task,
    openLocation,
}) => {
    return (
        <Box flex={1}>
            <DarkScrollbar>
                <Box padding={30}>
                    <TaskDetailsPropsList task={task} openLocation={openLocation} />
                </Box>
            </DarkScrollbar>
        </Box>
    )
}
