import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { SvgProps } from 'aos-ui/src/components/base/SvgProps'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Icon, IconVariant } from '../svg/Icon'

interface MenuButtonProps extends ButtonProps, SvgProps {
    isActive: boolean
    isDotVisible: boolean
}

export const MenuButton: FCWithChildren<MenuButtonProps> = props => (
    <MenuButtonWrapper onClick={props.onClick}>
        <Icon
            block
            svg={props.svg}
            iconSize={BlockSize.Large}
            iconVariant={props.isActive ? IconVariant.White : IconVariant.BlackGrey}
        />
        {props.isDotVisible && <Dot />}
    </MenuButtonWrapper>
)

const MenuButtonWrapper = styled(Button)`
    display: flex;
    position: relative;
    width: 96px;
    height: 76px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`

const Dot = styled.div`
    position: absolute;
    top: 16px;
    right: 28px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${Color.Primary};
`
