import { tileMetricType } from 'aos-services/src/core/statusDashboardData/cdm/selectors'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { CdmItemState } from '../../../../services/statusDashboard/types/itemStates/CdmItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemFadeIn } from '../../partials/StatusDashboardItemFadeIn'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { CdmTileGauge } from './CdmGauge'
import { CdmItem } from './partials/CdmItem'

interface CdmTileProps extends StatusDashboardItemCommonProps<CdmItemState> {}

export const CdmTile: FC<CdmTileProps> = props => (
    <CdmItem itemProps={pickDashboardItemProps(props)} link={link} syncId='overview'>
        <StatusDashboardItemFadeIn className='flex--auto'>
            <Box className='cdm-widget cdm-widget--tile' fullHeight paddingHorizontal={40}>
                <CdmTileGauge
                    metricType={tileMetricType}
                    itemState={props.itemState}
                    horizontal
                    itemId={props.id}
                />
            </Box>
        </StatusDashboardItemFadeIn>
    </CdmItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Operations })
