import { UserResult } from '../../../users/types/UserResult'

export interface ShiftNotesFormData {
    message: string
    reporter?: UserResult
}

export const emptyNotesForm: ShiftNotesFormData = {
    message: '',
}
