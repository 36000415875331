import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'

import { changeFilterAction, loadChemicalsReportAction } from '../../../../core/tasks/actions'
import {
    chemicalsReportSelector,
    ChemicalsSelectorState,
} from '../../../../core/tasks/reports/selectors/chemicals'
import { ReportsFilters } from '../../../../core/tasks/reports/state'
import { translateReportAirport } from '../../../../core/tasks/reports/types/ReportAirport'
import { ReportChart } from '../components/ReportChart'
import { ReportSummaryTile } from '../components/ReportSummaryTile'
import { getColor } from './chemicalsColor'
import { ChemicalsReportContent } from './ChemicalsReportContent'

interface ChemicalsReportProps extends ChemicalsSelectorState, ChemicalsReportDispatchProps {}

export const ChemicalsReportComponent: FC<ChemicalsReportProps> = ({
    loadReport,
    filters,
    changeFilter,
    allChemicals,
    chartState,
    maxYearRange,
    totalSummary,
    yearsSummary,
}) => {
    useEffect(() => {
        loadReport()
    }, [])

    return (
        <>
            <Box margin={24} alignItems='flex-end' row wrap>
                <ReportSummaryTile
                    title={translate('reports.chemicals.usage')}
                    titleSlot={
                        <Box marginLeft={16} row>
                            <Icon svg={SvgIcon.Flights} color={Color.UiBlack4} marginRight={8} />
                            <Text size={12} color={Color.UiBlack4}>
                                {translateReportAirport(filters.airport)}
                            </Text>
                        </Box>
                    }
                    value={totalSummary.value}
                    unit={filters.unit}
                    description={translate('reports.chemicals.total-in', {
                        year: totalSummary.yearRange.join('-'),
                    })}
                    large
                />
                {yearsSummary.map(({ value, yearRange }, index) => (
                    <ReportSummaryTile
                        key={index}
                        value={value}
                        unit={filters.unit}
                        description={translate('reports.chemicals.total-in', {
                            year: yearRange.join('-'),
                        })}
                        color={getColor(index)}
                    />
                ))}
            </Box>
            <Box margin={24} marginTop={12}>
                <ChemicalsReportContent
                    filters={filters}
                    onChange={changeFilter}
                    chemicals={allChemicals}
                    yearRange={maxYearRange}
                />
                <ReportChart
                    chartData={chartState}
                    unit={filters.unit}
                    getColor={getColor}
                    rotateBottomLabels={
                        filters.airport !== FilterOptionAll.All && chartState.dataKeys.length > 8
                    }
                />
            </Box>
        </>
    )
}

interface ChemicalsReportDispatchProps {
    loadReport(): void
    changeFilter(v: ReportsFilters): void
}

export const ChemicalsReport = connect<ChemicalsSelectorState, ChemicalsReportDispatchProps>(
    chemicalsReportSelector,
    {
        loadReport: loadChemicalsReportAction,
        changeFilter: changeFilterAction,
    },
)(ChemicalsReportComponent)
