import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum PaxForecastTimeRange {
    Today = 0,
    Custom = 'Custom',
    Next6Weeks = 41, // next 42 days, including today, which is automatically included, see `TimeRange.roundTimeRange`
}

export const translatePaxForecastTimeRange = translateEnum<PaxForecastTimeRange>(
    PaxForecastTimeRange,
    'dashboard.pax-forecast.filters.time-range',
)
