import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DeIcingChemicalTypeFilter } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import { EnumValues } from 'enum-values'
import { range } from 'lodash'

import { ChemicalCompoundFilter } from './types/ChemicalCompound'
import { ReportAirport } from './types/ReportAirport'
import { ReportAnimals } from './types/ReportAnimals'
import { ReportChemicals } from './types/ReportChemicals'
import { ReportType } from './types/ReportType'

export interface ReportsFilters {
    report: ReportType
    airport: ReportAirport
    chemical: ReportChemicals
    animal: ReportAnimals
    yearFrom: number
    yearTo: number
    chemicalType: DeIcingChemicalTypeFilter
    chemicalCompound: ChemicalCompoundFilter
    unit: ReportsUnit
    timeFrequency: ReportTimeFrequency
    season: ReportSeason
}

export enum ReportsUnit {
    Kg = 'kg',
    L = 'L',
}

export enum ReportTimeFrequency {
    Annually = 'annually',
    Monthly = 'monthly',
    Weekly = 'weekly',
}
export const allTimeFrequencies = EnumValues.getValues<ReportTimeFrequency>(ReportTimeFrequency)
export const translateTimeFrequency = translateEnum(ReportTimeFrequency, 'reports.time-frequency')

export enum ReportSeason {
    Winter = 'winter',
    Autumn = 'autumn',
    Spring = 'spring',
}
export const allSeasons = EnumValues.getValues<ReportSeason>(ReportSeason)
export const translateSeason = translateEnum(ReportSeason, 'reports.season')

export const seasonMonths: Record<ReportSeason, { currentYear: number[]; nextYear: number[] }> = {
    [ReportSeason.Winter]: { currentYear: [10, 11, 12], nextYear: [1, 2, 3, 4, 5] },
    [ReportSeason.Autumn]: { currentYear: [10, 11, 12], nextYear: [] },
    [ReportSeason.Spring]: { currentYear: [1, 2, 3, 4, 5], nextYear: [] },
}

export const seasonWeeks: Record<ReportSeason, { currentYear: number[]; nextYear: number[] }> = {
    [ReportSeason.Winter]: { currentYear: range(40, 53 + 1), nextYear: range(1, 22 + 1) },
    [ReportSeason.Autumn]: { currentYear: range(40, 53 + 1), nextYear: [] },
    [ReportSeason.Spring]: { currentYear: range(1, 22 + 1), nextYear: [] },
}

const currentYear = new Date().getFullYear()

export const initialReportsFilters: ReportsFilters = {
    report: ReportType.Chemicals,
    airport: FilterOptionAll.All,
    chemical: FilterOptionAll.All,
    animal: FilterOptionAll.All,
    yearFrom: currentYear - 3,
    yearTo: currentYear,
    chemicalType: FilterOptionAll.All,
    chemicalCompound: FilterOptionAll.All,
    unit: ReportsUnit.Kg,
    timeFrequency: ReportTimeFrequency.Annually,
    season: ReportSeason.Winter,
}
