import { findTimeRangeForLargeWaitingTime } from 'aos-services/src/core/statusDashboardData/pax/paxCalculations'
import { paxSelector } from 'aos-services/src/core/statusDashboardData/pax/selectors'
import { PointId } from 'aos-services/src/services/pax/types/PointId'
import { Carousel } from 'aos-ui/src/components/carousel/Carousel'
import { keys } from 'lodash'
import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { carouselTriggerSelector } from '../../../../core/statusDashboard/selectors'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PaxItem } from './partials/PaxItem'
import { SecurityPointBoxes } from './partials/SecurityPointBoxes'

export const PaxTile: FC<StatusDashboardItemCommonProps> = props => {
    const paxData = useSelector(paxSelector)
    const carouselTrigger = useSelector(carouselTriggerSelector)
    const largestWaitingTime = useMemo(() => findTimeRangeForLargeWaitingTime(paxData.paxGroups), [
        paxData,
    ])

    return (
        <PaxItem itemProps={pickDashboardItemProps(props)} link={link} syncId='overview'>
            <TileContainer verticalSpacing={false} autoHeight>
                <Carousel changeTrigger={carouselTrigger}>
                    {keys(paxData.paxGroups).map(pointId => (
                        <SecurityPointBoxes
                            key={pointId}
                            pointId={pointId as PointId}
                            data={largestWaitingTime[pointId === PointId.Point9 ? 0 : 1]}
                        />
                    ))}
                </Carousel>
            </TileContainer>
        </PaxItem>
    )
}

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Passengers })
