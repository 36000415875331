import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import {
    FilterablePageAndSortRequest,
    filterablePageAndSortRequestBuilder,
} from 'aos-helpers/src/helpers/PageRequest'
import { aosAdUserToAdUserProps } from 'aos-services/src/services/users/aosUserConverter'
import { groupService } from 'aos-services/src/services/users/groupService'
import { UserFilter } from 'aos-services/src/services/users/transport/UsersPageRequest'
import { AosAdUser, AosUser } from 'aos-services/src/services/users/types/AosUser'
import { USER_FORM_READY } from 'aos-services/src/services/users/types/AosUserFindStatus'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { userManagementService } from 'aos-services/src/services/users/userManagementService'
import {
    errorToUserResponseStatus,
    UserResponseStatus,
    userResponseToUserResponseStatus,
} from 'aos-services/src/services/users/userResponseConverter'
import { call, fork, put, select } from 'redux-saga/effects'

import { loadCommonGroupsAction } from '../../commonFrontend/actions'
import { State } from '../../state'
import { addAdUserActions, loadUsersSuccessAction, reloadGroupsSuccessAction } from '../actions'

export const isFilterApplicable = (filter: string = '') => filter.length > 2

export function* reloadUserList() {
    const selector: (
        s: State,
    ) => [FilterablePageAndSortRequest<UserFilter>, AosUserGroup | undefined] = (s: State) => [
        filterablePageAndSortRequestBuilder(s.groupManager.users),
        s.groupManager.selectedGroup,
    ]
    const [userPageRequest, selectedGroup]: ReturnType<typeof selector> = yield select(selector)

    if (selectedGroup && userPageRequest) {
        const shouldApplyFilter = isFilterApplicable(userPageRequest.filter.searchUserText)
        const users = shouldApplyFilter
            ? ((yield call(
                  userManagementService.getFilteredGroupUsers,
                  selectedGroup.id,
                  userPageRequest,
              )) as Pageable<AosUser>)
            : ((yield call(
                  userManagementService.getGroupUsers,
                  selectedGroup.id,
                  userPageRequest,
              )) as Pageable<AosUser>)

        yield put(loadUsersSuccessAction(users))
    }

    yield fork(reloadUserGroups)
}

export function* reloadUserGroups() {
    const groups: AosUserGroup[] = yield call(groupService.getAllGroups)
    const selectedGroup: AosUserGroup = yield select(
        (state: State) => state.groupManager.selectedGroup,
    )
    const updatedSelectedGroup = selectedGroup
        ? groups.filter(group => group.id === selectedGroup.id)[0]
        : undefined
    yield put(reloadGroupsSuccessAction([groups, updatedSelectedGroup]))
    yield fork(reloadCommonUserGroups)
}

export function* reloadCommonUserGroups() {
    yield put(loadCommonGroupsAction())
}

export function* handleAddAdUserResponseStatus(userStatus: UserResponseStatus) {
    if (userStatus) {
        if (userStatus[0] !== undefined) {
            yield put(addAdUserActions.setConflictUserIdAction(userStatus[0]!))
        }
        yield put(addAdUserActions.setUserStatusAction(userStatus[1]))
    }
}

const isSearchResponseValid = (user: AosAdUser): boolean => !user.id

export function* searchUserByEmail(email: string) {
    try {
        const user: AosAdUser = yield call(userManagementService.searchUserByEmail, email)

        if (isSearchResponseValid(user)) {
            yield put(addAdUserActions.searchUserSetResultAction(aosAdUserToAdUserProps(user)))
            yield put(addAdUserActions.setUserStatusAction(USER_FORM_READY))
        } else {
            const userResponseStatus = userResponseToUserResponseStatus(user)
            if (userResponseStatus) {
                yield handleAddAdUserResponseStatus(userResponseStatus)
            }
        }
    } catch (e) {
        const userResponseStatus = errorToUserResponseStatus(e as ResponseError)
        if (userResponseStatus) {
            yield handleAddAdUserResponseStatus(userResponseStatus)
        } else {
            throw e
        }
    }
}
