import { AlertsAction, SET_ALERTS_STATE } from './actions'
import { AlertsState, initialAlertsState } from './state'

export const alertsReducer = (state = initialAlertsState, action: AlertsAction): AlertsState => {
    switch (action.type) {
        case SET_ALERTS_STATE:
            return { ...initialAlertsState, ...action.payload }

        default:
            return state
    }
}
