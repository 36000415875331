import { isEmpty, omitBy } from 'lodash'

import {
    AosAdUserInputDto,
    AosContactInputDto,
    TaskPermissionsDto,
} from '../../dataaccess/users/types/AosUserDto'
import { AdUserFormData } from './input/AdUserFormData'
import { ContactFormData } from './input/ContactFormData'
import { AdUserProps, AosAdUser, TaskAccessLevel, UserTaskPermissions } from './types/AosUser'

export function newAdUserToAosAdUserInputDto(user: AdUserFormData): AosAdUserInputDto {
    return {
        email: user.email!,
        alternativePhoneNumber: user.alternativePhone || null,
        groupIds: user.selectedGroupsIds!,
        isAdditionalFinaviaAuthority: user.isAdditionalFinaviaAuthority,
        taskPermissions: cleanupTaskPermissions(user.taskPermissions),
        type: 'AOS_USER',
        isEventCommander: user.isEventCommander,
    }
}

export function newContactToAosContactInputDto(user: ContactFormData): AosContactInputDto {
    const { id, name, phone, type, userStatus, selectedGroupsIds, isEventCommander, ...rest } = user
    return {
        name,
        phoneNumber: phone,
        groupIds: selectedGroupsIds!,
        taskPermissions: cleanupTaskPermissions(user.taskPermissions),
        type: 'CONTACT',
        ...omitBy(rest, isEmpty),
    }
}

export function aosAdUserToAdUserProps(user: AosAdUser): AdUserProps {
    const stringValues = omitBy(
        {
            name: user.name,
            email: user.email,
            phone: user.phone,
            company: user.company,
            role: user.role,
            unit: user.unit,
            alternativePhone: user.alternativePhone,
        } as object,
        isEmpty,
    )
    return {
        ...stringValues,
        // _.isEmpty(boolean) === true,
        isFinaviaAuthority: user.isFinaviaAuthority,
        isAdditionalFinaviaAuthority: user.isAdditionalFinaviaAuthority,
    } as AdUserProps
}

const cleanupTaskPermissions = (taskPermissions: UserTaskPermissions = {}) => {
    return omitBy(taskPermissions, value => value === TaskAccessLevel.None) as TaskPermissionsDto
}
