import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    DeIcingChemicalUsagePayload,
    DeIcingChemicalUsagePayloadValidation,
    DeIcingTaskFormPayload,
    DeIcingTaskPayloadValidation,
} from 'aos-services/src/services/tasks/types/payload/DeIcingTaskFormPayload'
import { DeIcingArea } from 'aos-services/src/services/tasks/types/task/DeIcingArea'
import { DeIcingCar } from 'aos-services/src/services/tasks/types/task/DeIcingCar'
import { DeIcingChemicals } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'
import {
    allSurfaceTypes,
    DeIcingSurfaceType,
    translateSurfaceType,
} from 'aos-services/src/services/tasks/types/task/DeIcingSurfaceType'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownAutocomplete } from 'aos-ui/src/components/form/dropdown/DropdownAutocomplete'
import { LabeledEditableList } from 'aos-ui/src/components/form/labeled/LabeledEditableList'
import { LabeledNumberInput } from 'aos-ui/src/components/form/labeled/LabeledNumberInput'
import { LabeledSelect } from 'aos-ui/src/components/form/labeled/LabeledSelect'
import { values } from 'lodash'
import React, { FC } from 'react'

interface DeIcingTaskFormProps {
    form: FormValidation<DeIcingTaskPayloadValidation>
    payload: DeIcingTaskFormPayload
    metadata: TaskMetadata
    changeForm(t: Partial<DeIcingTaskFormPayload>): void
}

export const DeIcingTaskForm: FC<DeIcingTaskFormProps> = ({
    form,
    payload,
    metadata,
    changeForm,
}) => {
    const alreadyUsedChemicalTypes = payload.chemicalsUsage.map(v => v.chemicals?.chemicalsType)
    const filteredChemicals = metadata.deicingChemicals.filter(
        v => !alreadyUsedChemicalTypes.includes(v.chemicalsType),
    )

    return (
        <>
            <Box row alignItems='flex-start'>
                <Box flex={3}>
                    <LabeledSelect
                        keyPrefix='tasks.form.deicing.surface-type'
                        marginBottom={12}
                        isRequired
                        isError={form.error.surfaceType}
                        seleniumLocation='surface-type'
                        items={allSurfaceTypes}
                        labelRenderer={translateSurfaceType}
                        value={payload.surfaceType}
                        onChange={(surfaceType: DeIcingSurfaceType) =>
                            changeForm({
                                surfaceType: surfaceType as DeIcingSurfaceType,
                                title: surfaceType
                                    ? (translateSurfaceType(surfaceType) as DeIcingSurfaceType)
                                    : undefined,
                            })
                        }
                    />
                </Box>
                <Box flex={1} marginLeft={20}>
                    <LabeledNumberInput
                        isRequired
                        isError={form.error.temperature}
                        keyPrefix='tasks.form.deicing.temperature'
                        marginBottom={12}
                        seleniumLocation='tasks.form.deicing.temperature'
                        value={payload.temperature}
                        onChange={temperature => changeForm({ temperature })}
                    />
                </Box>
            </Box>
            <Box row alignItems='flex-start'>
                <Box flex={1}>
                    <LabeledSelect
                        keyPrefix='tasks.form.deicing.area'
                        marginBottom={12}
                        isRequired
                        isError={form.error.area}
                        seleniumLocation='area'
                        items={metadata.deicingAreas}
                        labelRenderer={(v: DeIcingArea) => v.label}
                        value={payload.area}
                        onChange={(area: DeIcingArea) => changeForm({ area })}
                    />
                </Box>
                <Box flex={1} marginLeft={20}>
                    <LabeledSelect
                        keyPrefix='tasks.form.deicing.car'
                        marginBottom={12}
                        seleniumLocation='car'
                        items={metadata.deicingCars}
                        labelRenderer={(v: DeIcingCar) => v.label}
                        value={payload.car}
                        onChange={(car: DeIcingCar) => changeForm({ car })}
                    />
                </Box>
            </Box>
            <LabeledEditableList
                keyPrefix='tasks.form.deicing.chemicals'
                isRequired
                marginBottom={20}
                seleniumLocation='chemicals'
                value={payload.chemicalsUsage}
                emptyItem={{ uuid: crypto.randomUUID() }}
                onChange={chemicalsUsage =>
                    changeForm({
                        chemicalsUsage: chemicalsUsage.length ? chemicalsUsage : [{}],
                    })
                }
                isError={form.error.chemicalsUsage.some(q => !values(q).some(v => !v))}
                spacing={16}
                maxItems={2}
                itemRenderer={({ item, index, onChangeItem }) => (
                    <ChemicalsUsageRenderer
                        allChemicals={filteredChemicals}
                        chemicals={item}
                        key={index}
                        form={form.error.chemicalsUsage[index]}
                        onChange={onChangeItem}
                    />
                )}
            />
        </>
    )
}
interface ChemicalsUsageRendererProps {
    chemicals: DeIcingChemicalUsagePayload
    allChemicals: DeIcingChemicals[]
    onChange(v: DeIcingChemicalUsagePayload): void
    form: DeIcingChemicalUsagePayloadValidation | undefined
}

export const ChemicalsUsageRenderer: FC<ChemicalsUsageRendererProps> = ({
    chemicals,
    allChemicals,
    onChange,
    form,
}) => (
    <Box row alignItems='flex-start' wrap paddingRight={16} columnGap={20}>
        <Box flex='1 1 100%'>
            <DropdownAutocomplete
                value={chemicals.chemicals}
                items={allChemicals}
                variant={DropdownVariant.White}
                labelRenderer={v => v.label + ' - ' + v.chemicalsType}
                placeholder={translate('tasks.form.deicing-usage-chemicals.placeholder')}
                onChange={c => onChange({ ...chemicals, chemicals: c })}
                outlined
            />
        </Box>
        <Box flex={1}>
            <LabeledNumberInput
                keyPrefix='tasks.form.deicing-usage-amount'
                isRequired
                isError={form?.amount}
                value={chemicals.amount}
                max={99}
                onChange={amount => onChange({ ...chemicals, amount })}
            />
        </Box>
        <Box flex={1}>
            <LabeledNumberInput
                keyPrefix='tasks.form.deicing-usage-total-amount'
                isRequired
                isError={form?.totalAmount}
                value={chemicals.totalAmount}
                onChange={amount => onChange({ ...chemicals, totalAmount: amount })}
            />
        </Box>
    </Box>
)
