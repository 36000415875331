import {
    ChecklistTemplateEditPayload,
    ChecklistTemplatePayload,
} from '../../services/checklists/types/input/CheclistTemplatePayload'
import { BaseRestService } from '../base/BaseRestService'
import {
    AosChecklistTemplateDto,
    AosSimpleChecklistTemplateDto,
} from './types/AosChecklistTemplateDto'
import { AosTaskInSeq } from './types/AosTaskInSeq'

interface Payload {
    content: string
    title?: string
    type: string
}

class ChecklistsTemplatesRestService extends BaseRestService {
    public create(checklistTemplate: ChecklistTemplatePayload): Promise<{}> {
        return this.postJsonAndTransform<{}>(
            this.apiUrl(),
            checklistTemplate,
            BaseRestService.jsonTransformer,
        )
    }

    public getAll(): Promise<AosSimpleChecklistTemplateDto[]> {
        return this.getAndTransform<AosSimpleChecklistTemplateDto[]>(
            this.apiUrl(),
            BaseRestService.jsonTransformer,
        )
    }

    public delete(checklistTemplateId: number): Promise<Response> {
        return this.deleteReq(this.apiUrl(`/${checklistTemplateId}`))
    }

    public update({
        id,
        ...checklistTemplateData
    }: ChecklistTemplateEditPayload): Promise<Response> {
        return this.patchJson(this.apiUrl(`/${id}`), checklistTemplateData)
    }

    public getOne(checklistTemplateId: number): Promise<AosChecklistTemplateDto> {
        return this.getAndTransform<AosChecklistTemplateDto>(
            this.apiUrl(`/${checklistTemplateId}`),
            BaseRestService.jsonTransformer,
        )
    }

    public reorder(checklistsTemplateOrder: AosTaskInSeq[]): Promise<{}> {
        return this.patchJson(this.apiUrl(), checklistsTemplateOrder)
    }

    public updateItem(checklistTemplateId: number, itemId: number, payload: Payload): Promise<{}> {
        return this.patchJsonAndTransform<{}>(
            this.apiUrl(`/${checklistTemplateId}/items/${itemId}`),
            payload,
            BaseRestService.jsonTransformer,
        )
    }

    public createItem(checklistTemplateId: number, payload: Payload): Promise<{}> {
        return this.postJsonAndTransform<{}>(
            this.apiUrl(`/${checklistTemplateId}/items`),
            payload,
            BaseRestService.jsonTransformer,
        )
    }

    public deleteItem(checklistTemplateId: number, taskId: number): Promise<{}> {
        return this.deleteReq(this.apiUrl(`/${checklistTemplateId}/items/${taskId}`))
    }

    public reorderItems(checklistTemplateId: number, tasksOrder: AosTaskInSeq[]): Promise<{}> {
        return this.patchJsonAndTransform<{}>(
            this.apiUrl(`/${checklistTemplateId}/items`),
            tasksOrder,
            BaseRestService.jsonTransformer,
        )
    }

    public exportAll(): Promise<[Blob, string]> {
        return this.getAndTransform(this.apiUrl('/export'), BaseRestService.fileDownloadTransformer)
    }

    public exportSingle(id: number): Promise<[Blob, string]> {
        return this.getAndTransform(
            this.apiUrl(`/export/template/${id}`),
            BaseRestService.fileDownloadTransformer,
        )
    }

    public markReviewed(checklistTemplateId: number): Promise<Response> {
        return this.post(this.apiUrl(`/${checklistTemplateId}/reviewed`))
    }

    private apiUrl = (suffix?: string) => `/checklists/templates${suffix ? suffix : ''}`
}

export const checklistTemplatesRestService = new ChecklistsTemplatesRestService()
