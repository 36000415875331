import {
    HoveredMapElement,
    HoveredMapElementType,
} from 'aos-services/src/services/map/types/HoveredMapElement'
import Feature from 'ol/Feature'
import RenderFeature from 'ol/render/Feature'

import { isNotNull } from '../../../../../aos-helpers/src/helpers/Function'

export type HoveredMapElementAndFeature = [Feature | RenderFeature, HoveredMapElement]

export const featuresToHoveredMapElementsAndFeature = (
    features: (Feature | RenderFeature)[],
): HoveredMapElementAndFeature[] =>
    features
        .map(f => {
            const hoveredMapElement = featureToHoveredMapElement(f)
            if (hoveredMapElement) {
                const result: HoveredMapElementAndFeature = [f, hoveredMapElement]
                return result
            }
            return null
        })
        .filter(isNotNull)

const featureToHoveredMapElement = (f: Feature | RenderFeature): HoveredMapElement | null => {
    if (f.getProperties().event) {
        return {
            type: HoveredMapElementType.Event,
            payload: f.getProperties().event,
        }
    }
    if (f.getProperties().task) {
        return {
            type: HoveredMapElementType.Task,
            payload: f.getProperties().task,
        }
    }
    return null
}
