import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { PageRequest } from 'aos-helpers/src/helpers/PageRequest'
import { AbstractEntity } from 'aos-services/src/services/base/types/AbstractEntity'
import { EventsFilter } from 'aos-services/src/services/events/transport/EventsFilter'
import { EventsSorting } from 'aos-services/src/services/events/transport/EventsSorting'
import { FeedInFilter } from 'aos-services/src/services/events/transport/FeedInPageRequest'
import { AosEventGroupOrEventOrFeedIn } from 'aos-services/src/services/events/types/AosEventGroup'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'

export const LOAD_FEED_IN = 'EVENT_MANAGER/LOAD_FEED_IN'
export type LoadFeedInAction = PayloadAction<typeof LOAD_FEED_IN, Partial<PageRequest>>
export const loadFeedInAction = payloadActionCreator<LoadFeedInAction, Partial<PageRequest>>(
    LOAD_FEED_IN,
)

export const LOAD_FEED_IN_SUCCESS = 'EVENT_MANAGER/LOAD_FEED_IN_SUCCESS'
export type LoadFeedInSuccessAction = PayloadAction<
    typeof LOAD_FEED_IN_SUCCESS,
    Pageable<AosFeedIn>
>
export const loadFeedInSuccessAction = payloadActionCreator<
    LoadFeedInSuccessAction,
    Pageable<AosFeedIn>
>(LOAD_FEED_IN_SUCCESS)

//
export const LOAD_EVENTS = 'EVENT_MANAGER/LOAD_EVENTS'
export type LoadEventsAction = PayloadAction<typeof LOAD_EVENTS, Partial<PageRequest>>
export const loadEventsAction = payloadActionCreator<LoadEventsAction, Partial<PageRequest>>(
    LOAD_EVENTS,
)

export const LOAD_EVENTS_SUCCESS = 'EVENT_MANAGER/LOAD_EVENTS_SUCCESS'
export type LoadEventsSuccessAction = PayloadAction<
    typeof LOAD_EVENTS_SUCCESS,
    Pageable<AosEventGroupOrEventOrFeedIn>
>
export const loadEventsSuccessAction = payloadActionCreator<
    LoadEventsSuccessAction,
    Pageable<AosEventGroupOrEventOrFeedIn>
>(LOAD_EVENTS_SUCCESS)

//
export const LOAD_OVERDUE_EVENTS_COUNT = 'EVENT_MANAGER/LOAD_OVERDUE_EVENTS_COUNT'
export type LoadOverdueEventsCountAction = Action<typeof LOAD_OVERDUE_EVENTS_COUNT>
export const loadOverdueEventsCountAction = emptyActionCreator<LoadOverdueEventsCountAction>(
    LOAD_OVERDUE_EVENTS_COUNT,
)

export const LOAD_OVERDUE_EVENTS_COUNT_SUCCESS = 'LOAD_OVERDUE_EVENTS_COUNT_SUCCESS'
export type LoadOverdueEventsCountSuccessAction = PayloadAction<
    typeof LOAD_OVERDUE_EVENTS_COUNT_SUCCESS,
    number
>
export const loadOverdueEventsCountSuccessAction = payloadActionCreator<
    LoadOverdueEventsCountSuccessAction,
    number
>(LOAD_OVERDUE_EVENTS_COUNT_SUCCESS)

//
export const SET_FEED_IN_FILTERING = 'EVENT_MANAGER/SET_FEED_IN_FILTERING'
export type SetFeedInFilteringAction = PayloadAction<typeof SET_FEED_IN_FILTERING, FeedInFilter>
export const setFeedInFilteringAction = payloadActionCreator<
    SetFeedInFilteringAction,
    FeedInFilter
>(SET_FEED_IN_FILTERING)

export const SET_EVENTS_FILTERING = 'EVENT_MANAGER/SET_EVENTS_FILTERING'
export type SetEventsFilteringAction = PayloadAction<typeof SET_EVENTS_FILTERING, EventsFilter>
export const setEventsFilteringAction = payloadActionCreator<
    SetFeedInFilteringAction,
    EventsFilter
>(SET_EVENTS_FILTERING)

export const SET_EVENTS_SORTING = 'EVENT_MANAGER/SET_EVENTS_SORTING'
export type SetEventsSortingAction = PayloadAction<typeof SET_EVENTS_SORTING, EventsSorting>
export const setEventsSortingAction = payloadActionCreator<SetEventsSortingAction, EventsSorting>(
    SET_EVENTS_SORTING,
)

export const RELOAD_EVENTS_LIST = 'EVENT_MANAGER/RELOAD_EVENTS_LIST'
export type ReloadEventsListAction = Action<typeof RELOAD_EVENTS_LIST>
export const reloadEventsListAction = emptyActionCreator<ReloadEventsListAction>(RELOAD_EVENTS_LIST)

export const RELOAD_FEED_IN_LIST = 'EVENT_MANAGER/RELOAD_FEED_IN_LIST'
export type ReloadFeedInListAction = Action<typeof RELOAD_FEED_IN_LIST>
export const reloadFeedInListAction = emptyActionCreator<ReloadFeedInListAction>(
    RELOAD_FEED_IN_LIST,
)

//
export const DISMISS_EVENT = 'EVENT_MANAGER/DISMISS_EVENT'
export type DismissEventAction = PayloadAction<typeof DISMISS_EVENT, AbstractEntity>
export const dismissEventAction = payloadActionCreator<DismissEventAction, AbstractEntity>(
    DISMISS_EVENT,
)

export const ADD_EVENT_TO_GROUP = 'EVENT_MANAGER/ADD_EVENT_TO_GROUP'
export type AddEventToGroupAction = PayloadAction<typeof ADD_EVENT_TO_GROUP, [number, number]>
export const addEventToGroupAction = payloadActionCreator<AddEventToGroupAction, [number, number]>(
    ADD_EVENT_TO_GROUP,
)

//
export const SYNC_EVENT_MANAGER = 'EVENT_MANAGER/SYNC_EVENT_MANAGER'
export type SyncEventManagerAction = PayloadAction<typeof SYNC_EVENT_MANAGER, boolean>
export const syncEventManagerAction = payloadActionCreator<SyncEventManagerAction, boolean>(
    SYNC_EVENT_MANAGER,
)

export type EventManagerAction =
    | LoadFeedInAction
    | LoadFeedInSuccessAction
    | LoadEventsAction
    | LoadEventsSuccessAction
    | LoadOverdueEventsCountAction
    | LoadOverdueEventsCountSuccessAction
    | DismissEventAction
    | AddEventToGroupAction
    | SetFeedInFilteringAction
    | SetEventsSortingAction
    | SetEventsFilteringAction
