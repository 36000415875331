import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { Children, FCWithChildren, ReactNode } from 'react'

interface InfoBannerProps {
    title: string
    message?: string
    children?: ReactNode
}

export const InfoBanner: FCWithChildren<InfoBannerProps> = ({ title, message, children }) => {
    return (
        <Text paddingHorizontal={40} paddingVertical={30} bg={Color.Black} textAlign='center'>
            <Text color={Color.White} size={14} paddingBottom={8}>
                {title}
            </Text>
            {message && (
                <Text paddingHorizontal={16} size={12} color={Color.Grey}>
                    {message}
                </Text>
            )}
            {Children.map(children, child => (
                <Box marginTop={16}>{child}</Box>
            ))}
        </Text>
    )
}
