import { Values } from 'aos-helpers/src/helpers/Object'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    BaggageDeliveryFilters,
    BaggageDeliveryFullViewTab,
    BaggageDeliveryTimeRange,
    BeltAreaFilter,
    translateBaggageDeliveryTimeRange,
    translateBeltArea,
} from 'aos-services/src/services/statusDashboard/types/filters/BaggageDeliveryFilters'
import {
    HandlingAgentFilter,
    handlingAgentFilterValues,
    translateHandlingAgent,
} from 'aos-services/src/services/statusDashboard/types/filters/HandlingAgentFilter'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { MultiRoundTabs } from 'aos-ui/src/components/tabs/MultiRoundTabs'
import { RoundTabsProps } from 'aos-ui/src/components/tabs/RoundTabsProps'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'

import { Footer } from '../base/Footer'

interface BaggageDeliveryFooterProps {
    timeRange: BaggageDeliveryTimeRange
    handlingAgent: HandlingAgentFilter
    fullViewTab: BaggageDeliveryFullViewTab
    beltArea: BeltAreaFilter
    beltAreaValues: string[]
    withFullViewTabSwitch: boolean
    setFilterAction(v: Partial<BaggageDeliveryFilters>): void
}

export class BaggageDeliveryFooter extends PureComponent<BaggageDeliveryFooterProps> {
    private get fullViewTabConfig() {
        return {
            items: EnumValues.getValues(BaggageDeliveryFullViewTab),
            value: this.props.fullViewTab,
            name: 'baggage-delivery-full-view-tab',
            formatter: this.translateFullView,
            onChange: this.changeMainTab,
            tooltip: translate('dashboard.baggage-delivery.full-view-tab.tooltip'),
            isAlwaysExpanded: true,
        }
    }

    private get tabsConfig(): RoundTabsProps<Values<BaggageDeliveryFilters>>[] {
        const timeRangeTabs = {
            items: EnumValues.getValues(BaggageDeliveryTimeRange),
            value: this.props.timeRange,
            name: 'baggage-delivery-time-range-tab',
            formatter: translateBaggageDeliveryTimeRange,
            onChange: this.changeTimeRange,
            tooltip: translate('dashboard.baggage-delivery.time-range-tab.tooltip'),
            width: DropdownWidth.Medium,
        }
        const handlingAgentTabs = {
            items: handlingAgentFilterValues,
            value: this.props.handlingAgent,
            name: 'baggage-delivery-handling-agent-tab',
            formatter: translateHandlingAgent,
            onChange: this.changeHandlingAgent,
            tooltip: translate('dashboard.baggage-delivery.handling-agent-tab.tooltip'),
        }
        const beltAreaTabs = {
            items: this.props.beltAreaValues,
            value: this.props.beltArea,
            name: 'baggage-delivery-belt-area-tab',
            formatter: translateBeltArea,
            onChange: this.changeBeltArea,
            tooltip: translate('dashboard.baggage-delivery.belt-area-tab.tooltip'),
        }
        return [timeRangeTabs, handlingAgentTabs, beltAreaTabs]
    }

    private translateFullView = translateEnum<BaggageDeliveryFullViewTab>(
        BaggageDeliveryFullViewTab,
        'dashboard.baggage-delivery.full-view-tab',
    )

    public render() {
        const { withFullViewTabSwitch } = this.props
        const fullViewConfig = withFullViewTabSwitch ? [this.fullViewTabConfig] : []
        const config = [...fullViewConfig, ...this.tabsConfig]
        return (
            <Footer>
                <MultiRoundTabs items={config} />
            </Footer>
        )
    }

    private changeTimeRange = (timeRange: BaggageDeliveryTimeRange) =>
        this.props.setFilterAction({ timeRange })

    private changeHandlingAgent = (handlingAgent: HandlingAgentFilter) =>
        this.props.setFilterAction({ handlingAgent })

    private changeBeltArea = (beltArea: string) => this.props.setFilterAction({ beltArea })

    private changeMainTab = (fullViewTab: BaggageDeliveryFullViewTab) =>
        this.props.setFilterAction({ fullViewTab })
}
