import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'
import styled from 'styled-components'

const VERTICAL_FACTOR = 0.85

export class BaseGaugeClass extends PureComponent<BaseGaugeProps> {
    public render() {
        const size = this.getSize()
        const svgSize = {
            width: size,
            height: size * VERTICAL_FACTOR,
        }
        const center = `translate(${size / 2}, ${size / 2})`
        return (
            <Box fullWidth fullHeight>
                <Content width={svgSize.width} height={svgSize.height}>
                    <g transform={center}>{this.props.contentRenderer(size)}</g>
                </Content>
                <GaugeValue style={{ top: size / 2 }}>
                    {this.props.valueRenderer &&
                        (this.props.valueRenderer as GaugeChildrenType)(size)}
                </GaugeValue>
            </Box>
        )
    }

    protected getSize() {
        const { width, height } = this.props.size
        const targetHeight = (height || 0) / VERTICAL_FACTOR
        return Math.min(width || 0, targetHeight) || 164
    }
}

const GaugeValue = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
`

const Content = styled.svg`
    display: block;
    margin: 0 auto;
`

export type GaugeChildrenType = f.Func1<number, JSX.Element | undefined>

export interface BaseGaugeProps extends SizeMeProps {
    valueRenderer?: GaugeChildrenType
    contentRenderer: GaugeChildrenType
}

export const BaseGauge = sizeMe({ monitorHeight: true })(BaseGaugeClass)
