import { AxisBottom } from 'aos-components/src/components/chart/axes/AxisBottom'
import { AxisLeft } from 'aos-components/src/components/chart/axes/AxisLeft'
import { AxisRight } from 'aos-components/src/components/chart/axes/AxisRight'
import { AxisUnit } from 'aos-components/src/components/chart/axes/AxisUnit'
import { LegacyChart } from 'aos-components/src/components/chart/LegacyChart'
import { LinearBarSeries } from 'aos-components/src/components/chart/series/LinearBarSeries'
import { LineSeries } from 'aos-components/src/components/chart/series/LineSeries'
import { calculateDomain, Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import {
    getSeriesByPredicate,
    getTypesByPredicate,
    WeatherSeriesDefinition,
    WeatherSingleSeriesDefinition,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesDefinition'
import { WeatherValueSeriesType } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { Box } from 'aos-ui/src/components/base/Box'
import { Scales } from 'aos-ui-common/src/components/chart/Scales'
import {
    ChartConfig,
    ChartScaleType,
    DateScaleConfig,
    getRanges,
    LinearScaleConfig,
} from 'aos-ui-common/src/components/chart/types/Chart'
import { format } from 'd3-format'
import { scaleLinear } from 'd3-scale'
import { flatten, values } from 'lodash'
import React, { PureComponent } from 'react'
import sizeMe, { SizeMeProps } from 'react-sizeme'

import { chartIconOffset, chartIconSize, chartUnitOffset } from '../WeatherConsts'
import { WeatherAdditionalIcon, WeatherIcons } from './WeatherIcons'

class WeatherMainChartClass extends PureComponent<WeatherMainChartProps & SizeMeProps> {
    public xAccessor = (d: TimeValuePoint) => d.time
    public leftSeriesPredicate = (p: WeatherSingleSeriesDefinition) => p.scale === ChartScaleType.Y1
    public rightSeriesPredicate = (p: WeatherSingleSeriesDefinition) =>
        p.scale === ChartScaleType.Y2

    public render() {
        const { chartConfig, size } = this.props
        const config = this.scalesConfig

        return (
            <Box className='no-scrollable' flex={1}>
                <LegacyChart chartConfig={chartConfig} size={size}>
                    {this.renderAxes(config)}
                    {this.renderAxesUnits()}
                    {this.renderLinearBarSeries(config)}
                    {this.renderLineSeries(config)}
                    {this.renderWeatherIcons()}
                </LegacyChart>
            </Box>
        )
    }

    private renderAxes = (config: ScalesConfig) => (
        <g className='chart-axes'>
            <AxisBottom
                axisConfig={{
                    tickValues: config[ChartScaleType.X].tickValues,
                    tickFormat: formatTime,
                    tickPadding: 30,
                }}
                scale={config[ChartScaleType.X].scale}
            />
            <AxisRight
                axisConfig={{
                    tickValues: config[ChartScaleType.Y2].tickValues,
                    tickFormat: format('d'),
                }}
                scale={config[ChartScaleType.Y2].scale}
                baseValues={[0]}
            />
            <AxisLeft
                axisConfig={{
                    tickValues: config[ChartScaleType.Y1].tickValues,
                    tickFormat: format('d'),
                }}
                scale={config[ChartScaleType.Y1].scale}
                baseValues={[0]}
            />
        </g>
    )

    private renderAxesUnits = () => {
        const { units } = this.props
        return (
            <>
                {units.y1 && (
                    <AxisUnit
                        unit={units.y1}
                        left={chartUnitOffset}
                        top={(this.props.size.height || 0) - this.props.chartConfig.margins.bottom}
                        textAnchor='middle'
                    />
                )}
                {units.y2 && (
                    <AxisUnit
                        unit={units.y2}
                        left={(this.props.size.width || 0) - chartUnitOffset}
                        top={(this.props.size.height || 0) - this.props.chartConfig.margins.bottom}
                        textAnchor='middle'
                    />
                )}
            </>
        )
    }

    private renderLineSeries = (config: ScalesConfig) => (
        <g className='chart-line-series'>
            {this.lineSeries.map((seriesDef: WeatherSingleSeriesDefinition, index: number) => (
                <LineSeries
                    key={index}
                    data={seriesDef.data}
                    seriesConfig={seriesDef.config}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: seriesDef.valueGetter }}
                    scales={{
                        xScale: config[ChartScaleType.X].scale,
                        yScale: config[seriesDef.scale].scale,
                    }}
                />
            ))}
        </g>
    )

    private renderLinearBarSeries = (config: ScalesConfig) => (
        <g className='chart-bar-series'>
            {this.barSeries.map((seriesDef: WeatherSingleSeriesDefinition, index: number) => (
                <LinearBarSeries
                    key={index}
                    data={seriesDef.data}
                    seriesConfig={seriesDef.config}
                    accessors={{ xAccessor: this.xAccessor, yAccessor: seriesDef.valueGetter }}
                    scales={{
                        xScale: config[ChartScaleType.X].scale,
                        yScale: config[seriesDef.scale].scale,
                    }}
                />
            ))}
        </g>
    )

    private renderWeatherIcons = () => (
        <>
            <WeatherIcons
                components={this.leftSeriesTypes}
                left={chartIconOffset}
                top={this.props.chartConfig.margins.top - 10}
                additionalIcons={this.props.leftAdditionalIcons}
            />
            <WeatherIcons
                components={this.rightSeriesTypes}
                left={(this.props.size.width || 0) - chartIconOffset - chartIconSize}
                top={this.props.chartConfig.margins.top - 10}
            />
        </>
    )

    private get scalesConfig(): ScalesConfig {
        const { chartConfig, xDomain } = this.props

        const { xRange, yRange } = getRanges(chartConfig.margins, this.size)

        const leftValueGetter =
            this.leftSeries.length > 0
                ? this.leftSeries[0].valueGetter
                : (d: TimeValuePoint) => d.value
        const rightValueGetter =
            this.rightSeries.length > 0
                ? this.rightSeries[0].valueGetter
                : (d: TimeValuePoint) => d.value

        const y1Domain = calculateDomain(
            flatten(this.leftSeries.map(s => s.data)),
            leftValueGetter,
            {
                count: 4,
                fixed: false,
            },
        )
        const y2Domain = calculateDomain(
            flatten(this.rightSeries.map(s => s.data)),
            rightValueGetter,
            {
                count: 4,
                fixed: true,
            },
        )

        const xScale = Scales.scaleDateTime(xDomain.domain, xRange)

        const y1Scale = scaleLinear().domain(y1Domain.domain).rangeRound(yRange)

        const y2Scale = scaleLinear().domain(y2Domain.domain).rangeRound(yRange)

        return {
            [ChartScaleType.X]: {
                scale: xScale,
                tickValues: xDomain.ticks,
            },
            [ChartScaleType.Y1]: {
                scale: y1Scale,
                tickValues: y1Domain.ticks,
            },
            [ChartScaleType.Y2]: {
                scale: y2Scale,
                tickValues: y2Domain.ticks,
            },
        }
    }

    private get barSeries() {
        return values(this.props.barSeriesDefinition)
    }

    private get lineSeries() {
        return values(this.props.lineSeriesDefinition)
    }

    private get leftSeriesTypes(): WeatherValueSeriesType[] {
        return this.getSeriesTypesByPredicate(this.leftSeriesPredicate)
    }

    private get rightSeriesTypes(): WeatherValueSeriesType[] {
        return this.getSeriesTypesByPredicate(this.rightSeriesPredicate)
    }

    private get leftSeries(): WeatherSingleSeriesDefinition[] {
        return this.getSeriesByPredicate(this.leftSeriesPredicate)
    }

    private get rightSeries(): WeatherSingleSeriesDefinition[] {
        return this.getSeriesByPredicate(this.rightSeriesPredicate)
    }

    private getSeriesTypesByPredicate(p: f.Func1<WeatherSingleSeriesDefinition, boolean>) {
        return getTypesByPredicate(
            p,
            this.props.lineSeriesDefinition,
            this.props.barSeriesDefinition,
        )
    }

    private getSeriesByPredicate(p: f.Func1<WeatherSingleSeriesDefinition, boolean>) {
        return getSeriesByPredicate(
            p,
            this.props.lineSeriesDefinition,
            this.props.barSeriesDefinition,
        )
    }

    private get size() {
        return { width: this.props.size.width || 0, height: this.props.size.height || 0 }
    }
}

interface ScalesConfig {
    [ChartScaleType.X]: DateScaleConfig
    [ChartScaleType.Y1]: LinearScaleConfig
    [ChartScaleType.Y2]: LinearScaleConfig
}

export const WeatherMainChart = sizeMe({ monitorHeight: true })(WeatherMainChartClass)

export interface WeatherMainChartProps {
    chartConfig: ChartConfig
    lineSeriesDefinition: WeatherSeriesDefinition
    barSeriesDefinition: WeatherSeriesDefinition
    xDomain: Domain<DateTime>
    leftAdditionalIcons?: WeatherAdditionalIcon[]
    units: {
        y1?: string
        y2?: string
    }
}
