import { QueueingTimeStat } from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { ControlPointConfig } from 'aos-services/src/services/queueingTime/types/ControlPointConfig'
import { QueueingDataTypeFilter } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { ColumnsGrid } from 'aos-ui/src/components/base/ColumnsGrid'
import React, { FC } from 'react'

import { QueueingStat } from './QueueingStat'

interface QueueingStatSectionProps {
    config: Record<string, ControlPointConfig>
    stats: Record<string, QueueingTimeStat>
    filter: QueueingDataTypeFilter
}

export const QueueingStatSection: FC<QueueingStatSectionProps> = ({ config, stats, filter }) => (
    <ColumnsGrid alignGridItems='center' compact>
        {Object.keys(stats).map(point => (
            <QueueingStat key={point} stat={stats[point]} config={config[point]} filter={filter} />
        ))}
    </ColumnsGrid>
)
