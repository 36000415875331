import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'

import {
    CHECK_CHECKLIST,
    ChecklistCheckData,
    ChecklistSelectionModalActions,
    CLOSE_CHECKLIST_MODAL,
    GET_AVAILABLE_CHECKLISTS_SUCCESS,
    OPEN_CHECKLIST_MODAL,
} from './actions'
import {
    ChecklistSelectionModalState,
    initialChecklistSelectionModalState,
    SelectableSimpleChecklistTemplate,
} from './state'

export function checklistSelectionModalReducer(
    state: ChecklistSelectionModalState = initialChecklistSelectionModalState,
    action: ChecklistSelectionModalActions,
): ChecklistSelectionModalState {
    switch (action.type) {
        case OPEN_CHECKLIST_MODAL:
            return { ...state, isOpen: true }

        case CLOSE_CHECKLIST_MODAL:
            return { ...state, isOpen: false }

        case GET_AVAILABLE_CHECKLISTS_SUCCESS:
            const [list, selected] = action.payload
            return {
                ...state,
                checklists: fillInAvailableAndSelectedChecklist(list, selected),
            }

        case CHECK_CHECKLIST:
            return {
                ...state,
                checklists: checkChecklist(state.checklists, action.payload),
            }

        default:
            return state
    }
}

function fillInAvailableAndSelectedChecklist(
    allChecklists: AosSimpleTemplate[],
    selectedChecklistIds: number[],
): SelectableSimpleChecklistTemplate[] {
    return allChecklists.map(checklist => ({
        ...checklist,
        selected: selectedChecklistIds.includes(checklist.id),
    }))
}

function checkChecklist(
    checklistsColumn: SelectableSimpleChecklistTemplate[],
    checkData: ChecklistCheckData,
): SelectableSimpleChecklistTemplate[] {
    return checklistsColumn.map(checklist =>
        checklist.id === checkData.id ? { ...checklist, selected: checkData.selected } : checklist,
    )
}
