import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { Booleanish } from 'aos-helpers/src/helpers/Boolean'

import { ActionModalConfig } from './state'

export const SHOW_ACTION_MODAL = 'ACTION_MODAL/SHOW_ACTION_MODAL'
export type ShowActionModalAction = PayloadAction<typeof SHOW_ACTION_MODAL, ActionModalConfig>
export const showActionModalAction = payloadActionCreator<ShowActionModalAction, ActionModalConfig>(
    SHOW_ACTION_MODAL,
)

export const HIDE_ACTION_MODAL = 'ACTION_MODAL/HIDE_ACTION_MODAL'
export type HideActionModalAction = PayloadAction<typeof HIDE_ACTION_MODAL, boolean>
export const hideActionModalAction = payloadActionCreator<HideActionModalAction, Booleanish>(
    HIDE_ACTION_MODAL,
)

export type ActionModalAction = HideActionModalAction | ShowActionModalAction
