import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import React, { FCWithChildren, useState } from 'react'

import { DatetimeInput } from './DatetimeInput'

interface DurationInputProps {
    /** minutes */
    value?: number
    onChange(value: number | undefined): void
}

export const DurationInput: FCWithChildren<DurationInputProps> = ({ value, onChange }) => {
    const [date, setDate] = useState<DateTime | undefined>(() => zeroDate().add(value, 'minutes'))

    const handleChange = (value: DateTime | undefined) => {
        setDate(value)
        if (value) {
            const h = value.hours()
            const m = value.minutes()
            onChange(h * 60 + m)
        }
    }

    return <DatetimeInput value={date} onChange={handleChange} mode='time' />
}

const zeroDate = () => dateTime().hours(0).minutes(0).second(0)
