import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'

export interface CommonFrontendState {
    groups: AosUserGroup[]
}

export const initialCommonFrontendState: CommonFrontendState = {
    groups: [],
}

export interface CommonFrontendStateAware {
    commonFrontend: CommonFrontendState
}

export const userGroupsSelector = (state: CommonFrontendStateAware) => state.commonFrontend.groups
