import { isNotEmpty, isNumber } from 'aos-helpers/src/helpers/FormValidation'

import { DeIcingArea } from '../task/DeIcingArea'
import { DeIcingCar } from '../task/DeIcingCar'
import { DeIcingChemicals } from '../task/DeIcingChemicals'
import { DeIcingSurfaceType } from '../task/DeIcingSurfaceType'
import { DeIcingTask } from '../task/DeIcingTask'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface DeIcingTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.DeIcing
    temperature?: number
    area?: DeIcingArea
    surfaceType?: DeIcingSurfaceType
    car?: DeIcingCar
    chemicalsUsage: DeIcingChemicalUsagePayload[]
}

export interface DeIcingChemicalUsagePayload {
    amount?: number
    totalAmount?: number
    chemicals?: DeIcingChemicals
}

export const initialDeIcingTaskPayload: DeIcingTaskFormPayload = {
    type: TaskType.DeIcing,
    ...initialBaseTaskPayload,
    chemicalsUsage: [
        {
            chemicals: undefined,
            amount: undefined,
            totalAmount: undefined,
        },
    ],
}

export interface DeIcingTaskPayloadValidation extends BaseTaskPayloadValidation {
    area: boolean
    surfaceType: boolean
    temperature: boolean
    chemicalsUsage: DeIcingChemicalUsagePayloadValidation[]
}

export interface DeIcingChemicalUsagePayloadValidation {
    amount: boolean
    totalAmount: boolean
    chemicals: boolean
}

export const validateDeIcingTask = (t: DeIcingTaskFormPayload): DeIcingTaskPayloadValidation => ({
    ...baseValidateTask(t),
    area: isNotEmpty(t.area),
    surfaceType: isNotEmpty(t.surfaceType),
    temperature: isNumber(t.temperature),
    chemicalsUsage: t.chemicalsUsage.map(u => ({
        amount: u.amount !== undefined && isNumber(u.amount) && u.amount < 100,
        chemicals: isNotEmpty(u.chemicals),
        totalAmount: isNumber(u.totalAmount),
    })),
})

export const deIcingTaskFormPayloadFromTask = (t: DeIcingTask): DeIcingTaskFormPayload => ({
    type: TaskType.DeIcing,
    ...baseTaskFormPayloadFromTask(t),
    temperature: t.temperature,
    area: t.area,
    surfaceType: t.surfaceType,
    car: t.car,
    chemicalsUsage: t.chemicalsUsage,
})
