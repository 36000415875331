import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Rotated } from 'aos-ui/src/components/base/Rotated'
import { boxShadow } from 'aos-ui/src/components/base/Theme'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { transparentize } from 'polished'
import React, { PureComponent } from 'react'
import ReactDatetime from 'react-datetime'
import styled, { css } from 'styled-components'

type GenericDatetimeProps = ReactDatetime.DatetimepickerProps & GenericDatetimeCustomProps

export class GenericDatetime extends PureComponent<GenericDatetimeProps> {
    public render() {
        return (
            <StyledReactDatetime
                timeFormat='HH:mm'
                topChevron={this.renderTopChevron()}
                bottomChevron={this.renderBottomChevron()}
                daysViewSwitchElement={this.daysViewSwitchElement()}
                timeViewSwitchElement={this.timeViewSwitchElement()}
                prevSwitchElement={this.prevBottom()}
                nextSwitchElement={this.nextBottom()}
                renderDay={this.renderDay}
                locale='en-GB'
                {...this.props}
                onChange={e => {
                    this.props.onChange!(e)
                }}
            />
        )
    }

    private renderDay = (props: any, currentDate: DateTime, selectedDate?: DateTime) => {
        const classes = []
        if (this.props.timeRangeStart) {
            const isWithinTimeRange =
                selectedDate &&
                currentDate.isSameOrAfter(this.props.timeRangeStart, 'day') &&
                currentDate.isSameOrBefore(selectedDate, 'day')
            const timeRangeStartDate = currentDate.isSame(this.props.timeRangeStart, 'day')
            const timeRangeEndDate = selectedDate && currentDate.isSame(selectedDate, 'day')
            if (timeRangeStartDate) {
                classes.push('rdtActive rdtRangeStart')
            }
            if (timeRangeEndDate) {
                classes.push('rdtActive rdtRangeEnd')
            }
            if (isWithinTimeRange) {
                classes.push('rdtInRange')
            }
        }
        const newProps = { ...props, className: `${props.className} ${classes.join(' ')}` }
        return (
            <td {...newProps}>
                <div className='rdtDayWrapper'>{currentDate.date()}</div>
            </td>
        )
    }

    private renderTopChevron = () => (
        <Rotated angle={180}>
            <Icon iconVariant={IconVariant.Black} iconSize={BlockSize.Std} svg={SvgIcon.Chevron} />
        </Rotated>
    )

    private renderBottomChevron = () => (
        <Icon iconVariant={IconVariant.Black} iconSize={BlockSize.Std} svg={SvgIcon.Chevron} />
    )

    private daysViewSwitchElement = () => (
        <Icon iconVariant={IconVariant.Blue} iconSize={BlockSize.Std} svg={SvgIcon.CurrentTime} />
    )

    private timeViewSwitchElement = () => (
        <Icon iconVariant={IconVariant.Blue} iconSize={BlockSize.Std} svg={SvgIcon.Datapicker} />
    )

    private prevBottom = () => (
        <Rotated angle={180}>
            <Icon
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Std}
                svg={SvgIcon.ChevronRight}
            />
        </Rotated>
    )

    private nextBottom = () => (
        <Icon iconVariant={IconVariant.Black} iconSize={BlockSize.Std} svg={SvgIcon.ChevronRight} />
    )
}

export interface GenericDatetimeCustomProps {
    timeRangeStart?: DateTime
    position: 'top' | 'bottom'
    viewMode?: ViewMode
}

export type ViewMode = 'years' | 'months' | 'days' | 'time'

export type ChangeValue = DateTime | string

const StyledReactDatetime = styled(ReactDatetime)<GenericDatetimeProps>`
    position: relative;
    display: ${p => (p.open ? 'block' : 'none')};
    color: ${Color.Black};
    font-size: 12px;
    font-weight: 400;

    ${p =>
        p.position === 'bottom' &&
        css`
            transform: translate(0, 11px);
            ::before {
                display: block;
                top: 0;
                left: 50%;
                background: ${Color.White};
                border-left: 1px solid ${Color.Grey2};
                border-top: 1px solid ${Color.Grey2};
                position: absolute;
                width: 16px;
                height: 16px;
                transform: translate(-50%, -50%) rotate(45deg);
                transform-origin: center;
                content: '';
            }
        `}

    .rdtPicker {
        box-shadow: ${boxShadow.popover};
        background: ${Color.White};
        padding: 16px;
        border: 1px solid ${Color.Grey2};
        border-radius: 5px;
        width: 260px;
    }

    .rdtSwitch {
        width: 100%;
    }

    table {
        width: 100%;
        margin: 0;
        border-collapse: separate;
        border-spacing: 0 4px;
    }

    td {
        cursor: pointer;
        font-weight: 500;
    }

    .rdtOld,
    .rdtNew {
        color: ${Color.Grey3};
    }

    .rdtDisabled,
    .rdtDisabled:hover {
        background: none;
        color: ${transparentize(0.5, Color.Grey3)};
        cursor: not-allowed;
    }

    .rdtDays {
        th {
            height: 28px;
            padding: 0 2px;
            text-align: center;
        }

        td {
            height: 28px;
            padding: 0 2px;
            text-align: center;

            & > .rdtDayWrapper {
                width: 28px;
                height: 28px;
                line-height: 28px;
                pointer-events: none;
            }

            :hover {
                .rdtDayWrapper {
                    background: ${Color.Grey1};
                }
            }
        }

        .rdtToday {
            position: relative;
            ::before {
                display: inline-block;
                position: absolute;
                right: 6px;
                bottom: 6px;
                border-bottom: 7px solid ${Color.Primary};
                border-left: 7px solid transparent;
                content: '';
            }
        }

        .rdtActive .rdtDayWrapper,
        .rdtActive:hover .rdtDayWrapper {
            border-radius: 5px;
            background-color: ${Color.Primary};
            color: ${Color.White};
        }

        .rdtActive.rdtToday:before {
            border-bottom-color: ${Color.White};
        }
    }

    .rdtMonths,
    .rdtYears {
        th {
            height: 28px;
            padding: 0 2px;
            text-align: center;
        }
        td {
            height: 50px;
            padding: 0 2px;
            text-align: center;
            :hover {
                background: ${Color.Grey1};
            }
        }
    }

    .rdtInRange {
        background-color: ${transparentize(0.8, Color.Primary)};

        &.rdtActive {
            background-color: transparent;

            &.rdtRangeStart {
                background-image: linear-gradient(
                    to right,
                    transparent,
                    ${transparentize(0.8, Color.Primary)} 50%
                );
            }

            &.rdtRangeEnd {
                background-image: linear-gradient(
                    to left,
                    transparent,
                    ${transparentize(0.8, Color.Primary)} 50%
                );
            }

            &.rdtRangeStart.rdtRangeEnd {
                background-image: none;
            }
        }
    }

    .rdtNext,
    .rdtPrev {
        vertical-align: middle;
    }

    thead tr:first-child th {
        cursor: pointer;
    }

    button {
        border: 0;
        background: none;
        cursor: pointer;
    }

    button:hover {
        background-color: ${Color.Grey1};
    }

    thead button {
        width: 100%;
        height: 100%;
    }

    .rdtCounters {
        font-weight: 300;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        font-size: 48px;

        .rdtCounter {
            flex-shrink: 0;
            width: 60px;
            height: 108px;

            .rdtBtn {
                display: block;
                height: 24px;
                line-height: 24px;
                cursor: pointer;
                overflow: hidden;
                user-select: none;

                svg {
                    display: block;
                    margin: 0 auto;
                }

                :hover {
                    background: ${Color.Grey1};
                }
            }

            .rdtCount {
                display: table-cell;
                width: 60px;
                height: 60px;
                line-height: 60px;
                vertical-align: baseline;
                text-align: center;
            }
        }

        .rdtCounterSeparator {
            display: table-cell;
            height: 108px;
            padding: 24px 20px;
            font-size: 48px;
            line-height: 60px;
            vertical-align: baseline;
        }

        .rdtMilli {
            width: 48px;
            padding-left: 8px;
            vertical-align: middle;

            input {
                width: 100%;
                margin-top: 37px;
                font-size: 1.2em;
            }
        }
    }
`
