import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box, NumberValues } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import styled from 'styled-components'

interface AddIconComponentProps {
    action?(): void
    marginTop?: NumberValues
}

export const AddIconComponent = ({ action, marginTop }: AddIconComponentProps) => (
    <AddInfo row onClick={action} cursor='pointer' marginLeft={6} marginTop={marginTop}>
        {translate('atc-event-detail.add-info')}
    </AddInfo>
)

const AddInfo = styled(Box)`
    font-size: 14px;
    color: ${Color.PrimaryLight};
    font-weight: 700;
`
