import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { parse, stringify } from 'query-string'
import { format } from 'url'

const ITEM_SEP = '.'
const VALUE_SEP = '-'

export interface FiltersObject {
    [key: string]: any
}

export interface UrlFiltersConfig {
    booleanFilters?: string[]
    numberFilters?: string[]
    stringFilters?: string[]
    arrayFilters?: string[]
    columnsFilters?: string[]
    fields?: FiltersObject
}

export const urlFiltersParser = (
    urlFiltersString: string,
    config: UrlFiltersConfig,
    defaultFilters: FiltersObject,
): FiltersObject => {
    const {
        booleanFilters = [],
        numberFilters = [],
        stringFilters = [],
        arrayFilters = [],
        columnsFilters = [],
        fields = {},
    } = config
    const filters: FiltersObject = { ...defaultFilters }
    const urlFilters = parse(urlFiltersString)

    // To boolean: 'true' → true
    booleanFilters.forEach(name => {
        const value = urlFilters[name]
        if (!value) {
            return
        }
        ;(filters[name] as boolean) = value === 'true'
    })

    // To numbers: '1' → 1
    numberFilters.forEach(name => {
        const value = urlFilters[name]
        if (!value || (+value).toString() !== value) {
            return
        }
        ;(filters[name] as number) = +value
    })

    // Just string
    stringFilters.forEach(name => {
        const value = urlFilters[name]
        if (typeof value !== 'string') {
            return
        }
        ;(filters[name] as string) = value
    })

    // Array filters: "one,two,…"
    arrayFilters.forEach(name => {
        const value = urlFilters[name]
        if (typeof value !== 'string') {
            return
        }
        filters[name] = value.split(ITEM_SEP)
    })

    // To column filters: "std:1,prm:0"
    columnsFilters.forEach(name => {
        const value = urlFilters[name]
        if (typeof value !== 'string') {
            return
        }
        filters[name] = value
            .split(ITEM_SEP)
            .map(item => {
                const [field, val] = item.split(VALUE_SEP)
                return { field, visible: val === '1' }
            })
            .filter(item => item.field in fields)
    })

    return filters
}

export const stringifyFilters = (filters: FiltersObject): string => {
    const normalizedFilters: { [key: string]: string | number | boolean | undefined } = {}

    Object.keys(filters).forEach(key => {
        const value = filters[key]
        if (Array.isArray(value)) {
            normalizedFilters[key] = value
                .map(f => (typeof f === 'string' ? f : `${f.field}${VALUE_SEP}${+f.visible}`))
                .join(ITEM_SEP)
        } else {
            normalizedFilters[key] = value
        }
    })

    return stringify(normalizedFilters)
}

export const getAirportUrlParam = (airport?: AosAirport): string =>
    airport
        ? format({
              query: {
                  airport,
              },
          })
        : ''
