import { AosMapEvent } from './AosMapEvent'
import { MapTask } from './MapTask'

export enum HoveredMapElementType {
    Event = 'event',
    Task = 'task',
}

export interface EventHoveredElement {
    type: HoveredMapElementType.Event
    payload: AosMapEvent
}

export interface TaskHoveredElement {
    type: HoveredMapElementType.Task
    payload: MapTask
}

export type HoveredMapElement = EventHoveredElement | TaskHoveredElement
