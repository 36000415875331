import { acceptString } from 'aos-services/src/services/attachments/types/AttachmentType'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

interface FileUploadProps {
    onChange(v: FileList): void
}

export const FileUpload: FCWithChildren<FileUploadProps> = ({ onChange }) => {
    const onChangeInner = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files) {
            onChange(ev.target.files)
        }
    }
    return <FileUploadInput type='file' multiple accept={acceptString} onChange={onChangeInner} />
}

const FileUploadInput = styled.input`
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`
