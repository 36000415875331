import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum TaskProcessType {
    Daily = 'DAILY',
    Seasonal = 'SEASONAL',
    AutomatedVehicleTask = 'AUTOMATED_VEHICLE_TASK',
}

export const taskProcessTypes = [TaskProcessType.Daily, TaskProcessType.Seasonal]

export const translateTaskProcessType = translateEnum(TaskProcessType, 'task-process-type')
