import {
    SUBMIT,
    SubmitAction,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/actions'
import { takeEveryChildAction } from 'aos-helpers/src/helpers/Saga'
import { PrivateChannelParticipantEventRef } from 'aos-services/src/services/firebaseEvents/input/PrivateChannelParticipantInput'
import { put } from 'redux-saga/effects'

import { I_WILL_ARRIVE_PARENT, updateInvitationAction } from '../actions'

function* submitIWillArriveTimeSaga() {
    function* createPrivateChannel(action: SubmitAction) {
        const eventRef: PrivateChannelParticipantEventRef = action.payload.payload
        yield put(updateInvitationAction({ ...eventRef, arrivalTime: action.payload.value }))
    }

    yield takeEveryChildAction<SubmitAction>(I_WILL_ARRIVE_PARENT, SUBMIT, createPrivateChannel)
}

export const iWillArriveSagas = [submitIWillArriveTimeSaga]
