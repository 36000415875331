import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { isResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SomethingWentWrongToast } from 'aos-ui/src/components/toast/SomethingWentWrongToast'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { toast } from 'react-toastify'

export const globalResponseErrorToast = (error: any) => {
    if (isResponseError(error) && !error.handled) {
        toast(<SomethingWentWrongToast error={error} />, {
            // emit one toast at once to prevent error overload on StatusDashboard,
            // which repeatedly fires many requests
            toastId: 'somethingWentWrong',
            type: 'error',
            icon: (
                <Icon
                    color={Color.Red}
                    svg={SvgIcon.WarningBold}
                    iconSize={BlockSize.Std}
                    marginRight={12}
                />
            ),
        })
    }
}
