import { isHelSelector } from 'aos-services/src/core/auth/state'
import { weatherDataSelector } from 'aos-services/src/core/statusDashboardData/weather/selectors'
import { WeatherTimePoint } from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { DashboardDataState } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import {
    WeatherGroup,
    WeatherTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import React, { FC, PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setWeatherTabStateAction } from '../../../../core/statusDashboard/actions'
import { WeatherItemState } from '../../../../services/statusDashboard/types/itemStates/WeatherItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { WeatherCompactView } from './compactView/WeatherCompactView'
import { WeatherFullView } from './fullView/WeatherFullView'
import { WeatherItem } from './WeatherItem'

export const Weather: FC<PropsWithChildren<WeatherOwnProps>> = ({ itemState, id, ...rest }) => {
    const dispatch = useDispatch()
    const {
        weatherSeries,
        weatherSeriesMetric,
        weatherCategory,
        weatherAlert,
        lvpAlert,
        noDataWarning,
    } = useSelector((state: DashboardDataState) => weatherDataSelector(state, { itemState }))
    const isHel = useSelector(isHelSelector)

    const handleChangeTab = (tab: WeatherTimePoint) => {
        dispatch(setWeatherTabStateAction([id, { timePoint: tab }]))
    }

    const handleChangeUnitTab = (unitTab: WeatherUnit) => {
        dispatch(setWeatherTabStateAction([id, { unit: unitTab }]))
    }

    const handleChangeGroupTab = (groupTab: WeatherGroup) => {
        dispatch(setWeatherTabStateAction([id, { group: groupTab }]))
    }

    const handleChangeTimeRangeTab = (timeRangeTab: WeatherTimeRange) => {
        dispatch(setWeatherTabStateAction([id, { timeRange: timeRangeTab }]))
    }

    const renderContent = () => {
        const isSmall = itemState.size === DashboardItemSizeVariant.Small

        return (
            <>
                {isSmall ? (
                    <WeatherCompactView
                        weatherSeriesMetric={weatherSeriesMetric}
                        weatherCategory={weatherCategory}
                        tab={itemState.timePoint}
                        unitTab={itemState.unit}
                        onChangeTime={handleChangeTab}
                        onChangeUnit={handleChangeUnitTab}
                    />
                ) : (
                    <WeatherFullView
                        isHel={isHel}
                        weatherSeries={weatherSeries || {}}
                        timeRangeTab={itemState.timeRange}
                        groupTab={itemState.group}
                        unitTab={itemState.unit}
                        onChangeGroup={handleChangeGroupTab}
                        onChangeTimeRange={handleChangeTimeRangeTab}
                        onChangeUnit={handleChangeUnitTab}
                    />
                )}
            </>
        )
    }

    return (
        <WeatherItem
            syncId={id}
            itemProps={pickDashboardItemProps({
                itemState,
                id,
                ...rest,
            })}
            noDataWarning={noDataWarning}
            weatherAlert={weatherAlert}
            lvpAlert={lvpAlert}
        >
            {renderContent()}
        </WeatherItem>
    )
}

type WeatherOwnProps = StatusDashboardItemCommonProps<WeatherItemState>
