import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { ClickOutside } from 'aos-ui/src/components/base/ClickOutside'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, {
    Children,
    FCWithChildren,
    isValidElement,
    PropsWithChildren,
    ReactNode,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'

import { IconButton } from '../buttons/IconButton'
import {
    DropdownContent,
    DropdownItemWrapper,
    DropdownLabel,
} from '../form/dropdown/base/DropdownContent'
import { DropdownPortal, DropdownPortalType } from '../form/dropdown/base/DropdownPortal'
import { DropdownVariant } from '../form/dropdown/base/DropdownVariant'
import { Icon, IconVariant } from '../svg/Icon'

interface MoreProps extends MarginBoxProps {
    trigger?: ReactNode
    header?: ReactNode
}

export const More: FCWithChildren<PropsWithChildren<MoreProps>> = ({
    children,
    trigger,
    header,
    ...props
}) => {
    const [isOpen, setOpen] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    const activeChildren = Children.toArray(children).filter(element => {
        if (isValidElement<MoreItemProps>(element)) {
            return !!element.props?.onClick
        }
        return false
    })

    if (!activeChildren) {
        return null
    }

    return (
        <Box {...props}>
            <Box
                paddingVertical={8}
                ref={ref}
                onClick={event => {
                    event.stopPropagation()
                    setOpen(true)
                }}
                data-test-id='more-menu'
            >
                {trigger || (
                    <IconButton
                        iconVariant={IconVariant.White}
                        svg={SvgIcon.More}
                        iconSize={BlockSize.Std}
                        seleniumLocation='more-icon-button'
                    />
                )}
            </Box>
            {isOpen && (
                <DropdownPortal element={ref} type={DropdownPortalType.BottomRight}>
                    <ClickOutside
                        onClickOutside={() => setOpen(false)}
                        data-test-id='menu-expanded'
                    >
                        <DropdownContentBox
                            paddingVertical={12}
                            variant={DropdownVariant.White}
                            onClick={event => {
                                event.stopPropagation()
                                setOpen(false)
                            }}
                        >
                            {header && <MoreHeader>{header}</MoreHeader>}
                            {activeChildren.map((element, index) => (
                                <Box key={index} onClick={() => setOpen(false)}>
                                    {element}
                                </Box>
                            ))}
                        </DropdownContentBox>
                    </ClickOutside>
                </DropdownPortal>
            )}
        </Box>
    )
}

interface MoreItemProps {
    disabled?: boolean
    onClick?(): void
}

export const MoreItem: FCWithChildren<PropsWithChildren<MoreItemProps>> = ({
    onClick,
    children,
    disabled,
}) => (
    <DropdownItemWrapper
        paddingHorizontal={16}
        onClick={onClick}
        variant={DropdownVariant.White}
        itemHeight={36}
        isSelected={disabled}
        row
    >
        <DropdownLabel size={13} as='span' variant={DropdownVariant.White} isSelected={disabled}>
            {children}
        </DropdownLabel>
    </DropdownItemWrapper>
)

interface MoreIconItemProps extends MoreItemProps {
    icon: Svg
}

export const MoreIconItem: FCWithChildren<PropsWithChildren<MoreIconItemProps>> = props => {
    const { icon, children, onClick, disabled } = props
    return (
        <MoreItem onClick={onClick} disabled={disabled}>
            <Box row>
                <Icon svg={icon} iconSize={BlockSize.Small} />
                <Box paddingLeft={4}>{children}</Box>
            </Box>
        </MoreItem>
    )
}

export const MoreButton = styled(Box)``

export const MoreHeader: FCWithChildren<PropsWithChildren> = ({ children }) => (
    <MoreHeaderBox padding={16} paddingTop={8}>
        {children}
    </MoreHeaderBox>
)

const MoreHeaderBox = styled(Box)`
    border-bottom: 1px solid ${Color.Grey1};
`

export const MoreHover = styled(Box)`
    transition: opacity 0.3s ease-in-out;
    ${MoreButton} {
        opacity: 0;
    }
    :hover ${MoreButton} {
        opacity: 1;
    }
`

const DropdownContentBox = styled(DropdownContent)`
    min-width: 120px;
`
