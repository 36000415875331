import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'

interface EventRef {
    severity: AosSeverity
    hasPrivateChannel: boolean
}

export function eventItemIconVariant(inTooltip: boolean, item: EventRef) {
    const isNotice = item.severity === AosSeverity.Notice
    return inTooltip || (isNotice && !item.hasPrivateChannel)
        ? IconVariant.Black
        : IconVariant.White
}

export function eventItemTextColor(inTooltip: boolean, item: EventRef) {
    const isNotice = item.severity === AosSeverity.Notice
    return inTooltip || (isNotice && !item.hasPrivateChannel) ? Color.Black : Color.White
}
