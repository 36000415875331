import { Box } from 'aos-ui/src/components/base/Box'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import React, { FCWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

export interface FormButtonContentProps {
    transparent?: boolean
    size: FormComponentSize
    children: ReactNode
}

const spacingMap: Record<FormComponentSize, number> = {
    [FormComponentSize.Big]: 48,
    [FormComponentSize.Header]: 24,
    [FormComponentSize.Std]: 40,
    [FormComponentSize.Small]: 24,
    [FormComponentSize.VerySmall]: 12,
}

export const LabelContent: FCWithChildren<FormButtonContentProps> = ({
    children,
    size,
    transparent,
}) => (
    <Label size={size} transparent={transparent}>
        {children}
    </Label>
)

const Label = styled.div<FormButtonContentProps>`
    padding: 0 ${p => spacingMap[p.size]}px;
    color: ${p => (p.transparent ? 'transparent' : 'inherit')};
    text-align: center;
`

interface LeftRightContentProps extends FormButtonContentProps {
    left?: ReactNode
    right?: ReactNode
}

export const LeftRightContent: FCWithChildren<LeftRightContentProps> = ({
    children,
    left,
    right,
    transparent,
    size,
}) => (
    <LabelRightLabel left={left} right={right} transparent={transparent} size={size}>
        {left && (
            <Box width={spacingMap[size]} centered>
                {left}
            </Box>
        )}
        {children}
        {right && (
            <Box width={spacingMap[size]} centered>
                {right}
            </Box>
        )}
    </LabelRightLabel>
)

const LabelRightLabel = styled.div<LeftRightContentProps>`
    display: flex;
    flex-direction: row;
    padding-left: ${p => (p.left ? 0 : spacingMap[p.size])}px;
    padding-right: ${p => (p.right ? 0 : spacingMap[p.size])}px;
    color: ${p => (p.transparent ? 'transparent' : 'inherit')};
`
