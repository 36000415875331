import { Box } from 'aos-ui/src/components/base/Box'
import { LeftRight } from 'aos-ui/src/components/leftRight/LeftRight'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { Bar } from './Bar'

export interface DispersionBarConfig {
    leftColor: Color
    rightColor: Color
    borderColor?: Color
    showValues: boolean
}

export interface DispersionBarProps {
    barConfig: DispersionBarConfig
    barData: [number, number]
}

export const DispersionBar: FCWithChildren<DispersionBarProps> = ({
    barConfig,
    barData: [leftValue, rightValue],
}) => {
    const leftBarValue = leftValue === 0 && rightValue === 0 ? 50 : leftValue
    const rightBarValue = leftValue === 0 && rightValue === 0 ? 50 : rightValue
    const total = leftBarValue + rightBarValue
    return (
        <Box>
            <Box paddingBottom={8}>
                <Bar
                    data={[leftBarValue, rightBarValue]}
                    colors={[barConfig.leftColor, barConfig.rightColor]}
                    borderColor={barConfig.borderColor}
                />
            </Box>
            {barConfig.showValues && (
                <LeftRight
                    textNoWrap
                    color={Color.Grey}
                    size={12}
                    weight='regular'
                    left={`${Math.round(total ? (leftValue / total) * 100 : 0)}%`}
                    right={`${Math.round(total ? (rightValue / total) * 100 : 0)}%`}
                />
            )}
        </Box>
    )
}
