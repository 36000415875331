import { ClassNameProps, cxp } from 'aos-components/src/components/base/ClassNames'
import {
    getColorForPrm,
    PrmColor,
} from 'aos-services/src/services/flightInformation/types/AosPublicRemark'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import React, { PureComponent } from 'react'

export class PublicRemarkCell extends PureComponent<PublicRemarkCellProps> {
    public render() {
        const flight = this.props.row
        const isDelayed = flight.isDelayedBasedOnEstimated
        const color = !isDelayed ? getColorForPrm(flight.prm.code) : PrmColor.Yellow
        const textClass = this.props.className ? '' : 'text__m-white'

        return (
            <span className={cxp(this.props, textClass)} style={{ color }}>
                {flight.prm.text}
            </span>
        )
    }
}

interface PublicRemarkCellProps extends ClassNameProps {
    row: Flight
}
