import { BaggageClaimProperties } from '../../layerData/properties/BaggageClaimProperties'
import { BusDoorProperties } from '../../layerData/properties/BusDoorProperties'
import { GatesProperties } from '../../layerData/properties/GatesProperties'
import { RingRailTrainMapProperties } from '../../layerData/properties/RingRailTrainMapProperties'
import { StandProperties } from '../../layerData/properties/StandProperties'
import { AosMapEventProperties } from './AosMapEvent'
import { MapTaskProperties } from './MapTask'

export enum SelectedMapElementType {
    Event = 'event',
    Task = 'task',
    Gate = 'gate',
    BusGate = 'busGate',
    Stand = 'stand',
    BaggageClaim = 'baggageClaim',
    Train = 'train',
    BusDoor = 'busDoor',
    Fod = 'fod',
}

export interface EventSelectedElement {
    type: typeof SelectedMapElementType.Event
    payload: AosMapEventProperties
}

export interface TaskSelectedElement {
    type: typeof SelectedMapElementType.Task
    payload: MapTaskProperties
}

export interface GateSelectedElement {
    type: typeof SelectedMapElementType.Gate
    payload: GatesProperties
}

export interface BusGateSelectedElement {
    type: typeof SelectedMapElementType.BusGate
    payload: GatesProperties
}

export interface StandSelectedElement {
    type: typeof SelectedMapElementType.Stand
    payload: StandProperties
}

export interface BaggageClaimSelectedElement {
    type: typeof SelectedMapElementType.BaggageClaim
    payload: BaggageClaimProperties
}

export interface BusDoorSelectedElement {
    type: typeof SelectedMapElementType.BusDoor
    payload: BusDoorProperties
}

export interface TrainSelectedElement {
    type: typeof SelectedMapElementType.Train
    payload: { train: RingRailTrainMapProperties }
}

export const trainForSelectedElement = (
    selectedElement: SelectedMapElement | null,
): RingRailTrainMapProperties | undefined =>
    selectedElement && selectedElement.type === SelectedMapElementType.Train
        ? selectedElement.payload.train
        : undefined

export type SelectedMapElement =
    | EventSelectedElement
    | TaskSelectedElement
    | GateSelectedElement
    | BusGateSelectedElement
    | BaggageClaimSelectedElement
    | StandSelectedElement
    | TrainSelectedElement
    | BusDoorSelectedElement

export const selectionTargetKey = 'selectTarget'
