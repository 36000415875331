import { calculateDomain } from 'aos-helpers/src/helpers/domain/Domain'
import { paxSelector } from 'aos-services/src/core/statusDashboardData/pax/selectors'
import { PaxEntry } from 'aos-services/src/services/pax/types/PaxEntry'
import { PointId } from 'aos-services/src/services/pax/types/PointId'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import {
    PaxSecurityPoint,
    translatePaxSecurityPoint,
} from 'aos-services/src/services/statusDashboard/types/filters/PaxFilters'
import { Box } from 'aos-ui/src/components/base/Box'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { RoundTabs } from 'aos-ui/src/components/tabs/RoundTabs'
import { EnumValues } from 'enum-values'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeMeProps } from 'react-sizeme'
import styled from 'styled-components'

import { setPaxTabAction } from '../../../../core/statusDashboard/actions'
import { PaxItemState } from '../../../../services/statusDashboard/types/itemStates/PaxItemState'
import { ChartTitle } from '../../partials/ChartTitle'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { Footer } from '../base/Footer'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { PaxItem } from './partials/PaxItem'
import { PaxChart } from './PaxChart'

export const Pax: FC<PaxProps> = props => {
    const pax = useSelector(paxSelector)
    const dispatch = useDispatch()
    const domain = useCallback(
        (pointId: PointId) => {
            const data = pax.paxGroups[pointId]
            const largestWaitingTime = data ? Math.max(...data.map(p => p.waitingTime)) : 1

            if (!data) {
                return null
            }

            return {
                y1Domain: calculateDomain(
                    data.map(p => p.pax),
                    p => p,
                    5,
                ),
                y2Domain: calculateDomain(
                    data.map(p => p.waitingTime),
                    p => p,
                    5,
                    Math.min(0.1 * largestWaitingTime, 10),
                ),
            }
        },
        [pax.paxGroups],
    )
    const renderContent = () => {
        const { itemState } = props
        if (itemState.size === DashboardItemSizeVariant.Small) {
            return renderSmallVariant()
        }

        return renderBigVariant()
    }
    const renderChartSection = (tab: PaxSecurityPoint, index: number) => (
        <ItemContainer vertical horizontalSpacing autoHeight key={index} chart chartSpacing>
            <ChartTitle>{translatePaxSecurityPoint(tab)}</ChartTitle>
            {index === 0 ? (
                <FloatingIcon svg={SvgIcon.Pax} />
            ) : (
                <FloatingIconBottom svg={SvgIcon.Pax} />
            )}
            <Box column flex='auto' marginLeft={20}>
                {renderChart(paxEntriesForTab(tab), true, paxTabForPointId[tab])}
            </Box>
        </ItemContainer>
    )

    const renderChart = (entries: PaxEntry[], showLegend: boolean, pointId: PointId) => {
        const domainConfig = domain(pointId)

        return (
            !!domainConfig && (
                <PaxChart
                    data={entries}
                    y2Domain={domainConfig.y2Domain}
                    y1Domain={domainConfig.y1Domain}
                    showLegend={showLegend}
                />
            )
        )
    }

    const paxEntriesForTab = (tab: PaxSecurityPoint) => pax.paxGroups[paxTabForPointId[tab]] || []
    const renderSmallVariant = () => {
        const setTab = (f: PaxSecurityPoint) => dispatch(setPaxTabAction([props.id, f]))
        return (
            <>
                <ItemContainer vertical horizontalSpacing autoHeight chart chartSpacing>
                    {renderChart(
                        paxEntriesForTab(props.itemState.paxSecurityPoint),
                        true,
                        paxTabForPointId[props.itemState.paxSecurityPoint],
                    )}
                </ItemContainer>
                <Footer>
                    <RoundTabs
                        items={EnumValues.getValues(PaxSecurityPoint)}
                        value={props.itemState.paxSecurityPoint}
                        name='pax-tab'
                        formatter={translatePaxSecurityPoint}
                        onChange={setTab}
                    />
                </Footer>
            </>
        )
    }

    const renderBigVariant = () => (
        <>
            {EnumValues.getValues(PaxSecurityPoint).map(renderChartSection)}
            <Footer />
        </>
    )

    return (
        <PaxItem itemProps={pickDashboardItemProps(props)} syncId={props.id}>
            {renderContent()}
        </PaxItem>
    )
}
const paxTabForPointId: Record<PaxSecurityPoint, PointId> = {
    [PaxSecurityPoint.Dep]: PointId.Dep,
    [PaxSecurityPoint.Point9]: PointId.Point9,
}

const FloatingIcon = styled(Icon)`
    position: absolute;
    top: 150px;
    left: 10px;
`

const FloatingIconBottom = styled(Icon)`
    position: absolute;
    bottom: 320px;
    left: 10px;
`

type PaxOwnProps = StatusDashboardItemCommonProps<PaxItemState>

interface PaxProps extends PaxOwnProps, SizeMeProps {}
