import { isValidNumber } from 'libphonenumber-js'
import { every } from 'lodash'
import { Moment } from 'moment/moment'
import { useState } from 'react'

export interface FormValidationState<T> {
    pristine: boolean
    valid: boolean
    fields: T
}

export class FormValidation<T extends object> implements FormValidationState<T> {
    public readonly pristine: boolean
    public readonly fields: T

    public static fromFields<P extends object>(fields: P, pristine: boolean = true) {
        return new FormValidation(pristine, fields)
    }

    private static isObjectValid = (v: object): boolean => {
        return Object.values(v).every(item => {
            if (typeof item === 'boolean') {
                return item
            }
            if (Array.isArray(item)) {
                return every(item.map(FormValidation.isObjectValid), i => i === true)
            }
            if (typeof item === 'object') {
                return FormValidation.isObjectValid(item)
            }
            return true
        })
    }

    private static objectProxy = (v: object, pristine: boolean): any => {
        return new Proxy(v, {
            get: (target: any, property: any) => {
                const item = target[property]
                if (typeof item === 'boolean') {
                    return !item && !pristine
                }
                if (Array.isArray(item)) {
                    return item.map(i => FormValidation.objectProxy(i, pristine))
                }
                if (typeof item === 'object') {
                    return FormValidation.objectProxy(item, pristine)
                }
                return true
            },
        })
    }

    private constructor(pristine: boolean, fields: T) {
        this.fields = fields
        this.pristine = pristine
    }

    get valid() {
        return FormValidation.isObjectValid(this.fields)
    }

    public modify = (c: Partial<T>, pristine?: boolean): FormValidation<T> =>
        new FormValidation(
            pristine === undefined ? this.pristine : pristine,
            Object.assign({}, this.fields, c),
        )

    public setPristine = (c: boolean): FormValidation<T> =>
        new FormValidation(c, Object.assign({}, this.fields))

    get error(): T {
        return FormValidation.objectProxy(this.fields, this.pristine)
    }
}

export function isEmpty(t: any): boolean {
    return t === '' || t === undefined || t === null
}

export function isNotEmpty(t: any): boolean {
    return !isEmpty(t)
}

export function isEndDateAfterStartDate(
    startTime: Moment | undefined,
    endTime: Moment | undefined,
): boolean {
    return endTime ? isNotEmpty(startTime) && endTime.isAfter(startTime) : true
}

export function isNotEmptyArray(a: any[]): boolean {
    return a !== null && a.length > 0
}

export const areObjectValuesEmpty = <T extends object>(obj: T) =>
    Object.values(obj).every(value => isEmpty(value))

export function isValidEmail(email: string | undefined) {
    /* tslint:disable:max-line-length */
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email !== undefined && email !== null ? re.test(email) : false
}

export function isNumber(value: unknown) {
    return value !== undefined && value !== null && !isNaN(parseFloat(value as string))
}

export function isValidPhone(phone: string | undefined) {
    if (phone === undefined || phone === null) {
        return false
    }
    return isValidNumber(phone, 'FI')
}

export const useFormValidation = <S, T extends object>(
    initialState: S,
    validator: f.Func1<S, T>,
) => {
    const [state, setStateInternal] = useState<{ state: S; form: FormValidation<T> }>({
        state: initialState,
        form: FormValidation.fromFields(validator(initialState)),
    })

    const setState = (s: Partial<S>) => {
        setStateInternal(current => {
            const newState = { ...current.state, ...s }
            return {
                state: newState,
                form: current.form.modify(validator(newState), current.form.pristine),
            }
        })
    }

    const setPristine = (v: boolean) =>
        setStateInternal(current => ({ state: current.state, form: current.form.setPristine(v) }))

    return {
        state: state.state,
        form: state.form,
        setState,
        setPristine,
    }
}
