import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { thinSpace } from 'aos-helpers/src/helpers/Text'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, ReactNode } from 'react'

interface ReportSummaryTileProps {
    color?: Color
    title?: string
    titleSlot?: ReactNode
    value: number
    unit?: string
    description?: string
    large?: boolean
}

export const ReportSummaryTile: FC<ReportSummaryTileProps> = ({
    color = Color.White,
    title,
    titleSlot,
    value,
    unit,
    description,
    large,
}) => {
    return (
        <Box marginRight={30} marginBottom={12}>
            {title && (
                <Box row alignItems='center'>
                    <Text size={18} color={color}>
                        {title}
                    </Text>
                    {titleSlot}
                </Box>
            )}

            <Text size={large ? 52 : 36} color={color} selectable>
                {formatNumber(value)}
                {unit && (
                    <Text size={18} as='span'>
                        {thinSpace + unit}
                    </Text>
                )}
            </Text>

            {description && (
                <Text size={12} color={color}>
                    {description}
                </Text>
            )}
        </Box>
    )
}
