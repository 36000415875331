import { lonLatToCoordinate } from 'aos-helpers/src/helpers/coordinate/CoordinateTransformer'
import { distanceBetweenLocation, LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { chain, Dictionary, every, isUndefined, keyBy, pick } from 'lodash'
import { Coordinate } from 'ol/coordinate'

import { ringRailDataAccessService } from '../../dataaccess/layerData/ringRailDataAccessService'
import { Station, Train, TrainLocation } from '../../dataaccess/layerData/types/RingRail'
import { RingRailStation, RingRailTrain } from '../layerData/types/RingRailTrain'
import { ringRailTrainMapper } from './ringRailTrainMapper'

type Vector = [number, number]

class RingRailService {
    public getTrainStations = (): Promise<Dictionary<RingRailStation>> =>
        ringRailDataAccessService
            .getTrainStations()
            .then(stations => this.generateStations(stations))

    public generateStations = (stations: Station[]): Dictionary<RingRailStation> => {
        const ringRailStation = chain(stations)
            .filter(station => !!station.passengerTraffic)
            .map(station =>
                pick(station, ['stationName', 'stationShortCode', 'longitude', 'latitude']),
            )
            .value()
        return keyBy(ringRailStation, 'stationShortCode')
    }

    public getCurrentTrains = (stations: Dictionary<RingRailStation>): Promise<RingRailTrain[]> =>
        Promise.all([
            ringRailDataAccessService.getCurrentTrains(),
            ringRailDataAccessService.getCurrentTrainLocations(),
        ]).then(([trains, positions]) => this.generateTrains(trains, positions, stations))

    public generateTrains = (
        trains: Train[],
        positions: TrainLocation[],
        stations: Dictionary<RingRailStation>,
    ) => {
        const locationDict = keyBy(positions, 'trainNumber')

        return trains.map(train => {
            const ringRailTrain = ringRailTrainMapper.ringRailTrainForTrain(train)
            const ringRailTrainPosition = this.getTrainLocationFromDict(
                locationDict,
                train.trainNumber,
            )

            return {
                ...ringRailTrain,
                ...ringRailTrainPosition,
                ...ringRailTrainMapper.ringRailTrainPositionForTrainByStation(
                    ringRailTrain,
                    stations,
                ),
                prevPosition: [],
            }
        })
    }

    public updateTrains = (
        currentTrains: RingRailTrain[],
        changedTrains: Train[],
        stations: Dictionary<Station>,
    ) => {
        const trainDict = keyBy(changedTrains, 'trainNumber')
        const newChangedTrains = changedTrains.filter((train: Train) =>
            isUndefined(currentTrains.find(t => t.trainNumber === train.trainNumber)),
        )

        const newTrains = newChangedTrains.map(train => {
            const ringRailTrain = ringRailTrainMapper.ringRailTrainForTrain(train)

            return {
                ...ringRailTrain,
                ...ringRailTrainMapper.ringRailTrainPositionForTrainByStation(
                    ringRailTrain,
                    stations,
                ),
                prevPosition: [],
            }
        })

        const updatedTrains = currentTrains.map(train => {
            const ringRailTrain = this.getTrainFromDict(trainDict, train.trainNumber)
            const ringRailTrainPosition = ringRailTrain
                ? ringRailTrainMapper.ringRailTrainPositionForTrainByStation(
                      ringRailTrain,
                      stations,
                  )
                : {}

            return this.storeAngle(
                this.storePrevPosition({
                    ...train,
                    ...ringRailTrain,
                    ...ringRailTrainPosition,
                }),
            )
        })

        return [...updatedTrains, ...newTrains]
    }

    public updateTrainLocations = (currentTrains: RingRailTrain[], positions: TrainLocation[]) => {
        const locationDict = keyBy(positions, 'trainNumber')

        return currentTrains.map(train => {
            const ringRailTrainPosition = this.getTrainLocationFromDict(
                locationDict,
                train.trainNumber,
            )
            const shouldUpdatePosition = this.isLocationValid(
                train.prevPosition,
                ringRailTrainPosition.position,
            )

            return shouldUpdatePosition
                ? this.storeAngle(
                      this.storePrevPosition({
                          ...train,
                          ...ringRailTrainPosition,
                      }),
                  )
                : train
        })
    }

    public getTrainFromDict = (
        dict: Dictionary<Train>,
        trainNumber: number,
    ): Partial<RingRailTrain> => {
        if (dict[trainNumber]) {
            return ringRailTrainMapper.ringRailTrainForTrain(dict[trainNumber])
        }
        return {}
    }

    public getTrainLocationFromDict = (
        dict: Dictionary<TrainLocation>,
        trainNumber: number,
    ): Partial<RingRailTrain> => {
        if (dict[trainNumber] && dict[trainNumber].location) {
            return ringRailTrainMapper.ringRailTrainPositionForTrainLocation(dict[trainNumber])
        }
        return {}
    }

    public calculateAngleBetweenPositions = (o: LonLat, n: LonLat) =>
        this.calculateAngleBetweenPoints(lonLatToCoordinate(o), lonLatToCoordinate(n))

    public calculateAngleBetweenPoints = (o: Coordinate, n: Coordinate) => {
        const v1: Vector = [n[0] - o[0], n[1] - o[1]]
        const plusAngle = v1[1] < 0 ? Math.PI : 0
        const sign = v1[0] < 0 ? -1 : 1
        return Math.atan(v1[0] / v1[1]) + sign * plusAngle
    }

    private storePrevPosition = (train: RingRailTrain): RingRailTrain => {
        if (!this.isLocationValid([train.prevPosition[0]], train.position)) {
            return train
        }

        return {
            ...train,
            prevPosition: [train.position!, ...train.prevPosition].slice(0, 4),
        }
    }

    private storeAngle = (train: RingRailTrain) => {
        if (train.prevPosition.length < 2) {
            return train
        }
        return {
            ...train,
            angle: this.calculateAngleBetweenPositions(
                train.prevPosition[1],
                train.prevPosition[0],
            ),
        }
    }

    private isLocationValid = (locations: LonLat[], b?: LonLat) => {
        if (b === undefined) {
            return false
        }

        return every(
            locations,
            location => !location || distanceBetweenLocation(location, b) > 0.000001,
        )
    }
}

export const ringRailService: RingRailService = new RingRailService()
