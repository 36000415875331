import { BaseMapTask } from 'aos-services/src/services/map/types/MapTask'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import { isEqual } from 'lodash'
import React, { FC } from 'react'

import { renderTaskIcon } from '../../icons/MapIconRenderer'
import { BaseLayerProps } from '../base/BaseLayer'
import { MarkerLikeLayer } from './MarkerLikeLayer'

interface TasksLayerProps extends BaseLayerProps {
    tasks: BaseMapTask[]
}

export const TasksLayer: FC<TasksLayerProps> = ({
    tasks,
    isVisible,
    zIndex,
    layerId,
    minZoom,
    maxZoom,
}) => {
    const comparator = (t1: BaseMapTask, t2: BaseMapTask) =>
        t1.id === t2.id &&
        isEqual(t1.location, t2.location) &&
        t1.severity === t2.severity &&
        t1.category === t2.category

    return (
        <MarkerLikeLayer
            comparator={comparator}
            items={tasks}
            iconRenderer={renderTaskIcon}
            isVisible={isVisible}
            zIndex={zIndex}
            layerId={layerId}
            minZoom={minZoom}
            maxZoom={maxZoom}
            selectionTarget={SelectedMapElementType.Task}
            setter={task => ({
                task,
            })}
        />
    )
}
