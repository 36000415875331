import { SimpleHorizontalLegend } from 'aos-components/src/components/chart/SimpleHorizontalLegend'
import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { LegendDotVariant } from 'aos-ui/src/components/ui/LegendItemDot'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const PaxForecastHoursChartLegend: FC = () => (
    <SimpleHorizontalLegend
        leftItems={[
            [
                { mainColor: Color.White, variant: LegendDotVariant.Line },
                translate('dashboard.pax-forecast.chart.hours.total'),
            ],
        ]}
        rightItems={[
            [
                { mainColor: Color.PaleOrange },
                translate('dashboard.pax-forecast.chart.hours.arrivals'),
            ],
            [
                { mainColor: Color.Violet },
                translate('dashboard.pax-forecast.chart.hours.departures'),
            ],
        ]}
        size={BlockSize.Tiny}
    />
)
