import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box, BoxProps } from '../base/Box'

interface SelectableItemProps extends BoxProps {
    isSelected: boolean
    onClick(): void
}

export const SelectableItem: FCWithChildren<PropsWithChildren<SelectableItemProps>> = ({
    isSelected,
    onClick,
    children,
    ...boxProps
}) => (
    <SelectableBox isSelected={isSelected} onClick={onClick} cursor='pointer' {...boxProps}>
        {children}
    </SelectableBox>
)

const SelectableBox = styled(Box)<{ isSelected: boolean }>`
    border-bottom: 1px solid ${Color.UiBlack3};
    ${p =>
        p.isSelected &&
        css`
            background: ${Color.UiBlack1};
        `}
`
