import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { SeleniumProps } from 'aos-ui/src/components/base/SeleniumProps'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import React from 'react'

import {
    DropdownAutocompleteCreateNew,
    DropdownAutocompleteCreateNewProps,
} from './DropdownAutocompleteCreateNew'

interface LabeledDropdownAutocompleteCreateNewProps
    extends SeleniumProps,
        DropdownAutocompleteCreateNewProps,
        MarginBoxProps {
    keyPrefix: string
    isRequired?: boolean
    isError?: boolean
    inputVariant?: ThemeVariant
}

export const LabeledDropdownAutocompleteCreateNew = ({
    keyPrefix,
    isRequired,
    isError,
    inputVariant = ThemeVariant.White,
    items,
    value,
    placeholder,
    width,
    small,
    valueRenderer,
    labelRenderer,
    onChange,
    seleniumLocation,
    variant,
    maxHeight,
    ...marginProps
}: LabeledDropdownAutocompleteCreateNewProps) => {
    const translateSuffix = (value: string) => translate(`${keyPrefix}.${value}`)
    const dropdownVariant =
        inputVariant === ThemeVariant.White ? DropdownVariant.White : DropdownVariant.Black
    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={inputVariant}
            {...marginProps}
        >
            <DropdownAutocompleteCreateNew
                value={value}
                items={items}
                variant={dropdownVariant}
                valueRenderer={valueRenderer}
                labelRenderer={labelRenderer}
                placeholder={translateSuffix('placeholder')}
                onChange={onChange}
                maxHeight={maxHeight}
                outlined
            />
        </LabeledFormElement>
    )
}
