import { attachmentsService } from 'aos-services/src/services/attachments/attachmentsService'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'

import { Box } from '../../base/Box'
import { AttachmentContainerItem } from './AttachmentContainerItem'
import { PreviewableProps } from './PreviewableProps'

interface PreviewablePhotoProps extends PreviewableProps {
    attachment: UploadedAttachment
}

export const PreviewableFile: FCWithChildren<PreviewablePhotoProps> = ({ attachment, ...rest }) => {
    const extension = (attachmentsService.extensionForAttachment(attachment) || '').toUpperCase()
    return (
        <AttachmentContainerItem {...rest}>
            <Box coverAll centered>
                {extension}
            </Box>
        </AttachmentContainerItem>
    )
}
