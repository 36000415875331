import { CLOSE_REPORTING_MODAL, OPEN_REPORTING_MODAL, ReportingAction } from './actions'
import { initialReportingState, ReportingState } from './state'

export const reportingReducer = (
    state: ReportingState = initialReportingState,
    action: ReportingAction,
): ReportingState => {
    switch (action.type) {
        case OPEN_REPORTING_MODAL:
            return {
                isOpen: true,
            }

        case CLOSE_REPORTING_MODAL:
            return {
                isOpen: false,
            }

        default:
            return state
    }
}
