import {
    WhiteModalContent,
    WhiteModalContentVariant,
} from 'aos-components/src/components/modal/WhiteModal/WhiteModalContent'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { PureComponent } from 'react'

export class UserError extends PureComponent<UserErrorProps> {
    public render() {
        return (
            <WhiteModalContent
                title={this.props.email}
                variant={WhiteModalContentVariant.Red}
                svg={SvgIcon.Error}
                buttons={this.getButtons()}
                description={this.props.description}
            />
        )
    }

    private getButtons = () => [
        <FormButton
            key='1'
            label={translate('manage-user.user-error.ok')}
            onClick={this.props.hide}
        />,
    ]
}

interface UserErrorProps {
    email: string
    description: string
    hide(): void
}
