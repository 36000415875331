import { LOG_OUT, SIGN_IN_SUCCESS, UNAUTHORIZED } from 'aos-services/src/core/auth/actions'
import { setTokenInAuth } from 'aos-services/src/core/auth/state'

import { AuthAction, GET_USER_DETAILS_SUCCESS } from './actions'
import { AuthState, initialAuthState } from './state'

export function authReducer(state = initialAuthState, action: AuthAction): AuthState {
    switch (action.type) {
        case SIGN_IN_SUCCESS:
            return setTokenInAuth(state, action.payload)

        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
            }

        case LOG_OUT:
        case UNAUTHORIZED:
            return setTokenInAuth(state, null)

        default:
            return state
    }
}
