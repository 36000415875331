import { Pageable, pageableForList } from 'aos-helpers/src/helpers/Pageable'
import { ShiftNotesDto } from 'aos-services/src/services/tasks/shiftNotes/types/ShiftNotesListDto'

export interface ShiftNotesStateAware {
    shiftNotes: ShiftNotesState
}

export interface ShiftNotesState {
    isLoading: boolean
    list: Pageable<ShiftNotesDto>
    isModalOpen: boolean
}

export const initialShiftNotesState: ShiftNotesState = {
    list: pageableForList(),
    isLoading: true,
    isModalOpen: false,
}
