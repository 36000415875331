import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { AosCommonEventProps } from 'aos-services/src/services/events/types/AosCommonEventProps'
import { Box } from 'aos-ui/src/components/base/Box'
import { InfoBarContent, InfoBarText } from 'aos-ui/src/components/infobar/InfoBar'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { EventIcon } from './EventIcon'
import { eventItemIconVariant, eventItemTextColor } from './eventItemFormatter'

export interface EventBarContentProps {
    item: AosCommonEventProps
    inTooltip?: boolean
}

export const EventBarContent: FC<EventBarContentProps> = props => {
    const { item, inTooltip = false } = props
    const iconVariant = eventItemIconVariant(inTooltip, item)
    const textColor = eventItemTextColor(inTooltip, item)

    return (
        <InfoBarContent
            inTooltip={inTooltip}
            row
            paddingHorizontal={8}
            paddingVertical={2}
            overflow='hidden'
        >
            {item.category && <EventIcon category={item.category} iconVariant={iconVariant} />}
            <InfoBarText
                size={14}
                color={textColor}
                overflow='hidden'
                lineHeight='large'
                flex={1}
                paddingLeft={8}
                data-timeline-text
            >
                {item.title}
            </InfoBarText>
            {item.hasPrivateChannel && (
                <Box paddingLeft={8} centered>
                    <Icon
                        iconSize={BlockSize.Std}
                        svg={SvgIcon.Private}
                        iconVariant={iconVariant}
                    />
                </Box>
            )}
        </InfoBarContent>
    )
}
