import React, { FC } from 'react'

import { ActivateEventModal } from './ActivateEventModal'
import { CreateEventModal } from './CreateEventModal'
import { EditEventModal } from './EditEventModal'
import { ReportExtendedFeedInModal } from './ReportExtendedFeedInModal'
import { ReportSimpleFeedInModal } from './ReportSimpleFeedInModal'

export const EventModals: FC = () => (
    <>
        <ActivateEventModal />
        <CreateEventModal />
        <ReportExtendedFeedInModal />
        <ReportSimpleFeedInModal />
        <EditEventModal />
    </>
)
