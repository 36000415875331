import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { connect } from 'react-redux'

import { editContactActions, removeUserAction } from '../../../core/groupManager/actions'
import { State } from '../../../core/state'
import {
    AddContactModalsClass,
    ContactModalsDispatchProps,
    ContactModalsProps,
} from './AddContactModalsClass'

export const EditContactModal = connect<ContactModalsProps, ContactModalsDispatchProps, {}>(
    (state: State) => ({
        ...state.groupManager.editContactState,
        groups: state.groupManager.groups,
        headerTitle: translate('manage-user.contact.edit.title'),
    }),
    {
        ...editContactActions,
        removeUserAction,
        saveUserAction: editContactActions.updateContactAction,
    },
)(AddContactModalsClass)
