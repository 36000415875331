import Map from 'ol/Map'
import React from 'react'
import VektorEmbeddedAirportLite from 'vektor-embedded-airport-lite'

export interface BimMapContextProps {
    bim: VektorEmbeddedAirportLite
    map: Map
}

const defaultBimMapContext: BimMapContextProps = {
    bim: null as any,
    map: null as any,
}

export const BimMapContext = React.createContext<BimMapContextProps>(defaultBimMapContext)
export const { Provider } = BimMapContext
