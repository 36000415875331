import { Color } from '../../../styles/Color'
import { GaugeNeedleCircles } from '../types/GaugeNeedleCircles'

export const getNeedleCircles = (base: number): GaugeNeedleCircles[] => [
    {
        radius: base * 1.2,
        fill: Color.White,
    },
    {
        radius: base / 3,
        fill: Color.UiBlack1,
    },
]
