import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum FeedInFilter {
    Current = 'current',
    Dismissed = 'dismissed',
}

import { EnumValues } from 'enum-values'

export const allFeedInFilters = EnumValues.getValues<FeedInFilter>(FeedInFilter)

export const translateFeedInFilter = translateEnum<FeedInFilter>(FeedInFilter, 'feed-in-filter')
