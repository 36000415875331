import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    airportIcon,
    AosAirport,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import {
    AirportFilter,
    FilterOption,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFilters'
import {
    getAirportFilterOptions,
    getAirportName,
} from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { Icon, IconVariant, svgIconForName, SvgIconType } from 'aos-ui/src/components/svg/Icon'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

const allAirports = getAirportFilterOptions().map(i => i as AosAirport)

interface AirportFilterDropdownProps {
    value: AirportFilter
    onChange: (value: AirportFilter) => void
}

export const AirportFilterDropdown: FC<AirportFilterDropdownProps> = ({ value, onChange }) => {
    const currentAirport = useSelector(currentUserSiteLocation)
    const [prev, setPrev] = useState(value)

    const handleFilterChange = useCallback(
        (values: AirportFilter) => {
            let result: AirportFilter = []
            if (values.includes(FilterOption.All) && !prev.includes(FilterOption.All)) {
                result = allAirports
            } else {
                if (!values.includes(FilterOption.All) && prev.includes(FilterOption.All)) {
                    result = [currentAirport]
                } else {
                    result = values.filter(i => i !== FilterOption.All) as AosAirport[]
                }
            }
            setPrev(result)
            onChange(result)
        },
        [prev, currentAirport],
    )

    const renderAirport = useCallback((airport: AosAirport | FilterOption) => {
        const label = getAirportName(airport)
        const icon = airportIcon(airport as AosAirport) as SvgIconType
        if (airport === FilterOption.All) {
            return label
        }

        return (
            <Box row>
                <Icon
                    iconSize={BlockSize.Std}
                    svg={svgIconForName(icon)}
                    iconVariant={IconVariant.BlackGrey}
                />
                <Box paddingLeft={8} as='span'>
                    {label}
                </Box>
            </Box>
        )
    }, [])
    return (
        <CheckboxDropdown
            preventEmpty
            allContent={translate('filter.placeholder.all')}
            partialContent={(i: AosAirport[]) =>
                i.length === 1
                    ? renderAirport(i[0])
                    : translate('filter.placeholder.selected', { count: i.length })
            }
            value={value}
            items={getAirportFilterOptions()}
            valueRenderer={renderAirport}
            onChange={handleFilterChange}
            width={DropdownWidth.Big}
            variant={DropdownVariant.Black}
        />
    )
}
