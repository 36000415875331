import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import { Feed } from '../../../services/newsFeed/types/Feed'
import { FeedChannelsFilter } from '../../../services/newsFeed/types/FeedChannelsFilter'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const NEWS_FEED_SYNC = 'STATUS_DASHBOARD_DATA/NEWS_FEED/NEWS_FEED_SYNC'
export type NewsFeedSyncAction = PayloadAction<
    typeof NEWS_FEED_SYNC,
    [SyncDataKey, FeedChannelsFilter[]]
>
export const newsFeedSyncAction = payloadActionCreator<
    NewsFeedSyncAction,
    [SyncDataKey, FeedChannelsFilter[]]
>(NEWS_FEED_SYNC)

export const NEWS_FEED_SYNC_STOP = 'STATUS_DASHBOARD_DATA/NEWS_FEED/NEWS_FEED_SYNC_STOP'
export type NewsFeedSyncStopAction = PayloadAction<typeof NEWS_FEED_SYNC_STOP, SyncDataKey>
export const newsFeedSyncStopAction = payloadActionCreator<NewsFeedSyncStopAction, SyncDataKey>(
    NEWS_FEED_SYNC_STOP,
)

export const NEWS_FEED_LOAD = 'STATUS_DASHBOARD_DATA/NEWS_FEED_LOAD'
export type NewsFeedLoadAction = PayloadAction<
    typeof NEWS_FEED_LOAD,
    [SyncDataKey, FeedChannelsFilter[]]
>
export const newsFeedLoadAction = payloadActionCreator<
    NewsFeedLoadAction,
    [SyncDataKey, FeedChannelsFilter[]]
>(NEWS_FEED_LOAD)

export const NEWS_FEED_LOAD_SUCCESS = 'STATUS_DASHBOARD_DATA/NEWS_FEED/NEWS_FEED_LOAD_SUCCESS'
export type NewsFeedLoadSuccessAction = PayloadAction<
    typeof NEWS_FEED_LOAD_SUCCESS,
    [SyncDataKey, Feed[]]
>
export const newsFeedLoadSuccessAction = payloadActionCreator<
    NewsFeedLoadSuccessAction,
    [SyncDataKey, Feed[]]
>(NEWS_FEED_LOAD_SUCCESS)

export type NewsFeedAction =
    | NewsFeedSyncAction
    | NewsFeedSyncStopAction
    | NewsFeedLoadAction
    | NewsFeedLoadSuccessAction
