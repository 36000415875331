import { syncValue, syncValueThrottle } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { select, takeEvery } from 'redux-saga/effects'

import { firebaseRangesRepository } from '../../dataaccess/ranges/firebaseRangesRepository'
import { RangesDto } from '../../dataaccess/ranges/types/RangesDto'
import { WidgetHealthsDto } from '../../dataaccess/widgetHealths/types/WidgetHealthsDto'
import { firebaseWidgetHealthsRepository } from '../../dataaccess/widgetHealths/widgetHealthsRepository'
import { WidgetStatesDto } from '../../dataaccess/widgetStates/types/WidgetStatesDto'
import { widgetStatesRepository } from '../../dataaccess/widgetStates/widgetStatesRepository'
import { firebaseService } from '../../services/firebase/firebaseService'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { rangesService } from '../../services/ranges/rangesService'
import { widgetHealthsService } from '../../services/widgetHealths/widgetHealthsService'
import { widgetStatesService } from '../../services/widgetStates/widgetStatesService'
import { PREFERENCES_LOCATION_CHANGED } from '../auth/actions'
import { currentUserSiteLocation } from '../auth/state'
import {
    setDashboardHealthsAction,
    setDashboardHealthsForAirportAction,
    setDashboardRangesAction,
    setDashboardStatusesAction,
    SYNC_STATUS_DASHBOARD,
    SYNC_STATUS_DASHBOARD_STOP,
} from './actions'

const FIREBASE_COMPONENT = 'dashboard'

enum FirebaseListener {
    DashboardStatuses = 'dashboardStatuses',
    DashboardRanges = 'dashboardRanges',
    DashboardHealths = 'dashboardHealths',
    DashboardHealthsForAirport = 'dashboardHealthsForAirport',
}

function* syncStatusDashboardStopSaga() {
    yield takeEvery(SYNC_STATUS_DASHBOARD_STOP, function* () {
        yield firebaseService.offListener(FirebaseListener.DashboardStatuses, FIREBASE_COMPONENT)
        yield firebaseService.offListener(FirebaseListener.DashboardRanges, FIREBASE_COMPONENT)
        yield firebaseService.offListener(FirebaseListener.DashboardHealths, FIREBASE_COMPONENT)
        yield firebaseService.offListener(
            FirebaseListener.DashboardHealthsForAirport,
            FIREBASE_COMPONENT,
        )
    })
}

function* syncStatusesTask() {
    const location: AosAirport = yield select(currentUserSiteLocation)
    yield syncValue(
        widgetStatesRepository.widgetStatesRef(location),
        ([_, dto]: [string, WidgetStatesDto]) =>
            setDashboardStatusesAction(widgetStatesService.itemStatusFromDto(dto)),
        false,
    )
}

function* syncRangesTask() {
    yield syncValue(
        firebaseRangesRepository.rangesRef(),
        ([_, dto]: [string, RangesDto]) =>
            setDashboardRangesAction(rangesService.rangesFromDto(dto)),
        false,
    )
}

function* syncHealthsTask() {
    yield syncValueThrottle(
        firebaseWidgetHealthsRepository.widgetHealthsRef(),
        ([_, dto]: [string, WidgetHealthsDto]) =>
            setDashboardHealthsAction(widgetHealthsService.widgetHealthsForDto(dto)),
        5000,
    )
}

function* syncHealthsForAirportTask() {
    const location: AosAirport = yield select(currentUserSiteLocation)

    yield syncValueThrottle(
        firebaseWidgetHealthsRepository.widgetHealthsForAirportRef(location),
        ([_, dto]: [string, WidgetHealthsDto]) =>
            setDashboardHealthsForAirportAction(
                widgetHealthsService.widgetHealthsForAirportForDto(dto),
            ),
        5000,
    )
}

function* locationChangeSaga() {
    yield takeEvery(PREFERENCES_LOCATION_CHANGED, function* () {
        yield firebaseService.restartListener(FirebaseListener.DashboardStatuses, syncStatusesTask)
        yield firebaseService.restartListener(
            FirebaseListener.DashboardHealthsForAirport,
            syncHealthsForAirportTask,
        )
    })
}

function* syncStatusDashboardSaga() {
    yield takeEvery(SYNC_STATUS_DASHBOARD, function* () {
        yield* firebaseService.onListeners(
            {
                dashboardRanges: syncRangesTask,
                dashboardStatuses: syncStatusesTask,
                dashboardHealths: syncHealthsTask,
                dashboardHealthsForAirport: syncHealthsForAirportTask,
            },
            FIREBASE_COMPONENT,
        )
    })
}

export const baseStatusDashboardSagas = [
    syncStatusDashboardSaga,
    syncStatusDashboardStopSaga,
    locationChangeSaga,
]
