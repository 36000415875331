import { cx } from 'aos-components/src/components/base/ClassNames'
import { formatOptionalTime } from 'aos-helpers/src/helpers/TimeFormat'
import {
    RingRailStation,
    RingRailTimeTableRow,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'

import { DelayInMinutesCell } from './cells/DelayInMinutesCell'
import { TrainTimeTableRow } from './TrainTimeTableRow'

/* eslint-disable react/jsx-no-literals */
export class TrainTimeTableItem extends PureComponent<TrainTimeTableItemProps> {
    public render() {
        const { item, station, highlightItem } = this.props

        return (
            <Box paddingVertical={4}>
                <TrainTimeTableRow>
                    <Box row>
                        <Box marginRight={8}>
                            <DelayInMinutesCell row={item} />
                        </Box>
                        <Box className={cx({ 'text__s-white': highlightItem }, 'flex--1')}>
                            {station.stationName}
                        </Box>
                    </Box>

                    <Box className={cx({ 'text__s-white': highlightItem })}>
                        {formatOptionalTime(item.arrivalScheduledTime) || '-'}-
                        {formatOptionalTime(item.departureScheduledTime) || '-'}
                    </Box>

                    <Box className={cx({ 'text__s-white': highlightItem })}>
                        {item.commercialTrack}
                    </Box>
                </TrainTimeTableRow>
            </Box>
        )
    }
}

interface TrainTimeTableItemProps {
    item: RingRailTimeTableRow
    station: RingRailStation
    highlightItem: boolean
}
