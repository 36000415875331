import { BaseRestService } from '../base/BaseRestService'
import { TowingDto, TowingsResponseDto } from './types/TowingDto'

class TowingsRestService extends BaseRestService {
    public async getAllTowings(): Promise<TowingDto[]> {
        const { towings } = await this.getAndTransform<TowingsResponseDto>(
            '/airport-status/towings',
            BaseRestService.jsonTransformer,
        )
        return towings
    }
}

export const towingsRestService = new TowingsRestService()
