import {
    REMOVE_ACCOUNT,
    REMOVE_ACCOUNT_ERROR,
    REMOVE_ACCOUNT_SUCCESS,
    RemoveAccountActions,
    RESET_REMOVE_ACCOUNT,
} from './actions'
import { initialRemoveAccountState, RemoveAccountActionTypes } from './state'

export function removeAccountReducer(
    state = initialRemoveAccountState,
    action: RemoveAccountActions,
) {
    switch (action.type) {
        case REMOVE_ACCOUNT:
            return {
                ...state,
                loading: true,
                result: null,
            }
        case REMOVE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                result: RemoveAccountActionTypes.SUCCESS,
            }
        case REMOVE_ACCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                result: RemoveAccountActionTypes.ERROR,
            }
        case RESET_REMOVE_ACCOUNT:
            return initialRemoveAccountState
        default:
            return state
    }
}
