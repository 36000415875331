import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import { AlertsStateDto } from '../../dataaccess/alerts/types/AlertsStateDto'

export const SYNC_ALERTS = 'ALERTS/SYNC'
export type SyncAlertsAction = PayloadAction<typeof SYNC_ALERTS, string>
export const syncAlertsAction = payloadActionCreator<SyncAlertsAction, string>(SYNC_ALERTS)

export const SYNC_ALERTS_STOP = 'ALERTS/SYNC_STOP'
export type SyncAlertsStopAction = PayloadAction<typeof SYNC_ALERTS_STOP, string>
export const syncAlertsStopAction = payloadActionCreator<SyncAlertsStopAction, string>(
    SYNC_ALERTS_STOP,
)

export const SET_ALERTS_STATE = 'ALERTS/SET_STATE'
export type SetAlertsStateAction = PayloadAction<typeof SET_ALERTS_STATE, AlertsStateDto>
export const setAlertsStateAction = payloadActionCreator<SetAlertsStateAction, AlertsStateDto>(
    SET_ALERTS_STATE,
)

export const SEND_ALERTS_STATE = 'ALERTS/SEND_STATE'
export type SendAlertsStateAction = PayloadAction<typeof SEND_ALERTS_STATE, Partial<AlertsStateDto>>
export const sendAlertsStateAction = payloadActionCreator<
    SendAlertsStateAction,
    Partial<AlertsStateDto>
>(SEND_ALERTS_STATE)

export type AlertsAction =
    | SyncAlertsAction
    | SyncAlertsStopAction
    | SendAlertsStateAction
    | SetAlertsStateAction
