import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { loadFod } from 'aos-services/src/core/fod/actions'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import { Box } from 'aos-ui/src/components/base/Box'
import { PaginationList } from 'aos-ui/src/components/list/PaginationList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    fodMapActions,
    previewFodLocationAction,
    setPreviewFodLocationModalOpenAction,
} from '../../core/fod/actions'
import { fodReloadAction } from '../../core/fod/actions'
import { fodListSelector } from '../../core/fod/selectors'
import { FodStateAware } from '../../core/fod/state'
import { FodNotesTile } from './list/FodNotesTile'
import { FodSyncWrapper } from './sync/FodSyncWrapper'

interface FodNotesListProps extends FodNotesListStateProps, FodNotesDispatchProps {}

const FodNotesListComponent: FC<FodNotesListProps> = ({
    fodNotesReload,
    fodLoad,
    list,
    fodToggleShowOnMap,
    isOpenShowOnMap,
    fodSelectMapLocation,
    fodSwitchVariant,
    fodPreviewLocation,
}) => {
    useEffect(() => {
        fodLoad({ page: 0, size: 10 })
    }, [])

    return (
        <FodSyncWrapper id='details'>
            <Box bg={Color.UiBlack1} padding={30}>
                {list && (
                    <PaginationList page={list} onChange={fodNotesReload} paddingBottom={20}>
                        {list.content?.map((item, index) => (
                            <Box key={index} paddingBottom={16}>
                                <FodNotesTile
                                    fodSelectMapLocation={fodSelectMapLocation}
                                    fodSwitchVariant={fodSwitchVariant}
                                    fodToggleShowOnMap={fodToggleShowOnMap}
                                    fodPreviewLocation={fodPreviewLocation}
                                    isShowOnMapOpen={isOpenShowOnMap}
                                    item={item}
                                />
                            </Box>
                        ))}
                    </PaginationList>
                )}
            </Box>
        </FodSyncWrapper>
    )
}

interface FodNotesListStateProps {
    list: Pageable<FodDto> | undefined
    isOpenShowOnMap: boolean
}

interface FodNotesDispatchProps {
    fodLoad: typeof loadFod
    fodNotesReload: typeof fodReloadAction
    fodToggleShowOnMap: typeof setPreviewFodLocationModalOpenAction
    fodSelectMapLocation: typeof fodMapActions.mapPositionChangedAction
    fodSwitchVariant: typeof fodMapActions.switchMapAction
    fodPreviewLocation: typeof previewFodLocationAction
}

export const FodNotesList = connect<FodNotesListStateProps, FodNotesDispatchProps>(
    createStructuredSelector({
        list: fodListSelector,
        isOpenShowOnMap: (state: FodStateAware) => state.fod.isPreviewModalOpen,
    }),
    {
        fodLoad: loadFod,
        fodToggleShowOnMap: setPreviewFodLocationModalOpenAction,
        fodNotesReload: fodReloadAction,
        fodSelectMapLocation: fodMapActions.mapPositionChangedAction,
        fodSwitchVariant: fodMapActions.switchMapAction,
        fodPreviewLocation: previewFodLocationAction,
    },
)(FodNotesListComponent)
