import { cx } from 'aos-components/src/components/base/ClassNames'
import { enumToName } from 'aos-helpers/src/helpers/Enum'
import { DashboardItemMode } from 'aos-services/src/services/statusDashboard/types/DashboardItemMode'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { useInterval } from 'aos-ui-common/src/components/hooks/useInterval'
import React, { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react'

interface AlertOverlayProps {
    title: ReactNode
    reasons: string[]
    status: DashboardItemMode
    onEnd(): void
}

const TIMEOUT = 4000
const HIDE_ANIMATION = 1000

const statusName = enumToName(DashboardItemMode)

export const StatusDashboardAlertOverlay: FC<PropsWithChildren<AlertOverlayProps>> = props => {
    const { title, onEnd, reasons, status } = props

    const [hidden, setHidden] = useState(false)
    const [ended, setEnded] = useState(false)
    const handleBodyClick = useCallback(() => setHidden(true), [])

    useInterval(
        () => {
            setHidden(true)
        },
        hidden ? null : TIMEOUT,
    )

    useInterval(
        () => {
            setEnded(true)
            onEnd()
        },
        hidden && !ended ? HIDE_ANIMATION : null,
    )

    const reasonsElements = reasons.map((reason, index) => (
        <p className='text__m-current padding-bottom--small' key={index}>
            {reason}
        </p>
    ))

    return (
        <Box
            className={cx('alert-overlay', `alert-overlay--${statusName(status)}`, {
                'alert-overlay--hide': hidden,
            })}
            coverAll
        >
            <Box className='alert-overlay__ripple' />
            <Text
                className='alert-overlay__body'
                textAlign='center'
                onClick={handleBodyClick}
                centered
                coverAll
            >
                <Box>
                    <h4 className='text__xxl-current padding-bottom--std'>{title}</h4>
                    {reasonsElements}
                </Box>
            </Text>
        </Box>
    )
}
