import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import { OneTimeKey } from './state'

export const ADD_ALREADY_SHOWN = 'ONE_TIME/ADD_ALREADY_SHOWN'
export type AddAlreadyShownAction = PayloadAction<typeof ADD_ALREADY_SHOWN, OneTimeKey>
export const addAlreadyShownAction = payloadActionCreator<AddAlreadyShownAction, OneTimeKey>(
    ADD_ALREADY_SHOWN,
)

export const REMOVE_ALREADY_SHOWN = 'ONE_TIME/REMOVE_ALREADY_SHOWN'
export type RemoveAlreadyShownAction = PayloadAction<typeof REMOVE_ALREADY_SHOWN, OneTimeKey>
export const removeAlreadyShownAction = payloadActionCreator<RemoveAlreadyShownAction, OneTimeKey>(
    REMOVE_ALREADY_SHOWN,
)

export type OneTimeAction = AddAlreadyShownAction | RemoveAlreadyShownAction
