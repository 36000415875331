import { isResponseError, ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'
import { PredicateMatcher } from 'aos-helpers/src/helpers/PredicateMatcher'

import { AosUserServerErrorDto } from '../../dataaccess/users/types/AosUserServerErrorDto'
import { AosAdUser } from './types/AosUser'
import {
    AOS_USER_EXISTS,
    AOS_USER_EXISTS_NOT_FOUND_IN_AD,
    AosUserAddStatus,
    CONTACT_EXISTS,
    USER_FOUND_IN_AD,
    USER_NOT_FOUND_IN_AD,
    USER_OR_GROUP_NOT_FOUND,
    USER_REMOVED,
} from './types/AosUserFindStatus'

export type UserResponseStatus = [number | undefined, AosUserAddStatus] | null

export function errorToUserResponseStatus(error: ResponseError): UserResponseStatus {
    if (isResponseError(error)) {
        const errorContent = error.errorPayload as AosUserServerErrorDto
        const userId = errorContent.details ? errorContent.details.userId : undefined

        const matcher = PredicateMatcher.of<AosUserServerErrorDto, UserResponseStatus>()
            .caseWhen(
                e => e.httpStatus === 404 && (!e.reason || e.reason === 'USER_DOES_NOT_EXIST'),
                [userId, USER_OR_GROUP_NOT_FOUND],
            )
            .caseWhen(e => e.httpStatus === 404 && e.reason === 'USER_NOT_FOUND_IN_LDAP', [
                userId,
                USER_NOT_FOUND_IN_AD,
            ])
            .caseWhen(e => e.httpStatus === 409 && e.reason === 'USER_FOUND_IN_LDAP', [
                userId,
                USER_FOUND_IN_AD,
            ])
            .caseWhen(e => e.httpStatus === 409 && e.reason === 'CONTACT_EXISTS', [
                userId,
                CONTACT_EXISTS,
            ])
            .caseWhen(e => e.httpStatus === 409 && e.reason === 'USER_EXISTS', [
                userId,
                AOS_USER_EXISTS,
            ])
            .caseWhen(e => e.httpStatus === 409 && e.reason === 'USER_IS_BLOCKED', [
                userId,
                USER_REMOVED,
            ])
            .caseWhen(e => e.httpStatus === 410 && e.reason === 'USER_ABSENT_FROM_LDAP', [
                userId,
                AOS_USER_EXISTS_NOT_FOUND_IN_AD,
            ])
            .else(null)

        return matcher.match(errorContent)
    } else {
        return null
    }
}

export const userResponseToUserResponseStatus = (user: AosAdUser): UserResponseStatus => {
    if (user.id) {
        return [user.id, AOS_USER_EXISTS]
    }
    return null
}
