import { isDefined } from 'aos-helpers/src/helpers/Function'
import { ChartContextProps } from 'aos-ui-common/src/components/chart/LegacyChartContext'
import { AxisDomain } from 'd3-axis'
import { identity } from 'lodash'
import React from 'react'

import { cx, cxp } from '../../base/ClassNames'
import { BaseChartComponent } from '../base/BaseChartComponent'
import { AxisProps } from './AxisProps'

interface DefaultTickConfig<T extends AxisDomain> {
    defaultPadding: number
    defaultTickSize: number
    defaultTickFormat?: f.Func1<T, string>
}

export abstract class AxisBase<T extends AxisDomain> extends BaseChartComponent<
    AxisProps<T> & ChartContextProps
> {
    protected abstract get elementTranslate(): string
    protected gElementClass: string = ''

    protected abstract renderAxisLine: () => JSX.Element | null
    protected abstract renderTick: (tick: T, index: number) => JSX.Element

    public render() {
        const { scale, axisConfig } = this.props
        const ticks = axisConfig.tickValues || (scale.ticks ? scale.ticks() : scale.domain())
        const showAxisLine = isDefined(axisConfig.showAxisLine) ? axisConfig.showAxisLine : true

        return (
            <g
                className={cxp(this.props, this.gElementClass, 'axis')}
                transform={this.elementTranslate || 'translate(0 0)'}
            >
                {showAxisLine && this.renderAxisLine()}
                {ticks.map(this.renderTick)}
                {/*{axisConfig.unit && this.renderUnit(axisConfig.unit)}*/}
            </g>
        )
    }
    protected getTickConfig = (
        tick: T,
        { defaultPadding, defaultTickSize, defaultTickFormat = identity }: DefaultTickConfig<T>,
    ) => {
        const { scale, axisConfig } = this.props

        const baseValues = this.props.baseValues || []
        const isBaseValue = baseValues.indexOf(tick) !== -1
        const isTickMarked = (axisConfig.tickMarked && axisConfig.tickMarked(tick)) || false

        const tickClass = cx(
            'tick',
            isBaseValue ? 'tick--base' : '',
            isTickMarked ? 'tick--marked' : '',
        )
        const tickLabelStyle = axisConfig.tickLabelStyle && axisConfig.tickLabelStyle(tick)

        const tickDimensions = this.getTickDimensions(defaultTickSize, defaultPadding)
        const tickFormat: (domainValue: T | number) => string =
            axisConfig.tickFormat || (scale.tickFormat ? scale.tickFormat() : defaultTickFormat)

        return {
            tickClass,
            ...tickDimensions,
            tickFormat,
            tickLabelStyle,
        }
    }

    protected getTickDimensions(defaultTickSize: number, defaultPadding: number) {
        const { axisConfig } = this.props
        const tickSize = isDefined(axisConfig.tickSize) ? axisConfig.tickSize : defaultTickSize
        const tickPadding = isDefined(axisConfig.tickPadding)
            ? axisConfig.tickPadding
            : defaultPadding
        const tickOffset = Math.max(tickSize, 0) + tickPadding
        const tickDimensions = {
            tickSize,
            tickPadding,
            tickOffset,
        }
        return tickDimensions
    }
}
