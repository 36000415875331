import {
    translateEnum,
    translateFirstEnum,
    translateParts,
} from 'aos-helpers/src/helpers/translations/Translations'
import { concat, partition, sortBy } from 'lodash'

import { AosBaseUserGroup } from './types/AosUserGroup'
import { AosUserGroupType, mapAosUserGroupToOrder } from './types/AosUserGroupType'

export const aosUserGroupNameByType = (type: AosUserGroupType, labelSuffix?: string) => {
    const labelPrefix = 'user-group'
    if (labelSuffix) {
        return translateFirstEnum<AosUserGroupType>(AosUserGroupType, [
            `${labelPrefix}.${labelSuffix}`,
            labelPrefix,
        ])(type)
    }
    return translateEnum<AosUserGroupType>(AosUserGroupType, labelPrefix)(type)
}

export const aosUserGroupName = (group: AosBaseUserGroup, labelSuffix?: string) => {
    if (group.type === AosUserGroupType.CUSTOM || group.type === undefined) {
        return group.name
    }
    return aosUserGroupNameByType(group.type, labelSuffix)
}

export function partitionGroupList<T extends AosBaseUserGroup>(groupList: T[]): [T[], T[]] {
    return partition(groupList, group => group.type !== AosUserGroupType.CUSTOM)
}

export function sortGroupListByName<T extends AosBaseUserGroup>(groupList: T[]): T[] {
    const partitionedGroups = partitionGroupList(groupList)
    return concat(
        sortBy(partitionedGroups[0], group => mapAosUserGroupToOrder[group.type]),
        sortBy(partitionedGroups[1], group => group.name.toLowerCase()),
    )
}

export function getCustomGroupList<T extends AosBaseUserGroup>(groupList: T[]): T[] {
    return groupList.filter(g => g.type === AosUserGroupType.CUSTOM)
}

export function getGroupMembersCountTranslation(count: number): string {
    let translation: string = ''
    if (count === 0) {
        translation = 'none'
    } else if (count === 1) {
        translation = 'single'
    } else if (count > 1) {
        translation = 'many'
    }
    return translateParts('people', translation)
}
