import { useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'

const DEFAULT_DEBOUNCE = 300

export const useDebouncedValueUpdate = <T>(
    value: T,
    onChange: f.Action1<T>,
    time = DEFAULT_DEBOUNCE,
) => {
    const [currentValue, updateValue] = useState(value)
    const [prevValue, setPrevValue] = useState<T>()

    useDebounce(
        () => {
            if (prevValue !== currentValue) {
                onChange(currentValue)
                setPrevValue(currentValue)
            }
        },
        time,
        [currentValue, prevValue],
    )

    const instantUpdate = (val: T) => {
        onChange(val)
        setPrevValue(val)
    }

    return [currentValue, updateValue, instantUpdate] as [T, f.Action1<T>, f.Action1<T>]
}
