import { transformExtent } from 'ol/proj'

import { AosAirport } from '../flightInformation/types/AosAirport'
import { MapPosition } from './types/MapPosition'

export const selectedLocationZoom = 14

export const halliAirportPosition = {
    lon: 24.7866,
    lat: 61.85605,
    zoom: 14,
}

export const helsinkiAirportPosition = {
    lon: 24.967433400000004,
    lat: 60.31615160624082,
    zoom: 16.3,
}

export const helsinkiAtcCenterPosition = {
    lon: 24.948,
    lat: 60.32,
    zoom: 13.5,
}

export const atcGridPositionExtent = transformExtent(
    [24.888485632497463, 60.27001465422546, 24.994462951095556, 60.34508232038925],
    'EPSG:4326',
    'EPSG:3857',
)

export const atcMapFitConfig = {
    duration: 3000,
}

export const ivalonAirportPosition = {
    lon: 27.40533,
    lat: 68.60727,
    zoom: 14,
}

export const joensuunAirportPosition = {
    lon: 29.60755,
    lat: 62.66291,
    zoom: 14,
}

export const jyvaskylanAirportPosition = {
    lon: 25.67825,
    lat: 62.39945,
    zoom: 14,
}

export const kajaaniAirportPosition = {
    lon: 27.69241,
    lat: 64.28547,
    zoom: 14,
}

export const kemiAirportPosition = {
    lon: 24.5991,
    lat: 65.78189,
    zoom: 14,
}

export const kittilanAirportPosition = {
    lon: 24.84685,
    lat: 67.70102,
    zoom: 14,
}

export const kokkolaAirportPosition = {
    lon: 23.1431,
    lat: 63.7212,
    zoom: 14,
}

export const kuopioAirportPosition = {
    lon: 27.79776,
    lat: 63.00715,
    zoom: 14,
}

export const kuusamonAirportPosition = {
    lon: 29.23938,
    lat: 65.98758,
    zoom: 14,
}

export const maarianhaminanAirportPosition = {
    lon: 19.8982,
    lat: 60.1222,
    zoom: 14,
}

export const ouluAirportPosition = {
    lon: 25.35456,
    lat: 64.93006,
    zoom: 14,
}

export const poriAirportPosition = {
    lon: 21.79998,
    lat: 61.46169,
    zoom: 14,
}

export const rovaniemiAirportPosition = {
    lon: 25.83041,
    lat: 66.56482,
    zoom: 14,
}

export const savonlinnaAirportPosition = {
    lon: 28.94514,
    lat: 61.94306,
    zoom: 14,
}

export const tampereAirportPosition = {
    lon: 23.60439,
    lat: 61.41415,
    zoom: 14,
}

export const turunAirportPosition = {
    lon: 22.26281,
    lat: 60.51414,
    zoom: 14,
}

export const uttiAirportPosition = {
    lon: 26.93835,
    lat: 60.89639,
    zoom: 14,
}

export const vaasanAirportPosition = {
    lon: 21.76218,
    lat: 63.05065,
    zoom: 14,
}

// position customized only for Helsinki
export const europeCentralPosition = {
    lon: 19.893307580295385,
    lat: 57.53543713442852,
    zoom: 4.3,
}

export const helsinkiApronPosition = {
    lon: 24.949386500245,
    lat: 60.31675622193521,
    zoom: 14.2,
}

export const helsinkiAirportAreaPosition = {
    lon: 24.95082817332487,
    lat: 60.264611683431355,
    zoom: 12.2,
}

const mappingAosAirportToAirportPosition: Record<AosAirport, MapPosition> = {
    [AosAirport.HEL]: europeCentralPosition,
    [AosAirport.IVL]: ivalonAirportPosition,
    [AosAirport.JOE]: joensuunAirportPosition,
    [AosAirport.JYV]: jyvaskylanAirportPosition,
    [AosAirport.KAJ]: kajaaniAirportPosition,
    [AosAirport.KEM]: kemiAirportPosition,
    [AosAirport.KEV]: halliAirportPosition,
    [AosAirport.KTT]: kittilanAirportPosition,
    [AosAirport.KOK]: kokkolaAirportPosition,
    [AosAirport.KUO]: kuopioAirportPosition,
    [AosAirport.KAO]: kuusamonAirportPosition,
    [AosAirport.MHQ]: maarianhaminanAirportPosition,
    [AosAirport.OUL]: ouluAirportPosition,
    [AosAirport.POR]: poriAirportPosition,
    [AosAirport.RVN]: rovaniemiAirportPosition,
    [AosAirport.SVL]: savonlinnaAirportPosition,
    [AosAirport.TMP]: tampereAirportPosition,
    [AosAirport.TKU]: turunAirportPosition,
    [AosAirport.UTI]: uttiAirportPosition,
    [AosAirport.VAA]: vaasanAirportPosition,
}

export const getAirportPosition = (aosAirport: AosAirport): MapPosition =>
    mappingAosAirportToAirportPosition[aosAirport]
