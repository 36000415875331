import { FlightFilterConfigOption } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'

const timeRange = FlightFilterConfigOption.HistoryTimeRange

export const delaysFilterConfigs = {
    small: [timeRange],
    history: [timeRange],
    flights: [
        timeRange,
        FlightFilterConfigOption.FlightType,
        FlightFilterConfigOption.Airline,
        FlightFilterConfigOption.HandlingAgent,
    ],
}
