import { mapPageableContent, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { PageRequest } from 'aos-helpers/src/helpers/PageRequest'
import { DateTime, dateTime, dateTimeToISOString } from 'aos-helpers/src/helpers/Time'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { AosMessageChannel } from 'aos-types/src/types/AosMessageChannel'
import { sortBy } from 'lodash'

import { eventsRestService } from '../../dataaccess/events/eventsRestService'
import { AosEventDto } from '../../dataaccess/events/types/AosEventDto'
import { feedsRestService } from '../../dataaccess/feeds/feedsRestService'
import { AosCommonEventDto } from '../../dataaccess/feeds/types/AosCommonEventDto'
import { AosFeedInDto } from '../../dataaccess/feeds/types/AosFeedInDto'
import { severitiesOrderGetter } from '../common/types/AosSeverity'
import { aosEventToAosBaseEventDto } from './aosEventConverter'
import { EventPayload } from './input/EventPayload'
import { NewFeedOut } from './input/NewFeedOut'
import { EventsFilter } from './transport/EventsFilter'
import { EventsSorting, eventsSorting } from './transport/EventsSorting'
import { AosDashboardEvent } from './types/AosDashboardEvent'
import { AosDashboardEventImpl } from './types/AosDashboardEventImpl'
import { AosEvent } from './types/AosEvent'
import { TaskChangeObject } from './types/AosEventChecklist'
import { AosEventGroupImpl } from './types/AosEventGroupImpl'
import { AosTimelineEvent } from './types/AosTimelineEvent'
import { AosTimelineEventImpl } from './types/AosTimelineEventImpl'

export class EventsService {
    public loadEvents(
        pageRequest: PageRequest,
        filter: EventsFilter,
        sort: EventsSorting,
    ): Promise<Pageable<AosEvent>> {
        const sortEntry = eventsSorting[filter][sort]
        return eventsRestService
            .loadEvents(filter, {
                ...pageRequest,
                sortBy: sortEntry.map(t => t[0]),
                sortOrder: sortEntry.map(t => t[1]),
            })
            .then(mapPageableContent(AosEventGroupImpl.eventOrEventGroupFromDto))
    }

    public async loadOverdueEventsCount(): Promise<number> {
        const r = await eventsRestService.loadEvents(EventsFilter.Overdue, {})
        return r.numberOfElements
    }

    public async loadDashboardEvents(): Promise<AosDashboardEvent[]> {
        const items = await eventsRestService.loadDashboardEvents()
        const events = items.map(t => new AosDashboardEventImpl(t))
        return sortBy(
            events,
            severitiesOrderGetter(e => e.severity),
            e_1 => -e_1.startTime.valueOf(),
        )
    }

    public async loadTimelineEvents(range: TimeRange): Promise<AosTimelineEvent[]> {
        const items = await eventsRestService.loadTimelineEvents({
            startTime: dateTimeToISOString(dateTime(range.timeStart)),
            endTime: dateTimeToISOString(dateTime(range.timeEnd)),
        })
        return items.map(t_1 => new AosTimelineEventImpl(t_1))
    }

    public async activateEvent(
        event: EventPayload,
        id: number | undefined,
    ): Promise<AosFeedInDto | AosEventDto | void> {
        if (id) {
            return await feedsRestService.activateEvent(id, await aosEventToAosBaseEventDto(event))
        } else {
            return await eventsRestService.activateEvent(await aosEventToAosBaseEventDto(event))
        }
    }

    public async activateAtcEvent(event: EventPayload): Promise<AosCommonEventDto> {
        return await eventsRestService.activateAtcEvent(await aosEventToAosBaseEventDto(event))
    }

    public async editEvent(event: EventPayload, id: number): Promise<void> {
        await eventsRestService.editEvent(id, await aosEventToAosBaseEventDto(event))
    }

    public async loadEvent(id: number): Promise<AosEvent> {
        let value = await eventsRestService.loadEvent(id)
        return AosEventGroupImpl.eventOrEventGroupFromDto(value)
    }

    public closeEvent(id: number, endTime: DateTime): Promise<void> {
        return eventsRestService.closeEvent(id, dateTimeToISOString(endTime))
    }

    public reopenEvent(id: number): Promise<void> {
        return eventsRestService.reopenEvent(id)
    }

    public deleteEvent(id: number): Promise<void> {
        return eventsRestService.deleteEvent(id)
    }

    public undeleteEvent(id: number): Promise<void> {
        return eventsRestService.undeleteEvent(id)
    }

    public addFeedOut(eventId: number, newFeedOut: NewFeedOut): Promise<void> {
        const { channels, ...feedOut } = newFeedOut
        return eventsRestService.createFeedOut(eventId, {
            ...feedOut,
            sms: channels.includes(AosMessageChannel.Sms),
            email: channels.includes(AosMessageChannel.Email),
            dashboard: channels.includes(AosMessageChannel.Notification),
            push: channels.includes(AosMessageChannel.Push),
            groupId: newFeedOut.groupId!,
            automated: newFeedOut.automated,
        })
    }

    public updateEventChecklistTask(changeObject: TaskChangeObject): Promise<void> {
        return eventsRestService.updateEventchecklistTask(changeObject)
    }

    public updateChecklists(checklistIds: number[], eventId: number): Promise<void> {
        return eventsRestService.updateChecklists(checklistIds, eventId)
    }

    public createPrivateChannel(eventId: number, groupId: number, hardAlert: boolean = false) {
        return eventsRestService.createPrivateChannel(eventId, groupId, hardAlert)
    }
}

export const eventsService = new EventsService()
