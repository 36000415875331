export class Optional<T> {
    public static NONE = new Optional<any>(null)

    public static empty<P>(): Optional<P> {
        return Optional.NONE
    }

    public static ofNullable<P>(val: P | null | undefined): Optional<P> {
        return new Optional(val)
    }

    public static of<P>(val: P): Optional<P> {
        return new Optional(val)
    }

    public static flatten<P>(optionals: Optional<P>[]): P[] {
        return optionals.reduce((acc: P[], elem: Optional<P>) => {
            elem.forEach((value: P) => {
                acc.push(value)
            })
            return acc
        }, [])
    }

    private static _isEmpty(value: any): boolean {
        return typeof value === 'undefined' || value === null
    }

    private value: T | null | undefined

    private constructor(value: T | null | undefined) {
        if (!Optional._isEmpty(Optional.NONE) && Optional._isEmpty(value)) {
            return Optional.NONE
        }
        this.value = value as T
        return this
    }

    public isEmpty(): boolean {
        return Optional._isEmpty(this.value)
    }

    public isPresent(): boolean {
        return !this.isEmpty()
    }

    public filter(filterer: (value: T) => boolean): Optional<T> {
        if (this.isPresent() && filterer(this.value!)) {
            return this
        }
        return Optional.NONE
    }

    public forEach(callback: (value: T) => any): void {
        if (this.isPresent()) {
            callback(this.value!)
        }
    }

    public map<U>(mapper: (value: T) => U): Optional<U> {
        if (this.isPresent()) {
            return new Optional<U>(mapper(this.value!))
        }
        return Optional.NONE
    }

    public flatMap<U>(mapper: (value: T) => Optional<U>): Optional<U> {
        if (this.isPresent()) {
            return mapper(this.value!)
        }
        return Optional.NONE
    }

    public orElseNull(): T | null {
        return this.value || null
    }

    public orElseUndefined(): T | undefined {
        return this.value || undefined
    }

    public orElseThrow(): T {
        if (this.isEmpty()) {
            throw new Error('Called getOrThrow on an empty Optional')
        }
        return this.value!
    }

    public orElseGet<U extends T>(other: () => U): T {
        if (this.isPresent()) {
            return this.value!
        }
        return other()
    }

    public orElse<U extends T>(other: () => Optional<U>): Optional<T> {
        if (this.isPresent()) {
            return this
        }
        return other()
    }

    public toArray(): T[] {
        if (this.isPresent()) {
            return [this.value!]
        } else {
            return []
        }
    }
}
