import { formatDateTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SplittedParticipants } from 'aos-services/src/services/firebaseEvents/types/PrivateChannelParticipant'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton } from 'aos-ui/src/components/buttons/FormButton'
import { MainPanelWithHeader } from 'aos-ui/src/components/container/MainPanelWithHeader'
import { PanelWithHeaderVariant } from 'aos-ui/src/components/container/PanelWithHeader'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { InfoBanner } from 'aos-ui/src/components/ui/InfoBanner'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { BackButton } from '../../../components/buttons/BackButton'
import { AtcEventInfoContent, DashboardOption } from './AtcEventInfoContent'
import { EventInfoContentProps } from './EventInfoContent'

export const AtcEventInfo: FC<EventInfoProps> = ({
    addPrivateChannel,
    closeEvent,
    editEvent,
    reopenEvent,
    deleteEvent,
    undeleteEvent,
    event,
    dashboardState,
    onChangeDashboard,
    participants,
}) => {
    const renderHeader = () => (
        <HeaderContainer
            leftButton={<BackButton />}
            title={translate('event-info.title')}
            rightButton={renderMore()}
        />
    )

    const renderMore = () => (
        <More>
            <MoreItem onClick={addPrivateChannel}>
                {translate('event-info.add-private-channel')}
            </MoreItem>
            <MoreItem onClick={closeEvent}>{translate('event-info.close')}</MoreItem>
            <MoreItem onClick={editEvent}>{translate('event-info.edit')}</MoreItem>
            <MoreItem onClick={reopenEvent}>{translate('event-info.reopen')}</MoreItem>
            <MoreItem onClick={deleteEvent}>{translate('event-info.delete')}</MoreItem>
            <MoreItem onClick={undeleteEvent}>{translate('event-info.undelete')}</MoreItem>
        </More>
    )

    const getCloseMessage = () => {
        if (!event.modifiedBy || !event.modifiedAt) {
            return
        }

        return translate('event-details.closed.message', {
            email: event.modifiedBy,
            dateTime: event.modifiedAt && formatDateTime(event.modifiedAt),
        })
    }

    const renderReopenBanner = () => {
        if (!reopenEvent || event.deleted) {
            return null
        }

        return (
            <InfoBanner title={translate('event-details.closed.title')} message={getCloseMessage()}>
                <FormButton
                    fullWidth
                    label={translate('event-details.closed.reopen')}
                    onClick={reopenEvent}
                />
            </InfoBanner>
        )
    }
    const renderContent = () => {
        // Force scrollbar re-render when status or deleted changes
        const scrollbarKey = event.status + event.deleted

        return (
            <Box column fullHeight bg={Color.UiBlack1}>
                <Box flex={1}>
                    <DarkScrollbar key={scrollbarKey}>
                        <Box padding={20}>
                            <AtcEventInfoContent
                                participants={participants}
                                dashboardState={dashboardState}
                                onChangeDashboard={onChangeDashboard}
                                event={event}
                                editEvent={editEvent}
                            />
                        </Box>
                    </DarkScrollbar>
                </Box>
                {renderReopenBanner()}
            </Box>
        )
    }

    return (
        <MainPanelWithHeader variant={PanelWithHeaderVariant.Medium} header={renderHeader()}>
            {renderContent()}
        </MainPanelWithHeader>
    )
}

interface EventInfoProps extends EventInfoContentProps {
    editEvent?(): void
    closeEvent?(): void
    reopenEvent?(): void
    deleteEvent?(): void
    addPrivateChannel?(): void
    undeleteEvent?(): void
    dashboardState: DashboardOption
    onChangeDashboard: (value: DashboardOption) => void
    participants: SplittedParticipants
}
