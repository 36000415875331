import { PrivateChannelInviteNotificationDto } from '../../../dataaccess/notifications/types/PrivateChannelInviteNotificationDto'
import { EventRelatedNotificationImpl } from './EventRelatedNotificationImpl'
import {
    PRIVATE_CHANNEL_INVITE,
    PrivateChannelInviteNotification,
} from './PrivateChannelInviteNotification'

export class PrivateChannelInviteNotificationImpl
    extends EventRelatedNotificationImpl<
        PrivateChannelInviteNotificationDto,
        typeof PRIVATE_CHANNEL_INVITE
    >
    implements PrivateChannelInviteNotification {
    constructor(
        public id: string,
        value: PrivateChannelInviteNotificationDto,
        markAlwaysAsRead: boolean,
    ) {
        super(id, value, markAlwaysAsRead)
    }
}
