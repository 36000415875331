import { AosNotification } from 'aos-services/src/services/notifications/types/Notification'
import { chain, unionBy } from 'lodash'
import { createSelector } from 'reselect'

export interface NotificationsStateUi {
    isOpen: boolean
    isPinned: boolean
}

export interface NotificationsState {
    notifications: AosNotification[]
    lazyLoadedNotifications: AosNotification[]
    ui: NotificationsStateUi
}

export interface NotificationsStateAware {
    notifications: NotificationsState
}

export const initialNotificationsState: NotificationsState = {
    notifications: [],
    lazyLoadedNotifications: [],
    ui: {
        isOpen: false,
        isPinned: false,
    },
}

export const getUnreadNotificationIDsSelector = (state: NotificationsStateAware): string[] =>
    state.notifications.notifications.filter(t => !t.read).map(t => t.id)

export const getUnreadNotificationCountSelector = createSelector(
    getUnreadNotificationIDsSelector,
    unreadNotifications => unreadNotifications.length,
)

export const getAllNotificationCountSelector = (state: NotificationsStateAware): number =>
    state.notifications.notifications.length + state.notifications.lazyLoadedNotifications.length

export const notificationUiSelector = (state: NotificationsStateAware) => state.notifications.ui

export interface NotificationGroup {
    day: string
    items: AosNotification[]
}

export const groupNotificationsSelector = createSelector(
    (state: NotificationsStateAware) => state.notifications,
    ({ notifications, lazyLoadedNotifications }) => {
        const allNotifications = unionBy(notifications, lazyLoadedNotifications, 'id')

        const orderItems = (items: AosNotification[]) =>
            chain(items)
                .orderBy((t: AosNotification) => t.timestamp.unix(), 'desc')
                .value()

        return chain(allNotifications)
            .groupBy(t => t.timestamp.startOf('day').format())
            .map((items: AosNotification[], day: string) => ({ day, items: orderItems(items) }))
            .orderBy('day', 'desc')
            .value()
    },
)
