import { isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { DateTime } from 'aos-helpers/src/helpers/Time'

import { BrushesBrand } from '../task/BrushesBrand'
import { BrushesVehicle } from '../task/BrushesVehicle'
import { MaintenanceJob, MaintenanceTask, MaintenanceTaskType } from '../task/MaintenanceTask'
import { TaskType } from '../TaskType'
import {
    BaseTaskFormPayload,
    baseTaskFormPayloadFromTask,
    BaseTaskPayloadValidation,
    baseValidateTask,
    initialBaseTaskPayload,
} from './BaseTaskFormPayload'

export interface MaintenanceTaskFormPayload extends BaseTaskFormPayload {
    type: TaskType.Maintenance
    assignments: MaintenanceJobAssignmentPayload[]
    billable: boolean
    contractors: MaintenanceContractorPayload[]
}

export interface MaintenanceContractorPayload {
    name?: string
    contractTime?: DateTime
    jobStart?: DateTime
    jobEnd?: DateTime
    numberOfWorkers?: number
    uuid?: string
}

export interface MaintenanceJobAssignmentPayload {
    job?: MaintenanceJob
    brushesVehicle?: BrushesVehicle
    newBrushVehicle?: string
    brushesBrand?: BrushesBrand
    engineLastShiftHours?: number
    brushesLastShiftHours?: number
    totalHours?: number
    uuid?: string
}

export const initialMaintenanceTaskPayload: MaintenanceTaskFormPayload = {
    type: TaskType.Maintenance,
    ...initialBaseTaskPayload,
    contractors: [],
    assignments: [],
    billable: false,
}

interface MaintenanceJobAssignmentPayloadValidation {
    job: boolean
    brushesVehicle?: boolean
    brushesBrand?: boolean
    engineLastShiftHours?: boolean
    brushesLastShiftHours?: boolean
    totalHours?: boolean
}

interface MaintenanceContractorPayloadValidation {
    name: boolean
    contractTime: boolean
    jobStart: boolean
    jobEnd: boolean
    numberOfWorkers: boolean
}

export interface MaintenanceTaskPayloadValidation extends BaseTaskPayloadValidation {
    assignments: MaintenanceJobAssignmentPayloadValidation[]
    contractors: MaintenanceContractorPayloadValidation[]
}

const isValidNumberOfHours = (hours: any): boolean => isNotEmpty(hours) && hours >= 0

const assignmentsValidation = (
    payload: MaintenanceTaskFormPayload,
): MaintenanceJobAssignmentPayloadValidation[] => {
    const brushes = payload.assignments.filter(a => a?.job?.jobType === MaintenanceTaskType.Brushes)
    const other = payload.assignments.filter(a => a?.job?.jobType !== MaintenanceTaskType.Brushes)

    return [
        ...brushes?.map(a => ({
            job: isNotEmpty(a?.job),
            brushesVehicle: isNotEmpty(a?.brushesVehicle),
            brushesBrand: isNotEmpty(a?.brushesBrand),
            engineLastShiftHours: isValidNumberOfHours(a?.engineLastShiftHours),
            brushesLastShiftHours: isValidNumberOfHours(a?.brushesLastShiftHours),
            totalHours: isValidNumberOfHours(a?.totalHours),
        })),
        ...other?.map(a => ({
            job: isNotEmpty(a?.job),
            brushesVehicle: true,
            brushesBrand: true,
            totalHours: true,
            engineLastShiftHours: true,
            brushesLastShiftHours: true,
        })),
    ].filter(Boolean)
}

export const validateMaintenanceTask = (
    t: MaintenanceTaskFormPayload,
): MaintenanceTaskPayloadValidation => ({
    ...baseValidateTask(t),
    assignments: t.assignments.length ? assignmentsValidation(t) : [{ job: false }],
    contractors:
        t.contractors.length > 0
            ? t.contractors.map(a => ({
                  name: isNotEmpty(a?.name),
                  contractTime: isNotEmpty(a?.contractTime),
                  jobStart: isNotEmpty(a?.jobStart),
                  jobEnd: isNotEmpty(a?.jobEnd),
                  numberOfWorkers: isNotEmpty(a?.numberOfWorkers),
              }))
            : [
                  {
                      name: true,
                      contractTime: true,
                      jobStart: true,
                      jobEnd: true,
                      numberOfWorkers: true,
                  },
              ],
})

export const maintenanceTaskFormPayloadFromTask = (
    t: MaintenanceTask,
    isClone = false,
): MaintenanceTaskFormPayload => ({
    type: TaskType.Maintenance,
    ...baseTaskFormPayloadFromTask(t),
    billable: t.billable,
    assignments: t.assignments,
    contractors: isClone ? t.contractors.map(item => ({ ...item, id: undefined })) : t.contractors,
})
