import { CdmMetricType } from 'aos-services/src/services/airportStatus/cdm/types/CdmMetricType'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'
import styled from 'styled-components'

import { CdmItemState } from '../../../../services/statusDashboard/types/itemStates/CdmItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { CdmGauge } from './CdmGauge'
import CdmGaugesTobt from './CdmGaugesTobt'
import CdmTobtChartHours from './CdmTobtChartHours'
import { CdmItem } from './partials/CdmItem'

interface CdmProps extends StatusDashboardItemCommonProps<CdmItemState> {}

export const Cdm: FC<CdmProps> = props => {
    return (
        <CdmItem itemProps={pickDashboardItemProps(props)} syncId={props.id}>
            <Row>
                <CdmGaugesTobt />
                {EnumValues.getValues(CdmMetricType).map(metricType => (
                    <GaugeWrapper key={metricType}>
                        <CdmGauge
                            key={metricType}
                            metricType={metricType as CdmMetricType}
                            itemId={props.id}
                            itemState={props.itemState}
                        />
                    </GaugeWrapper>
                ))}
            </Row>
            <CdmTobtChartHours {...props} />
        </CdmItem>
    )
}

const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
`

const GaugeWrapper = styled.div`
    height: 224px;
    padding: 5px 10px;
`
