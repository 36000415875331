import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { ChecklistsSubmitData } from 'aos-services/src/services/events/types/ChecklistsSubmitData'

export const OPEN_CHECKLIST_MODAL = 'CHECKLIST_SELECTION_MODAL/OPEN_MODAL'
export type OpenChecklistModalAction = Action<typeof OPEN_CHECKLIST_MODAL>
export const openChecklistModalAction =
    emptyActionCreator<OpenChecklistModalAction>(OPEN_CHECKLIST_MODAL)

export const CLOSE_CHECKLIST_MODAL = 'CHECKLIST_SELECTION_MODAL/CLOSE_MODAL'
export type CloseChecklistModalAction = Action<typeof CLOSE_CHECKLIST_MODAL>
export const closeChecklistModalAction =
    emptyActionCreator<CloseChecklistModalAction>(CLOSE_CHECKLIST_MODAL)

export const GET_AVAILABLE_CHECKLISTS_SUCCESS =
    'CHECKLIST_SELECTION_MODAL/GET_AVAILABLE_CHECKLISTS_SUCCESS'
export type GetAvailableChecklistsSuccess = PayloadAction<
    typeof GET_AVAILABLE_CHECKLISTS_SUCCESS,
    [AosSimpleTemplate[], number[]]
>
export const getAvailableChecklistsSuccess = payloadActionCreator<
    GetAvailableChecklistsSuccess,
    [AosSimpleTemplate[], number[]]
>(GET_AVAILABLE_CHECKLISTS_SUCCESS)

export interface ChecklistCheckData {
    id: number
    selected: boolean
}

export const CHECK_CHECKLIST = 'CHECKLIST_SELECTION_MODAL/CHECK_CHECKLIST'
export type CheckChecklistAction = PayloadAction<typeof CHECK_CHECKLIST, ChecklistCheckData>
export const checkChecklistAction = payloadActionCreator<CheckChecklistAction, ChecklistCheckData>(
    CHECK_CHECKLIST,
)

export const SUBMIT_CHECKLISTS = 'CHECKLIST_SELECTION_MODAL/SUBMIT_CHECKLISTS'
export type SubmitChecklistsAction = PayloadAction<typeof SUBMIT_CHECKLISTS, ChecklistsSubmitData>
export const submitChecklistsAction = payloadActionCreator<
    SubmitChecklistsAction,
    ChecklistsSubmitData
>(SUBMIT_CHECKLISTS)

export type ChecklistSelectionModalActions =
    | OpenChecklistModalAction
    | CloseChecklistModalAction
    | GetAvailableChecklistsSuccess
    | CheckChecklistAction
