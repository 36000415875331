import { ReactNode } from 'react'
import { Column, ColumnProps } from 'react-virtualized'

export interface TypedCellRendererProps<T, TField extends keyof T = keyof T> {
    rowData: T
    cellData: T[TField]
}

type TypedCellRenderer<T, TField extends keyof T> = (
    props: TypedCellRendererProps<T, TField>,
) => ReactNode

interface TypedColumnProps<T, TField extends keyof T>
    extends Omit<ColumnProps, 'cellRenderer' | 'dataKey'> {
    cellRenderer?: TypedCellRenderer<T, TField>
    dataKey: TField
}

const TypedColumnTemplateFunction = <T extends {}, TField extends keyof T = keyof T>(
    _props: TypedColumnProps<T, TField>,
) => undefined as any

export const TypedColumn = (Column as any) as typeof TypedColumnTemplateFunction
