import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateTrend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { formatCountStats } from 'aos-services/src/services/common/types/Stats'
import { TrendStats } from 'aos-services/src/services/common/types/TrendStats'
import { Box } from 'aos-ui/src/components/base/Box'
import { ColumnsGrid } from 'aos-ui/src/components/base/ColumnsGrid'
import { TrendLegendItem } from 'aos-ui/src/components/chart/trend/TrendLegendItem'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

export const RegularityStatsSection: FC<RegularityStatsProps> = props => {
    const renderTrend = (itemProps: RegularityLegendItemProps) => (
        <Tooltip
            body={translateTrend('dashboard.regularity.trend.tooltip')(itemProps.trend)}
            placement='top'
            withArrow
        >
            <div>
                <Box marginRight={20}>
                    <TrendLegendItem
                        value={itemProps.value}
                        unit={Unit.Percent}
                        subvalue={formatCountStats(itemProps)}
                        subunit={translate('dashboard.regularity.unit.cancelled')}
                        trendVariant={TrendChangeVariant.PreferredUp}
                        trend={itemProps.trend}
                        label={itemProps.label}
                        labelColor={itemProps.labelColor}
                    />
                </Box>
            </div>
        </Tooltip>
    )

    return (
        <ColumnsGrid>
            {renderTrend(props.arrivals)}
            {renderTrend(props.departures)}
        </ColumnsGrid>
    )
}

export interface RegularityLegendItemProps extends TrendStats {
    labelColor: Color
    label: string
}

export interface RegularityStatsProps {
    arrivals: RegularityLegendItemProps
    departures: RegularityLegendItemProps
}
