import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { conditionalWrapper } from 'aos-helpers/src/helpers/Function'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import { rgba } from 'polished'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { BaseDropTargetProps } from '../../helpers/DragAndDrop'

export const DropTargetOverlay: FC<DropTargetOverlayProps> = props => {
    const { isOver, variant = DropTargetOverlayVariant.Light } = props
    const wrapper = conditionalWrapper(true, props.connectDropTarget)

    return wrapper(
        <div>
            <DropTargetOverlayBox
                variant={variant}
                isOver={isOver}
                column
                centered
                color={Color.White}
                size={12}
            >
                <Icon
                    iconVariant={IconVariant.White}
                    svg={SvgIcon.Drop}
                    iconSize={BlockSize.Large}
                />
                <Box>{props.label}</Box>
            </DropTargetOverlayBox>
        </div>,
    )
}

export enum DropTargetOverlayVariant {
    Light,
    Dark,
}

export interface DropTargetOverlayProps extends BaseDropTargetProps {
    label: string
    variant?: DropTargetOverlayVariant
}

const DropTargetOverlayBox = styled(Text)<{ variant: DropTargetOverlayVariant; isOver?: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid ${Color.Primary};
    background-color: ${rgba(Color.Primary, 0.3)};
    ${p =>
        p.isOver &&
        css`
            background-color: ${overColor[p.variant]};
        `}
`

const overColor: Record<DropTargetOverlayVariant, Color> = {
    [DropTargetOverlayVariant.Dark]: rgba(Color.Primary, 0.9) as Color,
    [DropTargetOverlayVariant.Light]: rgba(Color.Primary, 0.6) as Color,
}
