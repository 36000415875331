import {
    LOAD_RECENT_TASKS,
    LOAD_RECENT_TASKS_FAILURE,
    LOAD_RECENT_TASKS_SUCCESS,
    RecentTasksAction,
} from './actions'
import { initialRecentTasksState, RecentTasksState } from './state'

export const recentTasksReducer = (
    state = initialRecentTasksState,
    action: RecentTasksAction,
): RecentTasksState => {
    switch (action.type) {
        case LOAD_RECENT_TASKS:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case LOAD_RECENT_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload,
                isLoading: false,
                isError: false,
            }
        case LOAD_RECENT_TASKS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state
    }
}
