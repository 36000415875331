import { toPairs, values } from 'lodash'

import { StatusRange } from '../../../common/types/StatusRange'

// Display of charts and legends depends on ordering of this enum
export enum BaggageStatSection {
    Normal = 'NORMAL',
    Warning = 'WARNING',
    Alert = 'ALERT',
}

export type BaggageStatusRange = StatusRange<BaggageStatSection>

export const baggageStatsRecordToPairs = <T>(
    r: Record<BaggageStatSection, T>,
): [BaggageStatSection, T][] => toPairs(r) as [BaggageStatSection, T][]

export const baggageStatsRecordToValues = <T>(r: Record<BaggageStatSection, T>): T[] =>
    values(r) as T[]
