import { identity, noop } from 'lodash'

export function conditionalFunctionCall1Arg<T>(
    predicate: boolean | undefined,
    f: f.Action1<T> | undefined,
    param: T,
): f.Action | undefined {
    if (predicate === true && f) {
        return () => f(param)
    }
    return undefined
}

export function conditionalFunctionCall2Arg<T, U>(
    predicate: boolean | undefined,
    f: f.Action2<T, U> | undefined,
    param: T,
    param2: U,
): f.Action | undefined {
    if (predicate === true && f) {
        return () => f(param, param2)
    }
    return undefined
}

/**
 * Calls a function with the given parameters if the predicate is true
 * @param predicate
 * @param f
 * @param params
 */
export function conditionalFunctionCall<T>(
    predicate: boolean | undefined,
    f: ((...params: [T]) => void) | undefined,
    ...params: [T]
): (() => void) | undefined {
    if (predicate === true && f) {
        return () => f(...params)
    }
    return undefined
}

export function conditionalFunction<T>(predicate: boolean | undefined, f?: T): T | undefined {
    if (predicate === true) {
        return f
    }
    return undefined
}

export function optionalFunctionCall1Arg<T>(f: f.Action1<T> | undefined, param: T): f.Action {
    return () => (f || noop)(param)
}

export function optionalFunctionCall(f?: f.Action): f.Action {
    return () => (f || noop)()
}

export function isDefined<T>(a: T | undefined): a is T {
    return a !== undefined
}

export function isNotNull<T>(a: T | null): a is T {
    return a !== null
}

export function isPresent<T>(a: T | null | undefined): a is T {
    return isDefined(a) && isNotNull(a)
}

export function conditionalWrapper<T, R>(
    predicate: boolean | undefined,
    f?: f.Func1<T, R>,
): f.Func1<T, R> {
    if (predicate === true && f) {
        return f
    }
    return identity
}

export function nullableToNotRequired<T>(value: T | null): T | undefined {
    return value !== null ? value : undefined
}

export const optionalFunction = <T, U>(f: f.Func1<T, U>): f.Func1<T | undefined, U | undefined> => (
    arg: T | undefined,
) => (arg !== undefined ? f(arg) : undefined)
