import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { DeIcingChemicals } from 'aos-services/src/services/tasks/types/task/DeIcingChemicals'

export type ReportChemicals = FilterOptionAll | DeIcingChemicals

export const translateReportChemicals = (value: ReportChemicals): string =>
    value === FilterOptionAll.All
        ? translateEnum(FilterOptionAll, 'tasks.reports.chemicals')(value)
        : value.label
