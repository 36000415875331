import { CommonAction, SET_CURRENT_TIME } from './actions'
import { CommonState, initialCommonState } from './state'

export function commonReducer(state = initialCommonState, action: CommonAction): CommonState {
    switch (action.type) {
        case SET_CURRENT_TIME:
            return { ...state, currentTime: action.payload }
        default:
            return state
    }
}
