import { UseFormApi } from './useForm'

export const useBindForm = <TData extends {}>(formApi: UseFormApi<TData>) => {
    type FieldName = keyof TData

    const { updateFieldValue, touchField, values, getFieldError } = formApi

    const bindLabeledInput = <T extends FieldName>(fieldName: T) => ({
        onChangeText: (value: TData[T]) => updateFieldValue(fieldName, value),
        onBlur: () => touchField(fieldName),
        value: values[fieldName],
        errorMessage: getFieldError(fieldName),
        isError: !!getFieldError(fieldName),
    })

    const bindDropdown = <T extends FieldName>(fieldName: T) => ({
        onChange: (value: TData[T]) => updateFieldValue(fieldName, value),
        value: values[fieldName],
        errorMessage: getFieldError(fieldName),
        isError: !!getFieldError(fieldName),
    })

    const bindOtherField = <T extends FieldName>(fieldName: T) => ({
        onChange: (value: TData[T]) => updateFieldValue(fieldName, value),
        onBlur: () => touchField(fieldName),
        value: values[fieldName],
        errorMessage: getFieldError(fieldName),
        isError: !!getFieldError(fieldName),
    })

    const bindLabeledLocation = <T extends FieldName>(fieldName: T) => ({
        onChange: (value: TData[T]) => {
            return updateFieldValue(fieldName, value)
        },
        onBlur: () => touchField(fieldName),
        errorMessage: getFieldError(fieldName),
        isError: !!getFieldError(fieldName),
    })

    return {
        bindLabeledTextInput: bindLabeledInput,
        bindLabeledTextArea: bindLabeledInput,
        bindDropdown: bindDropdown,
        bindMultiDropdown: bindDropdown,
        bindLabeledDatetimeInput: bindOtherField,
        bindLabeledLocation: bindLabeledLocation,
        ...formApi,
    }
}
