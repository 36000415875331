import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React from 'react'

export const DefaultSidebar = () => {
    return (
        <Sidebar title={translate('notam.timeline.default-sidebar.title')}>
            <PanelPlaceholder
                svg={SvgIcon.InspectorPlaceholder}
                label={translate('notam.timeline.inspector-description')}
            />
        </Sidebar>
    )
}
