import { domainForTicks } from 'aos-helpers/src/helpers/domain/Domain'
import { flatten, min, range, reverse } from 'lodash'

import { TimeValuePoint } from '../../base/types/TimePoint'

export interface FlightPoint extends TimeValuePoint {
    flightsCount: number
    flightNumbers: string
}

export interface FlightsSeries<T = FlightPoint> {
    arrivals: T[]
    departures: T[]
    all: T[]
}

const calculateMinFlightPointValue = (...points: FlightPoint[][]) =>
    Math.max(min(flatten(points).map(p => p.value)) || 0, 0) * 0.9

export const calculatePercentageYDomain = (fs: FlightsSeries) => {
    const minValue = calculateMinFlightPointValue(fs.arrivals, fs.departures)
    const step = minValue >= 50 ? 10 : 20
    return domainForTicks(reverse(range(6).map(v => 100 - v * step)))
}
