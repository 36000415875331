import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import {
    PaxForecastChartData,
    PaxForecastChartType,
} from 'aos-services/src/services/airportStatus/paxForecast/types/PaxForecastChartData'
import { PaxForecastArrivalDepartureFilter } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastArrivalDepartureFilter'
import React, { FC } from 'react'

import { PaxForecastDaysChart } from './days/PaxForecastDaysChart'
import { PaxForecastHoursChart } from './hours/PaxForecastHoursChart'
import { PaxForecastTimeOfDayChart } from './timeOfDay/PaxForecastTimeOfDayChart'

interface PaxForecastChartProps extends ClassNameProps {
    data: PaxForecastChartData
    arrivalDepartureFilter: PaxForecastArrivalDepartureFilter
}

export const PaxForecastChart: FC<PaxForecastChartProps> = props => {
    switch (props.data.type) {
        case PaxForecastChartType.Hours:
            return <PaxForecastHoursChart data={props.data.data} />
        case PaxForecastChartType.Days:
            return <PaxForecastDaysChart data={props.data.data} />
        case PaxForecastChartType.TimeOfDay:
            return (
                <PaxForecastTimeOfDayChart
                    data={props.data.data}
                    arrivalDepartureFilter={props.arrivalDepartureFilter}
                />
            )
    }
}
