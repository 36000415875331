import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import React, { FC } from 'react'

import { CloseIcon } from '../icons/CloseIcon'
import { SearchIcon } from '../icons/SearchIcon'

interface MapLayersVisibilityProps {
    layersSidebarOpen: boolean
    toggleLayersSidebar(v: boolean): void
}

export const MapLayersVisibility: FC<MapLayersVisibilityProps> = ({
    layersSidebarOpen,
    toggleLayersSidebar,
}) => {
    return (
        <Box row shrink={0}>
            {translate('map.layer-selector.layers')}
            {layersSidebarOpen ? (
                <CloseIcon toggleLayersSidebar={toggleLayersSidebar} />
            ) : (
                <SearchIcon toggleLayersSidebar={toggleLayersSidebar} />
            )}
        </Box>
    )
}
