import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { ButtonProps } from 'aos-ui/src/components/base/ButtonProps'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { CircleIndicator, CircleIndicatorVariant } from 'aos-ui/src/components/ui/CircleIndicator'
import React, { FC } from 'react'
import styled from 'styled-components'

interface NotificationBellProps extends ButtonProps {
    count: number
}

export const NotificationBell: FC<NotificationBellProps> = props => (
    <NotificationBellButton
        onClick={props.onClick}
        svg={SvgIcon.Notifications}
        iconSize={BlockSize.XLarge}
        iconVariant={IconVariant.White}
    >
        {props.count > 0 && (
            <Positioned top={0} right={0}>
                <CircleIndicator variant={CircleIndicatorVariant.Blue} large>
                    {props.count}
                </CircleIndicator>
            </Positioned>
        )}
    </NotificationBellButton>
)

const NotificationBellButton = styled(IconButton)`
    position: relative;
    width: 60px;
    height: 60px;
`
