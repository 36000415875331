import { Action } from 'aos-helpers/src/helpers/ActionCreator'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
export const useSyncHook = <T extends any>(
    syncActions: Action<T>[],
    syncStopActions: Action<T>[],
) => {
    const dispatch = useDispatch()
    useEffect(() => {
        syncActions.forEach(action => dispatch(action))
        return () => {
            syncStopActions.forEach(action => dispatch(action))
        }
    }, [])
}
