import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { TimeRangeFilter } from 'aos-services/src/services/flightInformation/types/TimeRangeFilter'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'

import { TableDropdown } from '../partial/TableDropdown'

interface FilterProps {
    value?: string | number
    onChange(v: string): void
}

export const StringFilter: FC<FilterProps> = ({ value, onChange }) => (
    <CleanableInput
        type='text'
        variant={ThemeVariant.Black}
        placeholder={translate('flight-information.filter.placeholder')}
        value={value}
        onChangeText={onChange}
    />
)

export const TimeFilter: FC<FilterProps> = ({ value, onChange }) => (
    <TableDropdown
        value={value}
        items={EnumValues.getValues(TimeRangeFilter)}
        valueRenderer={translateEnum<TimeRangeFilter>(
            TimeRangeFilter,
            'flight-information.time-range',
        )}
        onChange={onChange}
    />
)
