import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import { isEqual } from 'lodash'
import React, { FC } from 'react'

import { renderFodIcon } from '../../icons/MapIconRenderer'
import { BaseLayerProps } from '../base/BaseLayer'
import { MarkerLikeLayer } from './MarkerLikeLayer'

interface FodLayerProps extends BaseLayerProps {
    fods: FodDto[]
}

type FodWithId = FodDto & { id: number }

export const FodLayer: FC<FodLayerProps> = ({
    fods,
    isVisible,
    zIndex,
    layerId,
    minZoom,
    maxZoom,
}) => {
    const fodWithId = fods?.map((fod, index) => ({ ...fod, id: index }))
    const comparator = (e1: FodWithId, e2: FodWithId) =>
        e1.id === e2.id &&
        isEqual(e1.location, e2.location) &&
        e1.fodType === e2.fodType &&
        e1.fodSource === e2.fodSource

    return (
        <MarkerLikeLayer
            comparator={comparator}
            items={fodWithId}
            iconRenderer={renderFodIcon}
            isVisible={isVisible}
            zIndex={zIndex}
            layerId={layerId}
            minZoom={minZoom}
            maxZoom={maxZoom}
            selectionTarget={SelectedMapElementType.Fod}
            setter={Fod => ({
                Fod,
            })}
        />
    )
}
