import { getInitialValuesForEnumRecord } from 'aos-helpers/src/helpers/Enum'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'

import { WeatherLatestInfo } from '../../../../dataaccess/airportStatus/types/WeatherSeriesDto'
import { WeatherMetric, WeatherSeries } from './WeatherMetrics'
import { WeatherOtherSeriesType, WeatherValueSeriesType } from './WeatherSeriesType'

export interface WeatherState {
    forecasts: WeatherSeries
    current: WeatherMetric
    latestInfo?: WeatherLatestInfo
    lastUpdate: DateTime
}

export const emptyWeatherState: WeatherState = {
    forecasts: {
        ...getInitialValuesForEnumRecord(WeatherValueSeriesType, []),
        ...getInitialValuesForEnumRecord(WeatherOtherSeriesType, []),
    },
    current: {},
    lastUpdate: dateTime(),
    latestInfo: undefined,
}
