import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box, MarginBoxProps, marginStyleGenerator } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { CSSObject } from 'styled-components'

export enum LegendDotVariant {
    Stroke = 1,
    Fill,
    Line,
    FillAndStroke,
    Circle,
    VerticalLine,
}

const getDotStyle = (
    variant: LegendDotVariant,
    mainColor: Color,
    secondaryColor?: Color,
): CSSObject => {
    switch (variant) {
        case LegendDotVariant.Stroke:
            return {
                border: `2px solid ${mainColor}`,
            }
        case LegendDotVariant.Fill:
            return {
                backgroundColor: mainColor,
            }
        case LegendDotVariant.Line:
            return {
                backgroundColor: mainColor,
                height: '2px',
            }
        case LegendDotVariant.VerticalLine:
            return {
                backgroundColor: mainColor,
                width: '2px',
            }
        case LegendDotVariant.FillAndStroke:
            return {
                border: `2px solid ${mainColor}`,
                backgroundColor: secondaryColor,
            }
        case LegendDotVariant.Circle:
            return {
                border: `2px solid ${mainColor}`,
                backgroundColor: secondaryColor,
                borderRadius: '50%',
            }
    }
    return {}
}

export interface LegendDotConfig {
    size: BlockSize
    mainColor: Color
    secondaryColor?: Color
    variant?: LegendDotVariant
}

interface LegendItemDotProps extends LegendDotConfig, MarginBoxProps {}

export const LegendItemDot = styled(Box)<LegendItemDotProps>(
    ({ size, mainColor, secondaryColor, variant = LegendDotVariant.Fill, ...props }) => {
        const margins = marginStyleGenerator(props)

        const styles: CSSObject = {
            height: `${size}px`,
            width: `${size}px`,
            borderRadius: '3px',
            flexShrink: 0,
        }

        const colorStyles = getDotStyle(variant, mainColor, secondaryColor)

        return { ...margins, ...styles, ...colorStyles }
    },
)
