import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { attachmentsSagaFactory } from 'aos-services/src/core/attachments/sagas'
import { AttachmentsState } from 'aos-services/src/core/attachments/state'
import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import {
    changeFodFormAction,
    CREATE_FOD,
    CREATE_FOD_SUCCESS,
    CreateFodAction,
    createFodFailed,
    createFodSuccess,
    EDIT_FOD,
    EDIT_FOD_SUCCESS,
    EditFodAction,
    editFodFailed,
    editFodSuccess,
    fodClearFormAction,
    loadFod,
} from 'aos-services/src/core/fod/actions'
import { fodSagas as fodBaseSagas } from 'aos-services/src/core/fod/saga'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { fodService } from 'aos-services/src/services/fod/fodService'
import { FodDto } from 'aos-services/src/services/fod/types/FodDto'
import { initialFodNotesForm } from 'aos-services/src/services/fod/types/FodNotesFormData'
import { successToast } from 'aos-ui/src/components/toast/ToastMessages'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { rootHistory } from '../../components/providers/ToastNavigationConnectorWrapper'
import {
    FOD_ATTACHMENTS_PARENT,
    FOD_RELOAD,
    fodAttachmentsParentAction,
    FodReloadAction,
    fodReloadSuccessAction,
    SET_FOD_CREATE_MODAL_OPEN,
    SET_FOD_EDIT_MODAL_OPEN,
    setAirportAction,
    SetFodCreateModalOpenAction,
} from './actions'

function* reloadFodSaga() {
    yield takeEvery<FodReloadAction>(FOD_RELOAD, function* (action) {
        const siteLocation: AosAirport = yield select(currentUserSiteLocation)
        const response: Pageable<FodDto> = yield call(
            fodService.getFodList,
            siteLocation,
            action.payload.number,
            action.payload.size,
        )

        yield put(fodReloadSuccessAction(response))
    })
}

function* reloadAfterSaveFodSaga() {
    yield takeEvery([CREATE_FOD_SUCCESS, EDIT_FOD_SUCCESS], function* () {
        yield put(loadFod({ page: 0, size: 10 }))
        yield put(changeFodFormAction(initialFodNotesForm))
        yield put(fodClearFormAction(true))
    })
}

function* setAirportLocationSaga() {
    yield takeEvery<SetFodCreateModalOpenAction>(
        [SET_FOD_CREATE_MODAL_OPEN, SET_FOD_EDIT_MODAL_OPEN],
        function* (action) {
            if (!action.payload) {
                yield put(changeFodFormAction(initialFodNotesForm))
                yield put(fodClearFormAction(true))
            } else {
                const siteLocation: AosAirport = yield select(currentUserSiteLocation)
                yield put(setAirportAction(siteLocation))
            }
        },
    )
}

function* createFodSaga() {
    yield takeEvery<CreateFodAction>(CREATE_FOD, function* (action) {
        try {
            const siteLocation: AosAirport = yield select(currentUserSiteLocation)
            const attachmentsState: AttachmentsState = yield select(
                state => state.fod.attachmentsState,
            )
            yield call(fodService.add, {
                ...action.payload,
                siteLocation,
                attachments: attachmentsState.attachments,
            } as FodDto)
            yield all([
                put(createFodSuccess()),
                put(loadFod({ page: 0, size: 10 })),
                put(fodClearFormAction(true)),
            ])
            successToast(translate('fod.messages.created'), () => {
                rootHistory?.push('/tasks/fod')
            })
        } catch {
            yield put(createFodFailed())
        }
    })
}

function* editFodSaga() {
    yield takeEvery<EditFodAction>(EDIT_FOD, function* (action) {
        try {
            const attachmentsState: AttachmentsState = yield select(
                state => state.fod.attachmentsState,
            )
            yield call(
                fodService.edit,
                action.payload.id as number,
                {
                    ...action.payload,
                    id: undefined,
                    attachments: attachmentsState.attachments,
                } as FodDto,
            )
            yield all([
                put(editFodSuccess()),
                put(loadFod({ page: 0, size: 10 })),
                put(fodClearFormAction(true)),
            ])
            successToast(translate('fod.messages.updated'), () => {
                rootHistory?.push('/tasks/fod')
            })
        } catch {
            yield put(editFodFailed())
        }
    })
}

export const fodManagerSagas = [
    ...fodBaseSagas,
    reloadFodSaga,
    reloadAfterSaveFodSaga,
    setAirportLocationSaga,
    createFodSaga,
    editFodSaga,
    ...attachmentsSagaFactory(FOD_ATTACHMENTS_PARENT, fodAttachmentsParentAction),
]
