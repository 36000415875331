import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import {
    BaseStatusDashboardState,
    initialBaseStatusDashboardState,
} from 'aos-services/src/core/statusDashboard/state'
import { Breakpoint } from 'aos-ui/src/components/grid/ResponsiveGrid'

import { DashboardPresets } from '../../services/statusDashboard/types/DashboardPreset'
import { defaultPresets } from './presets'

export interface StatusDashboardState extends BaseStatusDashboardState {
    presets: DashboardPresets
    isSidebarOpen: boolean
    currentBreakpoint: Breakpoint
    carouselTrigger: DateTime
}

export interface StatusDashboardStateAware {
    statusDashboard: StatusDashboardState
}

export const initialStatusDashboardState: StatusDashboardState = {
    ...initialBaseStatusDashboardState,
    isSidebarOpen: false,
    currentBreakpoint: Breakpoint.lg,
    presets: defaultPresets,
    carouselTrigger: dateTime(),
}
