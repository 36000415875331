import { formatMinutesAsDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateTrend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Unit } from 'aos-helpers/src/helpers/Unit'
import { QueueingTimeStat } from 'aos-services/src/core/statusDashboardData/queueingTime/selectors'
import { ControlPointConfig } from 'aos-services/src/services/queueingTime/types/ControlPointConfig'
import { QueueingDataTypeFilter } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { Box } from 'aos-ui/src/components/base/Box'
import { TrendInversedLegendItem } from 'aos-ui/src/components/chart/trend/TrendInversedLegendItem'
import { TrendMeasureSizeVariant } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC } from 'react'

interface QueueingStatProps {
    config: ControlPointConfig
    stat: QueueingTimeStat
    compact?: boolean
    filter: QueueingDataTypeFilter
}

export const QueueingStat: FC<QueueingStatProps> = ({ stat, config, compact, filter }) => (
    <Tooltip
        body={translateTrend('dashboard.queueing-time.trend.tooltip')(stat.trend)}
        placement='top'
        withArrow
    >
        <div>
            <Box marginRight={20}>
                <TrendInversedLegendItem
                    compact={compact}
                    value={
                        filter === QueueingDataTypeFilter.WaitTime
                            ? formatMinutesAsDuration(stat.value)
                            : stat.value
                    }
                    unit={filter === QueueingDataTypeFilter.WaitTime ? undefined : Unit.Pax}
                    subvalue={
                        filter === QueueingDataTypeFilter.WaitTime
                            ? translate('dashboard.queueing-time.forecast')
                            : ''
                    }
                    subunit={
                        filter === QueueingDataTypeFilter.WaitTime
                            ? `${
                                  undefined === stat.forecast
                                      ? stat.forecast
                                      : formatMinutesAsDuration(stat.forecast)
                              } `
                            : ''
                    }
                    trendVariant={TrendChangeVariant.PreferredDown}
                    trend={stat.trend}
                    label={config.code}
                    labelColor={config.color}
                    labelNote={config.fullName}
                    sizeVariant={TrendMeasureSizeVariant.Regular}
                    subunitInversed
                />
            </Box>
        </div>
    </Tooltip>
)
