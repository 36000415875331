import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import Control from 'ol/control/Control'
import { Coordinate } from 'ol/coordinate'
import Geolocation from 'ol/Geolocation'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { BaseComponent } from '../../base/BaseComponent'

interface GeolocationOlControlOptions {
    label: Node
    onClick(): void
}

class GeolocationOlControl extends Control {
    constructor(options: GeolocationOlControlOptions) {
        const node = document.createElement('div')
        const button = document.createElement('button')
        button.appendChild(options.label)
        node.className = 'ol-geo ol-unselectable ol-control'
        button.addEventListener('click', options.onClick, false)
        button.addEventListener('touchstart', options.onClick, false)
        node.appendChild(button)
        super({
            element: node,
        })
    }
}

export class GeolocationControl extends BaseComponent<{}, {}> {
    private control: GeolocationOlControl
    private geolocationInstance: Geolocation | null = null
    private controlNode: Element

    constructor(props: {}) {
        super(props)
        this.controlNode = document.createElement('div')
        this.control = new GeolocationOlControl({
            label: this.controlNode,
            onClick: this.onRequestLocation,
        })
    }

    public componentDidMount() {
        this.geolocationInstance = new Geolocation({
            projection: this.context.map.getView().getProjection(),
        })

        const controlNodeRoot = createRoot(this.controlNode)
        controlNodeRoot.render(
            <Icon
                svg={SvgIcon.Geolocation}
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Std}
            />,
        )
        this.context.map.addControl(this.control)
    }

    private onRequestLocation = () => {
        this.animateToPosition(this.geolocation.getPosition())
        if (!this.geolocation.getTracking()) {
            this.geolocation.setTracking(true)
            this.geolocation.on('change:position', () => {
                this.animateToPosition(this.geolocation.getPosition())
                this.geolocation.setTracking(false)
            })
        }
    }

    private animateToPosition = (position: Coordinate | undefined) => {
        if (position) {
            this.context.map.getView().animate({
                center: position,
                duration: 500,
            })
        }
    }

    private get geolocation() {
        return this.geolocationInstance!
    }
}
