import { initialTimelineState, TimelineState } from 'aos-services/src/core/timeline/state'
import { Notam, Snowtam } from 'aos-services/src/services/flightInformation/types/Notam'
import { Moment } from 'moment'

export interface NotamState {
    list: Notam[]
    snowtam: Snowtam[]
    timeline: TimelineState
    selectedItem?: Notam | Snowtam
    lastUpdated?: Moment
    collapsedGroups: string[]
}

export interface NotamStateAware {
    notam: NotamState
}

export const initialNotamState: NotamState = {
    list: [],
    timeline: initialTimelineState,
    collapsedGroups: [],
    snowtam: [],
}
