import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { format } from 'url'

import { AosAirport } from '../../../services/flightInformation/types/AosAirport'
import { ShiftNotesFormData } from '../../../services/tasks/shiftNotes/types/ShiftNotesFormData'
import {
    ShiftNotesDto,
    ShiftNotesListDto,
} from '../../../services/tasks/shiftNotes/types/ShiftNotesListDto'
import { BaseRestService } from '../../base/BaseRestService'

class ShiftNotesRestService extends BaseRestService {
    public getShiftNotes = async (
        siteLocation: AosAirport,
    ): Promise<Pageable<ShiftNotesListDto>> => {
        return this.getAndTransform<Pageable<ShiftNotesListDto>>(
            format({
                pathname: '/shiftnotes',
                query: { siteLocation: siteLocation, sort: 'ASC', pageSize: 10 },
            }),
            BaseRestService.jsonTransformer,
        )
    }
    public reloadShiftNotes = async (
        siteLocation: AosAirport,
        data: Pageable<ShiftNotesDto>,
    ): Promise<Pageable<ShiftNotesListDto>> => {
        return this.getAndTransform<Pageable<ShiftNotesListDto>>(
            format({
                pathname: '/shiftnotes',
                query: {
                    siteLocation: siteLocation,
                    sort: 'ASC',
                    pageSize: data.size,
                    pageNumber: data.number,
                },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public add = async (siteLocation: AosAirport, data: ShiftNotesFormData) => {
        const notes = {
            message: data.message,
            reporterId: data.reporter?.id,
            siteLocation: siteLocation,
        }
        return this.postJsonAndTransform(
            format({
                pathname: '/shiftnotes',
            }),
            notes,
            BaseRestService.jsonTransformer,
        )
    }
}

export const shiftNotesRestService = new ShiftNotesRestService()
