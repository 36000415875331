import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { cdmService } from '../../../services/airportStatus/cdm/cdmService'
import { CdmMetrics } from '../../../services/airportStatus/cdm/types/CdmMetrics'
import { TobtList } from '../../../services/airportStatus/cdm/types/TobtTypes'
import { firebaseService } from '../../../services/firebase/firebaseService'
import {
    CDM_LOAD,
    CDM_SYNC,
    CDM_SYNC_STOP,
    cdmLoadAction,
    cdmLoadSuccessAction,
    CdmSyncAction,
    CdmSyncStopAction,
    TOBT_LOAD,
    TOBT_SYNC,
    TOBT_SYNC_STOP,
    TobtLoadAction,
    tobtLoadAction,
    tobtLoadSuccessAction,
    TobtSyncAction,
    TobtSyncStopAction,
} from './actions'

function* cdmSyncSaga() {
    yield takeEvery<CdmSyncAction>(CDM_SYNC, function* (action: CdmSyncAction) {
        yield* firebaseService.onListener(
            'cdm',
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.CDM),
                    cdmLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* cdmSyncStopSaga() {
    yield takeEvery<CdmSyncStopAction>(CDM_SYNC_STOP, function* (action: CdmSyncStopAction) {
        yield firebaseService.offListener('cdm', action.payload)
    })
}

function* cdmLoadSaga() {
    yield takeEvery(CDM_LOAD, function* () {
        const data: CdmMetrics = yield call(cdmService.getCdmMetrics)
        yield put(cdmLoadSuccessAction(data))
    })
}

function* tobtSyncSaga() {
    yield takeEvery<TobtSyncAction>(TOBT_SYNC, function* (action: TobtSyncAction) {
        yield* firebaseService.onListener(
            'tobt',
            function* () {
                yield syncValue(
                    sendToSyncRepository.stsRef(SendToSyncType.TOBT),
                    tobtLoadAction,
                    false,
                )
            },
            action.payload,
        ) as Generator
    })
}

function* tobtSyncStopSaga() {
    yield takeEvery<TobtSyncStopAction>(TOBT_SYNC_STOP, function* (action: TobtSyncStopAction) {
        yield firebaseService.offListener('tobt', action.payload)
    })
}

function* tobtLoadSaga() {
    yield takeEvery<TobtLoadAction>(TOBT_LOAD, function* () {
        const data: TobtList = yield call(cdmService.getTobtList)
        yield put(tobtLoadSuccessAction(data))
    })
}

export const cdmSagas = [
    cdmSyncSaga,
    cdmSyncStopSaga,
    cdmLoadSaga,
    tobtLoadSaga,
    tobtSyncSaga,
    tobtSyncStopSaga,
]
