import { mapRecordValues, RecordKey } from 'aos-helpers/src/helpers/Record'
import { Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { keys, sumBy, toPairs } from 'lodash'

export interface DistributionValue {
    percentage: number
    count: number
}

export interface DistributionWithTrendStat extends DistributionValue {
    trend: Trend
}

export const distributionValue = (actual: number, all: number) => {
    if (all === 0) {
        return { percentage: actual !== 0 ? 100 : 0, count: 0 }
    }
    return {
        percentage: Math.round((actual / all) * 100),
        count: actual,
    }
}

/**
 * Rounding values may produce distribution which sum is greater then 100, this function fixes it
 */
const fixRounding = <T extends RecordKey>(
    input: Record<T, DistributionValue>,
): Record<T, DistributionValue> => {
    let currentSum = 0
    const lastIndex = keys(input).length - 1
    return mapRecordValues(input, (v, _, index) => {
        if (index === lastIndex) {
            return { ...v, percentage: 100 - currentSum }
        }
        const percentage = currentSum + v.percentage > 100 ? 100 - currentSum : v.percentage
        currentSum += percentage
        return { ...v, percentage }
    })
}

export const distribution = <T extends RecordKey, U>(
    values: Record<T, U[]>,
): Record<T, DistributionValue> => {
    const pairs = toPairs(values) as [T, U[]][]
    const all = sumBy(pairs, ([, groupValues]) => groupValues.length)
    if (all === 0) {
        return mapRecordValues(values, (_v, _, index) =>
            index === 0 ? distributionValue(1, 0) : distributionValue(0, 0),
        )
    }
    return fixRounding(mapRecordValues(values, v => distributionValue(v.length, all)))
}
