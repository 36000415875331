import {
    DelaysSelectorState,
    delaysTileSelector,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/delays'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'

import { Link } from '../../../../core/Links'
import { DashboardProcessType } from '../../../../services/statusDashboard/types/DashboardPreset'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { TileContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { DelaysItem } from './partials/DelaysItem'
import { DelaysStatsSection } from './partials/DelaysStatsSection'

type DelaysTileOwnProps = StatusDashboardItemCommonProps

interface DelaysTileProps extends DelaysSelectorState, DelaysTileOwnProps {}

export const DelaysTileComponent: FC<DelaysTileProps> = props => (
    <DelaysItem
        noDataWarning={props.noDataWarning}
        itemProps={pickDashboardItemProps(props)}
        link={link}
        syncId='overview'
    >
        <TileContainer>
            <DelaysStatsSection stats={props.stats} />
        </TileContainer>
    </DelaysItem>
)

const link = generatePath(Link.StatusDashboardPreset, { preset: DashboardProcessType.Operations })

export const DelaysTile = connect<DelaysSelectorState>(delaysTileSelector)(DelaysTileComponent)
