import { Box, MarginBoxProps } from 'aos-ui/src/components/base/Box'
import { isEqual } from 'lodash'
import React, { ComponentType } from 'react'
import styled from 'styled-components'

interface RadioGroupProps<T> extends MarginBoxProps {
    items: T[]
    value?: T
    Renderer: ComponentType<RendererProps<T>>
    gridSize?: number
    gridGap?: number
    disabled?: boolean
    onChange(v: T): void
}

export interface RendererProps<T> {
    item: T
    isSelected: boolean
    disabled: boolean
    onSelect(): void
}

export const BlockRadioGroup = <T extends any>({
    value,
    items,
    Renderer,
    onChange,
    gridSize = items.length,
    gridGap = 8,
    disabled = false,
    ...margins
}: RadioGroupProps<T>) => (
    <RadioContainer columns={gridSize} gap={gridGap} {...margins}>
        {items.map((i, index) => (
            <Renderer
                key={index}
                item={i}
                disabled={disabled}
                onSelect={() => onChange(i)}
                isSelected={isEqual(i, value)}
            />
        ))}
    </RadioContainer>
)

const RadioContainer = styled(Box)<{ columns: number; gap: number }>`
    display: grid;
    grid-gap: ${p => p.gap}px;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
`
