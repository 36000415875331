import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { pageableForList } from 'aos-helpers/src/helpers/Pageable'
import { TimeRange } from 'aos-helpers/src/helpers/TimeRange'
import { AttachmentsState, initialAttachmentsState } from 'aos-services/src/core/attachments/state'
import { initialTimelineState, TimelineState } from 'aos-services/src/core/timeline/state'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { TaskUpdateMode } from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import { initialSimpleTaskPayload } from 'aos-services/src/services/tasks/types/payload/SimpleTaskFormPayload'
import {
    TaskFormPayload,
    TaskPayloadValidation,
    validateTaskPayload,
} from 'aos-services/src/services/tasks/types/payload/TaskFormPayload'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import { TaskMetadata } from 'aos-services/src/services/tasks/types/TaskMetadata'
import {
    initialTaskPageableFilters,
    TaskPageable,
    TaskPageableFilters,
} from 'aos-services/src/services/tasks/types/TaskPageable'
import { TaskProcessType } from 'aos-services/src/services/tasks/types/TaskProcessType'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { AnimalsReportData } from 'aos-services/src/services/tasksReporting/types/AnimalsReportData'
import { ChemicalsReportData } from 'aos-services/src/services/tasksReporting/types/ChemicalsReportData'
import {
    initialMapStateWithAdjustedZoom,
    MapState,
    withCustomizedLocation,
} from 'aos-ui-map/src/core/state'

import { initialReportsFilters, ReportsFilters } from './reports/state'

export interface TaskManagerStateAware {
    taskManager: TasksState
}

export interface TasksState {
    // timeline
    timelineTasks: TaskSnapshot[]
    timeline: TimelineState
    timelineFilters: TimelineFilterState
    loadedRange?: TimeRange
    collapsedGroups: TaskProcessType[]

    // list
    tasks: TaskPageable
    tasksFilters: TaskPageableFilters

    // details
    selectedTask?: Task
    isLoading: boolean
    isSendingMessage: boolean

    // modal
    isEditModalOpen: boolean
    isCreateModalOpen: boolean
    isPreviewModalOpen: boolean
    isTaskDurationModalOpen: boolean
    modeState?: TaskUpdateMode

    payload: TaskFormPayload
    form: FormValidation<TaskPayloadValidation>
    editMap: MapState
    attachmentsState: AttachmentsState
    metadata?: TaskMetadata

    // reporting
    chemicalsReport?: ChemicalsReportData
    animalsReport?: AnimalsReportData
    reportFilters: ReportsFilters
}

export interface TimelineFilterState {
    categories: TaskCategory[]
}

export interface ListFilterState {
    categories: TaskCategory[]
}

export const initialTaskModalState = {
    payload: initialSimpleTaskPayload,
    form: FormValidation.fromFields(validateTaskPayload(initialSimpleTaskPayload)),
    attachmentsState: initialAttachmentsState,
    editMap: {
        ...initialMapStateWithAdjustedZoom(-0.5),
        mode: MapMode.EditSingleLocation,
    },
}

export const withCustomizedMap = <T extends TasksState>(state: T, siteLocation: AosAirport) => ({
    ...state,
    editMap: withCustomizedLocation(state.editMap, siteLocation),
})

export const initialTasksState: TasksState = {
    // timeline
    timelineTasks: [],
    timeline: initialTimelineState,
    timelineFilters: {
        categories: [],
    },
    collapsedGroups: [],

    // list
    tasks: pageableForList([]),
    tasksFilters: initialTaskPageableFilters,

    // details
    isLoading: false,
    isSendingMessage: false,

    // modal
    isEditModalOpen: false,
    isCreateModalOpen: false,
    isPreviewModalOpen: false,
    isTaskDurationModalOpen: false,
    modeState: undefined,
    ...initialTaskModalState,

    // reports
    reportFilters: initialReportsFilters,
}
