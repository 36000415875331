import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Moment } from 'moment'
import moment from 'moment/moment'
import zod, { ZodError } from 'zod'

import { AosSeverity } from '../../common/types/AosSeverity'
import { AosChecklistItem } from './AosChecklistItem'
import { AosChecklistTemplate, AosSimpleTemplate } from './AosChecklistTemplate'
import { AosChecklistUnits } from './AosChecklistUnits'

export interface AosUnitTaskTemplate extends AosSimpleTemplate {
    severity: AosSeverity
    items: AosUnitTaskItem[]
    lastUsed?: DateTime
    numberOfTasks: number
    numberOfUnits: number
    status: UnitTaskStatus
}

export interface AosUnitTaskItemDTO extends AosUnitTaskItem {
    hasAdditionalNote: boolean
    createAnother: boolean
}

export enum UnitTaskStatus {
    TO_DO = 'TO_DO',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    SKIPPED = 'SKIPPED',
}

export interface AosUnitTaskItem {
    additionalNote?: string
    content: string
    eventPhase: EventPhase
    id: number
    sequenceNumber: number
    severity: AosSeverity
    unit: AosChecklistUnits
    status?: UnitTaskStatus
    modifiedAt?: string
    modifiedBy?: string
}

export interface UnitTaskGroup {
    group: EventPhase
    items: AosUnitTaskItem[]
}

export enum EventPhase {
    ALERTING = 'ALERTING',
    RESCUE_OPERATIONS = 'RESCUE_OPERATIONS',
    RECOVERY = 'RECOVERY',
}

export const eventPhaseOrder = {
    [EventPhase.ALERTING]: 1,
    [EventPhase.RESCUE_OPERATIONS]: 2,
    [EventPhase.RECOVERY]: 3,
}

export class AosUnitTaskTemplateImpl implements AosUnitTaskTemplate {
    constructor(p: AosUnitTaskTemplate) {
        this.severity = p.severity
        this.items = p.items
        this.lastUsed = moment(p.lastUsed)
        this.numberOfTasks = p.numberOfTasks
        this.numberOfUnits = p.numberOfUnits
        this.name = p.name
        this.tag = p.tag
        this.status = p.status
        this.unit = p.unit
        this.createdAt = moment(p.createdAt)
        this.createdBy = p.createdBy
        this.modifiedAt = moment(p.modifiedAt)
        this.modifiedBy = p.modifiedBy
        this.sequenceNumber = p.sequenceNumber
        this.reviewedAt = moment(p.reviewedAt)
        this.reviewedBy = p.reviewedBy
        this.id = p.id
    }

    severity: AosSeverity
    items: AosUnitTaskItem[]
    lastUsed?: Moment | undefined
    numberOfTasks: number
    numberOfUnits: number
    name: string
    tag: string | null
    status: UnitTaskStatus
    unit: AosChecklistUnits
    createdAt?: Moment | undefined
    createdBy?: string | undefined
    modifiedAt?: Moment | undefined
    modifiedBy?: string | undefined
    sequenceNumber: number
    reviewedAt?: Moment | undefined
    reviewedBy?: string | undefined
    id: number
}

export const isAosUnitTaskTemplate = (
    obj: AosChecklistTemplate | AosUnitTaskTemplate | AosSimpleTemplate | undefined,
): obj is AosUnitTaskTemplate => {
    return (obj as AosUnitTaskTemplate)?.severity !== undefined
}

export const isAosUnitTaskItem = (
    obj: AosUnitTaskItem | AosChecklistItem,
): obj is AosUnitTaskItem => {
    return (obj as AosUnitTaskItem).eventPhase !== undefined
}

export const aosUnitTaskItemSchema = zod
    .object({
        id: zod.number().optional(),
        additionalNote: zod.string().optional().nullable(),
        content: zod.string(),
        eventPhase: zod.enum([
            EventPhase.ALERTING,
            EventPhase.RECOVERY,
            EventPhase.RESCUE_OPERATIONS,
        ]),
        severity: zod.nativeEnum(AosSeverity),
        unit: zod.nativeEnum(AosChecklistUnits),
        hasAdditionalNote: zod.boolean().optional(),
        sequenceNumber: zod.number().optional(),
        createAnother: zod.boolean().optional(),
    })
    .refine(data => {
        if (data.hasAdditionalNote && !data.additionalNote) {
            throw new ZodError([
                {
                    message: translate('create-unit-task.additional-note.error'),
                    code: 'custom',
                    path: ['additionalNote'],
                },
            ])
        }

        return true
    })
