import { chemicalUsageToLabel } from 'aos-services/src/services/tasks/types/task/DeIcingChemicalUsage'
import { translateSurfaceType } from 'aos-services/src/services/tasks/types/task/DeIcingSurfaceType'
import { DeIcingTask } from 'aos-services/src/services/tasks/types/task/DeIcingTask'
import { Box } from 'aos-ui/src/components/base/Box'
import { PropsListEntry } from 'aos-ui/src/components/list/PropsList'
import React from 'react'

export const deicingTaskPropertyList = (t: DeIcingTask): PropsListEntry[] => [
    ['tasks.task-preview.surface-type', translateSurfaceType(t.surfaceType)],
    ['tasks.task-preview.temperature', t.temperature],
    ['tasks.task-preview.area', t.area?.label],
    ['tasks.task-preview.car', t.car?.label],
    [
        'tasks.task-preview.chemicals',

        t.chemicalsUsage.length ? (
            <Box>
                {t.chemicalsUsage.map((ch, index) => (
                    <Box key={index}>{chemicalUsageToLabel(ch)}</Box>
                ))}
            </Box>
        ) : null,
    ],
]
