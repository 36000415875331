import { toMapVariant } from 'aos-services/src/services/common/types/MapSiteLocationCustomization'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { toSiteLocationCustomization } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { getAirportPosition } from 'aos-services/src/services/map/mapPositions'

import {
    CLOSE_MAP,
    CUSTOMIZE_MAP,
    DESELECT_ALL_FEATURES,
    LOAD_MAP_EVENTS_SUCCESS,
    LOAD_MAP_RESTRICTIONS_SUCCESS,
    LOAD_MAP_TASKS_SUCCESS,
    MAP_POSITION_CHANGED,
    MapAction,
    NAVIGATE_TO_MAP,
    OPEN_MAP,
    RESET_PICKED_LOCATION,
    SELECT_FEATURE,
    SET_BASE_MAP_STATE,
    SWITCH_MAP,
    TOGGLE_LAYERS_SIDEBAR,
} from './actions'
import { initialMapState, MapState, updateMapPositionForVariant } from './state'

export function mapReducer(state = initialMapState, action: MapAction): MapState {
    switch (action.type) {
        case MAP_POSITION_CHANGED:
            return updateMapPositionForVariant(state, action.payload[0], action.payload[1])

        case LOAD_MAP_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload[0],
                eventsBBox: action.payload[1],
            }

        case LOAD_MAP_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload[0],
                tasksBBox: action.payload[1],
            }
        case LOAD_MAP_RESTRICTIONS_SUCCESS:
            return {
                ...state,
                restrictions: action.payload,
            }

        case SET_BASE_MAP_STATE:
            return {
                ...state,
                ...action.payload,
            }

        case CUSTOMIZE_MAP:
            return {
                ...updateMapPositionForVariant(
                    state,
                    MapVariant.World,
                    getAirportPosition(action.payload),
                ),
                siteLocationCustomization: toSiteLocationCustomization(action.payload),
                variant: toMapVariant(toSiteLocationCustomization(action.payload)),
                selectedElement: null,
                navigatedElement: null,
            }

        case SWITCH_MAP:
            return {
                ...state,
                variant: action.payload,
                selectedElement: null,
                navigatedElement: null,
            }

        case SELECT_FEATURE:
            return {
                ...state,
                selectedElement: action.payload,
                navigatedElement: null,
            }

        case DESELECT_ALL_FEATURES:
            return {
                ...state,
                selectedElement: null,
                navigatedElement: null,
            }

        case RESET_PICKED_LOCATION:
            return {
                ...state,
                pickedEvent: null,
                pickedTask: null,
            }

        case TOGGLE_LAYERS_SIDEBAR:
            return {
                ...state,
                layersSidebarOpen: action.payload,
            }

        case NAVIGATE_TO_MAP:
            return {
                ...state,
                layersSidebarOpen: false,
            }

        case CLOSE_MAP:
            return {
                ...state,
                layersSidebarOpen: false,
            }

        case OPEN_MAP:
            return {
                ...state,
                layersSidebarOpen: false,
            }

        default:
            return state
    }
}
