import {
    ChecklistTemplatePayload,
    emptyChecklistTemplatePayload,
} from 'aos-services/src/services/checklists/types/input/CheclistTemplatePayload'
import { connect } from 'react-redux'

import {
    createChecklistTemplateAction,
    hideCreateChecklistTemplateModalAction,
} from '../../../../../core/checklistManager/event/actions'
import { getExistingTags } from '../../../../../core/checklistManager/selectors'
import { State } from '../../../../../core/state'
import {
    ChecklistTemplateModal,
    ChecklistTemplateModalDispatchProps,
    ChecklistTemplateModalStateProps,
} from './ChecklistTemplateModal'

export const AddChecklistTemplateModal = connect<
    ChecklistTemplateModalStateProps<ChecklistTemplatePayload>,
    ChecklistTemplateModalDispatchProps
>(
    (state: State) => ({
        isOpen: state.checklistManager.eventChecklistManager.isCreateChecklistTemplateModalOpen,
        initialFormState: emptyChecklistTemplatePayload,
        existingTags: getExistingTags(
            state.checklistManager.eventChecklistManager.checklistTemplates,
        ),
        keyPrefix: 'create-checklist-template',
        submitAction: createChecklistTemplateAction,
        hideAction: hideCreateChecklistTemplateModalAction,
    }),
    dispatch => ({
        dispatch,
    }),
)(ChecklistTemplateModal)
