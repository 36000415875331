import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskCategory } from 'aos-services/src/services/tasks/types/TaskCategory'
import {
    TaskStatus,
    taskStatuses,
    translateTaskTitle,
} from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { OkButton } from 'aos-ui/src/components/buttons/OkButton'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { More, MoreItem } from 'aos-ui/src/components/list/More'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { InfoBanner } from 'aos-ui/src/components/ui/InfoBanner'
import { Color } from 'aos-ui-common/src/styles/Color'
import { TaskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import copyToClipboard from 'copy-to-clipboard'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { exportTaskLogsAction } from '../../../core/tasks/actions'
import { TaskDetailsChecklist } from './TaskDetailsChecklist'
import { TaskDetailsMessages } from './TaskDetailsMessages'
import { TaskDetailsPropsListContainer } from './TaskDetailsPropsListContainer'

interface TaskDetailsProps {
    url: string
    task: Task
    isSendingMessage: boolean
    canEdit: boolean
    openLocation(): void
    onEdit(): void
    onDelete(): void
    onClose(): void
    onClone(): void
    onMessage(v: string): void
    onStatusChange(status: TaskStatus): void
    onChecklistItemChange(checklistId: number, itemId: number, value: boolean): void
}

export const TaskDetails: FC<TaskDetailsProps> = ({
    task,
    isSendingMessage,
    url,
    canEdit,
    openLocation,
    onEdit,
    onClone,
    onDelete,
    onClose,
    onMessage,
    onStatusChange,
    onChecklistItemChange,
}) => {
    const statuses = taskStatuses
        .filter(status => status !== task.status)
        .filter(status =>
            task.category === TaskCategory.LVP ? status !== TaskStatus.OnHold : true,
        )

    const dispatch = useDispatch()
    const exportTaskLogs = () => {
        if (task.id !== undefined) {
            dispatch(exportTaskLogsAction(task.id))
        }
    }

    return (
        <Box fullHeight column>
            <Header paddingHorizontal={30} paddingVertical={16} row>
                <Box flex={1} row>
                    <TaskIcon category={task.category} iconVariant={IconVariant.White} />
                    <Text size={16} color={Color.White} paddingLeft={16}>
                        {task.title}
                    </Text>
                    {task.parentTaskId && (
                        <Box paddingHorizontal={16}>
                            <Icon
                                svg={SvgIcon.TaskRecurrence}
                                iconVariant={IconVariant.White}
                                iconSize={BlockSize.Std}
                            />
                        </Box>
                    )}
                </Box>
                <Box row>
                    {canEdit ? (
                        <>
                            <More
                                trigger={
                                    <FormButton
                                        label={
                                            <Box row>
                                                {translateTaskTitle(task.status)}
                                                <Icon
                                                    svg={SvgIcon.Chevron}
                                                    iconVariant={IconVariant.White}
                                                />
                                            </Box>
                                        }
                                    />
                                }
                            >
                                {statuses.map(status => (
                                    <MoreItem key={status} onClick={() => onStatusChange(status)}>
                                        {translateTaskTitle(status)}
                                    </MoreItem>
                                ))}
                            </More>
                            <More marginLeft={16}>
                                <MoreItem onClick={onEdit}>{translate('tasks.edit-task')}</MoreItem>
                                <MoreItem onClick={onClone}>
                                    {translate('tasks.clone-task')}
                                </MoreItem>
                                <MoreItem onClick={exportTaskLogs}>
                                    {translate('tasks.export-messages')}
                                </MoreItem>
                                <MoreItem onClick={onDelete}>
                                    {translate('tasks.delete-task')}
                                </MoreItem>
                                <MoreItem onClick={onClose}>
                                    {translate('tasks.close-task')}
                                </MoreItem>
                            </More>
                        </>
                    ) : (
                        <FormButton label={translateTaskTitle(task.status)} disabled />
                    )}
                </Box>
            </Header>
            <Box flex={1}>
                <ResizableSplittedPanel
                    fullSize
                    flex={1}
                    initialRightSizes={[50, 50]}
                    leftMinSizes={[30]}
                    rightMinSizes={[30]}
                >
                    <Box column fullHeight>
                        <TaskDetailsPropsListContainer task={task} openLocation={openLocation} />
                        {task.deleted && (
                            <InfoBanner
                                title={translate('tasks.deleted.title')}
                                message={translate('tasks.deleted.message')}
                            >
                                <OkButton
                                    fullWidth
                                    variant={FormButtonVariant.WhiteOutlined}
                                    label={translate('tasks.deleted.copy')}
                                    onClick={() => copyToClipboard(url)}
                                />
                            </InfoBanner>
                        )}
                    </Box>
                    {task.checklists && task.checklists.length > 0 && (
                        <DarkScrollbar>
                            <Box paddingHorizontal={16} column fullHeight>
                                <TaskDetailsChecklist
                                    onChecklistItemChange={onChecklistItemChange}
                                    checklists={task.checklists}
                                />
                            </Box>
                        </DarkScrollbar>
                    )}
                    <TaskDetailsMessages
                        isSendingMessage={isSendingMessage}
                        logs={task.log}
                        onMessage={onMessage}
                    />
                </ResizableSplittedPanel>
            </Box>
        </Box>
    )
}

const Header = styled(Box)`
    border-bottom: 1px solid ${Color.UiBlack3};
`
