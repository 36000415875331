import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    OperationalForecastGroupItems,
    translateOperationalForecastGroup,
} from 'aos-services/src/services/operationalForecast/types/OperationalForecast'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { DarkScrollableList } from 'aos-ui/src/components/list/DarkScrollableList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, Fragment } from 'react'

import { OperationalForecastListItem } from './OperationalForecastListItem'
import { OperationalForecastTileIconItem } from './OperationalForecastTileItem'

interface OperationalForecastListProps {
    items: OperationalForecastGroupItems[]
}

export const OperationalForecastList: FC<OperationalForecastListProps> = ({ items }) => {
    const iconSize = BlockSize.Small
    const paddingLeft = 12

    const renderBlock = ({ group, mode, data }: OperationalForecastGroupItems) => (
        <Fragment key={group}>
            <Box row paddingBottom={20}>
                <Box width={2 * iconSize}>
                    <OperationalForecastTileIconItem group={group} mode={mode} size='small' />
                </Box>
                <Text color={Color.White} size={18} paddingLeft={paddingLeft}>
                    {translateOperationalForecastGroup(group)}
                </Text>
            </Box>
            {data.map((i, index) => (
                <OperationalForecastListItem item={i} key={index} />
            ))}
        </Fragment>
    )

    return (
        <DarkScrollableList paddingTop={20} paddingHorizontal={20}>
            {items.map(renderBlock)}
        </DarkScrollableList>
    )
}
