import { DeIcingChemicals } from '../../tasks/types/task/DeIcingChemicals'
import { ChemicalsUsage } from './ChemicalsUsage'

export interface ChemicalsReportData {
    data: ChemicalsUsage[]
    types: DeIcingChemicals[]
}

export const emptyChemicalsReportData: ChemicalsReportData = {
    data: [],
    types: [],
}
