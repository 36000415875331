import Icon, { Options as IconOptions } from 'ol/style/Icon'
import Style from 'ol/style/Style'

import { markerSize } from '../icons/EventMapIcon'

const defaultMarkerIconStyle: Partial<IconOptions> = {
    anchor: [markerSize / 2, markerSize],
    anchorXUnits: 'pixels',
    anchorYUnits: 'pixels',
    opacity: 1,
    scale: 1,
    size: [markerSize, markerSize],
}

export const getMapMarkerIconStyleForUrl = (src: string) =>
    new Style({
        image: new Icon({
            ...defaultMarkerIconStyle,
            src,
        }),
    })
