import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'

import { SimpleFormModalState, ValueWrapper } from '../simpleForm/state'

export interface SingleInputModalState<P = any>
    extends SimpleFormModalState<string, SingleItemModalFormValidation, P> {}

export type StringValueWrapper = ValueWrapper<string, any>

export const emptyValueWrapper: StringValueWrapper = {}

export interface SingleItemModalFormValidation {
    value: boolean
}

export const validateValueWrapper = (e: StringValueWrapper): SingleItemModalFormValidation => ({
    value: isNotEmpty(e.value),
})

export const createInitialSingleInputModalState = (): SingleInputModalState => ({
    isOpen: false,
    currentValue: emptyValueWrapper,
    form: FormValidation.fromFields(validateValueWrapper(emptyValueWrapper)),
})
