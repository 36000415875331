import { Departures } from 'aos-services/src/services/flightInformation/types/ArrivalsAndDepartures'
import { Restriction } from 'aos-services/src/services/restrictions/types/Restriction'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
    flightMapDeparturesSelector,
    restrictionMapSelector,
} from '../../../core/airportMap/selectors'
import { SidebarOwnProps } from '../../../core/airportMap/state'
import { AirportResourceSidebar } from '../../sidebar/AirportResourceSidebar'

interface BusGateDetailsSidebarProps extends Departures {
    restriction?: Restriction
}

export const BusGateDetailsSidebar = connect<BusGateDetailsSidebarProps, {}, SidebarOwnProps>(
    createStructuredSelector({
        departures: flightMapDeparturesSelector,
        restriction: restrictionMapSelector,
    }),
)(AirportResourceSidebar)
