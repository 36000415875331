import { singleInputModalReducer } from 'aos-components/src/components/modal/InputModal/core/singleInput/reducer'
import { arrayMove } from 'aos-helpers/src/helpers/Array'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import {
    AosChecklistTemplate,
    AosSimpleTemplate,
} from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import {
    AosUnitTaskItem,
    AosUnitTaskTemplate,
} from 'aos-services/src/services/checklists/types/AosUnitTaskTemplate'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { cloneDeep, findIndex } from 'lodash'

import {
    CHANGE_ITEM_SEQ,
    ChecklistManagerAction,
    CREATE_TASK_OR_DESCRIPTION,
    EDIT_MESSAGE_TEMPLATE_PARENT,
    EDIT_TASK_OR_DESCRIPTION_PARENT,
    SET_CHECKLIST_FILTERING,
} from './actions'
import { taskOrDescriptionReducer } from './addTaskOrDescription/reducer'
import { messageTemplateReducer } from './editMessageTemplate/reducer'
import {
    EventChecklistManagerActions,
    LOAD_CHECKLIST_TEMPLATE_SUCCESS,
    LOAD_CHECKLIST_TEMPLATES_SUCCESS,
    SELECT_CHECKLIST_TEMPLATE,
    SET_EXPORTING_CHECKLIST_TEMPLATES,
} from './event/actions'
import { eventChecklistReducer } from './event/reducer'
import { ChecklistManagerState, initialChecklistManagerState } from './state'
import {
    LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS,
    LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS,
    SELECT_TASK_CHECKLIST_TEMPLATE,
    TaskChecklistManagerActions,
} from './task/actions'
import { taskChecklistReducer } from './task/reducer'
import {
    LOAD_UNIT_TASK_TEMPLATE_SUCCESS,
    LOAD_UNIT_TASK_TEMPLATES_SUCCESS,
    SELECT_UNIT_TASK_TEMPLATE,
    UnitTaskManagerActions,
} from './unit/actions'
import { unitTaskChecklistReducer } from './unit/reducer'

export function checklistManagerReducer(
    state: ChecklistManagerState = initialChecklistManagerState,
    action: ChecklistManagerAction,
): ChecklistManagerState {
    switch (action.type) {
        case LOAD_UNIT_TASK_TEMPLATES_SUCCESS:
        case LOAD_CHECKLIST_TEMPLATES_SUCCESS:
        case LOAD_TASK_CHECKLIST_TEMPLATES_SUCCESS:
            return {
                ...initialChecklistManagerState,
                unitTaskManager: unitTaskChecklistReducer(
                    state.unitTaskManager,
                    action as UnitTaskManagerActions,
                ),
                eventChecklistManager: eventChecklistReducer(
                    state.eventChecklistManager,
                    action as EventChecklistManagerActions,
                ),
                taskChecklistManager: taskChecklistReducer(
                    state.taskChecklistManager,
                    action as TaskChecklistManagerActions,
                ),
                selectedTemplate: (
                    action.payload as (AosChecklistTemplate | AosUnitTaskTemplate)[]
                ).find(q => q.id === state.selectedTemplate?.id),
                selectedItemFromTemplate: (action.payload as AosSimpleTemplate[]).find(
                    q => q.id === state.selectedTemplate?.id,
                ),
                headerFilter: state.headerFilter,
            }

        case SET_CHECKLIST_FILTERING:
            return {
                ...state,
                selectedTemplate: undefined,
                headerFilter: action.payload,
            }

        case SELECT_CHECKLIST_TEMPLATE:
        case SELECT_TASK_CHECKLIST_TEMPLATE:
        case SELECT_UNIT_TASK_TEMPLATE:
            return {
                ...state,
                selectedTemplate: undefined,
                selectedItemFromTemplate: action.payload,
            }

        case LOAD_CHECKLIST_TEMPLATE_SUCCESS:
        case LOAD_UNIT_TASK_TEMPLATE_SUCCESS:
        case LOAD_TASK_CHECKLIST_TEMPLATE_SUCCESS:
            return {
                ...state,
                selectedTemplate: action.payload,
            }

        case CHANGE_ITEM_SEQ:
            return {
                ...state,
                selectedTemplate: state.selectedTemplate
                    ? reorderTasks(state.selectedTemplate, action.payload.vector)
                    : undefined,
            }

        case EDIT_TASK_OR_DESCRIPTION_PARENT:
            return {
                ...state,
                editTaskOrDescriptionModal: singleInputModalReducer(
                    state.editTaskOrDescriptionModal,
                    action.payload,
                ),
            }

        case EDIT_MESSAGE_TEMPLATE_PARENT:
            return {
                ...state,
                editMessageTemplateModal: messageTemplateReducer(
                    state.editMessageTemplateModal,
                    action.payload,
                ),
            }

        case CREATE_TASK_OR_DESCRIPTION:
            return {
                ...state,
                createTaskOrDescriptionModal: taskOrDescriptionReducer(
                    state.createTaskOrDescriptionModal,
                    action.payload,
                ),
            }
        case SET_EXPORTING_CHECKLIST_TEMPLATES:
            return {
                ...state,
                exportingChecklistTemplates: action.payload,
            }

        default:
            return {
                ...state,
                unitTaskManager: unitTaskChecklistReducer(
                    state.unitTaskManager,
                    action as UnitTaskManagerActions,
                ),
                eventChecklistManager: eventChecklistReducer(
                    state.eventChecklistManager,
                    action as EventChecklistManagerActions,
                ),
                taskChecklistManager: taskChecklistReducer(
                    state.taskChecklistManager,
                    action as TaskChecklistManagerActions,
                ),
            }
    }
}

function reorderTasks(
    checklistTemplate: AosChecklistTemplate | AosUnitTaskTemplate | AosTaskChecklistTemplate,
    changeSeqVector: ChangeSeqVector,
) {
    const clonedChecklist = cloneDeep(checklistTemplate)
    const fromIndex = findIndex<AosUnitTaskItem | AosChecklistItem>(
        clonedChecklist.items,
        t => t.id === changeSeqVector.fromId,
    )
    const toIndex = findIndex<AosUnitTaskItem | AosChecklistItem>(
        clonedChecklist.items,
        t => t.id === changeSeqVector.toId,
    )
    clonedChecklist.items = arrayMove<AosUnitTaskItem | AosChecklistItem>(
        clonedChecklist.items,
        fromIndex,
        toIndex,
    ) as AosUnitTaskItem[] | AosChecklistItem[]
    return clonedChecklist
}
