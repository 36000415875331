import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { Pageable, pageableForList } from 'aos-helpers/src/helpers/Pageable'
import { sendToSyncRepository } from 'aos-services/src/dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from 'aos-services/src/dataaccess/sts/types/SendToSyncType'
import { eventsService } from 'aos-services/src/services/events/eventsService'
import { feedsService } from 'aos-services/src/services/events/feedsService'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { firebaseService } from 'aos-services/src/services/firebase/firebaseService'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { assureCommonUserGroup } from '../commonFrontend/sagas'
import {
    DISMISS_EVENT,
    DismissEventAction,
    LOAD_EVENTS,
    LOAD_FEED_IN,
    LOAD_OVERDUE_EVENTS_COUNT,
    LoadEventsAction,
    loadEventsAction,
    loadEventsSuccessAction,
    LoadFeedInAction,
    loadFeedInAction,
    loadFeedInSuccessAction,
    loadOverdueEventsCountAction,
    loadOverdueEventsCountSuccessAction,
    RELOAD_EVENTS_LIST,
    RELOAD_FEED_IN_LIST,
    reloadEventsListAction,
    reloadFeedInListAction,
    SET_EVENTS_FILTERING,
    SET_EVENTS_SORTING,
    SET_FEED_IN_FILTERING,
    SYNC_EVENT_MANAGER,
    SyncEventManagerAction,
} from './actions'
import { EventManagerStateAware } from './state'

function* currentFeedInListRequest() {
    return (yield select((state: EventManagerStateAware) => ({
        filter: state.eventManager.feedInFilter,
        pageRequest: {
            page: state.eventManager.feedInEvents.number,
            size: state.eventManager.feedInEvents.size,
        },
    }))) as Generator
}

function* currentEventsListRequest() {
    return (yield select((state: EventManagerStateAware) => ({
        filter: state.eventManager.eventsFilter,
        sorting: state.eventManager.eventsSorting,
        pageRequest: {
            page: state.eventManager.events.number,
            size: state.eventManager.events.size,
        },
    }))) as Generator
}

function* reloadFeedInList() {
    const { pageRequest } = yield currentFeedInListRequest()
    yield put(loadFeedInAction(pageRequest))
}

function* reloadEventsList() {
    const { pageRequest } = yield currentEventsListRequest()
    yield put(loadEventsAction(pageRequest))
    yield put(loadOverdueEventsCountAction())
}

function* loadFeedInSaga() {
    yield takeLatest<LoadFeedInAction>(LOAD_FEED_IN, function* (action: LoadFeedInAction) {
        try {
            yield assureCommonUserGroup()
            const { filter, pageRequest } = yield currentFeedInListRequest()
            const events: Pageable<AosFeedIn> = yield call(
                feedsService.loadFeeds,
                { ...pageRequest, ...action.payload },
                filter,
            )
            yield put(loadFeedInSuccessAction(events))
        } catch (e) {
            yield put(loadFeedInSuccessAction(pageableForList([])))
            throw e
        }
    })
}

function* loadEventsSaga() {
    yield takeLatest<LoadEventsAction>(LOAD_EVENTS, function* (action: LoadEventsAction) {
        try {
            const { filter, sorting, pageRequest } = yield currentEventsListRequest()
            const events: Pageable<AosFeedIn> = yield call(
                eventsService.loadEvents,
                { ...pageRequest, ...action.payload },
                filter,
                sorting,
            )
            yield put(loadEventsSuccessAction(events))
        } catch (e) {
            yield put(loadEventsSuccessAction(pageableForList([])))
            throw e
        }
    })
}

function* loadOverdueEventsCountSaga() {
    yield takeEvery(LOAD_OVERDUE_EVENTS_COUNT, function* () {
        const overdueEventsCount: number = yield call(eventsService.loadOverdueEventsCount)
        yield put(loadOverdueEventsCountSuccessAction(overdueEventsCount))
    })
}

function* dismissEventSaga() {
    yield takeLatest<DismissEventAction>(DISMISS_EVENT, function* (action: DismissEventAction) {
        try {
            yield call(feedsService.dismissFeed, action.payload.id)
        } catch (e) {
            yield put(loadFeedInSuccessAction(pageableForList([])))
            throw e
        }
    })
}

function* reloadEventListSaga() {
    yield takeEvery(RELOAD_EVENTS_LIST, reloadEventsList)
}

function* reloadFeedInListSaga() {
    yield takeEvery(RELOAD_FEED_IN_LIST, reloadFeedInList)
}

function* setEventsFilteringSaga() {
    yield takeEvery([SET_EVENTS_FILTERING, SET_EVENTS_SORTING], function* () {
        yield put(loadEventsAction({ page: 0 }))
    })
}

function* setFeedInFilteringSaga() {
    yield takeEvery(SET_FEED_IN_FILTERING, function* () {
        yield put(loadFeedInAction({ page: 0 }))
    })
}

// sync
function* syncEvents() {
    yield syncValue(
        sendToSyncRepository.stsRef(SendToSyncType.EVENT_MANAGER_EVENTS),
        reloadEventsListAction,
        true,
    )
}

function* syncFeedIns() {
    yield syncValue(
        sendToSyncRepository.stsRef(SendToSyncType.EVENT_MANAGER_FEEDS),
        reloadFeedInListAction,
        true,
    )
}

function* syncEventManagerSaga() {
    yield takeEvery<SyncEventManagerAction>(
        SYNC_EVENT_MANAGER,
        function* (action: SyncEventManagerAction) {
            if (action.payload) {
                yield* firebaseService.onListener('eventManagerEvents', syncEvents) as Generator
                yield* firebaseService.onListener('eventManagerFeeds', syncFeedIns) as Generator
            } else {
                yield firebaseService.offListener('eventManagerEvents')
                yield firebaseService.offListener('eventManagerFeeds')
            }
        },
    )
}

export const eventManagerSagas = [
    loadFeedInSaga,
    loadEventsSaga,
    loadOverdueEventsCountSaga,
    dismissEventSaga,
    reloadEventListSaga,
    reloadFeedInListSaga,
    syncEventManagerSaga,
    setEventsFilteringSaga,
    setFeedInFilteringSaga,
]
