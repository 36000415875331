import { mapValues } from 'lodash'

import { cdmRestService } from '../../../dataaccess/cdm/cdmRestService'
import { CdmMetricDto } from '../../../dataaccess/cdm/types/CdmMetricDto'
import { CdmMetricPointDto } from '../../../dataaccess/cdm/types/CdmMetricPointDto'
import { CdmMetricsDto } from '../../../dataaccess/cdm/types/CdmMetricsDto'
import { CdmMetric } from './types/CdmMetric'
import { CdmMetricPoint } from './types/CdmMetricPoint'
import { CdmMetricPointImpl } from './types/CdmMetricPointImpl'
import { CdmMetrics } from './types/CdmMetrics'
import { TobtList } from './types/TobtTypes'

export class CdmService {
    public getCdmMetrics = (): Promise<CdmMetrics> =>
        cdmRestService.getCdmMetrics().then(this.metricsDtoToMetrics)

    private metricsDtoToMetrics = (dto: CdmMetricsDto): CdmMetrics =>
        mapValues(dto, this.metricDtoToMetric) as unknown as CdmMetrics

    private metricDtoToMetric = (dto: CdmMetricDto): CdmMetric =>
        ({
            ...mapValues(dto.timeMetric, this.mapPoint),
            top5: this.mapPoint(dto.top5),
        } as unknown as CdmMetric)

    private mapPoint = (point: CdmMetricPointDto | null): CdmMetricPoint | null => {
        if (point) {
            return new CdmMetricPointImpl(point)
        }
        return null
    }

    public getTobtList = (): Promise<TobtList> => cdmRestService.getTobtList()
}

export const cdmService = new CdmService()
