import { Box } from 'aos-ui/src/components/base/Box'
import { Children } from 'react'
import styled from 'styled-components'

export const ColumnsGrid = styled(Box)<{
    compact?: boolean
    alignGridItems?: 'start' | 'end' | 'center'
}>`
    height: 100%;
    display: grid;
    grid-template-columns: repeat(
        ${p => Children.toArray(p.children).length},
        ${p => (p.compact ? 'max-content' : '1fr')}
    );
    align-items: ${p => p.alignGridItems || 'end'};
`
