import { toString } from 'lodash'
import React, { FCWithChildren } from 'react'

import { DropdownWidth } from './base/DropdownWidth'
import { Dropdown } from './Dropdown'

interface PageSizeDropdownProps {
    value: number
    pageSizes?: number[]
    onChange(v: number): void
}

export const PageSizeDropdown: FCWithChildren<PageSizeDropdownProps> = ({
    pageSizes = [10, 20, 40],
    value,
    onChange,
}) => (
    <Dropdown
        value={value}
        items={pageSizes}
        valueRenderer={toString}
        onChange={onChange}
        width={DropdownWidth.Small}
        small
    />
)
