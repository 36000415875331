import { translateEnum } from '../translations/Translations'

export enum Trend {
    Increasing = 'Increasing',
    Decreasing = 'Decreasing',
    Constant = 'Constant',
}

export const translateTrend = (prefix: string) => translateEnum<Trend>(Trend, prefix)

export const getTrend = (current: number, prev: number) => {
    if (current === prev) {
        return Trend.Constant
    } else {
        return current > prev ? Trend.Increasing : Trend.Decreasing
    }
}

export interface TrendData<T> {
    current: T[]
    previous: T[]
}

export const computeTrend = <T>(trendData: TrendData<T>, valueGetter: f.Func1<T[], number>) =>
    getTrend(valueGetter(trendData.current), valueGetter(trendData.previous))
