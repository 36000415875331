import { translate, translateParts } from './translations/Translations'

interface ModalContentProps {
    title: string
    description?: string
    okLabel: string
}

export function defaultOkLabel() {
    return translate('modal.ok')
}

export function modalContentFromKeyPrefix(prefix: string): ModalContentProps {
    const okPrefix = `${prefix}.okLabel`
    const okLabel = translate(okPrefix) === okPrefix ? defaultOkLabel() : translate(okPrefix)
    return {
        title: translateParts(prefix, 'title'),
        description: translateParts(prefix, 'description'),
        okLabel,
    }
}
