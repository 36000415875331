export class TextMeasurer {
    public static Instance = new TextMeasurer()
    private canvas: HTMLCanvasElement = document.createElement('canvas')
    private ctx: CanvasRenderingContext2D = this.canvas.getContext('2d')!

    public measure = (text: string, fontSize: number, fontFamily: string) => {
        this.ctx.font = `${fontSize}px ${fontFamily}`
        return this.ctx.measureText(text).width
    }
}
