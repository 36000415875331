import { BaseCalendarTimelineItem } from 'aos-ui/src/components/timeline/types/BaseCalendarTimelineItem'
import { Moment } from 'moment'
import moment from 'moment/moment'

import { AosAirport } from './AosAirport'

export interface Notam {
    createdAt: string
    endTime: Moment
    notamType: NotamType
    number: string
    siteLocation: AosAirport
    startTime: Moment
    text: string
}

export interface Snowtam {
    createdAt: string
    endTime: Moment
    number: string
    siteLocation: AosAirport
    startTime: Moment
    situationalAwareness: string
    aeroplanePerformance: string
}

export interface NotamTimelineItem extends BaseCalendarTimelineItem {
    payload: Notam | Snowtam
}

export enum NotamType {
    NOTAMN = 'NOTAMN',
    SNOWTAM = 'SNOWTAM',
}

export const isSnowtam = (item: Snowtam | Notam): item is Snowtam => {
    return (item as Snowtam).situationalAwareness !== undefined
}

export const endsIn8Hours = (item: Notam | Snowtam) => {
    const endTimeFromNow = moment(item.endTime).utc().diff(moment().utc(), 'minutes')
    return endTimeFromNow > 0 && endTimeFromNow < 60 * 8
}
