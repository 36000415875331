import { CheckboxItem } from 'aos-helpers/src/helpers/Checkbox'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { GreyTextButton } from 'aos-ui/src/components/buttons/GreyTextButton'
import { CheckboxList } from 'aos-ui/src/components/form/checkbox/CheckboxList'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface FixedHeightListProps {
    groups: AosUserGroup[]
    selectedGroupsIds: number[]
    changeGroups(v: number[]): void
    selectAllGroups(): void
    deselectAllGroups(): void
}

export const GroupsCheckList: FC<FixedHeightListProps> = ({
    groups,
    selectedGroupsIds,
    changeGroups,
    selectAllGroups,
    deselectAllGroups,
}) => {
    const groupsWithSelection = groups.map(group => {
        const selected = selectedGroupsIds.includes(group.id)
        return [group, selected] as CheckboxItem<AosUserGroup>
    })

    return (
        <Box className='container container--vertical full-height'>
            <Box paddingBottom={16}>
                <Text color={Color.UiBlack1} size={18}>
                    {`${translate('manage-user.groups.label')} (${groupsWithSelection.length})`}
                </Text>
            </Box>
            <Box
                flex={1}
                paddingHorizontal={12}
                bg={Color.White}
                border={Color.Grey2}
                rounded
                fullWidth
            >
                <CheckboxList<AosUserGroup>
                    items={groupsWithSelection}
                    itemToLabel={group => group.name}
                    onChange={newGroups =>
                        changeGroups(newGroups.filter(group => group[1]).map(group => group[0].id))
                    }
                />
            </Box>
            <Box row>
                <Box>
                    <GreyTextButton
                        key='1'
                        label={translate('manage-user.groups.select-all')}
                        onClick={selectAllGroups}
                        bare
                    />
                </Box>
                <Box marginLeft={20}>
                    <GreyTextButton
                        key='2'
                        label={translate('manage-user.groups.deselect-all')}
                        onClick={deselectAllGroups}
                        bare
                    />
                </Box>
            </Box>
        </Box>
    )
}
