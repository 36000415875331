import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    delaysSelector,
    DelaysSelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/delays'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { CommonFlightView } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { ExtendedDelayFilters } from 'aos-services/src/services/statusDashboard/types/filters/DelaysFilters'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setDelaysTabStateAction } from '../../../../core/statusDashboard/actions'
import { DelaysItemState } from '../../../../services/statusDashboard/types/itemStates/DelaysItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { FlightChartStatsWrapper } from '../base/FlightChartStatsWrapper'
import { FlightFooterFilters } from '../base/FlightFooterFilters'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { DelaysChart } from './DelaysChart'
import { delaysFilterConfigs } from './delaysFilterConfig'
import { DelaysFlights } from './DelaysFlights'
import { DelaysItem } from './partials/DelaysItem'
import { DelaysStatsSection } from './partials/DelaysStatsSection'

class DelaysClass extends PureComponent<
    DelaysSelectorState & DelaysOwnProps & DelaysDispatchProps
> {
    public render() {
        const { itemState, noDataWarning } = this.props
        const isSmall = itemState.size === DashboardItemSizeVariant.Small
        return (
            <DelaysItem
                itemProps={pickDashboardItemProps(this.props)}
                noDataWarning={noDataWarning}
                syncId={this.props.id}
            >
                {isSmall ? this.renderSmallVariant() : this.renderBigVariant()}
                <FlightFooterFilters
                    timeRange={itemState.timeRange}
                    airline={itemState.airline}
                    handlingAgent={itemState.handlingAgent}
                    flightType={itemState.flightType}
                    fullViewTab={itemState.fullViewTab}
                    setFilterAction={this.handleSetFilter}
                    isSmall={isSmall}
                    tabConfiguration={delaysFilterConfigs}
                />
            </DelaysItem>
        )
    }

    private renderSmallVariant = () => (
        <ItemContainer verticalSpacing horizontalSpacing autoHeight horizontal>
            {this.renderStatsContent()}
        </ItemContainer>
    )

    private renderBigVariant() {
        const { flights, itemState } = this.props
        return (
            <FlightChartStatsWrapper
                chart={this.renderChart()}
                statsContent={this.renderStatsContent()}
                flightTable={() => <DelaysFlights flights={flights} />}
                flightTableTitle={translate('dashboard.punctuality.flight-table-header')}
                showChart={itemState.fullViewTab === CommonFlightView.History}
            />
        )
    }

    private renderChart() {
        return (
            <DelaysChart
                flights={this.props.series}
                yDomain={this.props.yDomain}
                xDomain={this.props.xDomain}
                timeRangeFilter={this.props.itemState.timeRange}
            />
        )
    }

    private handleSetFilter = (f: Partial<ExtendedDelayFilters>) => {
        this.props.setDelaysTabStateAction([this.props.id, f])
    }

    private renderStatsContent() {
        return <DelaysStatsSection stats={this.props.stats} />
    }
}

interface DelaysDispatchProps {
    setDelaysTabStateAction: typeof setDelaysTabStateAction
}

type DelaysOwnProps = StatusDashboardItemCommonProps<DelaysItemState>

export const Delays = connect<DelaysSelectorState, DelaysDispatchProps, DelaysOwnProps>(
    delaysSelector,
    {
        setDelaysTabStateAction,
    },
)(DelaysClass)
