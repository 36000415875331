export enum AlertType {
    LVP_COUNTER = 'LVP_COUNTER',
    LVP = 'LVP',
    WA = 'WA',
}

export type AlertsStateDto = {
    [AlertType.LVP_COUNTER]: AlertItemLVPCounterDto
    [AlertType.WA]: AlertItemDto
    [AlertType.LVP]: AlertItemDto
}

export interface AlertItemDto {
    enabled: boolean
    comment: string
    reporter: string
}

export interface AlertItemLVPCounterDto {
    enabled: boolean
    endTime: string
    taskId: number
}
