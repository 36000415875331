import { getTobtValue } from 'aos-helpers/src/helpers/tobt/mappers'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { tobtWebDataSelector } from 'aos-services/src/core/statusDashboardData/cdm/selectors'
import { AirlineTobtList } from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CdmGaugeTobt from './CdmGaugeTobt'

interface Props {
    airlineTobt: AirlineTobtList
    tobtRanges: GaugeRange[]
}

const CdmGaugesTobtList = (props: Props) => {
    const { airlineTobt, tobtRanges } = props

    return (
        <div>
            <GaugeWrapper>
                <CdmGaugeTobt
                    ranges={tobtRanges}
                    tobtValue={getTobtValue(airlineTobt)}
                    description={translate('dashboard.tobt.gauge.too-late')}
                    title={translate('dashboard.tobt.gauge.tobt-updates')}
                    tooltip={translate('dashboard.tobt.gauge.tobt-updates-tooltip')}
                />
            </GaugeWrapper>
        </div>
    )
}

export default connect(tobtWebDataSelector)(CdmGaugesTobtList)

const GaugeWrapper = styled.div`
    height: 180px;
    padding: 5px 10px;
`
