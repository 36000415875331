import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { LegendItem } from '../../ui/LegendItem'
import { TrendMeasure, TrendProps } from './TrendMeasure'

interface TrendLegendItemProps extends TrendProps {
    labelColor: Color
}

export const TrendLegendItem: FCWithChildren<TrendLegendItemProps> = ({
    trend,
    label,
    labelColor,
    trendVariant,
    value,
    unit,
    subvalue,
    subunit,
    ...marginProps
}) => (
    <TrendMeasure
        value={value}
        unit={unit}
        subvalue={subvalue}
        subunit={subunit}
        trendVariant={trendVariant}
        trend={trend}
        label={
            <LegendItem
                marginTop={subvalue !== undefined ? 8 : 0}
                dotConfig={{ size: BlockSize.Tiny, mainColor: labelColor }}
            >
                {label}
            </LegendItem>
        }
        {...marginProps}
    />
)
