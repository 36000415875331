import { useEffect, useRef } from 'react'

export const useInterval = (callback: f.Action, delay: number | null) => {
    const savedCallback = useRef<f.Action>()

    useEffect(() => {
        savedCallback.current = callback
    })

    useEffect(() => {
        const tick = () => savedCallback.current!()

        if (delay === null) {
            return
        }

        const id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay])
}
