import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { BaseUserProps } from 'aos-services/src/services/users/types/AosUser'
import { AosUserAddStatus } from 'aos-services/src/services/users/types/AosUserFindStatus'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

export const SHOW_USER_MODAL = 'MANAGE_USER/SHOW_USER_MODAL'
export type ShowUserModalAction = Action<typeof SHOW_USER_MODAL>
export const showUserModalAction = emptyActionCreator<ShowUserModalAction>(SHOW_USER_MODAL)

export const SHOW_USER_EMPTY_MODAL = 'MANAGE_USER/SHOW_USER_EMPTY_MODAL'
export type ShowUserEmptyModalAction = PayloadAction<
    typeof SHOW_USER_EMPTY_MODAL,
    AosUserGroup | undefined
>
export const showUserEmptyModalAction = payloadActionCreator<
    ShowUserEmptyModalAction,
    AosUserGroup | undefined
>(SHOW_USER_EMPTY_MODAL)

export const SET_USER_DATA = 'MANAGE_USER/SET_USER_DATA'
export type SetUserDataAction = PayloadAction<typeof SET_USER_DATA, Partial<BaseUserProps>>
export const setUserDataAction = payloadActionCreator<SetUserDataAction, Partial<BaseUserProps>>(
    SET_USER_DATA,
)

export const HIDE_USER_MODAL = 'MANAGE_USER/HIDE_USER_MODAL'
export type HideUserModalAction = Action<typeof HIDE_USER_MODAL>
export const hideUserModalAction = emptyActionCreator<HideUserModalAction>(HIDE_USER_MODAL)

export const SET_FORM_PRISTINE = 'MANAGE_USER/SET_FORM_PRISTINE'
export type SetFormPristineAction = PayloadAction<typeof SET_FORM_PRISTINE, boolean>
export const setFormPristineAction = payloadActionCreator<SetFormPristineAction, boolean>(
    SET_FORM_PRISTINE,
)

export const SET_USER_STATUS = 'MANAGE_USER/SET_USER_STATUS'
export type SetUserStatusAction = PayloadAction<typeof SET_USER_STATUS, AosUserAddStatus>
export const setUserStatusAction = payloadActionCreator<SetUserStatusAction, AosUserAddStatus>(
    SET_USER_STATUS,
)

export const SET_CONFLICT_USER_ID = 'MANAGE_USER/SET_CONFLICT_USER_ID'
export type SetConflictUserIdAction = PayloadAction<typeof SET_CONFLICT_USER_ID, number>
export const setConflictUserIdAction = payloadActionCreator<SetConflictUserIdAction, number>(
    SET_CONFLICT_USER_ID,
)

export const SELECT_ALL_GROUPS = 'MANAGE_USER/SELECT_ALL_GROUPS'
export type SelectAllGroupsAction = PayloadAction<typeof SELECT_ALL_GROUPS, number[]>
export const selectAllGroupsAction = payloadActionCreator<SelectAllGroupsAction, number[]>(
    SELECT_ALL_GROUPS,
)

export const DESELECT_ALL_GROUPS = 'MANAGE_USER/DESELECT_ALL_GROUPS'
export type DeselectAllGroupsAction = Action<typeof DESELECT_ALL_GROUPS>
export const deselectAllGroupsAction = emptyActionCreator<DeselectAllGroupsAction>(
    DESELECT_ALL_GROUPS,
)

export const SWITCH_TO_EDIT_AD_USER = 'MANAGE_USER/SWITCH_TO_EDIT_AD_USER'
export type SwitchToEditAdUserAction = PayloadAction<typeof SWITCH_TO_EDIT_AD_USER, number>
export const switchToEditAdUserAction = payloadActionCreator<SwitchToEditAdUserAction, number>(
    SWITCH_TO_EDIT_AD_USER,
)

export const SWITCH_TO_EDIT_CONTACT = 'MANAGE_USER/SWITCH_TO_EDIT_CONTACT'
export type SwitchToEditContactAction = PayloadAction<typeof SWITCH_TO_EDIT_CONTACT, number>
export const switchToEditContactAction = payloadActionCreator<SwitchToEditContactAction, number>(
    SWITCH_TO_EDIT_CONTACT,
)

export const CHANGE_FORM_VALUE = 'MANAGE_USER/CHANGE_FORM_VALUE'
export type ChangeFormValueAction<T> = PayloadAction<typeof CHANGE_FORM_VALUE, T>

export const UPDATE_NOTIFICATIONS_SETTINGS = 'MANAGE_USER/UPDATE_NOTIFICATIONS_SETTINGS'
export type UpdateNotificationSettingsAction = PayloadAction<
    typeof UPDATE_NOTIFICATIONS_SETTINGS,
    Partial<AosUserNotificationsPreferences>
>
export const updateNotificationSettingsAction = payloadActionCreator<
    UpdateNotificationSettingsAction,
    Partial<AosUserNotificationsPreferences>
>(UPDATE_NOTIFICATIONS_SETTINGS)

export type CommonUserAction =
    | ShowUserModalAction
    | ShowUserEmptyModalAction
    | SetUserDataAction
    | HideUserModalAction
    | SetFormPristineAction
    | SetUserStatusAction
    | SetConflictUserIdAction
    | SelectAllGroupsAction
    | DeselectAllGroupsAction
    | SwitchToEditAdUserAction
    | SwitchToEditContactAction
    | UpdateNotificationSettingsAction

export interface CommonUserActionCreators {
    showUserModalAction: typeof showUserModalAction
    showUserEmptyModalAction: typeof showUserEmptyModalAction
    setUserDataAction: typeof setUserDataAction
    hideUserModalAction: typeof hideUserModalAction
    setFormPristineAction: typeof setFormPristineAction
    setUserStatusAction: typeof setUserStatusAction
    setConflictUserIdAction: typeof setConflictUserIdAction
    selectAllGroupsAction: typeof selectAllGroupsAction
    deselectAllGroupsAction: typeof deselectAllGroupsAction
    switchToEditAdUserAction: typeof switchToEditAdUserAction
    switchToEditContactAction: typeof switchToEditContactAction
    updateNotificationSettingsAction: typeof updateNotificationSettingsAction
}

export const commonUserActionCreators: CommonUserActionCreators = {
    showUserModalAction,
    showUserEmptyModalAction,
    setUserDataAction,
    hideUserModalAction,
    setFormPristineAction,
    setUserStatusAction,
    setConflictUserIdAction,
    selectAllGroupsAction,
    deselectAllGroupsAction,
    switchToEditAdUserAction,
    switchToEditContactAction,
    updateNotificationSettingsAction,
}
