import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

export const NAVIGATE_TO_DEFAULT_PAGE = 'ROUTER/NAVIGATE_TO_DEFAULT_PAGE'
export type NavigateToDefaultPageAction = Action<typeof NAVIGATE_TO_DEFAULT_PAGE>
export const navigateToDefaultPageAction = emptyActionCreator<NavigateToDefaultPageAction>(
    NAVIGATE_TO_DEFAULT_PAGE,
)

export const NAVIGATE_TO_LOGIN = 'ROUTER/NAVIGATE_TO_LOGIN'
export type NavigateToLoginAction = PayloadAction<typeof NAVIGATE_TO_LOGIN, boolean>
export const navigateToLoginAction = payloadActionCreator<NavigateToLoginAction, boolean>(
    NAVIGATE_TO_LOGIN,
)

export type RouterAction = NavigateToDefaultPageAction | NavigateToLoginAction
