import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { Color } from 'aos-ui-common/src/styles/Color'

import { FlightsType } from '../flights/types/FlightsType'

export interface RegularityLegendConfig {
    label: string
    labelColor: Color
    trendVariant: TrendChangeVariant
}

export const regularityLegendConfig = {
    [FlightsType.Arrivals]: {
        label: 'Arrivals',
        labelColor: Color.PaleOrange,
        trendVariant: TrendChangeVariant.PreferredUp,
    },
    [FlightsType.Departures]: {
        label: 'Departures',
        labelColor: Color.Violet,
        trendVariant: TrendChangeVariant.PreferredUp,
    },
}
