import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import {
    SimpleFormModalActionCreators,
    simpleFormModalActionCreators,
    SimpleFormModalActions,
} from '../simpleForm/actions'
import { ValueWrapper } from '../simpleForm/state'

export const SET_OPTIONS = 'SINGLE_ITEM/SET_OPTIONS'
export type SetOptionsAction = PayloadAction<typeof SET_OPTIONS, any[]>
export const setOptionsAction = payloadActionCreator<SetOptionsAction, any[]>(SET_OPTIONS)

export const CHANGE_SELECT_VALUE = 'SINGLE_ITEM/CHANGE_SELECT_VALUE'
export type ChangeSelectValueAction = PayloadAction<
    typeof CHANGE_SELECT_VALUE,
    ValueWrapper<any, any>
>
export const changeSelectValueAction = payloadActionCreator<
    ChangeSelectValueAction,
    ValueWrapper<any, any>
>(CHANGE_SELECT_VALUE)

export type SingleSelectModalAction =
    | SimpleFormModalActions
    | SetOptionsAction
    | ChangeSelectValueAction

export interface SingleSelectModalActionCreators extends SimpleFormModalActionCreators {
    setOptionsAction: typeof setOptionsAction
    changeSelectValueAction: typeof changeSelectValueAction
}

export const singleSelectModalActionCreators: SingleSelectModalActionCreators = {
    ...simpleFormModalActionCreators,
    setOptionsAction,
    changeSelectValueAction,
}
