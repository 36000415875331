import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { SyncDataKey } from 'aos-services/src/services/statusDashboard/types/DashboardDataState'
import React, { FC, PropsWithChildren } from 'react'

import { StatusDashboardItem } from '../../../partials/StatusDashboardItem'
import { StatusDashboardItemCommonProps } from '../../base/StatusDashboardItemCommonProps'
import { CdmSyncWrapper } from './CdmSyncWrapper'

interface CdmItemProps {
    itemProps: StatusDashboardItemCommonProps
    link?: string
    syncId: SyncDataKey
}

export const CdmItem: FC<PropsWithChildren<CdmItemProps>> = props => (
    <CdmSyncWrapper id={props.syncId}>
        <StatusDashboardItem
            title={translate('dashboard.cdm.title')}
            description={translate('dashboard.cdm.description')}
            link={props.link}
            {...props.itemProps}
        >
            {props.children}
        </StatusDashboardItem>
    </CdmSyncWrapper>
)
