import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import { Box } from '../base/Box'
import { Text } from '../base/Text'
import { Bar } from './Bar'
import { ProgressBarProps } from './ProgressBar'

interface PhaseProgressBarProps extends ProgressBarProps {
    maxValue: number
}

export const PhaseProgressBar: FC<PhaseProgressBarProps> = ({
    value,
    minValue,
    seriesConfig,
    maxValue = 100,
}) => {
    const realValue = Math.max(value - minValue, 0)
    const data = [realValue, maxValue - value]
    const isCompleted = value >= maxValue

    return (
        <Box rowGap={8} flex={1} column>
            <Bar
                withShadow
                round
                colors={[seriesConfig.leftColor, seriesConfig.rightColor]}
                data={data}
            />
            <Box justify='space-between' flex={1} row>
                <Text fontVariant='all-small-caps' color={Color.ProgressGreen}>
                    {isCompleted
                        ? translate('phase-progress-bar.completed', {
                              value: `${value}/${maxValue}`,
                          })
                        : translate('phase-progress-bar.finished', { value })}
                </Text>
                <Text fontVariant='all-small-caps' color={Color.ProgressRed}>
                    {!isCompleted &&
                        translate('phase-progress-bar.todo', { value: maxValue - value })}
                </Text>
            </Box>
        </Box>
    )
}
