import { range } from 'lodash'

import { TaskManagerStateAware } from '../../state'
import { ReportSeason, ReportsFilters } from '../state'

export const reportTypeSelector = (state: TaskManagerStateAware) =>
    state.taskManager.reportFilters.report

export const reportFiltersSelector = (state: TaskManagerStateAware) =>
    state.taskManager.reportFilters

export const getYearLabel = (year: number, overlapping: boolean) =>
    overlapping ? `${year}-${year + 1}` : `${year}`

export const isYearOverlapping = (filters: ReportsFilters) => filters.season === ReportSeason.Winter

export const filtersYearsRangeKeys = (filters: ReportsFilters) => {
    const overlapping = isYearOverlapping(filters)
    return range(filters.yearFrom, overlapping ? filters.yearTo + 1 : filters.yearTo).map(year =>
        getYearLabel(year, overlapping),
    )
}
