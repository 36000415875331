import {
    DragDropContext,
    DragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec,
    DropTarget,
    DropTargetSpec,
} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

type DndDragSpec<P, U = DraggableItem> = DragSourceSpec<P, U>
export type DndDropSpec<Props> = DropTargetSpec<Props>

export interface DropListProps<T extends DraggableItem> {
    onDrop?(v: T): void
}

export interface DraggableItem {
    id?: number
}

export enum DndItemType {
    EventItem = 'eventItem',
    ChecklistTemplate = 'checklistTemplate',
    ChecklistItem = 'checklistItem',
    UnitTaskItem = 'unitTaskItem',
    ChecklistMessageTemplate = 'checklistMessageTemplate',
}

export function draggableWrapper<P, U = DraggableItem>(
    itemType: DndItemType,
    spec: DndDragSpec<P, U>,
) {
    return DragSource<P>(
        itemType,
        spec as DragSourceSpec<P, {}>,
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )
}

export function droppableWrapper<P>(itemType: DndItemType, spec: DndDropSpec<P>) {
    return DropTarget<P>(itemType, spec, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
    }))
}

export const withDragDropContext = DragDropContext(HTML5Backend)
