import {
    createCollectionSyncActionCreator,
    SyncActionType,
} from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'
import { syncCollectionCancellable } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { put, takeEvery } from 'redux-saga/effects'

import { firebasePaxRepository } from '../../../dataaccess/pax/firebasePaxRepository'
import { PaxEntryDto } from '../../../dataaccess/pax/types/PaxEntryDto'
import { firebaseService } from '../../../services/firebase/firebaseService'
import { PaxEntryImpl } from '../../../services/pax/types/PaxEntryImpl'
import { paxEndDate, paxStartDate } from '../state'
import {
    PAX_SYNC,
    PAX_SYNC_STOP,
    PaxSyncAction,
    PaxSyncStopAction,
    paxSyncSuccessAction,
} from './actions'

const SYNC_TIMEOUT = 15 * 60 * 1000

function* paxSyncSaga() {
    yield takeEvery<PaxSyncAction>(PAX_SYNC, function* (action: PaxSyncAction) {
        yield* firebaseService.onListener(
            'pax',
            function* () {
                yield put(paxSyncSuccessAction([SyncActionType.InitialSet, []]))
                // listen for changes on collection

                while (true) {
                    const startDate = paxStartDate()
                    const endDate = paxEndDate()
                    yield syncCollectionCancellable(
                        firebasePaxRepository.paxRef(startDate, endDate),
                        createCollectionSyncActionCreator(
                            paxSyncSuccessAction,
                            (dto: [string, PaxEntryDto]) =>
                                new PaxEntryImpl(dto[0], dto[1], startDate),
                        ),
                        500,
                        SYNC_TIMEOUT,
                    )
                }
            },
            action.payload,
        ) as Generator
    })
}

function* paxSyncStopSaga() {
    yield takeEvery<PaxSyncStopAction>(PAX_SYNC_STOP, function* (action: PaxSyncStopAction) {
        yield firebaseService.offListener('pax', action.payload)
    })
}

export const paxSagas = [paxSyncSaga, paxSyncStopSaga]
