import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import React, { FC } from 'react'

interface AreaBeltCellProps {
    row: Flight
}

/* eslint-disable react/jsx-no-literals */
export const AreaBeltCell: FC<AreaBeltCellProps> = props => {
    const { belt, belt2 } = props.row
    const beltText = () => (belt2 ? belt + ', ' + belt2 : belt)

    return belt ? <span>{beltText()}</span> : null
}
