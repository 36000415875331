import { formatDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { baggageDeliveryChartColors } from 'aos-services/src/services/airportStatus/baggage/baggageDeliveryConstants'
import { BaggageStatSection } from 'aos-services/src/services/airportStatus/baggage/types/BaggageStatSection'
import { BaggageIndicatorProgress } from 'aos-services/src/services/flightInformation/types/AosFlightBeltStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Duration } from 'moment'
import React, { PureComponent } from 'react'

import { BaggageIndicatorIcon, BaggageIndicatorIconVariant } from './BaggageIndicatorIcon'

export interface BaggageIndicatorProps {
    progress: BaggageIndicatorProgress
    deliveryTime?: Duration
    deliveryState?: BaggageStatSection
    elaborative?: boolean
}

export class BaggageIndicator extends PureComponent<BaggageIndicatorProps> {
    private colorToDeliveryStarted: Record<BaggageStatSection, Color> = {
        ...baggageDeliveryChartColors,
        [BaggageStatSection.Normal]: Color.White,
    }

    private elaborativeTextKey: Partial<Record<BaggageIndicatorProgress, string>> = {
        [BaggageIndicatorProgress.DeliveryStarted]: 'flight-information.baggage.delivery-started',
        [BaggageIndicatorProgress.DeliveryCompleted]:
            'flight-information.baggage.delivery-completed',
    }

    public render() {
        return <Box row>{this.renderContent()}</Box>
    }

    private renderContent() {
        const { progress, deliveryState = BaggageStatSection.Normal } = this.props

        switch (progress) {
            case BaggageIndicatorProgress.NotLanded:
                return null

            case BaggageIndicatorProgress.Landed:
                return (
                    <>
                        <BaggageIndicatorIcon
                            color={Color.UiBlack3}
                            variant={BaggageIndicatorIconVariant.Empty}
                        />
                        {this.text(translate('flight-information.baggage.no-data'), Color.UiBlack3)}
                    </>
                )

            case BaggageIndicatorProgress.DeliveryStarted:
                const startedColor = this.colorToDeliveryStarted[deliveryState]
                return (
                    <>
                        <BaggageIndicatorIcon
                            color={startedColor}
                            variant={BaggageIndicatorIconVariant.HalfFull}
                        />
                        {this.deliveryTime(Color.White, BaggageIndicatorProgress.DeliveryStarted)}
                    </>
                )

            case BaggageIndicatorProgress.DeliveryCompleted:
                const completedColor = baggageDeliveryChartColors[deliveryState]
                return (
                    <>
                        <BaggageIndicatorIcon
                            color={completedColor}
                            variant={BaggageIndicatorIconVariant.Full}
                        />
                        {this.deliveryTime(
                            completedColor,
                            BaggageIndicatorProgress.DeliveryCompleted,
                        )}
                    </>
                )
        }
    }

    private deliveryTime(color: Color, progress: BaggageIndicatorProgress) {
        const { deliveryTime, elaborative = false } = this.props
        if (!deliveryTime) {
            return null
        }
        const duration = formatDuration(deliveryTime)
        const text = elaborative ? this.formatElaborative(duration, progress) : duration
        return this.text(text, color)
    }

    private formatElaborative(text: string, progress: BaggageIndicatorProgress) {
        if (this.elaborativeTextKey[progress]) {
            return translate(this.elaborativeTextKey[progress]!, { text })
        }
        return text
    }

    private text(text: string, color: Color) {
        return (
            <Text color={Color.White} size={13} paddingLeft={12} style={{ color }}>
                {text}
            </Text>
        )
    }
}
