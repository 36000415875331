class LanguageProvider {
    private lang: string = 'en-US'
    private fallbackLang: string = 'en-US'

    public setLanguage = (l: string) => {
        this.lang = l.replace('_', '-')
    }

    public getCurrent = () => this.lang

    public getFallback = () => this.fallbackLang
}

export const languageProvider = new LanguageProvider()
