import { downloadFileFromResponse } from 'aos-helpers/src/helpers/Download'

import { taskUnitRestService } from '../../dataaccess/checklists/taskUnitRestService'
import { AosTaskInSeq } from '../../dataaccess/checklists/types/AosTaskInSeq'
import { UnitTaskChangeObject } from '../events/types/AosEventChecklist'
import { AosUnitTaskItemDTO, AosUnitTaskTemplateImpl } from './types/AosUnitTaskTemplate'

class TaskUnitService {
    getAll = () =>
        taskUnitRestService
            .getAll()
            .then(templates => templates.map(t => new AosUnitTaskTemplateImpl(t)))

    getOne = (id: number) =>
        taskUnitRestService.getOne(id).then(template => new AosUnitTaskTemplateImpl(template))

    reorderItems = (checklistTemplateId: number, tasksOrder: AosTaskInSeq[]): Promise<void> =>
        taskUnitRestService.reorderItems(
            checklistTemplateId,
            tasksOrder.map((task, index) => ({
                id: task.id,
                sequenceNumber: index,
            })),
        )
    create = (checklistTemplate: AosUnitTaskItemDTO): Promise<void> =>
        taskUnitRestService.create({
            eventPhase: checklistTemplate.eventPhase,
            content: checklistTemplate.content,
            severity: checklistTemplate.severity,
            unit: checklistTemplate.unit,
            additionalNote: checklistTemplate.additionalNote,
        })

    update = (templateId: number, checklistTemplate: AosUnitTaskItemDTO): Promise<Response> =>
        taskUnitRestService.update(templateId, {
            id: checklistTemplate.id,
            eventPhase: checklistTemplate.eventPhase,
            content: checklistTemplate.content,
            severity: checklistTemplate.severity,
            unit: checklistTemplate.unit,
            additionalNote: checklistTemplate.additionalNote,
            sequenceNumber: checklistTemplate.sequenceNumber,
        })

    delete = (templateId: number, itemId: number): Promise<Response> =>
        taskUnitRestService.delete(templateId, itemId)

    exportSingle = (id: number): Promise<void> =>
        taskUnitRestService.exportSingle(id).then(downloadFileFromResponse)

    exportAll = (): Promise<void> => taskUnitRestService.exportAll().then(downloadFileFromResponse)

    updateTaskItem = (payload: UnitTaskChangeObject) => taskUnitRestService.updateUnitTask(payload)
}

export const taskUnitService = new TaskUnitService()
