import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { EventRelatedNotificationDto } from '../../../dataaccess/notifications/types/EventRelatedNotificationDto'
import { AosSeverity } from '../../common/types/AosSeverity'
import { AosEventScenario } from '../../events/types/AosEventScenario'
import { AosEventVisibility } from '../../events/types/AosEventVisibility'
import { EventRelatedNotification } from './EventRelatedNotification'

export abstract class EventRelatedNotificationImpl<T extends EventRelatedNotificationDto<U>, U>
    extends WrapperObject<T>
    implements EventRelatedNotification<U>
{
    protected constructor(public id: string, value: T, private markAlwaysAsRead: boolean) {
        super(value)
    }

    public get category() {
        return this.value.category as AosEventScenario
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get visibility() {
        return this.value.visibility as AosEventVisibility
    }

    public get title() {
        return this.value.title
    }

    public get eventId() {
        return this.value.eventId
    }

    public get type() {
        return this.value.type
    }

    public get read() {
        return this.markAlwaysAsRead || this.value.read
    }

    public get timestamp() {
        return dateTime(this.value.timestamp)
    }

    public get hardAlert() {
        return this.value.hardAlert
    }
}
