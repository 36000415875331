import React from 'react'

export const defaultChartTooltip = <T, TY>(
    color: string,
    label: string | undefined,
    yAccessor: f.Func1<T, TY>,
) => (data: T) => {
    const labelPrefix = label ? label + ': ' : ''
    return <span style={{ color }}>{labelPrefix + yAccessor(data)}</span>
}
