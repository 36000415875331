import React, { FC } from 'react'
import styled from 'styled-components'

import { Color } from '../../../../../aos-ui-common/src/styles/Color'
import { Box } from '../../base/Box'
import { IconButton } from '../../buttons/IconButton'
import { IconVariant } from '../../svg/Icon'
import { SvgIcon } from '../../svg/SvgIcon'
import { Input } from '../input/Input'
import { TimePickerProps } from './utils'

export const TimeField: FC<TimePickerProps> = ({
    value,
    onChange,
    step = 1,
    max,
    min = 0,
    label,
}) => {
    const increase = () => checkMinMax((value || 0) + step)
    const decrease = () => checkMinMax((value || 0) - step)

    const checkMinMax = (value: number) => {
        if (max && value > max) {
            onChange(max)
        } else if (value < min) {
            onChange(min)
        } else {
            onChange(value)
        }
    }

    return (
        <Box column rowGap={2}>
            <TimeBox row>
                <NoBorderInput
                    placeholder={label}
                    value={value}
                    onChange={e => checkMinMax(e.target.value ? parseInt(e.target.value) : 0)}
                />
                <Box paddingHorizontal={8} column justify='space-around'>
                    <RotatedIconButton
                        iconVariant={IconVariant.GreyTxt}
                        disabled={value === max}
                        onClick={increase}
                        iconSize={20}
                        svg={SvgIcon.Arrow}
                    />
                    <BottomIconButton
                        iconSize={20}
                        iconVariant={IconVariant.GreyTxt}
                        disabled={value === min}
                        onClick={decrease}
                        svg={SvgIcon.Arrow}
                    />
                </Box>
            </TimeBox>
        </Box>
    )
}

const NoBorderInput = styled(Input)`
    border: none;
    font-size: 12px;
    text-align: center;
    margin: 0 2px;
    color: ${Color.UiBlack1};
    height: 95%;
    padding: 2px;
`

const RotatedIconButton = styled(IconButton)`
    transform: rotate(180deg) translateX(-1px) translateY(-4px);
`

const BottomIconButton = styled(IconButton)`
    transform: translateY(-4px);
`

const TimeBox = styled(Box)`
    width: 128px;
    height: 40px;
    border-radius: 4px;
    background: ${Color.White};
    border: 1px solid ${Color.Grey2};
`
