import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    regularitySelector,
    RegularitySelectorState,
} from 'aos-services/src/core/statusDashboardData/flights/selectors/regularity/regularity'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { CommonFlightView } from 'aos-services/src/services/statusDashboard/types/filters/CommonFlightFilters'
import { RegularityFilters } from 'aos-services/src/services/statusDashboard/types/filters/RegularityFilters'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { setRegularityFilterAction } from '../../../../core/statusDashboard/actions'
import { RegularityItemState } from '../../../../services/statusDashboard/types/itemStates/RegularityItemState'
import { pickDashboardItemProps } from '../../partials/StatusDashboardItem'
import { FlightChartStatsWrapper } from '../base/FlightChartStatsWrapper'
import { FlightFooterFilters } from '../base/FlightFooterFilters'
import { InvertedPercentageFlightChart } from '../base/InvertedPercentageFlightChart'
import { ItemContainer } from '../base/ItemContainer'
import { StatusDashboardItemCommonProps } from '../base/StatusDashboardItemCommonProps'
import { RegularityItem } from './partials/RegularityItem'
import { regularityFilterConfigs } from './regularityFilterConfig'
import { RegularityFlights } from './RegularityFlights'
import { RegularityPieChart } from './RegularityPieChart'
import { RegularityTooltip } from './RegularityTooltip'

interface RegularityDispatchProps {
    setRegularityFilterAction: typeof setRegularityFilterAction
}

type RegularityOwnProps = StatusDashboardItemCommonProps<RegularityItemState>

type RegularityProps = RegularitySelectorState & RegularityDispatchProps & RegularityOwnProps

export class RegularityClass extends PureComponent<RegularityProps> {
    public render() {
        const { itemState, noDataWarning } = this.props
        const isSmall = itemState.size === DashboardItemSizeVariant.Small
        const itemProps = pickDashboardItemProps(this.props)
        return (
            <RegularityItem
                itemProps={itemProps}
                noDataWarning={noDataWarning}
                syncId={itemProps.id}
            >
                {isSmall ? this.renderCompact() : this.renderFull()}
                <FlightFooterFilters
                    timeRange={itemState.timeRange}
                    airline={itemState.airline}
                    flightType={itemState.flightType}
                    handlingAgent={itemState.handlingAgent}
                    fullViewTab={itemState.fullViewTab}
                    setFilterAction={this.handleSetFilter}
                    isSmall={isSmall}
                    tabConfiguration={regularityFilterConfigs}
                />
            </RegularityItem>
        )
    }

    private renderCompact() {
        const { stats } = this.props
        return (
            <ItemContainer autoHeight horizontal horizontalSpacing>
                <RegularityPieChart stat={stats} />
            </ItemContainer>
        )
    }

    private renderFull = () => {
        const { itemState, flights, series, xDomain, yDomain, stats } = this.props
        return (
            <FlightChartStatsWrapper
                chart={
                    <InvertedPercentageFlightChart
                        flights={series}
                        yDomain={yDomain}
                        xDomain={xDomain}
                        tooltip={p => <RegularityTooltip point={p} />}
                        color={Color.Red}
                        timeRangeFilter={this.props.itemState.timeRange}
                    />
                }
                flightTable={() => <RegularityFlights flights={flights} />}
                flightTableTitle={translate('dashboard.regularity.flight-table-header')}
                statsContent={<RegularityPieChart stat={stats} />}
                showChart={itemState.fullViewTab === CommonFlightView.History}
            />
        )
    }

    private handleSetFilter = (f: Partial<RegularityFilters>) => {
        this.props.setRegularityFilterAction([this.props.id, f])
    }
}

export const Regularity = connect<
    RegularitySelectorState,
    RegularityDispatchProps,
    RegularityOwnProps
>(regularitySelector, {
    setRegularityFilterAction,
})(RegularityClass)
