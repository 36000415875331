import { chain, range } from 'lodash'

export interface Position {
    x: number
    y: number
}

export interface RectangleDimensions {
    w: number
    h: number
}

export interface Rectangle extends Position, RectangleDimensions {}

/** Returns the summed area from (0, 0) to (x,y) without a rectangle of (w,h) placed at the bottom right of that area,
 * that is the sum of areas 1,2,3 in the following picture:
 *
 * (0,0)--------------------+
 * |                |       |
 * |        1       |   2   |
 * |----------------+---w---|
 * |                |       |
 * |        3       h       |
 * |                |       |
 * +----------------+-------(x,y)
 */
const exceptRect = (summedAreaTable: number[][], x: number, y: number, w: number, h: number) => {
    const left = x >= w ? summedAreaTable[y][x - w] : 0
    const up = y >= h ? summedAreaTable[y - h][x] : 0
    const upLeft = y >= h && x >= w ? summedAreaTable[y - h][x - w] : 0
    return up + left - upLeft
}

/** Computes a `w` by `h` boolean matrix, where `true` means that the location is occupied by some rect and false otherwise. */
const getOccupiedSpotsMatrix = (h: number, w: number, items: Rectangle[]) => {
    const occupiedSpots: boolean[][] = Array.from(Array(h), () => Array(w).fill(false))
    items.forEach(item =>
        range(item.y, item.y + item.h).forEach(y =>
            range(item.x, item.x + item.w).forEach(x => (occupiedSpots[y][x] = true)),
        ),
    )
    return occupiedSpots
}

const getGridHeight = (items: Rectangle[]) =>
    chain(items)
        .map(item => item.y + item.h)
        .max()
        .value() || 0

/**
 * Positions `targetRect` in the grid with `cols` columns, already occupied by `items`.
 * The returned location is the location with lowest possible `y`, such that no rectangle intersects `targetRect`.
 * If there is more one such position, the leftmost is returned.
 *
 * A "summed-area table" is used to ensure linear running time in respect to grid size.
 * https://en.wikipedia.org/wiki/Summed-area_table
 *
 * @param items items already present in the grid
 * @param cols grid width
 * @param targetRect dimensions of the new rectangle
 * @return `(x,y)` of new rectangle
 */
export const positionInGrid = (
    items: Rectangle[],
    cols: number,
    targetRect: RectangleDimensions,
): Position => {
    if (items.length === 0) {
        return { x: 0, y: 0 }
    }

    const maxY = getGridHeight(items) + targetRect.h // ensure there is free space for targetRect even if whole grid is occupied
    const occupiedSpots = getOccupiedSpotsMatrix(maxY, cols, items)

    const summedArea: number[][] = []
    for (let y = 0; y < maxY; y++) {
        summedArea[y] = Array(cols)
        for (let x = 0; x < cols; x++) {
            const exceptCurrent = exceptRect(summedArea, x, y, 1, 1)
            summedArea[y][x] = exceptCurrent + Number(occupiedSpots[y][x])

            const width = targetRect.w > cols ? cols : targetRect.w
            const height = targetRect.h

            if (width <= x + 1 && height <= y + 1) {
                const freeSpots = summedArea[y][x] - exceptRect(summedArea, x, y, width, height)
                if (freeSpots === 0) {
                    return { x: x - width + 1, y: y - height + 1 }
                }
            }
        }
    }

    throw Error(
        'It should not happen - adding targetRect.y to max item y ensures there is room in the grid for targetRect',
    )
}
