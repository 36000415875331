import { firebaseApp } from 'firebaseApp'

const FEATURE_FLAG_COLLECTION = 'feature_flags'
class FirebaseFeatureFlagService {
    constructor() {
        firebaseApp.remoteConfig().settings = {
            minimumFetchIntervalMillis: 3600,
            fetchTimeoutMillis: 60000,
        }
    }
    public getFeatureFlag = (
        featureFlag: string,
    ): Promise<firebase.remoteConfig.RemoteConfig.Value> =>
        firebaseApp
            .remoteConfig()
            .fetchAndActivate()
            .then(() => {
                return firebaseApp.remoteConfig().getValue(featureFlag)
            })
}

export const firebaseFeatureFlagService = new FirebaseFeatureFlagService()

export const featureFlagCallback = (featureFlag: string, callback: () => void) => {
    ;(async () => {
        const flag = await firebaseFeatureFlagService.getFeatureFlag(FEATURE_FLAG_COLLECTION)
        const flagValue = JSON.parse(flag.asString())
        if (flagValue[featureFlag]) {
            callback()
        }
    })()
}

export enum FeatureFlagType {
    ReportingContactsButton = 'web_reporting_contacts_button',
    ToastMessages = 'web_toast_messages',
    CreateAnother = 'web_create_another',
}
