import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { MapAction } from 'aos-ui-map/src/core/actions'

export const LOAD_SELECTION_DETAILS_SUCCESS = 'AIRPORT_MAP/LOAD_SELECTION_DETAILS_SUCCESS'
export type LoadSelectionDetailsSuccessAction = PayloadAction<
    typeof LOAD_SELECTION_DETAILS_SUCCESS,
    AosEvent | undefined
>
export const loadSelectionDetailsSuccessAction = payloadActionCreator<
    LoadSelectionDetailsSuccessAction,
    AosEvent | Task | undefined
>(LOAD_SELECTION_DETAILS_SUCCESS)

export const AIRPORT_MAP_MAP_PARENT = 'AIRPORT_MAP/AIRPORT_MAP_MAP_PARENT'
export type AirportMapMapParentAction = PayloadAction<typeof AIRPORT_MAP_MAP_PARENT, MapAction>
export const airportMapMapParentAction = payloadActionCreator<AirportMapMapParentAction, MapAction>(
    AIRPORT_MAP_MAP_PARENT,
)

export const SYNC_AIRPORT_MAP = 'AIRPORT_MAP/SYNC_AIRPORT_MAP'
export type SyncAirportMapAction = PayloadAction<typeof SYNC_AIRPORT_MAP, boolean>
export const syncAirportMapAction = payloadActionCreator<SyncAirportMapAction, boolean>(
    SYNC_AIRPORT_MAP,
)

export type AirportMapAction =
    | AirportMapMapParentAction
    | LoadSelectionDetailsSuccessAction
    | SyncAirportMapAction
