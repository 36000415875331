import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosSeverity,
    eventSeverityTitle,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren } from 'react'

import { MarginBoxProps } from '../../base/Box'
import { SeleniumProps } from '../../base/SeleniumProps'
import { ThemeVariant } from '../../base/ThemeVariant'
import { BlockRadioGroup, RendererProps } from '../radio/BlockRadioGroup'
import { CircleRadioRenderer } from '../radio/CircleRadioRenderer'
import { LabeledFormElement } from './LabeledFormElement'

interface LabeledSeverityProps extends SeleniumProps, MarginBoxProps {
    keyPrefix: string
    value?: AosSeverity
    severities: AosSeverity[]
    colorsMapping?: Record<AosSeverity, Color>
    isRequired?: boolean
    isError?: boolean
    variant?: ThemeVariant
    onChange(v: AosSeverity): void
    translateSeverity?(e: AosSeverity): string
    isAtc?: boolean
    disabled?: boolean
}

export const LabeledSeverity: FCWithChildren<LabeledSeverityProps> = ({
    keyPrefix,
    value,
    severities,
    isRequired,
    onChange,
    isError,
    variant,
    seleniumLocation,
    translateSeverity = eventSeverityTitle,
    colorsMapping = severityColorsMapping,
    disabled,
    ...marginProps
}) => {
    const translateSuffix = (v: string) => translate(`${keyPrefix}.${v}`)

    const SeverityRadioItem: FCWithChildren<RendererProps<AosSeverity>> = ({
        item,
        isSelected,
        onSelect,
        disabled,
    }) => (
        <CircleRadioRenderer
            disabled={disabled}
            label={translateSeverity(item)}
            color={colorsMapping[item]}
            isSelected={isSelected}
            onSelect={onSelect}
        />
    )

    return (
        <LabeledFormElement
            label={translateSuffix('label')}
            isRequired={isRequired}
            isError={isError}
            errorMessage={translateSuffix('error')}
            seleniumLocation={seleniumLocation}
            variant={variant}
            {...marginProps}
        >
            <BlockRadioGroup
                disabled={disabled}
                items={severities}
                value={value}
                Renderer={SeverityRadioItem}
                onChange={onChange}
                marginBottom={8}
                gridSize={3}
            />
        </LabeledFormElement>
    )
}
