import { alertsReducer } from 'aos-services/src/core/alerts/reducer'
import { bimLayersDataReducer } from 'aos-services/src/core/bimLayersData/reducer'
import { commonReducer } from 'aos-services/src/core/common/reducer'
import { eventMapUserLayerVisibilityReducer } from 'aos-services/src/core/eventUserLayersVisibility/reducer'
import { featureFlagReducer } from 'aos-services/src/core/featureFlags/reducer'
import { layersDataReducer } from 'aos-services/src/core/layersData/reducer'
import { restrictionsReducer } from 'aos-services/src/core/restrictions/reducer'
import { statusDashboardDataReducer } from 'aos-services/src/core/statusDashboardData/reducer'
import { userLayerVisibilityReducer } from 'aos-services/src/core/userLayersVisibility/reducer'
import { get, merge } from 'lodash'
import { Reducer } from 'react'
import { combineReducers, compose } from 'redux'
import { RouterState } from 'redux-first-history'
import { mergePersistedState } from 'redux-localstorage'

import { actionModalReducer } from './actionModal/reducer'
import { RESET_STATE } from './actions'
import { airportMapReducer } from './airportMap/reducer'
import { authReducer } from './auth/reducer'
import { checklistManagerReducer } from './checklistManager/reducer'
import { checklistSelectionModalReducer } from './checklistSelectionModal/reducer'
import { commonFrontendReducer } from './commonFrontend/reducer'
import { contactListReducer } from './contactList/reducer'
import { eventEditorReducer } from './eventEditor/reducer'
import { eventManagerReducer } from './eventManager/reducer'
import { eventModalsReducer } from './eventModals/reducer'
import { eventPreviewReducer } from './eventPreview/reducer'
import { eventTimelineReducer } from './eventTimeline/reducer'
import { flightInformationReducer } from './flightInformation/reducer'
import { globalMessagingReducer } from './globalMessaging/reducer'
import { groupManagerReducer } from './groupManager/reducer'
import { layoutReducer } from './layout/reducer'
import { loginReducer } from './login/reducers'
import { notamReducer } from './notamTimeline/reducer'
import { notificationsReducer } from './notifications/reducer'
import { oneTimeReducer } from './oneTime/reducer'
import { removeAccountReducer } from './removeAccount/reducer'
import { reportingReducer } from './reporting/reducer'
import { RouterAction } from './router/actions'
import { standsGatesTimelineReducer } from './standsGatesTimeline/reducer'
import { myDashboardPath, resetState, State } from './state'
import { statusDashboardReducer } from './statusDashboard/reducer'
import { tasksReducers } from './tasks'
import { userProfileModalReducer } from './userProfileModal/reducer'

export const stateReducers = (routerReducer: Reducer<RouterState, RouterAction>) =>
    combineReducers<State>({
        router: routerReducer,
        commonFrontend: commonFrontendReducer,
        common: commonReducer,
        alerts: alertsReducer,
        eventTimeline: eventTimelineReducer,
        login: loginReducer,
        layersData: layersDataReducer,
        bimLayers: bimLayersDataReducer,
        layout: layoutReducer,
        auth: authReducer,
        eventPreview: eventPreviewReducer,
        eventModals: eventModalsReducer,
        eventManager: eventManagerReducer,
        eventEditor: eventEditorReducer,
        actionModal: actionModalReducer,
        groupManager: groupManagerReducer,
        checklistManager: checklistManagerReducer,
        notifications: notificationsReducer,
        checklistSelectionModal: checklistSelectionModalReducer,
        airportMap: airportMapReducer,
        globalMessaging: globalMessagingReducer,
        flights: flightInformationReducer,
        statusDashboard: statusDashboardReducer,
        statusDashboardData: statusDashboardDataReducer,
        oneTime: oneTimeReducer,
        userProfileModal: userProfileModalReducer,
        restrictions: restrictionsReducer,
        standsGatesTimeline: standsGatesTimelineReducer,
        userLayerVisibility: userLayerVisibilityReducer,
        eventMapUserLayerVisibility: eventMapUserLayerVisibilityReducer,
        reporting: reportingReducer,
        featureFlags: featureFlagReducer,
        removeAccount: removeAccountReducer,
        notam: notamReducer,
        contactList: contactListReducer,
        ...tasksReducers,
    })

const rootReducer =
    (routerReducer: Reducer<RouterState, RouterAction>) =>
    (state: State, action: any): State => {
        switch (action.type) {
            case RESET_STATE:
                return stateReducers(routerReducer)(resetState(state), action)

            default:
                return stateReducers(routerReducer)(state, action)
        }
    }

export const reducers = (routerReducer: Reducer<RouterState, RouterAction>) =>
    compose(
        mergePersistedState((initialState: any, persistedState: any) => {
            const result = merge({}, initialState, persistedState)
            const persistedMyDashboard = get(persistedState, myDashboardPath)

            if (persistedMyDashboard) {
                result.statusDashboard.presets['my-dashboard'] = persistedMyDashboard
            }
            return result
        }),
    )(rootReducer(routerReducer))
