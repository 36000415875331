import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AosSeverity,
    eventSeverityTitle,
    severityColorsMapping,
} from 'aos-services/src/services/common/types/AosSeverity'
import { CheckboxColorDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxColorDropdown'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'

import { EventTimelineFilter } from '../../core/eventTimeline/state'

interface EventSeveritySelectorProps {
    filter: EventTimelineFilter
    onChange(v: Partial<EventTimelineFilter>): void
}

export const EventSeveritySelector: FC<EventSeveritySelectorProps> = ({ filter, onChange }) => {
    const allItems = EnumValues.getValues<AosSeverity>(AosSeverity)
    const value = !filter.selectedSeverities.length ? allItems : filter.selectedSeverities
    return (
        <CheckboxColorDropdown
            items={allItems}
            value={value}
            allContent={translate('dashboard.event-severity.all')}
            valueRenderer={item => eventSeverityTitle(item)}
            onChange={selectedSeverities => onChange({ selectedSeverities })}
            colors={severityColorsMapping}
            width={220}
            clearAction={() => onChange({ selectedSeverities: allItems })}
            preventEmpty
            small
        />
    )
}
