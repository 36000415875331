import { Box } from 'aos-ui/src/components/base/Box'
import { Color } from 'aos-ui-common/src/styles/Color'
import { sum } from 'lodash'
import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { boxShadow } from '../base/Theme'

interface BarProps {
    height?: number
    colors: Color[]
    data: number[]
    borderColor?: Color
    round?: boolean
    withShadow?: boolean
}

export const Bar: FCWithChildren<BarProps> = ({
    height = 12,
    colors,
    data,
    borderColor,
    round,
    withShadow,
}) => {
    const total = sum(data)
    const borderRadius = height / 2
    const borderStyle = (index: number) => {
        if (!round) {
            return {}
        }
        if (index === 0 || data[0] === 0) {
            return {
                borderRadius,
            }
        }
        return {
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
        }
    }

    return (
        <Box fullWidth row overflow='hidden'>
            {data.map((v, index) => (
                <BarItem
                    value={v}
                    index={index}
                    total={total}
                    colors={colors}
                    key={index}
                    height={height}
                    borderColor={borderColor}
                    withShadow={withShadow}
                    style={{
                        ...borderStyle(index),
                    }}
                />
            ))}
        </Box>
    )
}

const BarItem = styled(Box)<{
    index: number
    total: number
    value: number
    colors: Color[]
    borderColor?: Color
    withShadow?: boolean
}>`
    background: ${p => p.colors[p.index]};
    width: ${props => (props.value / props.total) * 100}%;
    border: ${p => (p.borderColor ? `1px solid ${p.borderColor}` : 'none')};
    box-shadow: ${p => (p.index === 0 && p.withShadow ? boxShadow.std : 'none')};
`
