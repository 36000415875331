import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { RingRailTrainMapProperties } from 'aos-services/src/services/layerData/properties/RingRailTrainMapProperties'
import {
    getRingRailTrainTimeTable,
    getTrainForTrainNumber,
    getUpcomingStationForTrain,
    RingRailStation,
    RingRailTrain,
} from 'aos-services/src/services/layerData/types/RingRailTrain'
import { Box } from 'aos-ui/src/components/base/Box'
import { Sidebar } from 'aos-ui/src/components/sidebar/Sidebar'
import { Dictionary } from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { State } from '../../../core/state'
import { TrainDetails } from '../../trainInformation/TrainDetails'
import { TrainTimeTable } from '../../trainInformation/TrainTimeTable'

export class TrainDetailsSidebarClass extends PureComponent<
    TrainDetailsSidebarProps & TrainDetailsSidebarOwnProps,
    {}
> {
    public render() {
        const train = getTrainForTrainNumber(this.props.trains, this.props.train.trainNumber)
        const timeTable = train ? getRingRailTrainTimeTable(train) : undefined
        const upcomingStation = train ? getUpcomingStationForTrain(train) : undefined

        return (
            <Sidebar title={translate('map.sidebar.train-details')}>
                <Box className='train-information' fullHeight column>
                    {train && (
                        <TrainDetails
                            className='padding-bottom--large border-bottom--black'
                            train={train}
                            stations={this.props.stations}
                        />
                    )}
                    {timeTable && (
                        <TrainTimeTable
                            className='flex--1 padding-top--decreased'
                            timeTable={timeTable}
                            upcomingStation={upcomingStation}
                            stations={this.props.stations}
                        />
                    )}
                </Box>
            </Sidebar>
        )
    }
}

interface TrainDetailsSidebarOwnProps {
    train: RingRailTrainMapProperties
}

interface TrainDetailsSidebarProps {
    trains: RingRailTrain[]
    stations: Dictionary<RingRailStation>
}

export const TrainDetailsSidebar = connect<
    TrainDetailsSidebarProps,
    {},
    TrainDetailsSidebarOwnProps
>(
    (state: State) => ({
        trains: state.layersData.ringRailTrainsInfo
            ? state.layersData.ringRailTrainsInfo.trains
            : [],
        stations: state.layersData.ringRailTrainsInfo
            ? state.layersData.ringRailTrainsInfo.stations
            : {},
    }),
    {},
)(TrainDetailsSidebarClass)
