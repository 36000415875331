import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    TowingStatus,
    towingStatusColor,
} from 'aos-services/src/services/flightInformation/types/towing/TowingStatus'
import React, { FC } from 'react'

interface TowingStatusCellProps {
    status: TowingStatus
}

export const TowingStatusCell: FC<TowingStatusCellProps> = props => {
    const { status } = props
    return (
        <span
            style={{
                minWidth: 0,
                overflow: 'hidden',
                color: towingStatusColor[status],
            }}
        >
            {translateTowingStatus(status)}
        </span>
    )
}

export const translateTowingStatus = translateEnum<TowingStatus>(
    TowingStatus,
    'flight-information.towing-status',
)
