import { BlockSize } from 'aos-helpers/src/helpers/Block'
import {
    airportIcon,
    AosAirport,
} from 'aos-services/src/services/flightInformation/types/AosAirport'
import { getAirportName } from 'aos-services/src/services/flightInformation/types/FlightInfoFormatter'
import { allAccessLevels, TaskAccessLevel } from 'aos-services/src/services/users/types/AosUser'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant, svgIconForName, SvgIconType } from 'aos-ui/src/components/svg/Icon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { RadioCheckbox } from '../../../../tasks/common/form/RadioCheckbox'

interface PermissionFromRowProps {
    airport: AosAirport
    accessLevel?: TaskAccessLevel
    onChange(accessLevel: TaskAccessLevel): void
}

export const PermissionFromRow: FC<PermissionFromRowProps> = ({
    airport,
    accessLevel = TaskAccessLevel.None,
    onChange,
}) => {
    const label = getAirportName(airport)
    const icon = airportIcon(airport as AosAirport) as SvgIconType

    return (
        <Row paddingVertical={8} paddingHorizontal={30} row>
            <Box flex={1} row>
                <Icon
                    iconSize={BlockSize.Std}
                    svg={svgIconForName(icon)}
                    iconVariant={IconVariant.BlackGrey}
                    color={Color.UiBlack2}
                />
                <Text as='span' paddingLeft={8} color={Color.UiBlack2}>
                    {label}
                </Text>
            </Box>
            <Box columnGap={40} width={180} row centered>
                {allAccessLevels.map(item => (
                    <RadioCheckbox
                        key={item}
                        checked={accessLevel === item}
                        onChange={() => onChange(item)}
                    />
                ))}
            </Box>
        </Row>
    )
}

const Row = styled(Box)`
    &:hover {
        background-color: ${Color.Grey1};
    }
`
