import 'react-virtualized/styles.css' // only needs to be imported once

import {
    TobtTableData,
    TobtTimeRange,
} from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import { TobtKeyTypes } from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import { TobtType } from 'aos-services/src/services/statusDashboard/types/filters/TobtFilters'
import { Box } from 'aos-ui/src/components/base/Box'
import React from 'react'
import { Column, Table } from 'react-virtualized'
import { AutoSizer } from 'react-virtualized'
import styled from 'styled-components'

const min = 'min'

function CdmTable({ data, type }: { data: TobtTableData[]; type: TobtType }) {
    const headerTitleRenderer = ({
        title,
        type,
        justifyContent,
    }: {
        title: string
        type?: 'time'
        justifyContent?: string
    }) => {
        return (
            <HeaderTitleWrapper justifyContent={justifyContent}>
                <HeaderTitle>{title}</HeaderTitle>
                {type === 'time' && <HeaderMin>{min}</HeaderMin>}
            </HeaderTitleWrapper>
        )
    }

    const cellColorRenderer = ({
        cellData,
        background,
        margin,
        color,
        justifyContent,
        tableWidth,
    }: {
        cellData: any
        background?: string
        margin?: string
        color?: string
        justifyContent?: string
        tableWidth?: number
    }) => {
        return (
            <CellWrapper
                background={background}
                margin={margin}
                color={color}
                justifyContent={justifyContent}
                padding={tableWidth && tableWidth >= 846 ? undefined : '10px'}
                height={tableWidth && tableWidth >= 846 ? undefined : '56px'}
            >
                {cellData}
            </CellWrapper>
        )
    }
    if (!data) {
        return null
    }

    return (
        <Box
            flex={1}
            height={230}
            fullHeight={false}
            paddingTop={0}
            paddingBottom={0}
            paddingLeft={16}
            paddingRight={16}
            style={{ background: '#14202C' }}
        >
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={50}
                            rowHeight={20}
                            rowCount={data.length}
                            rowGetter={({ index }) => data[index]}
                            rowStyle={({ index }) => {
                                if (index === -1) {
                                    return {
                                        marginRight: 0,
                                    }
                                }
                                if (index % 2 === 0) {
                                    return {
                                        marginRight: 0,
                                        background: '#232D43',
                                    }
                                } else {
                                    return {
                                        marginRight: 0,
                                        background: '#14202C',
                                    }
                                }
                            }}
                        >
                            <Column
                                headerRenderer={() =>
                                    headerTitleRenderer({
                                        title:
                                            type === TobtType.Airline
                                                ? TobtType.Airline
                                                : TobtType.GroundHandling,
                                        justifyContent: 'left',
                                    })
                                }
                                style={{ marginRight: 0 }}
                                headerStyle={{ marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({
                                        cellData,
                                        justifyContent: 'left',
                                        tableWidth: width,
                                    })
                                }
                                dataKey={
                                    type === TobtType.Airline
                                        ? TobtKeyTypes.airline
                                        : TobtKeyTypes.handler
                                }
                                width={200}
                            />
                            <Column
                                headerRenderer={() => headerTitleRenderer({ title: 'Before' })}
                                headerStyle={{ marginRight: 0 }}
                                dataKey={TobtTimeRange.before}
                                width={250}
                                style={width >= 846 ? undefined : { marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({ cellData, background: '#005DE5' })
                                }
                            />
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '> 60', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min60}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '51-60', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min51_60}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '41-50', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min41_50}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '31-40', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) =>
                                        cellColorRenderer({
                                            cellData,
                                        })
                                    }
                                    dataKey={TobtTimeRange.min31_40}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '21-30', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min21_30}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '11-20', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min11_20}
                                    width={130}
                                />
                            )}
                            {width >= 846 && (
                                <Column
                                    headerRenderer={() =>
                                        headerTitleRenderer({ title: '6-10', type: 'time' })
                                    }
                                    style={{ marginRight: 0 }}
                                    headerStyle={{ marginRight: 0 }}
                                    cellRenderer={({ cellData }) => cellColorRenderer({ cellData })}
                                    dataKey={TobtTimeRange.min6_10}
                                    width={130}
                                />
                            )}

                            <Column
                                headerRenderer={() =>
                                    headerTitleRenderer({ title: '0-5', type: 'time' })
                                }
                                headerStyle={{ marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({
                                        cellData,
                                        background: '#F8BA00',
                                        color: '#050A10',
                                    })
                                }
                                style={{ marginRight: '0px' }}
                                dataKey={TobtTimeRange.min0_5}
                                width={130}
                            />
                            <Column
                                headerRenderer={() => headerTitleRenderer({ title: 'Late' })}
                                headerStyle={{ marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({
                                        cellData,
                                        background: '#FF6D00',
                                        color: '#050A10',
                                    })
                                }
                                style={{ marginRight: '0px' }}
                                dataKey={TobtTimeRange.later}
                                width={130}
                            />
                            <Column
                                headerRenderer={() => headerTitleRenderer({ title: 'Susp.' })}
                                headerStyle={{ marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({
                                        cellData,
                                        background: '#E42626',
                                    })
                                }
                                style={{ marginRight: '0px' }}
                                dataKey={TobtTimeRange.suspended}
                                width={130}
                            />
                            <Column
                                headerRenderer={() => headerTitleRenderer({ title: 'Too Late' })}
                                headerStyle={{ marginRight: 0 }}
                                cellRenderer={({ cellData }) =>
                                    cellColorRenderer({
                                        cellData,
                                        background: '#E42626',
                                        margin: '0 0 0 4px',
                                    })
                                }
                                style={{ marginRight: '0px' }}
                                dataKey={TobtTimeRange.late}
                                width={247}
                            />
                        </Table>
                    )
                }}
            </AutoSizer>
        </Box>
    )
}

export default CdmTable

interface HeaderTitleWrapperProps {
    justifyContent?: string
}

interface CellWrapperProps {
    background?: string
    margin?: string
    justifyContent?: string
    color?: string
    padding?: string
    height?: string
}

const HeaderTitleWrapper = styled.div<HeaderTitleWrapperProps>`
    display: grid;
    text-transform: none;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
    font-weight: 200;
`

const HeaderTitle = styled.div`
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 17px;
`

const HeaderMin = styled.div`
    display: grid;
    font-size: 12px;
    justify-content: center;
`

const CellWrapper = styled.div<CellWrapperProps>`
    display: grid;
    font-size: 14px;
    background: ${({ background }) => background};
    margin: ${({ margin }) => margin};
    color: ${({ color }) => color};
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
    align-items: center;
    height: 18px;
    font-weight: 500;
    padding: ${({ padding }) => padding};
    height: ${({ height }) => height};
`
