import {
    TaskChecklistItemChange,
    TaskRecurrentChecklistItemChange,
} from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { TaskStatus } from 'aos-services/src/services/tasks/types/TaskStatus'
import React, { FC } from 'react'
import { Route, Switch } from 'react-router'

import { Link } from '../../../core/Links'
import { TaskDetailsContainer } from './TaskDetailsContainer'

interface TaskDetailsRouterProps {
    url: string
    selectedTask?: Task
    isLoading: boolean
    isSendingMessage: boolean
    selectTask(v: TaskId): void
    syncTask(v?: TaskId): void
    previewLocation(v: Task): void
    editTask(v: Task): void
    cloneTask(v: Task): void
    deleteTask(v: TaskId): void
    closeTask(v: TaskId): void
    changeStatus(v: [TaskStatus, TaskId, AosSeverity]): void
    sendMessage(v: [string, TaskId]): void
    changeTaskChecklistItem(v: TaskChecklistItemChange): void
    changeTaskRecurrentChecklistItem(v: TaskRecurrentChecklistItemChange): void
}

export const TasksDetailsRouter: FC<TaskDetailsRouterProps> = props => {
    const getTaskId = (p: Record<string, string> = {}): TaskId | undefined => {
        if (p.taskId && !p.parentTaskId) {
            return {
                id: parseInt(p.taskId, 10),
            }
        } else if (p.parentTaskId) {
            const instanceId = parseInt(p.instanceId, 10)
            return {
                parentTaskId: parseInt(p.parentTaskId, 10),
                instanceId: Number.isInteger(instanceId) ? instanceId : undefined,
                id: parseInt(p.taskId, 10),
            }
        }
        return undefined
    }

    const content = (p: Record<string, string>) => (
        <TaskDetailsContainer taskId={getTaskId(p)} {...props} />
    )

    return (
        <Switch>
            <Route exact path={`${Link.TasksListMatch}/:taskId(\\d+)?`}>
                {p => content(p.match?.params as Record<string, string>)}
            </Route>
            <Route exact path={`${Link.TasksListMatch}/:parentTaskId(\\d+)/:instanceId(\\d+)`}>
                {p => content(p.match?.params as Record<string, string>)}
            </Route>
            <Route
                exact
                path={`${Link.TasksListMatch}/:parentTaskId(\\d+)/:taskId(\\d+)/:instanceId(\\d+)`}
            >
                {p => content(p.match?.params as Record<string, string>)}
            </Route>
        </Switch>
    )
}
