import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    defaultCustomOnTaskRecurrenceMonthly,
    defaultOnTaskRecurrenceMonthly,
    defaultTaskRecurrenceMonthly,
    TaskRecurrenceMonthlyValidation,
} from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceMonthlyFormPayload'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { Box } from 'aos-ui/src/components/base/Box'
import { NumberInput } from 'aos-ui/src/components/form/input/NumberInput'
import { LabeledRowFormElement } from 'aos-ui/src/components/form/labeled/LabeledRowFormElement'
import React, { FC, useState } from 'react'

import { RadioCheckbox } from '../../../common/form/RadioCheckbox'
import { RecurrenceCustomOnComponent } from './RecurrenceCustomOnComponent'
import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'

export interface RecurrenceOnState {
    customOn: boolean
    regularOn: boolean
}

export const TaskRecurrenceMonthly: FC<TaskRecurrenceMonthlyProps> = ({
    value,
    onChange,
    errors,
    canEdit,
}) => {
    if (value?.type !== TaskRecurrenceType.Monthly) {
        return null
    }
    const [recurrenceOn, setRecurrenceOn] = useState<RecurrenceOnState>({
        customOn: value.customOn !== undefined && value.customOn !== null,
        regularOn: value.customOn === undefined || value.customOn === null,
    })

    const changeOnRecurrenceType = (regularOn: boolean) => {
        if (canEdit) {
            setRecurrenceOn({
                regularOn: regularOn,
                customOn: !recurrenceOn.customOn,
            })
            regularOn
                ? onChange(defaultOnTaskRecurrenceMonthly)
                : onChange(defaultCustomOnTaskRecurrenceMonthly)
        }
    }

    const changeCustomOnRecurrenceType = (customOn: boolean) => {
        if (canEdit) {
            setRecurrenceOn({
                customOn: customOn,
                regularOn: !recurrenceOn.regularOn,
            })
            customOn
                ? onChange(defaultCustomOnTaskRecurrenceMonthly)
                : onChange(defaultOnTaskRecurrenceMonthly)
        }
    }

    return (
        <>
            <LabeledRowFormElement
                prefixLabel={translate('tasks.every')}
                suffixLabel={translate('tasks.months')}
                marginVertical={8}
                isError={errors?.every}
                errorMessage={translate('tasks.every.error')}
            >
                <NumberInput
                    value={value.every}
                    onChange={every => onChange({ every })}
                    width={50}
                    fallback={defaultTaskRecurrenceMonthly.every}
                    marginHorizontal={8}
                    disabled={!canEdit}
                />
            </LabeledRowFormElement>
            <Box flex={1} row>
                <RadioCheckbox
                    checked={recurrenceOn.regularOn}
                    onChange={regularOn => changeOnRecurrenceType(regularOn)}
                />
                <LabeledRowFormElement
                    prefixLabel={translate('tasks.on')}
                    suffixLabel={translate('tasks.day')}
                    marginVertical={8}
                    marginLeft={16}
                    isError={errors?.on}
                    errorMessage={translate('tasks.monthly.on.error')}
                >
                    <NumberInput
                        value={value.on}
                        onChange={on => onChange({ on })}
                        width={50}
                        fallback={defaultTaskRecurrenceMonthly.on}
                        marginHorizontal={8}
                        disabled={!canEdit}
                    />
                </LabeledRowFormElement>
            </Box>
            <Box flex={1} row fullWidth>
                <RadioCheckbox
                    checked={recurrenceOn.customOn}
                    onChange={customOn => changeCustomOnRecurrenceType(customOn)}
                />
                <RecurrenceCustomOnComponent value={value} onChange={onChange} errors={errors} canEdit={canEdit} />
            </Box>
        </>
    )
}

export interface TaskRecurrenceMonthlyProps extends TaskRecurrenceFormProps {
    errors?: TaskRecurrenceMonthlyValidation
}
