import { ChartItemContainer } from 'aos-frontend/src/app/views/statusDashboard/renderers/base/ItemContainer'
import {
    calculateChartDomainForRange,
    calculateDomain,
} from 'aos-helpers/src/helpers/domain/Domain'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { timeRangeFromNow } from 'aos-helpers/src/helpers/TimeRange'
import { groupByHours, groupByTobtType } from 'aos-helpers/src/helpers/tobt/mappers'
import { tobtLoadAction } from 'aos-services/src/core/statusDashboardData/cdm/actions'
import { tobtWebDataSelector } from 'aos-services/src/core/statusDashboardData/cdm/selectors'
import {
    AirlineTobtList,
    HandlerTobsList,
    HourTobtList,
    TobtKeyTypes,
} from 'aos-services/src/services/airportStatus/cdm/types/TobtTypes'
import {
    TobtDataTimeRangeType,
    TobtType,
} from 'aos-services/src/services/statusDashboard/types/filters/TobtFilters'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'

import { setTobtFiltersStateAction } from '../../../../core/statusDashboard/actions'
import { CdmItemState } from '../../../../services/statusDashboard/types/itemStates/CdmItemState'
import { PercentageFlightChart } from '../base/PercentageFlightChart'
import TobtFooterFilters from '../base/TobtFooterFilters'
import CdmTable from './CdmTable'

interface CdmTobtChartHoursProps {
    id: number
    itemState: CdmItemState
    hourTobt: HourTobtList
    airlineTobt: AirlineTobtList
    handlerTobs: HandlerTobsList
}

const selectedDay = {
    [TobtDataTimeRangeType.Today]: 0,
    [TobtDataTimeRangeType.Yesterday]: 1,
}

function CdmTobtChartHours({
    itemState,
    ...props
}: CdmTobtChartHoursProps & TobtChartHoursDispatchProps) {
    const { hourTobt, airlineTobt, handlerTobs } = props

    const tableData = useMemo(() => {
        if (itemState.tobtType === TobtType.Airline) {
            return (
                airlineTobt &&
                groupByTobtType(airlineTobt, TobtKeyTypes.airline, itemState.tobtDataTimeRange)
            )
        }
        if (itemState.tobtType === TobtType.GroundHandling) {
            return (
                handlerTobs &&
                groupByTobtType(handlerTobs, TobtKeyTypes.handler, itemState.tobtDataTimeRange)
            )
        }
        return []
    }, [itemState.tobtType, itemState.tobtDataTimeRange, airlineTobt, handlerTobs])

    const chartTobtData = useMemo(() => {
        if (itemState.tobtDataTimeRange === TobtDataTimeRangeType.Today) {
            return groupByHours(hourTobt).filter(f => f.date.isSame(dateTime(), 'day'))
        }

        if (itemState.tobtDataTimeRange === TobtDataTimeRangeType.Yesterday) {
            return groupByHours(hourTobt).filter(f => f.date.isBefore(dateTime(), 'day'))
        }
        return []
    }, [hourTobt, itemState.tobtDataTimeRange])

    useEffect(() => {
        props.tobtLoadAction()
    }, [])

    const yDomain = calculateDomain(
        [...chartTobtData],
        f =>
            f.later
                ? f.later
                : 0 + f.suspended
                ? f.suspended
                : 0 + f.invertedValue
                ? f.invertedValue
                : 0 + f.min0_5
                ? f.min0_5
                : 0,
        {
            count: 5,
            fixed: true,
        },
    )

    const range =
        chartTobtData &&
        timeRangeFromNow(
            dateTime()
                .subtract(selectedDay[itemState.tobtDataTimeRange], 'days')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            0,
            24,
        )

    const xDomain = range && calculateChartDomainForRange(range)

    const handleSetFilter = (f: Partial<CdmItemState>) => {
        props.setTobtFiltersStateAction([props.id, f])
    }

    return (
        <>
            <ChartItemContainer chart autoHeight={false}>
                <CdmTable data={tableData} type={itemState.tobtType} />
            </ChartItemContainer>
            <ChartItemContainer chart autoHeight>
                <ChartWrapper>
                    {hourTobt && (
                        <PercentageFlightChart
                            flights={chartTobtData}
                            yDomain={yDomain}
                            xDomain={xDomain}
                            timeRangeFilter={0}
                            color={Color.Black}
                        />
                    )}
                </ChartWrapper>
            </ChartItemContainer>
            <TobtFooterFilters itemState={itemState} setFilterAction={handleSetFilter} />
        </>
    )
}

interface TobtChartHoursDispatchProps {
    setTobtFiltersStateAction: (tobtFilters: [id: number, f: Partial<CdmItemState>]) => void
    tobtLoadAction: () => void
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setTobtFiltersStateAction: (tobtFilters: [id: number, f: Partial<CdmItemState>]) =>
        dispatch(setTobtFiltersStateAction(tobtFilters)),
    tobtLoadAction: () => dispatch(tobtLoadAction()),
})

export default connect(tobtWebDataSelector, mapDispatchToProps)(CdmTobtChartHours)

const ChartWrapper = styled.div`
    height: 100%;
`
