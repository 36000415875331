import { Pageable, pageableForList } from 'aos-helpers/src/helpers/Pageable'
import { AbstractEntity } from 'aos-services/src/services/base/types/AbstractEntity'
import { EventsFilter } from 'aos-services/src/services/events/transport/EventsFilter'
import { EventsSorting } from 'aos-services/src/services/events/transport/EventsSorting'
import { FeedInFilter } from 'aos-services/src/services/events/transport/FeedInPageRequest'
import { AosEventGroupOrEventOrFeedIn } from 'aos-services/src/services/events/types/AosEventGroup'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { find } from 'lodash'

export interface EventManagerState {
    feedInEvents: Pageable<AosFeedIn>
    feedInFilter: FeedInFilter

    events: Pageable<AosEventGroupOrEventOrFeedIn>
    eventsFilter: EventsFilter
    eventsSorting: EventsSorting

    overdueEventsCount: number
}

export interface EventManagerStateAware {
    eventManager: EventManagerState
}

export const initialEventManagerState: EventManagerState = {
    feedInEvents: pageableForList([]),
    feedInFilter: FeedInFilter.Current,
    events: pageableForList([]),
    eventsFilter: EventsFilter.Active,
    eventsSorting: EventsSorting.ByTime,
    overdueEventsCount: 0,
}

export const feedInForAbstractEntity = (
    e: AbstractEntity,
    events: Pageable<AosFeedIn>,
): AosFeedIn | undefined => find(events.content, t => t.id === e.id)
