import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import { BimLayerCategory } from 'aos-ui-map/src/components/map/bim/BimLayerCategory'
import { BimLayerName } from 'aos-ui-map/src/components/map/bim/BimLayerName'
import React from 'react'
import styled from 'styled-components'

import { LayersVisibilityCategory } from './LayersVisibilityCategory'

interface WorldLayersVisibilityCategoryProps {
    bimMapLayersVisibility: string[]
    setBimLayersVisibility(v: string[]): void
}

export const WorldLayersVisibilityCategory = (props: WorldLayersVisibilityCategoryProps) => {
    const { bimMapLayersVisibility, setBimLayersVisibility } = props
    const taskAndEvents = [BimLayerName.Events, BimLayerName.Tasks]
    return (
        <>
            <SidebarTitle>{translate('map.layer-selector.layers')}</SidebarTitle>
            <LayersVisibilityCategory
                key={BimLayerCategory.TaskAndEvents}
                category={BimLayerCategory.TaskAndEvents}
                bimMapLayerNames={taskAndEvents}
                bimMapLayersVisibility={bimMapLayersVisibility}
                setBimLayersVisibility={setBimLayersVisibility}
            />
        </>
    )
}

const SidebarTitle = styled(Text)`
    color: ${Color.Black};
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 24px;
`
