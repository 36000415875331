import { dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'

import { metadataRestService } from '../../dataaccess/tasks/metadataRestService'
import { BrushesVehicleDto, TaskMetadataDto } from '../../dataaccess/tasks/types/TaskMetadataDto'
import { AnimalSpecie } from './types/task/AnimalsTask'
import { DeIcingChemicals } from './types/task/DeIcingChemicals'
import { InspectionGroup } from './types/task/InspectionGroup'
import { MaintenanceJob } from './types/task/MaintenanceTask'
import { RescueServiceTaskType } from './types/task/RescueServiceTask'
import { TaskMetadata } from './types/TaskMetadata'

class MetadataService {
    public getMetadata = (): Promise<TaskMetadata> =>
        metadataRestService.getTaskMetadata().then(this.fromDto)
    private fromDto = (data: TaskMetadataDto): TaskMetadata => ({
        animalSpecies: data.animalSpecies as AnimalSpecie[],
        deicingCars: data.deicingCars,
        deicingAreas: data.deicingAreas,
        deicingChemicals: data.deicingChemicals as DeIcingChemicals[],
        maintenanceJobs: data.maintenanceJobs as MaintenanceJob[],
        rescueServiceTaskTypes: data.rescueServiceTaskTypes as RescueServiceTaskType[],
        inspectionTypes: data.inspectionTypes.map(inspection => ({
            id: inspection.id,
            name: inspection.name,
            period: inspection.period,
            inspectionGroup: inspection.inspectionGroup as InspectionGroup,
            taskEndTime: dateTimeFromOptional(inspection.taskEndTime),
        })),
        brushesVehicles: this.addOtherToBrushesVehicle(data.brushesVehicles),
        fireTrucks: data.fireTrucks,
        brushesBrands: data.brushesBrands,
    })

    private addOtherToBrushesVehicle(brushesVehicles: BrushesVehicleDto[]) {
        return [
            ...brushesVehicles,
            {
                label: 'Other',
            } as BrushesVehicleDto
        ]
    }
}

export const metadataService = new MetadataService()
