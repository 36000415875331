import {
    FIREFIGHTER_UPDATE_SHIFT,
    FIREFIGHTER_UPDATE_SHIFT_SUCCESS,
    FIREFIGHTERS_LOAD_SHIFT,
    FIREFIGHTERS_LOAD_SHIFT_SUCCESS,
    FIRETRUCKS_LOAD,
    FIRETRUCKS_LOAD_SUCCESS,
} from 'aos-services/src/core/firefighters/actions'

import {
    CLOSE_FIRE_FIGHTER_FORM,
    FirefighterAction,
    FIREFIGHTERS_RELOAD,
    FIREFIGHTERS_RELOAD_SUCCESS,
    OPEN_FIRE_FIGHTER_FORM,
} from './actions'
import { FirefightersState, initialFirefightersState } from './state'

export const firefightersReducer = (
    state = initialFirefightersState,
    action: FirefighterAction,
): FirefightersState => {
    switch (action.type) {
        case FIREFIGHTERS_LOAD_SHIFT:
        case FIREFIGHTERS_RELOAD:
        case FIRETRUCKS_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case FIREFIGHTERS_RELOAD_SUCCESS:
        case FIREFIGHTERS_LOAD_SHIFT_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }

        case FIRETRUCKS_LOAD_SUCCESS:
            return {
                ...state,
                trucks: action.payload,
            }

        case FIREFIGHTER_UPDATE_SHIFT:
            return {
                ...state,
                isFormLoading: true,
            }

        case FIREFIGHTER_UPDATE_SHIFT_SUCCESS:
            return {
                ...state,
                isFormLoading: false,
            }

        case OPEN_FIRE_FIGHTER_FORM:
            return {
                ...state,
                isOpen: true,
                shiftToUpdate: action.payload,
            }

        case CLOSE_FIRE_FIGHTER_FORM:
            return {
                ...state,
                isOpen: false,
                shiftToUpdate: undefined,
            }

        default:
            return state
    }
}
