import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Color } from 'aos-ui-common/src/styles/Color'
import { concat, Dictionary, isUndefined } from 'lodash'

import { RingRailTrainPosition } from './RingRailTrainPosition'

export const TRAIN_SCHEDULE_THRESHOLD_AT_RISK: number = 0
export const TRAIN_SCHEDULE_THRESHOLD_DELAYED: number = 5
export const TRAIN_SCHEDULE_BASE_STATION: string = 'LEN'
export const TRAIN_SCHEDULE_BASE_STATION_OFFSET: number = 2

export type RingRailTrain = RingRailTrainProps & RingRailTrainPosition

export interface RingRailTrainsInfo {
    trains: RingRailTrain[]
    stations: Dictionary<RingRailStation>
}

export interface RingRailStation {
    stationName?: string
    stationShortCode?: string
    longitude?: number
    latitude?: number
}

export interface RingRailTrainRoute {
    from?: string
    to?: string
}

export interface RingRailTrainProps {
    trainNumber: number
    commuterLine: string
    trainType: string

    trainStatus: RingRailTrainStatus
    scheduleStatus: RingRailTrainScheduleStatus

    route: RingRailTrainRoute
    currentRoute: RingRailTrainRoute

    currentStation?: RingRailTimeTableRow
    previousStations: RingRailTimeTableRow[]
    nextStations: RingRailTimeTableRow[]
}

export interface RingRailTimeTableRow {
    stationShortCode: string
    commercialTrack: string

    arrivalScheduledTime?: DateTime
    arrivalEstimateTime?: DateTime
    arrivalActualTime?: DateTime

    departureScheduledTime?: DateTime
    departureEstimateTime?: DateTime
    departureActualTime?: DateTime

    differenceInMinutes?: number

    isCurrentStation: boolean
    scheduleStatus: RingRailTrainScheduleStatus
}

export enum RingRailTrainScheduleStatus {
    Unknown = 1,
    OnTime = 2,
    AtRisk = 3,
    Delayed = 4,
}

export enum RingRailTrainStatus {
    Running = 'Running',
    AtTheStation = 'AtTheStation',
    Canceled = 'Canceled',
    Unknown = 'Unknown',
}

const trainScheduleStatusColorsMap = new Map<RingRailTrainScheduleStatus, Color>([
    [RingRailTrainScheduleStatus.OnTime, Color.Green],
    [RingRailTrainScheduleStatus.AtRisk, Color.Orange],
    [RingRailTrainScheduleStatus.Delayed, Color.Red],
    [RingRailTrainScheduleStatus.Unknown, Color.Primary],
])

export const shouldShowStationInSchedule = (
    trainStation: RingRailTimeTableRow,
    stationIndex: number,
    baseStationIndex: number,
): boolean =>
    trainStation.stationShortCode === TRAIN_SCHEDULE_BASE_STATION ||
    (baseStationIndex !== -1 &&
        stationIndex >= baseStationIndex - TRAIN_SCHEDULE_BASE_STATION_OFFSET &&
        stationIndex <= baseStationIndex + TRAIN_SCHEDULE_BASE_STATION_OFFSET)

export const getColorForRingRailTrainScheduleStatus = (
    status?: RingRailTrainScheduleStatus,
): Color => (status ? trainScheduleStatusColorsMap.get(status)! : Color.Primary)

export const isStationTheUpcomingStation = (
    trainStation: RingRailTimeTableRow,
    upcomingStation: RingRailTimeTableRow | undefined,
): boolean =>
    !!upcomingStation &&
    trainStation.stationShortCode === upcomingStation!.stationShortCode &&
    isUndefined(trainStation.departureActualTime)

export const shouldShowStationForTrain = (
    trainStation: RingRailTimeTableRow,
    showStationInSchedule: boolean,
    upcomingStation: RingRailTimeTableRow | undefined,
): boolean => showStationInSchedule || isStationTheUpcomingStation(trainStation, upcomingStation)

export const getUpcomingStationForTrain = (
    train: RingRailTrain,
): RingRailTimeTableRow | undefined => train.currentStation || train.nextStations[0]

export type RingRailTimeTableRowWithShowSchedule = [RingRailTimeTableRow, boolean]

export const getRingRailTrainTimeTable = (
    train: RingRailTrain,
): RingRailTimeTableRowWithShowSchedule[] => {
    const allStations = concat(train.previousStations, train.nextStations)
    const upcomingStation = getUpcomingStationForTrain(train)
    const baseStationIndex = allStations.findIndex(
        s => s.stationShortCode === TRAIN_SCHEDULE_BASE_STATION,
    )

    return allStations
        .map(
            (s, index) =>
                [
                    s,
                    shouldShowStationInSchedule(s, index, baseStationIndex),
                ] as RingRailTimeTableRowWithShowSchedule,
        )
        .filter(s => shouldShowStationForTrain(s[0], s[1], upcomingStation))
}

export const getTrainForTrainNumber = (
    trains: RingRailTrain[],
    trainNumber: number,
): RingRailTrain | undefined => trains.find(t => t.trainNumber === trainNumber)
