import { union, without } from 'lodash'

import { ADD_ALREADY_SHOWN, OneTimeAction, REMOVE_ALREADY_SHOWN } from './actions'
import { initialOneTimeState, OneTimeState } from './state'

export function oneTimeReducer(
    state: OneTimeState = initialOneTimeState,
    action: OneTimeAction,
): OneTimeState {
    switch (action.type) {
        case ADD_ALREADY_SHOWN:
            return {
                alreadyShown: union(state.alreadyShown, [action.payload]),
            }

        case REMOVE_ALREADY_SHOWN:
            return {
                alreadyShown: without(state.alreadyShown, action.payload),
            }

        default:
            return state
    }
}
