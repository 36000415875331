import { Pageable, pageableForList } from 'aos-helpers/src/helpers/Pageable'
import { FirefighterShift } from 'aos-services/src/services/tasks/firefighters/types/FirefighterShift'
import { FireTruck } from 'aos-services/src/services/tasks/types/task/FireTruck'

export interface FirefightersStateAware {
    firefighters: FirefightersState
}

export interface FirefightersState {
    isLoading: boolean
    isFormLoading: boolean
    isOpen: boolean
    list: Pageable<FirefighterShift>
    shiftToUpdate?: FirefighterShift
    trucks: FireTruck[]
}

export const initialFirefightersState: FirefightersState = {
    isLoading: false,
    isFormLoading: false,
    isOpen: false,
    list: pageableForList(),
    trucks: [],
}
