import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'

export enum PaxForecastDayViewType {
    Hours,
    TimeOfDay = 42,
}

export const translatePaxForecastDayViewType = translateEnum<PaxForecastDayViewType>(
    PaxForecastDayViewType,
    'dashboard.pax-forecast.filters.day-view-type',
)
