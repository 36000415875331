import { dateTime } from 'aos-helpers/src/helpers/Time'
import { EnumValues } from 'enum-values'
import { sumBy } from 'lodash'

import { Restriction } from './Restriction'
import { RestrictionAreaType } from './RestrictionArea'
import { RestrictionsGroupedStats, toRestrictionsGroupedStats } from './RestrictionsGroupedStats'

export interface RestrictionsStats {
    active: number
    areaType: RestrictionAreaType
    grouped: RestrictionsGroupedStats[]
}

export const toRestrictionStats = (restrictions: Restriction[]): RestrictionsStats[] => {
    const activeRestrictions = restrictions.filter(isActive)
    const groupedStats = toRestrictionsGroupedStats(activeRestrictions)

    return EnumValues.getValues<RestrictionAreaType>(RestrictionAreaType).map(
        (restrictionAreaType): RestrictionsStats => {
            const areaStats = groupedStats.filter(stats => stats.areaType === restrictionAreaType)

            return {
                areaType: restrictionAreaType,
                active: sumBy(areaStats, stat => stat.active),
                grouped: areaStats,
            }
        },
    )
}

const isActive = (restriction: Restriction): boolean => {
    const now = dateTime()
    return now.isBefore(restriction.endDate) || now.isAfter(restriction.startDate)
}
