import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Box } from 'aos-ui/src/components/base/Box'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { Header, HeaderVariant } from 'aos-ui/src/components/header/Header'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FCWithChildren } from 'react'

interface SideContainerHeaderProps {
    title: string
    isPinned?: boolean
    variant?: HeaderVariant
    close(): void
    onPin?(v: boolean): void
}

export const SideContainerHeader: FCWithChildren<SideContainerHeaderProps> = props => {
    const { isPinned, onPin, variant = HeaderVariant.Notifications } = props
    return (
        <Header variant={variant} paddingHorizontal={30} shrink={0} row>
            <Box fullWidth>{props.title}</Box>
            {onPin && (
                <IconButton
                    svg={isPinned ? SvgIcon.Pinned : SvgIcon.Pin}
                    iconSize={BlockSize.Std}
                    iconVariant={IconVariant.White}
                    onClick={() => onPin(!isPinned)}
                />
            )}
            {onPin && !isPinned && (
                <IconButton
                    marginLeft={16}
                    svg={SvgIcon.Close}
                    iconSize={BlockSize.Std}
                    iconVariant={IconVariant.White}
                    onClick={props.close}
                />
            )}
        </Header>
    )
}
