import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box, BoxProps } from '../../base/Box'

interface ModalFooterProps extends BoxProps {
    topSeparator?: boolean
}

export const ModalFooter: FCWithChildren<PropsWithChildren<ModalFooterProps>> = props => {
    const { topSeparator, children } = props
    return (
        <Wrapper
            row
            paddingVertical={20}
            paddingHorizontal={30}
            shrink={0}
            topSeparator={topSeparator}
        >
            <Box rowReverse flex={1}>
                {children}
            </Box>
        </Wrapper>
    )
}

const Wrapper = styled(Box)<{ topSeparator?: boolean }>`
    ${p =>
        p.topSeparator &&
        css`
            border: 1px solid ${Color.Grey1};
        `}
`
