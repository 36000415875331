import { values } from 'lodash'
import { Task } from 'redux-saga'

export class ListenerAggregator {
    // shared listeners
    private listeners: {
        [key: string]: {
            task: Task
            components: Set<string>
        }
    }

    constructor() {
        this.listeners = {}
    }

    public registerComponent = (listenerName: string, component: string | number) => {
        this.listeners[listenerName].components.add(component.toString())
    }

    public freeComponent = (listenerName: string, component: string | number) => {
        this.listeners[listenerName].components.delete(component.toString())
    }

    public getComponents = (listenerName: string) => this.listeners[listenerName].components

    public getTask = (listenerName: string) => this.listeners[listenerName].task

    public registerListener = (label: string, task: any, component: string | number) => {
        this.listeners[label] = {
            task,
            components: new Set<string>([component.toString()]),
        }
    }

    public changeListenerTask = (label: string, task: any) => {
        if (this.listeners[label]) {
            this.listeners[label].task = task
        }
    }

    // common
    public hasListener = (listenerName: string) => this.getListenerTask(listenerName) !== undefined

    public getRunningTasks = (): Task[] => [...values(this.listeners).map(t => t.task)]

    public resetListeners = () => {
        this.listeners = {}
    }

    public resetListener = (name: string) => {
        delete this.listeners[name]
    }

    private getListenerTask = (name: string) =>
        this.listeners[name] ? this.listeners[name].task : undefined
}
