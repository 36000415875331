import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../../dataaccess/sts/types/SendToSyncType'
import { paxForecastService } from '../../../services/airportStatus/paxForecast/paxForecastService'
import { PaxForecast } from '../../../services/airportStatus/paxForecast/types/PaxForecast'
import { firebaseService } from '../../../services/firebase/firebaseService'
import {
    PAX_FORECAST_LOAD,
    PAX_FORECAST_SYNC,
    PAX_FORECAST_SYNC_STOP,
    paxForecastLoadAction,
    paxForecastLoadSuccessAction,
    PaxForecastSyncAction,
    PaxForecastSyncStopAction,
} from './actions'

function* paxForecastSyncSaga() {
    yield takeEvery<PaxForecastSyncAction>(
        PAX_FORECAST_SYNC,
        function* (action: PaxForecastSyncAction) {
            yield* firebaseService.onListener(
                'paxForecast',
                function* () {
                    yield syncValue(
                        sendToSyncRepository.stsRef(SendToSyncType.PAX_FORECAST),
                        paxForecastLoadAction,
                        false,
                    )
                },
                action.payload,
            ) as Generator
        },
    )
}

function* paxForecastSyncStopSaga() {
    yield takeEvery<PaxForecastSyncStopAction>(
        PAX_FORECAST_SYNC_STOP,
        function* (action: PaxForecastSyncStopAction) {
            yield firebaseService.offListener('paxForecast', action.payload)
        },
    )
}

function* paxForecastLoadSaga() {
    yield takeEvery(PAX_FORECAST_LOAD, function* () {
        const data: PaxForecast = yield call(paxForecastService.getPaxForecast)
        yield put(paxForecastLoadSuccessAction(data))
    })
}

export const paxForecastSagas = [paxForecastSyncSaga, paxForecastSyncStopSaga, paxForecastLoadSaga]
