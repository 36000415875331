import { translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { MapVariant } from 'aos-services/src/services/common/types/MapVariant'
import { HeaderFilter } from 'aos-ui/src/components/header/HeaderFilter'
import { HeaderFilterVariant } from 'aos-ui/src/components/header/HeaderFilterItem'
import { EnumValues } from 'enum-values'
import React, { FC } from 'react'

interface MapVariantHeaderFilterProps {
    mapVariant: MapVariant
    variant?: HeaderFilterVariant
    onChange(v: MapVariant): void
}

export const MapVariantHeaderFilter: FC<MapVariantHeaderFilterProps> = props => (
    <HeaderFilter
        items={EnumValues.getValues<MapVariant>(MapVariant)}
        value={props.mapVariant}
        variant={props.variant}
        onChange={props.onChange}
        valueFormatter={translateEnum<MapVariant>(MapVariant, 'map.variant')}
    />
)
