import React from 'react'

import { Scrollbar } from './Scrollbar'

export class DarkScrollbar extends Scrollbar {
    protected getThumbStyle(): Partial<React.CSSProperties> {
        return {
            backgroundColor: 'rgba(59, 75, 106, .8)',
            borderRadius: '5px',
        }
    }
}
