import { firebaseApp } from 'firebaseApp'

import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { SendToSyncForAirportType, SendToSyncType } from './types/SendToSyncType'

export class SendToSyncRepository {
    public stsRef = (sts: SendToSyncType) => firebaseApp.database().ref(`sendToSync/${sts}`)
}

export class SendToSyncForAirportRepository {
    public stsRef = (sts: SendToSyncForAirportType, airport: AosAirport) =>
        firebaseApp.database().ref(`sendToSyncForAirport/${airport}/${sts}`)
}

export const sendToSyncRepository = new SendToSyncRepository()
export const sendToSyncForAirportRepository = new SendToSyncForAirportRepository()
