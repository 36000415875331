import {
    DndDropSpec,
    DndItemType,
    draggableWrapper,
    droppableWrapper,
} from 'aos-components/src/helpers/DnD'
import { DnDWrapper } from 'aos-components/src/helpers/DnDWrapper'
import { BaseDragSourceProps, BaseDropTargetProps } from 'aos-components/src/helpers/DragAndDrop'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkListItem } from 'aos-ui/src/components/darkListItem/DarkListItem'
import {
    DarkListItemTextBlock,
    DarkListItemTextBlockVariant,
} from 'aos-ui/src/components/darkListItem/DarkListItemTextBlock'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import React, { PureComponent } from 'react'
import { DragSourceSpec } from 'react-dnd'

import { ChecklistItemIcon } from '../../../common/ChecklistItemIcon'

export class ChecklistItemClass extends PureComponent<
    ChecklistItemClassProps & BaseDragSourceProps & BaseDropTargetProps
> {
    public render() {
        const {
            item: { content, type, title },
            connectDragSource,
            connectDropTarget,
            isDragging,
            isEditable,
            isEven,
            onEdit,
            onDelete,
            item,
        } = this.props
        const deleteItem = () => onDelete(item)

        return (
            <DnDWrapper
                draggable={isEditable}
                droppable={isEditable}
                connectDragSource={connectDragSource}
                connectDropTarget={connectDropTarget}
            >
                <DarkListItem
                    row
                    paddingVertical={12}
                    justify='space-between'
                    marginBottom={12}
                    paddingHorizontal={12}
                    isDragging={isDragging}
                    cursor={isEditable ? 'pointer ' : 'default'}
                    lightBg={!isEven}
                >
                    <MoreHover row flex={1}>
                        <Box flex={1} row>
                            <ChecklistItemIcon type={type} />
                            <DarkListItemTextBlock
                                subtitle={content}
                                title={title}
                                variant={DarkListItemTextBlockVariant.DarkListItemChecklist}
                                noTextOverflow
                            />
                        </Box>
                        <Box>
                            {isEditable && (
                                <More>
                                    <MoreItem onClick={deleteItem}>
                                        {translate('checklist-manager.item-more.delete')}
                                    </MoreItem>
                                    <MoreItem onClick={onEdit}>
                                        {translate('checklist-manager.item-more.edit')}
                                    </MoreItem>
                                </More>
                            )}
                        </Box>
                    </MoreHover>
                </DarkListItem>
            </DnDWrapper>
        )
    }
}

interface ChecklistItemClassProps {
    item: AosChecklistItem
    isEditable?: boolean
    isEven: boolean
    seq: number
    onMove(v: number, v2: number): void
    onApplyMove(): void
    onEdit(): void
    onDelete(v: AosChecklistItem): void
}

const checklistItemSource: DragSourceSpec<ChecklistItemClassProps, ChecklistItemClassProps> = {
    beginDrag(props) {
        return {
            ...props,
        }
    },
    endDrag(props) {
        props.onApplyMove()
    },
}

const checklistItemTargetSpec: DndDropSpec<ChecklistItemClassProps> = {
    hover(dropElementProps, monitor) {
        const dragElementProps = monitor.getItem() as ChecklistItemClassProps
        if (dragElementProps && dragElementProps.seq !== dropElementProps.seq) {
            dragElementProps.onMove(dragElementProps.seq, dropElementProps.seq)
        }
    },
}

const dndItem = (type: DndItemType) =>
    droppableWrapper<ChecklistItemClassProps>(
        type,
        checklistItemTargetSpec,
    )(
        draggableWrapper<ChecklistItemClassProps, ChecklistItemClassProps>(
            type,
            checklistItemSource,
        )(ChecklistItemClass),
    )

export const ChecklistItem = dndItem(DndItemType.ChecklistItem)

export const ChecklistMessageTemplate = dndItem(DndItemType.ChecklistMessageTemplate)
