import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { canEditTasksSelector } from 'aos-services/src/core/auth/state'
import {
    TaskChecklistItemChange,
    TaskRecurrentChecklistItemChange,
} from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { TaskStatus } from 'aos-services/src/services/tasks/types/TaskStatus'
import { PanelPlaceholder } from 'aos-ui/src/components/container/PanelPlaceholder'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { TaskDetails } from './TaskDetails'

export interface TaskDetailsContainerProps extends TaskDetailsContainerStateProps {
    url: string
    taskId?: TaskId
    selectedTask?: Task
    isLoading: boolean
    isSendingMessage: boolean
    selectTask(v: TaskId): void
    syncTask(v?: TaskId): void
    previewLocation(v: Task): void
    editTask(v: Task): void
    cloneTask(v: Task): void
    deleteTask(v: TaskId): void
    closeTask(v: TaskId): void
    changeStatus(v: [TaskStatus, TaskId, AosSeverity]): void
    sendMessage(v: [string, TaskId]): void
    changeTaskChecklistItem(v: TaskChecklistItemChange): void
    changeTaskRecurrentChecklistItem(v: TaskRecurrentChecklistItemChange): void
}

export const TaskDetailsContainerComponent: FC<TaskDetailsContainerProps> = ({
    taskId,
    isLoading,
    canEdit,
    selectedTask,
    sendMessage,
    isSendingMessage,
    editTask,
    deleteTask,
    previewLocation,
    changeStatus,
    syncTask,
    closeTask,
    cloneTask,
    selectTask,
    changeTaskChecklistItem,
    changeTaskRecurrentChecklistItem,
    url,
}) => {
    useEffect(() => {
        if (taskId) {
            selectTask(taskId)
            syncTask(taskId)
        }
        return () => {
            syncTask(undefined)
        }
    }, [taskId?.id, taskId?.instanceId, taskId?.parentTaskId])

    return (
        <DarkScrollbar>
            {isLoading && !selectedTask && <Spinner />}
            {selectedTask && (
                <TaskDetails
                    url={url}
                    key={selectedTask.id}
                    task={selectedTask}
                    isSendingMessage={isSendingMessage}
                    canEdit={canEdit}
                    onClone={() => cloneTask(selectedTask)}
                    openLocation={() => selectedTask && previewLocation(selectedTask)}
                    onDelete={() => taskId && deleteTask(taskId)}
                    onEdit={() => editTask(selectedTask)}
                    onClose={() => closeTask(selectedTask)}
                    onMessage={message => sendMessage([message, selectedTask!])}
                    onStatusChange={status =>
                        changeStatus([status, selectedTask!, selectedTask?.severity])
                    }
                    onChecklistItemChange={(checklistId, itemId, value) => {
                        if (!selectedTask?.id) {
                            const checklist = selectedTask?.checklists?.find(
                                q => q.templateId === checklistId,
                            )
                            const templateId = checklist?.templateId
                            const seq = checklist?.items.find(q => q.id === itemId)?.sequenceNumber
                            changeTaskRecurrentChecklistItem([
                                selectedTask?.parentTaskId!,
                                selectedTask?.instanceId!,
                                templateId!,
                                seq!,
                                value,
                            ])
                        } else {
                            changeTaskChecklistItem([selectedTask.id, checklistId, itemId, value])
                        }
                    }}
                />
            )}
            {!isLoading && !selectedTask && (
                <PanelPlaceholder
                    label={translate('tasks.task-preview.placeholder')}
                    svg={SvgIcon.CreateTaskPlaceholder}
                />
            )}
        </DarkScrollbar>
    )
}

interface TaskDetailsContainerStateProps {
    canEdit: boolean
}

export const TaskDetailsContainer = connect<TaskDetailsContainerStateProps>(
    createStructuredSelector({
        canEdit: canEditTasksSelector,
    }),
)(TaskDetailsContainerComponent)
