import { getUniqueValues } from 'aos-helpers/src/helpers/Array'
import { FLIGHTS_LOAD_SUCCESS } from 'aos-services/src/core/flightInformation/actions'
import { TOWING_LOAD_SUCCESS } from 'aos-services/src/core/towings/actions'
import { flightFilterService } from 'aos-services/src/services/flightInformation/FlightFilterService'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Towing } from 'aos-services/src/services/flightInformation/types/towing/Towing'

import {
    CHANGE_SORTING,
    FlightInformationAction,
    LOCK_NOW,
    SAVE_LAST_FILTERS,
    SEND_SHARE_EMAIL,
    SEND_SHARE_EMAIL_SUCCESS,
    SET_QUICK_SEARCH,
    SHOW_CUSTOMIZE_MODAL,
    SHOW_SHARE_MODAL,
} from './actions'
import {
    FlightInformationColumnValues,
    FlightInformationState,
    initialFlightInformationState,
} from './state'

export function flightInformationReducer(
    state = initialFlightInformationState,
    action: FlightInformationAction,
): FlightInformationState {
    switch (action.type) {
        case FLIGHTS_LOAD_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
                airlines: flightFilterService.pickUniqAirlinesFromList(action.payload),
                columnValues: {
                    ...state.columnValues,
                    ...getFlightColumnValues(action.payload),
                },
            }

        case TOWING_LOAD_SUCCESS:
            return {
                ...state,
                towingList: action.payload,
                isTowingLoading: false,
                columnValues: {
                    ...state.columnValues,
                    ...getTowingColumnValues(action.payload),
                },
            }

        case SAVE_LAST_FILTERS: {
            const [type, payload] = action.payload
            return { ...state, lastFilters: { ...state.lastFilters, [type]: payload } }
        }

        case SHOW_SHARE_MODAL:
            return { ...state, showShareModal: action.payload }

        case SHOW_CUSTOMIZE_MODAL:
            return { ...state, showCustomizeModal: action.payload }

        case SEND_SHARE_EMAIL:
            return { ...state, sendingInProgress: true }

        case SEND_SHARE_EMAIL_SUCCESS:
            return { ...state, sendingInProgress: false }

        case LOCK_NOW:
            return { ...state, nowLocked: action.payload }

        case CHANGE_SORTING:
            const {
                type,
                value: { field },
            } = action.payload
            const currentField = state.sorting[type]
            const fieldValue = field ?? currentField.field
            const sameField = currentField.field === field
            const newSorting = {
                ...state.sorting,
                [type]: {
                    field: fieldValue,
                    desc: sameField ? !currentField.desc : true,
                },
            }
            return {
                ...state,
                sorting: newSorting,
            }

        case SET_QUICK_SEARCH:
            return {
                ...state,
                quickSearch: action.payload,
            }

        default:
            return state
    }
}

const getFlightColumnValues = (flights: Flight[]): Partial<FlightInformationColumnValues> => ({
    hapt: getUniqueValues(flights, flight => flight.hapt),
    naflt: getUniqueValues(flights, flight => flight.naflt.code),
    routes: getUniqueValues(flights, flight => flight.routes[0] && flight.routes[0].airport),
    actype: getUniqueValues(flights, flight => flight.actype),
    prm: getUniqueValues(flights, flight => flight.prm.text),
    bltarea: getUniqueValues(flights, flight => flight.bltarea),
    bag: getUniqueValues(flights, flight => flight.bag.indicator),
    door: getUniqueValues(flights, flight => flight.door),
    rwy: getUniqueValues(flights, flight => flight.rwy),
})

const getTowingColumnValues = (towings: Towing[]): Partial<FlightInformationColumnValues> => ({
    handl: getUniqueValues(towings, towing => towing.handl),
})
