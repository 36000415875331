import { formatMinutesAsDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { translateTrend } from 'aos-helpers/src/helpers/trend/Trend'
import { TrendChangeVariant } from 'aos-helpers/src/helpers/TrendChangeVariant'
import { FlightsType } from 'aos-services/src/services/airportStatus/flights/types/FlightsType'
import { RegularityStats } from 'aos-services/src/services/airportStatus/flights/types/RegularityStats'
import { LabeledDispersionBar } from 'aos-ui/src/components/bar/LabeledDispersionBar'
import { Box } from 'aos-ui/src/components/base/Box'
import { TrendMeasure } from 'aos-ui/src/components/chart/trend/TrendMeasure'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface DelaysStatsSectionProps {
    stats: RegularityStats
}

export const DelaysStatsSection: FC<DelaysStatsSectionProps> = props => {
    const { stats } = props
    const allTrend = stats[FlightsType.All].trend
    const translateTrendTooltip = translateTrend('dashboard.delays.trend.tooltip')
    return (
        <Box row fullWidth>
            <Tooltip body={translateTrendTooltip(allTrend)} placement='top' withArrow>
                <div>
                    <Box alignItems='flex-start' marginRight={20}>
                        <TrendMeasure
                            value={formatMinutesAsDuration(stats[FlightsType.All].value) || '-'}
                            trend={allTrend}
                            label={translate('dashboard.delays.label.average')}
                            trendVariant={TrendChangeVariant.PreferredDown}
                        />
                    </Box>
                </div>
            </Tooltip>
            <LabeledDispersionBar
                marginTop={2}
                barConfig={{
                    leftColor: Color.PaleOrange,
                    rightColor: Color.Violet,
                    showValues: true,
                }}
                barData={[
                    {
                        value: stats[FlightsType.Arrivals].value,
                        displayValue: formatMinutesAsDuration(stats[FlightsType.Arrivals].value),
                        label: translate('dashboard.delays.label.arrivals'),
                    },
                    {
                        value: stats[FlightsType.Departures].value,
                        displayValue: formatMinutesAsDuration(stats[FlightsType.Departures].value),
                        label: translate('dashboard.delays.label.departures'),
                    },
                ]}
            />
        </Box>
    )
}
