import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskVariant } from 'aos-services/src/dataaccess/tasks/types/TaskDto'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import moment from 'moment'
import { z } from 'zod'

const CheckboxItemZod = z.tuple([z.nativeEnum(TaskVariant), z.boolean()])
const CheckboxAirportItemZod = z.tuple([z.nativeEnum(AosAirport), z.boolean()])
// @ts-ignore
const MomentZod = z.instanceof(moment)

const exportFormSchema = z
    .object({
        taskTypes: z.array(CheckboxItemZod),
        timeStart: MomentZod,
        timeEnd: MomentZod,
        categories: z.array(z.string()),
        airports: z.array(CheckboxAirportItemZod),
    })
    .partial()
    .refine(
        data => {
            return data.taskTypes ? data.taskTypes?.filter(q => q[1]).length > 0 : false
        },
        {
            message: translate('export-tasks.task-types.required'),
            path: ['taskTypes'],
        },
    )
    .refine(
        data => {
            return data.airports ? data.airports?.filter(q => q[1]).length > 0 : false
        },
        { message: translate('export-tasks.airports.required'), path: ['airports'] },
    )
    .refine(data => !!data.timeStart, {
        path: ['timeStart'],
        message: translate('export-tasks.from.required'),
    })
    .refine(data => !!data.timeEnd, {
        path: ['timeEnd'],
        message: translate('export-tasks.to.required'),
    })
    .refine(
        data => {
            return data.timeStart && data.timeEnd ? data.timeStart < data.timeEnd : true
        },
        {
            message: translate('export-tasks.error.chronological'),
            path: ['isChronological'],
        },
    )
    .refine(
        data =>
            data.taskTypes?.find(q =>
                q[0] === TaskVariant.TASKS && q[1] ? data.categories?.length : true,
            ),
        {
            message: translate('export-tasks.categories.required'),
            path: ['categories'],
        },
    )

export { exportFormSchema }
