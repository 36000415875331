import moment from 'moment'

export function countDiffTime(date: moment.Moment | undefined) {
    if (!date) {
        return
    }
    const today = moment()

    const minutes = Math.abs(date.diff(today, 'minutes') % 60)
    const formattedMinutes = String(minutes).padStart(2, '0')

    return {
        days: date.diff(today, 'days'),
        hours: Math.abs(date.diff(today, 'hours')),
        minutes: formattedMinutes,
        when: today.isBefore(date) ? 'before' : 'after',
    }
}

export function arriveTimeSplit(arriveTime: moment.Moment) {
    const formattedMinutes = String(arriveTime.minutes()).padStart(2, '0')

    return {
        days: arriveTime.days(),
        hours: arriveTime.hours(),
        minutes: formattedMinutes,
    }
}
