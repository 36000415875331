import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import React from 'react'
import { useDispatch } from 'react-redux'

import { hideActionModalAction } from '../../actionModal/actions'

export const LVPModalFooter = () => {
    const dispatch = useDispatch()

    return (
        <Box column width='100%' rowGap={16}>
            <FormButton
                onClick={() => dispatch(hideActionModalAction(true))}
                label={translate('tasks.lvpCounter.modal.primaryButton')}
                marginHorizontal={30}
            />
            <FormButton
                onClick={() => dispatch(hideActionModalAction(false))}
                variant={FormButtonVariant.Text}
                fullWidth
                label={translate('tasks.lvpCounter.modal.secondaryButton')}
            />
        </Box>
    )
}
