import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Box } from 'aos-ui/src/components/base/Box'
import { IdAware } from 'aos-ui/src/components/base/IdAware'
import { BaseDropdown } from 'aos-ui/src/components/form/dropdown/base/BaseDropdown'
import {
    dropdownHeight,
    DropdownInput,
    DropdownLabel,
    DropdownPlaceholder,
} from 'aos-ui/src/components/form/dropdown/base/DropdownContent'
import { DropdownItemHoverable } from 'aos-ui/src/components/form/dropdown/base/DropdownItemHoverable'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import FuzzySearch from 'fuse.js'
import { VALUE_DOWN, VALUE_ESCAPE, VALUE_RETURN, VALUE_UP } from 'keycode-js'
import { identity, isEqual } from 'lodash'
import React, { KeyboardEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

export interface DropdownAutocompleteCreateNewProps extends IdAware {
    items: string[]
    value?: string

    variant?: DropdownVariant
    placeholder?: string
    width?: DropdownWidth
    small?: boolean
    outlined?: boolean
    maxHeight?: number

    valueRenderer?(item: string): ReactNode
    labelRenderer(item: string): string
    onChange(item?: string): void
}

export const DropdownAutocompleteCreateNew = ({
    value,
    id,
    items,
    variant = DropdownVariant.White,
    labelRenderer = identity,
    valueRenderer = labelRenderer,
    placeholder,
    width,
    onChange,
    small,
    outlined,
    maxHeight,
}: DropdownAutocompleteCreateNewProps) => {
    const getDropdownItems = (all: string[], inputValue: string, value?: string) => {
        if (!inputValue || (value && inputValue === labelRenderer(value))) {
            return all
        }
        const matchingItems = searchIndex.search(inputValue).map(r => all[r.refIndex])

        if (matchingItems.find(item => isEqual(item, inputValue)) === undefined) {
            matchingItems.push(inputValue)
        }

        return matchingItems
    }

    const [isOpen, setOpen] = useState(false)
    const [hoveredIndex, setHoveredIndex] = useState<number>()
    const [inputValue, setInputValue] = useState(value ? labelRenderer(value) : '')

    const [dropdownItems, setDropdownItems] = useState(() =>
        getDropdownItems(items, inputValue, value),
    )

    const searchIndex = useMemo(() => new FuzzySearch(items.map(labelRenderer)), [items])

    useEffect(() => {
        setInputValue(value ? labelRenderer(value) : '')
    }, [value])

    useEffect(() => {
        setDropdownItems(getDropdownItems(items, inputValue, value))
        setHoveredIndex(undefined)
    }, [inputValue, items, value])

    const height = dropdownHeight(variant, small)
    const padding = variant === DropdownVariant.BlackGrey ? 12 : 8

    const itemClick = (val: string, toggle: () => void) => {
        onChange(val)
        setInputValue(labelRenderer(val))
        toggle()
    }

    const clear = () => {
        onChange(undefined)
        setInputValue('')
    }

    return (
        <BaseDropdown
            isOpen={isOpen}
            setOpen={setOpen}
            items={dropdownItems}
            variant={variant}
            id={id}
            clearAction={clear}
            width={width}
            height={height}
            content={
                <DropdownContent
                    inputValue={inputValue}
                    items={dropdownItems}
                    setInputValue={setInputValue}
                    setOpen={setOpen}
                    value={value}
                    variant={variant}
                    hoveredIndex={hoveredIndex}
                    setHoveredIndex={setHoveredIndex}
                    placeholder={placeholder}
                    valueRenderer={valueRenderer}
                    onChange={onChange}
                />
            }
            cleanVisible={!!value}
            onItemClick={(value, toggle) => itemClick(value, toggle)}
            paddingHorizontal={padding}
            emptyContent={
                <DropdownPlaceholder
                    size={12}
                    as='span'
                    variant={variant}
                    paddingHorizontal={padding}
                >
                    {translate('dropdown.empty')}
                </DropdownPlaceholder>
            }
            outlined={outlined}
            maxHeight={maxHeight}
            ItemRenderer={props => (
                <DropdownItemHoverable
                    onClick={props.onClick}
                    variant={props.variant}
                    isSelected={value === props.v}
                    height={height}
                    padding={padding}
                    isHovered={props.index === hoveredIndex}
                    onMouseEnter={() => setHoveredIndex(props.index)}
                    onMouseLeave={() => setHoveredIndex(undefined)}
                >
                    {valueRenderer(props.v)}
                </DropdownItemHoverable>
            )}
        />
    )
}

interface DropdownContentProps {
    inputValue: string
    setInputValue(value: string): void
    setOpen(isOpen: boolean): void
    value?: string
    variant: DropdownVariant
    placeholder?: string
    valueRenderer(item: string): ReactNode
    onChange(value?: string): void

    hoveredIndex?: number
    setHoveredIndex(index: number | undefined): void
    items: string[]
}

export const DropdownContent = ({
    value,
    variant,
    setInputValue,
    valueRenderer,
    placeholder,
    setOpen,
    inputValue,
    hoveredIndex = -1,
    setHoveredIndex,
    items,
    onChange,
}: DropdownContentProps) => {
    const input = useRef<HTMLInputElement>(null)

    const handleOpen = () => {
        input.current?.focus()
        setOpen(true)
    }

    const onKey = (event: KeyboardEvent) => {
        const key = event.key

        if (items.length === 0) {
            return
        }

        switch (key) {
            case VALUE_UP:
                event.preventDefault()
                if (hoveredIndex === 0) {
                    setHoveredIndex(items.length - 1)
                } else {
                    setHoveredIndex(hoveredIndex - 1)
                }
                break

            case VALUE_DOWN:
                event.preventDefault()
                setHoveredIndex((hoveredIndex + 1) % items.length)
                break

            case VALUE_RETURN:
                event.preventDefault()
                if (hoveredIndex > -1) {
                    onChange(items[hoveredIndex])
                    setOpen(false)
                }
                break

            case VALUE_ESCAPE:
                event.preventDefault()
                setOpen(false)
                break

            default:
                setOpen(true)
        }
    }

    return (
        <Box flex={1} row onClick={handleOpen} relative>
            <DropdownLabel size={12} as='span' variant={variant} isSelected>
                {value && !inputValue && valueRenderer(value)}
            </DropdownLabel>

            {!value && !inputValue && (
                <DropdownPlaceholder variant={variant}>{placeholder}</DropdownPlaceholder>
            )}

            <Input
                variant={variant}
                type='text'
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
                onBlur={() => {
                    onChange(inputValue as string)
                }}
                onKeyDown={onKey}
                ref={input}
            />
        </Box>
    )
}

const Input = styled(DropdownInput)`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`
