import { optionalFunctionCall1Arg } from 'aos-helpers/src/helpers/Function'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { busDoorSubtitle } from 'aos-services/src/services/layerData/properties/BusDoorProperties'
import { selectionTargetTitle } from 'aos-services/src/services/map/selectionTargetFormatter'
import {
    EventSelectedElement,
    SelectedMapElement,
    SelectedMapElementType,
    TaskSelectedElement,
} from 'aos-services/src/services/map/types/SelectedMapElement'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { BottomSheet } from 'aos-ui/src/components/ui/BottomSheet'
import { IconEventItem } from 'aos-ui-event/src/components/event/IconEventItem'
import { IconTaskItem } from 'aos-ui-task/src/components/task/IconTaskItem'
import React, { PureComponent } from 'react'

import { BimLayerName } from '../../bim/BimLayerName'
import { renderSelectionTargetIcon } from '../../icons/SelectionTargetIconRenderer'
import { LayerId } from '../../LayerId'
import { availableFloors } from '../../partialMaps/CommonMapElements'
import { airportMapSelectConfigs } from '../../styleBuilder/selectControlStyleBuilder'
import { SelectableProps, SelectControl } from './SelectControl'

export class SelectBottomSheet extends PureComponent<
    SelectBottomSheetProps,
    SelectBottomSheetState
> {
    public state: SelectBottomSheetState = {}

    public render() {
        const layerNames = [
            BimLayerName.Gates,
            BimLayerName.BusGates,
            BimLayerName.Stands,
            BimLayerName.BusDoors,
        ]

        let layerIds: string[] = []

        availableFloors.map(floor => {
            layerNames.map(layerName => {
                layerIds.push(layerName + floor)
            })
        })

        layerIds.push(LayerId.Events)
        layerIds.push(LayerId.Tasks)

        return (
            <>
                <SelectControl
                    isActive={this.props.isActive}
                    layerIds={layerIds}
                    selectConfigs={airportMapSelectConfigs}
                    onSelect={this.setSelectionElement}
                    onResetSelection={this.setSelectionElement}
                    selectedElement={this.props.selectedElement}
                />
                {this.renderBottomSheet(this.props.selectedElement)}
            </>
        )
    }

    private setSelectionElement = (selectedElement?: SelectedMapElement) => {
        if (selectedElement) {
            renderSelectionTargetIcon(selectedElement).then(svgIcon => {
                this.setState({
                    svgIcon,
                })
            })
            if (this.props.onSelect) {
                this.props.onSelect(selectedElement)
            }
        } else if (this.props.onResetSelection) {
            this.props.onResetSelection()
        }
    }

    private renderBottomSheet = (selectedElement?: SelectedMapElement) => (
        <BottomSheet isOpen={!!selectedElement}>
            {selectedElement && this.renderBottomSheetContent(selectedElement)}
        </BottomSheet>
    )

    private renderBottomSheetContent = (selectedElement: SelectedMapElement) => {
        const clickFeature = optionalFunctionCall1Arg(this.props.openFeature, selectedElement)
        const isBusDoor = selectedElement.type === SelectedMapElementType.BusDoor
        return isBusDoor ? (
            <Box row padding={12} width='100%'>
                {this.busDoorTitle(selectedElement)}
                <FormButton
                    variant={FormButtonVariant.Mobile}
                    label={translate('map.bottom-sheet.view')}
                    onClick={clickFeature}
                />
            </Box>
        ) : (
            <Box row padding={20} width='100%'>
                {this.renderIcon(selectedElement)}
                {this.itemTitle(selectedElement)}
                <FormButton
                    variant={FormButtonVariant.Mobile}
                    label={translate('map.bottom-sheet.view')}
                    onClick={clickFeature}
                />
            </Box>
        )
    }

    private renderIcon = (selectedElement: SelectedMapElement) => {
        switch (selectedElement.type) {
            case SelectedMapElementType.Event:
                return this.renderEventContent(selectedElement)
            case SelectedMapElementType.Task:
                return this.renderTaskContent(selectedElement)
            default:
                return <img src={this.state.svgIcon} />
        }
    }

    private renderEventContent = (selectedElement: EventSelectedElement) => {
        const selectedEvent = selectedElement.payload.event
        return <IconEventItem severity={selectedEvent.severity} category={selectedEvent.category} />
    }

    private renderTaskContent = (selectedElement: TaskSelectedElement) => {
        const task = selectedElement.payload.task
        return <IconTaskItem category={task.category} severity={task.severity} />
    }

    private itemTitle = (selectedElement: SelectedMapElement) => (
        <Box flex={1} paddingHorizontal={12}>
            {selectionTargetTitle(selectedElement)}
        </Box>
    )

    private busDoorTitle = (selectedElement: SelectedMapElement) => (
        <Box flex={1} paddingHorizontal={12} column>
            <Text size={16} paddingBottom={4}>
                {selectionTargetTitle(selectedElement)}
            </Text>
            <Text size={14}>
                {selectedElement.type === SelectedMapElementType.BusDoor &&
                    busDoorSubtitle(selectedElement.payload)}
            </Text>
        </Box>
    )
}

interface SelectBottomSheetProps extends SelectableProps {
    isActive: boolean

    openFeature?(v: SelectedMapElement): void
}

interface SelectBottomSheetState {
    svgIcon?: string
}
