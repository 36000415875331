import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'

import { MetarState } from '../../../services/airportStatus/metar/types/MetarState'
import { RunwaysState } from '../../../services/runways/types/RunwaysState'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const RUNWAY_SYNC = 'STATUS_DASHBOARD_DATA/RUNWAY/RUNWAY_SYNC'
export type RunwaySyncAction = PayloadAction<typeof RUNWAY_SYNC, SyncDataKey>
export const runwaySyncAction = payloadActionCreator<RunwaySyncAction, SyncDataKey>(RUNWAY_SYNC)

export const RUNWAY_SYNC_STOP = 'STATUS_DASHBOARD_DATA/RUNWAY/RUNWAY_SYNC_STOP'
export type RunwaySyncStopAction = PayloadAction<typeof RUNWAY_SYNC_STOP, SyncDataKey>
export const runwaySyncStopAction = payloadActionCreator<RunwaySyncStopAction, SyncDataKey>(
    RUNWAY_SYNC_STOP,
)

export const RUNWAY_SYNC_SUCCESS = 'STATUS_DASHBOARD_DATA/RUNWAY/RUNWAY_SYNC_SUCCESS'
export type RunwaySyncSuccessAction = PayloadAction<typeof RUNWAY_SYNC_SUCCESS, RunwaysState>
export const runwaySyncSuccessAction = payloadActionCreator<RunwaySyncSuccessAction, RunwaysState>(
    RUNWAY_SYNC_SUCCESS,
)

export const METAR_SYNC_SUCCESS = 'STATUS_DASHBOARD_DATA/RUNWAY/METAR_SYNC_SUCCESS'
export type MetarSyncSuccessAction = PayloadAction<typeof METAR_SYNC_SUCCESS, MetarState>
export const metarSyncSuccessAction = payloadActionCreator<MetarSyncSuccessAction, MetarState>(
    METAR_SYNC_SUCCESS,
)

export type RunwayAction =
    | RunwaySyncAction
    | RunwaySyncStopAction
    | RunwaySyncSuccessAction
    | MetarSyncSuccessAction
