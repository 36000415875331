import { ThreeColumn, TwoColumn } from 'aos-helpers/src/helpers/ColumnLayout'
import { isLoggedInSelector } from 'aos-services/src/core/auth/state'
import { createSelector } from 'reselect'

import { alreadyShownSelector, OneTimeKey } from '../oneTime/state'

export interface LayoutState {
    isMenuOpen: boolean
    showWhatsNewModal: boolean
    isEventManagerDotVisible: boolean
    columnSizes: ColumnState
}

export interface LayoutStateAware {
    layout: LayoutState
}

export const initialColumnState: ColumnState = {
    eventManager: [50],
    groupManager: [50],
    checklistManager: [20],
    eventEditor: {
        checklistOnPrivateChannelOn: [70, 20],
        checklistOffPrivateChannelOn: [50],
        checklistOnPrivateChannelOff: [50],
        dashboard: [40],
    },
}

export const initialLayoutState: LayoutState = {
    isMenuOpen: false,
    showWhatsNewModal: false,
    isEventManagerDotVisible: false,
    columnSizes: initialColumnState,
}

export type DialogColumn = keyof ColumnState

export interface ColumnState {
    eventManager: TwoColumn
    groupManager: TwoColumn
    checklistManager: TwoColumn
    eventEditor: EventEditorColumnState
}

export type EventEditorDialogColumn = keyof EventEditorColumnState

export interface EventEditorColumnState {
    checklistOnPrivateChannelOn: ThreeColumn
    checklistOffPrivateChannelOn: TwoColumn
    checklistOnPrivateChannelOff: TwoColumn
    dashboard: TwoColumn
}

export const eventEditorColumnStateKey = (
    checklist: boolean,
    privateChannel: boolean,
): EventEditorDialogColumn => {
    const boolToString = (v: boolean) => (v ? 'On' : 'Off')
    return `checklist${boolToString(checklist)}PrivateChannel${boolToString(
        privateChannel,
    )}` as EventEditorDialogColumn
}

export const isMenuOpenSelector = (state: LayoutStateAware) => state.layout.isMenuOpen

export const isWhatsNewModalOpenSelector = createSelector(
    (state: LayoutStateAware) => state.layout.showWhatsNewModal,
    alreadyShownSelector,
    isLoggedInSelector,
    (showWhatsNewModal, alreadyShown, isLoggedIn): boolean => {
        if (!isLoggedIn) {
            return false
        }

        return showWhatsNewModal || !alreadyShown(OneTimeKey.CurrentWhatsNew)
    },
)

export const eventManagerDotListenerName = 'eventManagerDot'
