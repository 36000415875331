import { Optional } from 'aos-helpers/src/helpers/Optional'
import { KeycloakTokenParsed } from 'keycloak-js'
import { chain, head, identity, includes, some, values } from 'lodash'

import { AosAirport } from '../../flightInformation/types/AosAirport'
import { TaskAccessLevel } from '../../users/types/AosUser'
import { AosUserLocalisation } from '../../users/types/AosUserLocalisation'
import { Role, UnitRoles } from './Role'

export class CurrentUser {
    constructor(private token: KeycloakTokenParsed | null) {}

    public get userRoles(): Role[] {
        return Optional.ofNullable(this.token)
            .map(t => t.resource_access?.['aos-oidc'].roles as Role[])
            .orElseGet(() => [])
            .filter(identity)
    }

    public userHasRole = (r: Role): boolean => includes(this.userRoles, r)

    public userHasAnyOfTheseRoles = (roles: Role[]): boolean =>
        some(roles, r => this.userHasRole(r))

    public userHasAnyUnitRole = (): boolean => this.userHasAnyOfTheseRoles(UnitRoles)

    public isApoc = () => this.unitRole === Role.ApocUser

    public isNetwork = () => this.siteLocation !== AosAirport.HEL

    public isHel = () => this.siteLocation === AosAirport.HEL

    public get siteLocation(): AosAirport {
        return this.token?.siteLocation ?? AosAirport.HEL
    }

    public get localisation(): AosUserLocalisation {
        return this.token?.localisation ?? AosUserLocalisation.EN_US
    }

    private get taskPermissions(): Role[] | null {
        return (this.token?.resource_access?.['aos-oidc'].roles as Role[]) ?? null
    }

    public get taskPermission() {
        const viewPermission = this.taskPermissions?.find(
            role => role === `${this.siteLocation}_${TaskAccessLevel.View}`,
        )
        const editPermission = this.taskPermissions?.find(
            role => role === `${this.siteLocation}_${TaskAccessLevel.Edit}`,
        )
        return editPermission || viewPermission || TaskAccessLevel.None
    }

    public canViewTasks() {
        return (
            this.taskPermission === `${this.siteLocation}_${TaskAccessLevel.View}` ||
            this.taskPermission === `${this.siteLocation}_${TaskAccessLevel.Edit}`
        )
    }

    public canEditTasks() {
        return this.taskPermission === `${this.siteLocation}_${TaskAccessLevel.Edit}`
    }

    get unitRole(): Role | undefined {
        return head(
            chain(values(UnitRoles))
                .filter(unit => includes(this.userRoles, unit))
                .value(),
        )
    }
}
