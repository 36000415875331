import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'

import {
    defaultTaskRecurrenceHourly,
    TaskRecurrenceHourlyPayload,
    TaskRecurrenceHourlyValidation,
    validateTaskRecurrenceHourly,
} from './TaskRecurrenceHourlyFormPayload'
import {
    defaultTaskRecurrenceMonthly,
    TaskRecurrenceMonthlyPayload,
    TaskRecurrenceMonthlyValidation,
    validateTaskRecurrenceMonthly,
} from './TaskRecurrenceMonthlyFormPayload'
import {
    defaultTaskRecurrenceWeekly,
    TaskRecurrenceWeeklyPayload,
    TaskRecurrenceWeeklyValidation,
    validateTaskRecurrenceWeekly,
} from './TaskRecurrenceWeeklyFormPayload'
import {
    defaultTaskRecurrenceYearly,
    TaskRecurrenceYearlyPayload,
    validateTaskRecurrenceYearly,
} from './TaskRecurrenceYearlyFormPayload'

export type TaskRecurrencePayload =
    | TaskRecurrenceHourlyPayload
    | TaskRecurrenceWeeklyPayload
    | TaskRecurrenceMonthlyPayload
    | TaskRecurrenceYearlyPayload

export const defaultTaskRecurrence: Record<TaskRecurrenceType, TaskRecurrencePayload> = {
    [TaskRecurrenceType.Hourly]: defaultTaskRecurrenceHourly,
    [TaskRecurrenceType.Weekly]: defaultTaskRecurrenceWeekly,
    [TaskRecurrenceType.Monthly]: defaultTaskRecurrenceMonthly,
    [TaskRecurrenceType.Yearly]: defaultTaskRecurrenceYearly,
}

export type TaskRecurrenceValidation =
    | TaskRecurrenceHourlyValidation
    | TaskRecurrenceWeeklyValidation
    | TaskRecurrenceMonthlyValidation

export const validateTaskRecurrence = (r: TaskRecurrencePayload): TaskRecurrenceValidation => {
    switch (r.type) {
        case TaskRecurrenceType.Hourly:
            return validateTaskRecurrenceHourly(r)
        case TaskRecurrenceType.Weekly:
            return validateTaskRecurrenceWeekly(r)
        case TaskRecurrenceType.Monthly:
            return validateTaskRecurrenceMonthly(r)
        case TaskRecurrenceType.Yearly:
            return validateTaskRecurrenceYearly(r)
    }
}
