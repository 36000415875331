import { isDefined } from 'aos-helpers/src/helpers/Function'
import { DateTime, dateTime, startOfHour } from 'aos-helpers/src/helpers/Time'
import { timeRange } from 'aos-helpers/src/helpers/TimeRange'
import { chain } from 'lodash'

import { baggageDeliveryFullChartHours } from '../../../../../services/airportStatus/baggage/baggageDeliveryConstants'
import { BaggageDeliveryHourlyStat } from '../../../../../services/airportStatus/baggage/types/BaggageDeliveryStat'
import { distribution } from '../../../../../services/common/types/DistributionValue'
import { meanDuration } from '../../../../../services/common/types/Stats'
import { flightFilterService } from '../../../../../services/flightInformation/FlightFilterService'
import { Flight } from '../../../../../services/flightInformation/types/Flight'
import { groupFlightsBySection } from './common'

const chartTimeRange = (now: DateTime) =>
    timeRange(now.clone().subtract(baggageDeliveryFullChartHours, 'hours').startOf('hour'), now)

const statsForHour = (
    hour: DateTime,
    allFlights: Flight[],
    now: DateTime,
    timeStamp: DateTime,
): BaggageDeliveryHourlyStat | undefined => {
    const flightsWithBags = flightFilterService.getFlightsWithBags(allFlights)
    if (flightsWithBags.length === 0) {
        return undefined
    }
    const deliveryTimes = flightFilterService.flightsToDeliveryTimes(flightsWithBags, now)
    const flightsBySection = groupFlightsBySection(flightsWithBags, now)
    return {
        timeStamp,
        time: hour,
        meanDeliveryTime: meanDuration(deliveryTimes),
        distribution: distribution(flightsBySection),
        flightCounts: {
            all: allFlights.length,
            applicable: flightsWithBags.length,
        },
    }
}

export const hourlyStats = (flights: Flight[], now: DateTime): BaggageDeliveryHourlyStat[] =>
    chain(flightFilterService.getFlightsFromPeriod(flights, chartTimeRange(now)))
        .groupBy(f => startOfHour(f.sdt).valueOf())
        .toPairs()
        .map(([hour, flightsFromHour]) =>
            statsForHour(dateTime(Number(hour)), flightsFromHour, now, flightsFromHour[0].sdt),
        )
        .filter(isDefined)
        .orderBy(q => q.timeStamp)
        .value()
