import { TaskDuration } from 'aos-services/src/services/tasks/types/TaskStatus'
import { range, uniqBy } from 'lodash'
import { Duration, Moment, MomentInput } from 'moment'
import momentDurationFormat from 'moment-duration-format'
import moment, * as mns from 'moment-timezone'

momentDurationFormat(mns)

const APP_TIME_ZONE = 'Europe/Helsinki'

export const dateTime = (momentInput?: MomentInput, format?: moment.MomentFormatSpecification) =>
    moment.utc(momentInput, format).tz(APP_TIME_ZONE)

export const todayStart = () => startOfDay(dateTime())
export const todayEnd = () => endOfDay(dateTime())

export const startOfHour = (t: DateTime) => t.clone().startOf('hour')

export const startOfDay = (t: DateTime) => t.clone().startOf('day')
export const endOfDay = (t: DateTime) => t.clone().endOf('day')

export const startOfMonth = (t: DateTime) => t.clone().startOf('month')
export const startOfMonths = (t: DateTime[]) => uniqBy(t.map(startOfMonth), d => d.valueOf())

export const startOfNextMonth = (t: DateTime) => t.clone().add(1, 'month').startOf('month')

export const getMonth = (t: DateTime) => t.month() + 1
export const getWeek = (t: DateTime) => t.isoWeek()
export const getYear = (t: DateTime) => t.year()
export const getMonthDay = (t: DateTime) => t.date()

export const isToday = (t: DateTime) => dateTime().isSame(dateTime(t), 'day')

export const formatDateMonthTime = (t: DateTime) => t.format('DD.MM, HH:mm')

export const daysFrom = (date: DateTime, numberOfDays: number): DateTime[] =>
    range(0, numberOfDays).map(i => date.clone().add(i, 'days'))
export const hoursFrom = (date: DateTime, numberOfHours: number): DateTime[] =>
    range(0, numberOfHours).map(i => date.clone().add(i, 'hours'))

export const timelineNow = () => ({
    visibleTimeStart: dateTime().add(-4, 'hour').valueOf(),
    visibleTimeEnd: dateTime().add(24, 'hour').valueOf(),
})

export class Timestamps {
    public static Minute = 60 * 1000
    public static Hour = 60 * Timestamps.Minute
    public static Day = 24 * Timestamps.Hour
    public static Week = 7 * Timestamps.Day
    public static Year = 365 * Timestamps.Day
}

export type DateTime = Moment
export type Timestamp = number

export const mapOptionalDateTime =
    <T>(mapper: f.Func1<DateTime, T>) =>
    (t?: DateTime): T | undefined => {
        if (t !== undefined) {
            return mapper(t)
        } else {
            return undefined
        }
    }

export const dateTimeToISOString = (t: DateTime): string => dateTime(t).toISOString()

export const dateTimeToUTC = (t: DateTime): DateTime => t.clone().utc()

export const dateTimeToDate = (t: DateTime): Date => dateTime(t).toDate()

export const optionalDateTimeToISOString = (t?: DateTime): string | undefined =>
    mapOptionalDateTime(dateTimeToISOString)(t)

export const dateTimeFromOptional = (
    t: number | string | Date | undefined | null,
): DateTime | undefined => {
    if (t) {
        return dateTime(t)
    } else {
        return undefined
    }
}

export const minutesToDuration = (durationInMinutes: number): Duration =>
    moment.duration(Math.round(durationInMinutes * 100) / 100, 'minutes')

export const roundToMinutes = (t: DateTime, minutes: number) => {
    const intervals = Math.floor(t.minutes() / minutes)
    return startOfHour(t).minutes(intervals * minutes)
}

export const formatDuration = (duration: Duration) => duration.format('h:mm', { trim: false })

export const compareDateTimeAsc = (t1: DateTime, t2: DateTime) => t1.unix() - t2.unix()
export const compareDateTimeDesc = (t1: DateTime, t2: DateTime) => t2.unix() - t1.unix()

export const formatMinutesAsDuration = (v: number) => formatDuration(minutesToDuration(v))

export const minutesToTaskDuration = (minutes: number): TaskDuration => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60

    return { hour, minute }
}
