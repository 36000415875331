import React, { FC } from 'react'

import OpenSansFont from '!base64-inline-loader!../../../../assets/fonts/open-sans-400.woff2'

export const OpenSans: FC = () => (
    <defs>
        <style type='text/css'>
            {`
            @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-display: swap;
                src: url(${OpenSansFont}) format('woff2');
            }
            text {
                font-family: 'Open Sans', Arial, Helvetica, sans-serif;
            }
        `}
        </style>
    </defs>
)
