export enum LoginStatus {
    Idle = 'Idle',
    Loading = 'Loading',
    Success = 'Success',
    Failed = 'Failed',
}

export interface LoginState {
    loginStatus: LoginStatus
}

export interface LoginStateAware {
    login: LoginState
}

export const initialLoginState: LoginState = {
    loginStatus: LoginStatus.Idle,
}
