import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { MapTaskDto } from '../../../dataaccess/tasks/types/MapTaskDto'
import { AosLocation } from '../../common/types/AosLocation'
import { AosSeverity } from '../../common/types/AosSeverity'
import { TaskCategory } from '../../tasks/types/TaskCategory'
import { TaskProcessType } from '../../tasks/types/TaskProcessType'
import { TaskStatus } from '../../tasks/types/TaskStatus'
import { MapTask } from './MapTask'

export class MapTaskImpl extends WrapperObject<MapTaskDto> implements MapTask {
    constructor(value: MapTaskDto) {
        super(value)
    }

    public get id() {
        return this.value.id!
    }

    public get severity() {
        return this.value.severity as AosSeverity
    }

    public get category() {
        return this.value.category as TaskCategory
    }

    public get location() {
        return this.value.location as AosLocation
    }

    public get title() {
        return this.value.title
    }

    public get assignees() {
        return this.value.assignees
    }

    public get status() {
        return this.value.status as TaskStatus
    }

    public get processType() {
        return this.value.processType as TaskProcessType
    }
}
