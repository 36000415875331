import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent } from 'react'

import { BaggageIndicator, BaggageIndicatorProps } from '../partial/BaggageIndicator'

export class BeltBaggageCell extends PureComponent<BeltBaggageCellProps> {
    public render() {
        const indicatorProps = this.getIndicatorProps()

        return (
            <Tooltip body={this.renderTooltipBody(indicatorProps)} withArrow>
                <div>
                    <BaggageIndicator {...indicatorProps} />
                </div>
            </Tooltip>
        )
    }

    private getIndicatorProps() {
        const bag = this.props.row.bag
        const duration = this.props.row.bag.deliveryTime(this.props.now)
        return {
            deliveryState: bag.deliveryStatus(this.props.now),
            deliveryTime: duration,
            progress: bag.progress,
        }
    }

    private renderTooltipBody = (indicatorProps: BaggageIndicatorProps) => {
        const firstBag = this.firstBag
        const lastBag = this.lastBag

        return (
            <Box padding={8}>
                <BaggageIndicator {...indicatorProps} elaborative />
                <Box style={{ paddingLeft: 36 }}>
                    {firstBag && (
                        <p className='text__s-grey-txt padding-top--small'>
                            {this.dateText(
                                'flight-information.table.bag.tooltip.first-bag',
                                firstBag,
                            )}
                        </p>
                    )}
                    {lastBag && (
                        <p className='padding-top--smallest text__s-grey-txt'>
                            {this.dateText(
                                'flight-information.table.bag.tooltip.last-bag',
                                lastBag,
                            )}
                        </p>
                    )}
                </Box>
            </Box>
        )
    }

    private get firstBag() {
        return this.props.row.bag.firstBag
    }

    private get lastBag() {
        return this.props.row.bag.lastBag
    }

    private dateText(key: string, date: DateTime) {
        return `${translate(key)}: ${formatTime(date)}`
    }
}

interface BeltBaggageCellProps {
    row: Flight
    now: DateTime
}
