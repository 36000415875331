import { dateTime, dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { AosEventChecklistItemDto } from '../../../dataaccess/events/types/AosEventChecklistItemDto'
import { AosChecklistItemType } from '../../checklists/types/AosCheckListItemType'
import { AosEventChecklistItem } from './AosEventChecklist'

export class AosEventChecklistItemImpl
    extends WrapperObject<AosEventChecklistItemDto>
    implements AosEventChecklistItem
{
    constructor(value: AosEventChecklistItemDto) {
        super(value)
    }

    public get id() {
        return this.value.id
    }

    public get content() {
        return this.value.content
    }

    public get title() {
        return this.value.title
    }

    public get type() {
        return this.value.type as AosChecklistItemType
    }

    public get done() {
        return this.value.done
    }

    public get createdAt() {
        return dateTime(this.value.createdAt)
    }

    public get createdBy() {
        return this.value.createdBy
    }

    public get modifiedAt() {
        return dateTimeFromOptional(this.value.modifiedAt)
    }

    public get modifiedBy() {
        return this.value.modifiedBy
    }
}
