import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { formatDate, formatDayLong, formatTime } from 'aos-helpers/src/helpers/TimeFormat'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

interface FirefightersTimeProps {
    date: DateTime
    isDateSame?: boolean
}

export const FirefightersTime: FC<FirefightersTimeProps> = ({ date, isDateSame }) => (
    <Box justify='space-around' row>
        {!isDateSame && (
            <>
                <Text size={18} color={Color.White} paddingRight={8}>
                    {formatDayLong(date)}
                </Text>
                <Text paddingRight={16}>{formatDate(date)}</Text>
            </>
        )}

        <Box row>
            <Icon
                iconSize={BlockSize.Tiny}
                svg={SvgIcon.CurrentTime}
                iconVariant={IconVariant.White}
            />
            <Text paddingLeft={4} size={12}>
                {formatTime(date)}
            </Text>
        </Box>
    </Box>
)
