import { cx } from 'aos-components/src/components/base/ClassNames'
import { joinPipe } from 'aos-helpers/src/helpers/Text'
import { formatOptionalCalendarTime } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { aosUserGroupName } from 'aos-services/src/services/users/aosUserFormatter'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import {
    AosUserGroupType,
    mapAosUserGroupTypeToColor,
} from 'aos-services/src/services/users/types/AosUserGroupType'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import {
    LabeledTextElement,
    LabeledTextElementSize,
} from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import { ColorIndicator } from 'aos-ui/src/components/indicators/ColorIndicator'
import { More, MoreHover, MoreItem } from 'aos-ui/src/components/list/More'
import React, { PureComponent } from 'react'

interface GroupListItemProps {
    group: AosUserGroup
    isSelected: boolean
    onSelect(v: AosUserGroup): void
    onRemove(v: AosUserGroup): void
    onRename(v: AosUserGroup): void
    onEditNotificationSettings(v: AosUserGroup): void
}

/* eslint-disable react/jsx-no-literals */
export class GroupListItem extends PureComponent<GroupListItemProps> {
    public render() {
        const { group, isSelected } = this.props
        const isGroupEditable = group.type === AosUserGroupType.CUSTOM
        return (
            <MoreHover
                className={cx(
                    'group-list-item padding-vertical--large padding-horizontal--x-large border-bottom--black pointer',
                    'container container--horizontal container--justify-space',
                    { 'group-list-item--selected': isSelected },
                )}
                onClick={this.handleSelect}
                data-test-id='group-list-item'
            >
                <LabeledTextElement
                    label={this.renderTextContent()}
                    variant={ThemeVariant.Black}
                    size={LabeledTextElementSize.Large}
                    spacing={4}
                >
                    {this.renderSubtextContent()}
                </LabeledTextElement>
                <More>
                    <MoreItem onClick={isGroupEditable ? this.handleRemoveGroup : undefined}>
                        {translate('group-list-item.more.delete')}
                    </MoreItem>
                    <MoreItem onClick={isGroupEditable ? this.handleRenameGroup : undefined}>
                        {translate('group-list-item.more.rename')}
                    </MoreItem>
                    <MoreItem onClick={this.handleNotificationSettings}>
                        {translate('group-list-item.more.notifications')}
                    </MoreItem>
                </More>
            </MoreHover>
        )
    }

    private handleRemoveGroup = () => {
        this.props.onRemove(this.props.group)
    }

    private handleRenameGroup = () => {
        this.props.onRename(this.props.group)
    }

    private handleNotificationSettings = () => {
        this.props.onEditNotificationSettings(this.props.group)
    }

    private handleSelect = () => {
        this.props.onSelect(this.props.group)
    }

    private renderTextContent = () => (
        <span data-test-id='group-list-item-name'>
            {this.renderGroupIndicator()}
            {aosUserGroupName(this.props.group)}
            <span className='text__l-grey-txt' data-test-id='group-list-item-name-count'>
                {' '}
                ({this.props.group.userCount})
            </span>
        </span>
    )

    private renderGroupIndicator = () => {
        const type = this.props.group.type
        switch (type) {
            case AosUserGroupType.ALL:
                return (
                    <span className='padding-right--tiny'>
                        <ColorIndicator
                            color={mapAosUserGroupTypeToColor[AosUserGroupType.INTERNAL]}
                            marginRight={4}
                        />
                        <ColorIndicator
                            color={mapAosUserGroupTypeToColor[AosUserGroupType.EXTERNAL]}
                            marginRight={4}
                        />
                        <ColorIndicator
                            color={mapAosUserGroupTypeToColor[AosUserGroupType.MANUAL]}
                            marginRight={4}
                        />
                    </span>
                )
            case AosUserGroupType.INTERNAL:
            case AosUserGroupType.EXTERNAL:
            case AosUserGroupType.MANUAL:
            case AosUserGroupType.REMOVED:
                return (
                    <span className='padding-right--tiny'>
                        <ColorIndicator color={mapAosUserGroupTypeToColor[type]} marginRight={4} />
                    </span>
                )
            default:
                return null
        }
    }

    private renderSubtextContent = () =>
        joinPipe(
            [
                formatOptionalCalendarTime(this.props.group.modifiedAt),
                this.props.group.modifiedBy,
            ].filter(t => t) as string[],
        )
}
