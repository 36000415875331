import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { BaseStatusDashboardAction } from 'aos-services/src/core/statusDashboard/actions'
import { DashboardItemSizeVariant } from 'aos-services/src/services/statusDashboard/types/DashboardItemSizeVariant'
import { DashboardItemType } from 'aos-services/src/services/statusDashboard/types/DashboardItemType'
import { BaggageDeliveryFilters } from 'aos-services/src/services/statusDashboard/types/filters/BaggageDeliveryFilters'
import { CdmMetricTypeFilter } from 'aos-services/src/services/statusDashboard/types/filters/CdmFilters'
import { ExtendedDelayFilters } from 'aos-services/src/services/statusDashboard/types/filters/DelaysFilters'
import { ExtendedFlightsVolumeFilters } from 'aos-services/src/services/statusDashboard/types/filters/FlightsVolumesFilters'
import { NewsFeedInstanceFilters } from 'aos-services/src/services/statusDashboard/types/filters/NewsFeedFilters'
import { OperationalForecastFilters } from 'aos-services/src/services/statusDashboard/types/filters/OperationalForecastFilters'
import { PaxSecurityPoint } from 'aos-services/src/services/statusDashboard/types/filters/PaxFilters'
import { PaxForecastFilters } from 'aos-services/src/services/statusDashboard/types/filters/paxForecast/PaxForecastFilters'
import { PunctualityFilters } from 'aos-services/src/services/statusDashboard/types/filters/PunctualityFilters'
import { QueueingTimeFilters } from 'aos-services/src/services/statusDashboard/types/filters/QueueingTimeFilters'
import { RegularityFilters } from 'aos-services/src/services/statusDashboard/types/filters/RegularityFilters'
import { WeatherFilters } from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { Breakpoint } from 'aos-ui/src/components/grid/ResponsiveGrid'
import { Layouts } from 'react-grid-layout'

import {
    DashboardPresets,
    StatusDashboardPresetTab,
    StatusDashboardTab,
} from '../../services/statusDashboard/types/DashboardPreset'
import { CdmItemState } from '../../services/statusDashboard/types/itemStates/CdmItemState'

export const SET_PRESETS = 'STATUS_DASHBOARD/SET_PRESETS'
export type SetPresetsAction = PayloadAction<typeof SET_PRESETS, DashboardPresets>
export const setPresetsAction = payloadActionCreator<SetPresetsAction, DashboardPresets>(
    SET_PRESETS,
)

export const SET_WEATHER_TAB_STATE = 'STATUS_DASHBOARD/WEATHER/SET_WEATHER_TAB_STATE'
export type SetWeatherTabStateAction = PayloadAction<
    typeof SET_WEATHER_TAB_STATE,
    [number, Partial<WeatherFilters>]
>
export const setWeatherTabStateAction = payloadActionCreator<
    SetWeatherTabStateAction,
    [number, Partial<WeatherFilters>]
>(SET_WEATHER_TAB_STATE)

export const SET_NEWS_FEED_FILTER = 'STATUS_DASHBOARD/NEWS_FEED/SET_NEWS_FEED_FILTER'
export type SetNewsFeedFilterAction = PayloadAction<
    typeof SET_NEWS_FEED_FILTER,
    NewsFeedInstanceFilters
>
export const setNewsFeedFilterAction = payloadActionCreator<
    SetNewsFeedFilterAction,
    NewsFeedInstanceFilters
>(SET_NEWS_FEED_FILTER)

export const SET_PAX_TAB = 'STATUS_DASHBOARD/PAX/SET_PAX_TAB'
export type SetPaxTabAction = PayloadAction<typeof SET_PAX_TAB, [number, PaxSecurityPoint]>
export const setPaxTabAction = payloadActionCreator<SetPaxTabAction, [number, PaxSecurityPoint]>(
    SET_PAX_TAB,
)
export const SET_FLIGHTS_TAB_STATE = 'STATUS_DASHBOARD/FLIGHTS/SET_FLIGHTS_TAB_STATE'
export type SetFlightsTabStateAction = PayloadAction<
    typeof SET_FLIGHTS_TAB_STATE,
    [number, Partial<ExtendedFlightsVolumeFilters>]
>
export const setFlightsTabStateAction = payloadActionCreator<
    SetFlightsTabStateAction,
    [number, Partial<ExtendedFlightsVolumeFilters>]
>(SET_FLIGHTS_TAB_STATE)

export const SET_DELAYS_TAB_STATE = 'STATUS_DASHBOARD/FLIGHTS/SET_DELAYS_TAB_STATE'
export type SetDelaysTabStateAction = PayloadAction<
    typeof SET_DELAYS_TAB_STATE,
    [number, Partial<ExtendedDelayFilters>]
>
export const setDelaysTabStateAction = payloadActionCreator<
    SetDelaysTabStateAction,
    [number, Partial<ExtendedDelayFilters>]
>(SET_DELAYS_TAB_STATE)

export const SET_OPERATIONAL_FORECAST_TAB_STATE =
    'STATUS_DASHBOARD/SET_OPERATIONAL_FORECAST_TAB_STATE'
export type SetOperationalForecastTabStateAction = PayloadAction<
    typeof SET_OPERATIONAL_FORECAST_TAB_STATE,
    [number, Partial<OperationalForecastFilters>]
>
export const setOperationalForecastTabStateAction = payloadActionCreator<
    SetOperationalForecastTabStateAction,
    [number, Partial<OperationalForecastFilters>]
>(SET_OPERATIONAL_FORECAST_TAB_STATE)

export const SET_PUNCTUALITY_TAB_STATE = 'STATUS_DASHBOARD/FLIGHTS/SET_PUNCTUALITY_TAB_STATE'
export type SetPunctualityTabStateAction = PayloadAction<
    typeof SET_PUNCTUALITY_TAB_STATE,
    [number, Partial<PunctualityFilters>]
>
export const setPunctualityTabStateAction = payloadActionCreator<
    SetPunctualityTabStateAction,
    [number, Partial<PunctualityFilters>]
>(SET_PUNCTUALITY_TAB_STATE)

export const SET_BAGGAGE_DELIVERY_FILTER = 'STATUS_DASHBOARD/FLIGHTS/SET_BAGGAGE_DELIVERY_FILTER'
export type SetBaggageDeliveryFilterAction = PayloadAction<
    typeof SET_BAGGAGE_DELIVERY_FILTER,
    [number, Partial<BaggageDeliveryFilters>]
>
export const setBaggageDeliveryFilterAction = payloadActionCreator<
    SetBaggageDeliveryFilterAction,
    [number, Partial<BaggageDeliveryFilters>]
>(SET_BAGGAGE_DELIVERY_FILTER)

export const SET_REGULARITY_FILTER = 'STATUS_DASHBOARD/SET_REGULARITY_FILTER'
export type SetRegularityFilterAction = PayloadAction<
    typeof SET_REGULARITY_FILTER,
    [number, Partial<RegularityFilters>]
>
export const setRegularityFilterAction = payloadActionCreator<
    SetRegularityFilterAction,
    [number, Partial<RegularityFilters>]
>(SET_REGULARITY_FILTER)

export const SET_CDM_FILTERS = 'STATUS_DASHBOARD/CDM/SET_CDM_FILTERS'
export type SetCdmFiltersStateAction = PayloadAction<
    typeof SET_CDM_FILTERS,
    [number, Partial<CdmMetricTypeFilter>]
>
export const setCdmFiltersStateAction = payloadActionCreator<
    SetCdmFiltersStateAction,
    [number, Partial<CdmMetricTypeFilter>]
>(SET_CDM_FILTERS)

export const SET_TOBT_FILTERS = 'STATUS_DASHBOARD/CDM/SET_TOBT_FILTERS'
export type SetTobtFiltersStateAction = PayloadAction<
    typeof SET_TOBT_FILTERS,
    [number, Partial<CdmItemState>]
>
export const setTobtFiltersStateAction = payloadActionCreator<
    SetTobtFiltersStateAction,
    [number, Partial<CdmItemState>]
>(SET_TOBT_FILTERS)

export const SET_PAX_FORECAST_FILTERS = 'STATUS_DASHBOARD/SET_PAX_FORECAST_FILTERS'
export type SetPaxForecastFiltersAction = PayloadAction<
    typeof SET_PAX_FORECAST_FILTERS,
    [number, Partial<PaxForecastFilters>]
>
export const setPaxForecastFiltersAction = payloadActionCreator<
    SetPaxForecastFiltersAction,
    [number, Partial<PaxForecastFilters>]
>(SET_PAX_FORECAST_FILTERS)

export const OPEN_STATUS_DASHBOARD_TAB = 'STATUS_DASHBOARD/OPEN_STATUS_DASHBOARD_TAB'
export type OpenStatusDashboardTabAction = PayloadAction<
    typeof OPEN_STATUS_DASHBOARD_TAB,
    StatusDashboardTab
>
export const openStatusDashboardTabAction = payloadActionCreator<
    OpenStatusDashboardTabAction,
    StatusDashboardTab
>(OPEN_STATUS_DASHBOARD_TAB)

export const DISMISS_ALERT = 'STATUS_DASHBOARD/DISMISS_ALERT'
export type DismissAlertAction = PayloadAction<typeof DISMISS_ALERT, DashboardItemType>
export const dismissAlertAction = payloadActionCreator<DismissAlertAction, DashboardItemType>(
    DISMISS_ALERT,
)

interface UpdateLayoutPayload {
    layouts: Layouts
    tab: StatusDashboardPresetTab
}
export const UPDATE_LAYOUT = 'STATUS_DASHBOARD/UPDATE_LAYOUT'
export type UpdateLayoutAction = PayloadAction<typeof UPDATE_LAYOUT, UpdateLayoutPayload>
export const updateLayoutAction = payloadActionCreator<UpdateLayoutAction, UpdateLayoutPayload>(
    UPDATE_LAYOUT,
)

export const CHANGE_SIZE_VARIANT = 'STATUS_DASHBOARD/CHANGE_SIZE_VARIANT'
export type ChangeSizeVariantAction = PayloadAction<
    typeof CHANGE_SIZE_VARIANT,
    [number, DashboardItemSizeVariant]
>
export const changeSizeVariantAction = payloadActionCreator<
    ChangeSizeVariantAction,
    [number, DashboardItemSizeVariant]
>(CHANGE_SIZE_VARIANT)

export const SET_CURRENT_BREAKPOINT = 'STATUS_DASHBOARD/SET_CURRENT_BREAKPOINT'
export type SetCurrentBreakpointAction = PayloadAction<typeof SET_CURRENT_BREAKPOINT, Breakpoint>
export const setCurrentBreakpointAction = payloadActionCreator<
    SetCurrentBreakpointAction,
    Breakpoint
>(SET_CURRENT_BREAKPOINT)

export const ADD_DASHBOARD_ITEM = 'STATUS_DASHBOARD/ADD_DASHBOARD_ITEM'
export type AddDashboardItemAction = PayloadAction<typeof ADD_DASHBOARD_ITEM, DashboardItemType>
export const addDashboardItemAction = payloadActionCreator<
    AddDashboardItemAction,
    DashboardItemType
>(ADD_DASHBOARD_ITEM)

export const REMOVE_DASHBOARD_ITEM = 'STATUS_DASHBOARD/REMOVE_DASHBOARD_ITEM'
export type RemoveDashboardItemAction = PayloadAction<typeof REMOVE_DASHBOARD_ITEM, number>
export const removeDashboardItemAction = payloadActionCreator<RemoveDashboardItemAction, number>(
    REMOVE_DASHBOARD_ITEM,
)

export const TRIGGER_CAROUSEL_CHANGE = 'STATUS_DASHBOARD/TRIGGER_CAROUSEL_CHANGE'
export type TriggerCarouselChangeAction = PayloadAction<typeof TRIGGER_CAROUSEL_CHANGE, DateTime>
export const triggerCarouselChangeAction = payloadActionCreator<
    TriggerCarouselChangeAction,
    DateTime
>(TRIGGER_CAROUSEL_CHANGE)

export const TOGGLE_SIDEBAR = 'STATUS_DASHBOARD/TOGGLE_SIDEBAR'
export type ToggleSidebarAction = PayloadAction<typeof TOGGLE_SIDEBAR, boolean>
export const toggleSidebarAction = payloadActionCreator<ToggleSidebarAction, boolean>(
    TOGGLE_SIDEBAR,
)

export const SET_QUEUEING_TIMES_FILTERS =
    'STATUS_DASHBOARD/QUEUEING_TIMES/SET_QUEUEING_TIMES_FILTERS'
export type SetQueueingTimesFiltersAction = PayloadAction<
    typeof SET_QUEUEING_TIMES_FILTERS,
    [number, Partial<QueueingTimeFilters>]
>
export const setQueueingTimesFiltersAction = payloadActionCreator<
    SetQueueingTimesFiltersAction,
    [number, Partial<QueueingTimeFilters>]
>(SET_QUEUEING_TIMES_FILTERS)

export type StatusDashboardAction =
    | BaseStatusDashboardAction
    | SetPresetsAction
    | SetWeatherTabStateAction
    | SetNewsFeedFilterAction
    | SetPaxTabAction
    | SetFlightsTabStateAction
    | SetDelaysTabStateAction
    | SetPunctualityTabStateAction
    | SetBaggageDeliveryFilterAction
    | SetRegularityFilterAction
    | OpenStatusDashboardTabAction
    | ChangeSizeVariantAction
    | DismissAlertAction
    | UpdateLayoutAction
    | SetCdmFiltersStateAction
    | SetPaxForecastFiltersAction
    | SetCurrentBreakpointAction
    | AddDashboardItemAction
    | RemoveDashboardItemAction
    | TriggerCarouselChangeAction
    | SetOperationalForecastTabStateAction
    | SetQueueingTimesFiltersAction
    | ToggleSidebarAction
    | SetTobtFiltersStateAction
