import { towingsRestService } from '../../dataaccess/towingList/towingsRestService'
import { Towing } from '../flightInformation/types/towing/Towing'
import { TowingImpl } from '../flightInformation/types/towing/TowingImpl'

class TowingsService {
    public async loadTowings(): Promise<Towing[]> {
        const towings = await towingsRestService.getAllTowings()
        return towings.map(flight => new TowingImpl(flight))
    }
}

export const towingsService = new TowingsService()
