import { Domain } from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    BaggageDeliveryHourlyStat,
    BaggageDeliveryStat,
} from 'aos-services/src/services/airportStatus/baggage/types/BaggageDeliveryStat'
import { Flight } from 'aos-services/src/services/flightInformation/types/Flight'
import { BaggageDeliveryFullViewTab } from 'aos-services/src/services/statusDashboard/types/filters/BaggageDeliveryFilters'
import React, { FC } from 'react'

import { BaggageDeliveryItemState } from '../../../../../services/statusDashboard/types/itemStates/BaggageDeliveryItemState'
import { FlightChartStatsWrapper } from '../../base/FlightChartStatsWrapper'
import { BaggageDeliveryChart } from '../BaggageDeliveryChart'
import { BaggageDeliveryByTimeChart } from './BaggageDeliveryByTimeChart'
import { BaggageDeliveryFlights } from './BaggageDeliveryFlights'

interface BaggageDeliveryFullViewProps {
    itemState: BaggageDeliveryItemState
    stats: BaggageDeliveryStat
    flights: Flight[]
    chartData: BaggageDeliveryHourlyStat[]
    now: DateTime
    xDomain: Domain<DateTime>
    lineDomain: Domain<number>
    barDomain: Domain<number>
}

export const BaggageDeliveryFullView: FC<BaggageDeliveryFullViewProps> = props => {
    const { stats, flights, now, itemState, chartData, xDomain, lineDomain, barDomain } = props
    return (
        <FlightChartStatsWrapper
            chart={
                <BaggageDeliveryByTimeChart
                    data={chartData}
                    timeRange={itemState.timeRange}
                    xDomain={xDomain}
                    lineDomain={lineDomain}
                    barDomain={barDomain}
                />
            }
            flightTable={() => <BaggageDeliveryFlights flights={flights} now={now} />}
            flightTableTitle={translate('dashboard.baggage-delivery.flight-table-header')}
            statsContent={<BaggageDeliveryChart {...stats} />}
            showChart={itemState.fullViewTab === BaggageDeliveryFullViewTab.History}
        />
    )
}
