import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { TaskSnapshot } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { Box } from 'aos-ui/src/components/base/Box'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Spinner } from 'aos-ui/src/components/ui/Spinner'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, ReactNode } from 'react'

import { StatusDashboardItemWarning } from '../../base/StatusDashboardItemWarning'
import { TaskCard } from '../partial/TaskCard'

interface TaskCardListProps {
    tasks: TaskSnapshot[]
    isLoading: boolean
    isError: boolean
    empty?: ReactNode
    onOpen(task: TaskSnapshot): void
}

export const TaskCardList: FC<TaskCardListProps> = ({
    tasks,
    isLoading,
    isError,
    onOpen,
    empty = null,
}) => {
    return (
        <Box bg={Color.UiBlack1} flex={1} relative>
            {isLoading ? (
                <Spinner />
            ) : isError ? (
                <StatusDashboardItemWarning title={translate('tasks.loading-error')} />
            ) : !tasks.length ? (
                empty
            ) : (
                <DarkScrollbar>
                    <Box bg={Color.UiBlack1} padding={20}>
                        {tasks.map(task => (
                            <Box key={task.id} paddingBottom={12}>
                                <TaskCard task={task} onOpen={() => onOpen(task)} />
                            </Box>
                        ))}
                    </Box>
                </DarkScrollbar>
            )}
        </Box>
    )
}
