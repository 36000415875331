import {
    SimpleFormModalState,
    ValueWrapper,
} from 'aos-components/src/components/modal/InputModal/core/simpleForm/state'
import { FormValidation, isNotEmpty } from 'aos-helpers/src/helpers/FormValidation'
import { ChecklistTemplateItemEditPayload } from 'aos-services/src/services/checklists/types/input/ChecklistTemplateItemEditPayload'

export type MessageTemplateModalState = SimpleFormModalState<
    MessageTemplateState,
    MessageTemplateValidation,
    ChecklistTemplateItemEditPayload | undefined
>

export interface MessageTemplateState {
    content: string
    title: string
}

export interface MessageTemplateValidation {
    content: boolean
    title: boolean
}

export type MessageTemplateValueWrapper = ValueWrapper<MessageTemplateState, undefined>

export const initialMessageTemplateValueWrapper: MessageTemplateValueWrapper = {
    value: {
        content: '',
        title: '',
    },
}

export const validateMessageTemplate = (
    e: MessageTemplateValueWrapper,
): MessageTemplateValidation => ({
    content: isNotEmpty(e.value && e.value.content),
    title: isNotEmpty(e.value && e.value.title),
})

export const createInitialMessageTemplateModalState = (): MessageTemplateModalState => ({
    isOpen: false,
    currentValue: initialMessageTemplateValueWrapper,
    form: FormValidation.fromFields(validateMessageTemplate(initialMessageTemplateValueWrapper)),
})
