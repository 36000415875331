import { Color } from 'aos-ui-common/src/styles/Color'

import { UnitTaskStatus } from './AosUnitTaskTemplate'

export const statusToColor = {
    [UnitTaskStatus.TO_DO]: Color.ProgressRed,
    [UnitTaskStatus.IN_PROGRESS]: Color.ProgressYellow,
    [UnitTaskStatus.DONE]: Color.ProgressGreen,
    [UnitTaskStatus.SKIPPED]: Color.ProgressGrey,
}
