import { translate } from 'aos-helpers/src/helpers/translations/Translations'

import { Validator } from './types'

export const chainValidators = <T, TDate>(...validators: Validator<T>[]) => (
    value: T,
    values: TDate,
) => {
    for (const validator of validators) {
        const result = validator(value, values)
        if (result) {
            return result
        }
    }
    return undefined
}

export const required = (
    errorMessage = translate('form.required-field'),
): Validator<any> => value => {
    if (typeof value === 'string' && !value.trim()) {
        return errorMessage
    }
    return value ? undefined : errorMessage
}

const emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i

export const emailValidator = (
    errorMessage = translate('form.invalid-email'),
): Validator<string> => value => {
    if (value !== '' && !emailRegexp.exec(value)) {
        return errorMessage
    }
    return undefined
}
