import { CurrentTimeChart } from 'aos-components/src/components/chart/CurrentTimeChart'
import { calculateTimeDomain } from 'aos-helpers/src/helpers/domain/Domain'
import { dateTime } from 'aos-helpers/src/helpers/Time'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { TimeValuePoint } from 'aos-services/src/services/airportStatus/base/types/TimePoint'
import { filterNonEmptyWeatherSeries } from 'aos-services/src/services/airportStatus/weather/helpers'
import {
    convertAndRoundKtTo,
    WeatherValueSeries,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherSeriesDefinition } from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesDefinition'
import {
    translateChartWeatherSeriesType,
    WeatherValueSeriesType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import {
    getWeatherTicksCountForWeatherTimeRange,
    WeatherTimeRange,
} from 'aos-services/src/services/statusDashboard/types/filters/WeatherFilters'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { TooltipColorsOverlay } from 'aos-ui/src/components/tooltip/TooltipColorsOverlay'
import { ChartScaleType } from 'aos-ui-common/src/components/chart/types/Chart'
import {
    ChartLineSeriesConfig,
    ChartSeriesConfig,
} from 'aos-ui-common/src/components/chart/types/ChartSeries'
import React, { PureComponent } from 'react'

import { blockChartConfig, iconChartConfig, mainChartWindCloudsConfig } from '../WeatherConsts'
import {
    getWeatherColorForWeatherSeries,
    getWeatherColorForWeatherWindGustsValue,
} from '../WeatherSettings'
import { probabilityGradientSeriesConfig, WeatherGradientChart } from './WeatherGradientChart'
import { WeatherMainChart } from './WeatherMainChart'
import { WeatherWindDirectionChart } from './WeatherWindDirectionChart'
import { windGustsColorList } from './WindGusts'

const ktToRounded = (unitTab: WeatherUnit) => convertAndRoundKtTo(unitTab, 1)

const windGustsBarSeriesConfig = (unitTab: WeatherUnit): ChartSeriesConfig<TimeValuePoint> => ({
    color: getWeatherColorForWeatherWindGustsValue(unitTab),
    tooltip: (point: TimeValuePoint) =>
        translateChartWeatherSeriesType(
            WeatherValueSeriesType.MaxWindGust,
            ktToRounded(unitTab)(point.value),
            unitTab,
        ),
})

const commonLineSeriesConfigByType = (
    unitTab: WeatherUnit,
    type: WeatherValueSeriesType,
): ChartLineSeriesConfig<TimeValuePoint> => ({
    color: getWeatherColorForWeatherSeries(type),
    withDots: true,
    isCurved: true,
    tooltip: (point: TimeValuePoint) =>
        translateChartWeatherSeriesType(type, ktToRounded(unitTab)(point.value), unitTab),
})

const getWeatherLineSeriesDefinition = (
    weatherSeries: WeatherValueSeries,
    unitTab: WeatherUnit,
): WeatherSeriesDefinition =>
    filterNonEmptyWeatherSeries({
        [WeatherValueSeriesType.WindSpeed]: {
            data: weatherSeries[WeatherValueSeriesType.WindSpeed] || [],
            config: commonLineSeriesConfigByType(unitTab, WeatherValueSeriesType.WindSpeed),
            scale: ChartScaleType.Y1,
            valueGetter: (point: TimeValuePoint) => ktToRounded(unitTab)(point.value),
        },
        [WeatherValueSeriesType.CumulonimbusCloudsBase]: {
            data: weatherSeries[WeatherValueSeriesType.CumulonimbusCloudsBase] || [],
            config: commonLineSeriesConfigByType(
                unitTab,
                WeatherValueSeriesType.CumulonimbusCloudsBase,
            ),
            scale: ChartScaleType.Y2,
            valueGetter: (point: TimeValuePoint) => point.value,
        },
    })

const getWeatherBarSeriesDefinition = (
    weatherSeries: WeatherValueSeries,
    unitTab: WeatherUnit,
): WeatherSeriesDefinition =>
    filterNonEmptyWeatherSeries({
        [WeatherValueSeriesType.MaxWindGust]: {
            data: weatherSeries[WeatherValueSeriesType.MaxWindGust] || [],
            config: windGustsBarSeriesConfig(unitTab),
            scale: ChartScaleType.Y1,
            valueGetter: (point: TimeValuePoint) => ktToRounded(unitTab)(point.value),
        },
    })

export class WeatherWindCloudsChart extends PureComponent<WeatherWindsCloudsChartProps> {
    public render() {
        const { weatherSeries, unitTab, timeRangeTab } = this.props

        const chartLineSeriesDefinition = getWeatherLineSeriesDefinition(weatherSeries, unitTab)
        const chartBarSeriesDefinition = getWeatherBarSeriesDefinition(weatherSeries, unitTab)
        const timeTicksCount = getWeatherTicksCountForWeatherTimeRange(timeRangeTab)

        const xDomain = calculateTimeDomain(
            chartLineSeriesDefinition[WeatherValueSeriesType.WindSpeed]?.data ?? [],
            timeTicksCount,
        )

        const cloudsGradientSeriesData =
            weatherSeries[WeatherValueSeriesType.CumulonimbusCloudsProbability] || []
        const windDirectionSeriesData = weatherSeries[WeatherValueSeriesType.WindDirection] || []
        const windDirectionIconSeriesData = windDirectionSeriesData.filter(d =>
            xDomain.ticks.find(t => d.time.isSame(dateTime(t))),
        )

        return (
            <>
                <WeatherMainChart
                    chartConfig={mainChartWindCloudsConfig}
                    lineSeriesDefinition={chartLineSeriesDefinition}
                    barSeriesDefinition={chartBarSeriesDefinition}
                    xDomain={xDomain}
                    leftAdditionalIcons={[
                        {
                            svg: SvgIcon.WindScale,
                            tooltip: (
                                <TooltipColorsOverlay colors={windGustsColorList} withArrow>
                                    {translate('dashboard.weather.wind-gusts-bars')}
                                </TooltipColorsOverlay>
                            ),
                        },
                    ]}
                    units={{
                        y1: translateEnum<WeatherUnit>(
                            WeatherUnit,
                            'dashboard.weather.unit-tabs',
                        )(unitTab),
                        y2: translate('dashboard.weather.unit-tabs.ft'),
                    }}
                />
                {windDirectionIconSeriesData.length > 0 && (
                    <WeatherWindDirectionChart
                        chartConfig={iconChartConfig}
                        seriesData={windDirectionIconSeriesData}
                        xDomain={xDomain}
                        unit={translate('dashboard.weather.unit-tabs.direction-deg')}
                    />
                )}
                {cloudsGradientSeriesData.length > 0 && (
                    <WeatherGradientChart
                        seriesConfig={probabilityGradientSeriesConfig}
                        name='cb-chart'
                        type={WeatherValueSeriesType.CumulonimbusCloudsProbability}
                        chartConfig={blockChartConfig}
                        seriesData={cloudsGradientSeriesData}
                        xDomain={xDomain}
                    />
                )}
                <CurrentTimeChart margins={blockChartConfig.margins} domain={xDomain.domain} />
            </>
        )
    }
}

export interface WeatherWindsCloudsChartProps {
    weatherSeries: WeatherValueSeries
    unitTab: WeatherUnit
    timeRangeTab: WeatherTimeRange
}
