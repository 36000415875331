import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Flight } from '../../services/flightInformation/types/Flight'
import { SyncDataKey } from '../../services/statusDashboard/types/DashboardDataState'

export const FLIGHTS_SYNC = 'FLIGHTS/SYNC'
export type FlightsSyncAction = PayloadAction<typeof FLIGHTS_SYNC, SyncDataKey>
export const flightsSyncAction = payloadActionCreator<FlightsSyncAction, SyncDataKey>(FLIGHTS_SYNC)

export const FLIGHTS_SYNC_STOP = 'FLIGHTS/SYNC_STOP'
export type FlightsSyncStopAction = PayloadAction<typeof FLIGHTS_SYNC_STOP, SyncDataKey>
export const flightsSyncStopAction = payloadActionCreator<FlightsSyncStopAction, SyncDataKey>(
    FLIGHTS_SYNC_STOP,
)

export const FLIGHTS_LOAD = 'FLIGHTS/LOAD'
export type FlightLoadAction = Action<typeof FLIGHTS_LOAD>
export const flightLoadAction = emptyActionCreator<FlightLoadAction>(FLIGHTS_LOAD)

export const FLIGHTS_LOAD_SUCCESS = 'FLIGHTS/LOAD_SUCCESS'
export type FlightLoadSuccessAction = PayloadAction<typeof FLIGHTS_LOAD_SUCCESS, Flight[]>
export const flightLoadSuccessAction = payloadActionCreator<FlightLoadSuccessAction, Flight[]>(
    FLIGHTS_LOAD_SUCCESS,
)

export type FlightsAction =
    | FlightsSyncAction
    | FlightsSyncStopAction
    | FlightLoadAction
    | FlightLoadSuccessAction
