export enum NavigationMapElementType {
    Gate = 'gate',
    Stand = 'stand',
}

export interface GateNavigationElement {
    type: typeof NavigationMapElementType.Gate
    gateId: string
}

export interface StandNavigationElement {
    type: typeof NavigationMapElementType.Stand
    standId: string
}

export type NavigationMapElement = GateNavigationElement | StandNavigationElement
