import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Train, TrainLocation } from '../../dataaccess/layerData/types/RingRail'
import { LayerDataServiceEntry } from '../../services/layerData/types/LayerDataServiceEntry'
import { LayersDataState } from '../../services/layerData/types/LayersDataState'

export const LOAD_LAYERS_DATA = 'LAYERS_DATA/LOAD_LAYERS_DATA'
export type LoadLayersDataAction = PayloadAction<typeof LOAD_LAYERS_DATA, LayerDataServiceEntry[]>
export const loadLayersDataAction = payloadActionCreator<
    LoadLayersDataAction,
    LayerDataServiceEntry[]
>(LOAD_LAYERS_DATA)

export const LOAD_LAYERS_DATA_SUCCESS = 'LAYERS_DATA/LOAD_LAYERS_DATA_SUCCESS'
export type LoadLayersDataSuccessAction = PayloadAction<
    typeof LOAD_LAYERS_DATA_SUCCESS,
    Partial<LayersDataState>
>
export const loadLayersDataSuccessAction = payloadActionCreator<
    LoadLayersDataSuccessAction,
    Partial<LayersDataState>
>(LOAD_LAYERS_DATA_SUCCESS)

export const STOP_SYNC_RING_RAIL = 'LAYERS_DATA/STOP_SYNC_RING_RAIL'
export type StopSyncRingRailAction = Action<typeof STOP_SYNC_RING_RAIL>
export const stopSyncRingRailAction = emptyActionCreator<StopSyncRingRailAction>(
    STOP_SYNC_RING_RAIL,
)

export const UPDATE_TRAINS = 'LAYERS_DATA/UPDATE_TRAINS'
export type UpdateTrainsAction = PayloadAction<typeof UPDATE_TRAINS, Train[]>
export const updateTrainsAction = payloadActionCreator<UpdateTrainsAction, Train[]>(UPDATE_TRAINS)

export const UPDATE_TRAIN_LOCATIONS = 'LAYERS_DATA/UPDATE_TRAIN_LOCATIONS'
export type UpdateTrainLocationsAction = PayloadAction<
    typeof UPDATE_TRAIN_LOCATIONS,
    TrainLocation[]
>
export const updateTrainLocationsAction = payloadActionCreator<
    UpdateTrainLocationsAction,
    TrainLocation[]
>(UPDATE_TRAIN_LOCATIONS)

export type LayersDataAction =
    | LoadLayersDataAction
    | LoadLayersDataSuccessAction
    | UpdateTrainsAction
    | UpdateTrainLocationsAction
