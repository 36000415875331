import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AttachmentReadonlySection } from 'aos-ui/src/components/attachment/AttachmentReadonlySection'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { LabeledTextElementSize } from 'aos-ui/src/components/form/labeled/LabeledTextElement'
import React, { PureComponent } from 'react'

import { setDialogSubContentAction } from '../../../core/eventPreview/actions'
import { EventFeedOuts } from '../EventFeedOuts'
import { EventInfo } from './EventInfo'

export class EventContent extends PureComponent<EventContentProps> {
    public render() {
        const { event } = this.props
        return (
            <Box className='event-preview' coverAll row>
                {this.renderEventInfo(event)}
                {this.renderFeedOut(event)}
            </Box>
        )
    }

    private renderEventInfo(event: AosEvent) {
        const { setDialogModeAction } = this.props
        return (
            <EventInfo
                event={event}
                setDialogModeAction={setDialogModeAction}
                withVisibilityField={this.props.isApoc}
            >
                <AttachmentReadonlySection
                    attachments={event.allAttachments}
                    variant={ThemeVariant.White}
                    size={LabeledTextElementSize.Large}
                    compact
                />
            </EventInfo>
        )
    }

    private renderFeedOut = (event: AosEvent) => (
        <EventFeedOuts
            feedOuts={event.feedOuts}
            className='padding-top--x-large flex--1 scrollable'
        />
    )
}

interface EventContentProps {
    event: AosEvent
    setDialogModeAction: typeof setDialogSubContentAction
    isApoc: boolean
}
