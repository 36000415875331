import { ClassNameProps } from 'aos-components/src/components/base/ClassNames'
import { AosChecklistItem } from 'aos-services/src/services/checklists/types/AosChecklistItem'
import { ChangeSeqVector } from 'aos-services/src/services/checklists/types/input/ChangeSeqVector'
import { isMessageTemplate } from 'aos-services/src/services/events/types/AosEventChecklist'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { PlusButton } from 'aos-ui/src/components/buttons/PlusButton'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { ChecklistItem, ChecklistMessageTemplate } from './ChecklistItem'

export class ChecklistItemsSection extends PureComponent<ChecklistItemsSectionProps> {
    public render() {
        const { items, isEditable, onAdd, title } = this.props
        return (
            <>
                <Box row paddingBottom={16} paddingTop={20} paddingHorizontal={30} shrink={0}>
                    <Text flex={1} color={Color.White} weight='light' size={18}>
                        {title}
                    </Text>
                    {isEditable && (
                        <Box paddingLeft={20}>
                            <PlusButton
                                onClick={onAdd}
                                seleniumLocation={`${title}-action-button`}
                            />
                        </Box>
                    )}
                </Box>
                {items.length ? this.renderContent() : this.renderPlaceholder()}
            </>
        )
    }

    private renderContent = () => {
        const { items } = this.props
        return (
            <DarkScrollbar>
                <Box paddingHorizontal={30}>{items.map(this.renderItem)}</Box>
            </DarkScrollbar>
        )
    }

    private renderPlaceholder = () => (
        <Box paddingHorizontal={30} paddingBottom={30}>
            {this.props.emptyPlaceholder}
        </Box>
    )

    private renderItem = (item: AosChecklistItem, index: number) => {
        const { isEditable, onEdit, onChangeSeq, onApplySeqChanges, onDelete } = this.props
        const onMove = (fromId: number, toId: number) =>
            onChangeSeq({
                fromId,
                toId,
            })
        const onEditItem = () => onEdit(item)
        const onDeleteItem = () => onDelete(item)
        // different DnD group
        const ItemRenderer = isMessageTemplate(item) ? ChecklistMessageTemplate : ChecklistItem
        return (
            <ItemRenderer
                key={item.id}
                isEditable={isEditable}
                isEven={index % 2 === 0}
                item={item}
                seq={item.id}
                onMove={onMove}
                onApplyMove={onApplySeqChanges}
                onEdit={onEditItem}
                onDelete={onDeleteItem}
            />
        )
    }
}

interface ChecklistItemsSectionProps extends ClassNameProps {
    items: AosChecklistItem[]
    isEditable: boolean
    title: string
    emptyPlaceholder: JSX.Element
    onAdd(): void
    onEdit(v: AosChecklistItem): void
    onDelete(v: AosChecklistItem): void
    onChangeSeq(v: ChangeSeqVector): void
    onApplySeqChanges(): void
}
