import {
    CHANGE_FORM_VALUE,
    CLEAR_FEEDBACK,
    HIDE_MODAL,
    SET_FEEDBACK,
    SET_FORM_PRISTINE,
    SHOW_MODAL,
    SHOW_MODAL_WITH_PAYLOAD,
    SHOW_MODAL_WITH_VALUE,
    SimpleFormModalActions,
} from './actions'
import { SimpleFormModalState, ValueWrapperValidator } from './state'

type SimpleFormReducer<T, V extends object, P> = (
    state: SimpleFormModalState<T, V, P>,
    action: SimpleFormModalActions,
) => SimpleFormModalState<T, V, P>

export function simpleFormModalReducerCreator<T, V extends object, P>(
    validateValueWrapper: ValueWrapperValidator<T, V, P>,
    initialState: SimpleFormModalState<T, V, P>,
): SimpleFormReducer<T, V, P> {
    return function simpleFormModalReducer(state = initialState, action) {
        switch (action.type) {
            case SHOW_MODAL:
                return {
                    ...initialState,
                    isOpen: true,
                }

            case SHOW_MODAL_WITH_PAYLOAD:
                return {
                    ...initialState,
                    isOpen: true,
                    currentValue: { ...initialState.currentValue, payload: action.payload },
                }

            case SHOW_MODAL_WITH_VALUE:
                return {
                    ...initialState,
                    isOpen: true,
                    currentValue: action.payload,
                    form: state.form.modify(validateValueWrapper(action.payload), true),
                }

            case HIDE_MODAL:
                return { ...state, isOpen: false, feedback: undefined }

            case SET_FORM_PRISTINE:
                return {
                    ...state,
                    form: state.form.setPristine(action.payload),
                }

            case SET_FEEDBACK:
                return { ...state, isOpen: true, feedback: action.payload }

            case CLEAR_FEEDBACK:
                return { ...state, feedback: undefined }

            case CHANGE_FORM_VALUE:
                return {
                    ...state,
                    currentValue: action.payload,
                    form: state.form.modify(validateValueWrapper(action.payload)),
                }

            default:
                return state
        }
    }
}
