import { Box } from 'aos-ui/src/components/base/Box'
import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

import { InfoBar } from '../../infobar/InfoBar'
import { BaseCalendarTimelineItem } from '../types/BaseCalendarTimelineItem'

export interface TimelineItemProps<T extends BaseCalendarTimelineItem> {
    item: T
    isCollapsed: boolean
    isClippedLeft?: boolean
    isClippedRight?: boolean
    withContent: boolean
    inTooltip?: boolean
    ContentRenderer: (props: TimelineItemContentProps<T>) => React.ReactElement
    onClick?(): void
    onHoverClipped?(v: boolean): void
}

export interface TimelineItemContentProps<T> {
    item: T
    inTooltip?: boolean
}

export const TimelineItem = <T extends BaseCalendarTimelineItem = BaseCalendarTimelineItem>(
    props: TimelineItemProps<T>,
) => {
    const { ContentRenderer, isCollapsed, item, withContent, onHoverClipped } = props
    const ref = useRef<HTMLDivElement>(null)

    const isClipped = () => {
        if (!ref.current) {
            return false
        }

        const textContainer = ref.current.querySelectorAll<HTMLElement>('[data-timeline-text]')
        return Array.from(textContainer).some(
            container => container.offsetWidth < container.scrollWidth,
        )
    }

    const showHover = () => {
        const shouldHaveTooltip = isClipped() || props.isCollapsed
        if (shouldHaveTooltip && onHoverClipped) {
            onHoverClipped(true)
        }
    }

    return (
        <ItemBox
            isCollapsed={isCollapsed}
            justify='center'
            overflow='hidden'
            onClick={props.onClick}
            onMouseOver={showHover}
            onMouseLeave={() => onHoverClipped && onHoverClipped(false)}
        >
            <InfoBar
                fullHeight
                borderWidth={4}
                color={item.color}
                borderColor={item.borderColor}
                borderStyle={item.borderStyle}
                outlined={item.outlined}
                isClippedLeft={props.isClippedLeft}
                isClippedRight={props.isClippedRight}
                ref={ref}
            >
                {withContent && <ContentRenderer item={item} inTooltip={false} />}
            </InfoBar>
        </ItemBox>
    )
}

const ItemBox = styled(Box)<{ isCollapsed?: boolean }>`
    ${p =>
        p.isCollapsed &&
        css`
            height: 8px;
            padding: 2px 0;
        `}
`
