import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AdUserFormData } from 'aos-services/src/services/users/input/AdUserFormData'
import { AdUserProps, AosAdUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'

import {
    CHANGE_FORM_VALUE,
    ChangeFormValueAction,
    CommonUserAction,
    CommonUserActionCreators,
    commonUserActionCreators,
} from '../actions'

export const changeFormValueAction = payloadActionCreator<
    ChangeFormValueAction<AdUserFormData>,
    AdUserFormData
>(CHANGE_FORM_VALUE)

export const SHOW_USER_MODAL_WITH_AD_USER = 'MANAGE_USER/SHOW_USER_MODAL_WITH_AD_USER'
export type ShowUserModalWithAdUserAction = PayloadAction<
    typeof SHOW_USER_MODAL_WITH_AD_USER,
    AosAdUser
>
export const showUserModalWithAdUserAction = payloadActionCreator<
    ShowUserModalWithAdUserAction,
    AosAdUser
>(SHOW_USER_MODAL_WITH_AD_USER)

export interface AdUserData {
    formData: AdUserFormData
    notificationSettings?: AosUserNotificationsPreferences
}

export const CREATE_AD_USER = 'MANAGE_USER/CREATE_AD_USER'
export type CreateAdUserAction = PayloadAction<typeof CREATE_AD_USER, AdUserData>
export const createAdUserAction = payloadActionCreator<CreateAdUserAction, AdUserData>(
    CREATE_AD_USER,
)

export const UPDATE_AD_USER = 'MANAGE_USER/UPDATE_AD_USER'
export type UpdateAdUserAction = PayloadAction<typeof UPDATE_AD_USER, AdUserData>
export const updateAdUserAction = payloadActionCreator<UpdateAdUserAction, AdUserData>(
    UPDATE_AD_USER,
)

export const SEARCH_USER = 'MANAGE_USER/SEARCH_USER'
export type SearchUserAction = PayloadAction<typeof SEARCH_USER, string>
export const searchUserAction = payloadActionCreator<SearchUserAction, string>(SEARCH_USER)

export const SEARCH_USER_SET_RESULT = 'MANAGE_USER/SEARCH_USER_SUCCESS'
export type SearchUserSetResultAction = PayloadAction<typeof SEARCH_USER_SET_RESULT, AdUserProps>
export const searchUserSetResultAction = payloadActionCreator<
    SearchUserSetResultAction,
    AdUserProps
>(SEARCH_USER_SET_RESULT)

export const SWITCH_TO_CONTACT = 'MANAGE_USER/SWITCH_TO_CONTACT'
export type SwitchToContactAction = Action<typeof SWITCH_TO_CONTACT>
export const switchToContactAction = emptyActionCreator<SwitchToContactAction>(SWITCH_TO_CONTACT)

export type AdUserAction =
    | CommonUserAction
    | ShowUserModalWithAdUserAction
    | ChangeFormValueAction<AdUserFormData>
    | CreateAdUserAction
    | UpdateAdUserAction
    | SearchUserAction
    | SearchUserSetResultAction
    | SwitchToContactAction

export interface AdUserActionCreators extends CommonUserActionCreators {
    showUserModalWithAdUserAction: typeof showUserModalWithAdUserAction
    changeFormValueAction: typeof changeFormValueAction
    createAdUserAction: typeof createAdUserAction
    updateAdUserAction: typeof updateAdUserAction
    searchUserAction: typeof searchUserAction
    searchUserSetResultAction: typeof searchUserSetResultAction
    switchToContactAction: typeof switchToContactAction
}

export const adUserActionCreators: AdUserActionCreators = {
    ...commonUserActionCreators,
    showUserModalWithAdUserAction,
    changeFormValueAction,
    createAdUserAction,
    updateAdUserAction,
    searchUserAction,
    searchUserSetResultAction,
    switchToContactAction,
}
