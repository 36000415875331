import { formatDuration } from 'aos-helpers/src/helpers/Time'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { CountStats, formatCountStats } from 'aos-services/src/services/common/types/Stats'
import { BoxProps } from 'aos-ui/src/components/base/Box'
import { ChartLabel } from 'aos-ui/src/components/text/ChartLabel'
import { Duration } from 'moment'
import React, { FC } from 'react'

interface BaggageDeliveryChartLegendProps extends BoxProps {
    counts: CountStats
    minTime: Duration
    maxTime: Duration
}

export const BaggageDeliveryChartLegend: FC<BaggageDeliveryChartLegendProps> = ({
    counts,
    minTime,
    maxTime,
    ...boxProps
}) => (
    <ChartLabel
        items={[
            {
                left: formatCountStats(counts),
                right: translate('dashboard.baggage-delivery.unit.flights'),
            },
            {
                left: translate('dashboard.baggage-delivery.time.min'),
                right: formatDuration(minTime),
                inverted: true,
            },
            {
                left: translate('dashboard.baggage-delivery.time.max'),
                right: formatDuration(maxTime),
                inverted: true,
            },
        ]}
        {...boxProps}
    />
)
