import { enumToNumericMap } from 'aos-helpers/src/helpers/Enum'
import { EnumValues } from 'enum-values'

export enum AosEventProcessType {
    HelPax = 'HEL_PAX',
    HelBags = 'HEL_BAGS',
    HelTurnaround = 'HEL_TURNAROUND',
    HelOthers = 'HEL_OTHERS',
    HelAirportConstruction = 'HEL_AIRPORT_CONSTRUCTION',
    Network = 'NETWORK',
}

export enum AosAtcEventProcessType {
    HelOthers = 'HEL_OTHERS',
    Network = 'NETWORK',
}

const eventProcessOrder: Map<AosEventProcessType, number> = enumToNumericMap(AosEventProcessType)

export const aosEventProcessTypeToNumber = (e: AosEventProcessType): number =>
    eventProcessOrder.get(e)!

export const helsinkiAirportGroups = EnumValues.getValues<AosEventProcessType>(AosEventProcessType)

export const networkAirportGroups = [
    AosEventProcessType.Network,
    AosEventProcessType.HelPax,
    AosEventProcessType.HelBags,
    AosEventProcessType.HelTurnaround,
    AosEventProcessType.HelOthers,
    AosEventProcessType.HelAirportConstruction,
]
