import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { currentUserSelector } from 'aos-services/src/core/auth/state'
import { AosSimpleTemplate } from 'aos-services/src/services/checklists/types/AosChecklistTemplate'
import { Box } from 'aos-ui/src/components/base/Box'
import { ThemeVariant } from 'aos-ui/src/components/base/ThemeVariant'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { CleanableInput } from 'aos-ui/src/components/form/input/CleanableInput'
import { Searchable } from 'aos-ui/src/components/search/Searchable'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { showActionModalAction } from '../../../core/actionModal/actions'
import {
    applyChecklistTemplateSeqChangesAction,
    exportSingleChecklistTemplateAction,
} from '../../../core/checklistManager/actions'
import {
    changeChecklistTemplateSeqAction,
    deleteChecklistTemplateAction,
    editChecklistTemplateAction,
    selectChecklistTemplateAction,
} from '../../../core/checklistManager/event/actions'
import { getExistingTags } from '../../../core/checklistManager/selectors'
import { ChecklistManagerStateAware } from '../../../core/checklistManager/state'
import { State } from '../../../core/state'
import { ChecklistTemplateItem } from '../checklist/partials/ChecklistTemplateItem'
import { TemplatesList } from '../TemplatesList'

export const ChecklistTemplateList = () => {
    const dispatch = useDispatch()
    const [tagFilter, setTagFilter] = useState<string | undefined>()
    const {
        exportingChecklistTemplates,
        eventChecklistManager: { checklistTemplates },
        selectedItemFromTemplate,
    } = useSelector((state: State) => state.checklistManager)
    const currentUser = useSelector(currentUserSelector)
    const existingTags = useSelector((state: ChecklistManagerStateAware) =>
        getExistingTags(state.checklistManager.eventChecklistManager.checklistTemplates),
    )

    useEffect(() => {
        if (tagFilter && !existingTags.includes(tagFilter)) {
            setTagFilter(undefined)
        }
    }, [tagFilter, existingTags])

    const exportSingleChecklistsAction = (id: number) => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.export-checklist'),
                svg: SvgIcon.ExportChecklistTemplates,
                onClickAction: () => dispatch(exportSingleChecklistTemplateAction(id)),
            }),
        )
    }

    const onDeleteChecklistTemplate = (checklistTemplate: AosSimpleTemplate) => {
        dispatch(
            showActionModalAction({
                ...modalContentFromKeyPrefix('checklist-manager.delete-checklist-template'),
                svg: SvgIcon.DeleteRound,
                onClickAction: () => dispatch(deleteChecklistTemplateAction(checklistTemplate.id)),
                okLabel: translate('checklist-manager.delete-checklist-template.okLabel'),
            }),
        )
    }

    return (
        <Searchable list={checklistTemplates} extractText={item => item.name}>
            {(filteredChecklists, query, setQuery) => (
                <>
                    <Box
                        row
                        className='selectable-item border-bottom--black'
                        paddingVertical={20}
                        paddingHorizontal={30}
                    >
                        <Box flex={2} marginRight={20}>
                            <CleanableInput
                                type='text'
                                variant={ThemeVariant.Black}
                                placeholder={translate('checklist-manager.search.placeholder')}
                                value={query}
                                leftSvg={SvgIcon.SearchWhite}
                                onChangeText={e => setQuery(e || '')}
                                small
                            />
                        </Box>
                        <Box flex={1}>
                            <Dropdown
                                placeholder={translate(
                                    'checklist-manager.filter-by-tag.placeholder',
                                )}
                                value={tagFilter}
                                items={existingTags}
                                valueRenderer={tag => tag}
                                onChange={selectedTag => {
                                    setTagFilter(selectedTag)
                                }}
                                clearAction={() => {
                                    setTagFilter(undefined)
                                }}
                                width={DropdownWidth.Big}
                                variant={DropdownVariant.Black}
                                small
                            />
                        </Box>
                    </Box>
                    <TemplatesList
                        canEdit={currentUser.isApoc()}
                        Component={ChecklistTemplateItem()}
                        onSelect={value => dispatch(selectChecklistTemplateAction(value))}
                        checklistTemplates={filteredChecklists.filter(
                            checklist => !tagFilter || checklist.tag === tagFilter,
                        )}
                        selectedChecklistTemplate={
                            selectedItemFromTemplate as AosSimpleTemplate | undefined
                        }
                        onDelete={onDeleteChecklistTemplate}
                        onEdit={value => dispatch(editChecklistTemplateAction(value))}
                        onExport={exportSingleChecklistsAction}
                        exportingChecklistTemplates={exportingChecklistTemplates}
                        currentUser={currentUser}
                        onChangeSeq={val => dispatch(changeChecklistTemplateSeqAction(val))}
                        onApplySeqChanges={() => dispatch(applyChecklistTemplateSeqChangesAction())}
                    />
                </>
            )}
        </Searchable>
    )
}
