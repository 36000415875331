import { AttachmentUpdatePayload } from 'aos-services/src/services/attachments/types/AttachmentUpdatePayload'
import { UploadedAttachment } from 'aos-services/src/services/attachments/types/UploadedAttachment'
import React, { FCWithChildren } from 'react'

import { ModalWrapper } from '../modal/ModalWrapper'
import { PreviewBox } from './PreviewBox'

interface PreviewModalProps {
    isOpen: boolean
    attachments: UploadedAttachment[]
    attachmentIndex: number
    editable: boolean
    closeAction(): void
    updateAttachment?(v: AttachmentUpdatePayload): void
    onIndexChange(v: number): void
}

export const PreviewModal: FCWithChildren<PreviewModalProps> = props => (
    <ModalWrapper isOpen={props.isOpen} closeAction={props.closeAction} shouldCloseOnOverlayClick>
        <PreviewBox
            attachments={props.attachments}
            attachmentIndex={props.attachmentIndex}
            updateAttachment={props.updateAttachment}
            onIndexChange={props.onIndexChange}
            editable={props.editable}
            close={props.closeAction}
        />
    </ModalWrapper>
)
