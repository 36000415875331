import React, { FCWithChildren, ReactElement } from 'react'

import { Box } from '../base/Box'

interface HorizontalLegendProps {
    leftItems: ReactElement[]
    rightItems: ReactElement[]
}

export const HorizontalLegend: FCWithChildren<HorizontalLegendProps> = props => (
    <Box row>
        <Box flex={1} row>
            {props.leftItems.map((el, index) => (
                <Box marginRight={16} key={index}>
                    {el}
                </Box>
            ))}
        </Box>
        <Box row>
            {props.rightItems.map((el, index) => (
                <Box key={index} marginLeft={16}>
                    {el}
                </Box>
            ))}
        </Box>
    </Box>
)
