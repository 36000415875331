import React, { FCWithChildren, SVGProps } from 'react'

interface SvgMultilineTextProps extends SVGProps<SVGTextElement> {
    children: string
}

export const SvgMultilineText: FCWithChildren<SvgMultilineTextProps> = props => {
    const lines = props.children.split('\n')
    return (
        <text {...props}>
            {lines.map((line, index) => (
                <tspan key={index} x='0' dy={index !== 0 ? '1.2em' : undefined}>
                    {line}
                </tspan>
            ))}
        </text>
    )
}
