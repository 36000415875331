import { timelineReducer } from 'aos-services/src/core/timeline/reducer'
import { xor } from 'lodash'

import {
    CLOSE_FILTERS_MODAL,
    OPEN_FILTERS_MODAL,
    SELECT_ITEM,
    STANDS_GATES_TIMELINE_TIMELINE_PARENT,
    StandsGatesTimelineAction,
    SUBMIT_FILTERS,
    TOGGLE_GROUP,
} from './actions'
import { initialStandsGatesTimelineState, StandsGatesTimelineState } from './state'

export function standsGatesTimelineReducer(
    state = initialStandsGatesTimelineState,
    action: StandsGatesTimelineAction,
): StandsGatesTimelineState {
    switch (action.type) {
        case STANDS_GATES_TIMELINE_TIMELINE_PARENT:
            return {
                ...state,
                timeline: timelineReducer(state.timeline, action.payload),
            }
        case TOGGLE_GROUP:
            return {
                ...state,
                collapsedGroups: xor(state.collapsedGroups, [action.payload.rootKey]),
            }
        case SELECT_ITEM:
            return {
                ...state,
                selectedItem: action.payload,
            }
        case SUBMIT_FILTERS:
            return {
                ...state,
                filters: action.payload,
            }
        case OPEN_FILTERS_MODAL:
            return {
                ...state,
                isFiltersModalOpen: true,
            }
        case CLOSE_FILTERS_MODAL:
            return {
                ...state,
                isFiltersModalOpen: false,
            }
        default:
            return state
    }
}
