import {
    JUMP_TO_DATE,
    JUMP_TO_NOW,
    TIMELINE_SCROLL,
    TIMELINE_TIME_CHANGE,
    TimelineAction,
    ZOOM_IN,
    ZOOM_OUT,
} from './actions'
import {
    initialTimelineState,
    scaleTimeline,
    setTimelineDay,
    setTimelineNow,
    TimelineState,
} from './state'

export const timelineReducer = (
    state = initialTimelineState,
    action: TimelineAction,
): TimelineState => {
    switch (action.type) {
        case TIMELINE_TIME_CHANGE:
            return {
                ...state,
                visibleTimeStart: action.payload[0],
                visibleTimeEnd: action.payload[1],
            }

        case ZOOM_IN:
            const nextTimelineZoomIn = scaleTimeline(state, 0.5)
            return {
                ...state,
                visibleTimeStart: nextTimelineZoomIn[0],
                visibleTimeEnd: nextTimelineZoomIn[1],
            }

        case ZOOM_OUT:
            const nextTimelineZoomOut = scaleTimeline(state, 2)
            return {
                ...state,
                visibleTimeStart: nextTimelineZoomOut[0],
                visibleTimeEnd: nextTimelineZoomOut[1],
            }

        case JUMP_TO_DATE:
            return setTimelineDay(state, action.payload)

        case JUMP_TO_NOW:
            return setTimelineNow(state)

        case TIMELINE_SCROLL:
            return {
                ...state,
                savedPosition: action.payload,
            }

        default:
            return state
    }
}
