import { ActionModal } from 'aos-components/src/components/modal/ActionModal/ActionModal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { hideActionModalAction } from '../../core/actionModal/actions'
import { ActionModalConfig, ActionModalState } from '../../core/actionModal/state'
import { State } from '../../core/state'

class ActionModalClass extends PureComponent<ActionModalProps & ActionModalDispatchProps> {
    public render() {
        return this.props.modalConfig ? this.renderActionModal(this.props.modalConfig) : null
    }

    public renderActionModal = (config: ActionModalConfig) => (
        <ActionModal
            isOpen
            closeAction={() => this.props.hideActionModalAction(false)}
            {...config}
            onClickAction={config.onClickAction || (() => this.props.hideActionModalAction(true))}
        />
    )
}

type ActionModalProps = ActionModalState

interface ActionModalDispatchProps {
    hideActionModalAction: typeof hideActionModalAction
}

export const ActionModals = connect<ActionModalProps, ActionModalDispatchProps, {}>(
    (state: State) => state.actionModal,
    {
        hideActionModalAction,
    },
)(ActionModalClass)
