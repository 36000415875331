import { dateTime } from 'aos-helpers/src/helpers/Time'
import { formatCalendarDay } from 'aos-helpers/src/helpers/TimeFormat'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { AosNotification } from 'aos-services/src/services/notifications/types/Notification'
import { Box } from 'aos-ui/src/components/base/Box'
import { SideContainer, SideContainerVariant } from 'aos-ui/src/components/container/SideContainer'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { openEventAction } from '../../core/eventEditor/actions'
import {
    closeNotificationsContainerAction,
    loadMoreAction,
    pinNotificationsContainerAction,
} from '../../core/notifications/actions'
import {
    groupNotificationsSelector,
    NotificationGroup,
    NotificationsStateUi,
    notificationUiSelector,
} from '../../core/notifications/state'
import { GroupHeader } from './GroupHeader'
import { NotificationItem } from './NotificationItem'

class NotificationContainerClass extends PureComponent<NotificationContainerProps> {
    public render() {
        const {
            notificationsUi,
            closeNotificationsContainer,
            pinNotificationsContainer,
        } = this.props
        return (
            <SideContainer
                title={translate('notifications.title')}
                isOpen={notificationsUi.isOpen}
                close={closeNotificationsContainer}
                variant={
                    notificationsUi.isPinned
                        ? SideContainerVariant.Pinned
                        : SideContainerVariant.Right
                }
                onPin={pinNotificationsContainer}
            >
                {notificationsUi.isOpen && this.renderContent()}
            </SideContainer>
        )
    }

    private renderContent() {
        const { notificationGroups } = this.props
        return (
            <DarkScrollbar onEnd={this.props.loadMoreAction}>
                {notificationGroups.map(this.renderGroup)}
            </DarkScrollbar>
        )
    }

    private renderGroup = (group: NotificationGroup) => (
        <Box key={group.day}>
            <GroupHeader>{formatCalendarDay(dateTime(group.day))}</GroupHeader>
            {group.items.map(this.renderItem)}
        </Box>
    )

    private renderItem = (notification: AosNotification) => {
        const openEvent = (id: number) => {
            if (!this.props.notificationsUi.isPinned) {
                this.props.closeNotificationsContainer()
            }
            this.props.openEventAction(id)
        }
        return (
            <NotificationItem
                notification={notification}
                key={notification.id}
                openEventAction={openEvent}
            />
        )
    }
}

interface NotificationContainerStateProps {
    notificationGroups: NotificationGroup[]
    notificationsUi: NotificationsStateUi
}

interface NotificationContainerDispatchProps {
    closeNotificationsContainer: typeof closeNotificationsContainerAction
    openEventAction: typeof openEventAction
    loadMoreAction: typeof loadMoreAction
    pinNotificationsContainer: typeof pinNotificationsContainerAction
}

interface NotificationContainerProps
    extends NotificationContainerStateProps,
        NotificationContainerDispatchProps {}

export const NotificationContainer = connect<
    NotificationContainerStateProps,
    NotificationContainerDispatchProps
>(
    createStructuredSelector({
        notificationGroups: groupNotificationsSelector,
        notificationsUi: notificationUiSelector,
    }),
    {
        closeNotificationsContainer: closeNotificationsContainerAction,
        pinNotificationsContainer: pinNotificationsContainerAction,
        openEventAction,
        loadMoreAction,
    },
)(NotificationContainerClass)
