import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useIsMounted } from './useIsMounted'

export const useDebounce = <T extends (...args: any[]) => any>(cb: T, delay: number): T => {
    const inputsRef = useRef({ cb, delay })
    const isMounted = useIsMounted()
    useEffect(() => {
        inputsRef.current = { cb, delay }
    })
    return useCallback(
        (debounce((...args: any[]) => {
            // Debounce is an async callback. Cancel it, if in the meanwhile
            // (1) component has been unmounted (see isMounted in snippet)
            // (2) delay has changed
            if (inputsRef.current.delay === delay && isMounted()) {
                inputsRef.current.cb(...args)
            }
        }) as any) as T,
        [delay, debounce],
    )
}

export const useDebounceValue = <T>(value: T, delay: number): T => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay], // Only re-call effect if value or delay changes
    )

    return debouncedValue
}
