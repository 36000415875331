// @ts-nocheck
import { BimLayer } from '../../../../aos-ui-map/src/components/map/bim/BimLayer'
import { BimLayerName } from '../../../../aos-ui-map/src/components/map/bim/BimLayerName'
import { groupedGateToSingleGates } from '../../services/layerData/properties/GatesProperties'
import { findPointInLayerData } from '../../services/layerData/types/LayersDataState'

export const standStateSelector = (id: string) => (state: BimLayersStateAware) =>
    // @ts-ignore
    findPointInLayerData(
        state.bimLayers.list.find(bimLayer => bimLayer.name === BimLayerName.Stands).geojson,
        props => props.teksti === id,
    )

export const gateStateSelector = (id: string) => (state: BimLayersStateAware) =>
    // @ts-ignore
    findPointInLayerData(
        state.bimLayers.list.find(bimLayer => bimLayer.name === BimLayerName.Gates).geojson,
        props => props.Gate === id,
    )

export const busGateStateSelector = (id: string) => (state: BimLayersStateAware) =>
    // @ts-ignore
    findPointInLayerData(
        state.bimLayers.list.find(bimLayer => bimLayer.name === BimLayerName.BusGates).geojson,
        props => groupedGateToSingleGates(props.Gate).includes(id),
    )

export interface BimLayersState {
    list: BimLayer[]
    isLoading: boolean
}

export interface BimLayersStateAware {
    bimLayers: BimLayersState
}

export const initialBimLayersState: BimLayersState = {
    list: [],
    isLoading: true,
}

export const initialWorldBimLayersState: BimLayersState = {
    list: [],
    isLoading: false,
}
