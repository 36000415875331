import { LonLat } from 'aos-helpers/src/helpers/coordinate/LonLat'
import { isEqual } from 'lodash'

export interface RingRailTrainPosition {
    trainNumber: number
    position?: LonLat
    prevPosition: LonLat[]
    speed?: number
    angle?: number
}

export const isRingRailTrainPositionEqual = (
    p1: RingRailTrainPosition,
    p2: RingRailTrainPosition,
) => p1.trainNumber === p2.trainNumber

export const isRingRailTrainPositionDifferent = (
    p1: RingRailTrainPosition,
    p2: RingRailTrainPosition,
) =>
    p1.trainNumber === p2.trainNumber &&
    !(p1.angle === p2.angle && isEqual(p1.position, p2.position))
