import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { InvitationRole } from 'aos-services/src/services/firebaseEvents/types/InvitationStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { FormComponentSize } from 'aos-ui/src/components/base/Theme'
import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { SvgImage } from 'aos-ui/src/components/svg/SvgImage'
import { Color } from 'aos-ui-common/src/styles/Color'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface PrivateChannelInvitationProps {
    selectAction: (action: InvitationRole) => void
    isCommander: boolean
    atcAlert: boolean | undefined
}

export const PrivateChannelInvitation: React.FC<PrivateChannelInvitationProps> = React.memo(
    ({ selectAction, isCommander, atcAlert }) => {
        const history = useHistory()
        return (
            <Box centered column rowGap={12}>
                {atcAlert ? (
                    <SvgImage svg={SvgIcon.Atc} attributes={{ fill: Color.Red, color: 'red' }} />
                ) : (
                    <SvgImage svg={SvgIcon.PrivateInvitation} />
                )}

                <Text lineHeight='standard' textAlign='center' weight='bold'>
                    {atcAlert
                        ? translate('private-channel.emergency.title')
                        : translate('private-channel.invitation.title')}
                </Text>
                <Text lineHeight='standard' textAlign='center'>
                    {atcAlert
                        ? translate('private-channel.emergency.text')
                        : translate('private-channel.invitation.text')}
                </Text>
                <Box column paddingTop={30} rowGap={16}>
                    {isCommander && (
                        <FormButton
                            size={FormComponentSize.Big}
                            label={translate('private-channel.role.button.command')}
                            variant={FormButtonVariant.Red}
                            onClick={() => selectAction(InvitationRole.Command)}
                            seleniumLocation='private-channel.role.button.command'
                        />
                    )}
                    <FormButton
                        size={FormComponentSize.Big}
                        label={translate('private-channel.role.button.observe')}
                        variant={FormButtonVariant.Grey}
                        onClick={() => selectAction(InvitationRole.Observe)}
                        seleniumLocation='private-channel.role.button.observe'
                    />
                    <FormButton
                        size={FormComponentSize.Big}
                        label={translate('private-channel.role.button.participate')}
                        variant={FormButtonVariant.Green}
                        onClick={() => selectAction(InvitationRole.Participate)}
                        seleniumLocation='private-channel.role.button.participate'
                    />
                    <FormButton
                        size={FormComponentSize.Big}
                        label={translate('private-channel.role.button.left')}
                        variant={FormButtonVariant.Flat}
                        onClick={() => {
                            selectAction(InvitationRole.Left)
                            history.push('/status-dashboard/overview')
                        }}
                        seleniumLocation='private-channel.role.button.left'
                    />
                </Box>
            </Box>
        )
    },
)
