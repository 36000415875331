import {
    Action,
    emptyActionCreator,
    parentActionWrapper,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { modalContentFromKeyPrefix } from 'aos-helpers/src/helpers/ModalContentBuilder'
import { AttachmentsAction } from 'aos-services/src/core/attachments/actions'
import { EventFormPayload } from 'aos-services/src/services/events/input/EventPayload'
import { AosEvent } from 'aos-services/src/services/events/types/AosEvent'
import { AosFeedIn } from 'aos-services/src/services/events/types/AosFeedIn'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { MapAction, MapActionCreators, mapActionCreators } from 'aos-ui-map/src/core/actions'

import { showActionModalAction } from '../actionModal/actions'

export const CHANGE_FORM_VALUE = 'EVENT_MODALS/CHANGE_FORM_VALUE'
export type ChangeFormValueAction = PayloadAction<typeof CHANGE_FORM_VALUE, EventFormPayload>
export const changeFormValueAction = payloadActionCreator<ChangeFormValueAction, EventFormPayload>(
    CHANGE_FORM_VALUE,
)

export const SET_SIMPLE_FORM_PRISTINE = 'EVENT_MODALS/SET_SIMPLE_FORM_PRISTINE'
export type SetSimpleFormPristineAction = PayloadAction<typeof SET_SIMPLE_FORM_PRISTINE, boolean>
export const setSimpleFormPristineAction = payloadActionCreator<
    SetSimpleFormPristineAction,
    boolean
>(SET_SIMPLE_FORM_PRISTINE)

export const SET_EXTENDED_FORM_PRISTINE = 'EVENT_MODALS/SET_EXTENDED_FORM_PRISTINE'
export type SetExtendedFormPristineAction = PayloadAction<
    typeof SET_EXTENDED_FORM_PRISTINE,
    boolean
>
export const setExtendedFormPristineAction = payloadActionCreator<
    SetExtendedFormPristineAction,
    boolean
>(SET_EXTENDED_FORM_PRISTINE)

export const EVENT_HIDE_MODAL = 'EVENT_MODALS/EVENT_HIDE_MODAL'
export type EventHideModalAction = Action<typeof EVENT_HIDE_MODAL>
export const eventHideModalAction = emptyActionCreator<EventHideModalAction>(EVENT_HIDE_MODAL)

export const REPORT_SIMPLE_FEED_IN = 'EVENT_MODALS/REPORT_SIMPLE_FEED_IN'
export type ReportSimpleFeedInAction = PayloadAction<
    typeof REPORT_SIMPLE_FEED_IN,
    [EventFormPayload, number | undefined]
>
export const reportSimpleFeedInAction = payloadActionCreator<
    ReportSimpleFeedInAction,
    [EventFormPayload, number | undefined]
>(REPORT_SIMPLE_FEED_IN)

export const REPORT_EXTENDED_FEED_IN = 'EVENT_MODALS/REPORT_EXTENDED_FEED_IN'
export type ReportExtendedFeedInAction = PayloadAction<
    typeof REPORT_EXTENDED_FEED_IN,
    [EventFormPayload, number | undefined]
>
export const reportExtendedFeedInAction = payloadActionCreator<
    ReportExtendedFeedInAction,
    [EventFormPayload, number | undefined]
>(REPORT_EXTENDED_FEED_IN)

export const TRY_ACTIVATE_FEED_IN = 'EVENT_MODALS/TRY_ACTIVATE_FEED_IN'
export type TryActivateFeedInAction = PayloadAction<typeof TRY_ACTIVATE_FEED_IN, AosFeedIn>
export const tryActivateFeedInAction = payloadActionCreator<TryActivateFeedInAction, AosFeedIn>(
    TRY_ACTIVATE_FEED_IN,
)

export const CREATE_EVENT = 'EVENT_MODALS/CREATE_EVENT'
export type CreateEventActionType = PayloadAction<typeof CREATE_EVENT, [EventFormPayload, number]>
export const createEventAction = payloadActionCreator<
    CreateEventActionType,
    [EventFormPayload, number]
>(CREATE_EVENT)

export const ACTIVATE_EVENT = 'EVENT_MODALS/ACTIVATE_EVENT'
export type ActivateEventAction = PayloadAction<
    typeof ACTIVATE_EVENT,
    [EventFormPayload, number | undefined]
>
export const activateEventAction = payloadActionCreator<
    ActivateEventAction,
    [EventFormPayload, number | undefined]
>(ACTIVATE_EVENT)

export const REPORT_SIMPLE_FEED_IN_SHOW_MODAL = 'EVENT_MODALS/REPORT_SIMPLE_FEED_IN_SHOW_MODAL'
export type ReportSimpleFeedInShowModalAction = Action<typeof REPORT_SIMPLE_FEED_IN_SHOW_MODAL>
export const reportSimpleFeedInShowModalAction =
    emptyActionCreator<ReportSimpleFeedInShowModalAction>(REPORT_SIMPLE_FEED_IN_SHOW_MODAL)

export const REPORT_EXTENDED_FEED_IN_SHOW_MODAL = 'EVENT_MODALS/REPORT_EXTENDED_FEED_IN_SHOW_MODAL'
export type ReportExtendedFeedInShowModalAction = Action<typeof REPORT_EXTENDED_FEED_IN_SHOW_MODAL>
export const reportExtendedFeedInShowModalAction =
    emptyActionCreator<ReportExtendedFeedInShowModalAction>(REPORT_EXTENDED_FEED_IN_SHOW_MODAL)

export const CREATE_EVENT_SHOW_MODAL = 'EVENT_MODALS/CREATE_EVENT_SHOW_MODAL'
export type CreateEventShowModalAction = Action<typeof CREATE_EVENT_SHOW_MODAL>
export const createEventShowModalAction =
    emptyActionCreator<CreateEventShowModalAction>(CREATE_EVENT_SHOW_MODAL)

export const REOPEN_EVENT_SHOW_MODAL = 'EVENT_MODALS/REOPEN_EVENT_SHOW_MODAL'
export type ReopenEventShowModalAction = PayloadAction<
    typeof REOPEN_EVENT_SHOW_MODAL,
    EventFormPayload
>
export const reopenEventShowModalAction = payloadActionCreator<
    ReopenEventShowModalAction,
    EventFormPayload
>(REOPEN_EVENT_SHOW_MODAL)

export const EDIT_EVENT_SHOW_MODAL = 'EVENT_MODALS/EDIT_EVENT_SHOW_MODAL'
export type EditEventShowModalAction = PayloadAction<typeof EDIT_EVENT_SHOW_MODAL, AosEvent>
export const editEventShowModalAction = payloadActionCreator<EditEventShowModalAction, AosEvent>(
    EDIT_EVENT_SHOW_MODAL,
)

export const EDIT_EVENT = 'EVENT_MODALS/EDIT_EVENT'
export type EditEventAction = PayloadAction<typeof EDIT_EVENT, [EventFormPayload, number]>
export const editEventAction = payloadActionCreator<EditEventAction, [EventFormPayload, number]>(
    EDIT_EVENT,
)

export const SAVE_LOCATION = 'EVENT_MODALS/SAVE_LOCATION'
export type SaveLocationAction = Action<typeof SAVE_LOCATION>
export const saveLocationAction = emptyActionCreator<SaveLocationAction>(SAVE_LOCATION)

export const EVENT_MODALS_MAP_PARENT = 'EVENT_MODALS/EVENT_MODALS_MAP_PARENT'
export type EventModalsMapParentAction = PayloadAction<typeof EVENT_MODALS_MAP_PARENT, MapAction>
export const eventModalsMapParentAction = payloadActionCreator<
    EventModalsMapParentAction,
    MapAction
>(EVENT_MODALS_MAP_PARENT)

export const EVENT_MODALS_ATTACHMENTS_PARENT = 'EVENT_MODALS/EVENT_MODALS_ATTACHMENTS_PARENT'
export type EventModalsAttachmentsParentAction = PayloadAction<
    typeof EVENT_MODALS_ATTACHMENTS_PARENT,
    AttachmentsAction
>
export const eventModalsAttachmentsParentAction = payloadActionCreator<
    EventModalsAttachmentsParentAction,
    AttachmentsAction
>(EVENT_MODALS_ATTACHMENTS_PARENT)

export const eventModalsMapAction: MapActionCreators = parentActionWrapper(
    eventModalsMapParentAction,
    mapActionCreators,
)

export const showThankYouForReportingAction = () =>
    showActionModalAction({
        ...modalContentFromKeyPrefix('thank-you-for-reporting'),
        svg: SvgIcon.Invitation,
    })

export const ADD_ATC_LAYER = 'EVENT_MODALS/ADD_ATC_LAYER'
export type AddAtcLayerAction = PayloadAction<typeof ADD_ATC_LAYER, string>
export const addAtcLayerAction = payloadActionCreator<AddAtcLayerAction, string>(ADD_ATC_LAYER)

export const REMOVE_ATC_LAYER = 'EVENT_MODALS/REMOVE_ATC_LAYER'
export type RemoveAtcLayerAction = PayloadAction<typeof REMOVE_ATC_LAYER, string>
export const removeAtcLayerAction = payloadActionCreator<RemoveAtcLayerAction, string>(
    REMOVE_ATC_LAYER,
)

export const SET_ATC_LAYERS = 'EVENT_MODALS/SET_ATC_LAYERS'
export type SetAtcLayersAction = PayloadAction<typeof SET_ATC_LAYERS, string[]>
export const setAtcLayersAction = payloadActionCreator<SetAtcLayersAction, string[]>(SET_ATC_LAYERS)

export type CreateEventAction =
    | EventHideModalAction
    | ReportSimpleFeedInShowModalAction
    | ReportExtendedFeedInShowModalAction
    | EditEventShowModalAction
    | ChangeFormValueAction
    | SetSimpleFormPristineAction
    | SetExtendedFormPristineAction
    | ReportSimpleFeedInAction
    | ReportExtendedFeedInAction
    | TryActivateFeedInAction
    | ActivateEventAction
    | EditEventAction
    | CreateEventShowModalAction
    | SaveLocationAction
    | EventModalsMapParentAction
    | EventModalsAttachmentsParentAction
    | CreateEventActionType
    | AddAtcLayerAction
    | RemoveAtcLayerAction
    | SetAtcLayersAction
    | ReopenEventShowModalAction
