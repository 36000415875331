import { mapValues } from 'lodash'

export type RecordKey = string | number | symbol

export const mapRecordValues = <T extends RecordKey, U, V>(
    r: Record<T, U>,
    mapper: (o: U, key: T, index?: number) => V,
) => {
    let index = 0
    return mapValues<Record<T, U>, V>(r, (v, k) => mapper(v, k as T, index++))
}

export const mapPartialRecordValues = <T extends RecordKey, U, V>(
    r: Partial<Record<T, U>>,
    mapper: (o: U, key: T, index?: number) => V,
): Partial<Record<T, V>> => {
    let index = 0
    return mapValues<Partial<Record<T, U>>, V | undefined>(r, (v: U | undefined, k) =>
        v !== undefined ? mapper(v, k as T, index++) : undefined,
    )
}

export const recordEntries = <T extends string, U>(record: Record<T, U>): [T, U][] =>
    Object.entries(record) as [T, U][]

export const partialRecordKeys = <T extends RecordKey>(record: Partial<Record<T, any>>): T[] =>
    Object.keys(record) as T[]
