export class PredicateMatcher<T, U> {
    public static of<P, R>(): PredicateMatcher<P, R> {
        return new PredicateMatcher()
    }

    private matchers: [(v: T) => boolean, U][]
    private elseValue: U | null = null

    private constructor() {
        this.matchers = []

        return this
    }

    public caseWhen(predicate: (v: T) => boolean, result: U): PredicateMatcher<T, U> {
        this.matchers.push([predicate, result])

        return this
    }

    public else(result: U): PredicateMatcher<T, U> {
        this.elseValue = result

        return this
    }

    public match(v: T): U | null {
        const matcher = this.matchers.find(m => m[0](v))

        return matcher ? matcher[1] : this.elseValue
    }
}
