import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    FiretruckMember,
    getEmptyFiretruckMember,
} from 'aos-services/src/services/tasks/firefighters/types/FirefighterFormFields'
import { FireTruck } from 'aos-services/src/services/tasks/types/task/FireTruck'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Button } from 'aos-ui/src/components/buttons/Button'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { RadioCheckbox } from '../../common/form/RadioCheckbox'
import { UserNameAutocomplete } from '../../common/form/UserNameAutocomplete'

interface FirefighterFormFiretruckProps {
    truck: FireTruck
    members: FiretruckMember[]
    onChange(members: FiretruckMember[]): void
    showHeader?: boolean
    isError?: boolean
}

const fireFighterSquadSize = 2

export const FirefighterFormFiretruck: FC<FirefighterFormFiretruckProps> = ({
    truck,
    members,
    onChange,
    showHeader,
    isError,
}) => {
    const updateMemberValue = (
        selectedMember: FiretruckMember,
        value?: Partial<FiretruckMember>,
    ) => {
        onChange(
            members.map(member => {
                if (member.key === selectedMember.key) {
                    return { ...member, ...value }
                }

                const category = { ...member }
                if (value?.lp3) {
                    category.lp3 = false
                }
                if (value?.kat12) {
                    category.kat12 = false
                }
                if (value?.lp5) {
                    category.lp5 = false
                }

                return category
            }),
        )
    }

    const addNewMember = () => {
        onChange([...members, getEmptyFiretruckMember()])
    }

    const removeMember = (member: FiretruckMember) => {
        onChange(members.filter(({ key }) => key !== member.key))
    }

    return (
        <>
            {showHeader && (
                <Box paddingHorizontal={30} paddingTop={24} row>
                    <Box flex={1} /> {/** Truck callSign placeholder */}
                    <Box flex={3}>
                        <Box paddingBottom={8} row>
                            <Box flex={3}>
                                <Text size={14} color={isError ? Color.Red : Color.Black}>
                                    {translate('firefighters.form.member')}
                                </Text>
                            </Box>
                            <Box row flex={2} justify='space-around'>
                                <Text size={14} color={isError ? Color.Red : Color.Black}>
                                    {translate('firefighters.form.lp3')}
                                </Text>
                                <Text size={14} color={isError ? Color.Red : Color.Black}>
                                    {translate('firefighters.form.lp5')}
                                </Text>
                                <Text size={14} color={isError ? Color.Red : Color.Black}>
                                    {translate('firefighters.form.kat12')}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

            <BorderedBox
                key={truck.id}
                paddingHorizontal={30}
                paddingVertical={8}
                alignItems='flex-start'
                row
            >
                <Box flex={1} paddingVertical={16}>
                    <Text size={14} color={Color.Black}>
                        {truck.callSign}
                    </Text>
                </Box>

                <Box flex={3}>
                    {members.map(member => (
                        <Box key={member.key} paddingVertical={8} row>
                            <Box flex={3} row>
                                <UserInputWrapper flex={1} marginRight={6}>
                                    <UserNameAutocomplete
                                        trimLongValues
                                        value={member.user}
                                        onChange={user => {
                                            updateMemberValue(member, { user })
                                        }}
                                        placeholder={translate(
                                            'firefighters.form.find-user-placeholder',
                                        )}
                                    />
                                </UserInputWrapper>
                                <IconButton
                                    svg={SvgIcon.Delete}
                                    iconVariant={IconVariant.Grey}
                                    onClick={() => removeMember(member)}
                                />
                            </Box>

                            <Box row flex={2} justify='space-around'>
                                <RadioCheckbox
                                    checked={member.lp3}
                                    onChange={lp3 => updateMemberValue(member, { lp3 })}
                                />

                                <RadioCheckbox
                                    checked={member.lp5}
                                    onChange={lp5 => updateMemberValue(member, { lp5 })}
                                />

                                <RadioCheckbox
                                    checked={member.kat12}
                                    onChange={kat12 => updateMemberValue(member, { kat12 })}
                                />
                            </Box>
                        </Box>
                    ))}

                    {members.length < fireFighterSquadSize && (
                        <AddMember onClick={addNewMember}>
                            {translate('firefighters.form.add-member')}
                        </AddMember>
                    )}
                </Box>
            </BorderedBox>
        </>
    )
}

const UserInputWrapper = styled(Box)`
    max-width: 200px;
`

const AddMember = styled(Button)`
    color: ${Color.Primary};
    margin-top: 4px;
    padding: 8px 0;
    font-size: 14px;
    font-weight: normal;

    &:hover {
        color: ${Color.PrimaryLight};
    }
`

const BorderedBox = styled(Box)`
    border-bottom: 1px solid ${Color.Grey1};
`
