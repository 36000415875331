import { FilterOptionAll } from 'aos-services/src/services/statusDashboard/types/filters/common'
import { AnimalSpecie } from 'aos-services/src/services/tasks/types/task/AnimalsTask'
import { DropdownVariant } from 'aos-ui/src/components/form/dropdown/base/DropdownVariant'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import React, { FC } from 'react'

import {
    ReportAnimals,
    translateReportAnimals,
} from '../../../../core/tasks/reports/types/ReportAnimals'

interface ReportAnimalsDropdownProps {
    animals: AnimalSpecie[]
    value: ReportAnimals
    onChange(v: ReportAnimals): void
}

export const ReportAnimalsDropdown: FC<ReportAnimalsDropdownProps> = ({
    value,
    onChange,
    animals,
}) => {
    return (
        <Dropdown
            variant={DropdownVariant.BlackGrey}
            value={value}
            items={[FilterOptionAll.All, ...animals]}
            valueRenderer={translateReportAnimals}
            onChange={onChange}
        />
    )
}
