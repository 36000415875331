import { syncValue } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { call, put, takeEvery } from 'redux-saga/effects'

import { sendToSyncRepository } from '../../dataaccess/sts/sendToSyncRepository'
import { SendToSyncType } from '../../dataaccess/sts/types/SendToSyncType'
import { firebaseService } from '../../services/firebase/firebaseService'
import { Towing } from '../../services/flightInformation/types/towing/Towing'
import { towingsService } from '../../services/towings/towingsService'
import {
    TOWING_LOAD,
    TOWING_SYNC,
    TOWING_SYNC_STOP,
    TowingLoadAction,
    towingLoadAction,
    towingLoadSuccessAction,
    TowingSyncAction,
    TowingSyncStopAction,
} from './actions'

const LISTENER_NAME = 'towing'

function* towingSyncSaga() {
    yield takeEvery<TowingSyncAction>(TOWING_SYNC, function* () {
        yield* firebaseService.onListener(LISTENER_NAME, function* () {
            yield syncValue(
                sendToSyncRepository.stsRef(SendToSyncType.TOWING),
                towingLoadAction,
                false,
            )
        }) as Generator
    })
}

function* towingSyncStopSaga() {
    yield takeEvery<TowingSyncStopAction>(TOWING_SYNC_STOP, function* () {
        yield firebaseService.offListener(LISTENER_NAME)
    })
}

function* towingLoadSaga() {
    yield takeEvery<TowingLoadAction>(TOWING_LOAD, function* () {
        const data: Towing[] = yield call(towingsService.loadTowings)
        yield put(towingLoadSuccessAction(data))
    })
}

export const towingSagas = [towingSyncSaga, towingSyncStopSaga, towingLoadSaga]
