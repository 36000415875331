import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { Text } from '../../base/Text'
import { Icon } from '../../svg/Icon'
import { CheckableBlock } from '../base/CheckableBlock'

interface IconRadioRendererProps {
    isSelected: boolean
    label?: ReactNode
    color?: Color
    icon: Svg
    disabled?: boolean
    onSelect(): void
}

export const IconRadioRenderer: FCWithChildren<IconRadioRendererProps> = ({
    isSelected,
    label,
    onSelect,
    color = Color.Primary,
    disabled,
    icon,
}) => (
    <CheckableBlock
        row
        isSelected={isSelected}
        color={color}
        disabled={disabled}
        onClick={!disabled ? onSelect : undefined}
        paddingHorizontal={8}
    >
        <Icon iconSize={BlockSize.Std} svg={icon} marginRight={label ? 8 : 0} />
        {label}
    </CheckableBlock>
)

export const IconRadioRendererLarge: FCWithChildren<IconRadioRendererProps> = ({
    isSelected,
    label,
    onSelect,
    color = Color.Primary,
    icon,
    disabled,
}) => (
    <CheckableBlock
        height={80}
        column
        isSelected={isSelected}
        color={color}
        onClick={!disabled ? onSelect : undefined}
        paddingHorizontal={8}
        alignItems='center'
        disabled={disabled}
    >
        <Box column flex={1} justify='flex-end' marginBottom={4}>
            <Icon iconSize={BlockSize.Std} svg={icon} />
        </Box>
        <Label marginBottom={8} textAlign='center'>
            {label}
        </Label>
    </CheckableBlock>
)

const Label = styled(Text)`
    height: 2em;
`
