import React, { FCWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { ThemeVariant } from '../../base/ThemeVariant'
import { CleanValueButton } from '../../buttons/CleanValueButton'
import { Textarea } from './Input'

interface CleanableTextareaProps {
    onChangeText?(value: string): void
    onBlur?(): void
    value?: string | number | null
    placeholder?: string
    variant?: ThemeVariant
    rows?: number
    resizeable?: boolean
    name?: string
}

export const CleanableTextarea: FCWithChildren<CleanableTextareaProps> = ({
    variant = ThemeVariant.White,
    value = '',
    onChangeText,
    onBlur,
    placeholder,
    rows,
    resizeable,
    name,
}) => {
    const ref = React.useRef<HTMLTextAreaElement>(null)
    const [hasScroll, setHasScroll] = useState(false)

    useEffect(() => updateHasScroll(), [])

    const updateHasScroll = () => {
        const target = ref.current
        if (target) {
            setHasScroll(target.scrollHeight > target.clientHeight)
        }
    }

    const updateValue = (value: string) => onChangeText?.(value)
    const clean = () => updateValue('')

    return (
        <Box relative>
            <CleanAreaTextarea
                ref={ref}
                name={name}
                resizeable={resizeable}
                rows={rows}
                value={value || ''}
                variant={variant}
                placeholder={placeholder}
                onChange={e => updateValue(e.target.value)}
                onKeyUp={updateHasScroll}
                onMouseUp={updateHasScroll}
                onBlur={onBlur}
            />
            {!!value && <CleanButton withScroll={hasScroll} onClick={clean} />}
        </Box>
    )
}

const CleanButton = styled(CleanValueButton)<{ withScroll: boolean }>`
    position: absolute;
    top: 8px;
    right: ${p => (p.withScroll ? 20 : 8)}px;
`

const CleanAreaTextarea = styled(Textarea)`
    padding-right: 32px;
`
