import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { useState } from 'react'
import { Dimensions, ItemProps, TimelineContext } from 'react-calendar-timeline'

import { BaseCalendarTimelineItem } from '../types/BaseCalendarTimelineItem'
import { TimelineItem, TimelineItemContentProps } from './TimelineItem'

/* eslint-disable react/no-unused-prop-types */
interface TimelineItemRendererProps<T extends BaseCalendarTimelineItem> {
    item: T
    dimensions: Dimensions
    timelineContext: TimelineContext
    itemProps: any
    isCollapsed: boolean
    ContentRenderer: (props: TimelineItemContentProps<T>) => React.ReactElement
    openItem(item: T): void
    container: Element
    withTooltip?: boolean
}

export const TimelineItemRenderer = <T extends BaseCalendarTimelineItem = BaseCalendarTimelineItem>(
    props: TimelineItemRendererProps<T>,
) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)

    const fixContentOffset = (p: ItemProps) => {
        const { style, className } = p
        const { background, border, ...restStyle } = style
        const { timelineContext } = props
        const canvasLeft = timelineContext.visibleTimeStart
        const canvasRight = timelineContext.visibleTimeEnd
        const factor = timelineContext.timelineWidth / (canvasRight - canvasLeft)
        const timelineLeft =
            (timelineContext.visibleTimeStart - timelineContext.canvasTimeStart) * factor
        const timelineRight =
            (timelineContext.visibleTimeEnd - timelineContext.canvasTimeStart) * factor
        const eventLeft = parseInt(style.left, 10)
        const eventRight = eventLeft + parseInt(style.width, 10)
        const newEventLeft =
            eventRight > timelineLeft ? Math.max(eventLeft, timelineLeft) : eventLeft
        const newEventRight =
            eventLeft < timelineRight ? Math.min(eventRight, timelineRight) : eventRight
        return {
            className,
            style: {
                ...restStyle,
                left: newEventLeft,
                width: newEventRight - newEventLeft,
            },
            isClippedLeft: newEventLeft !== eventLeft,
            isClippedRight: newEventRight !== eventRight,
        }
    }

    const { item, itemProps, isCollapsed, ContentRenderer } = props
    const previewAction = () => props.openItem(item)
    const newItemProps = fixContentOffset(itemProps)

    return (
        <Tooltip
            visible={isTooltipVisible && props.withTooltip}
            getTooltipContainer={() => props.container}
            overlay={<ContentRenderer item={item} inTooltip />}
        >
            <div className={newItemProps.className} style={newItemProps.style}>
                <TimelineItem
                    isCollapsed={isCollapsed}
                    isClippedLeft={newItemProps.isClippedLeft}
                    isClippedRight={newItemProps.isClippedRight}
                    withContent={!isCollapsed}
                    item={props.item}
                    inTooltip={false}
                    onHoverClipped={setTooltipVisible}
                    onClick={previewAction}
                    ContentRenderer={ContentRenderer}
                />
            </div>
        </Tooltip>
    )
}
