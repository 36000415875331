import { Feature, GeoJSON } from 'geojson'
import { Dictionary, fromPairs } from 'lodash'
import VektorEmbeddedAirportLite from 'vektor-embedded-airport-lite'

import { logger } from '../../../../aos-helpers/src/helpers/logging/Logger'
import { SelectedMapElementType } from '../map/types/SelectedMapElement'
import { BaggageClaimPictogramCategory } from './properties/BaggageClaimProperties'
import { GeoJSONWithFeature } from './types/BimLayer'

export class BimGeojsonService {
    public loadGates = async (): Promise<GeoJSONWithFeature> => {
        const layerData: GeoJSONWithFeature = await loadBimGeoLayer('digimap_wms:gates')
        const modifiedFeatures = layerData.features?.map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                Gate: feature.properties.label,
                selectTarget: SelectedMapElementType.Gate,
            },
        }))

        return {
            ...layerData,
            features: modifiedFeatures as Feature[],
        }
    }

    public loadBusGates = async (): Promise<GeoJSONWithFeature> => {
        const layerData: GeoJSONWithFeature = await loadBimGeoLayer('digimap_wms:bus_gates')
        const modifiedFeatures = layerData.features?.map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                Gate: feature.properties.label,
                selectTarget: SelectedMapElementType.Gate,
            },
        }))
        return {
            ...layerData,
            features: modifiedFeatures as Feature[],
        }
    }

    public loadBusDoors = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:bus_doors').then(layerData => layerData)

    public loadWalkingRoutes = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:walking_routes').then(layerData => layerData)

    public loadArrivalHalls = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:arrival_halls').then(layerData => layerData)

    public loadArrivalServices = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:arrival_services').then(layerData => layerData)

    public loadToiletsBabyCare = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:toilets_baby_care').then(layerData => layerData)

    public loadBaggageClaim = async (): Promise<GeoJSONWithFeature> => {
        const layerData: GeoJSONWithFeature = await loadBimGeoLayer('digimap_wms:baggage_claim')
        const modifiedFeatures = layerData.features?.map(feature => ({
            ...feature,
            properties: {
                ...feature.properties,
                belt: baggageClaimPictogramToBeltMapping[feature.properties.name],
                selectTarget: SelectedMapElementType.BaggageClaim,
            },
        }))
        return {
            ...layerData,
            features: modifiedFeatures as Feature[],
        }
    }

    public loadCheckInDesks = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:check_in_desks').then(layerData => layerData)

    public loadCheckInKiosks = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:check_in_kiosks').then(layerData => layerData)

    public loadCustoms = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:customs').then(layerData => layerData)

    public loadDisabledToilets = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:disabled_toilets').then(layerData => layerData)

    public loadLifts = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:lifts').then(layerData => layerData)

    public loadNonSchengenArea = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:non_schengen_area').then(layerData => layerData)

    public loadSecurityChecksPublic = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:security_checks_public').then(layerData => layerData)

    public loadSisScreens = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:sis_locations').then(layerData => layerData)

    public loadTerminalBuilding = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:terminal_outline').then(layerData => layerData)

    public loadToilets = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:toilets_men_women').then(layerData => layerData)

    public loadBusStops = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:bus_stops').then(layerData => layerData)

    public loadParkingHalls = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:parking_halls').then(layerData => layerData)

    public loadParkingLabels = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:parking_labels').then(layerData => layerData)

    public loadParkingMachines = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:parking_machines').then(layerData => layerData)

    public loadShuttleBusRoute = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:shuttle_bus_route').then(layerData => layerData)

    public loadShuttleBusStops = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:shuttle_bus_stops').then(layerData => layerData)

    public loadTaxiStands = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:taxi_stands').then(layerData => layerData)

    public loadTrainStations = (): Promise<GeoJSON> =>
        loadBimGeoLayer('digimap_wms:train_stations').then(layerData => layerData)

    public loadSecurityCheckStaff = (): Promise<GeoJSON> =>
        loadBimGeoLayer('terminaali:security_check_staff').then(layerData => layerData)

    public loadStands = async (): Promise<GeoJSONWithFeature> => {
        const layerData: GeoJSONWithFeature = await loadBimGeoLayer('Taustakartta:asematasopisteet')
        const modifiedFeatures = layerData.features?.map((feature: Feature) => ({
            ...feature,
            properties: {
                ...feature.properties,
                teksti: feature.properties?.tunnus, // mobile map backward compatibility
                selectTarget: SelectedMapElementType.Stand,
                pictogram: 'FA_Pictogram_Series_' + feature.properties?.tunnus + '_Stand',
            },
        }))
        return {
            ...layerData,
            features: modifiedFeatures as Feature[],
        }
    }
}

const loadBimGeoLayer = (layerId: string): Promise<GeoJSON> => {
    return VektorEmbeddedAirportLite.fetchVectorLayerData(layerId)
        .then(layerData => layerData)
        .catch(e => {
            logger.handleError(e)
        })
}

const baggageClaimPictogramToBeltMapping: Dictionary<string> = fromPairs([
    [BaggageClaimPictogramCategory.One, '1'],
    [BaggageClaimPictogramCategory.TwoA, '2A'],
    [BaggageClaimPictogramCategory.TwoB, '2B'],
])

export const bimGeojsonService = new BimGeojsonService()
