import { translate } from 'aos-helpers/src/helpers/translations/Translations'

import { BaggageClaimProperties } from '../layerData/properties/BaggageClaimProperties'
import { busDoorNumber, BusDoorProperties } from '../layerData/properties/BusDoorProperties'
import { GatesProperties, gateToGatesArray } from '../layerData/properties/GatesProperties'
import { StandProperties } from '../layerData/properties/StandProperties'
import { SelectedMapElement, SelectedMapElementType } from './types/SelectedMapElement'

export const gateTitle = (gate: GatesProperties) =>
    `${translate('map.sidebar.gate')} ${gateToGatesArray(gate).join(', ')}`

export const standTitle = (s: StandProperties) => `${translate('map.sidebar.stand')} ${s.teksti}`

export const beltTitle = (b: BaggageClaimProperties) => `${translate('map.sidebar.belt')} ${b.belt}`

export const busDoorTitle = (b: BusDoorProperties) =>
    `${translate('map.sidebar.bus-door')} ${busDoorNumber(b)}`

export const selectionTargetTitle = (selectedElement: SelectedMapElement) => {
    if (selectedElement.payload) {
        switch (selectedElement.type) {
            case SelectedMapElementType.Event: {
                return selectedElement.payload.event.title
            }
            case SelectedMapElementType.Task: {
                return selectedElement.payload.task.title
            }
            case SelectedMapElementType.Gate: {
                return gateTitle(selectedElement.payload)
            }
            case SelectedMapElementType.BusGate: {
                return gateTitle(selectedElement.payload)
            }
            case SelectedMapElementType.Stand: {
                return standTitle(selectedElement.payload)
            }
            case SelectedMapElementType.BaggageClaim: {
                return beltTitle(selectedElement.payload)
            }
            case SelectedMapElementType.BusDoor: {
                return busDoorTitle(selectedElement.payload)
            }
            default:
                return
        }
    } else {
        return
    }
}
