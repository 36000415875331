import { isNotEmpty, isNotEmptyArray } from 'aos-helpers/src/helpers/FormValidation'

import { TaskRecurrenceType } from '../task/TaskRecurrenceType'
import { TaskRecurrenceWeekDay } from '../task/TaskRecurrenceWeekly'

export interface TaskRecurrenceWeeklyPayload {
    type: TaskRecurrenceType.Weekly
    duration: number
    every: number
    weekDays: TaskRecurrenceWeekDay[]
}

export const defaultTaskRecurrenceWeekly: TaskRecurrenceWeeklyPayload = {
    type: TaskRecurrenceType.Weekly,
    duration: 60,
    every: 1,
    weekDays: [],
}

export interface TaskRecurrenceWeeklyValidation {
    duration: boolean
    every: boolean
    weekDays: boolean
}

export const validateTaskRecurrenceWeekly = (
    r: TaskRecurrenceWeeklyPayload,
): TaskRecurrenceWeeklyValidation => ({
    duration: isNotEmpty(r.duration) && r.duration > 0,
    every: isNotEmpty(r.every) && r.every > 0,
    weekDays: isNotEmptyArray(r.weekDays),
})
