import { ResponseError } from 'aos-helpers/src/helpers/error/ResponseError'

import { BaseRestService } from '../BaseRestService'

const incorrectStatusesResponseInterceptor = async (r: Response): Promise<Response> => {
    if (r.status >= 200 && r.status < 300) {
        return Promise.resolve(r)
    } else {
        try {
            const errorPayload = await r.json()
            throw new ResponseError(r, errorPayload)
        } catch (e) {
            throw new ResponseError(r)
        }
    }
}

export const registerIncorrectStatusesResponseInterceptor = () => {
    BaseRestService.responseInterceptors.push(incorrectStatusesResponseInterceptor)
}
