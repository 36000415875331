import { BlockSize } from 'aos-helpers/src/helpers/Block'
import React, { ReactNode, useRef, useState } from 'react'

import { Box, NumberValues } from '../../../base/Box'
import { ClickOutside } from '../../../base/ClickOutside'
import { IdAware } from '../../../base/IdAware'
import { CleanValueButton } from '../../../buttons/CleanValueButton'
import { CollapsibleButton, CollapsibleButtonVariant } from '../../../buttons/CollapsibleButton'
import { Tooltip } from '../../../tooltip/Tooltip'
import { DropdownBottomContent, dropdownStyles, DropdownTopContent } from './DropdownContent'
import { DropdownHeight } from './DropdownHeight'
import { DropdownPortal, DropdownPortalType } from './DropdownPortal'
import { DropdownScrollbar } from './DropdownScrollbar'
import { DropdownVariant } from './DropdownVariant'
import { DropdownWidth } from './DropdownWidth'

export interface DropdownItemProps<T> {
    v: T
    index: number
    variant: DropdownVariant
    onClick(e: React.MouseEvent<any>): void
}

interface BaseDropdownProps<T> extends IdAware {
    items: T[]
    content: ReactNode
    emptyContent?: ReactNode
    variant?: DropdownVariant
    width?: DropdownWidth | number
    height?: DropdownHeight
    type?: DropdownPortalType
    outlined?: boolean
    maxHeight?: number
    minDropWidth?: number
    toggleVisible?: boolean
    cleanVisible?: boolean
    tooltip?: ReactNode
    disabled?: boolean
    ItemRenderer: React.ComponentType<DropdownItemProps<T>>

    paddingHorizontal?: NumberValues

    isOpen?: boolean

    clearAction?(): void
    setOpen?(v: boolean): void

    onItemClick(val: T, toggle: () => void): void
}

export const BaseDropdown = <T extends any>(props: BaseDropdownProps<T>) => {
    const [isOpenInternal, setOpenInternal] = useState<boolean>(false)
    const {
        id,
        toggleVisible = true,
        variant = DropdownVariant.White,
        clearAction,
        content,
        tooltip,
        items,
        ItemRenderer,
        onItemClick,
        type = DropdownPortalType.ElementWidth,
        paddingHorizontal,
        width = 'auto',
        height = DropdownHeight.Std,
        cleanVisible,
        isOpen = isOpenInternal,
        setOpen = setOpenInternal,
        emptyContent,
        outlined,
        maxHeight,
        minDropWidth,
        disabled,
    } = props
    const ref = useRef<HTMLDivElement>(null)
    const toggleOpen = () => {
        setOpen(!isOpen)
    }

    const styles = dropdownStyles[variant]

    const clickOutside = (e: React.MouseEvent<Element>) => {
        if (!ref.current!.contains(e.target as Element)) {
            setOpen(false)
        }
    }

    const toggleButton = () => (
        <Box shrink={0}>
            <CollapsibleButton
                variant={CollapsibleButtonVariant.TopBottom}
                onClick={toggleOpen}
                iconVariant={styles.toggleVariant}
                iconSize={BlockSize.Small}
                isCollapsed={!isOpen}
                svg={styles.toggleSvg}
            />
        </Box>
    )

    const clearButton = () => (
        <Box shrink={0}>
            <CleanValueButton onClick={clearAction} padding={2} />
        </Box>
    )

    const contentClick = () => {
        if (!props.setOpen) {
            toggleOpen()
        }
    }

    const hasClean = clearAction && cleanVisible
    const hasButtons = hasClean || toggleVisible
    const dropdownContent = (
        <DropdownTopContent
            id={id}
            disabled={disabled}
            onClick={contentClick}
            ref={ref}
            isOpen={isOpen}
            variant={variant}
            paddingHorizontal={paddingHorizontal}
            width={width}
            minHeight={height}
            outlined={outlined}
            row
        >
            <Box row flex='auto'>
                {content}
            </Box>
            {hasButtons && (
                <Box row shrink={0} paddingLeft={8}>
                    {hasClean && clearButton()}
                    {toggleVisible && toggleButton()}
                </Box>
            )}
        </DropdownTopContent>
    )

    const bottomContent = isOpen && (
        <DropdownPortal element={ref} type={type}>
            <ClickOutside onClickOutside={clickOutside} data-test-id='menu-expanded'>
                <DropdownBottomContent
                    variant={variant}
                    paddingVertical={8}
                    outlined={outlined}
                    minWidth={minDropWidth}
                >
                    <DropdownScrollbar variant={variant} maxHeight={maxHeight}>
                        {items.length
                            ? items.map((val, index) => (
                                  <ItemRenderer
                                      key={index}
                                      index={index}
                                      v={val}
                                      onClick={() => onItemClick(val, toggleOpen)}
                                      variant={variant}
                                  />
                              ))
                            : emptyContent}
                    </DropdownScrollbar>
                </DropdownBottomContent>
            </ClickOutside>
        </DropdownPortal>
    )

    if (tooltip) {
        return (
            <>
                <Tooltip body={tooltip} placement='top' withArrow>
                    {dropdownContent}
                </Tooltip>
                {bottomContent}
            </>
        )
    }
    return (
        <>
            {dropdownContent}
            {bottomContent}
        </>
    )
}
