import { mapPageableContent, Pageable } from 'aos-helpers/src/helpers/Pageable'
import { pageRequestToQuery } from 'aos-helpers/src/helpers/PageRequest'

import { AosUserDto } from '../../dataaccess/users/types/AosUserDto'
import { userManagementRestService } from '../../dataaccess/users/userManagementRestService'
import { newAdUserToAosAdUserInputDto, newContactToAosContactInputDto } from './aosUserConverter'
import { AdUserFormData } from './input/AdUserFormData'
import { ContactFormData } from './input/ContactFormData'
import { UserPageRequest } from './transport/UsersPageRequest'
import { AosAdUserImpl } from './types/AosAdUserImpl'
import { AosContactImpl } from './types/AosContactImpl'
import { AosUser } from './types/AosUser'
import { AosUserType } from './types/AosUserType'

class UserManagementService {
    public getGroupUsers = (
        groupId: number,
        pageRequest: UserPageRequest,
    ): Promise<Pageable<AosUser>> =>
        userManagementRestService
            .getGroupUsers(groupId, pageRequestToQuery(pageRequest))
            .then(mapPageableContent(t => this.aosUserFromDto(t)))

    public getFilteredGroupUsers = (
        groupId: number,
        pageRequest: UserPageRequest,
    ): Promise<Pageable<AosUser>> =>
        userManagementRestService
            .getFilteredGroupUsers(
                groupId,
                pageRequest.filter.searchUserText || '',
                pageRequestToQuery(pageRequest),
            )
            .then(mapPageableContent(t => this.aosUserFromDto(t)))

    public searchUserByEmail = (email: string): Promise<AosUser> =>
        userManagementRestService.searchUserByEmail(email).then(user => this.aosUserFromDto(user))

    public getUserById = (id: number): Promise<AosUser> =>
        userManagementRestService.getUserById(id).then(user => this.aosUserFromDto(user))

    public createContact = (user: ContactFormData): Promise<AosUser> =>
        userManagementRestService
            .createContact(newContactToAosContactInputDto(user))
            .then(u => this.aosUserFromDto(u))

    public updateContact = (user: ContactFormData): Promise<Response> =>
        userManagementRestService.updateContact(newContactToAosContactInputDto(user), user.id!)

    public initUser = () => userManagementRestService.initUser()

    public createAdUser = (user: AdUserFormData): Promise<AosUser> =>
        userManagementRestService
            .createAdUser(newAdUserToAosAdUserInputDto(user))
            .then(u => this.aosUserFromDto(u))

    public updateAdUser = (user: AdUserFormData): Promise<Response> =>
        userManagementRestService.updateAdUser(newAdUserToAosAdUserInputDto(user), user.id!)

    public removeUser = (userId: number): Promise<Response> =>
        userManagementRestService.removeUser(userId)

    public restoreUser = (userId: number): Promise<Response> =>
        userManagementRestService.restoreUser(userId)

    public removeUserPermanently = (userId: number): Promise<Response> =>
        userManagementRestService.removeUserPermanently(userId)

    private aosUserFromDto(value: AosUserDto): AosUser {
        switch (value.origin) {
            case AosUserType.MANUAL:
                return new AosContactImpl(value)
            case AosUserType.INTERNAL:
            case AosUserType.EXTERNAL:
                return new AosAdUserImpl(value)
            default:
                return undefined as any
        }
    }
}

export const userManagementService = new UserManagementService()
