import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
interface ApocRouteProps extends RouteProps {
    component?: any
    isApoc: boolean
}

const ApocRoute: React.FC<ApocRouteProps> = ({ component: Component, isApoc, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isApoc ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default ApocRoute
