import { CommonFrontendAction, LOAD_COMMON_GROUPS_SUCCESS } from './actions'
import { CommonFrontendState, initialCommonFrontendState } from './state'

export function commonFrontendReducer(
    state = initialCommonFrontendState,
    action: CommonFrontendAction,
): CommonFrontendState {
    switch (action.type) {
        case LOAD_COMMON_GROUPS_SUCCESS:
            return { ...state, groups: action.payload }
        default:
            return state
    }
}
