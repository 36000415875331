import React, { PureComponent } from 'react'

export class ListTooltipBody extends PureComponent<ListTooltipBodyProps> {
    public render() {
        const { items } = this.props
        if (items.length > 1) {
            return (
                <ul>
                    {items.map((i, index) => (
                        <li key={index}>{`- ${i}`}</li>
                    ))}
                </ul>
            )
        } else if (items.length === 1) {
            return <p>{items[0]}</p>
        }
        return null
    }
}

interface ListTooltipBodyProps {
    items: string[]
}
