import { State as WebState } from 'aos-frontend/src/app/core/state'
import { syncValueWithCallback } from 'aos-helpers/src/helpers/firebase/FirebaseSaga'
import { State as MobileState } from 'aos-mobile/src/app/core/state'
import { call, select, takeEvery } from 'redux-saga/effects'

import { firebaseEventsRepository } from '../../dataaccess/firebaseEvents/firebaseEventsRepository'
import { handleUserLayerVisibilityChange } from '../../services/events/util'
import { firebaseService } from '../../services/firebase/firebaseService'
import { firebaseEventsService } from '../../services/firebaseEvents/firebaseEventsService'
import {
    ADD_EVENT_LAYER_VISIBILITY,
    AddEventLayerVisibility,
    SYNC_CHECKLIST,
    SyncChecklistAction,
    UPDATE_EVENT_LAYER_VISIBILITY,
    UpdateEventLayerVisibility,
} from './actions'

export function* syncChecklistSaga(eventId: number, reloadEvent: (id: number) => Generator) {
    yield* firebaseService.restartListener('checklist', function* () {
        yield syncValueWithCallback(
            firebaseEventsRepository.checklistRef(eventId),
            function* () {
                yield reloadEvent(eventId)
            },
            true,
        )
    }) as Generator
}

function* updateChecklistSaga() {
    yield takeEvery<SyncChecklistAction>(SYNC_CHECKLIST, function* (action) {
        yield call(
            firebaseEventsService.updateChecklist,
            action.payload.eventId,
            action.payload.checklistId,
        )
    })
}

const isMobile = (state: MobileState | WebState): state is MobileState => {
    return !!(state as MobileState)?.eventList?.firebaseEvent
}

const getValuesForStateType = (state: MobileState | WebState) => {
    if (isMobile(state)) {
        return {
            eventId: state.eventList.event?.id,
            groupId: state.eventList.event?.privateGroup?.id,
            currentVisibilityLayers: state.eventList.firebaseEvent?.visibilityLayers,
        }
    } else {
        return {
            eventId: state.eventEditor.event?.id,
            groupId: state.eventEditor.event?.privateGroup?.id,
            currentVisibilityLayers: state.eventEditor.firebaseEvent?.visibilityLayers,
        }
    }
}

type ValuesForStateType = ReturnType<typeof getValuesForStateType>

function* updateEventLayersVisibilitySaga() {
    yield takeEvery<UpdateEventLayerVisibility>(UPDATE_EVENT_LAYER_VISIBILITY, function* (action) {
        const { currentVisibilityLayers, groupId, eventId }: ValuesForStateType = yield select(
            (state: MobileState | WebState) => getValuesForStateType(state),
        )
        if (groupId && eventId) {
            const state = handleUserLayerVisibilityChange(
                action.payload,
                currentVisibilityLayers || [],
            )
            yield call(firebaseEventsService.updateVisibilityLayers, eventId, state)
        }
    })
}

function* addEventVisibilityLayerSaga() {
    yield takeEvery<AddEventLayerVisibility>(ADD_EVENT_LAYER_VISIBILITY, function* (action) {
        yield call(
            firebaseEventsService.updateVisibilityLayers,
            action.payload.eventId,
            action.payload.visibilityLayers,
        )
    })
}

export function* syncEventLayersSaga(eventId: number, reloadEvent: (id: number) => Generator) {
    yield* firebaseService.restartListener('visibilityLayers', function* () {
        yield syncValueWithCallback(
            firebaseEventsRepository.visibilityLayersRef(eventId),
            function* () {
                yield reloadEvent(eventId)
            },
            true,
        )
    }) as Generator
}

export const baseEventSagas = [
    updateChecklistSaga,
    updateEventLayersVisibilitySaga,
    addEventVisibilityLayerSaga,
]
