import { FormButton, FormButtonVariant } from 'aos-ui/src/components/buttons/FormButton'
import _ from 'lodash'
import React, { FC, PropsWithChildren } from 'react'

import { ClassNameProps, cx, cxp } from '../../base/ClassNames'

export enum ActionModalMode {
    Normal,
    Important,
}

export const ActionModalContent: FC<PropsWithChildren<ActionModalContentProps>> = props => {
    const Footer =
        props.Footer ||
        ((() => (
            <div className='container__item--centered'>
                <FormButton
                    label={props.okLabel}
                    onClick={props.onClickAction || _.noop}
                    variant={buttonVariantForMode(props.mode)}
                />
            </div>
        )) as React.ElementType)
    return (
        <div
            className={cxp(
                props,
                'container container--vertical flex--1 padding-vertical--x-large',
            )}
        >
            {props.title ? (
                <div
                    className={cx(
                        props.titleClassName
                            ? { [props.titleClassName]: true }
                            : 'text__modal-title text--lh-std',
                    )}
                >
                    {props.title}
                </div>
            ) : null}
            {props.children}
            {props.Content ? <props.Content /> : null}
            {props.Content ? null : (
                <div className='padding-top--x-large'>
                    <Footer />
                </div>
            )}
        </div>
    )
}

const buttonVariantForMode = (m?: ActionModalMode) => {
    switch (m) {
        case ActionModalMode.Normal:
            return FormButtonVariant.Blue

        case ActionModalMode.Important:
            return FormButtonVariant.Red

        default:
            return FormButtonVariant.Blue
    }
}

export interface ActionModalContentProps extends ClassNameProps {
    titleClassName?: string
    title?: string | JSX.Element
    okLabel?: string
    mode?: ActionModalMode
    onClickAction?(): void
    skipCloseAction?: boolean
    Footer?: React.ElementType
    Content?: React.ElementType
    isNonDismissible?: boolean
}
