import { PayloadAction, payloadActionCreator } from 'aos-helpers/src/helpers/ActionCreator'
import { FirebaseActionPayload } from 'aos-helpers/src/helpers/firebase/FirebaseCollectionReducer'

import { PaxEntry } from '../../../services/pax/types/PaxEntry'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const PAX_SYNC = 'STATUS_DASHBOARD_DATA/PAX/PAX_SYNC'
export type PaxSyncAction = PayloadAction<typeof PAX_SYNC, SyncDataKey>
export const paxSyncAction = payloadActionCreator<PaxSyncAction, SyncDataKey>(PAX_SYNC)

export const PAX_SYNC_SUCCESS = 'STATUS_DASHBOARD_DATA/PAX/PAX_SYNC_SUCCESS'
export type PaxSyncSuccessAction = PayloadAction<
    typeof PAX_SYNC_SUCCESS,
    FirebaseActionPayload<PaxEntry>
>
export const paxSyncSuccessAction = payloadActionCreator<
    PaxSyncSuccessAction,
    FirebaseActionPayload<PaxEntry>
>(PAX_SYNC_SUCCESS)

export const PAX_SYNC_STOP = 'STATUS_DASHBOARD_DATA/PAX/PAX_SYNC_STOP'
export type PaxSyncStopAction = PayloadAction<typeof PAX_SYNC_STOP, SyncDataKey>
export const paxSyncStopAction = payloadActionCreator<PaxSyncStopAction, SyncDataKey>(PAX_SYNC_STOP)

export type PaxAction = PaxSyncSuccessAction | PaxSyncAction | PaxSyncStopAction
