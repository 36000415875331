import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { WeatherState } from '../../../services/airportStatus/weather/types/WeatherState'
import { SyncDataKey } from '../../../services/statusDashboard/types/DashboardDataState'

export const WEATHER_SYNC = 'STATUS_DASHBOARD_DATA/WEATHER/WEATHER_SYNC'
export type WeatherSyncAction = PayloadAction<typeof WEATHER_SYNC, SyncDataKey>
export const weatherSyncAction = payloadActionCreator<WeatherSyncAction, SyncDataKey>(WEATHER_SYNC)

export const WEATHER_SYNC_STOP = 'STATUS_DASHBOARD_DATA/WEATHER/WEATHER_SYNC_STOP'
export type WeatherSyncStopAction = PayloadAction<typeof WEATHER_SYNC_STOP, SyncDataKey>
export const weatherSyncStopAction = payloadActionCreator<WeatherSyncStopAction, SyncDataKey>(
    WEATHER_SYNC_STOP,
)

export const WEATHER_LOAD = 'STATUS_DASHBOARD_DATA/WEATHER/WEATHER_LOAD'
export type WeatherLoadAction = Action<typeof WEATHER_LOAD>
export const weatherLoadAction = emptyActionCreator<WeatherLoadAction>(WEATHER_LOAD)

export const WEATHER_LOAD_SUCCESS = 'STATUS_DASHBOARD_DATA/WEATHER/WEATHER_LOAD_SUCCESS'
export type WeatherLoadSuccessAction = PayloadAction<typeof WEATHER_LOAD_SUCCESS, WeatherState>
export const weatherLoadSuccessAction = payloadActionCreator<
    WeatherLoadSuccessAction,
    WeatherState
>(WEATHER_LOAD_SUCCESS)

export type WeatherAction =
    | WeatherSyncAction
    | WeatherSyncStopAction
    | WeatherLoadAction
    | WeatherLoadSuccessAction
