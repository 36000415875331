import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import { optionalConvertAndRoundKtTo } from 'aos-services/src/services/airportStatus/weather/types/WeatherMetrics'
import { WeatherUnit } from 'aos-services/src/services/statusDashboard/types/filters/WeatherUnit'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import React, { FC } from 'react'

import { WeatherMetric } from './WeatherMetric'

interface WindSpeedProps {
    unit: WeatherUnit
    value?: number
}

export const WindSpeed: FC<WindSpeedProps> = props => (
    <WeatherMetric
        label={translate('dashboard.weather.wind-speed')}
        value={optionalConvertAndRoundKtTo(props.unit)(props.value)}
        unit={translateEnum<WeatherUnit>(WeatherUnit, 'dashboard.weather.unit-tabs')(props.unit)}
    >
        <Icon
            svg={SvgIcon.WeatherWindSpeed}
            iconSize={BlockSize.Large}
            iconVariant={IconVariant.White}
        />
    </WeatherMetric>
)
