import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { Towing } from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { TableHeaderButton } from 'aos-ui/src/components/table/TableHeaderButton'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { PureComponent, ReactNode } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { changeSortingAction } from '../../../core/flightInformation/actions'
import { sortingSelector } from '../../../core/flightInformation/selectors'
import { FlightSort, SortingRule } from '../../../core/flightInformation/state'
import { TowingFilters } from '../../../core/flightInformation/towing/towingFiltersState'
import { towingFiltersSelector } from '../../../core/flightInformation/towing/towingSelectors'

interface Caption {
    name: keyof Towing
    label?: string
    filter?: ReactNode
}

interface TowingTableHeaderProps
    extends TowingTableHeaderStateProps,
        TowingTableHeaderDispatchProps {
    section: string
    captions: Caption[]
}

class TowingTableHeaderClass extends PureComponent<TowingTableHeaderProps> {
    public render() {
        const { section, captions } = this.props
        return (
            <Text textAlign='center' column justify='center' fullHeight paddingTop={8}>
                <span className='text__l-blue text--upper'>
                    {translate(`flight-information.table.${section}.section`)}
                </span>
                <Box className='flight-table-equal-columns' marginTop={12}>
                    {captions.map(this.renderCaption)}
                </Box>
            </Text>
        )
    }

    private renderCaption = ({ name, label = name, filter }: Caption) => {
        const { filtersVisible } = this.props.filters
        const { sort } = this.props
        return (
            <Box key={name}>
                <Tooltip
                    body={translate(`flight-information.table.${label}.description`)}
                    withArrow
                >
                    <TableHeaderButton
                        sort={{
                            sort: {
                                key: sort!.field,
                                direction: sort!.desc ? 'desc' : 'asc',
                            },
                            sortKey: name,
                            onChange: v =>
                                this.props.changeSorting({
                                    value: { field: v.key },
                                    type: FlightSort.TOWING,
                                }),
                        }}
                        label={translate(`flight-information.table.${label}.title`)}
                    />
                </Tooltip>
                {filtersVisible && filter}
            </Box>
        )
    }
}

interface TowingTableHeaderStateProps {
    sort: SortingRule | undefined
    filters: TowingFilters
}

interface TowingTableHeaderDispatchProps {
    changeSorting: typeof changeSortingAction
}

export const TowingTableHeader = connect<
    TowingTableHeaderStateProps,
    TowingTableHeaderDispatchProps
>(
    createStructuredSelector({
        sort: sortingSelector(FlightSort.TOWING),
        filters: towingFiltersSelector,
    }),
    {
        changeSorting: changeSortingAction,
    },
)(TowingTableHeaderClass)
