import { Box } from 'aos-ui/src/components/base/Box'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { PieChartInner, PieChartInnerProps } from './PieChartInner'

export interface PieChartProps<T> extends PieChartInnerProps<T> {
    legendContent?: JSX.Element
    label?: JSX.Element
    compact?: boolean
}

export class PieChart<T> extends PureComponent<PieChartProps<T>> {
    public render() {
        const { arcContent, yAccessor, data, seriesConfig, compact } = this.props
        return (
            <Box overflow='hidden' row alignItems='flex-end' flex='auto'>
                <Box column paddingRight={40} shrink={0}>
                    <Container compact={compact}>
                        <PieChartInner
                            seriesConfig={seriesConfig}
                            arcContent={arcContent}
                            yAccessor={yAccessor}
                            data={data}
                        />
                    </Container>
                    {this.props.label}
                </Box>
                {this.props.legendContent}
            </Box>
        )
    }
}

const Container = styled(Box)<{ compact?: boolean }>`
    width: ${p => (p.compact ? 180 : 192)}px;
    height: ${p => (p.compact ? 90 : 96)}px;
    position: relative;
`
