import { dateTime } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { RestrictionDto } from '../../../dataaccess/restrictions/types/RestrictionDto'
import { Restriction } from './Restriction'
import { RestrictionAreaType } from './RestrictionArea'
import { RestrictionResourceType } from './RestrictionResourceType'

export class RestrictionImpl extends WrapperObject<RestrictionDto> implements Restriction {
    public id = this.value.restrictionId
    public startDate = dateTime(this.value.startDate)
    public endDate = dateTime(this.value.endDate)
    public resourceType = this.value.resourceType as RestrictionResourceType
    public resourceNumber = this.value.resourceNumber
    public areaType = this.value.areaType as RestrictionAreaType
    public areaNumber = this.value.areaNumber
    public message = this.value.message
    public aosMessage = this.value.aosMessage
}
