export const waitingTimeConfig = {
    LONG: {
        label: '> 30 min',
        value: 30,
    },
    MEDIUM: {
        label: '15 - 30 min',
        value: 15,
    },
    SHORT: {
        label: '< 15 min',
        value: 0,
    },
}
