import { Pageable } from 'aos-helpers/src/helpers/Pageable'
import { format, Query } from 'url'

import { BaseRestService } from '../base/BaseRestService'
import { AosAdUserInputDto, AosContactInputDto, AosUserDto } from './types/AosUserDto'

class UserManagementService extends BaseRestService {
    public getUserById(id: number): Promise<AosUserDto> {
        return this.getAndTransform<AosUserDto>(
            `/user-management/${id}`,
            BaseRestService.jsonTransformer,
        )
    }

    public initUser = (): Promise<Response> => {
        return this.post('/user-management/init')
    }

    public getGroupUsers(groupId: number, pr: Query) {
        return this.getAndTransform<Pageable<AosUserDto>>(
            format({
                pathname: `/groups/${groupId}/members`,
                query: pr,
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public getFilteredGroupUsers(groupId: number, filter: string, pr: Query) {
        const query = filter ? { f: filter } : {}

        return this.getAndTransform<Pageable<AosUserDto>>(
            format({
                pathname: `/groups/${groupId}/members`,
                query: { ...pr, ...query },
            }),
            BaseRestService.jsonTransformer,
        )
    }

    public searchUserByEmail(email: string) {
        return this.getAndTransform<AosUserDto>(
            `/user-management?email=${email}`,
            BaseRestService.jsonTransformer,
        )
    }

    public createContact(user: AosContactInputDto): Promise<AosUserDto> {
        return this.postJsonAndTransform<AosUserDto>(
            '/user-management/contacts',
            user,
            BaseRestService.jsonTransformer,
        )
    }

    public updateContact(user: AosContactInputDto, userId: number): Promise<Response> {
        return this.patchJson(`/user-management/contacts/${userId}`, user)
    }

    public createAdUser(user: AosAdUserInputDto): Promise<AosUserDto> {
        return this.postJsonAndTransform<AosUserDto>(
            '/user-management',
            user,
            BaseRestService.jsonTransformer,
        )
    }

    public updateAdUser(user: AosAdUserInputDto, userId: number): Promise<Response> {
        return this.patchJson(`/user-management/${userId}`, user)
    }

    public removeUser(userId: number): Promise<Response> {
        return this.postJson(`/user-management/${userId}/block`, {})
    }

    public restoreUser(userId: number): Promise<Response> {
        return this.postJson(`/user-management/${userId}/restore`, {})
    }

    public removeUserPermanently(userId: number): Promise<Response> {
        return this.deleteReq(`/user-management/${userId}`)
    }
}

export const userManagementRestService = new UserManagementService()
