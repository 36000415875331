import { AosFcmToken } from '../../dataaccess/users/types/AosFcmToken'
import { userProfileRestService } from '../../dataaccess/users/userProfileRestService'
import { AosAdUserImpl } from './types/AosAdUserImpl'
import { AosAdUser } from './types/AosUser'
import { AosUserNotificationsPreferences } from './types/AosUserNotificationsPreferences'
import { AosUserProfile } from './types/AosUserSetting'

class UserProfileService {
    public getUserProfileById = (id: number): Promise<AosAdUser> =>
        userProfileRestService.getProfileById(id).then(user => new AosAdUserImpl(user))

    public async addFcmTokenToUser(userId: number, fcmToken: AosFcmToken): Promise<void> {
        await userProfileRestService.addFcmTokenToUser(userId, fcmToken)
    }

    public async removeFcmTokenFromUser(userId: number, token: string): Promise<void> {
        await userProfileRestService.removeFcmTokenFromUser(userId, token)
    }

    public updateUserNotifications = (
        userId: number,
        preferences: AosUserNotificationsPreferences,
    ): Promise<Response> => userProfileRestService.updateUserNotifications(userId, preferences)

    public updateNotificationPreferences = (
        user: AosAdUser,
        newPreferences: Partial<AosUserNotificationsPreferences>,
    ) => {
        const originalDto = (user as AosAdUserImpl).dto
        const notificationPreferences = originalDto.notificationPreferences
        return new AosAdUserImpl({
            ...originalDto,
            notificationPreferences: notificationPreferences
                ? {
                      ...notificationPreferences,
                      ...newPreferences,
                  }
                : undefined,
        })
    }

    public updateUserProfile = (userId: number, profile: AosUserProfile): Promise<Response> =>
        userProfileRestService.updateUserProfile(userId, profile)

    public deleteAccount = (email: string) => userProfileRestService.deleteAccount(email)
}

export const userProfileService = new UserProfileService()
