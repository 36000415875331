import {
    CLOSE_SHIFT_NOTES_MODAL,
    OPEN_SHIFT_NOTES_MODAL,
    SHIFT_NOTES_LOAD,
    SHIFT_NOTES_LOAD_SUCCESS,
    SHIFT_NOTES_RELOAD,
    SHIFT_NOTES_RELOAD_SUCCESS,
    ShiftNotesAction,
} from './actions'
import { initialShiftNotesState, ShiftNotesState } from './state'

export const shiftNotesReducer = (
    state = initialShiftNotesState,
    action: ShiftNotesAction,
): ShiftNotesState => {
    switch (action.type) {
        case SHIFT_NOTES_LOAD:
        case SHIFT_NOTES_RELOAD:
            return {
                ...state,
                isLoading: true,
            }

        case SHIFT_NOTES_RELOAD_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case SHIFT_NOTES_LOAD_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isLoading: false,
            }
        case OPEN_SHIFT_NOTES_MODAL:
            return {
                ...state,
                isModalOpen: true,
            }
        case CLOSE_SHIFT_NOTES_MODAL:
            return {
                ...state,
                isModalOpen: false,
            }

        default:
            return state
    }
}
