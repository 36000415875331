import { IdAware } from 'aos-ui/src/components/base/IdAware'
import { ModalFooter } from 'aos-ui/src/components/modal/Modal/ModalFooter'
import { ModalContainer } from 'aos-ui/src/components/modal/ModalContainer'
import { ModalKind } from 'aos-ui/src/components/modal/ModalKind'
import { ModalWrapper, ModalWrapperProps } from 'aos-ui/src/components/modal/ModalWrapper'
import React, { PropsWithChildren, PureComponent } from 'react'

import { cxp } from '../../base/ClassNames'
import { WhiteModalHeader } from './WhiteModalHeader'

export class WhiteModal extends PureComponent<PropsWithChildren<WhiteModalProps>> {
    public render() {
        return (
            <ModalWrapper
                disableFullScreen={this.props.disableFullScreen}
                isOpen={this.props.isOpen}
                closeAction={this.props.closeAction}
                className='rc-modal--overflow-visible'
                shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
            >
                <ModalContainer
                    id={this.props.id}
                    className={cxp(this.props)}
                    size={this.props.size || ModalKind.White}
                >
                    <WhiteModalHeader {...this.props} />
                    {this.props.children}
                    {this.props.footer && (
                        <ModalFooter topSeparator={this.props.footerSeparator}>
                            {this.props.footer}
                        </ModalFooter>
                    )}
                </ModalContainer>
            </ModalWrapper>
        )
    }
}

export interface WhiteModalProps extends IdAware, ModalWrapperProps {
    title: string
    footer?: JSX.Element[] | JSX.Element
    footerSeparator?: boolean
    size?: ModalKind
}
