import { Task } from '../task/Task'
import { TaskCategory } from '../TaskCategory'
import { TaskType } from '../TaskType'
import {
    AnimalsTaskFormPayload,
    animalsTaskFormPayloadFromTask,
    AnimalsTaskPayloadValidation,
    initialAnimalsTaskPayload,
    validateAnimalsTask,
} from './AnimalsTaskFormPayload'
import { baseTaskFormPayloadFromTask } from './BaseTaskFormPayload'
import {
    DeIcingTaskFormPayload,
    deIcingTaskFormPayloadFromTask,
    DeIcingTaskPayloadValidation,
    initialDeIcingTaskPayload,
    validateDeIcingTask,
} from './DeIcingTaskFormPayload'
import {
    initialInspectionTaskPayload,
    InspectionTaskFormPayload,
    inspectionTaskFormPayloadFromTask,
    InspectionTaskPayloadValidation,
    validateInspectionTask,
} from './InspectionTaskFormPayload'
import {
    initialMaintenanceTaskPayload,
    MaintenanceTaskFormPayload,
    maintenanceTaskFormPayloadFromTask,
    MaintenanceTaskPayloadValidation,
    validateMaintenanceTask,
} from './MaintenanceTaskFormPayload'
import {
    initialRescueServiceTaskPayload,
    RescueServiceTaskFormPayload,
    rescueServiceTaskFormPayloadFromTask,
    RescueServiceTaskPayloadValidation,
    validateRescueServiceTask,
} from './RescueServiceTaskFormPayload'
import {
    initialSimpleTaskPayload,
    SimpleTaskFormPayload,
    simpleTaskFormPayloadFromTask,
    SimpleTaskPayloadValidation,
    validateSimpleTask,
} from './SimpleTaskFormPayload'

export type TaskActionPayload = { payload: TaskFormPayload; createAnother: boolean }

export type TaskFormPayload =
    | SimpleTaskFormPayload
    | AnimalsTaskFormPayload
    | DeIcingTaskFormPayload
    | MaintenanceTaskFormPayload
    | InspectionTaskFormPayload
    | RescueServiceTaskFormPayload

export type TaskPayloadValidation =
    | SimpleTaskPayloadValidation
    | AnimalsTaskPayloadValidation
    | DeIcingTaskPayloadValidation
    | MaintenanceTaskPayloadValidation
    | InspectionTaskPayloadValidation
    | RescueServiceTaskPayloadValidation

export const validateTaskPayload = (v: TaskFormPayload): TaskPayloadValidation => {
    switch (v.type) {
        case TaskType.Simple:
            return validateSimpleTask(v)
        case TaskType.AnimalPrevention:
            return validateAnimalsTask(v)
        case TaskType.DeIcing:
            return validateDeIcingTask(v)
        case TaskType.Maintenance:
            return validateMaintenanceTask(v)
        case TaskType.RescueService:
            return validateRescueServiceTask(v)
        case TaskType.Inspection:
            return validateInspectionTask(v)
    }
}

export const taskPayloadFromTask = (v: Task, isClone = false): TaskFormPayload => {
    switch (v.type) {
        case TaskType.Simple:
            return simpleTaskFormPayloadFromTask(v)
        case TaskType.AnimalPrevention:
            return animalsTaskFormPayloadFromTask(v)
        case TaskType.DeIcing:
            return deIcingTaskFormPayloadFromTask(v)
        case TaskType.Maintenance:
            return maintenanceTaskFormPayloadFromTask(v, isClone)
        case TaskType.RescueService:
            return rescueServiceTaskFormPayloadFromTask(v)
        case TaskType.Inspection:
            return inspectionTaskFormPayloadFromTask(v)
        default:
            return simpleTaskFormPayloadFromTask(v)
    }
}

const initialPayloadForCategory: Record<TaskCategory, TaskFormPayload> = {
    [TaskCategory.Animals]: initialAnimalsTaskPayload,
    [TaskCategory.Maintenance]: initialMaintenanceTaskPayload,
    [TaskCategory.DeIcing]: initialDeIcingTaskPayload,
    [TaskCategory.RescueService]: initialRescueServiceTaskPayload,
    [TaskCategory.Inspection]: initialInspectionTaskPayload,
    [TaskCategory.LVP]: initialSimpleTaskPayload,
    [TaskCategory.HBA]: initialSimpleTaskPayload,
}

export const migrateTaskPayload = (from: TaskFormPayload, to: TaskFormPayload): TaskFormPayload => {
    if (from.category === to.category) {
        return to
    }
    const initial = to.category ? initialPayloadForCategory[to.category] : initialSimpleTaskPayload
    const base = baseTaskFormPayloadFromTask(to)

    return {
        ...initial,
        ...base,
    }
}
