import { Rectangle } from 'aos-helpers/src/helpers/Grid'
import {
    translateEnum,
    translateEnumUnion,
} from 'aos-helpers/src/helpers/translations/Translations'
import { Breakpoint, BreakpointValues } from 'aos-ui/src/components/grid/ResponsiveGrid'

import { DashboardItemsWeb } from '../../../views/statusDashboard/StatusDashboardItemConfig'
import { DashboardItemState } from './DashboardItemState'
import { BaseDashboardItemState } from './itemStates/base/BaseDashboardItemState'

export const selectBreakpoint = (breakpoint: Breakpoint, values: BreakpointValues) =>
    values[breakpoint]

export enum DashboardProcessType {
    Weather = 'weather',
    Passengers = 'passengers',
    Baggage = 'baggage',
    Operations = 'operations',
    Events = 'events',
    FireFighters = 'FireFighters',
    ShiftNotes = 'ShiftNotes',
    Tasks = 'Tasks',
}

export enum MyDashboardTab {
    MyDashboard = 'my-dashboard',
}

export enum StatusDashboardOverviewTab {
    Overview = 'overview',
}

export type StatusDashboardPresetTab = DashboardProcessType | MyDashboardTab
export type StatusDashboardTab = StatusDashboardOverviewTab | StatusDashboardPresetTab

export const allStatusDashboardTabs = [
    StatusDashboardOverviewTab.Overview,
    DashboardProcessType.Events,
    DashboardProcessType.Operations,
    DashboardProcessType.Passengers,
    DashboardProcessType.Weather,
    DashboardProcessType.Baggage,
]

export const isPresetTab = (tab: StatusDashboardTab): tab is StatusDashboardPresetTab =>
    tab !== StatusDashboardOverviewTab.Overview

export const translateTab = translateEnumUnion(
    'dashboard.tab',
    StatusDashboardOverviewTab,
    DashboardProcessType,
    MyDashboardTab,
)

export const translatePresetType = (prefix: string) =>
    translateEnum<DashboardProcessType>(DashboardProcessType, prefix)

export interface RegularDashboardPresetWithTab {
    tab: StatusDashboardTab
    items: DashboardItem<DashboardItemState>[]
}

export type DashboardPresets = Record<StatusDashboardPresetTab, DashboardItem<DashboardItemState>[]>

export const getDashboardItemKey = (k: DashboardItem) => k.id.toString()

export interface DashboardItem<
    T extends BaseDashboardItemState<DashboardItemsWeb> = BaseDashboardItemState<DashboardItemsWeb>,
> {
    id: number
    layouts: Record<Breakpoint, Rectangle>
    state: T
}
