import {
    calculateChartDomainForRange,
    calculateDomain,
    Domain,
} from 'aos-helpers/src/helpers/domain/Domain'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { isTimeWithin, TimeRange, timeRangeFromNow } from 'aos-helpers/src/helpers/TimeRange'
import { getTrend, Trend } from 'aos-helpers/src/helpers/trend/Trend'
import { flatten, fromPairs, values } from 'lodash'
import { createSelector } from 'reselect'

import { TimeValuePoint } from '../../../services/airportStatus/base/types/TimePoint'
import {
    allBorderControlPoints,
    borderControlPointConfig,
} from '../../../services/queueingTime/types/BorderControlPoint'
import { ControlPointConfig } from '../../../services/queueingTime/types/ControlPointConfig'
import { QueueingData } from '../../../services/queueingTime/types/QueueingData'
import { QueueingTimePoint } from '../../../services/queueingTime/types/QueueingTimePoint'
import {
    allSecurityControlPoints,
    securityControlPointConfig,
} from '../../../services/queueingTime/types/SecurityControlPoint'
import {
    QueueingDataTypeFilter,
    QueueingTimeFilters,
} from '../../../services/statusDashboard/types/filters/QueueingTimeFilters'
import { currentTimeSelector } from '../../common/selectors'
import { ItemStateAware } from '../flights/selectors/common'
import { StatusDashboardDataStateAware } from '../state'

export interface QueueingTimeSelectorState<T extends QueueingTimePoint> {
    filters: QueueingTimeFilters
    series: Record<T, TimeValuePoint[]>
    yDomain: Domain<number>
    xDomain: Domain<DateTime>
    noDataWarning: boolean
    currentTime: DateTime
    stats: Record<T, QueueingTimeStat>
    config: Record<T, ControlPointConfig>
    type: QueueingTimeType
}

export enum QueueingTimeType {
    QueueingTimeBorder,
    QueueingTimeSecurity,
}

export interface QueueingTimeStat {
    forecast?: number
    trend: Trend
    value: number
}

export const allPointsSelector = (state: StatusDashboardDataStateAware) =>
    state.statusDashboardData.queueingTime

const queueingTimeFiltersOwnPropsSelector = createSelector(
    (_: any, ownProps: ItemStateAware<QueueingTimeFilters>) =>
        ownProps.itemState.queueingTimeDataType,
    queueingTimeDataType => ({ queueingTimeDataType }),
)

const getCurrentStats = (forecast: number | undefined, value: number, prev: number) => ({
    value,
    trend: getTrend(prev, value),
    forecast,
})

export const queueingTimeMerger = <T extends QueueingTimePoint>(
    points: T[],
    config: Record<T, ControlPointConfig>,
    data: QueueingData,
    filters: QueueingTimeFilters,
    now: DateTime,
    type: QueueingTimeType,
): QueueingTimeSelectorState<T> => {
    const seriesForWaitTimePoint = (p: T, r: TimeRange) => {
        return [
            ...data[p].waitTimeHistory.filter(i => isTimeWithin(i.time, r)),
            { time: now.clone(), value: data[p].waitTime },
            { time: now.clone().add(1, 'hour'), value: data[p].waitTimeForecast },
        ]
    }
    const seriesForLengthPoint = (p: T, r: TimeRange) => {
        return [
            ...data[p].lengthHistory.filter(i => isTimeWithin(i.time, r)),
            { time: now.clone(), value: data[p].length },
        ]
    }
    const statRecord = (mapper: f.Func1<T, QueueingTimeStat>) =>
        fromPairs(points.map(p => [p, mapper(p)])) as Record<T, QueueingTimeStat>
    const lastValue = (arrayData: TimeValuePoint[]) => {
        if (arrayData.length) {
            return arrayData[arrayData.length - 1].value
        }
        return 0
    }

    const range = timeRangeFromNow(now, -3, 3)
    const series =
        filters.queueingTimeDataType === QueueingDataTypeFilter.WaitTime
            ? (fromPairs(points.map(p => [p, seriesForWaitTimePoint(p, range)])) as Record<
                  T,
                  TimeValuePoint[]
              >)
            : (fromPairs(points.map(p => [p, seriesForLengthPoint(p, range)])) as Record<
                  T,
                  TimeValuePoint[]
              >)

    const allPoints: TimeValuePoint[] = flatten(values(series))
    const yDomain = calculateDomain([...allPoints], f => f.value, 5)
    const xDomain = calculateChartDomainForRange(range)
    const noDataWarning = !allPoints.length
    const stats =
        filters.queueingTimeDataType === QueueingDataTypeFilter.WaitTime
            ? statRecord(p =>
                  getCurrentStats(
                      data[p].waitTimeForecast,
                      data[p].waitTime ?? 0,
                      lastValue(data[p].waitTimeHistory),
                  ),
              )
            : statRecord(p =>
                  getCurrentStats(0, data[p].length ?? 0, lastValue(data[p].lengthHistory)),
              )
    return {
        config,
        series,
        yDomain,
        xDomain,
        filters,
        noDataWarning,
        currentTime: now,
        stats,
        type,
    }
}

export const queueingTimeBorderSelector = createSelector(
    () => allBorderControlPoints,
    () => borderControlPointConfig,
    allPointsSelector,
    queueingTimeFiltersOwnPropsSelector,
    currentTimeSelector,
    () => QueueingTimeType.QueueingTimeBorder,
    (points, config, data, filters, now, type) =>
        queueingTimeMerger(points, config, data, filters, now, type),
)

export const queueingTimeSecuritySelector = createSelector(
    () => allSecurityControlPoints,
    () => securityControlPointConfig,
    allPointsSelector,
    queueingTimeFiltersOwnPropsSelector,
    currentTimeSelector,
    () => QueueingTimeType.QueueingTimeSecurity,
    (points, config, data, filters, now, type) =>
        queueingTimeMerger(points, config, data, filters, now, type),
)

export const queueingTimeBorderTileSelector = createSelector(
    () => allBorderControlPoints,
    () => borderControlPointConfig,
    allPointsSelector,
    () => ({ queueingTimeDataType: QueueingDataTypeFilter.WaitTime }),
    currentTimeSelector,
    () => QueueingTimeType.QueueingTimeBorder,
    (points, config, data, filters, now, type) =>
        queueingTimeMerger(points, config, data, filters, now, type),
)

export const queueingTimeSecurityTileSelector = createSelector(
    () => allSecurityControlPoints,
    () => securityControlPointConfig,
    allPointsSelector,
    () => ({ queueingTimeDataType: QueueingDataTypeFilter.WaitTime }),
    currentTimeSelector,
    () => QueueingTimeType.QueueingTimeSecurity,
    (points, config, data, filters, now, type) =>
        queueingTimeMerger(points, config, data, filters, now, type),
)
