import { currentUserSiteLocation } from 'aos-services/src/core/auth/state'
import { taskChecklistTemplateService } from 'aos-services/src/services/checklists/taskChecklistTemplateService'
import { AosTaskChecklistTemplate } from 'aos-services/src/services/checklists/types/AosTaskChecklistTemplate'
import { AosLocation } from 'aos-services/src/services/common/types/AosLocation'
import { AosAirport } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { taskIcon } from 'aos-ui-task/src/components/task/TaskIcon'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { setExportingChecklistTemplatesAction } from '../event/actions'
import {
    CREATE_TASK_CHECKLIST_TEMPLATE,
    CreateTaskChecklistTemplateAction,
    DELETE_TASK_CHECKLIST_TEMPLATE,
    DeleteTaskChecklistTemplateAction,
    EXPORT_ALL_TASK_CHECKLIST_TEMPLATES,
    EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE,
    ExportAllTaskChecklistTemplatesAction,
    ExportSingleTaskChecklistTemplateAction,
    LOAD_TASK_CHECKLIST_TEMPLATE,
    LOAD_TASK_CHECKLIST_TEMPLATES,
    LoadTaskChecklistTemplateAction,
    loadTaskChecklistTemplateAction,
    LoadTaskChecklistTemplatesAction,
    loadTaskChecklistTemplatesAction,
    loadTaskChecklistTemplatesSuccessAction,
    loadTaskChecklistTemplateSuccessAction,
    SELECT_TASK_CHECKLIST_TEMPLATE,
    SelectTaskChecklistTemplateAction,
    UPDATE_TASK_CHECKLIST_TEMPLATE,
    UpdateTaskChecklistTemplateAction,
} from './actions'

function* loadTaskChecklistTemplatesSaga() {
    yield takeEvery<LoadTaskChecklistTemplatesAction>(LOAD_TASK_CHECKLIST_TEMPLATES, function* () {
        const currentLocation: AosLocation = yield select(currentUserSiteLocation)
        const result: AosTaskChecklistTemplate[] = yield call(
            taskChecklistTemplateService.getAll,
            currentLocation,
        )
        yield put(
            loadTaskChecklistTemplatesSuccessAction(
                result.map(template => ({ ...template, icon: taskIcon[template.taskCategory] })),
            ),
        )
    })
}

function* selectTaskChecklistTemplateSaga() {
    function* selectTaskChecklistTemplate(action: SelectTaskChecklistTemplateAction) {
        const unitId = action.payload.id
        yield put(loadTaskChecklistTemplateAction(unitId))
    }

    yield takeLatest<SelectTaskChecklistTemplateAction>(
        SELECT_TASK_CHECKLIST_TEMPLATE,
        selectTaskChecklistTemplate,
    )
}

function* loadChecklistSaga() {
    function* loadChecklist(action: LoadTaskChecklistTemplateAction) {
        const result: AosTaskChecklistTemplate = yield call(
            taskChecklistTemplateService.getOne,
            action.payload,
        )
        yield put(loadTaskChecklistTemplateSuccessAction(result))
    }

    yield takeLatest<LoadTaskChecklistTemplateAction>(LOAD_TASK_CHECKLIST_TEMPLATE, loadChecklist)
}

function* createTaskChecklistTemplateSaga() {
    yield takeEvery<CreateTaskChecklistTemplateAction>(
        CREATE_TASK_CHECKLIST_TEMPLATE,
        function* (action) {
            yield call(taskChecklistTemplateService.create, action.payload)
            yield put(loadTaskChecklistTemplatesAction())
        },
    )
}

function* deleteTaskChecklistTemplateSaga() {
    yield takeEvery<DeleteTaskChecklistTemplateAction>(
        DELETE_TASK_CHECKLIST_TEMPLATE,
        function* (action) {
            yield call(taskChecklistTemplateService.delete, action.payload)
            yield put(loadTaskChecklistTemplatesAction())
        },
    )
}

function* updateTaskChecklistTemplateSaga() {
    yield takeEvery<UpdateTaskChecklistTemplateAction>(
        UPDATE_TASK_CHECKLIST_TEMPLATE,
        function* (action) {
            yield call(taskChecklistTemplateService.update, action.payload)
            yield put(loadTaskChecklistTemplatesAction())
        },
    )
}

function* exportTaskChecklistTemplateSaga() {
    yield takeEvery<ExportAllTaskChecklistTemplatesAction>(
        EXPORT_ALL_TASK_CHECKLIST_TEMPLATES,
        function* () {
            const location: AosAirport = yield select(currentUserSiteLocation)
            yield put(setExportingChecklistTemplatesAction(true))
            yield call(taskChecklistTemplateService.exportAll, location)
            yield put(setExportingChecklistTemplatesAction(false))
        },
    )
}

function* exportSingleTaskChecklistTemplateSaga() {
    yield takeEvery<ExportSingleTaskChecklistTemplateAction>(
        EXPORT_SINGLE_TASK_CHECKLIST_TEMPLATE,
        function* (action) {
            yield put(setExportingChecklistTemplatesAction(true))
            yield call(taskChecklistTemplateService.exportSingle, action.payload)
            yield put(setExportingChecklistTemplatesAction(true))
        },
    )
}

export const taskChecklistSagas = [
    loadTaskChecklistTemplatesSaga,
    selectTaskChecklistTemplateSaga,
    loadChecklistSaga,
    createTaskChecklistTemplateSaga,
    deleteTaskChecklistTemplateSaga,
    updateTaskChecklistTemplateSaga,
    exportTaskChecklistTemplateSaga,
    exportSingleTaskChecklistTemplateSaga,
]
