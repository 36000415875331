import { Restriction } from '../../services/restrictions/types/Restriction'

export interface RestrictionsState {
    list: Restriction[]
    isLoading: boolean
}

export interface RestrictionsStateAware {
    restrictions: RestrictionsState
}

export const initialRestrictionsState: RestrictionsState = {
    list: [],
    isLoading: true,
}
