import { Chart } from 'aos-components/src/components/chart/Chart'
import { ManyBarsSeries } from 'aos-components/src/components/chart/series/ManyBarsSeries'
import { formatNumber } from 'aos-helpers/src/helpers/Number'
import { ChartChildrenProps } from 'aos-ui-common/src/components/chart/ChartProps'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'

import {
    ChartMultiPoint,
    ChartMultiSelectorState,
} from '../../../../core/tasks/reports/selectors/types/ChartSelectorState'
import { ReportsUnit } from '../../../../core/tasks/reports/state'
import { YearChartLegend } from './YearChartLegend'

interface ReportMultiChartProps {
    chartData: ChartMultiSelectorState
    unit?: ReportsUnit
    getColor(index: number): Color
    rotateBottomLabels?: boolean
}

export const ReportMultiChart: FC<ReportMultiChartProps> = ({
    chartData,
    unit,
    rotateBottomLabels,
    getColor,
}) => {
    return (
        <Chart<string>
            xBandConfig={{
                domain: { ticks: chartData.dataKeys },
                tickFormat: value => value,
                bandPadding: { x0: { inner: 0.25, outer: 0 } },
                showGrids: false,
                barCount: chartData.dataSubKeys.length,
            }}
            y1Config={{
                domain: chartData.yDomain,
                tickFormat: formatNumber,
                unit,
            }}
            bottomLegend={{
                height: 30,
                component: (
                    <YearChartLegend yearRange={chartData.dataSubKeys} getColor={getColor} />
                ),
            }}
            bottomLabelsRotation={rotateBottomLabels ? 10 : 0}
        >
            {({ xBandScale, y1Scale }: ChartChildrenProps<string>) => {
                if (!xBandScale || !y1Scale) {
                    return null
                }

                return (
                    <ManyBarsSeries
                        data={chartData.data}
                        series={chartData.dataSubKeys.map((key, index) => ({
                            fill: getColor(index),
                            yAccessor: (point: ChartMultiPoint) => point.data[key],
                            tooltip: (point: ChartMultiPoint) => formatNumber(point.data[key]),
                        }))}
                        scales={{
                            x0Scale: xBandScale.x0,
                            x1Scale: xBandScale.x1,
                            yScale: y1Scale,
                        }}
                        xAccessor={(point: ChartMultiPoint) => point.key}
                    />
                )
            }}
        </Chart>
    )
}
