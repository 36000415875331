import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    recurrenceCustomOns,
    RecurrenceCustomOnValue,
    recurrenceCustomOnValues,
} from 'aos-services/src/services/tasks/types/payload/RecurrencePayloadEnums'
import { TaskRecurrenceMonthlyValidation } from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceMonthlyFormPayload'
import { TaskRecurrenceYearlyValidation } from 'aos-services/src/services/tasks/types/payload/TaskRecurrenceYearlyFormPayload'
import { TaskRecurrenceType } from 'aos-services/src/services/tasks/types/task/TaskRecurrenceType'
import { Box } from 'aos-ui/src/components/base/Box'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { Dropdown } from 'aos-ui/src/components/form/dropdown/Dropdown'
import { Input } from 'aos-ui/src/components/form/input/Input'
import { LabeledRowFormElement } from 'aos-ui/src/components/form/labeled/LabeledRowFormElement'
import React, { FC } from 'react'

import { TaskRecurrenceFormProps } from './TaskRecurrenceFormProps'

export const RecurrenceCustomOnComponent: FC<RecurrenceCustomOnComponentProps> = ({
    value,
    onChange,
    errors,
    canEdit,
}) => {
    if (value?.type !== TaskRecurrenceType.Monthly && value?.type !== TaskRecurrenceType.Yearly) {
        return null
    }
    const changeCustomOnValue = (customOnValue: RecurrenceCustomOnValue[]) => {
        if (
            value.customOnValue.includes(RecurrenceCustomOnValue.DAY) ||
            !customOnValue.includes(RecurrenceCustomOnValue.DAY)
        ) {
            customOnValue = customOnValue.filter(i => !i.includes(RecurrenceCustomOnValue.DAY))
        } else {
            customOnValue = customOnValue.filter(i => i.includes(RecurrenceCustomOnValue.DAY))
        }
        onChange({ customOnValue })
    }

    const partialCustomOnValue = (customOnValue: RecurrenceCustomOnValue[]) =>
        customOnValue.includes(RecurrenceCustomOnValue.DAY)
            ? translate('tasks.day')
            : translate('tasks.selected') + `: ${customOnValue.length}`

    const allContentMessage = () =>
        value.customOnValue ? translate('tasks.selected') + `: ${value.customOnValue.length}` : ''

    return (
        <LabeledRowFormElement
            prefixLabel={translate('tasks.on')}
            marginVertical={8}
            marginLeft={16}
            isError={errors?.customOnValue}
            errorMessage={translate('tasks.custom-on.error')}
            fullWidth
        >
            <Box marginLeft={8} row fullWidth justify='space-between'>
                {canEdit ? (
                    <Dropdown
                        value={value.customOn}
                        items={recurrenceCustomOns}
                        valueRenderer={value => translate('tasks.recurrence-custom-on.' + value)}
                        onChange={customOn => onChange({ customOn })}
                        outlined
                        width={78}
                    />
                ) : (
                    <Input
                        type='text'
                        value={translate('tasks.recurrence-custom-on.' + value.customOn)}
                        disabled
                    />
                )}
                <Box>
                    <CheckboxDropdown
                        items={recurrenceCustomOnValues}
                        value={value.customOnValue ? value.customOnValue : []}
                        allContent={allContentMessage()}
                        partialContent={(i: RecurrenceCustomOnValue[]) => partialCustomOnValue(i)}
                        onChange={(customOnValue: RecurrenceCustomOnValue[]) =>
                            changeCustomOnValue(customOnValue)
                        }
                        valueRenderer={i => translate('tasks.recurrence-custom-on-value.' + i)}
                        width={110}
                        outlined
                        splitterPosition={7}
                        disabled={!canEdit}
                    />
                </Box>
            </Box>
        </LabeledRowFormElement>
    )
}

export interface RecurrenceCustomOnComponentProps extends TaskRecurrenceFormProps {
    errors?: TaskRecurrenceMonthlyValidation | TaskRecurrenceYearlyValidation
}
