import { AuditableDto } from '../../common/types/AuditableDto'
import { AosCommonEventDto } from './AosCommonEventDto'

export interface AosFeedInDto extends AosFeedInputDto, AuditableDto {}

export interface AosFeedInputDto extends AosCommonEventDto {
    type?: 'SIMPLE' | 'EXTENDED'
    status?: 'CURRENT' | 'DISMISSED' | 'ACTIVATED'
}

export function isNotActivatedFeedIn(e: AosFeedInDto): boolean {
    return e.status !== 'ACTIVATED'
}
