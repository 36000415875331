import { DateTime } from 'aos-helpers/src/helpers/Time'

export interface Feed {
    id: string
    title: string
    pubDate: DateTime
    link: string
    description: string
    comments: string
}

export const byPubDateComparator = (a: Feed, b: Feed) =>
    a.pubDate.valueOf() > b.pubDate.valueOf() ? -1 : 1
