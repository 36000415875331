import { cx } from 'aos-components/src/components/base/ClassNames'
import { FormValidation } from 'aos-helpers/src/helpers/FormValidation'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { ContactFormData } from 'aos-services/src/services/users/input/ContactFormData'
import { AosUserGroup } from 'aos-services/src/services/users/types/AosUserGroup'
import { Box } from 'aos-ui/src/components/base/Box'
import { FormFieldContainer } from 'aos-ui/src/components/container/FormFieldContainer'
import { InnerFormContainer } from 'aos-ui/src/components/container/InnerFormContainer'
import { LabeledFormElement } from 'aos-ui/src/components/form/labeled/LabeledFormElement'
import { LabeledInput } from 'aos-ui/src/components/form/labeled/LabeledInput'
import { FormSidebar } from 'aos-ui/src/components/form/ui/FormSidebar'
import React, { PureComponent } from 'react'

import { CommonContactFormValidationState } from '../../../../core/groupManager/manageUser/state'
import { GroupsCheckList } from '../GroupsCheckList'

export class ContactForm extends PureComponent<ContactFormProps> {
    public render() {
        const { form, user } = this.props
        const changeUsername = (name: string) => this.changeValue({ name })
        const changeCompany = (company: string) => this.changeValue({ company })
        const changeRole = (role: string) => this.changeValue({ role })
        const changeEmail = (email: string) => this.changeValue({ email })
        const changePhone = (phone: string) => this.changeValue({ phone })

        return (
            <Box coverAll row>
                <FormFieldContainer
                    className={cx(
                        'full-height flex--1 scrollable padding-left--x-large',
                        'padding-vertical--large user-modal__l-container',
                    )}
                >
                    <LabeledInput
                        keyPrefix='manage-user.full-name'
                        onChangeText={changeUsername}
                        value={user.name}
                        type='text'
                        isRequired
                        isError={form.error.name}
                        seleniumLocation='username'
                    />
                    <LabeledFormElement
                        label={translate('manage-user.contact-information')}
                        labelComment={` (${translate('manage-user.at-least-one-required')})`}
                        isRequired
                        isError={form.error.contactInformation}
                        errorMessage={translate('manage-user.contact-information.error')}
                    >
                        <InnerFormContainer paddingHorizontal={20} paddingVertical={8}>
                            <LabeledInput
                                keyPrefix='manage-user.email'
                                onChangeText={changeEmail}
                                value={user.email}
                                type='email'
                                isError={form.error.email}
                                seleniumLocation='email'
                            />
                            <LabeledInput
                                keyPrefix='manage-user.phone'
                                onChangeText={changePhone}
                                value={user.phone}
                                type='text'
                                isError={form.error.phone}
                                seleniumLocation='phone'
                            />
                        </InnerFormContainer>
                    </LabeledFormElement>
                    <LabeledInput
                        keyPrefix='manage-user.company'
                        onChangeText={changeCompany}
                        value={user.company}
                        type='text'
                        seleniumLocation='company'
                    />
                    <LabeledInput
                        keyPrefix='manage-user.role'
                        onChangeText={changeRole}
                        value={user.role}
                        type='text'
                        seleniumLocation='role'
                    />
                </FormFieldContainer>
                <FormSidebar>{this.renderGroupsCheckList()}</FormSidebar>
            </Box>
        )
    }

    private renderGroupsCheckList() {
        const changeGroups = (groups: number[]) => this.changeValue({ selectedGroupsIds: groups })

        return (
            <GroupsCheckList
                groups={this.props.groups}
                selectedGroupsIds={this.props.user.selectedGroupsIds}
                changeGroups={changeGroups}
                selectAllGroups={this.props.selectAllGroups}
                deselectAllGroups={this.props.deselectAllGroups}
            />
        )
    }

    private changeValue = (v: Partial<ContactFormData>) => {
        this.props.changeForm(v)
    }
}

export interface ContactFormProps {
    user: ContactFormData
    form: FormValidation<CommonContactFormValidationState>
    groups: AosUserGroup[]
    changeForm(v: Partial<ContactFormData>): void
    selectAllGroups(): void
    deselectAllGroups(): void
}
