import { timelineReducer } from 'aos-services/src/core/timeline/reducer'
import { xor } from 'lodash'

import {
    EVENT_TIMELINE_TIMELINE_PARENT,
    EventTimelineAction,
    LOAD_EVENTS_SUCCESS,
    SET_FILTER,
    TOGGLE_GROUP,
} from './actions'
import { EventTimelineState, initialEventTimelineState } from './state'

export function eventTimelineReducer(
    state = initialEventTimelineState,
    action: EventTimelineAction,
): EventTimelineState {
    switch (action.type) {
        case LOAD_EVENTS_SUCCESS:
            return { ...state, events: action.payload[0], loadedRange: action.payload[1] }

        case TOGGLE_GROUP:
            return {
                ...state,
                collapsedGroups: xor(state.collapsedGroups, [action.payload]),
            }

        case SET_FILTER:
            return {
                ...state,
                filter: Object.assign({}, state.filter, action.payload),
            }

        case EVENT_TIMELINE_TIMELINE_PARENT:
            return {
                ...state,
                timeline: timelineReducer(state.timeline, action.payload),
            }
        default:
            return state
    }
}
