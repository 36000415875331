import { pie } from 'd3-shape'
import { chain, flatten } from 'lodash'

import { createArc } from './arc/createArc'
import { createLabels } from './labels/createLabels'
import { createNeedle } from './needle/createNeedle'
import { GaugeArc } from './types/GaugeArc'
import { GaugeLabel } from './types/GaugeLabel'
import { GaugeNeedle } from './types/GaugeNeedle'
import { GaugeOptions } from './types/GaugeOptions'
import { GaugeRange } from './types/GaugeRange'

export interface GenerateGaugeReturn {
    arcs: GaugeArc[]
    labels: GaugeLabel[]
    needle: GaugeNeedle | null
}

export const DEFAULT_ANGLE = 130

export const generateGauge = (options: GaugeOptions): GenerateGaugeReturn => {
    const { ranges, angle = DEFAULT_ANGLE } = options

    const startAngle = -angle * (Math.PI / 180)
    const endAngle = angle * (Math.PI / 180)

    const pieGenerator = pie<GaugeRange>()
        .value(({ from, to }) => Math.abs(from > to ? from - to : to - from) + 1)
        .startAngle(startAngle)
        .endAngle(endAngle)
        .sort(null)

    const pieSeriesData = pieGenerator(ranges)

    const arcs = flatten(pieSeriesData.map(datum => createArc(datum, options)))

    const labels = chain(pieSeriesData)
        .map(datum => createLabels(datum, options))
        .flatten()
        .value()

    const needle = createNeedle({ ...options, angle })

    return { arcs, labels, needle }
}
