import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Icon, IconVariant } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import Rotate from 'ol/control/Rotate'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { BaseComponent } from '../../base/BaseComponent'

export class RotateControl extends BaseComponent<{}, {}> {
    private control: Rotate
    private controlNode: HTMLElement

    constructor(props: {}) {
        super(props)
        this.controlNode = document.createElement('div')
        this.control = new Rotate({
            label: this.controlNode,
        })
    }

    public componentDidMount() {
        const rootControlNode = createRoot(this.controlNode)
        rootControlNode.render(
            <Icon
                svg={SvgIcon.Compass}
                iconVariant={IconVariant.Black}
                iconSize={BlockSize.Large}
            />,
        )

        this.context.map.addControl(this.control)
    }
}
