import { isPresent } from 'aos-helpers/src/helpers/Function'
import { DateTime } from 'aos-helpers/src/helpers/Time'
import { Box } from 'aos-ui/src/components/base/Box'
import React, {
    Children,
    FCWithChildren,
    Key,
    PropsWithChildren,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

interface CarouselProps {
    changeTrigger: DateTime // any change to this prop triggers change of displayed item
}

export const Carousel: FCWithChildren<PropsWithChildren<CarouselProps>> = props => {
    const children = Children.toArray(props.children).filter(isPresent)

    if (!childrenHaveKey(children)) {
        throw Error('Every child of Carousel must have key prop specified')
    }

    const index = useCarouselState(props.changeTrigger, children)

    return (
        <TransitionGroupWrapper>
            {index !== -1 && (
                <CSSTransition timeout={400} key={props.changeTrigger.unix()} classNames='carousel'>
                    <Box>{children[index]}</Box>
                </CSSTransition>
            )}
        </TransitionGroupWrapper>
    )
}

const childrenHaveKey = (children: ReactNode[]): children is ReactElement[] =>
    children.every(
        child => child && typeof child === 'object' && 'key' in child && child.key !== undefined,
    )

const childrenKeysString = (children: React.ReactElement[]) => children.map(c => c.key).join(',')

const useCarouselState = (changeTrigger: DateTime, children: ReactElement[]) => {
    const [lastKey, setLastKey] = useState<Key | null>(null)
    const [lastIndex, setLastIndex] = useState(-1)

    const keyIndex = children.findIndex(child => child.key === lastKey)

    const updateIndex = (index: number) => {
        let newIndex = index
        if (index === -1 && children.length > 0) {
            newIndex = 0
        }
        newIndex = children.length > 0 ? newIndex % children.length : -1

        const key = newIndex !== -1 ? children[newIndex].key : null

        if (lastIndex !== newIndex || lastKey !== key) {
            setLastIndex(newIndex)
            setLastKey(key)
        }
    }

    useEffect(() => {
        updateIndex(lastIndex + 1)
    }, [changeTrigger])

    useEffect(() => {
        updateIndex(keyIndex === -1 ? lastIndex : keyIndex)
    }, [childrenKeysString(children)])

    return keyIndex
}

const TransitionGroupWrapper = styled(TransitionGroup)`
    position: relative;
    overflow: hidden;
    width: 100%;

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
    }
    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .carousel-enter {
        animation: slideIn 0.3s ease both;
    }

    .carousel-exit {
        position: absolute;
        top: 0;
        animation: slideOut 0.3s ease both;
    }
`
