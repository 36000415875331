import { DateTime } from 'aos-helpers/src/helpers/Time'
import { currentTimeSelector } from 'aos-services/src/core/common/selectors'
import { Towing } from 'aos-services/src/services/flightInformation/types/towing/Towing'
import {
    TowingStatus,
    towingStatusColor,
} from 'aos-services/src/services/flightInformation/types/towing/TowingStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { TypedCellRendererProps, TypedColumn } from 'aos-ui/src/components/table/TypedColumn'
import { LargeSpinner } from 'aos-ui/src/components/ui/LargeSpinner'
import { EnumValues } from 'enum-values'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { AutoSizer, Table } from 'react-virtualized'
import { createStructuredSelector } from 'reselect'

import { lockNowAction, updateTowingFiltersAction } from '../../core/flightInformation/actions'
import { isNowLockedSelector } from '../../core/flightInformation/flight/flightSelectors'
import { FlightInformationColumnValues } from '../../core/flightInformation/state'
import { TowingFilters } from '../../core/flightInformation/towing/towingFiltersState'
import {
    isLoadingTowingSelector,
    sortedAndFilteredTowingListSelector,
    towingFiltersSelector,
    towingListColumnValuesSelector,
    towingListNowIndexSelector,
} from '../../core/flightInformation/towing/towingSelectors'
import { HighlightedTimeCell } from './cells/HighlightedTimeCell'
import { RelativeTimeCell } from './cells/RelativeTimeCell'
import { StandCell } from './cells/StandCell'
import { TimeCellWithType } from './cells/TimeCellWithType'
import { TowingMultiCell } from './cells/TowingMultiCell'
import { TowingStatusCell, translateTowingStatus } from './cells/TowingStatusCell'
import { FlightTableWrapper } from './FlightTableWrapper'
import { StringFilter, TimeFilter } from './header/Filters'
import { TowingTableHeader } from './header/TowingTableHeader'
import { TableDropdown } from './partial/TableDropdown'
import { NOW_ROW_HEIGHT, ROW_HEIGHT, TableRow } from './partial/TableRow'

interface TowingTableProps extends TowingTableStateProps, TowingTableDispatchProps {}

class TowingTableClass extends PureComponent<TowingTableProps> {
    private ref = React.createRef<Table>()

    public render() {
        const { towingList, isLoading, nowLocked, nowIndex, now, filters } = this.props

        const darkerColumnProps = {
            headerClassName: 'flight-table-cell--darker',
            className: 'flight-table-cell--darker full-height',
        }

        return (
            <Box flex={1} fullHeight padding={16} paddingRight={8}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FlightTableWrapper
                            innerRef={this.ref}
                            width={width}
                            height={height}
                            headerHeight={filters.filtersVisible ? 120 : 80}
                            rowHeight={({ index }) =>
                                index === nowIndex ? NOW_ROW_HEIGHT : ROW_HEIGHT
                            }
                            scrollToIndex={nowLocked ? nowIndex : undefined}
                            scrollToAlignment='center'
                            rowCount={towingList.length}
                            rowGetter={({ index }) => towingList[index]}
                            rowClassName='flight-table-row text__l-grey text--center'
                            rowRenderer={row => (
                                <TableRow key={row.index} row={row} now={now} nowIndex={nowIndex} />
                            )}
                            headerClassName='full-height'
                            onUserScroll={this.handleUserScroll}
                            noRowsRenderer={() => (
                                <Box className='full-size' centered>
                                    {isLoading && <LargeSpinner />}
                                </Box>
                            )}
                        >
                            <TypedColumn<Towing>
                                width={250}
                                dataKey='acreg'
                                headerRenderer={this.renderOperationHeader}
                                cellRenderer={this.renderOperationCell}
                            />
                            <TypedColumn<Towing>
                                width={250}
                                dataKey='standFrom'
                                {...darkerColumnProps}
                                headerRenderer={this.renderStandHeader}
                                cellRenderer={this.renderStandCell}
                            />
                            <TypedColumn<Towing>
                                width={250}
                                dataKey='after'
                                headerRenderer={this.renderTimeframeHeader}
                                cellRenderer={this.renderTimeframeCell}
                            />
                            <TypedColumn<Towing, 'arrFltnr'>
                                width={450}
                                dataKey='arrFltnr'
                                {...darkerColumnProps}
                                headerRenderer={this.renderArrivalHeader}
                                cellRenderer={this.renderArrivalCell}
                            />
                            <TypedColumn<Towing, 'depFltnr'>
                                width={450}
                                dataKey='depFltnr'
                                headerRenderer={this.renderDepartureHeader}
                                cellRenderer={this.renderDepartureCell}
                            />
                            <TypedColumn<Towing>
                                width={450}
                                dataKey='status'
                                {...darkerColumnProps}
                                headerRenderer={this.renderStatusHeader}
                                cellRenderer={this.renderStatusCell}
                            />
                        </FlightTableWrapper>
                    )}
                </AutoSizer>
            </Box>
        )
    }

    public componentDidUpdate() {
        if (this.ref.current) {
            this.ref.current.recomputeRowHeights()
        }
    }

    /*
     * Operation
     */
    private renderOperationHeader = () => (
        <TowingTableHeader
            section='operation'
            captions={[
                {
                    name: 'acreg',
                    filter: (
                        <StringFilter
                            value={this.props.filters.acreg}
                            onChange={this.updateFilterHandler('acreg')}
                        />
                    ),
                },
                {
                    name: 'handl',
                    filter: (
                        <TableDropdown
                            items={this.props.columnValues.handl}
                            onChange={this.updateFilterHandler('handl')}
                            value={this.props.filters.handl}
                        />
                    ),
                },
            ]}
        />
    )
    private renderOperationCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <span>{rowData.acreg}</span>
            <span>{rowData.handl}</span>
        </TowingMultiCell>
    )

    /*
     * Stand
     */
    private renderStandHeader = () => (
        <TowingTableHeader
            section='stand'
            captions={[
                {
                    name: 'standFrom',
                    filter: (
                        <StringFilter
                            value={this.props.filters.standFrom}
                            onChange={this.updateFilterHandler('standFrom')}
                        />
                    ),
                },
                {
                    name: 'standTo',
                    filter: (
                        <StringFilter
                            value={this.props.filters.standTo}
                            onChange={this.updateFilterHandler('standTo')}
                        />
                    ),
                },
            ]}
        />
    )
    private renderStandCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <StandCell stand={rowData.standFrom} />
            <StandCell stand={rowData.standTo} />
        </TowingMultiCell>
    )

    /*
     * Timeframe
     */
    private renderTimeframeHeader = () => (
        <TowingTableHeader
            section='timeframe'
            captions={[
                {
                    name: 'after',
                    filter: (
                        <TimeFilter
                            value={this.props.filters.after}
                            onChange={this.updateFilterHandler('after')}
                        />
                    ),
                },
                {
                    name: 'until',
                    filter: (
                        <TimeFilter
                            value={this.props.filters.until}
                            onChange={this.updateFilterHandler('until')}
                        />
                    ),
                },
            ]}
        />
    )
    private renderTimeframeCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <HighlightedTimeCell value={rowData.after} />
            <HighlightedTimeCell value={rowData.until} />
        </TowingMultiCell>
    )

    /*
     * Arrival
     */
    private renderArrivalHeader = () => (
        <TowingTableHeader
            section='arrival'
            captions={[
                {
                    name: 'arrFltnr',
                    label: 'fltnr',
                    filter: (
                        <StringFilter
                            value={this.props.filters.arrFltnr}
                            onChange={this.updateFilterHandler('arrFltnr')}
                        />
                    ),
                },
                {
                    name: 'arrCallsign',
                    label: 'callsign',
                    filter: (
                        <StringFilter
                            value={this.props.filters.arrCallsign}
                            onChange={this.updateFilterHandler('arrCallsign')}
                        />
                    ),
                },
                {
                    name: 'arrTime',
                    filter: (
                        <TimeFilter
                            value={this.props.filters.arrTime}
                            onChange={this.updateFilterHandler('arrTime')}
                        />
                    ),
                },
            ]}
        />
    )
    private renderArrivalCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <span>{rowData.arrFltnr}</span>
            <span>{rowData.arrCallsign}</span>
            <TimeCellWithType value={rowData.arrTime} type={rowData.arrTimeType} />
        </TowingMultiCell>
    )

    /*
     * Departure
     */
    private renderDepartureHeader = () => (
        <TowingTableHeader
            section='departure'
            captions={[
                {
                    name: 'depFltnr',
                    label: 'fltnr',
                    filter: (
                        <StringFilter
                            value={this.props.filters.depFltnr}
                            onChange={this.updateFilterHandler('depFltnr')}
                        />
                    ),
                },
                {
                    name: 'depCallsign',
                    label: 'callsign',
                    filter: (
                        <StringFilter
                            value={this.props.filters.depCallsign}
                            onChange={this.updateFilterHandler('depCallsign')}
                        />
                    ),
                },
                {
                    name: 'depTime',
                    filter: (
                        <TimeFilter
                            value={this.props.filters.depTime}
                            onChange={this.updateFilterHandler('depTime')}
                        />
                    ),
                },
            ]}
        />
    )
    private renderDepartureCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <span>{rowData.depFltnr}</span>
            <span>{rowData.depCallsign}</span>
            <TimeCellWithType value={rowData.depTime} type={rowData.depTimeType} />
        </TowingMultiCell>
    )

    /*
     * Status
     */
    private renderStatusHeader = () => (
        <TowingTableHeader
            section='status'
            captions={[
                {
                    name: 'status',
                    filter: (
                        <TableDropdown
                            items={EnumValues.getValues(TowingStatus)}
                            onChange={this.updateFilterHandler('status')}
                            value={this.props.filters.status}
                            valueRenderer={this.renderStatusDropdownItem}
                        />
                    ),
                },
                { name: 'lastUpdated' },
            ]}
        />
    )
    private renderStatusCell = ({ rowData }: TowingCellRenderer) => (
        <TowingMultiCell>
            <TowingStatusCell status={rowData.status} />
            <RelativeTimeCell value={rowData.lastUpdated} />
        </TowingMultiCell>
    )
    private renderStatusDropdownItem = (status: TowingStatus) => (
        <span style={{ color: towingStatusColor[status] }}>{translateTowingStatus(status)}</span>
    )

    private updateFilterHandler = (key: keyof TowingFilters) => (value: string) => {
        this.props.updateFilters({ [key]: value })
    }

    private handleUserScroll = () => {
        this.props.lockNow(false)
    }
}

type TowingCellRenderer<F extends keyof Towing = keyof Towing> = TypedCellRendererProps<Towing, F>

interface TowingTableStateProps {
    towingList: Towing[]
    isLoading: boolean
    nowLocked: boolean
    nowIndex?: number
    now: DateTime
    filters: TowingFilters
    columnValues: FlightInformationColumnValues
}

interface TowingTableDispatchProps {
    lockNow: typeof lockNowAction
    updateFilters: typeof updateTowingFiltersAction
}

export const TowingTable = connect<TowingTableStateProps, TowingTableDispatchProps>(
    createStructuredSelector({
        towingList: sortedAndFilteredTowingListSelector,
        isLoading: isLoadingTowingSelector,
        nowLocked: isNowLockedSelector,
        nowIndex: towingListNowIndexSelector,
        columnValues: towingListColumnValuesSelector,
        now: currentTimeSelector,
        filters: towingFiltersSelector,
    }),
    {
        lockNow: lockNowAction,
        updateFilters: updateTowingFiltersAction,
    },
)(TowingTableClass)
