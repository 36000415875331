export interface RemoveAccountState {
    loading: boolean
    result: RemoveAccountActionTypes | null
}

export enum RemoveAccountActionTypes {
    SUCCESS,
    ERROR,
}

export const initialRemoveAccountState: RemoveAccountState = {
    loading: false,
    result: null,
}

export interface RemoveAccountStateAware {
    removeAccount: RemoveAccountState
}
