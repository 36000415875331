import { State } from 'aos-frontend/src/app/core/state'
import { createSelector } from 'reselect'

import { FlightsType } from '../../../../../services/airportStatus/flights/types/FlightsType'
import {
    CommonFlightView,
    CommonTimeRange,
} from '../../../../../services/statusDashboard/types/filters/CommonFlightFilters'
import { HandlingAgentFilter } from '../../../../../services/statusDashboard/types/filters/HandlingAgentFilter'
import { RegularityFilters } from '../../../../../services/statusDashboard/types/filters/RegularityFilters'
import { ItemStateAware } from '../common'

export const timeRangeOwnPropsSelector = (_0: State, ownProps: ItemStateAware<RegularityFilters>) =>
    ownProps.itemState.timeRange

export const handlingAgentOwnPropsSelector = (
    _0: State,
    ownProps: ItemStateAware<RegularityFilters>,
) => ownProps.itemState.handlingAgent

export const flightTypeOwnPropsSelector = (
    _0: State,
    ownProps: ItemStateAware<RegularityFilters>,
) => ownProps.itemState.flightType

export const airlineOwnPropsSelector = (_0: State, ownProps: ItemStateAware<RegularityFilters>) =>
    ownProps.itemState.airline

export const fullViewOwnPropsSelector = (_0: State, ownProps: ItemStateAware<RegularityFilters>) =>
    ownProps.itemState.fullViewTab

export const baseRegularityFiltersOwnPropsSelector = createSelector(
    timeRangeOwnPropsSelector,
    handlingAgentOwnPropsSelector,
    flightTypeOwnPropsSelector,
    airlineOwnPropsSelector,
    fullViewOwnPropsSelector,
    (
        timeRange: CommonTimeRange,
        handlingAgent: HandlingAgentFilter,
        flightType: FlightsType,
        airline: string,
        fullViewTab: CommonFlightView,
    ) => ({
        timeRange,
        handlingAgent,
        flightType,
        airline,
        fullViewTab,
    }),
)
