import React from 'react'
import styled from 'styled-components'

import { Box } from '../../base/Box'
import { BaseCalendarTimelineGroup } from '../types/BaseCalendarTimelineGroup'
import { CollapsibleTimelineGroup } from './CollapsibleTimelineGroup'

interface TimelineGroupRendererProps<G extends BaseCalendarTimelineGroup> {
    group: G
    toggleGroup(id: G): void
}

export const TimelineGroupRenderer = <
    G extends BaseCalendarTimelineGroup = BaseCalendarTimelineGroup
>({
    group,
    toggleGroup,
}: TimelineGroupRendererProps<G>) => (
    <Wrapper fullSize>
        <CollapsibleTimelineGroup
            label={group.timelineLabel}
            isCollapsed={group.isCollapsed}
            toggle={() => toggleGroup(group)}
        />
    </Wrapper>
)

const Wrapper = styled(Box)`
    line-height: 1rem;
`
