import { dateTimeFromOptional } from 'aos-helpers/src/helpers/Time'
import { WrapperObject } from 'aos-helpers/src/helpers/WrapperObject'

import { PrivateChannelParticipantDto } from '../../../dataaccess/firebaseEvents/types/PrivateChannelParticipantDto'
import { InvitationRole, InvitationStatus } from './InvitationStatus'
import { PrivateChannelParticipant } from './PrivateChannelParticipant'

export class PrivateChannelParticipantImpl
    extends WrapperObject<PrivateChannelParticipantDto>
    implements PrivateChannelParticipant {
    constructor(value: PrivateChannelParticipantDto, public firebaseUid: string) {
        super(value)
    }

    public get displayName() {
        return this.value.displayName
    }

    public get invitationRole() {
        return this.value.invitationRole as InvitationRole
    }

    public get invitationStatus() {
        return this.value.invitationStatus as InvitationStatus
    }

    public get arrivalTime() {
        return this.value.arrivalTime !== 'null'
            ? dateTimeFromOptional(this.value.arrivalTime)
            : undefined
    }
}
