import { DateTime } from 'aos-helpers/src/helpers/Time'
import { sumBy } from 'lodash'

import { AosAirport } from './AosAirport'
import { AosFlightBeltStatus } from './AosFlightBeltStatus'
import { AosHandlingAgent } from './AosHandlingAgent'
import { FlightNatureCode } from './AosNatureOfFlight'
import { FlightPrmGroup, PublicRemarkCode } from './AosPublicRemark'
import { FlightListType } from './FlightListType'
import { QuickFilterable } from './QuickFilterable'

export interface Flight extends QuickFilterable {
    id: string
    hapt: AosAirport
    prfix: string
    sffix: string

    fltnr: string
    nr: string
    cflight: string[]

    flightType: FlightListType
    sdt: DateTime
    acreg: string
    actype: string
    mfltnr?: string
    naflt: AosNatureOfFlight
    handl?: AosHandlingAgent
    routes: FlightRoute[]
    park: FlightPark
    gate: FlightGate
    irmsg?: string
    prm: FlightPrm
    tobt?: DateTime
    est?: DateTime
    pest?: DateTime
    appr?: DateTime
    act?: DateTime
    ablk?: DateTime
    eibt?: DateTime
    tsat?: DateTime
    departureTime?: DateTime
    bltarea?: string
    belt?: string
    belt2?: string
    bltInd?: string
    fibag?: DateTime
    labag?: DateTime
    paxTotal?: number
    callsign: string
    chkarea?: string
    chkdsk: string[]
    door?: string
    rwy?: string
    airline: FlightAirline
    aircraft?: FlightAircraft
    key: string
    isDelayedBasedOnEstimated: boolean
    isHel: boolean
    cancelled: boolean
    bag: AosFlightBeltStatus
}

export interface AosNatureOfFlight {
    code?: FlightNatureCode
    value: string
}

export interface FlightRoute {
    airport: AosAirport
    airportName: string
}

export interface FlightPark {
    current?: string
    previous?: string
}

export interface FlightGate {
    current?: string
    previous?: string
}

export interface FlightPrm {
    code: PublicRemarkCode
    text: string
    group: FlightPrmGroup
}

export interface FlightAirline {
    code: string
    name: string
}

export interface FlightAircraft {
    code: string
    name: string
}

export type FlightKey = keyof Flight

export const flightDateTimeColumns: FlightKey[] = [
    'sdt',
    'est',
    'pest',
    'appr',
    'act',
    'ablk',
    'eibt',
    'tobt',
    'tsat',
]

export const flightInRangePredicate = (from: DateTime, to: DateTime) => (f: Flight) =>
    f.sdt.isBetween(from, to, 's', '[)')

export const bySdtComparator = (a: Flight, b: Flight) => a.sdt.valueOf() - b.sdt.valueOf()

export const isCompleted = (f: Flight) => !!f.ablk

export const isPunctual = (f: Flight) => getFlightDelay(f) < 15

export const isDelayed = (f: Flight) => f.sdt.isBefore(f.ablk)

export const isRegular = (f: Flight) => !f.cancelled

export const getFlightDelay = (f: Flight) => f.ablk!.diff(f.sdt, 'minute', false)

export const toPercent = (a: number, b: number) => (b ? Math.floor((a / b) * 100) : 100)

export const getFlightPercentage = (all: Flight[], regular: Flight[]) =>
    toPercent(regular.length, all.length)

export const averageForFlights = (flights: Flight[], getter: f.Func1<Flight, number>) =>
    Math.round(sumBy(flights, getter) / (flights.length || 1))

export const getAverageDelay = (_all: Flight[], delayed: Flight[]) =>
    averageForFlights(delayed, getFlightDelay)

export const getFlightCount = (all: Flight[], _a: Flight[]) => all.length

export const getFlightCountPerHour = (hourCount: number) => (all: Flight[], _a: Flight[]) =>
    Math.floor(all.length / hourCount)

export const chkString = ({ chkdsk, chkarea }: Flight) =>
    chkarea ? `${chkarea} ${chkdsk.length ? `(${chkdsk.join(' - ')})` : ''}` : undefined

export const isListFlight = (f: Flight, now: DateTime) => {
    return (
        (f.prm.group !== FlightPrmGroup.Landed &&
            f.prm.group !== FlightPrmGroup.Departed &&
            f.prm.group !== FlightPrmGroup.Cancelled) ||
        f.sdt.isAfter(now.clone().add(-12, 'hour'))
    )
}
