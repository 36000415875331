import { ParkingProperties } from 'aos-services/src/services/layerData/properties/ParkingProperties'
import { TravelServicesProperties } from 'aos-services/src/services/layerData/properties/TravelServicesProperties'
import { svgIconForName, SvgIconType } from 'aos-ui/src/components/svg/Icon'
import { camelCase, upperFirst } from 'lodash'

export enum IconHeight {
    Stand = 30,
    Gate = 30,
    BusGate = 26,
    TravelServices = 32,
    Parking = 48,
}

const travelServicePropertiesToIconName = (p: TravelServicesProperties) =>
    `TravelServices${upperFirst(camelCase(p.Pictogram_category))}` as SvgIconType

const parkingPropertiesToIconName = (p: ParkingProperties) =>
    `Parking${upperFirst(camelCase(p.Name))}` as SvgIconType

export const travelServiceMapper = (g: TravelServicesProperties) =>
    svgIconForName(travelServicePropertiesToIconName(g))

export const parkingMapper = (g: ParkingProperties) =>
    svgIconForName(parkingPropertiesToIconName(g))
