import { Color } from 'aos-ui-common/src/styles/Color'

import { PaxForecastArrivalOrDeparture } from '../../../../airportStatus/paxForecast/types/PaxForecastArrivalOrDeparture'
import { FilterOptionAll, filterOptionsWithAll, translateFilterWithAllOption } from '../common'

export type PaxForecastArrivalDepartureFilter = FilterOptionAll | PaxForecastArrivalOrDeparture
export const paxForecastArrivalDepartureFilterValues: PaxForecastArrivalDepartureFilter[] = filterOptionsWithAll(
    PaxForecastArrivalOrDeparture,
)
export const paxForecastArrivalDepartureColors: Record<PaxForecastArrivalDepartureFilter, Color> = {
    [FilterOptionAll.All]: Color.White,
    [PaxForecastArrivalOrDeparture.Arrivals]: Color.PaleOrange,
    [PaxForecastArrivalOrDeparture.Departures]: Color.Violet,
}

export const paxForecastArrivalDepartureTransparentColors: Record<
    PaxForecastArrivalDepartureFilter,
    Color
> = {
    [FilterOptionAll.All]: Color.TransparentWhite,
    [PaxForecastArrivalOrDeparture.Arrivals]: Color.PaleOrange,
    [PaxForecastArrivalOrDeparture.Departures]: Color.Violet,
}

export const translatePaxArrivalDepartureFilter: f.Func1<
    PaxForecastArrivalDepartureFilter,
    string
> = translateFilterWithAllOption(
    PaxForecastArrivalOrDeparture,
    'dashboard.pax-forecast.filters.arrival-or-departure',
)

export const translatePaxArrivalDepartureFilterForChart: f.Func1<
    PaxForecastArrivalDepartureFilter,
    string
> = translateFilterWithAllOption(
    PaxForecastArrivalOrDeparture,
    'dashboard.pax-forecast.chart.time-of-day',
)
