import {
    TimeType,
    translateTimeType,
} from 'aos-services/src/services/flightInformation/types/towing/Towing'
import { Box } from 'aos-ui/src/components/base/Box'
import { Tooltip } from 'aos-ui/src/components/tooltip/Tooltip'
import React, { FC } from 'react'

import { TimeTypeIcon } from '../partial/TimeTypeIcon'
import { HighlightedTimeCell, HighlightedTimeCellProps } from './HighlightedTimeCell'

interface TimeCellWithTypeProps extends HighlightedTimeCellProps {
    type?: TimeType
}

export const TimeCellWithType: FC<TimeCellWithTypeProps> = props => {
    const { type, ...restProps } = props

    if (!type) {
        return null
    }

    return (
        <Box row centered>
            <Box style={{ minWidth: 60 }}>
                <HighlightedTimeCell {...restProps} />
            </Box>
            <Tooltip body={translateTimeType(type)} withArrow>
                <span>
                    <TimeTypeIcon type={type} />
                </span>
            </Tooltip>
        </Box>
    )
}
