import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import { UserLayerVisibilityState } from 'aos-services/src/core/userLayersVisibility/state'
import { LayerName } from 'aos-services/src/services/map/types/LayersVisibility'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { IconButton } from 'aos-ui/src/components/buttons/IconButton'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useCallback } from 'react'
import styled from 'styled-components'

import { BimLayer, bimLayerData } from '../../../bim/BimLayer'
import { BimLayerCategory } from '../../../bim/BimLayerCategory'
import { bimLayerImage } from '../../../bim/BimLayerImage'
import { AtcLayers, BimLayerName } from '../../../bim/BimLayerName'

interface LayerModalProps {
    isOpen: boolean
    closeAction: () => void
    userLayerVisibility: UserLayerVisibilityState
    onSelectedLayer?: (layer: BimLayerName) => void
    isApoc: boolean
}

const layers = bimLayerData.filter(q => q.category === BimLayerCategory.Atc)
export const LayerModal: FC<LayerModalProps> = ({
    isOpen,
    closeAction,
    onSelectedLayer,
    userLayerVisibility,
    isApoc,
}) => {
    const getLayerNameLabel = useCallback((l: LayerName) => translate('bim.layer.name.' + l), [])
    const toggleActiveLayer = (layer: BimLayerName) => {
        if (onSelectedLayer) {
            onSelectedLayer(layer)
        }
    }
    const renderGrid = () => {
        return (
            <Box padding={12} flex={1} width='100%' height='100%'>
                <Box marginLeft={12} row justify='space-between'>
                    <Text color={Color.Black}>{translate('private-channel.map.title')}</Text>
                    <IconButton
                        svg={SvgIcon.Close}
                        iconSize={BlockSize.Large}
                        onClick={closeAction}
                    />
                </Box>
                <Grid>
                    {layers.map(item =>
                        renderItem(item, userLayerVisibility.list.includes(item.name)),
                    )}
                </Grid>
            </Box>
        )
    }

    const renderItem = (layer: BimLayer, isActive: boolean) => {
        const Wrapper = isApoc ? LayerItem : Box
        return (
            <Wrapper
                onClick={() => isApoc && toggleActiveLayer(layer.name)}
                alignItems='center'
                padding={8}
                column
                width={110}
                key={layer.name}
            >
                <RectangularImage
                    isActive={isActive}
                    src={bimLayerImage[layer.name as AtcLayers]}
                />
                <Text
                    marginTop={8}
                    textAlign='center'
                    size={14}
                    color={isActive ? Color.PrimaryDark : Color.Black}
                >
                    {getLayerNameLabel(layer.name)}
                </Text>
            </Wrapper>
        )
    }

    return <div>{isOpen && <ModalWrapper>{renderGrid()}</ModalWrapper>}</div>
}

const ModalWrapper = styled.div`
    width: 40%;
    min-width: 360px;
    max-width: 460px;
    height: 560px;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1000;
    border-radius: 8px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
`
const RectangularImage = styled.img<{ isActive: boolean }>`
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
    border: ${props => (props.isActive ? '1px solid' : 'none')};
    border-color: ${Color.PrimaryDark};
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    place-items: start;
`

const LayerItem = styled(Box)`
    :hover {
        opacity: 0.8;
    }
    :active {
        opacity: 0.6;
    }
`
