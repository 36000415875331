import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PropsWithChildren, PureComponent } from 'react'

export class PrivateChannelSection extends PureComponent<
    PropsWithChildren<PrivateChannelSectionProps>
> {
    public render() {
        return (
            <Box paddingBottom={20}>
                <Text color={Color.White} size={18}>
                    {this.props.title}
                </Text>
                {this.renderSubTitle()}
                <Box>{this.props.children}</Box>
            </Box>
        )
    }

    private renderSubTitle = () =>
        this.props.subTitle ? (
            <Text color={Color.Grey} size={14} paddingVertical={8} lineHeight='large'>
                {this.props.subTitle}
            </Text>
        ) : null
}

interface PrivateChannelSectionProps {
    title: string
    subTitle?: string
}
