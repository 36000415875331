import {
    taskCategories,
    TaskCategory,
    translateTaskCategory,
} from 'aos-services/src/services/tasks/types/TaskCategory'
import { TaskFilter, translateTaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskPageableFilters } from 'aos-services/src/services/tasks/types/TaskPageable'
import { allTaskSortings } from 'aos-services/src/services/tasks/types/TaskSorting'
import { Box } from 'aos-ui/src/components/base/Box'
import { DropdownWidth } from 'aos-ui/src/components/form/dropdown/base/DropdownWidth'
import { CheckboxDropdown } from 'aos-ui/src/components/form/dropdown/CheckboxDropdown'
import { DropdownGroups } from 'aos-ui/src/components/form/dropdown/DropdownGroups'
import { PageSizeAndSortDropdown } from 'aos-ui/src/components/form/dropdown/PageSizeAndSortDropdown'
import { HeaderContainer } from 'aos-ui/src/components/header/HeaderContainer'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Link } from '../../../core/Links'
import { FilterType, TasksHeaderFilter } from '../common/TasksHeaderFilter'

interface TasksProps {
    filter: TaskFilter
    tasksFilters: TaskPageableFilters
    loadTasks(r: TaskPageableFilters): void
}

export const TasksHeader: FC<TasksProps> = ({ filter, loadTasks, tasksFilters }) => {
    const history = useHistory()

    const loadTasksByCategory = (category: TaskCategory[]) =>
        loadTasks({ ...tasksFilters, category })
    const clearCategories = () => loadTasksByCategory([])

    return (
        <HeaderContainer title={<TasksHeaderFilter value={FilterType.List} />}>
            <DropdownGroups
                items={[
                    [TaskFilter.All, TaskFilter.AssignedToMe],
                    [
                        TaskFilter.Todo,
                        TaskFilter.InProgress,
                        TaskFilter.OnHold,
                        TaskFilter.Done,
                        TaskFilter.Overdue,
                    ],
                ]}
                value={filter}
                valueRenderer={translateTaskFilter}
                onChange={v => history.push(`${Link.TasksList}/${v}`)}
                width={DropdownWidth.Std}
                small
            />
            <Box marginHorizontal={30}>
                <CheckboxDropdown
                    items={taskCategories}
                    value={tasksFilters.category}
                    allContent='All categories'
                    partialContent={i => `Categories: ${i.length}`}
                    valueRenderer={translateTaskCategory}
                    onChange={loadTasksByCategory}
                    clearAction={clearCategories}
                    width={DropdownWidth.Std}
                    preventEmpty
                    small
                />
            </Box>
            <PageSizeAndSortDropdown
                pageSize={tasksFilters.pageSize}
                sort={tasksFilters.sort}
                onChangeSize={pageSize => loadTasks({ ...tasksFilters, pageSize, page: 0 })}
                onChangeSort={sort => loadTasks({ ...tasksFilters, sort, page: 0 })}
                keyPrefix='tasks.sort'
                sortEntries={allTaskSortings}
            />
        </HeaderContainer>
    )
}
