import { ZIndex } from 'aos-ui/src/components/base/Theme'
import RcTooltip, { Placement, RcTooltipProps } from 'rc-tooltip'
import React, { FCWithChildren, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { TooltipOverlay, TooltipOverlayBox, TooltipOverlayVariant } from './TooltipOverlay'

interface TooltipProps extends Partial<RcTooltipProps> {
    body?: ReactNode
    withArrow?: boolean
    white?: boolean
    offset?: [number, number]
    className?: string
}

const getDefaultOffset = (placement: Placement, withArrow: boolean) => {
    const value = withArrow ? 6 : 1
    type MaskValue = 1 | 0 | -1
    const placementMasks: Record<Placement, MaskValue[]> = {
        left: [-1, 0],
        right: [1, 0],
        top: [0, -1],
        topLeft: [0, -1],
        topRight: [0, -1],
        bottom: [0, 1],
        bottomLeft: [0, 1],
        bottomRight: [0, 1],
    }
    const placementMask = placementMasks[placement]
    return [placementMask[0] * value, placementMask[1] * value]
}

const TooltipComponent: FCWithChildren<TooltipProps> = props => {
    // tslint:disable:trailing-comma
    const {
        body,
        placement = 'topLeft',
        white,
        offset,
        withArrow = false,
        overlay,
        className,
        ...rest
    } = props

    const defaultOverlay = (
        <TooltipOverlay
            withArrow={withArrow}
            padding={8}
            variant={white ? TooltipOverlayVariant.White : TooltipOverlayVariant.Black}
        >
            {body}
        </TooltipOverlay>
    )
    const finalOffset = offset || getDefaultOffset(placement as Placement, withArrow)
    return (
        <RcTooltip
            overlayClassName={className}
            placement={placement}
            overlay={overlay ? overlay : defaultOverlay}
            align={{ offset: finalOffset }}
            {...rest}
        />
    )
}

export const Tooltip = styled(TooltipComponent)<PropsWithChildren<TooltipProps>>`
    display: block;
    position: absolute;
    z-index: ${ZIndex.Tooltip};

    .rc-tooltip-arrow {
        display: none;
    }

    .rc-tooltip-inner {
        display: block;
    }

    .rc-tooltip-content {
        max-width: 400px;
    }

    &.rc-tooltip-hidden {
        display: none;
    }

    &.rc-tooltip-placement-top ${TooltipOverlayBox}::before {
        bottom: -4px;
        left: 0;
        right: 0;
    }

    &.rc-tooltip-placement-topLeft ${TooltipOverlayBox}::before {
        left: 10px;
        bottom: -4px;
    }

    &.rc-tooltip-placement-topRight ${TooltipOverlayBox}::before {
        right: 10px;
        bottom: -4px;
    }

    &.rc-tooltip-placement-left ${TooltipOverlayBox}::before {
        right: -4px;
        top: 0;
        bottom: 0;
    }

    &.rc-tooltip-placement-right ${TooltipOverlayBox}::before {
        left: -4px;
        top: 0;
        bottom: 0;
    }

    &.rc-tooltip-placement-bottom ${TooltipOverlayBox}::before {
        top: -4px;
        left: 0;
        right: 0;
    }

    &.rc-tooltip-placement-bottomLeft ${TooltipOverlayBox}::before {
        left: 10px;
        top: -4px;
    }

    &.rc-tooltip-placement-bottomRight ${TooltipOverlayBox}::before {
        right: 10px;
        top: -4px;
    }
`
