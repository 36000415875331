import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { Restriction } from '../../services/restrictions/types/Restriction'
import { SyncDataKey } from '../../services/statusDashboard/types/DashboardDataState'

export const RESTRICTIONS_SYNC = 'RESTRICTIONS/SYNC'
export type RestrictionsSyncAction = PayloadAction<typeof RESTRICTIONS_SYNC, SyncDataKey>
export const restrictionsSyncAction = payloadActionCreator<RestrictionsSyncAction, SyncDataKey>(
    RESTRICTIONS_SYNC,
)

export const RESTRICTIONS_SYNC_STOP = 'RESTRICTIONS/SYNC_STOP'
export type RestrictionsSyncStopAction = PayloadAction<typeof RESTRICTIONS_SYNC_STOP, SyncDataKey>
export const restrictionsSyncStopAction = payloadActionCreator<
    RestrictionsSyncStopAction,
    SyncDataKey
>(RESTRICTIONS_SYNC_STOP)

export const RESTRICTIONS_LOAD = 'RESTRICTIONS/LOAD'
export type RestrictionsLoadAction = Action<typeof RESTRICTIONS_LOAD>
export const restrictionsLoadAction = emptyActionCreator<RestrictionsLoadAction>(RESTRICTIONS_LOAD)

export const RESTRICTIONS_LOAD_SUCCESS = 'RESTRICTIONS/LOAD_SUCCESS'
export type RestrictionLoadSuccessAction = PayloadAction<
    typeof RESTRICTIONS_LOAD_SUCCESS,
    Restriction[]
>
export const restrictionsLoadSuccessAction = payloadActionCreator<
    RestrictionLoadSuccessAction,
    Restriction[]
>(RESTRICTIONS_LOAD_SUCCESS)

export type RestrictionsAction =
    | RestrictionsSyncAction
    | RestrictionsSyncStopAction
    | RestrictionsLoadAction
    | RestrictionLoadSuccessAction
