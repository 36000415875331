import { AosEventOrFeedIn } from 'aos-services/src/services/events/types/AosEventOrFeedIn'
import { MapMode } from 'aos-services/src/services/mapui/types/BaseMapState'
import { DialogView } from 'aos-ui/src/components/modal/MapAwareModal'
import { initialMapStateWithAdjustedZoom, MapState } from 'aos-ui-map/src/core/state'

export interface EventPreviewState {
    event?: AosEventOrFeedIn
    sendToSync?: number
    subContent: DialogView
    mode: EventPreviewMode
    map: MapState
}

export interface EventPreviewStateAware {
    eventPreview: EventPreviewState
}

export enum EventPreviewMode {
    ContentAndMap,
    MapOnly,
}

export const initialEventPreviewState: EventPreviewState = {
    subContent: DialogView.Content,
    mode: EventPreviewMode.ContentAndMap,
    map: {
        ...initialMapStateWithAdjustedZoom(-0.9),
        mode: MapMode.ViewSingleLocation,
    },
}

const getFirebaseEventSendToSyncValue = (state: EventPreviewStateAware) => {
    if (state.eventPreview.sendToSync) {
        return state.eventPreview.sendToSync
    }
    return undefined
}

export const compareEventPreviewStsValues = (
    state: EventPreviewStateAware,
    sendToSyncValue: number,
) => getFirebaseEventSendToSyncValue(state) !== sendToSyncValue

export const getEventId = (state: EventPreviewStateAware) => {
    if (state.eventPreview.event) {
        return state.eventPreview.event.id
    }
    return undefined
}
