import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { AosUser } from 'aos-services/src/services/users/types/AosUser'
import { AosUserNotificationsPreferences } from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { AosUserProfileSettings } from 'aos-services/src/services/users/types/AosUserSetting'

import { TabId, UserProfileFormState } from './state'

export const TOGGLE_USER_PROFILE_MODAL = 'USER_PROFILE_MODAL/TOGGLE_USER_PROFILE_MODAL'
export type ToggleUserProfileModalAction = PayloadAction<typeof TOGGLE_USER_PROFILE_MODAL, boolean>
export const toggleUserProfileModalAction = payloadActionCreator<
    ToggleUserProfileModalAction,
    boolean
>(TOGGLE_USER_PROFILE_MODAL)

export const UPDATE_NOTIFICATION_SETTINGS = 'USER_PROFILE_MODAL/UPDATE_NOTIFICATION_SETTINGS'
export type UpdateNotificationSettingsAction = PayloadAction<
    typeof UPDATE_NOTIFICATION_SETTINGS,
    Partial<AosUserNotificationsPreferences>
>

export const SAVE_USER_PROFILE = 'USER_PROFILE_MODAL/SAVE_USER_PROFILE '
export type SaveUserProfileAction = PayloadAction<typeof SAVE_USER_PROFILE, AosUserProfileSettings>
export const saveUserProfileAction = payloadActionCreator<
    SaveUserProfileAction,
    AosUserProfileSettings
>(SAVE_USER_PROFILE)

export const SAVE_PROFILE_SUCCESS = 'USER_PROFILE_MODAL/SAVE_PROFILE_SUCCESS'
export type SaveProfileSuccessAction = Action<typeof SAVE_PROFILE_SUCCESS>
export const saveProfileSuccessAction = emptyActionCreator<SaveProfileSuccessAction>(
    SAVE_PROFILE_SUCCESS,
)

export const SET_FORM_PRISTINE = 'USER_PROFILE_MODAL/SET_FORM_PRISTINE'
export type SetFormPristineAction = PayloadAction<typeof SET_FORM_PRISTINE, boolean>
export const setFormPristineAction = payloadActionCreator<SetFormPristineAction, boolean>(
    SET_FORM_PRISTINE,
)

export const SET_ACTIVE_TAB = 'USER_PROFILE_MODAL/SET_ACTIVE_TAB'
export type SetActiveTabAction = PayloadAction<typeof SET_ACTIVE_TAB, TabId>
export const setActiveTabAction = payloadActionCreator<SetActiveTabAction, TabId>(SET_ACTIVE_TAB)

export const UPDATE_FORM_STATE = 'USER_PROFILE_MODAL/UPDATE_FORM_STATE'
export type UpdateFormStateAction = PayloadAction<typeof UPDATE_FORM_STATE, UserProfileFormState>
export const updateFormStateAction = payloadActionCreator<
    UpdateFormStateAction,
    UserProfileFormState
>(UPDATE_FORM_STATE)

export const GET_USER_DETAILS_SUCCESS = 'USER_PROFILE_MODAL//GET_USER_DETAILS_SUCCESS'
export type GetUserDetailsSuccessAction = PayloadAction<typeof GET_USER_DETAILS_SUCCESS, AosUser>
export const getUserDetailsSuccessAction = payloadActionCreator<
    GetUserDetailsSuccessAction,
    AosUser
>(GET_USER_DETAILS_SUCCESS)

export type UserProfileModalAction =
    | UpdateFormStateAction
    | UpdateNotificationSettingsAction
    | SaveProfileSuccessAction
    | ToggleUserProfileModalAction
    | SaveUserProfileAction
    | SetFormPristineAction
    | GetUserDetailsSuccessAction
    | SetActiveTabAction
