import {
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'
import { TimelineAction } from 'aos-services/src/core/timeline/actions'
import { Action } from 'redux'

import { StandsGatesTimelineFilters } from './state'
import { StandsGatesTimelineGroup } from './types/StandsGatesTimelineGroup'
import { StandsGatesTimelineItem } from './types/StandsGatesTimelineItem'

export const STANDS_GATES_TIMELINE_TIMELINE_PARENT =
    'STANDS_GATES_TIMELINE/STANDS_GATES_TIMELINE_TIMELINE_PARENT'
export type StandsGatesTimelineTimelineParentAction = PayloadAction<
    typeof STANDS_GATES_TIMELINE_TIMELINE_PARENT,
    TimelineAction
>
export const standsGatesTimelineTimelineParentAction = payloadActionCreator<
    StandsGatesTimelineTimelineParentAction,
    TimelineAction
>(STANDS_GATES_TIMELINE_TIMELINE_PARENT)

export const TOGGLE_GROUP = 'STANDS_GATES_TIMELINE/TOGGLE_GROUP'
export type ToggleGroupAction = PayloadAction<typeof TOGGLE_GROUP, StandsGatesTimelineGroup>
export const toggleGroupAction = payloadActionCreator<ToggleGroupAction, StandsGatesTimelineGroup>(
    TOGGLE_GROUP,
)

export const SELECT_ITEM = 'STANDS_GATES_TIMELINE/SELECT_ITEM'
export type SelectItemAction = PayloadAction<typeof SELECT_ITEM, StandsGatesTimelineItem>
export const selectItemAction = payloadActionCreator<SelectItemAction, StandsGatesTimelineItem>(
    SELECT_ITEM,
)

export const SUBMIT_FILTERS = 'STANDS_GATES_TIMELINE/SUBMIT_FILTERS'
export type SubmitFiltersAction = PayloadAction<typeof SUBMIT_FILTERS, StandsGatesTimelineFilters>
export const submitFiltersAction = payloadActionCreator<
    SubmitFiltersAction,
    StandsGatesTimelineFilters
>(SUBMIT_FILTERS)

export const OPEN_FILTERS_MODAL = 'STANDS_GATES_TIMELINE/OPEN_FILTERS_MODAL'
export type OpenFiltersModalAction = Action<typeof OPEN_FILTERS_MODAL>
export const openFiltersModalAction = emptyActionCreator<SubmitFiltersAction>(OPEN_FILTERS_MODAL)

export const CLOSE_FILTERS_MODAL = 'STANDS_GATES_TIMELINE/CLOSE_FILTERS_MODAL'
export type CloseFiltersModalAction = Action<typeof CLOSE_FILTERS_MODAL>
export const closeFiltersModalAction = emptyActionCreator<SubmitFiltersAction>(CLOSE_FILTERS_MODAL)

export type StandsGatesTimelineAction =
    | StandsGatesTimelineTimelineParentAction
    | ToggleGroupAction
    | SelectItemAction
    | SubmitFiltersAction
    | OpenFiltersModalAction
    | CloseFiltersModalAction
