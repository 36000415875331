import React, { FCWithChildren } from 'react'
import styled from 'styled-components'

import { Box, BoxProps } from '../base/Box'

export const HeaderItems: FCWithChildren<BoxProps> = ({ children, ...props }) => (
    <Wrapper row fullHeight {...props}>
        {React.Children.map(children, (child, index) => (
            <Item key={index}>{child}</Item>
        ))}
    </Wrapper>
)

const Item = styled(Box)``

const Wrapper = styled(Box)`
    ${Item} + ${Item} {
        margin-left: 20px;
    }
`
