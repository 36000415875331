import { BaseRestService } from '../base/BaseRestService'
import { AnimalSpeciesDto, DeIcingChemicalsDto } from '../tasks/types/TaskMetadataDto'
import { AnimalsOccurrenceDto } from './types/AnimalsOccurrenceDto'
import { ChemicalsUsageDto } from './types/ChemicalsUsageDto'

class TasksRestServiceClass extends BaseRestService {
    public loadChemicalsReport(): Promise<ChemicalsUsageDto[]> {
        return this.getAndTransform<ChemicalsUsageDto[]>(
            '/task-manager/tasks/reporting/chemicals/report',
            BaseRestService.jsonTransformer,
        )
    }
    public loadChemicalsTypes(): Promise<DeIcingChemicalsDto[]> {
        return this.getAndTransform<DeIcingChemicalsDto[]>(
            '/task-manager/tasks/reporting/chemicals/types',
            BaseRestService.jsonTransformer,
        )
    }
    public loadAnimalsReport(): Promise<AnimalsOccurrenceDto[]> {
        return this.getAndTransform<AnimalsOccurrenceDto[]>(
            '/task-manager/tasks/reporting/animals/report',
            BaseRestService.jsonTransformer,
        )
    }
    public loadAnimalsTypes(): Promise<AnimalSpeciesDto[]> {
        return this.getAndTransform<AnimalSpeciesDto[]>(
            '/task-manager/tasks/reporting/animals/types',
            BaseRestService.jsonTransformer,
        )
    }
}

export const tasksReportingRestService = new TasksRestServiceClass()
