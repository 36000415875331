import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren } from 'react'

import { OpenSans } from './font/OpenSans'

interface PictogramIconProps {
    width: number
    height: number
    color?: Color
}

export const PictogramIcon: FC<PropsWithChildren<PictogramIconProps>> = props => {
    const { width, height, color = '#0C2645' } = props
    return (
        <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
            <OpenSans />
            <g fill='none' fillRule='evenodd'>
                <rect stroke='#FFF' strokeWidth='3' fill={color} width='100%' height='100%' />
                {props.children}
            </g>
        </svg>
    )
}
