import { cdmSagas } from './cdm/sagas'
import { eventsSagas } from './events/sagas'
import { fodSyncSagas } from './fod/sagas'
import { newsFeedSagas } from './newsFeed/sagas'
import { operationalForecastSagas } from './operationalForecast/sagas'
import { paxSagas } from './pax/sagas'
import { paxForecastSagas } from './paxForecast/sagas'
import { queueingTimeSagas } from './queueingTime/sagas'
import { runwaySagas } from './runway/sagas'
import { weatherSagas } from './weather/sagas'

export const statusDashboardDataSagas = [
    ...paxSagas,
    ...runwaySagas,
    ...operationalForecastSagas,
    ...cdmSagas,
    ...queueingTimeSagas,
    ...eventsSagas,
    ...eventsSagas,
    ...weatherSagas,
    ...paxForecastSagas,
    ...newsFeedSagas,
    ...fodSyncSagas,
]
