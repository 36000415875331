import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import { Property } from 'csstype'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { ButtonProps } from '../base/ButtonProps'
import { Rotated } from '../base/Rotated'
import { Text } from '../base/Text'
import { Icon } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'
import { TooltipChild } from '../tooltip/TooltipChild'

export interface TableHeaderButtonProps<T extends string> extends ButtonProps, TooltipChild {
    label: ReactNode
    textTransform?: Property.TextTransform
    textAlign?: Property.TextAlign
    sort?: {
        sort: Sort<T>
        sortKey: T
        onChange(v: Sort<T>): void
    }
}

export interface Sort<T extends string> {
    key: T
    direction: 'asc' | 'desc'
}

export const TableHeaderButton = <T extends string>({
    label,
    textTransform = 'uppercase',
    textAlign = 'center',
    sort,
    onMouseEnter,
    onMouseLeave,
}: TableHeaderButtonProps<T>) => {
    const hasSorting = sort?.sortKey === sort?.sort?.key
    const desc = sort?.sort.direction === 'desc'
    const change = () => {
        if (!sort) {
            return
        }
        if (sort.sortKey === sort.sort?.key) {
            sort.onChange({
                direction: desc ? 'asc' : 'desc',
                key: sort.sortKey,
            })
        } else {
            sort.onChange({
                direction: 'asc',
                key: sort.sortKey,
            })
        }
    }
    return (
        <ButtonWrapper
            as='button'
            onClick={change}
            color={Color.White}
            size={14}
            textNoWrap
            paddingBottom={8}
            marginLeft={hasSorting && textAlign !== 'left' ? 16 : 0}
            row
            cursor='pointer'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            textTransform={textTransform}
        >
            <span>{label}</span>
            {hasSorting && (
                <Rotated angle={desc ? 0 : 180} animated>
                    <Icon svg={SvgIcon.Arrow} iconSize={BlockSize.Tiny} block />
                </Rotated>
            )}
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled(Text)`
    display: inline-flex;
`
