import {
    WeatherOtherSeriesType,
    WeatherSeriesType,
    WeatherValueSeriesType,
} from 'aos-services/src/services/airportStatus/weather/types/WeatherSeriesType'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'

const weatherSeriesIconsMapping: Record<WeatherSeriesType, Svg> = {
    [WeatherValueSeriesType.LvpProbability]: SvgIcon.ChartLvp,
    [WeatherValueSeriesType.ThunderstormProbability]: SvgIcon.ChartThunderstorm,
    [WeatherValueSeriesType.Rain]: SvgIcon.ChartRain,
    [WeatherValueSeriesType.Snow]: SvgIcon.ChartSnow,
    [WeatherValueSeriesType.MaxWindGust]: SvgIcon.ChartWindGusts12,
    [WeatherValueSeriesType.WindDirection]: SvgIcon.ChartWindDirection,
    [WeatherValueSeriesType.WindSpeed]: SvgIcon.ChartWindSpeed,
    [WeatherValueSeriesType.CumulonimbusCloudsProbability]: SvgIcon.ChartCbProbability,
    [WeatherValueSeriesType.CumulonimbusCloudsBase]: SvgIcon.ChartCloudBase,
    [WeatherValueSeriesType.Temperature]: SvgIcon.ChartTemperature,
    [WeatherValueSeriesType.DewPoint]: SvgIcon.ChartDewPoint,
    [WeatherValueSeriesType.RainAndSnow]: SvgIcon.ChartSnowRain,
    [WeatherOtherSeriesType.FreezingWeatherPhenomenon]: SvgIcon.ChartFwp,
    [WeatherOtherSeriesType.TemporaryFreezingWeatherPhenomenon]: SvgIcon.ChartTfwp,
    [WeatherValueSeriesType.PresentWeather]: SvgIcon.WeatherNormal, // not used
    [WeatherOtherSeriesType.WeatherCategory]: SvgIcon.WeatherNormal, // not used
}

export const getIconsForSeriesType = (t: WeatherSeriesType) => weatherSeriesIconsMapping[t]
