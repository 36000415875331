import { compact, findKey, isEqual } from 'lodash'

/* Weird names because of compatibility to Pageable */
export interface Page {
    size: number
    /* 0 based */
    number: number
    totalElements: number
}

export interface Pageable<T> {
    content: T[]
    totalPages: number
    totalElements: number
    size: number
    sort: Sort[]
    numberOfElements: number
    number: number
}

export interface Sort {
    direction: SortDirection
    property: string
    ignoreCase: boolean
    ascending: boolean
    descending: boolean
    nullHandling: string
}

export enum SortDirection {
    Desc = 'DESC',
    Asc = 'ASC',
}

// TODO remove this hell
export interface FilterableAndPageable<T, U> {
    data: Pageable<T>
    filter: U
}

export type SortEntry = [string, SortDirection]

export interface SortEntryMap {
    [key: string]: SortEntry[]
}

export const defaultPageSize = 10

export const mapPageableContent =
    <T, U>(mapper: f.Func1<T, U | undefined>) =>
    (i: Pageable<T>): Pageable<U> => ({
        ...i,
        content: compact(i.content.map(mapper)),
    })

export const pageableForList = <T>(content?: T[], sorts?: SortEntry[]): Pageable<T> => {
    const items = content || []
    const totalElements = items.length
    const pageSize = defaultPageSize

    const sortEntryToSort = (sort: SortEntry) => ({
        direction: sort[1],
        property: sort[0],
        ignoreCase: true,
        ascending: sort[0] === SortDirection.Asc,
        descending: sort[0] === SortDirection.Desc,
        nullHandling: '',
    })

    const sortItems = (sorts || []).map(sortEntryToSort)

    return {
        content: items,
        totalPages: Math.ceil(totalElements / pageSize),
        totalElements,
        size: pageSize,
        sort: sortItems,
        numberOfElements: Math.min(pageSize, totalElements),
        number: 0,
    }
}

export const filterableAndPageableForList = <T, U>(
    content: T[],
    filter: U,
    sort?: SortEntry[],
): FilterableAndPageable<T, U> => ({
    data: pageableForList(content, sort),
    filter,
})

export const sortEntriesForPageable = (pg: Pageable<any>): SortEntry[] =>
    pg.sort.map(t => [t.property, t.direction] as SortEntry)

export const sortingKeyForPageable = (
    pg: Pageable<any>,
    sortEntryMap: SortEntryMap,
): string | undefined => {
    const valueFromPageable = sortEntriesForPageable(pg)
    return findKey(sortEntryMap, p => isEqual(p, valueFromPageable))
}

export const hasMorePages = (p: Pageable<any>) =>
    p.totalPages > 0 && (p.number < p.totalPages - 1 || p.totalElements === 0)
