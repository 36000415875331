import { LabeledTextArea } from 'aos-ui/src/components/form/labeled/LabeledTextArea'
import React, { PureComponent } from 'react'

import { SimpleFormModalActionCreators } from './core/simpleForm/actions'
import { SingleInputModalState, StringValueWrapper } from './core/singleInput/state'
import { SimpleFormModal, SimpleFormModalProps } from './SimpleFormModal'

export class SingleTextAreaModalClass extends PureComponent<
    SimpleFormModalProps & SimpleFormModalActionCreators & SingleInputModalState,
    {}
> {
    public render() {
        const changeText = (value: string) => this.changeForm({ value })
        return (
            <SimpleFormModal {...this.props}>
                <LabeledTextArea
                    isError={this.props.form.error.value}
                    value={this.props.currentValue.value}
                    onChangeText={changeText}
                    isRequired
                    keyPrefix={this.props.keyPrefix}
                />
            </SimpleFormModal>
        )
    }

    protected changeForm = (p: Partial<StringValueWrapper>) => {
        this.props.changeFormValueAction(Object.assign({}, this.props.currentValue, p))
    }
}
