import { debounce } from 'lodash'
import React, { Children, PureComponent, ReactElement } from 'react'
import { ColumnProps, Table, TableProps } from 'react-virtualized'

import { FlightInfoColumnListMeta } from '../../core/flightInformation/flight/flightColumnsState'

interface FlightTableWrapperProps extends TableProps {
    innerRef?: React.RefObject<Table>
    columnList?: FlightInfoColumnListMeta[]
    children: ReactElement<ColumnProps> | any
    onUserScroll?(): void
}

export class FlightTableWrapper extends PureComponent<FlightTableWrapperProps> {
    private scrollCount = 0

    public render() {
        const { innerRef, onUserScroll, children, columnList, ...restProps } = this.props
        return (
            <Table ref={innerRef} {...restProps} onScroll={this.handleScroll}>
                {this.renderColumns()}
            </Table>
        )
    }

    private renderColumns() {
        const { children, columnList } = this.props

        if (!columnList) {
            return children
        }

        const childrenArray = Children.toArray(children).filter(
            (el: ReactElement) => el.props,
        ) as ReactElement[]

        const columns = columnList
            .filter(col => col.visible)
            .map(col => {
                const child = childrenArray.find(el => col.field === el.props.dataKey)
                if (!child) {
                    return null
                }
                return child
            })

        return columns
    }

    private handleScroll = () => {
        this.scrollCount++
        this.checkUserScroll()
    }

    private checkUserScroll = debounce(() => {
        if (this.props.onUserScroll && this.scrollCount > 3) {
            this.props.onUserScroll()
        }
        this.scrollCount = 0
    }, 100)
}
