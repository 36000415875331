import {
    DateTime,
    endOfDay,
    startOfDay,
    timelineNow,
    Timestamps,
} from 'aos-helpers/src/helpers/Time'
import {
    getTimeRangeWithBuffer,
    isTimeRangeWithin,
    TimeRange,
    timeRange,
} from 'aos-helpers/src/helpers/TimeRange'

export interface TimelineState {
    visibleTimeStart: number
    visibleTimeEnd: number
    minZoom: number
    maxZoom: number
    savedPosition?: ScrollPosition
}

export interface ScrollPosition {
    top: number
    left: number
    clientWidth: number
    clientHeight: number
    scrollWidth: number
    scrollHeight: number
    scrollLeft: number
    scrollTop: number
}

export const initialTimelineState: TimelineState = {
    visibleTimeStart: timelineNow().visibleTimeStart,
    visibleTimeEnd: timelineNow().visibleTimeEnd,
    maxZoom: Timestamps.Year,
    minZoom: 4 * Timestamps.Hour,
}

export const setTimelineDay = (timeline: TimelineState, time: DateTime): TimelineState => ({
    ...timeline,
    visibleTimeStart: startOfDay(time).valueOf(),
    visibleTimeEnd: endOfDay(time).valueOf(),
})

export const setTimelineNow = (timeline: TimelineState): TimelineState => {
    const now = timelineNow()
    return {
        ...timeline,
        ...now,
    }
}

export const getCurrentTimelineRange = (state: TimelineState): TimeRange =>
    timeRange(state.visibleTimeStart, state.visibleTimeEnd)

export const scaleTimeline = (timeline: TimelineState, factor: number): [number, number] => {
    const center = (timeline.visibleTimeEnd + timeline.visibleTimeStart) / 2
    let nextWidth = (timeline.visibleTimeEnd - timeline.visibleTimeStart) * factor
    if (nextWidth < timeline.minZoom) {
        nextWidth = timeline.minZoom
    }
    if (nextWidth > timeline.maxZoom) {
        nextWidth = timeline.maxZoom
    }
    return [Math.ceil(center - nextWidth / 2), Math.ceil(center + nextWidth / 2)]
}

export const getTimelineLoadRange = (
    state: TimelineState,
    currentRange?: TimeRange,
): TimeRange | undefined => {
    const timelineLoadRange = getTimeRangeWithBuffer(getCurrentTimelineRange(state), 1)
    if (!currentRange || !isTimeRangeWithin(timelineLoadRange, currentRange)) {
        return timelineLoadRange
    }
    return undefined
}
