import {
    generateGauge,
    GenerateGaugeReturn,
} from 'aos-ui-common/src/components/gauge/generateGauge'
import { GaugeRange } from 'aos-ui-common/src/components/gauge/types/GaugeRange'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

import { BaseGauge, GaugeChildrenType } from './BaseGauge'

export class Gauge extends PureComponent<GaugeProps> {
    public render() {
        return (
            <BaseGauge contentRenderer={this.renderContent} valueRenderer={this.props.children} />
        )
    }

    public renderContent = (size: number) => {
        const { currentValue, ranges } = this.props
        const gauge = generateGauge({ size, currentValue, ranges })

        return (
            <>
                {this.props.unit && this.renderUnit()}
                {this.renderArcs(gauge)}
                {this.renderTics(gauge)}
                {this.renderLabels(gauge)}
                {this.renderNeedle(gauge)}
            </>
        )
    }

    private renderUnit() {
        return (
            <text
                x={0}
                y={16}
                fontSize={9}
                fill={Color.Grey}
                dominantBaseline='middle'
                textAnchor='middle'
            >
                {this.props.unit}
            </text>
        )
    }

    private renderNeedle(gauge: GenerateGaugeReturn) {
        if (!gauge.needle) {
            return null
        }

        const { fill, path, circles } = gauge.needle

        return (
            <>
                <path fill={fill} d={path} />
                {circles.map((circle, index) => (
                    <circle key={index} cx={0} cy={0} r={circle.radius} fill={circle.fill} />
                ))}
            </>
        )
    }

    private renderLabels(gauge: GenerateGaugeReturn): React.ReactNode {
        return gauge.labels.map(({ value, textSize, fill, position: { x, y } }, i) => (
            <text
                key={i}
                x={x}
                y={y}
                fill={fill}
                dominantBaseline='middle'
                textAnchor='middle'
                fontSize={textSize}
                height={textSize}
            >
                {value}
            </text>
        ))
    }

    private renderTics(gauge: GenerateGaugeReturn): React.ReactNode {
        return gauge.labels.map(({ fill, tickPosition: { x, y, width, height, angle } }, i) => (
            <rect
                key={i}
                x={x}
                y={y}
                width={width}
                height={height}
                fill={fill}
                transform={`
                    rotate(${angle},${x},${y})
                    translate(-${width / 2}, -${height / 2})
                `}
            />
        ))
    }

    private renderArcs(gauge: GenerateGaugeReturn): React.ReactNode {
        return gauge.arcs.map((arc, index) => <path key={index} fill={arc.fill} d={arc.path} />)
    }
}

interface GaugeProps {
    ranges: GaugeRange[]
    currentValue: number
    unit?: string
    children?: GaugeChildrenType
}
